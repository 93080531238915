import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { ChartService } from '../../services/chart/chart.service';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-schedule-chart-legend',
  templateUrl: './project-schedule-chart-legend.component.html',
  styleUrls: ['./project-schedule-chart-legend.component.scss']
})
export class ProjectScheduleChartLegendComponent implements OnInit, OnChanges {

  @Input() colorOptionInput;
  @ViewChild('legendContainer') legendContainer;

  legendLabel: string;
  legendData: any;

  constructor(
    private chartService: ChartService
  ) { }

  // tslint:disable-next-line:no-empty
  ngOnInit() { }

  ngOnChanges() {
    if (this.colorOptionInput === 'Criticality') {
      this.buildTaskData();
    } else if (this.colorOptionInput === 'Status') {
      this.buildStatusData();
    }
  }

  buildTaskData(): void {
    this.legendLabel = 'criticality';
    const legendColorPalette = this.chartService.getCriticalityColorPalette();
    if (legendColorPalette) {
      const percentSplit = 100 / legendColorPalette.length;
      this.legendData = !Utils.isEmpty(legendColorPalette)
        ? legendColorPalette.map((color, index) => ({
          label: this.buildTaskLegendLabel(index, percentSplit),
          color: color
        }))
        : null;
    }
  }

  buildTaskLegendLabel(index: number, percentSplit: number): string {
    return index === 0
      ? 0 + ' - ' + ((index * percentSplit) + percentSplit) + '%'
      : (index * percentSplit) + 1 + ' - ' + ((index * percentSplit) + percentSplit) + '%';
  }

  buildStatusData(): void {
    this.legendLabel = 'status';
    const legendColorPalette = this.chartService.getStatusColorPallette();
    if (legendColorPalette) {
      this.legendData = !Utils.isEmpty(legendColorPalette)
        ? legendColorPalette.map((color, index) => ({
          label: this.buildStatusLegendLabel(index),
          color: color
        }))
        : null;
    }
  }

  buildStatusLegendLabel(index: number): string {
    switch (index) {
      case 0:
      return 'scheduled';
      case 1:
      return 'committed';
      case 2:
      return 'in_progress';
      case 3:
      return 'completed';
    }
  }
}
