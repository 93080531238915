import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IColumnHeader, ICustomTable, IRow, IRowItem } from '../../../models/custom-table/custom-table.interface';
import { IExceptionHours, IWorkdayData } from '../../../models/project/project-workdays/project-workdays.interface';

import { HttpBackendService } from '../../http-backend/http-backend.service';

import { Utils } from '../../../utils/utils';
@Injectable()
export class ProjectWorkDaysService {
  // days - associated array to declare all the days of the week and its available selections
  public newDaySelection: any[] =  [
    {
      name: 'sunday',
      display: 'day_sunday',
      abbreviation: 'day_sunday_abbreviation',
      oneDigit: 'day_sunday_letter',
      selected: false,
    },
    {
      name: 'monday',
      display: 'day_monday',
      abbreviation: 'day_monday_abbreviation',
      oneDigit: 'day_monday_letter',
      selected: true,
    },
    {
      name: 'tuesday',
      display: 'day_tuesday',
      abbreviation: 'day_tuesday_abbreviation',
      oneDigit: 'day_tuesday_letter',
      selected: true,
    },
    {
      name: 'wednesday',
      display: 'day_wednesday',
      abbreviation: 'day_wednesday_abbreviation',
      oneDigit: 'day_wednesday_letter',
      selected: true,
    },
    {
      name: 'thursday',
      display: 'day_thursday',
      abbreviation: 'day_thursday_abbreviation',
      oneDigit: 'day_thursday_letter',
      selected: true,
    },
    {
      name: 'friday',
      display: 'day_friday',
      abbreviation: 'day_friday_abbreviation',
      oneDigit: 'day_friday_letter',
      selected: true,
    },
    {
      name: 'saturday',
      display: 'day_saturday',
      abbreviation: 'day_saturday_abbreviation',
      oneDigit: 'day_saturday_letter',
      selected: false,
    },
  ];

  public newHour: any = {
    startTime: 8, // 8:00 am
    startDD: false,
    endTime: 16, // 4:00 pm
    endDD: false
  };

  constructor(private _http: HttpBackendService) {}

  public transformWorkDaysToTableData(projectExceptions: IExceptionHours[], dropDownItems): ICustomTable {

    // Table
    const transformedTableData:  ICustomTable = {
      title: 'Exception Days',
      filterable: false,
      searchable: false,
      columnHeaders: [],
      rows: [],
      multiSelect: false,
      multiSearch: false,
      canCreate: true,
      createRowModel: [],
      pagination: {
        count: projectExceptions.length,
        pageSize: projectExceptions.length,
        pageStart: 0,
        display: true
      },
      showIntro: false
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'date',
        key: 'date',
        sortable: false,
        active: false,
        searchable: false
      },
      {
        title: 'Closed',
        key: 'closed',
        sortable: false,
        active: false,
        searchable: false
      },
      {
        title: 'start_time',
        key: 'startTime',
        sortable: false,
        active: false,
        searchable: false
      },
      {
        title: 'end_time',
        key: 'endTime',
        sortable: false,
        active: false,
        searchable: false
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Table Rows
    const tableRows: IRow[] = [];

    const date = new Date();
    // Create item row
    const createRowModel = [
      {
        name: 'date',
        value: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
        prevStateValue: '',
        editable: true,
        type: 'date'
      },
      {
        name: 'closed',
        value: false,
        prevStateValue: false,
        editable: true,
        type: 'toggleDisabledFlagsCheckbox'
      },
      {
        name: 'startTime',
        value: [8],
        prevStateValue: [8],
        editable: true,
        type: 'dropdown',
        disabled: false,
        staticDropdown: {
          staticDropdownItemsInput: dropDownItems,
          multiSelect: false,
          title: 'Start Time'
        }
      },
      {
        name: 'endTime',
        value: [17],
        prevStateValue: [17],
        editable: true,
        type: 'dropdown',
        disabled: false,
        staticDropdown: {
          staticDropdownItemsInput: dropDownItems,
          multiSelect: false,
          title: 'End Time'
        }
      }
    ];
    transformedTableData.createRowModel = createRowModel;

    // Add other rows
    projectExceptions.forEach(item => {
      const rowItems: IRowItem[] = [
        {
          name: 'date',
          value: Utils.isEmpty(item.date) ? '' : Utils.convertFromUtcToDate(item.date),
          prevStateValue: Utils.isEmpty(item.date) ? '' : Utils.convertFromUtcToDate(item.date),
          editable: true,
          type: 'date'
        },
        {
          name: 'closed',
          value: item.times.length === 0,
          prevStateValue: item.times.length === 0,
          editable: true,
          type: 'toggleDisabledFlagsCheckbox'
        },
        {
          name: 'startTime',
          value: [this.showTime(item.times[0])],
          prevStateValue: [this.showTime(item.times[0])],
          editable:  !this.isTimeEnabled(item),
          type: 'dropdown',
          disabled: item.times.length === 0,
          staticDropdown: {
            staticDropdownItemsInput: dropDownItems,
            multiSelect: false,
            title: 'Start Time'
          }
        },
        {
          name: 'endTime',
          value: [this.showTime(item.times[1])],
          prevStateValue: [this.showTime(item.times[1])],
          editable:  !this.isTimeEnabled(item),
          type: 'dropdown',
          disabled: item.times.length === 0,
          staticDropdown: {
            staticDropdownItemsInput: dropDownItems,
            multiSelect: false,
            title: 'End Time'
          }
        }
      ];

      let key = item.date;
      if (item.times.length === 2) {
        key += item.times[0] + item.times[1];
      }

      tableRows.push({
        selectable: false,
        editable: true,
        removeable: true,
        active: false,
        rowItems: rowItems,
        key: key,
        isEditing: false
      });
    });
    transformedTableData.rows = tableRows;

    return transformedTableData;
  }

  isTimeEnabled(item) {
    return item.wholeDay;
  }

  showTime(seconds) {
    return Utils.isEmpty(seconds) ? 0 : Number(seconds / 3600);
  }

    // data sent back from scenarios need to be converted back to workday
  transformScenarioToWorkDay(workDayArray): IWorkdayData[] {
    const workDayData = [];
    const dayHour = 3600;
    const sameDayDetection = []; // temp way of doing to push hour set to same day selectors
    // loops through each of the work day data to set days selected and hours that was set
    if (workDayArray.length > 0) {
      workDayArray.forEach(workDay => {
        const daySelector = {
          days: [],
          hours: []
        };
        const daySelection = JSON.parse(JSON.stringify(this.newDaySelection));
        const newHourSet = JSON.parse(JSON.stringify(this.newHour));
        let checkString = ''; // using true|false binary to do a string check to get index of the same day to push new hour set into similar day selectors
        // each day selection array value needs to be checked and each value has a name associated with the returned associated array
        // NOTE: also might as well set the startTime and endTime since this is how it was done before did not want to change
        daySelection.forEach(day => {
          const selectedCheck = workDay[day.name].length > 0 ? 1 : 0;
          checkString += selectedCheck.toString();
          if (selectedCheck) {
            day.selected = true;
            newHourSet.startTime = workDay[day.name][0] / dayHour;
            newHourSet.endTime = workDay[day.name][1] / dayHour;
          } else {
            day.selected = false;
          }
        });
        // NOTE: very dirty way of doing this unless BE can return it in how FE needs it.
        // if binary checkString already exist then push new hour set only.
        if (sameDayDetection.indexOf(checkString) !== -1) {
          const index = sameDayDetection.indexOf(checkString);
          workDayData[index].hours.push(newHourSet);
        } else {
          sameDayDetection.push(checkString);
          daySelector.days = daySelection;
          daySelector.hours.push(newHourSet);
          workDayData.push(daySelector);
        }
      });
    }
    return workDayData;
  }

  // transform work day data to be sent into the scenarios schedular
  // TODO: not a very ideal way of doing better to have associated arrays or something
  transformWorkDay(workDayData) {
    const newHours = [];
    const dayHour = 3600;
    if (!Utils.isEmptyList(workDayData)) {
      // work day sets
      workDayData.forEach(workDay => {
        // each of selected hours needs to be associated with the day selector
        workDay.hours.forEach(hour => {
          const dayData = {};
          // check each of the selected days and create associated array with start and end time for the selected days
          workDay.days.forEach(day => {
            if (day.selected) {
              dayData[day.name] = [hour.startTime * dayHour, hour.endTime * dayHour];
            } else {
              dayData[day.name] = [];
            }
          });
          newHours.push(dayData);
        });
      });
    }
    return newHours;
  }
}
