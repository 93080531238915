import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../http-backend/http-backend.service';
import { Utils } from '../../utils/utils';

@Injectable()
export class AuthenticationService {

  constructor(private _http: HttpBackendService) { }

  public isAuthenticated(): Observable<any> {
    return this._http.get('/isAuthenticated');
  }

  public authenticate(json: any): Observable<any> {
    return this._http.post('/authenticate/cookie', json);
  }

  public shortCode(): Observable<any> {
    return this._http.get('/authenticate/shortCode');
  }

  public qrCode(): Observable<any> {
    return this._http.get('/authenticate/qr');
  }

  public logout(): Observable<any> {
    return this._http.post('/authenticate/logout', {});
  }

  public removeMfa() {
    return this._http.delete('/authenticate/mfa');
  }

  public setupMfa() {
    return this._http.post('/authenticate/mfa', {});
  }

  public verifyMfa(token) {
    return this._http.post('/authenticate/mfa/' + token, {});
  }
}
