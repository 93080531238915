import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Utils } from '../../utils/utils';

import { IProjectScheduleStep, IScheduleSelectionOutput } from '../../models/project/project-schedule/project-schedule.interface';
import { IStepsBySub } from '../../models/sidebar/sidebar.interface';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-project-subcontractor-steps-sidebar',
  templateUrl: './project-subcontractor-steps-sidebar.component.html',
  styleUrls: ['./project-subcontractor-steps-sidebar.component.scss']
})
export class ProjectSubcontractorStepsSidebarComponent implements OnChanges, OnInit {
  @Input() sidebarData: IStepsBySub;
  @Input() projectPermissionInput: IUserPermission;

  @Output() itemButtonClickOutput: EventEmitter<IProjectScheduleStep> = new EventEmitter();
  @Output() stepHoverOutput: EventEmitter<IScheduleSelectionOutput> = new EventEmitter();

  dataAvailable: boolean = false;
  noDataMessage: string = 'select_task';
  objectKeys = Object.keys; // used for ngFor loop on object in template
  showEditButton: boolean = false;

  // tslint:disable-next-line:no-empty
  constructor() { }

  // tslint:disable-next-line:no-empty
  ngOnInit() { }

  ngOnChanges() {
    this.dataAvailable = !Utils.isEmpty(this.sidebarData);
    this.showEditButton = this.projectPermissionInput.edit;
  }

  toggleAccordion(item: IStepsBySub): void {
    item.active = !item.active;
  }

  handleItemHover(action: string, step: IProjectScheduleStep) {
    step['active'] = action === 'mouseenter' ? true : false;
    const selectionAction = action === 'mouseenter' ? 'hover' : null;
    const chartSelection = {
      action: selectionAction,
      steps: [step],
      showPastSteps: true
    };

    this.stepHoverOutput.emit(chartSelection);
  }

  handleItemClick(step: IProjectScheduleStep) {
    if (step.projectStepId) {
      this.itemButtonClickOutput.emit(step);
    }
  }
}
