import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ISidebarFilterListItem } from '../../models/sidebar/sidebar.interface';

@Component({
  selector: 'app-project-sidebar-filter',
  templateUrl: './project-sidebar-filter.component.html',
  styleUrls: ['./project-sidebar-filter.component.scss']
})
export class ProjectSidebarFilterComponent implements OnInit, OnChanges {
  @Input() filterListInput: ISidebarFilterListItem[];

  @Output() itemSelectionOutput: EventEmitter<ISidebarFilterListItem> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  ngOnChanges() {}

  handleListItemClick(item: ISidebarFilterListItem): void {
    item.active = !item.active;
    this.itemSelectionOutput.emit(item);
  }

}
