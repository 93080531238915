import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IProjectEquipment, IUnitOfMeasureOption, UnitOfMeasure } from '../../../models/project/project-equipment/project-equipment.interface';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';

@Component({
  selector: 'app-project-equipment-form',
  templateUrl: './project-equipment-form.component.html',
  styleUrls: ['./project-equipment-form.component.scss']
})
export class ProjectEquipmentFormComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() currency: string;
  @Input() plannedQuantity: string;

  @Output() submitOutput: EventEmitter<IProjectEquipment> = new EventEmitter();

  // Template Variables
  addingNew: boolean = false;
  // measureOptions: IUnitOfMeasureOption[] = [];
  currencySymbol: string;

  // Returns specific form control by label
  get resourceId() { return this.formInput.get('resourceId'); }
  get name() { return this.formInput.get('name'); }
  get unitOfMeasure() { return this.formInput.get('unitOfMeasure'); }
  get cost() { return this.formInput.get('cost'); }

  get quantityAssigned() { return this.formInput.get('quantityAssigned'); }
  get budgetQuantity() { return this.formInput.get('budgetQuantity'); }
  get budget() { return this.formInput.get('budget'); }

  constructor(
    private equipmentService: ProjectEquipmentService
  ) {/*EMPTY*/}

  ngOnInit() {
    if (!this.name.value) this.addingNew = true;
    // Object.keys(UnitOfMeasure).forEach(key => this.measureOptions.push({value: UnitOfMeasure[key], display: this.equipmentService.getDisplayValue(UnitOfMeasure[key])}));
  }

  onSubmit(form): void {
    const formValues = form.getRawValue();
    const subcontractor: IProjectEquipment = {
      id: formValues.id ? formValues.id : null,
      resourceId: formValues.resourceId ? formValues.resourceId : null,
      name: formValues.name,
      unitOfMeasure: formValues.unitOfMeasure,
      cost: formValues.cost,
      quantityAssigned: formValues.quantityAssigned,
      budgetQuantity: formValues.budgetQuantity,
      budget: formValues.budget
    };
    this.submitOutput.emit(subcontractor);
  }

  displayFn(item: any): string {
    return item && item.activity && item.activity.name ? item.activity.name : undefined;
  }

}
