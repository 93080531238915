import { Component, NgZone, OnInit } from '@angular/core';

import { EventStatus } from '../../utils/enums/notification.enum';
import { INotification } from '../../models/notification/notification.interface';
import { NotificationService } from '../../services/notification/notification.service';
import { Utils } from '../../utils/utils';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'notification',
    templateUrl: 'notification.component.html'
})

export class NotificationComponent implements OnInit {
    notifications: INotification[] = [];

    constructor(private notificationService: NotificationService, private ngZone: NgZone) { }

    ngOnInit() {
        this.notificationService.getNotification().subscribe((notif: INotification) => {
            if (!notif) {
                this.notifications = [];
                return;
            }
            this.notifications.push(notif);
            Utils.sleep(5000, this.ngZone).then(() => {
                const index = this.notifications.indexOf(notif);
                if (index >= 0) this.notifications.splice(index, 1);
            });
        });
    }

    removeNotification(notif: INotification) {
        this.notifications = this.notifications.filter(x => x !== notif);
    }

    cssClass(notif: INotification) {
        if (!notif) {
            return;
        }

        switch (notif.type) {
            case EventStatus.Success:
                return 'alert alert-success alert-dismissible alert-banner-grit';
            case EventStatus.Error:
                return 'alert alert-danger alert-dismissible alert-banner-grit';
            case EventStatus.Info:
                return 'alert alert-info alert-dismissible alert-banner-grit';
        }
    }
}
