export interface IGritTable {
  title?: string;
  paginationOptions?: IPaginationOptions;
  colHeaders: IColHeader[];
  rows: IGRow[];
  showIntro?: boolean;
  searchOptions?: ISearchOptions;
  filterOptions?: IFilterOptions;
  deleteOptions?: IDeleteOptions;
  addOptions?: IAddOptions;
  selectOptions?: ISelectOptions;
  openEditable?: boolean;
}

export interface IGRow {
  key: string;
  rowItems: IGRowItem[];
  selectable?: boolean;
  selected?: boolean;
  editable?: boolean;
  editing?: boolean;
  editOptions?: IEditOptions;
  dependents? : IGRowItemDependents[];
}

export interface IColHeader {
  displayName: string;
  colKey: string;
  type: RowItemType;
  width?: string;
  sort?: IGSort;
  align? : string;
}

export interface IGRowItem {
  colKey: string;
  value: any;
  sortValue?: any;
  editable?: boolean;
  editing?: boolean;
  required?: boolean;
  placeholder?: string;
  floatLabel?: boolean;
  matSelectOptions?: Array<{value: any, display: string}>;
  suffix?: string;
}

// May replace AddRowItem with just row item, still considering if there might be enough difference in future
export interface IAddRowItem {
  type: RowItemType;
  value: any;
  colKey: string;
  required?: boolean;
  editable?: boolean;
  placeholder?: string;
  floatLabel?: boolean;
  matSelectOptions?: Array<{value: any, display: string}>;
}

export interface IGSort {
  sortable: boolean;
  selected: boolean;
  sortAsc: boolean;
}

export interface ISelectOptions {
  showCheckboxes?: boolean;
  multiSelect?: boolean;
  showSelected?: boolean;
}

export interface IEditOptions {
  deletePermission?: boolean;
  rowEdits?: IRowEditOption[];
  openEdit?: boolean;
  editRowValidationFn?: (rowInput: IGRowItem[], columnKey?) => boolean;
}

export interface IAddOptions {
  inline?: boolean;
  displayName?: string;
  icon?: string;
  enterToAdd?: boolean;
  addPermission?: boolean;
  addModel?: IAddRowItem[];
  addRowValidationFn?: (rowInput: IGRowItem[], columnKey?) => boolean;
}

export interface IDeleteOptions {
  show?: boolean;
  displayName?: string;
  icon?: string;
}

export interface IRowEditOption {
  type: EditType;
  disabled?: boolean;
  customEdit?: string;
  displayName?: string;
  customVal?: any;
  icon?: string;
}

export interface IGRowItemDependents {
  dependentCol: string;
  dependentOn: Array<string>;
  staticValues : any;
}

export enum EditType {
  Delete = 'delete',
  InlineEdit = 'inlineEdit',
  ModalEdit = 'modalEdit',
  Custom = 'custom'
}

export enum RowItemType {
  Date = 'date',
  Text = 'text',
  Number = 'number',
  MatSelect = 'matSelect',
  Checkbox = 'checkbox',
  Icon = 'icon',
  Static = 'static',
  TextIcon = 'textIcon',
  TextDate = 'textDate',
  Currency = 'currency'
}

export interface ISearchOptions {
  show?: boolean;
  enterToSearch?: boolean;
  placeholder?: string;
  colKeys?: string[];
  clearButton?: boolean;
  query?: string;
}

export interface IPaginationOptions {
  showTop?: boolean;
  showBottom?: boolean;
  pageSize?: number;
}

export interface IFilterOptions {
  filtered?: boolean;
  filters: IFilter[];
}

export interface IFilter {
  title: string;
  selected: boolean;
  filterFn: (currentRows: IGRow[]) => IGRow[];
}
