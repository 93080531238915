import { Component, Input, OnInit } from '@angular/core';

import { MenuService } from '../../services/menu/menu.service';
import { ProjectHeaderService } from '../../services/project/project-header/project-header.service';

import { IPageHeader } from '../../models/page-header/page-header.interface';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() pageHeaderInfoInput: IPageHeader;

  constructor(
    public menuService: MenuService,
    private _projectHeaderService: ProjectHeaderService
  ) {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  formatDate(date: string): string {
    const d = new Date(date);
    return d.toLocaleDateString();
  }
}
