
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable , ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { NotificationService } from '../../services/notification/notification.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Utils } from '../../utils/utils';

import { IGritTable, IGRow, IGRowItem } from '../../shared/grit-table/grit-table';
import { INoficationContext } from '../../models/notification/notification.interface';
import { Permission } from '../../utils/enums/permission.enum';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-accounts-token',
  templateUrl: './accounts-token.component.html',
  styleUrls: ['../accounts.component.scss']
})
export class AccountsTokenComponent implements OnInit, OnDestroy {
  accountId: string;
  accountPermission: IUserPermission;
  tokens: any[];
  tableData: IGritTable;

  // Subscription
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private accountService: AccountService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.accountId = params['accountId'];

      this.accountService.getAccountPermission(this.accountId).pipe(takeUntil(this.destroyed$)).subscribe(permission => {
        this.accountPermission = this.accountService.transformPermissions(permission);
      });

      this.getData();
      SegmentService.track('Account Users Loaded', {accountId: this.accountId});
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getData(): void {
    this.accountService.getApiTokens(this.accountId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.tokens = Utils.sortByString(res, 'name');
        this.tableData = this.accountService.transformToTokenTableData(res, this.accountPermission);
      },
      err => {
        const context: INoficationContext = {
          type: 'account user(s)',
          action: 'get'
        };

        this.notificationService.error(err, context);
      });
  }

  create(rowItems: IGRowItem[]): void {
    const newUser = {
      id: this.accountId,
      name: rowItems.find(ri => ri.colKey === 'name').value,
      permission: rowItems.find(ri => ri.colKey === 'permission').value
    };

    if (Utils.isEmpty(newUser.permission)) newUser.permission = Permission.ReadOnly;

    this.accountService.createApiToken(this.accountId, newUser.name, newUser.permission).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        SegmentService.track('API Token Created', {});
        this.tokens.splice(0, 0, res);
        this.tableData = this.accountService.transformToTokenTableData(this.tokens, this.accountPermission);
        this.notificationService.success('API_TOKEN_CREATED', {});
      },
      err => {
        const context: INoficationContext = {
          type: 'api token',
          action: 'create'
        };

        this.notificationService.error(err, context);
      }
    );
  }

  deleteRows(rowIds: string[]) {
    const observableArray: Array<Observable<any>> = new Array();
    rowIds.forEach(id => {
      observableArray.push(this.accountService.removeApiToken(id));
    });

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('API Tokens Deleted', {ids: rowIds});
      },
      err => {
        const context: INoficationContext = {
          type: 'api token',
          action: 'remove'
        };

        this.notificationService.error(err, context);
        this.getData();
    });
  }

  edit(row: IGRow) {
    this.accountService.updateApiToken(row.key, true, row.rowItems[1].value).subscribe(
      () => {
        SegmentService.track('API Tokens Edited', {id: row.key});
      },
      err => {
        const context: INoficationContext = {
          type: 'account user(s)',
          action: 'edit'
        };
        this.notificationService.error(err, context);
        this.getData();
      });
  }
}
