import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Utils } from '../../../utils/utils';

import { IProjectScheduleStep } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectStepHistory } from '../../../models/project/project-step/project-step-history.interface';
import { IProjectStep } from '../../../models/project/project-step/project-step.interface';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpBackendService } from '../../http-backend/http-backend.service';

import { ProjectService } from '../project.service';

@Injectable()
export class ProjectStepService {

  constructor(
    private _http: HttpBackendService,
    private projectService: ProjectService,
    private formBuilderService: FormBuilder
  ) { }

  public getProjectStep(projectStepId: string) {
    return this._http.get('/project/step/' + projectStepId);
  }

  public getPlanStep(planStepId: string) {
    return this._http.get('/project/plan/step/' + planStepId);
  }

  public getProjectPlanStep(projectPlanId: string, projectStepId: string) {
    return this._http.get('/project/plan/' + projectPlanId + '/step/' + projectStepId);
  }

  public getPlannedActivitiesByObjectId(projectId: string, projectObjectId: string) {
    return this._http.get('/project/' + projectId + '/steps/object/' + projectObjectId);
  }

  public getProjectStepByObjectActivityPair(projectId: string, projectObjectId: string, activityId: string) {
    const json = {
      projectId: projectId,
      projectObjectId: projectObjectId,
      activityId: activityId
    };
    return this._http.post('/project/step/objectActivity', json);
  }

  public getLastUpdatedStepFromActivity(projectId: string, activityId: string) {
    return this._http.get('/project/' + projectId + '/activity/' + activityId + '/lastUpdatedStep');
  }

  public getAllProjectSteps(projectId: string, unmodeledOnly?: boolean) {
    if (!Utils.isEmpty(unmodeledOnly)) {
      if (unmodeledOnly) {
        return this._http.get('/project/' + projectId + '/steps?unmodeledOnly=true');
      } else {
        return this._http.get('/project/' + projectId + '/steps?unmodeledOnly=false');
      }
    } else {
      return this._http.get('/project/' + projectId + '/steps');
    }
  }

  public getProjectStepHistory(projectStepId: string): Observable<IProjectStepHistory[]> {
    return this._http.get('/project/step/' + projectStepId + '/history');
  }

  public createProjectStep(json: any): Observable<any> {
    return this._http.post('/project/step', json);
  }

  public updateProjectStep(stepId: string, json: any): Observable<any> {
    return this._http.put('/project/step/' + stepId, json);
  }

  public updateProjectStepStatus(stepId: string, json: any): Observable<any> {
    return this._http.put('/project/step/' + stepId + '/status', json);
  }

  public updateProjectStepMakeReadyStatus(stepId: string, json: any): Observable<any> {
    return this._http.put('/project/step/' + stepId + '/makeReadyStatus', json);
  }

  public deleteProjectStep(stepId: string): Observable<any> {
    return this._http.delete('/project/step/' + stepId);
  }

  public getProjectStepImage(stepId: string): Observable<any> {
    return this._http.getFile('/project/step/' + stepId + '/image');
  }

  public updateStepName(stepId: string, stepName: string): Observable<any> {
    return this._http.put('/project/step/' + stepId + '/stepName', { 'stepName': stepName });
  }

  public getPreqIdsFromSteps(steps: IProjectScheduleStep[]): string[] {
    const prereqIds = [];
    steps.forEach(step => {
      if (step.prerequisiteObjectIds && step.prerequisiteObjectIds.length > 0) {
        step.prerequisiteObjectIds.forEach(id => {
          prereqIds.push(id);
        });
      }
    });

    return prereqIds;
  }

  public getUncommittedBlockerSteps(selectedSteps: IProjectScheduleStep[], allSteps: IProjectScheduleStep[]): IProjectScheduleStep[] {
    const blockerSteps = [];
    const preReqObjectIds = this.getPreqIdsFromSteps(selectedSteps);

    allSteps.forEach(step => {
      if (step.objectIds && step.objectIds.length > 0) {
        step.objectIds.forEach(id => {
          if (preReqObjectIds.includes(id) && step.stepStatus < 2) blockerSteps.push(step);
        });
      }
    });

    return blockerSteps;
  }

  convertStatusToString(status: number) {
    switch (status) {
      case 0:
        return 'Planned';
      case 1:
        return 'Backlog';
      case 2:
        return 'Committed';
      case 3:
        return 'In Progress';
      case 4:
        return 'Completed';
      case 5:
        return 'Updated';
      case 6:
        return 'Not Completed';
    }
  }

  buildFormInput(step: IProjectStep, presetValues?: {[key: string]: any}): FormGroup {
    const stepFormInput = this.formBuilderService.group({
      id: [null],
      projectId: [this.projectService.currentProject.id],
      stepName: [null, Validators.required],
      durationHours: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
      crewSize: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
      lead: [0, Validators.pattern('^[0-9]*$')],
      lag: [0, Validators.pattern('^[0-9]*$')],
      materialIds: [[]],
      equipmentIds: [[]],
      notes: [null],
      ignoreBlockers: [false]
    });

    if (step) {
      stepFormInput.controls['id'].setValue(step.id);
      stepFormInput.controls['stepName'].setValue(step.stepName);
      stepFormInput.controls['durationHours'].setValue(step.durationHours);
      stepFormInput.controls['crewSize'].setValue(step.crewSize);
      stepFormInput.controls['lead'].setValue(step.lead);
      stepFormInput.controls['lag'].setValue(step.lag);
      stepFormInput.controls['ignoreBlockers'].setValue(!step.ignoreBlockers);
      stepFormInput.controls['notes'].setValue('');
      if (step.materialIds) stepFormInput.controls['materialIds'].setValue(step.materialIds);
      if (step.equipmentIds) stepFormInput.controls['equipmentIds'].setValue(step.equipmentIds);
    } else if (presetValues && !Utils.objectIsEmpty(presetValues)) {
      Object.keys(presetValues).forEach(key => {
        stepFormInput.controls[key].setValue(presetValues[key]);
      });
    }

    // if step is committed or more, we don't want to edit these properties
    if (step && step.stepStatus && step.stepStatus > 1) {
      stepFormInput.controls['durationHours'].disable();
      stepFormInput.controls['crewSize'].disable();
      stepFormInput.controls['lead'].disable();
      stepFormInput.controls['lag'].disable();
      stepFormInput.controls['ignoreBlockers'].disable();
    }

    return stepFormInput;
  }

  userHasStepPermissions(step: IProjectScheduleStep): boolean {
    const userPermission = ProjectService.userPermission ? ProjectService.userPermission : null;
    if (userPermission) {
      const userIsReadOnly = !userPermission.edit && !userPermission.admin;
      const userIsGc = (userPermission.gc && !userIsReadOnly) ? userPermission.gc : null;
      const stepSubId = step.subContractorId ? step.subContractorId : null;
      const projectSub = stepSubId ? this.projectService.getLocalSubcontractor(stepSubId) : null;
      const accountSubId = (projectSub && projectSub.subContractorId) ? projectSub.subContractorId : null;

      if (userIsReadOnly) {
        return false;
      } else if (userIsGc && !userIsReadOnly) {
        return true;
      } else if (userPermission.subContractorId && accountSubId) {
        return userPermission.subContractorId === accountSubId;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
