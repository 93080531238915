import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICompleteOutput } from '../../../../models/project/project-sprint/project-sprint.interface';

import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-project-sprint-result-form',
  templateUrl: './project-sprint-result-form.component.html',
  styleUrls: ['./project-sprint-result-form.component.scss']
})
export class ProjectSprintResultFormComponent implements OnInit, OnDestroy {

  @Input() completeFormInput: {actualCrewSize: number, startDate? : any};

  @Output() updateOutput: EventEmitter<{valid: boolean, data: ICompleteOutput}> = new EventEmitter();

  get notes() { return this.formInput.get('notes'); }
  get completeDate() {return this.formInput.get('completeDate'); }
  get actualCrewSize() { return this.formInput.get('actualCrewSize'); }
  get actualDuration() { return this.formInput.get('actualDuration'); }
  get startDate() { return this.formInput.get('startDate');  }

  formInput: FormGroup;
  updateDependentfieldsOnceOnly: boolean = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private formBuilderService: FormBuilder) {/*EMPTY*/}

  ngOnInit() {
    this.formInput = this.buildCompleteFormInput(this.completeFormInput);

    this.handleActualStartDateChange();
    this.handleExpectedDurationChange();
    this.handleExpectedFinishDateChange();
    this.formInput.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onCompleteSubmit(this.formInput);
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  onCompleteSubmit(formOutput) {
    const formValues = formOutput.getRawValue();
    const output: ICompleteOutput = {
      result: null,
      notes: formValues.notes,
      actualCrewSize: formValues.actualCrewSize,
      actualDuration: Utils.convertDaysToHours(formValues.actualDuration),
      completionDate: formValues.completeDate ? Utils.toUtcDate(formValues.completeDate) : null,
      actualEquipment: [],
      actualMaterials: [],
      startDate : formValues.startDate ? Utils.toUtcDate(formValues.startDate) : null
    };
    this.updateOutput.emit({valid: this.formInput.valid, data: output});
  }

  buildCompleteFormInput(completeIn): FormGroup {
    let formInput;

    if(completeIn.startDate){
      formInput = this.formBuilderService.group({
        notes: [''],
        actualCrewSize: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.max(99), Validators.min(1)])],
        actualDuration: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        completeDate: [null, Validators.required],
        startDate : [null, Validators.required],
        actualEquipmentIds: [[]],
        actualMaterialIds: [[]]
      });
    } else {
      formInput = this.formBuilderService.group({
        notes: [''],
        actualCrewSize: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.max(99), Validators.min(1)])],
        actualDuration: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        completeDate: [null, Validators.required],
        actualEquipmentIds: [[]],
        actualMaterialIds: [[]]
      });
    }

    if (completeIn.actualCrewSize) formInput.controls['actualCrewSize'].setValue(completeIn.actualCrewSize);
    if(completeIn.startDate) formInput.controls['startDate'].setValue(new Date(completeIn.startDate));
    if(completeIn.actualDurationHours) formInput.controls['actualDuration'].setValue(Utils.convertHoursToDays(completeIn.actualDurationHours));
    const expectedFinish = Utils.getEndDateExcludingWeekends(completeIn.startDate, Utils.convertHoursToDays(completeIn.actualDurationHours));
    formInput.controls['completeDate'].setValue(new Date(expectedFinish));
    return formInput;
  }

  handleActualStartDateChange() {
    this.formInput.controls['startDate'].valueChanges.subscribe(value => {
      if (!value) return;
      const increment = this.formInput.controls['actualDuration'].value;
      const finishDate = Utils.getEndDateExcludingWeekends(+value, increment);
      
      this.completeDate.setValue(new Date(finishDate));
      this.validationHandlerForActualFinishDate();
    });
  }

  validationHandlerForActualFinishDate() {
    const actualFinishDate = this.completeDate.value;
    if (actualFinishDate < this.formInput.controls['startDate'].value) {
      this.formInput.controls['completeDate'].markAsTouched();
      this.formInput.controls['completeDate'].setErrors({ 'invalid': true });
    }
  }

  handleExpectedDurationChange() {
    this.formInput.controls['actualDuration'].valueChanges.subscribe(value => {
      if(this.updateDependentfieldsOnceOnly) return;

      if(!value){
        this.completeDate.setValue(null);
        return;
      }

      if (value && +this.formInput.controls['startDate'].value) {
        const finishDate = Utils.getEndDateExcludingWeekends(+this.formInput.controls['startDate'].value, value)
        this.completeDate.setValue(new Date(finishDate));
        this.validationHandlerForActualFinishDate()
      }
    });
  }

  handleExpectedFinishDateChange() {
    this.formInput.controls['completeDate'].valueChanges.subscribe(value => {
      if (!value) {
        this.formInput.controls['completeDate'].markAsTouched();
        this.formInput.controls['completeDate'].setErrors({ 'invalid': true });
      }
      this.validationHandlerForActualFinishDate();
      this.updateDependentfieldsOnceOnly = true;
      this.actualDuration.setValue(Utils.getExpectedDuration(+this.formInput.controls['startDate'].value, +this.formInput.controls['completeDate'].value));
      this.updateDependentfieldsOnceOnly = false;
    });
  }
}
