
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { NotificationService } from '../../services/notification/notification.service';
import { Permission } from '../../utils/enums/permission.enum';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
  selector: 'app-accounts-settings',
  templateUrl: './accounts-settings.component.html',
  styleUrls: ['./accounts-settings.component.scss']
})
export class AccountsSettingsComponent implements OnInit, OnDestroy {

  accountPermission: Permission;
  importing = false;

  showImportModal: boolean = false;
  importModalInput: IConfirmationModalInput = {
    displayMessage: 'Please enter the password used to export the file.',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true
  };

  importPassword: string = '';
  importFile = null;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public accountService: AccountService,
    public notificationService: NotificationService,
    public router: Router,
    public segmentService: SegmentService,
    public projectService: ProjectService) { }

  ngOnInit() {
    this.accountService.getAccountPermission(this.accountService.currentAccount.id).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.accountPermission = res;
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  update() {
    const update = {
      name: this.accountService.currentAccount.name,
      contactName: this.accountService.currentAccount.contactName,
      contactEmail: this.accountService.currentAccount.contactEmail,
    };

    this.accountService.updateAccountById(this.accountService.currentAccount.id, update).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.notificationService.success('SUCCESSFUL_SAVE', null);
      },
      err => {
        this.notificationService.error(err, null);
      }
    );
  }

  import(event) {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    this.importFile = files[0];
    this.showImportModal = true;
  }

  importModal(password) {
    this.showImportModal = false;
    this.importing = true;
    this.accountService.importProject(this.accountService.currentAccount.id, this.importFile, password).subscribe(
      res => {
        if (res.type === 4) {
          this.importing = false;
          this.segmentService.track('Project Imported', { accountId: this.accountService.currentAccount.id });
          this.notificationService.success('SUCCESS', null);
          this.projectService.getProject(res.body.response.id).subscribe(project => {
            project.accountName = this.accountService.currentAccount.name;
            MenuEmitterService.emitProjectChange(project);
          });
        }
      },
      () => {
        this.importing = false;
        this.notificationService.error('Project Import Failed (file or password are invalid)', null);
      });
  }

}
