import { MakeReadyStatus } from '../../../utils/enums/make-ready-status.enum';

export interface IProjectMaterial {
    id?: string;
    projectId?: string;
    name: string;
    unitOfMeasure: UnitOfMeasure;
    subId?: string;
    cost?: number;
    startDate?: number;
    prepareLeadDays: number;
    requredOnSiteDate?: number;
    fabricationLeadDays: number;
    approvalDate?: number;
    reviewLeadDays: number;
    submitByDate?: number;
    procoreId?: string;
    makeReadyStatus?: MakeReadyStatus;
    quantityAssigned: string;
    budgetQuantity: string;
    budget: string;
    quantity?: number;
    resourceId?: string;
    plannedQuantity?: number;
    resourceRate?: number;
    percentageLoss?: number;
    importedFromCsv?: boolean;
    importedFromProcore?: boolean;
}

export interface IUnitOfMeasureOption {
    value: UnitOfMeasure;
    display: string;
}

export enum UnitOfMeasure {
    Volume = 'volume',
    Each = 'each',
    Length = 'length',
    SurfaceArea = 'surfaceArea',
    ManHour = 'manHour',
    ManDay = 'manDay'
}
