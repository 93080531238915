
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ColorPaletteService } from '../../services/color-palette/color-palette.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectDashboardService } from '../../services/project/project-dashboard/project-dashboard.service';
import { ProjectPageCriteriaService } from '../../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectSprintService } from '../../services/project/project-sprint/project-sprint.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';

import { IColorPalette } from '../../models/color-palette/color-palette.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProgressBar } from '../../models/progress-bar/progress-bar.interface';
import { IDashboardGridRow, IProjectHomeworkFeedConfig } from '../../models/project/project-dashboard/project-dashboard.interface';
import { IProjectSprint } from '../../models/project/project-sprint/project-sprint.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISidebarFilterListItem, ISidebarTab } from '../../models/sidebar/sidebar.interface';

import { SidebarState } from '../../utils/enums/sidebar-state';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnDestroy, OnInit {
  pageIsLoading: boolean = true;
  loadingMessage: string = 'Loading...';
  errorGettingData: boolean = false;
  errorMessage: string = 'There was error getting dashboard data.';
  homeworkFeedConfig: IProjectHomeworkFeedConfig = {
    showIntro: false,
    header: 'activities_needing_planned',
    showLoadMore: false,
    pageSize: 25
  };

  // Project
  projectId: string;
  projectSubcontractors: IProjectSubContractor[];
  projectChecksPassed: boolean;

  allProjectGridData: IDashboardGridRow[];
  filteredProjectGridData: IDashboardGridRow[];
  overallProjectCompletionTotals: { [key: string]: IProgressBar };

  activeSchedule: any;
  livingSchedule: any;
  allLivingScheduleChartData: any;
  sprintList: IProjectSprint[];
  sprintDdList: IDropdownItem[];
  allSprintData: any;
  allSprintGridData: IDashboardGridRow[];
  filteredSprintGridData: IDashboardGridRow[];
  overallSprintCompletionTotals: { [key: string]: IProgressBar };
  allSprintResourcesData: any;
  filteredSprintResourcesData: any;
  allProjectPlanChartData: any;
  filteredProjectPlanData: any;
  allProjectProductivityChartData: any;
  projectPlanNotCompleteEnums: number[];
  colorPalette: IColorPalette[];

  // Sidebar
  activeSidebarState: SidebarState;
  sidebarState = SidebarState;
  subFilterList: ISidebarFilterListItem[];
  showSidebar: boolean = false;
  selectedSubIds: string[] = [];

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private projectSprintService: ProjectSprintService,
    private projectSubcontractorService: ProjectSubContractorService,
    private projectScheduleService: ProjectScheduleService,
    private routerService: Router,
    private colorPaletteService: ColorPaletteService,
    public projectService: ProjectService,
    public projectPageCriteriaService: ProjectPageCriteriaService,
    public projectDashboardService: ProjectDashboardService
  ) { }

  async ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    if (this.projectService.getProjectReady()) {
      this.getData(this.projectId);
    } else {
      this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
        this.getData(this.projectId);
      });
    }
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async getData(projectId: string) {
    await this.colorPaletteService.setLocalColorPalettes();
    this.colorPalette = this.colorPaletteService.getLocalColorPalettes();
    await this.projectBasicData(projectId);
    if (!Utils.isEmpty(this.projectSubcontractors)) {
      this.setupSidebar(this.projectSubcontractors);

      this.getProjectSectionData(projectId);
      this.getSprintSectionData(projectId);
    }
  }

  async projectBasicData(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const observableArray: Array<Observable<any>> = [];
      observableArray.push(
        this.projectSubcontractorService.getAssociatedSubContractors(projectId), // 0) Ensure at least one subcontractor is associated with project

        this.projectSprintService.getSprintList(projectId) // 1) Determine what sprint to display info for (not a criteria check)
      );

      forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.projectSubcontractors = res[0];
          this.sprintList = !Utils.isEmpty(res[1])
            ? Utils.sortByNumber(res[1], 'startDate', true)
            : res[6];

          this.pageIsLoading = false;

          return resolve();
        },
        err => {
          const context: INoficationContext = {
            type: 'dashboard data',
            action: 'get'
          };
          this.notificationService.error(err, context);

          this.errorGettingData = true;
          this.pageIsLoading = false;

          return resolve();
        }
      );
    });
  }
  /**
   * getProjectSectionData - makes calls to get the data for project side of dashboard
   */
  getProjectSectionData(projectId: string): void {
    this.getLivingScheduleData(projectId);
    this.getProjectCompletionData(projectId);
    this.getProjectPlanCompletionData(projectId);
    this.getProjectProductivity(projectId);
  }

  getLivingScheduleData(projectId: string): void {
    const observableArray = [
      this.projectScheduleService.getActiveSchedule(this.projectId),
      this.projectScheduleService.getLivingSchedule(this.projectId),
      this.projectDashboardService.getLivingScheduleMilestonesData(this.projectId)
    ];
    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.activeSchedule = res[0];
        this.livingSchedule = res[1];
        this.allLivingScheduleChartData = this.projectDashboardService.transformLivingScheduleChartData(res[2]);
      },
      err => {
        const context: INoficationContext = {
          type: 'get dashboard living schedule',
          action: 'get'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  getProjectCompletionData(projectId: string): void {
    this.projectDashboardService.getProjectCompletionTotals(projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        const sortedResults = !Utils.isEmpty(res)
          ? res.sort((a, b) => {
            const aActualHoursPercent = a.totalHrs && a.completedHrs ? a.completedHrs / a.totalHrs : 0;
            const bActualHoursPercent = b.totalHrs && b.completedHrs ? b.completedHrs / b.totalHrs : 0;
            if (aActualHoursPercent > bActualHoursPercent) return -1;
            if (aActualHoursPercent < bActualHoursPercent) return 1;
            return 0;
          })
          : res;
        this.allProjectGridData = !Utils.isEmpty(sortedResults)
          ? this.projectDashboardService.transformTotalsForGrid(this.projectSubcontractors, sortedResults)
          : res;

        this.filteredProjectGridData = this.projectDashboardService.getFilteredGrid(this.allProjectGridData, this.selectedSubIds);
        this.overallProjectCompletionTotals = this.projectDashboardService.getOverallPercentCompleteTotals(res);
      },
      err => {
        const context: INoficationContext = {
          type: 'get dashboard project completion totals',
          action: 'get'
        };
        this.notificationService.error(err, context);
      });
  }

  getProjectPlanCompletionData(projectId: string): void {
    this.projectDashboardService.getAllSprintsCompletionTotals(projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.allProjectPlanChartData = res.length > 0 && this.sprintList.length > 0
          ? this.projectDashboardService.transformProjectPlanDataForChart(this.sprintList, res)
          : null;

        this.projectPlanNotCompleteEnums = this.projectDashboardService.aggregateNotCompleteEnums(res);
      },
      err => {
        const context: INoficationContext = {
          type: 'get dashboard project plan completion totals',
          action: 'get'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  getProjectProductivity(projectId: string): void {
    this.projectDashboardService.getCompletedSprintsCompletionTotals(projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.allProjectProductivityChartData = !Utils.isEmpty(res) && !Utils.isEmpty(this.sprintList)
          ? this.projectDashboardService.transformProjectProductivityDataForChart(this.sprintList, res)
          : null;
      },
      err => {
        const context: INoficationContext = {
          type: 'get dashboard project productivity totals',
          action: 'get'
        };
        this.notificationService.error(err, context);
      });
  }

  /**
   * getSprintSectionData - makes calls to get the data for sprint side of dashboard
   */
  getSprintSectionData(projectId: string): void {
    if (this.sprintList && !Utils.isEmpty(this.sprintList)) {
      this.sprintDdList = this.projectDashboardService.getSprintDd(this.sprintList);
      const displayedSprint = !Utils.isEmpty(this.sprintDdList) ? this.sprintDdList.filter(sprint => sprint.selected)[0] : null;
      const displayedSprintId = displayedSprint && displayedSprint.value ? displayedSprint.value : null;

      if (displayedSprintId) {
        this.getSprintCompletionData(projectId, displayedSprintId);
      }
    }
  }

  getSprintCompletionData(projectId: string, sprintId: string): void {
    this.projectDashboardService.getSprintCompletionTotalsBySub(projectId, sprintId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.allSprintData = res;
        this.allSprintGridData = !Utils.isEmpty(this.allSprintData)
          ? this.projectDashboardService.transformTotalsForGrid(this.projectSubcontractors, this.allSprintData, sprintId)
          : this.allSprintData;
        this.filteredSprintGridData = this.projectDashboardService.getFilteredGrid(this.allSprintGridData, this.selectedSubIds);
        this.overallSprintCompletionTotals = this.projectDashboardService.getOverallPercentCompleteTotals(this.allSprintData);
      },
      err => {
        const context: INoficationContext = {
          type: 'get dashboard completion sprint data',
          action: 'get'
        };
        this.notificationService.error(err, context);
      });
  }

  setupSidebar(subcontractors: IProjectSubContractor[]): void {
    // sort subs at the start, so all lists are consistent
    this.projectSubcontractors = Utils.sortByString(subcontractors, 'name', true);
    this.transformSubsForFilter(this.projectSubcontractors);
    this.setSidebarState();
  }

  transformSubsForFilter(subcontractors: IProjectSubContractor[]): void {
    if (!Utils.isEmpty(subcontractors)) {
      this.subFilterList = this.projectDashboardService.transformSubsToFilterList(subcontractors);

      this.errorGettingData = false;
      this.pageIsLoading = false;
    } else {
      this.errorGettingData = true;
    }
  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.activeSidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab?: ISidebarTab): void {
    if (tab) {
      this.projectDashboardService.sidebarTabs.map(item => item.active = false);
      tab.active = true;
      this.activeSidebarState = tab.key;
    } else {
      this.projectDashboardService.sidebarTabs.map(item => item.active = false);
      this.projectDashboardService.sidebarTabs[0].active = true;
      this.activeSidebarState = this.projectDashboardService.sidebarTabs[0].key;
    }
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) this.projectService.toggleSidebarVisibility(isVisible);
    else this.projectService.toggleSidebarVisibility();
  }

  handleSubFilterOutput(filterItem: ISidebarFilterListItem): void {
    const selectedSubId = filterItem.key;
    const action = filterItem.active ? 'add' : 'remove';

    this.selectedSubIds = this.projectDashboardService.getFilteredSubIds(this.selectedSubIds, selectedSubId, action);
    this.filteredProjectGridData = this.projectDashboardService.getFilteredGrid(this.allProjectGridData, this.selectedSubIds);
    this.filteredSprintGridData = this.projectDashboardService.getFilteredGrid(this.allSprintGridData, this.selectedSubIds);
    this.filteredSprintResourcesData = this.projectDashboardService.getFilteredResourcesChartData(this.allSprintResourcesData, this.selectedSubIds);
    this.overallSprintCompletionTotals = this.projectDashboardService.getOverallPercentCompleteTotals(this.allSprintData, this.selectedSubIds);
  }

  handleSprintSelectionOutput(sprintId: string): void {
    this.getSprintCompletionData(this.projectId, sprintId);
  }

  handleActivitySelection(activityId: string): void {
    this.routerService.navigateByUrl('/project/' + this.projectService.currentProject.id + '/planner/filter/' + activityId);
  }

  pageRefresh(): void {
    window.location.reload(true);
  }
}
