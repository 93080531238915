import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent {

  @Input() showModal: boolean;
  @Input() width: string;
  @Input() height: string;
  @Output() closeModalOutput: EventEmitter<any> = new EventEmitter<any>();

  constructor() {/*EMPTY*/}

  closeModal() {
    this.closeModalOutput.emit();
  }
}
