import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { Utils } from '../../utils/utils';

import { ChartService } from '../../services/chart/chart.service';
import { IColorPalette } from '../../models/color-palette/color-palette.interface';

import * as D3 from 'd3';

@Component({
  selector: 'app-project-dashboard-plan-not-complete',
  templateUrl: './project-dashboard-plan-not-complete.component.html',
  styleUrls: ['./project-dashboard-plan-not-complete.component.scss']
})
export class ProjectDashboardPlanNotCompleteComponent implements OnChanges {
  @Input() chartDataInput: any[];
  @Input() colorPalette: IColorPalette[];

  @ViewChild('chartContainer') chartContainer;

  loadingMessage: string = 'loading';
  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  noData: boolean = true;

  chartHeight: number = 200;
  chartWidth: number = 200;
  radius: number = Math.min(this.chartWidth, this.chartHeight) / 2;
  chartTransform: string = 'translate(' + this.chartWidth / 2 + ',' + this.chartHeight / 2 + ')';
  colorScale: D3.ScaleOrdinal<string, number> = null;

  firstColorSet: string[] = ['#9532BA', '#2EC4B6', '#FF9F1C', '#44AF69', '#386FA4', '#008CFF'];
  // id: 1 is 'Complete' in enum, that's why it's not included
  chartLegendItems: any[] = [];

  constructor(private chartService: ChartService) {}

  ngOnChanges() {
    if (this.chartDataInput) {
      this.setupData();

      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  setupData() {
    if (this.chartDataInput.length > 0) {
      this.setupChart();
      this.noData = false;
    } else {
      this.noData = true;
    }
  }

  async setupChart() {
    this.buildLegend();
    await this.createScales();
    await this.drawChart();
  }

  buildLegend() {
    this.chartDataInput.forEach((item, index) => {
      if (index < this.firstColorSet.length) {
        this.chartLegendItems.push({name: item.name, color: this.firstColorSet[index]});
      } else {
        if (index >= this.colorPalette.length) {
          this.chartLegendItems.push({name: item.name, color: '#000000'});
        } else {
          let notValid = true;
          let randomColor;
          while (notValid) {
            randomColor = this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)];
            if (this.chartLegendItems.filter(cl => cl.color === randomColor.hex).length === 0) {
              this.chartLegendItems.push({name: item.name, color: randomColor.hex});
              notValid = false;
            }
          }
        }
      }
    });
  }

  async createScales() {
    this.colorScale = D3.scaleOrdinal()
      .range(this.chartLegendItems.map(item => item.color))
      .domain(this.chartLegendItems.map(item => item.name));
  }

  async drawChart() {
    const arc = D3.arc().outerRadius(this.radius - 10).innerRadius(0);
    const getPieData = D3.pie().value((d) => d.value);
    const pieChartData = getPieData(this.chartDataInput);

    if (!Utils.isEmpty(pieChartData)) {
      const chart = D3.select(this.chartContainer.nativeElement)
      .append('g')
      .attr('transform', this.chartTransform)
      .selectAll('g');

      chart
        .append('g')
        .attr('transform', this.chartTransform)
        .data(pieChartData)
        .enter()
        .append('g')
        .append('path')
        .attr('d', arc)
        .style('fill', (d) => this.colorScale(d.data.name))
        .on('mouseover', (d) => this.handleSliceHover(d.data, 'mouseover'))
        .on('mouseout', (d) => this.handleSliceHover(d.data, 'mouseout'));
    } else {
      this.noData = true;
    }
  }

  handleSliceHover(sliceData: any, action: string): void {
    if (action === 'mouseover') {
      const ttHeight = 50;
      const ttWidth = sliceData.name ? sliceData.name.length * 8 : 90;
      const ttPosition = this.chartService.positionTooltip(D3.event.pageX, D3.event.pageY, ttHeight, ttWidth);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(sliceData));

    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  drawTooltip(data: any): string {
    let tooltip = ``;
    const percent = data.value ? data.value + '%' : null;
    if (data.name) tooltip += `<div class='data-point no-pad'><span class='label'>` + data.name + `: </span></div>`;
    if (percent) tooltip += `<div class='data-point'><span class='value'>` + percent + `</span></div>`;

    return tooltip;
  }
}
