import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { Utils } from '../../utils/utils';

import { IDashboardColHead, IDashboardGrid } from '../../models/project/project-dashboard/project-dashboard.interface';

@Component({
  selector: 'app-project-dashboard-grid',
  templateUrl: './project-dashboard-grid.component.html',
  styleUrls: ['./project-dashboard-grid.component.scss']
})
export class ProjectDashboardGridComponent implements OnInit, OnChanges {
  @Input() dashboardGridInput: IDashboardGrid;

  selectColHead: IDashboardColHead;

  // tslint:disable-next-line:no-empty
  constructor() {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (this.dashboardGridInput && this.selectColHead) {
      // hold sort order when changing filters
      this.sortTableRows(this.dashboardGridInput.rows, this.selectColHead.sortKey, this.selectColHead.ascending);
    }
  }

  handleColHeadClick(colHead: IDashboardColHead): void {
    this.setActiveSortOrder(colHead);
  }

  setActiveSortOrder(colHead?: IDashboardColHead): void {
    this.selectColHead = colHead ? colHead : this.dashboardGridInput.colHeads[0];

    // set active header
    this.dashboardGridInput.colHeads.map(colH => colH.active = false);
    this.selectColHead.active = true;
    this.selectColHead.ascending = !this.selectColHead.ascending;

    // set active order
    const sortDirection = this.selectColHead.ascending;
    const sortKey = this.selectColHead.sortKey;
    const rows = this.dashboardGridInput.rows;
    this.sortTableRows(rows, sortKey, sortDirection);
  }

  sortTableRows(rows: any[], field: string, ascending?: boolean): any[] {
    if (Utils.isEmpty(ascending)) ascending = true;
    if (Utils.isEmptyList(rows)) { return rows; }

    rows.sort((a, b) => {
      let value1 = a.items.filter(item => item.key === field);
      value1 = !Utils.isEmpty(value1) ? value1[0].value : null;
      let value2 = b.items.filter(item => item.key === field);
      value2 = !Utils.isEmpty(value1) ? value2[0].value : null;

      if (value1 !== null && value2 !== null) {
        if (ascending) {
          if (value1 < value2) {
            return -1;
          }
          if (value1 > value2) {
            return 1;
          }
          return 0;
        } else {
          if (value1 < value2) {
            return 1;
          }
          if (value1 > value2) {
            return -1;
          }
          return 0;
        }
      }
    });

    return rows;
  }
}
