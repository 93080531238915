import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { Utils } from '../../utils/utils';

import { ChartService } from '../../services/chart/chart.service';
import { TranslationService } from '../../services/translation/translation.service';

import * as D3 from 'd3';

@Component({
  selector: 'app-project-dashboard-sprint-productivity',
  templateUrl: './project-dashboard-sprint-productivity.component.html',
  styleUrls: ['./project-dashboard-sprint-productivity.component.scss']
})
export class ProjectDashboardSprintProductivityComponent implements OnInit, OnChanges {
  @Input() chartDataInput: any;

  @ViewChild('ppChartWrapper') ppChartWrapper;
  @ViewChild('chartContainer') chartContainer;
  @ViewChild('chartBarsContainer') chartBarsContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('yAxis') yAxis;

  loadingMessage: string = 'loading';
  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  noData: boolean = true;

  chartHeight: number = 300;
  chartWidth: number;
  chartOffset: number = 50;
  chartTransform: string;
  barWidth: number = 25;
  xAxisTransform: string;
  allXaxisValues: string[];
  allYaxisValues: number[];
  xScale: D3.scaleOrdinal<any> = null;
  yScale: D3.ScaleLinear<number, number> = null;

  constructor(private _chartService: ChartService) {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (this.chartDataInput) {
      this.setupData();

      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  async setupData() {
    const sprintDataAvailable = !Utils.isEmpty(this.chartDataInput);

    if (sprintDataAvailable) {
        this.setupChart();

        this.noData = false;
    } else {
        this.noData = true;
    }
  }

  /**
   * setupChart - call methods in order to build D3 chart
   */
  async setupChart() {
    await this.createScalesAndDimensions();
    await this.setupAxes();
    await this.drawBars();
  }

  /**
   * createScalesAndDimensions - sets D3 scale and chart dimensions
   */
  async createScalesAndDimensions() {
    this.allXaxisValues = [''].concat(this.chartDataInput.map(sprint => sprint.sprintName).sort()).concat(['']); // add empty values at start and end to give horizontal buffer to chart

    // Dimensions
    // const scalePadding = this.chartContainerPadding * 2; // top+botom padding & left&right padding
    const chartMinWidth = document.getElementById('pp-wrapper').offsetWidth;
    const xAxisWidth = (this.allXaxisValues.length * 100) - (this.chartOffset * 2); // 100 is x-axis tick spacing
    this.chartWidth = xAxisWidth < chartMinWidth ? chartMinWidth : xAxisWidth;
    this.chartTransform = `translate(${this.chartOffset}, ${this.chartOffset})`;
    this.xAxisTransform = `translate(0, ${this.chartHeight})`;
    const xScaleRange = this.buildXscaleRange();

    // Scales
    if (!Utils.isEmpty(this.allXaxisValues)) {
      this.xScale = D3.scaleOrdinal()
        .range(xScaleRange)
        .domain(this.allXaxisValues);

      this.yScale = D3.scaleLinear()
        .range([(this.chartHeight), 0])
        .domain([0, 2]);

    } else {
      this.noData = true;
    }
  }

  /**
   * buildXscaleRange - builds the necessary xScale range array based on the number of values in allXaxisValues (ordinal domain/range values are 1 to 1)
   */
  buildXscaleRange(): string[] {
    const xScaleRange = [];
    const rangeDivision =  this.chartWidth / this.allXaxisValues.length;
    for (let i = 0; i < this.chartWidth; i += rangeDivision) xScaleRange.push(i);

    return xScaleRange;
  }

  async setupAxes() {
    await this.drawXaxis(this.allXaxisValues);
    await this.drawYaxis();
  }

  async drawYaxis() {
    const yAxis = D3.axisLeft()
      .scale(this.yScale)
      .ticks(3)
      .tickFormat(D3.format(',.0%'))
      .tickSize(5);

    D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis')
      .call(yAxis);
  }

  async drawXaxis(xAxisValues: string[]) {
    const xAxis = D3.axisBottom()
      .scale(this.xScale)
      .tickSize(5)
      .tickPadding(10)
      .ticks(xAxisValues.length);

    if (!Utils.isEmpty(xAxisValues)) {
      D3.select(this.xAxis.nativeElement)
        .attr('class', 'x-axis')
        .call(xAxis);
    }
  }

  async drawBars() {
    const barContainer = D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('g')
      .data(this.chartDataInput)
      .enter();

    barContainer
      .append('line')
      .style('stroke', '#A4A4B2') // $grit-med-gray
      .style('stroke-width', 1)
      .style('stroke-dasharray', '5,5')
      .attr('y1', this.yScale(1))
      .attr('y2', this.yScale(1))
      .attr('x1', 0)
      .attr('x2', this.chartWidth);

    barContainer
      .append('rect')
      .attr('class', (d) => d.productivityPercent >= 1 ? 'chart-bar green' : 'chart-bar red')
      .attr('x', (d) => this.xScale(d.sprintName) - (this.barWidth / 2))
      .attr('width', this.barWidth)
      .attr('y', (d) => this.getBarScale(d.productivityPercent))
      .attr('height', (d) => d.productivityPercent === 1 ? 1 : this.getBarHeight(d.productivityPercent))
      .on('mouseover', (d) => this.handleBarHover(d, 'mouseover'))
      .on('mouseout', (d) => this.handleBarHover(d, 'mouseout'));

    barContainer
      .append('text')
      .attr('class', (d) => d.productivityPercent >= 1 ? 'chart-bar-label green' : 'chart-bar-label red')
      .attr('y', (d) => this.getBarLabelPosition(d.productivityPercent))
      .attr('x', (d) => this.xScale(d.sprintName) - (this.barWidth / 2) - 2)
      .text((d) => d.label);
  }

  getBarScale(value: number): number {
    let yScale = value >= 1
      ? this.yScale(value)
      : this.yScale(1);

    yScale = yScale < 0 ? 0 : yScale; // anything above 200% will position in middle of chart

    return yScale;
  }

  getBarHeight(value: number): number {
    let height = Math.abs(this.yScale(value) - this.yScale(1));
    height = height >= 150 ? 150 : height; // anything above 200% won't go off chart

    return height;
  }

  getBarLabelPosition(value: number): number {
    return value >= 1
      ? this.getBarScale(value) - 6
      : this.getBarScale(value) + this.getBarHeight(value) + 12;
  }

  handleBarHover(barData: any, action: string): void {
    const chartDimensions = this.ppChartWrapper.nativeElement.getBoundingClientRect();
    const distFromRight = Math.abs(D3.event.pageX - chartDimensions.right);
    const distFromBottom = chartDimensions.bottom - D3.event.pageY;

    if (action === 'mouseover') {
      const ttBuffer = 10;
      const ttHeight = 75;
      const ttWidth = 150;
      const adjustFromRight = distFromRight < (ttWidth + ttBuffer) ? true : false;
      const adjustFromBottom = distFromBottom < (ttHeight + ttBuffer) ? true : false;
      const ttPosition = {
        top: adjustFromBottom ? ((D3.event.pageY + ttBuffer) - ttHeight) : D3.event.pageY + ttBuffer,
        left: adjustFromRight ? ((D3.event.pageX - ttBuffer) - ttWidth) : D3.event.pageX + ttBuffer
      };

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left + 'px')
        .style('top', ttPosition.top + 'px')
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(barData));

    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  drawTooltip(data: any) {
    let tooltip = ``;
    if (data.totalHrs) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('planned_hours_label') +
      `: </span><span class='value'>` + data.totalHrs + `</span></div>`;
    if (data.actualHrs) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('actual_hours') +
    `: </span><span class='value'>` + data.actualHrs + `</span></div>`;
    return tooltip;
  }

}
