import * as FileSaver from 'file-saver';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ICustomTable, IRow, ITableSearchOptions } from '../../models/custom-table/custom-table.interface';
import { IProjectMaterial } from '../../models/project/project-material/project-material.interface';
import { IUserPermission } from '../../models/user/user.interface';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { IGritTable } from '../../shared/grit-table/grit-table';
import { Utils } from '../../utils/utils';
import { ForgeViewerService } from '../../services/forge/forge-viewer.service';

@Component({
  selector: 'app-project-material-settings',
  templateUrl: './project-material-settings.component.html',
  styleUrls: ['./project-material-settings.component.scss']
})
export class ProjectMaterialSettingsComponent implements OnInit, OnDestroy {

  @Input() projectPermissionInput: IUserPermission;
  @Input() projectId: string;

  @Output() ProcurementLineFilter: EventEmitter<boolean> = new EventEmitter();

  file: any = null;
  upLoading: boolean = false;
  isLoading: boolean = true;
  tableData: IGritTable;
  importedTableData: IGritTable;
  submittalData: IGritTable;

  materials: IProjectMaterial[] = [];
  addedMaterials: IProjectMaterial[] = [];
  importedMaterials: IProjectMaterial[] = [];
  currentData: IProjectMaterial[] = [];

  showModal: boolean = false;
  formInput: FormGroup;
  currency;
  searchQuery: string;

  hasProcore = false;
  showProcLinesOnMaster = true;
  plannedQuantity: any = 0;
  autoAssignResources: boolean;
  searchFilters: Array<string> = ["resourceId", "name"];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private materialService: ProjectMaterialService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private segmentService: SegmentService,
    public forgeViewerService: ForgeViewerService,
  ) { }

  ngOnInit() {
    this.getData();
    this.autoAssignResources = this.projectService.currentProject.autoAssignResources ? true : false;
    this.showProcLinesOnMaster = (localStorage.getItem('previouslySavedProcuremntLinesToggle') == "true");
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async exportCSV() {
    let gcOnly = true;
    let subId = null;
    if (this.projectPermissionInput.subContractorId) {
      gcOnly = false;
      subId = this.projectPermissionInput.subContractorId;
    }
    const results = await this.materialService.exportMaterials(this.projectId, subId, gcOnly).pipe(takeUntil(this.destroyed$))
    if (results) {
      results.subscribe(blob => {
        FileSaver.saveAs(blob,'Material_' + this.projectService.currentProject.name + '.csv');
      })
    }
  }

  async getData() {
    if (this.projectService.currentProjectAccount.hasProcore && this.projectService.currentProject.procoreProjectId) this.hasProcore = true;
    let gcOnly = true;
    let subId = null;
    if (this.projectPermissionInput.subContractorId) {
      gcOnly = false;
      subId = this.projectPermissionInput.subContractorId;
    }
    const results = await this.materialService.getList(this.projectId, subId, gcOnly).toPromise();
    this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    if (results) {
      this.materials = Utils.sortByString(results, 'name');
      this.materials.forEach((item) => {
        item.importedFromCsv || item.importedFromProcore ? this.importedMaterials.push(item) : this.addedMaterials.push(item);
      });
      this.setTableData();
      this.isLoading = false;
      this.forgeViewerService.setDisablePage(false);
    } else {
      this.isLoading = false;
      this.forgeViewerService.setDisablePage(false);
    }
  }

  setTableData() {
    this.tableData = this.materialService.transformToTableData(this.materials, this.projectPermissionInput, this.currency);
    this.importedTableData = this.materialService.transformToImportedTableData(this.importedMaterials, this.projectPermissionInput, this.currency);
    this.submittalData = this.materialService.transformToSubmittalTableData(this.materials, this.projectPermissionInput, this.currency);
  }

  async edit(item: IProjectMaterial) {
    if (this.projectPermissionInput.subContractorId) item.subId = this.projectPermissionInput.subContractorId;
    this.materialService.update(item.id, item).pipe(take(1)).subscribe(
      (res) => {
        const index1 = this.materials.findIndex(i => i.id === res.id);
        if (index1 >= 0) this.materials[index1] = res;
        const index2 = this.importedMaterials.findIndex(i => i.id === res.id);
        if (index2 >= 0) this.importedMaterials[index2] = res;
        this.setTableData();
        this.showModal = false;
      },
      err => {
        this.notificationService.error(err, {type: 'material', action: 'update'});
      }
    );
  }

  async deleteRows(selectedIds) {
    await this.materialService.delete(selectedIds).toPromise();
    this.materials = this.materials.filter(item => !selectedIds.includes(item.id));
    this.importedMaterials = this.importedMaterials.filter(item => !selectedIds.includes(item.id));
    this.setTableData()
  }

  async create(item: IProjectMaterial) {
    item.projectId = this.projectId;
    if (this.projectPermissionInput.subContractorId) item.subId = this.projectPermissionInput.subContractorId;
    this.materialService.insert(item).pipe(take(1)).subscribe(
      res => {
        this.materials.push(res);
        this.materials = Utils.sortByString(this.materials, 'name');
        this.currentData = this.materials;
        this.setTableData();
        this.showModal = false;
      },
      err => {
        this.notificationService.error(err, {type: 'material', action: 'insert'});
      }
    );
  }

  validateNewReasonRowFunction(row): boolean {
    if (row.find(col => col.name === 'name').value.length > 0) return true;
    return false;
  }

  async showCreateModal(id: string): Promise<void> {
    const currentItem = this.materials.find(s => s.id === id);
    if (id) this.formInput = this.materialService.buildFormInput(currentItem);
    else this.formInput = this.materialService.buildFormInput(null);
    this.showModal = true;
    this.plannedQuantity = currentItem && parseFloat(Math.round(((currentItem.plannedQuantity * currentItem.resourceRate) * 100) / 100).toString()).toFixed(2);
  }

  formOutput(item: IProjectMaterial) {
    if (item.id) this.edit(item);
    else this.create(item);
  }

  fileAdded(event: EventTarget) {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    this.file = files[0];
  }

  setProcLinesToggle(event) {
    this.showProcLinesOnMaster = event.checked;
    this.ProcurementLineFilter.emit(event.checked);
    localStorage.setItem('previouslySavedProcuremntLinesToggle', event.checked);
  }

  uploadFile(): void {
    if (this.upLoading || this.file === null) return;
    this.upLoading = true;
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    this.forgeViewerService.setDisablePage(true);
    this.materialService.importMaterials(this.projectId, this.file, this.projectPermissionInput.subContractorId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (res.type === 4) {
          this.upLoading = false;
          this.file = null;
          this.segmentService.track('Material CSV Uploaded', {projectId: this.projectId});
          this.getData();
        }
      },
      err => {
        this.upLoading = false;
        this.file = null;
        this.notificationService.error(err, {action: 'upload', filetype: '.csv'});
      }
    );
  }

  importFromProcore() {
    this.isLoading = true;
    this.materialService.importFromProcore(this.projectId).subscribe(
      res => {
        this.materials = this.materials.concat(res);
        this.materials = Utils.sortByString(this.materials, 'name');
        this.currentData = this.materials;
        this.setTableData();
        this.isLoading = false;
      },
      err => {
        this.notificationService.error(err);
        this.isLoading = false;
      });
  }

  filterSearch(searchQuery: string): void {
    this.projectService.externalSearchFilterationOnGritTableHandler(searchQuery, false, this.searchFilters);
  }

  clearSearchAndFilters(): void {
    this.searchQuery = null;
    this.projectService.externalSearchFilterationOnGritTableHandler(null, true, this.searchFilters);
  }
}
