import * as FileSaver from 'file-saver';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ReplaySubject, forkJoin, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActivityService, DEFAULT_TIMING } from '../../services/activity/activity.service';
import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectActivityTypesService } from '../../services/project/project-activity-types/project-activity-types.service';
import { ProjectFilterSearchService } from '../../services/project/project-filter-search/project-filter-search.service';
import { ProjectGanttChartService } from '../../services/project/project-gantt-chart/project-gantt-chart.service';
import { ProjectMasterScheduleService } from '../../services/project/project-master-schedule/project-master-schedule.service';
import { ProjectMilestoneService } from '../../services/project/project-milestone/project-milestone.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';

import { IActivity, IActivityFormOutput, IMasterScheduleActivity } from '../../models/activity/activity.interface';
import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../utils/enums/forge-viewer-type';
import { IMatAutoComplete } from '../../models/material/material-components.interface';
import { MessageType } from '../../utils/enums/message-type.enum';
import { EventType, EventStatus } from '../../utils/enums/notification.enum';
import { PopupModalState } from '../../utils/enums/popup-modal-state.enum';
import { IProjectSchedule } from '../../models/project/project-schedule/project-schedule.interface';
import { IExceptionHours, IHours } from '../../models/project/project-workdays/project-workdays.interface';
import { ViewMode, ViewerMode, PageRenderMode } from '../../utils/enums/shared.enum';
import { SidebarState } from '../../utils/enums/sidebar-state';
import { IPageSpecificFilter, ISidebarTab, IStepsBySub } from '../../models/sidebar/sidebar.interface';

import { Utils } from '../../utils/utils';
import { ProjectComponent } from '../project.component';
import { PDFViewerService } from '../../services/pdf/pdfViewer.service';
import { Color } from 'fabric/fabric-impl';
import { element } from '@angular/core/src/render3/instructions';
import { ActivityColorMap } from '../../models/notification/notification.interface';
import { e } from '@angular/core/src/render3';
import { selection } from 'd3-selection';
import { ProjectEquipmentService } from '../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { IUserPermission } from '../../models/user/user.interface';
import { ProjectSprintService } from '../../services/project/project-sprint/project-sprint.service';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { ProjectSprintBoardService } from '../../services/project/project-sprint-board/project-sprint-board.service';

let projectMasterScheduleComponent: ProjectMasterScheduleComponent;


@Component({
  selector: 'app-project-master-schedule',
  templateUrl: './project-master-schedule.component.html',
  styleUrls: ['./project-master-schedule.component.scss']
})


export class ProjectMasterScheduleComponent implements OnInit, OnDestroy {

  @ViewChild('ganttChart') ganttChart;
  @ViewChild('activityTable') activityTable;

  // Template Variables
  pageIsLoading: boolean = true;
  errorGettingData: boolean = false;
  showActivityModal: boolean = false;
  showModal: boolean = false;
  showConfirmationModal: boolean = false;
  sidebarStateEnum = SidebarState;
  viewModeEnum = ViewMode;
  viewMode: ViewMode = ViewMode.Gantt;
  assigningObjects: boolean = false;
  assignObjectsText: string = 'Assigning Objects';
  popupModalEnum = PopupModalState;
  popupModalState: PopupModalState = PopupModalState.ActivityModal;
  allScenarios: any[] = [];
  importProcessedActivities: any = [];
  selectedScenarioId: string = 'none';
  curActivityIdsSelected: string[] = [];
  activityColorMap: ActivityColorMap;

  // Component Inputs

  activityInput: IMasterScheduleActivity[] = [];
  activityFormInput: FormGroup;
  activitiesFormInput: FormGroup[];
  subAutoInput: IMatAutoComplete;
  milestoneAutoInput: IMatAutoComplete;
  selectedObjectIds: string[] = [];
  confirmationModalInput: IConfirmationModalInput;
  prereqAutoInput: IMasterScheduleActivity[] = [];
  milestoneFormInput: FormGroup;
  lastSelectedObjectIds: string[] = [];
  pageSpecificFilters: IPageSpecificFilter[] = [{ label: 'isolate_selection', checked: false, disabled: false, value: FilterModelOptions.Objects }];
  workDayData: any;
  projectHoursObj: IHours;
  generateScheduleFormInput: FormGroup;
  userNameInput: string;
  subcontractors: IProjectSubContractor[] = [];
  selectedActivityId: string;
  selectedActivities: any;
  activeSprintId: string;

  // Sidebar
  sidebarState: SidebarState = SidebarState.MODEL_FILTER;
  sidebarTabIconData: ISidebarTab[];
  activeFilterType: FilterModelOptions = FilterModelOptions.Activities;
  activeFilters: string[] = [];
  filtersApplied: boolean = false;
  messageType = MessageType;
  searchQuery: string = '';

  chartCheck = true;
  viewerCheck = true;
  orAndFilter = true;
  symbol: string;


  typingTimer;                //timer identifier
  doneTypingInterval = 500;  //time in ms, 5 second for example

  private firstLoad: boolean = true;
  private deletingActivities: boolean = false;
  private myViewerState = {};
  private isolateChartSelection: boolean = false;
  private activityIdsToDelete: string[];

  private loadedActivityId: string;
  private loadedMessage: boolean;
  private quickLinkSelectReady: boolean;
  projectPermission: IUserPermission; 

  // Side Bar Data Variables:
  sidebarStepData: IStepsBySub[] = [];
  cloneOfSidebarStepData: IStepsBySub[] = [];
  
  // Subscription Killer
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private projectService: ProjectService,
    private equipmentService: ProjectEquipmentService,
    private materialService: ProjectMaterialService,
    private masterScheduleService: ProjectMasterScheduleService,
    private projectObjectService: ProjectObjectService,
    private subContractorService: ProjectSubContractorService,
    private milestoneService: ProjectMilestoneService,
    private activityTypesService: ProjectActivityTypesService,
    private forgeViewerService: ForgeViewerService,
    private pdfViewerService: PDFViewerService,
    private projectComponent: ProjectComponent,
    private filterSearchService: ProjectFilterSearchService,
    private projectScheduleService: ProjectScheduleService,
    private notificationService: NotificationService,
    private userService: UserService,
    public projectGanttChartService: ProjectGanttChartService,
    public projectSprintService: ProjectSprintService,
   public projectSprintBoardService: ProjectSprintBoardService
  ) {/*EMPTY*/ }

  async ngOnInit() {
    SegmentService.track(`Master Schedule: Loaded`, { projectId: this.projectService.currentProject.id });
    this.symbol = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency).symbol;
    projectMasterScheduleComponent = this;

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(async myParams => {
      this.loadedMessage = myParams['message'];
      this.loadedActivityId = myParams['activityId'];
      if (this.quickLinkSelectReady) {
        this.handleQuickLink();
      }
    });

    this.projectPermission = ProjectService.userPermission;

    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      if (this.forgeViewerService.poppedOut || !this.firstLoad) {
        SegmentService.track(`Master Schedule: Viewer pop out`, { isPoppedOut: this.forgeViewerService.poppedOut });
        await this.forgeViewerService.setViewerState(this.myViewerState);
        this.setViewerSelections();
        if (this.ganttChart) this.ganttChart.stickyHeader();
      } else {
        this.loadPageData();
      }
    });
    if (this.projectService.getProjectReady()) {
      this.loadPageData();
    }
    // Viewer output
    this.forgeViewerService.forgeViewerOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      this.handleForgeViewerOutput(gritSelection);
    });

    this.forgeViewerService.showSimilarOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      SegmentService.track(`Master Schedule: Filter similar objects`, { viewerContextMenu: true });
      const idForCat = gritSelection[0];
      if (idForCat) this.filterByLastCat(idForCat);
    });

    this.forgeViewerService.clearFiltersOutput.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      SegmentService.track(`Master Schedule: Clear filters`, { viewerContextMenu: true });
      this.filterModelClick([]);
    });

    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!this.notificationService.validEvent(event, this.projectService.currentProject.id, EventType.SCHEDULE_GENERATED)) return;
      if (event.body.status === 14) this.notificationService.eventPopup(EventStatus.Info, 'Generating new schedule.');
      else if (event.body.status === 2) {
        this.notificationService.eventPopup(EventStatus.Success, 'New schedule activated.');
        this.getBackendData();
      }
    });

    this.userService.getAuthenticatedUser().pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.userNameInput = '';
      if (user.firstName) this.userNameInput = user.firstName + ' ';
      if (user.lastName) this.userNameInput += user.lastName;
      if (this.userNameInput === '') this.userNameInput = user.email;
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.masterScheduleService.destroyOpenSubscriptions();
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  async loadPageData() {
    await this.projectService.setEquipmentAndMaterials();
    await this.projectService.setRfis();
    await this.projectService.setLabors();

    const observableArray: Array<Observable<any>> = [];
    await observableArray.push(this.projectSprintService.getActiveSprint(this.projectService.currentProject.id));
    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        // check for existing sprints
        if (!Utils.objectIsEmpty(res[0])) {
          this.projectSprintService.setActiveSprint(res[0]);
          this.activeSprintId = this.projectSprintService.activeSprint.id;
        } else {
          this.projectSprintService.setActiveSprint(null);
        }
      });

    MenuEmitterService.slideOutStateChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => { if (this.ganttChart) this.ganttChart.drawChart(); });
    this.firstLoad = false;
    this.setupSideBar();
    await this.setViewToAllObjects();

    if (!await this.getBackendData()) this.errorGettingData = true;

    this.pageIsLoading = false;
    if (this.loadedActivityId) {
      SegmentService.track(`Master Schedule: Loaded activity`, {});
      this.handleQuickLink();
    }
    this.quickLinkSelectReady = true;
  }

  setScenariosDropdown(scenarios: IProjectSchedule[]): void {
    if (scenarios.length > 0) {
      this.allScenarios = this.projectScheduleService.sortSchedules(scenarios);
    }

    this.allScenarios.unshift({ name: 'None', id: 'none' });
  }

  handleScenarioSelection(): void {
    const sc = this.allScenarios.find(s => s.id === this.selectedScenarioId);
    SegmentService.track(`Master Schedule: Selected scenario overlay`, { scheduleName: sc ? sc.name : 'none', active: sc.active, living: sc.living });
    if (this.ganttChart) {
      this.ganttChart.showAllTasks = this.selectedScenarioId !== 'none';
      this.ganttChart.setSelectedScenarioId(this.selectedScenarioId);
      this.ganttChart.drawChart();
    }
  }

  getBackendData(): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.subContractorService.setLocalProjectSubcontractors(this.projectService.currentProject.id);
      await this.milestoneService.setLocalProjectMilestones(this.projectService.currentProject.id);

      const scenarios = await this.masterScheduleService.getScenarios();
      this.setScenariosDropdown(scenarios);

      const returnObj = await this.masterScheduleService.getProjectHours();
      this.projectHoursObj = {
        timeZone: returnObj.timeZone,
        exceptions: returnObj.exceptions,
        hours: returnObj.hours
      };

      this.workDayData = returnObj.workDayData;
      if (!await this.getActivityData()) {
        return resolve(false);
      } else {
        return resolve(true);
      }
    });
  }

  async setViewToAllObjects() {
    this.forgeViewerService.clearAllThemingColors();
    this.forgeViewerService.clearCutPlanes();
    this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitObjectsToView(this.myViewerState);
  }

  clearSelections() {
    this.forgeViewerService.clearAllThemingColors();
    this.selectedObjectIds = [];
    this.lastSelectedObjectIds = [];
    this.curActivityIdsSelected = [];
    this.selectedScenarioId = 'none';
    if (this.ganttChart) this.ganttChart.resetAllSelections();
    if (this.activityTable) this.activityTable.setTableData();
  }

  handleForgeViewerOutput(gritViewerOutputData: any) {
    if (gritViewerOutputData !== 'esc') {
      if (gritViewerOutputData.length > 0) {
        if (this.assigningObjects) {
          if (gritViewerOutputData.length > 1) {
            gritViewerOutputData.forEach(selection => {
              this.addToSelection(selection);
            });
          } else {
            this.addOrRemoveToSelection(gritViewerOutputData[0]);
          }
          this.setViewerSelections();
        } else {
          this.lastSelectedObjectIds = gritViewerOutputData;
        }
      }
    } else {
      this.selectedObjectIds = [];
      this.lastSelectedObjectIds = [];
      this.setViewerSelections();
    }
  }


  cleanSearchAndFilter() {

    this.searchQuery = "";
    this.activeFilters = [];
    this.filtersApplied = false;

  }

  setSearchQuiry(searchQuery: string) {
    this.searchQuery = searchQuery;

    if (!this.searchQuery && !this.filtersApplied) {
      this.ganttChart.handleSearchCtrl("", this.activeFilterType);

      if(this.projectService.viewerMode === ViewerMode.ForgViewer){
        this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
        if (this.myViewerState)
          this.forgeViewerService.setViewerState(this.myViewerState);
      } else {
        this.pdfViewerService.fileterPDFFiles(null); // Reset pdf viewer to orignal
      }
    }
    else {
      if (!this.filtersApplied) {
        clearTimeout(this.typingTimer);
        if (this.viewerCheck) {
          this.typingTimer = setTimeout(function () {
            projectMasterScheduleComponent.commonFilterAndSelectionChannelForViewer();
          }, projectMasterScheduleComponent.doneTypingInterval);
        }
      }
    }

    if (this.chartCheck)
      this.commonFilterAndSelectionChannelForChart()
  }

  hoverObjectIds(objectIds: string[]): void {

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.pdfViewerService.howerOverActivity(objectIds);
    } else {
      objectIds.forEach(id => {
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Hover });
      });
      if (this.selectedObjectIds.length > 0 && objectIds.length === 0) {
        this.forgeViewerService.clearAllThemingColors();
        this.selectedObjectIds.forEach(id => {
          this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Select });
        });
      } else if (objectIds.length === 0) {
        this.forgeViewerService.clearAllThemingColors();
      }
    }

  }

  addToSelection(gritObjectId: string): void {
    if (!this.selectedObjectIds.includes(gritObjectId)) {
      this.selectedObjectIds.push(gritObjectId);
    }
  }

  addOrRemoveToSelection(gritObjectId: string): void {
    if (this.selectedObjectIds.includes(gritObjectId)) {
      this.selectedObjectIds.splice(this.selectedObjectIds.indexOf(gritObjectId), 1);
    } else {
      this.selectedObjectIds.push(gritObjectId);
    }
  }

  // ACTIVITY DATA
  getActivityData(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const getActivitiesRes = await this.masterScheduleService.getActivities();
      if (getActivitiesRes) {
        this.activityInput = getActivitiesRes;
        this.prereqAutoInput = this.masterScheduleService.setPrereqAutoInput(this.activityInput);
        return resolve(true);
      } else {
        return resolve(false);
      }
    });
  }

  async deleteActivities(ids: string[]) {
    this.forgeViewerService.setDisablePage(true);
    this.deletingActivities = false;
    const deleteResult = await this.masterScheduleService.deleteActivities(ids);
    if (deleteResult && deleteResult.length > 0) {
      deleteResult.forEach(id1 => {
        const itemToEdit = this.activityInput.findIndex(m => m.activity.id === id1);
        if (itemToEdit > -1) this.activityInput.splice(itemToEdit, 1);
      });
      this.clearSelections();
      await this.getActivityData(); // Reset data
      if (this.ganttChart) this.ganttChart.drawChart(this.activityInput);
      if (this.activityTable) this.activityTable.setTableData();
    }
    this.forgeViewerService.setDisablePage(false);
  }

  // END ACTIVITY DATA

  // CHART AND TABLE FUNCTIONS
  async selectionOutput(selected: string[]) {
    // Had to slice array to make onChanges fire on messages sidebar component
    this.curActivityIdsSelected = selected.slice(0);
    if (this.curActivityIdsSelected.length < 1) {
      this.resetSideBarData();
      this.selectedObjectIds = [];
      this.setViewerSelections();
    } else {
      if (this.curActivityIdsSelected && this.curActivityIdsSelected.length > 0) {
        const selectedActivities = [];
        this.curActivityIdsSelected.forEach(id => {
          const activity = this.activityInput.find(a => a.activity.id === id);
          const activityObj = {
            ...activity.activity,
            subContractor: {
              subContractorId: activity.subContractorId
            }
          };
          if (!Utils.isEmpty(activity)) selectedActivities.push(activityObj);
        });

        const selectedStepsArray = selectedActivities.length === 0 ? [] : selectedActivities;
        const stepsBySub = this.projectSprintService.sortStepsBySubContractor(selectedStepsArray);
        this.subcontractors = this.subContractorService.getLocalProjectSubcontractors().concat(ProjectSubContractorService.unassignedSubcontractor);
        this.resetSideBarData();
        this.sidebarStepData = this.projectSprintService.createArrayForSidebarDisplay(stepsBySub, this.subcontractors);
        this.cloneOfSidebarStepData = JSON.parse(JSON.stringify(this.sidebarStepData));
      }

      const objectIdsToPush = await this.filterSearchService.getFilterData(this.curActivityIdsSelected, FilterModelOptions.Activities, this.orAndFilter);
      this.selectedObjectIds = Object.keys(objectIdsToPush);
      this.setViewerSelections();
    }
  }

  resetSideBarData(): void {
    this.sidebarStepData = [];
    this.cloneOfSidebarStepData = [];
  }

  async importClickOutput(data: { file: File, static: boolean, configuration: {} }) {
    const file = data.file;
    SegmentService.track(`Master Schedule: Import file click`, { fileSize: file.size, fileType: file.type });
    this.forgeViewerService.setDisablePage(true);
    const importSuccessful: any = await this.masterScheduleService.importActivities(file, data.static, data.configuration);
    if (importSuccessful.error) {
      this.forgeViewerService.setDisablePage(false);
      this.showModal = false;
    } else {
      await this.getActivityData();
      this.importProcessedActivities = importSuccessful.body.response.importProcessedActivities;
      if (this.ganttChart) this.ganttChart.drawChart(this.activityInput);
      if (this.activityTable) this.activityTable.setTableData();
      this.forgeViewerService.setDisablePage(false);
      this.showModal = false;
      if (this.importProcessedActivities.length > 0) this.showActivityModal = true;
    }
  }

  importOutput(): void {
    this.popupModalState = PopupModalState.ImportGanttModal;
    this.showModal = true;
  }

  exportOutput(type): void {
    this.projectService.exportActivities(this.projectService.currentProject.id, this.selectedScenarioId, type).pipe(takeUntil(this.destroyed$)).subscribe(
      blob => {
        FileSaver.saveAs(blob,
          type === 'xlsx' ? 'P6_' + this.projectService.currentProject.name + '.xlsx'
            : 'Project_' + this.projectService.currentProject.name + '.csv');
      });
  }

  async updateWorkDayOutput(data: any) {
    SegmentService.track(`Master Schedule: Project hours set`, {});
    this.showModal = false;
    const update: IHours = {
      timeZone: this.projectHoursObj.timeZone,
      hours: data,
      exceptions: this.projectHoursObj.exceptions
    };
    this.forgeViewerService.setDisablePage(true);
    if (await this.masterScheduleService.updateProjectHours(update)) {
      this.clearSelections();
      await this.getBackendData();
      this.forgeViewerService.setDisablePage(false);

      if (this.ganttChart) this.ganttChart.drawChart(this.activityInput);
      if (this.activityTable) this.activityTable.setTableData();
    }
  }

  phOutput(): void {
    this.popupModalState = PopupModalState.UpdateProjectHours;
    this.showModal = true;
  }

  exceptionsOutput(): void {
    this.popupModalState = PopupModalState.UpdateExceptionDays;
    this.showModal = true;
  }

  async eUpdateOutput(exceptions: IExceptionHours[]) {
    SegmentService.track(`Master Schedule: Project exceptions set`, {});
    this.showModal = false;
    const update: IHours = {
      timeZone: this.projectHoursObj.timeZone,
      hours: this.projectHoursObj.hours,
      exceptions: exceptions
    };
    this.forgeViewerService.setDisablePage(true);
    if (await this.masterScheduleService.updateProjectHours(update)) {
      this.clearSelections();
      await this.getBackendData();
      this.forgeViewerService.setDisablePage(false);

      if (this.ganttChart) this.ganttChart.drawChart(this.activityInput);
      if (this.activityTable) this.activityTable.setTableData();
    }
  }

  addActivityOutput(parentId: string = null): void {
    SegmentService.track(`Master Schedule: Add activity click`, { rootActivity: parentId ? true : false });
    this.activityFormInput = this.activityTypesService.buildFormInputs(null, this.viewMode, parentId, this.activityInput);
    this.selectedActivityId = parentId;
    this.popupModalState = PopupModalState.ActivityModal;
    this.showModal = true;
  }

  async editActivityOutput(id: string) {
    SegmentService.track(`Master Schedule: Edit activity click`, {});
    await this.getActivityData();
    const activityToEdit = this.activityInput.find(a => a.activity.id === id);
    this.selectedActivityId = id;
    this.activityFormInput = this.activityTypesService.buildFormInputs(activityToEdit, this.viewMode, activityToEdit.activity.parentId);
    this.popupModalState = PopupModalState.ActivityModal;
    this.showModal = true;
  }

  bulkEditActivitiesOutput(ids: string[]): void {
    SegmentService.track(`Master Schedule: Bulk Edit activity click`, {});

    let activitiesToEdit: IMasterScheduleActivity[] = [];
    ids.forEach(id => {
      activitiesToEdit.push(this.activityInput.find(a => a.activity.id === id));
    });

    this.activityFormInput = this.activityTypesService.buildFormInputForBulkEdit(ids, activitiesToEdit);
    this.popupModalState = PopupModalState.bulkEdit;
    this.showModal = true;
  }

  rowEditActivitiesOutput(ids: string[]): void {
    SegmentService.track(`Master Schedule: row edit activities click`, {});
    let activititiesToEdit = [];

    ids.forEach(id => {
      activititiesToEdit.push(this.activityInput.find(a => a.activity.id === id));
    })

    this.subcontractors = this.subContractorService.getLocalProjectSubcontractors();
    this.activitiesFormInput = this.activityTypesService.buildFormInputForRowEdit(activititiesToEdit, this.viewMode);
    this.popupModalState = PopupModalState.activitiesRowEdit;
    this.showModal = true;
  }

  async deleteActivityOutput(ids: string[]) {
    this.showModal = false;
    this.deletingActivities = true;
    this.activityIdsToDelete = ids;
    const affectedStepIds = await this.masterScheduleService.getAffectedStepIds([], this.activityIdsToDelete);
    SegmentService.track(`Master Schedule: Delete activities click`, { numStepsAffected: affectedStepIds.length });
    this.setupConfirmationModal(affectedStepIds);
  }

  async deleteActivityOutputForRowEdit(ids: string[]) {
    this.deletingActivities = true;
    this.activityIdsToDelete = ids;
    this.deleteActivities(this.activityIdsToDelete);
    SegmentService.track(`Master Schedule: Delete activities For quick edit click`, { numStepsAffected: this.activityIdsToDelete.length });
  }

  async assignObjectsOutput(value: boolean) {

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      if (this.pdfViewerService.projectPDFViewerComponent.getpdfPageRender() !== PageRenderMode.onePage){
        this.notificationService.info("annotation_cannot_assigned", {});
        // this.ganttChart.disableObjectAssignIcons();
        return;
      }
    }

    this.assigningObjects = value;


    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      // Desable disablePdfControlDrag it is enable.
    //  / Rushikesh
      this.projectComponent.disablePdfControlDrag();
      this.pdfViewerService.enableSelectionWindow(value, this.activityColorMap);
    } else {
      this.forgeViewerService.enableSelectionWindow(this.assigningObjects);
    }
    if (!this.assigningObjects) {
      if (this.curActivityIdsSelected.length > 0) {
        const affectedStepIds = await this.masterScheduleService.getAffectedStepIds(this.selectedObjectIds, this.curActivityIdsSelected);
        SegmentService.track(`Master Schedule: Assign objects click`, { numStepsAffected: affectedStepIds.length });
        if (affectedStepIds.length > 0) {
          this.setupConfirmationModal(affectedStepIds);
        } else {
          this.forgeViewerService.setDisablePage(true);
          await this.masterScheduleService.assignObjects(this.selectedObjectIds, this.curActivityIdsSelected);
          this.clearSelections();
          await this.getActivityData();
          this.forgeViewerService.setDisablePage(false);

          if (this.ganttChart) this.ganttChart.drawChart(this.activityInput, null, true);
          if (this.activityTable) this.activityTable.setTableData();
        }
      }
    } else {
      if (this.pdfViewerService.projectPDFViewerComponent.getpdfPageRender() !== PageRenderMode.onePage){
        return;
      }
      const mAct = this.activityInput.find(item => item.activity.id === this.curActivityIdsSelected[0]);
      let header = 'Assigning objects to';
      if (this.projectService.viewerMode === ViewerMode.PDFViewer)
        header = 'Assigning work area to';

      if (mAct) this.assignObjectsText = `${header} ${mAct.activity.name}`;
      // Commenting it for now Will remove it once properly test -> Aniket
      // this.filterModelClick(this.activeFilters);
    }
  }

  cancelAssignObjects(): void {
    this.assigningObjects = false;
    this.clearSelections();

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.pdfViewerService.cancelDrawAnnotation();
    } else {
      this.forgeViewerService.enableSelectionWindow(false);
    }

  }

  switchViewOutput(view: ViewMode) {
    this.clearSelections();
    this.viewMode = view;
  }

  addPrereqOutput(output: { activityId: string, prereqId: string }): void {
    const activityToEdit = this.activityInput.find(a => a.activity.id === output.activityId);
    const editItem = cloneDeep(activityToEdit);
    editItem.activity.prerequisites.push({ activity: output.prereqId, timing: DEFAULT_TIMING });
    this.addUpdateActivityModalOutput(editItem);
  }

  viewerObjectOutput(output) {
    this.selectedActivities = output;
    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.pdfViewerService.selectActivities(output);
      // Applying existing filters
      let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
      this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
    } else {
      if (!output.viewState) {
        this.selectionOutput([]);
        this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
      } else {
        this.selectionOutput(output.activityIds);
        this.myViewerState = Utils.copyKeysFromObject(output.viewState);
      }
      this.forgeViewerService.setViewerState(this.myViewerState, output.disablePage);
    }
  }
  // END CHART AND TABLE FUNCTIONS

  // CONFIRMATION MODAL
  setupConfirmationModal(totalStepsAffected?: string[]): void {
    let confirmationModalMessage;
    if (this.deletingActivities) {
      if (totalStepsAffected.length > 0) {
        confirmationModalMessage = 'Deleting activity will force delete <b>' +
          totalStepsAffected.length + '</b> planned task(s).';
      } else {
        confirmationModalMessage = 'Really delete this activity and any child activities?';
      }
    } else {
      confirmationModalMessage = 'Assigning selected objects to the current activity will force delete <b>' +
        totalStepsAffected.length + '</b> planned task(s).';
    }

    this.showConfirmationModal = true;
    this.confirmationModalInput = {
      displayMessage: confirmationModalMessage,
      hasCancelAction: true,
      hasConfirmationAction: true
    };
  }

  handleCancelCloseOutput() {
    this.showConfirmationModal = false;
    this.deletingActivities = false;
    this.clearSelections();
  }

  async handleConfirmationConfirmOutput() {
    this.showConfirmationModal = false;
    if (this.deletingActivities) {
      SegmentService.track(`Master Schedule: Delete activities confirm click`, { numActivities: this.activityIdsToDelete.length });
      this.deleteActivities(this.activityIdsToDelete);
    } else {
      SegmentService.track(`Master Schedule: Assign objects confirm click`, { numObjects: this.selectedObjectIds.length });
      this.forgeViewerService.setDisablePage(true);
      await this.masterScheduleService.assignObjects(this.selectedObjectIds, this.curActivityIdsSelected);
      this.clearSelections();
      await this.getActivityData();
      this.forgeViewerService.setDisablePage(false);

      if (this.ganttChart) this.ganttChart.drawChart(this.activityInput);
      if (this.activityTable) this.activityTable.setTableData();
    }
  }
  // END CONFIRMATION MODAL

  // MODAL
  async addUpdateActivityModalOutput(formOutput: IActivityFormOutput) {   
    this.showModal = false;
    this.forgeViewerService.setDisablePage(true);
    let addEditActivity = formOutput;
    if (formOutput.activity.id) {
      const requestedTasks: Array<any> = [
        this.masterScheduleService.updateActivity(formOutput),
        this.masterScheduleService.setSubContractor(addEditActivity, formOutput),
        this.masterScheduleService.setMilestone(addEditActivity, formOutput),
        this.masterScheduleService.setEquipment(addEditActivity, formOutput, formOutput.updatedEquipments),
        this.masterScheduleService.setMaterials(addEditActivity, formOutput, formOutput.updatedMaterials),
        this.masterScheduleService.setRfi(addEditActivity, formOutput),
        this.masterScheduleService.setLabor(addEditActivity, formOutput, formOutput.updatedLabors)
      ];

      if (formOutput.activity.sprintId) {
        const activity: any = {
          actualStartDate: +formOutput.activity.actualStartDate,
          anchorDate: formOutput.activity.anchorDate,
          crewSize: formOutput.activity.crewSize,
          duration: formOutput.activity.duration,
          expectedFinishDate: +formOutput.activity.expectedFinishDate,
          externalActivityId: formOutput.activity.externalActivityId,
          id: formOutput.activity.id,
          name: formOutput.activity.name,
          sprintId: formOutput.activity.sprintId,
          static: formOutput.activity.static
        }

        if (formOutput.activity.actualFinishDate) {
          activity.actualFinishDate = +formOutput.activity.actualFinishDate;
        }
        requestedTasks.push(this.activityService.autoAssignActivities(this.projectService.currentProject.id, [activity]))
      }

      await Promise.all(requestedTasks);
    } else {
      SegmentService.track(`Master Schedule: Added activity click`, {});
      addEditActivity = await this.masterScheduleService.addActivity(formOutput);
      if (addEditActivity) {
        await Promise.all([
          this.masterScheduleService.setSubContractor(addEditActivity, formOutput),
          this.masterScheduleService.setMilestone(addEditActivity, formOutput),
          this.masterScheduleService.setEquipment(addEditActivity, formOutput, formOutput.updatedEquipments),
          this.masterScheduleService.setMaterials(addEditActivity, formOutput, formOutput.updatedMaterials),
          this.masterScheduleService.setRfi(addEditActivity, formOutput),
          this.masterScheduleService.setLabor(addEditActivity, formOutput, formOutput.updatedLabors),
        ]);
      }
    }
    if (!addEditActivity) {
      await this.getActivityData(); // Reset data
      this.forgeViewerService.setDisablePage(false);
      return;
    }

    const actRes: IActivity = await this.masterScheduleService.getActivity(addEditActivity.activity.id);
    if (actRes && formOutput.milestoneName) {
      SegmentService.track(`Master Schedule: Created and assigned milestone`, {});
      await this.addAndAssignMilestone({ name: formOutput.milestoneName, endDate: actRes.endDate }, addEditActivity.activity.id);
      await this.milestoneService.setLocalProjectMilestones(this.projectService.currentProject.id);
    }

    await this.getActivityData(); // Reset data
    if (this.ganttChart) this.ganttChart.drawChart(this.activityInput, addEditActivity.activity.id, true);
    if (this.activityTable) this.activityTable.setTableData();
    this.forgeViewerService.setDisablePage(false);
  }

  async addAndAssignMilestone(formOutput: { name: string, endDate: number }, activityId: string) {
    formOutput['projectId'] = this.projectService.currentProject.id;
    this.forgeViewerService.setDisablePage(true);
    this.showModal = false;
    await this.masterScheduleService.addMilestone(formOutput).then(async (id) => {
      await this.masterScheduleService.setMilestoneFromIds(activityId, id).catch(() => {/*EMPTY*/ });
    }).catch(() => {/*EMPTY*/ });
  }

  closeModalClick(): void {
    this.showModal = false;
  }

  // MODEL FILTER // It works as selction. If you are restructuring the code name it appropriately
  async filterModelClick(filters: string[]) {
    // this.viewerObjectOutput(this.selectedActivities);
    SegmentService.track(`Master Schedule: Filtered model`, { numFilters: filters.length });
    this.activeFilters = filters;
    this.filtersApplied = filters.length < 1 ? false : true;

    if (!this.filtersApplied && !this.searchQuery) {

      if (this.chartCheck)
        this.ganttChart.handleSearchCtrl("", this.activeFilterType);

      if (this.viewerCheck) {
        if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
          this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
          this.forgeViewerService.setViewerState(this.myViewerState);
        } else {
          let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
          this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
        }
      }
    } else {
      this.commonFilterAndSelectionChannel();
    }
  }

  getPDFUrlsForPDFIds(pdfIds: string[]): string[] {

    if (!pdfIds)
      return null;

    pdfIds = Utils.deDupeArray(pdfIds);

    let pdfUrls = [];
    pdfIds.forEach(pdfId => {
      const pdfObject = this.projectService.getPDFObjectForId(pdfId);
      pdfUrls.push(pdfObject.url);
    });

    return pdfUrls
  }

  // Funtion to return the pdfIds for selections
  getPDFActivitiesMatchingSelections(selections: string[], filterOption: FilterModelOptions, orFilterType: boolean): string[] {

    let chartInput = this.activityInput;
    let pdfIds = [];
    if (selections.length) {
      switch (filterOption) {
        case FilterModelOptions.Activities:
          selections.forEach(sItem => {
            chartInput.forEach(item => {
              if (item.activity.id === sItem) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          });
          break;
        case FilterModelOptions.SubContractor:
          selections.forEach(sItem => {
            chartInput.forEach(item => {
              if (item.subContractorId === sItem) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          });
          break;
        case FilterModelOptions.FloorPlan:
          pdfIds.push(...selections);
          // if (orFilterType) {
          //   selections.forEach(sItem => {
          //     chartInput.forEach(item => {
          //       if (item.activity.pdfIds.includes(sItem)) {
          //         pdfIds.push(...item.activity.pdfIds);
          //       }
          //     });
          //   });
          // } else {
          //   chartInput.forEach(item => {
          //     if (Utils.checker(item.activity.pdfIds, selections)) {
          //       pdfIds.push(...item.activity.pdfIds);
          //     }
          //   });
          // }

          break;
        case FilterModelOptions.Equipment:
          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.equipmentIds.includes(sItem)) {
                  pdfIds.push(...item.activity.pdfIds);
                }
              });
            });
          } else {
            chartInput.forEach(item => {
              if (Utils.checker(item.activity.equipmentIds, selections)) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          }
          break;
        case FilterModelOptions.Materials:
          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.materialIds.includes(sItem)) {
                  pdfIds.push(...item.activity.pdfIds);
                }
              });
            });
          }
      }
    } else {
      pdfIds = null;
    }

    return pdfIds;
  }

  // Funtion to return the pdfIds for Filter
  getPDFActivitiesMatchingFilter(filterQuery: string, filterOption: FilterModelOptions): string[] {

    let chartInput = this.activityInput;
    let pdfIds = [];

    if (filterQuery) {

      switch (filterOption) {
        case FilterModelOptions.Activities:
          chartInput.forEach(item => {
            if (item.activity.name.toLowerCase().includes(filterQuery.toLowerCase())) {
              pdfIds.push(...item.activity.pdfIds);
            }
          });
          break;
        case FilterModelOptions.SubContractor:
          chartInput.forEach(item => {
            if (item.subContractorId) {
              const subcontractor = this.projectService.getLocalSubcontractor(item.subContractorId);
              if (subcontractor.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                pdfIds.push(...item.activity.pdfIds);
              }
            }
          });
          break;
        case FilterModelOptions.FloorPlan:
          chartInput.forEach(item => {
            pdfIds.push(...item.activity.pdfIds);
          });
          break;
        case FilterModelOptions.Equipment:
          chartInput.forEach(item => {
            const equipmentId = item.activity.equipmentIds;
            equipmentId.forEach(eId => {
              const equipment = this.equipmentService.getLocalEquipmentById(eId);
              if (equipment.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          });
          break;
        case FilterModelOptions.Materials:
          chartInput.forEach(item => {
            const materialId = item.activity.materialIds;
            materialId.forEach(mId => {
              const material = this.materialService.getLocalMaterialById(mId);
              if (material.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          });
          break;
      }
    } else {
      pdfIds = null;
    }

    return pdfIds;
  }

  async commonFilterAndSelectionChannel() {
    if (this.filtersApplied) {
      this.ganttChart.clearColumnSelections();
      // filter activites based on activeFilterType
      if (this.chartCheck) {
        this.ganttChart.handleSelectionCtrl(this.activeFilters, this.activeFilterType, this.orAndFilter);
      }
      if (this.viewerCheck) {
        if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
          this.projectService.setFilteringModel(true);
          this.myViewerState = Utils.copyKeysFromObject(await this.filterSearchService.getFilterData(this.activeFilters, this.activeFilterType, this.orAndFilter));
          this.forgeViewerService.setViewerState(this.myViewerState);
        } else {
          let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
          this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
        }
      }
    }
    else {
      if (this.searchQuery) {
        if (this.chartCheck) {
          this.ganttChart.handleSearchCtrl(this.searchQuery, this.activeFilterType);
        }
        if (this.viewerCheck) {
          if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
            if (this.activeFilterType != FilterModelOptions.Category) {
              this.projectService.setFilteringModel(true);
              this.myViewerState = Utils.copyKeysFromObject(await this.filterSearchService.getFilterDataForSearchQuery(this.searchQuery, this.activeFilterType));
              this.forgeViewerService.setViewerState(this.myViewerState);
            }
          }
          else {
            let pdfIds = this.getPDFActivitiesMatchingFilter(this.searchQuery, this.activeFilterType);
            this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
          }
        }
      }
    }
  }

  async commonFilterAndSelectionChannelForViewer() {

    if (this.filtersApplied) {
      this.ganttChart.clearColumnSelections();
      if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
        this.projectService.setFilteringModel(true);
        this.myViewerState = Utils.copyKeysFromObject(await this.filterSearchService.getFilterData(this.activeFilters, this.activeFilterType, this.orAndFilter));
        this.forgeViewerService.setViewerState(this.myViewerState);
      } else {
        let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
        this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
      }
    }
    else {
      if (this.searchQuery && this.activeFilterType != FilterModelOptions.Category) {
        if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
          this.projectService.setFilteringModel(true);
          this.myViewerState = Utils.copyKeysFromObject(await this.filterSearchService.getFilterDataForSearchQuery(this.searchQuery, this.activeFilterType));
          this.forgeViewerService.setViewerState(this.myViewerState);
        } else {
          let pdfIds = this.getPDFActivitiesMatchingFilter(this.searchQuery, this.activeFilterType);
          this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
        }
      }
    }
  }

  async commonFilterAndSelectionChannelForChart() {
    if (this.filtersApplied) {
      this.ganttChart.handleSelectionCtrl(this.activeFilters, this.activeFilterType, this.orAndFilter);
    }
    else {
      if (this.searchQuery)
        this.ganttChart.handleSearchCtrl(this.searchQuery, this.activeFilterType);
    }
  }

  setViewerSelections(): void {

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      if (this.activityColorMap)
        this.pdfViewerService.selectActivities([this.activityColorMap]);
    }
    else {
      this.forgeViewerService.clearAllThemingColors();
      this.selectedObjectIds.forEach(id => {
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Select });
      });
      if (this.isolateChartSelection) {
        this.switchFilterType(FilterModelOptions.Activities);
        this.filterModelClick(this.curActivityIdsSelected);
      }
    }
  }

  async switchFilterType(filterType: FilterModelOptions) {
    SegmentService.track(`Master Schedule: Filter type click`, { filter: filterType });
    this.activeFilterType = filterType;
    this.activeFilters = [];
    this.filtersApplied = false;
  }

  async filterByLastCat(objectId: string) {
    const catIds = this.filterSearchService.filterByLastCat(objectId);
    if (catIds.length > 0) {
      if (this.activeFilterType === FilterModelOptions.Category) {
        this.activeFilters = catIds;
        this.filterModelClick(this.activeFilters);
      } else {
        await this.switchFilterType(FilterModelOptions.Category);
        this.activeFilters = catIds;
        this.filterModelClick(this.activeFilters);
      }
    }
  }

  pageFilterOutput(filter: IPageSpecificFilter) {
    if (filter.value === FilterModelOptions.Objects) {
      this.isolateChartSelection = filter.checked;
      SegmentService.track(`Master Schedule: Filter isolate set`, { isolate: this.isolateChartSelection, numActivities: this.curActivityIdsSelected.length });
      if (this.curActivityIdsSelected.length > 0 && this.isolateChartSelection) {
        this.switchFilterType(FilterModelOptions.Activities);
        this.filterModelClick(this.curActivityIdsSelected);
      }
    }
  }
  // END MODEL FILTER

  // SIDEBAR
  setupSideBar(): void {
    this.sidebarTabIconData = this.masterScheduleService.getSidebarTabData();
    this.forgeViewerService.resizeViewerAfterLoad();
  }

  toggleSideBar(isVisible?: boolean): void {
    this.projectService.toggleSidebarVisibility(isVisible);
    setTimeout(() => {
      if(this.projectService.viewerMode === ViewerMode.ForgViewer)
      this.forgeViewerService.resizeViewerAfterLoad();
      else
      this.pdfViewerService.reloadPDF(this.projectService.showProjectSidebar);
      if (this.ganttChart) this.ganttChart.drawChart(); }, 500);
  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    if (this.sidebarState === tab.key) {
      this.toggleSideBar(!this.projectService.showProjectSidebar);
    } else {
      SegmentService.track(`Master Schedule: Sidebar loaded`, { sideBar: tab.key });
      this.toggleSideBar(true);
    }
    this.setSidebarState(tab);
  }

  setSidebarState(tab: ISidebarTab): void {
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;
    this.sidebarState = tab.key;
  }
  // END SIDEBAR

  handleQuickLink() {
    if (this.loadedMessage) {
      const sidebarMessagesTab = this.sidebarTabIconData.find(tab => tab.key === SidebarState.TASK_MESSAGES);
      if (!sidebarMessagesTab.active) {
        this.handleSidebarIconClick(sidebarMessagesTab);
      }
    }
    if (!this.quickLinkSelectReady) this.selectionOutput([this.loadedActivityId]);
    else this.ganttChart.handleQuickLink(this.loadedActivityId);
  }

  // Search gantt chart activity 27a14b00-f696-11e9-a487-05d5f81f2246"from side bar based on chartCheck toggl
  setChartheck(check) {
    this.chartCheck = check;

    if (!this.chartCheck)
      this.ganttChart.handleSearchCtrl("", this.activeFilterType);
    else {
      this.commonFilterAndSelectionChannelForChart();
    }
  }

  // Search viewerbased on viewerCheck toggle
  async setViewerCheck(check) {

    this.viewerCheck = check;

    if (!this.viewerCheck) {
      if(this.projectService.viewerMode == ViewerMode.ForgViewer){
        this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
        this.forgeViewerService.setViewerState(this.myViewerState);
      }
      else{
        let pdfIds = this.getPDFActivitiesMatchingFilter(this.searchQuery, this.activeFilterType);
        this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
      }
    } else {
      this.commonFilterAndSelectionChannelForViewer();
    }
  }

  async setOrAndFilter(check) {
    this.orAndFilter = check;
    this.commonFilterAndSelectionChannel();
  }

  async setSelectionFilterCheck(check) {
    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.pdfViewerService.setSelectionFilterCheck(check);
    }

  }

  // Generate Schedule Modal
  generateScheduleOutput() {
    this.generateScheduleFormInput = this.masterScheduleService.buildGenerateScheduleForm();
    this.popupModalState = PopupModalState.GenerateSchedule;
    this.showModal = true;
  }

  generateSchedule(formOutput: { name: string, honorCommitments: boolean, sendActivationEmail: boolean }) {
    this.showModal = false;
    this.projectScheduleService.generateSchedule(this.projectService.currentProject.id, { name: formOutput.name, ignoreCommittments: !formOutput.honorCommitments, active: true,
    sendActivationEmail: formOutput.sendActivationEmail });
  }

  async updateMilestoneEquipmentMaterialsSubContractorAndActivityWithPromis(ActivitiesData: IMasterScheduleActivity, formOutput: IMasterScheduleActivity) {
    await Promise.all([
      this.masterScheduleService.updateActivity(formOutput),
      this.masterScheduleService.setSubContractor(ActivitiesData, formOutput),
      this.masterScheduleService.setMilestone(ActivitiesData, formOutput),
      this.masterScheduleService.setEquipment(ActivitiesData, formOutput),
      this.masterScheduleService.setMaterials(ActivitiesData, formOutput),
      this.masterScheduleService.setLabor(ActivitiesData, formOutput)
    ]);
  }

  async updateSubContractorAndActivityWithPromis(ActivitiesData: IMasterScheduleActivity, formOutput: IMasterScheduleActivity) {
    await Promise.all([
      this.masterScheduleService.updateActivity(formOutput),
      this.masterScheduleService.setSubContractor(ActivitiesData, formOutput)
    ]);
  }

  async addUpdateRowEditActivitiesModalOutput(formOutput: any) {
    SegmentService.track(`Master Schedule: bulk edit activity click`, {});

    this.showModal = false;
    this.forgeViewerService.setDisablePage(true);
    let bulkEditActivitiesData = formOutput;

    for (let i = 0; i < bulkEditActivitiesData.length; ++i) {
      if (bulkEditActivitiesData[i].activity.id) {
        this.updateSubContractorAndActivityWithPromis(bulkEditActivitiesData[i], formOutput[i]);
      } else {
        let addEditActivity = await this.masterScheduleService.addActivity(formOutput[i]);

        if (addEditActivity) {
          await Promise.all([
            this.masterScheduleService.setSubContractor(addEditActivity, formOutput[i]),
          ]);
        }

      }
    }
    await this.getActivityData();
    if (this.ganttChart) this.ganttChart.drawChart(this.activityInput, null, true);
    if (this.activityTable) this.activityTable.setTableData();

    this.forgeViewerService.setDisablePage(false);
  }

  async addUpdateBulkActivitiesModalOutput(formOutput: any) {
    SegmentService.track(`Master Schedule: bulk edit activity click`, {});
    this.showModal = false;
    this.forgeViewerService.setDisablePage(true);
    let bulkEditActivitiesData = formOutput;

    for (let i = 0; i < bulkEditActivitiesData.length; ++i) {
      this.updateMilestoneEquipmentMaterialsSubContractorAndActivityWithPromis(bulkEditActivitiesData[i], formOutput[i]);
    }

    for (let i = 0; i < bulkEditActivitiesData.length; ++i) {

      const actRes: IActivity = await this.masterScheduleService.getActivity(bulkEditActivitiesData[i].activity.id);
      if (actRes && formOutput[i].milestoneName) {
        SegmentService.track(`Master Schedule: Created and assigned milestone`, {});
        await this.addAndAssignMilestone({ name: formOutput[i].milestoneName, endDate: actRes.endDate }, bulkEditActivitiesData[i].activity.id);
        await this.milestoneService.setLocalProjectMilestones(this.projectService.currentProject.id);
      }

    }

    await this.getActivityData();
    if (this.ganttChart) this.ganttChart.drawChart(this.activityInput, null, true);
    if (this.activityTable) this.activityTable.setTableData();

    this.forgeViewerService.setDisablePage(false);
  }

  async deleteActivityAnnotation() {
    this.pdfViewerService.deleteAnnotations(this.projectService.currentProject.id, this.curActivityIdsSelected[0]);
    this.assigningObjects = false;
    this.clearSelections();
  }

  setAcctivtySubOutput(activityColorMap: ActivityColorMap) {
    this.activityColorMap = activityColorMap;
    this.curActivityIdsSelected = [activityColorMap.activityId];
  }

  autoAssignActivities(activities) {
    this.forgeViewerService.setDisablePage(true);
    this.activityService.autoAssignActivities(this.projectService.currentProject.id, activities)
      .subscribe(res => {
        this.showActivityModal = false;
        this.forgeViewerService.setDisablePage(false);
      }, err => {
        console.log(err);
        this.forgeViewerService.setDisablePage(false);
      });
  }
}
