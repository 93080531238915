import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectService } from '../../services/project/project.service';

import { IPageSubmenuSection } from '../../models/page-submenu/page-submenu.interface';

enum ReportSectionsEnum {
  BudgetOverTime = 0,
  RollingBudgetOverTime = 1,
  ManPower = 2,
  MakeReady = 3,
  ScheduleChange = 4
}
@Component({
  selector: 'app-project-report',
  templateUrl: './project-report.component.html',
  styleUrls: ['./project-report.component.scss']
})
export class ProjectReportComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  reportSections = ReportSectionsEnum;
  activeSection: ReportSectionsEnum = ReportSectionsEnum.BudgetOverTime;
  submenuSections: IPageSubmenuSection[] = [
    {
      title: 'cost',
      links: [
        {
          title: 'budget_over_time',
          menuIndex: 0,
          key: ReportSectionsEnum.BudgetOverTime,
          active: true
        },
        {
          title: 'rolling_budget',
          menuIndex: 1,
          key: ReportSectionsEnum.RollingBudgetOverTime,
          active: false
        },
        {
          title: 'manpower',
          menuIndex: 2,
          key: ReportSectionsEnum.ManPower,
          active: false
        },
        {
          title: 'equipment_and_materials',
          menuIndex: 3,
          key: ReportSectionsEnum.MakeReady,
          active: false
        },
        {
          title: 'schedule_change',
          menuIndex: 4,
          key: ReportSectionsEnum.ScheduleChange,
          active: false
        }
      ]
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.projectService.checkToHideSidebar(this.projectService.showProjectSidebar);
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(queryParams => {
      this.activeSection = this.getActiveSection(queryParams.menuItem);
      this.setSubmenuState(this.activeSection);
    });
  }

  getActiveSection(param: number): ReportSectionsEnum {
    let activeSection: ReportSectionsEnum;
    Object.keys(this.reportSections).forEach(section => {
      if (section === this.reportSections[param]) activeSection = this.reportSections[section];
    });

    return activeSection ? activeSection : ReportSectionsEnum.BudgetOverTime;
  }

  setSubmenuState(activeSection: ReportSectionsEnum): void {
    this.submenuSections.forEach(section => {
      section.links.forEach(link => {
        if (link.key === activeSection) link.active = true;
        else link.active = false;
      });
    });
  }

  ngOnDestroy() {
    this.projectService.checkToHideSidebar(this.projectService.showProjectSidebar, true);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }
}
