import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccountService } from '../../services/account/account.service';
import { ForgeService } from '../../services/forge/forge.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProcoreService } from '../../services/procore/procore.service';

import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IUserPermission } from '../../models/user/user.interface';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-accounts-integrations',
  templateUrl: './accounts-integrations.component.html',
  styleUrls: ['./accounts-integrations.component.scss']
})
export class AccountsIntegrationsComponent implements OnInit, OnDestroy {

  private accountId: string;
  public accountPermission: IUserPermission;

  // Procore Integration
  procoreIntegrated: boolean = false;

  modalInput: IConfirmationModalInput = {
    displayMessage: 'Are you sure you would like to disconnect.',
    hasCancelAction: false,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: false
  };
  showConfirm: boolean = false;

  private disconnectingProcore: boolean = false;

  private routeSubscription: Subscription;

  bim360Accounts = [];

  constructor(
    private route: ActivatedRoute,
    private procoreService: ProcoreService,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private forgeService: ForgeService
  ) { }

  ngOnInit() {
    // Set procore integration
    this.procoreIntegrated = this.accountService.currentAccount.hasProcore;

    this.forgeService.getList(this.accountService.currentAccount.id).subscribe(
      res => {
        this.bim360Accounts = res;
      },
      err => {
        const context: INoficationContext = {
          type: 'BIM360 Setup',
          action: 'get'
        };
        this.notificationService.error(err, context);
      }
    );
    this.accountPermission = AccountService.userAccountPermission;

    this.routeSubscription = this.route.parent.params.subscribe(params => {
      this.accountId = params['accountId'];
    });

    this.route.queryParams.subscribe(params => {
      if (!Utils.isEmpty(params['code'])) {
        if (params['integration'] === 'bim360') {
          this.forgeService.postAuthCode(this.accountService.currentAccount.id, params['code']).subscribe(
            () => {
              this.forgeService.getList(this.accountService.currentAccount.id).subscribe(result => {
                this.bim360Accounts = result;
              });
            },
            err => {
              const context: INoficationContext = {
                type: 'BIM360 Setup',
                action: 'get'
              };
              this.notificationService.error(err, context);
            }
          );
        } else if (params['integration'] === 'procore') {
          this.procoreService.postAuthCode(this.accountService.currentAccount.id, params['code']).subscribe(
            () => {
              this.accountService.currentAccount.hasProcore = true;
              this.procoreIntegrated = true;
            },
            err => {
              const context: INoficationContext = {
                type: 'Procore Setup',
                action: 'get'
              };
              this.notificationService.error(err, context);
            }
          );
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  addProcore() {
    this.procoreService.getAuthUrl(localStorage.getItem('csrfToken')).subscribe(url => {
      localStorage.setItem('accountId', this.accountService.currentAccount.id);
      window.location.href = url;
    });
  }

  disconnectProcore() {
    this.procoreService.removeProcore(this.accountService.currentAccount.id).toPromise().then(
      () => {
        this.accountService.currentAccount.hasProcore = false;
        this.procoreIntegrated = false;
      }
    ).catch(
      err => {
        const context: INoficationContext = {
          type: 'Procore removal',
          action: 'put'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  showConfirmModal(disconnectingProcore: boolean) {
    this.disconnectingProcore = disconnectingProcore;
    this.modalInput.displayMessage = `Are you sure you want to disconnect ${this.disconnectingProcore ? 'Procore' : 'BIM 360'} from this account.
    This may seriously affect any projects associated with this account and may not be able to be reversed.
    Please proceed with caution.`;
    this.showConfirm = true;
  }

  addBim360() {
    this.forgeService.getAuthUrl(localStorage.getItem('csrfToken')).subscribe(url => {
      localStorage.setItem('accountId', this.accountService.currentAccount.id);
      window.location.href = url.url;
    });
  }

  disconnectBim360() {
    this.forgeService.removeBim360(this.bim360Accounts[0].id).toPromise().then(
      () => {
        this.bim360Accounts = [];
      }
    ).catch(
      err => {
        const context: INoficationContext = {
          type: 'BIM 360 removal',
          action: 'delete'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  confirmModalOut() {
    this.showConfirm = false;
    if (this.disconnectingProcore) {
      this.disconnectProcore();
    } else {
      this.disconnectBim360();
    }
  }

}
