import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Utils } from '../../utils/utils';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { ForgeService } from '../../services/forge/forge.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { NotificationService } from '../../services/notification/notification.service';
import { SegmentService } from '../../services/segment/segment.service';
import { TranslationService } from '../../services/translation/translation.service';

import { IForgeContextMenuOutput, IForgeProjectModel } from '../../models/forge/forge.interface';
import { DecodeMesh } from '../../services/forge/polygon.util';

import { BoxTree } from '../../services/utils/box-tree';

import { Buffer } from 'buffer/';

declare const Autodesk: any;
declare const THREE: any;

@Component({
  selector: 'app-project-forge-viewer',
  templateUrl: './project-forge-viewer.component.html',
  styleUrls: ['./project-forge-viewer.component.scss']
})
export class ProjectForgeViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() projectId: string;
  @Output() viewerLoadCompleteOutput = new EventEmitter();
  @Output() detachViewerOutput = new EventEmitter();
  @Output() forgeViewerOutput: EventEmitter<any> = new EventEmitter();
  @Output() showSimilarOutput: EventEmitter<any> = new EventEmitter();
  @Output() clearFiltersOutput: EventEmitter<void> = new EventEmitter();
  @Output() contextMenuOutput: EventEmitter<IForgeContextMenuOutput> = new EventEmitter(); // Put all further context menu events in this, can't keep making output for every menu item
  @Output() saveViewOutput: EventEmitter<void> = new EventEmitter();
  @Output() viewerCutOutput: EventEmitter<any> = new EventEmitter();

  // Forge Viewer
  @ViewChild('viewerContainer') viewerContainer: any;
  forgeViewer: any;
  firstGlobalOffset;
  firstScale;
  modelArray: IForgeProjectModel[] = [];
  totalModels: number;
  viewerIsDoneLoading: boolean = false;
  largestModelBB: any;

  // Loading
  loadPercentage: number = 0;
  percentDisplay: number = 0;
  loadingComplete: boolean = false;
  loadingModelsMessage: string = 'Model Loading';

  forgeModels: any[] = [];

  materials = {};
  objectMeshes = {};
  objectIds = {};
  objectData = {};

  // TODO this is to prevent the selectionChange method from running when isolate fires in runActionsOnObjects method
  fireSelectionChange: boolean = true;

  // Cur click location in viewer
  curClientX;
  curClientY;

  // Subscriptions
  modelUrnSubscription: Subscription;
  similarObjectsMenuListener: any;
  clearFiltersMenuListener: any;
  cutListener: any;
  escapeListener: any;
  contextMenuListener: any;

  viewerTries: number = 0;
  customLoaded: boolean = false;

  boxTree: BoxTree;

  private ranSetup: boolean = false;
  private lastHit = null;
  private selectionWindowTool = null;

  constructor(
    private ngZone: NgZone,
    private forgeService: ForgeService,
    private notificationService: NotificationService,
    public forgeViewerService: ForgeViewerService,
    private segmentService: SegmentService
  ) {}

  ngOnInit() {
    Autodesk.Viewing.theExtensionManager.registerExternalExtension(
      'GritContextMenu',
      window.location.origin + '/assets/script/extensions/gritContextMenu.js');

    Autodesk.Viewing.theExtensionManager.registerExternalExtension(
      'SelectionWindow',
      window.location.origin + '/assets/script/extensions/SelectionWindow/SelectionWindow.js');

    Autodesk.Viewing.theExtensionManager.registerExternalExtension(
      'GritCutTool',
      window.location.origin + '/assets/script/extensions/gritCutTool.js');

    this.boxTree = new BoxTree();
    this.similarObjectsMenuListener = window.addEventListener('VIEWER_SHOW_SIMILAR', this.showSimilar.bind(this));
    this.clearFiltersMenuListener = window.addEventListener('VIEWER_CLEAR_FILTERS', this.clearFilters.bind(this));
    this.contextMenuListener = window.addEventListener('GRIT_CONTEXT_MENU', this.gritContextMenuEvent.bind(this));
    this.cutListener = window.addEventListener('VIEWER_CUT', () => {
      if (event['detail'].objectId) {
        this.performCut(event['detail']);
      }
    });
    this.escapeListener = window.addEventListener('keydown', this.keyDown.bind(this));
  }

  ngAfterViewInit() {
    this.getSetupData();
  }

  ngOnDestroy() {
    this.tearDownViewer();
    window.removeEventListener('VIEWER_SHOW_SIMILAR', this.similarObjectsMenuListener);
    window.removeEventListener('VIEWER_CUT', this.cutListener);
    window.removeEventListener('GRIT_CONTEXT_MENU', this.contextMenuListener);
    if (this.modelUrnSubscription) this.modelUrnSubscription.unsubscribe();
  }

  handleViewerError(err) {
    if (++this.viewerTries >= 1) {
      this.viewerLoadCompleteOutput.emit(err);
    } else {
      this.fireSelectionChange = false;
      try {
        if (this.forgeViewer) {
          this.forgeViewer.tearDown();
          this.forgeViewer.finish();
        }
      } catch (e) {
        console.error(e);
      }
      this.forgeModels = [];
      this.forgeViewerService.forgeModels = [];
      this.forgeViewer = null;
      this.fireSelectionChange = true;

      this.getSetupData();
    }
  }

  tearDownViewer() {
    this.fireSelectionChange = false;
    if (this.forgeViewer && this.forgeViewer.running) {
      this.forgeViewer.tearDown();
      this.forgeViewer.finish();
      this.forgeModels = [];
      this.forgeViewerService.forgeModels = [];
      this.forgeViewer = null;
      this.loadPercentage = 0;
      this.ranSetup = false;
      this.forgeViewerService.viewerComponent = null;
    }

    this.fireSelectionChange = true;
  }

  // Get data from parent components
  getSetupData(): void {
    this.forgeViewerService.projectModelIdMap = new Object();
    this.forgeViewerService.forgeModelIdMap = new Object();
    this.forgeViewerService.projectModelForgeModelMap = new Object();
    this.forgeViewerService.getForgeViewerData(this.projectId).then((modelsArray) => {
      this.modelArray = modelsArray;
      this.totalModels = modelsArray.length;
      if (this.viewerContainer) {
        this.setupViewer();
      }
    });
  }

  // Forge Viewer Setup
  async setupViewer() {
    const options = {
      useADP: false,
      env: 'AutodeskProduction',
      useCredentials: true
    };

    // Check if the viewer has already been initialised - this isn't the nicest, but we've set the env in our
    // options above so we at least know that it was us who did this!
    const loadViewer = async () => {
      try {
        const proxyData = this.forgeService.getProxyData();
        Autodesk.Viewing.endpoint.setEndpointAndApi(proxyData.server + '/lmv-proxy/' + this.projectId, 'modelDerivativeV2');
        Autodesk.Viewing.endpoint.setUseCredentials(true);
        Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS['X-CSRF-TOKEN'] = proxyData.csrf;
        delete Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS['Authorization'];

        this.forgeViewer = new Autodesk.Viewing.Private.GuiViewer3D(this.viewerContainer.nativeElement);

        this.setupModels(this.modelArray);
      } catch (error) {
        this.handleViewerError(error);
        this.segmentService.track('ERROR', {projectId: this.projectId, error: 'VIEWERSETUP: ' + error});
      }
    };
    if (!Autodesk.Viewing.Private.env) {
      Autodesk.Viewing.Initializer(options, loadViewer);
    } else {
      // We need to give an initialised viewing application a tick to allow the DOM element to be established before we re-draw
      setTimeout(loadViewer);
    }
  }

  setupForgeViewerSetttings() {
    // Viewer optimization settings
    this.forgeViewer.setOptimizeNavigation(true);
    this.forgeViewer.setProgressiveRendering(true);
    this.forgeViewer.setGroundReflection(false);
    this.forgeViewer.setGhosting(false);
    const user = MenuEmitterService.getAuthenticatedUser();
    this.forgeViewer.navigation.setReverseZoomDirection(user ? (user.viewerZoomScrollDirection !== null ? user.viewerZoomScrollDirection : true) : true);
    this.forgeViewer.getHotkeyManager().popHotkeys('Autodesk.Escape');
  }

  setupModels(modelArray: any[]): void {

    // add urn: prefix to models object
    try {
      for (let i = 0; i <= modelArray.length - 1; i++) {
        this.modelArray[i].urn = 'urn:' + this.modelArray[i].urn;
      }
      // Models are loaded in sequence via a promise, so they are added to the viewer in order
      Autodesk.Viewing.Document.load(
        modelArray[0].urn,
        (doc) => {
          this.loadModels(doc).then(
            () => {
              try {
                for (let i = 0; i <= this.modelArray.length - 1; i++) { // If more than one model, run through promise
                  Autodesk.Viewing.Document.load(
                    modelArray[i].urn,
                    (loadDoc) => {
                      this.loadModels(loadDoc);
                    },
                    err => {
                      this.handleViewerError(err);
                      this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_SETUP: ' + err});
                      this.notificationService.error('APP_VIEWER_MODEL_SETUP_ERROR', {});
                    });
                }
              } catch (error) {
                this.handleViewerError(error);
                this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_SETUP: ' + error});
                this.notificationService.error('APP_VIEWER_MODEL_SETUP_ERROR', {});
              }
            }
          );
        },
        (code, message, args) => {
          console.log(code, message, args);
          this.handleViewerError(message);
          this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_SETUP: ' + message});
          this.notificationService.error('APP_VIEWER_MODEL_SETUP_ERROR', {});
        }
      );
    } catch (error) {
      this.handleViewerError(error);
      this.segmentService.track('ERROR', {projectId: this.projectId, error: 'VIEWER_SETUP: ' + error});
      this.notificationService.error('APP_VIEWER_SETUP_ERROR', {});
    }
  }

  loadModels(doc) {
    return new Promise ((resolve, reject) => {
      try {
        const viewables = Autodesk.Viewing.Document.getSubItemsWithProperties(
          doc.getRootItem(), { 'type': 'geometry', 'role': '3d'},
          true
        );

        if (viewables.length === 0) {
          return;
        }

        // Choose the first avialble viewables
        const initialViewable = viewables[0];
        const svfUrl = doc.getViewablePath(initialViewable);

        // Setup model load options
        let scale = this.getUnitScale(this.modelArray.find(model => model.urn === doc.myPath));
        this.firstScale = this.firstScale || scale;
        scale /= this.firstScale;
        const mat = new THREE.Matrix4().scale({x: scale, y: scale, z: scale});
        const loadOptions = {
            placementTransform: mat,
            globalOffset: this.firstGlobalOffset || null,
            sharedPropertyDbPath: doc.getPropertyDbPath()
        };

        // Add load listener for view geometry
        this.forgeViewer.addEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          (event) => this.geometryLoaded(event)
        );

        this.forgeViewer.addEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          async (event) => {
            // tslint:disable-next-line:no-shadowed-variable
            await new Promise((resolve) => {
              // Selection Window extension
              this.forgeViewer.loadExtension('SelectionWindow').then(extension => {
                this.selectionWindowTool = extension;
                extension.init(event.model, TranslationService);
                resolve();
              });
              this.forgeViewer.loadExtension('GritCutTool').then(extension => {
                extension.init(this.forgeViewerService.loadedRoute, event.model, (dbId, modelId) => {
                  const obj = this.forgeViewerService.getGritObject(dbId, modelId);
                  return (obj && obj.planes || []).map(plane => {
                    const d = plane[3] / this.firstScale - this.firstGlobalOffset.x * -plane[0] - this.firstGlobalOffset.y * -plane[2] - this.firstGlobalOffset.z * plane[1];
                    return [-plane[0], -plane[2], plane[1], d];
                  });
                // tslint:disable-next-line:no-shadowed-variable
                }, (event) => {
                  this.curClientX = event.clientX;
                  this.curClientY = event.clientY;
                  const hit = this.getHit();
                  return hit && hit.object || hit;
                }, TranslationService);
              });
            });
            await this.objectTreeCreated(event);
          }
        );

        if (doc.myPath === this.modelArray[0].urn) {

          // load the model
          this.forgeViewer.start(
            svfUrl,
            loadOptions,
            (model) => {
              try {
                // Get model offset from first model loaded
                this.firstGlobalOffset = this.firstGlobalOffset
                  ? this.firstGlobalOffset
                  : this.setUpModelOffset(model);

                // Context menu extension
                this.forgeViewer.loadExtension('GritContextMenu').then(extension => {
                  extension.init(this.forgeViewerService.loadedRoute, TranslationService);
                });

                // match model via urn
                const findResult = this.modelArray.find(m => m.urn === doc.myPath);
                const fModelId = model.getModelId();
                findResult.forgeModelId = fModelId;
                this.forgeViewerService.projectModelIdMap[findResult.id] = fModelId;
                this.forgeViewerService.projectModelForgeModelMap[findResult.id] = model;
                this.forgeViewerService.forgeModelIdMap[fModelId] = findResult.id;

                // Add click event listener
                this.forgeViewer.addEventListener(
                  Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
                  (event) => this.selectionChanged(event)
                );

                this.forgeViewer.addEventListener(
                  Autodesk.Viewing.ESCAPE_EVENT,
                  (event) => this.keyDown(event)
                );

                this.forgeModels.push(model);
                this.forgeViewerService.forgeModels.push(model);

                return resolve();
              } catch (error) {
                this.handleViewerError(error);
                this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_LOAD: ' + error});
                this.notificationService.error('APP_VIEWER_MODEL_LOAD_ERROR', {});
              }
            },
            err => {
              this.handleViewerError(err);
              this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODELLOAD: ' + err});
              this.notificationService.error('APP_VIEWER_MODEL_LOAD_ERROR', {});

              return reject();
            }
          );
        } else {
          this.forgeViewer.loadModel(
            svfUrl,
            loadOptions,
            (model) => {
              try {
                // match model via urn
                const findResult = this.modelArray.find(m => m.urn === doc.myPath);
                const fModelId = model.getModelId();
                findResult.forgeModelId = fModelId;
                this.forgeViewerService.projectModelIdMap[findResult.id] = fModelId;
                this.forgeViewerService.projectModelForgeModelMap[findResult.id] = model;
                this.forgeViewerService.forgeModelIdMap[fModelId] = findResult.id;

                this.forgeModels.push(model);
                this.forgeViewerService.forgeModels.push(model);

                return resolve();
              } catch (error) {
                this.handleViewerError(error);
                this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_LOAD: ' + error});
                this.notificationService.error('APP_VIEWER_MODEL_LOAD_ERROR', {});
              }
            },
            err => {
              this.handleViewerError(err);
              this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_LOAD: ' + err});
              this.notificationService.error('APP_VIEWER_MODEL_LOAD_ERROR', {});

              return reject();
            }
          );
        }
      } catch (error) {
        this.handleViewerError(error);
        this.segmentService.track('ERROR', {projectId: this.projectId, error: 'MODEL_LOAD: ' + error});
        this.notificationService.error('APP_VIEWER_MODEL_LOAD_ERROR', {});
      }
    });
  }

  private getUnitScale(model) {
    if (model.unitScale) {
      return model.unitScale;
    }
    switch (model.units) {
      case 'meter':
      case 'meters':
      case 'm':
          return 1.0;
      case 'feet and inches':
      case 'foot':
      case 'feet':
      case 'ft':
          return 0.3048;
      case 'inch':
      case 'inches':
      case 'in':
          return 0.0254;
      case 'centimeter':
      case 'centimeters':
      case 'cm':
          return 0.01;
      case 'millimeter':
      case 'millimeters':
      case 'mm':
          return 0.001;
      default:
          return 1.0;
    }
  }
  setUpModelOffset(model: any): any {
    return {
      x: model.getData().globalOffset.x,
      y: model.getData().globalOffset.y,
      z: model.getData().globalOffset.z
    };
  }

  setUpToolbar() {
    this.forgeViewer.toolbar.getControl('navTools').removeControl('toolbar-vrTool');
    this.forgeViewer.toolbar.getControl('navTools').removeControl('toolbar-cameraSubmenuTool');
    this.forgeViewer.toolbar.getControl('navTools').removeControl('toolbar-orbitTools');
    this.forgeViewer.toolbar.getControl('navTools').removeControl('toolbar-zoomTool');
    const pt = this.forgeViewer.toolbar.getControl('navTools').getControl('toolbar-panTool');
    if (pt) pt.setToolTip(TranslationService.translate('pan'));
    const fp = this.forgeViewer.toolbar.getControl('navTools').getControl('toolbar-firstPersonTool');
    if (fp) fp.setToolTip(TranslationService.translate('first_person'));
    this.forgeViewer.toolbar.getControl('settingsTools').removeControl('toolbar-modelStructureTool');
    this.forgeViewer.toolbar.getControl('settingsTools').removeControl('toolbar-propertiesTool');
    this.forgeViewer.toolbar.getControl('settingsTools').removeControl('toolbar-settingsTool');
    this.forgeViewer.toolbar.getControl('modelTools').removeControl('toolbar-explodeTool');
    const sectionTool = this.forgeViewer.toolbar.getControl('modelTools').getControl('toolbar-sectionTool');
    if (sectionTool) {
      sectionTool.setToolTip(TranslationService.translate('section_analysis'));
      sectionTool.subMenu.getControl('toolbar-sectionTool-x').setToolTip(TranslationService.translate('add_x_plane'));
      sectionTool.subMenu.getControl('toolbar-sectionTool-y').setToolTip(TranslationService.translate('add_y_plane'));
      sectionTool.subMenu.getControl('toolbar-sectionTool-z').setToolTip(TranslationService.translate('add_z_plane'));
      sectionTool.subMenu.getControl('toolbar-sectionTool-box').setToolTip(TranslationService.translate('add_box'));
    }
    const mea = this.forgeViewer.toolbar.getControl('modelTools').getControl('toolbar-measurementSubmenuTool');
    if (mea) mea.setToolTip(TranslationService.translate('measure'));
    this.forgeViewer.toolbar.getControl('settingsTools').getControl('toolbar-fullscreenTool').setToolTip(TranslationService.translate('detach_attach_viewer'));
    this.forgeViewer.toolbar.getControl('settingsTools').getControl('toolbar-fullscreenTool').onClick = (() => {
      this.detachViewerOutput.emit();
    });
    this.addToolbarControls();
  }

  addToolbarControls(): void {
    const toggleGhostingButton = new Autodesk.Viewing.UI.Button('toolbar-toggleGhostingTool');
    toggleGhostingButton.onClick = (() => { this.toggleGhosting(); });
    toggleGhostingButton.setToolTip(TranslationService.translate('toggle_ghosting'));
    toggleGhostingButton.icon.className = 'adsk-button-icon fa fa-low-vision fa-lg';
    this.forgeViewer.toolbar.getControl('settingsTools').addControl(toggleGhostingButton);

    const homeButton = new Autodesk.Viewing.UI.ComboButton('toolbar-homeTool');
    homeButton.onClick = (() => { this.gotoHomeView(); });
    homeButton.setToolTip(TranslationService.translate('home_view'));
    homeButton.icon.className = 'adsk-button-icon fa fa-home fa-lg';

    const homeSubButton = new Autodesk.Viewing.UI.Button('toolbar-homeToolSub');
    homeSubButton.onClick = (() => {
      this.setHomeView();
      homeButton.toggleFlyoutVisible();
    });
    homeSubButton.setToolTip(TranslationService.translate('save_home_view'));
    homeSubButton.icon.className = 'adsk-button-icon fas fa-save fa-lg';
    homeButton.subMenu.addControl(homeSubButton);

    this.forgeViewer.toolbar.getControl('navTools').addControl(homeButton);
  }

  gotoHomeView() {
    if (!this.forgeViewerService.homeView) {
      this.saveViewOutput.emit();
      return;
    }
    this.restoreForgeView(this.forgeViewerService.homeView.forgeView);
  }

  setHomeView() {
    this.saveViewOutput.emit();
  }

  async addObject(obj, hidden?) {
    try {
      if (obj.fragmentData || obj.fragmentBuffer) {
        const buf = obj.fragmentBuffer || Buffer.from(obj.fragmentData[0], 'base64');
        const data = DecodeMesh(await Utils.gunzip(buf));
        const geometry = new THREE.BufferGeometry();
        const vertices = [];
        for (let i = 0; i < data.triangles.length; i += 3) {
          const t1 = data.triangles[i];
          const t2 = data.triangles[i + 1];
          const t3 = data.triangles[i + 2];
          const v1 = t1 * 3 >= data.vertices.length ? data.copies[t1 - data.vertices.length / 3] * 3 : t1 * 3;
          const v2 = t2 * 3 >= data.vertices.length ? data.copies[t2 - data.vertices.length / 3] * 3 : t2 * 3;
          const v3 = t3 * 3 >= data.vertices.length ? data.copies[t3 - data.vertices.length / 3] * 3 : t3 * 3;
          vertices.push(-data.vertices[v1] / this.firstScale - this.firstGlobalOffset.x);
          vertices.push(-data.vertices[v1 + 2] / this.firstScale - this.firstGlobalOffset.y);
          vertices.push(data.vertices[v1 + 1] / this.firstScale - this.firstGlobalOffset.z);
          vertices.push(-data.vertices[v3] / this.firstScale - this.firstGlobalOffset.x);
          vertices.push(-data.vertices[v3 + 2] / this.firstScale - this.firstGlobalOffset.y);
          vertices.push(data.vertices[v3 + 1] / this.firstScale - this.firstGlobalOffset.z);
          vertices.push(-data.vertices[v2] / this.firstScale - this.firstGlobalOffset.x);
          vertices.push(-data.vertices[v2 + 2] / this.firstScale - this.firstGlobalOffset.y);
          vertices.push(data.vertices[v2 + 1] / this.firstScale - this.firstGlobalOffset.z);
        }
        geometry.addAttribute( 'position', new THREE.BufferAttribute( new Float32Array(vertices), 3 ) );
        geometry.computeVertexNormals();
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();
        const colorHex = Math.floor(data.material[0] * 255) * 0x10000 + Math.floor(data.material[1] * 255) * 0x100 + Math.floor(data.material[2] * 255);
        let material = this.materials[colorHex];
        if (!material) {
          material = new THREE.MeshPhongMaterial( {color: colorHex, opacity: data.material[3]} );
          this.forgeViewer.impl.matman().addMaterial('CustomMaterial-' + colorHex, material, true);
          this.materials[colorHex] = material;
        }
        const mesh = new THREE.Mesh( geometry, material );
        mesh.dbId = obj.forgeObjectId;
        mesh.model = this.forgeViewerService.projectModelForgeModelMap[obj.projectModelId];
        mesh.originalMaterial = material;
        mesh.fragId = mesh.model.id * 1e9 + obj.forgeObjectId + obj.dbId;
        mesh.currentMaterial = material;
        this.objectMeshes[mesh.model.id + ':' + obj.forgeObjectId] = mesh;
        this.objectIds[mesh.model.id + ':' + obj.dbId] = this.objectIds[mesh.model.id + ':' + obj.dbId] || [];
        this.objectIds[mesh.model.id + ':' + obj.dbId].push(mesh.model.id + ':' + obj.forgeObjectId);
        this.objectData[mesh.model.id + ':' + obj.forgeObjectId] = obj;

        const box = [
          -obj.boundingBox[3] / this.firstScale - this.firstGlobalOffset.x,
          -obj.boundingBox[5] / this.firstScale - this.firstGlobalOffset.y,
          obj.boundingBox[1] / this.firstScale - this.firstGlobalOffset.z,
          -obj.boundingBox[0] / this.firstScale - this.firstGlobalOffset.x,
          -obj.boundingBox[2] / this.firstScale - this.firstGlobalOffset.y,
          obj.boundingBox[4] / this.firstScale - this.firstGlobalOffset.z,
        ];
        mesh.box = new THREE.Box3(new THREE.Vector3(box[0], box[1], box[2]), new THREE.Vector3(box[3], box[4], box[5]));
        this.boxTree.add(mesh.box, mesh);
        mesh.isCustomVisible = !hidden;
        if (!hidden) this.addMesh(mesh);

      }
      this.forgeViewer.impl.invalidate(true);
    } catch (e) {
      console.error('FAILED TO ADD OBJECT', e);
    }
  }

  private addMesh(mesh) {
    mesh.isCustomVisible = true;
    this.selectionWindowTool.addMesh(mesh);
    this.forgeViewer.impl.scene.add( mesh );
  }

  private removeMesh(mesh) {
    mesh.isCustomVisible = false;
    this.selectionWindowTool.removeMesh(mesh);
    this.forgeViewer.impl.scene.remove( mesh );
  }

  performCut(details) {
    const obj = this.forgeViewerService.getGritObject(details.objectId, details.modelId);
    const planes = details.planes.map(plane => {
      const d = (plane[3] + this.firstGlobalOffset.x * plane[0] + this.firstGlobalOffset.y * plane[1] + this.firstGlobalOffset.z * plane[2]) * this.firstScale;
      return [-plane[0], plane[2], -plane[1], d];
    });
    const selectedModel = this.forgeModels.find(m => m.id === details.modelId);
    this.hideObjectsInModel([details.originalObjectId], selectedModel);
    (this.objectIds[details.modelId + ':' + details.originalObjectId] || []).forEach(forgeId => {
      this.removeMesh(this.objectMeshes[forgeId]);
      delete this.objectMeshes[forgeId];
      delete this.objectData[forgeId];
    });
    delete this.objectIds[details.modelId + ':' + details.originalObjectId];
    this.forgeService.split(obj.id, planes).subscribe(res => {
      if (!planes.length) {
        this.showObjectsInModel([details.originalObjectId], selectedModel);
      } else {
        res.added.forEach((o) => {
          this.addObject(o);
        });
      }
      this.forgeViewer.impl.invalidate(true);
      this.forgeViewerService.updateObjects(res.removed, res.hidden, res.added);
      this.viewerCutOutput.emit(event['detail']);
    });
  }

  toggleGhosting() {
    this.forgeViewer.setGhosting(!this.forgeViewer.impl.showGhosting);
  }

  keyDown(event: any): void {
    if (this.fireSelectionChange) {
      // Escape key
      if (event.keyCode === 27) {
        this.forgeViewerOutput.emit('esc');
      }
    }
  }

  async selectionChanged(event: any) {
    if (this.fireSelectionChange) {
      // Await for screen coords to be set
      await Utils.sleep(0, this.ngZone);

      let forgeSelectedObjects = [];
      event.selections.forEach(selectedObject => {
        const forgeObjectInfo = {
          modelId: selectedObject.model.id,
          objectIds: selectedObject.dbIdArray
        };
        forgeSelectedObjects.push(forgeObjectInfo);
      });
      const result = this.getHit();
      if (result) {
        this.forgeViewer.navigation.setPivotPoint(result.intersectPoint);
        if (result.object && result.object.isCustomVisible) {
          forgeSelectedObjects = [{modelId: result.object.model.id, objectIds: [result.object.dbId]}];
        }
      }
      this.forgeViewerOutput.emit(forgeSelectedObjects);
    }
  }

  private getHit() {
    if (this.forgeViewer) {
      const viewport = this.forgeViewer.container.getBoundingClientRect();
      const canvasX = this.curClientX - viewport.left;
      const canvasY = this.curClientY - viewport.top;
      const result = this.forgeViewer.impl.hitTest( canvasX, canvasY, false );
  
      try {
        let end = new THREE.Vector3(
          ((canvasX + 0.5) / viewport.width) * 2 - 1,
          -((canvasY + 0.5) / viewport.height) * 2 + 1, 1 );
        let start = end.clone();
        start.z = -1.0;
  
        const camera = this.forgeViewer.getCamera();
        start = start.unproject(camera);
        end = end.unproject( camera );
  
        end.sub( start ).normalize();
  
        const ray = new THREE.Raycaster();
        ray.set(!camera.isPerspective ? start : camera.position, end);
  
        const hits = this.boxTree.raycast(ray).filter(h => h.object.isCustomVisible);
  
        if (hits.length > 0 && (!result || hits[0].point.distanceTo(camera.position) < result.intersectPoint.distanceTo(camera.position))) {
          return hits[0];
        }
      } catch (e) {
        // Not inited
      }
  
      return result;
    }
  }

  logCoords(event) {
    this.curClientX = event.clientX;
    this.curClientY = event.clientY;

    const hit = this.getHit();
    if (this.lastHit && (!hit || hit.object !== this.lastHit)) {
      this.setThemingColor(this.lastHit.dbId, null, this.lastHit.model);
    }
    if (hit && hit.object && hit.object.isCustomVisible && (!this.lastHit || this.lastHit !== hit.object)) {
      this.lastHit = hit.object;
      const color = this.lastHit.currentMaterial.color;
      const newColor = color.r >= 0.9 && color.g >= 0.9 && color.b >= 0.9 ?
              new THREE.Vector4((color.r + 1) / 3, (color.g + 1) / 3, (color.b + 1) / 3, 1)
            : new THREE.Vector4((color.r + 2) / 3, (color.g + 2) / 3, (color.b + 2) / 3, 1);
      this.setThemingColor(this.lastHit.dbId, color, this.lastHit.model, true);
    } else {
      this.lastHit = hit && hit.object && hit.object.isCustomVisible ? hit.object : null;
    }
  }

  showSimilar(): void {
    if (!Utils.isEmpty(event['detail'])) {
      const forgeSelection = [{modelId: event['detail'].model.id, objectIds: [event['detail'].dbId]}];
      this.showSimilarOutput.emit(forgeSelection);
    }
  }

  clearFilters(): void {
    this.clearFiltersOutput.emit();
  }

  gritContextMenuEvent(): void {
    this.contextMenuOutput.emit(event['detail']);
  }

  getForgeView() {
    const returnState = this.forgeViewer.getState();
    returnState['objectSet'] = [];
    return JSON.stringify(returnState);
  }

  restoreForgeView(view): void {
    this.fireSelectionChange = false;
    this.forgeViewer.viewerState.restoreState(JSON.parse(view));
    this.fireSelectionChange = true;
  }

  clearCutPlanes() {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('CCP - FORGE VIEWER DOES NOT EXIST'); return; }
    const returnState = this.forgeViewer.getState();
    returnState['objectSet'] = [];
    returnState['cutplanes'] = [];
    this.forgeViewer.viewerState.restoreState(returnState);
  }

  setLargestBoundingBoxModel(): void {
    let largestVol = 0;
    this.forgeModels.forEach(model => {
      const bb = model.getBoundingBox();
      if (bb) {
        const curVol = this.computeVolume(bb.max.x, bb.max.y, bb.max.z, bb.min.x, bb.min.y, bb.min.z);
        if (curVol > largestVol) {
          largestVol = curVol;
          this.largestModelBB = model;
          this.forgeViewerService.largestProjectModelId = this.forgeViewerService.forgeModelIdMap[model.id];
        }
      }
    });
  }

  computeVolume(maxX: number, maxY: number, maxZ: number, minX: number, minY: number, minZ: number) {
    let volume = 0;
    const xDist = maxX - minX;
    const yDist = maxY - minY;
    const zDist = maxZ - minZ;

    volume = xDist * yDist * zDist;

    return volume;
  }

  // END Forge Viewer Setup

  // Sets custom loading state for all models
  async setProgressState() {
    this.loadPercentage++;
    this.percentDisplay = (this.loadPercentage / Math.pow(this.totalModels, 2)) * 100;
    this.forgeViewerService.setViewerLoadingState(this.percentDisplay);
    if (this.percentDisplay >= 100) {
      this.loadingComplete = true;
    }
  }

  // Forge Viewer Event Listeners
  geometryLoaded(event: any) {
    const viewer = event.target;
    viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.geometryLoaded);
  }

  async objectTreeCreated(event: any) {
    if (!this.customLoaded) {
      this.customLoaded = true;
      const custom = await this.forgeViewerService.getCustomObjects(this.projectId);
      Object.keys(custom).forEach(id => {
        this.addObject(custom[id]);
      });
      const customHidden = await this.forgeViewerService.getHiddenCustomObjects(this.projectId);
      Object.keys(customHidden).forEach(id => {
        this.addObject(customHidden[id], true);
      });
    }

    this.setProgressState();

    if (this.percentDisplay >= 100 && !this.ranSetup) { // wait until all models are loaded to emit value
      this.ranSetup = true;
      this.setLargestBoundingBoxModel();
      this.viewerLoadCompleteOutput.emit();
      this.viewerTries = 0;
      this.viewerIsDoneLoading = true;
      this.resize();
      this.setupForgeViewerSetttings();
      this.setUpToolbar();
    }
  }
  // END Forge Viewer Event Listeners

  // Resets
  resize() {
    if (Utils.isEmpty(this.forgeViewer)) return;
    this.forgeViewer.resize();
  }
  // End Resets

  // Start NEW//////////////////////////////////////////

  hideObjectsInModel(objectIds: number[], model: any): void {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('HIDE - FORGE VIEWER DOES NOT EXIST'); return; }
    this.fireSelectionChange = false;
    const realObjectIds = [];
    objectIds.forEach(id => {
      if (this.objectMeshes[model.id + ':' + id]) {
        this.removeMesh(this.objectMeshes[model.id + ':' + id]);
      } else {
        realObjectIds.push(id);
      }
    });
    this.forgeViewer.impl.invalidate(true);
    if (realObjectIds.length > 0 || objectIds.length === 0) {
      this.forgeViewer.impl.visibilityManager.hide(realObjectIds, model);
    }
    this.fireSelectionChange = true;
  }

  showObjectsInModel(objectIds: number[], model: any): void {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('SHOW - FORGE VIEWER DOES NOT EXIST'); return; }
    this.fireSelectionChange = false;
    const realObjectIds = [];
    objectIds.forEach(id => {
      if (this.objectMeshes[model.id + ':' + id]) {
        this.addMesh(this.objectMeshes[model.id + ':' + id]);
      } else {
        realObjectIds.push(id);
      }
    });
    this.forgeViewer.impl.invalidate(true);
    if (realObjectIds.length > 0 || objectIds.length === 0) {
      this.forgeViewer.impl.visibilityManager.show(realObjectIds, model);
    }
    this.fireSelectionChange = true;
  }

  clearThemingColors(model: any): void {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('CTC - FORGE VIEWER DOES NOT EXIST'); return; }
    Object.keys(this.objectMeshes).forEach(id => {
      this.objectMeshes[id].material = this.objectMeshes[id].originalMaterial;
      this.objectMeshes[id].currentMaterial = this.objectMeshes[id].material;
    });
    this.forgeViewer.impl.invalidate(true);
    this.forgeViewer.clearThemingColors(model);
  }

  setThemingColor(objectId: number, color: any, model: any, temporary?, reflect?): void {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('STC - FORGE VIEWER DOES NOT EXIST'); return; }
    this.fireSelectionChange = false;
    const vectorColor = color == null ? null : new THREE.Vector4(color.x, color.y, color.z, color.w);
    if (this.objectMeshes[model.id + ':' + objectId]) {
      const mesh = this.objectMeshes[model.id + ':' + objectId];
      if (vectorColor == null) {
        mesh.material = mesh.currentMaterial;
      } else if (vectorColor.w === 0) {
        mesh.material = mesh.originalMaterial;
        if (!temporary) mesh.currentMaterial = mesh.material;
      } else {
        const colorHex = Math.floor(vectorColor.x * 255) * 0x10000 + Math.floor(vectorColor.y * 255) * 0x100 + Math.floor(vectorColor.z * 255);
        let material = this.materials[colorHex];
        if (!material) {
          material = new THREE.MeshPhongMaterial( {color: colorHex, combine: reflect ? THREE.MixOperation : THREE.Multiply} );
          this.forgeViewer.impl.matman().addMaterial('CustomMaterial-' + colorHex, material, true);
          this.materials[colorHex] = material;
        }
        mesh.material = material;
        if (!temporary) mesh.currentMaterial = mesh.material;
      }
      this.forgeViewer.impl.invalidate(true);
    } else {
      this.forgeViewer.setThemingColor(objectId, vectorColor, model);
    }
    this.fireSelectionChange = true;
  }

  fitLargestToView(): void {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('FITL - FORGE VIEWER DOES NOT EXIST'); return; }
    this.forgeViewer.fitToView([], this.largestModelBB);
  }

  fitObjectsToView(objectIds: number[], model: any): void {
    if (Utils.isEmpty(this.forgeViewer)) { console.error('FITO - FORGE VIEWER DOES NOT EXIST'); return; }
    if (!objectIds || objectIds.length === 0) {
      this.forgeViewer.fitToView(objectIds, model);
    } else {
      const realObjectIds = [];
      objectIds.forEach(id => {
        if (this.objectData[model.id + ':' + id]) {
          realObjectIds.push(this.objectData[model.id + ':' + id].dbId);
        } else {
          realObjectIds.push(id);
        }
      });
      this.forgeViewer.fitToView(realObjectIds, model);
    }
  }

  fitBoxToView(box: number[]) {
    box = [
      -box[3] / this.firstScale - this.firstGlobalOffset.x,
      -box[5] / this.firstScale - this.firstGlobalOffset.y,
      box[1] / this.firstScale - this.firstGlobalOffset.z,
      -box[0] / this.firstScale - this.firstGlobalOffset.x,
      -box[2] / this.firstScale - this.firstGlobalOffset.y,
      box[4] / this.firstScale - this.firstGlobalOffset.z,
    ];
    this.forgeViewer.navigation.fitBounds(false, new THREE.Box3(new THREE.Vector3(box[0], box[1], box[2]), new THREE.Vector3(box[3], box[4], box[5])));
  }

  notifyRoute(route: string): void {
    // dispatch event for extension
    const contextMenuEvent = new CustomEvent('ROUTE_CHANGE', {detail: route});
    window.dispatchEvent(contextMenuEvent);
  }

  // Selection Window
  enableSelectionWindow(enable: boolean) {
    const selectionWindowEvent = new CustomEvent('SELECTION_WINDOW_ENABLE', {detail: enable});
    window.dispatchEvent(selectionWindowEvent);
  }

  // END NEW//////////////////////////////////////////

}
