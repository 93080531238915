import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IProjectEquipment } from '../../../models/project/project-equipment/project-equipment.interface';
import { IProjectMaterial } from '../../../models/project/project-material/project-material.interface';

import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectService } from '../../../services/project/project.service';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-step-equipment-and-materials',
  templateUrl: './step-equipment-and-materials.component.html',
  styleUrls: ['./step-equipment-and-materials.component.scss']
})
export class StepEquipmentAndMaterialsComponent implements OnInit {

  @Input() materialIds: string[] = [];
  @Input() activityMaterialIds: string[] = [];
  @Input() equipmentIds: string[] = [];
  @Input() activityEquipmentIds: string[] = [];

  @Output() updateOutput: EventEmitter<{materialIds: string[], equipmentIds: string[]}> = new EventEmitter();

  allMaterials: IProjectMaterial[] = [];
  filteredMats: Observable<IProjectMaterial[]>;
  materialOptions: IProjectMaterial[];
  materialButtons: Array<{id: string, name: string, selected: boolean}> = [];
  materialCtrl = new FormControl();

  allEquipment: IProjectEquipment[] = [];
  filteredEquipment: Observable<IProjectEquipment[]>;
  equipmentOptions: IProjectEquipment[];
  equipmentButtons: Array<{id: string, name: string, selected: boolean}> = [];
  equipmentCtrl = new FormControl();

  get displayFn() { return Utils.defaultDisplayFn; }

  constructor(
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService
  ) {/*EMPTY*/}

  ngOnInit() {
    this.allMaterials = ProjectService.userPermission.subContractorId ? this.materialService.getLocalUserProjectMaterials() : this.materialService.getLocalAllProjectMaterials();
    this.allEquipment = ProjectService.userPermission.subContractorId ? this.equipmentService.getLocalUserProjectEquipment() : this.equipmentService.getLocalAllProjectEquipment();
    this.setMaterialButtons();
    this.setEquipmentButtons();
    this.setValidOptions();
    this.setAutocomplete();
  }

  setMaterialButtons() {
    this.materialIds.concat(this.activityMaterialIds).forEach(materialId => {
      if (!this.materialButtons.find(materialButton => materialButton.id === materialId)) {
        const material = this.allMaterials.find(mat => mat.id === materialId);
        if (material) this.addMaterialOrEquipmentButton(material, false, this.materialIds.includes(material.id));
      }
    });
    Utils.sortByString(this.materialButtons, 'name', true);
  }

  setEquipmentButtons() {
    this.equipmentIds.concat(this.activityEquipmentIds).forEach(equipmentId => {
      if (!this.equipmentButtons.find(equipmentbutton => equipmentbutton.id === equipmentId)) {
        const equipment = this.allEquipment.find(equip => equip.id === equipmentId);
        if (equipment) this.addMaterialOrEquipmentButton(equipment, true, this.equipmentIds.includes(equipment.id));
      }
    });
    Utils.sortByString(this.equipmentButtons, 'name', true);
  }

  addMaterialOrEquipmentButton(materialOrEquipment: IProjectMaterial | IProjectEquipment, equipment: boolean, selected: boolean) {
    const buttonToAdd = {
      id: materialOrEquipment.id,
      name: materialOrEquipment.name,
      selected: selected
    };
    if (equipment) {
      this.equipmentButtons.push(buttonToAdd);
    } else {
      this.materialButtons.push(buttonToAdd);
    }
  }

  matSelected(matMaterial) {
    const material: IProjectMaterial = matMaterial.option.value;
    this.materialIds.push(material.id);
    this.addMaterialOrEquipmentButton(material, false, true);
    this.updateOutput.emit({materialIds: this.materialIds, equipmentIds: this.equipmentIds});
    this.clearInput();
  }

  equipSelected(matEquipment) {
    const equipment: IProjectEquipment = matEquipment.option.value;
    this.equipmentIds.push(equipment.id);
    this.addMaterialOrEquipmentButton(equipment, true, true);
    this.updateOutput.emit({materialIds: this.materialIds, equipmentIds: this.equipmentIds});
    this.clearInput();
  }

  setValidOptions(): void {
    this.materialOptions = this.allMaterials.filter(item => !this.materialButtons.find(material => material.id === item.id));
    this.equipmentOptions = this.allEquipment.filter(item => !this.equipmentButtons.find(equipment => equipment.id === item.id));
  }

  setAutocomplete(): void {
    this.filteredMats = this.materialCtrl.valueChanges.pipe(
      startWith<string | IProjectMaterial >(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.materialSearch(name) : this.getAllMaterialOptions()));

    this.filteredEquipment = this.equipmentCtrl.valueChanges.pipe(
      startWith<string | IProjectEquipment >(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.equipmentSearch(name) : this.getAllEquipmentOptions()));
  }

  materialSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.materialOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllMaterialOptions() {
    return this.materialOptions.slice(0, Math.min(this.materialOptions.length, 50));
  }

  equipmentSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.equipmentOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllEquipmentOptions() {
    return this.equipmentOptions.slice(0, Math.min(this.equipmentOptions.length, 50));
  }

  clearInput(): void {
    this.materialCtrl.setValue('');
    this.materialCtrl.updateValueAndValidity();
    this.equipmentCtrl.setValue('');
    this.equipmentCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }

  equipmentButtonClick(equipmentButton: {id: string, name: string, selected: boolean}) {
    equipmentButton.selected = !equipmentButton.selected;
    if (equipmentButton.selected && !this.equipmentIds.includes(equipmentButton.id)) {
      this.equipmentIds.push(equipmentButton.id);
    } else if (!equipmentButton.selected && this.equipmentIds.includes(equipmentButton.id)) {
      this.equipmentIds = this.equipmentIds.filter(id => id !== equipmentButton.id);
    }
    this.updateOutput.emit({materialIds: this.materialIds, equipmentIds: this.equipmentIds});
  }

  materialButtonClick(materialButton: {id: string, name: string, selected: boolean}) {
    materialButton.selected = !materialButton.selected;
    if (materialButton.selected && !this.materialIds.includes(materialButton.id)) {
      this.materialIds.push(materialButton.id);
    } else if (!materialButton.selected && this.materialIds.includes(materialButton.id)) {
      this.materialIds = this.materialIds.filter(id => id !== materialButton.id);
    }
    this.updateOutput.emit({materialIds: this.materialIds, equipmentIds: this.equipmentIds});
  }

}
