
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable , ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectEquipmentService } from '../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { ProjectPageCriteriaService } from '../../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectSprintService } from '../../services/project/project-sprint/project-sprint.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { WeatherService } from '../../services/weather/weather.service';

import { IAccount } from '../../models/account/account.interface';
import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { MessageType } from '../../utils/enums/message-type.enum';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectSchedule, IProjectScheduleStep } from '../../models/project/project-schedule/project-schedule.interface';
import { IProjectSprintStory } from '../../models/project/project-sprint/project-sprint-story/project-sprint-story.interface';
import { IProjectSprint } from '../../models/project/project-sprint/project-sprint.interface';
import { IProjectSprintChartOutput } from '../../models/project/project-sprint/project-sprint.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { IProject } from '../../models/project/project.interface';
import { ISidebarTab, IStepsBySub } from '../../models/sidebar/sidebar.interface';
import { IUserPermission } from '../../models/user/user.interface';
import { IWeatherForecast } from '../../models/weather/weather.interface';

import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { PopupModalState } from '../../utils/enums/popup-modal-state.enum';
import { ViewMode } from '../../utils/enums/shared.enum';
import { SidebarState } from '../../utils/enums/sidebar-state';
import { Utils } from '../../utils/utils';

import * as moment from 'moment';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';

@Component({
  selector: 'app-project-sprints',
  templateUrl: './project-sprints.component.html',
  styleUrls: ['./project-sprints.component.scss']
})
export class ProjectSprintsComponent implements OnInit, OnDestroy {
  // display toggles
  pageIsLoading: boolean = true;
  noData: boolean = false;
  errorGettingData: boolean = false;
  hasSprintEditPermission: boolean = true;
  noSprintDataMessage: string = 'no_work_plans';
  selectedDisplayMode: string = ViewMode.SprintBoard;
  weatherData: IWeatherForecast[];

  // project info
  projectId: string;
  projectPermission: IUserPermission;
  projectPlan: any;
  currentProject: IProject;
  currentProjectAccount: IAccount;
  projectSubContractorData: IProjectSubContractor[] = [];

  // Viewer
  firstLoad: boolean = true;

  // data
  subContractorsDropdown: IDropdownItem[];
  scheduleData: IProjectSchedule;
  firstSunInSchedule: number;

  ssResults = [];

  // Sprint controls & data
  openSprintDropdown = [];
  filteredOpenSprintDropdown = [];
  sprintDropdownInput = '';
  currentSprintId: string = null;
  planningMode: boolean = false;
  sprintCloseMode: boolean = false;
  action: string = null;
  sprintName: string = null;
  sprintStartDate: string = null;
  sprintEndDate: string = null;
  placeholderSprints = 'Select Sprint';
  addSprintForm: FormGroup;
  chartOutput: IProjectSprintChartOutput;
  defaultSprintId: string = '';

  // chart
  chartInput: {
    scheduleId: string,
    scheduleStart: number,
    sprintRange: number[],
    sprintId: string
  };
  viewMode = ViewMode;

  // modal
  popupModalState = PopupModalState;
  sprintModalInput: IConfirmationModalInput;
  sprintModalForm: FormGroup;
  modal: { type: any, action: string, show: boolean } = { type: null, action: null, show: false };

  // Sidebar
  sidebarState = SidebarState;
  sidebarTabs: ISidebarTab[];
  activeSidebarState: string;
  sidebarIsLoading: boolean = false;
  noSelectionMessage: string = 'Select a task from the Work Plan board.';
  selectedObjectName: string;
  totalScheduledSteps: number;
  selectedStep: any;
  sidebarStepIds: string[];
  sidebarPlanStepIds: string[];
  selectedGritObjectIds: string[] = [];
  messageType = MessageType;
  sidebarStepData: IStepsBySub[] = [];
  cloneOfSidebarStepData: IStepsBySub[] = [];
  selectedStepIdsForSidebar: string[] = [];

  // Sprint board
  @ViewChild('sprintBoard') sprintBoard: any;
  @ViewChild('sprintChart') sprintChart: any;
  @ViewChild('sprintCommitment') sprintCommitment: any;

  loadedSprintId: string;

  // Subscriptions
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private projectScheduleService: ProjectScheduleService,
    private segmentService: SegmentService,
    private weatherService: WeatherService,
    public forgeViewerService: ForgeViewerService,
    public projectSprintService: ProjectSprintService,
    public projectService: ProjectService,
    private projectEquipmentService: ProjectEquipmentService,
    private projectMaterialService: ProjectMaterialService,
    public projectPageCriteriaService: ProjectPageCriteriaService
  ) { }

  async ngOnInit() {
    await this.projectMaterialService.setLocalAllProjectMaterials(this.projectService.currentProject.id);
    await this.projectEquipmentService.setLocalAllProjectEquipment(this.projectService.currentProject.id);
    await this.projectService.loadActivities(this.projectService.currentProject.id);
    this.projectScheduleService.resetLocalSchedule();

    this.projectId = this.projectService.currentProject.id;
    this.projectPermission = ProjectService.userPermission;
    this.currentProject = this.projectService.currentProject;
    this.currentProjectAccount = this.projectService.currentProjectAccount;
    this.hasSprintEditPermission = this.projectPermission && this.projectPermission.gc && this.projectPermission.edit;

    // listen for page data
    if (this.projectService.getProjectReady()) {
      this.firstLoad = false;
      this.setupPageData();
    }

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(async myParams => {
      this.loadedSprintId = myParams['sprintId'];
    });

    // Viewer setup
    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      if (this.forgeViewerService.poppedOut || !this.firstLoad) {
        this.resetDisplayModeState();
      } else {
        this.firstLoad = false;
        this.setupPageData();
      }
    });

    // Viewer output
    this.forgeViewerService.forgeViewerOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      /* we will need this in the future */
    });

    MenuEmitterService.slideOutStateChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.resizeChart();
    });
  }

  ngOnDestroy() {
    this.projectScheduleService.destroyOpenSubscriptions();
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async setupPageData() {
    await this.projectService.setEquipmentAndMaterials();
    this.forgeViewerService.clearAllThemingColors();
    this.forgeViewerService.clearCutPlanes();
    this.pageIsLoading = true;
    this.setupSideBar();
    this.projectScheduleService.resetLocalSchedule();

    const observableArray: Array<Observable<any>> = [];
    observableArray.push(this.projectService.getProjectSubcontractors(this.projectId));
    observableArray.push(this.projectScheduleService.getActiveSchedule(this.projectId));
    observableArray.push(this.projectSprintService.getActiveSprint(this.projectId));
    observableArray.push(this.projectSprintService.getSprintList(this.projectId));
    observableArray.push(this.projectSprintService.getSprintResultLibrary(this.projectId, true));
    observableArray.push(this.weatherService.getWeatherForecast(this.projectId));
    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (res[0].length > 0) {
          this.projectSubContractorData = res[0].concat(ProjectSubContractorService.unassignedSubcontractor);
        } else {
          this.scheduleData = null;
          this.firstSunInSchedule = null;
        }

        if (!Utils.objectIsEmpty(res[1])) {
          this.scheduleData = res[1];
          this.firstSunInSchedule = moment.utc(this.scheduleData.startDate).clone().startOf('week').valueOf();
        } else {
          this.scheduleData = null;
          this.firstSunInSchedule = null;
        }

        // check for existing sprints
        if (res[3].length > 0) {
          this.projectSprintService.setActiveSprint(null); // need to reset active sprint each time we load page
          this.projectSprintService.setSprintList(res[3]);
          this.defaultSprintId = this.projectSprintService.getDefaultSprint();
          this.setSprintDropdowns();
          this.noData = false;
        } else {
          this.noData = true;
          this.pageIsLoading = false;
          this.projectSprintService.setSprintList([]);
        }

        // Result library
        if (res[4].length > 0) {
          this.ssResults = res[4];
        } else {
          this.noData = true;
          this.errorGettingData = true;
          this.pageIsLoading = false;
        }

        if (!Utils.objectIsEmpty(res[2])) {
          this.projectSprintService.setActiveSprint(res[2]);
          this.checkSprintEndDate(res[2]);
          this.selectedDisplayMode = ViewMode.SprintBoard;
        } else {
          this.projectSprintService.setActiveSprint(null);
          this.selectedDisplayMode = ViewMode.SprintBoard;
        }

        if (this.loadedSprintId) {
          this.setCurrSprint(this.loadedSprintId);
        } else {
          this.setCurrSprint();
        }

        if (this.scheduleData && this.currentSprintId) {
          this.setupChart();
          this.setFilterType();
        }

        this.weatherData = this.weatherService.transformWeatherDataForCharts(res[5]);

        this.setPlanningAndCloseMode();

        this.errorGettingData = false;
        this.pageIsLoading = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'task information',
          action: 'get'
        };

        this.notificationService.error(err, context);
        this.noData = true;
        this.errorGettingData = true;
        this.pageIsLoading = false;
      }
    );
  }

  // check if current sprint's scheduled end date has passed
  checkSprintEndDate(sprint: IProjectSprint): void {
    const updateSprintEndDate = this.projectSprintService.checkToUpdateSprintEndDate(sprint);
    if (updateSprintEndDate && this.hasSprintEditPermission) this.showSprintModal(PopupModalState.UpdateSprint);
  }

  resizeChart(): void {
    if (this.sprintChart) this.sprintChart.resizeChart();
  }

  async setupChart() {
    const scheduleId = this.scheduleData && this.scheduleData.id ? this.scheduleData.id : null;
    if (scheduleId && this.currentSprintId && this.firstSunInSchedule) {
      const { scheduledStartDate, scheduledEndDate } = this.projectSprintService.getSprint(this.currentSprintId);
      const firstSunInSprint = scheduledStartDate ? moment.utc(scheduledStartDate).clone().startOf('week').valueOf() : 0;
      const earliestStartDate = Math.min(firstSunInSprint, this.firstSunInSchedule);

      await this.updateChartData(earliestStartDate, scheduledEndDate);
    } else {
      this.noData = true;
      this.errorGettingData = true;
    }
  }

  async updateChartData(fromDate: number, toDate: number) {
    const { startDate, scheduledStartDate, endDate, scheduledEndDate } = this.projectSprintService.getSprint(this.currentSprintId);
    const sprintRangeStart = startDate ? startDate : scheduledStartDate;
    const sprintRangeEnd = endDate ? endDate : scheduledEndDate;

    await this.projectScheduleService.updateLocalScheduleData(fromDate, toDate, this.scheduleData.id, this.projectSubContractorData).then(() => {
      this.chartInput = {
        scheduleId: this.scheduleData.id,
        scheduleStart: this.firstSunInSchedule,
        sprintRange: [sprintRangeStart, sprintRangeEnd],
        sprintId: this.currentSprintId
      };

      this.noData = false;
      this.errorGettingData = false;
    }).catch((err) => {
      const context: INoficationContext = {
        type: 'schedule',
        action: 'get'
      };

      this.notificationService.error(err, context);
      this.noData = true;
      this.errorGettingData = true;
    });
  }

  // Sidebar
  setupSideBar(): void {
    this.sidebarTabs = this.projectSprintService.getSidebarTabs();
    this.forgeViewerService.resizeViewerAfterLoad();
    this.setSidebarState(this.sidebarTabs[0]);
  }

  setupSidebarDataFromBoard(boardItemOutput: any[]): void {
    // If we are passing in nothing, we need to reset data
    this.selectedStep = boardItemOutput.length === 1 ? boardItemOutput[0] : null;
    this.sidebarStepIds = boardItemOutput.map(item => item.stepId);
    this.sidebarPlanStepIds = boardItemOutput.map(item => item.planStepId);

    // Setup selected grit objects for side bar
    const objectIds = boardItemOutput.map(item => item.objectIds);
    this.selectedGritObjectIds = [].concat.apply([], objectIds);
    const selectedStepsArray = boardItemOutput.length === 0 ? [] : boardItemOutput;
    const stepsBySub = this.projectSprintService.sortStepsBySubContractor(selectedStepsArray);

    this.sidebarStepData = this.projectSprintService.createArrayForSidebarDisplay(stepsBySub, this.projectSubContractorData);
    this.cloneOfSidebarStepData = JSON.parse(JSON.stringify(this.sidebarStepData));
    this.selectedStepIdsForSidebar = [];
    for (const step of this.sidebarStepData) {
      for (const s of step.steps) {
        if (this.selectedStepIdsForSidebar.indexOf(step['projectPlanStepId']) === -1) this.selectedStepIdsForSidebar.push(s['projectPlanStepId']);
      }
    }
  }

  handleSidebarStepHover(event) {
    // Handle Sidebar Task list step hover
  }

  setupSidebarDataFromSteps(steps: IProjectScheduleStep[]): void {
    // If we are passing in nothing, we need to reset data
    this.selectedStep = steps.length === 1 ? steps[0] : null;
    this.sidebarStepIds = steps.map(item => item.projectStepId);
    this.sidebarPlanStepIds = steps.map(item => item.projectPlanStepId);

    // Setup selected grit objects for side bar
    const objectIds = steps.map(item => item.objectIds);
    this.selectedGritObjectIds = [].concat.apply([], objectIds);
  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.activeSidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab: ISidebarTab): void {
    this.sidebarTabs.map(item => item.active = false);
    tab.active = true;
    this.activeSidebarState = tab.key;

    this.segmentService.track('Sprints Sidebar Loaded', { sideBar: tab.key });
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) this.projectService.toggleSidebarVisibility(isVisible);
    else this.projectService.toggleSidebarVisibility();

    setTimeout(() => { this.forgeViewerService.resizeViewerAfterLoad(); this.resizeChart(); }, 500);
  }
  // END Sidebar

  isSprintActive(): boolean {
    const activeSprint = this.projectSprintService.activeSprint;
    if (!Utils.isEmpty(activeSprint) && activeSprint.id === this.currentSprintId) return true;
    return false;
  }

  setCurrSprint(id?: string) {
    if (id) {
      this.currentSprintId = id;
    } else if (this.projectSprintService.activeSprint) {
      this.currentSprintId = this.projectSprintService.activeSprint.id;
    } else if (this.projectSprintService.sprintList.length > 0) {
      this.currentSprintId = this.projectSprintService.sprintList[0].id;
    } else {
      this.currentSprintId = null;
      this.noData = true;
    }
    if (this.currentSprintId) {
      this.setSprintDropdowns(); // ensures new sprints are added to the dropdown before trying to set it as selected
      this.sprintDropdownInput = this.openSprintDropdown.find(sprint => sprint.id === this.currentSprintId).name;
    }
  }

  setSprintDropdowns() {
    const list = this.projectSprintService.getUnclosedSprints();
    this.openSprintDropdown = this.projectSprintService.transformSprintList(list);
  }

  setPlanningAndCloseMode() {
    // set planning mode
    if (!Utils.isEmpty(this.projectSprintService.activeSprint) && this.currentSprintId === this.projectSprintService.activeSprint.id) {
      this.planningMode = false;
    } else {
      this.planningMode = true;
    }

    // set close mode (action button should be disabled if close mode)
    const currSprint = this.projectSprintService.getSprint(this.currentSprintId);
    if (Utils.isEmpty(currSprint)) this.sprintCloseMode = false;
    else if (currSprint['startDate'] && currSprint['endDate']) this.sprintCloseMode = true;
    else this.sprintCloseMode = false;
  }

  refreshSprintDropdown(refresh: any) {
    if (refresh) {
      this.projectSprintService.getSprintList(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
        results => {
          this.projectSprintService.setSprintList(results);
          this.defaultSprintId = this.projectSprintService.getDefaultSprint();
          this.setSprintDropdowns();
          this.setPlanningAndCloseMode();
        },
        error => {
          const context: INoficationContext = {
            type: 'sprint list',
            action: 'get'
          };

          this.notificationService.error(error, context);
        }
      );
    }
  }

  handleSprintPlanningDropdownOutput() {
    this.setCurrSprint(this.currentSprintId);
    this.setPlanningAndCloseMode();

    const { scheduledStartDate, scheduledEndDate } = this.projectSprintService.getSprint(this.currentSprintId);
    this.updateChartData(scheduledStartDate, scheduledEndDate);
  }

  handleChartSelectionOutput(steps: IProjectScheduleStep[]): void {
    this.setupSidebarDataFromSteps(steps);
  }

  getActiveSprintName() {
    const activeSprint = this.projectSprintService.activeSprint;
    if (!Utils.isEmpty(activeSprint)) return activeSprint.name;
    return 'No active sprint';
  }

  showStartModal(): void {
    const currSprint = this.projectSprintService.getSprint(this.currentSprintId);
    const updateSprintEndDate = currSprint ? this.projectSprintService.checkToUpdateSprintEndDate(currSprint) : null;

    if (updateSprintEndDate) this.showSprintModal(PopupModalState.UpdateStartSprint);
    else this.showSprintModal('start');
  }

  showSprintModal(action: any) {
    const currSprint = this.projectSprintService.getSprint(this.currentSprintId);
    const sprintName = (currSprint && currSprint.name) ? currSprint.name : '';
    this.action = action;

    // If we do not have permission, we don't need to do anything.
    if (!this.hasSprintEditPermission) {
      this.action = '';
      action = '';
    }

    switch (action) {
      case PopupModalState.UpdateStartSprint:
        this.sprintModalInput = this.projectSprintService.buildSprintModalInput(action, sprintName);
        this.sprintModalForm = this.projectSprintService.buildSprintForm(action, currSprint);
        this.modal = { type: 'pop-up', action: action, show: true };
        break;
      case PopupModalState.AddSprint:
        this.sprintModalInput = this.projectSprintService.buildSprintModalInput(action, sprintName);
        this.sprintModalForm = this.projectSprintService.buildSprintForm(action);
        this.modal = { type: 'pop-up', action: action, show: true };
        break;
      case 'delete':
        if (!this.projectSprintService.sprintDeletable) return;
        this.sprintModalInput = this.projectSprintService.buildSprintModalInput(action, sprintName);
        this.modal = { type: 'confirmation', action: action, show: true };
        break;
      case 'start':
        if (this.sprintCloseMode) return;
        this.sprintModalInput = this.projectSprintService.buildSprintModalInput(action, sprintName);
        this.modal = { type: 'confirmation', action: action, show: true };
        break;
      case 'end':
        if (this.sprintCloseMode) return;
        if (this.planningMode) return;
        if(this.currentSprintId === this.defaultSprintId) return;
        this.sprintModalInput = this.projectSprintService.buildSprintModalInput(action, sprintName);
        this.modal = { type: 'confirmation', action: action, show: true };
        break;
      default:
        this.sprintModalInput = null;
        this.modal = { type: 'confirmation', action: action, show: false };
        break;
    }
  }

  modalOutput(action: string, sprintInfo?: any) {
    switch (action) {
      case 'delete':
        if (this.hasSprintEditPermission) {
          this.deleteSprint();
          this.showSprintModal('');
        }
        break;
      case 'start':
        if (this.hasSprintEditPermission) {
          this.beginSprint();
          this.showSprintModal('');
        }
        break;
      case 'end':
        if (this.hasSprintEditPermission) {
          this.endSprint();
          this.showSprintModal('');
        }
        break;
      case 'add':
        if (this.hasSprintEditPermission) {
          this.addSprint(sprintInfo);
          this.showSprintModal('');
        }
        break;
      case 'updateStart':
        if (this.hasSprintEditPermission) {
          this.beginSprint(sprintInfo);
          this.showSprintModal('');
        }
        break;
      case 'update':
        if (this.hasSprintEditPermission) {
          this.updateSprint(sprintInfo);
          this.showSprintModal('');
        }
        break;
      case 'commit':
        const tasksToUpdateIds = this.chartOutput.selectedSteps.map(task => task.id);
        this.commitTasks(this.chartOutput.rangeStart, tasksToUpdateIds);
        this.showSprintModal('');
        break;
      case 'uncommit':
        const storyIdsToRemove = this.chartOutput.selectedSteps.map(task => task.sprintStoryId);
        this.uncommitTasks(this.chartOutput.rangeStart, storyIdsToRemove);
        this.showSprintModal('');
        break;
      default:
        this.showSprintModal('');
        break;
    }
  }

  addSprint(sprintInfo: any): void {
    if (!this.hasSprintEditPermission) return;

    sprintInfo.projectId = this.projectId;

    this.projectSprintService.addProjectSprint(sprintInfo).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        this.projectSprintService.updateSprintList('add', res);
        this.sprintName = null;
        this.setCurrSprint(res.id);
        this.setSprintDropdowns();
        this.setPlanningAndCloseMode();
        this.setupChart();

        this.segmentService.track('Sprint added', { sprintId: this.currentSprintId });
      },
      error => {
        const context: INoficationContext = {
          type: 'sprint',
          action: 'add'
        };

        this.notificationService.error(error, context);
      }
    );
  }

  updateSprint(sprintInfo: any): void {
    if (!this.hasSprintEditPermission) return;

    sprintInfo.sprintId = this.projectSprintService.activeSprint.id ? this.projectSprintService.activeSprint.id : null;
    sprintInfo.projectId = this.projectId;

    if (sprintInfo.sprintId) {
      this.projectSprintService.updateProjectSprint(sprintInfo.sprintId, sprintInfo).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.segmentService.track('Sprint updated', { sprintId: this.currentSprintId });

          this.setupChart();
        },
        err => {
          const context: INoficationContext = {
            type: 'sprint',
            action: 'update'
          };

          this.notificationService.error(err, context);
        }
      );
    } else {
      const context: INoficationContext = {
        type: 'sprint',
        action: 'update'
      };

      this.notificationService.error('INVALID_PROJECT_SPRINT_ID', context);
    }
  }

  deleteSprint() {
    this.projectSprintService.deleteProjectSprint(this.currentSprintId).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.projectSprintService.updateSprintList('delete', this.currentSprintId);
        this.setCurrSprint();
        if (!this.noData) {
          this.setSprintDropdowns();
          this.setPlanningAndCloseMode();
          this.setupChart();
        }

        this.segmentService.track('Sprint deleted', { sprintId: this.currentSprintId });
      },
      err => {
        const context: INoficationContext = {
          type: 'sprint',
          action: 'delete'
        };

        this.notificationService.error(err, context);
      }
    );
  }

  endSprint() {
    const activeSprint = this.projectSprintService.activeSprint;
    this.projectSprintService.endSprint(activeSprint.id).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.segmentService.track('Sprint ended', { sprintId: activeSprint.id });
        // Check if sprint is also closed
        if (res['closedDate']) {
          this.segmentService.track('Sprint closed automatically when ended', { sprintId: activeSprint.id });
          this.notificationService.info('APP_SPRINT_CLOSED', {});
          this.setCurrSprint();
        }

        // Reset data
        this.projectSprintService.updateSprintList('update', res);
        this.projectSprintService.setActiveSprint(null);
        this.setSprintDropdowns();
        this.setPlanningAndCloseMode();
        this.setupChart();
      },
      error => {
        const context: INoficationContext = {
          type: 'sprint',
          action: 'end'
        };

        this.notificationService.error(error, context);
      }
    );
  }

  async beginSprint(sprintInfo?: any) {
    this.showSprintModal('none');
    if (!this.hasSprintEditPermission) return;

    /*-- in case we try to start a sprint with a scheduledEndDate in the past, we need to update it first --*/
    if (sprintInfo) {
      await this.projectSprintService.updateProjectSprint(this.currentSprintId, sprintInfo).pipe(takeUntil(this.destroyed$)).toPromise();
    }

    this.projectSprintService.startSprint(this.currentSprintId).pipe(takeUntil(this.destroyed$)).subscribe(
      async result => {
        this.projectSprintService.updateSprintList('update', result);
        this.projectSprintService.setActiveSprint(result);
        this.setCurrSprint(result.id);
        this.setSprintDropdowns();
        this.setPlanningAndCloseMode();
        this.setupChart();

        this.segmentService.track('Sprint started', { sprintId: this.currentSprintId });
      },
      error => {
        const context: INoficationContext = {
          type: 'sprint',
          action: 'begin'
        };

        this.notificationService.error(error, context);
      }
    );
  }

  commitTasks(rangeKey: number, stepsIds: string[]): void {
    this.forgeViewerService.setDisablePage(true);
    const json = { projectSprintId: this.currentSprintId, projectScheduleTaskIds: stepsIds };

    this.projectSprintService.addSprintStories(json).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        const newStoryIds = res.filter(item => item.id);
        this.setCurrSprint(this.currentSprintId); // this gets us an updated story list in the sprint service variable, necessary for chart redraw to color bars correctly
        this.updateLocalChartValues(rangeKey, res, 'stepStatus', 'stepStatus'); /** TODO - probably should do these all at once **/
        this.updateLocalChartValues(rangeKey, res, 'sprintStoryId', 'id');
        this.updateLocalChartValues(rangeKey, res, 'sprintId', 'projectSprintId');
        if (this.sprintChart) {
          await this.sprintChart.setLatestSprint(this.currentSprintId);
          await this.sprintChart.setupData();
        }

        this.buildNotification(newStoryIds.length, stepsIds.length);
        this.segmentService.track('Sprint story added', { taskIds: stepsIds });

        this.forgeViewerService.setDisablePage(false);
      },
      err => {
        const context: INoficationContext = {
          type: 'task',
          action: 'update'
        };

        this.notificationService.error(err, context);
        this.forgeViewerService.setDisablePage(false);
      }
    );
  }

  uncommitTasks(rangeKey: number, storyIds: string[]): void {
    this.forgeViewerService.setDisablePage(true);
    const storyIdsToRemove = this.chartOutput.selectedSteps.map(task => task.sprintStoryId);

    this.projectSprintService.removeSprintStories(storyIdsToRemove).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        const updatedSteps = res.filter(item => item === true);
        await this.setCurrSprint(this.currentSprintId); // this gets us an updated story list in the sprint service variable, necessary for chart redraw to color bars correctly
        if (updatedSteps.length > 0) {
          this.setLocalChartDataValues(rangeKey, this.chartOutput.selectedSteps, 'stepStatus', 1); /** TODO - probably should do these all at once **/
          this.setLocalChartDataValues(rangeKey, this.chartOutput.selectedSteps, 'sprintStoryId', null);
          this.setLocalChartDataValues(rangeKey, this.chartOutput.selectedSteps, 'sprintId', null);
          if (this.sprintChart) {
            await this.sprintChart.setLatestSprint(this.currentSprintId);
            await this.sprintChart.setupData();
          }
        }

        this.buildNotification(updatedSteps.length, storyIds.length);
        this.segmentService.track('Sprint story remove', { storyIds: storyIdsToRemove });

        this.forgeViewerService.setDisablePage(false);
      },
      err => {
        const context: INoficationContext = {
          type: 'task',
          action: 'remove'
        };

        this.notificationService.error(err, context);
        this.forgeViewerService.setDisablePage(false);
      });
  }

  handleUncommitOutput(chartOutput: IProjectSprintChartOutput): void {
    this.chartOutput = chartOutput;

    const displayMessage = `You have selected ` + this.chartOutput.selectedSteps.length + ` committed task(s). Are you sure you to <b>uncommit</b> to these task(s)?`;
    this.sprintModalInput = {
      displayMessage: displayMessage,
      hasCancelAction: true,
      hasConfirmationAction: true,
      hasDeleteAction: false,
      customContent: false
    };
    this.modal = { type: 'confirmation', action: 'uncommit', show: true };
  }

  handleCommitOutput(chartOutput: IProjectSprintChartOutput): void {
    this.chartOutput = chartOutput;

    let displayMessage = `You have selected ` + this.chartOutput.selectedSteps.length + ` uncommited task(s). `;
    if (this.chartOutput.blockerSteps.length > 0) {
      displayMessage += `There are ` + this.chartOutput.blockerSteps.length + ` uncommitted prerequesite task(s) associated with your selection. `;
    }
    displayMessage += `Are you sure you to <b>commit</b> to these task(s)?`;

    this.sprintModalInput = {
      displayMessage: displayMessage,
      hasCancelAction: true,
      hasConfirmationAction: true,
      hasDeleteAction: false,
      customContent: false
    };
    this.modal = { type: 'confirmation', action: 'commit', show: true };
  }

  /**
   * setLocalChartDataValues - set the local data step values
   */
  setLocalChartDataValues(rangeKey: number, steps: IProjectScheduleStep[], key: string, value: any): void {
    const updateLocalObj = steps.map(step => ({ step: step, key: key, value: value }));
    this.projectScheduleService.updateLocalScheduleStepFromStep(rangeKey, updateLocalObj);
  }

  /**
   * updateLocalChartValues - updates local data step values from updated sprint stories values
   */
  updateLocalChartValues(rangeKey: number, stories: IProjectSprintStory[], key: string, valueKey: string): void {
    const updateLocalObj = stories.map(story => ({ story: story, key: key, value: story[valueKey] }));
    this.projectScheduleService.updateLocalScheduleStepFromStory(rangeKey, updateLocalObj);
  }

  buildNotification(updatedStepCount: number, totalStepCount: number): void {
    const context: INoficationContext = {
      type: 'task',
      action: 'update'
    };
    const message = updatedStepCount + '/' + totalStepCount + ' task(s) updated.';
    if (updatedStepCount > 0) this.notificationService.success(message, context);
    else this.notificationService.error(message, context);
  }

  filterModel(filters: string[]): void {
    this.projectSprintService.setActiveFilters(filters);

    if (this.sprintChart) this.sprintChart.drawChart();
    if (this.sprintBoard) this.sprintBoard.setBoardItems();
    if (this.sprintCommitment) this.sprintCommitment.filterBoardItems();
  }

  setFilterType(type?: FilterModelOptions): void {
    const filterType = type ? type : FilterModelOptions.Activities;
    this.projectSprintService.setActiveFilterType(filterType);
    this.projectSprintService.setActiveFilters([]);
  }

  stepNameChanged(name): void {
    const index = this.sprintBoard._projectSprintBoardService.sprintBoardData.findIndex(item => item.stepId === this.selectedStep.stepId);
    if (index >= 0) this.sprintBoard._projectSprintBoardService.sprintBoardData[index].stepName = name;
  }

  handleDisplayModeOutput(mode: any) {
    this.setDisplayMode(mode.mode);
    this.setupSidebarDataFromSteps([]);
  }
  setDisplayMode(displayMode: string): void {
    this.projectSprintService.setSprintDislayMode(displayMode);
    this.selectedDisplayMode = displayMode;

    this.setupChart();
  }
  resetDisplayModeState(): void {
    if (this.sprintBoard) this.sprintBoard.resetAll();
    if (this.sprintChart) this.sprintChart.setMyViewerState();
  }

  pageRefresh(): void {
    window.location.reload(true);
  }

  filterSprints(clicked: boolean) {
    if (clicked) {
      this.filteredOpenSprintDropdown = this.openSprintDropdown;
      return;
    }
    if (this.sprintDropdownInput !== '') {
      this.filteredOpenSprintDropdown = this.openSprintDropdown.filter(sprint => sprint.name.toLowerCase().includes(this.sprintDropdownInput.toLowerCase()));
    } else {
      this.filteredOpenSprintDropdown = this.openSprintDropdown;
    }
  }

  selectSprint() {
    const selectedSprint = this.openSprintDropdown.find(sprint => sprint.name.toLowerCase() === this.sprintDropdownInput.toLowerCase());
    if (selectedSprint) this.currentSprintId = selectedSprint.id;
    else this.sprintDropdownInput = this.openSprintDropdown.find(sprint => sprint.id === this.currentSprintId).name;
    this.setCurrSprint(this.currentSprintId);
    this.setPlanningAndCloseMode();
  }
}
