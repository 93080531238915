
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectSprintService } from '../../../services/project/project-sprint/project-sprint.service';
import { ProjectStepService } from '../../../services/project/project-step/project-step.service';

import { IXaxisChartData } from '../../../models/chart/chart.interface';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { IEpochRange, IProjectScheduleControls, IProjectScheduleStep } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectSprintChartOutput, IProjectViewModes } from '../../../models/project/project-sprint/project-sprint.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { IWeatherForecast } from '../../../models/weather/weather.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../../utils/enums/forge-viewer-type';
import { StepStatusColor } from '../../../utils/enums/hex-color.enum';
import { ViewMode } from '../../../utils/enums/shared.enum';
import { Utils } from '../../../utils/utils';

import * as D3 from 'd3';
import * as moment from 'moment';
import { TranslationService } from '../../../services/translation/translation.service';

@Component({
  selector: 'app-project-sprint-chart',
  templateUrl: './project-sprint-chart.component.html',
  styleUrls: ['./project-sprint-chart.component.scss']
})
export class ProjectSprintChartComponent implements OnChanges, OnInit, OnDestroy {
  @Input() chartInput: any;
  @Input() subContractorsInput: IProjectSubContractor[];
  @Input() weatherDataInput: IWeatherForecast[];

  @Output() commitOutput: EventEmitter<IProjectSprintChartOutput> = new EventEmitter();
  @Output() uncommitOutput: EventEmitter<IProjectSprintChartOutput> = new EventEmitter();
  @Output() displayModeOutput: EventEmitter<IProjectViewModes> = new EventEmitter();
  @Output() chartSelectionOutput: EventEmitter<IProjectScheduleStep[]> = new EventEmitter();

  @ViewChild('chartContainer') chartContainer;
  @ViewChild('patternContainer') patternContainer;
  @ViewChild('gridContainer') gridContainer;
  @ViewChild('chartBarsContainer') chartBarsContainer;
  @ViewChild('startAndEndLinesContainer') startAndEndLinesContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('yAxis') yAxis;

  chartDataAvailable: boolean = false;
  noDataMessage: string = 'no_activity_in_range';
  errorGettingData: boolean = false;
  errorMessage: string = 'error';

  chartContainerHeight: number;
  chartContainerWidth: number;
  private gridContainerHeight: number;
  private gridContainerWidth: number;
  private minGridContainerWidth: number;
  private minGridContainerHeight: number;
  private colWidth: number;
  private visibleChartRange: IEpochRange;
  private visibleChartData: any;
  private visibleDays: number[];
  private visibleViewerRange: number[]; // date range of objects in viewer (schedule start - last day in chart range)
  private visibleStepsBySub: { [key: string]: IProjectScheduleStep[] }; // all steps in view, sorted by subcontractor
  private barData: any[] = [];
  private gridData: any[] = [];
  private categoryScales: D3.ScaleBand<any> = {};
  private xScaleTime: D3.scaleUtc<any> = null;
  private colorScale: D3.scaleQuantize<any> = null;
  private rows: any[] = [];
  private allD3Bars: D3.selection<SVGElement>;
  private allD3Cells: D3.selection<SVGElement>;
  private xAxisData: IXaxisChartData[];
  chartControls: IProjectScheduleControls;

  // selections
  private blockerSteps: IProjectScheduleStep[] = [];
  taskSelections: { [key: string]: IProjectScheduleStep[] } = {
    uncommitted: [],
    committed: [],
    all: []
  };
  currentCellIdSelections: any[] = []; // cell selections
  currentDaySelections: number[] = [];

  private defaultViewerState = {};
  private myViewerState = {};
  private commitedSteps: any = {};

  // sprint
  currSprintData: any;
  currSprintStoryIds: string[];

  private shiftKeyPressed: boolean = false;

  // Subscription takeUntil
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private forgeViewerService: ForgeViewerService,
    private projectStepService: ProjectStepService,
    private notificationService: NotificationService,
    public projectScheduleService: ProjectScheduleService,
    public projectSprintService: ProjectSprintService
  ) { }

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    window.addEventListener('keyup', this.keyRelease);
    this.projectSprintService.setSprintDislayMode(ViewMode.SprintBoard);
  }

  async ngOnChanges() {
    if (this.chartInput && this.subContractorsInput.length > 0) {
      if (!this.currSprintData || (this.currSprintData.id !== this.chartInput.sprintId)) {
        await this.setLatestSprint(this.chartInput.sprintId);
      }
      const firstSunInRange = moment.utc(this.chartInput.sprintRange[0]).startOf('week').valueOf();
      this.setVisibleData(firstSunInRange);
      this.buildChartControls();
      await this.setupData();
    } else {
      this.setDataAvailable(false);
    }
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  keyPress = (event) => {
    if (event.key === 'Shift') {
      this.shiftKeyPressed = true;
    }
  }

  keyRelease = (event) => {
    if (event.key === 'Shift') {
      this.shiftKeyPressed = false;
    }
  }

  setVisibleData(fromDate: number, dayCount: number = 7) {
    this.visibleChartRange = this.projectScheduleService.getActiveEpochRange(fromDate, dayCount);
    this.visibleChartData = this.projectScheduleService.getLocalScheduleData()[this.visibleChartRange.startEpochRange];
    this.visibleDays = this.projectScheduleService.getListOfActiveEpochs(fromDate, dayCount);
    this.visibleViewerRange = [this.chartInput.scheduleStart, this.visibleChartRange.endEpochRange];
    this.xAxisData = this.projectSprintService.buildXaxisData(this.visibleDays, this.weatherDataInput);

    if (!this.visibleChartData || this.visibleChartData.steps.length === 0) {
      this.setDataAvailable(false);
      return;
    } else {
      this.visibleStepsBySub = this.projectScheduleService.sortStepsBySubContractorName(this.visibleChartData.steps, this.subContractorsInput);
      if (!Utils.objectIsEmpty(this.visibleStepsBySub)) {
        this.setDataAvailable(true);
      } else {
        this.setDataAvailable(false);
      }
    }
  }

  async setupData() {
    if (!this.errorGettingData && this.chartDataAvailable) {
      this.drawChart();
    } else {
      this.setMyViewerState();
      this.setDataAvailable(false);
    }
  }

  async setLatestSprint(id: string) { /*--TODO - hold current sprint data in service???? --*/
    return new Promise(resolve => {
      this.currSprintData = this.projectSprintService.getSprint(id);
      /*-- TODO - get stories in sprint component and share the data with the sprint chart (currently getting stories in both child components) --*/
      this.projectSprintService.getAllSprintStories(id).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.currSprintData['stories'] = res;
          this.currSprintStoryIds = res.length > 0 ? res.map(story => story.id) : [];
          this.checkSprintIsDeletable();

          resolve();
        },
        err => {
          const context: INoficationContext = {
            type: 'sprint stories',
            action: 'get'
          };

          this.notificationService.error(err, context);
          this.setDataError(true);
          resolve();
        }
      );
    });
  }

  buildChartControls() {
    this.chartControls = {
      bulkEdit: false,
      reset: true,
      today: true,
      search: false,
      animation: false,
      timeFrames: {
        display: true,
        rangeStartDate: this.visibleChartRange.startRangeForDisplay,
        rangeEndDate: this.visibleChartRange.endRangeForDisplay,
        daily: {
          title: '',
          display: false,
        },
        range: true,
        start: {
          title: 'scheduled_start',
          display: true
        },
        end: {
          title: 'scheduled_end',
          display: true
        }
      },
      customControls: true
    };
  }
  setChartControlRange(): void {
    this.chartControls.timeFrames.rangeStartDate = this.visibleChartRange.startRangeForDisplay;
    this.chartControls.timeFrames.rangeEndDate = this.visibleChartRange.endRangeForDisplay;
  }

  async resizeChart() {
    this.calculateRows();
    this.calculateChartDimensions();
    await this.createScales();
    this.drawYaxis();
    this.drawXaxis();
    this.setupGridAndBars();
    this.addPatterns();
    this.drawStartAndEndLines();
  }

  async drawChart() {
    this.resetAllSelections();
    this.calculateRows();
    if (this.rows.length > 0) {
      this.calculateChartDimensions();
      await this.createScales();
      this.drawYaxis();
      this.drawXaxis();
      this.setupGridAndBars();
      this.addPatterns();
      this.drawStartAndEndLines();
    } else {
      this.setDataAvailable(false);
    }

    this.setMyViewerState();
  }

  calculateRows() {
    this.rows = [];
    let yTransform = 0;

    for (const key in this.visibleStepsBySub) {
      if (this.visibleStepsBySub[key]) {
        const steps = this.visibleStepsBySub[key];
        const dyanmicRowInfo = this.getDynamicRowInfo(steps);
        const subId = steps[0]['subInfo'].id ? steps[0]['subInfo'].id : null;
        const rowObject = {
          hidden: this.rowFilterOut(subId),
          height: dyanmicRowInfo.height,
          yPos: yTransform,
          id: subId,
          label: key ? key : '',
          abbrv: steps[0]['subInfo'].abbreviation ? steps[0]['subInfo'].abbreviation : '',
          subInfo: steps[0]['subInfo'],
          steps: steps,
          selected: false,
          rowScaleIndex: dyanmicRowInfo.scale
        };

        if (!rowObject.hidden) {
          yTransform += dyanmicRowInfo.height;
          this.rows.push(rowObject);
        }
      }
    }
  }
  rowFilterOut(subId: string): boolean {
    const activeFilter = this.projectSprintService.getActiveFilterType();
    const activeFilters = this.projectSprintService.getActiveFilters();

    return (subId && activeFilter === FilterModelOptions.SubContractor && activeFilters.length > 0)
      ? !activeFilters.includes(subId)
      : false;
  }

  /**
   * getDynamicRowInfo - does the calculating to figure how tall each row is based on steps in that row, and the y-coord for the step bar
   * @param steps
   */
  getDynamicRowInfo(steps: IProjectScheduleStep[]): any {
    let dynamicScale = 0;
    const dynamicRowScale = [dynamicScale];
    let largestRowIncrementer = 1; // largest increment for all days per sub, this will be set as the overall total
    let rowHeightIncrementer = 1; // per sub per day increment, used to compare against ^^^
    let previousStepDay;

    steps.forEach((step, index) => {
      const stepDay = step.dayStart;
      const sameDay = stepDay === previousStepDay;
      const lastTask = index === steps.length - 1;

      // if tasks overlap on the same day, increment the rowIncrementer and add to domain array for row scale
      if (sameDay) {
        rowHeightIncrementer += 1;
        dynamicScale += 1;
        if (!dynamicRowScale.includes(dynamicScale)) dynamicRowScale.push(dynamicScale);
      }

      // if it's a new day or the last task for sub, calc the largest incrementer and reset for the next day
      // do this because we want the overall incrementer to be the largest on any given day in that row
      if (!sameDay || lastTask) {
        if (rowHeightIncrementer > largestRowIncrementer) {
          largestRowIncrementer = rowHeightIncrementer;
        }
        rowHeightIncrementer = 1;
        dynamicScale = 0;
      }

      previousStepDay = step.dayStart;
    });

    return {
      height: this.projectScheduleService.sD.minRowHeight * largestRowIncrementer,
      scale: dynamicRowScale
    };
  }

  /**
   * calculateChartDimensions - calculates height, width and offset transform for chart container and grid
   */
  calculateChartDimensions() {
    const sbWidth = JSON.parse(localStorage.getItem('showSidebar')) ? 300 : 0;
    const menuWidth = JSON.parse(localStorage.getItem('showMainMenu')) ? 155 : 45;
    const fitWidthToView = ((window.innerWidth - sbWidth - menuWidth) * .9) - this.projectScheduleService.sD.chartOffsetLeft; // 90% of window without navigation and yAxisOffset
    this.minGridContainerWidth = (this.projectScheduleService.sD.minColumnWidth * this.visibleDays.length);
    this.minGridContainerHeight = this.projectScheduleService.sD.minRowHeight * (this.subContractorsInput.length);
    this.gridContainerWidth = this.minGridContainerWidth < fitWidthToView
      ? fitWidthToView
      : this.minGridContainerWidth;
    this.gridContainerHeight = !Utils.isEmpty(this.rows)
      ? this.rows.map(row => row.height).reduce((a, b) => a + b)
      : this.minGridContainerHeight;
    this.chartContainerHeight = this.gridContainerHeight + this.projectScheduleService.sD.chartOffsetTop;
    this.chartContainerWidth = this.gridContainerWidth + this.projectScheduleService.sD.chartOffsetLeft;
    const borderOffset = this.visibleDays.length - 1; // offset width for cell borders
    this.colWidth = (this.chartContainerWidth / this.visibleDays.length) - borderOffset;
  }

  async createScales() {
    const endOfLastDay = new Date(this.visibleChartRange.endEpochRange).setHours(23, 59, 59, 999);

    // x-axis scale
    this.xScaleTime = D3.scaleUtc()
      .range([0, this.gridContainerWidth])
      .domain([this.visibleChartRange.startEpochRange, endOfLastDay]);

    // criticality color scale
    this.colorScale = D3.scaleQuantize()
      .domain([0, 1])
      .range(this.projectScheduleService.getCriticalityColorPalette().map(color => D3.rgb(color)));

    // each row has it's own scale
    this.createRowScales(this.rows);
  }

  createRowScales(rows: any[]): void {
    let rowScaleRangeStart = this.projectScheduleService.sD.barPadding / 2;

    rows.forEach(row => {
      const rowHeight = row.height;
      const rowScaleIndex = row.rowScaleIndex;
      const rowId = row.id;
      const rowScaleRangeEnd = rowScaleRangeStart + rowHeight;

      this.createRowScale(rowId, rowScaleRangeStart, rowScaleRangeEnd, rowScaleIndex);
      rowScaleRangeStart = rowScaleRangeEnd;
    });
  }

  // creates individual scale for rows, for stacking steps if needed
  createRowScale(rowId: string, rangeStart: number, rangeEnd: number, domain: any[]): void {
    const rowScale = D3.scaleBand()
      .range([rangeStart, rangeEnd])
      .domain(domain);
    this.categoryScales[rowId] = rowScale;
  }

  drawYaxis() {
    if (this.yAxis) {

      if (!Utils.isEmpty(this.rows)) {
        // have to manually create y-axis labels because of grouping...probably a better way to do this
        D3.select(this.yAxis.nativeElement)
          .attr('class', 'y-axis-labels')
          .attr('transform', this.projectScheduleService.sD.yAxisTransform)
          .selectAll('g')
          .remove() // refresh data each time
          .exit()
          .data(this.rows)
          .enter()
          .append('g')
          .append('text')
          .text((d) => d.label.substring(0, 10))
          .attr('text-anchor', 'end')
          .attr('y', (d) => this.categoryScales[d.id](0) + d.height / 2)
          .attr('class', (d) => d.selected
            ? 'y-axis-label _' + d.id + ' label-selected'
            : 'y-axis-label _' + d.id
          )
          .on('click', (d) => this.handleYaxisLabelClick(d));

        // removes extra line from axis
        D3.select(this.yAxis.nativeElement)
          .select('path')
          .remove();
      } else {
        D3.select(this.yAxis.nativeElement)
          .selectAll('g')
          .remove(); // refresh data each time
      }
    }
  }

  handleYaxisLabelClick(row: any): void {
    row.selected = !row.selected;
    const selectedLabel = D3.selectAll('.y-axis-label').filter('._' + row.id);
    const rowCells = D3.selectAll('.D3-grid-cell').filter('._' + row.id);
    const rowSteps = row.steps;

    if (selectedLabel.classed('label-selected')) {
      selectedLabel.classed('label-selected', false);
      rowCells.classed('cell-selected', false); // remove single cell selections
      rowCells.classed('cell-selected-y', false); // remove selections from y-axis

      this.updateCellSelections(rowCells, 'remove');
      this.updateTaskSelections(rowSteps, 'remove');
      this.setSelectedBarsState(this.taskSelections.all);
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) this.resetAllSelections();

      selectedLabel.classed('label-selected', true);
      rowCells.classed('cell-selected', false);
      rowCells.classed('cell-selected-y', true);

      this.updateCellSelections(rowCells, 'remove');
      this.updateTaskSelections(rowSteps, 'add');
      this.setSelectedBarsState(this.taskSelections.all);
    }
  }

  drawXaxis() {
    if (this.xAxisData.length > 0) {
      const xAxisLabel = D3.select(this.xAxis.nativeElement)
        .attr('class', 'x-axis-labels')
        .attr('transform', this.projectScheduleService.sD.xAxisTransform)
        .selectAll('g')
        .remove() // refresh data each time
        .exit()
        .data(this.xAxisData)
        .enter()
        .append('g')
        .attr('transform', (d) => 'translate(' + this.xScaleTime(d.day) + ', 0)');

      xAxisLabel
        .append('text')
        .text((d) => Utils.formatDateToDayAndMonthString(d.day))
        .attr('class', (d) => 'x-axis-label _' + d.day)
        .attr('x', (this.colWidth / 2) - 20)
        .on('click', (d) => this.handleXaxisLabelClick(d.day));

      this.drawWeatherInfo(xAxisLabel);
    } else {
      this.setDataAvailable(false);
    }
  }

  drawWeatherInfo(label: any) {
    const weatherLabel = label
      .filter((d) => d.weather)
      .append('foreignObject')
      .append('xhtml:div')
      .attr('class', 'weather-info')
      .style('width', this.colWidth + 'px');

    weatherLabel
      .append('xhtml:i')
      .attr('class', (d) => 'fa ' + d.weather.icon);

    weatherLabel
      .append('xhtml:span')
      .attr('class', 'weather-info-label')
      .html((d) => d.weather.highTemp + '&deg; / ' + d.weather.lowTemp + '&deg;');
  }

  handleXaxisLabelClick(timestamp: number): void {
    const selectedLabel = D3.selectAll('.x-axis-label').filter('._' + timestamp);
    const columnCells = D3.selectAll('.D3-grid-cell').filter('._' + timestamp);
    const colSteps = this.projectScheduleService.getStepsFromDay(timestamp);

    if (selectedLabel.classed('label-selected')) {
      selectedLabel.classed('label-selected', false);
      columnCells.classed('cell-selected', false); // remove single cell selections
      columnCells.classed('cell-selected-x', false); // remove selections from x-axis

      this.updateCellSelections(columnCells, 'remove');
      this.updateTaskSelections(colSteps, 'remove');
      this.setSelectedBarsState(this.taskSelections.all);
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) this.resetAllSelections();

      selectedLabel.classed('label-selected', true);
      columnCells.classed('cell-selected-x', true); // add selection from x-axis specifically

      this.updateCellSelections(columnCells, 'add');
      this.updateTaskSelections(colSteps, 'add');
      this.setSelectedBarsState(this.taskSelections.all);
    }
  }

  setupGridAndBars() {
    this.resetChartData();

    this.rows.forEach((row, index) => {
      const rowColor = index % 2 === 0 ? '#EFF3F4' : 'white';
      this.visibleDays.forEach(day => {
        const cellId = day + row.id; // used as a way to have unique identifer for each cell
        const stepsInCell = row.steps.filter(step => step.dayStart === day && row.id === step.subContractorId);
        const cellData = {
          height: row.height,
          width: this.colWidth,
          columnId: day,
          endOfDay: new Date(day).setHours(23, 59, 59, 999),
          rowId: row.id,
          steps: stepsInCell,
          cellId: cellId,
          transform: 'translate(' + this.xScaleTime(day) + ', ' + row.yPos + ')',
          yPos: row.yPos,
          class: 'grid-cell',
          selected: this.currentCellIdSelections.includes(cellId),
          defaultBackground: rowColor
        };

        this.gridData.push(cellData);
        this.setBarData(stepsInCell);
      });
    });

    if (this.barData.length > 0) {
      this.drawGrid(this.gridData);
      this.drawBars(this.barData);

      this.setDataAvailable(true);
    } else {
      this.setDataAvailable(false);
    }
  }

  setBarData(steps): void {
    const taskBars = [];
    const selectedTaskIds = this.taskSelections.all.map(task => task.id);
    let rowScaleIndex = 0;

    steps.forEach(step => {
      const barWidth = (step.epochEnd && step.epochStart) ? (this.xScaleTime(step.epochEnd) - this.xScaleTime(step.epochStart)) - 3 : null;
      const subId = step.subInfo.id;
      const barInfo = {
        height: this.projectScheduleService.sD.barHeight,
        width: barWidth > 4 ? barWidth : 4,
        xCoord: this.xScaleTime(step.epochStart),
        status: step.stepStatus ? step.stepStatus : null,
        yCoord: this.categoryScales[subId](rowScaleIndex),
        subcontractor: step.subInfo.name ? step.subInfo.name : '',
        stepName: step.name ? step.name : 'Unnamed',
        activityTypes: step.activities && step.activities.length > 0 ? step.activities : null,
        color: this.getBarColor(step), // committed or criticality
        hidden: this.taskFilteredOut(step),
        disabled: this.getDisabledState(step), // in another sprint, or status > 2 (this will overwrite color)
        inactive: this.getInActiveState(step), // if blockers
        blocker: this.getBlockerState(step),
        name: (step.criticality === -1 ? 'Lag: ' : '') + (step.name ? step.name : 'Unnamed'),
        crewSize: step.crewSize ? step.crewSize : null,
        columnId: step.epochStart,
        sprintId: step.sprintId ? step.sprintId : null,
        barId: step.id ? step.id : null,
        rowId: subId,
        steps: [step],
        totalDuration: step.totalDurationHours ? step.totalDurationHours.toFixed(2) : null,
        taskDuration: step.durationHours ? step.durationHours : null,
        selected: selectedTaskIds.includes(step.id) ? true : false
      };

      if (!barInfo.hidden) this.updateDefaultViewerState([step], 'add');
      else this.updateDefaultViewerState([step], 'remove');

      taskBars.push(barInfo);
      rowScaleIndex += 1;
    });

    this.barData = this.barData.concat(taskBars);
  }

  drawGrid(data: any): void {
    const grid = D3.select(this.gridContainer.nativeElement)
      .attr('width', this.gridContainerWidth)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(data)
      .enter();

    grid
      .append('rect')
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('transform', (d) => d.transform)
      .attr('fill', (d) => d.defaultBackground)
      .attr('class', (d) => d.selected
        ? 'D3-grid-cell _' + d.rowId + ' _' + d.columnId + ' cell-selected'
        : 'D3-grid-cell _' + d.rowId + ' _' + d.columnId
      ).on('click', (d) => this.handleGridCellClick(d));

    this.allD3Cells = D3.selectAll('.D3-grid-cell');
  }

  drawBars(data: any) {
    D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(data)
      .enter()
      .append('rect')
      .attr('fill', (d) => d.color)
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('x', (d) => d.xCoord + 1) // 2 offsets it from grid border
      .attr('y', (d) => d.yCoord)
      .attr('opacity', (d) => d.hasBlockers ? .25 : 1)
      .attr('class', (d) => this.getBarClass(d))
      .on('click', (d) => this.handleBarClick(d))
      .on('mouseover', (d) => this.handleBarHover(d, 'mouseover'))
      .on('mouseout', (d) => this.handleBarHover(d, 'mouseout'));

    this.allD3Bars = D3.selectAll('.D3-chart-bar');
  }

  handleGridCellClick(cell: any): void {
    cell.selected = !cell.selected;
    const selectedColumnId = cell.columnId;
    const selectedRowId = cell.rowId;
    const selectedCell = this.allD3Cells.filter('._' + selectedRowId).filter('._' + selectedColumnId);
    const cellSteps = cell.steps;

    // set cell state
    if (selectedCell.classed('cell-selected')) {
      selectedCell.classed('cell-selected', false);

      this.updateCellSelections(selectedCell, 'remove');
      this.updateTaskSelections(cellSteps, 'remove');
      this.setSelectedBarsState(this.taskSelections.all);
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) this.resetAllSelections();
      selectedCell.classed('cell-selected', true);

      this.updateCellSelections(selectedCell, 'add');
      this.updateTaskSelections(cellSteps, 'add');
      this.setSelectedBarsState(this.taskSelections.all);
    }
  }

  getDisabledState(step: IProjectScheduleStep): boolean {
    let disableTask = false;
    if (!step.canEdit) {
      disableTask = true;
    } else if (this.currSprintStoryIds) {
      const inSprint = this.currSprintStoryIds.find(id => id === step.sprintStoryId);
      disableTask = (step.sprintStoryId && !inSprint) || (step.stepStatus > 2);
    }

    return disableTask;
  }

  getInActiveState(step: IProjectScheduleStep): boolean {
    return (step.prerequisiteObjectIds && step.prerequisiteObjectIds.length > 0) && step.stepStatus < 2;
  }

  getBlockerState(step: IProjectScheduleStep): boolean {
    const stepId = step.id ? step.id : null;
    const bStepIds = this.blockerSteps.map(bStep => bStep.id);

    return (stepId && bStepIds.includes(stepId));
  }

  getBarColor(step: IProjectScheduleStep): string {
    return step.stepStatus === 2 ? StepStatusColor.committed : this.colorScale(step.criticality);
  }

  getBarClass(bar: any): string {
    let barClass = 'D3-chart-bar _' + bar.barId + ' _' + bar.columnId;
    if (bar.selected) {
      barClass += ' bar-active';
    } else {
      if (bar.disabled) barClass += ' bar-disabled';
      if (bar.inactive) barClass += ' bar-inactive';
      if (bar.hidden) barClass += ' bar-hidden';
      if (bar.blocker) barClass += ' bar-blocker';
    }

    return barClass;
  }

  taskFilteredOut(step: IProjectScheduleStep): boolean {
    const activeFilterType = this.projectSprintService.getActiveFilterType();
    const activeFilters = this.projectSprintService.getActiveFilters();

    if (activeFilters.length > 0) {
      switch (activeFilterType) {
        case FilterModelOptions.Activities:
          return step.activities.length > 0 && Utils.similarValuesInArrays(step.activities, activeFilters).length < 1;

        case FilterModelOptions.SubContractor:
          return step.subContractorId && !activeFilters.includes(step.subContractorId);

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  handleBarHover(barData: any, action: string): void {
    if (action === 'mouseover') {
      const ttHeight = 120;
      const ttWidth = 400;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(barData, 'bar'));

      // Removed code to handle bar hover
      // barData.steps.forEach(step => {
      //   this.setFvObjectState(step, ForgeViewerType.Hover);
      // });
    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip').style('display', 'none');
      // Removed code to handle bar hover
      // barData.steps.forEach(step => {
      //   this.setFvObjectState(step);
      // });
    }
  }

  handleBarClick(bar: any): void {
    bar.selected = !bar.selected;
    const selectedBar = this.allD3Bars.filter('._' + bar.barId);

    if (selectedBar.classed('bar-active')) {
      this.updateTaskSelections(bar.steps, 'remove');
      this.setSelectedBarsState(this.taskSelections.all);
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) this.resetAllSelections();

      this.updateTaskSelections(bar.steps, 'add');
      this.setSelectedBarsState(this.taskSelections.all);
    }
  }

  updateTaskSelections(steps: IProjectScheduleStep[], action: string): void {
    const clickedStepIds = this.taskSelections.all.map(step => step.id);
    if (steps.length > 0) {
      steps.forEach(step => {
        const isFilteredOut = this.taskFilteredOut(step);
        if (!clickedStepIds.includes(step.id) && action === 'add' && !isFilteredOut && step.canEdit) {
          const stepActionable = this.projectSprintService.stepActionable(step, this.currSprintData.stories);
          const sprintActive = this.currSprintData.startDate && !this.currSprintData.endDate;

          if (step.stepStatus > 1 && !sprintActive && stepActionable && !Utils.objectInArray(this.taskSelections.committed, 'id', step.id)) this.taskSelections.committed.push(step);
          if (step.stepStatus < 2 && stepActionable && !Utils.objectInArray(this.taskSelections.uncommitted, 'id', step.id)) this.taskSelections.uncommitted.push(step);
          this.taskSelections.all.push(step);
        } else if (action === 'remove' && !isFilteredOut && step.canEdit) {
          if (step.stepStatus > 1) this.taskSelections.committed = this.taskSelections.committed.filter(task => task.id !== step.id);
          if (step.stepStatus < 2) this.taskSelections.uncommitted = this.taskSelections.uncommitted.filter(task => task.id !== step.id);
          this.taskSelections.all = this.taskSelections.all.filter(task => task.id !== step.id);
        }
      });
    }

    this.setMyViewerState();
    this.emitChartSelection();
  }

  updateCellSelections(cells: any, action: string): void {
    const cellData = [];
    cells.each((d) => cellData.push(d));

    cellData.forEach(cell => {
      const cellId = cell.cellId ? cell.cellId : null;
      if (cellId && !this.currentCellIdSelections.includes(cellId) && action === 'add') {
        this.currentCellIdSelections.push(cellId);
      } else if (cellId && action === 'remove') {
        this.currentCellIdSelections = this.currentCellIdSelections.filter(id => id !== cellId);
      }
    });
  }

  setSelectedBarsState(steps: IProjectScheduleStep[]): void {
    this.allD3Bars
      .classed('bar-blocker', false)
      .classed('bar-active', false);

    this.blockerSteps = this.projectStepService.getUncommittedBlockerSteps(steps, this.visibleChartData.steps);
    this.setActiveSteps(this.taskSelections.all);
    this.setBlockerSteps(this.blockerSteps);
  }

  setBlockerSteps(steps: IProjectScheduleStep[]): void {
    if (steps.length > 0) {
      steps.forEach(step => {
        D3.selectAll('._' + step.id).classed('bar-blocker', true);
      });
    }
  }

  setActiveSteps(steps: IProjectScheduleStep[]): void {
    if (steps.length > 0) {
      steps.forEach(step => {
        D3.selectAll('._' + step.id).classed('bar-active', true);
      });
    }
  }

  emitChartSelection(): void {
    this.chartSelectionOutput.emit(this.taskSelections.all);
  }

  addPatterns() {
    const patContainer = D3.select(this.patternContainer.nativeElement);
    const patternEl = (patId) => ({
      id: patId,
      width: '4',
      height: '4',
      patternUnits: 'userSpaceOnUse',
      patternTransform: 'rotate(45)'
    });

    const rectEl = (fill, width, height) => ({
      width: width,
      height: height,
      transform: 'translate(0, 0)',
      fill: fill
    });

    patContainer
      .append('pattern')
      .attrs(patternEl('stripes'))
      .append('rect')
      .attrs(rectEl(StepStatusColor.blocker, 2, 4));

    const pat2 = patContainer
      .append('pattern')
      .attrs(patternEl('stripes-2'));

    pat2.append('rect')
      .attrs(rectEl(StepStatusColor.selected, 4, 4));
    pat2.append('rect')
      .attrs(rectEl(StepStatusColor.blocker, 2, 4));
  }

  drawStartAndEndLines() {
    const startDate = this.currSprintData.startDate ? moment.utc(this.currSprintData.startDate).startOf('day').valueOf()
      : this.currSprintData.scheduledStartDate ? this.currSprintData.scheduledStartDate
        : null;

    const endDate = this.currSprintData.endDate ? moment.utc(this.currSprintData.endDate).endOf('day').valueOf()
      : this.currSprintData.scheduledEndDate ? moment.utc(this.currSprintData.scheduledEndDate).endOf('day').valueOf()
        : null;

    const startAndEndLines = [
      {
        height: this.gridContainerHeight,
        width: 4,
        color: 'green',
        value: startDate,
        type: 'start',
        transform: 'translate(' + (this.xScaleTime(startDate) - 2) + ', 0)'
      },
      {
        height: this.gridContainerHeight,
        width: 4,
        color: 'red',
        value: endDate,
        type: 'end',
        transform: 'translate(' + (this.xScaleTime(endDate)) + ', 0)'
      }
    ];

    const linesContainer = D3.select(this.startAndEndLinesContainer.nativeElement)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(startAndEndLines)
      .enter();

    linesContainer
      .append('rect')
      .attr('fill', (d) => d.color)
      .attr('class', 'line-marker')
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('transform', (d) => d.transform)
      .on('mouseover', (d) => this.handleLineHover(d, 'mouseover'))
      .on('mouseout', (d) => this.handleLineHover(d, 'mouseout'));
  }

  handleLineHover(lineData: any, action: string): void {
    if (action === 'mouseover') {
      const ttHeight = 40;
      const ttWidth = 150;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(lineData, 'line'));
    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip').style('display', 'none');
    }
  }

  drawTooltip(data: any, type: string): string {
    let tooltip = ``;
    switch (type) {
      case 'line':
        if (data.type === 'start') tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('start_date') +
          `: </span><span class='value'>` + moment.utc(data.value).format('MM/DD/YYYY') + `</span></div>`;
        if (data.type === 'end') tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('end_date') +
        `: </span><span class='value'>` + moment.utc(data.value).format('MM/DD/YYYY') + `</span></div>`;
        break;

      case 'bar':
        if (data.stepName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('name') +
        `: </span><span class='value'>` + data.stepName + `</span></div>`;
        if (data.subcontractor) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('subcontractor') +
        `: </span><span class='value'>` + data.subcontractor + `</span></div>`;
        if (data.taskDuration) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('task_hours') +
        `: </span><span class='value'>` + data.taskDuration + `</span></div>`;
        if (data.totalDuration) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('total_hours') +
        `: </span><span class='value'>` + data.totalDuration + `</span></div>`;
        if (data.sprintId) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('sprint') +
        `: </span><span class='value'>` + this.projectSprintService.getSprintName(data.sprintId) + `</span></div>`;
        if (data.status) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('status') +
        `: </span><span class='value'>` + this.projectStepService.convertStatusToString(data.status) + `</span></div>`;
        break;
    }

    return tooltip;
  }

  handledisplayModeSelection(displayMode: IProjectViewModes): void {
    this.displayModeOutput.emit(displayMode);
  }

  handleResetCtrl(): void {
    this.resetAllSelections();
    this.setMyViewerState();
  }

  handleTodayCtrl(): void {
    const today = moment.utc().startOf('week').valueOf();
    this.setVisibleData(today);
    this.setChartControlRange();

    this.drawChart();
  }

  handleStartCtrl(): void {
    const firstSunInRange = moment.utc(this.chartInput.sprintRange[0]).startOf('week').valueOf();
    this.setVisibleData(firstSunInRange);
    this.setChartControlRange();

    this.drawChart();
  }

  handleEndCtrl(): void {
    const startOfEndWeek = moment.utc(this.chartInput.sprintRange[1]).startOf('week').valueOf();
    this.setVisibleData(startOfEndWeek);
    this.setChartControlRange();

    this.drawChart();
  }

  handleRangeCtrl(direction: string): void {
    if (direction === 'next') this.setNextRange();
    if (direction === 'prev') this.setPrevRange();
  }

  async setNextRange() {
    const startOfNextRange = moment.utc(this.visibleChartRange.startEpochRange).clone().add(this.visibleDays.length, 'days').valueOf();
    const endOfNextRange = moment.utc(startOfNextRange).clone().add('day', this.visibleDays.length).valueOf();

    await this.projectScheduleService.updateLocalScheduleData(startOfNextRange, endOfNextRange, this.chartInput.scheduleId, this.subContractorsInput).then(() => {
      this.setVisibleData(startOfNextRange, this.visibleDays.length);
      this.setChartControlRange();
      const steps = (this.projectScheduleService.getLocalScheduleData()[startOfNextRange] || {})['steps'] || [];
      this.updateDefaultViewerState(steps, 'add');
      this.drawChart();

      this.setDataError(false);
    }).catch(() => {
      this.setDataError(true);
    });
  }

  async setPrevRange() {
    const startOfPrevRange = moment.utc(this.visibleChartRange.startEpochRange).clone().subtract(this.visibleDays.length, 'days').valueOf();
    const endOfPrevRange = moment.utc(startOfPrevRange).clone().add('day', this.visibleDays.length).valueOf();

    await this.projectScheduleService.updateLocalScheduleData(startOfPrevRange, endOfPrevRange, this.chartInput.scheduleId, this.subContractorsInput).then(() => {
      this.setVisibleData(startOfPrevRange, this.visibleDays.length);
      this.setChartControlRange();
      this.setDefaultViewerState();
      this.drawChart();

      this.setDataError(false);
    }).catch(() => {
      this.setDataError(true);
    });
  }

  handleUncommitClick(): void {
    const uncommitTasksOutput = {
      rangeStart: Number(this.visibleChartRange.startEpochRange),
      selectedSteps: this.taskSelections.committed
    };

    this.uncommitOutput.emit(uncommitTasksOutput);
  }

  handleCommitClick(): void {
    const commitTasksOutput = {
      rangeStart: Number(this.visibleChartRange.startEpochRange),
      selectedSteps: this.taskSelections.uncommitted,
      blockerSteps: this.blockerSteps
    };

    this.commitOutput.emit(commitTasksOutput);
  }

  updateCommitedSteps(step: IProjectScheduleStep): void {
    const inSprint = this.currSprintStoryIds.includes(step.sprintStoryId);
    if (step.stepStatus === 2 && inSprint) this.commitedSteps[step.id] = step;
    else if (this.commitedSteps[step.id]) delete this.commitedSteps[step.id];
  }
  getFilteredSteps(steps: IProjectScheduleStep[]): IProjectScheduleStep[] {
    const activeFilterType = this.projectSprintService.getActiveFilterType();
    const activeFilters = this.projectSprintService.getActiveFilters();
    let filteredSteps = [];
    switch (activeFilterType) {
      case FilterModelOptions.Activities:
        filteredSteps = activeFilters.length > 0
          ? steps.filter(step => Utils.similarValuesInArrays(step.activities, activeFilters).length > 0)
          : steps;
        break;

      case FilterModelOptions.SubContractor:
        filteredSteps = activeFilters.length > 0
          ? steps.filter(step => activeFilters.includes(step.subContractorId))
          : steps;
        break;

      default:
        filteredSteps = steps;
        break;
    }

    return filteredSteps;
  }

  updateDefaultViewerState(steps: IProjectScheduleStep[], action: string): void {
    const filteredSteps = this.getFilteredSteps(steps);

    if (filteredSteps && filteredSteps.length > 0) {
      filteredSteps.forEach(step => {
        const objectIds = step.objectIds ? step.objectIds : [];
        this.updateCommitedSteps(step);
        objectIds.forEach(id => {
          if (action === 'remove') delete this.defaultViewerState[id];
          if (action === 'add') this.defaultViewerState[id] = id;
        });
      });
    }
  }
  async setMyViewerState() {
    this.forgeViewerService.clearAllThemingColors();
    this.myViewerState = {};

    if (this.taskSelections.all.length > 0) {
      const filteredTasks = this.getFilteredSteps(this.taskSelections.all);
      filteredTasks.forEach(task => {
        const objectIds = task.objectIds ? task.objectIds : [];
        objectIds.forEach(id => {
          if (id) this.myViewerState[id] = id;
        });
      });

      this.passStateToViewer();
    } else {
      await this.setDefaultViewerState();
      this.passStateToViewer();
    }
  }
  setFvObjectState(step: IProjectScheduleStep, fvType?: ForgeViewerType): void {
    const objectIds = step.objectIds ? step.objectIds : [];

    objectIds.forEach(id => {
      if (id) {
        if (this.commitedSteps[step.id]) this.forgeViewerService.setState({ gritObjectId: id, type: fvType ? fvType : ForgeViewerType.Committed });
        else this.forgeViewerService.setState({ gritObjectId: id, type: fvType ? fvType : ForgeViewerType.Default });
      }
    });
  }
  setDefaultViewerState() {
    const activeFilters = this.projectSprintService.getActiveFilters();
    const activeFilterType = this.projectSprintService.getActiveFilterType();

    return new Promise((resolve => {
      // tslint:disable-next-line:max-line-length
      this.projectScheduleService.getScheduleObjects(this.chartInput.scheduleId, activeFilters, activeFilterType, this.visibleViewerRange[0], this.visibleViewerRange[1]).subscribe(
        res => {
          this.defaultViewerState = {};
          if (res.ids) res.ids.map(id => this.defaultViewerState[id] = id);
          this.myViewerState = this.defaultViewerState;

          resolve();
        },
        err => {
          const context: INoficationContext = {
            type: 'object information',
            action: 'get'
          };

          this.notificationService.error(err, context);
          this.errorGettingData = true;

          resolve();
        }
      );
    }));
  }
  passStateToViewer(): void {
    this.forgeViewerService.setViewerState(this.myViewerState);
    if (!Utils.objectIsEmpty(this.myViewerState)) this.forgeViewerService.fitObjectsToView(this.myViewerState);
    Object.keys(this.commitedSteps).forEach(key => this.setFvObjectState(this.commitedSteps[key]));
  }

  checkSprintIsDeletable(): void {
    this.projectSprintService.isSafeToDeleteSprint(this.currSprintData, this.currSprintData.stories);
  }

  async resetAllSelections() {
    this.taskSelections.all = [];
    this.taskSelections.committed = [];
    this.taskSelections.uncommitted = [];
    this.blockerSteps = [];
    this.currentCellIdSelections = [];
    this.rows = this.rows.map(row => ({ ...row, selected: false }));

    D3.selectAll('.x-axis-label').each(function(d) { D3.select(this).classed('label-selected', false); });
    D3.selectAll('.y-axis-label').each(function(d) { D3.select(this).classed('label-selected', false); });
    D3.selectAll('.D3-grid-cell').each(function(d) { D3.select(this).classed('cell-selected', false).classed('cell-selected-y', false).classed('cell-selected-x', false); });
    D3.selectAll('.D3-chart-bar').each(function(d) { D3.select(this).classed('bar-active', false).classed('blocker', false); });

    this.chartSelectionOutput.emit([]);
  }

  resetChartData(): void {
    this.gridData = [];
    this.barData = [];
  }

  setDataError(isError: boolean): void {
    this.errorGettingData = isError;
  }

  setDataAvailable(isAvailable: boolean): void {
    this.chartDataAvailable = isAvailable;
  }

  pageRefresh(): void {
    window.location.reload(true);
  }
}
