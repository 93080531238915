
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IForgeProjectModel, IForgeToken } from '../../models/forge/forge.interface';
import { HttpBackendService } from '../http-backend/http-backend.service';

@Injectable()
export class ForgeService {

  constructor(private httpService: HttpBackendService) { }

  public getModelUrns(projectId: string): Observable<IForgeProjectModel[]> {
    return this.httpService.get('/project/' + projectId + '/models');
  }

  public getTree(projectId: string): Observable<any> {
    return this.httpService.get('/bim360/' + projectId + '/tree');
  }

  public getPDFTree(projectId: string): Observable<any> {
    return this.httpService.get('/bim360/' + projectId + '/PDFtree');
  }

  public getList(accountId: string): Observable<any> {
    return this.httpService.get('/bim360/' + accountId + '/accounts');
  }

  public getAuthUrl(state: string): Observable<any> {
    return this.httpService.get('/bim360/oauth/url?state=' + state);
  }

  public postAuthCode(accountId: string, code: string): Observable<any> {
    return this.httpService.post('/bim360/oauth/code', {accountId, code});
  }

  public removeBim360(bim360accountId: string): Observable<any> {
    return this.httpService.delete(`/bim360/account/${bim360accountId}`);
  }

  public linkFloorPlanPDF(projectId:string, json:any):Observable<any> {
    return this.httpService.post('/bim360/linkFloorPlan?projectId='+projectId,json);
  }

  public getFloorPlanPDFsURL(projectId:string):Observable<any> {
    return this.httpService.get('/bim360/getFloorPlansURL?projectId='+projectId);
  }

  public getAllFloorPlan(projectId:string){
    return this.httpService.get('/bim360/getAllFloorPlan?projectId='+projectId);
  }

  public removeFloorPlanPDF( projectId:string, json:any):Observable<any> {
    return this.httpService.post('/bim360/removeFloorPlan?projectId='+projectId,json);
  }

  public getPDF( projectId:string,link:string):Observable<any>{
    return this.httpService.get('/bim360/'+projectId+'/getPDF?url='+link);
  }
  
  public getProxyData() {
    return {
      csrf: this.httpService.getCsrfToken(),
      server: this.httpService.getApiServer()
    };
  }

  public split(objectId: string, planes: number[][]): Observable<any> {
    return this.httpService.post('/project/model/object/' + objectId + '/split', {planes});
  }
}
