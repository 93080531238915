import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import { ProjectSprintService } from '../../../services/project/project-sprint/project-sprint.service';
import { ProjectService } from '../../../services/project/project.service';
import { Utils } from '../../../utils/utils';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { NotificationService } from '../../../services/notification/notification.service';

@Component({
  selector: 'app-assign-activity-modal',
  templateUrl: './assign-activity-modal.component.html',
  styleUrls: ['./assign-activity-modal.component.scss']
})
export class AssignActivityModalComponent implements OnInit {
  @Input() allActivities: any;
  @Output() autoAssignActivities: EventEmitter<any> = new EventEmitter();

  disabledSubmitBtn: boolean = true;
  objArray: Array<any> = [];
  sprintId: string = '';
  inProgressActivities: any;
  approvedActivities: any;
  curTabIndex: number = 0;

  constructor(
    public activityService: ActivityService,
    public projectSprintService: ProjectSprintService,
    public projectService: ProjectService,
    public notificationService: NotificationService
  ) { }

  async ngOnInit() {
    const inProgressActivities = [];
    const approvedActivities = [];

    this.allActivities.forEach((item) => {
      item.actualFinishDate === undefined ? inProgressActivities.push(item) : approvedActivities.push(item);
    });

    this.inProgressActivities = inProgressActivities;
    this.approvedActivities = approvedActivities;

    const projectId = this.projectService.currentProject.id;

    await this.projectSprintService.getSprintList(projectId).subscribe(res => {
      this.objArray = res.filter(obj => Utils.isEmpty(obj.endDate));
      const defaultSprint = this.objArray.find(a => a.is_default);
      if (!Utils.isEmpty(defaultSprint)) {
        this.approvedActivities.forEach(activity => {
          if (activity.actualFinishDate) {
            activity.sprintId = defaultSprint.id;
          }
        });
      }
      this.disabledSubmitBtn = this.inProgressActivities.every(a => a.sprintId) ? false : true;
    }, error => {
      const context: INoficationContext = {
        type: 'sprint list',
        action: 'get'
      };

      this.notificationService.error(error, context);
    });
  }

  assignActivities() {
    const assignActivities = [];
    this.inProgressActivities.forEach((item) => {
      assignActivities.push(item);
    });
    this.approvedActivities.forEach((item) => {
      assignActivities.push(item);
    });
    this.autoAssignActivities.emit(assignActivities);
  }

  selectSprint(): void {
    this.disabledSubmitBtn = this.inProgressActivities.every(a => a.sprintId) ? false : true;
  }
}
