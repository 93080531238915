import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

import { IMenuItems } from '../../models/menu/menu.interface';

import { HttpBackendService } from '../http-backend/http-backend.service';
import { Utils } from '../../utils/utils';

@Injectable()
export class MenuService {
  mainMenuOpen: boolean = false;
  allowSubMenuNavigation: boolean = false;

  constructor(private _http: HttpBackendService, private ngZone: NgZone) {}

  public sendFeedback(json: any): Observable<any> {
    return this._http.post('/supportIssue', json);
  }

  async setAllowSubMenuNav(value) {
    await Utils.sleep(0, this.ngZone);
    this.allowSubMenuNavigation = value;
  }

  async checkMainMenuState() {
    await Utils.sleep(0, this.ngZone);
    this.mainMenuOpen = localStorage.getItem('showMainMenu')
      ? JSON.parse(localStorage.getItem('showMainMenu'))
      : true;
  }

  createProjectSlideoutMenu(menuTitle: string, routeName: string, items: any, routeParam?: string) {
    const mainMenuItems = [];
    const groups = Utils.groupBy(items, item => item.accountName);

    let previouslyExpandedAccounts: any = localStorage.getItem('previouslyExpandedAccounts') || '[]';
    previouslyExpandedAccounts = JSON.parse(previouslyExpandedAccounts);

    groups.forEach(group => {
      let accountDisabled = group[1][0].accountDisabled;
      let accountDisabledMessage;
      if (!accountDisabled) {
        accountDisabled = group[1][0].accountTrialExpires ? Utils.getCurrentDate() > group[1][0].accountTrialExpires : false;
        accountDisabledMessage = accountDisabled ? 'Trial Expired' : '';
      } else {
        accountDisabledMessage = 'Account Disabled';
      }
      mainMenuItems.push({
        accountName: group[0],
        accountDisabled: accountDisabled,
        accountDisabledMessage: accountDisabledMessage,
        projects: group[1].map(project => ({
          name: project.name,
          route: routeParam
            ? [routeName, project.id, routeParam]
            : [routeName, project.id],
        })),
        showProjects: previouslyExpandedAccounts.includes(group[0]),
      });
    });

    mainMenuItems.forEach(item => {
      item.projects = Utils.sortByString(item.projects, 'name');
    });

    return {
     title: menuTitle,
     menuItems: mainMenuItems
    };
  }

  createSlideoutMenu(menuTitle: string, routeName: string, items: any, routeParam?: string) {
    const menuItems = [];
    items.forEach(item => {
      let accountDisabled = item.accountDisabled;
      let accountDisabledMessage;
      if (!accountDisabled) {
        accountDisabled = item.accountTrialExpires ? Utils.getCurrentDate() > item.accountTrialExpires : false;
        accountDisabledMessage = accountDisabled ? 'Trial Expired' : '';
      } else {
        accountDisabledMessage = 'Account Disabled';
      }
      menuItems.push({
        item: item.name,
        route: routeParam
          ? [routeName, item.id, routeParam]
          : [routeName, item.id],
        active: false,
        accountDisabled: accountDisabled,
        accountDisabledMessage: accountDisabledMessage
      });
    });

    return {
     title: menuTitle,
     menuItems: menuItems
    };
  }

  setNavIconState(currentUrl: string, menuItems: IMenuItems[]): void {
    let currentPage = currentUrl.split('/')[3];
    if (currentPage && currentPage.includes('?'))  currentPage = currentPage.split('?')[0];
    menuItems.forEach(item => {
      let active = false;
      if (!Utils.isEmpty(item.routes)) {
        item.routes.forEach(route => {
          if (currentPage === route) {
            active = true;
            if (!Utils.isEmpty(item.subMenuItems)) {
              const sub = item.subMenuItems.find(subItem => subItem.route === route);
              if (sub) sub.active = true;
            }
          } else if (currentPage === 'settings' && route.includes(currentPage)) {
            active = true;
            if (!Utils.isEmpty(item.subMenuItems)) {
              const sub = item.subMenuItems.find(subItem => subItem.route === route);
              if (sub) sub.active = true;
            }
          } else {
            if (!Utils.isEmpty(item.subMenuItems)) {
              const sub = item.subMenuItems.find(subItem => subItem.route === route);
              if (sub) sub.active = false;
            }
          }
        });
      }
      item.active = active;
    });
  }

  resetNavIconState(menuItems: IMenuItems[]) {
    menuItems.forEach(item => {
      item.active = false;
      if (!Utils.isEmpty(item.subMenuItems)) {
        item.subMenuItems.forEach(subItem => {
          subItem.active = false;
        });
      }
    });
  }
}
