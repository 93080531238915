import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IAccount } from '../../models/account/account.interface';

import { AccountService } from '../../services/account/account.service';
import { INoficationContext } from '../../models/notification/notification.interface';
import { NotificationService } from '../../services/notification/notification.service';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
  selector: 'app-accounts-create',
  templateUrl: './accounts-create.component.html',
  styleUrls: ['./accounts-create.component.css'],
  providers: [
    AccountService
  ]
})
export class AccountsCreateComponent implements OnInit {

  public entry = { name: '' };
  message: any;

  createButtonDisabled: boolean = false;

  constructor(
    private accountService: AccountService,
    private notificationService: NotificationService,
    public activeModal: NgbActiveModal,
    private segmentService: SegmentService
  ) { }

  // tslint:disable-next-line:no-empty
  ngOnInit() {
  }

  submitAccount() {
    this.createButtonDisabled = true;
    this.accountService.createAccount(this.entry).subscribe(
      success => {
        this.createButtonDisabled = false;
        this.activeModal.close(success);
        this.segmentService.track('Account Create', {accountName: this.entry.name});
      },
      err => {
        this.createButtonDisabled = false;
        const context: INoficationContext = {
          type: 'account',
          action: 'create'
        };

        this.notificationService.error(err, context);
      });
  }

}
