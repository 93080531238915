import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { Utils } from '../../utils/utils';

import { ChartService } from '../../services/chart/chart.service';
import { TranslationService } from '../../services/translation/translation.service';

import { GritColors, StepStatusColor } from '../../utils/enums/hex-color.enum';

import * as D3 from 'd3';

@Component({
  selector: 'app-project-dashboard-plan-complete',
  templateUrl: './project-dashboard-plan-complete.component.html',
  styleUrls: ['./project-dashboard-plan-complete.component.scss']
})
export class ProjectDashboardPlanCompleteComponent implements OnInit, OnChanges {
  @Input() chartDataInput: any;

  @ViewChild('ppcWrapper') ppcWrapper;
  @ViewChild('chartContainer') chartContainer;
  @ViewChild('chartLinesContainer') chartLinesContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('yAxis') yAxis;

  loadingMessage: string = 'loading';
  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  noData: boolean = true;
  formattedChartData: any[] = [];

  chartHeight: number = 300;
  chartWidth: number;
  chartOffset: number = 50;
  chartTransform: string;
  xAxisTransform: string;
  allXaxisValues: string[];
  allYaxisValues: number[];
  chartLegendItems: any[] = [
    {
      name: 'active',
      color: StepStatusColor.selected
    },
    {
      name: 'completed',
      color: GritColors.dkGray
    }
  ];
  xScale: D3.scaleOrdinal<any> = null;
  yScale: D3.ScaleLinear<number, number> = null;

  constructor(private _chartService: ChartService) {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (this.chartDataInput) {
      this.setupData();

      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  async setupData() {
    const sprintDataAvailable = !Utils.isEmpty(this.chartDataInput);

    if (sprintDataAvailable) {
        this.setupChart();

        this.noData = false;
    } else {
        this.noData = true;
    }
  }

  /**
   * setupChart - call methods in order to build D3 chart
   */
  async setupChart() {
    this.formatData();
    if (this.formattedChartData.length > 0) {
      await this.createScalesAndDimensions();
      this.setupAxes();
      this.setupLineChartData();
    }
  }

  formatData(): void {
    if (this.chartDataInput.length > 0) {
      this.chartDataInput.forEach((item, i) => {
          item.name = item.name + i; // D3 scaleOrdinal aggregates data with the same value, this allows each name to be unique so the ordinal scale doesn't do that
          this.formattedChartData.push(item);
        }
      );
    }
  }

  /**
   * createScalesAndDimensions - sets D3 scale and chart dimensions
   */
  createScalesAndDimensions() {
    this.allXaxisValues = this.formattedChartData.map(sprint => sprint.name); // add empty values at start and end to give horizontal buffer to chart

    // Dimensions
    // const scalePadding = this.chartPadding * 2; // top+botom padding & left&right padding
    const chartMinWidth = document.getElementById('ppc-wrapper').offsetWidth;
    const xAxisWidth = (this.allXaxisValues.length * 100) - (this.chartOffset * 2); // 100 is x-axis tick spacing
    this.chartWidth = xAxisWidth < chartMinWidth ? chartMinWidth : xAxisWidth;
    this.chartTransform = `translate(${this.chartOffset}, ${this.chartOffset})`;
    this.xAxisTransform = `translate(0, ${this.chartHeight})`;
    const xScaleRange = this.buildXscaleRange();

    // Scales
    if (!Utils.isEmpty(this.allXaxisValues)) {
      this.xScale = D3.scaleOrdinal()
        .range(xScaleRange)
        .domain(this.allXaxisValues);

      this.yScale = D3.scaleLinear()
        .range([(this.chartHeight), 0])
        .domain([0, 1]);

    } else {
      this.noData = true;
    }
  }

  /**
   * buildXscaleRange - builds the necessary xScale range array based on the number of values in allXaxisValues (ordinal domain/range values are 1 to 1)
   */
  buildXscaleRange(): string[] {
    const xScaleRange = [];
    const rangeDivision =  this.chartWidth / this.allXaxisValues.length;
    for (let i = 0; i < this.chartWidth; i += rangeDivision) xScaleRange.push(i);

    return xScaleRange;
  }

  async setupAxes() {
    await this.drawXaxis(this.allXaxisValues);
    await this.drawYaxis();
  }

  async drawYaxis() {
    const yAxis = D3.axisLeft()
      .scale(this.yScale)
      .ticks(10)
      .tickFormat(D3.format(',.0%'))
      .tickSize(5);

    D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis')
      .call(yAxis);
  }

  async drawXaxis(xAxisValues: string[]) {
    const xAxis = D3.axisBottom()
      .scale(this.xScale)
      .tickSize(5)
      .tickPadding(10)
      .tickFormat(d => d.slice(0, -1)) // we want to remove the added index string from display that we added if formatData()
      .ticks(xAxisValues.length);

    if (!Utils.isEmpty(xAxisValues)) {
      D3.select(this.xAxis.nativeElement)
        .attr('class', 'x-axis')
        .call(xAxis);
    }
  }

  /**
   * setupLineChartData - adds D3 Line data to sprint items
   */
  async setupLineChartData() {
    const getLine = D3.line()
      .x((d) => this.xScale(d.name))
      .y((d) => this.yScale(d.activities.percent));

    this.formattedChartData.forEach((sprint, index) => {
      const point1 = sprint;
      const point2 = this.formattedChartData[index + 1] ? this.formattedChartData[index + 1] : null;
      if (point2) {
        const linePoints = [point1, point2];
        sprint['d3Line'] = getLine(linePoints);
      }
    });

    this.drawLinesAndCircles(this.formattedChartData);
  }

  async drawLinesAndCircles(sprints) {
    const linesContainer = D3.select(this.chartLinesContainer.nativeElement)
      .selectAll('g')
      .data(sprints)
      .enter();

    linesContainer
      .append('path')
      .attr('class', 'chart-line')
      .attr('d', (d) => d.d3Line)
      .attr('stroke', GritColors.dkGray); // $grit-dk-gray

    linesContainer
      .append('circle')
      .attr('class', 'chart-circle')
      .attr('r', '3px')
      .attr('fill', (d) => d.isActive ? StepStatusColor.selected : GritColors.dkGray) // $grit-dk-gray
      .attr('cx', (d) => this.xScale(d.name))
      .attr('cy', (d) => this.yScale(d.activities.percent))
      .on('mouseover', (d) => this.handlePointHover(d, 'mouseover'))
      .on('mouseout', (d) => this.handlePointHover(d, 'mouseout'));
  }

  handlePointHover(pointData: any, action: string): void {
    const chartDimensions = this.ppcWrapper.nativeElement.getBoundingClientRect();
    const distFromRight = Math.abs(D3.event.pageX - chartDimensions.right);
    const distFromBottom = chartDimensions.bottom - D3.event.pageY;
    if (action === 'mouseover') {
      const ttBuffer = 10;
      const ttHeight = 150;
      const ttWidth = 200;
      const adjustFromRight = distFromRight < (ttWidth + ttBuffer) ? true : false;
      const adjustFromBottom = distFromBottom < (ttHeight + ttBuffer) ? true : false;
      const ttPosition = {
        top: adjustFromBottom ? ((D3.event.pageY + ttBuffer) - ttHeight) : D3.event.pageY + ttBuffer,
        left: adjustFromRight ? ((D3.event.pageX - ttBuffer) - ttWidth) : D3.event.pageX + ttBuffer
      };

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left + 'px')
        .style('top', ttPosition.top + 'px')
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(pointData));

    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  drawTooltip(data: any) {
    let tooltip = ``;
    if (data.activities.completed) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('tasks_completed') +
      `: </span><span class='value'>` + data.activities.completed + `</span></div>`;
    if (data.activities.total) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('tasks_planned') +
      `: </span><span class='value'>` + data.activities.total + `</span></div>`;
    if (data.activities.percentDisplay) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('percent_complete') +
      `: </span><span class='value'>` + data.activities.percentDisplay + `</span></div>`;
    if (data.sprintHrs.completed) tooltip += `<div class='data-divider'></div><div class='data-point'><span class='label'>` + TranslationService.translate('hours_completed') +
      `: </span><span class='value'>` + data.sprintHrs.completed + ` hrs</span></div>`;
    if (data.sprintHrs.total) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('hours_planned') +
      `: </span><span class='value'>` + data.sprintHrs.total + ` hrs</span></div>`;
    if (data.sprintHrs.percentDisplay) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('percent_complete') +
      `: </span><span class='value'>` + data.sprintHrs.percentDisplay + `</span></div>`;
    return tooltip;
  }
}
