export enum GritColors {
    red = '#e12727',
    ltGray = '#eff3f4',
    medGray = '#525266',
    dkGray = '#14151c'
}

export enum StepStatusColor {
    committed = '#a020f0',
    selected = '#3399ff',
    blocker = '#e12727'
}

export enum StepStatusBarColor {
    Planning = '#a4a4b2',
    Scheduled = '#a4a4b2',

    Planned = '#a4a4b2',
    Recommended = '#a4a4b2',
    Backlog = '#a4a4b2',
    Committed = '#a020f0',
    InProgress = '#029359',
    Completed = '#14151c',
}

export enum CriticalityColor {
    c1 = '#029359',
    c2 = '#B4E560',
    c3 = '#E7F738',
    c4 = '#F28F32',
    c5 = '#E5221B'
}

export enum TaskStatusColor {
    scheduled = '#a4a4b2',
    committed = '#a020f0',
    inProgress = '#029359',
    completed = '#14151c',
}

export enum CostColor {
    Equipment = '#e8764c',
    Labor = '#1fb5D3',
    Material = '#00b55a',
}
