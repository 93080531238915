export enum StepStatus {
    Planned = 0,
    Recommended = 1,
    Backlog = 1,
    Committed = 2,
    InProgress = 3,
    Completed = 4,
    Updated = 5,
    NotCompleted = 6 // only used for story status
}
