import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ICompleteOutput, INotCompleteOutput, ISprintStoryResult, IStoryEquipment, IStoryMaterial, SprintStoryResultStatus, IInProgressOutput, IStoryLabor } from '../../../models/project/project-sprint/project-sprint.interface';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-sprint-result-modal',
  templateUrl: './sprint-result-modal.component.html',
  styleUrls: ['./sprint-result-modal.component.scss']
})
export class SprintResultModalComponent implements OnInit, OnDestroy {

  @Input() objectIds: string[];
  @Input() taskId: string;
  @Input() completeFormInput: {actualCrewSize: number, actualEquipmentIds: string[], actualMaterialIds: string[],actualLaborIds: string[], startDate: any, actualDurationHours: any};
  @Input() inProgressFormInput: IInProgressOutput;
  @Input() ssResults: ISprintStoryResult[];
  @Input() moveToOptions: Array<{id: string, name: string}>;
  @Input() modalType: string;

  @Output() notCompleteFormOutput: EventEmitter<INotCompleteOutput> = new EventEmitter();
  @Output() completeFormOutput: EventEmitter<ICompleteOutput> = new EventEmitter();
  @Output() inProgressFormOutput: EventEmitter<IInProgressOutput> = new EventEmitter();

  get resultControl() { return this.formInput.get('ssResults'); }
  get moveToControl() { return this.formInput.get('moveTo'); }
  get notes() { return this.formInput.get('notes'); }

  completeForm: boolean = false;
  isApprovedModal: boolean = false;
  isInProgressModal: boolean = false;
  completeFormDataIn: {actualCrewSize?: number, startDate?: any};

  storyEquipment: IStoryEquipment[] = [];

  formInput: FormGroup;
  resultOptions: Observable<ISprintStoryResult[]>;
  moveOptions: Observable<Array<{id: string, name: string}>>;
  displayFn = Utils.defaultDisplayFn;

  possibleResults: ISprintStoryResult[] = [];
  possibleMoveOptions: Array<{id: string, name: string}> = [];

  curTabIndex: number = 0;
  curGeneralInfo: ICompleteOutput;
  curActualLabor: IStoryLabor[];
  curActualMats: IStoryMaterial[];
  curActualEquipment: IStoryEquipment[];

  curInProgressInfo: IInProgressOutput;

  generalTabInvalid: boolean = true;
  equipmentTabInvalid: boolean = false;
  matTabInvalid: boolean = true;
  laborTabInvalid: boolean = true;
  inProgressTabInvalid: boolean = true;

  constructor(
    private formBuilderService: FormBuilder
  ) {/*EMPTY*/}

  async ngOnInit() {
    if(this.modalType === 'InProgress'){
      this.curInProgressInfo = this.inProgressFormInput;
      this.isInProgressModal = true;
      return
    }
    if(this.modalType === 'Approved'){
      this.isApprovedModal = true;
    }

    if (this.completeFormInput && this.completeFormInput.actualCrewSize !== null) {

      this.completeForm = true;
      this.completeFormDataIn = {actualCrewSize: this.completeFormInput.actualCrewSize};
      if(this.completeFormInput.actualDurationHours) this.completeFormDataIn['actualDurationHours'] = this.completeFormInput.actualDurationHours;
      if(this.completeFormInput.startDate) this.completeFormDataIn['startDate'] = this.completeFormInput.startDate;
      window.addEventListener('keydown', this.keyPress);
    } else {
      this.possibleResults = this.ssResults.filter(item => item.status !== SprintStoryResultStatus.COMPLETE);
      this.possibleResults = Utils.sortByString(this.possibleResults, 'name');
      this.possibleMoveOptions = this.moveToOptions;
      this.formInput = this.buildNotCompleteFormInput();
      this.setupResultAutoInput();
      this.setupMoveToAutoInput();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (!this.getTabsInvalid()) {
        this.isApprovedModal ? this.submitApproveStory() : this.submitCompleteStory();
      } else {
        if (this.curTabIndex < 3) {
          this.advanceTab();
        }
      }
    }
  }

  onSubmitIncomplete(formOutput) {
    const formValues = formOutput.getRawValue();
    const output: INotCompleteOutput = {
      result: formValues.ssResults.id,
      notes: formValues.notes,
      moveTo: formValues.moveTo.id
    };
    this.notCompleteFormOutput.emit(output);
  }

  buildNotCompleteFormInput(): FormGroup {
    const formInput = this.formBuilderService.group({
      notes: [''],
      ssResults: [null],
      moveTo: [null]
    });
    formInput.controls['ssResults'].setValidators([Validators.required, this.validateAutoVal()]);
    formInput.controls['moveTo'].setValidators([Validators.required, this.validateAutoVal()]);
    return formInput;
  }

  validateAutoVal(): ValidatorFn {
    return (control: FormControl): {} => {
      const invalid = control.value && control.value.id ? false : true;
      if (invalid) return { invalidResult: true };
      return {};
    };
  }

  setupResultAutoInput() {
    this.resultOptions = this.resultControl.valueChanges.pipe(
      startWith<string | ISprintStoryResult>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.resultSearch(name) : this.possibleResults.slice(0, Math.min(this.possibleResults.length, 50)))
    );
  }

  setupMoveToAutoInput() {
    this.moveOptions = this.moveToControl.valueChanges.pipe(
      startWith<string | {id: string, name: string}>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.moveToSearch(name) : this.possibleMoveOptions.slice(0, Math.min(this.possibleMoveOptions.length, 50)))
    );
  }

  resultSearch(name: string): ISprintStoryResult[] {
    const searchQuery = name.toLowerCase();
    const searchRes = this.possibleResults.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  moveToSearch(name: string): Array<{id: string, name: string}> {
    const searchQuery = name.toLowerCase();
    const searchRes = this.possibleMoveOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getTabsInvalid() {
    if(this.isInProgressModal){
      return this.inProgressTabInvalid;
    } else
    return this.generalTabInvalid || this.matTabInvalid || this.equipmentTabInvalid || this.laborTabInvalid;
  }

  advanceTab() {
    this.curTabIndex++;
  }

  submitCompleteStory() {
    const ssrId = this.ssResults.find(item => item.name === 'Complete').id;
    const output: ICompleteOutput = {
      result: ssrId,
      notes: this.curGeneralInfo.notes,
      actualCrewSize: this.curGeneralInfo.actualCrewSize,
      actualDuration: this.curGeneralInfo.actualDuration,
      startDate: this.curGeneralInfo.startDate,
      completionDate: this.curGeneralInfo.completionDate,
      actualEquipment: this.curActualEquipment,
      actualMaterials: this.curActualMats,
      actualLabors: this.curActualLabor
    };
    this.completeFormOutput.emit(output);
  }

  submitApproveStory() {
    const ssrId = this.ssResults.find(item => item.name === 'Complete').id;
    const output: ICompleteOutput = {
      result: ssrId,
      notes: this.curGeneralInfo.notes,
      actualCrewSize: this.curGeneralInfo.actualCrewSize,
      actualDuration: this.curGeneralInfo.actualDuration,
      startDate: this.curGeneralInfo.startDate,
      completionDate: this.curGeneralInfo.completionDate,
      actualEquipment: this.curActualEquipment,
      actualMaterials: this.curActualMats,
      actualLabors: this.curActualLabor,
      isApproved: true
    };
    this.completeFormOutput.emit(output);
  }

  submitInProgressStory(){
    if(this.inProgressTabInvalid) return;
    this.inProgressFormOutput.emit(this.curInProgressInfo)
  }

  completeFormUpdate(event) {
    this.generalTabInvalid = !event.valid;
    this.curGeneralInfo = event.data;
  }

  materialsUpdate(event) {
    this.matTabInvalid = !event.valid;
    this.curActualMats = event.data;
  }

  equipmentUpdate(event) {
    this.equipmentTabInvalid = !event.valid;
    this.curActualEquipment = event.data;
  }

  laborUpdate(event){
    this.laborTabInvalid = !event.valid;
    this.curActualLabor = event.data;
  }

  inProgressFormUpdate(event) {
    this.inProgressTabInvalid = !event.valid;
    this.curInProgressInfo = event.data;
  }
}
