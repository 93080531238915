import { Injectable } from '@angular/core';

import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectService } from '../project.service';
import { TranslationService } from '../../translation/translation.service';

import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { ISprintBoardItemResponse, ITransformedSprintBoardData, ITransformedSprintBoardItem } from '../../../models/project/project-sprint/project-sprint-board.interface';
import { ISubContractor } from '../../../models/subcontractor/subcontractor.interface';

import { MakeReadyStatus } from '../../../utils/enums/make-ready-status.enum';
import { SprintStoryResult } from '../../../utils/enums/sprint-story-result.enum';
import { StoryStatus } from '../../../utils/enums/story-status.enum';
import { Utils } from '../../../utils/utils';
import { ProjectSprintService } from '../project-sprint/project-sprint.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class ProjectSprintBoardService {

  sprintBoardData: ISprintBoardItemResponse[] = new Array();
  sprintBoardColumnPageSize: number = 25;
  sprintBoardPagingData: ITransformedSprintBoardData;
  completeSprintMode: boolean = false;

  constructor(
    private projectService: ProjectService,
    private projectMaterialService: ProjectMaterialService,
    private projectEquipmentService: ProjectEquipmentService,
    public projectSprintService: ProjectSprintService,
    private formBuilderService: FormBuilder
  ) {}

  sortData(data: any[]): any[] {
    return data.sort( (a, b) => {
      const x = a['scheduledStart'];
      const y = b['scheduledStart'];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getTMinusHours(sprintItem: ISprintBoardItemResponse): string {
    let tMinusDisplay;
    if (sprintItem.scheduledStart) {
      tMinusDisplay = sprintItem.scheduledStart <= Utils.getCurrentDate()
        ? `T+` + moment(Utils.getCurrentDate()).diff(moment(sprintItem.scheduledStart), 'days')
        : `T-` + moment(sprintItem.scheduledStart).diff(moment(Utils.getCurrentDate()), 'days');
      sprintItem['pastDue'] = sprintItem.scheduledStart <= Utils.getCurrentDate();
    }

    return tMinusDisplay;
  }

  transformSubContractorsList(subContractors: ISubContractor[]): IDropdownItem[] {
    const listItems: IDropdownItem[] = [];
    subContractors = Utils.sortByString(subContractors, 'name');
    subContractors.forEach(item => {
      listItems.push({value: item.id, label: item.name});
    });

    return listItems;
  }

  transformNotCompleteReasonList(): IDropdownItem[] {
    const listItems: IDropdownItem[] = [
      {label: 'Coordination', value: String(SprintStoryResult.Coordination)},
      {label: 'Equipment', value: String(SprintStoryResult.Equipment)},
      {label: 'Information', value: String(SprintStoryResult.Information)},
      {label: 'Labor', value: String(SprintStoryResult.Labor)},
      {label: 'Material', value: String(SprintStoryResult.Material)},
      {label: 'Weather', value: String(SprintStoryResult.Weather)},
      {label: 'Other', value: String(SprintStoryResult.Other)},
    ];

    return listItems;
  }

  transformSprintBoardData(data: ISprintBoardItemResponse[]): ITransformedSprintBoardData {
    const sortedData = this.sortData(data);

    // reset all display flags
    this.sprintBoardData.map(item => item.displayedOnBoard = false);

    const transformedData = {
      backlog: {
        active: false,
        items: [],
        currentPage: 1
      },
      committed: {
        active: false,
        items: [],
        currentPage: 1
      },
      inProgress: {
        active: false,
        items: [],
        currentPage: 1
      },
      notCompleted: {
        active: false,
        items: [],
        currentPage: 1
      },
      completed: {
        active: false,
        items: [],
        currentPage: 1
      },
      approved: {
        active: false,
        items: [],
        currentPage: 1
      }
    };

    if (Utils.isEmpty(this.sprintBoardPagingData)) {
      this.sprintBoardPagingData = {
        backlog: {
          active: false,
          items: [],
          currentPage: 1
        },
        committed: {
          active: false,
          items: [],
          currentPage: 1
        },
        inProgress: {
          active: false,
          items: [],
          currentPage: 1
        },
        notCompleted: {
          active: false,
          items: [],
          currentPage: 1
        },
        completed: {
          active: false,
          items: [],
          currentPage: 1
        },
        approved: {
          active: false,
          items: [],
          currentPage: 1
        }
      };
    } else {
      // Reset lists so they are empty to start
      this.sprintBoardPagingData.backlog.items = [];
      this.sprintBoardPagingData.committed.items = [];
      this.sprintBoardPagingData.completed.items = [];
      this.sprintBoardPagingData.notCompleted.items = [];
      this.sprintBoardPagingData.inProgress.items = [];
      this.sprintBoardPagingData.approved.items = [];
    }

    let backlogCounter = 0;
    let committedCounter = 0;
    let inProgressCounter = 0;
    let notCompletedCounter = 0;
    let completedCounter = 0;
    let approvedCounter = 0;

    sortedData.forEach(item => {
      item['active'] = false;
      item['isPrerequisite'] = false;
      item['isSuccessor'] = false;
      item['isDim'] = false;
      item['blocked'] = false;
      item['blocker'] = false;
      item.tMinus = this.getTMinusHours(item);

      switch (item.storyStatus) {
        case StoryStatus.Backlog:
          item['type'] = TranslationService.translate('backlog');
          item['color'] = '#A4a4B2';
          if (backlogCounter < (this.sprintBoardPagingData.backlog.currentPage * this.sprintBoardColumnPageSize)) {
            transformedData.backlog.items.push(item);
            backlogCounter++;
            if (!this.completeSprintMode) this.sprintBoardData.find(sprintItem => item.taskId === sprintItem.taskId).displayedOnBoard = true;
          }
          this.sprintBoardPagingData.backlog.items.push(item as ITransformedSprintBoardItem);
          break;
        case StoryStatus.Committed:
          item['type'] = TranslationService.translate('committed');
          item['color'] = '#A020F0';
          if (committedCounter < (this.sprintBoardPagingData.committed.currentPage * this.sprintBoardColumnPageSize)) {
            transformedData.committed.items.push(item);
            committedCounter++;
            this.sprintBoardData.find(sprintItem => item.taskId === sprintItem.taskId).displayedOnBoard = true;
          }
          this.sprintBoardPagingData.committed.items.push(item as ITransformedSprintBoardItem);
          break;
        case StoryStatus.InProgress:
          item['type'] = TranslationService.translate('in_progress');
          item['color'] = '#029359';
          if (inProgressCounter < (this.sprintBoardPagingData.inProgress.currentPage * this.sprintBoardColumnPageSize)) {
            transformedData.inProgress.items.push(item);
            inProgressCounter++;
            this.sprintBoardData.find(sprintItem => item.taskId === sprintItem.taskId).displayedOnBoard = true;
          }
          this.sprintBoardPagingData.inProgress.items.push(item as ITransformedSprintBoardItem);
          break;
        case StoryStatus.NotCompleted:
          item['type'] = TranslationService.translate('not_completed');
          item['color'] = '#A4A4B2';
          if (notCompletedCounter < (this.sprintBoardPagingData.notCompleted.currentPage * this.sprintBoardColumnPageSize)) {
            transformedData.notCompleted.items.push(item);
            notCompletedCounter++;
            if (this.completeSprintMode) this.sprintBoardData.find(sprintItem => item.taskId === sprintItem.taskId).displayedOnBoard = true;
          }
          this.sprintBoardPagingData.notCompleted.items.push(item as ITransformedSprintBoardItem);
          break;
        case StoryStatus.Completed:
          item['type'] = TranslationService.translate('completed');
          item['color'] = '#14151C';
          if (completedCounter < (this.sprintBoardPagingData.completed.currentPage * this.sprintBoardColumnPageSize)) {
            transformedData.completed.items.push(item);
            completedCounter++;
            this.sprintBoardData.find(sprintItem => item.taskId === sprintItem.taskId).displayedOnBoard = true;
          }
          this.sprintBoardPagingData.completed.items.push(item as ITransformedSprintBoardItem);
          break;
        case StoryStatus.Approved:
          item['type'] = TranslationService.translate('approved');
          item['color'] = '#14151C';
          if (approvedCounter < (this.sprintBoardPagingData.approved.currentPage * this.sprintBoardColumnPageSize)) {
            transformedData.approved.items.push(item);
            approvedCounter++;
            this.sprintBoardData.find(sprintItem => item.taskId === sprintItem.taskId).displayedOnBoard = true;
          }
          this.sprintBoardPagingData.approved.items.push(item as ITransformedSprintBoardItem);
          break;
        default:
          break;
      }
    });

    return this.checkForBlockers(transformedData);
  }

  checkForBlockers(data: ITransformedSprintBoardData): ITransformedSprintBoardData {
    let stepDataCache = {};

    const masterDataList = this.sprintBoardData;
    data.backlog.items.forEach(item => {
      // reset block status
      item.blocked = false;

      item.prerequisiteObjectIds.forEach((prereqId, prereqIndex) => {
        const prereqStep = masterDataList.find(step => {
          if (!stepDataCache[step.stepId]) {
            stepDataCache[step.stepId] = {};
            if (step.objectIds) {
              step.objectIds.forEach((oid, idx) => stepDataCache[step.stepId][oid] = idx);
            }
          }

          if (step.objectIds) {
            const objectIndex = stepDataCache[step.stepId][prereqId];
            return objectIndex != undefined && step.activities[objectIndex] === item.prerequisiteActivities[prereqIndex];
          } else {
            return false;
          }
        });
        if (!Utils.isEmpty(prereqStep)) {
          if (prereqStep.storyStatus <= StoryStatus.Backlog) {
            item.blocked = true;
          }
        }
      });
    });

    return data;
  }

  getHiddenBlockers(prereqId: string, prereqIndex: number, sprintBoardItem: ITransformedSprintBoardItem, columnData: ITransformedSprintBoardItem[]): ITransformedSprintBoardItem[] {
    const filteredData = this.sprintBoardData.filter(item => item.storyStatus === StoryStatus.Backlog);
    const prereqSprintBoardItem = filteredData.find(item => {
      const objectIndex = item.objectIds.indexOf(prereqId);
      if (objectIndex !== -1) {
        return item.activities[objectIndex] === sprintBoardItem.prerequisiteActivities[prereqIndex];
      } else {
        return false;
      }
    }) as ITransformedSprintBoardItem;

    if (!Utils.isEmpty(prereqSprintBoardItem)) {
      prereqSprintBoardItem.active = false;
      prereqSprintBoardItem.type = 'backlog';
      prereqSprintBoardItem.blocker = true;
      const index = columnData.findIndex(item => item.taskId === sprintBoardItem.taskId);
      columnData.splice(index + 1, 0, prereqSprintBoardItem);
    }

    return columnData;
  }

  loadMoreData(columnData: ITransformedSprintBoardItem[], columnType: string): ITransformedSprintBoardItem[] {
    let counter: number = this.sprintBoardPagingData[columnType].currentPage * this.sprintBoardColumnPageSize;
    this.sprintBoardPagingData[columnType].currentPage ++;

    // keep adding items until the end of the list is reached
    for ( ; counter < (this.sprintBoardPagingData[columnType].currentPage * this.sprintBoardColumnPageSize) && counter < this.sprintBoardPagingData[columnType].items.length; counter++) {
      this.sprintBoardPagingData[columnType].items[counter]['active'] = false;
      this.sprintBoardPagingData[columnType].items[counter]['type'] = columnType;
      this.sprintBoardPagingData[columnType].items[counter].displayedOnBoard = true;
      columnData.push(this.sprintBoardPagingData[columnType].items[counter]);
    }
    return columnData;
  }

  checkColumnNeedsPaging(columnType: string) {
    if (this.sprintBoardPagingData[columnType].items.length > this.sprintBoardColumnPageSize) {
      return true;
    } else {
      return false;
    }
  }

  loadSprintBoardData(data: any[], subcontractorList: any[], clear?) {
    if (clear) {
      this.sprintBoardData = [];
    }
    this.sprintBoardData = this.sprintBoardData.concat(this.initializeStoryData(data, subcontractorList));
  }

  // Several items may not be present. Set default values and build data structure to match.
  initializeStoryData(rawData: any[], subcontractorList: any[]): ISprintBoardItemResponse[] {
    const sprintBoardData: ISprintBoardItemResponse[] = [];
    const userPermission = ProjectService.userPermission;
    const hasEditPrivilege = userPermission.admin || userPermission.edit;
    const isSubContractor = !userPermission.gc;
    const userSubcontractorId = userPermission.subContractorId;
    const projectId = this.projectService.currentProject.id;

    if (Utils.isEmpty(subcontractorList)) return [];

    // tslint:disable-next-line:cyclomatic-complexity
    rawData.forEach(item => {
      let subInfo;
      if (item.hasOwnProperty('subContractorId')) {
        subInfo = subcontractorList.find(subContractor => subContractor.id === item.subContractorId);
      } else if (item.hasOwnProperty('subContractor')) {
        subInfo = item.subContractor;
      }

      // User will NOT be readonly for an item in the following situations:
      // 1) User must have edit permissions at a project or account level
      // 2) If a subcontractor, can only edit items associated with their company (and have edit permissions)
      const readonly = !((hasEditPrivilege && !isSubContractor) || (hasEditPrivilege && isSubContractor && userSubcontractorId === subInfo.subContractorId));

      const itemMaterials = item.materials ? item.materials : [];
      const materialReady = (itemMaterials.length > 0 && itemMaterials.every(mat => (mat.makeReadyStatus && mat.makeReadyStatus === MakeReadyStatus.ready))) ? true : false;
      const hasSubmittals = (itemMaterials.length > 0 && itemMaterials.find(mat => mat.procoreId)) ? true : false;
      const itemEquipment = item.equipment ? item.equipment : [];
      const equipmentReady = (itemEquipment.length > 0 && itemEquipment.every(e => (e.makeReadyStatus && e.makeReadyStatus === MakeReadyStatus.ready))) ? true : false;
      const itemLabor = item.labor ? item.labor : [];
      const laborReady = (itemLabor.length > 0 && itemLabor.every(l => (l.makeReadyStatus && l.makeReadyStatus === MakeReadyStatus.ready))) ? true : false;

      sprintBoardData.push({
        stepName: Utils.isEmpty(item.name) ? this.buildStepName(item) : item.name,
        crewSize: Utils.isEmpty(item.actualCrewSize) ? (Utils.isEmpty(item.crewSize) ? 0 : Number(item.crewSize)): Number(item.actualCrewSize),
        durationHours: Utils.isEmpty(item.actualDurationHours) ? item.durationHours :item.actualDurationHours ,
        taskId: Utils.isEmpty(item.projectScheduleTaskId) ? item.id : item.projectScheduleTaskId,
        objectIds: item.objectIds,
        activities: item.activities,
        prerequisiteObjectIds: Utils.isEmpty(item.prerequisiteObjectIds) ? new Array() : item.prerequisiteObjectIds,
        prerequisiteActivities: Utils.isEmpty(item.prerequisiteActivities) ? new Array() : item.prerequisiteActivities,
        projectId: projectId,
        criticality: Utils.isEmpty(item.criticality) ? 0 : Number(item.criticality),
        subContractor: {
            name: subInfo ? subInfo.name : '',
            abbreviation: subInfo ? subInfo.abbreviation : '',
            hexCode: subInfo ? subInfo.hexCode : '',
            subContractorId: subInfo ? subInfo.id : ''
          },
        storyStatus: Utils.isEmpty(item.storyStatus) ? 0 : Number(item.storyStatus),
        readonly: readonly,
        scheduledAtHours: Utils.isEmpty(item.scheduledAtHours) ? -1 : Number(item.scheduledAtHours),
        scheduledStart: item.scheduledStart ? item.scheduledStart : null,
        scheduledEnd: item.scheduledEnd ? item.scheduledEnd : null,
        actualStartDate: item.started ? item.started : null,
        scheduledEndDisplay: Utils.formatDate(item.scheduledStart),
        projectScheduleTaskId: item.projectScheduleTaskId ? item.projectScheduleTaskId : null,
        storyId: Utils.isEmpty(item.projectScheduleTaskId) ? null : item.id,
        startDate: Utils.isEmpty(item.startDate) ? null : item.startDate,
        stepId: Utils.isEmpty(item.projectStepId) ? null : item.projectStepId,
        planStepId: Utils.isEmpty(item.projectPlanStepId) ? null : item.projectPlanStepId,
        displayedOnBoard: false,
        equipment: itemEquipment,
        materials: itemMaterials,
        labor: itemLabor,
        materialReady: materialReady,
        equipmentReady: equipmentReady,
        hasSubmittals: hasSubmittals,
        day: item.day || null,
        hour: item.hour || null,
        workflow: item.workflow || null,
        view: item.view || null,
        viewID: item.viewID || null
      });
    });

    return sprintBoardData;
  }

  private buildStepName(item): string {
    if (!Utils.isEmpty(item.name)) return item.name;
    return (Utils.isEmptyList(item.objectIds)) ? '0 objects' : item.objectIds.length + ' object(s)';
  }

  updateBoardItem(taskId: string, updatedItem: any, subcontractorList: any[], alreadyFormatted?: boolean) {
    if (Utils.isEmpty(updatedItem)) {
      // Find data and remove story ID...if new is null, then we put it in the backlog
      const updatedItemOffset = this.sprintBoardData.map(item => item.taskId).indexOf(taskId);
      this.sprintBoardData[updatedItemOffset].storyId = null;
      this.sprintBoardData[updatedItemOffset].storyStatus = StoryStatus.Backlog;
    } else {
      // Format data if necessary
      let formattedBoardData: ISprintBoardItemResponse;
      if (alreadyFormatted) {
        formattedBoardData = updatedItem as ISprintBoardItemResponse;
      } else {
        const itemArray = new Array();
        itemArray.push(updatedItem);

        // Have to transform data into board data
        formattedBoardData = this.initializeStoryData(itemArray, subcontractorList)[0];
      }

      // Find old data and update it
      const updatedItemOffset = this.sprintBoardData.map(item => item.taskId).indexOf(taskId);
      this.sprintBoardData[updatedItemOffset] = formattedBoardData;
    }
  }

  updateBoardItemStatus(updatedItem: ISprintBoardItemResponse, newStatus) {
    const taskId = updatedItem.taskId;
    // Find data and remove story ID...if new is null, then we deleted the story
    // const updatedItemOffset = this.sprintBoardDataIndexes[taskId];
    const updatedItemOffset = this.sprintBoardData.findIndex(s=> s.taskId=== updatedItem.taskId)
    this.sprintBoardData[updatedItemOffset].storyStatus = newStatus;
    if(updatedItem.crewSize) this.sprintBoardData[updatedItemOffset].crewSize = updatedItem.crewSize;
    if(updatedItem.durationHours) this.sprintBoardData[updatedItemOffset].durationHours = updatedItem.durationHours;
    if(updatedItem.equipment) this.sprintBoardData[updatedItemOffset].equipment = updatedItem.equipment;
    if(updatedItem.materials) this.sprintBoardData[updatedItemOffset].materials = updatedItem.materials;
    if(updatedItem.labor) this.sprintBoardData[updatedItemOffset].labor = updatedItem.labor;
    if(updatedItem.hour) this.sprintBoardData[updatedItemOffset].hour = updatedItem.hour;
    if(updatedItem.day) this.sprintBoardData[updatedItemOffset].day = updatedItem.day;
    if(updatedItem.workflow) this.sprintBoardData[updatedItemOffset].workflow = updatedItem.workflow;
    if(updatedItem.view) this.sprintBoardData[updatedItemOffset].view = updatedItem.view;
    if(updatedItem.viewID) this.sprintBoardData[updatedItemOffset].viewID = updatedItem.viewID;
  }

  isSprintClosed() {
    const committed = this.sprintBoardData.filter(item => item.storyStatus === StoryStatus.Committed);
    const inProgress = this.sprintBoardData.filter(item => item.storyStatus === StoryStatus.InProgress);

    if (Utils.isEmpty(committed) && Utils.isEmpty(inProgress)) return true;
    else return false;
  }

  getBoardItemByTaskId(taskId: string) {
    return this.sprintBoardData.find(item => item.taskId === taskId);
  }

  getRefreshedData() {
    if (Utils.isEmpty(this.sprintBoardData)) {
      return null;
    } else {
      return this.transformSprintBoardData(this.sprintBoardData);
    }
  }

  removeWorkFlowrelatedDetails(sprintId, boardItem){
    boardItem.day = -1;
    boardItem.hour = -1;
    boardItem.workflow = -1;
    return this.projectSprintService.editSprintStoryBack(sprintId, boardItem);
  }

  buildViewFormInput(): FormGroup {
    const addViewFormInput = this.formBuilderService.group({
      name: ['', Validators.required],
    });
    return addViewFormInput;
  }
}
