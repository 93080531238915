import { Injectable } from '@angular/core';

import { ProjectObjectService } from '../project-object/project-object.service';
import { ProjectService } from '../project.service';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';

import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectFilterSearchService {

  constructor(
    private projectService: ProjectService,
    private projectObjectService: ProjectObjectService
  ) {/*EMPTY*/ }

  public getFilterDataByModel(activeFilters: string[], activeFilterType: FilterModelOptions, activeProjectModelId: string) {
    return new Promise(resolve => {
      if (!Utils.isEmptyList(activeFilters)) {
        let observable;
        switch (activeFilterType) {
          case FilterModelOptions.Category:
            observable = this.projectObjectService.getModelCategoryObjects(activeProjectModelId, activeFilters);
            break;
          case FilterModelOptions.Activities:
            observable = this.projectObjectService.getModelActivityObjects(activeProjectModelId, activeFilters);
            break;
        }
        observable.subscribe(results => {
          return resolve(results);
        });
      } else {
        return resolve(-1);
      }
    });
  }


  public getFilterDataForSearchQuery(searchQuery: string, activeFilterType: FilterModelOptions) { // optional parameter for Equipment and Materials

    return new Promise(resolve => {
      if (!searchQuery) return resolve(-1);
      let observable;
      let subActivities = [];
      switch (activeFilterType) {
        case FilterModelOptions.Category:
          //return resolve(-1) // Disabled for FilterModelOptions.Category
          //break;
        case FilterModelOptions.Activities:
          subActivities = subActivities.concat(this.projectService.getActivitiesMatchingName(searchQuery));
          subActivities = Utils.deDupeArray(subActivities);
          if(subActivities.length === 0)
          return resolve(-1);  
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
        case FilterModelOptions.SubContractor:
          subActivities = subActivities.concat(this.projectService.getLocalSubContractorMatchingName(searchQuery));
          subActivities = Utils.deDupeArray(subActivities);
          if (Utils.isEmptyList(subActivities)) return resolve([]);
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
        case FilterModelOptions.Equipment:
          subActivities = subActivities.concat(this.projectService.getLocalEqupmentsMatchingName(searchQuery));
          subActivities = Utils.deDupeArray(subActivities);
          if (Utils.isEmptyList(subActivities)) return resolve([]);
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
        case FilterModelOptions.Materials:
          subActivities = subActivities.concat(this.projectService.getLocalMaterialsMatchingName(searchQuery));
          subActivities = Utils.deDupeArray(subActivities);
          if (Utils.isEmptyList(subActivities)) return resolve([]);
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
      }
      observable.subscribe(results => {
        return resolve(results);
      });
    });
  }


  public getFilterData(activeFilters: string[], activeFilterType: FilterModelOptions, orAndFilter: boolean) { // optional parameter for Equipment and Materials

    return new Promise(resolve => {
      if (activeFilters.length < 1) return resolve(-1);
      let observable;
      let subActivities = [];
      switch (activeFilterType) {
        case FilterModelOptions.Category:
          observable = this.projectObjectService.getCategoryObjects(this.projectService.currentProject.id, activeFilters);
          break;
        case FilterModelOptions.Activities:
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, activeFilters);
          break;
        case FilterModelOptions.SubContractor:
          activeFilters.forEach(subId => {
            subActivities = subActivities.concat(this.projectService.getLocalSubcontractor(subId).activities);
          });
          subActivities = Utils.deDupeArray(subActivities);
          if (Utils.isEmptyList(subActivities)) return resolve([]);
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
        case FilterModelOptions.Equipment:

          if (orAndFilter) {
            activeFilters.forEach(subId => {
              subActivities = subActivities.concat(this.projectService.getLocalEqupments(subId).activities);
            });
          }
          else {

            let tempCommonActivitiesArr = [];
            let retained = [];
            let index = 0;
            activeFilters.forEach(subId => {

              tempCommonActivitiesArr[index] = this.projectService.getLocalEqupments(subId).activities;

              if (index > 0) {
                let newRetained = retained.filter(x => tempCommonActivitiesArr[index].includes(x))
                retained = newRetained;
              }
              else {
                retained = tempCommonActivitiesArr[index];
              }
              ++index;
            });

            subActivities = subActivities.concat(...retained);

          }
          subActivities = Utils.deDupeArray(subActivities);
          if (Utils.isEmptyList(subActivities)) return resolve([]);
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
        case FilterModelOptions.Materials:
          if (orAndFilter) {
            activeFilters.forEach(subId => {
              subActivities = subActivities.concat(this.projectService.getLocalMaterials(subId).activities);
            });
          } else {

            let tempCommonActivitiesArr = [];
            let retained = [];
            let index = 0;
            activeFilters.forEach(subId => {

              tempCommonActivitiesArr[index] = this.projectService.getLocalMaterials(subId).activities;
              if (index > 0) {
                let newRetained = retained.filter(x => tempCommonActivitiesArr[index].includes(x))
                retained = newRetained;
              }
              else {
                retained = tempCommonActivitiesArr[index];
              }
              ++index;
            });
            subActivities = subActivities.concat(...retained);
          }
          subActivities = Utils.deDupeArray(subActivities);
          if (Utils.isEmptyList(subActivities)) return resolve([]);
          observable = this.projectObjectService.getActivityObjects(this.projectService.currentProject.id, subActivities);
          break;
      }
      observable.subscribe(results => {
        return resolve(results);
      });
    });
  }

  public getSearchData(activeSearchQuery: string, dataToSearch: any) {
    const searchedData = {};
    if (!Utils.isEmpty(activeSearchQuery)) {
      Object.keys(dataToSearch).forEach((key) => {
        // <-=========Should probably also eventually only make it searchable on specific keys
        for (const colKey in dataToSearch[key]) {
          if (!Utils.isEmpty(dataToSearch[key][colKey])) {
            const value = dataToSearch[key][colKey].toString();
            if (value && value.toLowerCase().includes(activeSearchQuery)) {
              searchedData[key] = dataToSearch[key];
              break;
            }
          }
        }
      });
      return searchedData;
    }
    return -1;
  }

  filterByLastCat(objectId: string): string[] {
    const objCatNames = this.projectObjectService.getLocalObject(objectId).categories;
    if (objCatNames && objCatNames.length < 1) return;
    const lastCatName = objCatNames[objCatNames.length - 1].toString();
    const allCatsMap = this.projectService.getLocalCategories();
    const catIds = [];
    for (const key in allCatsMap) {
      if (key) {
        if (allCatsMap[key].name === lastCatName) {
          catIds.push(key);
        }
      }
    }
    return catIds;
  }
}
