import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Utils } from '../../utils/utils';

import { HttpBackendService } from '../http-backend/http-backend.service';

@Injectable()
export class UtilsService {

  constructor(private _http: HttpBackendService) { }

  public getTimeZones(): Observable<string[]> {
    return this._http.get('/timezones');
  }
}
