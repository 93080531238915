import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication/authentication.service';
import { MenuEmitterService } from '../services/menu/menu-emitter.service';
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class AuthGuard {

  constructor( private authenticationService: AuthenticationService, private router: Router, private notification: NotificationService ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean> {
    return new Promise( resolve => {
        this.authenticationService.isAuthenticated().subscribe(
            response => {
                this.notification.setupSocket();
                MenuEmitterService.emitShowMenuChange(true);
                resolve(true);
                if (route.url[0].path === 'login') this.router.navigateByUrl(localStorage.getItem('loginReturn') || '/home');
            },
            err => {
                resolve(false);
                localStorage.setItem('loginReturn', state.url);
                this.router.navigateByUrl('/login');
            }
        );
    });
  }
}
