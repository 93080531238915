import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject  } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IProjectHomeworkFeedConfig, IProjectHomeworkItem } from '../../../models/project/project-dashboard/project-dashboard.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';

import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectDashboardService } from '../../../services/project/project-dashboard/project-dashboard.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../../services/project/project.service';

import { Utils } from '../../../utils/utils';

import * as moment from 'moment';

@Component({
  selector: 'app-project-homework-feed',
  templateUrl: './project-homework-feed.component.html',
  styleUrls: [
    './project-homework-feed.component.scss',
    '../../project-planner/project-planner-form/project-planner-form.component.scss'
  ]
})
export class ProjectHomeworkFeedComponent implements OnInit, OnDestroy {
  @Input() hwConfigInput: IProjectHomeworkFeedConfig;
  @Output() hwSelectionOutput: EventEmitter<IProjectHomeworkItem> = new EventEmitter();

  // TEMPLATE VARIABLES
  isLoading: boolean = true;
  homeworkFeed: IProjectHomeworkItem[] = [];
  filterApplied: boolean = false;
  moreToLoad: boolean = false;
  showActivityFilters: boolean = false;

  private rawFeedData: any;
  private filterQuery: string; // used in planner form
  private today: number;
  private activityIds: string[];
  private showPlannedActivities: boolean = false;
  private selectedActivityId: string;

  // Subscription
  private updateData: Subject<string> = new Subject();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private subcontractorService: ProjectSubContractorService,
    private projectDashboardService: ProjectDashboardService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.today = Utils.getCurrentDate();
    this.getProjectFeed();

    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(myParams => {
      this.selectedActivityId = myParams['activityId'];
    });

    this.updateData.pipe(takeUntil(this.destroyed$)).subscribe(selectedActivityId => {
      this.resetFeedData(selectedActivityId);
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async resetFeedData(selectedActivityId?: string): Promise<void> {
    this.isLoading = true;
    this.homeworkFeed = [];
    await this.getProjectHomework(this.activityIds);
    if (selectedActivityId) this.setSelectedFeedItem(selectedActivityId);
  }

  async getProjectFeed() {
    this.activityIds = [];
    await this.subcontractorService.setLocalProjectSubcontractors(this.projectService.currentProject.id);
    if (ProjectService.userPermission.subContractorId) {
      const subs = this.subcontractorService.getLocalProjectSubsByAccountSub(ProjectService.userPermission.subContractorId);
      let subActivities: string[] = [];
      subs.forEach(s => {
        subActivities = subActivities.concat(s.activities);
      });
      if (subActivities.length > 0) {
        this.activityIds = subActivities;
      } else {
        this.isLoading = false;
        return;
      }
    }
    this.getProjectHomework(this.activityIds);
  }

  async getProjectHomework(activityIds: string[]): Promise<any> {
    return new Promise((resolve) => {
      this.projectDashboardService.getProjectHomework(this.projectService.currentProject.id, activityIds).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.rawFeedData = res;
          this.filterAndFormatData(this.rawFeedData);
          this.isLoading = false;
          resolve();
        },
        err => {
          this.isLoading = false;
          this.notificationService.error(err, {type: 'homework', action: 'get'});
          resolve();
        }
      );
    });
  }

  filterAndFormatData(feed: IProjectHomeworkItem[]): void {
    const filteredFeedData = [];
    this.homeworkFeed = [];
    for (const item of feed) {
      // filter out data
      if (!this.showPlannedActivities && item.objectCount === item.plannedCount) continue;
      else if (this.filterQuery && !item.activityName.toLowerCase().includes(this.filterQuery.toLowerCase())) continue;
      else filteredFeedData.push(item);

      // build display
      if (this.homeworkFeed.length < this.hwConfigInput.pageSize) {
        const subInfo = this.subcontractorService.getLocalProjectSubcontractor(item.subContractorId);
        const feedItem = this.createFeedItem(item, subInfo);
        this.homeworkFeed.push(feedItem);
      }
    }

    this.moreToLoad = filteredFeedData.length > this.homeworkFeed.length;
    this.setFilterAppliedFlag();
  }

  createFeedItem(item: IProjectHomeworkItem, subInfo: IProjectSubContractor): IProjectHomeworkItem {
    item.dueDateDisplay = Utils.formatDate(item.dueDate);
    item.daysUntilDue = moment(item.dueDate).diff(moment(this.today), 'days');
    item.tMinusDisplay = this.getTMinusHours(item);
    item.subContractorName = (subInfo && subInfo.name) ? subInfo.name : null;
    item.subContractorColor = (subInfo && subInfo.hexCode) ? subInfo.hexCode : null;
    item.selected = item.activityId === this.selectedActivityId;

    return item;
  }

  getTMinusHours(hwItem: IProjectHomeworkItem): string {
    let tMinusDisplay;
    if (hwItem.dueDate) {
      tMinusDisplay = hwItem.dueDate <= this.today
        ? `T+` + moment(this.today).diff(moment(hwItem.dueDate), 'days')
        : `T-` + moment(hwItem.dueDate).diff(moment(this.today), 'days');
      hwItem.pastDue = hwItem.dueDate <= this.today;
    }

    return tMinusDisplay;
  }

  buildSubcontractorName(subContractorId: string) {
    if (subContractorId) {
      const sub = this.subcontractorService.getLocalProjectSubcontractor(subContractorId);
      if (sub) return `<span style='color: ${sub.hexCode}'>${sub.name}</span>`;
    }
    return '(Not Assigned)';
  }

  handleActivityClick(hwItem: IProjectHomeworkItem): void {
    if (this.hwConfigInput.itemsSelectable) {
      if (hwItem.selected) {
        this.setSelectedHwItem();
        this.hwSelectionOutput.emit();
      } else {
        this.setSelectedHwItem(hwItem.activityId);

        this.hwSelectionOutput.emit(hwItem);
      }
    }
  }

  // done this way to i can set it from the planner form
  setSelectedHwItem(activityId?: string): void {
    this.selectedActivityId = null;
    if (this.homeworkFeed.length > 0) {
      this.homeworkFeed.forEach(item => item.selected = false);
      if (activityId) {
        const hwItem = this.homeworkFeed.find(hw => hw.activityId === activityId);
        hwItem.selected = true;
        this.selectedActivityId = activityId;
      }
    }
  }

  setFilterAppliedFlag(): void {
    if (this.filterQuery) this.filterApplied = true;
    else this.filterApplied = false;
  }

  toggleCompletedActivities(): void {
    this.showPlannedActivities = !this.showPlannedActivities;
    this.filterAndFormatData(this.rawFeedData);
  }

  applySearch(query?: string): void {
    this.filterQuery = query;
    this.filterAndFormatData(this.rawFeedData);
  }

  toggleActivitiesFilters(): void {
    this.showActivityFilters = !this.showActivityFilters;
  }

  loadMore(): void {
    this.hwConfigInput.pageSize += this.hwConfigInput.pageSize;
    this.filterAndFormatData(this.rawFeedData);
  }

  setSelectedFeedItem(activityId: string): void {
    const selectedFeedItem = this.homeworkFeed.find(hwItem => hwItem.activityId === activityId);
    if (selectedFeedItem) selectedFeedItem.selected = true;
  }
}
