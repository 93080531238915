import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ISnapshot } from '../../../models/forge/forge.interface';
import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { SegmentService } from '../../segment/segment.service';
import { Utils } from '../../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SnapshotsService {

  private snapshots: ISnapshot[] = [];
  private homeView: ISnapshot = null;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private http: HttpBackendService,
    private notificationService: NotificationService,
  ) {/*EMPTY*/}

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getProjectSnapshots(projectId: string): Promise<ISnapshot[]> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.http.get('/project/' + projectId + '/snapshots').pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'snapshots', action: 'get'});
          return reject();
        }
      );
    });
  }

  addSnapshot(snapshot: ISnapshot): Promise<ISnapshot> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.http.post('/project/snapshot', snapshot).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          SegmentService.track('Model Filter: Snapshot Added ', {homeView: res.isHomeView});
          this.snapshots.push(res);
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'snapshot', action: 'insert'});
          return reject();
        }
      );
    });
  }

  editSnapshot(snapshot: ISnapshot): Promise<ISnapshot> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.http.put(`/project/snapshot/${snapshot.id}`, snapshot).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          const snapIndex = this.snapshots.findIndex(s => s.id === snapshot.id);
          if (snapIndex > -1) this.snapshots[snapIndex] = snapshot;
          SegmentService.track('Model Filter: Snapshot Edited ', {homeView: this.snapshots[snapIndex].isHomeView});
          return resolve();
        },
        err => {
          this.notificationService.error(err, {type: 'snapshot', action: 'update'});
          return reject();
        }
      );
    });
  }

  deleteSnapshot(snapshotId: string): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.http.delete(`/project/snapshot/${snapshotId}`).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          SegmentService.track('Model Filter: Snapshot Deleted ', {});
          return resolve();
        },
        err => {
          this.notificationService.error(err, {type: 'snapshot', action: 'delete'});
          return reject();
        }
      );
    });
  }

  setLocalSnapshots(projectId: string): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise(async (resolve) => {
      await this.getProjectSnapshots(projectId).then(res => {
        this.snapshots = Utils.sortByString(res, 'name');
        this.homeView = this.snapshots.find(s => s.isHomeView === true);
        return resolve();
      }).catch(() => {
        this.snapshots = [];
        return resolve();
      });
    });
  }

  getLocalSnapshots(): ISnapshot[] {
    return this.snapshots.filter(s => s.isHomeView === false);
  }

  getSnapshot(snapshotId: string) {
    return this.snapshots.find(snap => snap.id === snapshotId);
  }

  getHomeView(): ISnapshot {
    return this.homeView;
  }

}
