import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IProjectStep } from '../../../models/project/project-step/project-step.interface';

export interface IPlannerTaskListItem extends IProjectStep {
  expanded: boolean;
  recommended: boolean;
  workPackageId?: string;
}

@Component({
  selector: 'app-project-task-list',
  templateUrl: './project-task-list.component.html',
  styleUrls: ['./project-task-list.component.scss']
})
export class ProjectTaskListComponent implements OnDestroy {
  @Input() taskList: IPlannerTaskListItem[] = [];

  @Output() editOutput: EventEmitter<string> = new EventEmitter();
  @Output() deleteOutput: EventEmitter<string> = new EventEmitter();
  @Output() taskOrderEdit: EventEmitter<void> = new EventEmitter();
  @Output() addRecommendedTaskOutput: EventEmitter<string> = new EventEmitter();

  // Subscriptions
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  get isCommitted() {
    return this.taskList.some(s => s.stepStatus >= 2);
  }

  constructor(
    private dragularService: DragulaService
  ) {
    dragularService.drop.pipe(takeUntil(this.destroyed$)).subscribe(itemsData => {
      // the order of taskList is kept through [dragulaModel] in template
      setTimeout(() => {
        this.taskOrderEdit.emit();
      }, 1);
    });
    const bag: any = this.dragularService.find('first-bag');
    if (bag !== undefined ) this.dragularService.destroy('first-bag');
    dragularService.setOptions('first-bag', {
      moves: (el, source, handle, sibling) => !el.classList.contains('no-drag')
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  deleteTask(task) {
    this.deleteOutput.emit(task.id);
  }

  editTask(task) {
    this.editOutput.emit(task.id);
  }

  addTask(task) {
    task.recommended = false;
    this.addRecommendedTaskOutput.emit(task.id);
  }

  expandTaskItem(task, e): void {
    e.stopPropagation();
    task.expanded = !task.expanded;
  }
}
