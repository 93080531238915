import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../http-backend/http-backend.service';

import { IWeatherForecast } from '../../models/weather/weather.interface';

import * as moment from 'moment';

enum Weather {
  clear = 'clear',
  cloudy = 'cloudy',
  rain = 'rain',
  snow = 'snow',
  windy = 'windy'
}

export enum WeatherIcon {
  clear = 'fa-sun',
  cloudy = 'fa-cloud',
  rain = 'fa-tint',
  snow = 'fa-snowflake-o',
  windy = 'fa-leaf'
}

@Injectable()
export class WeatherService {

  constructor(
    private _http: HttpBackendService
  ) { }

  public getWeatherForecast(projectId: string): Observable<IWeatherForecast[]> {
    return this._http.get('/project/' + projectId + '/weatherForecast');
  }

  transformWeatherDataForCharts(weatherData: IWeatherForecast[]): IWeatherForecast[] {
      return weatherData.map(item => ({
        ...item,
        date: moment.utc(item.date * 1000).clone().startOf('day').valueOf(),
        icon: this.getWeatherIcons(item.dayWeather)
      }));
  }

  getWeatherIcons(weather: string): WeatherIcon {
    switch (weather) {
      case Weather.clear:
        return WeatherIcon.clear;
      case Weather.cloudy:
        return WeatherIcon.cloudy;
      case Weather.rain:
        return WeatherIcon.rain;
      case Weather.snow:
        return WeatherIcon.snow;
      case Weather.windy:
        return WeatherIcon.windy;
    }
  }
}
