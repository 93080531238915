import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoginStatusService {

    private static emitChangeSource = new Subject<boolean>();

    static changeEmitted$ = LoginStatusService.emitChangeSource.asObservable();

    static emitChange(change: boolean) {
        this.emitChangeSource.next(change);
    }
}
