import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IProjectMaterial, IUnitOfMeasureOption, UnitOfMeasure } from '../../../models/project/project-material/project-material.interface';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { IProjectRfi } from '../../../models/project/project-rfi/project-rfi.interface';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-rfi-form',
  templateUrl: './project-rfi-form.component.html',
  styleUrls: ['./project-rfi-form.component.scss']
})
export class ProjectRfiFormComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() currency: string;
  @Output() submitOutput: EventEmitter<IProjectRfi> = new EventEmitter();

  // Template Variables
  addingNew: boolean = false;
  measureOptions: IUnitOfMeasureOption[] = [];
  currencySymbol: string;

  // returns specific form control by label
  get number() { return this.formInput.get('number'); }
  get subject() { return this.formInput.get('subject'); }
  get initiatedAt() { return this.formInput.get('initiatedAt'); }
  get dueDate() { return this.formInput.get('dueDate'); }
  get prepareLeadDays() { return this.formInput.get('prepareLeadDays'); }
  get reviewLeadDays() { return this.formInput.get('reviewLeadDays'); }

  constructor(
    private materialService: ProjectMaterialService
  ) {/*EMPTY*/}

  ngOnInit() {
    if (!this.subject.value) this.addingNew = true;
    Object.keys(UnitOfMeasure).forEach(key => this.measureOptions.push({value: UnitOfMeasure[key], display: this.materialService.getDisplayValue(UnitOfMeasure[key])}));

  }

  onSubmit(form): void {
    const formValues = form.getRawValue();

    const rfi: IProjectRfi = {
      id: formValues.id ? formValues.id : null,
      subject: formValues.subject,
      number: formValues.number,
      initiatedAt: formValues.initiatedAt,
      dueDate: formValues.dueDate,
      prepareLeadDays: formValues.prepareLeadDays,
      reviewLeadDays: formValues.reviewLeadDays,
    };
    this.submitOutput.emit(rfi);
  }

  displayFn(item: any): string {
    return item && item.activity && item.activity.name ? item.activity.name : undefined;
  }

  changeDueDate(): any {
    let startDate = new Date(this.formInput.controls.initiatedAt.value);
    const reviewDays = this.formInput.controls.reviewLeadDays.value || 0;
    const prepareLeadDays = this.formInput.controls.prepareLeadDays.value || 0;
    startDate = new Date(startDate.setDate(startDate.getDate() - (reviewDays + prepareLeadDays)));
    this.formInput.controls.dueDate.setValue(startDate);
  }
}
