import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IColorPalette } from '../../models/color-palette/color-palette.interface';
import { HttpBackendService } from '../http-backend/http-backend.service';
import { Utils } from '../../utils/utils';

@Injectable()
export class ColorPaletteService {

  constructor(private _http: HttpBackendService) { }

  private localColorPalettes: IColorPalette[] = [];
  private availableColorPalettes: IColorPalette[] = [];

  public getColorPalettes(): Observable<IColorPalette[]> {
    return this._http.get('/colorPalettes');
  }

  public getAvailableColorPalettes(projectId: string, currentSubContractorId: string): Observable<IColorPalette[]> {
    return this._http.get('/project/' + projectId + '/availableColors/' + currentSubContractorId);
  }

  public async setLocalColorPalettes(): Promise<void> {
    this.localColorPalettes = await this.getColorPalettes().toPromise();
  }

  public async setAvailableColorPalettes(projectId: string, currentSubContractorId: string) {
    this.availableColorPalettes = await this.getAvailableColorPalettes(projectId, currentSubContractorId).toPromise();
  }

  public getLocalColorPalettes(): IColorPalette[] {
    return this.localColorPalettes;
  }

  public getLocalAvailableColorPalettes(): IColorPalette[] {
    return this.availableColorPalettes;
  }
}
