import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';

import { EventStatus } from '../../../utils/enums/notification.enum';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { IHours } from '../../../models/project/project-workdays/project-workdays.interface';
import { Utils } from '../../../utils/utils';

import * as moment from 'moment';

@Component({
  selector: 'app-project-scenario-form',
  templateUrl: './project-scenario-form.component.html',
  styleUrls: ['./project-scenario-form.component.scss']
})
export class ProjectScenarioFormComponent implements OnInit, OnDestroy {

  @Input() masterScheduleHours;
  @Input() exceptions;

  @Output() closeFormOutput: EventEmitter<any> = new EventEmitter();
  @Output() scenarioGeneratedOutput: EventEmitter<any> = new EventEmitter();

  projectId: string;

  @ViewChild('projectExceptionDaysComponent') exceptionDays: any;
  @ViewChild('projectWorkDaysComponent') workDays;
  workingDays: IHours = {
    hours: null,
    timeZone: null,
    exceptions: null
  };
  displayWorkingDays: any;
  scheduleName: string;
  honorCommittments: boolean = true;
  projectSubcontractors: IProjectSubContractor[];

  pageIsLoading: boolean = true;
  loadingMessage: string = 'data_loading';
  errorGettingData: boolean = false;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private ref: ChangeDetectorRef,
    private projectService: ProjectService,
    private projectSubcontractorService: ProjectSubContractorService,
    private notificationService: NotificationService,
    private projectScheduleService: ProjectScheduleService,
    private segmentService: SegmentService,
  ) { }

  // tslint:disable-next-line:no-empty
  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    this.projectSubcontractorService.getAssociatedSubContractors(this.projectService.currentProject.id, true).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.segmentService.track('Scenarios: Add Modal Opened', { projectId: this.projectId });
        this.projectSubcontractors = Utils.sortByString(res, 'name', true).filter(psc => psc.activities.length > 0);
        this.projectSubcontractors.forEach(sc => sc.originalCrew = sc.availableCrew);
        this.pageIsLoading = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'project subcontractors',
          action: 'get'
        };
        this.notificationService.error(err, context);

        this.errorGettingData = true;
        this.pageIsLoading = false;
      }
    );
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  displayWorkDay() {
    if (!this.workDays) return;
    this.segmentService.track('Scenarios: Working Days Clicked', { projectId: this.projectId });

    const workDayData = this.workDays.getData();
    this.setDisplayWorkingDays(workDayData);
    this.ref.detectChanges(); // manually fire off change Detections to update anything angular
  }

  setDisplayWorkingDays(workDayData) {
    const displayedWorkDays = [];
    if (!Utils.isEmptyList(workDayData)) {
      // loop throught each working day set to create display data
      workDayData.forEach(workday => {
        const dayOfWeek = [];
        // merge each day of the week that has been selected and create single string at the end U|M|T|W|R|F|S
        workday.days.forEach(day => {
          if (day.selected) dayOfWeek.push(day.oneDigit);
        });
        const dayHours = [];
        // to make sure all space is used for the html col-md-2 (up to 12) we are creating 4 spaces else create empty spot with hyphen (-)
        for (let i = 0; i < 4; i++) {
          if (workday.hours[i]) {
            let startT = workday.hours[i].startTime; // startTime
            const sPeriod = Math.floor(startT / 12); // 0(a) or 1(p)
            startT = startT >= 12 ? startT - 12 : startT; // show 1-12 only
            startT = startT === 0 ? 12 : startT; // show 12 if equal to 0 for 12am mainly
            const startDisplay = sPeriod ? startT + 'p' : startT + 'a'; // 'a' for a.m && 'p' for p.m

            let endT = workday.hours[i].endTime; // endTime
            const ePeriod = Math.floor(endT / 12);
            endT = endT > 12 ? endT - 12 : endT;
            endT = endT === 0 ? 12 : endT;
            const endDisplay = ePeriod ? endT + 'p' : endT + 'a';

            dayHours.push(startDisplay + '-' + endDisplay); // create string #a-#p
          } else {
            // TODO: need to implement the hypen Pipe when it is available
            dayHours.push('-');
          }
        }
        // create string up to U|M|T|W|R|F|S
        displayedWorkDays.push({ day: dayOfWeek.join('|'), hours: dayHours });
      });
      this.displayWorkingDays = displayedWorkDays;
    }
  }

  transformWorkDay(workDayData) {
    const newHours = [];
    const dayHour = 3600;
    if (!Utils.isEmptyList(workDayData)) {
      // work day sets
      workDayData.forEach(workDay => {
        // each of selected hours needs to be associated with the day selector
        workDay.hours.forEach(hour => {
          const dayData = {};
          // check each of the selected days and create associated array with start and end time for the selected days
          workDay.days.forEach(day => {
            if (day.selected) {
              dayData[day.name] = [hour.startTime * dayHour, hour.endTime * dayHour];
            } else {
              dayData[day.name] = [];
            }
          });
          newHours.push(dayData);
        });
      });
    }
    return newHours;
  }

  generateScheduleScenario(noSchedule) {
    if (!this.scheduleName) return;
    let options = {};
    if (!noSchedule) {
      if (this.scheduleName === '') return;
      const subs: any = {};

      this.projectSubcontractors.forEach(sc => subs[sc.id] = sc.availableCrew);
      this.workingDays.hours = this.transformWorkDay(this.workDays.getData());
      this.workingDays.exceptions = this.exceptionDays.formatOutput();
      this.workingDays.timeZone = this.workDays.getTimeZone();

      options = {
        name: this.scheduleName,
        hours: this.workingDays,
        subContractors: subs,
        timeBuffer: 0,
        ignoreCommittments: !this.honorCommittments
      };
    } else {
      options = { name: moment().format('LLLL'), subContractors: '{}', timeBuffer: 0 };
    }
    this.projectScheduleService.generateSchedule(this.projectService.currentProject.id, options).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.segmentService.track('Scenarios: Scenario Generated', { projectId: this.projectId });
        this.scheduleName = ''; //  reset schedule name;
        this.scenarioGeneratedOutput.emit();
      },
      (err) => {
        const context: INoficationContext = {
          type: 'create schedule',
          action: 'get'
        };
        this.errorGettingData = true;
        this.notificationService.error(err, context);
        this.scenarioGeneratedOutput.emit();
      });
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.scheduleName) {
        this.generateScheduleScenario(this.projectId);
      }
    }
  }
}
