
import { Component, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { PDFViewerService } from '../../services/pdf/pdfViewer.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { ProjectMilestoneService } from '../../services/project/project-milestone/project-milestone.service';
import { ProjectPageCriteriaService } from '../../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { WeatherService } from '../../services/weather/weather.service';

import { IXaxisChartData } from '../../models/chart/chart.interface';
import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectMaterial } from '../../models/project/project-material/project-material.interface';
import { IProjectScheduleMilestone } from '../../models/project/project-milestone/project-milestone.interface';
import { IEpochRange, IProjectScheduleItem, IProjectScheduleStep, IScheduleSelectionOutput } from '../../models/project/project-schedule/project-schedule.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { IStepsBySub } from '../../models/sidebar/sidebar.interface';
import { ISidebarTab } from '../../models/sidebar/sidebar.interface';
import { IUserPermission } from '../../models/user/user.interface';
import { IWeatherForecast } from '../../models/weather/weather.interface';

import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../utils/enums/forge-viewer-type';
import { MessageType } from '../../utils/enums/message-type.enum';
import { ColorMode, ViewMode, ViewerMode } from '../../utils/enums/shared.enum';
import { SidebarState } from '../../utils/enums/sidebar-state';
import { Utils } from '../../utils/utils';
import { ActivityColorMap } from '../../models/notification/notification.interface';

import * as moment from 'moment';
import { forEach } from '@angular/router/src/utils/collection';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectFilterSearchService } from '../../services/project/project-filter-search/project-filter-search.service';
import { ProjectMasterScheduleService } from '../../services/project/project-master-schedule/project-master-schedule.service';

@Component({
  selector: 'app-project-schedule',
  templateUrl: './project-schedule.component.html',
  styleUrls: ['./project-schedule.component.scss']
})
export class ProjectScheduleComponent implements OnDestroy, OnInit {
  @ViewChild('scheduleChart') scheduleChart;
  @ViewChild('scheduleCal') scheduleCal;

  // display toggles
  pageIsLoading: boolean = true;
  showRetryBtn: boolean = false;
  changingRanges: boolean = false;

  // forge viewer
  firstLoad: boolean = true;
  viewerPoppedOut: boolean = false;
  pastObjectIdsMap: any;

  loadingMessage: string = 'Loading...';
  errorGettingData: boolean = false;
  noScheduleData: boolean = false;
  scheduleIsEmpty: boolean = false;
  projectId: string;
  projectPermission: IUserPermission;
  syncedData: any;
  projectSchedule: any;
  activeViewMode: ViewMode = ViewMode.ThreeWeekSchedule;
  viewMode = ViewMode;
  activeColorMode: ColorMode = ColorMode.Criticality;
  colorMode = ColorMode;
  visibleDays: number = 21; // days visible in chart
  visibleEpochs: number[];
  xAxisData: IXaxisChartData[]; // days with weather data
  visibleEpochRange: IEpochRange;
  selectedDays: number[] = [];
  enabledPlayControl: boolean = true;
  enablePrevDayControl: boolean = true;
  enableNextDayControl: boolean = true;
  projectStartDate: number;
  projectEndDate: number;
  transformedCompleteSchedule: IProjectScheduleItem[];
  projectSubcontractors: IProjectSubContractor[];
  selectedSteps: IProjectScheduleStep[] = [];
  selectedObjectIds: string[] = [];
  objectIdsInRange: string[] = [];
  objectIdsInPast: string[] = [];
  animationIsPlaying: boolean = false;
  animationInterval: any;
  animationSpeed: number = 1;
  scheduleOptions: IDropdownItem[];
  planOptions: any;
  showScheduleDropdown: boolean = false;
  selectedScheduleVersionId: string;
  activeScheduleId: string;
  enabledScheduleActivation: boolean;
  allScheduleByPlan: any;
  displayedPlan: any;
  displayedPlanTimestamp: string;
  enableColorOptions: boolean = true;
  noDataMessages: string[] = [
    'There was an error getting schedule data.',
    'A schedule needs to be generated.',
    'A schedule needs to be generated. Only users with project admin permissions that are not associated with a subcontractor may perform this action.',
    'There is no active schedule. Select a schedule for the dropdown or create a new schedule.',
    'The selected schedule contains no data. Ensure activities are assigned.'
  ];
  noScheduleDisplayMessage: string;
  scheduleGenerationEnabled: boolean = false;
  scheduleCreationEnabled: boolean = false;
  weatherForecast: IWeatherForecast[] = [];
  projectMilestones: IProjectScheduleMilestone[];
  projectMaterials: IProjectMaterial[];
  activeChartSelection: IScheduleSelectionOutput;
  reformatControlsDisplay: boolean;
  isDayScrolling: boolean = false;
  isSideBarOpen: boolean = false;
  today: number;
  firstScheduleLoad: boolean = true;
  firstSunInSchedule: number;

  showSidebarData: boolean;
  sidebarTabs: ISidebarTab[];
  sidebarState = SidebarState;
  activeSidebarState: SidebarState;
  selectedGritObjectIds: string[] = [];
  sidebarStepData: IStepsBySub[] = [];
  messageType = MessageType;
  sidebarPlanStepIds: string[];
  sidebarStepIds: string[];
  selectedStepIdsForSidebar: string[] = [];

  // Material display information
  showDataModal: boolean = false;
  dataModalInput: IConfirmationModalInput = {
    displayMessage: 'UNKNOWN',
    hasCancelAction: false,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true,
    customConfirmLabel: 'Done'
  };
  modalCurrentOffset: number = 0;
  modalCurrentList: any;
  modalCurrentItem: any;
  modalDataType: string;
  selectedLable = this.projectScheduleService.viewOptions[0].label;

  // filters
  lastCatFilter: string;
  resettedActivityidColorMap: ActivityColorMap[] = [];

  // Subscription Killer
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  gritRating: number | string = `__`;
  // tslint:disable-next-line:max-line-length
  gritRatingInfo: string = `grit_score_description.`;

  private loadedStepId: string; // Used in html - passed in to schedule chart
  private loadedScheduleId: string;
  private loadedMessage: boolean;
  quickLinkSelectReady: boolean;
  modelStatus: boolean;

  //Defaults to Three Week View
  selectedViewWeekIndex: number = 1;

  activeFilterType: FilterModelOptions = FilterModelOptions.Activities;
  activeFilters: string[] = [];
  filtersApplied: boolean = false;
  searchQuery: string = '';

  chartCheck = true;
  viewerCheck = true;
  orAndFilter = true;
  symbol: string;
  private myViewerState = {};
  activityInput: any;
  prereqAutoInput: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private forgeViewerService: ForgeViewerService,
    private pdfViewerService: PDFViewerService,
    private notificationService: NotificationService,
    private projectMilestoneService: ProjectMilestoneService,
    private segmentService: SegmentService,
    private weatherService: WeatherService,
    private projectMaterialService: ProjectMaterialService,
    public projectPageCriteriaService: ProjectPageCriteriaService,
    public projectScheduleService: ProjectScheduleService,
    public projectService: ProjectService,
    private projectObjectService: ProjectObjectService,
    private filterSearchService : ProjectFilterSearchService,
    private masterScheduleService: ProjectMasterScheduleService
  ) {
    this.projectService.setHideSidebar(false);
  }

  async ngOnInit() {
    window.addEventListener('keydown', this.keyPress);

    this.modelStatus =  this.projectService.getHasModelsStatus();
    this.projectScheduleService.resetLocalSchedule();
    this.projectId = this.projectService.currentProject.id;
    this.projectPermission = ProjectService.userPermission;
    this.scheduleGenerationEnabled = this.projectPermission.gc && this.projectPermission.admin;
    this.scheduleCreationEnabled = this.projectPermission.gc && this.projectPermission.admin;
    this.today = moment.utc().clone().valueOf();
    this.checkToReformatControlsDisplay();
    await this.getActivityData();

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(async myParams => {
      this.loadedScheduleId = myParams['scheduleId'];
      this.loadedStepId = myParams['stepId'];
      this.loadedMessage = myParams['message'];

      if (this.loadedStepId && this.quickLinkSelectReady) {
        this.handleQuickLink();
      }
    });

    // if forge viewer has already loaded
    if (this.projectService.getProjectReady()) {
      this.firstLoad = false;
      this.setupPageData();
    }

    // if we need to wait for forge viewer to load
    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.viewerPoppedOut = this.forgeViewerService.poppedOut;
      if (this.forgeViewerService.poppedOut || !this.firstLoad) {
        await this.forgeViewerService.setViewerState(this.myViewerState);
        await this.setForgeViewer(this.selectedObjectIds, this.objectIdsInPast, 'click', true);
      } else {
        this.firstLoad = false;
        this.setupPageData();
      }
    });

    await this.projectService.loadActivities(this.projectId);

    // detect main menu slideout to resize charts
    MenuEmitterService.slideOutStateChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.resizeChart();
      this.checkToReformatControlsDisplay();
    });

    // // Segment Analytics
    this.segmentService.track('Schedule Loaded', {projectId: this.projectId});
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 39) {
      this.getNextDay()
    }
    if (event.keyCode === 37) {
      this.getPrevDay();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.animationInterval);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  keyPress = (event) => {
    if (event.key === 'Escape') {
      this.resetChartSelections();
    }
  }

  async setupPageData() {
    // clear anything selected from a different route FIRST
    this.forgeViewerService.clearAllThemingColors();
    this.forgeViewerService.clearCutPlanes();
    this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitObjectsToView(this.myViewerState);
    this.setupSideBar();
    this.getSubcontractors(this.projectId);
    this.getScheduleData();
    this.projectScheduleService.colorOptions.map(option => option.selected = false);
    this.projectScheduleService.colorOptions[0].selected = true;
    this.projectScheduleService.viewOptions.map(view => view.selected = false);
    this.projectScheduleService.viewOptions[0].selected = true;
  }

  getSubcontractors(projectId: string) {
    this.projectService.getProjectSubcontractors(projectId).pipe(take(1)).subscribe(
      res => {
        this.projectSubcontractors = res.concat(ProjectSubContractorService.unassignedSubcontractor);
        this.projectSubcontractors = Utils.sortByString(this.projectSubcontractors, 'name', true);

        this.errorGettingData = false;
      },
      err => {
        this.setNoDataDisplay('error');
        const context: INoficationContext = {
          type: 'get subcontractors',
          action: 'get'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  /*-- Checks all criteria that is needed to build a schedule, the second parameter is for cases when you want to check
      criteria for a specific schedule id (when generating a schedule), if no parameter it will try to find the active
      schedule id --*/
  /*--TODO - clean up, does too much--*/
  async getScheduleData() {
    this.pageIsLoading = true;
    const observableArray: Array<Observable<any>> = [
      this.projectScheduleService.getSchedulesByPlan(this.projectId), // check for schedules
      this.weatherService.getWeatherForecast(this.projectId) // get weather
    ];

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        if (res[0].length > 0) {
          this.noScheduleData = false;
          this.allScheduleByPlan = res[0];

          this.scheduleOptions = this.projectScheduleService.transformSchedulesForDropdown(this.allScheduleByPlan);
        } else {
          this.setNoDataDisplay('no-data');
          this.noScheduleData = true;
          this.pageIsLoading = false;
        }

        if (!this.noScheduleData) {
          this.weatherForecast = this.weatherService.transformWeatherDataForCharts(res[1]);
        }

        if (!this.noScheduleData && this.weatherForecast) {
          let firstChoice = null;
          let secondChoice = null;
          let lastChoice = null;
          for (const p of this.allScheduleByPlan) {
            const schedules = p.schedules ? p.schedules : [];
            if (schedules.length > 0) {
              for (const s of schedules) {
                if (s.id === this.loadedScheduleId) {
                  firstChoice = s.id;
                } else if (!firstChoice && s.active === true) {
                  firstChoice = s.id;
                } else if (!secondChoice && s.living === true) {
                  secondChoice = s.id;
                } else if (!lastChoice) {
                  lastChoice = s.id;
                }
              }
            }
          }
          if (firstChoice) {
            this.selectedScheduleVersionId = firstChoice;
          } else if (secondChoice) {
            this.selectedScheduleVersionId = secondChoice;
          } else if (lastChoice) {
            this.selectedScheduleVersionId = lastChoice;
          }
          if (this.selectedScheduleVersionId) {
            this.noScheduleData = false;
            this.setupDisplayedSchedule(this.selectedScheduleVersionId);
          } else {
            this.pageIsLoading = false;
            this.setNoDataDisplay('no-data');
          }
        }
      },
      err => {
        this.setNoDataDisplay('error');
        const context: INoficationContext = {
          type: 'schedule criteria check',
          action: 'get'
        };
        this.notificationService.error(err, context);

        this.pageIsLoading = false;
      }
    );
  }
  getScheduleAndMilestones(scheduleId: string): void {
    this.projectScheduleService.resetLocalSchedule();
    this.pageIsLoading = true;
    const observableArray: Array<Observable<any>> = [];
    observableArray.push(this.projectMilestoneService.getScheduledMilestones(scheduleId));
    observableArray.push(this.projectMaterialService.getListForSchedule(scheduleId));

    forkJoin(observableArray).subscribe(
      async res => {
        this.errorGettingData = false;
        this.projectMilestones = !Utils.isEmpty(res[0]) ? this.projectScheduleService.transformMilestones(res[0]) : null;
        this.projectMaterials = !Utils.isEmpty(res[1]) ? this.projectScheduleService.transformMaterials(res[1]) : null;
        // sending null for start and end dates because we want to get the entire schedule. We do this because the user has to be able to scroll through the days with tasks,
        // and we need to know when to stop checking for more tasked days
        await this.projectScheduleService.updateLocalScheduleData(null, null, scheduleId, this.projectSubcontractors);
        const projectStart = this.projectScheduleService.getFirstProjectDayFromLocal();
        this.firstSunInSchedule = this.projectStartDate ? moment.utc(projectStart).clone().startOf('week').valueOf() : null;

        if (Utils.objectIsEmpty(this.projectScheduleService.getLocalScheduleData())) {
          this.setNoDataDisplay('empty-schedule');
        } else {
          this.scheduleIsEmpty = false;
          const startDate = this.projectScheduleService.findStartDate(this.today);
          if (this.activeViewMode === ViewMode.ThreeWeekSchedule) {
            this.quickLinkSelectReady = true;
            if (this.loadedStepId) {
              const schedule = this.projectScheduleService.getLocalScheduleData();
              const day = Object.keys(schedule).find(key => schedule[key].steps.filter(step => step.id === this.loadedStepId).length > 0);
              if (day) {
                this.setVisibleChartRange(Number(day), this.visibleDays);
              } else {
                this.loadedStepId = null;
                if (this.projectScheduleService.getStepsFromLocalScheduleByDay(this.today).length > 0) {
                  this.setVisibleChartRange(this.today, this.visibleDays);
                } else {
                  this.setVisibleChartRange(this.projectStartDate, this.visibleDays);
                }
              }
            } else {
              this.setVisibleChartRange(this.today, this.visibleDays);
            }
          } else if (this.activeViewMode === ViewMode.Calendar) {
            this.setVisibleCalendarRange(startDate);
          } else this.setNoDataDisplay('no-data');

          this.setFilterType(FilterModelOptions.Activities, false);
          this.setDisplayedPlan(this.selectedScheduleVersionId, this.allScheduleByPlan);

          this.enabledScheduleActivation = this.projectPermission.gc && this.selectedScheduleVersionId !== this.activeScheduleId;
        }

        this.pageIsLoading = false;
      },
      err => {
        this.setNoDataDisplay('error');
        const context: INoficationContext = {
          type: 'get schedule',
          action: 'get'
        };
        this.notificationService.error(err, context);

        this.pageIsLoading = false;
      }
    );
  }

  async setVisibleCalendarRange(activeDay: number) {
    this.visibleEpochs = this.projectScheduleService.getMonthOfEpochs(activeDay);
    this.visibleEpochRange = this.projectScheduleService.getActiveEpochRange(this.visibleEpochs[0], this.visibleDays);
    await this.projectScheduleService.updateLocalScheduleData(this.visibleEpochRange.startEpochRange, this.visibleEpochRange.endEpochRange, this.selectedScheduleVersionId, this.projectSubcontractors);
    this.setDefaultChartState();
  }

  async setVisibleChartRange(startEpoch: number, numberOfDays: number) {
    const startOfRange = moment.utc(startEpoch).startOf('week').valueOf();
    this.visibleEpochs = this.projectScheduleService.getListOfActiveEpochs(startOfRange, numberOfDays);
    this.visibleEpochRange = this.projectScheduleService.getActiveEpochRange(this.visibleEpochs[0], numberOfDays);
    await this.projectScheduleService.updateLocalScheduleData(this.visibleEpochRange.startEpochRange, this.visibleEpochRange.endEpochRange, this.selectedScheduleVersionId, this.projectSubcontractors);
    this.setDefaultChartState();
  }

  setDisplayedPlan(scheduleId: any, schedulesByPlan: any[]): void {
    const displayedPlan = schedulesByPlan.filter(plan => !Utils.isEmpty(plan.schedules.filter(schedule => schedule.id === scheduleId)));

    if (displayedPlan.length > 0) {
      this.displayedPlan = displayedPlan;
      this.displayedPlanTimestamp = moment.utc(displayedPlan[0].timestamp).format('MMM DD, YYYY HH:mm:ss');
    }
  }

  setupDisplayedSchedule(scheduleId: string): void {
    this.selectedScheduleVersionId = scheduleId;
    this.firstScheduleLoad = true;
    this.getScheduleAndMilestones(this.selectedScheduleVersionId);
  }

  getNextRange() {
    this.resetChartSelections();
    const startOfNextRange = moment.utc(this.visibleEpochRange.startEpochRange).clone().add('day', this.visibleDays).valueOf();
    const startOfNextMonth = moment.utc(startOfNextRange).add(15, 'days').startOf('month').valueOf();

    if (this.activeViewMode === ViewMode.Calendar) this.setVisibleCalendarRange(startOfNextMonth);
    else this.setVisibleChartRange(startOfNextRange, this.visibleDays);
  }

  getNextDay() {
    this.isDayScrolling = true;
    const startOfNextRange = moment.utc(this.visibleEpochRange.endEpochRange).clone().add('day', 1).startOf('day').valueOf();
    const startOfNextMonth = moment.utc(startOfNextRange).add(15, 'days').startOf('month').valueOf();
    const startOfCurrRange = this.visibleEpochRange.startEpochRange;
    this.selectedDays[0] = (!this.selectedDays[0] || this.selectedDays.length === 0)
      ? this.projectScheduleService.getNextTaskDay(startOfCurrRange)
      : this.projectScheduleService.getNextTaskDay(this.selectedDays[0]);

    if (this.selectedDays[0] >= startOfNextRange) {
      this.changingRanges = true;
      this.selectedSteps = this.projectScheduleService.getStepsFromDay(this.selectedDays[0]);
      this.setSelectedObjectIdsFromSteps(this.selectedSteps);
      if (this.activeViewMode === ViewMode.Calendar) this.setVisibleCalendarRange(startOfNextMonth);
      else this.setVisibleChartRange(startOfNextRange, this.visibleDays);
    } else {
      this.projectScheduleService.setDaySelectionState(this.selectedDays);
    }
  }

  getPrevRange() {
    this.resetChartSelections();
    const startOfPrevRange = moment.utc(this.visibleEpochRange.startEpochRange).clone().subtract('day', this.visibleDays).valueOf();
    const startOfPrevMonth = moment.utc(startOfPrevRange).add(15, 'days').startOf('month').valueOf();

    if (this.activeViewMode === ViewMode.Calendar) this.setVisibleCalendarRange(startOfPrevMonth);
    else this.setVisibleChartRange(startOfPrevRange, this.visibleDays);
  }

  getPrevDay() {
    this.isDayScrolling = true;
    const startOfPrevRange = moment.utc(this.visibleEpochRange.startEpochRange).clone().subtract('day', this.visibleDays).startOf('day').valueOf();
    const startOfPrevMonth = moment.utc(startOfPrevRange).add(15, 'days').startOf('month').valueOf();
    const endOfPrevRange = moment.utc(this.visibleEpochRange.startEpochRange).clone().subtract('day', 1).valueOf();
    const endOfCurrRange = this.visibleEpochRange.endEpochRange;
    this.selectedDays[0] = (!this.selectedDays[0] || this.selectedDays.length === 0)
      ? this.projectScheduleService.getPrevTaskDay(endOfCurrRange)
      : this.projectScheduleService.getPrevTaskDay(this.selectedDays[0]);

    if (this.selectedDays[0] <= endOfPrevRange) {
      if (this.activeViewMode === ViewMode.Calendar) this.setVisibleCalendarRange(startOfPrevMonth);
      else this.setVisibleChartRange(startOfPrevRange, this.visibleDays);
    } else {
      this.projectScheduleService.setDaySelectionState(this.selectedDays);
    }
  }

  jumpToToday() {
    this.resetChartSelections();
    const startOfThisMonth = moment.utc(this.today).clone().startOf('month').valueOf();

    if (this.activeViewMode === ViewMode.Calendar) {
      this.setVisibleCalendarRange(startOfThisMonth);
    } else {
      this.setVisibleChartRange(this.today, this.visibleDays);
    }
  }

  jumpToStartDate() {
    this.resetChartSelections();
    if (this.activeViewMode === ViewMode.Calendar) {
      const projectStartMonth = moment.utc(this.projectStartDate).clone().startOf('month').valueOf();
      this.setVisibleCalendarRange(projectStartMonth);
    } else {
      this.setVisibleChartRange(this.projectStartDate, this.visibleDays);
    }
  }

  downloadPdf(pageLayout) {
    const data = document.getElementsByClassName('schedule-container')[0];
    const projectName = this.projectService.currentProject.name;
    const startHTMLString = `<!DOCTYPE html>
      <html lang="en">
      <head>
      <style>
        @page { size: ${pageLayout} landscape; }
        @media screen, print {
          text{
            fill: #a4a4b2;
            text-transform: uppercase;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            font-size: 11px;
            cursor: pointer;
          }
          text.label-header{
            font-size: 12px;
            font-weight: 800;
            fill: #14151c;
          }
          .x-axis-labels text.vertical {
            writing-mode: tb;
          }
        }
      </style>
      </head>
      <body>
      <h5>
        <center>
         ${projectName} - Lookahead Schedule
        </center>
      </h5>`;
    const endHTMLString = `</body>
      </html>`;

    const completeHTML =  startHTMLString + data.innerHTML + endHTMLString;
    const voiceBlob = URL.createObjectURL(
      new Blob([this.cleanstring(completeHTML)], { type: 'text/html' })
    );

    const myWindow = window.open(voiceBlob);
    setTimeout(() => { myWindow.print(); }, 10);
    myWindow.onfocus = () => { setTimeout(() => { myWindow.close(); }, 10); };

    return;
  }

  cleanstring(dirty) {
    const smartchr = [ '’', '‘', '“', '”', '–', '—', '…', '„', '‚' , '«', '»', '‹', '›'];
    const correctchr = ["'", "'", '"', '"', '-', '-', '...', '"', "'", '"', '"', "'", "'"];

    let thestring = dirty;
    let regex;
    for (let i = 0; i < smartchr.length; i++) {
      regex = new RegExp(smartchr[i], 'g');
      thestring = thestring.replace(regex, correctchr[i]);
    }

    return thestring;
  }

  jumpToEndDate() {
    this.resetChartSelections();

    if (this.activeViewMode === ViewMode.Calendar) {
      const projectEndMonth = moment.utc(this.projectEndDate).clone().startOf('month').valueOf();

      this.setVisibleCalendarRange(projectEndMonth);
    } else {
      const startOfEndWeek = moment.utc(this.projectEndDate).clone().utc().startOf('week').valueOf();

      this.setVisibleChartRange(startOfEndWeek, this.visibleDays);
    }
  }

  set1WeekView() {
    this.resetChartSelections();
    this.xAxisData = null;

    this.visibleDays = 7;
    this.activeViewMode = ViewMode.OneWeekSchedule;
    this.enableColorOptions = true;
    this.setVisibleChartRange(this.visibleEpochRange.startEpochRange, this.visibleDays);
  }

  set3WeekView() {
    this.resetChartSelections();
    this.xAxisData = null;

    this.visibleDays = 21;
    this.activeViewMode = ViewMode.ThreeWeekSchedule;
    this.enableColorOptions = true;
    this.setVisibleChartRange(this.visibleEpochRange.startEpochRange, this.visibleDays);
  }

  set6WeekView() {
    this.resetChartSelections();
    this.xAxisData = null;

    this.visibleDays = 42;
    this.activeViewMode = ViewMode.SixWeekSchedule;
    this.enableColorOptions = true;
    this.setVisibleChartRange(this.visibleEpochRange.startEpochRange, this.visibleDays);
  }

  setCalendarView() {
    this.resetChartSelections();
    this.xAxisData = null;

    this.visibleDays = 35;
    this.activeViewMode = ViewMode.Calendar;
    this.enableColorOptions = false;
    this.setVisibleCalendarRange(this.visibleEpochRange.startEpochRange);
  }

  setDayControlsState(resetColums: boolean): void {
    if (resetColums) {
      this.selectedDays = [];
      this.enableNextDayControl = true;
      this.enablePrevDayControl = true;
    } else {
      const lastDaySelected = this.selectedDays[0] === this.projectEndDate;
      const firstDaySelected = this.selectedDays[0] === this.projectStartDate;
      const tasksInView = this.scheduleChart ? this.scheduleChart.scheduleDataAvailable
        : this.scheduleCal ? this.scheduleCal.calDataAvailable
          : true;

      if (this.selectedDays.length > 1 || !tasksInView) {
        this.enableNextDayControl = false;
        this.enablePrevDayControl = false;
      } else {
        this.enabledPlayControl = !lastDaySelected;
        this.enableNextDayControl = !lastDaySelected;
        this.enablePrevDayControl = !firstDaySelected;
      }
    }
  }

  setupSideBar(): void {
    this.sidebarTabs = this.projectScheduleService.getSidebarTabs();
    this.forgeViewerService.resizeViewerAfterLoad();
    if (this.loadedMessage) {
      this.setSidebarState(this.sidebarTabs.find(tab => tab.key === SidebarState.TASK_MESSAGES));
      this.toggleSideBar(true);
    } else {
      this.setSidebarState(this.sidebarTabs[0]);
    }
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) this.projectService.toggleSidebarVisibility(isVisible);
    else this.projectService.toggleSidebarVisibility();

    this.checkToReformatControlsDisplay();

    // SetTimeout is used here so the viewer is resized after the CSS animation
    // to resize to correct width
    setTimeout(() => { 
      if(this.projectService.viewerMode === ViewerMode.ForgViewer)
        this.forgeViewerService.resizeViewerAfterLoad();
      else
        this.pdfViewerService.reloadPDF(this.projectService.showProjectSidebar);
      this.resizeChart(); }, 500);
  }

  setSidebarState(tab: ISidebarTab): void {
    this.sidebarTabs.map(item => item.active = false);
    tab.active = true;
    this.activeSidebarState = tab.key;
  }

  getSidebarData(selectedSteps?: IProjectScheduleStep[]): void {
    const stepsSelected = selectedSteps ? selectedSteps : this.selectedSteps;

    // Activity Info
    this.sidebarPlanStepIds = stepsSelected.map(step => step.projectPlanStepId);

    // Activity Messages
    this.sidebarStepIds = stepsSelected.map(step => step.projectStepId);

    // object properties
    this.selectedGritObjectIds = [];
    const selectedGritObjectIds = stepsSelected.map(step => step.objectIds ? step.objectIds : []);
    this.selectedGritObjectIds = Utils.deDupeArray([].concat(...selectedGritObjectIds));

    const stepsBySub = this.projectScheduleService.sortStepsBySubContractor(stepsSelected);

    this.sidebarStepData = this.projectScheduleService.createArrayForSidebarDisplay(stepsBySub, this.projectSubcontractors);
    this.selectedStepIdsForSidebar = [];
    for (const step of this.sidebarStepData) {
      for (const s of step.steps) {
        if (this.selectedStepIdsForSidebar.indexOf(step['projectPlanStepId']) === -1) this.selectedStepIdsForSidebar.push(s['projectPlanStepId']);
      }
    }
  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.activeSidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setDefaultChartState(): void {
    this.xAxisData = null;
    this.getViewerObjects(true, true);
    this.xAxisData = this.projectScheduleService.buildXaxisData(this.visibleEpochs, this.weatherForecast, this.projectMilestones, this.projectMaterials, this.selectedDays);
  }

  getViewerObjects(getActive: boolean, getPast: boolean): void {
    const observableArray: Array<Observable<any>> = [];
    const activeFilterType = this.projectScheduleService.getActiveFilterType();
    const activeFilters = this.projectScheduleService.getActiveFilters();
    const lastOfPrevRange = moment.utc(this.visibleEpochs[0]).clone().subtract('day', 1).valueOf();
    const activeStartParam = this.visibleEpochRange.startEpochRange;
    const activeEndDateParam = moment.utc(this.visibleEpochRange.endEpochRange).clone().endOf('week').startOf('day').valueOf();
    const pastEndParam = lastOfPrevRange;
    const disablePage = !this.animationIsPlaying;
    getPast = getPast ? this.firstSunInSchedule < lastOfPrevRange : false; // double check there is something to get in past

    if (getActive) observableArray.push(this.projectScheduleService.getScheduleObjects(this.selectedScheduleVersionId, activeFilters, activeFilterType, activeStartParam, activeEndDateParam));
    else observableArray.push(of([])); // pass empty observable so we can always rely on the result index

    if (getPast) observableArray.push(this.projectScheduleService.getScheduleObjects(this.selectedScheduleVersionId, activeFilters, activeFilterType, null, pastEndParam));

    forkJoin(observableArray).subscribe(
      res => {
        if (res[0]) {
          this.objectIdsInRange = res[0].ids ? res[0].ids : [];
          this.selectedObjectIds = !this.isDayScrolling ? this.objectIdsInRange : this.selectedObjectIds;
          this.projectStartDate = (res[0].dates && res[0].dates.startDate) ? moment.utc(res[0].dates.startDate).clone().startOf('day').valueOf() : this.projectStartDate;
          this.projectEndDate = (res[0].dates && res[0].dates.endDate) ? moment.utc(res[0].dates.endDate).clone().startOf('day').valueOf() : this.projectEndDate;
        }

        if (res[1]) {
          this.objectIdsInPast = res[1].ids ? res[1].ids : [];
        }

        this.setDayControlsState(false);
        this.setForgeViewer(this.selectedObjectIds, this.objectIdsInPast, 'click', disablePage);
        this.setGritRating();
        this.changingRanges = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'get schedule objects',
          action: 'get'
        };
        this.notificationService.error(err, context);
        this.errorGettingData = true;
      }
    );
  }

  async setForgeViewer(activeObjectIds: string[], pastObjectIds: string[], action: string, disablePage: boolean, defaultObjectIds?: string[]) {
    const objectIdMap = {};
    if (action === 'hover') {
      if (activeObjectIds.length > 0) {
        activeObjectIds.forEach(id => {
          if (!objectIdMap[id]) {
            objectIdMap[id] = id;
            this.forgeViewerService.setState({gritObjectId: id, type: ForgeViewerType.Hover});
          }
        });
      }
    } else {
      if (activeObjectIds.length > 0) {
        activeObjectIds.forEach(id => {
          if (!objectIdMap[id]) {
            objectIdMap[id] = id;
            this.forgeViewerService.setState({gritObjectId: id, type: ForgeViewerType.Select});
          }
        });
      }

      if (pastObjectIds.length > 0) {
        pastObjectIds.forEach(id => {
          if (!objectIdMap[id]) {
            objectIdMap[id] = id;
            this.forgeViewerService.setState({gritObjectId: id, type: ForgeViewerType.Default});
          }
        });
      }

      if (defaultObjectIds && defaultObjectIds.length > 0) {
        defaultObjectIds.forEach(id => {
          if (!objectIdMap[id]) {
            objectIdMap[id] = id;
            this.forgeViewerService.setState({gritObjectId: id, type: ForgeViewerType.Default});
          }
        });
      }

      if (action === 'click') await this.forgeViewerService.setViewerState(objectIdMap, disablePage);
    }
  }

  async setPDFViewer(activeObjectIds: string[], pastObjectIds: string[], action: string, disablePage: boolean, defaultObjectIds?: string[]) {
    const objectIdMap = {};
    if (action === 'hover') {
      this.pdfViewerService.howerOverActivity(activeObjectIds);
    }
    else if(action === 'hoverOut'){
      this.pdfViewerService.howerOverActivity(activeObjectIds);
    }
  }

  async setPDFViewerSelect(ActivityColorMap, action: string,){
    if (action === 'click') {
      this.pdfViewerService.selectActivities(ActivityColorMap);
    }
    else if(action === 'reset')
    {
      this.pdfViewerService.selectActivities(this.resettedActivityidColorMap);
    }
  }

  async setSelectionFilterCheck(check) {
    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.pdfViewerService.setSelectionFilterCheck(check);
    }
  }

  handleChartSelectionOutput(chartSelection: IScheduleSelectionOutput): void {
    if (this.changingRanges) return;
    if (chartSelection.action === 'hover' || chartSelection.action === 'hoverOut') {
      this.handleChartHover(chartSelection);
    } else if (chartSelection.action === 'reset') {
      this.handleChartReset(chartSelection);
    } else {
      this.handleChartClick(chartSelection);
    }
  }

  resetAndSelectAllActivities(activityIdColorMap: ActivityColorMap[] ){
    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.resettedActivityidColorMap = activityIdColorMap;
      this.pdfViewerService.selectActivities(activityIdColorMap);
    }
  }

  handleChartReset(chartSelection: IScheduleSelectionOutput): void {
    this.selectedObjectIds = this.objectIdsInRange;

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      const hoverActivityIds = [];
      // var activityIdColorMap : ActivityColorMap[] = [];
      this.setPDFViewerSelect(this.resettedActivityidColorMap, chartSelection.action);
    }
    else{
    this.setForgeViewer(this.objectIdsInRange, this.objectIdsInPast, chartSelection.action, true);
    }
    this.getSidebarData([]);
    this.setGritRating();
  }

  handleChartHover(chartSelection: IScheduleSelectionOutput): void {
    const hoverObjIds = [];
    const hoverActivityIds = [];
    if (chartSelection.action === 'hover') {
      chartSelection.steps.forEach(step => {
        const objIds = step.objectIds ? step.objectIds : null;
        if (objIds) objIds.forEach(id => hoverObjIds.push(id));

        const actIds = step.activities ? step.activities : null;
        if (actIds) actIds.forEach(id => hoverActivityIds.push(id));
      });

      // IF PDF viewer is loaded
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        this.setPDFViewer(hoverActivityIds, [] , chartSelection.action, false );
      }
      else {
        this.setForgeViewer(hoverObjIds, [], chartSelection.action, false);
      }
    } else {
        chartSelection.steps.forEach(step => {
          const objIds = step.objectIds ? step.objectIds : [];
          objIds.forEach(id => {
            if (!this.selectedObjectIds.includes(id)) hoverObjIds.push(id);
          });
        });
        if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
          this.setPDFViewer(hoverActivityIds, [] , chartSelection.action, false );
        }
        else {
          this.setForgeViewer(this.selectedObjectIds, this.objectIdsInPast, chartSelection.action, true, hoverObjIds);
        }
    }
  }

  handleChartClick(chartSelection: IScheduleSelectionOutput): void {
    const disablePage = !this.animationIsPlaying;
    const setGritRating = !this.animationIsPlaying && chartSelection.action !== 'hover' && chartSelection.action !== 'hoverOut';
    this.selectedSteps = chartSelection.steps;
    this.selectedObjectIds = [];
    const hoverActivityIds = [];
    var activityIdColorMap : ActivityColorMap[] = [];

    if (this.selectedSteps.length > 0) {
      let pastObjects = [];

      this.setSelectedObjectIdsFromSteps(this.selectedSteps);

      if (chartSelection.showPastSteps) {
        pastObjects = this.objectIdsInPast.concat(this.getPastObjectIdsFromRange(this.selectedSteps[0].epochStart));
      }

      this.selectedSteps.forEach( step => {
        const actIds = step.activities ? step.activities : null;
        if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

        if (step['subInfo']) {
          var subId = step['subInfo'].id;
          var color = this.projectSubcontractors.filter(subcontractor => subcontractor.id === subId)[0].hexCode;
          activityIdColorMap.push({activityId: actIds[0], color: color});
        }
      });

      if (this.projectService.viewerMode === ViewerMode.PDFViewer)
        this.setPDFViewerSelect(activityIdColorMap, chartSelection.action);
      else
        this.setForgeViewer(this.selectedObjectIds, pastObjects, chartSelection.action, disablePage);
      // If PDf Viewer is loaded

      this.getSidebarData(this.selectedSteps);
    } else {
      this.getSidebarData([]);
      if (this.projectService.viewerMode === ViewerMode.PDFViewer)
        this.setPDFViewerSelect(activityIdColorMap, chartSelection.action);
    }
    this.viewerObjectOutput(activityIdColorMap);

    if (setGritRating) this.setGritRating(this.selectedSteps);
  }

  viewerObjectOutput(output) {
    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      // Applying existing filters
      let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
      this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
    } else {
      if (!output.viewState) {
        this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
      } else {
        this.myViewerState = Utils.copyKeysFromObject(output.viewState);
      }
      this.forgeViewerService.setViewerState(this.myViewerState, output.disablePage);
    }
  }

  setSelectedObjectIdsFromSteps(input: IProjectScheduleStep[]) {
    input.forEach(step => {
      const objIds = step.objectIds ? step.objectIds : null;
      if (objIds) {
        objIds.forEach(id => this.selectedObjectIds.push(id));
      }
    });
  }

  getPastObjectIdsFromRange(toDate: number): string[] {
    if (toDate) {
      const activeStartParam = this.visibleEpochRange.startEpochRange;
      const objIdsInRange = toDate ? this.projectScheduleService.getObjectIdsFromLocalByRange(activeStartParam, toDate) : [];
      return objIdsInRange;
    } else {
      return this.objectIdsInPast;
    }
  }

  handleScheduleDropdownOutput(): void {
    this.noScheduleData = false;
    this.setupDisplayedSchedule(this.selectedScheduleVersionId);
  }

  decreaseViewSelection() {
    if (this.selectedViewWeekIndex >= this.projectScheduleService.viewOptions.length) {
      return;
    }

    this.selectedViewWeekIndex++;
    this.handleViewSelection(this.projectScheduleService.viewOptions[this.selectedViewWeekIndex]);
  }

  increaseViewSelection() {
    if (this.selectedViewWeekIndex <= 0) {
      return;
    }

    this.selectedViewWeekIndex--;
    this.handleViewSelection(this.projectScheduleService.viewOptions[this.selectedViewWeekIndex]);
  }

  handleViewSelection(selectedOption: any): void {
    const selectedValue = selectedOption && selectedOption.value;
    this.projectScheduleService.viewOptions.map(view => view.selected = false);
    if(selectedOption)  selectedOption.selected = true;

    switch (selectedValue) {
      case ViewMode.ThreeWeekSchedule:
        this.segmentService.track('Schedule: 3 week view clicked', { projectId: this.projectId });
        this.set3WeekView();
        break;
      case ViewMode.SixWeekSchedule:
        this.segmentService.track('Schedule: 6 week view clicked', { projectId: this.projectId });
        this.set6WeekView();
        break;
      case ViewMode.Calendar:
        this.segmentService.track('Schedule: Calendar view clicked', { projectId: this.projectId });
        this.setCalendarView();
        break;
      case ViewMode.OneWeekSchedule:
        this.segmentService.track('Schedule: 1 week view clicked', { projectId: this.projectId });
        this.set1WeekView();
        break;
      default:
        this.jumpToToday();
        break;
    }
  }

  handleColorSelection(selectedOption: any): void {
    this.projectScheduleService.colorOptions.map(option => option.selected = false);
    selectedOption.selected = true;
    this.activeColorMode = selectedOption.value;
  }

  handleDaySelectionOutput(selectedEpochs: number[]): void {
    this.selectedDays = selectedEpochs;
    this.setDayControlsState(false);
  }

  handleAnimationClick(action: string): void {
    if (action === 'play') {
      this.animationIsPlaying = true;
      this.animationInterval = setInterval(() => this.iterateAnimation(true), this.animationSpeed * 250);
      this.iterateAnimation(true);
      this.projectService.setDisabledSidebar(true);

    } else if (action === 'stop') {
      this.stopAnimation();
    }
  }

  handleAnimationSpeedClick(): void {
    if (this.animationSpeed === 4) this.animationSpeed = 2;
    else if (this.animationSpeed === 2) this.animationSpeed = 1;
    else if (this.animationSpeed === 1) this.animationSpeed = .5;
    else if (this.animationSpeed === .5) this.animationSpeed = .25;
    else if (this.animationSpeed === .25) this.animationSpeed = 4;
    else this.animationSpeed = 1;
  }

  getAnimationSpeedLabel(): string {
    if (this.animationSpeed === 4) return '.25x';
    else if (this.animationSpeed === 2) return '.5x';
    else if (this.animationSpeed === 1) return '1x';
    else if (this.animationSpeed === .5) return '2x';
    else if (this.animationSpeed === .25) return '4x';
    else return '1x';
  }

  iterateAnimation(run: boolean): void {
    const onLastDayOfProject = this.selectedDays[0] === this.projectEndDate;
    if (run && !onLastDayOfProject) {
        this.getNextDay();
    } else {
      this.stopAnimation();
    }
  }

  stopAnimation(): void {
    clearInterval(this.animationInterval);
    this.animationIsPlaying = false;
    if (this.selectedSteps) this.getSidebarData(this.selectedSteps);
    this.gritRating = this.projectScheduleService.getGritRating(this.selectedSteps);
    this.projectService.setDisabledSidebar(false);
  }

  handleSidebarStepHover(chartSelection: IScheduleSelectionOutput): void {
    const selectionAction = chartSelection.action;

    // Get object Ids that we are working with
    // Since this is a hover, the number of objects should not be large
    const activeObjIds = [].concat.apply([], chartSelection.steps.map(step => step.objectIds));
    const pastObjIds = [].concat.apply([], chartSelection.steps.map(step => step.objectIds));

    // Get appropriate states for the items
    const activeColorType = selectionAction === 'hover' ? ForgeViewerType.Hover :  ForgeViewerType.Select;
    const pastColorType = selectionAction === 'hover' ? ForgeViewerType.Hover :  ForgeViewerType.Default;

    // Set color values approproiately
    activeObjIds.forEach(objId => {
      this.forgeViewerService.setState({gritObjectId: objId, type: activeColorType});
    });

    pastObjIds.forEach(objId => {
      this.forgeViewerService.setState({gritObjectId: objId, type: pastColorType});
    });
  }

  handleSidebarStepClick(step: IProjectScheduleStep): void {
    if (step.projectStepId) {
      this.resetChartSelections();
      this.scheduleChart.selectBar(step.id);
      this.activeSidebarState = SidebarState.TASK_INFORMATION;
      this.sidebarTabs.find(s => s.key === SidebarState.TASK_LIST).active = false;
      this.sidebarTabs.find(s => s.key === SidebarState.TASK_INFORMATION).active = true;

      this.segmentService.track('Task List Task Clicked', {projectId: this.projectId});
    }
  }

  setNoDataDisplay(type: string): void {
    switch (type) {
      case 'no-data' :
        this.noScheduleData = true;
        this.showRetryBtn = false;
        this.noScheduleDisplayMessage = this.scheduleCreationEnabled
          ? this.noDataMessages[1]
          : this.noDataMessages[2];
        break;
      case 'no-active-data' :
        this.noScheduleData = true;
        this.showRetryBtn = false;
        this.noScheduleDisplayMessage = this.noDataMessages[3];
        break;
      case 'empty-schedule' :
        this.errorGettingData = true;
        this.showRetryBtn = false;
        this.noScheduleDisplayMessage = this.noDataMessages[4];
        break;
      default :
        this.errorGettingData = true;
        this.showRetryBtn = true;
        this.noScheduleDisplayMessage = this.noDataMessages[0];
        break;
    }
  }

  onResize(): void {
    this.checkToReformatControlsDisplay();
  }

  /*-- Checks screen to determine whether or not to reposition controls --*/
  checkToReformatControlsDisplay(): void {
    const windowWidth = window.innerWidth;
    const sbWidth = this.projectService.showProjectSidebar ? 300 : 0;
    const menuWidth = JSON.parse(localStorage.getItem('showMainMenu')) ? 155 : 0;
    const scheduleViewport = windowWidth - sbWidth - menuWidth;

    this.reformatControlsDisplay = scheduleViewport <= 870;
  }

  // Material data handling
  handleMaterials(materials: IProjectMaterial[]) {
    if (materials.length > 0) {
      this.modalCurrentOffset = 0;
      this.modalCurrentItem = materials[this.modalCurrentOffset];
      this.modalCurrentList = materials;
      this.modalDataType = 'Material';

      this.dataModalInput.displayMessage = materials.length > 1 ? 'MATERIALS' : 'MATERIAL';
      this.showDataModal = true;
    }
  }

  handleMilestones(milestones: IProjectScheduleMilestone[]) {
    if (milestones.length > 0) {
      this.modalCurrentOffset = 0;
      this.modalCurrentItem = milestones[this.modalCurrentOffset];
      this.modalCurrentList = milestones;
      this.modalDataType = 'Milestone';

      this.dataModalInput.displayMessage = milestones.length > 1 ? 'MILESTONES' : 'MILESTONE';
      this.showDataModal = true;
    }
  }

  setGritRating(steps?: IProjectScheduleStep[]): void {
    const activeSteps = steps
      ? steps
      : this.projectScheduleService.getStepsFromLocalByRange(this.visibleEpochRange.startEpochRange, this.visibleEpochRange.endEpochRange);

    this.gritRating = this.projectScheduleService.getGritRating(activeSteps);
  }

  formatDate(date: number) {
    return Utils.formatDate(date);
  }

  formatStringList(strings: string[]) {
    return strings.join(', ');
  }

  handleDataChange(shift: number) {
    const proposedOffset = this.modalCurrentOffset + shift;
    const totalMaterials = this.modalCurrentList.length;
    if (proposedOffset >= 0 && proposedOffset < totalMaterials) this.modalCurrentOffset = proposedOffset;
    if (proposedOffset < 0 && proposedOffset < totalMaterials) this.modalCurrentOffset = this.modalCurrentList.length - 1;
    if (proposedOffset >= 0 && proposedOffset >= totalMaterials) this.modalCurrentOffset = 0;
    this.modalCurrentItem = this.modalCurrentList[this.modalCurrentOffset];
  }

  getActivtyText(activityIds: string[]) {
    return activityIds.length !== 0 ? activityIds.map(activity => this.projectService.getLocalActivity(activity).name).sort() : 'None';
  }

  drawChart(isResize: boolean): void {
    if (this.scheduleChart && this.activeViewMode !== ViewMode.Calendar) {
      this.scheduleChart.drawChart(isResize);
    }
    if (this.scheduleCal && this.activeViewMode === ViewMode.Calendar) {
      this.scheduleCal.drawCalendar(isResize);
    }
  }

  filterModel(filters: string[]): void {
    this.activeFilters = filters;
    this.filtersApplied = filters.length < 1 ? false : true;

    // if (true) {
      if (!this.filtersApplied && !this.searchQuery) {
        if (this.viewerCheck) {
          if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
            this.myViewerState = Utils.copyKeysFromObject(this.projectObjectService.getLocalNonHiddenObjects());
            this.forgeViewerService.setViewerState(this.myViewerState);
          } else {
            let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
            this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
          }
        }
      } else {
        this.commonFilterAndSelectionChannel();
      }
    // } else {
    //   let pdfIds = this.getPDFActivitiesMatchingSelections([], FilterModelOptions.FloorPlan, this.orAndFilter);
    //   this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
    // }
    this.resetChartSelections();
    this.projectScheduleService.setActiveFilters(filters);
    this.getViewerObjects(true, true);
    this.drawChart(false);
  }

  cleanSearchAndFilter() {
    this.filterModel([]);
  }

  // ACTIVITY DATA
  getActivityData(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const getActivitiesRes = await this.masterScheduleService.getActivities();
      if (getActivitiesRes) {
        this.activityInput = getActivitiesRes;
        this.prereqAutoInput = this.masterScheduleService.setPrereqAutoInput(this.activityInput);
        return resolve(true);
      } else {
        return resolve(false);
      }
    });
  }

  async commonFilterAndSelectionChannel() {
    if (this.filtersApplied) {
      if (this.viewerCheck) {
        if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
          this.projectService.setFilteringModel(true);
          this.myViewerState = Utils.copyKeysFromObject(await this.filterSearchService.getFilterData(this.activeFilters, this.activeFilterType, this.orAndFilter));
          this.forgeViewerService.setViewerState(this.myViewerState);
        } else {
          let pdfIds = this.getPDFActivitiesMatchingSelections(this.activeFilters, this.activeFilterType, this.orAndFilter);
          this.pdfViewerService.fileterPDFFiles(this.getPDFUrlsForPDFIds(pdfIds));
        }
      }
    } else {
      if (this.searchQuery) {
      }
    }
  }

  // Search viewerbased on viewerCheck toggle
  async setViewerCheck(check) {
    this.viewerCheck = check;
  }

  getPDFActivitiesMatchingSelections(selections: string[], filterOption: FilterModelOptions, orFilterType: boolean): string[] {
    let chartInput = this.activityInput;
    let pdfIds = [];
    if (selections.length) {
      switch (filterOption) {
        case FilterModelOptions.Activities:
          selections.forEach(sItem => {
            chartInput.forEach(item => {
              if (item.activity.id === sItem) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          });
          break;
        case FilterModelOptions.SubContractor:
          selections.forEach(sItem => {
            chartInput.forEach(item => {
              if (item.subContractorId === sItem) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          });
          break;
        case FilterModelOptions.FloorPlan:
          pdfIds.push(...selections);
          // if (orFilterType) {
          //   selections.forEach(sItem => {
          //     chartInput.forEach(item => {
          //       if (item.activity.pdfIds.includes(sItem)) {
          //         pdfIds.push(...item.activity.pdfIds);
          //       }
          //     });
          //   });
          // } else {
          //   chartInput.forEach(item => {
          //     if (Utils.checker(item.activity.pdfIds, selections)) {
          //       pdfIds.push(...item.activity.pdfIds);
          //     }
          //   });
          // }

          break;
        case FilterModelOptions.Equipment:
          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.equipmentIds.includes(sItem)) {
                  pdfIds.push(...item.activity.pdfIds);
                }
              });
            });
          } else {
            chartInput.forEach(item => {
              if (Utils.checker(item.activity.equipmentIds, selections)) {
                pdfIds.push(...item.activity.pdfIds);
              }
            });
          }
          break;
        case FilterModelOptions.Materials:
          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.materialIds.includes(sItem)) {
                  pdfIds.push(...item.activity.pdfIds);
                }
              });
            });
          }
      }
    } else {
      pdfIds = null;
    }

    return pdfIds;
  }

  getPDFUrlsForPDFIds(pdfIds: string[]): string[] {
    if (!pdfIds)
      return null;

    pdfIds = Utils.deDupeArray(pdfIds);

    let pdfUrls = [];
    pdfIds.forEach(pdfId => {
      const pdfObject = this.projectService.getPDFObjectForId(pdfId);
      pdfUrls.push(pdfObject.url);
    });

    return pdfUrls;
  }

  setFilterType(type?: FilterModelOptions, setViewer: boolean = true): void {
    const filterType = type;
    this.activeFilterType = filterType;
    this.activeFilters = [];
    this.filtersApplied = false;
    this.projectScheduleService.setActiveFilterType(filterType);
    this.projectScheduleService.setActiveFilters([]);
    if (setViewer) this.getViewerObjects(true, true);
  }

  resetViewAndSelections(): void {
    this.getViewerObjects(true, true);
    this.resetChartSelections();
  }

  resetChartSelections(): void {
    this.isDayScrolling = false;
    this.objectIdsInPast = [];
    this.selectedObjectIds = [];
    this.selectedDays = [];
    this.setDayControlsState(true);
    this.getSidebarData([]);
    this.setGritRating();

    if (this.scheduleCal && this.activeViewMode === ViewMode.Calendar) {
      this.scheduleCal.resetAllSelections();
    } else {
      this.scheduleChart.resetAllSelections();
    }
  }

  goToPage(page: string): void {
    const route = '/project/' + this.projectId + '/' + page;
    this.router.navigateByUrl(route);
  }

  resizeChart(): void {
    this.drawChart(true);
  }

  handleQuickLink() {
    if (this.loadedMessage) {
      this.setSidebarState(this.sidebarTabs.find(tab => tab.key === SidebarState.TASK_MESSAGES));
      this.toggleSideBar(true);
    }
    const schedule = this.projectScheduleService.getLocalScheduleData();
    const day = Object.keys(schedule).find(key => schedule[key].steps.filter(step => step.projectStepId === this.loadedStepId).length > 0);
    if (day) {
      this.setVisibleChartRange(Number(day), this.visibleDays);
    } else {
      this.loadedStepId = null;
      if (this.projectScheduleService.getStepsFromLocalScheduleByDay(this.today).length > 0) {
        this.setVisibleChartRange(this.today, this.visibleDays);
      } else {
        this.setVisibleChartRange(this.projectStartDate, this.visibleDays);
      }
    }
  }

  openInProcore(externalId: number) {
    const procoreProjectId = this.projectService.currentProject.procoreProjectId;
    //  const host = this.projectMaterialService.getProcoreHost();
    const url = '/' + procoreProjectId + '/project/submittal_logs/' + externalId;
    window.open(url);
  }
}
