
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable , ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { NotificationService } from '../../services/notification/notification.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Utils } from '../../utils/utils';

import { IGritTable, IGRow, IGRowItem } from '../../shared/grit-table/grit-table';
import { INoficationContext } from '../../models/notification/notification.interface';
import { Permission } from '../../utils/enums/permission.enum';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-accounts-trial',
  templateUrl: './accounts-trial.component.html',
  styleUrls: ['../accounts.component.scss']
})
export class AccountsTrialComponent implements OnInit, OnDestroy {
  accountId: string;
  accounts: any[];
  tableData: IGritTable;

  // Subscription
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private accountService: AccountService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.accountId = params['accountId'];

      this.getData();
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getData(): void {
    this.accountService.getTrials().pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.accounts = Utils.sortByString(res, 'name');
        this.tableData = this.accountService.transformTrialData(res);
      },
      err => {
        const context: INoficationContext = {
          type: 'account trial(s)',
          action: 'get'
        };

        this.notificationService.error(err, context);
      });
  }

  deleteRows(rowIds: string[]) {
    const observableArray: Array<Observable<any>> = new Array();
    rowIds.forEach(id => {
      observableArray.push(this.accountService.updateTrial(id, null));
    });

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.getData();
      },
      err => {
        const context: INoficationContext = {
          type: 'trial',
          action: 'remove'
        };

        this.notificationService.error(err, context);
        this.getData();
    });
  }

  edit(row: IGRow) {
    this.accountService.updateTrial(row.key, row.rowItems[1].value).subscribe(
      () => {
        // noop
      },
      err => {
        const context: INoficationContext = {
          type: 'account user(s)',
          action: 'edit'
        };
        this.notificationService.error(err, context);
        this.getData();
      });
  }
}
