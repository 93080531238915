import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map ,  startWith } from 'rxjs/operators';

import { ColorPaletteService } from '../../color-palette/color-palette.service';
import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { ProjectService } from '../project.service';
import { Utils } from '../../../utils/utils';

import { IColorPalette } from '../../../models/color-palette/color-palette.interface';
import { EditType, IColHeader, IFilter, IFilterOptions, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { IMatAutoComplete } from '../../../models/material/material-components.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISubContractor } from '../../../models/subcontractor/subcontractor.interface';
import { IUserPermission } from '../../../models/user/user.interface';

@Injectable()
export class ProjectSubContractorService {
  static unassignedSubcontractor: IProjectSubContractor = {
    id: 'unassigned',
    subContractorId: 'unassigned',
    name: 'Unassigned',
    abbreviation: 'unassigned',
    activities: [],
    hexCode: '#a4a4b2',
    availableCrew: 0
  };

  public tablePageSize: number = 20;

  constructor(
    private httpService: HttpBackendService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private formBuilderService: FormBuilder,
    private colorPaletteService: ColorPaletteService
  ) { }

  private projectSubcontractors: IProjectSubContractor[] = [];
  private accountSubcontractors: ISubContractor[] = [];

  public addProjectSubcontractor(json: any): Observable<any> {
    return this.httpService.post('/project/subContractor', json);
  }

  public deleteProjectSubcontractors(projectId: string, subContractorIds: string[]): Observable<any> {
    return this.httpService.post('/project/' + projectId + '/subContractors/delete', {subContractorIds: subContractorIds});
  }

  public getOwnerAccount(projectId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/account');
  }

  public getAccountSubcontractors(projectId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/availableSubContractors');
  }

  public getAssociatedSubContractors(projectId: string, includeCrew?): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/subContractors' + (includeCrew ? '?calculateCrew=true' : ''));
  }

  public getList(projectId: string): Observable<IProjectSubContractor[]> {
    return this.httpService.get('/project/' + projectId + '/subContractors');
  }

  public setLocalProjectSubcontractors(projectId: string): Promise<boolean> {
    this.projectSubcontractors = [];
    return new Promise((resolve) => {
      this.httpService.get('/project/' + projectId + '/subContractors').subscribe(
        res => {
          this.projectSubcontractors = res;
          return resolve(true);
        },
        err => {
          this.notificationService.error(err, { type: 'subcontractors', action: 'get' });
          return resolve(false);
        }
      );
    });
  }

  public setLocalAccountSubcontractors() {
    this.accountSubcontractors = [];
    return new Promise((resolve) => {
      this.getAccountSubcontractors(this.projectService.currentProject.id).subscribe(
        res => {
          this.accountSubcontractors = res;
          return resolve(true);
        },
        err => {
          this.notificationService.error(err, { type: 'account subcontractors', action: 'get' });
          return resolve(false);
        }
      );
    });
  }

  public getLocalProjectSubcontractors(): IProjectSubContractor[] {
    return this.projectSubcontractors;
  }
  public getLocalProjectSubcontractor(subContractorId: string): IProjectSubContractor {
    return this.projectSubcontractors.find(sub => sub.id === subContractorId);
  }
  public getLocalProjectSubsByAccountSub(subContractorId: string): IProjectSubContractor[] {
    return this.projectSubcontractors.filter(sub => sub.subContractorId === subContractorId);
  }
  public getLocalAccountSubcontractors(): ISubContractor[] {
    return this.accountSubcontractors;
  }

  public getProjectSubcontractorByActivityId(activityId: string) {
    let subContractor;
    if (this.projectSubcontractors && !Utils.objectIsEmpty(this.projectSubcontractors)) {
      Object.keys(this.projectSubcontractors).filter(subId => {
        if (this.projectSubcontractors[subId].activities.includes(activityId)) subContractor = this.projectSubcontractors[subId];
      });
    }

    return subContractor;
  }

  transformToTableData(dataInput: any, permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'role',
        colKey: 'name',
        type: RowItemType.Text,
        width: '30%'
      },
      {
        displayName: 'company',
        colKey: 'accountSubContractorName',
        type: RowItemType.Text,
        width: '20%'
      },
      {
        displayName: 'activities',
        colKey: 'activities',
        type: RowItemType.Text,
        width: '50%'
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(sub => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: sub.name,
          editable: permission.edit && permission.gc
        },
        {
          colKey: 'accountSubContractorName',
          value: sub.accountSubContractorName
        },
        {
          colKey: 'activities',
          value: sub.activities.length > 0
            ? sub.activities.map(activity => this.projectService.getLocalActivity(activity).name).sort().join(', ')
            : 'None Assigned'
        }
      ];
      rows.push(
        {
          key: sub.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.edit && permission.gc,
            rowEdits: permission.edit && permission.gc ? [{type: EditType.ModalEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const roleFilter: IFilter = {
      title: 'Only show roles used',
      selected: false,
      filterFn: this.roleFilter
    };

    const filterOptions: IFilterOptions = {
      filters: [roleFilter]
    };

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      filterOptions: filterOptions,
      addOptions: {
        addPermission: permission.edit && permission.gc
      }
    };

    return retTable;
  }

  roleFilter(currentRows: IGRow[]): IGRow[] {
    let rItem: IGRowItem;
    for (let x = currentRows.length - 1; x > -1; x--) {
      rItem = currentRows[x].rowItems.find(ri => ri.colKey === 'activities');
      if (rItem) {
        if (rItem.value === 'None Assigned') {
          currentRows = currentRows.slice(0, x).concat(currentRows.slice(x + 1));
        }
      }
    }
    return currentRows;
  }

  async buildFormInput(formInput: IProjectSubContractor, projectId: string): Promise<FormGroup> {

    await this.setLocalAccountSubcontractors();
    await this.colorPaletteService.setAvailableColorPalettes(projectId, formInput ? formInput.id : null);

    const subcontractorFormInput = this.formBuilderService.group({
      id: [null],
      name: [null, Validators.required],
      abbreviation: [null, Validators.required],
      color: [null],
      subContractorId: [null],
      activities: [[]]
    });

    if (formInput) {
      subcontractorFormInput.controls['id'].setValue(formInput.id);
      subcontractorFormInput.controls['name'].setValue(formInput.name);
      subcontractorFormInput.controls['abbreviation'].setValue(formInput.abbreviation);
      subcontractorFormInput.controls['color'].setValue(formInput.hexCode);
      if (formInput.subContractorId) subcontractorFormInput.controls['subContractorId'].setValue(formInput.subContractorId);
      if (formInput.activities) subcontractorFormInput.controls['activities'].setValue(formInput.activities);
    }

    return subcontractorFormInput;
  }

  buildSubAutoInput(projectSubcontractor: IProjectSubContractor) {
    const accountSubcontractors = Utils.sortByString(this.getLocalAccountSubcontractors(), 'name');

    const subAutoInput: IMatAutoComplete = {
      formControl: new FormControl(),
      listOptions: null,
      displayFn: this.displayFn
    };
    subAutoInput.listOptions = subAutoInput.formControl.valueChanges.pipe(
      startWith<string | ISubContractor>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.subSearch(name) : accountSubcontractors.slice(0, Math.min(accountSubcontractors.length, 50)))
    );
    if (projectSubcontractor && projectSubcontractor.subContractorId) subAutoInput.formControl.setValue(accountSubcontractors.find(sub => sub.id === projectSubcontractor.subContractorId));
    return subAutoInput;
  }

  displayFn(item: any): string {
    return item && item.name ? item.name : undefined;
  }

  subSearch(name: string): IProjectSubContractor[] {
    const accountSubcontractors = Utils.sortByString(this.getLocalAccountSubcontractors(), 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = accountSubcontractors.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  buildColorAutoInput(projectSubcontractor: IProjectSubContractor) {
    const colors: IColorPalette[] = Utils.sortByString(this.colorPaletteService.getLocalAvailableColorPalettes(), 'name');

    const colorAutoInput: IMatAutoComplete = {
      formControl: new FormControl(),
      listOptions: null,
      displayFn: this.displayFn
    };
    colorAutoInput.listOptions = colorAutoInput.formControl.valueChanges.pipe(
      startWith<string | IColorPalette>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.colorSearch(name) : colors.slice(0, Math.min(colors.length, 50)))
    );
    if (projectSubcontractor && projectSubcontractor.hexCode) colorAutoInput.formControl.setValue(colors.find(color => color.hex === projectSubcontractor.hexCode));
    return colorAutoInput;
  }

  colorSearch(name: string): IProjectSubContractor[] {
    const colors = Utils.sortByString(this.colorPaletteService.getLocalAvailableColorPalettes(), 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = colors.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

}
