
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectFilterSearchService } from '../../../services/project/project-filter-search/project-filter-search.service';
import { ProjectObjectService } from '../../../services/project/project-object/project-object.service';
import { ProjectPlannerPopoutModalService } from '../../../services/project/project-planner-popout-modal/project-planner-popout-modal.service';
import { ProjectPlannerService } from '../../../services/project/project-planner/project-planner.service';
import { ProjectStepService } from '../../../services/project/project-step/project-step.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectWorkPackagesService } from '../../../services/project/project-work-packages/project-work-packages.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';

import { IActivity } from '../../../models/activity/activity.interface';
import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { IProjectHomeworkFeedConfig, IProjectHomeworkItem } from '../../../models/project/project-dashboard/project-dashboard.interface';
import { IProjectStep } from '../../../models/project/project-step/project-step.interface';
import { IPlannerTaskListItem } from '../project-task-list/project-task-list.component';
import { ISelectionModal } from '../../../models/selection-modal/selection-modal.interface';
import { IPageSpecificFilter } from '../../../models/sidebar/sidebar.interface';
import { IWorkPackage } from '../../../models/work-package/work-package.interface';

import { ButtonState } from '../../../utils/enums/button-state.enum';
import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../../utils/enums/forge-viewer-type';
import { EventStatus } from '../../../utils/enums/notification.enum';
import { PopupModalState } from '../../../utils/enums/popup-modal-state.enum';
import { StepStatus } from '../../../utils/enums/step-status.enum';

import { Utils } from '../../../utils/utils';

enum HeaderMessages {
  Default = 'default',
  ActivitySelected = 'activitySelected',
  WorkPackageSelected = 'workPackageSelected'
}
@Component({
  selector: 'app-project-planner-form',
  templateUrl: './project-planner-form.component.html',
  styleUrls: ['./project-planner-form.component.scss']
})
export class ProjectPlannerFormComponent implements OnDestroy {

  @Input() prereqActivitiesInput;
  filteredPrereqActivities = {};

  @Output() notifyStepIds: EventEmitter<string[]> = new EventEmitter();
  @Output() notifySelectedGritObjIds = new EventEmitter();
  @Output() disablePageFiltersOutput = new EventEmitter();
  @Output() addEditTaskOutput: EventEmitter<IProjectStep> = new EventEmitter();
  @Output() startAddingObjects: EventEmitter<boolean> = new EventEmitter();
  @Output() loadedActivityFilter: EventEmitter<string[]> = new EventEmitter();
  @Output() homeworkActivityOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() selectedActivityOutput: EventEmitter<IActivity> = new EventEmitter();
  @Output() workPackageSelectionOutput: EventEmitter<IWorkPackage> = new EventEmitter();
  @Output() updateActiveFiltersOutput: EventEmitter<{filters: string[], type: FilterModelOptions}> = new EventEmitter();

  @ViewChild('homeworkFeed') homeworkFeed;
  @ViewChild('workPackages') workPackages;

  private readonly headerMessages = {
    default: 'select_activity_or_object_to_plan',
    activitySelected: 'add_or_edit_work_package',
    workPackageSelected: (disableTaskCreation) => disableTaskCreation ? 'add_objects_to_work_package' : 'edit_work_package'
  };

  // Template
  hasFocus: boolean = false;
  selectedActivityId: string;
  selectedObjects = {};
  selectedPrereqs = {};
  selectedActivityPrereqs = {};
  selectedActivityPrereqObjects = {};
  addingToKit: boolean = false;
  selectingPrereqs: boolean = false;
  objectKeys = Object.keys; // used for ngFor loop on object in template
  enableShowHideButton: boolean = true;
  disableCurStepUpdate: boolean = false;
  headerMessage: string = '';
  taskCreationDisabled: boolean = true;
  saveDisabled: boolean = true;
  showActivityFilters: boolean = false;
  showActivitySearch: boolean = false;
  activitySearchQuery: string;
  homeworkFeedConfig: IProjectHomeworkFeedConfig = {
    header: 'activities',
    showIntro: true,
    pageSize: 25,
    showLoadMore: true,
    itemsSelectable: true
  };
  wpPrereqFormInput: FormGroup;

  // Helpers
  private activeFilterType: FilterModelOptions;
  private hoveredProjectObjectId: string = null;
  private stepIdToDelete: string = null;
  private deletedIds: string[] = [];
  private prevActiveFilters: string[] = [];
  private prevFilterType: FilterModelOptions = FilterModelOptions.Activities;
  private tempPrereqFilterIds: string[] = [];
  showCurrObjectList: boolean = false;
  showPrereqObjectList: boolean = false;

  // Component Inputs
  activityAuthority: string[];
  activeFilters = [];
  exceptionActivities = []; // Used for removing from selection modal for subs
  activitySelection: ISelectionModal;
  taskList = [];
  recommendedTasks = []; // list of tasks that we recommeneded based on last updated task in activity

  // Project info
  private projectId: string;
  private allActivities: string[];

  // Forge Viewer
  private myViewerState = {};

  // Object structures
  private objects = {};
  private authenticatedObjects = {};
  private curKitObjects = {};
  private curFilterObjects = {};
  private colorKitPreqs = {};

  // Modes
  private hideThingsAlreadyPlanned: boolean = false;
  private hidingOtherWork: boolean = false;
  private wasHidingAlreadyPlanned: boolean = false;

  // Step info
  loadedStepId: string;

  // Activity Filter
  loadedActivityId: string;

  // Current Selection Data
  curGritObjectId: string = '';
  curObjectData = null;
  curSelectionActivities = [];
  curPlanningActivity = { name: '', activity: '' };
  curPrereqActivity = { name: '', activity: '' };

  // Popup Modal
  showModal: boolean = false;
  modalState: PopupModalState = PopupModalState.None;
  popupModalState = PopupModalState;
  showDeleteTaskConfirmationBox = false;
  deleteTaskConfirmationModalInput: IConfirmationModalInput = {
    displayMessage: 'Are you sure you want to permanently delete this task?',
    hasCancelAction: true,
    hasConfirmationAction: false,
    hasDeleteAction: true,
    customContent: false
  };
  showParentModal: boolean = false;

  // Activity Prereqs
  showActivityPrereqModal: boolean = false;
  prereqActivityInput: string = '';
  selectedPrereqActivity;

  // Subscriptions
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectObjectService: ProjectObjectService,
    private projectStepService: ProjectStepService,
    private notificationService: NotificationService,
    private forgeViewerService: ForgeViewerService,
    private projectPlannerService: ProjectPlannerService,
    private projectPlannerPopoutModalService: ProjectPlannerPopoutModalService,
    private projectService: ProjectService,
    private filterSearchService: ProjectFilterSearchService,
    private projectWorkPackageService: ProjectWorkPackagesService,
    private projectSubcontractorService: ProjectSubContractorService
  ) {}

  ngOnDestroy(): void {
    this.setDisablePage(false);
    window.removeEventListener('keydown', this.keyPress);
    window.removeEventListener('keyup', this.keyRelease);

    this.projectPlannerService.destroyOpenSubscriptions();

    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async loadAllSetup(input) {
    SegmentService.track(`Planner Form: Loaded`, {projectId: this.projectService.currentProject.id});
    this.headerMessage = this.headerMessages.default;
    this.listenersSetup();
    this.objects = input.objects;
    this.activeFilterType = input.activeFilterType;
    this.authenticatedObjects = input.authenticatedObjects;
    this.loadedStepId = input.loadedStepId;
    this.loadedActivityId = input.loadedActivityId;
    this.projectId = input.projectId;
    this.activityAuthority = input.activityAuthority;
    this.exceptionActivities = input.exceptionActivities;
    this.allActivities = input.allActivities;

    this.myViewerState = Utils.copyKeysFromObject(this.objects);
    await this.setInitViewer();

    if (this.loadedActivityId) {
      this.hideThingsAlreadyPlanned = true;
      this.wasHidingAlreadyPlanned = true;
      const loadedActivity = this.projectService.getLocalActivity(this.loadedActivityId);
      const objects = loadedActivity.objectIds;

      // if an activity isn't fully planned it will not have an originalStartDate
      // we do not want to highlight any objects but still filter the activity
      if (loadedActivity.originalStartDate != null) {
        this.curGritObjectId = objects[0];
        this.curObjectData = this.projectObjectService.getLocalObject(this.curGritObjectId);
      }

      await this.runSelectedKitActivity({name: loadedActivity.name, activity: loadedActivity.id});
      this.loadedActivityFilter.emit([this.loadedActivityId]);
    }
  }

  async setInitViewer() {
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitObjectsToView(this.myViewerState);
  }

  listenersSetup(): void {
    window.addEventListener('keydown', this.keyPress);
    window.addEventListener('keyup', this.keyRelease);

    this.projectPlannerPopoutModalService.reset();
    this.activityListenerSetup();
    this.clearSelections();
  }

  activityListenerSetup() {
    // Enables data sharing for popped out viewer
    this.projectPlannerPopoutModalService.selectedActivity.pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (!Utils.isEmpty(res)) this.confirmActivityClick(res);
      }
    );

    this.projectPlannerPopoutModalService.showActivityModal.pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (!this.forgeViewerService.poppedOut) {
          this.showModal = res;
          if (this.showModal) {
            this.modalState = PopupModalState.SelectActivity;
          } else {
            this.modalState = PopupModalState.None;
          }
        } else if (this.forgeViewerService.poppedOut && !res) {
          this.closeConfirmationClick();
        }
      }
    );

    this.projectPlannerPopoutModalService.activitySelection.pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.activitySelection = res;
      }
    );
  }

  callPopoutSetup(): void {
    this.setInitViewer();
    this.setViewerSelections();
  }

  async startSelectingPrereqs() {
    this.wasHidingAlreadyPlanned = this.hideThingsAlreadyPlanned;
    this.disablePageFiltersOutput.emit(true);
    this.enableShowHideButton = false;
    this.selectingPrereqs = true;
    this.hideThingsAlreadyPlanned = false;
    this.prevActiveFilters = cloneDeep(this.activeFilters);
    this.prevFilterType = this.activeFilterType;
    this.tempPrereqFilterIds = this.projectService.getLocalActivity(this.curPlanningActivity.activity).prerequisites.map(pre => pre.activity);
    this.tempPrereqFilterIds.push(this.curPlanningActivity.activity);
    this.activeFilters = Utils.deDupeArray(this.activeFilters.concat(this.tempPrereqFilterIds));
    if (this.activeFilterType !== FilterModelOptions.Activities) this.activeFilterType = FilterModelOptions.Activities;
    this.updateActiveFiltersOutput.emit({filters: this.activeFilters, type: this.activeFilterType});
    await this.applyFilterOnly();
    this.startAddingObjects.emit(true);
  }

  async handleForgeViewerOutput(gritViewerOutputData) {
    if (!this.showModal) {
      if (gritViewerOutputData.length > 0) {
        this.curGritObjectId = gritViewerOutputData[0];
        if (!this.objects[this.curGritObjectId]) return;

        this.curObjectData = this.projectObjectService.getLocalObject(this.curGritObjectId);

        // Figure out what to do with the selection
        if (this.addingToKit) {
          gritViewerOutputData.forEach(selection => {
            this.curGritObjectId = selection;
            this.curObjectData = this.projectObjectService.getLocalObject(this.curGritObjectId);
            if (gritViewerOutputData.length === 1 && this.selectedObjects[selection]) {
              this.removeObject(this.curGritObjectId);
            } else {
              this.runAddingToKit(this.curGritObjectId);
            }
          });
        } else if (this.selectingPrereqs) {
          // Allow clicking on the same prereq, could have two activity types that need to be prereqs
          if (this.workPackages && this.workPackages.selectedWp) {
            let objectInPrereqWp = false;
            this.workPackages.selectedWp.prerequisiteWorkPackages.forEach(wp => {
              objectInPrereqWp = wp.objectIds.some(oId => oId === this.curGritObjectId);
            });

            if (!objectInPrereqWp) this.runAddingToPrereqs();
            else {
              this.notificationService.eventPopup(EventStatus.Info, 'object_in_prereq_work_package');
              return;
            }
          }

        } else if (!this.selectedActivityId) {
          this.runNewSelection();
        }
      }
    }
  }

  // Called by forge viewer output handler
  async runNewSelection() {
    // Reset any selections because this is a click in no mode
    // Check to see if we have permissions to plan that object by looking through activities
    // If so, bring up activity options, then check to see if it has a plan step
    // If has plan step, then populate all the data; if not, just populate the objects field

    this.setDisablePage(true);
    this.clearSelections();
    this.curSelectionActivities = this.curObjectData.activities;
    const planningActivities = [];
    this.curSelectionActivities.forEach(tc => { if (this.activityAuthority.includes(tc)) planningActivities.push(tc); });
    const curActivityData = [];
    let displayType = ButtonState.None;
    let status = '';
    const plannedActivities = await this.projectStepService.getPlannedActivitiesByObjectId(this.projectId, this.curGritObjectId).toPromise();
    for (const i of planningActivities) {
      displayType = ButtonState.None;
      status = '';
      if (plannedActivities.includes(i)) {
        status = '[Planned]';
        displayType = ButtonState.Highlighted;
      }
      curActivityData.push({ code: i, status: status, displayType: displayType });
    }

    const selectionValid = this.checkLeafNodeActivity(curActivityData);
    if (curActivityData.length > 0 && selectionValid) {
      this.projectPlannerPopoutModalService.setActivitySelection(await this.projectPlannerService.transformSelectionModalData(curActivityData));
      this.setDisablePage(false);
      this.projectPlannerPopoutModalService.setShowActivityModal(true);
    } else {
      this.setDisablePage(false);
      this.notificationService.eventPopup(EventStatus.Info, 'Object has no plannable activities assigned.');
    }
  }

  // checks all selected object activities for any children to see if it is a leaf node
  checkLeafNodeActivity(arrayActivityData: any[]) {
    let hasLeafNode = false;
    arrayActivityData.forEach( activityObjData => {
      const activityData = this.projectService.getLocalActivity(activityObjData.code);
      if (activityData.children.length === 0) hasLeafNode = true;
    });
    return hasLeafNode;
  }

  // Called by forge viewer output handler
  async runAddingToPrereqs() {
    this.setDisablePage(true);
    this.curSelectionActivities = this.curObjectData.activities;

    // Remove CurPlanning Activity if same object selected is in already selected objects
    if (!Utils.isEmpty(this.selectedObjects[this.curGritObjectId])) {
      const findIndex = this.curSelectionActivities.indexOf(this.curPlanningActivity.activity);
      if (findIndex > -1) this.curSelectionActivities.splice(findIndex, 1);
    }

    const curActivityData = this.curSelectionActivities.map(activityId => {
      const currSeletedActivity = this.selectedPrereqs[this.curGritObjectId] && this.selectedPrereqs[this.curGritObjectId][activityId] ||
      this.colorKitPreqs[this.workPackages.selectedWp.id] && this.colorKitPreqs[this.workPackages.selectedWp.id][this.curGritObjectId];

      return {
        code: activityId,
        displayType: currSeletedActivity ? ButtonState.Highlighted : ButtonState.None,
        status: currSeletedActivity ? '[Selected]' : '',
      };
    });

    if (curActivityData.length > 0) {
      this.projectPlannerPopoutModalService.setActivitySelection(await this.projectPlannerService.transformSelectionModalData(curActivityData));
      this.setDisablePage(false);
      this.projectPlannerPopoutModalService.setShowActivityModal(true);
    } else {
      this.setDisablePage(false);
      this.notificationService.eventPopup(EventStatus.Info, 'Object has no plannable activities assigned.');
    }
  }

  // Called by clicking activity type popup
  async runSelectedPrereqActivity(code: { name, activity }): Promise<void> {
    let selectedWorkPackageId = this.workPackages.selectedWp ? this.workPackages.selectedWp.id : null;
    this.curPrereqActivity = code;
    let removePrereq = false;
    const activityWorkPackages = await this.projectWorkPackageService.getActivityWorkPackages(code.activity);

    if (this.selectedPrereqs[this.curGritObjectId]) {
      if (this.selectedPrereqs[this.curGritObjectId][this.curPrereqActivity.activity]) {
        removePrereq = true;
      }
    }

    activityWorkPackages.forEach(wp => {
      if (this.colorKitPreqs[wp.id] && this.colorKitPreqs[wp.id][this.curGritObjectId]) {
        selectedWorkPackageId = wp.id;
        removePrereq = true;
      }
    });

    let canAddPrereq = this.selectedObjects[this.curGritObjectId] && (this.curPrereqActivity.activity === this.curPlanningActivity.activity) ? false : true;
    if (!canAddPrereq) {
      this.notificationService.error('CIRCULAR_REFERENCE', {});
      return;
    }

    if (removePrereq) {
      this.removePrereq(this.curGritObjectId, selectedWorkPackageId, this.curPrereqActivity.activity);
    } else {
      this.setDisablePage(true);
      this.projectObjectService.getObject(this.curGritObjectId).pipe(takeUntil(this.destroyed$)).subscribe(
        async result => {
          this.setDisablePage(false);
          const prereqObject = result;
          canAddPrereq = true;
          // Check to make sure that activity prereq objects does not contain any selected objects with same TC
          if (prereqObject.scheduledPrerequisites[this.curPrereqActivity.activity] && prereqObject.scheduledPrerequisites[this.curPrereqActivity.activity].length > 0) {
            prereqObject.scheduledPrerequisites[this.curPrereqActivity.activity].forEach(preObj => {
              if (this.selectedObjects[preObj.objectId] && (this.curPlanningActivity.activity === preObj.activity)) {
                this.notificationService.error('CIRCULAR_REFERENCE', {});
                canAddPrereq = false;
              }
            });
          }
          if (canAddPrereq) {
            const preReqDataItem = Object.assign({}, this.curObjectData);
            preReqDataItem['selectedActivity'] = this.curPrereqActivity;
            if (!this.selectedPrereqs[this.curGritObjectId]) {
              this.selectedPrereqs[this.curGritObjectId] = {};
              this.selectedPrereqs[this.curGritObjectId][this.curPrereqActivity.activity] = preReqDataItem;
            } else {
              this.selectedPrereqs[this.curGritObjectId][this.curPrereqActivity.activity] = preReqDataItem;
            }
            // Color other prereqs if kit step
            const objectIdsToColor = await this.isKitStep(this.curGritObjectId, this.curPrereqActivity.activity);
            if (objectIdsToColor) {
              preReqDataItem.selectedActivity.name = '(KIT) - ' + preReqDataItem.selectedActivity.name;
              this.addKitPrereqs(objectIdsToColor, this.curPrereqActivity.activity, this.curGritObjectId);
            }

            // Color objects in work package
            activityWorkPackages.forEach(wp => {
              wp.objectIds.forEach(id => {
                if (id === this.curGritObjectId) {
                  this.addKitPrereqs(wp.objectIds, wp.id, this.curGritObjectId);
                }
              });
            });

            this.setViewerSelections();
          }
        },
        err => {
          this.notificationService.error(err, { type: 'object', action: 'get' });
          this.setDisablePage(false);
        });
    }
  }

  async finishSelectingPrereqs() {
    const prereqActivityId = this.curPrereqActivity.activity !== '' ? this.curPrereqActivity.activity : null;
    this.selectingPrereqs = false;
    this.activeFilters = this.prevActiveFilters;
    this.activeFilterType = this.prevFilterType;
    this.updateActiveFiltersOutput.emit({filters: this.activeFilters, type: this.activeFilterType});
    await this.resetView();
    const prerequisiteObjects = [];
    Object.keys(this.selectedPrereqs).map(key => {
      Object.keys(this.selectedPrereqs[key]).map(actKey => {
        prerequisiteObjects.push({
          objectId: key,
          activity: actKey
        });
      });
    });
    const addPrereqs = this.workPackages && prereqActivityId;
    const showNewTaskForm = this.workPackages.inAddFlow && (this.recommendedTasks && this.recommendedTasks.length < 1);

    if (addPrereqs) this.workPackages.addPreqObjectsToWp(prerequisiteObjects);
    if (showNewTaskForm) this.addEditTaskOutput.emit();
  }

  async isKitStep(gritObjectId: string, activityId: string) {
    const steps = await this.getStepsByObjectActivity(gritObjectId, activityId);
    if (steps.length > 0) {
      const objectIds = steps[0].objects.map(obj => obj.objectId).filter(id => id !== gritObjectId);
      if (objectIds.length > 0) return objectIds;
    }
    return null;
  }

  addKitPrereqs(objectIds: string[], workPackageId: string, objectIdKey: string): void {
    if (!this.colorKitPreqs[workPackageId]) this.colorKitPreqs[workPackageId] = {};
    objectIds.forEach(id => {
      this.colorKitPreqs[workPackageId][id] = id;
    });
  }

  async setViewerSelections() {
    this.forgeViewerService.clearAllThemingColors();
    Object.keys(this.selectedObjects).forEach((key) => {
      this.setStateAfterAddRemoveHover(key);
    });
    Object.keys(this.selectedPrereqs).forEach((key) => {
      this.setStateAfterAddRemoveHover(key);
    });
    Object.keys(this.selectedActivityPrereqObjects).forEach(objectId => {
      this.setStateAfterAddRemoveHover(objectId);
    });
    Object.keys(this.colorKitPreqs).forEach(wpId => {
      Object.keys(this.colorKitPreqs[wpId]).forEach(kId => {
        this.setStateAfterAddRemoveHover(kId);
      });
    });
  }

  async filterModel(filters: string[]) {
    this.activeFilters = filters;
    this.setViewerToFilterObjects();
  }

  async applyFilterOnly() {
    if (!Utils.isEmptyList(this.activeFilters)) {
      this.curFilterObjects = await this.filterSearchService.getFilterData(this.activeFilters, this.activeFilterType, true);
      this.myViewerState = Utils.copyKeysFromObject(this.curFilterObjects);
    } else {
      if (this.addingToKit) {
        this.myViewerState = Utils.copyKeysFromObject(this.curKitObjects);
      } else if (this.selectingPrereqs) {
        this.myViewerState = Utils.copyKeysFromObject(this.objects);
      } else {
        if (this.hidingOtherWork) {
          this.setViewToAuthenticatedObjectsOnly();
          return;
        } else {
          this.myViewerState = Utils.copyKeysFromObject(this.objects);
        }
      }
    }

    this.projectService.setFilteringModel(true);
    await this.forgeViewerService.setViewerState(this.myViewerState);
  }

  async switchFilterType(type: FilterModelOptions) {
    this.activeFilterType = type;
    this.activeFilters = [];
    this.filterModel([]);
  }

  getRecommendedTasks(activityId: string, subcontractorId: string): Promise<IProjectStep[]> {
    return new Promise<any>((resolve, reject) => {
      this.projectStepService.getLastUpdatedStepFromActivity(this.projectId, activityId).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          const formattedRecSteps = res.map(step => {
            return {
              ...step,
              id: Utils.TempId(), // we are returning steps from last udpated step, we need to replace step id so FE treats it as a new step
              projectId: this.projectId,
              subContractor: this.projectSubcontractorService.getLocalProjectSubcontractor(subcontractorId),
              recommended: true
            };
          });
          resolve(formattedRecSteps);
        },
        err => { reject(); }
      );
    });
  }

  // // Getters
  getStepsByObjectActivity(gritObjectId: string, activity: string, getLastUpdatedStep: boolean = false) {
    const observableArray: Array<Observable<any>> = [this.projectStepService.getProjectStepByObjectActivityPair(this.projectId, gritObjectId, activity)];
    if (getLastUpdatedStep) observableArray.push(this.projectStepService.getLastUpdatedStepFromActivity(this.projectId, activity));
    this.setDisablePage(true);

    return new Promise<any>((resolve) => {
      forkJoin(observableArray).pipe(
        takeUntil(this.destroyed$)
      ).subscribe(
        res => {
          if (res[0] && res[0].length > 0) resolve(res[0]);
          else if (res[1] && res[1].length > 0) resolve(res[1]);
          else resolve([]);

          this.setDisablePage(false);
        },
        err => {
          this.notificationService.error(err, { type: 'activity', action: 'get' });
          resolve([]);
          this.setDisablePage(false);
        }
      );
    });
  }
  // END Getters

  // Helpers
  clearSelections(): void {
    this.forgeViewerService.clearAllThemingColors();
    this.selectedObjects = {};
    this.selectedPrereqs = {};
    this.selectedActivityPrereqs = {};
    this.selectedActivityPrereqObjects = {};
    this.showPrereqObjectList = false;
    this.showCurrObjectList = false;
    this.colorKitPreqs = {};
    this.notifySelectedGritObjIds.emit([]);
    this.selectingPrereqs = false;
    this.addingToKit = false;
    this.curPlanningActivity = { name: '', activity: '' };
    this.stepIdToDelete = null;
    this.deletedIds = [];
    this.recommendedTasks = [];
    this.disableCurStepUpdate = false;
    this.notifyStepIds.emit([]);
    this.disablePageFiltersOutput.emit(false);
    this.forgeViewerService.enableSelectionWindow(false);
    this.startAddingObjects.emit(null);
    this.headerMessage = this.headerMessages.default;
  }
  // END Helpers

  // Key events
  popoutKeyDownEvent(event): void {
    this.keyPress(event);
  }

  popoutKeyUpEvent(event): void {
    this.keyRelease(event);
  }

  keyPress = (event) => {
    if (!this.showDeleteTaskConfirmationBox && !this.showParentModal) {
      if (!this.addingToKit && !this.selectingPrereqs) {
        if (event.ctrlKey && event.key === 's') {
          event.preventDefault();
          this.showHidePlannedObjects(!this.hideThingsAlreadyPlanned);
        }
      }
    }
  }

  keyRelease = (event) => {
    if (!this.workPackages || !this.workPackages.selectedWp) return;

    if (event.key === 'Alt') {
      if (!this.selectingPrereqs) this.startSelectingPrereqs();
      else this.startAddingObjects.emit(null);
    }
    if (event.key === 'Control') {
      if (!this.addingToKit) this.startAddingKitObjects(this.workPackages.selectedWp.id);
      else this.startAddingObjects.emit(null);
    }
  }
  // END key events

  // Form events
  async startAddingKitObjects(workPackageId?: string) {
    this.wasHidingAlreadyPlanned = this.hideThingsAlreadyPlanned;
    this.disablePageFiltersOutput.emit(true);
    this.enableShowHideButton = false;
    this.addingToKit = true;
    this.forgeViewerService.enableSelectionWindow(true);
    // Need to hide things that do not have same activity therefore we can't directly call passInputToForgeViewer
    await this.runStartAddingToKit(workPackageId); //
    this.startAddingObjects.emit(false);
  }

  // Called by clicking form button
  async runStartAddingToKit(workPackageId?: string) {
    const selectedWpObjectIds = this.projectWorkPackageService.getWorkPackageObjectIds(workPackageId).objectIds;
    const assignedWpObjectsIds = this.projectWorkPackageService.getAssignedWorkPackageObjectIds(this.selectedActivityId, workPackageId).objectIds;
    this.projectObjectService.getUnPlannedModelObjects(this.projectId, [this.curPlanningActivity.activity], this.activeFilters, this.activeFilterType).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.curKitObjects = res;
        this.myViewerState = Utils.copyKeysFromObject(this.curKitObjects);

        // remove object ids that are already assigned to work packages
        assignedWpObjectsIds.forEach(oId => {
          if (this.myViewerState[oId]) delete this.myViewerState[oId];
        });

        // color objects in work package
        selectedWpObjectIds.forEach(id => {
          const localObj = this.projectObjectService.getLocalObject(id);
          this.myViewerState[id] = localObj;
          this.selectedObjects[id] = localObj;
        });

        this.forgeViewerService.setViewerState(this.myViewerState);
        this.setViewerSelections();
      },
      err => {
        this.notificationService.error(err);
      }
    );
  }

  // Called by forge viewer output handler
  // We know the curObject is not one that is currently selected
  // Figure out if the object is part of a project step already
  // If it is not part of a project step or in prereq list, okay to add
  async runAddingToKit(objectId) {
    const selectedWorkPackageId = this.workPackages.selectedWp.id;
    this.curSelectionActivities = this.curObjectData.activities; // activity to work package
    if (!this.curSelectionActivities.includes(this.curPlanningActivity.activity)) return; // activity to work package
    const steps = await this.getStepsByObjectActivity(objectId, this.curPlanningActivity.activity); // activity to work package
    const workPackageObjectIds = this.projectWorkPackageService.getAssignedWorkPackageObjectIds(this.selectedActivityId, selectedWorkPackageId).objectIds;
    if (workPackageObjectIds.includes(objectId)) {
      this.notificationService.info('ALREADY_IN_WORK_PACKAGE', {});
    } else if (steps.length > 0) {
      this.notificationService.info('APP_ALREADY_PLANNED', {});
    } else {
      if (this.selectedPrereqs[objectId] && this.selectedPrereqs[objectId][this.curPlanningActivity.activity]) {
        this.notificationService.error('CIRCULAR_REFERENCE', {});
      } else {
        const selObject = Object.assign({}, this.curObjectData);
        this.selectedObjects[objectId] = selObject;
        this.notifySelectedGritObjIds.emit(Object.keys(this.selectedObjects));
      }
    }

    this.setViewerSelections();
  }

  async finishAddingKitObjects() {
    this.addingToKit = false;
    this.forgeViewerService.enableSelectionWindow(false);
    if (this.workPackages) this.workPackages.addObjectsToWp(this.selectedObjects);

    await this.resetView();
    this.setViewerSelections();
  }

  async resetView() {
    this.disablePageFiltersOutput.emit(false);
    this.enableShowHideButton = true;
    this.hideThingsAlreadyPlanned = this.wasHidingAlreadyPlanned;
    await this.setViewerToFilterObjects();
  }

  removeObject(objIdToRemove): void {
    delete this.selectedObjects[objIdToRemove];
    this.setStateAfterAddRemoveHover(objIdToRemove);
  }

  removePrereq(objIdToRemove: string, workPackageId: string, activityId: string): void {
    const checkToRemoveSelectedPrereq = (oId, actId) => {
      if (this.selectedPrereqs[oId] && this.selectedPrereqs[oId][actId]) delete this.selectedPrereqs[oId][actId];
      if (this.selectedPrereqs[oId] && Object.keys(this.selectedPrereqs[oId]).length < 1) delete this.selectedPrereqs[oId];
    };

    // // remove selected object
    checkToRemoveSelectedPrereq(objIdToRemove, activityId);

    // // remove workpackage objects
    if (this.colorKitPreqs[workPackageId] && this.colorKitPreqs[workPackageId][objIdToRemove]) {
      const workPackageObjectIds = Object.keys(this.colorKitPreqs[workPackageId]);
      workPackageObjectIds.forEach(oId => { checkToRemoveSelectedPrereq(oId, activityId); });
      delete this.colorKitPreqs[workPackageId];
    }

    this.setViewerSelections();
  }

  async removePrereqActivity(prereqActivityId: any) {
    delete this.selectedActivityPrereqs[prereqActivityId];
    if (!Utils.objectIsEmpty(this.selectedActivityPrereqs))
      this.selectedActivityPrereqObjects = await this.projectObjectService.getActivityObjects(this.projectId, Object.keys(this.selectedActivityPrereqs)).toPromise();
    else
      this.selectedActivityPrereqObjects = {};
    this.setViewerSelections();
    this.showPrereqObjectList = !Utils.objectIsEmpty(this.selectedPrereqs) || !Utils.objectIsEmpty(this.selectedActivityPrereqs);
  }

  setStateAfterAddRemoveHover(gritObjectId: string): void {
    const kitIds = [];
    Object.keys(this.colorKitPreqs)
      .forEach(wpId => Object.keys(this.colorKitPreqs[wpId])
      .forEach(oId => kitIds.push(oId)));

    const inKitPrereqs = kitIds.some(id => id === gritObjectId);
    if (this.selectedObjects[gritObjectId]) {
      if (this.selectedPrereqs[gritObjectId] || inKitPrereqs) {
        this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Select });
      }
    } else if (this.selectedPrereqs[gritObjectId]) {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Prereq });
    } else if (this.selectedActivityPrereqObjects[gritObjectId]) {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Prereq});
    } else if (inKitPrereqs) {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Prereq });
    } else {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Default });
    }
  }

  showHidePlannedObjects(value: boolean) {
    this.hideThingsAlreadyPlanned = value;
    this.setViewerToFilterObjects();
  }

  setViewToAuthenticatedObjectsOnly() {
    this.setDisablePage(true);
    this.projectObjectService.getActivityObjects(this.projectId, this.activityAuthority).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        this.authenticatedObjects = res;
        this.myViewerState = Utils.copyKeysFromObject(this.authenticatedObjects);
        await this.forgeViewerService.setViewerState(this.myViewerState);
        this.setDisablePage(false);
      },
      err => {
        this.setDisablePage(false);
        this.notificationService.error(err, { type: 'object', action: 'get' });
      }
    );
  }

  showHideMyWork(value: boolean) {
    this.hidingOtherWork = value;
    this.setViewerToFilterObjects();
  }

  pageFilterOutput(filter: IPageSpecificFilter): void {
    if (!this.enableShowHideButton) return;
    switch (filter.value) {
      case FilterModelOptions.Unplanned:
        this.showHidePlannedObjects(filter.checked);
        break;
      case FilterModelOptions.MyWork:
        this.showHideMyWork(filter.checked);
        break;
    }
  }

  async setViewerToFilterObjects() {
    this.projectService.setFilteringModel(true);

    let codeAuth = this.hidingOtherWork ? this.activityAuthority : this.allActivities;
    codeAuth = this.addingToKit ? [this.curPlanningActivity.activity] : codeAuth;

    if (this.hideThingsAlreadyPlanned) {
      switch (this.activeFilterType) {
        case FilterModelOptions.Activities:
          this.myViewerState = await this.projectObjectService.getUnPlannedModelObjects(this.projectId, codeAuth, this.activeFilters, this.activeFilterType).toPromise();
          break;
        case FilterModelOptions.SubContractor:
          let subActivities = [];
          this.activeFilters.forEach(subId => {
            subActivities = subActivities.concat(this.projectService.getLocalSubcontractor(subId).activities);
          });
          subActivities = Utils.deDupeArray(subActivities);
          this.myViewerState = await this.projectObjectService.getUnPlannedModelObjects(this.projectId, codeAuth, subActivities, FilterModelOptions.Activities).toPromise();
          break;
        case FilterModelOptions.Category:
          this.myViewerState = await this.projectObjectService.getUnPlannedModelObjects(this.projectId, codeAuth, this.activeFilters, this.activeFilterType).toPromise();
          break;
      }
      await this.forgeViewerService.setViewerState(this.myViewerState);
    } else {
      this.applyFilterOnly();
      return;
    }
    this.setDisablePage(false);
  }
  // End form events

  closeConfirmationClick(): void {
    this.showDeleteTaskConfirmationBox = false;
  }

  async addRecommendedTask(taskId: string, updateAll: boolean = false): Promise<void> {
    const selectedWorkPackage = this.workPackages.selectedWp;
    if (!updateAll) this.updateRecommendedTasks(taskId);
    else this.updateRecommendedTasks(null, true);
    const fitleredOutRecTasks = this.taskList.filter(task => !task.recommended);
    this.taskList.forEach(task => {
      if (task.id === taskId) {
        task.recommended = false;
      }
    });

    // tslint:disable-next-line:max-line-length
    await this.projectPlannerService.createUpdateStepList(fitleredOutRecTasks, this.selectedActivityPrereqs, this.curPlanningActivity.activity, this.deletedIds, selectedWorkPackage);

    if (this.workPackages) {
      this.workPackages.updateData.next({reset: false, emitBack: false});
    }

    const taskName = fitleredOutRecTasks[0].name;
    SegmentService.track('Planner Form: Added Recommended Task ', {name: taskName, id: taskId});
  }

  async saveChanges() {
    const selectedWorkPackage = this.workPackages.selectedWp;
    const fitleredOutRecTasks = this.taskList.filter(task => !task.recommended);
    this.setDisablePage(true);

    // tslint:disable-next-line:max-line-length
    await this.projectPlannerService.createUpdateStepList(fitleredOutRecTasks, this.selectedActivityPrereqs, this.curPlanningActivity.activity, this.deletedIds, selectedWorkPackage);
    if (this.hideThingsAlreadyPlanned) {
      this.setViewerToFilterObjects();
    } else {
      this.setDisablePage(false);
    }

    this.deletedIds = [];

    // update homework component
    if (this.homeworkFeed) {
      this.homeworkFeed.updateData.next(this.selectedActivityId);
    }

    // update work packages
    if (this.workPackages) {
      this.workPackages.updateData.next({reset: false, emitBack: true});
    }
  }

  async confirmDeleteTaskClick(): Promise<void> {
    this.showDeleteTaskConfirmationBox = false;
    const index = this.taskList.findIndex(step => step.id === this.stepIdToDelete);
    this.taskList.splice(index, 1);

    if (!Utils.isTempId(this.stepIdToDelete)) {
      this.deletedIds.push(this.stepIdToDelete);
    } else {
      this.updateRecommendedTasks(this.stepIdToDelete);
    }

    await this.saveChanges();
  }

  confirmActivityClick(activity): void {
    this.projectPlannerPopoutModalService.setShowActivityModal(false);
    this.setDisablePage(false);
    // Now do the normal stuff depending on if we are selecting prereqs

    if (this.selectingPrereqs) {
      this.runSelectedPrereqActivity(activity);
    } else {
      this.runSelectedKitActivity(activity);
    }

    if (activity && activity.activity) this.selectedActivityOutput.emit(activity.activity);
  }
  // End Activity selector box events

  handlePrereqHover(hover: boolean, objectId: string, activityId: string) {
    if (hover) {
      this.hoveredProjectObjectId = objectId;
      this.forgeViewerService.setState({ gritObjectId: this.hoveredProjectObjectId, type: ForgeViewerType.Hover });
    } else {
      this.setStateAfterAddRemoveHover(this.hoveredProjectObjectId);
      this.hoveredProjectObjectId = null;
    }
  }

  async handlePrereqActivityHover(hover: boolean, activityId: string) {
    const hoveredObjectIds = await this.projectObjectService.getActivityObjects(this.projectId, [activityId]).toPromise();
    if (Object.keys(hoveredObjectIds).length > 0) {
      if (hover) {
        Object.keys(hoveredObjectIds).forEach(objectId => {
          this.forgeViewerService.setState({ gritObjectId: objectId, type: ForgeViewerType.Hover });
        });
      } else {
        Object.keys(hoveredObjectIds).forEach(objectId => {
          this.setStateAfterAddRemoveHover(objectId);
          this.hoveredProjectObjectId = null;
        });
      }
    }
  }

  handleObjectHover(event: any) {
    if (event.type === 'mouseenter') {
      this.hoveredProjectObjectId = event.srcElement.dataset['id'];
      this.forgeViewerService.setState({ gritObjectId: this.hoveredProjectObjectId, type: ForgeViewerType.Hover });
    } else if (event.type === 'mouseleave') {
      this.setStateAfterAddRemoveHover(this.hoveredProjectObjectId);
      this.hoveredProjectObjectId = null;
    }
  }

  closeModalClick() {
    this.showModal = false;
    this.projectPlannerPopoutModalService.setShowActivityModal(false);
    this.setDisablePage(false);
    this.modalState = PopupModalState.None;
  }

  addObjectClick() {
    this.modalState = PopupModalState.AddUnmodeledObject;
    this.showModal = true;
  }

  addPrereqClick() {
    this.modalState = PopupModalState.AddUnmodeledObject;
    this.showModal = true;
  }

  doneAddingObjects(): void {
    if (this.addingToKit) this.finishAddingKitObjects();
    if (this.selectingPrereqs) this.finishSelectingPrereqs();
  }

  showObjectList(listType: string): void {
    if (listType === 'prereq') this.showPrereqObjectList = !this.showPrereqObjectList;
    else this.showCurrObjectList = !this.showCurrObjectList;
  }

  formOutput(stepOutput: IPlannerTaskListItem): void {
    stepOutput.workPackageId = this.workPackages.selectedWp.id;
    stepOutput.ignoreBlockers = !stepOutput.ignoreBlockers;

    if (stepOutput.id) {
      const index = this.taskList.findIndex(item => item.id === stepOutput.id);
      this.taskList[index] = stepOutput;
    } else {
      stepOutput.id = Utils.TempId();
      this.taskList.push(stepOutput);
    }

    this.saveChanges();
  }

  editTaskOutput(taskId: string): void {
    const step = this.taskList.find(item => item.id === taskId);
    this.addEditTaskOutput.emit(step);
  }

  updateRecommendedTasks(taskId: string, udpateAll: boolean = false): void {
    if (!udpateAll) {
      this.taskList.forEach(task => {
        if (task.id === taskId) task.recommended = false;
      });
      this.recommendedTasks = this.taskList.filter(task => task.recommended);
    } else {
      this.taskList.forEach(task => task.recommended = false);
      this.recommendedTasks = [];
    }
  }

  taskOrderEdit() {
    this.saveChanges();
  }

  deleteTaskOutput(taskId: string): void {
    const taskToDelete = this.taskList.find(task => task.id === taskId);
    if (taskToDelete && taskToDelete.recommended) this.removeRecommendedTask(taskId);
    else this.deleteTask(taskId);
  }

  removeRecommendedTask(taskId: string): void {
    const updatedTaskList = this.taskList.filter(task => task.id !== taskId);
    this.taskList = cloneDeep(updatedTaskList);
    this.recommendedTasks = this.taskList;
  }

  deleteTask(taskId: string): void {
    this.stepIdToDelete = taskId;
    if (!Utils.isTempId(this.stepIdToDelete)) {
      this.showDeleteTaskConfirmationBox = true;
    } else {
      this.confirmDeleteTaskClick();
    }
  }

  handleHomeworkSelection(hwItem: IProjectHomeworkItem): void {
    this.selectedObjects = {};
    this.selectedPrereqs = {};
    this.colorKitPreqs = {};
    this.enableTaskCreation();

    if (hwItem) {
      this.setHeaderMessage(HeaderMessages.ActivitySelected);
      this.curPlanningActivity.name = hwItem.activityName;
      this.curPlanningActivity.activity = hwItem.activityId;
      this.selectedActivityId = hwItem.activityId;
      if (this.selectedActivityId) this.homeworkActivityOutput.emit([this.selectedActivityId]);
    } else {
      this.headerMessage = this.headerMessages.default;
      this.curPlanningActivity.name = null;
      this.curPlanningActivity.activity = null;
      this.selectedActivityId = null;
      this.homeworkActivityOutput.emit([]);
    }

    this.setViewerSelections();
  }

  // Called by clicking activity type popup
  async runSelectedKitActivity(code) {
    this.selectedObjects = {};
    this.selectedPrereqs = {};
    this.colorKitPreqs = {};

    this.curPlanningActivity = code;
    this.selectedActivityId = code.activity;
    if (this.selectedActivityId) {
      this.homeworkActivityOutput.emit([this.selectedActivityId]);
      if (this.homeworkFeed) this.homeworkFeed.setSelectedHwItem(this.selectedActivityId);
    }

    this.setHeaderMessage(HeaderMessages.ActivitySelected);
    this.setViewerSelections();
  }

  // tslint:disable-next-line:cyclomatic-complexity
  async setWorkPackageData(wp: IWorkPackage): Promise<void> {
    this.selectedObjects = {};
    this.selectedPrereqs = {};
    this.colorKitPreqs = {};
    this.recommendedTasks = [];
    this.taskList = [];
    this.workPackageSelectionOutput.emit(wp);

    if (wp) {
      const getRecommendedTasks = !wp.steps || wp.steps.length < 1;
      const workPackageViewerState = cloneDeep(this.myViewerState);

      const addIdsToTempWorkPackageView = (ids: string[]) => {
        ids.forEach(id => {
          if (!this.myViewerState[id]) {
            workPackageViewerState[id] = id;
          }
        });
      };

      this.enableTaskCreation(wp);
      this.setHeaderMessage(HeaderMessages.WorkPackageSelected, this.taskCreationDisabled);
      if (wp.steps && wp.steps.length > 0) {
        this.taskList = wp.steps.map(task => {
          if (task.stepStatus > StepStatus.Recommended && task.stepStatus !== StepStatus.Updated) this.disableCurStepUpdate = true;

          return {
            ...task,
            projectId: this.projectService.currentProject.id,
            expanded: true,
            subContractor: wp.subContractorId ? this.projectSubcontractorService.getLocalProjectSubcontractor(wp.subContractorId) : null
          };
        });
      } else if (getRecommendedTasks) {
        this.getRecommendedTasks(wp.activityId, wp.subContractorId).then(
          recTasks => {
            this.taskList = recTasks;
            this.recommendedTasks = recTasks;
          }
        );
      }

      if (wp.objectIds) {
        for (const id of wp.objectIds) {
          this.selectedObjects[id] = this.projectObjectService.getLocalObject(id);
        }
      }

      if (wp.prerequisiteObjects) {
        this.colorKitPreqs[wp.id] = {};
        for (const o of wp.prerequisiteObjects) {
          if (!this.colorKitPreqs[o.workPackageId]) this.colorKitPreqs[o.workPackageId] = {};
          if (!this.selectedPrereqs[o.objectId]) this.selectedPrereqs[o.objectId] = {};

          this.selectedPrereqs[o.objectId][o.activity] = this.projectObjectService.getLocalObject(o.objectId);

          // color all object in prereq work package
          if (o.kitIds.length > 0) {
            o.kitIds.forEach(kId => {
              this.colorKitPreqs[o.workPackageId][kId] = this.projectObjectService.getLocalObject(kId);
            });

            // add prereq workpackage objects to view
            addIdsToTempWorkPackageView(o.kitIds);
          }

          // if prereqs object to view
          addIdsToTempWorkPackageView([o.objectId]);
        }
      }

      if (wp.prerequisiteWorkPackages) {
        for (const pwp of wp.prerequisiteWorkPackages) {
          if (pwp.objectIds && pwp.objectIds.length > 0) {
            if (!this.colorKitPreqs[pwp.id]) this.colorKitPreqs[pwp.id] = {};
            for (const pwpoId of pwp.objectIds) {
              this.colorKitPreqs[pwp.id][pwpoId] = this.projectObjectService.getLocalObject(pwpoId);
            }

            // add prereq workpackage objects to view
            addIdsToTempWorkPackageView(pwp.objectIds);
          }
        }
      }

      if (!this.addingToKit) await this.forgeViewerService.setViewerState(workPackageViewerState);
    } else {
      this.enableTaskCreation();
      this.setHeaderMessage(HeaderMessages.ActivitySelected);
      this.taskList = [];

      await this.forgeViewerService.setViewerState(this.myViewerState);
    }

    this.setViewerSelections();
  }

  setHeaderMessage(messageType: HeaderMessages, taskCreationDisabled?: boolean): void {
    switch (messageType) {
      case HeaderMessages.ActivitySelected :
        this.headerMessage = this.headerMessages.activitySelected;
        break;
      case HeaderMessages.WorkPackageSelected :
        this.headerMessage = this.headerMessages.workPackageSelected(taskCreationDisabled);
        break;
      default :
        this.headerMessage = this.headerMessages.default;
    }
  }

  enableTaskCreation(wp?: IWorkPackage): void {
    if (!wp) this.taskCreationDisabled = true;
    else this.taskCreationDisabled = false;
  }

  async handleDeleteWorkPackage(wp: IWorkPackage): Promise<void> {
    const wpActivityId = wp.activityId ? wp.activityId : null;
    this.homeworkFeed.updateData.next(wpActivityId);
    this.taskList = [];
    this.recommendedTasks = [];
    this.selectedPrereqs = {};
    this.selectedObjects = {};
    this.colorKitPreqs = {};
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.enableTaskCreation();
    this.setViewerSelections();
  }

  setInAddFlow(inAddFlow: boolean): void {
    if (this.workPackages) this.workPackages.setInAddFlow(inAddFlow);
  }

  setDisablePage(disabled: boolean) {
    this.forgeViewerService.setDisablePage(disabled);
  }
}
