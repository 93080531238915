import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInProgressOutput } from '../../../../models/project/project-sprint/project-sprint.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-project-sprint-inprogress-result-form',
  templateUrl: './project-sprint-inprogress-result-form.component.html',
  styleUrls: ['./project-sprint-inprogress-result-form.component.scss']
})
export class ProjectSprintInprogressResultFormComponent implements OnInit {
  @Input () inProgressFormInput : IInProgressOutput;
  @Output() updateOutput: EventEmitter<{valid: boolean, data: IInProgressOutput}> = new EventEmitter();
  
  get startDate() { return this.formInput.get('startDate'); }
  get expectedFinishDate() { return this.formInput.get('expectedFinishDate'); }

  formInput: FormGroup;
  duration: number;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  constructor(private formBuilderService: FormBuilder) { }

  ngOnInit() {
    this.duration = this.inProgressFormInput.duration;
    this.formInput = this.buildCompleteFormInput();
    this.handleStartDateChange(); 

    this.formInput.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.updateInProgressFormInput(this.formInput);
    });
  }

  buildCompleteFormInput(): FormGroup {
    const formInput = this.formBuilderService.group({
      startDate: [null, Validators.required],
      expectedFinishDate: [null, Validators.required]
    });
    formInput.controls['startDate'].setValue(new Date(this.inProgressFormInput.startDate));
    formInput.controls['expectedFinishDate'].setValue(new Date(this.inProgressFormInput.expectedFinishDate));

    return formInput;
  }

  handleStartDateChange() {
    this.formInput.controls['startDate'].valueChanges.subscribe(value => {
      if (!value) {
        this.formInput.controls['expectedFinishDate'].setValue(null);
        return
      };
      const expectedFinishDate = Utils.getEndDateExcludingWeekends(+value, this.duration);
      this.formInput.controls['expectedFinishDate'].setValue(new Date(expectedFinishDate));
    })
  }

  updateInProgressFormInput (formOutput){
    const formValues = formOutput.getRawValue();
    const output: IInProgressOutput = {
      startDate: formValues.startDate,
      expectedFinishDate: formValues.expectedFinishDate
    };
    this.updateOutput.emit({valid: this.formInput.valid, data: output});
  }

}
