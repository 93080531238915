
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectMilestoneService } from '../../services/project/project-milestone/project-milestone.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Utils } from '../../utils/utils';

import { IGritTable, IGRow, IGRowItem } from '../../shared/grit-table/grit-table';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectMilestone } from '../../models/project/project-milestone/project-milestone.interface';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-project-milestone-settings',
  templateUrl: './project-milestone-settings.component.html',
  styleUrls: ['./project-milestone-settings.component.scss']
})
export class ProjectMilestoneSettingsComponent implements OnInit, OnDestroy {

  @Input() projectId: string;
  @Input() projectPermissionInput: IUserPermission;

  file: any = null;
  upLoading: boolean = false;
  projectMilestones: IProjectMilestone[] = [];
  isLoading: boolean = true;
  tableData: IGritTable;

  context: INoficationContext = {
    type: 'milestone(s)',
    action: 'add'
  };

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectMilestoneService: ProjectMilestoneService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    SegmentService.track('Milestone Settings: Loaded', {projectId: this.projectId});
    this.getData();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getData(): void {
    this.isLoading = true;
    this.projectMilestoneService.getMilestones(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.isLoading = false;
        this.projectMilestones = Utils.sortByString(res, 'name');
        this.tableData = this.projectMilestoneService.transformTableData(this.projectMilestones, this.projectPermissionInput);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.context.action = 'get';
        this.notificationService.error(err, this.context);
      }
    );
  }

  deleteMilestoneRows(rowIds: string[]): void {
    const observableArray: Array<Observable<any>> = [];

    rowIds.forEach(id => {
      observableArray.push(this.projectMilestoneService.deleteMilestone(id));
    });

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      results => {
        for (let x = 0; x < observableArray.length; x++) {
          if (results[x] === '1 deleted') this.projectMilestones.splice(this.projectMilestones.findIndex(milestone => milestone.id === rowIds[x]), 1);
        }
      },
      error => {
        const context: INoficationContext = {
          type: 'milestone',
          action: 'delete'
        };
        this.notificationService.error(error, context);
        this.getData();
      }
    );
  }

  createMilestoneRow(rowItems: IGRowItem[]): void {
    this.isLoading = true;

    const endDate = rowItems.find(ri => ri.colKey === 'endDate').value;
    const newMilestone: IProjectMilestone = {
      projectId: this.projectId,
      name: rowItems.find(ri => ri.colKey === 'name').value,
      endDate: Utils.isEmpty(endDate) ? null : Utils.toUtcDate(endDate),
      objects: []
    };

    this.projectMilestoneService.addMilestone(newMilestone).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.isLoading = false;
        this.projectMilestones.push(res);
        this.projectMilestones = Utils.sortByString(this.projectMilestones, 'name');
        this.tableData = this.projectMilestoneService.transformTableData(this.projectMilestones, this.projectPermissionInput);
        SegmentService.track('Milestone Settings: Created', {projectId: this.projectId, milestoneName: newMilestone.name, milestoneId: res.id});
      },
      err => {
        this.isLoading = false;
        this.context.action = 'add';
        this.notificationService.error(err, this.context);
      }
    );
  }

  editMilestoneRow(row: IGRow): void {
    this.isLoading = true;
    const editedMilestone: IProjectMilestone = {
      name: row.rowItems.find(ri => ri.colKey === 'name').value,
      endDate: Utils.toUtcDate(row.rowItems.find(ri => ri.colKey === 'endDate').value),
      objects: this.projectMilestones.find(milestone => milestone.id === row.key).objects
    };

    this.projectMilestoneService.updateMilestone(row.key, editedMilestone).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Milestone Settings: Edited', {projectId: this.projectId, milestoneId: row.key});
        this.getData();
      },
      err => {
        this.context.action = 'update';
        this.notificationService.error(err, this.context);
        this.getData();
      }
    );
  }

  fileAdded(event: EventTarget) {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    this.file = files[0];
  }

  uploadFile(): void {
    if (this.upLoading || this.file === null) return;

    this.upLoading = true;
    this.projectMilestoneService.importCSVMilestones(this.projectId, this.file).subscribe(
      res => {
        if (res.type === 4) {
          this.upLoading = false;
          this.file = null;
          SegmentService.track('Milestone Settings: CSV Uploaded', {projectId: this.projectId});
          this.getData();
        }
      },
      err => {
        this.upLoading = false;
        this.file = null;
        this.context.action = 'upload';
        this.context.filetype = '.csv';
        this.notificationService.error(err, this.context);
      }
    );
  }
}
