export enum PopupModalState {
  None,
  AddUnmodeledObject,
  SelectActivity,
  UpdateSprint,
  AddSprint,
  UpdateStartSprint,
  ActivityModal,
  MilestoneModal,
  SelectTask,
  ImportGanttModal,
  UpdateProjectHours,
  UpdateExceptionDays,
  SelectPrereqActivity,
  EditWorkPackage,
  GenerateSchedule,
  bulkEdit,
  activitiesRowEdit
}
