export enum EventStatus {
    Success,
    Error,
    Info
}

export enum NotificationType {
    OBJECT_MESSAGE = 'objectMessage',
    TASK_MESSAGE = 'taskMessage',
    ACTIVITY_MESSAGE = 'activityMessage',
    SPRINT = 'sprint',
    SCHEDULE = 'schedule',
    PROCESS = 'process'
}

export enum EventType {
    MODEL_STATUS_UPDATED = 'modelStatusUpdated',
    MODEL_CONFLICTS = 'modelConflicts',
    MODELS_INVALIDATED = 'modelsInvalidated',
    OBJECT_ACTIVITY_CHANGE = 'objectActivityChange',
    OBJECT_HIDDEN = 'objectHidden',
    ACTIVITIES_UPDATED = 'activitiesUpdated',
    MESSAGE_POSTED = 'messagePosted',
    SCHEDULE_GENERATED = 'scheduleGenerated',
    SCHEDULE_ACTIVATED = 'scheduleActivated',
    SPRINT_START = 'sprintStarted',
    SPRINT_END = 'sprintEnded',
    STEP_PLANNED = 'stepPlanned',
    STEP_UPDATED = 'stepUpdated',
    STEP_STATUS_UPDATED = 'stepStatusUpdated',
    STEP_DELETED = 'stepDeleted',
    STORY_UPDATED = 'storyUpdated',
    PROJECT_SUBS_UPDATED = 'projectSubsUpdated',
    SNAPSHOTS_UPDATED = 'snapshotsUpdated',
    MATERIAL_UPDATED = 'materialUpdated',
    FLOOR_PLANS_UPDATE = 'floorPlansUpdated'
}
