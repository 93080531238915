import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { ProjectDashboardService } from '../../services/project/project-dashboard/project-dashboard.service';
import { ProjectService } from '../../services/project/project.service';

import { IDashboardGrid } from '../../models/project/project-dashboard/project-dashboard.interface';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-dashboard-living-schedule',
  templateUrl: './project-dashboard-living-schedule.component.html',
  styleUrls: ['./project-dashboard-living-schedule.component.scss']
})
export class ProjectDashboardLivingScheduleComponent implements OnInit, OnChanges {

  @Input() activeScheduleInput;
  @Input() livingScheduleInput;
  @Input() dashboardGridInput: IDashboardGrid;

  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  expectedCompletionDate: string;
  activeCompletionDate: string;
  completionSavings: number;
  showMilestoneTable: boolean;

  constructor(
    private projectService: ProjectService,
    private projectDashboardService: ProjectDashboardService,
    private router: Router
  ) { }

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    this.isLoading = true;
    if (this.livingScheduleInput && this.activeScheduleInput) {
      this.expectedCompletionDate = Utils.formatDate(this.livingScheduleInput.endDate);
      this.activeCompletionDate = Utils.formatDate(this.activeScheduleInput.endDate);
      this.completionSavings = this.projectDashboardService.calculateCompletionSavings(this.activeScheduleInput.endDate, this.livingScheduleInput.endDate);
    }
    this.isLoading = false;
  }

  handleMilestonesClick() {
    this.showMilestoneTable = !this.showMilestoneTable;
  }

  navigateToLookahead() {
    this.router.navigateByUrl('/project/' + this.projectService.currentProject.id + '/schedule?scheduleId=' + this.livingScheduleInput.id);
  }

}
