import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CacheService {
    private initialized = false;

    async initializeCache(url) {
        const files = [];

        if (!this.initialized) {
            this.initialized = true;

            try {
                await navigator.serviceWorker.register('/service-worker.js');
            } catch (e) {
                console.log(e);
            }

            const scripts = document.getElementsByTagName('script');
            const links = document.getElementsByTagName('link');

            // tslint:disable:prefer-for-of
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src) files.push(scripts[i].src);
            }
            for (let i = 0; i < links.length; i++) {
                if (links[i].href) files.push(links[i].href);
            }
        }

        await this.submitWorkerTask({operation: 'CACHE_FILES', files, url});
    }

    async setProjectId(projectId) {
        await this.submitWorkerTask({operation: 'SET_PROJECT_ID', projectId});
    }

    async clearProjectId(projectId) {
        await this.submitWorkerTask({operator: 'CLEAR_PROJECT_ID', projectId});
    }

    submitWorkerTask(task) {
        return navigator.serviceWorker.ready.then((req) => {
            return new Promise((resolve, reject) => {
                const channel = new MessageChannel();
                channel.port1.onmessage = (event) => {
                    if (event.data.error) {
                        reject(event.data);
                    } else {
                        resolve(event.data);
                    }
                };
                req.active.postMessage(task, [channel.port2]);
            });
        });
    }
}
