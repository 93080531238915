import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ProjectMilestoneService } from '../project-milestone/project-milestone.service';
import { ProjectSubContractorService } from '../project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../project.service';

import { IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { IColumnHeader, ICustomTable, IRow, IRowItem, ITableSearchOptions } from '../../../models/custom-table/custom-table.interface';
import { IProjectObjectSidebarTabs } from '../../../models/project/project-object/project-object.interface';
import { ViewMode } from '../../../utils/enums/shared.enum';

import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectActivityTypesService {
  tablePageSize: number = 20;

  constructor(
    private projectService: ProjectService,
    private formBuilderService: FormBuilder,
    private subContractorService: ProjectSubContractorService,
    private milestoneService: ProjectMilestoneService
  ) { }

  // Transform data for table
  transformObjectTableData(
    allTableData: any,
    selectedProjectObjects: any,
    searchQuery?: string,
    paginationStart?: number,
    activeSearchOptions?: string[]
  ): ICustomTable {
    const objectsData = allTableData;
    const objectsDataKeys = Object.keys(objectsData);
    const selectedGritObjectIds = Object.keys(selectedProjectObjects);
    const overallCount = objectsDataKeys.length;
    const pageSize = this.tablePageSize;
    const pageStart = paginationStart ? paginationStart : 0;

    const transformedTableData: ICustomTable = {
      title: 'Project Objects',
      filterable: false,
      searchable: true,
      columnHeaders: [],
      rows: [],
      multiSelect: true,
      canCreate: false,
      multiSearch: false,
      searchOptions: [],
      filterByOptions: null,
      bulkEdit: false,
      searchQuery: searchQuery,
      pagination: {
        count: overallCount,
        pageSize: pageSize,
        pageStart: pageStart,
        totalItemsSelected: 0,
        display: true
      },
      showIntro: true,
      bulkHide: true,
      allRowsSelected: overallCount === selectedGritObjectIds.length ? true : false
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'name',
        key: 'name',
        sortable: false,
        active: false,
        searchable: true
      },
      {
        title: 'categories',
        key: 'categories',
        sortable: false,
        active: false,
        searchable: true
      },
      {
        title: 'activities',
        key: 'activities',
        sortable: false,
        active: false,
        searchable: true
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Define search options for multi-search
    const searchOptions: ITableSearchOptions[] = [];
    transformedTableData.columnHeaders.filter((colHead, index) => {
      if (colHead.searchable === true) {
        searchOptions.push({
          title: colHead.title,
          active: activeSearchOptions && activeSearchOptions.includes(colHead.title) ? true : false
        });
      }
    });
    transformedTableData.searchOptions = searchOptions;

    let totalOtherItemsSelected = selectedGritObjectIds.length;

    // Table Rows
    const tableRows: IRow[] = [];
    for (let r = pageStart; r < pageStart + pageSize; r++) {
      if (r < objectsDataKeys.length) {
        const rowItems: IRowItem[] = [
          {
            value: objectsData[objectsDataKeys[r]].name,
            prevStateValue: objectsData[objectsDataKeys[r]].name,
            editable: false,
            type: 'text',
            actionOutput: objectsData[objectsDataKeys[r]]
          },
          {
            value: objectsData[objectsDataKeys[r]].categories,
            prevStateValue: objectsData[objectsDataKeys[r]].categories,
            editable: false,
            type: 'text',
            actionOutput: objectsData[objectsDataKeys[r]]
          },
          {
            name: 'activities',
            value: (!Utils.isEmptyList(objectsData[objectsDataKeys[r]].activities)
              ? objectsData[objectsDataKeys[r]].activities.map(activity => this.projectService.getLocalActivity(activity).name).sort()
              : 'None Assigned'),
            prevStateValue: (!Utils.isEmptyList(objectsData[objectsDataKeys[r]].activities)
              ? objectsData[objectsDataKeys[r]].activities.map(activity => this.projectService.getLocalActivity(activity).name).sort()
              : 'None Assigned'),
            editable: true,
            type: 'text',
            bulkEditId: 'activity'
          }
        ];

        if (selectedProjectObjects.hasOwnProperty(objectsData[objectsDataKeys[r]].id)) {
          totalOtherItemsSelected--;
        }

        tableRows.push({
          selectable: true,
          editable: false,
          removeable: false,
          active: selectedProjectObjects.hasOwnProperty(objectsData[objectsDataKeys[r]].id),
          rowItems: rowItems,
          key: objectsData[objectsDataKeys[r]].id,
          isEditing: false,
          isHidden: objectsData[objectsDataKeys[r]].hidden
        });
      }
    }

    transformedTableData.rows = tableRows;
    transformedTableData.pagination.totalItemsSelected = totalOtherItemsSelected;

    return transformedTableData;
  }

  // Get Sidebar data
  getSidebarTabData(): IProjectObjectSidebarTabs[] {
    const sidebarTabs = [
      {
        name: 'filter',
        icon: 'fa-filter',
        key: 'filter',
        active: true
      },
      {
        name: 'thread',
        icon: 'fa-comments',
        key: 'messages',
        active: false
      },
      {
        name: 'object_properties',
        icon: 'fa-cube',
        key: 'properties',
        active: false
      }
    ];

    return sidebarTabs;
  }

  buildFormInputForRowEdit(masterActivityInput: IMasterScheduleActivity[], currentView: ViewMode, allActivities: IMasterScheduleActivity[] = []) {
    const requiredFormOptions = ['name', 'duration'];
    let formInputs = [];

    masterActivityInput.forEach(masterActivityInput => {
      const formInput = this.buildFormInput(masterActivityInput, requiredFormOptions, masterActivityInput.activity.parentId);
      this.buildSubAutoInputs(formInput, masterActivityInput, masterActivityInput.activity.parentId, allActivities);
      this.buildMilestoneAutoInputs(formInput, masterActivityInput, masterActivityInput.activity.parentId, allActivities);
      formInputs.push(formInput);
    });

    return formInputs;
  }

  buildFormInputs(masterActivityInput: IMasterScheduleActivity, currentView: ViewMode, parentId: string, allActivities: IMasterScheduleActivity[] = []) {
    const requiredFormOptions = ['name', 'duration'];
    const formInput = this.buildFormInput(masterActivityInput, requiredFormOptions, parentId);
    this.buildSubAutoInputs(formInput, masterActivityInput, parentId, allActivities);
    this.buildMilestoneAutoInputs(formInput, masterActivityInput, parentId, allActivities);
    return formInput;
  }

  buildFormInputForBulkEdit(ids: string[], masterActivityInput: IMasterScheduleActivity[]) {

    const formInput = this.formBuilderService.group({
      ids: [[]],
      projectId: [this.projectService.currentProject.id],
      names: [[]],
      externalActivityId: [null],
      duration: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      startDate: [null],
      endDate: [null],
      anchorDate: [null],
      static: [false],
      prerequisites: [[]],
      crewSize: [0, Validators.pattern('^[0-9]*$')],
      subContractor: [null],
      milestone: [null],
      equipmentIds: [[]],
      equipmentCost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      materialIds: [[]],
      rfiIds: [[]],
      laborIds: [[]],
      materialCost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      laborCost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')]
    });

    let activityNames = [];
    let duration = [];
    let subContractorId = [];
    let crewSize = [];
    let milestoneId = [];
    let anchorDate = [];
    let materialIds = [];
    let equipmentIds = [];
    let rfiIds = [];
    let laborIds = [];
    let equipmentCost = [];
    let laborCost = [];
    let materialCost = [];

    let durationEditEnableFlag = true

    masterActivityInput.forEach(input => {

      if (input.activity.children.length > 0 || !durationEditEnableFlag) {
        durationEditEnableFlag = false;
      }

      activityNames.push(input.activity.name);
      duration.push(input.activity.duration);
      crewSize.push(input.activity.crewSize);
      anchorDate.push(input.activity.anchorDate);
      materialIds.push(input.activity.materialIds);
      equipmentIds.push(input.activity.equipmentIds);
      rfiIds.push(input.activity.rfiIds);
      laborIds.push(input.activity.laborIds);
      laborCost.push(input.activity.laborCost);
      equipmentCost.push(input.activity.equipmentCost);
      materialCost.push(input.activity.materialCost);
      milestoneId.push(input.milestoneId);
      subContractorId.push(input.subContractorId);
    });

    // Update formInput
    formInput.controls['ids'].setValue(ids);
    formInput.controls['names'].setValue(activityNames);
    formInput.controls['duration'].setValue(Utils.getCommonElementFromArray(duration));
    formInput.controls['crewSize'].setValue(Utils.getCommonElementFromArray(crewSize));
    formInput.controls['materialIds'].setValue(Utils.getCommonElementsFrom2DArray(materialIds));
    formInput.controls['equipmentIds'].setValue(Utils.getCommonElementsFrom2DArray(equipmentIds));
    formInput.controls['rfiIds'].setValue(Utils.getCommonElementsFrom2DArray(rfiIds));
    formInput.controls['laborIds'].setValue(Utils.getCommonElementsFrom2DArray(laborIds));
    formInput.controls['laborCost'].setValue(Utils.getCommonElementFromArray(laborCost));
    formInput.controls['equipmentCost'].setValue(Utils.getCommonElementFromArray(equipmentCost));
    formInput.controls['materialCost'].setValue(Utils.getCommonElementFromArray(materialCost));

    if (!durationEditEnableFlag) formInput.controls['duration'].disable();

    let commonAnchorDate = Utils.getCommonElementFromArray(anchorDate);
    if (commonAnchorDate)
      formInput.controls['anchorDate'].setValue(Utils.fromUtcDate(commonAnchorDate));

    // Find subcontractor
    const commonSubContractorId = Utils.getCommonElementFromArray(subContractorId);
    const projectSubcontractors = Utils.sortByString(this.subContractorService.getLocalProjectSubcontractors(), 'name');
    formInput.controls['subContractor'].setValidators([this.validateSub()]);
    if (commonSubContractorId) formInput.controls['subContractor'].setValue(projectSubcontractors.find(sub => sub.id === commonSubContractorId));

    // Find milestone
    let commonMilestoneId = Utils.getCommonElementFromArray(milestoneId);
    const projectMilestones = Utils.sortByString(this.milestoneService.getLocalProjectMilestones(), 'name');
    if (commonMilestoneId) formInput.controls['milestone'].setValue(projectMilestones.find(m => m.id === commonMilestoneId));

    return formInput;
  }

  buildFormInput(masterActivityInput: IMasterScheduleActivity, requiredFormOptions: string[], parentId: string) {
    const activityFormInput = this.formBuilderService.group({
      id: [null],
      parentId: [null],
      projectId: [this.projectService.currentProject.id],
      name: [null],
      externalActivityId: [null],
      duration: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      startDate: [null],
      endDate: [null],
      anchorDate: [null],
      static: [false],
      prerequisites: [[]],
      crewSize: [0, Validators.pattern('^[0-9]*$')],
      subContractor: [null],
      milestone: [null],
      equipmentIds: [[]],
      rfiIds: [[]],
      laborIds: [[]],
      equipmentCost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      materialIds: [[]],
      materialCost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      laborCost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      actualStartDate: [null],
      actualFinishDate: [null],
      expectedFinishDate: [null],
      taskId: [null],
      sprintId: [null],
      expectedDuration: [null]
    });

    requiredFormOptions.forEach(option => {
      if (activityFormInput.controls[option].validator) {
        activityFormInput.controls[option].setValidators([activityFormInput.controls[option].validator, Validators.required]);
      } else {
        activityFormInput.controls[option].validator = Validators.required;
      }
    });

    if (parentId) activityFormInput.controls['parentId'].setValue(parentId);

    if (masterActivityInput) {
      activityFormInput.controls['id'].setValue(masterActivityInput.activity.id);
      activityFormInput.controls['name'].setValue(masterActivityInput.activity.name);
      if (masterActivityInput.activity.externalActivityId) activityFormInput.controls['externalActivityId'].setValue(masterActivityInput.activity.externalActivityId);
      if (masterActivityInput.activity.duration) activityFormInput.controls['duration'].setValue(masterActivityInput.activity.duration);
      if (masterActivityInput.activity.startDate) activityFormInput.controls['startDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.startDate));
      if (masterActivityInput.activity.endDate) activityFormInput.controls['endDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.endDate));
      if (masterActivityInput.activity.anchorDate) activityFormInput.controls['anchorDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.anchorDate));
      if (masterActivityInput.activity.static) activityFormInput.controls['static'].setValue(masterActivityInput.activity.static);
      if (masterActivityInput.activity.parentId) activityFormInput.controls['parentId'].setValue(parentId);
      if (masterActivityInput.activity.prerequisites) activityFormInput.controls['prerequisites'].setValue(masterActivityInput.activity.prerequisites);
      if (masterActivityInput.activity.crewSize) activityFormInput.controls['crewSize'].setValue(masterActivityInput.activity.crewSize);
      if (masterActivityInput.activity.equipmentIds) activityFormInput.controls['equipmentIds'].setValue(masterActivityInput.activity.equipmentIds);
      if (masterActivityInput.activity.equipmentCost) activityFormInput.controls['equipmentCost'].setValue(masterActivityInput.activity.equipmentCost);
      if (masterActivityInput.activity.materialIds) activityFormInput.controls['materialIds'].setValue(masterActivityInput.activity.materialIds);
      if (masterActivityInput.activity.rfiIds) activityFormInput.controls['rfiIds'].setValue(masterActivityInput.activity.rfiIds);
      if (masterActivityInput.activity.laborIds) activityFormInput.controls['laborIds'].setValue(masterActivityInput.activity.laborIds);
      if (masterActivityInput.activity.materialCost) activityFormInput.controls['materialCost'].setValue(masterActivityInput.activity.materialCost);
      if (masterActivityInput.activity.laborCost) activityFormInput.controls['laborCost'].setValue(masterActivityInput.activity.laborCost);
      if (masterActivityInput.activity.actualStartDate) activityFormInput.controls['actualStartDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.actualStartDate));
      if (masterActivityInput.activity.actualFinishDate) activityFormInput.controls['actualFinishDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.actualFinishDate));
      if (masterActivityInput.activity.children.length > 0 || masterActivityInput.activity.static) activityFormInput.controls['duration'].disable();
      if (masterActivityInput.activity.children.length > 0 || masterActivityInput.activity.static) activityFormInput.controls['actualStartDate'].disable();
      if (masterActivityInput.activity.children.length > 0 || masterActivityInput.activity.static) activityFormInput.controls['actualFinishDate'].disable();
      if (masterActivityInput.activity.children.length > 0 || masterActivityInput.activity.static) activityFormInput.controls['expectedFinishDate'].disable();
      if (masterActivityInput.activity.children.length > 0 || masterActivityInput.activity.static) activityFormInput.controls['sprintId'].disable();
      if (masterActivityInput.activity.actualStartDate) activityFormInput.controls['actualStartDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.actualStartDate));
      if (masterActivityInput.activity.actualFinishDate) activityFormInput.controls['actualFinishDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.actualFinishDate));
      if (masterActivityInput.activity.expectedFinishDate) activityFormInput.controls['expectedFinishDate'].setValue(Utils.fromUtcDate(masterActivityInput.activity.expectedFinishDate));
      if (masterActivityInput.activity.taskId) activityFormInput.controls['taskId'].setValue(masterActivityInput.activity.taskId);
      if (masterActivityInput.activity.sprintId) activityFormInput.controls['sprintId'].setValue(masterActivityInput.activity.sprintId);
      if (masterActivityInput.activity.expectedDuration) activityFormInput.controls['expectedDuration'].setValue(masterActivityInput.activity.expectedDuration);
    }

    return activityFormInput;
  }

  buildSubAutoInputs(formInput: FormGroup, masterActivityInput: IMasterScheduleActivity, parentId?: string, allActivities?: IMasterScheduleActivity[]) {
    const projectSubcontractors = Utils.sortByString(this.subContractorService.getLocalProjectSubcontractors(), 'name');

    formInput.controls['subContractor'].setValidators([this.validateSub()]);
    if (masterActivityInput && masterActivityInput.subContractorId) formInput.controls['subContractor'].setValue(projectSubcontractors.find(sub => sub.id === masterActivityInput.subContractorId));
    if (!masterActivityInput && parentId) {
      const findRes = allActivities.find(item => item.activity.id === parentId);
      if (findRes && findRes.subContractorId) formInput.controls['subContractor'].setValue(projectSubcontractors.find(sub => sub.id === findRes.subContractorId));
    }
  }

  validateSub(): ValidatorFn {
    return (control: FormControl): {} => {
      if (!control.value) return {};
      if (control.value === '') return {};
      const invalid = control.value && control.value.id ? false : true;
      if (invalid) return { invalidSub: invalid };
      return {};
    };
  }

  buildMilestoneAutoInputs(formInput: FormGroup, masterActivityInput: IMasterScheduleActivity, parentId?: string, allActivities?: IMasterScheduleActivity[]) {
    const projectMilestones = Utils.sortByString(this.milestoneService.getLocalProjectMilestones(), 'name');
    if (masterActivityInput && masterActivityInput.milestoneId) formInput.controls['milestone'].setValue(projectMilestones.find(m => m.id === masterActivityInput.milestoneId));
    if (!masterActivityInput && parentId) {
      const findRes = allActivities.find(item => item.activity.id === parentId);
      if (findRes && findRes.milestoneId) formInput.controls['milestone'].setValue(projectMilestones.find(m => m.id === findRes.milestoneId));
    }
  }
}
