import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectPDFViewerComponent } from '../../project/project-PDF-viewer/project-PDF-viewer.component';
import { ProjectPDFPopoutComponent } from '../../project/project-pdf-popout/project-pdf-popout.component';
import { IGanttChartBar } from '../../models/activity/activity.interface';
import { HttpBackendService } from '../http-backend/http-backend.service';
import { ActivityColorMap } from '../../models/notification/notification.interface';
import { element } from 'protractor';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectService } from '../project/project.service';
import { IActivityAnnotation } from '../../models/pdf/pdf.interface';

@Injectable()
export class PDFViewerService {

  constructor(private httpService: HttpBackendService,
    private notificationService: NotificationService, ) { }
  projectPDFViewerComponent: ProjectPDFViewerComponent;
  // private _selectedActivity: IGanttChartBar;
  private _highlightColor: string = "#ffff00";
  public pdfLoadingComplete: boolean = false;
  private _selectActivities: ActivityColorMap[] = [];
  private _activtyAnnoations: IActivityAnnotation[] = [];

  // Popout
  poppedOut: boolean = false;
  poppedOutpdfComponent: ProjectPDFPopoutComponent;
  detatchedWindow : boolean;

  // PDF Viewer oprations  Below funtions are going in project service.
  public enableSelectionWindow(flag: boolean, activityMap: ActivityColorMap) {
    if (flag && activityMap) {
      this.projectPDFViewerComponent.enableDrawAnnotation(activityMap.activityId);
      this._selectActivities = [activityMap];
    }
    else {
      this.projectPDFViewerComponent.endAndSaveAnnotation();
      this._selectActivities.splice(0, this._selectActivities.length);
    }
  }

  public resetPDFViewerService() {
    this._selectActivities = [];

    // Reset PDF viewer if it's initialized already
    if (this.projectPDFViewerComponent)
      this.projectPDFViewerComponent.resetPDFViewer();
  }

  public get activtyAnnoations() {
    return this._activtyAnnoations;
  }

  public updatePdf(pdfFiles: string[]) {
    if (this.pdfLoadingComplete) {
      this.projectPDFViewerComponent.resetPDFViewer()
      this.projectPDFViewerComponent._pdfFiles = pdfFiles;
      this.projectPDFViewerComponent.ngOnInit();
    }
  }

  public cancelDrawAnnotation() {
    this._selectActivities.splice(0, this._selectActivities.length);
    this.projectPDFViewerComponent.cancelDrawAnnotation();
  }

  public updateDrawAnnotation() {
    this._selectActivities.splice(0, this._selectActivities.length);
    this.projectPDFViewerComponent.updateDrawAnnotation();
  }


  public setPDFViewer(pdfViewer: ProjectPDFViewerComponent) {
    this.projectPDFViewerComponent = pdfViewer;
  }

  public getSubcontractornColor(activityId: string): string {

    let color = "#808080";
    let subColor = this._selectActivities.find(element => element.activityId === activityId);
    if (subColor) {
      color = subColor.color;
      if (color === "gray")
        color = "#808080";
    }

    return color;
  }

  public gethowerHeighlightColor(): string {
    return this._highlightColor;
  }


  // gantt chart oprations
  public howerOverActivity(activityIds: string[]) {
    // If activity is selected hover will not going to work
    // if (this._selectActivities.length === 0)
    //   this.projectPDFViewerComponent.howerOverActivity(activityIds);
  }

  // gantt chart oprations
  public selectActivities(activityIds: ActivityColorMap[]) {
    this._selectActivities = activityIds;
    if (!activityIds)
      this._selectActivities = [];

    let activityIdArray: string[] = []
    this._selectActivities.forEach(element => {
      activityIdArray.push(element.activityId);
    });

    this.projectPDFViewerComponent.selectActivities(activityIdArray);
  }

  // PDF related oprations
  public zoomIn() {
    this.projectPDFViewerComponent.zoomIn();
  }
  public zoomOut() {
    this.projectPDFViewerComponent.zoomOut();
  }

  public fitToScreen() {
    this.projectPDFViewerComponent.fitToScreen();
  }
  public getPreviousPage() {
    this.projectPDFViewerComponent.getPreviousPage();
  }

  public getNextPage() {
    this.projectPDFViewerComponent.getNextPage();
  }

  public openPDFInNewWindow() {
    this.projectPDFViewerComponent.openPDFInNewWindow();
  }

  public closePopWindow() {
    this.projectPDFViewerComponent.closePopWindow();
  }

  public deleteAnnotations(projectId: string, activityId: string) {
    this.deleteActivity(projectId, activityId).subscribe(
      res => {
        this.updateDrawAnnotation();
        this.notificationService.success("annotation_removed", {});
      },
      err => {
        this.notificationService.error(err, {});
      }
    );
  }

  public getAnnotations(projectId: string) {
    this.getAllAnnotations(projectId).subscribe(
      res => {
        this._activtyAnnoations = res;
      },
      err => {
        this.notificationService.error(err, {});
      }
    );
  }

  public checkIfAnnotationExist(activityIds: string[]): string[] {

    let selectedActivities = [];

    activityIds.forEach(activityId => {
      if (this._activtyAnnoations.find(activtyAnnoation => activtyAnnoation.activityId === activityId))
        selectedActivities.push(activityId);
    });

    return selectedActivities;
  }

  // returns PDF's associated with activity id.
  public getPDFIdForActivity(activityId : string): string[]{
    let pdfids : string[] = []
    this._activtyAnnoations.forEach(aElement=>{
        if(activityId === aElement.activityId ){
          aElement.annotation.forEach(annotElement =>{
            pdfids.push(annotElement.pdfId);
          });
        }
    });
    return pdfids;
  }

  // Apply the filter on PDF files.
  public fileterPDFFiles(urls  :string[]) {
    this.projectPDFViewerComponent.applyPDFFilter(urls);
  }

  public reloadPDF(isVisable? : boolean) {
    this.projectPDFViewerComponent.reloadPDF(isVisable);
  }

  // Apply the filter on PDF files.
  public setSelectionFilterCheck(check : boolean){
    this.projectPDFViewerComponent.setSelectionFilterCheck(check);
  }

  public updateAnnotation(projectId: string, json: any): Observable<any> {
    return this.httpService.post('/pdfFloorPlan/' + projectId + '/updateAnnotation', json);
  }

  public getAnnotation(projectId: string, activityId: string, pdfId: string): Observable<any> {
    return this.httpService.get('/pdfFloorPlan/' + projectId + '/getAnnotation?activityId=' + activityId + '&pdfId=' + pdfId);
  }

  public getAllAnnotations(projectId: string): Observable<any> {
    return this.httpService.get('/pdfFloorPlan/' + projectId + '/getAllAnnotations');

  }

  public deleteActivity(projectId: string, activityId: string): Observable<any> {
    return this.httpService.get('/pdfFloorPlan/' + projectId + '/deleteAnnotation?activityId=' + activityId);
  }

  public getData(projectId?: string, scheduleId?: string): Observable<any> {
    return this.httpService.get('/pdfFloorPlan/' + projectId + '/getSheetActivities?scheduleId=' + scheduleId);
  }

  setPDFViewerPopout(pdfViewerPopout: ProjectPDFViewerComponent): void {
    this.projectPDFViewerComponent = pdfViewerPopout;
    this.poppedOut = true;
  }

  setMyPopout(popOutComponent: ProjectPDFPopoutComponent): void {
    this.poppedOutpdfComponent = popOutComponent;
  }

  reassignForgeViewer(pdfViewer: ProjectPDFViewerComponent): void {
    this.setPDFViewer(pdfViewer);
    this.poppedOut = false;
  }

}
