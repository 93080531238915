import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { ProjectService } from '../../project/project.service';
import { Utils } from '../../../utils/utils';

import { IColumnHeader, ICustomTable, IRow, IRowItem, ITableSearchOptions } from '../../../models/custom-table/custom-table.interface';
import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { IProjectModel } from '../../../models/project/project-model/project-model.interface';
import { IUserPermission } from '../../../models/user/user.interface';

@Injectable()
export class ProjectModelService {
  tablePageSize: number = 20;
  public projectModels: any;
  public existingModelIds: number[] = [];

  constructor(
    private _http: HttpBackendService,
    private projectService: ProjectService
  ) { }

  public insertModel(projectId: string, file: any, activities: string[], replaceModelId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('activities', JSON.stringify(activities));
    formData.append('replaceModelId', replaceModelId);

    return this._http.formData('/project/' + projectId + '/model', formData);
  }

  public importBim360(projectId: string, json: any): Observable<any> {
    return this._http.post('/project/' + projectId + '/model/bim360', json);
  }

  public updateModel(projectId: string, json: any): Observable<any> {
    return this._http.put('/project/' + projectId + '/model/' + json.id, json);
  }

  public resolveConflicts(projectId: string, keep: {[key: string]: string[]}, remove: {[key: string]: string[]}): Observable<any> {
    return this._http.post('/project/' + projectId + '/model/conflicts', {keep, remove});
  }

  public getModelList(projectId: string): Observable<any> {
    return this._http.get('/project/' + projectId + '/models');
  }

  public deleteModel(modelId: string, projectId: string): Observable<IProjectModel[]> {
    return this._http.delete('/project/model/' + modelId);
  }

  public updateProjectModelStatus(modelId: number, action: string): void {
    if (action === 'add') {
      this.existingModelIds.push(modelId);
    } else if (action === 'remove') {
      const indexOfModelId = this.existingModelIds.indexOf(modelId);
      this.existingModelIds.splice(indexOfModelId, 1);
    }
  }

  transformTableData(dataInput: IProjectModel[], permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      },
      {
        displayName: 'date_added',
        colKey: 'uploadDate',
        type: RowItemType.Text
      },
      {
        displayName: 'file_size',
        colKey: 'fileSize',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'status',
        colKey: 'status',
        type: RowItemType.TextIcon,
        width: '15%'
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(model => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: model.name,
          editable: permission.edit && permission.gc,
        },
        {
          colKey: 'uploadDate',
          value: model.uploadDate ? new Date(model.uploadDate).toLocaleString() : 'Unknown'
        },
        {
          colKey: 'fileSize',
          value: model.fileSize ? Utils.bytesToString(model.fileSize) : 'Imported'
        },
        {
          colKey: 'status',
          value: {text: this.showStatus(model.status, model.conflicts).status, icon: this.showStatus(model.status, model.conflicts).icon}
        }
      ];
      rows.push(
        {
          key: model.id,
          rowItems: rowItems,
          selectable: false,
          editOptions: {
            deletePermission: permission.edit && permission.gc,
            rowEdits: permission.edit && permission.gc ? [{type: EditType.ModalEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      searchOptions: {
        show: false
      },
      addOptions: {
        addPermission: false
      }
    };

    return retTable;
  }

  // Transform data for table
  transformConflictTableData(
    allTableData: any,
    selectedProjectObjects: any,
    currentSelection: any,
    searchQuery?: string,
    paginationStart?: number,
    activeSearchOptions?: string[],
    conflictFilter?: number
  ): ICustomTable {
    const objectsData = allTableData;
    const objectsDataKeys = Object.keys(objectsData)
      .filter(id => !conflictFilter || objectsData[id].conflict === conflictFilter)
      .sort((i1, i2) => (objectsData[i1].conflict || 100) - (objectsData[i2].conflict || 100));
    const selectedGritObjectIds = Object.keys(selectedProjectObjects);
    const overallCount = objectsDataKeys.length;
    const pageSize = this.tablePageSize;
    const pageStart = paginationStart ? paginationStart : 0;

    const transformedTableData = {
      title: 'Project Object Conflict',
      showIntro: true,
      filterable: false,
      searchable: true,
      columnHeaders: [],
      rows: [],
      multiSelect: false,
      canCreate: false,
      multiSearch: false,
      searchOptions: [],
      filterByOptions: null,
      bulkEdit: false,
      searchQuery: searchQuery,
      pagination: {
        count: overallCount,
        pageSize: pageSize,
        pageStart: pageStart,
        totalItemsSelected: 0,
        display: true
      },
      bulkHide: false,
      allRowsSelected: overallCount === selectedGritObjectIds.length ? true : false
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'Name',
        key: 'name',
        sortable: false,
        active: false,
        searchable: true
      },
      {
        title: 'Categories',
        key: 'categories',
        sortable: false,
        active: false,
        searchable: true
      },
      {
        title: 'Status',
        key: 'status',
        sortable: true,
        active: false,
        searchable: true
      },
      {
        title: 'Action',
        key: 'action',
        sortable: true,
        active: false,
        searchable: true
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Define search options for multi-search
    const searchOptions: ITableSearchOptions[] = [];
    transformedTableData.columnHeaders.filter((colHead, index) => {
      if (colHead.searchable === true) {
        searchOptions.push({
          title: colHead.title,
          active: activeSearchOptions && activeSearchOptions.includes(colHead.title) ? true : false
        });
      }
    });
    transformedTableData.searchOptions = searchOptions;

    let totalOtherItemsSelected = selectedGritObjectIds.length;

    // Table Rows
    const tableRows: IRow[] = [];
    for (let r = pageStart; r < pageStart + pageSize; r++) {
      if (r < objectsDataKeys.length) {
        const obj = objectsData[objectsDataKeys[r]];
        const rowItems: IRowItem[] = [
          {
            value: obj.name,
            prevStateValue: obj.name,
            editable: false,
            type: 'text',
            actionOutput: obj
          },
          {
            value: obj.categories,
            prevStateValue: obj.categories,
            editable: false,
            type: 'text',
            actionOutput: obj
          },
          {
            name: 'status',
            value: obj.conflict > 0 ? 'Added' : obj.conflict < 0 ? obj.replacements.length === 0 ? 'Removed' : 'Replaced' : 'Updated',
            prevStateValue: '',
            editable: false,
            type: 'text',
            icon: obj.conflict > 0 ? 'fas fa-plus green' : obj.conflict < 0 ? obj.replacements.length === 0 ? 'fas fa-times red' :
              'fas fa-circle yellow' : 'fas fa-circle black'
          },
          {
            name: 'action',
            value: '',
            prevStateValue: '',
            editable: false,
            type: 'text',
            actionOutput: obj,
            button: obj.conflict < 0 ? obj.replacements.length === 0 ? 'Delete' : 'Replace with ' + obj.replacements.length + ' Objects' : ''
          }
        ];

        if (selectedProjectObjects.hasOwnProperty(obj.id)) {
          totalOtherItemsSelected--;
        }

        tableRows.push({
          selectable: true,
          editable: false,
          removeable: false,
          active: selectedProjectObjects.hasOwnProperty(obj.id) || obj.id === currentSelection,
          rowItems: rowItems,
          key: obj.id,
          isEditing: false,
          isHidden: obj.hidden
        });
      }
    }

    transformedTableData.rows = tableRows;
    transformedTableData.pagination.totalItemsSelected = totalOtherItemsSelected;

    return transformedTableData;
  }

  showStatus(status: number, conflicts?: number): { status: string; icon: string } {
    if (status === 0) {
      return {
        status: 'Pending',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 1) {
      return {
        status: 'Failed',
        icon: 'fa fa-times red'
      };
    }
    if (status === 2) {
      if (conflicts > 0) {
        return {
          status: 'Conflicted',
          icon: 'fa fa-wrench red'
        };
      }
      return {
        status: 'Complete',
        icon: 'fa fa-check green'
      };
    }
    if (status === 3) {
      return {
        status: 'Authenticating',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 4) {
      return {
        status: 'Geting Bucket Info',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 5 || status === 6) {
      return {
        status: 'Shipping',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 7) {
      return {
        status: 'Converting',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 8) {
      return {
        status: 'Extracting Data',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 9) {
      return {
        status: 'Creating Scene',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 10) {
      return {
        status: 'Extracting Scene',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 11) {
      return {
        status: 'Building Scene',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 12) {
      return {
        status: 'Validating Scene',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 13) {
      return {
        status: 'Extracting Geometry',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 14) {
      return {
        status: 'Generating',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 15) {
      return {
        status: 'Overwriting Conflict',
        icon: 'fas fa-circle-notch spin'
      };
    }
    if (status === 16) {
      return {
        status: 'Simplifying Scene',
        icon: 'fas fa-circle-notch spin'
      };
    }

    return {
      status: 'Unknown',
      icon: 'fa fa-question red'
    };
  }

  transformDataForViewerFromTable(gritObjectIds: any[], type: string): any {
    const forgeViewerInput = [];

    gritObjectIds.forEach(id => {
      forgeViewerInput.push({
        id: id,
        type: type
      });
    });

    return forgeViewerInput;
  }

  async newModelsExist() {
    let newModels = await this.getModelList(this.projectService.currentProject.id).toPromise();
    newModels = newModels.filter(model => model.status === 2);
    const newModelIds = newModels.map(model => model.id);
    const currentModelIds = this.projectModels.filter(model => model.status === 2).map(model => model.id);
    const newModelsExist = !Utils.arraysIdentical(newModelIds, currentModelIds);

    return newModelsExist;
  }
}
