import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { ProjectService } from '../../../services/project/project.service';

import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { IProjectScheduleStep } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectSprintStory } from '../../../models/project/project-sprint/project-sprint-story/project-sprint-story.interface';
import { IProjectSprint, IProjectViewModes } from '../../../models/project/project-sprint/project-sprint.interface';
import { ISidebarTab } from '../../../models/sidebar/sidebar.interface';
import { IWeatherForecast } from '../../../models/weather/weather.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { PopupModalState } from '../../../utils/enums/popup-modal-state.enum';
import { ViewMode } from '../../../utils/enums/shared.enum';
import { SidebarState } from '../../../utils/enums/sidebar-state';
import { Utils } from '../../../utils/utils';

import { IXaxisChartData } from '../../../models/chart/chart.interface';
import * as moment from 'moment';
import { ISprintBoardItemResponse } from '../../../models/project/project-sprint/project-sprint-board.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';

@Injectable()
export class ProjectSprintService {
  private activeFilters: string[] = [];
  private activeFilterType: FilterModelOptions;
  private hiddenFilters: FilterModelOptions[] = [FilterModelOptions.Category];
  activeSprint: IProjectSprint;
  sprintList: IProjectSprint[] = [];
  backlogPage: number = 0;
  backlogPageSize: number = 25;
  sprintDeletable: boolean = false;
  sprintDisplayModes: IProjectViewModes[] = [
    {
      mode: ViewMode.SprintBoard,
      label: 'board',
      selected: false,
      showOnDefaultSprint: true
    },
    // {
    //   mode: ViewMode.OneWeekSchedule,
    //   label: 'calendar',
    //   selected: true
    //  },
    {
      mode: ViewMode.SprintCommitment,
      label: 'flow',
      selected: false,
      showOnDefaultSprint: false
    }
  ];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpBackendService,
    private projectService: ProjectService
  ) { }

  public addProjectSprint(json: any): Observable<IProjectSprint> {
    return this.httpService.post('/project/sprint', json);
  }

  public updateProjectSprint(sprintId: string, json: any): Observable<IProjectSprint> {
    return this.httpService.put('/project/sprint/' + sprintId, json);
  }

  public addSprintStory(json: any): Observable<IProjectSprintStory> {
    return this.httpService.post('/project/sprint/story', json);
  }

  public addSprintStories(json: any): Observable<IProjectSprintStory[]> {
    return this.httpService.post('/project/sprint/stories', json);
  }

  public startSprintStory(storyId: any, json?: any): Observable<any> {
    return this.httpService.put('/project/sprint/story/' + storyId + '/start', json);
  }

  public endSprintStory(storyId: any, json: any): Observable<any> {
    return this.httpService.put('/project/sprint/story/' + storyId + '/end', json);
  }

  public approveSprintStory(storyId: any, json: any): Observable<any> {
    return this.httpService.put('/project/sprint/story/' + storyId + '/approve', json);
  }

  public moveSprintStoryBack(storyId: string, stepStatus: number): Observable<any> {
    return this.httpService.put('/project/sprint/story/' + storyId + '/moveBack', { stepStatus: stepStatus });
  }

  public editSprintStoryBack(storyId: string, json: any): Observable<any> {
    return this.httpService.put('/project/sprint/story/' + storyId, json);
  }

  public removeSprintStories(json: string[]): Observable<any> {
    return this.httpService.put('/project/sprint/stories/remove', json);
  }

  public removeSprintStory(storyId: any): Observable<any> {
    return this.httpService.delete('/project/sprint/story/' + storyId);
  }

  public getSprintList(projectId: string): Observable<IProjectSprint[]> {
    return this.httpService.get('/project/' + projectId + '/sprints');
  }

  public getSprintBacklog(projectId: string, backlogPage?: number): Observable<any> {
    let pageStart;
    if (Utils.isEmpty(backlogPage))
      pageStart = this.backlogPage * this.backlogPageSize;
    else
      pageStart = backlogPage * this.backlogPageSize;

    return this.httpService.paged('/project/' + projectId + '/sprint/backlog?pageSize=' + this.backlogPageSize + '&pageStart=' + pageStart);
  }

  public getAllSprintStories(sprintId: string): Observable<any> {

    return this.httpService.get('/project/sprint/' + sprintId + '/stories');
  }

  public getActiveSprint(projectId: string): Observable<IProjectSprint> {
    return this.httpService.get('/project/' + projectId + '/sprint');
  }

  public startSprint(sprintId: string): Observable<IProjectSprint> {
    return this.httpService.put('/project/sprint/' + sprintId + '/start', {});
  }

  public endSprint(sprintId: string): Observable<IProjectSprint> {
    return this.httpService.put('/project/sprint/' + sprintId + '/end', {});
  }

  public deleteProjectSprint(sprintId: string): Observable<IProjectSprint> {
    return this.httpService.delete('/project/sprint/' + sprintId + '/end');
  }

  public getSprintResultLibrary(projectId: string, uneditable: boolean = false): Observable<any[]> {
    return this.httpService.get('/project/' + projectId + '/sprintResultLibrary?uneditable=' + uneditable);
  }

  setActiveFilterType(type: FilterModelOptions): void {
    this.activeFilterType = type;
  }
  getActiveFilterType(): FilterModelOptions {
    return this.activeFilterType;
  }
  setActiveFilters(filters: string[]): void {
    this.activeFilters = filters;
  }
  getActiveFilters(): string[] {
    return this.activeFilters;
  }
  getHiddenFilters(): FilterModelOptions[] {
    return this.hiddenFilters;
  }

  getSidebarTabs(): ISidebarTab[] {
    let sidebarTabs = [
      {
        name: 'filter',
        icon: 'fa-filter',
        key: SidebarState.MODEL_FILTER,
        active: true
      },
      {
        name: 'task_list',
        icon: 'fa-tasks',
        key: SidebarState.TASK_LIST,
        active: false
      },
      {
        name: 'task_information',
        icon: 'fa-info',
        key: SidebarState.TASK_INFORMATION,
        active: false
      },
      {
        name: 'thread',
        icon: 'fa-comments',
        key: SidebarState.TASK_MESSAGES,
        active: false
      },
      {
        name: 'object_properties',
        icon: 'fa-cube',
        key: SidebarState.OBJECT_PROPERTIES,
        active: false
      },
      {
        name: 'procurement_menu_item',
        icon: 'fa-dolly',
        key: SidebarState.PROCUREMENT,
        active: false
      }
    ];

    // if (!this.projectService.hasModels) sidebarTabs = sidebarTabs.filter(
    //   item => (item.key !== SidebarState.MODEL_FILTER) && (item.key !== SidebarState.OBJECT_PROPERTIES)
    // );

    if (!this.projectService.hasModels) sidebarTabs = sidebarTabs.filter(
      item => (item.key !== SidebarState.OBJECT_PROPERTIES)
    );
    return sidebarTabs;
  }

  setActiveSprint(sprint: IProjectSprint) {
    if (Utils.isEmpty(sprint)) this.activeSprint = null;
    else this.activeSprint = sprint;
  }

  setSprintList(sprints: IProjectSprint[]) {
    this.sprintList = sprints;
  }

  getDefaultSprint(): string {
    const defaultSprint = this.sprintList.find(sprint => sprint.is_default);
    return defaultSprint && defaultSprint.id ? defaultSprint.id : '';
  }

  getSprintName(sprintId: string) {
    const sprintInfo = this.sprintList.find(sprint => sprint.id === sprintId);
    if (Utils.isEmpty(sprintInfo)) {
      return 'UNKNOWN WORK PLAN';
    } else {
      return sprintInfo.name;
    }
  }

  getSprint(sprintId: string) {
    return this.sprintList.find(sprint => sprint.id === sprintId);
  }

  updateSprintList(action: string, sprint: any) {
    switch (action) {
      case 'add':
        this.sprintList.push(sprint);
        break;
      case 'delete':
        const deletedSprint = this.sprintList.map(item => item.id).indexOf(sprint);
        this.sprintList.splice(deletedSprint, 1);
        break;
      case 'update':
        const updateSprint = this.sprintList.map(item => item.id).indexOf(sprint.id);
        this.sprintList[updateSprint] = sprint;
    }
  }

  getOpenSprints() {
    return this.sprintList.filter(sprint => Utils.isEmpty(sprint.endDate));
  }

  isSafeToDeleteSprint(currSprint: IProjectSprint, currStories: IProjectSprintStory[]) {
    const currSprintActive = currSprint && currSprint.startDate && !currSprint.endDate;
    const containsStories = currStories ? currStories.find(story => story.storyStatus > 1) : null;
    if (currSprintActive) {
      this.sprintDeletable = false;
    } else if (containsStories) {
      this.sprintDeletable = false;
    } else {
      this.sprintDeletable = true;
    }
  }

  getUnclosedSprints() {
    return this.sprintList.filter(sprint => Utils.isEmpty(sprint.closedDate));
  }

  transformSprintList(sprintList: IProjectSprint[]): any[] {
    const listItems = [];
    sprintList = Utils.sortByNumber(sprintList, 'scheduledStartDate', true);
    sprintList.forEach(item => {
      let displayName;
      if (!Utils.isEmpty(item.startDate) && Utils.isEmpty(item.endDate)) displayName = '* ' + item.name + ' (Active)';
      else if (!Utils.isEmpty(item.endDate)) displayName = item.name + ' (Ended)';
      else displayName = item.name;
      listItems.push({ id: item.id, name: displayName });
    });

    return listItems;
  }

  getBacklogPage() {
    return this.backlogPage;
  }

  nextBacklogPage() {
    this.backlogPage++;
  }

  isSprintEnded(sprintId: string): boolean {
    const sprint = this.sprintList.find(item => item.id === sprintId);
    if (Utils.isEmpty(sprint)) return true;
    else if (sprint.hasOwnProperty('endDate')) return true;
    else return false;
  }

  /**
   * getMostRecentSprintId - if active sprint, return sprint id / if no active sprint, return most recently closed sprint id / if no sprints return null
   */
  getMostRecentSprintId(sprintList: IProjectSprint[]): string {
    if (!Utils.isEmpty(sprintList)) {
      const isActiveSprint = !Utils.isEmpty(sprintList.filter(sprint => !sprint.endDate)) ? true : false;
      const activeSprintId = isActiveSprint ? sprintList.filter(sprint => !sprint.endDate)[0].id : null;
      const mostRecentSprint = sprintList.reduce((a, b) => {
        const endDate1 = a.endDate ? a.endDate : 0;
        const endDate2 = b.endDate ? b.endDate : 0;

        if (endDate1 > endDate2) {
          return a;
        } else {
          return b;
        }
      });

      return activeSprintId ? activeSprintId : mostRecentSprint.id;
    } else {
      return null;
    }
  }

  getSprintStatus(sprint: IProjectSprint): string {
    if (sprint.closedDate) return 'closed';
    else if (!sprint.closedDate && sprint.endDate) return 'ended';
    else if (sprint.startDate && !sprint.endDate) return 'started';
    else return 'opened';
  }

  sortSprintsByStartThenName(sprints: IProjectSprint[]): IProjectSprint[] {
    let nonStartDates = [];
    let startDates = [];
    sprints.forEach(sprint => {
      if (!sprint.startDate) nonStartDates.push(sprint);
      else startDates.push(sprint);
    });
    nonStartDates = Utils.sortByString(nonStartDates, 'name');
    startDates = Utils.sortByNumber(startDates, 'startDate', true);

    return startDates.concat(nonStartDates);
  }

  checkToUpdateSprintEndDate(sprint: IProjectSprint): boolean {
    const today = Utils.toUtcDate(new Date());
    return (sprint && sprint.scheduledEndDate) && (today > sprint.scheduledEndDate);
  }

  buildSprintModalInput(type: string | PopupModalState , sprintName: string): IConfirmationModalInput {
    switch (type) {
      case PopupModalState.UpdateSprint :
        return {
          displayMessage: 'Work Plan ' + sprintName + ' has a scheduled end date in the past, would you like to end the Work Plan or update it?',
          hasCancelAction: false,
          hasConfirmationAction: false,
          hasDeleteAction: false,
          customContent: true
        };
      case PopupModalState.AddSprint :
        return {
          displayMessage: 'Add a Work Plan',
          hasCancelAction: false,
          hasConfirmationAction: false,
          hasDeleteAction: false,
          customContent: true
        };
      case PopupModalState.UpdateStartSprint :
        return {
          displayMessage: 'Work Plan ' + sprintName + ' has a scheduled end date in the past, you need to update it before the Work Plan can start.',
          hasCancelAction: false,
          hasConfirmationAction: false,
          hasDeleteAction: false,
          customContent: true
        };
      case 'delete' :
        return {
          displayMessage: 'Are you sure you want to remove Work Plan ' + '"' + sprintName + '"?',
          hasCancelAction: true,
          hasConfirmationAction: false,
          hasDeleteAction: true,
          customContent: false
        };
      case 'start' :
        return {
          displayMessage: 'Are you sure you want to start Work Plan ' + '"' + sprintName + '"?',
          hasCancelAction: true,
          hasConfirmationAction: true,
          hasDeleteAction: false,
          customContent: false
        };
      case 'end' :
        return {
          displayMessage: 'Are you sure you want to end Work Plan ' + '"' + sprintName + '"?',
          hasCancelAction: true,
          hasConfirmationAction: true,
          hasDeleteAction: false,
          customContent: false
        };
      default :
        return null;
    }
  }

  /**
   * buildSprintForm - either build form to add a sprint or a form to update the scheduled end date
   */
  buildSprintForm(action: PopupModalState, sprint?: IProjectSprint): FormGroup {
    const sprintForm = this.fb.group({
      type: [ action ],
      name: [
        null,
        Validators.required
      ],
      scheduledStartDate: [
        null,
        Validators.required
      ],
      scheduledEndDate: [
        null,
        Validators.required
      ],
    });

    switch (action) {
      case PopupModalState.UpdateStartSprint :
        if (sprint) {
          sprintForm.addControl('type', new FormArray([]));
          sprintForm.controls['type'].setValue('start');
          sprintForm.controls['name'].setValue(sprint.name);
          sprintForm.controls['name'].disable();
          sprintForm.controls['scheduledStartDate'].setValue(new Date(sprint.scheduledStartDate));
          sprintForm.controls['scheduledStartDate'].disable();
          sprintForm.controls['scheduledEndDate'].setValue(new Date(sprint.scheduledEndDate));
        }
        break;

      case PopupModalState.UpdateSprint :
        if (sprint) {
          sprintForm.addControl('type', new FormArray([]));
          sprintForm.controls['type'].setValue('update');
          sprintForm.controls['name'].setValue(sprint.name);
          sprintForm.controls['name'].disable();
          sprintForm.controls['scheduledStartDate'].setValue(new Date(sprint.scheduledStartDate));
          sprintForm.controls['scheduledStartDate'].disable();
          sprintForm.controls['scheduledEndDate'].setValue(new Date(sprint.scheduledEndDate));
        }
        break;
    }

    return sprintForm;
  }

  /**
   * stepActionable - checks if step is associated with a sprint story and permissionss
   */
  stepActionable(step: IProjectScheduleStep, stories: IProjectSprintStory[]): boolean {
    let stepCommittable: boolean = false;

    stories.forEach(story => {
      if (story.id === step.sprintStoryId) stepCommittable = true;
    });

    return (!step.sprintStoryId || stepCommittable) && step.canEdit;
  }

  setSprintDislayMode(mode: string): void {
    this.sprintDisplayModes.forEach(displayMode => {
      if (displayMode.mode === mode) displayMode.selected = true;
      else displayMode.selected = false;
    });
  }

  getFilteredSprintDisplayMode(): IProjectViewModes[] {
    return this.sprintDisplayModes.filter(mode => mode.showOnDefaultSprint);
  }

  buildXaxisData(epochs: number[], weatherForecast: IWeatherForecast[] = []): IXaxisChartData[] {
    return epochs.map(item => {
      return {
        day: item,
        weather: weatherForecast.filter(weaterDay => weaterDay.date === item).length > 0
          ? weatherForecast.filter(weaterDay => weaterDay.date === item)[0]
          : null
      };
    });
  }

  sortStepsBySubContractor(steps: ISprintBoardItemResponse[]) {
    const stepsBySubcontractor = {};
    steps.forEach(step => {
      const subContractorId = step.subContractor && step.subContractor.subContractorId ? step.subContractor.subContractorId : 'unassigned';
      if (stepsBySubcontractor[subContractorId]) {
        stepsBySubcontractor[subContractorId].push(step);
      } else {
        stepsBySubcontractor[subContractorId] = [step];
      }
    });

    return stepsBySubcontractor;
  }

  /*--SIDEBAR--*/
  // Sidebar Transforms
  createArrayForSidebarDisplay(stepsBySub: any, subcontractors: IProjectSubContractor[]): any[] {
    const sidebarDisplay = [];
    if (!Utils.objectIsEmpty(stepsBySub)) {
      for (const key in stepsBySub) {
        if (stepsBySub[key]) {
          const filteredSubs = subcontractors.filter(subcontractor => subcontractor.id === key);
          const activeSubcontractor = !Utils.isEmpty(filteredSubs) ? filteredSubs[0] : null;
          if (activeSubcontractor) {
            const sidebarObj = {
              label: activeSubcontractor.name ? activeSubcontractor.name : null,
              color: activeSubcontractor.hexCode ? activeSubcontractor.hexCode : null,
              steps: this.sidebarStepDataAggregation(stepsBySub[key]),
              active: true
            };
            sidebarDisplay.push(sidebarObj);
          }
        }
      }
    }

    return sidebarDisplay;
  }

  sidebarStepDataAggregation(arrData) {
    if (!arrData) return;
    // create new step data array for aggregation
    const stepArr = [];
    arrData.forEach(step => {
      stepArr.push(step)
    });
    return stepArr;
  }

}
