import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { ICustomActionOutput, ICustomTable, IFilterByOptions, IRow, ITableSearchOptions } from '../../../models/custom-table/custom-table.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';
import { ViewMode } from '../../../utils/enums/shared.enum';

import { ProjectMasterScheduleService } from '../../../services/project/project-master-schedule/project-master-schedule.service';
import { ProjectService } from '../../../services/project/project.service';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-activity-table',
  templateUrl: './project-activity-table.component.html',
  styleUrls: ['./project-activity-table.component.scss']
})
export class ProjectActivityTableComponent implements OnChanges {

  @Input() tableInput: IMasterScheduleActivity[];

  @Output() tableSelectionOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() importOutput: EventEmitter<void> = new EventEmitter();
  @Output() addActivityOutput: EventEmitter<void> = new EventEmitter(); // Used in HTML
  @Output() editActivityOutput: EventEmitter<string> = new EventEmitter();
  @Output() deleteActivityOutput: EventEmitter<string[]> = new EventEmitter(); // Used in HTML
  @Output() assignObjectsOutput: EventEmitter<boolean> = new EventEmitter();
  @Output() switchViewOutput: EventEmitter<ViewMode> = new EventEmitter();
  @Output() hoverObjectsOutput: EventEmitter<string[]> = new EventEmitter();

  // Template variables
  viewModeEnum = ViewMode;
  curActivitiesSelected: string[] = [];
  tableDisplay: IMasterScheduleActivity[] = [];

  // Component Inputs
  activityTableData: ICustomTable;
  editPermission: boolean = ProjectService.userPermission.gc && ProjectService.userPermission.edit;
  chartControls: IProjectScheduleControls;

  // Table helpers
  private curFilterInput: IMasterScheduleActivity[] = null;
  private paginationStart: number = 0;
  private curSearch: ITableSearchOptions = null;
  private filterUnused: boolean;

  canAdd = ProjectService.userPermission.gc && ProjectService.userPermission.edit;

  constructor(
    public masterScheduleService: ProjectMasterScheduleService
  ) {
    this.setChartControls();
  }

  ngOnChanges() {
    this.setTableData();
  }

  // CONTROLS
  setChartControls(): void {
    this.chartControls = {
      bulkEdit: false,
      reset: false,
      today: false,
      search: false,
      animation: false,
      timeFrames: {
        display: false,
      },
      customControls: true
    };
  }

  importClick(): void {
    this.importOutput.emit();
  }

  switchViewClick(view: ViewMode): void {
    if (view !== ViewMode.Table) {
      this.masterScheduleService.setChartMode(view);
      this.switchViewOutput.emit(view);
    }
  }
  // END CONTROLS

  // HELPERS
  setTableData(): void {
    this.curActivitiesSelected = [];
    this.tableDisplay = [];
    this.tableInput.forEach(item => this.tableDisplay.push(item));
    this.tableDisplay = Utils.sortArrayByObjectKeyString(this.tableDisplay, 'activity', 'name');
    if (this.curSearch) this.applySearch(this.curSearch);
    else this.setActivityTableData();
  }

  assignObjectsClick(key: string) {
    this.curActivitiesSelected = [key];
    this.setActivityTableData();
    this.tableSelectionOutput.emit(this.curActivitiesSelected);
    this.assignObjectsOutput.emit(true);
  }
  // END HELPERS

  // TABLE FUNCTIONS
  setActivityTableData(): void {
    if (this.curFilterInput) {
      this.curFilterInput = Utils.sortArrayByObjectKeyString(this.curFilterInput, 'activity', 'name');
      this.activityTableData = this.masterScheduleService.transformTableData(this.curFilterInput, this.curActivitiesSelected, this.paginationStart);
    } else {
      this.tableDisplay = Utils.sortArrayByObjectKeyString(this.tableDisplay, 'activity', 'name');
      this.activityTableData = this.masterScheduleService.transformTableData(this.tableDisplay, this.curActivitiesSelected, this.paginationStart);
    }
  }

  rowSelection(rows: IRow[], selectAll: boolean) {
    if (selectAll && rows.length > 0) {
      this.curActivitiesSelected = this.tableInput.map(item => item.activity.id);
    } else if (selectAll && rows.length < 1) {
      this.curActivitiesSelected = [];
    } else {
      rows.forEach(row => {
        const index = this.curActivitiesSelected.indexOf(row.key);
        if (index > -1) this.curActivitiesSelected.splice(index, 1);
        else this.curActivitiesSelected.push(row.key);
      });
    }
    this.setActivityTableData();
    this.tableSelectionOutput.emit(this.curActivitiesSelected);
  }

  rowHover(row: IRow): void {
    let hoveredObjectIds: string[] = [];
    if (row) {
      hoveredObjectIds = this.tableDisplay.find(item => item.activity.id === row.key).activity.objectIds;
    }
    this.hoverObjectsOutput.emit(hoveredObjectIds);
  }

  applyPagination(paginationStart: number) {
    this.paginationStart = paginationStart;
    this.setActivityTableData();
  }

  applySearch(search: ITableSearchOptions) {
    if (search.searchQuery) {
      this.curSearch = search;
      this.curFilterInput = this.tableDisplay.filter( x => {
        let include = x.activity.name.toUpperCase().indexOf(search.searchQuery.toUpperCase()) !== -1;
        if (this.filterUnused) {
          include = include && x.activity.objectIds.length === 0;
        }
        return include;
      });
    } else {
      if (this.filterUnused) {
        this.curFilterInput = this.tableDisplay.filter( x => x.activity.objectIds.length === 0);
      } else {
        this.curSearch = null;
        this.curFilterInput = null;
      }
    }
    this.setActivityTableData();
  }

  customEditTableOutput(output: ICustomActionOutput): void {
    switch (output.actionFn) {
      case 'edit':
        this.editActivityOutput.emit(output.key);
        break;
      case 'assign':
        this.assignObjectsClick(output.key);
        break;
      case 'delete':
        this.deleteActivityOutput.emit([output.key]);
        break;
    }
  }
  // END TABLE FUNCTIONS

  bulkDeleteOutput(output: IRow[]) {
    this.deleteActivityOutput.emit(output.map(activity => activity.key));
  }

  filterOutput(filter: IFilterByOptions) {
    this.filterUnused = filter.active;
    if (this.filterUnused) {
      this.curFilterInput = this.tableDisplay.filter( x => {
        let include = x.activity.objectIds.length === 0;
        if (this.curSearch) {
          include = include && x.activity.name.toUpperCase().indexOf(this.curSearch.searchQuery.toUpperCase()) !== -1;
        }
        return include;
      });
      this.setActivityTableData();
    } else {
      if (this.curSearch) {
        this.applySearch(this.curSearch);
      } else {
        this.curFilterInput = null;
        this.setActivityTableData();
      }
    }
  }

}
