import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IWorkPackage } from '../../../models/work-package/work-package.interface';

import { Utils } from '../../../utils/utils';
@Component({
  selector: 'app-work-package-prereq-form',
  templateUrl: './work-package-prereq-form.component.html',
  styleUrls: ['./work-package-prereq-form.component.scss']
})
export class WorkPackagePrereqFormComponent implements OnInit, OnDestroy {
  @Input() formInput: FormGroup;
  @Output() formOutput: EventEmitter<IWorkPackage> = new EventEmitter();
  @Output() cancelFormOutput: EventEmitter<void> = new EventEmitter();

  wpChips: IWorkPackage[] = [];

  // returns specific form control by label
  get id() { return this.formInput.get('id'); }
  get name() { return this.formInput.get('name'); }
  get activityId() { return this.formInput.get('activityId'); }
  get inheritsPrereqs() { return this.formInput.get('inheritsPrereqs'); }
  get selectedWpPrereqs() { return this.formInput.get('selectedWpPrereqs'); }
  get wpPrereqOptions() { return this.formInput.get('wpPrereqOptions'); }
  get displayFn() { return Utils.defaultDisplayFn; }

  constructor() {/*EMPTY*/}

  ngOnInit() {
    this.setWpPrereqs();
  }

  ngOnDestroy() {
    this.clearValues();
  }

  setWpPrereqs(): void {
    if (this.selectedWpPrereqs.value) {
      const filteredOptions = [];
      this.wpChips = this.selectedWpPrereqs.value;
      this.wpPrereqOptions.value.forEach(option => {
        if (!this.wpChips.some(chip => chip.id === option.id)) filteredOptions.push(option);
      });

      this.wpPrereqOptions.setValue(filteredOptions);
    }
  }

  submit(): void {
    const wp: IWorkPackage = {
      id: this.id.value,
      activityId: this.activityId.value,
      name: this.name.value,
      inheritsPrereqs: this.inheritsPrereqs.value
    };

    if (this.selectedWpPrereqs.value) {
      wp.prerequisiteWorkPackages = this.selectedWpPrereqs.value;
    }

    this.formOutput.emit(wp);
  }

  cancelForm(): void {
    this.cancelFormOutput.emit();
  }

  wpPrereqSelected(matWp): void {
    const wp: IWorkPackage = matWp.option.value;
    this.wpChips.push(wp);
    this.selectedWpPrereqs.setValue(this.wpChips);

    this.wpPrereqOptions.setValue(this.wpPrereqOptions.value.filter(option => option.id !== wp.id));
  }

  removeWpPrereq(wp: IWorkPackage): void {
    this.wpChips = this.wpChips.filter(chip => chip.id !== wp.id);
    this.selectedWpPrereqs.setValue(this.wpChips);

    if (!Utils.objectInArray(this.wpPrereqOptions.value, 'id', wp.id)) {
      this.wpPrereqOptions.setValue(this.wpPrereqOptions.value.concat(wp));
    }
  }

  clearValues(): void {
    this.wpChips = [];
    this.id.setValue(null);
    this.name.setValue('');
    this.activityId.setValue('');
    this.selectedWpPrereqs.setValue([]);
    this.wpPrereqOptions.setValue([]);
  }
}
