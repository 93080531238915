import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IProjectStep } from '../../../models/project/project-step/project-step.interface';

@Component({
  selector: 'app-project-step-form',
  templateUrl: './project-step-form.component.html',
  styleUrls: ['./project-step-form.component.scss']
})
export class ProjectStepFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() formInput: FormGroup;
  @Output() stepOutput: EventEmitter<{data: IProjectStep, valid: boolean}> = new EventEmitter();

  addingNew: boolean = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // returns specific form control by label
  get stepName() { return this.formInput.get('stepName'); }
  get durationHours() { return this.formInput.get('durationHours'); }
  get crewSize() { return this.formInput.get('crewSize'); }
  get lead() { return this.formInput.get('lead'); }
  get lag() { return this.formInput.get('lag'); }
  get notes() { return this.formInput.get('notes'); }
  get ignoreBlockers() { return this.formInput.get('ignoreBlockers'); }

  constructor() {/*EMPTY*/}

  ngOnInit() {
    this.ignoreBlockers.setValue(!this.ignoreBlockers.value); /*-- ignoreBlockers === !Blocks Nearby Tasks --*/

    if (!this.stepName.value) this.addingNew = true;

    // Called to update edit modal on load
    this.onSubmit(this.formInput, this.addingNew);
  }

  ngOnChanges() {
    this.formInput.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onSubmit(this.formInput);
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  resetForm(): void {
    this.addingNew = true;
    this.formInput.reset();
  }

  onSubmit(form, firstAddingNew?): void {
    const formValues = form.getRawValue();
    const projectStep: IProjectStep = {
      id: formValues.id ? formValues.id : null,
      projectId: this.formInput.get('projectId').value,
      stepName: formValues.stepName,
      durationHours: formValues.durationHours,
      crewSize: formValues.crewSize,
      lead: formValues.lead,
      lag: formValues.lag,
      ignoreBlockers: !formValues.ignoreBlockers,
      objects: [],
      notes: formValues.notes
    };

    let valid = this.formInput.valid;
    if (firstAddingNew) valid = false;

    this.stepOutput.emit({data: projectStep, valid: valid});
  }
}
