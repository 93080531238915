import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import * as FileSaver from 'file-saver';
import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectService } from '../../../services/project/project.service';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent implements OnInit {

  @Output() closeExportModal: EventEmitter<boolean> = new EventEmitter();

  importedXmlFiles: Array<string>;
  scheduleData: any[];
  xmlFileName: string;
  gritVersion: string;
  selectedcheckbox: string;
  projectId: string;
  fileTypes: any;
  selectedFileType: string;
  disabledSubmitBtn: boolean = false;

  constructor(
    public activityService: ActivityService,
    public projectScheduleService: ProjectScheduleService,
    public projectService: ProjectService,
    private forgeViewerService: ForgeViewerService
  ) { }

  async ngOnInit() {
    this.fileTypes = ['xlsx', 'xml'];
    this.selectedFileType = 'xml';
    this.selectedcheckbox = '3';
    this.projectId = this.projectService.currentProject.id;
    await this.projectService.getProject(this.projectId).subscribe(project => {
      this.importedXmlFiles = project.importedActivityXmls ? project.importedActivityXmls : [];
      this.xmlFileName = this.importedXmlFiles && this.importedXmlFiles.length > 0 ? this.importedXmlFiles[this.importedXmlFiles.length - 1] : 'No file imported';
      this.disabledSubmitBtn = this.importedXmlFiles && this.importedXmlFiles.length > 0 ?  false : true;
    });
    await this.projectScheduleService.get(this.projectService.currentProject.id).subscribe(
      res => {
        this.scheduleData = res;
        this.scheduleData.length > 0 ? this.gritVersion = res.find((item) => item.active === true).id : this.disabledSubmitBtn = true;
      },
      err => {}
    );
  }

  async export() {
    this.forgeViewerService.setDisablePage(true);
    const fileType = this.selectedFileType ? this.selectedFileType : undefined;
    const scheduleId = this.gritVersion ? this.gritVersion : undefined;
    const sortOptions = this.selectedcheckbox ? this.selectedcheckbox : undefined;
    await this.activityService.downloadXML(this.projectId, this.xmlFileName, fileType, scheduleId, sortOptions).subscribe(
      res => {
        const blob = new Blob([res], { type: fileType === 'xlsx' ? 'text/xlsx' : 'text/xml' });
        FileSaver.saveAs(blob,
          this.xmlFileName.slice(0, -4) + '.' + fileType);
        this.forgeViewerService.setDisablePage(false);
        this.closeExportModal.emit(false);
      }, err => {
        this.forgeViewerService.setDisablePage(false);
      });
  }

}
