import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectSprintService } from '../../../services/project/project-sprint/project-sprint.service';
import { ProjectService } from '../../../services/project/project.service';

import { INoficationContext } from '../../../models/notification/notification.interface';
import { IProjectSprintForm } from '../../../models/project/project-sprint/project-sprint.interface';

import { PopupModalState } from '../../../utils/enums/popup-modal-state.enum';
import { Utils } from '../../../utils/utils';

import * as moment from 'moment';
import { IUserPermission } from '../../../models/user/user.interface';

enum InvalidSprintFormInput {
  invertedDates,
  requireSprintName,
  duplicateSprintName
}
@Component({
  selector: 'app-project-sprint-form',
  templateUrl: './project-sprint-form.component.html',
  styleUrls: ['./project-sprint-form.component.scss']
})

export class ProjectSprintFormComponent implements OnInit, OnChanges {
  @Input() sprintForm: FormGroup;
  @Output() submitOutput: EventEmitter<IProjectSprintForm> = new EventEmitter();
  @Output() endSprintOutput: EventEmitter<IProjectSprintForm> = new EventEmitter();

  addSprintForm: FormGroup;
  invalidDatesMessage: string = '';
  invalidSprintNameMessage: string = '';
  submitBtnLabel: string;
  projectPermission: IUserPermission;
  hasSprintEditPermission: boolean;
  private allSprintNames: string[];

  constructor(
    private projectSprintService: ProjectSprintService,
    private notificationService: NotificationService
  ) {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {
    this.projectPermission = ProjectService.userPermission;
    this.hasSprintEditPermission = this.projectPermission && this.projectPermission.gc && this.projectPermission.edit;
  }

  ngOnChanges() {
    this.allSprintNames = this.projectSprintService.sprintList.length > 0
      ? this.projectSprintService.sprintList.map(sprint => sprint.name.toLowerCase())
      : [];

    if (this.sprintForm) {
      this.sprintForm.setValidators([
        this.validateDates('scheduledStartDate', 'scheduledEndDate'),
      ]);

      this.name.setValidators([
        this.validateSprintName()
      ]);

      this.setSubmitBtnlabel(this.type);
    }

  }

  // returns specific form control by label
  get type() { return this.sprintForm.get('type').value; }
  get name() { return this.sprintForm.get('name'); }
  get scheduledStartDate() { return this.sprintForm.get('scheduledStartDate'); }
  get scheduledEndDate() { return this.sprintForm.get('scheduledEndDate'); }

  setSubmitBtnlabel(formType: PopupModalState): void {
    switch (formType) {
      case PopupModalState.AddSprint :
      this.submitBtnLabel = 'add_work_plan';
      break;

      case PopupModalState.UpdateSprint :
      this.submitBtnLabel = 'update_sprint';
      break;

      case PopupModalState.UpdateStartSprint :
      this.submitBtnLabel = 'update_sprint';
      break;

      default:
      this.submitBtnLabel = 'submit';
      break;
    }
  }

  /**
   * validateDates - ensures sprint dates are in the future and start if before end
   */
  validateDates(from: string, to: string): any {
    return (group: FormGroup): {[key: string]: any} => {
      const startUtc = group.controls[from].value ? Utils.toUtcDate(group.controls[from].value) : null;
      const endUtc = group.controls[to].value ? Utils.toUtcDate(group.controls[to].value) : null;
      const yesterday = Utils.getUtcYesterday();
      const invertedDates = (startUtc && endUtc) && (startUtc >= endUtc);

      // add message to form.errors object
      if (invertedDates) {
        this.setInvalidDateMessage(InvalidSprintFormInput.invertedDates);

        return { invalidDates: true };
      }

      return {};
    };
  }

  setInvalidDateMessage(type: InvalidSprintFormInput): void {
    switch (type) {
      case InvalidSprintFormInput.invertedDates :
        this.invalidDatesMessage = 'inverted_dates_error';
        break;
      default :
        this.invalidDatesMessage = 'invalid_dates';
    }
  }

  validateSprintName() {
    return () => {
      if (!this.name.value) {
        this.setInvalidSprintNameMessage(InvalidSprintFormInput.requireSprintName);
        return { isEmpty: true };
      } else {
        const sprintNameExists = this.checkIfSprintNameExists();
        this.setInvalidSprintNameMessage(InvalidSprintFormInput.duplicateSprintName);

        return sprintNameExists
          ? { invalidSprintName: sprintNameExists }
          : {};
      }
    };
  }

  checkIfSprintNameExists(): boolean {
    const inputSprintName = this.name.value ? this.name.value.toLowerCase() : null;
    const sprintNameExists = inputSprintName ? this.allSprintNames.filter(sprintName => sprintName === inputSprintName).length > 0 : false;
    return sprintNameExists;
  }

  setInvalidSprintNameMessage(type: InvalidSprintFormInput): void {
    switch (type) {
      case InvalidSprintFormInput.requireSprintName :
        this.invalidSprintNameMessage = 'name_required';
        break;

      case InvalidSprintFormInput.duplicateSprintName :
        this.invalidSprintNameMessage = 'sprint_name_duplicate';
        break;

      default :
        this.invalidDatesMessage = 'sprint_name_invalid';
    }
  }

  resetForm(): void {
    this.sprintForm.controls['name'].reset();
    this.sprintForm.controls['scheduledStartDate'].reset();
    this.sprintForm.controls['scheduledEndDate'].reset();
  }

  setEndDate(startDate: Date, dayCount: number): void {
    this.scheduledEndDate.setValue(moment(startDate).utc().clone().add('day', dayCount).toDate());
  }

  onSubmit(form): void {
    const formValues = form.getRawValue() ? form.getRawValue() : null; // getRawValue() both enabled and disabled values
    const validSubmission = formValues && !Utils.objectHasNull(formValues);

    if (validSubmission) {
      const sprintInfo = {
        name: formValues.name,
        scheduledStartDate: Utils.toUtcDate(this.scheduledStartDate.value),
        scheduledEndDate: Utils.toUtcDate(this.scheduledEndDate.value)
      };

      this.submitOutput.emit(sprintInfo);
    } else {
      const context: INoficationContext = {
        type: 'sprint',
        action: 'add'
      };
      const errorMessage = 'Error adding sprint';

      this.notificationService.error(errorMessage, context);
    }
  }

  endSprint(): void {
    this.endSprintOutput.emit();
  }
}
