import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpBackendService } from '../../services/http-backend/http-backend.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { SegmentService } from '../../services/segment/segment.service';

// tslint:disable
const asRgb = function(h, s, l) {
  const hp = h / 60;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((hp % 2) - 1));
  const r = 0 <= hp && hp < 1 || 5 <= hp && hp < 6 ? c :
  1 <= hp && hp < 2 || 4 <= hp && hp < 5 ? x : 0;
  const g = 1 <= hp && hp < 2 || 2 <= hp && hp < 3 ? c :
  0 <= hp && hp < 1 || 3 <= hp && hp < 4 ? x : 0;
  const b = 3 <= hp && hp < 4 || 4 <= hp && hp < 5 ? c :
  2 <= hp && hp < 3 || 5 <= hp && hp < 6 ? x : 0;
  const m = l - 0.5 * c;
  return `rgb(${Math.floor((r + m) * 255)}, ${Math.floor((g + m) * 255)}, ${Math.floor((b + m) * 255)})`;
}

const getColor = function(i, m) {
  const h = (i / m * 360 + 270) % 360;
  const s = 1;
  const l = 0.25;
  return asRgb(h, s, l);
}

let running = false;
@Component({
  selector: 'app-gritris',
  templateUrl: './gritris.component.html',
  styleUrls: ['./gritris.component.scss']
})
export class GritrisComponent implements OnInit, OnDestroy {

  private canvas;
  private context;
  private width;
  private height;
  private hexWidth;
  private hexHeight;

  private table = [];
  private rows = 20;
  private cols = 10;
  private nextIndex = [];

  private pieces = [
    [[-1, 0], [0, 0], [1, 0], [2, 0]], // Long
    [[-1, 0], [0, 0], [1, 0], [-2, -1]], // L
    [[-1, 0], [0, 0], [1, 0], [1, -1]], // L
    [[-1, 0], [0, 0], [0, -1], [-1, -1]], // box
    [[-1, 0], [0, 0], [1, 0], [0, -1]], // flag
    [[-1, 0], [0, 0], [1, 0], [-1, -1]], // flag
    [[-1, 0], [0, -1], [1, 0], [-1, -1]], // hook
    [[-1, 0], [0, 0], [0, -1], [1, -1]], // Z
    [[1, 0], [0, 0], [-1, -1], [-2, -1]], // Z
    [[-1, 0], [0, 0], [0, -1], [1, 1]] // Y
  ];

  private colors = [
    getColor(0, 10),
    getColor(1, 10),
    getColor(2, 10),
    getColor(3, 10),
    getColor(4, 10),
    getColor(5, 10),
    getColor(6, 10),
    getColor(7, 10),
    getColor(8, 10),
    getColor(9, 10)
  ];

  private currentPiece;
  private currentIndex;
  private currentX;
  private currentY;

  private timeout;
  private keypress;
  private touchstart;
  private touchmove;
  private touchend;
  private click;
  private lost;
  private lines;
  private random = null;
  private isRunning = false;

  private verification = {name: 'Default', timestamp: null, sequence: []};

  constructor(private backend: HttpBackendService, private segment: SegmentService) {

  }

  ngOnInit() {
    this.segment.track('Played Gritris', {});
    
    if (running) {
      return;
    }
    
    if (MenuEmitterService.getAuthenticatedUser()) localStorage.setItem('gritrisName', MenuEmitterService.getAuthenticatedUser().firstName);
    if (!localStorage.getItem('gritrisName')) {
      localStorage.setItem('gritrisName', prompt('Please enter you name', 'Gritris'));
    }
    this.verification.name = localStorage.getItem('gritrisName');

    running = true;
    this.isRunning = true;

    this.canvas = document.getElementById('gritris-canvas');
    this.canvas.setAttribute('width', this.canvas.clientWidth);
    this.canvas.setAttribute('height', this.canvas.clientHeight);
    this.context = this.canvas.getContext('2d');
    this.width = this.canvas.clientWidth;
    this.height = this.canvas.clientHeight;
    const sideLength = Math.min(this.width / (this.rows - 1 + this.cols * 2) / this.sqrt * 2,
                                this.height / ((this.rows - 1) * 3 + 4) * 2);
    this.hexWidth = sideLength * this.sqrt / 2;
    this.hexHeight = sideLength / 2;
    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.cols; x++) {
        this.table.push(0);
      }
    }
    this.lines = 0;
    this.nextIndex = [this.nextRandom(), this.nextRandom(), this.nextRandom()];

    this.restore();

    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.cols; x++) {
        this.drawHexagon(x, y);
      }
    }
    this.renderNext(1);
    this.renderLines();

    this.keypress = (event) => {
      this.onKeyPress(event);
    };
    this.touchstart = (event) => {
      this.onTouchMove(event, true);
    }
    this.touchmove = (event) => {
      this.onTouchMove(event);
    };
    this.touchend = (event) => {
      this.onTouchMove(event, false);
    };
    this.click = (event) => {
      this.onClick(event);
    };
    window.addEventListener('keydown', this.keypress);
    window.addEventListener('touchmove', this.touchmove);
    window.addEventListener('touchstart', this.touchstart);
    window.addEventListener('touchend', this.touchend);
    window.addEventListener('click', this.click);
    this.verification.sequence.push({type: 'p'});
    this.doProgress(true);
  }

  private reset() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.verification.sequence = [];
    this.verification.timestamp = null;
    this.random = null;
    
    this.table = [];
    this.lines = 0;
    this.currentPiece = null;
    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.cols; x++) {
        this.table.push(0);
      }
    }
    this.nextIndex = [this.nextRandom(), this.nextRandom(), this.nextRandom()];

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.backend.get('/gritris/scores').subscribe((scores) => {
      this.context.font = '24px serif';
      scores.forEach((s, i) => {
        this.context.fillText(`${s.name} ${s.score}`, this.width - 120, i * 30 + 30);
      });
    });

    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.cols; x++) {
        this.drawHexagon(x, y);
      }
    }
    this.renderNext(1);
    this.renderLines();

    this.verification.sequence.push({type: 'p'});
    this.doProgress(true);
  }

  private nextRandom() {
    if (!this.random) {
      this.verification.timestamp = new Date().getTime();
      this.random = this.verification.timestamp;
    }

    this.random = (this.random * 16807) % 2147483647;

    return this.random % this.pieces.length;
  }

  private restore() {
    const obj: any = JSON.parse(localStorage.getItem('gritris') || 'null');
    if (obj) {
      this.table = obj.table || this.table;
      this.lines = obj.lines || 0;
      this.currentX = obj.currentX || this.currentX;
      this.currentY = obj.currentY || this.currentY;
      this.currentPiece = obj.currentPiece || this.currentPiece;
      this.currentIndex = obj.currentIndex || this.currentIndex;
      this.nextIndex = obj.nextIndex || this.nextIndex;
      this.random = obj.random;
      this.verification = obj.verification;

      this.renderNext(1);
    }
  }

  private save() {
    if (this.lost) {
      localStorage.removeItem('gritris');
      this.backend.post('/gritris/scores', this.verification).subscribe(result => {
        this.backend.get('/gritris/scores').subscribe((scores) => {
          const text = ['Your Score: ' + result.score, '', 'High Scores:'];
          scores.forEach((s, i) => {
            text.push(`${s.name}: ${s.score}`);
          });
          alert(text.join('\n'));
        });
      });
      return;
    }
    localStorage.setItem('gritris', JSON.stringify({
      table: this.table,
      lines: this.lines,
      currentX: this.currentX,
      currentY: this.currentY,
      currentPiece: this.currentPiece,
      currentIndex: this.currentIndex,
      nextIndex: this.nextIndex,
      random: this.random,
      verification: this.verification
    }));
  }

  ngOnDestroy() {
    if (this.isRunning) {
      running = false;
    } else {
      return;
    }
    this.save();
    window.removeEventListener('keydown', this.keypress);
    window.removeEventListener('touchmove', this.touchmove);
    window.removeEventListener('touchstart', this.touchstart);
    window.removeEventListener('touchend', this.touchend);
    window.removeEventListener('click', this.click);
    clearTimeout(this.timeout);
  }

  private generateNext() {
    this.renderNext(0);
    this.nextIndex.shift();
    this.nextIndex.push(this.nextRandom());
    this.renderNext(1);
  }

  private renderNext(color) {
    this.nextIndex.forEach((v, i) => {
      this.renderNextIndex(v, i, color);
    });
  }
  private renderNextIndex(v, j, color) {
    const piece = this.pieces[v];
    for (let i = 0; i < piece.length; i++) {
      const x = 13 + piece[i][0];
      const y = 17 - j * 4  + piece[i][1];

      this.drawHexagon(x, y, color > 0 ? this.colors[v] : '#fff');
    }
  }

  private doProgress(timer) {
    if (!this.currentPiece) {
      this.currentPiece = [];
      this.currentIndex = this.nextIndex[0];
      const piece = this.pieces[this.nextIndex[0]];
      piece.forEach(p => this.currentPiece.push([p[0], p[1]]));
      this.currentX = 4;
      this.currentY = 19;
      this.lost = false;
      for (let i = 0; i < this.currentPiece.length; i++) {
        const x = this.currentX + this.currentPiece[i][0];
        const y = this.currentY + this.currentPiece[i][1];
        if (this.table[y * this.cols + x] === 1) {
          this.lost = true;
          break;
        }
      }
      if (this.lost) {
        this.renderPiece(1);
        this.save();
        return;
      } else {
        this.renderPiece(2);
      }
      this.generateNext();
    } else {
      if (!this.move(0, -1)) {
        this.renderPiece(1);
        this.currentPiece = null;
        this.checkLines();
      }
    }

    this.resetTimer();
  }

  private resetTimer() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.verification.sequence.push({type: 'p'});
      this.doProgress(true);
    }, 20 + Math.max(0, 490 - 20 * Math.floor(this.lines / 10)));
  }

  private checkLines() {
    let cleared = 0;
    for (let y = 0; y - cleared < this.rows; y++) {
      let match = true;
      for (let x = 0; x < this.cols; x++) {
        this.table[(y - cleared) * this.cols + x] = y >= this.rows ? 0 : this.table[y * this.cols + x];
        if (cleared > 0) {
          this.drawHexagon(x, y - cleared);
        }
        if (this.table[(y - cleared) * this.cols + x] === 0) {
          match = false;
        }
      }
      if (match) {
        this.lines++;
        this.renderLines();
        cleared++;
      }
    }
  }

  private renderLines() {
    let lines = this.lines;
    for (let i = 0; i < 3; i++) {
      const remainder = lines - Math.floor(lines / 10) * 10;
      for (let j = 0; j < 10; j++) {
        this.drawHexagon(-2 - i, j, j >= remainder ? '#fff' : i === 2 ? '#f00' : i === 1 ? '#0f0' : '#00f');
      }
      lines = Math.floor(lines / 10);
    }
  }

  private renderPiece(color) {
    for (let i = 0; i < this.currentPiece.length; i++) {
      const x = this.currentX + this.currentPiece[i][0];
      const y = this.currentY + this.currentPiece[i][1];

      if (x < 0 || x >= this.cols || y < 0 || y >= this.rows) {
        continue;
      }
      this.table[y * this.cols + x] = color;
      this.drawHexagon(x, y, color === 2 ? this.colors[this.currentIndex] : undefined);
    }
  }

  private drawHexagon(x, y, override?) {

    const code = this.table[y * this.cols + x];
    const color = override || (code === 2 ? '#070' : code === 1 ? '#700' : (x % 2 === 1 ? '#444' : '#000'));

    const rx = this.hexWidth * (x * 2 + 19 - y);
    const ry = (19 - y) * this.hexHeight * 3;
    this.context.fillStyle = color;
    this.context.strokeStyle = '#fff';
    this.context.beginPath();
    this.context.moveTo(rx, ry + this.hexHeight);
    this.context.lineTo(rx + this.hexWidth, ry);
    this.context.lineTo(rx + this.hexWidth * 2, ry + this.hexHeight);
    this.context.lineTo(rx + this.hexWidth * 2, ry + this.hexHeight * 3);
    this.context.lineTo(rx + this.hexWidth, ry + this.hexHeight * 4);
    this.context.lineTo(rx, ry + this.hexHeight * 3);
    this.context.closePath();
    this.context.fill();
    this.context.stroke();
  }

  private move(dx, dy) {
    let blocked = false;
    for (let i = 0; i < this.currentPiece.length; i++) {
      const nx = this.currentX + this.currentPiece[i][0] + dx;
      const ny = this.currentY + this.currentPiece[i][1] + dy;
      if ((ny < 0 || nx < 0 || nx >= this.cols || ny < this.rows && this.table[ny * this.cols + nx] === 1)) {
        blocked = true;
        break;
      }
    }
    if (blocked && dy === 0) {
      if (this.move(dx < 0 ? -1 : 0, -1)) {
        this.resetTimer();
        return true;
      }
      return false;
    }
    if (!blocked) {
      this.renderPiece(0);
      this.currentX += dx;
      this.currentY += dy;
      this.renderPiece(2);
    }
    return !blocked;
  }

  private rotate(direction) {
    let blocked = true;
    let bdx;
    let bdy;
    for (let dy = 0; dy >= -1; dy--) {
      for (let dx = -1; dx <= (dy === 0 ? 1 : 0); dx++) {
        let found = false;
        for (let i = 0; i < this.currentPiece.length; i++) {
          const pos = this.rotatePoint(this.currentPiece[i][0], this.currentPiece[i][1], direction);
          const nx = this.currentX + pos[0] + dx;
          const ny = this.currentY + pos[1] + dy;
          if ((ny < 0 || nx < 0 || nx >= this.cols || ny < this.rows && this.table[ny * this.cols + nx] === 1)) {
            found = true;
            break;
          }
        }
        if (!found && (blocked || dy === 0 && dx === 0)) {
          bdx = dx;
          bdy = dy;
          blocked = false;
        }
      }
    }
    if (!blocked) {
      this.renderPiece(0);
      for (let i = 0; i < this.currentPiece.length; i++) {
        const pos = this.rotatePoint(this.currentPiece[i][0], this.currentPiece[i][1], direction);
        this.currentPiece[i] = [pos[0] + bdx, pos[1] + bdy];
      }
      this.renderPiece(2);
    }
    return !blocked;
  }

  // -1, 0; -1, 1; 0, 1; 1, 0; 1, -1; 0, -1
  private cos = Math.cos(Math.PI / 3);
  private sin = Math.sin(Math.PI / 3);
  private sqrt = Math.sqrt(3);

  private rotatePoint(x, y, dir) {
    const tx = (x + y) * this.sqrt;
    const ty = (x - y) * 3;
    const nx = this.cos * tx - dir * this.sin * ty;
    const ny = dir * this.sin * tx + this.cos * ty;
    const fx = Math.round((nx / this.sqrt + ny / 3) * 0.5);
    const fy = Math.round((nx / this.sqrt - ny / 3) * 0.5);
    return [fx, fy];
  }

  private lastX = null;
  private lastY = null;
  private moved = false;
  onTouchMove(event, start?) {
    const touch = event.changedTouches && event.changedTouches[0] || event.touches && event.touches[0];
    if (touch) {
      if (!this.lastX) {
        this.lastX = touch.clientX;
      } else if (Math.abs(this.lastX - touch.clientX) >= this.hexWidth * 1.6) {
        const dir = this.lastX < touch.clientX ? 1 : -1;
        this.verification.sequence.push({type: 'm', dx: dir, dy: 0});
        this.move(dir, 0);
        this.lastX = touch.clientX;
        this.moved = true;
      }
      if (!this.lastY) {
        this.lastY = touch.clientY;
      } else if (touch.clientY - this.lastY >= this.hexHeight * 3.2) {
        this.verification.sequence.push({type: 'p'});
        this.doProgress(false);
        this.lastY = touch.clientY;
        this.moved = true;
      }
    }
    if (start === false) {
      // if (!this.moved) {
      //   this.rotate(event.touches.length > 1 ? -1 : 1);
      // }
      this.lastX = null;
      this.lastY = null;
      this.moved = false;
    }
  }

  onClick(event) {
    if (this.lost) {
      this.reset();
      return;
    }
    if (!this.currentPiece) {
      return;
    }
    const x = event.clientX;
    const rect = this.canvas.getBoundingClientRect();
    const dir = (x - rect.left) / (this.hexWidth * 40) < 0.5 ? -1 : 1;
    this.verification.sequence.push({type: 'r', direction: dir});
    this.rotate(dir);
  }

  onKeyPress(event) {
    if (this.lost) {
      this.reset();
      return;
    }
    if (!this.currentPiece) {
      return;
    }
    switch (event.key) {
      case 'a':
      case 'ArrowLeft':
        this.verification.sequence.push({type: 'm', dx: -1, dy: 0});
        this.move(-1, 0);
        break;
      case 'ArrowRight':
      case 'd':
        this.verification.sequence.push({type: 'm', dx: 1, dy: 0});
        this.move(1, 0);
        break;
      case 'q':
      case ' ':
      case 'ArrowUp':
        this.rotate(-1);
        this.verification.sequence.push({type: 'r', direction: -1});
        break;
      case 'w':
        this.rotate(-1);
        this.verification.sequence.push({type: 'r', direction: -1});
        this.rotate(-1);
        this.verification.sequence.push({type: 'r', direction: -1});
        this.rotate(-1);
        this.verification.sequence.push({type: 'r', direction: -1});
        break;
      case 'e':
      case 'Enter':
        this.rotate(1);
        this.verification.sequence.push({type: 'r', direction: 1});
        break;
      case 's':
      case 'ArrowDown':
        this.verification.sequence.push({type: 'p'});
        this.doProgress(false);
        break;
    }
  }
}
