import { IProjectScheduleStep } from '../project-schedule/project-schedule.interface';
import { IProjectSprintStory } from './project-sprint-story/project-sprint-story.interface';
import { ViewMode } from '../../../utils/enums/shared.enum';

export interface IProjectSprint {
  name: string;
  scheduledStartDate: number;
  scheduledEndDate: number;
  id?: string;
  projectId?: string;
  startDate?: number;
  endDate?: number;
  closedDate?: number;
  stories?: IProjectSprintStory[];
  is_default: boolean;
}

export interface IProjectSprintForm {
  name: string;
  scheduledStartDate: number;
  scheduledEndDate: number;
  id?: string;
  projectId?: string;
  startDate?: number;
  endDate?: number;
}

export interface IProjectSprintChartOutput {
  rangeStart: number;
  selectedSteps: IProjectScheduleStep[];
  blockerSteps?: IProjectScheduleStep[];
}

export interface IProjectViewModes {
  mode: ViewMode;
  label: string;
  selected: boolean;
  showOnDefaultSprint?: boolean;
}

export interface ISprintStoryResult {
  id?: string;
  name?: string;
  status?: SprintStoryResultStatus;
  projectId?: string;
}

export enum SprintStoryResultStatus {
  COMPLETE = 1,
  INCOMPLETE = 2
}

export interface INotCompleteOutput {
  result: string;
  notes?: string;
  moveTo: string;
}

export interface ICompleteOutput {
  result: string;
  notes?: string;
  actualDuration: number;
  actualCrewSize: number;
  completionDate: number;
  startDate? : number;
  actualEquipment: IStoryEquipment[];
  actualMaterials: IStoryMaterial[];
  actualLabors?: IStoryLabor[];
  isApproved? : boolean;
}

export interface IInProgressOutput {
  startDate: number;
  expectedFinishDate: number;
  duration?: number;
}

export interface IStoryEquipment {
  equipmentId: string;
  name?: string;
  calculatedHours?: number;
  hours: number;
  plannedQuantity? :any;

}

export interface IStoryMaterial {
  materialId: string;
  name?: string;
  calculatedQuantity?: number;
  quantity: number;
  plannedQuantity? :any;
}

export interface IStoryLabor {
  laborId: string;
  name?: string;
  calculatedQuantity?: number;
  quantity?: number;
  plannedQuantity? :any;
}
