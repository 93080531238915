
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AccountService } from '../services/account/account.service';
import { CacheService } from '../services/cache/cache.service';
import { ForgeViewerService } from '../services/forge/forge-viewer.service';
import { PDFViewerService } from '../services/pdf/pdfViewer.service';
import { MenuEmitterService } from '../services/menu/menu-emitter.service';
import { MenuService } from '../services/menu/menu.service';
import { NotificationService } from '../services/notification/notification.service';
import { ProjectModelService } from '../services/project/project-model/project-model.service';
import { ProjectObjectService } from '../services/project/project-object/project-object.service';
// ProjectPlannerPopoutModalService is needed so the popout viewer can reference it correctly
import { ProjectPlannerPopoutModalService } from '../services/project/project-planner-popout-modal/project-planner-popout-modal.service';
import { ProjectService } from '../services/project/project.service';
import { SegmentService } from '../services/segment/segment.service';
import { SnapshotsService } from '../services/project/snapshots/snapshots.service';
import { UserService } from '../services/user/user.service';

import { IAccount } from '../models/account/account.interface';
import { IConfirmationModalInput } from '../models/confirmation-modal/confirmation-modal.interface';
import { IForgeContextMenuOutput, ISnapshot } from '../models/forge/forge.interface';
import { INoficationContext } from '../models/notification/notification.interface';
import { IProject } from '../models/project/project.interface';
import { IUserPermission } from '../models/user/user.interface';
import { IPDFToolbar } from '../models/pdf/pdf.interface';

import { EventStatus, EventType } from '../utils/enums/notification.enum';
import { ReplaySubject } from 'rxjs';
import { Utils } from '../utils/utils';
import { ViewerMode } from '../utils/enums/shared.enum';
import { ForgeService } from '../services/forge/forge.service';
import {ProjectMasterScheduleComponent} from './project-master-schedule/project-master-schedule.component'

var projectMasterScheduleComponent: ProjectMasterScheduleComponent;
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})

export class ProjectComponent implements OnInit, OnDestroy {
  // Active url - Used for popout -> DO NOT REMOVE
  activeUrl: string;

  // Model Check
  allowModelsCheck: boolean = false;
  ranSetup: boolean = false;

  // display toggles
  pageIsLoading: boolean = true;
  projectDataLoading: boolean = true;
  loadingMessage: string = 'Model Loading';
  errorGettingData: boolean = false;

  // project info
  projectId: string;
  projectPermission: IUserPermission;
  accountPermission: IUserPermission;
  currentProject: IProject;
  currentAccount: IAccount;

  // Popout
  hasChildWindow: boolean;
  childWindow: Window;
  lastMainViewerState = {};
  lastForgeView: string = '{}';
  pdfViewerMode: boolean = false;
  pdfControlMode: boolean = true;
  isSideBarOpen: boolean = false;
  viewerMode: ViewerMode;

  // Forge Viewer
  @ViewChild('forgeViewer') forgeViewer: any;
  @ViewChild('pdfViewer') pdfViewer: any;
  @ViewChild('pdfControl') pdfControl: any;
  noModelsMessage: string = 'This project currently has no models associated with it.';
  forgeViewerLoadingProgress: number = 0;
  private prevView = null;
  showConfirmationModal: boolean = false;
  confirmationModalInput: IConfirmationModalInput;

  // routes that we want to load the forge viewer but hide it
  routesToHideForgeViewer: string[] = ['settings', 'dashboard'];

  // Subscriptions
  routeSubscription: Subscription;
  routerSubscription: Subscription;
  projectAccountSubscription: Subscription;
  forgeViewerLoadingSubscription: Subscription;
  permissionDataSubscription: Subscription;
  currentToolbar : IPDFToolbar;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private forgeService: ForgeService,
    private segmentService: SegmentService,
    private snapshotService: SnapshotsService,
    private cacheService: CacheService,
    private projectObjectService: ProjectObjectService,
    private notificationService: NotificationService,
    private projectPlannerPopoutModalService: ProjectPlannerPopoutModalService,
    public router: Router,
    public forgeViewerService: ForgeViewerService,
    public pdfViewerService: PDFViewerService,
    public projectService: ProjectService,
    public projectModelService: ProjectModelService,
    public menuService: MenuService,

  ) {
    router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = router.url;

        // if forge viewer is not loaded, the forge viewer loading emitter will do this
        await this.projectService.checkToHideViewerByRoute(this.activeUrl, this.projectId); // looks to hide viewer based on route AND criteria

        // sets the hidden context menu for the forgeviewer extension
        // if forge viewer is not loaded, the forge viewer loading emitter will do this
        this.forgeViewerService.notifyRoute(this.activeUrl);

        if (this.allowModelsCheck && this.projectService.getProjectReady()) {
          await this.runNewModelsCheck();
        }
      }
    });

    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.MODELS_INVALIDATED)) return;
      this.notificationService.eventPopup(EventStatus.Info, 'Models have changed. Please <a href=".">refresh your page</a> to get the latest updates.');
    });
    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.MODEL_CONFLICTS)) return;
      if (event.body.conflicts > 0) {
        this.notificationService.eventPopup(
          EventStatus.Info,
          `Model conflicts found. Please <a href="/project/${event.projectId}/model-conflicts">go to the conflict resolution page</a> to resolve them.`
        );
      }
    });
  }

  async ngOnInit() {
    // checks local storage to set main menu state
    this.menuService.checkMainMenuState();
    this.projectService.checkSidebarState();
    this.isSideBarOpen = localStorage.getItem('isSideBarOpen') === null ? false : true ;
    // NOTE: https://blog.angular-university.io/angular-debugging/
    // using setTimeout to defer the code to another VM turn code.
    // that way it needs to let Angular display the data before changing it again
    // setTimeout is still considered hackish but sometimes is necessary
    this.projectService.projectSidebarChange.subscribe((value) => {
      this.isSideBarOpen = value;
    });

    window['gritParentComponent'] = this;
    window.addEventListener('beforeunload', this.onClose.bind(this));

    this.routeSubscription = this.route.params.subscribe(async params => {

      this.ranSetup = false;
      this.allowModelsCheck = false;
      this.currentProject = null;
      this.projectService.clearCachedData();
      this.menuService.setAllowSubMenuNav(false);
      this.activeUrl = this.router.url;
      this.forgeViewerService.loadedRoute = this.activeUrl;
      this.projectId = params['projectId'];
      this.pageIsLoading = true;
      this.projectDataLoading = true;
      this.forgeViewerService.viewerLoaded = false;
      this.projectService.hasModels = false;
      this.projectService.hasPDFs = false;
      this.forgeViewerLoadingProgress = 0;

      await this.cacheService.setProjectId(this.projectId);

      // listen for viewer loading status
      this.forgeViewerLoadingSubscription = this.forgeViewerService.forgeViewerLoadingStateOutput.subscribe(res => {
        this.forgeViewerLoadingProgress = res;
        if (this.forgeViewerLoadingProgress >= 100) {
          this.menuService.setAllowSubMenuNav(true);
        }
      });
      await this.setProjectModelState();
      await this.setPermissions();
      await this.getProjectData(this.projectId);
      await this.loadObjects(this.projectId);
      await this.projectService.loadActivities(this.projectId);
      // loadCategories will load all categories for this project by sending in recurse = true
      await this.projectService.loadCategories(this.projectId, this.projectModelService.projectModels);
      await this.projectService.loadSubcontractors(this.projectId);
      await this.projectService.loadEquipments(this.projectId);
      await this.projectService.loadMaterials(this.projectId);
      await this.snapshotService.setLocalSnapshots(this.projectId);
      this.projectService.floorPlanPDFs = [];
      this.projectService.floorPlans = [];
      this.projectService.Bim360PDFURLs = {};
      await this.projectService.loadProcoreFloorPlanData(this.projectId);
      await this.projectService.loadBIM360FloorPlanData(this.projectId);
      for (let i = 0; i < this.projectService.floorPlanPDFs.length; i++) {
        if(this.projectService.floorPlanPDFs[i].indexOf("https://developer.api.autodesk.com") != -1){
          const x = await this.forgeService.getPDF(this.projectId, this.projectService.floorPlanPDFs[i].replace("https://developer.api.autodesk.com", "")).toPromise();
          var byteArray = new Uint8Array(x.data);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const blobURL = URL.createObjectURL(blob);
          this.projectService.Bim360PDFURLs[blobURL] = this.projectService.floorPlanPDFs[i];
          this.projectService.floorPlanPDFs[i] = blobURL;
        }
      }
      this.projectService.ViewerSetUp();
      this.projectService.setProjectDataReady(true);
      this.projectDataLoading = false;
      this.menuService.setAllowSubMenuNav(true);
      await this.projectService.checkToHideViewerByRoute(this.activeUrl, this.projectId); // looks to hide viewer based on route AND criteria
      if (!this.ranSetup)
        this.runSetup();
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.projectService.destroyOpenSubscriptions();
    this.projectService.clearCachedData();
    if (this.hasChildWindow) {
      this.onClose();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.projectAccountSubscription) {
      this.projectAccountSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.forgeViewerLoadingSubscription) {
      this.forgeViewerLoadingSubscription.unsubscribe();
    }
    if (this.permissionDataSubscription) {
      this.permissionDataSubscription.unsubscribe();
    }
  }

  runSetup() {
    if (this.projectService.getProjectReady()) {
      this.ranSetup = true;
      this.allowModelsCheck = true;
      // Remove unknown objects in viewer
      this.forgeViewerService.removeUnknownObjects();
      this.forgeViewerService.currentViewerState = this.projectObjectService.getAllObjectIds();
      const removeHidden = this.projectObjectService.getAllHiddenObjects();
      this.forgeViewerService.removeHiddenObjects(removeHidden);
      this.forgeViewerService.homeView = this.snapshotService.getHomeView();
      if (this.forgeViewerService.homeView) this.forgeViewerService.restoreView(this.forgeViewerService.homeView.forgeView);
      this.projectService.emitProjectSetup(this.projectService.hasModels);
    }
  }

  loadObjects(projectId: string) {
    return new Promise((resolve, reject) => {
      this.projectModelService.getModelList(projectId).subscribe(modelResults => {
        const promiseArray = [];

        this.projectModelService.projectModels = modelResults;

        promiseArray.push(this.projectService.loadObjects(projectId));
        promiseArray.push(this.projectService.loadHiddenObjects(projectId));

        modelResults.forEach(model => {
          promiseArray.push(this.projectService.loadModelObjects(model.id));
        });

        Promise.all(promiseArray).then(() => {
          this.projectService.buildForgeKeyMap();
          return resolve();
        });
      });
    });
  }

  async setProjectModelState() {
    let projectModels = await this.projectModelService.getModelList(this.projectId).toPromise();
    projectModels = projectModels.filter(model => model.status === 2);
    this.projectModelService.projectModels = projectModels;

    if (projectModels.length > 0) {
      this.projectModelService.existingModelIds = projectModels.map(model => model.id);
      this.projectService.hasModels = true;
      this.pageIsLoading = false;
    } else {
      this.projectService.hasModels = false;
      this.projectService.setForgeViewerReady(true);
      this.allowModelsCheck = true;
      this.menuService.setAllowSubMenuNav(true);
      this.pageIsLoading = false;
    }
  }

  // if new models are detected, we want to do a complete reset of project data
  async runNewModelsCheck() {
    const newModelsExist = await this.projectModelService.newModelsExist();
    if (newModelsExist) {
      this.notificationService.eventPopup(EventStatus.Info, 'Models have changed. Please <a href=".">refresh your page</a> to get the latest updates.');
    }
  }

  async setPermissions() {
    return new Promise((resolve, reject) => {

      const processUser = (authenticatedUser) => {
        const userProject = authenticatedUser.projects.find(project => project.id === this.projectId);
        const userAccount = authenticatedUser.accounts.find(account => account['name'] === userProject['accountName']);

        if (Utils.isEmpty(userProject)) {
          this.notificationService.error('COULD_NOT_LOAD_PERMISSIONS', null);
          return reject();
        }

        if (!Utils.isEmpty(userAccount)) {
          AccountService.userAccountPermission = {
            admin: userAccount.permission === 1,
            edit: userAccount.permission !== 0 && userAccount.permission <= 2,
            gc: null
          };
        } else {
          AccountService.userAccountPermission = null;
        }

        UserService.userId = authenticatedUser.id;

        ProjectService.userPermission = {
          admin: userProject.permission === 1,
          edit: userProject.permission <= 2,
          gc: Utils.isEmpty(userProject.subContractorId)
        };
        if (!Utils.isEmpty(userProject.subContractorId)) ProjectService.userPermission.subContractorId = userProject.subContractorId;

        this.projectPermission = ProjectService.userPermission;
        MenuEmitterService.emitPermissionChange(ProjectService.userPermission);

        resolve();
      };
      if (MenuEmitterService.getAuthenticatedUser()) {
        processUser(MenuEmitterService.getAuthenticatedUser());
      } else {
        MenuEmitterService.authenticatedUserChanged$.pipe(take(1)).subscribe(() => {
          processUser(MenuEmitterService.getAuthenticatedUser());
        });
      }
    });
  }

  // Get Data Methods
  getProjectData(projectId: string) {
    return new Promise(resolve => {
      // if routing between projects ng destroy doesn't get called so we need to close it over here too.
      if (this.hasChildWindow) {
        this.onClose();
      }
      const observableArray: Array<Observable<any>> = [];
      observableArray.push(this.projectService.getProjectAccount(projectId));
      observableArray.push(this.projectService.getProject(projectId));
      forkJoin(observableArray).subscribe(
        res => {
          this.currentAccount = res[0];
          this.currentProject = res[1];
          this.projectService.currentProjectAccount = this.currentAccount;
          this.projectService.currentProject = this.currentProject;
          this.projectService.currencyCode = this.currentProject.currency ? this.currentProject.currency : null;
          this.projectId = this.currentProject.id;

          this.projectService.currentProjectHeader = {
            title: {
              value: this.currentProject.name,
              editable: false
            },
            address: this.currentProject.address,
            account: this.currentAccount.name,
            trialExpires: this.currentAccount.trialExpires
          };
          this.segmentService.track('Project Loaded', { projectName: this.currentProject.name });
          resolve();
        },
        err => {
          const context: INoficationContext = {
            type: 'project data',
            action: 'get'
          };
          this.notificationService.error(err, context);

          resolve();
        });
    });
  }
  // END Get Data Methods

  // Popout window
  openNewWindow() {
    // disable page
    this.projectService.setDisablePage(true);
    this.projectService.setForgeViewerReady(false);

    if (this.childWindow) {
      if (!this.childWindow.closed) {
        this.childWindow.close();
        this.closedPopout();
      }
    } else {
      this.childWindow = window.open('/project/popout/' + this.projectId, 'childWindow', '_blank');
      this.lastForgeView = this.forgeViewerService.getForgeView();
      this.lastMainViewerState = Utils.copyKeysFromObject(this.forgeViewerService.currentViewerState);
      this.forgeViewerService.poppedOut = true;
      this.forgeViewerService.viewerLoaded = false;
      this.hasChildWindow = true;
    }
  }

  closedPopout(): any {
    // disable page
    this.lastForgeView = this.forgeViewerService.getForgeView();
    this.projectService.setDisablePage(false);
    this.projectService.setForgeViewerReady(true);
    this.hasChildWindow = false;
    this.forgeViewerService.reassignForgeViewer(this.forgeViewer);
    this.forgeViewerService.setToLatestForgeModels();
    this.childWindow = null;
    this.forgeViewerService.restoreView(this.lastForgeView);
    this.lastForgeView = '{}';
    this.forgeViewerService.currentViewerState = this.lastMainViewerState;
    this.projectService.emitProjectSetup(this.projectService.hasModels);
    this.forgeViewerService.resizeViewerAfterLoad();
    this.forgeViewerService.notifyRoute(this.activeUrl);
    this.forgeViewerService.enableTools();
  }

  closedPDFPopout() {
    this.pdfViewerService.reassignForgeViewer(this.pdfViewer);
  }

  onClose(): void {
    if (this.childWindow) this.childWindow.close();
    this.closedPopout();
  }

  zoomIn() {
    this.pdfViewerService.zoomIn();
    // this.pdfViewer.zoomIn();
  }
  zoomOut() {
    this.pdfViewerService.zoomOut();
    // this.pdfViewer.zoomOut();
  }
  fitToScreen() {
    this.pdfViewer.fitToScreen();
  }
  getPreviousPage() {
    this.pdfViewer.getPreviousPage();
  }
  getNextPage() {
    this.pdfViewer.getNextPage();
  }

  updateToolbar(toolbar: IPDFToolbar) {
    this.currentToolbar = toolbar;
    if (this.pdfControl) {
      this.pdfControl.updateToolbar(toolbar);
    }
  }

  updateAddAnnotationIcons(toggleBool: boolean) {
    this.projectService.updateAddAnnotationIcons(toggleBool);
  }

  disablePdfControlDrag() {
    if (this.pdfControl) {
      this.pdfControl.disableDrag();
    }
  }


  hideToolbar(toggleBool: boolean) {
    if (toggleBool) {
      // this.pdfControlMode = false;
      document.getElementById("pdfControlId").style.display = "none";
    }
    else {
      // this.pdfControlMode = true;
      if(document.getElementById("pdfControlId")!=null)
      document.getElementById("pdfControlId").style.display = "block";
    }
  }

  popoutKeyDownEvent(event: any): void {
    this.forgeViewerService.popoutKeyDownEvent(event);
  }

  popoutKeyUpEvent(event: any): void {
    this.forgeViewerService.popoutKeyUpEvent(event);
  }
  // END Popout window

  // Forge Viewer Methods
  async forgeViewLoaded(error?) {
    if (!error) {
      // START - This can be run without all project data being completed
      this.projectService.setForgeViewerReady(true);
      if (!this.forgeViewerService.poppedOut) {
        this.forgeViewerService.setForgeViewer(this.forgeViewer);
        this.forgeViewerService.recordLatestForgeModelIds();
      } else {
        const viewerComp = this.childWindow['gritPopoutComponent'].projectForgeViewerComponent;
        this.forgeViewerService.setToPopoutForgeModels(viewerComp);
        this.forgeViewerService.restoreView(this.lastForgeView);
        this.lastForgeView = '{}';
      }
      this.errorGettingData = false;
      // END

      // START Must check for project data to be there <-Runs if setup ran because of popout
      if (this.ranSetup) {
        // Remove unknown objects in viewer
        this.forgeViewerService.removeUnknownObjects();

        this.forgeViewerService.currentViewerState = this.projectObjectService.getAllObjectIds();
        const removeHidden = this.projectObjectService.getAllHiddenObjects();
        this.forgeViewerService.removeHiddenObjects(removeHidden);
        this.projectService.emitProjectSetup(this.projectService.hasModels);
      } else {
        this.runSetup();
      }

      this.forgeViewerService.notifyRoute(this.activeUrl);
      this.forgeViewerService.enableTools();
      // END
    } else {
      this.errorGettingData = true;
    }
  }

  handleForgeViewerOutput(forgeViewerOutputData: any): void {
    this.forgeViewerService.handleForgeViewerOutput(forgeViewerOutputData);
  }

  showSimilarOutput(filterOutput: any) {
    this.forgeViewerService.handleShowSimilarOutput(filterOutput);
  }

  clearFiltersOutput() {
    this.forgeViewerService.handleClearFiltersOutput();
  }

  contextMenuOutput(data: IForgeContextMenuOutput) {
    this.forgeViewerService.handleContextMenuOutput(data);
  }

  async saveViewOutput() {
    if (this.forgeViewerService.homeView) {
      this.overwrite();
    } else {
      const newSnapshot: ISnapshot = {
        name: 'HOME_VIEW',
        forgeView: this.forgeViewerService.getForgeView(),
        myViewerState: '{}',
        isHomeView: true,
        projectId: this.projectService.currentProject.id
      };
      await this.snapshotService.addSnapshot(newSnapshot).then(
        res => {
          this.forgeViewerService.homeView = res;
        }
      ).catch(() => {/*EMPTY*/ });
    }
  }

  async viewerCutOutput(data: any) {
    await this.projectService.loadObjects(this.projectId);
    this.forgeViewerService.handleViewerCutOutput(data);
  }
  // END Forge Viewer Methods

  resizeForgeViewer(): void {
    this.forgeViewerService.resizeViewerAfterLoad();
  }
  // END Data Resets

  // Helpers
  overwrite() {
    this.showConfirmationModal = true;
    this.confirmationModalInput = {
      displayMessage: 'are_you_sure',
      hasCancelAction: true,
      hasConfirmationAction: true
    };
  }

  async handleConfirmationConfirmOutput() {
    this.showConfirmationModal = false;
    this.prevView = this.forgeViewerService.homeView.forgeView;
    this.forgeViewerService.homeView.forgeView = this.forgeViewerService.getForgeView();
    await this.snapshotService.editSnapshot(this.forgeViewerService.homeView).catch(() => { this.forgeViewerService.homeView.forgeView = this.prevView; });
  }

  handleCancelCloseOutput(): void {
    this.showConfirmationModal = false;
  }

  navigateToFileManager(): void {
    this.router.navigateByUrl('/project/' + this.projectId + '/settings?menuItem=3');
  }

  pageRefresh(): void {
    window.location.reload(true);
  }
  // END Helpers
}
