import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-milestone-form',
  templateUrl: './project-milestone-form.component.html',
  styleUrls: ['./project-milestone-form.component.scss']
})
export class ProjectMilestoneFormComponent {

  @Input() formInput: FormGroup;

  @Output() submitOutput: EventEmitter<{name: string, endDate: number}> = new EventEmitter();

  constructor() {/*EMPTY*/}

  get name() { return this.formInput.get('name'); }
  get endDate() { return this.formInput.get('endDate'); }

  onSubmit(form): void {
    const formValues = form.getRawValue();
    const formOutput = {
      name: formValues.name,
      endDate: Utils.toUtcDate(formValues.endDate)
    };
    this.submitOutput.emit(formOutput);
  }

}
