export default {
  "1_month": "1 mes",
  "3_month": "3 meses",
  "6_month": "6 meses",
  "12_month": "12 meses",
  "abbreviation": "Abreviatura",
  "account_users": "Usuarios de la cuenta",
  "actual_start": "Comienzo real",
  "actual_finish": "Acabado real",
  "assign_activity": "ASIGNAR ACTIVIDAD",
  "accounts_menu_item": "Cuentas",
  "actions": "Comportamiento",
  "activate": "Activar",
  "activate_schedule": "Activar Horario",
  "activate_schedule_confirm": "¿Seguro que quieres activar este horario?",
  "activate_schedule_no_honor_confirm": "Este horario no cumple con los compromisos. Si lo activa, se eliminarán todos los Planes de Trabajo no activos. ¿Seguro que quieres activar este horario?",
  "activate_schedule_first": "Por favor active un horario para continuar",
  "active": "Activo",
  "active_schedule": "Horario activo",
  "activities": "Actividades",
  "activities_needing_planned": "Actividades que necesitan planeadas",
  "activity": "Actividad",
  "activity_does_not_contain_objects": "La actividad no contiene objetos",
  "activity_has_dependencies": "No se puede eliminar una actividad asignada a un objeto",
  "activity_prerequisite": "Prerrequisito de actividad",
  "activity_required": "Se requiere actividad",
  "activity_list": "Lista de actividades",
  "actual": "Real",
  "actual_hours": "Horas reales",
  "actual_hours_complete": "Horas por ciento completado",
  "actual_hours_percent_complete": "Horas reales% completado",
  "actual_percent_complete": "Porcentaje de actividad completado",
  "adBlocker_warning" : "Exportación a PDF de la vista actual. Asegúrese de que Ad Blocker esté desactivado para este sitio.",
  "add": "Añadir",
  "add_all_recommended": "Añadir todo lo recomendado",
  "add_box": "Añadir cuadro",
  "add_child": "Añadir niño",
  "add_cut": "Agregar corte",
  "add_days": "Agregar dias",
  "add_equipment": "Añadir equipo",
  "add_material": "Añadir material",
  "add_labor": "Añadir labor",
  "add_milestone": "Añadir hito",
  "add_objects_to_work_package": "Agregar objetos al paquete de trabajo",
  "add_or_edit_work_package": "Agregar o editar un paquete de trabajo",
  "add_package": "Añadir paquete",
  "add_prerequisite": "Añadir un requisito previo",
  "add_project_user": "Añadir usuario del proyecto",
  "add_rfi": "Insertar RFI",
  "add_scenario": "Añadir escenario",
  "add_subcontractor_role": "Añadir rol de subcontratista",
  "add_unmodeled_prereq": "Añadir un prerrequisito sin modelar",
  "add_unmodeled_task": "Añadir tarea sin modelar",
  "add_view": "Añadir vista",
  "add_work_plan": "Agregar Plan de Trabajo",
  "add_x_plane": "Añadir x plano",
  "add_y_plane": "Añadir y plano",
  "add_z_plane": "Añadir z plano",
  "adds_activities_predecessors_as_prerequisites": "Agrega las actividades enumeradas como predecesoras inmediatas como requisitos previos",
  "assigned_elements": "Elementos asignados",
  "edit_other_prereqs": "Editar otros requisitos previos",
  "edit_prereq_activities": "Editar actividades de requisitos previos",
  "edit_prereq_objects": "Editar objetos de requisitos previos",
  "editing_work_package_objects": "Seleccione objetos para instalar",
  "editing_prereq_objects": "Edición de objetos de requisitos previos",
  "editing_work_package_prereq_objects": "Edición de objetos de prerrequisitos de paquetes de trabajo",
  "adding_to_work_plan": "Agregando al Plan de Trabajo",
  "additional_equipment": "Equipamiento Adicional",
  "additional_materials": "Materiales Adicionales",
  "additional_labor": "Trabajo adicional",
  "address": "Dirección",
  "admin": "Admin",
  "aggregate_hours": "{{aggregateHours}} horas agregadas",
  "always": "Siempre",
  "anchor_date": "Fecha de anclaje",
  "api_keys": "Claves de la API",
  "api_token_created": "Se ha generado la clave y el secreto de tu token. Guarda el secreto, ya que esto no se te mostrará de nuevo.",
  "app_delete_confirm": "Proyecto de recarga",
  "app_help_ticket_sent": "Hemos recibido su solicitud y nos pondremos en contacto con usted lo antes posible",
  "app_not_planned": "Objeto no ha sido planeado",
  "app_password_match": "Las contraseñas deben coincidir",
  "app_required_fields_missng": "Por favor proporcione toda la información requerida",
  "app_reset_password_confirm": "Revisa la bandeja de entrada de {{email}} para restablecer tu contraseña",
  "app_verify_success": "Por favor Iniciar sesión",
  "approval_date_abbrev": "Fecha de Aprobación",
  "approval_date": "Fecha de aprobación",
  "approved": "Aprobado",
  "ar_code_expires": "Su código expirará en 5 minutos",
  "ar_qr_menu_item": "Código QR de AR",
  "archive": "Archivo",
  "archive_project": "Proyecto de archivo",
  "archive_project_description": "Ya no necesito acceso regular a este proyecto",
  "are_you_sure": "¿Estás seguro?",
  "ascending": "Ascendiendo",
  "assign_work_area": "Asignar área de trabajo",
  "assign_objects": "Asignar objetos",
  "assign_prereq": "Asignar un requisito previo",
  "assign_prerequisites": "Asignar requisitos previos",
  "assign_sprint": "Asignar Sprint",
  "auto_assign_info": "Información de asignación automática",
  "backlog": "Reserva",
  "blocker_information": "Información del bloqueador",
  "blockers": "Bloqueadores",
  "blocking_task": "Tarea de bloqueo",
  "blocks_nearby_tasks": "Bloquea las tareas cercanas",
  "board": "Tabla",
  "body_invalid": "Cuerpo inválido",
  "bored_menu_item": "Estoy aburrido",
  "budget": "Presupuesto",
  "budget_over_time": "Presupuesto a traves del tiempo",
  "budget_quantity" : "Cantidad de presupuesto",
  "budget_assigned": "Presupuesto asignado",
  "calculated": "Calculado",
  "calendar": "Calendario",
  "cancel": "Cancelar",
  "cannot_retrieve_planning_permissions": "No se pueden recuperar todos los permisos de planificación",
  "categories": "Categorías",
  "floor_plan": "Floor Plan",
  "fp": "Plan",
  "cat": "Cat",
  "category": "Categoría",
  "center": "Centrar",
  "change_password": "Cambia la contraseña",
  "circular_reference": "Las actividades no pueden depender unas de otras, creando un bucle. Por favor verifique sus requisitos previos: {{details}}",
  "clear": "Eliminar",
  "clear_filters": "Filtros claros",
  "clear_photo": "Eliminar imagen",
  "clear_set": "Eliminar conjunto",
  "closed": "Cerrado",
  "closing_sprint": "Cerrando el Plan de Trabajo",
  "collapse_menu_item": "Colapso",
  "color": "Color",
  "comments": "Comentarios",
  "commit": "Entregar",
  "committed": "Comprometido",
  "company": "Empresa",
  "compare_to": "Compare el horario",
  "completed": "Terminado",
  "completion_date_required": "Se requiere fecha de finalización",
  "confirm": "Confirmar",
  "confirm_password": "Confirmar contraseña",
  "confirm_to_finish": "Confirmar para terminar",
  "connect": "Conectar",
  "contact_email": "Email de contacto",
  "contact_name": "Nombre de contacto",
  "continue_without_saving": "¿Está seguro de que desea continuar sin guardar los cambios?",
  "cost": "Costo",
  "cost_data_error": "Datos sin costo dentro del rango",
  "flow_data_error": "No hay datos de actividades en el rango",
  "create": "Crear",
  "create_master_schedule": "Crear horario maestro",
  "create_new_project": "Crear nuevo proyecto",
  "creating_tasks_for_x": "Creando tareas para {{activityName}}",
  "credentials_invalid": "Correo electrónico y / o contraseña no coinciden",
  "credentials_missing": "Introduzca el correo electrónico y la contraseña",
  "crew_size": "Tamaño de la tripulación",
  "crew_size_at_least_x": "El tamaño de la tripulación debe ser al menos {{minCrewSize}}",
  "crew_size_less_than_x": "El tamaño de la tripulación debe ser inferior a {{maxCrewSize}}",
  "crew_size_must_be_integer": "El tamaño de la tripulación debe ser un número entero",
  "crew_size_must_be_number": "El tamaño de la tripulación debe ser un número",
  "crew_size_required": "Se requiere el tamaño de la tripulación",
  "crew_supply": "Suministro de tripulación",
  "criteria_dashboard": "Aún no estás listo para usar el tablero",
  "criteria_last_planner": "Aún no estás listo para usar el último planificador",
  "criteria_lookahead": "No estas listo para pronosticar",
  "criteria_low_permission": "Es posible que tenga permisos insuficientes para completar los criterios necesarios",
  "criteria_models": "Aún no estás listo para ver modelos",
  "criteria_network_map": "Aún no estás listo para usar el mapa de red",
  "criteria_make_ready": "Aún no estás listo para usar la página de preparación",
  "criteria_reports": "Aún no estás listo para ver informes",
  "criteria_scenarios": "Aún no estás listo para usar la página de escenarios",
  "criteria_sprints": "Aún no estás listo para hacer Planes de Trabajo",
  "criteria_step": "Paso {{step}}",
  "critical": "Crítico",
  "criticality": "Criticidad",
  "currency": "Moneda",
  "current_password": "Contraseña actual",
  "cut": "cortar",
  "descending": "Descendiendo",
  "dashboard_menu_item": "Tablero",
  "data_loading": "Cargando",
  "date": "Fecha",
  "date_added": "Fecha agregada",
  "date_by_unit": "Fecha (por {{unit}})",
  "date_completed": "Fecha de finalización",
  "daily_logs": "Registros diarios",
  "day": "Día",
  "day_friday": "viernes",
  "day_friday_abbreviation": "Vi",
  "day_friday_letter": "V",
  "day_monday": "lunes",
  "day_monday_abbreviation": "Lu",
  "day_monday_letter": "L",
  "day_saturday": "sábado",
  "day_saturday_abbreviation": "Sa",
  "day_saturday_letter": "S",
  "day_sunday": "domingo",
  "day_sunday_abbreviation": "Do",
  "day_sunday_letter": "D",
  "day_thursday": "jueves",
  "day_thursday_abbreviation": "Ju",
  "day_thursday_letter": "J",
  "day_tuesday": "martes",
  "day_tuesday_abbreviation": "Ma",
  "day_tuesday_letter": "M",
  "day_wednesday": "miércoles",
  "day_wednesday_abbreviation": "Mi",
  "day_wednesday_letter": "X",
  "days": "Dias",
  "dys": "Dia(s)",
  "delete": "Eliminar",
  "delete_confirm": "¿Estas seguro que quieres borrarlo?",
  "delete_tasks": "Eliminar las tareas del último planificador",
  "delete_tasks_description": "Quiero eliminar todas las tareas planificadas en el último planificador",
  "delete_work_plan": "Eliminar Plan de Trabajo",
  "delivered": "Entregado",
  "detach_attach_viewer": "Separar/Adjuntar visor",
  "deviation": "Desviación",
  "disabled": "Deshabilitado",
  "disconnect": "Desconectar",
  "do_not_leave_page": "No dejes esta pagina",
  "done": "Hecho",
  "download_export_template": "Descargue nuestra plantilla para exportar desde Microsoft Project",
  "download_help": "Descarga nuestra guía de ayuda",
  "downstream_influence": "Influencia corriente abajo",
  "downstream_tasks": "{{downstreamTasks}} tareas posteriores",
  "duplicate_email": "El usuario ya existe",
  "duplicate_project_model_file_name": "El modelo ya existe con este nombre de archivo",
  "duplicate_sprint_name": "Este nombre del Plan de Trabajo ya existe",
  "duplicate_sprint_story_result_name": "Ya hay una razón con ese nombre",
  "duration": "Duración",
  "duration_hours": "Horas de duración",
  "duration_must_be_integer": "La duración debe ser un número entero",
  "duration_must_be_number": "La duración debe ser un número",
  "duration_required": "La duración es necesaria",
  "due_date": "Fecha de vencimiento",
  "edit": "Editar",
  "edit_activity": "Editar actividad",
  "edit_equipment" : "Editar equipo",
  "edit_material": "Editar material",
  "edit_rfi": "Editar RFI",
  "edit_objects": "Editar objetos",
  "edit_subcontractor_role": "Editar rol del subcontratista",
  "edit_work_package": "Editar paquete de trabajo",
  "email": "Dirección de correo electrónico",
  "email_preferences": "Preferencias de correo electrónico",
  "email_preferences_description": "¿Cuándo quieres que te avisemos por correo electrónico?",
  "email_preferences_homework": "Semanalmente cuando tengo actividades para planificar",
  "email_preferences_model": "Al subir al modelo",
  "email_preferences_scenario": "Al generar un escenario",
  "email_preferences_schedule_chnage": "Al cambiar el escenario activo",
  "end_date": "Fecha final",
  "end_date_required": "Se requiere fecha de finalización",
  "end_sprint": "Terminar el Plan de Trabajo",
  "end_time": "Hora de finalización",
  "ended": "Terminado",
  "equip": "Equip",
  "equipment": "Equipo",
  "equipment_and_materials": "Equipos y Materiales",
  "equipment_cost": "Costo del equipo",
  "equipment_not_ready": "El equipo no está listo",
  "equipment_ready": "El equipo esta listo",
  "error": "Hubo un error",
  "error_getting_data": "Error al obtener datos",
  "error_planning_permission": "Error al configurar los permisos de planificación",
  "exception_date": "Fecha de excepción",
  "exception_days": "Días de excepción",
  "expand_menu_item": "Expandir",
  "export": "Exportar",
  "export_p6": "Exportar P6",
  "export_project": "Exportar MS Project",
  "export_csv": "Exportar .csv",
  "export_password": "Por favor ingrese una contraseña para asegurar el archivo exportado",
  "export_project": "Proyecto de exportación",
  "export_project_description": "Exportar y descargar todos los datos del proyecto",
  "export_your_schedule": "Exporte su programación a un archivo XML, luego elija importar como solo lectura o editable.",
  "fabrication_days": "Días de Fabricación",
  "file_size": "Tamaño del archivo",
  "file_too_large": "Los archivos deben ser menos de 5 MB",
  "filter": "Filtrar",
  "filter_by": "Filtrado por",
  "filter_similar_objects": "Filtrar objetos similares",
  "first_name": "Nombre",
  "first_person": "Primera persona",
  "follow_help_guide": "Siga las instrucciones descritas en la guía de ayuda",
  "forbidden": "No tienes permiso",
  "forgot_your_password": "¿Olvidaste tu contraseña?",
  "from_last_planner": "Desde ultimo planificador",
  "from_master_schedule": "Del horario maestro",
  "general_menu_item": "Configuración",
  "generate": "Generar",
  "generate_scenario": "Generar un escenario",
  "generated": "Generado",
  "generating": "Generando",
  "grit_score_description": "De 0 a 1000, el Grit Rating de un marco de tiempo es su medida de granularidad y consistencia de duraciones de tareas",
  "has_mfa": "Tiene auten de factor segundo",
  "have_admin_add_permission": "Haga que su administrador lo agregue a un proyecto o cuenta",
  "help_documentation": "Documentación de ayuda",
  "help_menu_item": "Apoyo",
  "here": "aquí",
  "hide_planned_work": "Ocultar el trabajo planeado",
  "home_menu_item": "Inicio",
  "home_view": "Vista de la casa",
  "honor_commitments": "Compromisos de honor",
  "send_activation_email": "Enviar correo electrónico de activación",
  "honor_commitments_info": "Cuando está marcada, las tareas comprometidas del Plan de trabajo tendrán prioridad sobre las tareas no comprometidas. Cuando no se marca, las tareas del Plan de trabajo comprometido para los Planes de trabajo no activos se tratarán igual que las tareas no comprometidas.",
  "hour": "Hora",
  "hours": "Horas",
  "hours_blocked": "{{hoursBlocked}} horas bloqueadas",
  "hours_completed": "Horas completadas",
  "hours_planned": "Horas previstas",
  "hrs": "hr(s)",
  "imperial": "Imperial",
  "import": "Importar",
  "import_edit": "Importar como Editable",
  "import_static": "Importar como Solo Lectura",
  "import_bim": "Importar desde BIM 360",
  "import_csv": "Importar CSV",
  "export_csv": "Exportar CSV",
  "import_exported_file": "Importe su archivo {{fileType}} exportado a Grit usando el botón de archivo de importación a continuación",
  "import_from_microsoft_project": "Importar desde Microsoft Project",
  "import_from_p6": "Importar desde Primavera P6",
  "import_select_SubVal": "Por favor seleccione el nombre del subcontratista..",
  "import_select_SubAbb": "Seleccione la abreviatura Subcontracter.",
  "import_select_SubErr": "Error al obtener el código del subcontratista.",
  "import_materials_how": "Cómo importar materiales",
  "import_milestones_how": "How To Import Milestones",
  "import_model": "Importar un modelo",
  "import_modal_desc": "Solo lectura no permitirá la actualización de las fechas de Inicio y Fin, mientras que Editable permitirá a los usuarios de Grit crear Actividades, ajustar Calendarios, duraciones y lógica de relación.",
  "import_project": "Importar un proyecto",
  "import_project_description": "Importar un archivo .GRIT como un nuevo proyecto",
  "import_relation": "Relaciones Capturada",
  "import_relation_not": "Relaciones No Capturada",
  "import_header_grit": "GRIT Atributos",
  "import_header_XML": "Importar Atributos",
  "import_header_preview": "Importar Avance",
  "import_setting": "Configuración de importación XML",
  "insufficient_permissions": "Permisos insuficientes",
  "in_progress": "En progreso",
  "influence": "Influencia",
  "inherited_access": "El acceso a este proyecto se hereda de su cuenta",
  "integrations_menu_item": "Integraciones",
  "invalid_abbreviation": "Abreviatura no válida",
  "invalid_assigned_elements" : "Elementos asignados inválidos",
  "invalid_budget" : "Presupuesto inválido",
  "invalid_budget_quantity" : "Cantidad de presupuesto no válida",
  "invalid_quantity_parameter" : "Parámetro de cantidad inválida",
  "invalid_company": "Empresa inválida",
  "invalid_crew_size": "Tamaño de la tripulación no válido",
  "invalid_dates": "Fechas no válidas",
  "invalid_days": "Días inválidos",
  "invalid_email": "Correo electrónico inválido",
  "invalid_name": "Nombre inválido",
  "invalid_project_id": "ID de proyecto inválido",
  "invalid_quantity_assigned" : "Cantidad asignada no válida",
  "invalid_id": "Identificación invalida",
  "invalid_step_status": "Estado de paso inválido",
  "invalid_user_id": "Dado el nombre de usuario falta o no se puede encontrar",
  "invalid_verification_number": "El número de verificación no es válido",
  "invalid_verification_token": "El token de verificación del usuario proporcionado no es válido o ha caducado. Utilice la contraseña olvidada para generar un nuevo token de verificación.",
  "invalid_workpackageid": "ID de paquete de trabajo no válido",
  "inverted_dates_error": "La fecha de inicio debe ser anterior a la fecha de finalización",
  "isolate_selection": "Aislar selección de gráfico",
  "iterations": "Iteraciones",
  "keep": "Mantener",
  "key": "Clave",
  "labor": "Labor",
  "labor_cost": "Costo de producción",
  "lag": "Retraso",
  "lag_must_be_number": "El retraso debe ser un número",
  "language_menu_item": "English",
  "last_name": "Apellido",
  "last_planner_menu_item": "Ultimo planificador",
  "lead": "Dirigir",
  "lead_lag": "Dirigir/Retraso",
  "lead_must_be_number": "El dirigir debe ser un número",
  "legal": "Legal",
  "letter": "Letra",
  "libraries_menu_item": "Liza",
  "load_data": "Carga datos",
  "load_more": "Carga más",
  "location_required": "Se requiere mover la ubicación",
  "log_in": "Iniciar sesión",
  "log_out": "Cerrar sesión",
  "lookahead_menu_item": "Pronóstico",
  "make_predecessors_into_prerequisites": "Hacer predecesores en requisitos previos",
  "make_ready_menu_item": "Obtención",
  "make_ready_not_approved": "<b>{{userName}}</b> revocó la aprobación de <b>{{secondarySubjectName}}</b> para <b>{{taskName}}</b>",
  "make_ready_approved": "<b>{{userName}}</b> aprobó <b>{{secondarySubjectName}}</b> para <b>{{taskName}}</b>",
  "make_ready_ordered": "<b>{{userName}}</b> ordenó <b>{{secondarySubjectName}}</b> para <b>{{taskName}}</b>",
  "make_ready_delivered": "<b>{{userName}}</b> entregó <b>{{secondarySubjectName}}</b> para <b>{{taskName}}</b>",
  "make_ready_ready": "<b>{{userName}}</b> hizo <b>{{secondarySubjectName}}</b> listos para <b>{{taskName}}</b>",
  "man_hours_percent_complete": "Hombre horas% completado",
  "manpower": "Mano de obra",
  "manpower_data_error": "No hay datos de mano de obra en rango",
  "manpower_report": "Informe de mano de obra",
  "map": "Mapa",
  "mark_all_as_read": "Marcar todo como leido",
  "master_schedule_menu_item": "Programa Maestro",
  "mat": "Mat",
  "material": "Material",
  "material_cost": "Costo material",
  "material_not_ready": "El material no esta listo",
  "material_ready": "El material esta listo",
  "materials": "Materiales",
  "measure": "Medida",
  "message": "Mensaje",
  "metric": "Métrico",
  "milestone": "Hito",
  "milestones": "Hitos",
  "mix": "Mezcla",
  "model": "Modelo",
  "model_filters": "Filtros modelo",
  "model_upload_limit_reached": "Has alcanzado el límite de subidas de modelos permitidas. Por favor, póngase en contacto con nosotros para obtener más créditos.",
  "models_menu_item": "Modelos",
  "month": "Mes",
  "month_of": "Mes de",
  "move_task_to": "Mover tarea a...",
  "multi_factor_authentication": "Autenticación del segundo factor",
  "multi-factor_authentication": "Autenticación del segundo factor",
  "multi-factor_authentication_description": "La autenticación del segundo factor proporciona seguridad mejorada en caso de que su correo electrónico y contraseña sean robados",
  "multi-factor_authentication_finish_setup": "Ingrese el código provisto por la aplicación de autenticación a continuación para completar la configuración.",
  "multi-factor_authentication_setup": "Escanee el código a continuación en una aplicación de autenticador (como Google Authenticator) para comenzar",
  "name": "Nombre",
  "names": "Nombre",
  "name_required": "Se requiere el nombre",
  "network_map_menu_item": "Mapa De Red",
  "never": "Nunca",
  "new_message": "Nuevo mensaje",
  "new_password": "Nueva contraseña",
  "new_scenario_name": "Nuevo nombre de escenario",
  "next": "Siguiente",
  "next_day_with_work": "Dia siguiente con trabajo",
  "next_range": "Siguiente rango",
  "no_activities": "No hay actividades",
  "no_activities_needing_planned": "No hay actividades que necesiten ser planeadas",
  "no_activity_in_range": "No hay actividad en este rango de tiempo",
  "no_data": "No hay datos en este momento",
  "no_projects": "No tienes ningún proyecto activo",
  "no_work_plans": "Actualmente no hay Planes de Trabajo",
  "none_available": "Ninguno disponible",
  "not_completed": "Sin completar",
  "not_found": "Alguna información esperada no se pudo encontrar",
  "not_integrated_with_procore": "No integrado con Procore",
  "notifications_menu_item": "Notificaciones",
  "number": "número",
  "object": "Objeto",
  "object_in_prereq_work_package": "El objeto pertenece a un paquete de trabajo de requisito previo",
  "object_properties": "Propiedades del objeto",
  "object_unplanned": "Objeto no es planeado",
  "objects": "Objetos",
  "offset_days": "Compensar (Días)",
  "opened": "Abrió",
  "ordered": "Ordenado",
  "object_names": "Nombres de objeto",
  "object_number": "Numero de objetos",
  "pagination": "{{pageStart}} - {{pageEnd}} de {{total}}",
  "pan": "Mover",
  "password": "Contraseña",
  "password_requirements": "La contraseña debe tener al menos ocho caracteres y debe contener una letra mayúscula, una letra minúscula, un número y un carácter especial (#,?,!, @, $,%, ^, &, *, -)",
  "past_due": "Atrasado",
  "path": "Camino",
  "path_information": "Ruta más crítica a través del objeto seleccionado",
  "peak": "Pico",
  "peak_description": "Si el tamaño de la tripulación especificado es inferior al tamaño de la tripulación de un paso, la tripulación adicional solo se activará cuando sea necesario",
  "people": "Gente",
  "people_menu_item": "Gente",
  "percent_complete": "Porcentaje completo",
  "percent_plan_complete": "Porcentaje de plan completo",
  "percentage_loss": "Porcentaje de pérdida",
  "permission": "Permiso",
  "person": "Persona",
  "planned_cost": "Costo planificado",
  "planned_hours": "{{plannedHours}} horas planeadas",
  "planned_hours_label": "Horas planeadas",
  "planned_tasks": "{{plannedTasks}} tareas planificadas",
  "planning_lead": "Planificación de días de plomo",
  "planned_quantity": "Cantidad planificada",
  "play": "Jugar",
  "predecessors": "Antecesores",
  "preferences": "Preferencias",
  "prep_days": "Días de Preparación",
  "prereq_required": "Se requiere un requisito previo",
  "prerequisite": "Pequisito previo",
  "prerequisites": "Prerrequisitos",
  "previous": "Anterior",
  "previous_day_with_work": "Dia anterior con trabajo",
  "previous_range": "Rango anterior",
  "Processes": "Procesos",
  "procore_company": "Empresa Procore",
  "procore_due_date": "Fecha de vencimiento",
  "procore_import_companies": "Importar Empresas desde Procore",
  "procore_import_submittals": "Importar presentaciones de Procore",
  "procore_manual_import": "Sincronizar todo el trabajo completado ahora",
  "procore_not_integrated": "No integrado con Procore",
  "procore_only_before": "Solo si la nueva fecha es anterior a la fecha de Procore existente",
  "procore_project": "Proyecto Procore",
  "procore_required_date": "Requerido en la fecha del sitio",
  "procore_select_dates": "¿Qué fechas te gustaría escribir?",
  "procore_submit_date": "Enviar Por Fecha",
  "procore_write_dates_back": "¿Escribir fechas basadas en el horario activo de Procore?",
  "procore_daily_log": "Escribir entradas de registro diarias para historias completadas a la hora dada cada día.",
  "procore_daily_hour": "Hora escribir",
  "procore_daily_manpower": "Escribir el registro diario de la mano de obra del tamaño de la tripulación y la duración trabajada.",
  "procore_daily_equipment": "Escribir el registro diario de los equipos utilizados.",
  "procore_daily_delivery": "Escribir el registro de entrega diaria.",
  "procore_daily_quantity": "Escribir el diario de cantidad de material utilizado.",
  "procore_write_daily_log": "¿Qué campos te gustaría escribir?",
  "procurement_menu_item": "Obtención",
  "profile_menu_item": "Perfil",
  "project_menu_item": "Proyecto",
  "project_name": "Nombre del proyecto",
  "project_productivity": "Productividad del proyecto",
  "project_progress": "Progreso del proyecto",
  "project_updates": "Actualizaciones del proyecto",
  "projected_completion_date": "Fecha de finalización proyectada",
  "projects_menu_item": "Proyectos",
  "quantity": "Cantidad",
  "quantity_assigned": "Cantidad asignada",
  "read_only": "Leer",
  "ready": "Listo",
  "ready_for_review": "Listo para revisión",
  "reason_required": "Se requiere razon",
  "reason_task_not_complete": "La tarea de la razón no se completó",
  "reasons_not_complete_menu_item": "Razones no completas",
  "recommended": "Recomendado",
  "refresh": "Refrescar",
  "relationship": "Relación",
  "remaining_days": "días restantes",
  "remember_me": "Recuérdame",
  "remove": "Quitar",
  "replacements": "Reemplazos",
  "replacing_objects": "Seleccionando reemplazos de objetos",
  "reports_menu_item": "Informes",
  "request_help_menu_item": "Solicitar ayuda",
  "required_on_site": "Requerido en el Sitio",
  "reset": "Reiniciar",
  "reset_form": "Restablecer formulario",
  "reset_project": "Reiniciar proyecto",
  "reset_project_description": "Quiero borrar todos los Horarios y Planes de Trabajo",
  "resource": "Recurso",
  "restore_defaults": "Restaurar los valores predeterminados",
  "retry": "Procesar de nuevo",
  "revert_exceptions": "Volver a los días de excepción del horario maestro",
  "review_days": "Días de Revisión",
  "role": "Papel",
  "rolling_budget": "Presupuesto Continuo a lo Largo del Tiempo",
  "ros_date": "R.O.S. Date",
  "rows_selected": "({{numberOfRows}} seleccionados)",
  "rfi": "RFI",
  "save": "Guardar",
  "save_home_view": "Guardar vista actual como vista de inicio",
  "save_name": "Guardar nombre",
  "saved_views": "Vistas guardadas",
  "savings": "Ahorros",
  "scenario": "Escenario",
  "scenarios_menu_item": "Escenarios",
  "scheduled": "Programado",
  "scheduled_date" : "Cita agendada",
  "scheduled_end": "Final programado",
  "scheduled_for": "Programado para {{date}}",
  "scheduled_start": "Inicio programado",
  "search": "Buscar",
  "secret": "Secreto",
  "section_analysis": "Análisis de sección",
  "select_activities": "Seleccionar Actividades",
  "select_activity": "Seleccione una actividad",
  "select_activity_filtered": "Seleccione una actividad (Filtrado)",
  "select_activity_or_object_to_plan": "Seleccione una actividad u objeto para comenzar a planificar",
  "select_date_range": "Seleccione un rango de fechas",
  "select_location": "Seleccione una ubicación de la lista",
  "select_object": "Seleccione un objeto en el modelo",
  "select_object_in_model": "Seleccione un objeto en el modelo",
  "select_object_to_plan": "Seleccione un objeto en el visor para comenzar a planificar",
  "select_prereq": "Seleccione un requisito previo",
  "select_project_to_get_started": "Seleccione un proyecto a la izquierda para empezar",
  "select_reason": "Seleccione un motivo de la lista",
  "select_subcontractor": "Seleccione un subcontratista de la lista",
  "select_task": "Seleccione una tarea",
  "select_work_package": "Seleccione un paquete de trabajo",
  "selection_filter": "Filtro de selección",
  "selection_window_contains": "Ventana de selección (contiene)",
  "selection_window_intersects": "Ventana de selección (intersecciones)",
  "server_error": "Hubo un problema con el servidor",
  "settings_menu_item": "Configuración",
  "setup": "Preparar",
  "short_code_expires": "Este código caducará en 30 segundos",
  "show_all": "Mostrar todo",
  "show_my_work": "Mostrar mi trabajo",
  "show_procurement_lines": "Show Procurement Lead Lines",
  "showing_x_of_x_items": "mostrando {{shown}} de {{total}}",
  "sprint": "Plan de Trabajo",
  "sprint_name_duplicate": "Ya hay un Plan de Trabajo con este nombre",
  "sprint_name_invalid": "Nombre de Plan de Trabajo no válido",
  "sprints_menu_item": "Plan de Trabajo",
  "start_date": "Fecha de inicio",
  "start_date_required": "Se requiere fecha de inicio",
  "start_time": "Hora de inicio",
  "start_work_plan": "Iniciar Plan de Trabajo",
  "started": "Empezado",
  "status": "Estado",
  "stop": "Detener",
  "sub": "Sub",
  "sub_companies": "Sub empresas",
  "subcontractor": "Subcontratista",
  "subcontractor_roles_menu_item": "Roles de Subcontratistas",
  "subcontractors": "Subcontratistas",
  "subject": "Asunto",
  "submittals": "Presentaciones",
  "submit": "Enviar",
  "submit_by": "Enviar Por",
  "submit_create_another": "Enviar y crear otro",
  "submittal_info": "información de envío",
  "success": "Operación exitosa",
  "successful_delete": "Los datos fueron eliminados con éxito",
  "successful_save": "Los datos fueron guardados con éxito",
  "successor": "Sucesor",
  "successors": "Sucesores",
  "supply": "Suministro",
  "sheet": "Sheet",
  "tabloid": "Tabloide",
  "task": "Tarea",
  "task_cannot_be_editted": "La tarea ya está comprometida y no se puede editar",
  "task_hours": "Horas de tarea",
  "task_information": "Información de la tarea",
  "task_list": "Lista de tareas",
  "task_update_changed": "<b>{{userName}}</b> hizo cambios a <b>{{taskName}}</b>",
  "task_update_committed": "<b>{{userName}}</b> se comprometió con <b>{{taskName}}</b>",
  "task_update_completed": "<b>{{userName}}</b> completó <b>{{taskName}}<b/>",
  "task_update_in_progress": "<b>{{userName}}</b> movió <b>{{taskName}}</b> a en progreso",
  "task_update_not_completed": "<b>{{userName}}</b> movió <b>{{taskName}}</b> ara no completarse debido a <b>{{reason}}</b> y lo movió a <b>{{secondarySubjectName}}</b>",
  "task_update_not_completed_backlog": "<b>{{userName}}</b> movió <b>{{taskName}}</b> ara no completarse debido a <b>{{reason}}</b> y lo movió al Reserva",
  "task_update_planned": "<b>{{userName}}</b> planeó <b>{{taskName}}</b>",
  "task_update_scheduled": "<b>{{userName}}</b> programado <b>{{taskName}}</b>",
  "tasks_blocked": "{{tasksBlocked}} tareas bloqueadas",
  "tasks_completed": "Tareas completadas",
  "tasks_due": "Tareas debidas",
  "tasks_in_path": "{{tasksInPath}} tareas en el camino",
  "tasks_planned": "Tareas planeadas",
  "thread": "Hilo del mensaje",
  "time_zone": "Zona horaria",
  "today": "Hoy",
  "toggle_ghosting": "Alternar fantasmas",
  "token": "Simbólico",
  "total_cost": "Coste total",
  "total_hours": "Horas totales",
  "total_hours_description": "Total de horas de trabajo para este subcontratista",
  "total_hours_in_path": "{{totalHoursInPath}} horas totales en ruta",
  "trial_expires": "Juicio Expira",
  "type": "Tipo",
  "unauthorized": "Usted no se ha identificado",
  "uncommit": "No entregado",
  "undo": "Deshacer",
  "unit_cost": "Costo unitario",
  "unit_rate": "Unidad de medida",
  "unit_of_measure": "Unidad de medida",
  "update_sprint": "Actualizar Plan de Trabajo",
  "upload_file": "Subir archivo",
  "upload_photo": "Subir imagen",
  "uploading": "Subiendo",
  "use_qr_code": "Escanee el siguiente código para iniciar sesión en el entorno de asistencia de tareas de AR",
  "use_short_code": "Planificacion de realidad virtual (RV)",
  "user": "Usuario",
  "user_disabled": "Este usuario ha sido desactivado",
  "user_guide_menu_item": "Guía del usuario",
  "user_information": "Informacion del usuario",
  "users_menu_item": "Usuarios",
  "version": "Versión",
  "view_in_lookahead": "Ver en Pronóstico",
  "view_in_procore": "Ver en Procore",
  "viewer_settings": "Configuraciones del visor",
  "viewer_settings_description": "Personaliza el visor 3D",
  "viewer_settings_scroll": "Expandir / Subir (se acerca cuando el panel táctil se expande o cuando la rueda de desplazamiento se desplaza hacia arriba)",
  "vr_short_menu_item": "Código Corto VR",
  "week": "Semana",
  "week_of": "Semana de",
  "week_one": "1 semana",
  "week_six": "6 semanas",
  "week_three": "3 semanas",
  "work_package": "Paquete de trabajo",
  "work_packages": "Paquetes de trabajo",
  "work_package_contains_comitted_task": "Los paquetes de trabajo que contienen tareas confirmadas no se pueden editar",
  "work_plan": "Plan de trabajo",
  "work_plan_percent_complete": "Plan de Trabajo Por Ciento Completado",
  "working_days": "Días laborables",
  "working_hours": "Horas laborales",
  "schedule_change": "Cambio de Horario",
  "old_start_date": "Fecha de Inicio Anterior",
  "new_start_date": "Nueva Fecha de Inicio",
  "old_scenario": "Viejo Escenario",
  "new_scenario": "Nuevo Escenario",
  "filter_chart": "Gráfico de filtro",
  "selection_filtering": "Filtrado de selección de actividad",
  "filter_viewer": "Visor de filtro",
  "and": "SÍ",
  "or": "O",
  "bulk_edit": "Edición masiva",
  "bulk_assign": "Asignación masiva",
  "this_is_bulk_edit_for": "Esta es una edición masiva para ",
  "invalid_externalActivityId": "ID de actividad externa no válida",
  "id": "CARNÉ DE",
  "in_progress": "En progreso",
  "not_assigned": "(NO ASIGNADO)",
  "not_completed": "Sin completar",
  "quick_edit": "Edición rapida",
  "quantity_parameter": "Parámetro de cantidad",
  "remove_activity_from_List": "Eliminar actividad de la lista",
  "default_info": "información predeterminada",
  "delete_activity": "Eliminar actividad",
  "floor_plans_menu_item": "Planos de planta",
  "procore": "Procore",
  "bim_360": "BIM 360",
  "project_files": "Archivos de proyecto",
  "floor_plans": "Planos de planta",
  "add_project_files": "Añadir",
  "remove_project_files": "Eliminar",
  "select_floor_plans_from_the_file_list_to_the_left": "Seleccione los planos de planta de la lista de archivos a la izquierda",
  "select_a_project_from_your_data_source_above": "Seleccione un proyecto de su fuente de datos arriba",
  "data_source": "Fuente de datos",
  "error_get_procore_floorplan":"Error al obtener planos de planta de procore",
  "error_get_floorplan":"Error al obtener planos de planta",
  "error_remove_floorplan":"Error al eliminar el plano del proyecto",
  "error_add_floorplan":"Error al vincular el plano de planta al proyecto",
  "error_orderupdate_floorplan":"Error al actualizar el orden de los planos de planta",
  "error_get_annotations":"Error al obtener anotaciones",
  "error_update_annotations":"Error al actualizar anotaciones",
  "annotation_assigned":"Anotación asignada con éxito",
  "annotation_cannot_assigned":"Cambie al modo de página única para dibujar anotaciones",
  "error_annotation_assign":"Error al asignar anotación",
  "annotation_removed":"Las anotaciones de actividad se eliminaron correctamente",
  "error_remove_annotation":"Error al eliminar anotaciones de actividad",
  "delete_activity": "Eliminar actividad",
  "no_workspaces_assigned":"No hay espacios de trabajo asignados",
  "no_plan_avalable":"No hay plan disponible",
  "floorPlans_connect" : "Los planos de planta no se pueden usar ya que los modelos están conectados a este proyecto",
  "models_connect" : "Los modelos no se pueden usar ya que los planos de planta están conectados a este proyecto",
  "flow_line_chart": "Horario de la línea de flujo",
  "xml_file": "Archivo XML",
  "grit_version": "Versión de grano",
  "export_xml": "Exportar XML",
  "expected_end_date": "Fecha de finalización esperada",
  "expected_finish": "Fecha de finalización esperada",
  "actual_finish_date": "Fecha de finalización real",
  "actual_finish_date_required": "Se requiere la fecha de finalización real",
  "actual_start_date": "Fecha de inicio real",
  "actual_start_date_required": "Se requiere la fecha de inicio real",
  "expected_finish_date_required": "Se requiere la fecha de finalización esperada"
}
