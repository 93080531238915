import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IColumnHeader, ICustomTable, IRow, IRowItem } from '../../models/custom-table/custom-table.interface';
import { IProject } from '../../models/project/project.interface';
import { IUser } from '../../models/user/user.interface';

import { HttpBackendService } from '../http-backend/http-backend.service';
import { IGritTable, IColHeader, RowItemType, IGRow, IGRowItem, EditType } from '../../shared/grit-table/grit-table';

@Injectable()
export class UserService {
  public static userId: string;
  public userAvatar: string = null;
  private authenticatedUser: IUser;

  constructor(private _http: HttpBackendService) {}

  public getAuthenticatedUser(): Observable<IUser> {
    return this._http.get('/authenticatedUser');
  }

  public createUser(json: any): Observable<IUser> {
    return this._http.post('/user', json);
  }

  public updateUser(json: any): Observable<IUser> {
    return this._http.put('/user/' + json.id, json);
  }

  public updateUserAvatar(id: string, formData: FormData): Observable<any> {
    return this._http.formData('/user/' + id + '/image', formData);
  }

  public deleteUserAvatar(id: string): Observable<any> {
    return this._http.delete('/user/' + id + '/image');
  }

  public getUserAvatar(id: string): Observable<any> {
    return this._http.getFile('/user/' + id + '/image');
  }

  public resetPassword(json: any): Observable<IUser> {
    return this._http.post('/user/password/reset', json);
  }

  public updatePassword(json: any): Observable<any> {
    return this._http.put('/user/password', json);
  }

  public getUserData() {
    return this.authenticatedUser;
  }

  public setUserData(user: IUser) {
    this.authenticatedUser = user;
  }

  public getUserAvatarImage() {
    return this.userAvatar;
  }

  public setUserAvatarImage(url: string) {
    this.userAvatar = url;
  }

  public getProfileImg(userId: string) {
    return new Promise((resolve, reject) => {
      this.getUserAvatar(userId).subscribe(
        result => {
          if (this.userAvatar !== result) {
            this.setUserAvatarImage(result);
          }
          resolve(true);
        },
        err => {
          this.setUserAvatarImage(null);
          resolve(false);
        }
      );
    });
  }

  transformAccountsTableData(accounts: Account[], canEdit: boolean): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'accountName',
        type: RowItemType.Text
      }
    ];

    const rows: IGRow[] = [];
    accounts.forEach(account => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'accountName',
          value: account.name,
          editable: false
        }
      ];
      rows.push(
        {
          key: account.id,
          rowItems: rowItems,
          selectable: false,
          editable: canEdit,
          editOptions: {
            deletePermission: canEdit,
            rowEdits: canEdit ? [{type: EditType.Delete, disabled: false}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false
      },
      deleteOptions: {
        show: false
      }
    };

    return retTable;
  }

  transformProjectsTableData(projects: IProject[], canEdit: boolean): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'projectName',
        type: RowItemType.Text
      },
      {
        displayName: 'account',
        colKey: 'account',
        type: RowItemType.Text
      }
    ];

    const rows: IGRow[] = [];
    projects.forEach(project => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'projectName',
          value: project.name,
          editable: false
        },
        {
          colKey: 'account',
          value: project.accountName,
          editable: false
        }
      ];
      rows.push(
        {
          key: project.id,
          rowItems: rowItems,
          selectable: false,
          editable: canEdit,
          editOptions: {
            deletePermission: canEdit,
            rowEdits: canEdit ? [{type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false
      }
    };

    return retTable;
  }
}
