import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Utils } from '../../utils/utils';

import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';

import { ILocalScheduleData, IProjectScheduleStep, IScheduleSelectionOutput } from '../../models/project/project-schedule/project-schedule.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';

import * as D3 from 'd3';
import * as moment from 'moment';
import { IProjectMaterial } from '../../models/project/project-material/project-material.interface';
import { IProjectScheduleMilestone } from '../../models/project/project-milestone/project-milestone.interface';

@Component({
  selector: 'app-project-schedule-calendar',
  templateUrl: './project-schedule-calendar.component.html',
  styleUrls: ['./project-schedule-calendar.component.scss']
})
export class ProjectScheduleCalendarComponent implements OnChanges, OnDestroy, OnInit {
  @Input() cellDataInput: any[]; // includes dates and weather data
  @Input() subContractorsInput: IProjectSubContractor[];

  @Output() chartSelectionOutput: EventEmitter<IScheduleSelectionOutput> = new EventEmitter();
  @Output() daySelectionOutput: EventEmitter<number[]> = new EventEmitter();
  @Output() dayMaterialOutput: EventEmitter<IProjectMaterial[]> = new EventEmitter();
  @Output() dayMilestonesOutput: EventEmitter<IProjectScheduleMilestone[]> = new EventEmitter();

  @ViewChild('gridContainer') gridContainer;
  @ViewChild('calBarsContainer') calBarsContainer;

  visibleSchedule: ILocalScheduleData = {};
  stepsInRange: IProjectScheduleStep[] = [];
  calDataAvailable: boolean = false;
  calContainerHeight: number = 800;
  calContainerWidth: number;
  minCalContainerWidth: number;
  cellHeight: number = 120;
  minCellWidth: number = 50;
  minBarHeight: number = 12;
  maxBarsInCell: number = 6;
  gridData: any[] = [];
  startOfActiveMonthEpoch: number;
  calLabel: string;
  endOfActiveMonthEpoch: number;
  calOffset: string = 'translate(0, 0)';
  currentStepSelections: IProjectScheduleStep[] = []; // individual task selections by bar, cell, row or column
  currentCellSelections: any[] = []; // cell selections
  currentDaySelections: number[] = [];
  dayWithOverflow: number[] = [];
  shiftKeyPressed: boolean = false;
  private allD3Bars: D3.selection<SVGElement>;
  showPastObjectsInSelection: boolean = true;

  // scales
  xScale: D3.ScaleLinear<number, number> = null;
  yScale: D3.ScaleLinear<number, number> = null;
  cellYscale: D3.ScaleBand<any, any> = null;

  // subscriptions
  daySelectionSubscription: Subscription;

  constructor(
    private projectScheduleService: ProjectScheduleService
  ) { }

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    window.addEventListener('keyup', this.keyRelease);

    this.daySelectionSubscription = this.projectScheduleService.daySelectionSubject.subscribe( // TODO change to input?
      res => {
        this.currentDaySelections = res ? res : [];
        this.setCellState(this.currentDaySelections[0], true);
      }
    );
  }

  async ngOnChanges() {
    this.setupChart();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    window.removeEventListener('keyup', this.keyRelease);

    if (this.daySelectionSubscription) {
      this.daySelectionSubscription.unsubscribe();
    }
  }

  keyPress = (event) => {
    if (event.key === 'Shift') {
      this.shiftKeyPressed = true;
    }
  }

  keyRelease = (event) => {
    if (event.key === 'Shift') {
      this.shiftKeyPressed = false;
    }
  }

  async setupChart() {
    if (this.subContractorsInput.length > 0 && this.cellDataInput) {

      this.setVisibleSchedule();

      if (this.calDataAvailable) await this.drawCalendar(false);
      else this.calDataAvailable = false;
    } else {
      this.calDataAvailable = false;
    }
  }

  async drawCalendar(isResize: boolean) {
    if (!isResize) await this.resetAllSelections();

    this.calculateCalendarDimensions();
    await this.creatScales();
    this.drawBars();
    this.drawGridAndDataStones();
    this.checkForPreSelectedDays();
  }

  // used for switching ranges with the day by day control
  checkForPreSelectedDays(): void {
    const preSelectedDays = this.cellDataInput.filter(item => item.selected);
    if (preSelectedDays.length > 0) {
      this.currentDaySelections = preSelectedDays;
      preSelectedDays.forEach(day => {
        this.setCellState(day.day);
      });
    }
  }

  async setVisibleSchedule() {
    this.visibleSchedule = {};
    this.stepsInRange = [];
    // tslint:disable-next-line:max-line-length
    this.startOfActiveMonthEpoch = moment.utc(this.cellDataInput[15].day).clone().startOf('month').valueOf(); // 15 is an arbitrary middle of the month day, ensuring we are checking for the visible month
    this.endOfActiveMonthEpoch = moment.utc(this.cellDataInput[15].day).clone().endOf('month').valueOf();
    this.calLabel = moment.utc(this.startOfActiveMonthEpoch).clone().format('MMMM YYYY');
    const localSchedData = this.projectScheduleService.getLocalScheduleData();
    const visibleSchedKeys = this.projectScheduleService.getLocalScheduleKeysFromRange(this.startOfActiveMonthEpoch, this.endOfActiveMonthEpoch);
    if (visibleSchedKeys.length > 0) {
      visibleSchedKeys.forEach(key => {
        if (localSchedData[key]) {
          this.visibleSchedule[key] = localSchedData[key];
          this.stepsInRange.push(...localSchedData[key].steps);
        }
      });

      this.calDataAvailable = true;
    } else {
      this.calDataAvailable = false;
    }
  }

  async calculateCalendarDimensions() {
    const sbWidth = JSON.parse(localStorage.getItem('showSidebar')) ? 300 : 0;
    const menuWidth = JSON.parse(localStorage.getItem('showMainMenu')) ? 155 : 0;
    const fitWidthToView = ((window.innerWidth - 40 - sbWidth - menuWidth) * .95); // 95% of window without navigation
    const colWidth = (fitWidthToView / 7) < this.minCellWidth
      ? this.minCellWidth
      : (fitWidthToView / 7);
    this.minCalContainerWidth = colWidth * 7;
    this.calContainerHeight = (this.cellHeight * 5);
    this.calContainerWidth = this.minCalContainerWidth < fitWidthToView
      ? fitWidthToView
      : this.minCalContainerWidth;
  }

  async creatScales() {
    this.xScale = D3.scaleLinear()
      .range([0, this.calContainerWidth])
      .domain([0, 7]);

    this.yScale = D3.scaleLinear()
      .range([0, this.calContainerHeight])
      .domain([0, 5]);

    this.cellYscale = D3.scaleLinear()
      .range([0, this.cellHeight])
      .domain(this.subContractorsInput.map(subcontractor => subcontractor.id));
  }

  async drawGridAndDataStones() {
    this.gridData = [];
    let yTransform = 0;
    this.cellDataInput.forEach((item, index) => {
      const date = item.day;
      const dayNumber = moment.utc(date).day(); // 0 Sun, 1 Mon, 2 Tues....
      const activeSteps = this.projectScheduleService.getStepsFromLocalScheduleByDay(date);
      const overflowDisplay = !Utils.isEmpty(this.dayWithOverflow.filter(day => day === date))
        ? '+ ' + this.dayWithOverflow.filter(day => day === date).length + ' more'
        : null;
      const cellWidth = this.xScale(date) - this.xScale(date - 1);
      const smtOffset = item.milestones ? cellWidth - 10 : cellWidth;
      const materialObjs = item.materials ? this.getMaterialStone(item.materials, smtOffset) : null;
      const milestoneObjs = item.milestones ? this.getMilestoneStone(item.milestones, cellWidth) : null;
      const selectedCellIds = this.currentCellSelections.map(cell => cell.cellId);
      const cellIsSelected = selectedCellIds.includes(date);

      const cellData = {
        height: this.cellHeight,
        width: this.xScale(date) - this.xScale(date - 1),
        defaultBackground: 'white',
        cellId: date,
        label: moment.utc(date).clone().format('D'),
        transform: 'translate(' + this.xScale(dayNumber) + ', ' + this.yScale(yTransform) + ')',
        steps: activeSteps,
        selected: cellIsSelected,
        overflowDisplay: overflowDisplay,
        milestones: milestoneObjs,
        materials: materialObjs
      };

      this.gridData.push(cellData);

      if ( index !== 0 && (index + 1) % 7 === 0 ) {
        yTransform++;
      }
    });

    if (this.gridContainer) {
      const calGrid = D3.select(this.gridContainer.nativeElement)
        .selectAll('g')
        .remove() // refresh data each time
        .exit()
        .data(this.gridData);

      const calendarCell = calGrid
        .enter()
        .append('g')
        .attr('transform', (d) => d.transform);

      calendarCell
        .append('rect')
        .attr('height', (d) => d.height)
        .attr('width', (d) =>  d.width)
        .attr('fill', (d) =>  d.defaultBackground)
        .attr('class', (d) => d.selected
          ? 'D3-grid-cell _' + d.cellId + ' calendar' + ' cell-selected'
          : 'D3-grid-cell _' + d.cellId + ' calendar'
        )
        .on('click', (d) => this.setCellState(d.cellId));

      calendarCell
        .insert('text')
        .attr('transform', 'translate(5, 15)')
        .attr('class', 'cal-cell-label')
        .text((d) => d.label);

      calendarCell
        .filter((d) => d.overflowDisplay)
        .append('foreignObject')
        .append('xhtml:div')
        .attr('class', 'D3-cal-modal-button')
        .style('height', (d) => '16px')
        .style('width', (d) => d.width + 'px')
        .style('left' , (d) => d.xCoord + 2 + 'px')
        .style('top' , (d) => d.height - 18 + 'px')
        .html((d) => d.overflowDisplay)
        .on('click', (d) => this.setCellState(d.cellId));

      calendarCell
        .filter((d) => d.milestones)
        .append('g')
        .selectAll('rect')
        .data((d) => d.milestones)
        .enter()
        .append('rect')
        .attr('fill', (d) => d.color)
        .attr('class', 'D3-milestone-diamond-marker')
        .attr('style', 'cursor: pointer')
        .attr('height', (d) => d.dHeight)
        .attr('width', (d) =>  d.dWidth)
        .attr('transform', (d) => d.dTransform)
        .on('click', (d) => this.emitMilestones(d.stoneData))
        .on('mouseover', (d) => this.handleStoneHover(d.stoneData, 'mouseover', 'milestone'))
        .on('mouseout', (d) => this.handleStoneHover(d.stoneData, 'mouseout', 'milestone'));

      calendarCell
        .filter((d) => d.materials)
        .append('g')
        .selectAll('circle')
        .data((d) => d.materials)
        .enter()
        .append('circle')
        .attr('fill', (d) => d.color)
        .attr('class', 'D3-material-circle-marker')
        .attr('style', 'cursor: pointer')
        .attr('r', (d) => d.radius)
        .attr('cx', (d) =>  d.xAxis)
        .attr('cy', (d) =>  d.yAxis)
        .on('click', (d) => this.emitMaterials(d.stoneData))
        .on('mouseover', (d) => this.handleStoneHover(d.stoneData, 'mouseover', 'material'))
        .on('mouseout', (d) => this.handleStoneHover(d.stoneData, 'mouseout', 'material'));
      }
  }

  getMilestoneStone(milestones: any[], xPos: number): any[] {
    if (!Utils.isEmpty(milestones)) {
      return [{
        color: !Utils.isEmpty(milestones.find(ms => ms.behindSchedule)) ? 'red' : 'black',
        dHeight: 6,
        dWidth: 6,
        dTransform: 'translate(' + (xPos - 10) + ', ' + 5 + ') rotate(45 3 3)',
        stoneData: milestones
      }];
    } else {
      return null;
    }
  }

  getMaterialStone(materials: any[], xPos: number): any[] {
    if (!Utils.isEmpty(materials)) {
      return [{
        xAxis: (xPos - 10),
        yAxis: 7,
        radius: 4,
        color: 'black',
        stoneData: materials
    }];
    } else {
      return null;
    }
  }

  handleStoneHover(stoneData: any, action: string, type: string): void {
    const oneLine = 35;
    if ( action === 'mouseover') {
      const ttHeight = 40 + ((stoneData.length - 1) * oneLine);
      const ttWidth = 150;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(stoneData, type));
    } else if ( action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  setCellState(epoch: any, deselectOthers?: boolean): void {
    if (epoch) {
      const selectedCell = D3.selectAll('.D3-grid-cell').filter('._' + epoch);
      const cellInView = !selectedCell.empty();
      const cellSteps = this.projectScheduleService.getStepsFromLocalScheduleByDay(epoch);

      // if you want this to be the only highlighted column, deselect all others
      if (deselectOthers) {
        this.resetAllSelections();
      }

      if (cellInView) {
        // set cell state
        if (selectedCell.classed('cell-selected')) {
          selectedCell.classed('cell-selected', false);

          this.updateCellSelections(selectedCell, 'remove');
          this.filterOutBarSelections(cellSteps, 'remove');
        } else {
          // If we aren't pressing the shift key, we just need to turn everything off
          if (!this.shiftKeyPressed) this.resetAllSelections();

          selectedCell.classed('cell-selected', true);

          this.updateCellSelections(selectedCell, 'add');
          this.filterOutBarSelections(cellSteps, 'add');
        }
      }
    }
  }

  async drawBars() {
    const barData = [];
    const visibleDays = this.cellDataInput.map(cell => cell.day);

    Object.keys(this.visibleSchedule).forEach(key => {
      if (this.visibleSchedule[key]) {
        const daysInRange = this.visibleSchedule[key].daysInRange;
        if (daysInRange.length > 0) {
          daysInRange.forEach(day => {
            const dayEpoch = day;
            const dayNumber = moment.utc(dayEpoch).clone().day(); // 0 Sun, 1 Mon, 2 Tues....
            const cellOrderNumber = visibleDays.indexOf(dayEpoch); // 0 is 1st cell in grid, 34 is last cell in grid
            const rowNumber = Math.floor(cellOrderNumber / 7);
            const formattedDate = moment.utc(dayEpoch).clone().format('M / D / YYYY');
            const steps = this.projectScheduleService.getStepsFromLocalScheduleByDay(dayEpoch);
            const sortedSteps = steps.length > 0 ? Utils.sortObjectArrayByKey(steps.filter(o=> !Utils.isEmpty(o.subContractorId)), 'subContractorId') : null;
            const stepsBySubcontractor = {};
            const cellLabelBuffer = 15;
            let subCountByDay = 0;
            let subBuffer = 0; // adjust y position based on number of subs

            if (dayEpoch && sortedSteps) {
              sortedSteps.forEach(step => {
                const subContractorId = step.subContractorId ? step.subContractorId : null;
                const activeSubcontractor = subContractorId ? this.subContractorsInput.filter(subcontractor => subcontractor.id === subContractorId)[0] : null;
                const barWidth = this.xScale(dayEpoch) - this.xScale(dayEpoch - 1);
                const barHeight = this.minBarHeight;
                const selectedBar = D3.selectAll('.bar-active').filter('._' + step.id);
                const stepIsSelected = selectedBar.empty() ? false : true;

                // only create bar for unique subcontractor per day
                if (stepsBySubcontractor[subContractorId]) {
                  stepsBySubcontractor[subContractorId].push(step);
                } else {
                  const subcontractorSteps = steps.filter(item => item.subContractorId === subContractorId);
                  stepsBySubcontractor[subContractorId] = [step];
                  subBuffer += barHeight;
                  subCountByDay++;

                  const barInfo = {
                    height: barHeight,
                    width: barWidth - 4, // 4 is just a an offset so bar doesn't overlap grid lines,
                    xCoord: this.xScale(dayNumber),
                    yCoord: this.yScale(rowNumber) + cellLabelBuffer + subBuffer,
                    rowNumber: rowNumber,
                    day: formattedDate,
                    hidden: this.projectScheduleService.subIsFilteredOut(activeSubcontractor, subcontractorSteps),
                    subcontractor: activeSubcontractor ? activeSubcontractor.name : '',
                    totalSteps: subcontractorSteps.length,
                    totalDuration: subcontractorSteps.map(subStep => subStep.durationHours).reduce((a, b) => a + b).toFixed(2),
                    steps: subcontractorSteps,
                    color: activeSubcontractor ? activeSubcontractor.hexCode : null,
                    barId: stepsBySubcontractor[subContractorId][0].id + dayEpoch,
                    cellId: dayEpoch,
                    selected: stepIsSelected,
                    overflowDisplay: subCountByDay > this.maxBarsInCell ? subCountByDay : null // if true bar will only display in show more modal
                  };

                  // collect days that will need overflow modal
                  if (barInfo.overflowDisplay) {
                    this.dayWithOverflow.push(barInfo.cellId);
                  }

                  if (!barInfo.hidden) barData.push(barInfo);
                }
              });
            }
          });

          this.calDataAvailable = true;
        } else {
          this.calDataAvailable = false;
        }
      }
    });

    const calBarContainer = D3.select(this.calBarsContainer.nativeElement)
      .attr('x', 0)
      .attr('y', 0)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(barData)
      .enter();

    calBarContainer
      .filter((d) => !d.overflowDisplay)
      .append('rect')
      .attr('fill', (d) => d.color)
      .attr('stroke', 'white')
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('x', (d) =>  d.xCoord + 2) // 2 offsets it from grid border
      .attr('y', (d) => d.yCoord)
      .attr('class', (d) => 'D3-chart-bar _' + d.barId + ' _' + d.cellId)
      .on('click', (d) => this.handleBarClick(d))
      .on('mouseover', (d) => this.handleBarHover(d, 'mouseover'))
      .on('mouseout', (d) => this.handleBarHover(d, 'mouseout'));

    this.allD3Bars = D3.selectAll('.D3-chart-bar');
  }

  handleBarClick(barData: any): void {
    this.showPastObjectsInSelection = false;
    const selectedBar = D3.selectAll('.D3-chart-bar').filter('._' + barData.barId).filter('._' + barData.cellId);
    const selectedStep = barData.steps;

    if (selectedBar.classed('bar-active')) {
      this.updateStepSelections(selectedStep, 'remove');
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) this.resetAllSelections();
      this.updateStepSelections(selectedStep, 'add');
    }
  }

  handleBarHover(barData: any, action: string): void {
    const selectedStep = barData.steps;

    if ( action === 'mouseover') {
      const ttHeight = 100;
      const ttWidth = 150;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(barData, 'subcontractor'));

      this.emitChartSelectionOutput(selectedStep, 'hover');
    } else if ( action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');

      this.emitChartSelectionOutput(selectedStep, 'hoverOut');
    }
  }

  drawTooltip(data: any, type: string): string {
    let tooltip;
    switch (type) {
      case 'subcontractor' :
        tooltip = ``;
        if (data.day) tooltip += `<div class='data-point'><span class='label'>Date: </span><span class='value'>` + data.day + `</span></div>`;
        if (data.subcontractor) tooltip += `<div class='data-point'><span class='label'>Subcontactor: </span><span class='value'>` + data.subcontractor + `</span></div>`;
        if (data.totalSteps) tooltip += `<div class='data-point'><span class='label'>Total activities: </span><span class='value'>` + data.totalSteps + `</span></div>`;
        if (data.totalDuration) tooltip += `<div class='data-point'><span class='label'>Total Hours: </span><span class='value'>` + data.totalDuration + ` hrs</span></div>`;
        break;

      case 'milestone' :
        tooltip = ``;
        if (!Utils.isEmptyList(data) && data.length > 1) {
          tooltip += `<div class='data-point'><span class='label'>` + data.length + ` Milestones</span></div>`;
          tooltip += `<div class='data-point'><span class='value'>` + data.map(d => d.name).join('<br>') + `</span></div>`;
        } else {
          tooltip += `<div class='data-point'><span class='label'>Milestone: </span><span class='value'>` + data[0].name + `</span></div>`;
        }
        break;

      case 'material' :
        tooltip = ``;
        if (!Utils.isEmptyList(data) && data.length > 1) {
          tooltip += `<div class='data-point'><span class='label'>` + data.length + ` Materials</span></div>`;
          tooltip += `<div class='data-point'><span class='value'>` + data.map(d => d.name).join('<br>') + `</span></div>`;
        } else {
          tooltip += `<div class='data-point'><span class='label'>Material: </span><span class='value'>` + data[0].name + `</span></div>`;
        }
        break;
    }

    return tooltip;
  }

  updateCellSelections(cells: any, action: string): void {
    const cellData = [];
    cells.each((d) => cellData.push(d));
    const selectedCellIds = cellData.map(cell => cell.cellId);

    cellData.forEach(cellObj => {
      if (!selectedCellIds.includes(cellObj.id) && action === 'add') {
        this.currentCellSelections.push(cellObj);
      } else if (action === 'remove') {
        this.currentCellSelections = this.currentCellSelections.filter(selectedCell => selectedCell.cellId !== cellObj.cellId);
      }
    });

    this.currentDaySelections = this.currentCellSelections.map(cell => cell.cellId);
    this.emitDaySelections(this.currentDaySelections);
  }

  filterOutBarSelections(steps: IProjectScheduleStep[], action: string): void {
    const stepsToUpdate = [];

    if (!Utils.isEmpty(steps)) {
      steps.forEach(step => {
        const selectedBar = D3.selectAll('.bar-active').filter('._' + step.id);
        const stepIsSelected = selectedBar.empty() ? false : true;

        if (!stepIsSelected) {
          stepsToUpdate.push(step);
        }
      });
    }

    this.updateStepSelections(stepsToUpdate, action);
  }

  updateStepSelections(steps: IProjectScheduleStep[], action: string): void {
    const selectedTaskStepIds = this.currentStepSelections.map(step => step.id);

    if (steps.length > 0) {
      steps.forEach((step, index) => {
        if (!selectedTaskStepIds.includes(step.id) && action === 'add') {
          this.currentStepSelections.push(step);
          if (step.id && step.subContractorId && step.epochStart) this.setActiveBarState(step, action);
        } else if (action === 'remove') {
          this.currentStepSelections = this.currentStepSelections.filter(selecteStep => selecteStep.id !== step.id);
          if (step.id && step.subContractorId && step.epochStart) this.setActiveBarState(step, action);
        }
      });
    } else {
      this.currentStepSelections = [];
      this.allD3Bars.classed('bar-active', false);
    }

    this.emitChartSelectionOutput(this.currentStepSelections, 'click');
  }

  setActiveBarState(step: IProjectScheduleStep, action: string): void {
    const selectedBar = D3.selectAll('.D3-chart-bar').filter('._' + step.id + moment.utc(step.epochStart).clone().startOf('day').valueOf());

    if (selectedBar) {
      if (action === 'add') selectedBar.classed('bar-active', true);
      else selectedBar.classed('bar-active', false);
    }
  }

  emitDaySelections(selectedEpochs: number[]): void {
    this.daySelectionOutput.emit(selectedEpochs);
  }

  emitChartSelectionOutput(selectedSteps: IProjectScheduleStep[], action: string): void {
    const noSelections = this.currentCellSelections.length === 0 && this.currentStepSelections.length === 0;

    if (action && action === 'click') {
      action = noSelections ? 'reset' : action;
      this.chartSelectionOutput.emit({
        action: action,
        steps: selectedSteps,
        showPastSteps: this.showPastObjectsInSelection
      });
    } else if ((action === 'hover' || action === 'hoverOut' || action === null)) {
      this.chartSelectionOutput.emit({
        action: action,
        steps: selectedSteps,
        showPastSteps: false
      });
    }

    this.showPastObjectsInSelection = true; // reset after emission
  }

  async resetAllSelections() {
    this.currentStepSelections = [];
    this.currentCellSelections = [];
    this.currentDaySelections = [];

    D3.selectAll('.D3-grid-cell').each(function(d) { D3.select(this).classed('cell-selected', false); });
    D3.selectAll('.D3-chart-bar').each(function(d) { D3.select(this).classed('bar-active', false); });
  }

  // Stone data emit
  emitMaterials(materials: IProjectMaterial[]) {
    this.dayMaterialOutput.emit(materials);
  }

  emitMilestones(milestones: IProjectScheduleMilestone[]) {
    this.dayMilestonesOutput.emit(milestones);
  }
}
