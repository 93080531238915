import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { IPageSpecificFilter } from '../../models/sidebar/sidebar.interface';

import { ProjectEquipmentService } from '../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { Utils } from '../../utils/utils';
import { ViewerMode } from '../../utils/enums/shared.enum';

@Component({
  selector: 'app-model-filter',
  templateUrl: './project-model-filter.component.html',
  styleUrls: ['./project-model-filter.component.scss']
})
export class ProjectModelFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() codesSelected: string[] = [];
  @Input() filterType: FilterModelOptions;
  @Input() hideFilters?: string[];
  @Input() placeholderOptionTitle?: string;
  @Input() projectModelId?: string;
  @Input() pageSpecificFilters?: IPageSpecificFilter[] = [];
  @Input() filterHeader: string = 'model_filters';

  @Output() activitySelected: EventEmitter<string[]> = new EventEmitter();
  @Output() setSearchQuiry: EventEmitter<string> = new EventEmitter();
  @Output() cleanSearchAndFilter: EventEmitter<string> = new EventEmitter();
  @Output() switchFilterType: EventEmitter<any> = new EventEmitter();
  @Output() pageFilterOutput: EventEmitter<IPageSpecificFilter> = new EventEmitter();
  @Output() orAndFilterEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() chartFilter: EventEmitter<boolean> = new EventEmitter();
  @Output() selectionFilter: EventEmitter<boolean> = new EventEmitter();
  @Output() viewerFilter: EventEmitter<boolean> = new EventEmitter();

  // Template Variables
  loading: boolean = false;
  showSubContractorFilter: boolean = true;
  showCategoryFilter: boolean = true;
  showFloorPlanFilter: boolean = true;
  showActivitiesFilter: boolean = true;
  showPlaceholder: boolean = true;
  placeholderSelected: boolean = false;
  currentCategory: any;
  currentActivity: any;
  moreAvailable: boolean = false;
  myFilterModelOptions = FilterModelOptions;
  selectedItems = [];
  saveViewsExpanded: boolean = true;
  showEquipmentFilter: boolean = true;
  showMaterialFilter: boolean = true;
  filterViewer: boolean = true;
  filterChart: boolean = true;
  selectionFiltering: boolean = false;
  orAndFilter: boolean = true;
  orAndDisabled: boolean = true;
  modelStatus: boolean = false;
  selectionFilteringDisabled:boolean = false;

  // Activity Filter
  private activityFilterTree = {};
  private currentActivityId: string = '';
  private allActivities = [];
  private activities = [];

  // Sub Filter
  private subcontractors = [];
  private allSubcontractors = [];

  // Category Filter
  private categoryFilterTree = {};
  private currentCategoryId: string = '';
  private allCategories = [];
  private categories = [];

  // Equipmentes Filter
  private allEquipmentes = [];
  private equipmentes = [];

  // Equipmentes Materials
  private allMaterials = [];
  private materials = [];

  // Floor plan
  private floorPlan = [];
  private allFloorPlan = [];

  // Helpers
  private curSearchRes = [];
  searchQuery: string = '';
  private pageSize: number = 50;

  // Subscription takeUntil
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // tslint:disable-next-line:no-empty
  constructor(
    public projectService: ProjectService,
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService,
  ) { }

  async ngOnInit() {
    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.setFilterByOptions();
      await this.getFilterDataOptions();
      this.setMoreAvailable();
      this.setAlreadySelected();
    });
    if (this.projectService.getProjectReady()) {
      this.setFilterByOptions();
      await this.getFilterDataOptions();
      this.setMoreAvailable();
      this.setAlreadySelected();
    }
    // Default to filter by activity type if none is selected already
    if (Utils.isEmpty(this.filterType) && this.showActivitiesFilter) this.filterType = FilterModelOptions.Activities;

    this.materialService = this.projectService.getMaterialService();
    this.equipmentService = this.projectService.getEquipmentService();
    this.modelStatus = this.projectService.getHasPDFStatus() || this.projectService.getHasModelsStatus();

    if (!this.modelStatus) {
      this.filterViewer = false;
      this.viewerFilter.emit(false);
    } else {
      this.filterViewer = true;
      this.filterChart = true;
      this.viewerFilter.emit(true);
      this.chartFilter.emit(true);
    }
  }

  ngOnChanges(event) {
    if (event.codesSelected) {
      this.allActivities.forEach(tc => tc.selected = false);
      this.activities.forEach(tc => tc.selected = false);
      this.subcontractors.forEach(sub => sub.selected = false);
      this.allCategories.forEach(cat => cat.selected = false);
      this.placeholderSelected = false;
      this.selectedItems = [];
      this.setAlreadySelected();
    }
    if (event.projectModelId && !event.projectModelId.firstChange) {
      this.clearSelected();
      this.clearCategories();
      this.allCategories = [];
      this.categories = [];
      if (!Utils.isEmpty(this.projectModelId)) {
        this.allCategories = this.projectService.getLocalModelCategoriesObjArray(this.projectModelId);
      } else {
        this.allCategories = this.projectService.getLocalCategoriesArray();
      }
      this.getSubCategories();
    }
    this.setMoreAvailable();
    this.updateSelectionFilterFlag();
  }

  viewsExpanded(expanded: boolean) {
    this.saveViewsExpanded = expanded;
  }

  async getFilterDataOptions() {
    return new Promise(async resolve => {
      const getAllFilterData = [this.getSubCategories()];
      let limit;

      if (Utils.isEmptyList(this.allActivities)) {
        this.updateActivity();
        getAllFilterData.push(this.getSubActivities());
      }

      if (Utils.isEmptyList(this.allEquipmentes)) {
        this.updateEquipmentes();
      }

      if (Utils.isEmptyList(this.allFloorPlan)) {
        this.updateFloorPlan();
      }

      if (Utils.isEmptyList(this.allMaterials)) {
        this.updateMatrials();
      }

      // listener to detect if local trade codes has changed and to update the current view
      // NOTE: this will update for all logged in users
      this.projectService.projectActivityChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.updateActivity();
        this.getSubActivities();
      });

      const subMap = this.projectService.getLocalProjectSubcontractorsMap();
      Object.keys(subMap).forEach((key) => { this.allSubcontractors.push(subMap[key]); });
      this.allSubcontractors = Utils.sortByString(this.allSubcontractors, 'name');
      limit = Math.min(this.pageSize, this.allSubcontractors.length);
      this.subcontractors = this.allSubcontractors.slice(0, limit);

      // Equipmentes
      limit = Math.min(this.pageSize, this.allEquipmentes.length);
      this.equipmentes = this.allEquipmentes.slice(0, limit);

      // Materials
      limit = Math.min(this.pageSize, this.allMaterials.length);
      this.materials = this.allMaterials.slice(0, limit);

      // floor plan
      limit = Math.min(this.pageSize, this.allFloorPlan.length);
      this.floorPlan = this.allFloorPlan.slice(0, limit);

      if (!Utils.isEmpty(this.projectModelId)) {
        this.allCategories = this.projectService.getLocalModelCategoriesObjArray(this.projectModelId);
      } else {
        this.allCategories = this.projectService.getLocalCategoriesArray();
      }

      Promise.all(getAllFilterData).then(() => resolve());
    });
  }

  updateActivity() {
    this.allActivities = [];
    const usedTcMap = this.projectService.getLocalUsedActivitiesMap();
    Object.keys(usedTcMap).forEach((key) => { this.allActivities.push(usedTcMap[key]); });
    this.allActivities = Utils.sortByString(this.allActivities, 'name');
  }

  updateFloorPlan() {
    this.allFloorPlan = [];
    const usedTcMap = this.projectService.getLocalFloorPlans(); // Need to remove links 
    Object.keys(usedTcMap).forEach((key) => { this.allFloorPlan.push(usedTcMap[key]); });
  }

  updateEquipmentes() {
    this.allEquipmentes = [];
    const usedTcMap = this.equipmentService.getLocalAllProjectEquipment();
    Object.keys(usedTcMap).forEach((key) => { this.allEquipmentes.push(usedTcMap[key]); });
    this.allEquipmentes = Utils.sortByString(this.allEquipmentes, 'name');
  }

  updateMatrials() {
    this.allMaterials = [];
    const usedTcMap = this.materialService.getLocalAllProjectMaterials();
    Object.keys(usedTcMap).forEach((key) => { this.allMaterials.push(usedTcMap[key]); });
    this.allMaterials = Utils.sortByString(this.allMaterials, 'name');
  }

  setMoreAvailable() {
    this.moreAvailable = false;
    switch (this.filterType) {
      case FilterModelOptions.Activities:
        if (!Utils.isEmpty(this.searchQuery)) return this.moreAvailable = this.activities.length < this.curSearchRes.length;
        break;
      case FilterModelOptions.SubContractor:
        if (!Utils.isEmpty(this.searchQuery)) return this.moreAvailable = this.subcontractors.length < this.curSearchRes.length;
        this.moreAvailable = this.subcontractors.length < this.allSubcontractors.length;
        break;
      case FilterModelOptions.Category:
        if (!Utils.isEmpty(this.searchQuery)) return this.moreAvailable = this.categories.length < this.curSearchRes.length;
        break;
      case FilterModelOptions.Equipment:
        if (!Utils.isEmpty(this.searchQuery)) return this.moreAvailable = this.equipmentes.length < this.curSearchRes.length;
        break;
      case FilterModelOptions.Materials:
        if (!Utils.isEmpty(this.searchQuery)) return this.moreAvailable = this.materials.length < this.curSearchRes.length;
        break;
    }
  }

  clearSelected() {

    this.searchQuery = null;
    this.placeholderSelected = false;
    this.allActivities.forEach(tc => tc.selected = false);
    this.allSubcontractors.forEach(sub => sub.selected = false);
    this.allCategories.forEach(cat => cat.selected = false);
    this.allEquipmentes.forEach(equ => equ.selected = false);
    this.allMaterials.forEach(mat => mat.selected = false);
    this.allFloorPlan.forEach(fp => fp.selected = false);
    this.selectedItems = [];
    this.updateSelectionFilterFlag();

  }

  ngOnDestroy() {
    this.clearSelected();
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  setFilterByOptions() {
    if (!Utils.isEmptyList(this.hideFilters)) {
      this.showActivitiesFilter = !this.hideFilters.includes(FilterModelOptions.Activities);
      this.showSubContractorFilter = !this.hideFilters.includes(FilterModelOptions.SubContractor);
      this.showCategoryFilter = !this.hideFilters.includes(FilterModelOptions.Category);
      this.showFloorPlanFilter = !this.hideFilters.includes(FilterModelOptions.FloorPlan);
      this.showEquipmentFilter = !this.hideFilters.includes(FilterModelOptions.Equipment);
      this.showMaterialFilter = !this.hideFilters.includes(FilterModelOptions.Materials);
    }

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.showFloorPlanFilter = true;
      this.showCategoryFilter = false;
    } else {
      this.showFloorPlanFilter = false;
      this.showCategoryFilter = true;
    }
  }

  setAlreadySelected() {

    switch (this.filterType) {
      case FilterModelOptions.Activities:
        if (!Utils.isEmpty(this.codesSelected) && !Utils.isEmptyList(this.activities)) {
          if (this.codesSelected.includes('')) {
            this.placeholderSelected = true;
          }
          const codesToSet = this.allActivities.filter(activity => this.codesSelected.includes(activity.id));
          codesToSet.forEach(code => {
            const indexOf = this.selectedItems.indexOf(code);
            if (indexOf < 0) {
              code.selected = true;
              code.type = FilterModelOptions.Activities;
              this.selectedItems.push(code);
              const activity = this.activities.find(act => act.id === code.id);
              if (activity) activity.selected = true;
            }
          });
        }
        break;
      case FilterModelOptions.SubContractor:
        if (!Utils.isEmpty(this.codesSelected) && !Utils.isEmptyList(this.subcontractors)) {
          const codesToSet = this.allSubcontractors.filter(sub => this.codesSelected.includes(sub.id));
          codesToSet.forEach(code => {
            const indexOf = this.selectedItems.indexOf(code);
            if (indexOf < 0) {
              code.selected = true;
              this.selectedItems.push(code);
            }
          });
        }
        break;
      case FilterModelOptions.Category:
        if (!Utils.isEmpty(this.codesSelected) && !Utils.isEmptyList(this.categories)) {
          if (this.codesSelected.includes('')) {
            this.placeholderSelected = true;
          }
          const codesToSet = this.allCategories.filter(cat => this.codesSelected.includes(cat.id));
          codesToSet.forEach(code => {
            const indexOf = this.selectedItems.indexOf(code);
            if (indexOf < 0) {
              code.selected = true;
              code.type = FilterModelOptions.Category;
              this.selectedItems.push(code);
            }
          });
        }
        break;
      case FilterModelOptions.Equipment:
        if (!Utils.isEmpty(this.codesSelected) && !Utils.isEmptyList(this.equipmentes)) {
          const codesToSet = this.allEquipmentes.filter(sub => this.codesSelected.includes(sub.id));
          codesToSet.forEach(code => {
            const indexOf = this.selectedItems.indexOf(code);
            if (indexOf < 0) {
              code.selected = true;
              this.selectedItems.push(code);
            }
          });
        }
        break;
      case FilterModelOptions.Materials:
        if (!Utils.isEmpty(this.codesSelected) && !Utils.isEmptyList(this.materials)) {
          const codesToSet = this.allMaterials.filter(sub => this.codesSelected.includes(sub.id));
          codesToSet.forEach(code => {
            const indexOf = this.selectedItems.indexOf(code);
            if (indexOf < 0) {
              code.selected = true;
              this.selectedItems.push(code);
            }
          });
        }
        break;

      case FilterModelOptions.FloorPlan:
      if (!Utils.isEmpty(this.codesSelected) && !Utils.isEmptyList(this.floorPlan)) {
        const codesToSet = this.allFloorPlan.filter(fp => this.codesSelected.includes(fp.id));
        codesToSet.forEach(code => {
          const indexOf = this.selectedItems.indexOf(code);
          if (indexOf < 0) {
            code.selected = true;
            this.selectedItems.push(code);
          }
        });
      }
      break;  
    }

  }

  async itemSelected(item, filterType) {

    SegmentService.track('Model Filter: Filtered View', { type: filterType });
    switch (filterType) {
      case FilterModelOptions.Activities:
        let selectedActivity = this.allActivities.find(activity => activity.id === item.id);
        selectedActivity.selected = !selectedActivity.selected;
        selectedActivity = this.curSearchRes.find(activity => activity.id === item.id);
        if (selectedActivity) selectedActivity.selected = !selectedActivity.selected;
        selectedActivity = this.activities.find(activity => activity.id === item.id);
        if (selectedActivity) selectedActivity.selected = !selectedActivity.selected;
        let activitiesSelected = this.allActivities.filter(activity => activity.selected === true).map(activity => activity.id);
        activitiesSelected = this.placeholderSelected ? activitiesSelected.concat(['']) : activitiesSelected;
        this.activitySelected.emit(activitiesSelected);
        break;
      case FilterModelOptions.SubContractor:
        item.selected = !item.selected;
        const subsSelected = this.allSubcontractors.filter(sub => sub.selected === true).map(sub => sub.id);
        this.activitySelected.emit(subsSelected);
        break;
      case FilterModelOptions.Category:
        const selectedCategory = this.allCategories.find(c => c.id === item.id);
        selectedCategory.selected = !selectedCategory.selected; // set the displayed item selected
        let catSelected = this.allCategories.filter(cat => cat.selected === true).map(cat => cat.id);
        catSelected = this.placeholderSelected ? catSelected.concat(['']) : catSelected;
        this.activitySelected.emit(catSelected);
        break;
      case FilterModelOptions.Equipment:
        item.selected = !item.selected;
        const eqipmentSelected = this.allEquipmentes.filter(eqipment => eqipment.selected === true).map(eqipment => eqipment.id);
        this.activitySelected.emit(eqipmentSelected);
        break;
      case FilterModelOptions.Materials:
        item.selected = !item.selected;
        const materialsSelected = this.allMaterials.filter(materials => materials.selected === true).map(materials => materials.id);
        this.activitySelected.emit(materialsSelected);
        break;
      case FilterModelOptions.FloorPlan:
        item.selected = !item.selected;
        const floorPlanSelected = this.allFloorPlan.filter(fp => fp.selected === true).map(fp => fp.id);
        this.activitySelected.emit(floorPlanSelected);
        break;
    }
  }

  async placeHolderOptionSelected() {

    SegmentService.track('Model Filter: Filtered View', { type: 'noActivity' });
    this.placeholderSelected = !this.placeholderSelected;
    switch (this.filterType) {
      case FilterModelOptions.Activities:
        let tcsSelected = this.allActivities.filter(tc => tc.selected === true).map(tc => tc.id);
        tcsSelected = this.placeholderSelected ? tcsSelected.concat(['']) : tcsSelected;
        this.activitySelected.emit(tcsSelected);
        break;
      case FilterModelOptions.Category:
        let catSelected = this.allCategories.filter(cat => cat.selected === true).map(cat => cat.id);
        catSelected = this.placeholderSelected ? catSelected.concat(['']) : catSelected;
        this.activitySelected.emit(catSelected);
        break;
    }
  }

  applySearch(dontSearcFlag?: boolean) {
    switch (this.filterType) {
      case FilterModelOptions.Activities:
        if (Utils.isEmpty(this.searchQuery)) {
          this.getSubActivities();
        } else {
          this.curSearchRes = this.allActivities.filter(tc => tc.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          const limit = Math.min(this.pageSize, this.curSearchRes.length);
          this.activities = this.curSearchRes.slice(0, limit);
          this.showPlaceholder = false;
          this.clearActivities();
        }
        // this.activities = Utils.sortByString(this.activities, 'name');
        this.activities = Utils.sortData(this.activities, this.activities );
        break;
      case FilterModelOptions.SubContractor:
        if (Utils.isEmpty(this.searchQuery)) {
          if (!Utils.isEmptyList(this.allSubcontractors)) {
            const limit = Math.min(this.pageSize, this.allSubcontractors.length);
            this.subcontractors = this.allSubcontractors.slice(0, limit);
          }
        } else {
          this.curSearchRes = this.allSubcontractors.filter(tc => tc.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          const limit = Math.min(this.pageSize, this.curSearchRes.length);
          this.subcontractors = this.curSearchRes.slice(0, limit);
        }
        this.subcontractors = Utils.sortByString(this.subcontractors, 'name');
        break;
      case FilterModelOptions.Category:
        if (Utils.isEmpty(this.searchQuery)) {
          this.getSubCategories();
        } else {
          if (!Utils.isEmpty(this.projectModelId)) {
            this.allCategories = this.projectService.getLocalModelCategoriesObjArray(this.projectModelId);
          } else {
            this.allCategories = this.projectService.getLocalCategoriesArray();
          }
          this.curSearchRes = this.allCategories.filter(cat => cat.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          const limit = Math.min(this.pageSize, this.curSearchRes.length);
          this.categories = this.curSearchRes.slice(0, limit);
          this.showPlaceholder = false;
          // reset the category trees since it's not needed when you start search
          this.clearCategories();
        }
        this.categories = Utils.sortByString(this.categories, 'name');
        break;
      case FilterModelOptions.Equipment:
        if (Utils.isEmpty(this.searchQuery)) {
          this.loadingFilters(true); // disable page while categories load
          if (!Utils.isEmptyList(this.allEquipmentes)) {
            const limit = Math.min(this.pageSize, this.allEquipmentes.length);
            this.equipmentes = this.allEquipmentes.slice(0, limit);
          }
        } else {
          this.curSearchRes = this.allEquipmentes.filter(tc => tc.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          const limit = Math.min(this.pageSize, this.curSearchRes.length);
          this.equipmentes = this.curSearchRes.slice(0, limit);
        }
        this.equipmentes = Utils.sortByString(this.equipmentes, 'name');
        this.loadingFilters(false); // enable page 
        break;
      case FilterModelOptions.Materials:
        if (Utils.isEmpty(this.searchQuery)) {
          this.loadingFilters(true); // disable page while categories load
          if (!Utils.isEmptyList(this.allMaterials)) {
            const limit = Math.min(this.pageSize, this.allMaterials.length);
            this.materials = this.allMaterials.slice(0, limit);
          }
        } else {
          this.curSearchRes = this.allMaterials.filter(tc => tc.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          const limit = Math.min(this.pageSize, this.curSearchRes.length);
          this.materials = this.curSearchRes.slice(0, limit);
        }
        this.materials = Utils.sortByString(this.materials, 'name');
        this.loadingFilters(false); // enable page 
        break;
      case FilterModelOptions.FloorPlan:
        if (Utils.isEmpty(this.searchQuery)) {
          this.loadingFilters(true); // disable page while categories load
          if (!Utils.isEmptyList(this.allFloorPlan)) {
            const limit = Math.min(this.pageSize, this.allFloorPlan.length);
            this.floorPlan = this.allFloorPlan.slice(0, limit);
          }
        } else {
          this.curSearchRes = this.allFloorPlan.filter(tc => tc.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          const limit = Math.min(this.pageSize, this.curSearchRes.length);
          this.floorPlan = this.curSearchRes.slice(0, limit);
        }
        // No sorting is requred 
        // Show it in same sequence as present in floor plan settings page.
        //  this.floorPlan = Utils.sortByString(this.floorPlan, 'name');
        this.loadingFilters(false); // enable page 
        break;  
    }

    this.setSearchQuiry.emit(this.searchQuery);
    this.updateSelectionFilterFlag();
    this.setMoreAvailable();
  }

  clearSearch() {
    this.searchQuery = null;
    this.clearSelected();
    this.clearCategories();
    this.clearActivities();
    this.cleanSearchAndFilter.emit();
    this.applySearch(true);
  }



  handleViewSelect(value: FilterModelOptions) {
    if (this.filterType === value) return;
    this.filterType = value;
    this.clearSelected();
    this.clearSearch();
    this.setMoreAvailable();

    if (this.filterType === FilterModelOptions.Activities || this.filterType === FilterModelOptions.SubContractor || this.filterType === FilterModelOptions.Category) {
      this.orAndFilter = true;
      this.orAndFilterEmitter.emit(this.orAndFilter);
      this.orAndDisabled = true;
    } else {
      this.orAndDisabled = false;
    }

    this.switchFilterType.emit(value);
  }

  clearCategories() {
    this.currentCategory = null;
    this.categoryFilterTree = {};
    this.currentCategoryId = '';
  }

  clearActivities() {
    this.currentActivity = null;
    this.activityFilterTree = {};
    this.currentActivityId = '';
  }

  loadMore() {
    let limit;
    switch (this.filterType) {
      case FilterModelOptions.Activities:
        this.activities = Utils.sortByString(this.activities, 'name');
        if (!Utils.isEmpty(this.searchQuery)) {
          limit = Math.min(this.activities.length + this.pageSize, this.curSearchRes.length);
          this.activities = this.curSearchRes.slice(0, limit);
        } else {
          limit = Math.min(this.activities.length + this.pageSize, this.activities.length);
          this.activities = this.activities.slice(0, limit);
        }
        break;
      case FilterModelOptions.SubContractor:
        if (!Utils.isEmpty(this.searchQuery)) {
          limit = Math.min(this.subcontractors.length + this.pageSize, this.curSearchRes.length);
          this.subcontractors = this.curSearchRes.slice(0, limit);
        } else {
          limit = Math.min(this.subcontractors.length + this.pageSize, this.allSubcontractors.length);
          this.subcontractors = this.allSubcontractors.slice(0, limit);
        }
        break;
      case FilterModelOptions.Category:
        this.categories = Utils.sortByString(this.categories, 'name');
        if (!Utils.isEmpty(this.searchQuery)) {
          limit = Math.min(this.categories.length + this.pageSize, this.curSearchRes.length);
          this.categories = this.curSearchRes.slice(0, limit);
        } else {
          limit = Math.min(this.categories.length + this.pageSize, this.categories.length);
          this.categories = this.categories.slice(0, limit);
        }
        break;

      case FilterModelOptions.Equipment:
        this.equipmentes = Utils.sortByString(this.equipmentes, 'name');
        if (!Utils.isEmpty(this.searchQuery)) {
          limit = Math.min(this.equipmentes.length + this.pageSize, this.curSearchRes.length);
          this.equipmentes = this.curSearchRes.slice(0, limit);
        } else {
          limit = Math.min(this.equipmentes.length + this.pageSize, this.equipmentes.length);
          this.equipmentes = this.equipmentes.slice(0, limit);
        }
        break;
      case FilterModelOptions.Materials:
        this.materials = Utils.sortByString(this.materials, 'name');
        if (!Utils.isEmpty(this.searchQuery)) {
          limit = Math.min(this.materials.length + this.pageSize, this.curSearchRes.length);
          this.materials = this.curSearchRes.slice(0, limit);
        } else {
          limit = Math.min(this.materials.length + this.pageSize, this.materials.length);
          this.materials = this.materials.slice(0, limit);
        }
        break;
    }
    this.setMoreAvailable();
  }

  categoryGoBack() {
    this.loadingFilters(true); // disable page while categories load

    // if no models are preset then display everything
    let parentId;
    if (!this.categoryFilterTree[this.currentCategoryId]) {
      parentId = '';
      // if no more parent options available then check if there was an original search query and default to that
      if (!Utils.isEmpty(this.searchQuery)) {
        this.applySearch();
        this.loadingFilters(false); // re-enable page
        return;
      }
    } else {
      parentId = this.categoryFilterTree[this.currentCategoryId].parentId;
    }

    const query = { categoryId: parentId };
    this.projectService.getCategories(this.projectService.currentProject.id, query).pipe(takeUntil(this.destroyed$)).subscribe((categories) => {
      let modelCategory;
      if (Utils.isEmpty(this.projectModelId)) modelCategory = categories;
      else modelCategory = categories.filter(mCat => mCat.modelIds.some(x => x === this.projectModelId));

      // create a reference link to the local Categories list
      const newCategoriesArr = [];
      modelCategory.forEach(cat => {
        const refCategory = this.projectService.getLocalCategoryId(cat.id);
        if (refCategory) {
          if (!Utils.isEmptyList(this.codesSelected)) refCategory.selected = this.codesSelected.includes(cat.id) ? true : false;
          else refCategory.selected = false;

          refCategory.children = cat.children;
          newCategoriesArr.push(refCategory);
        }
      });
      this.categories = Utils.sortByString(newCategoriesArr, 'name');

      // make sure to delete the last current category id from the filter tree and set the new parentId as the new current
      delete this.categoryFilterTree[this.currentCategoryId];
      this.currentCategoryId = parentId;
      this.currentCategory = this.categoryFilterTree[parentId];

      if (Utils.isEmpty(this.currentCategoryId)) this.currentCategory = null;
      this.curSearchRes = [];
      this.setMoreAvailable();
      this.loadingFilters(false); // re-enable page
    });
  }

  activityGoBack() {
    this.loadingFilters(true); // disable page while categories load

    // if no models are preset then display everything
    let parentId;
    if (!this.activityFilterTree[this.currentActivityId]) {
      parentId = '';
      // if no more parent options available then check if there was an original search query and default to that
      if (!Utils.isEmpty(this.searchQuery)) {
        this.applySearch();
        this.loadingFilters(false); // re-enable page
        return;
      }
    } else {
      parentId = this.activityFilterTree[this.currentActivityId].parentId;
    }

    const query = { parent: parentId };
    this.projectService.getActivityTree(this.projectService.currentProject.id, query).pipe(takeUntil(this.destroyed$)).subscribe((activities) => {
      // create a reference link to the local Categories list
      const newActivitiesArr = [];
      activities.forEach(activity => {
        const activityData = this.projectService.getLocalActivity(activity.id);
        if (activityData) {
          if (!Utils.isEmptyList(this.codesSelected)) activityData.selected = this.codesSelected.includes(activity.id) ? true : false;
          else activityData.selected = false;

          activityData.children = activity.children;
          newActivitiesArr.push(activityData);
        }
      });
      // this.activities = Utils.sortByString(newActivitiesArr, 'name');
      this.activities = Utils.sortData(newActivitiesArr, newActivitiesArr );
      // make sure to delete the last current category id from the filter tree and set the new parentId as the new current
      delete this.activityFilterTree[this.currentActivityId];
      this.currentActivityId = parentId;
      this.currentActivity = this.activityFilterTree[parentId];

      if (Utils.isEmpty(this.currentActivityId)) this.currentActivity = null;
      this.curSearchRes = [];
      this.setMoreAvailable();
      this.loadingFilters(false); // re-enable page
    });
  }

  async getSubCategories(cat?: any, bypass?: boolean) {
    return new Promise(resolve => {
      if (!this.categoryFilterTree) this.categoryFilterTree = {};
      if (!Utils.isEmpty(cat) && this.categoryFilterTree[cat.id] && !bypass) return;

      this.loadingFilters(true); // disable page while categories load

      const query = cat != null || (cat != null && cat.id !== '') ? { categoryId: cat.id } : null;
      this.projectService.getCategories(this.projectService.currentProject.id, query).pipe(takeUntil(this.destroyed$)).subscribe(
        (categories) => {
          let modelCategory;
          if (Utils.isEmpty(this.projectModelId)) modelCategory = categories;
          else modelCategory = categories.filter(mCat => mCat.modelIds.some(x => x === this.projectModelId));

          // create a reference link to the local Categories list
          const newCategoriesArr = [];
          modelCategory.forEach(c => {
            const refCategory = this.projectService.getLocalCategoryId(c.id);
            if (refCategory) {
              if (!Utils.isEmptyList(this.codesSelected)) refCategory.selected = this.codesSelected.includes(c.id) ? true : false;
              else refCategory.selected = false;

              refCategory.children = c.children;
              newCategoriesArr.push(refCategory);
            }
          });
          this.categories = Utils.sortByString(newCategoriesArr, 'name');

          // set a new item in the filter tree with the parent id of it's parent category then set the new category as the current category
          if (cat != null) {
            if (Utils.isEmpty(this.categoryFilterTree[cat.id])) {
              this.categoryFilterTree[cat.id] = cat;
              this.categoryFilterTree[cat.id]['parentId'] = this.currentCategoryId;
            }

            this.currentCategoryId = cat.id;
            this.currentCategory = this.categoryFilterTree[cat.id];
          }
          this.curSearchRes = [];
          this.setMoreAvailable();

          this.loadingFilters(false); // re-enable page

          return resolve();
        },
        err => {
          return resolve();
        });
    });
  }

  async getSubActivities(act?: any, bypass?: boolean) {
    return new Promise(resolve => {
      if (!this.activityFilterTree) this.activityFilterTree = {};
      if (!Utils.isEmpty(act) && this.activityFilterTree[act.id] && !bypass) return resolve();

      this.loadingFilters(true); // disable page while categories load
      const query = act != null || (act != null && act.id !== '') ? { parent: act.id } : null;
      this.projectService.getActivityTree(this.projectService.currentProject.id, query).pipe(takeUntil(this.destroyed$)).subscribe(
        (activities) => {
          // create a reference link to the local Categories list
          const newActivitiesArr = [];
          activities.forEach(activity => {
            const activityData = this.projectService.getLocalActivity(activity.id);
            if (activityData) {
              if (!Utils.isEmptyList(this.codesSelected)) activityData.selected = this.codesSelected.includes(activity.id) ? true : false;
              else activityData.selected = false;

              activityData.children = activity.children;
              activityData.objectCount = activity.objectCount;
              activityData.plannedObjectCount = activity.plannedObjectCount;
              newActivitiesArr.push(activityData);
            }
          });
          // this.activities = Utils.sortByString(newActivitiesArr, 'startDate');
          this.activities = Utils.sortData(newActivitiesArr, newActivitiesArr );
          // set a new item in the filter tree with the parent id of it's parent activity then set the new activity as the current activity
          if (act != null) {
            if (Utils.isEmpty(this.activityFilterTree[act.id])) {
              this.activityFilterTree[act.id] = act;
              this.activityFilterTree[act.id]['parentId'] = this.currentActivityId;
            }

            this.currentActivityId = act.id;
            this.currentActivity = this.activityFilterTree[act.id];
          }
          this.curSearchRes = [];
          this.setMoreAvailable();

          this.loadingFilters(false); // re-enable page

          return resolve();
        },
        err => {
          return resolve();
        });
    });
  }

  setPageSpecificFilter(event, filter) {
    SegmentService.track('Model Filter: Filtered View', { type: filter.label });
    const filterIndexToUpdate = this.pageSpecificFilters.findIndex(f => f.label === filter.label);
    if (filterIndexToUpdate > -1) {
      this.pageSpecificFilters[filterIndexToUpdate].checked = event.checked;
    }
    this.pageFilterOutput.emit(filter);
  }

  loadingFilters(state: boolean) {
    this.projectService.filteringModel = state;
  }

  setFilterViewer(event) {
    this.filterViewer = event.checked;
    this.viewerFilter.emit(event.checked);
  }

  setOrAndFilter(event) {
    this.orAndFilter = event.checked;
    this.orAndFilterEmitter.emit(this.orAndFilter);
  }

  setFilterChart(event) {
    this.filterChart = event.checked;
    this.chartFilter.emit(event.checked);
  }

  setSelectionFiltering(event) {
    this.selectionFiltering = event.checked;
    this.selectionFilter.emit(event.checked);
  }

  updateSelectionFilterFlag(){

    if(this.selectedItems.length > 0 || this.placeholderSelected)
      this.selectionFilteringDisabled = true;
    else
      this.selectionFilteringDisabled = false;
    
    if(!this.selectionFilteringDisabled){ // it means if it is not disable then try check searchQuery to disable it.
      if(this.searchQuery && this.searchQuery.length > 0)
        this.selectionFilteringDisabled = true;
      else
        this.selectionFilteringDisabled = false;      
    }
    
    if (this.selectionFilteringDisabled) {
      if (this.selectionFiltering) {
        this.selectionFiltering = false;
        this.selectionFilter.emit(false);
      }     
    }
  }

}
