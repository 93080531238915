
import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProcoreService } from '../../../services/procore/procore.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';

import { IAccount } from '../../../models/account/account.interface';
import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { IProject } from '../../../models/project/project.interface';
import { IUserPermission } from '../../../models/user/user.interface';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-procore-settings',
  templateUrl: './project-procore-settings.component.html',
  styleUrls: ['./project-procore-settings.component.scss']
})
export class ProjectProcoreSettingsComponent implements OnInit, OnDestroy {

  @Input() projectPermission: IUserPermission;
  @Input() currentAccount: IAccount;
  @Output() updateMenu: EventEmitter<any> = new EventEmitter();

  projectId: string;
  currentProject: IProject;
  accountHasProcore: boolean = false;
  accountName: string;

  procoreProjectDropdown: IDropdownItem[] = new Array();
  filteredProcoreDropdown: IDropdownItem[] = new Array();
  showProcoreDropdown: boolean = false;

  writeBack: number = 1;

  procoreWriteRequiredOnSiteDate: boolean = false;
  procoreWriteDueDate: boolean = false;
  procoreWriteSubmitByDate: boolean = false;
  procoreOnlyAccelerateDates: boolean = true;

  procoreWriteDailyHour: number = null;
  procoreWriteDailyManpower: boolean = false;
  procoreWriteDailyEquipment: boolean = false;
  procoreWriteDailyDelivery: boolean = false;
  procoreWriteDailyQuantity: boolean = false;

  hoursDropDownList: IDropdownItem[]; // data comes from Utils

  procoreProjectInput: string = '';
  selectedProcoreProject;
  currentProcoreProject;

  isLoading: boolean;

  // confirm modal
  showConfirmModal = false;
  confirmModalInput: IConfirmationModalInput = {
    displayMessage: 'Changing the Procore project will delete all data related to the current project. Continue?',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: false
  };

  syncModal: boolean = false;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public projectService: ProjectService,
    private procoreService: ProcoreService,
    private notificationService: NotificationService,
    private segmentService: SegmentService,
    private forgeViewerService: ForgeViewerService
  ) {}

  ngOnInit() {
    this.hoursDropDownList = Utils.hourDropdownItems();
    this.projectId = this.projectService.currentProject.id;
    this.currentProject = this.projectService.currentProject;
    this.accountName = this.currentAccount.name;

    if (!Utils.isEmpty(this.currentProject.procoreWriteRequiredOnSiteDate)) this.procoreWriteRequiredOnSiteDate = this.currentProject.procoreWriteRequiredOnSiteDate;
    if (!Utils.isEmpty(this.currentProject.procoreWriteDueDate)) this.procoreWriteDueDate = this.currentProject.procoreWriteDueDate;
    if (!Utils.isEmpty(this.currentProject.procoreWriteSubmitByDate)) this.procoreWriteSubmitByDate = this.currentProject.procoreWriteSubmitByDate;
    if (!Utils.isEmpty(this.currentProject.procoreOnlyAccelerateDates)) this.procoreOnlyAccelerateDates = this.currentProject.procoreOnlyAccelerateDates;
    this.procoreWriteDailyManpower = !!this.currentProject.procoreWriteDailyManpower;
    this.procoreWriteDailyEquipment = !!this.currentProject.procoreWriteDailyEquipment;
    this.procoreWriteDailyDelivery = !!this.currentProject.procoreWriteDailyDelivery;
    this.procoreWriteDailyQuantity = !!this.currentProject.procoreWriteDailyQuantity;
    this.procoreWriteDailyHour = this.currentProject.procoreWriteDailyHour || 0;

    if (!this.procoreWriteDueDate && !this.procoreWriteRequiredOnSiteDate && !this.procoreWriteSubmitByDate) this.writeBack = 1;
    else if (this.procoreOnlyAccelerateDates) this.writeBack = 3;
    else this.writeBack = 2;

    if (this.currentAccount.hasProcore) {
      this.accountHasProcore = true;
      this.getProcoreData();
    }
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getProcoreData() {
    this.isLoading = true;
    this.procoreService.getProcoreProjects(this.currentAccount.id, this.currentProject.id).pipe(takeUntil(this.destroyed$)).subscribe(
      results => {
        this.isLoading = false;
        const procoreProjects = Utils.sortByString(results, 'projectName');
        this.procoreProjectDropdown = procoreProjects.map(project => ({ value: project.projectId, label: project.projectName + ' (' + project.projectId + ')' }));
        if (this.currentProject.procoreProjectId) {
          this.currentProcoreProject =  this.procoreProjectDropdown.find(proj => proj.value === this.currentProject.procoreProjectId);
          this.selectedProcoreProject = this.currentProcoreProject;
          this.procoreProjectInput = this.selectedProcoreProject.label;
        }
      },
      error => {
        this.isLoading = false;
        const context: INoficationContext = {
          type: 'Procore project(s)',
          action: 'get'
        };
        this.notificationService.error(error, context);
      }
    );
  }

  modalConfirm() {
    if (this.syncModal) {
      this.sync();
    } else {
      this.clearProcoreProject();
    }
  }

  updateSubmittalSettings() {
    this.currentProject.procoreWriteDueDate = this.procoreWriteDueDate;
    this.currentProject.procoreWriteRequiredOnSiteDate = this.procoreWriteRequiredOnSiteDate;
    this.currentProject.procoreWriteSubmitByDate = this.procoreWriteSubmitByDate;
    this.currentProject.procoreOnlyAccelerateDates = this.procoreOnlyAccelerateDates;
    this.updateProjectInformation();
  }

  clearProcoreProject() {
    this.currentProcoreProject = null;
    this.selectedProcoreProject = null;
    this.procoreProjectInput = null;

    delete this.currentProject.procoreProjectId;
    delete this.currentProject.procoreOnlyAccelerateDates;
    delete this.currentProject.procoreWriteDailyEquipment;
    delete this.currentProject.procoreWriteDailyHour;
    delete this.currentProject.procoreWriteDailyManpower;
    delete this.currentProject.procoreWriteDailyQuantity;
    delete this.currentProject.procoreWriteDailyQuantity;
    delete this.currentProject.procoreWriteDueDate;
    delete this.currentProject.procoreWriteRequiredOnSiteDate;
    delete this.currentProject.procoreWriteSubmitByDate;

    this.updateProjectInformation();
  }

  updateProcoreProject() {
    this.currentProject.procoreProjectId = this.selectedProcoreProject ? this.selectedProcoreProject.value : this.currentProcoreProject.value;
    this.currentProcoreProject = this.currentProject.procoreProjectId;
    this.selectedProcoreProject = this.currentProject.procoreProjectId;
    this.updateProjectInformation();
  }

  updateDailyLogSettings() {
    this.currentProject.procoreWriteDailyManpower = this.procoreWriteDailyManpower;
    this.currentProject.procoreWriteDailyEquipment = this.procoreWriteDailyEquipment;
    this.currentProject.procoreWriteDailyDelivery = this.procoreWriteDailyDelivery;
    this.currentProject.procoreWriteDailyQuantity = this.procoreWriteDailyQuantity;
    this.currentProject.procoreWriteDailyHour = this.procoreWriteDailyHour;
    this.updateProjectInformation();
  }

  updateProjectInformation(): void {
    this.showConfirmModal = false;
    this.forgeViewerService.setDisablePage(true);
    const  body = {
      procoreProjectId: this.currentProject.procoreProjectId,
      procoreWriteDueDate: this.currentProject.procoreWriteDueDate,
      procoreWriteRequiredOnSiteDate: this.currentProject.procoreWriteRequiredOnSiteDate,
      procoreWriteSubmitByDate: this.currentProject.procoreWriteSubmitByDate,
      procoreOnlyAccelerateDates: this.currentProject.procoreOnlyAccelerateDates,
      procoreWriteDailyManpower: this.currentProject.procoreWriteDailyManpower,
      procoreWriteDailyEquipment: this.currentProject.procoreWriteDailyEquipment,
      procoreWriteDailyDelivery: this.currentProject.procoreWriteDailyDelivery,
      procoreWriteDailyQuantity: this.currentProject.procoreWriteDailyQuantity,
      procoreWriteDailyHour: this.currentProject.procoreWriteDailyHour
    };

    this.procoreService.linkProcoreProject(this.projectId, body).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.forgeViewerService.setDisablePage(false);
        this.segmentService.track('Project Updated', {projectId: this.projectId});
        this.notificationService.success('SUCCESSFUL_SAVE', null);
        this.updateMenu.next();
      },
      error => {
        this.forgeViewerService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'project information',
          action: 'update'
        };
        this.notificationService.error(error, context);
      });
  }

  showSyncModal() {
    this.syncModal = true;
    this.confirmModalInput.displayMessage = 'are_you_sure';
    this.showConfirmModal = true;
  }

  sync(): void {
    this.showConfirmModal = false;
    this.segmentService.track('Procore Synced', {projectId: this.projectId});
    this.forgeViewerService.setDisablePage(true);
    this.procoreService.syncProcoreProject(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.forgeViewerService.setDisablePage(false);
        this.notificationService.success('SUCCESS', null);
        this.segmentService.track('Procore Synced', {projectId: this.projectId});
      },
      error => {
        this.forgeViewerService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'procore',
          action: 'sync'
        };
        this.notificationService.error(error, context);
      });
  }

  // confirmation modal
  projectClearClick() {
    this.syncModal = false;
    this.confirmModalInput.displayMessage = 'are_you_sure';
    this.showConfirmModal = true;
  }

  radioEvent() {
    if (this.writeBack === 1) {
      this.procoreWriteDueDate = false;
      this.procoreWriteRequiredOnSiteDate = false;
      this.procoreWriteSubmitByDate = false;
    }
    if (this.writeBack === 3) this.procoreOnlyAccelerateDates = true;
    else this.procoreOnlyAccelerateDates = false;
  }

  filterProcoreProjects(clicked: boolean) {
    if (clicked) {
      this.filteredProcoreDropdown = this.procoreProjectDropdown;
      return;
    }
    if (this.procoreProjectInput !== '') {
      this.filteredProcoreDropdown = this.procoreProjectDropdown.filter(proj => proj.label.toLowerCase().includes(this.procoreProjectInput.toLowerCase()));
    } else {
      this.filteredProcoreDropdown = this.procoreProjectDropdown;
    }
    this.selectedProcoreProject = this.procoreProjectDropdown.find(proj => proj.label === this.procoreProjectInput);
  }
}
