export enum FilterModelOptions {
    Activities = 'activity',
    SubContractor = 'subcontractor',
    Category = 'category',
    FloorPlan = 'floorPlan',
    Unplanned = 'unplanned',
    MyWork = 'myWork',
    Tasks = 'tasks',
    Objects = 'objects',
    Equipment = 'equipment',
    Materials = 'materials'
  }
