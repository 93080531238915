import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ChartService } from '../../../services/chart/chart.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectReportsService } from '../../../services/project/project-reports/project-reports.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectService } from '../../../services/project/project.service';

import { INoficationContext } from '../../../models/notification/notification.interface';
import { ICostReportChartData, ICostReportData } from '../../../models/reports/project-report.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';

import { CostColor, GritColors } from '../../../utils/enums/hex-color.enum';
import { ChartIntervalState, CostReportValueTypes } from '../../../utils/enums/reports.enum';
import { ViewMode } from '../../../utils/enums/shared.enum';
import { Utils } from '../../../utils/utils';

import * as D3 from 'd3';
import * as moment from 'moment';
import { TranslationService } from '../../../services/translation/translation.service';
@Component({
  selector: 'app-project-budget-report',
  templateUrl: './project-budget-report.component.html',
  styleUrls: ['./project-budget-report.component.scss']
})
export class ProjecBudgetReportComponent implements OnInit, OnDestroy {
  @ViewChild('chartSvgContainer') chartSvgContainer;
  @ViewChild('graphContainer') graphContainer;
  @ViewChild('columnContainer') columnContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('xAxisLabel') xAxisLabel;
  @ViewChild('yAxis') yAxis;
  @ViewChild('yAxisLabel') yAxisLabel;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  pageIsLoading: boolean = false;
  errorGettingData: boolean = false;
  noData: boolean = false;
  errorMessage: string = 'error_getting_data.';
  noDataMessage: string = 'cost_data_error';
  private scheduleCostData: any[] = [];
  private formattedData: ICostReportData[] = [];
  private activeChartInterval: ChartIntervalState;
  private allYaxisValues: number[] = [];
  private xScaleTime: D3.scaleUtc<any> = null;
  private yScale: D3.ScaleLinear<number, number> = null;
  chartControls: IProjectScheduleControls;
  private activeDisplayMode: ViewMode = ViewMode.OneMonthGantt;
  private chartStart: number;
  private chartEnd: number;
  private visibleMonths: number;
  private xAxisTickCount: number;

  constructor(
    private chartService: ChartService,
    private projectService: ProjectService,
    private projectScheduleService: ProjectScheduleService,
    private notificationService: NotificationService,
    public projectReportsService: ProjectReportsService
  ) { }

  ngOnInit() {
    this.getActiveSchedule();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getActiveSchedule(): void {
    this.pageIsLoading = true;
    const getCostSchedule = (scheduleId: string) => this.projectScheduleService.getCostSchedule(scheduleId);

    this.projectScheduleService.getActiveSchedule(this.projectService.currentProject.id)
      .pipe(switchMap(val => getCostSchedule(val['id'])))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.scheduleCostData = this.projectReportsService.transformCostReportData(res);
          this.setupData();

          this.pageIsLoading = false;
          this.errorGettingData = false;
          this.noData = res.length < 1;
        },
        err => {
          const context: INoficationContext = {
            type: 'get schedule',
            action: 'get'
          };

          this.notificationService.error(err, context);
          this.pageIsLoading = false;
          this.errorGettingData = true;
        }
      );
  }

  setupData(): void {
    this.setChartDisplayMode(ViewMode.OneMonthGantt);
    this.formattedData = this.projectReportsService.formatCostReportDataForChart(this.activeDisplayMode, this.scheduleCostData);
    this.setXaxisData();
    this.setupChartControls();
    this.drawChart();
  }

  drawChart(): void {
    this.createChartDimensions();
    this.setScales();
    this.drawYaxis();
    this.drawXaxis();
    this.setupChartData(this.formattedData);
    this.drawColumns(this.formattedData);
  }

  setupChartControls(): void {
    this.chartControls = this.projectReportsService.getChartControls(this.chartStart, this.chartEnd);
  }

  setXaxisData(startDate?: number): void {
    switch (this.activeDisplayMode) {
      case ViewMode.ThreeMonthGantt :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(3, ChartIntervalState.Month, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
        break;
      case ViewMode.SixMonthGantt :
        this.activeChartInterval = ChartIntervalState.Month;
        this.setStartEnd(6, this.activeChartInterval, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
        break;
      case ViewMode.TwelveMonthGantt :
        this.activeChartInterval = ChartIntervalState.Month;
        this.setStartEnd(12, this.activeChartInterval, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
        break;
      default :
        this.activeChartInterval = ChartIntervalState.Day;
        this.setStartEnd(1, ChartIntervalState.Month, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
    }
  }

  setStartEnd(monthCount: number, interval: any, startDate?: number): void {
    this.chartStart = startDate ? startDate : moment.utc(this.formattedData[0].dayStart).startOf('month').valueOf();
    this.chartEnd = moment.utc(this.chartStart).clone().add(monthCount, interval).subtract(1, 'day').valueOf(); // so last day is last of month
    this.visibleMonths = monthCount;
  }

  createChartDimensions(): void {
    const containerHeight = window.innerHeight - this.projectReportsService.chartIntroHeight - (200);
    const containerWidth = document.getElementById('chart-scroll-container').offsetWidth - 40;

    this.projectReportsService.setGraphDimensions(containerHeight, containerWidth, this.xAxisTickCount);
    this.projectReportsService.setChartWrapperDimensions();
    this.projectReportsService.setChartAxes();
  }

  setScales(): void {
    this.allYaxisValues = this.formattedData.map(item => item.totalCost);

    this.yScale = D3.scaleLinear()
      .range([(this.projectReportsService.graphDimensions.height), 0])
      .domain([0, Math.max(...this.allYaxisValues) + 1])
      .nice();

    this.xScaleTime = D3.scaleUtc()
      .range([0, this.projectReportsService.graphDimensions.width])
      .domain([this.chartStart, this.chartEnd]);
  }

  drawYaxis(): void {
    const yAxis = D3.axisLeft()
      .scale(this.yScale)
      .tickSize(5)
      .ticks(10)
      .tickPadding(10);

    D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis')
      .attr('transform', this.projectReportsService.axisData.yAxisTransform)
      .call(yAxis);

    D3.select(this.yAxisLabel.nativeElement)
      .attr('transform', `rotate(-90) ${this.projectReportsService.axisData.yAxisLabelTransform}`)
      .style('text-anchor', 'middle')
      .text(TranslationService.translate('cost'));
  }

  drawXaxis(): void {
    const formatLabel = (date: number) => {
      switch (this.activeChartInterval) {
        case ChartIntervalState.Month : return moment.utc(date).clone().format('M/Y');
        default : return moment.utc(date).clone().format('M/D');
      }
    };

    const xAxis = D3.axisBottom()
      .scale(this.xScaleTime)
      .tickSize(5)
      .tickPadding(10)
      .ticks(this.xAxisTickCount)
      .tickFormat((d) => formatLabel(d));

    D3.select(this.xAxis.nativeElement)
      .attr('class', 'x-axis')
      .attr('transform', this.projectReportsService.axisData.xAxisTransform)
      .call(xAxis);

    D3.select(this.xAxisLabel.nativeElement)
      .attr('transform', this.projectReportsService.axisData.xAxisLabelTransform)
      .text(TranslationService.translate('date_by_unit', {unit: TranslationService.translate(this.activeChartInterval)})); // + ' (' + '(by ${this.activeChartInterval})`);
  }

  setupChartData(data) {
    const rangeData: ICostReportChartData[] = [];
    this.projectReportsService.chartLegendItems.forEach(item => item.value = 0);
    const lineGenerators = {
      equipmentCostLine: D3.line().x((d) => this.xScaleTime(d.dayStart)).y((d) => this.yScale(d.equipmentCost)),
      laborCostLine: D3.line().x((d) => this.xScaleTime(d.dayStart)).y((d) => this.yScale(d.laborCost)),
      materialCostLine: D3.line().x((d) => this.xScaleTime(d.dayStart)).y((d) => this.yScale(d.materialCost)),
      totalCostLine: D3.line().x((d) => this.xScaleTime(d.dayStart)).y((d) => this.yScale(d.totalCost)),
    };

    data.forEach((item, index) => {
      const drawCircle = (item.dayStart >= this.chartStart) && (item.dayStart <= this.chartEnd);
      if (drawCircle) {
        this.updateLegendValues(item);
        const point1 = item;
        const point2 = data[index + 1] ? data[index + 1] : null;
        const drawLine = point2 && (point1.dayStart >= this.chartStart) && (point2.dayStart <= this.chartEnd) ; // don't draw next line that extends off the chart
        if (drawLine) {
          item.equipmentCostLine = lineGenerators.equipmentCostLine([point1, point2]);
          item.laborCostLine = lineGenerators.laborCostLine([point1, point2]);
          item.materialCostLine = lineGenerators.materialCostLine([point1, point2]);
          item.totalCostLine = lineGenerators.totalCostLine([point1, point2]);
        }

        rangeData.push(item);
      }
    });

    this.noData = rangeData.length < 1;
    this.drawLinesAndCircles(rangeData);
  }

  updateLegendValues(item: ICostReportData): void {
    this.projectReportsService.chartLegendItems.forEach(legendItem => {
      if (legendItem.key === CostReportValueTypes.Equipment) legendItem.value = legendItem.value += item.equipmentCost;
      if (legendItem.key === CostReportValueTypes.Labor) legendItem.value = legendItem.value += item.laborCost;
      if (legendItem.key === CostReportValueTypes.Material) legendItem.value = legendItem.value += item.materialCost;
      if (legendItem.key === CostReportValueTypes.Total) legendItem.value = legendItem.value += item.totalCost;
    });

    this.projectReportsService.chartLegendItems.map(legnedItem => legnedItem.displayValue = Utils.formatCurrency(legnedItem.value, this.projectService.currencyCode));
  }

  drawLinesAndCircles(scheduleCostData: any) {
    const graphContainer = D3.select(this.graphContainer.nativeElement)
      .selectAll('path, circle')
      .remove()
      .exit()
      .data(scheduleCostData);

    const addLine = (lineKey, color) => graphContainer
      .enter()
      .append('path')
      .attr('class', 'chart-line')
      .attr('d', (d) => d[lineKey])
      .attr('stroke', color);
    addLine('equipmentCostLine', CostColor.Equipment);
    addLine('laborCostLine', CostColor.Labor);
    addLine('materialCostLine', CostColor.Material);
    addLine('totalCostLine', GritColors.dkGray);

    const addCircle = (lineKey, color) => {
      graphContainer
        .enter()
        .append('circle')
        .attr('class', 'chart-circle')
        .attr('fill', color)
        .attr('r', '3px')
        .attr('cx', (d) => this.xScaleTime(d.dayStart))
        .attr('cy', (d) => this.yScale(d[lineKey]))
        .on('mouseover', (d) => this.handlePointHover(d, 'mouseover', lineKey))
        .on('mouseout', (d) => this.handlePointHover(d, 'mouseout', lineKey));
    };
    addCircle('equipmentCost', CostColor.Equipment);
    addCircle('laborCost', CostColor.Labor);
    addCircle('materialCost', CostColor.Material);
    addCircle('totalCost', GritColors.dkGray);
  }

  handlePointHover(pointData: any, action: string, dataKey: string): void {
    const chartDimensions = this.chartSvgContainer.nativeElement.getBoundingClientRect();
    const distFromRight = Math.abs(D3.event.pageX - chartDimensions.right);
    const distFromBottom = chartDimensions.bottom - D3.event.pageY;
    if (action === 'mouseover') {
      const ttBuffer = 10;
      const ttHeight = dataKey !== 'totalCost' ? 75 : 140;
      const ttWidth = 230;
      const adjustFromRight = distFromRight < (ttWidth + ttBuffer) ? true : false;
      const adjustFromBottom = distFromBottom < (ttHeight + ttBuffer) ? true : false;
      const ttPosition = {
        top: adjustFromBottom ? ((D3.event.pageY + ttBuffer) - ttHeight) : D3.event.pageY + ttBuffer,
        left: adjustFromRight ? ((D3.event.pageX - ttBuffer) - ttWidth) : D3.event.pageX + ttBuffer
      };

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left + 'px')
        .style('top', ttPosition.top + 'px')
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(pointData, dataKey));

    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  drawTooltip(data: any, dataKey: string) {
    let tooltip = ``;
    if (data.dayStart) tooltip += this.getTTlabel(data.dayStart);
    // TODO - do this check differently
    if (dataKey !== 'totalCost' && data[dataKey]) {
      // tslint:disable-next-line:max-line-length
      tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('equipment_cost') + `: </span><span class='value'>` + Utils.formatCurrency(data[dataKey], this.projectService.currencyCode) + `</span></div>`;
    } else {
      // tslint:disable-next-line:max-line-length
      if (data.equipmentCost) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('equipment_cost') + `: </span><span class='value'>` + Utils.formatCurrency(data.equipmentCost, this.projectService.currencyCode) + `</span></div>`;
      // tslint:disable-next-line:max-line-length
      if (data.laborCost) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('labor_cost') + `: </span><span class='value'>` + Utils.formatCurrency(data.laborCost, this.projectService.currencyCode) + `</span></div>`;
      // tslint:disable-next-line:max-line-length
      if (data.materialCost) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('material_cost') + `: </span><span class='value'>` + Utils.formatCurrency(data.materialCost, this.projectService.currencyCode) + `</span></div>`;
      // tslint:disable-next-line:max-line-length
      if (data.totalCost) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('total_cost') + `: </span><span class='value'>` + Utils.formatCurrency(data.totalCost, this.projectService.currencyCode) + `</span></div>`;
    }
    return tooltip;
  }

  getTTlabel(day: number): string {
    switch (this.activeDisplayMode) {
      case ViewMode.ThreeMonthGantt :
        // tslint:disable-next-line:max-line-length
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('week_of') + `: </span><span class='value'>${moment.utc(day).clone().format('MM/DD/YY')}</span></div>`;
      case ViewMode.SixMonthGantt :
      case ViewMode.TwelveMonthGantt :
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('month_of') + `: </span><span class='value'>${moment.utc(day).clone().format('MMM.')}</span></div>`;
      default :
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('date') + `: </span><span class='value'>${moment.utc(day).clone().format('MM/DD/YY')}</span></div>`;
    }
  }

  drawColumns(data: any): void {
    const colWidth = this.xScaleTime(this.chartStart) + this.xScaleTime(moment.utc(this.chartStart).clone().add(1, 'month').valueOf()); // sets cell width to the scaled width of a day
    const colContainer = D3.select(this.columnContainer.nativeElement)
      .selectAll('rect')
      .remove()
      .exit()
      .data(data);

    if (this.activeChartInterval === ChartIntervalState.Month) {
      colContainer
        .enter()
        .append('rect')
        .attr('class', 'column')
        .attr('transform', this.projectReportsService.graphDimensions.transform)
        .attr('height', this.projectReportsService.graphDimensions.height)
        .attr('width', colWidth)
        .attr('fill', 'none')
        .attr('x', (d) => this.xScaleTime(d.dayStart))
        .on('click', (d) => this.handleColumnClick(d));
    }
  }

  handleColumnClick(data: any): void {
    this.setChartDisplayMode(ViewMode.OneMonthGantt);
    this.formattedData = this.projectReportsService.formatCostReportDataForChart(this.activeDisplayMode, this.scheduleCostData);
    this.setXaxisData(data.dayStart);
    this.setupChartControls();
    this.drawChart();
  }

  handleResetCtrl(): void {
    this.chartStart = this.formattedData[0].dayStart;
    this.setXaxisData(this.chartStart);
    this.setupChartControls();
    this.drawChart();
  }
  handleTodayCtrl(): void {
    this.chartStart = moment.utc(Utils.getCurrentDate()).startOf('month').valueOf();
    this.setXaxisData(this.chartStart);
    this.setupChartControls();
    this.drawChart();
  }
  handleRangeCtrl(direction: string): void {
    if (direction === 'next') {
      this.chartStart = moment.utc(this.chartStart).clone().add(this.visibleMonths, 'month').valueOf();
      this.setXaxisData(this.chartStart);
      this.setupChartControls();
      this.drawChart();
    } else {
      this.chartStart = moment.utc(this.chartStart).clone().subtract(this.visibleMonths, 'month').valueOf();
      this.setXaxisData(this.chartStart);
      this.setupChartControls();
      this.drawChart();
    }
  }
  handleStartFinishCtrl(isStart: boolean): void {
    if (isStart) {
      this.chartStart = this.formattedData[0].dayStart;
      this.setXaxisData(this.chartStart);
      this.setupChartControls();
      this.drawChart();
    } else {
      this.chartEnd = this.scheduleCostData[this.scheduleCostData.length - 1].dayStart;
      this.chartStart = moment.utc(this.chartEnd).clone().subtract(this.visibleMonths, 'month').valueOf();
      this.setXaxisData(this.chartStart);
      this.drawChart();
    }
  }

  switchViewClick(viewMode: ViewMode): void {
    this.setChartDisplayMode(viewMode);
    this.formattedData = this.projectReportsService.formatCostReportDataForChart(viewMode, this.scheduleCostData);
    this.setXaxisData(this.chartStart);
    this.setupChartControls();
    this.drawChart();
  }

  setChartDisplayMode(viewMode: ViewMode): void {
    this.projectReportsService.chartDisplayModes.forEach(mode => {
      if (viewMode === mode.mode) {
        mode.selected = true;
        this.activeDisplayMode = mode.mode;
      } else {
        mode.selected = false;
      }
    });
  }

  resize(): void {
    this.setXaxisData();
    this.drawChart();
  }

  refresh(): void {
    window.location.reload(true);
  }
}
