import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';

import { IProjectSchedCriteriaData, ProjectPageCriteriaService, RouteKeysEnum } from '../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectService } from '../services/project/project.service';
import { IUser, IUserPermission } from '../models/user/user.interface';
import { UserService } from '../services/user/user.service';

@Injectable()
export class CriteriaGuard {
    userPermission: any;

    constructor(
        private userService: UserService,
        private projectService: ProjectService,
        private projectPageCriteriaService: ProjectPageCriteriaService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const projectId = state.root.firstChild.params.projectId;
        const routeKey = this.projectPageCriteriaService.getRouteKeyFromUrl(state.url);

        return new Promise((resolve, reject) => {
            const getProjectStatus = this.projectService.getProjectStatus(projectId);
            const getUserOrPermissions = ProjectService.userPermission ? of(ProjectService.userPermission) : this.userService.getAuthenticatedUser();
            const observablesArray: Array<Observable<any>> = [getProjectStatus, getUserOrPermissions];

            forkJoin(observablesArray).subscribe(
                res => {
                    const projectStatus = res[0];
                    this.userPermission = ProjectService.userPermission ? ProjectService.userPermission : this.getUserPermissions(res[1], projectId);
                    const criteriaData: IProjectSchedCriteriaData = {
                        pageAction: this.projectPageCriteriaService.getCriteriaPageAction(routeKey),
                        list: this.projectPageCriteriaService.getCriteriaList(route.data.criteria, projectId, projectStatus, this.userPermission)
                    };
                    this.projectPageCriteriaService.pageCriteriaPassed = criteriaData.list.length > 0 ? !criteriaData.list.some(item => !item.completed) : true;
                    this.projectPageCriteriaService.criteriaData = criteriaData;

                    if (!this.projectPageCriteriaService.pageCriteriaPassed) {
                        this.router.navigateByUrl('/project/' + projectId + '/criteria');
                        resolve(false);
                    } else {
                        resolve(true);
                    }

                    this.projectPageCriteriaService.setCriteriaError(false);
                },
                err => {
                    this.projectPageCriteriaService.setCriteriaError(true);
                    this.router.navigateByUrl('/home');
                    resolve(false);
                }
            );
        });
    }

    getUserPermissions(user: IUser, projectId: string): IUserPermission {
        const userProject = user.projects.find(project => project.id === projectId);
        const isGc = !userProject.subContractorId || userProject.subContractorId.length === 0;
        const userPermission: IUserPermission = {
            admin: userProject.permission === 1,
            edit: userProject.permission <= 2,
            gc: isGc
        };
        if (!isGc) userPermission.subContractorId = userProject.subContractorId;

        return userPermission;
    }
}
