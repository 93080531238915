export interface IProjectEquipment {
    id?: string;
    resourceId?: string;
    projectId?: string;
    name: string;
    unitOfMeasure?: UnitOfMeasure;
    cost?: number;
    subId?: string;
    startDate?: number;
    quantityAssigned?: String;
    budgetQuantity?: String;
    budget?: String;
    hours?: number;
    plannedQuantity?: number;
    resourceRate?: number;
    percentageLoss?: number;
    importedFromCsv?: boolean;
}

export interface IUnitOfMeasureOption {
    value: UnitOfMeasure;
    display: string;
}

export enum UnitOfMeasure {
    EquipmentHour = 'equipmentHour',
    EquipmentDay = 'equipmentDay'
}
