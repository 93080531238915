import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { Utils } from '../../utils/utils';

import { ChartService } from '../../services/chart/chart.service';

import * as D3 from 'd3';
import * as moment from 'moment';

@Component({
  selector: 'app-project-dashboard-sprint-resources',
  templateUrl: './project-dashboard-sprint-resources.component.html',
  styleUrls: ['./project-dashboard-sprint-resources.component.scss']
})
export class ProjectDashboardSprintResourcesComponent implements OnInit, OnChanges {
  @Input() chartDataInput: any;

  @ViewChild('chartContainer') chartContainer;
  @ViewChild('chartLinesContainer') chartLinesContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('yAxis') yAxis;

  loadingMessage: string = 'Loading...';
  isLoading: boolean = true;
  noDataMessage: string = 'No sprint resources data.';
  noData: boolean = true;

  chartHeight: number = 300;
  chartWidth: number;
  chartOffset: number = 50;
  chartTransform: string;
  xAxisTransform: string;
  allXaxisValues: number[];
  allYaxisValues: number[];
  chartLegendItems: any[];
  xScaleTime: D3.ScaleTime<number, number> = null;
  yScale: D3.ScaleLinear<number, number> = null;

  constructor(private _chartService: ChartService) {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (this.chartDataInput) {
      this.setupData();

      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  setupData() {
    const sprintDataAvailable = !Utils.isEmpty(this.chartDataInput)
      && this.chartDataInput.startDate
      && !Utils.isEmpty(this.chartDataInput.stepsByDay);

    if (sprintDataAvailable) {
        this.setupChart();

        this.noData = false;
    } else {
        this.noData = true;
    }
  }

  /**
   * setupChart - call methods in order to build D3 chart
   */
  async setupChart() {
    this.createChartLegend();
    await this.createScalesAndDimensions();
    await this.setupAxes();
    await this.setupLineChartData();
  }

  createChartLegend() {
    this.chartLegendItems = !Utils.isEmpty(this.chartDataInput.legendItems)
      ? this.chartDataInput.legendItems
      : null;
  }

  /**
   * createScalesAndDimensions - sets D3 scale and chart dimensions
   */
  async createScalesAndDimensions() {
    const allCrewSizes = this.chartDataInput.yAxisValues;
    const maxCrewSize = Math.max(...allCrewSizes);
    this.allYaxisValues = this.getAllYAxisNumbers(maxCrewSize);

    const sprintStartDate = this.chartDataInput.startDate;
    const lastSprintDate = this.chartDataInput.endDate;
    const graphStartDate = moment.utc(sprintStartDate).clone().subtract(1, 'day').valueOf(); // add day to start of graph to give x-axis buffer
    const graphEndDate = moment.utc(lastSprintDate).clone().add(1, 'day').valueOf(); // add day to end of graph to give x-axis buffer
    this.allXaxisValues = this.chartDataInput.xAxisValues;

    // // Dimensions
    const chartMinWidth = document.getElementById('sr-wrapper').offsetWidth;
    const xAxisWidth = this.allXaxisValues.length * 100;
    this.chartWidth = xAxisWidth < chartMinWidth ? chartMinWidth : xAxisWidth;
    this.chartTransform = `translate(${this.chartOffset}, ${this.chartOffset})`;
    this.xAxisTransform = `translate(0, ${this.chartHeight})`;

    // Scales
    if (sprintStartDate && lastSprintDate) {
      this.xScaleTime = D3.scaleTime()
        .range([0, this.chartWidth])
        .domain([graphStartDate, graphEndDate]);
    }

    if (maxCrewSize) {
      this.yScale = D3.scaleLinear()
        .range([this.chartHeight, 0])
        .domain([0, maxCrewSize]);
    }
  }

  getAllYAxisNumbers(maxValue: number): number[] {
    const allYAxisValues = [];

    for (let i = 1; i <= maxValue; i++) {
      allYAxisValues.push(i);
    }

    return allYAxisValues;
  }

  async setupAxes() {
    await this.drawXaxis(this.allXaxisValues);
    await this.drawYaxis(this.allYaxisValues);
  }

  async drawYaxis(yAxisValues: number[]) {
    const yAxis = D3.axisLeft()
      .scale(this.yScale)
      .tickSize(5)
      .ticks(this.allYaxisValues.length);

    if (!Utils.isEmpty(yAxisValues)) {
      D3.select(this.yAxis.nativeElement)
        .attr('class', 'y-axis')
        .call(yAxis);
    }
  }

  async drawXaxis(xAxisValues: number[]) {
    const xAxis = D3.axisBottom()
      .scale(this.xScaleTime)
      .tickFormat(D3.timeFormat('%m/%d'))
      .tickSize(5)
      .tickPadding(10)
      .ticks(this.allXaxisValues.length + 1);

    if (!Utils.isEmpty(xAxisValues)) {
      D3.select(this.xAxis.nativeElement)
        .attr('class', 'x-axis')
        .call(xAxis);
    }
  }

  /**
   * setupLineChartData - sorts steps by subcontractor, adds D3 Line data to steps and passes data to drawLinesAndCircles()
   **/
  async setupLineChartData() {
    const getLine = D3.line()
      .x((d) => this.xScaleTime(d.date))
      .y((d) => this.yScale(d.crewSize));

    D3.select(this.chartLinesContainer.nativeElement)
      .selectAll('circle, path')
      .remove()
      .exit();

    this.chartDataInput.stepsByDay.forEach(daySteps => {
      Object.keys(daySteps).forEach(key => {

        if (daySteps[key].d3linePoints) {
          const linePoints = daySteps[key].d3linePoints;
          daySteps[key]['d3Line'] = getLine(linePoints);
        }

        if (!Utils.isEmpty(daySteps[key])) {
          this.drawLinesAndCircles(daySteps[key]);
        }
      });
    });
  }

  async drawLinesAndCircles(steps) {
    const lineContainer = D3.select(this.chartLinesContainer.nativeElement)
      .selectAll('g')
      .data([steps])
      .enter();

    // lineContainer
    //   .append('path')
    //   .attr('class', 'chart-line')
    //   .attr('d', (d) => d.d3Line)
    //   .attr('stroke', (d) => d.color)
    //   .attr('stroke-width', 1.5);

    lineContainer
      .append('circle')
      .attr('class', 'chart-circle')
      .attr('r', '3px')
      .attr('fill', (d) => d.color)
      .attr('cx', (d) => this.xScaleTime(d.date))
      .attr('cy', (d) => this.yScale(d.crewSize));
  }
}
