import { Injectable } from '@angular/core';
import { Utils } from '../../utils/utils';

import en from '../../../assets/translation/en.js';
import es from '../../../assets/translation/es.js';

let currentLanguage = 'en';
let keys = en;

@Injectable()
export class TranslationService {

    static translate(key: string, variables?: object): string {
        let result = keys[key];

        if (result && variables) {
            for (const item of Object.keys(variables)) {
                result = Utils.replaceAll(result, '{{' + item + '}}', variables[item]);
            }
        }

        if (result) return result;
        return key;
    }

    static getLanguage(): string {
        return currentLanguage;
    }

    initalize(): void {
        const language = localStorage.getItem('language');
        if (Utils.isEmpty(language)) this.setLanguage(currentLanguage);
        this.setLanguage(language);
    }

    setLanguage(language: string): void {
        if (language === 'es') {
            keys = es;
            currentLanguage = 'es';
            localStorage.setItem('language', 'es');
        } else {
            keys = en;
            currentLanguage = 'en';
            localStorage.setItem('language', 'en');
        }
    }
}
