import { Injectable } from '@angular/core';
import { isDevMode } from '@angular/core';

@Injectable()
export class SegmentService {

    static track(action: string, properties: object): void {
        try {
            const trackSegment = !isDevMode();
            if (trackSegment) window['analytics'].track(action, properties);
        } catch (e) {
            console.error(e);
        }
    }

    static hideLauncher() {
        window['analytics'].page({}, {Intercom: {hideDefaultLauncher: true}});
    }

    identify(userId: string, properties: object): void {
        try {
            const trackSegment = !isDevMode();
            if (trackSegment) window['analytics'].identify(userId, properties);
        } catch (e) {
            console.error(e);
        }
    }

    track(action: string, properties: object): void {
        try {
            const trackSegment = !isDevMode();
            if (trackSegment) window['analytics'].track(action, properties);
        } catch (e) {
            console.error(e);
        }
    }
}
