import { Injectable } from '@angular/core';

import { IColumnHeader, ICustomTable, IRow, IRowItem, ITableSearchOptions } from '../../../models/custom-table/custom-table.interface';
import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { ISprintStoryResult } from '../../../models/project/project-sprint/project-sprint.interface';
import { IUserPermission } from '../../../models/user/user.interface';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';

@Injectable()
export class SprintSettingsService {
  constructor(
    private httpService: HttpBackendService,
    private notificationService: NotificationService
    ) {/*EMPTY*/}

  public async getSprintReasonsLibrary(projectId: string): Promise<ISprintStoryResult[]> {
    let list: ISprintStoryResult[] = null;
    await this.httpService.get('/project/' + projectId + '/sprintResultLibrary').toPromise().then(
      res => {
        list = res;
      }
    ).catch(
      err => {
        this.notificationService.error(err, {});
      }
    );
    return list;
  }

  public async editSprintStoryReason(sprintStoryId: string, newEdit: ISprintStoryResult): Promise<boolean> {
    let updated = false;
    await this.httpService.put('/project/sprintStoryResult/' + sprintStoryId, newEdit).toPromise().then(
      () => {
        updated = true;
      }
    ).catch(
      err => {
        this.notificationService.error(err, {});
      }
    );
    return updated;
  }

  public async addSprintStoryReason(projectId: string, ssr: ISprintStoryResult): Promise<ISprintStoryResult> {
    let added = null;
    await this.httpService.post('/project/' + projectId + '/addSprintStoryResult', ssr).toPromise().then(
      res => {
        added = res;
      }
    ).catch(
      err => {
        this.notificationService.error(err, {});
      }
    );
    return added;
  }

  public async deleteSprintStoryReasons(projectId: string, ids: string[]): Promise<boolean> {
    let deleted = false;
    await this.httpService.post('/project/' + projectId + '/deleteSSRs', ids).toPromise().then(
      () => {
        deleted = true;
      }
    ).catch(
      err => {
        this.notificationService.error(err, {});
      }
    );
    return deleted;
  }

  transformToTableData(dataInput: any[], permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(equipment => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: equipment.name,
          editable: permission.edit
        }
      ];
      rows.push(
        {
          key: equipment.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: permission.edit,
        inline: true,
        addModel: [
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'name',
            required: true,
            editable: true,
          }
        ]
      }
    };

    return retTable;
  }

  // Transform data for table
  transformTableData(
      allTableData: any[],
      selectedIds: string[],
      searchQuery?: string,
      paginationStart?: number,
      activeSearchOptions?: string[]
    ): ICustomTable {
      const overallCount = allTableData.length;
      const pageSize = 20;
      const pageStart = paginationStart ? paginationStart : 0;

      const transformedTableData: ICustomTable = {
        title: 'Reasons Not Complete',
        filterable: false,
        searchable: true,
        columnHeaders: [],
        rows: [],
        multiSelect: true,
        canCreate: true,
        multiSearch: false,
        searchOptions: [],
        bulkEdit: false,
        searchQuery: searchQuery,
        pagination: {
          count: overallCount,
          pageSize: pageSize,
          pageStart: pageStart,
          totalItemsSelected: 0,
          display: true,
        },
        showIntro: true,
        bulkDelete: true,
        createRowModel: [],
        filterByOptions: [],
        allRowsSelected: overallCount === selectedIds.length ? true : false
      };

      // Column Headers
      const columnHeaders: IColumnHeader[] = [
        {
          title: 'name',
          key: 'name',
          sortable: true,
          active: true,
          searchable: true
        }
      ];
      transformedTableData.columnHeaders = columnHeaders;

      // Create item row
      const createRowModel = [
        {
          name: 'name',
          value: '',
          prevStateValue: '',
          editable: true,
          type: 'input'
        }
      ];
      transformedTableData.createRowModel = createRowModel;

      // Define search options for multi-search
      const searchOptions: ITableSearchOptions[] = [];
      transformedTableData.columnHeaders.filter(colHead => {
        if (colHead.searchable === true) {
          searchOptions.push({
            title: colHead.title,
            active: activeSearchOptions && activeSearchOptions.includes(colHead.title) ? true : false
          });
        }
      });
      transformedTableData.searchOptions = searchOptions;

      let totalOtherItemsSelected = selectedIds.length;
      // Table Rows
      const tableRows: IRow[] = [];
      let active = false;
      for (let r = pageStart; r < pageStart + pageSize; r++) {
        if (r < allTableData.length) {
          active = false;
          const rowItems: IRowItem[] = [
            {
              value: allTableData[r].name,
              prevStateValue: allTableData[r].name,
              editable: true,
              type: 'input',
              actionOutput: allTableData[r].id,
              name: 'name'
            }
          ];

          if (selectedIds.includes(allTableData[r].id)) {
            active = true;
            totalOtherItemsSelected--;
          }

          tableRows.push({
            selectable: true,
            editable: true,
            removeable: true,
            active: active,
            rowItems: rowItems,
            key: allTableData[r].id,
            isEditing: false,
            infoIcon: false
          });
        }
      }

      transformedTableData.rows = tableRows;
      transformedTableData.pagination.totalItemsSelected = totalOtherItemsSelected;

      return transformedTableData;
    }
}
