import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IImageModalInput } from '../../models/image-modal/image-modal.interface';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})

export class ImageModalComponent implements OnInit {

    @Input() imgSrc;
    @Input() showImage;
    @Input() userName;
    @Input() time;
    @Input() message;
    @Output() imageClosedEvent = new EventEmitter<any>();

    // tslint:disable-next-line:no-empty
    constructor() {}

    // tslint:disable-next-line:no-empty
    ngOnInit() {}

    onImageClick() {
        this.showImage = true;
    }

    onImageClose() {
        this.imageClosedEvent.emit();
        this.showImage = false;
    }
}
