import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ISelectionModal } from '../../../models/selection-modal/selection-modal.interface';

@Injectable()
export class ProjectPlannerPopoutModalService {

  // Activity switches
  showActivityModal = new BehaviorSubject<boolean>(false);
  selectedActivity = new BehaviorSubject<string>('');
  activitySelection = new BehaviorSubject<any>({});
  activeFilter = new BehaviorSubject<string[]>([]);

  constructor() { /*empty*/ }

  reset() {
    this.selectedActivity.next('');
    this.activitySelection.next({});
    this.showActivityModal.next(false);
    this.activeFilter.next([]);
  }

  setSelectedActivity(activity: string): void {
    return this.selectedActivity.next(activity);
  }

  setShowActivityModal(showModal: boolean): void {
    return this.showActivityModal.next(showModal);
  }

  setActivitySelection(activitySelection: ISelectionModal) {
    return this.activitySelection.next(activitySelection);
  }
}
