import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ProjectEquipmentService } from '../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { ProjectNetworkMapService } from '../../services/project/project-network-map/project-network-map.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectStepService } from '../../services/project/project-step/project-step.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';

import { IAccordion } from '../../models/accodordion/accordion.interface';
import { IProjectEquipment } from '../../models/project/project-equipment/project-equipment.interface';
import { IProjectMaterial } from '../../models/project/project-material/project-material.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { IProject } from '../../models/project/project.interface';

import { forkJoin, Subscription } from 'rxjs';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-sidebar-procurement',
  templateUrl: './project-sidebar-procurement.component.html',
  styleUrls: ['./project-sidebar-procurement.component.scss']
})
export class ProjectSidebarProcurementComponent implements OnChanges, OnInit {
  @Input() stepIds;

  curObjIdPos: number = 0;
  curObjName: string = '';
  curObjId: string = '';
  accordionData: IAccordion[] = [];
  showArrows: boolean = false;
  errorGettingData: boolean = false;
  noData: boolean = true;
  loadingMessage: string = 'loading';
  errorMessage: string = 'error';
  noDataMessage: string = 'select_task';
  openCategories: string[];
  oldArrayMethod: boolean = true;

  projectObjectSubscription: Subscription;

  objectKeys = Object.keys; // used for ngFor loop on object in template

  currency: string;
  currencyFormat: string;
  isMetric: boolean;
  project: IProject;

  dataLoading = true;

  equipment: IProjectEquipment[] = [];
  materials: IProjectMaterial[] = [];
  subContractors: IProjectSubContractor[] = [];

  initalized = false;

  constructor(
    private projectService: ProjectService,
    private networkMapService: ProjectNetworkMapService,
    private projectMaterialService: ProjectMaterialService,
    private projectEquipmentService: ProjectEquipmentService,
    private projectSubContractorService: ProjectSubContractorService,
    private projectObjectService: ProjectObjectService,
    private projectStepService: ProjectStepService
  ) {/*EMPTY*/ }

  ngOnInit() {
    this.project = this.projectService.currentProject;
    this.currency = this.projectService.currentProject.currency;
    this.isMetric = this.projectService.currentProject.isMetric;

    const observableArray = [];
    observableArray.push(this.projectEquipmentService.getList(this.project.id));
    observableArray.push(this.projectMaterialService.getList(this.project.id));
    observableArray.push(this.projectSubContractorService.getList(this.project.id));

    forkJoin(observableArray).subscribe(
      results => {
        this.equipment = results[0];
        this.materials = results[1];
        this.subContractors = results[2];
        this.loadSteps();
      }
    );
  }

  ngOnChanges() {
    if (this.initalized) this.loadSteps();
  }

  loadSteps() {
    if (Utils.isEmptyList(this.stepIds)) {
      this.noData = true;
      this.accordionData = [];
      return;
    }
    this.noData = false;
    this.accordionData = [];

    const planStepObservables = [];
    for (const planStep of this.stepIds) {
      planStepObservables.push(this.networkMapService.getPlanStep(planStep));
    }

    this.dataLoading = true;

    forkJoin(planStepObservables).subscribe(async results => {

      const totals = {
        tasks: [],
        cost: {},
        equipment: {},
        labor: {},
        material: {}
      };

      for (const planStep of results) {

        const step = await this.projectStepService.getProjectStep(planStep.projectStepId).toPromise();

        // tslint:disable-next-line:cyclomatic-complexity

        if (!step) return;

        const calculatedMeasures = this.projectObjectService.calculateUnitOfMeasures(planStep.objectIds);

        totals.tasks.push(step.name);

        // Cost
        if (planStep.equipmentCost) {
          if (!totals.cost['equipment']) {
            totals.cost['equipment'] = planStep.equipmentCost;
          } else {
            totals.cost['equipment'] += planStep.equipmentCost;
          }
        }

        if (planStep.laborCost) {
          if (!totals.cost['labor']) {
            totals.cost['labor'] = planStep.laborCost;
          } else {
            totals.cost['labor'] += planStep.laborCost;
          }
        }

        if (planStep.materialCost) {
          if (!totals.cost['material']) {
            totals.cost['material'] = planStep.materialCost;
          } else {
            totals.cost['material'] += planStep.materialCost;
          }
        }

        // Equipment
        if (step.equipmentIds) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < step.equipmentIds.length; i++) {
            if (!totals.equipment[step.equipmentIds[i]]) {
              // TODO: add equipment count required
              totals.equipment[step.equipmentIds[i]] = 1;
            } else {
              totals.equipment[step.equipmentIds[i]] += 1;
            }
          }
        }

        // Labor
        if (planStep.subContractorId) {
          if (!totals.labor[planStep.subContractorId]) {
            totals.labor[planStep.subContractorId] = planStep.crewSize;
          } else {
            if (totals.labor[planStep.subContractorId] < planStep.crewSize) totals.labor[planStep.subContractorId] = planStep.crewSize;
          }
        }

        // Materials
        if (step.materialIds) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < step.materialIds.length; i++) {
            if (!totals.material[step.materialIds[i]]) {
              // tslint:disable-next-line:max-line-length
              totals.material[step.materialIds[i]] = { 'volume': calculatedMeasures.volume, 'surfaceArea': calculatedMeasures.surfaceArea, 'length': calculatedMeasures.length, 'each': calculatedMeasures.each };
            } else {
              totals.material[step.materialIds[i]].size += step.materialSizes[i];
            }
          }
        }
      }

      const ai: IAccordion = {
        label: 'task_list',
        active: true,
        info: {}
      };
      const tasks = totals.tasks.sort();
      for (const task of tasks) {
        ai.info[task] = null;
      }
      this.accordionData.push(ai);

      if (Object.keys(totals.cost).length > 0) {
        const accordionItem: IAccordion = {
          label: 'budget',
          active: true,
          info: {}
        };
        if (totals.cost['equipment']) accordionItem.info['equipment'] = Utils.formatCurrency(totals.cost['equipment'], this.currency);
        if (totals.cost['labor']) accordionItem.info['labor'] = Utils.formatCurrency(totals.cost['labor'], this.currency);
        if (totals.cost['material']) accordionItem.info['material'] = Utils.formatCurrency(totals.cost['material'], this.currency);
        this.accordionData.push(accordionItem);
      }

      if (Object.keys(totals.equipment).length > 0) {
        const accordionItem: IAccordion = {
          label: 'equipment',
          active: true,
          info: {}
        };
        for (const key of Object.keys(totals.equipment)) {
          const eq = this.equipment.find(e => e.id === key);
          if (!eq) continue;
          accordionItem.info[eq.name] = null;
        }
        this.accordionData.push(accordionItem);
      }

      if (Object.keys(totals.labor).length > 0) {
        const accordionItem: IAccordion = {
          label: 'labor',
          active: true,
          info: {}
        };
        for (const key of Object.keys(totals.labor)) {
          const sc = this.subContractors.find(s => s.id === key);
          if (!sc) continue;
          accordionItem.info[sc.name] = totals.labor[key];
        }
        this.accordionData.push(accordionItem);
      }

      if (Object.keys(totals.material).length > 0) {
        const accordionItem: IAccordion = {
          label: 'materials',
          active: true,
          info: {}
        };
        for (const key of Object.keys(totals.material)) {
          const mat = this.materials.find(m => m.id === key);
          if (!mat) continue;
          accordionItem.info[mat.name] = this.formatMaterial(mat, totals, key);
        }
        this.accordionData.push(accordionItem);
      }

      this.dataLoading = false;
      this.initalized = true;
    });
  }

  toggleAccordion(item: IAccordion): void {
    item.active = !item.active;
    if (item.active) {
      if (!this.openCategories.includes(item.label)) this.openCategories.push(item.label);
    } else {
      if (this.openCategories.includes(item.label)) this.openCategories.splice(this.openCategories.indexOf(item.label), 1);
    }
  }

  formatMaterial(mat, totals, key): string {
    const units = Number(totals.material[key][mat.unitOfMeasure]);
    if (units === 0) return null;
    let value = Utils.formatUnitOfMeasure(units, mat.unitOfMeasure, this.isMetric);
    if (mat.cost) {
      value += ' | ' + Utils.formatCurrency(mat.cost * Number(Utils.formatUnitOfMeasure(units, mat.unitOfMeasure, this.isMetric, true)), this.project.currency);
    }
    return value;
  }

}
