import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { Utils } from '../../utils/utils';

import { IDashboardGrid, IDashboardGridRow } from '../../models/project/project-dashboard/project-dashboard.interface';

@Component({
  selector: 'app-project-dashboard-percent-complete',
  templateUrl: './project-dashboard-project-complete.component.html',
  styleUrls: ['./project-dashboard-project-complete.component.scss']
})
export class ProjectDashboardProjectCompleteComponent implements OnInit, OnChanges {
  @Input() gridDataInput: IDashboardGridRow[];
  @Input() overallProjectCompletionTotals: any;

  loadingMessage: string = 'loading';
  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  noData: boolean = true;
  showSubTable: boolean = false;
  columnHeaders: any[] = [
    {label: 'subcontractor', sortKey: 'subcontractor', active: true, ascending: true, type: 'string'},
    {label: 'actual_hours_percent_complete', sortKey: 'actHrs', active: false, ascending: false, type: 'string'},
    {label: 'man_hours_percent_complete', sortKey: 'manHrs', active: false, ascending: false, type: 'string'}
  ];
  dashboardGridInput: IDashboardGrid;

  // tslint:disable-next-line:no-empty
  constructor() {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (this.gridDataInput) {
      this.setupData();
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  setupData(): void {
    if (!Utils.isEmpty(this.gridDataInput)) {
      this.setupGrid(this.gridDataInput);

      this.noData = false;
    } else {
      this.noData = true;
    }
  }

  setupGrid(gridRows: IDashboardGridRow[]): void {
    this.dashboardGridInput = {
      colHeads: this.columnHeaders,
      rows: gridRows
    };
  }

  handleSubTotalsClick(): void {
    this.showSubTable = !this.showSubTable;
  }
}
