import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectHeaderService {

  public isSecondaryHeader: boolean = false;

  // tslint:disable-next-line:no-empty
  constructor(
    private ngZone: NgZone
  ) {}

  async setSecondaryHeaderState(exists: boolean) {
    await Utils.sleep(0, this.ngZone);
    this.isSecondaryHeader = exists;
  }

  getSecondaryHeaderState(): boolean {
    return this.isSecondaryHeader;
  }
}
