import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IProjectEquipment } from '../../../models/project/project-equipment/project-equipment.interface';
import { IProjectMaterial } from '../../../models/project/project-material/project-material.interface';

import { ActivityService } from '../../../services/activity/activity.service';
import { IGritTable, IGRow, IGRowItem } from '../../../shared/grit-table/grit-table';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectService } from '../../../services/project/project.service';
import { Utils } from '../../../utils/utils';
@Component({
  selector: 'app-activity-equipment-and-materials',
  templateUrl: './activity-equipment-and-materials.component.html',
  styleUrls: ['./activity-equipment-and-materials.component.scss']
})
export class ActivityEquipmentAndMaterialsComponent implements OnInit {

  @Input() equipmentIds: string[] = [];
  @Input() materialIds: string[] = [];
  @Input() selectedActivityIds: Array<string>;
  @Output() updateOutput: EventEmitter<{ equipmentIds: string[] }> = new EventEmitter();
  @Output() equipmentOut: EventEmitter<boolean> = new EventEmitter();
  @Output() updateParameters:  EventEmitter<Array<any>> = new EventEmitter();

  allEquipment: IProjectEquipment[] = [];
  filteredEquipment: Observable<IProjectEquipment[]>;
  equipmentOptions: IProjectEquipment[];
  equipmentCtrl = new FormControl();
  equipmentChips: IProjectEquipment[];
  tableData: IGritTable;
  totalBudget: string;
  currency;
  autoAssignResources: boolean;

  get displayFn() { return Utils.defaultDisplayFn; }

  constructor(
    private equipmentService: ProjectEquipmentService,
    private projectService: ProjectService,
    private activityService: ActivityService
  ) {/*EMPTY*/ }

  ngOnInit() {
    this.allEquipment = ProjectService.userPermission.subContractorId ? this.equipmentService.getLocalUserProjectEquipment() : this.equipmentService.getLocalAllProjectEquipment();
    this.setValidOptions();
    this.setAutocomplete();
    if(this.selectedActivityIds.length === 1){
      this.activityService.getActivityEquipments(this.selectedActivityIds[0]).subscribe(res => {
        this.equipmentChips = res;
        this.setTableData();
      });
    } else {
      this.activityService.getActivitiesEquipments(this.selectedActivityIds).subscribe(res => {
        this.equipmentChips = res;
        this.setTableData();
      });
    }
    this.projectService.currentProject.autoAssignResources ? this.autoAssignResources = this.projectService.currentProject.autoAssignResources : this.autoAssignResources = false;
  }

  deleteRows(ids) {
    ids.forEach(id => {
        this.removeEquipment(id);
    });
  }

  editRows(event) {
    const equipmentId = event.key;
    let updatedEquipment: any = [];
    updatedEquipment = this.equipmentChips.find(e => e.id === equipmentId);
    if(updatedEquipment.isMannuallyAddedPlannedQty){
      updatedEquipment.equipPlannedQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'plannedQuantity').value;
    }
    
    updatedEquipment.plannedQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'plannedQuantity').value;
    updatedEquipment.actualQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'actualQuantity').value;
    this.updateParameters.emit(updatedEquipment);
    this.calculateTotalBudget();
  }

  calculateTotalBudget() {
    let totalBudget: any = 0.00;
    const data: any = this.equipmentChips;

    data.forEach((item) => {
      let selectedValue = 0;

      if (item.elementUnit.toString() === 'area') {
        selectedValue = item.areaSum ? item.areaSum : 0;
      } else if (item.elementUnit.toString() === 'volume') {
        selectedValue = item.volumeSum ? item.volumeSum : 0;
      } else if (item.elementUnit.toString() === 'length') {
        selectedValue = item.lengthSum ? item.lengthSum : 0;
      } else if (item.elementUnit.toString() === 'unit') {
        selectedValue = 1;
      }

      const plannedQuantity: any =  item.isMannuallyAddedPlannedQty ? parseFloat(item.equipPlannedQuantity) : selectedValue * item.resourceRate;
      const budgetAssigned: any = plannedQuantity * item.cost;
      totalBudget += parseFloat(budgetAssigned);
    });

    this.totalBudget = `$ ${totalBudget.toFixed(2)}`;
  }

  removeEquipment(equipmentId: string) {
    this.equipmentOut.emit(true);
    this.equipmentIds = this.equipmentIds.filter(id => id !== equipmentId);
    this.equipmentChips = this.equipmentChips.filter(item => item.id !== equipmentId);
    this.updateOutput.emit({ equipmentIds: this.equipmentIds });
    this.calculateTotalBudget();
    this.clearInput();
  }

  setTableData() {
    this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    this.autoAssignResources ?  this.tableData = this.equipmentService.transformToTableDataForActivityWithAutoAssign(this.equipmentChips, ProjectService.userPermission, this.currency) : this.tableData = this.equipmentService.transformToTableDataForActivity(this.equipmentChips, ProjectService.userPermission, this.currency);
    this.calculateTotalBudget();
  }

  equipSelected(matEquipment) {
    this.equipmentOut.emit(true);
    const equipment: IProjectEquipment = matEquipment.option.value;
    this.equipmentIds.push(equipment.id);
    this.equipmentChips.push(this.equipmentOptions.find(item => item.id === equipment.id));
    this.setTableData();
    this.updateOutput.emit({ equipmentIds: this.equipmentIds });
    this.clearInput();
  }

  setValidOptions(): void {
    this.equipmentOptions = this.allEquipment.filter(item => !this.equipmentIds.includes(item.id));
  }

  setAutocomplete(): void {
    this.filteredEquipment = this.equipmentCtrl.valueChanges.pipe(
      startWith<string | IProjectEquipment>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.equipmentSearch(name) : this.getAllEquipmentOptions()));
  }

  equipmentSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.equipmentOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllEquipmentOptions() {
    return this.equipmentOptions.slice(0, Math.min(this.equipmentOptions.length, 50));
  }

  clearInput(): void {
    this.equipmentCtrl.setValue('');
    this.equipmentCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }
}
