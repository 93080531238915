
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActivityService } from '../../services/activity/activity.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectStepService } from '../../services/project/project-step/project-step.service';

import { MessageType } from '../../utils/enums/message-type.enum';
import { INoficationContext } from '../../models/notification/notification.interface';

@Component({
  selector: 'app-project-message-sidebar',
  templateUrl: './project-message-sidebar.component.html',
  styleUrls: ['./project-message-sidebar.component.scss']
})
export class ProjectMessageSidebarComponent implements OnDestroy, OnChanges {

  @Input() projectStepIds: string[] = [];
  @Input() projectObjectIds: string[] = [];
  @Input() projectActivityIds: string[] = [];
  @Input() messageType: MessageType;
  selectedId: string;
  selectionInfoLoading: boolean;
  childComponentLoading: boolean = true;
  selectionName: string;
  noSelection: boolean = false;
  noSelectionMessage: string;

  currentSelectionIdIndex: number = 0;
  showArrows: boolean = false;

  // Subscriptions
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectStepService: ProjectStepService,
    private notificationService: NotificationService,
    private projectObjectService: ProjectObjectService,
    private activityService: ActivityService
  ) { }

  ngOnChanges() {
    let idArray;
    switch (this.messageType) {
      case MessageType.TASK:
        this.noSelectionMessage = 'select_task';
        idArray = this.projectStepIds;
        if (idArray && idArray.length > 0 && idArray[0] !== '') {
          this.selectedId = idArray[0];
          this.getProjectStepData();
        }
        break;
      case MessageType.OBJECT:
        this.noSelectionMessage = 'select_object';
        idArray = this.projectObjectIds;
        if (idArray && idArray.length > 0) {
          this.selectedId = idArray[0];
          this.getProjectObjectData();
        }
        break;
      case MessageType.ACTIVITY:
        this.noSelectionMessage = 'select_activity';
        idArray = this.projectActivityIds;
        if (idArray && idArray.length > 0) {
          this.selectedId = idArray[0];
          this.getProjectActivityData();
        }
        break;
    }
    if (idArray && idArray.length > 0 && idArray[0] !== '') {
      this.noSelection = false;
      this.showArrows = idArray.length > 1;
    } else {
      this.noSelection = true;
      this.selectedId = null;
    }
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getProjectStepData() {
    this.selectionInfoLoading = true;
    this.projectStepService.getProjectStep(this.selectedId).pipe(takeUntil(this.destroyed$)).subscribe(
      results => {
        this.selectionName = results['name'];
        this.selectionInfoLoading = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'project step',
          action: 'get'
        };
        this.notificationService.error(err, context);
        this.selectionInfoLoading = false;
      });
  }

  getProjectObjectData() {
    this.selectionInfoLoading = true;
    this.projectObjectService.getObject(this.selectedId).pipe(takeUntil(this.destroyed$)).subscribe(
      results => {
        this.selectionName = results['name'];
        this.selectionInfoLoading = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'project object',
          action: 'get'
        };
        this.notificationService.error(err, context);
        this.selectionInfoLoading = false;
      });
  }

  getProjectActivityData() {
    this.selectionName = this.activityService.getLocalActivity(this.selectedId)['name'];
  }

  nextStep(direction: number): void {
    let idsArray;
    switch (this.messageType) {
      case MessageType.TASK:
        idsArray = this.projectStepIds;
        this.getProjectStepData();
        break;
      case MessageType.OBJECT:
        idsArray = this.projectObjectIds;
        this.getProjectObjectData();
        break;
      case MessageType.ACTIVITY:
        idsArray = this.projectActivityIds;
        this.getProjectActivityData();
        break;
    }
    this.currentSelectionIdIndex += direction;
    if (this.currentSelectionIdIndex >= idsArray.length) {
      this.currentSelectionIdIndex = 0;
    } else if (this.currentSelectionIdIndex < 0) {
      this.currentSelectionIdIndex = idsArray.length - 1;
    }
    this.selectedId = idsArray[this.currentSelectionIdIndex];
    switch (this.messageType) {
      case MessageType.TASK:
        this.getProjectStepData();
        break;
      case MessageType.OBJECT:
        this.getProjectObjectData();
        break;
      case MessageType.ACTIVITY:
        this.getProjectActivityData();
        break;
    }
  }

  setChildComponentLoading(childLoading: boolean) {
    this.childComponentLoading = childLoading;
  }
}
