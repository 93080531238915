import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationVerificationService } from '../../services/authentication/authentication-verification.service';
import { SegmentService } from '../../services/segment/segment.service';

@Component({
  selector: 'app-users-reject',
  templateUrl: './users-reject.component.html',
  styleUrls: ['./users-reject.component.scss'],
  providers: [AuthenticationVerificationService]
})

export class UsersRejectComponent implements OnInit {

  result: string;

  constructor( private _authenticationVerificationService: AuthenticationVerificationService, private route: ActivatedRoute, private segmentService: SegmentService) { }

  ngOnInit() {
    this._authenticationVerificationService.rejectVerification(this.route.snapshot.params['userId'], this.route.snapshot.params['userVerificationId']).subscribe(
      success => {
        this.segmentService.track('Verification Rejected', {userId: this.route.snapshot.params['userId']});
        this.result = "Don't worry, we rejected that request.";
      },
      err => {
        this.result = "We couldn't find that request. But don't worry, it was probably already invalid.";
      });
  }
}
