import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { SegmentService } from '../../segment/segment.service';

import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';

import { IProjectRfi } from '../../../models/project/project-rfi/project-rfi.interface';

import { IUserPermission } from '../../../models/user/user.interface';

import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectRfiService {
  private allProjectRfis: IProjectRfi[] = [];
  private userAvailableRfis: IProjectRfi[] = [];

  constructor(
    private httpService: HttpBackendService,
    private notificationService: NotificationService,
    private formBuilderService: FormBuilder
  ) { }

  public getList(projectId: string, subId?: string, includeGC?: boolean): Observable<IProjectRfi[]> {
    let params = '';

    if (includeGC) {
      params += '?includeGC=true';
    }

    const req = `/project/${projectId}/rfi${params}`;
    return this.httpService.get(req);
  }

  public update(rfiId: string, json: any): Observable<any> {
    return this.httpService.put('/project/rfi/' + rfiId, json);
  }

  public delete(rfiIds: string[]): Observable<void> {
    return this.httpService.post('/project/rfi/delete', rfiIds);
  }

  public importFromProcore(projectId: string): Observable<any> {
    return this.httpService.post('/project/' + projectId + '/rfi/procore', {});
  }

  public getLocalAllProjectRfis(): IProjectRfi[] {
    return this.allProjectRfis;
  }

  public setLocalAllProjectRfis(projectId: string): Promise<boolean> {
    this.allProjectRfis = [];
    return new Promise((resolve) => {
      this.httpService.get('/project/' + projectId + '/rfi').pipe(take(1)).subscribe(
        res => {
          this.allProjectRfis = res;
          return resolve(true);
        },
        err => {
          this.notificationService.error(err, { type: 'rfis', action: 'get' });
          return resolve(false);
        }
      );
    });
  }

  public setLocalUserProjectRfis(projectId: string, subId: string): Promise<boolean> {
    this.userAvailableRfis = [];
    return new Promise((resolve) => {
      this.httpService.get(`/project/${projectId}/rfi?subContractorId=${subId}&includeGC=true`).pipe(take(1)).subscribe(
        res => {
          this.userAvailableRfis = res;
          return resolve(true);
        },
        err => {
          this.notificationService.error(err, { type: 'rfis', action: 'get' });
          return resolve(false);
        }
      );
    });
  }

  transformToTableData(dataInput: any[], permission: IUserPermission, currency): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'Number',
        colKey: 'number',
        type: RowItemType.Text,
        width: '25%'
      },
      {
        displayName: 'subject',
        colKey: 'subject',
        type: RowItemType.Text,
        width: '25%'
      },
      {
        displayName: 'Start Date',
        colKey: 'initiatedAt',
        type: RowItemType.Text,
        width: '25%'
      },
      {
        displayName: 'Due Date',
        colKey: 'dueDate',
        type: RowItemType.Text,
        width: '25%'
      },

    ];

    const rows: IGRow[] = [];
    dataInput.forEach(item => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'number',
          value: item.number
        },
        {
          colKey: 'subject',
          value: item.subject
        },
        {
          colKey: 'initiatedAt',
          value: !Utils.isEmpty(item.initiatedAt) ? new Date(item.initiatedAt).toLocaleString() : '-'
        },
        {
          colKey: 'dueDate',
          value: !Utils.isEmpty(item.dueDate) ? new Date(item.dueDate).toLocaleString() : '-'
        }
      ];
      rows.push(
        {
          key: item.id,
          rowItems: rowItems,
          selectable: true,
          editable: permission.edit,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.ModalEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      deleteOptions: {
        show: true
      }
    };

    return retTable;
  }

  buildFormInput(formInput: IProjectRfi): FormGroup {
    const addRfiFormInput = this.formBuilderService.group({
      id: [null],
      number: [null, Validators.required],
      subject: [null, Validators.required],
      initiatedAt: [null, Validators.required],
      dueDate: [null, Validators.required],
      prepareLeadDays: [null, Validators.required],
      reviewLeadDays: [null, Validators.required],
    });

    if (formInput) {
      addRfiFormInput.controls['id'].setValue(formInput.id);
      addRfiFormInput.controls['number'].setValue(formInput.number);
      addRfiFormInput.controls['subject'].setValue(formInput.subject);
      addRfiFormInput.controls['initiatedAt'].setValue(formInput.initiatedAt);
      addRfiFormInput.controls['dueDate'].setValue(formInput.dueDate);
      addRfiFormInput.controls['prepareLeadDays'].setValue(formInput.prepareLeadDays);
      addRfiFormInput.controls['reviewLeadDays'].setValue(formInput.reviewLeadDays);
    }

    return addRfiFormInput;
  }
}
