import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { SegmentService } from '../../segment/segment.service';

import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';

import { IProjectLabor, UnitOfMeasure } from '../../../models/project/project-labor/project-labor.interface';
import { IUserPermission } from '../../../models/user/user.interface';

import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectLaborService {

  allLabors: IProjectLabor[];

  constructor(
    private httpService: HttpBackendService,
    private notificationService: NotificationService,
    private formBuilderService: FormBuilder
  ) { }

  public exportLabors(projectId: string, subId?: string, includeGC?: boolean) {
    let params = '';
    if (subId) {
      if (includeGC) {
        params += `?subContractorId=${subId}&includeGC=true`;
      } else {
        params += `?subContractorId=${subId}`;
      }
    } else {
      if (includeGC) {
        params += '?includeGC=true';
      }
    }
    const req = `/project/${projectId}/labor/export${params}`;
    return this.httpService.getBlob(req);
  }

  public getList(projectId: string, subId?: string, includeGC?: boolean): Observable<IProjectLabor[]> {
    let params = '';
    if (subId) {
      if (includeGC) {
        params += `?subContractorId=${subId}&includeGC=true`;
      } else {
        params += `?subContractorId=${subId}`;
      }
    } else {
      if (includeGC) {
        params += '?includeGC=true';
      }
    }
    const req = `/project/${projectId}/labor${params}`;
    return this.httpService.get(req);
  }

  public get(laborId: string): Observable<IProjectLabor> {
    return this.httpService.get('/project/labor' + laborId);
  }

  public getListForSchedule(scheduleId: string): Observable<any> {
    return this.httpService.get('/project/schedule/' + scheduleId + '/labor');
  }

  public insert(json: any): Observable<IProjectLabor> {
    return this.httpService.post('/project/labor', json);
  }

  public update(laborId: string, json: any): Observable<any> {
    return this.httpService.put('/project/labor/' + laborId, json);
  }

  public updateLabors(json: any, projectId: string): Observable<any> {
    return this.httpService.put('/project/labors/' + projectId, json);
  }

  public delete(laborIds: string[]): Observable<void> {
    return this.httpService.post('/project/labor/delete', laborIds);
  }

  public importLabor(projectId: string, file: any, subId?: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const params = subId ? `?subContractorId=${subId}` : '';
    return this.httpService.formData(`/project/${projectId}/labor${params}`, formData);
  }

  public importFromProcore(projectId: string): Observable<any> {
    return this.httpService.post('/project/' + projectId + '/labor/procore', {});
  }

  transformToImportedTableData(dataInput: any[], permission: IUserPermission, currency): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'ID',
        colKey: 'resourceId',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'Elements',
        colKey: 'assignedElements',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'Parameter of Unit',
        colKey: 'quantityParameter',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'unit_rate',
        colKey: 'unitRate',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'Resource Rate',
        colKey: 'resourceRate',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'percentage_loss',
        colKey: 'percentageLoss',
        type: RowItemType.Text,
        width: '10%'
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(item => {
      let manuallyAssignedPQ = 0;
      item.plannedRelations.forEach((rel) => {
        manuallyAssignedPQ += parseFloat(rel.properties.plannedQuantity || 0);
      })
      let plannedQuantity: any = item.plannedQuantity * item.resourceRate;
      plannedQuantity = plannedQuantity + manuallyAssignedPQ;
      const budgetAssigned: any = plannedQuantity * item.cost;
      const costAssigned: any = item.quantityAssigned * item.cost;

      const rowItems: IGRowItem[] = [
        {
          colKey: 'resourceId',
          value: item.resourceId
        },
        {
          colKey: 'name',
          value: item.name
        },
        {
          colKey: 'assignedElements',
          value: item.quantityParameter
        },
        {
          colKey: 'quantityParameter',
          value: this.getParamterOfUnit(item.elementUnit)
        },
        {
          colKey: 'unitRate',
          value: item.unitRate || '-'
        },
        {
          colKey: 'resourceRate',
          value: item.resourceRate
        },
        {
          colKey: 'percentageLoss',
          value: item.percentageLoss || '-'
        }
      ];
      rows.push(
        {
          key: item.id,
          rowItems: rowItems,
          selectable: true,
          editable: permission.edit,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.ModalEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false,
        inline: false
      },
      deleteOptions: {
        show: true
      },
      searchOptions: {
        show: false
      }
    };

    return retTable;
  }

  transformToTableData(dataInput: any[], permission: IUserPermission, currency): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'ID',
        colKey: 'resourceId',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text,
        width: '20%'
      },
      {
        displayName: 'UOM',
        colKey: 'unitOfMeasure',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'Cost',
        colKey: 'unitRate',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'budget_quantity',
        colKey: 'budgetQuantity',
        type: RowItemType.Text,
        width: '10%',
        align : 'right'
      },
      {
        displayName: 'Budget Amount',
        colKey: 'budgetAssigned',
        type: RowItemType.Text,
        width: '10%',
        align : 'right'
      },
      {
        displayName: 'planned_quantity',
        colKey: 'plannedQuantity',
        type: RowItemType.Text,
        width: '10%',
        align : 'right'
      },
      {
        displayName: 'planned_cost',
        colKey: 'plannedCost',
        type: RowItemType.Text,
        width: '10%',
        align : 'right'
      },
      // {
      //   displayName: 'planned_quantity',
      //   colKey: 'plannedQuantity',
      //   type: RowItemType.Text,
      //   width: '10%'
      // },
      // {
      //   displayName: 'percentage_loss',
      //   colKey: 'percentageLoss',
      //   type: RowItemType.Text,
      //   width: '10%'
      // },
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(item => {
      let plannedQuantity: any = item.plannedQuantity;

      let manuallyAssignedPQ = 0;
      item.plannedRelations.forEach((rel) => {
        if (!rel.properties.autoAssigned)
          manuallyAssignedPQ += parseFloat(rel.properties.plannedQuantity || 0);
      })
      
      plannedQuantity = parseFloat((plannedQuantity * item.resourceRate).toString());
      plannedQuantity = plannedQuantity + manuallyAssignedPQ;
      plannedQuantity = parseFloat((plannedQuantity + (plannedQuantity * (item.percentageLoss / 100 )))); 
      const budgetAssigned: any = plannedQuantity * item.cost;
      const plannedCost: any = plannedQuantity * item.cost;

      const rowItems: IGRowItem[] = [
        {
          colKey: 'resourceId',
          value: item.resourceId
        },
        {
          colKey: 'name',
          value: item.name
        },
        {
          colKey: 'unitOfMeasure',
          value: this.getDisplayValue(item.unitOfMeasure)
        },
        {
          colKey: 'unitRate',
          value: Utils.formatCurrency(Number(item.cost), currency.code)
        },
        {
          colKey: 'budgetQuantity',
          value: item.budgetQuantity
        },
        {
          colKey: 'budgetAssigned',
          value: Utils.isEmpty(item.budget) || parseFloat(item.budget) === 0 ? '-' : Utils.formatCurrency(Number(item.budget), currency.code, 2)
        },
        {
          colKey: 'plannedQuantity',
          value: Utils.isEmpty(plannedQuantity) || parseFloat(plannedQuantity) === 0 ? '-' :  Number(plannedQuantity).toFixed(2)
        },
        {
          colKey: 'plannedCost',
          value: Utils.isEmpty(plannedCost) || parseFloat(plannedCost) === 0 ? '-' : Utils.formatCurrency(Number(plannedCost), currency.code, 2)
        }
        // {
        //   colKey: 'plannedQuantity',
        //   value: Utils.isEmpty(plannedQuantity) || parseFloat(plannedQuantity) === 0 ? '-' : Number(plannedQuantity.toFixed(2))
        // },
        // {
        //   colKey: 'percentageLoss',
        //   value: Utils.isEmpty(percentageLoss) || parseInt(percentageLoss, 10) === 0 ? '-' : Number(percentageLoss.toFixed(3))
        // },
      ];
      rows.push(
        {
          key: item.id,
          rowItems: rowItems,
          selectable: true,
          editable: permission.edit,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.ModalEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: permission.edit,
        inline: false
      },
      deleteOptions: {
        show: true
      },
      searchOptions: {
        show: false
      }
    };

    return retTable;
  }

  transformToTableDataForActivity(dataInput: any[], permission: IUserPermission, currency): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'id',
        colKey: 'id',
        type: RowItemType.Text,
        width: '8%'
      },
      {
        displayName: 'Name',
        colKey: 'name',
        type: RowItemType.Text,
        width: '25%'
      },
      {
        displayName: 'Planned Qty',
        colKey: 'plannedQuantity',
        type: RowItemType.Text,
        width: '10%',
        align: 'right'
      },
      {
        displayName: 'Actual Qty',
        colKey: 'actualQuantity',
        type: RowItemType.Text,
        width: '10%',
        align: 'right'
      },
      {
        displayName: 'UOM',
        colKey: 'unitOfMeasure',
        type: RowItemType.Text,
        width: '5%',
        align: 'center'
      },
      {
        displayName: 'unit_cost',
        colKey: 'unitCost',
        type: RowItemType.Text,
        width: '10%',
        align: 'right'
      },
      {
        displayName: 'planned_cost',
        colKey: 'budgetAssigned',
        type: RowItemType.Text,
        width: '15%',
        align: 'right'
      },
      // {
      //   displayName: 'budget',
      //   colKey: 'budget',
      //   type: RowItemType.Text,
      //   width: '30%'
      // }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(item => {
      let selectedValue = 0;
      if (item.elementUnit.toString() === 'area') {
        selectedValue = item.areaSum ? item.areaSum : 0;
      } else if (item.elementUnit.toString() === 'volume') {
        selectedValue = item.volumeSum ? item.volumeSum : 0;
      } else if (item.elementUnit.toString() === 'length') {
        selectedValue = item.lengthSum ? item.lengthSum : 0;
      } else if (item.elementUnit.toString() === 'unit') {
        selectedValue = 1;
      }
  
      const plannedQuantity: any =  item.isMannuallyAddedPlannedQty ? item.labPlannedQuantity : selectedValue * item.resourceRate;
      const actualQuantity: any = item.labActualQuantity || 0;
      const budgetAssigned: any = plannedQuantity * item.cost;
      const unitCost: any = item.cost;
      const rowItems: IGRowItem[] = [
        {
          colKey: 'id',
          value: item.resourceId
        },
        {
          colKey: 'name',
          value: item.name
        },
        {
          colKey: 'plannedQuantity',
          value: Utils.isEmpty(plannedQuantity) || parseFloat(plannedQuantity) === 0 ? '-' : Number(plannedQuantity).toFixed(2),
          editable: item.labAutoAssigned ? false : permission.edit
        },
        {
          colKey: 'actualQuantity',
          value: Utils.isEmpty(actualQuantity) || parseFloat(actualQuantity) === 0 ? '-' : Number(actualQuantity).toFixed(2),
          editable: permission.edit
        },
        {
          colKey: 'unitOfMeasure',
          value: this.getDisplayValue(item.unitOfMeasure)
        },
        {
          colKey: 'unitCost',
          value: Utils.isEmpty(unitCost) || parseFloat(unitCost) === 0 ? '-' : Utils.formatCurrency(unitCost, currency.code),
        },
        {
          colKey: 'budgetAssigned',
          value: Utils.isEmpty(budgetAssigned) || parseFloat(budgetAssigned) === 0 ? '-' : Utils.formatCurrency(budgetAssigned, currency.code, 2)
        },
        // {
        //   colKey: 'budget',
        //   value: (Utils.isEmpty(item.quantityAssigned * item.cost) || item.quantityAssigned * item.cost <= 0 ) ? '-' : Utils.formatCurrency( item.quantityAssigned * item.cost, currency.code)
        // }
      ];
      rows.push(
        {
          key: item.id,
          rowItems: rowItems,
          selectable: true,
          editable: permission.edit,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{ type: EditType.InlineEdit }, { type: EditType.Delete }] : [],
            editRowValidationFn: this.rowValidationFn
          },
          dependents: [{
            dependentCol: 'budgetAssigned',
            dependentOn: ['plannedQuantity'],
            staticValues: {
              itemCost: item.cost,
              plannedQuantity: plannedQuantity
            }
          }]
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false,
        inline: true,
        addModel: [
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'plannedQuantity',
            required: true,
            editable: true,
          },
        ]
      },
      deleteOptions: {
        show: false
      }
    };

    return retTable;
  }

  rowValidationFn(rowItems: IGRowItem[]): boolean {
    const plannedQuantity = rowItems.find(ri => ri.colKey === 'plannedQuantity');
    const actualQuantity = rowItems.find(ri => ri.colKey === 'actualQuantity');
    if (Utils.isEmpty(plannedQuantity)) return false;
    if (Utils.isEmpty(plannedQuantity.value)) return false;

    if (Utils.isEmpty(actualQuantity)) return false;
    if (Utils.isEmpty(actualQuantity.value)) return false;

    return true;
  }

  transformToTableDataForActivityWithAutoAssign(dataInput: any[], permission: IUserPermission, currency): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'id',
        colKey: 'id',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'Name',
        colKey: 'resourceName',
        type: RowItemType.Text,
        width: '20%'
      },
      {
        displayName: 'quantity',
        colKey: 'quantityParameter',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'Resource Rate',
        colKey: 'resourceRate',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'Planned Qty',
        colKey: 'plannedQuantity',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'UOM',
        colKey: 'unitOfMeasure',
        type: RowItemType.Text,
        width: '15%'
      },
      {
        displayName: 'planned_cost',
        colKey: 'budgetAssigned',
        type: RowItemType.Text,
        width: '10%'
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(item => {
      let selectedValue = 0;

      if (item.elementUnit.toString() === 'area') {
        selectedValue = item.areaSum ? item.areaSum : 0;
      } else if (item.elementUnit.toString() === 'volume') {
        selectedValue = item.volumeSum ? item.volumeSum : 0;
      } else if (item.elementUnit.toString() === 'length') {
        selectedValue = item.lengthSum ? item.lengthSum : 0;
      } else if (item.elementUnit.toString() === 'unit') {
        selectedValue = 1
      }

      const plannedQuantity: any = selectedValue * item.resourceRate;
      const budgetAssigned: any = plannedQuantity * item.cost;
      const rowItems: IGRowItem[] = [
        {
          colKey: 'id',
          value: item.resourceId
        },
        {
          colKey: 'resourceName',
          value: item.name
        },
        {
          colKey: 'quantityParameter',
          value: Utils.isEmpty(selectedValue)  ? '-' : selectedValue.toFixed(2)
        },
        {
          colKey: 'resourceRate',
          value: Utils.isEmpty(item.resourceRate) ? '-' : Number(item.resourceRate)
        },
        {
          colKey: 'plannedQuantity',
          value: Utils.isEmpty(plannedQuantity) || parseFloat(plannedQuantity) === 0 ? '-' : Number(plannedQuantity).toFixed(2)
        },
        {
          colKey: 'unitOfMeasure',
          value: this.getDisplayValue(item.unitOfMeasure)
        },
        {
          colKey: 'budgetAssigned',
          value: Utils.isEmpty(budgetAssigned) || parseFloat(budgetAssigned) === 0 ? '-' : Utils.formatCurrency(budgetAssigned, currency.code, 2)
        }
      ];
      rows.push(
        {
          key: item.id,
          rowItems: rowItems,
          selectable: true,
          editable: permission.edit,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false,
        inline: false
      },
      deleteOptions: {
        show: false
      }
    };

    return retTable;
  }

  getDisplayValue(value: UnitOfMeasure) {
    switch (value) {
      case UnitOfMeasure.ManHour:
        return 'Man Hour';
      case UnitOfMeasure.ManDay:
        return 'Man Day';
    }

    return value;
  }

  getParamterOfUnit(value: string) {
    if (value === 'm') return 'Length';
    if (value === 'm2') return 'Area';
    if (value === 'm3') return 'Volume';
    else return '-';
  }

  buildFormInput(formInput: IProjectLabor): FormGroup {
    const addLaborFormInput = this.formBuilderService.group({
      id: [null],
      resourceId: [null, Validators.required],
      name: [null, Validators.required],
      unitOfMeasure: [null, Validators.required],
      cost: [null, Validators.pattern('^[0-9]*(\\.[0-9]*)?$')],
      quantityAssigned: [null],
      budgetQuantity: [null],
      budget: [null],
    });

    if (formInput) {
      addLaborFormInput.controls['id'].setValue(formInput.id);
      addLaborFormInput.controls['resourceId'].setValue(formInput.resourceId);
      addLaborFormInput.controls['name'].setValue(formInput.name);
      addLaborFormInput.controls['unitOfMeasure'].setValue(formInput.unitOfMeasure);
      addLaborFormInput.controls['cost'].setValue(formInput.cost);
      addLaborFormInput.controls['quantityAssigned'].setValue(formInput.quantityAssigned);
      addLaborFormInput.controls['budgetQuantity'].setValue(formInput.budgetQuantity);
      addLaborFormInput.controls['budget'].setValue(formInput.budget);
    }

    return addLaborFormInput;
  }

  public getLocalLaborById(id: string): IProjectLabor {
    return this.allLabors.find(labor => labor.id === id);
  }

  public getLocalAllProjectLabor(): IProjectLabor[] {
    return this.allLabors;
  }

  public setLocalUserProjectLabor(projectId: string, subId?: string, includeGC?: boolean): Promise<boolean> {
    let params = '';

    if (subId) {
      if (includeGC) {
        params += `?subContractorId=${subId}&includeGC=true`;
      } else {
        params += `?subContractorId=${subId}`;
      }
    } else {
      if (includeGC) {
        params += '?includeGC=true';
      }
    }

    const req = `/project/${projectId}/labor${params}`;
    this.allLabors = [];
    return new Promise((resolve) => {
      this.httpService.get(req).pipe(take(1)).subscribe(
        res => {
          this.allLabors = res;
          return resolve(true);
        },
        err => {
          return resolve(false);
        }
      );
    });
  }
}
