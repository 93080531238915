import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectModelService } from '../../services/project/project-model/project-model.service';
import { UserService } from '../../services/user/user.service';

import { NotificationType } from '../../utils/enums/notification.enum';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() unseenNotificationCountEvent: EventEmitter<number> = new EventEmitter();

  allProcesses = [];
  processes = [];
  allNotifications = [];
  notifications =  [];
  unseenNotificationCount = 0;
  userAvatars = {};

  processesPageSize = 5;
  numberOfDisplayedNotifications: number;
  notificationsPageSize = 10;
  numberOfDisplayedProcesses: number;

  processesExpanded: boolean;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private userService: UserService,
    private router: Router,
    private projectModelService: ProjectModelService
  ) {
    if (localStorage.getItem('processesExpanded')) {
      this.processesExpanded = JSON.parse(localStorage.getItem('processesExpanded'));
    }

    this.notificationService.notification$.pipe(takeUntil(this.destroyed$)).subscribe(async event => {
      const notification = Object.assign({}, event);
      notification['time'] = Utils.formatDateTime(notification.generated, true, true);
      if (!this.userAvatars[notification.fromUserId]) {
        this.userAvatars[notification.fromUserId] = await this.userService.getUserAvatar(notification.fromUserId).toPromise();
      }
      notification['avatar'] = this.userAvatars[notification.fromUserId];
      notification['target'] = this.getTargetType(notification);
      const notificationIndex = this.allNotifications.findIndex(n => n.id === notification.id);
      if (notificationIndex < 0) {
        this.allNotifications.push(notification);
      } else {
        this.allNotifications[notificationIndex] = notification;
      }
      this.allNotifications = this.sort(this.allNotifications);
      this.notifications = this.allNotifications.slice(0, this.notifications.length + 1);
      this.updateSeenNotificationCount();
    });

    this.notificationService.process$.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      const process = Object.assign({}, event);
      process['time'] = Utils.formatDateTime(process.generated, true, true);
      const status = this.projectModelService.showStatus(Number(process.body));
      process['body'] = status.status;
      process['icon'] = status.icon;
      const processIndex = this.allProcesses.findIndex(p => p.id === process.id);
      if (processIndex < 0) {
        this.allProcesses.push(process);
      } else {
        this.allProcesses[processIndex] = process;
      }
      this.allProcesses = this.sort(this.allProcesses);
      this.processes = this.allProcesses.slice(0, this.processes.length + 1);
      this.updateSeenNotificationCount();
    });
  }

  // tslint:disable-next-line:no-empty
  async ngOnInit() {
    this.notificationService.getNotifications().pipe(takeUntil(this.destroyed$)).subscribe(async notifications => {
      this.allNotifications = notifications;
      this.allNotifications = this.sort(this.allNotifications);
      this.updateSeenNotificationCount();
      for (const notification of this.allNotifications) {
        notification['target'] = this.getTargetType(notification);
        notification['time'] = Utils.formatDateTime(notification.generated, true, true);
        if (!this.userAvatars[notification.fromUserId]) {
          this.userAvatars[notification.fromUserId] = await this.userService.getUserAvatar(notification.fromUserId).toPromise();
        }
        notification['avatar'] = this.userAvatars[notification.fromUserId];
      }
      this.notifications = this.allNotifications.slice(0, this.notificationsPageSize);
      this.numberOfDisplayedNotifications = this.notifications.length;
    });

    this.notificationService.getProcesses().pipe(takeUntil(this.destroyed$)).subscribe(async processes => {
      this.allProcesses = processes;
      for (const process of this.allProcesses) {
        process['time'] = Utils.formatDateTime(process.generated, true, true);
        const status = this.projectModelService.showStatus(Number(process.body));
        process['body'] = status.status;
        process['icon'] = status.icon;
      }
      this.allProcesses = this.sort(this.allProcesses);
      this.updateSeenNotificationCount();
      this.processes = this.allProcesses.slice(0, this.processesPageSize);
      this.numberOfDisplayedProcesses = this.processes.length;
    });
  }

  closeIconClick() {
    this.closeEvent.emit();
  }

  seeNotification(notification) {
    if (!notification.seen) {
      notification.seen = true;
      this.updateSeenNotificationCount();
      this.notificationService.seeNotification(notification.id).subscribe();
    }
  }

  async dismissNotification(notification, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    notification.dismissed = true;
    await Utils.sleep(400, this.ngZone);
    if (notification.notificationType !== 'process') {
      this.allNotifications.splice(this.allNotifications.indexOf(notification), 1);
      this.notifications = this.allNotifications.slice(0, this.notifications.length - 1);
    } else {
      this.allProcesses.splice(this.allProcesses.indexOf(notification), 1);
      this.processes = this.allProcesses.slice(0, this.processes.length - 1);
    }
    this.updateSeenNotificationCount();
    this.notificationService.dismissNotification(notification.id).subscribe();
  }

  getTargetType(notification): string {
    switch (notification.notificationType) {
      case NotificationType.OBJECT_MESSAGE:
        return 'Model';
      case NotificationType.TASK_MESSAGE:
        return 'Look Ahead';
      case NotificationType.ACTIVITY_MESSAGE:
        return 'Master Schedule';
      case NotificationType.SPRINT:
        return 'Sprints Page';
      case NotificationType.SCHEDULE:
        return 'Look Ahead';
    }
  }

  sort(array: any[]): any[] {
    return array.sort((a, b) => {
      if (a.notificationType === 'process') {
        if ((a.body === 'Complete' || a.body === 'Failed') && (b.body !== 'Complete' && b.body !== 'Failed')) return 1;
        else if ((a.body !== 'Complete' && a.body !== 'Failed') && (b.body === 'Complete' || b.body === 'Failed')) return -1;
        else if (a.generated < b.generated) return 1;
        else if (a.generated > b.generated) return -1;
      } else {
        if (a.generated < b.generated) return 1;
        if (a.generated > b.generated) return -1;
      }
      return 0;
    });
  }

  updateSeenNotificationCount() {
    this.unseenNotificationCount = this.allNotifications.filter(notification => !notification.seen).length + this.allProcesses.filter(process => !process.seen).length;
    this.unseenNotificationCountEvent.emit(this.unseenNotificationCount);
  }

  GoToNotificationTarget(notification) {
      this.seeNotification(notification);
      this.router.navigateByUrl(this.notificationService.getNotificationUrl(notification));
      this.closeIconClick();
  }

  loadMoreNotifications() {
    const notificationCount = this.notifications.length + this.notificationsPageSize > this.allNotifications.length
      ? this.allNotifications.length
      : this.notifications.length + this.notificationsPageSize;
    this.notifications = this.allNotifications.slice(0, notificationCount);
  }

  loadMoreProcesses() {
    const processCount = this.processes.length + this.processesPageSize > this.allProcesses.length
      ? this.allProcesses.length
      : this.processes.length + this.processesPageSize;
    this.processes = this.allProcesses.slice(0, processCount);
  }

  markAllNotificationsAsRead() {
    this.notifications.forEach(notification => {
      this.seeNotification(notification);
    });
  }

  dismissAllNotifications() {
    this.notifications.forEach(notification => {
      if (notification.dismissable !== false) {
        this.dismissNotification(notification);
      }
    });
    this.notifications = this.allNotifications.slice(0, this.notificationsPageSize);
    this.loadMoreNotifications();
  }

  markAllProcessesAsRead() {
    this.processes.forEach(process => {
      this.seeNotification(process);
    });
  }

  dismissAllProcesses() {
    this.processes.forEach(process => {
      if (process.dismissable !== false) {
        this.dismissNotification(process);
      }
    });
    if (this.processes.length < 1) {
      this.processes = this.allProcesses.slice(0, this.processesPageSize);
    }
    this.loadMoreProcesses();
  }

  expandProcesses(expand: boolean) {
    this.processesExpanded = expand;
    localStorage.setItem('processesExpanded', expand.toString());
  }
}
