
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventType } from '../../utils/enums/notification.enum';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectFeedItem } from '../../models/project/project-dashboard/project-dashboard.interface';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectDashboardService } from '../../services/project/project-dashboard/project-dashboard.service';
import { ProjectService } from '../../services/project/project.service';
import { TranslationService } from '../../services/translation/translation.service';
import { UserService } from '../../services/user/user.service';

import { Utils } from '../../utils/utils';

import * as moment from 'moment';

@Component({
  selector: 'app-project-dashboard-feed',
  templateUrl: './project-dashboard-feed.component.html',
  styleUrls: ['./project-dashboard-feed.component.scss']
})
export class ProjectDashboardFeedComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  expectedCompletionDate: string;
  activeCompletionDate: string;
  completionSavings: number;
  showMilestoneTable: boolean;

  private projectId: string;
  private projectFeedAvatars: any = {};
  projectFeed: IProjectFeedItem[];

  newItem = false;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectService: ProjectService,
    private projectDashboardService: ProjectDashboardService,
    private notificationService: NotificationService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    this.getProjectFeed();
    this.isLoading = false;

    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe(async event => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.STEP_STATUS_UPDATED)) return;
      this.newItem = true;
      const item = event.body;
      item.text = this.buildMessage(item.stepStatus, item.userName, item.stepName, item.reason, item.sprintChange);
      if (this.projectFeedAvatars[item.userId]) item.avatar = this.projectFeedAvatars[item.userId];
      else item.avatar = null;
      this.projectFeed.unshift(item);
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getProjectFeed(): void {
    if (this.projectId) {
      this.projectDashboardService.getProjectFeed(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.transformFeed(res);
        },
        err => {
          const context: INoficationContext = {
            type: 'project feed',
            action: 'get'
          };

          this.notificationService.error(err, context);
        });
    }
  }

  async transformFeed(feed: IProjectFeedItem[]) {
    if (feed.length > 0) {
      for (const item of feed) {
        if (item.date) {
          const itemDate = moment.utc(item.date).clone().valueOf();
          item.date = Utils.formatDateTime(itemDate, true, false);
        }
        if (item.userId) await this.getAvatar(item.userId);
        item.text = this.buildMessage(item.stepStatus, item.userName, item.stepName, item.reason, item.secondarySubjectName);
        if (this.projectFeedAvatars[item.userId]) item.avatar = this.projectFeedAvatars[item.userId];
        else item.avatar = null;
      }
    }

    this.projectFeed = feed;
  }

  async getAvatar(userId: string): Promise<any> {
    if (this.projectFeedAvatars.hasOwnProperty(userId)) return;

    this.projectFeedAvatars[userId] = null;
    return new Promise(resolve => {
      this.userService.getUserAvatar(userId).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.projectFeedAvatars[userId] = res;
          resolve();
        },
        err => {
          resolve();
        }
      );
    });
  }

  buildMessage(status, userName, taskName, reason?, secondarySubjectName?) {
    userName = userName ? userName : 'Unknown';
    if (status === 0) {
      return TranslationService.translate('task_update_planned', {userName: userName, taskName: taskName});
    } else if (status === 1) {
      return TranslationService.translate('task_update_scheduled', {userName: userName, taskName: taskName});
    } else if (status === 2) {
      return TranslationService.translate('task_update_committed', {userName: userName, taskName: taskName});
    } else if (status === 3) {
      return TranslationService.translate('task_update_in_progress', {userName: userName, taskName: taskName});
    } else if (status === 4) {
      return TranslationService.translate('task_update_completed', {userName: userName, taskName: taskName});
    } else if (status === 5) {
      return TranslationService.translate('task_update_changed', {userName: userName, taskName: taskName});
    } else if (status === 7) {
      return TranslationService.translate('task_update_not_completed_backlog', {userName: userName, taskName: taskName, reason: reason});
    } else if (status === 8) {
      return TranslationService.translate('task_update_not_completed', {userName: userName, taskName: taskName, reason: reason, secondarySubjectName: secondarySubjectName});
    } else if (status === 9) {
      return TranslationService.translate('make_ready_not_approved', {userName: userName, taskName: taskName, secondarySubjectName: secondarySubjectName});
    } else if (status === 10) {
      return TranslationService.translate('make_ready_approved', {userName: userName, taskName: taskName, secondarySubjectName: secondarySubjectName});
    } else if (status === 11) {
      return TranslationService.translate('make_ready_ordered', {userName: userName, taskName: taskName, secondarySubjectName: secondarySubjectName});
    } else if (status === 12) {
      return TranslationService.translate('make_ready_delivered', {userName: userName, taskName: taskName, secondarySubjectName: secondarySubjectName});
    } else if (status === 13) {
      return TranslationService.translate('make_ready_ready', {userName: userName, taskName: taskName, secondarySubjectName: secondarySubjectName});
    }
    return '';
  }
}
