
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectActivityTypesService } from '../../services/project/project-activity-types/project-activity-types.service';
import { ProjectFilterSearchService } from '../../services/project/project-filter-search/project-filter-search.service';
import { ProjectModelService } from '../../services/project/project-model/project-model.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { ICustomTable, IRow, ITableSearchOptions, IRowItem } from '../../models/custom-table/custom-table.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../utils/enums/forge-viewer-type';
import { MessageType } from '../../utils/enums/message-type.enum'; // USED IN HTML
import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectModel } from '../../models/project/project-model/project-model.interface';
import { IProjectObjectSidebarTabs } from '../../models/project/project-object/project-object.interface';
import { IUserPermission } from '../../models/user/user.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-model-conflicts-component',
  templateUrl: './project-model-conflicts.component.html',
  styleUrls: ['./project-model-conflicts.component.scss']
})
export class ProjectModelConflictsComponent implements OnInit, OnDestroy {
  // display toggles
  pageIsLoading: boolean = true;
  dataIsAvailable: boolean = true;
  viewerPoppedOut: boolean;

  // project info
  projectId: string;
  projectPermission: IUserPermission;
  currentProjectObjects: any = {};
  projectChecksPassed: boolean;
  errorGettingData: boolean = false;

  // forge viewer
  firstLoad: boolean = true;
  addedState = {};
  removedState = {};
  updatedState = {};
  hoveredProjectObjectId: string;

  // table
  tableIsLoading: boolean = false;
  visibleTableData: ICustomTable; // data transformed for table only rows in view
  filteredData: any; // total results of filter (not just items in view)
  selectedProjectObjectIds = {};
  lastSelectedObjectId = null;
  activeSearchQuery: string;
  paginationStart: number = 0;
  allRowsSelected: boolean = false;
  searching = false;
  clearingSearch = false;
  curSelectedRowIndex: number;

  // filters
  activeFilterType: FilterModelOptions;
  activeFilters: string[] = [];
  lastCatFilter: string;
  filtersApplied: boolean = false;

  hasConflicts: boolean = false;

  // sidebar
  sidebarTabIconData: IProjectObjectSidebarTabs[];
  sidebarState: string = 'filter';
  activeActivityFilters: string[] = [];
  filterTypesToHide: string[] = [FilterModelOptions.SubContractor];
  selectedObjectIdsArray = []; // Object properties input
  messageType = MessageType;

  currentObjectRemoved = false;
  currentObjectName;
  currentObjectReplacements = [];
  showReplacements = false;

  showConfirmationModal: boolean = false;
  confirmationModalInput: IConfirmationModalInput;

  // Subscription takeUntil
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // Error handling
  errorContext: INoficationContext = {
    type: 'object data',
    action: 'set'
  };

  constructor(
    private router: Router,
    private activityTypeService: ProjectActivityTypesService,
    private projectObjectService: ProjectObjectService,
    private notificationService: NotificationService,
    private projectModelService: ProjectModelService,
    private segmentService: SegmentService,
    private forgeViewerService: ForgeViewerService,
    private projectService: ProjectService,
    private projectFilterSearchService: ProjectFilterSearchService
  ) { }

  async ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    await this.forgeViewerService.toggleConflictsView(true);

    this.forgeViewerService.onCategoryFilterEnabledPage = true;

    this.pageIsLoading = true;
    this.projectPermission = ProjectService.userPermission;
    this.activeFilterType = FilterModelOptions.Activities;

    if (this.projectService.getProjectReady()) {
      this.firstLoad = false;
      this.setupPageData();
    }

    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.viewerPoppedOut = this.forgeViewerService.poppedOut;

      if (this.forgeViewerService.poppedOut || !this.firstLoad) {
        // Viewer was just popped out and ready to be set to correct state
        await this.setUpTableAndViewer();
        this.highlightSelectedObjectsInViewer();
      } else {
        this.firstLoad = false;
        this.setupPageData();
      }
    });

    // Viewer output
    this.forgeViewerService.forgeViewerOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      this.handleForgeViewerOutput(gritSelection);
    });

    // Viewer show similar output
    this.forgeViewerService.showSimilarOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      const idForCat = gritSelection[0];
      if (idForCat) {
        this.filterByLastCat(idForCat);
      }
    });

    // Viewer clear filters output
    this.forgeViewerService.clearFiltersOutput.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.filterModelClick([]);
    });

    this.segmentService.track('Project Models Loaded', { projectId: this.projectId });
  }

  async ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }

    await this.forgeViewerService.toggleConflictsView(false);
  }

  // Initialize Methods
  async setupPageData() {
    const state = {};
    const objects = this.projectService.getAllObjectsMap();
    for (const key in objects) {
      if (objects[key]) {
        state[key] = key;
        this.currentProjectObjects[key] = objects[key];
        this.currentProjectObjects[key].replacements = this.currentProjectObjects[key].replacements || [];
      }
    }

    await this.setUpTableAndViewer();
    this.pageIsLoading = false;
  }

  resetView() {
    this.forgeViewerService.clearAllThemingColors();
  }

  getDefaultColor(id) {
    if (this.hoveredProjectObjectId === id) return ForgeViewerType.Hover;
    if (this.currentObjectReplacements && this.currentObjectReplacements.indexOf(id) >= 0) return ForgeViewerType.Added;
    if (this.lastSelectedObjectId && this.lastSelectedObjectId === id) return ForgeViewerType.Select;
    if (this.selectedProjectObjectIds[id]) return ForgeViewerType.Removed;
    return ForgeViewerType.Default;
  }
  // END Initialization

  // Object Selection
  async handleForgeViewerOutput(gritViewerOutputData: any) {
    if (gritViewerOutputData !== 'esc') {
      if (gritViewerOutputData) {
        gritViewerOutputData.forEach(selection => {
          if (gritViewerOutputData.length > 1) {
            this.addSelectedObject(selection);
          } else {
            this.addOrRemoveSelectedObject(selection);
          }
        });
        // Update table active rows

        this.applyPagination(gritViewerOutputData[0]);
      } else {
        await this.resetRowSelections();
        this.setVisibleTableData();
      }
    } else {
      this.setViewerToFilterData();
    }
  }

  async assignItemsClick(item: IRowItem) {
    if (this.showReplacements) {
      await this.toggleReplacements();
    }
    await this.addSelectedObject(item.actionOutput.id);
    await this.toggleReplacements();
  }

  async applySingleRowClick(rows: IRow[]) {
    if (this.showReplacements) {
      await this.toggleReplacements();
    }
    let curId;
    // tslint:disable-next-line:prefer-for-of
    for (let r = 0; r < rows.length; r++) {
      curId = rows[r].key;
      this.addOrRemoveSelectedObject(curId);
    }
  }
  // END Object selection

  // Resets
  async resetRowSelections() {
    if (this.showReplacements) {
      await this.toggleReplacements();
    }
    this.resetView();
    this.selectedProjectObjectIds = {};
    this.selectedObjectIdsArray = [];
  }

  async setViewerToFilterData() {
    await this.resetRowSelections();
    this.setVisibleTableData();
  }
  // END Resets

  highlightSelectedObjectsInViewer(): void {
    this.resetView();
    Object.keys(this.selectedProjectObjectIds).forEach((key) => {
      this.forgeViewerService.setState({ gritObjectId: key, type: this.getDefaultColor(key) });
    });
  }

  async addSelectedObject(curId: string) {
    if (this.showReplacements && this.currentProjectObjects[curId].conflict > 0) {
      const i = this.currentObjectReplacements.indexOf(curId);
      if (i === -1) {
        this.currentObjectReplacements.push(curId);
        this.currentProjectObjects[this.lastSelectedObjectId].replacements.push(curId);
        this.currentProjectObjects[curId].replacements.push(this.lastSelectedObjectId);
      }
    }

    if (this.currentProjectObjects[curId].conflict < 0) {
      this.selectedProjectObjectIds[curId] = curId;
      this.selectedObjectIdsArray = Object.keys(this.selectedProjectObjectIds);
    }

    const prevId = this.lastSelectedObjectId;
    this.lastSelectedObjectId = curId;
    if (prevId) this.forgeViewerService.setState({ gritObjectId: prevId, type: this.getDefaultColor(prevId) });

    this.forgeViewerService.setState({ gritObjectId: curId, type: this.getDefaultColor(curId) });
    this.forgeViewerService.fitObjectsToView({[curId]: true});

    await this.setVisibleTableData();
  }

  async addOrRemoveSelectedObject(curId: string) {
    if (this.showReplacements && this.currentProjectObjects[curId].conflict > 0) {
      const i = this.currentObjectReplacements.indexOf(curId);
      if (i === -1) {
        this.currentObjectReplacements.push(curId);
        this.currentProjectObjects[this.lastSelectedObjectId].replacements.push(curId);
        this.currentProjectObjects[curId].replacements.push(this.lastSelectedObjectId);
      } else {
        this.currentObjectReplacements.splice(i, 1);
        const originalReplacements = this.currentProjectObjects[this.lastSelectedObjectId].replacements;
        if (originalReplacements.indexOf(curId) !== -1) {
          originalReplacements.splice(originalReplacements.indexOf(curId), 1);
        }
        const selectionReplacements = this.currentProjectObjects[curId].replacements;
        if (selectionReplacements.indexOf(this.lastSelectedObjectId) !== -1) {
          selectionReplacements.splice(selectionReplacements.indexOf(this.lastSelectedObjectId), 1);
        }
      }
    }

    if (this.currentProjectObjects[curId].conflict < 0) {
      // Set viewer
      if (this.selectedProjectObjectIds[curId]) {
        delete this.selectedProjectObjectIds[curId];
      } else {
        this.selectedProjectObjectIds[curId] = curId;
      }
      this.selectedObjectIdsArray = Object.keys(this.selectedProjectObjectIds);
    }

    const prevId = this.lastSelectedObjectId;
    this.lastSelectedObjectId = curId;
    if (prevId) this.forgeViewerService.setState({ gritObjectId: prevId, type: this.getDefaultColor(prevId) });
    this.forgeViewerService.setState({ gritObjectId: curId, type: this.getDefaultColor(curId) });
    this.forgeViewerService.fitObjectsToView({[curId]: true});
    await this.setVisibleTableData();
  }

  handleRowHover(row: IRow) {
    if (this.hoveredProjectObjectId) {
      const curId = this.hoveredProjectObjectId;
      this.hoveredProjectObjectId = null;
      this.forgeViewerService.setState({ gritObjectId: curId, type: this.getDefaultColor(curId) });
    }
    if (row) {
      this.hoveredProjectObjectId = row.key;
      this.forgeViewerService.setState({ gritObjectId: this.hoveredProjectObjectId, type: this.getDefaultColor(this.hoveredProjectObjectId) });
    }
  }
  // END Helpers

  // Table and Viewer set <== Set in same area for efficiencies and less looping
  async setUpTableAndViewer() {
    await this.setupTableData();

    this.resetView();
    this.addedState = {};
    this.removedState = {};
    Object.keys(this.filteredData).forEach(key => {
      if (this.currentProjectObjects[key].conflict > 0) {
        this.addedState[key] = key;
      } else if (this.currentProjectObjects[key].conflict < 0) {
        this.removedState[key] = key;
      } else {
        this.updatedState[key] = key;
      }
    });
    await this.forgeViewerService.setViewerState(this.removedState);

    this.searching = false;
    this.clearingSearch = false;

    await this.setVisibleTableData();

    const first = this.visibleTableData.rows[0];
    if (first) this.addSelectedObject(first.key);
  }
  // END Table and Viewer set

  // Table methods
  async setupTableData() {
    this.filteredData = await this.projectFilterSearchService.getFilterData(this.activeFilters, this.activeFilterType, true);
    if (this.filteredData === -1) this.filteredData = this.currentProjectObjects;

    const searchedData = this.projectFilterSearchService.getSearchData(this.activeSearchQuery, this.filteredData);
    if (searchedData !== -1) this.filteredData = searchedData;

    if (!this.filteredData) {
      if ((!this.activeFilters || !this.activeFilters.length) && !this.activeSearchQuery) {
        this.filteredData = this.currentProjectObjects;
      }
    }
    Object.keys(this.filteredData).forEach(key => {
      if (!this.currentProjectObjects[key]) {
        delete this.filteredData[key];
      }
    });

  }

  async setVisibleTableData() {
    // Custom table component input data
    this.visibleTableData = this.projectModelService.transformConflictTableData(
      this.filteredData,
      this.selectedProjectObjectIds,
      this.lastSelectedObjectId,
      this.activeSearchQuery,
      this.paginationStart,
      null
    );

    this.currentObjectName = this.lastSelectedObjectId ? this.currentProjectObjects[this.lastSelectedObjectId].name : '';
    this.currentObjectRemoved = this.lastSelectedObjectId && this.currentProjectObjects[this.lastSelectedObjectId].conflict < 0
      ? true : this.lastSelectedObjectId && this.currentProjectObjects[this.lastSelectedObjectId].conflict > 0 ? false : null;
    this.currentObjectReplacements = (this.lastSelectedObjectId ? this.currentProjectObjects[this.lastSelectedObjectId].replacements || [] : [])
      .filter(key => this.currentObjectRemoved ? this.currentProjectObjects[key].conflict > 0 : this.currentProjectObjects[key].conflict < 0);
    await this.forgeViewerService.setViewerState(this.currentObjectRemoved === null ? this.updatedState :
      this.showReplacements || this.currentObjectRemoved === false ? this.addedState : this.removedState);
    this.forgeViewerService.setState({ gritObjectId: this.lastSelectedObjectId, type: this.getDefaultColor(this.lastSelectedObjectId) });
  }

  async applyPagination(key: string) {
    const filterDataKeys = Object.keys(this.filteredData)
      .filter(id => this.filteredData[id].conflict < 0)
      .sort((i1, i2) => this.filteredData[i1].conflict - this.filteredData[i2].conflict);
    this.curSelectedRowIndex = typeof key === 'number' ? key : filterDataKeys.findIndex(k => k === key);
    this.paginationStart = this.curSelectedRowIndex === -1 ? 0 : Math.floor(this.curSelectedRowIndex / 20) * 20;
    await this.setVisibleTableData();
  }

  applySearchClick(searchOption: ITableSearchOptions): void {
    this.activeSearchQuery = !searchOption ? '' : searchOption.searchQuery.toLowerCase();
    this.paginationStart = 0; // go back to first page on new search

    this.resetRowSelections();
    if (this.activeSearchQuery === '') {
      this.clearingSearch = true;
    } else {
      this.searching = true;
    }
    this.setUpTableAndViewer();
  }

  async filterByLastCat(objectId: string) {
    const objCatNames = this.projectObjectService.getLocalObject(objectId).categories;
    if (!objCatNames || !objCatNames.length) return;
    const lastCatName = objCatNames[objCatNames.length - 1].toString();
    const allCatsMap = this.projectService.getLocalCategories();
    const catIds = [];
    for (const key in allCatsMap) {
      if (key) {
        if (allCatsMap[key].name === lastCatName) {
          catIds.push(key);
        }
      }
    }
    if (catIds.length > 0) {
      if (this.activeFilterType === FilterModelOptions.Category) {
        this.activeFilters = catIds;
        this.filterModelClick(this.activeFilters);
      } else {
        await this.switchFilterType(FilterModelOptions.Category);
        this.activeFilters = catIds;
        this.filterModelClick(this.activeFilters);
      }
    }
  }
  // END Table methods

  selectAllFilteredData(): void {
    Object.keys(this.filteredData).forEach((key) => {
      this.selectedProjectObjectIds[key] = key;
      this.forgeViewerService.setState({ gritObjectId: key, type: this.getDefaultColor(key) });
    });
  }

  // Sidebar
  setupSideBar(): void {
    this.sidebarTabIconData = this.activityTypeService.getSidebarTabData();
    this.forgeViewerService.resizeViewerAfterLoad();
  }

  handleSidebarIconClick(tab: IProjectObjectSidebarTabs): void {
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;

    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.sidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab: IProjectObjectSidebarTabs): void {
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;
    this.sidebarState = tab.key;
  }

  async filterModelClick(filters: string[]) {
    this.filtersApplied = !filters || !filters.length ? false : true;
    this.activeFilters = filters;
    this.paginationStart = 0;
    this.resetRowSelections();
    this.projectService.setFilteringModel(true);
    this.forgeViewerService.setDisablePage(true);
    await this.setUpTableAndViewer();
  }

  async switchFilterType(type: FilterModelOptions) {
    this.lastCatFilter = '';
    this.activeFilterType = type;
    this.activeFilters = [];
    this.activeSearchQuery = '';
    this.filtersApplied = false;
    this.resetRowSelections();
    await this.setUpTableAndViewer();
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) {
      this.segmentService.track('Model Conflicts Sidebar Loaded', { sideBar: 'properties' });
      this.projectService.toggleSidebarVisibility(isVisible);
    } else {
      this.projectService.toggleSidebarVisibility();
    }
    // SetTimeout is used here so the viewer is resized after the CSS animation
    // to resize to correct width
    setTimeout(() => { this.forgeViewerService.resizeViewerAfterLoad(); }, 500);
  }
  // END Sidebar

  // No models
  navigateToFileManager(): void {
    this.router.navigateByUrl('/project/' + this.projectId + '/settings?menuItem=3');
  }
  // END No models

  pageRefresh(): void {
    window.location.reload(true);
  }

  async skip(reverse?: boolean) {
    if (this.showReplacements) {
      await this.toggleReplacements();
    }
    let index = this.visibleTableData.rows.findIndex(r => r.key === this.lastSelectedObjectId) + this.paginationStart;
    const count = this.visibleTableData.pagination.count;
    const pageSize = this.visibleTableData.pagination.pageSize;
    index = index == null ? 0 : reverse && index === 0 ? count - 1 : !reverse && index === count - 1 ? 0
      : index + (reverse ? -1 : 1);
    const newPage = Math.floor(index / pageSize) * pageSize;
    if (newPage !== this.paginationStart) {
      this.paginationStart = newPage;
      await this.setVisibleTableData();
    }
    const objectId = this.visibleTableData.rows[index - this.paginationStart].key;
    this.addSelectedObject(objectId);
  }

  commit() {
    const data = this.getMergeData();
    this.showConfirmationModal = true;
    this.confirmationModalInput = {
      displayMessage: `Commit to remove these ${Object.keys(data.remove).length} objects?`,
      hasCancelAction: true,
      hasConfirmationAction: true
    };
  }

  async toggleReplacements() {
    this.showReplacements = !this.showReplacements;
    await this.forgeViewerService.setViewerState(this.showReplacements ? this.addedState : this.removedState);
    const keys = {};
    this.currentObjectReplacements.forEach(key => {
      keys[key] = key;
    });
    this.currentObjectReplacements.forEach(key => {
      const color = this.getDefaultColor(key);
      this.forgeViewerService.setState({ gritObjectId: key, type: color });
    });
    await this.forgeViewerService.fitObjectsToView(this.showReplacements ? keys : {[this.lastSelectedObjectId]: true});
  }

  private getMergeData() {
    const keep = {};
    const remove = {};
    Object.keys(this.selectedProjectObjectIds).forEach(key => {
      const obj = this.currentProjectObjects[key];
      remove[key] = (obj.replacements || []).filter(r => this.currentProjectObjects[r].conflict > 0);
    });
    return {keep, remove};
  }

  confirmMerge() {
    this.showConfirmationModal = false;

    const data = this.getMergeData();
    this.projectModelService.resolveConflicts(this.projectId, data.keep, data.remove).subscribe((res) => {
      Object.keys(data.keep).concat(Object.keys(data.remove)).forEach(key => {
        delete this.currentProjectObjects[key];
        delete this.removedState[key];
        delete this.addedState[key];
        delete this.filteredData[key];
      });

      this.forgeViewerService.removeObjects(Object.keys(data.remove), []);
      if (Object.keys(this.currentProjectObjects).filter(id => this.currentProjectObjects[id].conflict < 0).length === 0) {
        this.forgeViewerService.removeObjects([], Object.keys(this.currentProjectObjects).filter(id => this.currentProjectObjects[id].conflict > 0));
        this.router.navigate(['project', this.projectId, 'models']);
      } else {
        if (Object.keys(this.filteredData).length === 0) {
          this.filterModelClick([]);
        } else {
          this.filterModelClick(this.activeFilters);
        }
      }
    }, err => {
      const context: INoficationContext = {
        type: 'model',
        action: 'commit'
      };
      this.notificationService.error(err, context);
    });
  }
}
