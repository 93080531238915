import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { Utils } from '../../utils/utils';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectPageCriteriaService } from '../../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectService } from '../../services/project/project.service';

import { EventStatus } from '../../utils/enums/notification.enum';

@Component({
  selector: 'app-project-criteria-check',
  templateUrl: './project-criteria-check.component.html',
  styleUrls: ['./project-criteria-check.component.scss']
})
export class ProjectScheduleCriteriaCheckComponent implements OnInit, OnDestroy {
  @Output() splashButtonClick: EventEmitter<any> = new EventEmitter<any>();

  lowPermMessage: string = 'criteria_low_permission';
  displayLowPermMessage: boolean;
  showGenerateScreen: boolean = false;
  showVideoModal: boolean = false;
  currentVideoUrl: string;
  listDone: boolean = false;
  lProjectPageCriteriaService;
  originalSideBarState: boolean = false;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private ngZone: NgZone,
    private projectService: ProjectService,
    private projectPageCriteriaService: ProjectPageCriteriaService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.lProjectPageCriteriaService = projectPageCriteriaService;
   }

  ngOnInit() {
    this.originalSideBarState = this.projectService.showProjectSidebar;
    this.projectService.checkToHideSidebar(this.projectService.showProjectSidebar);
    // if user loads on the criteria route
    if (!this.projectPageCriteriaService.criteriaData) this.router.navigateByUrl('/project/' + this.projectService.currentProject.id + '/dashboard');
    else if (this.projectPageCriteriaService.errorGettingCriteria) return;
    else {
      this.displayLowPermMessage = this.projectPageCriteriaService.criteriaData.list.some(check => check.permission === false);
      this.listDone = !this.projectPageCriteriaService.criteriaData.list.some(check => check.completed === false);
      this.showGenerateScreen = this.listDone && this.projectPageCriteriaService.criteriaData.showSplashScreen;

      this.projectService.checkToHideSidebar(this.projectService.showProjectSidebar);
    }
  }

  runFunction() {
    this.splashButtonClick.emit(true);
  }

  closeVideoModal() {
    this.currentVideoUrl = null;
    this.showVideoModal = false;
  }

  videoPopup(videoUrl) {
    const target = event.currentTarget;
    if (!videoUrl) {
      // Give the input box about 1 sec before closing the modal and giving error
      setTimeout(() => {
        this.notificationService.eventPopup(EventStatus.Info, 'Video Error');
        this.closeVideoModal();
      },         1000);
      this.currentVideoUrl = null;
      return;
    }

    this.currentVideoUrl = videoUrl + '?autoplay=1&;enablejsapi=1';
    this.showVideoModal = true;
    return;
  }

  refresh(): void {
    window.location.reload();
  }

  async ngOnDestroy() {
    this.projectService.checkToHideSidebar(this.originalSideBarState, this.originalSideBarState);
    await Utils.sleep(0, this.ngZone);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
}
