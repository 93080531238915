import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';

import { HoverType, IGanttChartBar, IGanttChartRow, IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { StepStatusBarColor } from '../../../utils/enums/hex-color.enum';
import { ViewMode, ViewerMode, BulkEditSelection } from '../../../utils/enums/shared.enum';

import { ProjectFilterSearchService } from '../../../services/project/project-filter-search/project-filter-search.service';
import { ProjectGanttChartService } from '../../../services/project/project-gantt-chart/project-gantt-chart.service';
import { ProjectMasterScheduleService } from '../../../services/project/project-master-schedule/project-master-schedule.service'; // Used in html
import { ProjectMilestoneService } from '../../../services/project/project-milestone/project-milestone.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { IUserPermission } from '../../../models/user/user.interface';

import { Utils } from '../../../utils/utils';

import * as D3 from 'd3';
import * as moment from 'moment';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { MakeReadyStatus } from '../../../utils/enums/make-ready-status.enum';
import { ProjectRfiService } from '../../../services/project/project-rfi/project-rfi.service';
import { element } from 'protractor';
import { ActivityColorMap } from '../../../models/notification/notification.interface';

@Component({
  selector: 'app-project-gantt-chart',
  templateUrl: './project-gantt-chart.component.html',
  styleUrls: ['./project-gantt-chart.component.scss']
})

export class ProjectGanttChartComponent implements OnChanges, OnInit, OnDestroy {
  @Input() chartInput: IMasterScheduleActivity[];
  @Input() selectionInput: string[];
  @Input() activeFiltersInput: string[];
  @Input() activeFilterTypeInput: FilterModelOptions;

  @Output() chartSelectionOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() generateScheduleOutput: EventEmitter<void> = new EventEmitter();
  @Output() importOutput: EventEmitter<void> = new EventEmitter();
  @Output() exportOutput: EventEmitter<void> = new EventEmitter();
  @Output() phOutput: EventEmitter<void> = new EventEmitter();
  @Output() exceptionsOutput: EventEmitter<void> = new EventEmitter();
  @Output() addActivityOutput: EventEmitter<string> = new EventEmitter();
  @Output() editActivityOutput: EventEmitter<string> = new EventEmitter();
  @Output() assignObjectsOutput: EventEmitter<boolean> = new EventEmitter();
  @Output() switchViewOutput: EventEmitter<ViewMode> = new EventEmitter();
  @Output() hoverObjectsOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() addPrereqOutput: EventEmitter<{ activityId: string, prereqId: string }> = new EventEmitter();
  @Output() viewerObjectOutput: EventEmitter<any> = new EventEmitter();
  @Output() acctivtySubOutput: EventEmitter<any> = new EventEmitter();
  @Output() bulkEditOutput: EventEmitter<any> = new EventEmitter();
  @Output() rowEditOutput: EventEmitter<any> = new EventEmitter();

  @ViewChild('chartControl') chartControl;
  @ViewChild('chartContainer') chartContainer;
  @ViewChild('ganttContainer') ganttContainer;
  @ViewChild('rowHoverAreas') rowHoverAreas;
  @ViewChild('chartBarsContainer') chartBarsContainer;
  @ViewChild('overlayBarsContainer') overlayBarsContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('yAxis') yAxis;
  @ViewChild('headerContainer') headerContainer;
  @ViewChild('rowHover') rowHover;
  @ViewChild('columnSelContainer') columnSelContainer;
  @ViewChild('gridContainer') gridContainer;
  @ViewChild('prereqContainer') prereqContainer;

  // Template Variables
  chartDisplay: IMasterScheduleActivity[] = [];
  viewModeEnum = ViewMode;
  curActivitiesSelected: string[] = [];
  chartContainerHeight: number;
  chartContainerWidth: number;
  gridOffsetTransform: string;
  canImport = ProjectService.userPermission.edit && ProjectService.userPermission.gc;
  tasksList: any;
  canEdit = ProjectService.userPermission.edit && ProjectService.userPermission.gc;

  animationIsPlaying: boolean = false;
  animationSpeed: number = 1;
  animationInterval: any;
  curxAxisDay: number;
  checkAllFlagForBulkEdit: BulkEditSelection = 0;
  currentRange: any;

  // Component Inputs
  chartControls: IProjectScheduleControls;
  showAllTasks: boolean = false;
  bulkEdit: boolean = false;

  // search funtionality
  private filterQuery: string;

  private xAxisDataInput: any[] = [];
  private gridContainerHeight: number;
  private gridContainerWidth: number;
  private xAxisTransform: string;
  private yAxisTransform: string;
  private selectedScenarioId: string;

  private today: number;
  private projectEndDate: number;
  private curChartStartEpoch: number;
  private curChartEndEpoch: number;
  private visibleEpochs: number[];

  private rows: IGanttChartRow[] = [];
  private gridLineData: Array<{ x: number, y: number }> = [];
  private barData: IGanttChartBar[] = [];
  private overlayBarData: any[] = [];
  private firstLoad: boolean = true;
  private rowScales: D3.ScaleBand<any> = {};
  private xScaleTime: D3.scaleUtc<any> = null;
  private expandedIds: string[] = [];

  private hScrollResetDelay: any;
  private preventHscrolling: boolean = false;
  private hScrollCounter: number = 0;
  private isHscrolling: boolean = false;
  private ctrlPressed: boolean = false;

  private isDragging: boolean = false;
  private prereqLine;
  private validPrereqIds: string[] = [];

  private prereqLineData = [];
  private dragStartPos: { x: number, y: number };

  private colSelected = [];

  private resizeTimeout;
  private sortType;
  private sortAsc: boolean = true;
  projectPermission: IUserPermission;
  projectId: string;
  private materialResults1: any;
  private rfiResults: any;
  private scheduleData: any;
  private toggleProcurement: boolean;
  showExportModal: boolean = false;

  constructor(
    public masterScheduleService: ProjectMasterScheduleService,
    private ganttChartService: ProjectGanttChartService,
    private subcontractorService: ProjectSubContractorService,
    private milestoneService: ProjectMilestoneService,
    private projectService: ProjectService,
    private projectFilterSearchService: ProjectFilterSearchService,
    private materialService: ProjectMaterialService,
    private projectScheduleService: ProjectScheduleService,
    private projectRfiService: ProjectRfiService,
  ) {
    this.projectService.componentMethodCalled$.subscribe(
      (toggle) => {
        var toggleAssignObjectIcon = toggle;
        this.rows.forEach( d => {
          if(!toggleAssignObjectIcon) {
            // D3.select("#line_" + d.id.replace(/[ )(]/g,'')).attr("pointer-events","none");
            // D3.select("#line_" + d.id.replace(/[ )(]/g,'')).attr("opacity","0.5");

          }
          else if(toggleAssignObjectIcon) {
            D3.select("#line_" + d.id.replace(/[ )(]/g,'')).attr("pointer-events","auto");
            D3.select("#line_" + d.id.replace(/[ )(]/g,'')).attr("opacity","1");
          }

        })
      }
    );
   }

  @HostListener('mousewheel', ['$event'])
  onScrollEvent(event) {
    if (this.isDragging) {
      if (this.ctrlPressed) event.preventDefault();
      return;
    }
    const hScrollInterval = 8;
    if (this.hScrollResetDelay) clearTimeout(this.hScrollResetDelay);
    if (!this.preventHscrolling) {
      // prevents intertia scrolling events from firing horizontal scrolling unintentionally (user does one big scroll, then presses 'ctrl' -> the intertia scrolling events would still be firing)
      if (!this.ctrlPressed) this.preventHscrolling = true;
      else this.handleHscroll(event, hScrollInterval);
    }

    this.setHscrollTimer();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.drawChart();
    }, 100);

  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let index = -1;
    for (let i = 0; i < this.xAxisDataInput.length; i++) {
      if (this.xAxisDataInput[i].day == this.colSelected) {
        index = i;
      }
    }
    if (index == -1)
      return;
    if (event.keyCode === 39 && this.xAxisDataInput.length - 1 > index) {
      this.xAxisClick(this.xAxisDataInput[index + 1]);
    }
    if (event.keyCode === 37 && index > 0) {
      this.xAxisClick(this.xAxisDataInput[index - 1]);
    }
  }

  setSelectedScenarioId(scenarioId: string): void {
    this.selectedScenarioId = scenarioId;
  }

  handleHscroll(event: any, scrollInterval: number): void {
    event.preventDefault();
    const scrollingRight = event.deltaY < 0 ? true : false;
    scrollInterval = this.hScrollCounter % scrollInterval;
    if (this.hScrollCounter === 0) this.handleHover(null, HoverType.Dehover);
    if (scrollInterval === 0) this.moveRange(scrollingRight, 1);

    this.hScrollCounter++;
    this.isHscrolling = true;
  }

  setHscrollTimer(): void {
    this.hScrollResetDelay = setTimeout(
      () => {
        this.hScrollCounter = 0;
        this.isHscrolling = false;
        this.preventHscrolling = false;
      },
      500
    );
  }

  keyDown = (event) => {
    if (event.key === 'Control') this.ctrlPressed = true;
  }
  keyUp = (event) => {
    if (event.key === 'Control') this.ctrlPressed = false;
  }

  ngOnInit() {
    window.addEventListener('keydown', this.keyDown);
    window.addEventListener('keyup', this.keyUp);

    this.gridOffsetTransform = `translate(${this.ganttChartService.gO.gridOffsetLeft}, ${this.ganttChartService.gO.gridOffsetTop})`;

    D3.select(this.chartContainer.nativeElement).attr('oncontextmenu', 'return false;');
    this.ganttChartService.setProjectService(this.projectService);
    this.projectPermission = ProjectService.userPermission;
    this.projectId = this.projectService.currentProject.id;

    this.toggleProcurement = (localStorage.getItem('previouslySavedProcuremntLinesToggle') == "true");
    if (this.toggleProcurement)
      this.getData();

    this.currentRange = this.ganttChartService.curGanttDisplay;
  }

  async ngOnChanges() {
    if (!this.firstLoad) return;
    this.chartInput = this.ganttChartService.removeInputWithNoDates(this.chartInput);
    this.chartInput.forEach(item => item.expanded = false);

    this.chartDisplay = this.ganttChartService.getRootParents(this.chartInput, this.expandedIds, false);

    if (this.chartDisplay.length > 0) {
      this.firstLoadSetup();
      // this.getData();
    }

    if (this.selectionInput.length > 0) {
      this.expandedIds = this.chartInput.map(item => {
        if (this.selectionInput[0] === item.activity.id || item.activity.children.includes(this.selectionInput[0])) {
          return item.activity.id;
        }
      });
      this.handleNewData(this.chartInput, this.selectionInput[0]);
    }

    await this.drawChart();

    //If sidebar events are regular
    setTimeout(() => { this.drawChart(); }, 5000);
    setTimeout(() => { this.drawChart(); }, 2000);
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyDown);
    window.removeEventListener('keyup', this.keyUp);
    clearInterval(this.animationInterval);
  }

  closeExportModal(event) {
    this.showExportModal = event;
  }

  async getData() {
    //if (this.projectService.currentProjectAccount.hasProcore && this.projectService.currentProject.procoreProjectId) this.hasProcore = true;
    let gcOnly = true;
    let subId = null;
    if (this.projectPermission.subContractorId) {
      gcOnly = false;
      subId = this.projectPermission.subContractorId;
    }
    const materialResults = await this.materialService.getList(this.projectId, subId, gcOnly).toPromise();
    console.log(' Material Results : ', materialResults);
    this.materialResults1 = materialResults;

    const rfis = await this.projectRfiService.getList(this.projectId, subId, gcOnly).toPromise();
    this.rfiResults = rfis;

    this.projectScheduleService.getActiveScheduleMakeReadyTasks(this.projectService.currentProject.id)
      .subscribe(
        res => {
          const Scheduledata = res;
          this.scheduleData = res;
          // Scheduledata.forEach(step => {
          //   step.materials.forEach(material => {

          //   })
          // });

        },
        err => {

        }
      );
  }

  stickyHeader(event?) {
    if (!this.chartContainer || !this.ganttContainer) return;
    const chartBox = this.chartContainer.nativeElement.getBoundingClientRect();
    const ganttBox = this.ganttContainer.nativeElement.getBoundingClientRect();
    const distanceTop = ganttBox.top - chartBox.top;

    const header = D3.select(this.headerContainer.nativeElement);
    const hTransform = distanceTop + this.ganttChartService.gO.chartOffsetTop;
    header.attr('transform', `translate(0, ${hTransform})`);

    const xAxis = D3.select(this.xAxis.nativeElement);
    const xTransform = distanceTop + this.ganttChartService.gO.xAxisLabelYpos;
    xAxis.attr('transform', `translate(${this.ganttChartService.gO.gridOffsetLeft}, ${xTransform})`);
  }

  dragStarted() {
    if (!this.canEdit) return;
    this.dragStartPos = {
      x: D3.event.sourceEvent.offsetX,
      y: D3.event.sourceEvent.offsetY
    };
  }

  dragBarStart(d) {
    this.resetAllSelections();
    this.clearColumnSelections(false);
    this.chartSelectionOutput.emit([]);
    const startActivityId = d.id;
    this.validPrereqIds = this.masterScheduleService.getPossiblyValidLevelPrereqs(this.chartInput, startActivityId);

    let selectedGraphics = D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('path')
      .filter((i) => i.id === startActivityId);
    selectedGraphics.classed('gantt-bar-selecting-prereqs', true);

    selectedGraphics = D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('path')
      .filter((i) => this.validPrereqIds.includes(i.id));
    selectedGraphics.classed('gantt-bar-available-prereqs', true);

    this.handleHover(null, HoverType.Dehover);
    this.isDragging = true;
    this.addLine(this.dragStartPos.x, this.dragStartPos.y);
  }

  onBarDrag(d) {
    if (!this.canEdit) return;
    if (!this.isDragging) {
      if (!this.dragStartPos) return;
      const pA = this.dragStartPos;
      const pB = { x: D3.event.sourceEvent.offsetX, y: D3.event.sourceEvent.offsetY };
      if (Utils.dist(pA, pB) > this.ganttChartService.gO.dragTolerence) {
        this.dragBarStart(d);
      }
    } else {
      this.updatePrereqLine(D3.event.sourceEvent.offsetX, D3.event.sourceEvent.offsetY);
    }
  }

  dragBarEnd(d) {
    if (!this.canEdit) return;
    if (!this.isDragging) return;
    this.isDragging = false;
    this.removeLine();
    const startActivityId = d.id;
    const endActivityId = D3.event.sourceEvent.srcElement.id;
    let selectedGraphics = D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('path')
      .filter((i) => i.id === startActivityId);
    selectedGraphics.classed('gantt-bar-selecting-prereqs', false);
    selectedGraphics = D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('path')
      .filter((i) => this.validPrereqIds.includes(i.id));
    selectedGraphics.classed('gantt-bar-available-prereqs', false);
    selectedGraphics.classed('gantt-bar-hovering-prereq', false);

    if (this.validPrereqIds.includes(endActivityId)) {
      SegmentService.track(`Gantt Chart: Added prereq drag`, { isParent: d.numChildren > 0 });
      this.addPrereqOutput.emit({ activityId: startActivityId, prereqId: endActivityId });
    }
  }

  addLine(x, y) {
    const sel = D3.select(this.prereqContainer.nativeElement);
    x = x - this.ganttChartService.gO.paddingLeftOffset;
    this.prereqLineData = [{ x: x, y: y }, { x: x, y: y }];
    this.prereqLine = sel
      .append('path')
      .attr('pointer-events', 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('fill', 'none')
      .attr('d', this.ganttChartService.gO.lineFunction(this.prereqLineData));
  }

  updatePrereqLine(x, y) {
    x = x - this.ganttChartService.gO.paddingLeftOffset;
    this.prereqLineData[1] = { x: x, y: y };
    this.prereqLine.attr('d', this.ganttChartService.gO.lineFunction(this.prereqLineData));
  }

  removeLine() {
    D3.select(this.prereqContainer.nativeElement)
      .selectAll('path')
      .remove();
  }

  firstLoadSetup() {
    this.firstLoad = false;
    this.today = moment.utc().clone().valueOf();
    const dataStartEpoch = Math.min(...this.chartDisplay.map(c => c.activity.startDate));
    const dataEndEpoch = Math.max(...this.chartDisplay.map(c => c.activity.endDate));
    if ((this.today >= dataStartEpoch) && (this.today <= dataEndEpoch)) {
      SegmentService.track(`Gantt Chart: Loaded view on current day`, {});
      this.curChartStartEpoch = moment.utc(this.today).startOf('isoWeek').valueOf();
    } else {
      SegmentService.track(`Gantt Chart: Loaded view on first activity start date`, {});
      this.curChartStartEpoch = moment.utc(dataStartEpoch).startOf('isoWeek').valueOf();
    }
    if (this.curActivitiesSelected.length > 0) {
      const activity = this.chartDisplay.find(t => this.curActivitiesSelected.includes(t.activity.id));
      this.curChartStartEpoch = activity ? activity.activity.startDate : this.curChartStartEpoch;
    }
    this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
    this.visibleEpochs = this.ganttChartService.getListOfActiveEpochs(this.curChartStartEpoch, this.ganttChartService.gO.chartRange);
    this.xAxisDataInput = this.ganttChartService.buildXaxisData(this.visibleEpochs);
    this.sortData('externalActivityId');
  }

  calculateChartWidthDimensions(): void {
    const scrollbarWidth = 20;
    const fitWidthToView = this.ganttContainer.nativeElement.getBoundingClientRect().width - scrollbarWidth;

    const minGridContainerWidth = this.ganttChartService.gO.minColumnWidth * this.xAxisDataInput.length;
    const minChartContainerWidth = minGridContainerWidth + this.ganttChartService.gO.gridOffsetLeft;

    // this.chartContainerWidth = fitWidthToView > minChartContainerWidth ? fitWidthToView : minChartContainerWidth;
    this.chartContainerWidth = fitWidthToView;
    // Addec check to make gannt chart width not more than fitToView.
    if (this.bulkEdit)
      this.chartContainerWidth = fitWidthToView;

    this.gridContainerWidth = this.chartContainerWidth - this.ganttChartService.gO.gridOffsetLeft;
  }

  updateAllFlags(){
    this.checkAllFlagForBulkEdit = this.checkAllFlagForBulkEdit == 1 ? 0 : 1;
    this.ganttChartService.updateEditFlagForAll(this.chartDisplay, this.chartInput, this.expandedIds, this.checkAllFlagForBulkEdit);
  }

  drawHeaders(): void {

    let bulkEditPadding = 0;
    let columnXStart = 337;
    if (this.bulkEdit) {
      // cAUSING SMALL ISSUE HERE FOR GANTT CHART ui SWITCH IN bulkEdit
      this.ganttChartService.gO.gridOffsetLeft = 1250;
      bulkEditPadding = 10;
    }
    else {
      this.ganttChartService.gO.gridOffsetLeft = 595;
      bulkEditPadding = 0;
    }

    this.calculateChartWidthDimensions();

    const headerTransform = `translate(0, ${this.ganttChartService.gO.chartOffsetTop})`;
    let headers = D3.select(this.headerContainer.nativeElement);
    headers
      .selectAll('g')
      .remove();

    headers = headers
      .attr('class', 'headers')
      .attr('transform', headerTransform)
      .append('g');


    // Grit Project Header
    headers
      .append('rect')
      .attr('class', 'y-axis-labels')
      .attr('fill', this.ganttChartService.gO.projectColor)
      .attr('stroke', this.ganttChartService.gO.borderColor)
      .attr('height', this.ganttChartService.gO.rowHeight)
      .attr('width', this.ganttChartService.gO.gridOffsetLeft)
      .attr('y', this.ganttChartService.gO.rowHeight)
      .on('click', () => this.collapseToRoots());

    if (this.chartDisplay.length < 1) {
      this.today = moment.utc().clone().valueOf();
      this.curChartStartEpoch = moment.utc(this.today).startOf('isoWeek').valueOf();
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
      this.visibleEpochs = this.ganttChartService.getListOfActiveEpochs(this.curChartStartEpoch, this.ganttChartService.gO.chartRange);
      this.xAxisDataInput = this.ganttChartService.buildXaxisData(this.visibleEpochs);
    }

    headers
      .append('text')
      .attr('class', 'y-axis-labels')
      .style('fill', 'white')
      .attr('y', 2 * this.ganttChartService.gO.rowHeight)
      .attr('x', 8)
      .attr('dy', '-.6em')
      .text('ID', 0)
      .on('click', () => { this.sortData('externalActivityId'); })
      .on("mouseover", function (d, i) {
        // make the row red
        D3.select(this)
          .style('stroke', 'white').transition()
          .duration(100);
      })
      .on("mouseout", function (d, i) {
        D3.select(this)
          .style('stroke', 'none').transition()
          .duration(100);
      });

    if (this.bulkEdit) {
      headers
        .append('foreignObject')
        .attr('class', 'y-axis-labels')
        .html(this.ganttChartService.getCheckBoxIconForFlag(this.checkAllFlagForBulkEdit))
        .style('color', "ffffff")
        .attr('y', this.ganttChartService.gO.rowHeight - 2)
        .attr('x', this.ganttChartService.gO.textPadding + 72 + bulkEditPadding)
        .on('click', () => { this.updateAllFlags(), this.drawChart() });
    }

    headers
      .append('text')
      .attr('class', 'y-axis-labels')
      .style('fill', 'white')
      .attr('y', 2 * this.ganttChartService.gO.rowHeight)
      .attr('x', this.ganttChartService.gO.textPadding + 92 + bulkEditPadding)
      .attr('dy', '-.6em')
      .text(this.ganttChartService.getValidYaxisLabelLength(this.projectService.currentProject.name, 0))
      .on('click', () => {
        this.collapseToRoots();
        //  this.sortData('name');
      }).on("mouseover", function (d, i) {
        // make the row red
        D3.select(this)
          .style('stroke', 'white').transition()
          .duration(100);
      })
      .on("mouseout", function (d, i) {
        D3.select(this)
          .style('stroke', 'none').transition()
          .duration(100);
      });

    headers
      .append('text')
      .attr('class', 'y-axis-labels')
      .style('fill', 'white')
      .attr('y', 2 * this.ganttChartService.gO.rowHeight)
      .attr('x', () => columnXStart)
      .attr('dy', '-.6em')
      .text('Dur')
      .on('click', () => { this.sortData('dur'); }).on("mouseover", function (d, i) {
        // make the row red
        D3.select(this)
          .style('stroke', 'white').transition()
          .duration(100);
      })
      .on("mouseout", function (d, i) {
        D3.select(this)
          .style('stroke', 'none').transition()
          .duration(100);
      });

    if (this.chartDisplay.length > 0) {
      this.projectEndDate = Math.max(...this.chartDisplay.map(c => c.activity.endDate));
      const projectedEndDate = Utils.formatDate(this.projectEndDate);
    }

    columnXStart = columnXStart + 40;

    headers
      .append('text')
      .attr('class', 'y-axis-labels')
      .style('fill', 'white')
      .attr('y', 2 * this.ganttChartService.gO.rowHeight)
      .attr('x', () => columnXStart)
      .attr('dy', '-.6em')
      .text('Start')
      .on('click', () => { this.sortData('startdate'); }).on("mouseover", function (d, i) {
        // make the row red
        D3.select(this)
          .style('stroke', 'white').transition()
          .duration(100);
      })
      .on("mouseout", function (d, i) {
        D3.select(this)
          .style('stroke', 'none').transition()
          .duration(100);
      });

    columnXStart = columnXStart + 80;

    headers
      .append('text')
      .attr('class', 'y-axis-labels')
      .style('fill', 'white')
      .attr('y', 2 * this.ganttChartService.gO.rowHeight)
      .attr('x', () => columnXStart)
      .attr('dy', '-.6em')
      .text('End')
      .on('click', () => { this.sortData('enddate'); }).on("mouseover", function (d, i) {
        // make the row red
        D3.select(this)
          .style('stroke', 'white').transition()
          .duration(100);
      })
      .on("mouseout", function (d, i) {
        D3.select(this)
          .style('stroke', 'none').transition()
          .duration(100);
      });

    columnXStart = columnXStart + 115;

    if (this.bulkEdit) {
      columnXStart = columnXStart - 40;

      headers
        .append('text')
        .attr('class', 'y-axis-labels')
        .style('fill', 'white')
        .attr('y', 2 * this.ganttChartService.gO.rowHeight)
        .attr('x', () => columnXStart)
        .attr('dy', '-.6em')
        .text('Subcontractor');

      columnXStart = columnXStart + 200;

      headers
        .append('text')
        .attr('class', 'y-axis-labels')
        .style('fill', 'white')
        .attr('y', 2 * this.ganttChartService.gO.rowHeight)
        .attr('x', () => columnXStart)
        .attr('dy', '-.6em')
        .text('Equipment');

      columnXStart = columnXStart + 150;

      headers
        .append('text')
        .attr('class', 'y-axis-labels')
        .style('fill', 'white')
        .attr('y', 2 * this.ganttChartService.gO.rowHeight)
        .attr('x', () => columnXStart)
        .attr('dy', '-.6em')
        .text('Material');

      columnXStart = columnXStart + 150;

      headers
        .append('text')
        .attr('class', 'y-axis-labels')
        .style('fill', 'white')
        .attr('y', 2 * this.ganttChartService.gO.rowHeight)
        .attr('x', () => columnXStart)
        .attr('dy', '-.6em')
        .text('Labor');

      columnXStart = columnXStart + 150;
    }

    if (this.canEdit) {
      headers
        .append('foreignObject')
        .attr('class', 'y-axis-labels')
        .style('color', () => 'white')
        .html(this.ganttChartService.gO.addIcon)
        .attr('x', () => columnXStart)
        .attr('y', this.ganttChartService.gO.rowHeight - this.ganttChartService.gO.headerIconYOffset)
        .on('click', () => this.addActivity())
        .on('mouseover', () => this.handleHover(null, HoverType.Add))
        .on('mouseout', () => this.handleHover(null, HoverType.Dehover));
    }

    if (!this.bulkEdit) {
      // Dates border
      headers
        .append('rect')
        .attr('fill', 'white')
        .attr('stroke', this.ganttChartService.gO.borderColor)
        .attr('height', this.ganttChartService.gO.rowHeight)
        .attr('width', this.gridContainerWidth - this.ganttChartService.gO.paddingOffset)
        .attr('y', this.ganttChartService.gO.rowHeight)
        .attr('x', this.ganttChartService.gO.gridOffsetLeft);
    }

    if (this.chartDisplay.length < 1) {
      this.today = moment.utc().clone().valueOf();
      this.curChartStartEpoch = moment.utc(this.today).startOf('isoWeek').valueOf();
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
      this.visibleEpochs = this.ganttChartService.getListOfActiveEpochs(this.curChartStartEpoch, this.ganttChartService.gO.chartRange);
      this.xAxisDataInput = this.ganttChartService.buildXaxisData(this.visibleEpochs);
    }
  }

  async drawChart(chartIn?: IMasterScheduleActivity[], selectId?: string, sortOnAdd?: boolean) {

    if (chartIn) this.handleNewData(chartIn, selectId);
    this.drawHeaders();
    await this.createXaxisScale();
    this.drawXaxis();
    this.chartControls = this.ganttChartService.getChartControls(this.curChartStartEpoch, this.curChartEndEpoch);

    if (this.chartDisplay.length < 1) {
      this.clearChartRows();
      return;
    }

    await this.setChartBarAndRowData();
    this.calculateChartHeightDimension();
    this.drawRowHoverAreas();
    this.drawGrid();

    if (!this.bulkEdit) {
      this.drawBars();
      this.drawOverlayBars();
      this.drawYaxis();
    }
    else {
      this.drawBulkEditYaxis();
    }


    this.ganttChartService.drawColumnSelections(this.colSelected, await this.xScaleTime, this.columnSelContainer.nativeElement, this.rows.length);
    this.stickyHeader();
    if (sortOnAdd === true)
      this.sortData('externalActivityId')
  }

  clearChartRows(): void {
    D3.select(this.gridContainer.nativeElement)
      .selectAll('path')
      .remove();
    D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('path')
      .remove();
    D3.select(this.rowHoverAreas.nativeElement)
      .selectAll('rect')
      .remove();
    D3.select(this.yAxis.nativeElement)
      .selectAll('g')
      .remove();
  }

  handleNewData(chartIn: IMasterScheduleActivity[], selectId?: string) {
    const newData = this.ganttChartService.removeInputWithNoDates(chartIn);
    this.chartInput = newData;
    this.chartDisplay = this.ganttChartService.getRootParents(this.chartInput, null, true);
    if (selectId) {
      const selectedItem = this.chartInput.find(item => item.activity.id === selectId);
      if (selectedItem && selectedItem.activity.parentId) {
        if (!this.expandedIds.includes(selectedItem.activity.parentId)) this.expandedIds.push(selectedItem.activity.parentId);
      }

      if (this.projectService.viewerMode === ViewerMode.ForgViewer){
        this.curActivitiesSelected = [selectId];
        this.chartSelectionOutput.emit(this.curActivitiesSelected);
      } else {
        // Not requred for PDF.
      }

    }

    const idsToCheck = this.chartInput.map(item => item.activity.id);
    for (let i = this.expandedIds.length; i >= 0; i--) {
      if (!idsToCheck.includes(this.expandedIds[i])) {
        this.expandedIds.splice(i, 1);
      } else {
        this.ganttChartService.expandFromChildParentId(this.expandedIds[i], this.chartDisplay, this.chartInput);
      }
    }
  }

  // Chart Controls
  async moveRange(forward: boolean, rangeMovement: number) {
    if (forward) {
      this.curChartStartEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, rangeMovement).valueOf();
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
    } else {
      this.curChartStartEpoch = moment.utc(this.curChartStartEpoch).clone().subtract(this.ganttChartService.gO.chartInterval, rangeMovement).valueOf();
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
    }
    await this.drawChartWithCurEpochs();
  }

  collapseToRoots() {
    this.chartDisplay.forEach(item => item.expanded = false);
    this.expandedIds = [];
    this.chartDisplay = this.ganttChartService.getRootParents(this.chartInput, null, true);
    this.resetAllSelections();
    this.chartSelectionOutput.emit(this.curActivitiesSelected);
    this.sortData('externalActivityId');
    this.drawChart();
  }

  applyfilter() {
    this.ganttChartService.updateChartDisplayForFilter(this.chartDisplay, this.chartInput, this.filterQuery, this.expandedIds, null);
  }

  async setChartBarAndRowData() {
    this.rows = [];
    this.barData = [];
    this.overlayBarData = [];
    const getTaskList = this.showAllTasks && (this.ganttChartService.curGanttDisplay !== ViewMode.SixMonthGantt && this.ganttChartService.curGanttDisplay !== ViewMode.TwelveMonthGantt);
    if (getTaskList && this.selectedScenarioId) this.tasksList = await this.masterScheduleService.getTasksList(this.selectedScenarioId, this.curChartStartEpoch, this.curChartEndEpoch);
    let rowScaleRangeStart = this.ganttChartService.gO.barPadding / 2;
    // tslint:disable-next-line:cyclomatic-complexity
    this.chartDisplay.forEach(async (c) => {
      const objectIds = c.activity.objectIds ? c.activity.objectIds : [];
      const sub = this.subcontractorService.getLocalProjectSubcontractor(c.subContractorId);

      const matIds = c.activity.materialIds ? c.activity.materialIds : [];
      const eqipIds = c.activity.equipmentIds ? c.activity.equipmentIds : [];
      const laborIds = c.activity.laborIds ? c.activity.laborIds : [];
      const rfiIds = c.activity.rfiIds ? c.activity.rfiIds : [];


      let materials = "";
      let equipments = "";
      let labors = "";

      matIds.forEach(matId => {
        if (materials.length > 0)
          materials = materials + ", " + this.projectService.getMaterialService().getLocalMaterialById(matId).name;
        else
          materials = this.projectService.getMaterialService().getLocalMaterialById(matId).name;
      });

      eqipIds.forEach(eqId => {
        if (equipments.length > 0)
          equipments = equipments + ", " + this.projectService.getEquipmentService().getLocalEquipmentById(eqId).name;
        else
          equipments = this.projectService.getEquipmentService().getLocalEquipmentById(eqId).name;
      });

      laborIds.forEach(laborId => {
        if (labors.length > 0)
          labors = labors + ", " + this.projectService.getLaborService().getLocalLaborById(laborId).name;
        else
        labors = this.projectService.getLaborService().getLocalLaborById(laborId).name;
      });

      const milestone = this.milestoneService.getLocalProjectMilestone(c.milestoneId);

      let startDayEpoch = c.activity.startDate;
      if (c.activity.started && !c.activity.actualFinishDate) {
        startDayEpoch = c.activity.actualStartDate;
      }

      if(c.activity.actualStartDate){
        startDayEpoch = c.activity.actualStartDate;
      }

      const formattedStartDate = c.activity.startDate ? Utils.formatDate(c.activity.startDate) : null;
      const expectedFinishDate = c.activity.expectedFinishDate;
      const formattedExpectedFinishDate = expectedFinishDate ? Utils.formatDate(expectedFinishDate) : null;
      let endDayEpoch = c.activity.endDate;
      if (c.activity.started && expectedFinishDate) {
        endDayEpoch = expectedFinishDate;
      }

      if(c.activity.actualFinishDate){
        endDayEpoch = c.activity.actualFinishDate;
      }

      const formattedEndDate = c.activity.endDate ? Utils.formatDate(c.activity.endDate) : null;
      const chartActivityStart = this.ganttChartService.setChartActivityStart(startDayEpoch, endDayEpoch, this.curChartStartEpoch);
      const chartActivityEnd = this.ganttChartService.setChartActivityEnd(endDayEpoch, this.curChartEndEpoch);

      const rowScaleRangeEnd = rowScaleRangeStart + this.ganttChartService.gO.rowHeight;
      this.createRowScale(c.activity.id, rowScaleRangeStart, rowScaleRangeEnd, [0]);
      rowScaleRangeStart = rowScaleRangeEnd;

      let prerequisiteIds: string[] = [];
      c.activity.prerequisites.forEach(elemnt => { prerequisiteIds.push(elemnt.activity) });

      const rowObject: IGanttChartRow = {
        id: c.activity.id,
        label: this.ganttChartService.getValidYaxisLabelLength(c.activity.name, c.activity.depth),
        rowId: c.activity.id,
        externalActivityId: c.activity.externalActivityId,
        objectIds: objectIds,
        xCoord: this.ganttChartService.getYaxisLabelXcoord(c.activity.depth),
        yCoord: this.rowScales[c.activity.id](0),
        textColor: null,
        name: c.activity.name,
        expanded: c.expanded,
        depth: c.activity.depth,
        duration: c.activity.duration,
        subName: sub ? sub.name : '(Not Assigned)',
        materials: materials.length > 0 ? materials : '(Not Assigned)',
        equipments: equipments.length > 0 ? equipments : '(Not Assigned)',
        labors: labors.length > 0 ? labors : '(Not Assigned)',
        prerequisiteIds: prerequisiteIds,
        milestoneName: milestone ? milestone.name : '(Not Assigned)',
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        expectedFinishDate: formattedExpectedFinishDate,
        completed: c.activity.actualFinishDate || null,
        started: c.activity.started || null,
        startDayEpoch: startDayEpoch,
        endDayEpoch: endDayEpoch,
        color: this.ganttChartService.getYaxisLabelBackgroundColor(c.activity.depth),
        numChildren: c.activity.children.length,
        bulkEditSelection: c.bulkEditSelection,
        materialIds: c.activity.materialIds,
        rfiIds: c.activity.rfiIds
      };
      rowObject.textColor = Utils.textBlackOrWhiteHex(rowObject.color);
      this.rows.push(rowObject);

      if (chartActivityStart >= this.curChartStartEpoch && chartActivityStart < this.curChartEndEpoch) {

        let barWidth = (this.xScaleTime(chartActivityEnd) - this.xScaleTime(chartActivityStart)) - this.ganttChartService.gO.barPadding;
        barWidth = barWidth > this.ganttChartService.gO.minBarWidth ? barWidth : this.ganttChartService.gO.minBarWidth;

        const barObject: IGanttChartBar = cloneDeep(rowObject);
        barObject.selected = this.curActivitiesSelected.includes(c.activity.id) ? true : false;
        barObject.stroke = 'none';
        barObject.color = sub && c.activity.children.length < 1 ? sub.hexCode : 'gray';


        let hasMatPreparation: boolean = false;
        if (this.toggleProcurement) {
          if (c.activity.materialIds.length) {
            let greaterMaterialDays: any = 0;
            let greaterMaterialId: any;
            let greaterRfiDays: any = 0;
            let greaterRfiId: any;
            if (c.activity.materialIds.length > 0) {
              c.activity.materialIds.forEach(mId => {
                this.materialResults1.forEach(async (m) => {
                  if (m.id === mId) {
                    if (greaterMaterialDays < (m.prepareLeadDays + m.fabricationLeadDays + m.reviewLeadDays)) {
                      greaterMaterialDays = m.prepareLeadDays + m.fabricationLeadDays + m.reviewLeadDays;
                      greaterMaterialId = mId;
                    }

                  }
                });
              });

            }

            c.activity.materialIds.forEach(mId => {
              this.materialResults1.forEach(async (m) => {
                if (m.id === mId) {
                  if (greaterMaterialId) {
                    if (greaterMaterialId !== mId)
                      return;
                  }

                  var materialProcure: any;
                  if(Array.isArray(this.scheduleData)){
                    this.scheduleData.forEach(schedule => {
                      if (schedule.activities[0] === c.activity.id) {
                        schedule.materials.forEach(material => {
                          if (material.id === greaterMaterialId) {
                            materialProcure = material;

                            const dueDate = this.getMaterialDueDate(materialProcure, schedule.scheduledStart);
                            const status = material.makeReadyStatus ? material.makeReadyStatus : MakeReadyStatus.scheduled;
                            let tMinusDisplay = this.getTMinusHours(dueDate);
                            materialProcure.tMinusDisplay = tMinusDisplay;
                            barObject.tMinusDisplay = tMinusDisplay;
                          }
                        });
                      }
                    });
                  }

                  if (!materialProcure) {
                    materialProcure = m;
                  }
                  hasMatPreparation = true;

                  if (!Utils.isEmpty(materialProcure.submitByDate)) {
                    if (materialProcure.makeReadyStatus < 1) {

                      let materialReview = Utils.getUtcSubstract(chartActivityStart, (materialProcure.reviewLeadDays + materialProcure.fabricationLeadDays + materialProcure.prepareLeadDays));
                      barObject.materialReview = Utils.fromUtcDate(materialReview).toLocaleDateString();
                      barObject.submitByDate = materialReview;
                      barObject.ActualSubmitBy = materialProcure.submitByDate;

                      // const submitByDateDue = this.getMaterialDueDate(materialProcure, materialProcure.submitByDate);
                      let tMinusDisplayDueSubmitDate = this.getTMinusHours(materialProcure.submitByDate);
                      barObject.tMinusDisplayDueSubmitDate = tMinusDisplayDueSubmitDate;
                    }
                    // barObject.matstartDate = materialProcure.startDate;
                  }
                  if (!Utils.isEmpty(materialProcure.requredOnSiteDate)) {
                    if (materialProcure.makeReadyStatus < 3) {
                      let materialROS = Utils.getUtcSubstract(chartActivityStart, (materialProcure.fabricationLeadDays + materialProcure.prepareLeadDays));
                      barObject.materialROS = Utils.fromUtcDate(materialROS).toLocaleDateString();
                      barObject.requredOnSiteDate = materialROS;
                      barObject.ActualROS = materialProcure.requredOnSiteDate;

                      // const ROSdateDue = this.getMaterialDueDate(materialProcure, materialProcure.requredOnSiteDate);
                      let tMinusDisplayDueROS = this.getTMinusHours(materialProcure.requredOnSiteDate);
                      barObject.tMinusDisplayDueROS = tMinusDisplayDueROS;
                    }
                    // barObject.requredOnSiteDate= materialProcure.requredOnSiteDate;
                  }
                  if (!Utils.isEmpty(materialProcure.startDate)) {

                    if (materialProcure.makeReadyStatus < 4) {
                      let materialStart = Utils.getUtcSubstract(chartActivityStart, materialProcure.prepareLeadDays);
                      barObject.materialStart = Utils.fromUtcDate(materialStart).toLocaleDateString();
                      barObject.matstartDate = materialStart;

                      // const approveByDateDue = this.getMaterialDueDate(materialProcure, materialStart);
                      // barObject.submitByDate = materialProcure.submitByDate;
                    }
                  }
                  if (!Utils.isEmpty(materialProcure.approvalDate)) {
                    barObject.materialName = materialProcure.name;
                    barObject.ActualApproved = materialProcure.approvalDate;

                    let tMinusDisplayDueApproved = this.getTMinusHours(materialProcure.approvalDate);
                    barObject.tMinusDisplayDueApproved = tMinusDisplayDueApproved;
                  }
                }
              });
            })
          }

          if(c.activity.rfiIds){
            if (c.activity.rfiIds.length) {
              c.activity.rfiIds.forEach(rfId => {
                this.rfiResults.forEach(async (r) => {
                  if (r.id === rfId) {
                    if (!Utils.isEmpty(r.initiatedAt)) {
                      barObject.rfiName = r.subject;
                      let rfiStart = Utils.getUtcSubstract(chartActivityStart, (r.prepareLeadDays));
                      barObject.rfiStart = Utils.fromUtcDate(rfiStart).toLocaleDateString();
                      barObject.rfiStartDate = rfiStart;
                    }
                    if (!Utils.isEmpty(r.dueDate)) {
                      let rfiReview = Utils.getUtcSubstract(chartActivityStart, r.prepareLeadDays + r.reviewLeadDays);
                      barObject.rfiReview = Utils.fromUtcDate(rfiReview).toLocaleDateString();
                      barObject.dueDate = rfiReview;
                    }
                  }
                });
              });
            }
          }

        }

        const barHeight = this.ganttChartService.getBarHeight(c.activity.children.length);
        const hasStartMarker = startDayEpoch === chartActivityStart && c.activity.children.length > 0 ? true : false;
        const hasEndMarker = endDayEpoch === chartActivityEnd && c.activity.children.length > 0 ? true : false;
        const startYCoord = this.ganttChartService.getBarYcoord(c.activity.id, barHeight, this.rowScales);
        // const hasMatPreparation = barObject.matstartDate ? true: false;
        // if(hasMatPreparation)
        let chartMaterialActivityStartSubmitDate: number, matbarWidth: number, rfbarWidth: number, chartRfiActivityStartPrepation: number,
          chartRfiActivityStartSubmitDate: number, chartMaterialActivityStartPrepation: number;
        const hasRfiPreparation = barObject.rfiStartDate ? true : false;

        if (this.toggleProcurement) {
          if (hasMatPreparation) {
            if (barObject.submitByDate) {
              chartMaterialActivityStartSubmitDate = this.ganttChartService.setChartActivityStart(barObject.submitByDate, chartActivityStart, this.curChartStartEpoch);
            }
            else if (barObject.requredOnSiteDate) {
              chartMaterialActivityStartSubmitDate = this.ganttChartService.setChartActivityStart(barObject.requredOnSiteDate, chartActivityStart, this.curChartStartEpoch);
            }
            else if (barObject.matstartDate) {
              chartMaterialActivityStartSubmitDate = this.ganttChartService.setChartActivityStart(barObject.matstartDate, chartActivityStart, this.curChartStartEpoch);
            }

            const chartMaterialActivityStartFND = this.ganttChartService.setChartActivityStart(barObject.requredOnSiteDate, chartActivityStart, this.curChartStartEpoch);
            chartMaterialActivityStartPrepation = this.ganttChartService.setChartActivityStart(barObject.matstartDate, chartActivityStart, this.curChartStartEpoch);
            matbarWidth = (this.xScaleTime(chartActivityStart) - this.xScaleTime(chartMaterialActivityStartSubmitDate)) - this.ganttChartService.gO.barPadding;
            matbarWidth = matbarWidth > this.ganttChartService.gO.minBarWidth ? matbarWidth : this.ganttChartService.gO.minBarWidth;

            // Circle at preparation day
            const materialbarXCoordSubmitDate = this.xScaleTime(chartMaterialActivityStartSubmitDate) + 1;
            const materialbarXCoordFND = this.xScaleTime(chartMaterialActivityStartFND) + 1;
            const materialbarXCoordPrepation = this.xScaleTime(chartMaterialActivityStartPrepation) + 1;
            barObject.circlePreparation = materialbarXCoordPrepation;
            barObject.circleFND = materialbarXCoordFND;
            barObject.circleSubmitedby = materialbarXCoordSubmitDate;
            barObject.circleCenters = this.ganttChartService.makeActivityCircleCenterData(startYCoord, hasMatPreparation,
              materialbarXCoordPrepation, materialbarXCoordFND, materialbarXCoordSubmitDate, matbarWidth);
          }

          if (hasRfiPreparation) {
            chartRfiActivityStartPrepation = this.ganttChartService.setChartActivityStart(barObject.rfiStartDate, chartActivityStart, this.curChartStartEpoch);
            chartRfiActivityStartSubmitDate = this.ganttChartService.setChartActivityStart(barObject.dueDate, chartActivityStart, this.curChartStartEpoch);
            rfbarWidth = (this.xScaleTime(chartActivityStart) - this.xScaleTime(chartRfiActivityStartSubmitDate)) - this.ganttChartService.gO.barPadding;
            rfbarWidth = rfbarWidth > this.ganttChartService.gO.minBarWidth ? rfbarWidth : this.ganttChartService.gO.minBarWidth;

            // circle at preparation day
            const rfiBarXCoordPrepation = this.xScaleTime(chartRfiActivityStartPrepation) + 1;
            const rfiBarXCoordSubmitDate = this.xScaleTime(chartRfiActivityStartSubmitDate) + 1;
            barObject.circleRfiPreparation = rfiBarXCoordPrepation;
            barObject.circleRfiSubmittedBy = rfiBarXCoordSubmitDate;

            barObject.rfiCircleCenters = this.ganttChartService.makeActivityRfiCircleCenterData(startYCoord, hasRfiPreparation,
              rfiBarXCoordPrepation, rfiBarXCoordSubmitDate, rfbarWidth);
          }

        }

        barObject.pathData = this.ganttChartService.makeActivityBarPathData(this.xScaleTime(chartActivityStart), startYCoord, barWidth, hasStartMarker, hasEndMarker, c.activity.children.length > 0,
          hasMatPreparation, this.xScaleTime(chartMaterialActivityStartSubmitDate), matbarWidth, hasRfiPreparation, this.xScaleTime(chartRfiActivityStartSubmitDate), rfbarWidth);
        this.barData.push(barObject);
      }

      // SET OVERLAY BARS
      if (this.tasksList && getTaskList) {
        const getTasks = this.tasksList[c.activity.id];
        if (getTasks && getTasks.length > 0) {
          this.setOverlayBarData(getTasks, c.activity.id);
        }
      }
    }
    );
  }

  getMaterialDueDate(material, scheduledStart: number): number {
    switch (material.makeReadyStatus) {
      case MakeReadyStatus.scheduled:
      case MakeReadyStatus.approved:
        return material.approvalDate;
      case MakeReadyStatus.ordered:
        return scheduledStart - (material.prepareLeadDays * 24 * 60 * 60 * 1000);
      case MakeReadyStatus.delivered:
        return scheduledStart;
      case MakeReadyStatus.ready:
        return scheduledStart;
    }
  }

  getTMinusHours(dueDate: number): string {
    const today = Utils.getCurrentDate();
    return dueDate <= today
      ? `T+` + moment(today).diff(moment(dueDate), 'days')
      : `T-` + moment(dueDate).diff(moment(today), 'days');
  }

  setOverlayBarData(overlayBarArray: any[], actId: string) {
    overlayBarArray.forEach(item => {
      const info = item.info;
      const taskInfo = item.taskInfo;
      const startDayEpoch = taskInfo.scheduledStart;
      const formattedStartDate = startDayEpoch ? moment.utc(startDayEpoch).clone().format('M / D / YYYY') : null;

      const endDayEpoch = taskInfo.scheduledEnd;
      const formattedEndDate = endDayEpoch ? moment.utc(endDayEpoch).clone().format('M / D / YYYY') : null;
      const chartTaskStart = this.ganttChartService.setChartActivityStart(startDayEpoch, endDayEpoch, this.curChartStartEpoch);
      const chartTaskEnd = this.ganttChartService.setChartActivityEnd(endDayEpoch, this.curChartEndEpoch);

      const valid = chartTaskStart >= this.curChartStartEpoch && chartTaskStart < this.curChartEndEpoch;

      if (valid) {
        const barWidth = (this.xScaleTime(chartTaskEnd) - this.xScaleTime(chartTaskStart)) - this.ganttChartService.gO.barPadding;
        const objectIds = this.projectService.getLocalActivity(taskInfo.id);
        const taskStatus = this.ganttChartService.getStatus(info.stepStatus);
        const barInfo = {
          name: info.name,
          durationHours: taskInfo.durationHours,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          height: this.ganttChartService.getBarHeight(0) / 2,
          width: barWidth > this.ganttChartService.gO.minBarWidth ? barWidth : this.ganttChartService.gO.minBarWidth,
          objectIds: objectIds,
          xCoord: this.xScaleTime(chartTaskStart),
          yCoord: 0, // calculated after height assigned
          status: taskStatus,
          color: StepStatusBarColor[taskStatus],
          stroke: '#525266',
          textColor: 'white',
          id: taskInfo.id,
          rowId: actId,
          depth: actId,
          markerYpos: this.rowScales[actId](0) + (this.ganttChartService.gO.blockMarkerOffset / 2),
        };
        barInfo.yCoord = this.ganttChartService.getBarYcoord(actId, barInfo.height * 2, this.rowScales) + (barInfo.height / 2);
        this.overlayBarData.push(barInfo);
      }
    });
  }

  drawRowHoverAreas() {
    const rowTransform = `translate(${this.ganttChartService.gO.barPadding} , ${this.ganttChartService.gO.gridOffsetTop})`;
    const invisibleRows = D3.select(this.rowHoverAreas.nativeElement)
      .attr('transform', rowTransform)
      .selectAll('rect')
      .remove()
      .exit()
      .data(this.rows)
      .enter();

    invisibleRows
      .append('rect')
      .attr('fill', 'transparent')
      .attr('height', () => this.ganttChartService.gO.rowHeight)
      .attr('width', this.xScaleTime(this.curChartEndEpoch))
      .attr('x', this.ganttChartService.gO.gridOffsetLeft - this.ganttChartService.gO.barPadding)
      .attr('y', (d) => this.rowScales[d.rowId](0))
      .attr('rx', '10')
      .attr('ry', '10')
      .on('mouseover', (d) => this.handleHover(d, HoverType.RowHover))
      .on('mouseout', () => this.handleHover(null, HoverType.Dehover));

  }

  drawBars() {
    const bars = D3.select(this.chartBarsContainer.nativeElement)
      .selectAll('*')
      .remove()
      .exit()
      .data(this.barData)
      .enter();

    // Visual bar
    bars
      .append('path')
      .attr('id', (d) => d.id)
      .attr('fill', (d) => d.color)
      .attr('stroke', (d) => d.stroke)
      .attr('d', (d) => this.ganttChartService.gO.lineFunction(d.pathData))
      .attr('class', (d) => d.selected
        ? 'D3-chart-bar _' + d.id + ' _bar gantt-bar-selected'
        : 'D3-chart-bar _' + d.id + ' _bar'
      )
      .on('click', (d) => this.handleBarClick(d))
      .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
      .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover))
      .call(D3.drag()
        .on('start', () => this.dragStarted())
        .on('drag', (d) => this.onBarDrag(d))
        .on('end', (d) => this.dragBarEnd(d)));

    if (this.toggleProcurement) {

      bars.append("circle")
        .attr("visibility", function (d, i) {
          if (d.circleCenters && !isNaN(d.circleCenters[0].x))
            return "visible";
          else
            return "hidden";
        })
        .attr("cx", (d) => {
          if (d.circleCenters && !isNaN(d.circleCenters[0].x))
            return d.circleCenters[0].x
        })
        .attr("cy", (d) => {
          if (d.circleCenters && !isNaN(d.circleCenters[0].x))
            return d.circleCenters[0].y + 1
        })
        .attr("r", 4)
        .on('mouseover', (d) => this.handleHover(d, HoverType.materialCircle))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover))
        .transition()
        .duration(2000)
        .attr('fill', "white")
        .attr('stroke', "black");

      bars.append("circle")
        .attr("visibility", function (d, i) {
          if (d.circleCenters && !isNaN(d.circleCenters[1].x))
            return "visible";
          else
            return "hidden";
        })
        .attr("cx", (d) => {
          if (d.circleCenters && !isNaN(d.circleCenters[1].x))
            return d.circleCenters[1].x
        })
        .attr("cy", (d) => {
          if (d.circleCenters && !isNaN(d.circleCenters[1].x))
            return d.circleCenters[1].y + 1
        })
        .attr("r", 4)
        .on('mouseover', (d) => this.handleHover(d, HoverType.materialCircle))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover))
        .transition()
        .duration(2000)
        .attr('fill', "white")
        .attr('stroke', "black");

      bars.append("circle")
        .attr("visibility", function (d, i) {
          if (d.circleCenters && !isNaN(d.circleCenters[2].x))
            return "visible";
          else
            return "hidden";
        })
        .attr("cx", (d) => {
          if (d.circleCenters && !isNaN(d.circleCenters[2].x))
            return d.circleCenters[2].x
        })
        .attr("cy", (d) => {
          if (d.circleCenters && !isNaN(d.circleCenters[2].x))
            return d.circleCenters[2].y + 1
        })
        .attr("r", 4)
        .on('mouseover', (d) => this.handleHover(d, HoverType.materialCircle))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover))
        .transition()
        .duration(2000)
        .attr('fill', "white")
        .attr('stroke', "black");

      bars.append("rect")
        .attr("visibility", function (d, i) {
          if (d.rfiCircleCenters && !isNaN(d.rfiCircleCenters[0].x))
            return "visible";
          else
            return "hidden";
        })
        .attr("transform", (d) => {
          if (d.rfiCircleCenters)
            return `translate(${d.rfiCircleCenters[0].x - 10 / 2}, ${d.rfiCircleCenters[0].y - 10 / 2}) rotate(45)`;
        })
        .attr("height", 8)
        .attr("width", 8)
        .on('mouseover', (d) => this.handleHover(d, HoverType.rfiCircle))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover))
        .attr('fill', "white")
        .attr('stroke', "black");

      bars.append("rect")
        .attr("visibility", function (d, i) {
          if (d.rfiCircleCenters && !isNaN(d.rfiCircleCenters[1].x))
            return "visible";
          else
            return "hidden";
        })
        .attr("transform", (d) => {
          if (d.rfiCircleCenters)
            return `translate(${d.rfiCircleCenters[1].x - 10 / 2}, ${d.rfiCircleCenters[1].y - 10 / 2}) rotate(45)`;
        })
        .attr("height", 8)
        .attr("width", 8)
        .on('mouseover', (d) => this.handleHover(d, HoverType.rfiCircle))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover))
        .attr('fill', "white")
        .attr('stroke', "black");
    }

  }

  drawOverlayBars() {
    // Overlay Bars that draws sub bars within the main bar as overlay
    const barOverlay = D3.select(this.overlayBarsContainer.nativeElement)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(this.overlayBarData)
      .enter();

    barOverlay
      .append('rect')
      .attr('class', 'overlay-tasks')
      .attr('fill', (d) => d.color)
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('x', (d) => d.xCoord + (this.ganttChartService.gO.barPadding / 2)) // offsets it from grid border
      .attr('y', (d) => d.yCoord)
      .attr('rx', '10')
      .attr('ry', '10')
      .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
      .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  // Sets resets bar color along with it's predecessor and successor
  // handels bar click event
  handleBarClick(bar: any): void {

    SegmentService.track(`Gantt Chart: Clicked gantt bar`, { isParent: bar.numChildren > 0 });
    this.clearColumnSelections();
    bar.selected = true;
    const selectedGraphics = D3.selectAll('.D3-chart-bar').filter('._' + bar.id).filter('._bar');

    selectedGraphics.classed('gantt-bar-selecting-prereqs', false);
    selectedGraphics.classed('gantt-bar-available-prereqs', false);
    selectedGraphics.classed('gantt-bar-hovering-prereq', false);

    D3.selectAll('.D3-tooltip')
      .style('display', 'none');

    if (this.curActivitiesSelected.includes(bar.id)) {
      this.resetAllSelections();
      this.curActivitiesSelected.splice(this.curActivitiesSelected.indexOf(bar.id), 1);

    } else {
      this.resetAllSelections();
      let predecessors = bar.prerequisiteIds;
      let sucessors = [];

      this.barData.forEach(d3bar => {
        if (d3bar.prerequisiteIds.includes(bar.id)) {
          sucessors.push(d3bar.id);
        }
      });

      this.barData.forEach(d3bar => {

        if (d3bar.id === bar.id) {
          selectedGraphics.classed('gantt-bar-selected', true);
        } else if (predecessors.includes(d3bar.id)) {
          const predecessorGraphics = D3.selectAll('.D3-chart-bar').filter('._' + d3bar.id).filter('._bar');
          predecessorGraphics.classed('gantt-bar-predecessor', true);
        } else if (sucessors.includes(d3bar.id)) {
          const successorGraphics = D3.selectAll('.D3-chart-bar').filter('._' + d3bar.id).filter('._bar');
          successorGraphics.classed('gantt-bar-successor', true);
        } else {
          const otherGaphics = D3.selectAll('.D3-chart-bar').filter('._' + d3bar.id).filter('._bar');
          otherGaphics.classed('gantt-bar-dim', true);
        }

      });
      this.curActivitiesSelected.push(bar.id);
    }

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      let activitesInRangeId = [];
      if (this.curActivitiesSelected.length > 0)
        activitesInRangeId.push({ activityId: bar.id, color: bar ? bar.color : "gray" });
      this.viewerObjectOutput.emit(activitesInRangeId);
      this.chartSelectionOutput.emit(this.curActivitiesSelected);
    } else {
      this.chartSelectionOutput.emit(this.curActivitiesSelected);
    }
  }



  handleHover(data: any, hoverType: HoverType): void {
    if (this.isDragging) {
      if (!data || !data.id || !this.validPrereqIds.includes(data.id)) return;
      const selectedGraphics = D3.select(this.chartBarsContainer.nativeElement)
        .selectAll('path')
        .filter((i) => i.id === data.id);
      switch (hoverType) {
        case HoverType.DataInfo:
          selectedGraphics.classed('gantt-bar-hovering-prereq', true);
          this.hoverObjectsOutput.emit(data.objectIds);
          break;
        case HoverType.Dehover:
          selectedGraphics.classed('gantt-bar-hovering-prereq', false);
          this.hoverObjectsOutput.emit([]);
          break;
        case HoverType.rfiCircle:
          selectedGraphics.classed('gantt-bar-hovering-prereq', false);
          this.hoverObjectsOutput.emit([]);
          break;
        case HoverType.materialCircle:
          selectedGraphics.classed('gantt-bar-hovering-prereq', true);
          this.hoverObjectsOutput.emit(data.objectIds);
          break;

      }
    } else {
      if (!this.isHscrolling) {
        switch (hoverType) {
          case HoverType.DataInfo:
            this.ganttChartService.drawInfoTooltip(data);
            this.drawRowHover(data);
            break;
          case HoverType.materialCircle:
            this.ganttChartService.drawMaterialInfoTooltip(data);
            this.drawRowHover(data);
            break;
          case HoverType.rfiCircle:
            this.ganttChartService.drawRfiInfoTooltip(data);
            this.drawRowHover(data);
            break;
          case HoverType.Assign:
            this.ganttChartService.drawTextTooltip(TranslationService.translate('assign_objects'));
            this.drawRowHover(data);
            break;
          case HoverType.AssignWorkArea:
            this.ganttChartService.drawTextTooltip(TranslationService.translate('assign_work_area'));
            this.drawRowHover(data);
            break;
          case HoverType.Edit:
            this.ganttChartService.drawTextTooltip(TranslationService.translate('edit_activity'));
            this.drawRowHover(data);
            break;
          case HoverType.Add:
            this.ganttChartService.drawTextTooltip(TranslationService.translate('add_child'));
            this.drawRowHover(data);
            break;
          case HoverType.ProjectedEndDate:
            this.ganttChartService.drawTextTooltip(TranslationService.translate('projected_completion_date'));
            this.drawRowHover(data);
            break;
          case HoverType.Expand:
            if (data.expanded) this.ganttChartService.drawTextTooltip(TranslationService.translate('collapse_menu_item'));
            else this.ganttChartService.drawTextTooltip(TranslationService.translate('expand_menu_item'));
            this.drawRowHover(data);
            break;
          case HoverType.RowHover:
            this.drawRowHover(data);
            break;
          case HoverType.Dehover:
            this.ganttChartService.clearTooltip();
            this.drawRowHover();
            break;
        }
      }
    }
  }

  drawRowHover(data: any = null) {
    const hoverRect = D3.select(this.rowHover.nativeElement)
      .attr('class', 'headers')
      .attr('fill', 'black')
      .attr('stroke', 'transparent')
      .style('opacity', 0);
    let hoveredObjectIds: string[] = [];
    if (data) {
      const yPos = this.rowScales[data.rowId](0) + this.ganttChartService.gO.gridOffsetTop;
      const hoverTransform = `translate(0, ${yPos})`;
      hoverRect
        .attr('transform', hoverTransform)
        .attr('class', 'headers')
        .attr('fill', 'black')
        .attr('stroke', 'transparent')
        .style('opacity', .2)
        .attr('height', this.ganttChartService.gO.rowHeight)
        .attr('width', this.chartContainerWidth - this.ganttChartService.gO.paddingOffset);
      if (data.objectIds) {
        hoveredObjectIds = data.objectIds;
      }
    }
    if (this.projectService.viewerMode === ViewerMode.ForgViewer) {
      this.hoverObjectsOutput.emit(hoveredObjectIds);
    } else {
      if (data) {
        this.hoverObjectsOutput.emit([data.id]);
      } else this.hoverObjectsOutput.emit(null);
    }
  }

  async drawGrid() {
    this.gridLineData = [];
    const bottomY = this.rows.length * this.ganttChartService.gO.rowHeight;
    for (const xAxisItem of this.xAxisDataInput) {
      this.gridLineData.push({ x: this.xScaleTime(xAxisItem.day), y: 0 });
      this.gridLineData.push({ x: this.xScaleTime(xAxisItem.day), y: bottomY });
      this.gridLineData.push(null);
    }

    // Bottom line
    this.gridLineData.push({ x: 0, y: bottomY });
    this.gridLineData.push({ x: this.gridContainerWidth - this.ganttChartService.gO.paddingOffset, y: bottomY });
    this.gridLineData.push(null);

    const grid = D3.select(this.gridContainer.nativeElement);
    grid
      .attr('width', this.gridContainerWidth)
      .selectAll('path')
      .remove();
    grid
      .append('path')
      .attr('pointer-events', 'none')
      .attr('stroke', this.ganttChartService.gO.borderColor)
      .attr('stroke-width', this.ganttChartService.gO.lineWidth)
      .attr('fill', 'none')
      .attr('d', this.ganttChartService.gO.lineFunction(this.gridLineData));
  }

  async drawXaxis() {
    this.xAxisTransform = `translate(${this.ganttChartService.gO.gridOffsetLeft}, ${this.ganttChartService.gO.xAxisLabelYpos})`;
    const drawIn = [];
    this.xAxisDataInput.forEach(item => { drawIn.push(item); });
    if (drawIn) {
      drawIn.splice(drawIn.length - 1, 1); // Do not draw the last date label
      const xAxisLabel = D3.select(this.xAxis.nativeElement)
        .attr('class', 'x-axis-labels')
        .attr('transform', this.xAxisTransform)
        .selectAll('text')
        .remove()
        .exit()
        .data(drawIn)
        .enter();

      // Added check to not allow drawing date column
      if (!this.bulkEdit) {
        xAxisLabel
          .append('text')
          .attr('transform', (d, i) => this.ganttChartService.getXaxisChartLabelPos(d.day, this.xAxisDataInput[i + 1].day, this.xScaleTime))
          .attr('text-anchor', 'middle')
          .attr('class','date-font')
          .attr('dy', '.2em')
          .text((d) => Utils.formatDateToDayAndMonthString(d.day))
          .on('click', (d) => {
            this.xAxisClick(d);
            SegmentService.track(`Gantt Chart: Column click`, {});
          });
      }
    }
  }

  async xAxisClick(xAxisItem, disablePage: boolean = true) {
    this.resetAllSelections();
    const index = this.colSelected.indexOf(xAxisItem.day);
    if (index > -1) {
      this.colSelected = [];
    } else {
      this.colSelected = [xAxisItem.day];
    }
    this.ganttChartService.drawColumnSelections(this.colSelected, this.xScaleTime, this.columnSelContainer.nativeElement, this.rows.length);

    let viewerObj = {};
    let activitesInRangeId: ActivityColorMap[] = [];
    let activitesArray: string[] = [];
    if (this.colSelected.length > 0) {
      const activitiesInRange = this.ganttChartService.getLeafActivitiesInRange(this.chartDisplay, xAxisItem.day);

      activitiesInRange.forEach(element => {
        let findRes = this.barData.find(item => item.id === element.activity.id);
        let activityColor;

        // If findRes not present then need to take the sub color from acitity subcontractor.

        if (!findRes) {
          let activity = this.chartInput.find(cheartElement => cheartElement.activity.id === element.activity.id);
          if (activity && activity.subContractorId) {
            let subcontractor = this.subcontractorService.getLocalProjectSubcontractor(activity.subContractorId);
            activityColor = subcontractor ? subcontractor.hexCode : 'gray';
          }
          else // if subcontractor not present then default color will be gray
            activityColor = 'gray';
        } else {

          activityColor = findRes ? findRes.color : "gray";
        }

        activitesInRangeId.push({ activityId: element.activity.id, color: activityColor });
        activitesArray.push(element.activity.id);
      });


      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        this.curActivitiesSelected = this.projectService.checkIfAnnotationExist(activitesArray);
      } else {
        const pastActivities = this.ganttChartService.getPastActivitiesFromRange(this.chartInput, xAxisItem.day);
        const filteredObjectIds = Object.keys(await this.projectFilterSearchService.getFilterData(this.activeFiltersInput, this.activeFilterTypeInput, true));
        viewerObj = this.ganttChartService.buildPastAndActiveObjectIds(pastActivities, activitiesInRange, filteredObjectIds);
        this.curActivitiesSelected = viewerObj['activityIds'];
      }

      const selectedGraphics = D3.select(this.chartBarsContainer.nativeElement).selectAll('path').filter((d) => this.curActivitiesSelected.includes(d.id));
      selectedGraphics.classed('gantt-bar-selected', true);
    }

    if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      this.viewerObjectOutput.emit(activitesInRangeId);
      this.chartSelectionOutput.emit(activitesArray);
    } else {
      viewerObj['disablePage'] = disablePage;
      this.viewerObjectOutput.emit(viewerObj);
      this.chartSelectionOutput.emit(activitesArray);
    }
  }

  clearColumnSelections(disablePage: boolean = true) {
    if (this.colSelected.length > 0) {
      this.colSelected = [];
      this.resetAllSelections();

      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
      }
      else {
        this.viewerObjectOutput.emit({ disablePage: disablePage });
      }

    }
    this.ganttChartService.clearColumnSelections(this.columnSelContainer.nativeElement);
  }

  sortData(key: string) {
    this.sortType = key;
    let sortAscendBool = this.sortAsc = !this.sortAsc;
    let sortedEndResult = [];
    let sortedDepth1Array = [];
    let sortedDepth1ParentsArray = [];
    let leveloneparentsArray = [];
    let depth = 1;

    // Created for storing chart Display to make ther sorted list only with filtered chart Display
    let chartDisplayOld = [];

    this.chartDisplay.forEach(element => {
      chartDisplayOld.push(element);
    });

    this.chartDisplay = [];
    this.chartInput.forEach(cI => {
      if (cI.activity.children.length > 0 && cI.activity.depth == depth) {
        leveloneparentsArray.push(cI);
      }
    });

    let emptyExternalIDDepth1ParentArray = [];
    let valuedExternalIdDepth1ParentArray = [];
    leveloneparentsArray.forEach(sd1 => {
      if (sd1.activity.externalActivityId) {
        valuedExternalIdDepth1ParentArray.push(sd1);
      }
      else {
        emptyExternalIDDepth1ParentArray.push(sd1);
      }

    });

    let number: boolean = false;
    for (let i = 0; i < valuedExternalIdDepth1ParentArray.length; i++) {
      if (valuedExternalIdDepth1ParentArray[i].activity.externalActivityId) {
        let result = parseInt(valuedExternalIdDepth1ParentArray[i].activity.externalActivityId, 10);
        if (isNaN(result)) {
          number = false;
          break;
        }
        else {
          number = true;
          // break;
        }
      }
    };

    valuedExternalIdDepth1ParentArray.sort(function (a, b) {
      if (number == false) {
        if (a.activity.externalActivityId && b.activity.externalActivityId) {
          const nameA = a.activity.externalActivityId.toLowerCase(),
            nameB = b.activity.externalActivityId.toLowerCase();
          if (nameA < nameB) // sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; // default return value (no sorting)
          // return D3.ascending(a.activity.duration, b.activity.duration);
        }
      }
      else {
        return D3.ascending(parseInt(a.activity.externalActivityId), parseInt(b.activity.externalActivityId));
      }
    });
    leveloneparentsArray = [];
    leveloneparentsArray.push(...valuedExternalIdDepth1ParentArray, ...emptyExternalIDDepth1ParentArray);

    leveloneparentsArray.forEach(level1Parent => {
      this.chartInput.forEach(cI => {
        if (cI.activity.children.length > 0 && cI.activity.depth == depth) {

          if (level1Parent.activity.id === cI.activity.id) {
            if (cI.expanded) {
              sortedDepth1ParentsArray.push(cI);
              let temparray: any = this.SortChildrenRecussive(cI.activity.id, this.chartInput, sortAscendBool, key, 2);
              sortedDepth1ParentsArray.push(...temparray);
            }
            else {
              sortedDepth1ParentsArray.push(cI);
            }
          }
        }
      });
    });

    // });
    // else {
    this.chartInput.forEach(cI => {
      if (cI.activity.children.length == 0 && cI.activity.depth == depth)
        sortedDepth1Array.push(cI);
      // }
    });

    // sort by Duration

    switch (key) {
      case 'externalActivityId':
        // if (sortAscendBool) {
        let emptyExternalIDDepth1Array = [];
        let valuedExternalIdDepth1Array = [];
        sortedDepth1Array.forEach(sd1 => {
          if (sd1.activity.externalActivityId) {
            valuedExternalIdDepth1Array.push(sd1);
          }
          else {
            emptyExternalIDDepth1Array.push(sd1);
          }

        });
        for (let i = 0; i < valuedExternalIdDepth1Array.length; i++) {
          if (valuedExternalIdDepth1Array[i].activity.externalActivityId) {
            let result = parseInt(valuedExternalIdDepth1Array[i].activity.externalActivityId, 10);
            if (isNaN(result)) {
              number = false;
              break;
            }
            else {
              number = true;
              // break;
            }
          }
        };
        valuedExternalIdDepth1Array.sort(function (a, b) {
          if (number == false) {
            if (a.activity.externalActivityId && b.activity.externalActivityId) {
              const nameA = a.activity.externalActivityId.toLowerCase(),
                nameB = b.activity.externalActivityId.toLowerCase();
              if (nameA < nameB) // sort string ascending
                return -1;
              if (nameA > nameB)
                return 1;
              return 0; // default return value (no sorting)
              // return D3.ascending(a.activity.duration, b.activity.duration);
            }
          }
          else {
            return D3.ascending(parseInt(a.activity.externalActivityId), parseInt(b.activity.externalActivityId));
          }
        });

        sortedDepth1Array = [];
        sortedDepth1Array.push(...valuedExternalIdDepth1Array, ...emptyExternalIDDepth1Array);


        break;
      case 'dur':
        sortedDepth1Array.sort(function (a, b) {
          if (sortAscendBool)
            return D3.ascending(a.activity.duration, b.activity.duration);
          else
            return D3.descending(a.activity.duration, b.activity.duration);
        });
        break;
      case 'name':
        if (sortAscendBool) {
          sortedDepth1Array.sort(function (a, b) {
            const nameA = a.activity.name.toLowerCase(),
              nameB = b.activity.name.toLowerCase();
            if (nameA < nameB) // sort string ascending
              return -1;
            if (nameA > nameB)
              return 1;
            return 0; // default return value (no sorting)
          });
        }
        else {
          sortedDepth1Array.sort(function (a, b) {
            const nameA = a.activity.name.toLowerCase(),
              nameB = b.activity.name.toLowerCase();
            if (nameA > nameB) // sort string ascending
              return -1;
            if (nameA < nameB)
              return 1;
            return 0; // default return value (no sorting)
          });
        }
        break;
      case 'startdate':
        if (sortAscendBool) {
          sortedDepth1Array.sort(function (a, b) {
            return D3.ascending(a.activity.startDate, b.activity.startDate);
          });
        }
        else {
          sortedDepth1Array.sort(function (a, b) {
            return D3.descending(a.activity.startDate, b.activity.startDate);
          });
        }
        break;
      case 'enddate':
        if (sortAscendBool) {
          sortedDepth1Array.sort(function (a, b) {
            return D3.ascending(a.activity.endDate, b.activity.endDate);
          });
        }
        else {
          sortedDepth1Array.sort(function (a, b) {
            return D3.descending(a.activity.endDate, b.activity.endDate);
          });
        }
        break;
    }

    sortedEndResult.push(...sortedDepth1Array, ...sortedDepth1ParentsArray);

    // ChartDisplay will have elements that are present in chartDisplayOld
    sortedEndResult.filter(element => {
      if (element.activity.id) {
        chartDisplayOld.forEach(el => {
          if (el.activity.id === element.activity.id)
            this.chartDisplay.push(element)
        })
      }
    })

    this.clearChartRows();
    this.drawChart();


  }

  SortChildrenRecussive(parentID, chartInput, sortAscendBool, sortKey, depth) {
    let SubParents = [];
    let nonParentsActivities = [];
    let MixedChartInputSubset = [];
    let sortedResultArray = [];
    this.chartInput.forEach(cI => {
      if (cI.activity.parentId == parentID) {
        if (cI.activity.children.length > 0 && cI.activity.depth == depth)
          SubParents.push(cI);
        else {
          if (cI.activity.children.length == 0 && cI.activity.depth == depth)
            nonParentsActivities.push(cI);
        }
      }
    });

    //SortByDuration
    switch (sortKey) {
      case 'externalActivityId':
        let number: boolean = false;
        let emptyExternalIDDepth1Array = [];
        let valuedExternalIdDepth1Array = [];
        nonParentsActivities.forEach(sd1 => {
          if (sd1.activity.externalActivityId) {
            valuedExternalIdDepth1Array.push(sd1);
          }
          else {
            emptyExternalIDDepth1Array.push(sd1);
          }

        });

        for (let i = 0; i < valuedExternalIdDepth1Array.length; i++) {
          if (valuedExternalIdDepth1Array[i].activity.externalActivityId) {
            let result = parseInt(valuedExternalIdDepth1Array[i].activity.externalActivityId, 10);
            if (isNaN(result)) {
              number = false;
              break;
            }
            else {
              number = true;
              // break;
            }
          }
        };

        valuedExternalIdDepth1Array.sort(function (a, b) {
          if (number == false) {
            if (a.activity.externalActivityId && b.activity.externalActivityId) {
              const nameA = a.activity.externalActivityId.toLowerCase(),
                nameB = b.activity.externalActivityId.toLowerCase();
              if (nameA < nameB) // sort string ascending
                return -1;
              if (nameA > nameB)
                return 1;
              return 0; // default return value (no sorting)
              // return D3.ascending(a.activity.duration, b.activity.duration);
            }
          }
          else {
            return D3.ascending(parseInt(a.activity.externalActivityId), parseInt(b.activity.externalActivityId));
          }
        });
        nonParentsActivities = [];
        nonParentsActivities.push(...valuedExternalIdDepth1Array, ...emptyExternalIDDepth1Array);


        break;
      case 'dur':
        nonParentsActivities.sort(function (a, b) {
          if (sortAscendBool)
            return D3.ascending(a.activity.duration, b.activity.duration);
          else
            return D3.descending(a.activity.duration, b.activity.duration);
        });
        break;
      case 'name':
        if (sortAscendBool) {
          nonParentsActivities.sort(function (a, b) {
            const nameA = a.activity.name.toLowerCase(),
              nameB = b.activity.name.toLowerCase();
            if (nameA < nameB) // sort string ascending
              return -1;
            if (nameA > nameB)
              return 1;
            return 0; // default return value (no sorting)
            // return D3.ascending(a.activity.duration, b.activity.duration);
          });
        }
        else {
          nonParentsActivities.sort(function (a, b) {
            const nameA = a.activity.name.toLowerCase(),
              nameB = b.activity.name.toLowerCase();
            if (nameA > nameB) // sort string ascending
              return -1;
            if (nameA < nameB)
              return 1;
            return 0; // default return value (no sorting)
            // return D3.ascending(a.activity.duration, b.activity.duration);
          });
        }
        break;
      case 'startdate':
        if (sortAscendBool) {
          nonParentsActivities.sort(function (a, b) {
            return D3.ascending(a.activity.startDate, b.activity.startDate);
          });
        }
        else {
          nonParentsActivities.sort(function (a, b) {
            return D3.descending(a.activity.startDate, b.activity.startDate);
          });
        }
        break;
      case 'enddate':
        if (sortAscendBool) {
          nonParentsActivities.sort(function (a, b) {
            return D3.ascending(a.activity.endDate, b.activity.endDate);
          });
        }
        else {
          nonParentsActivities.sort(function (a, b) {
            return D3.descending(a.activity.endDate, b.activity.endDate);
          });
        }
        break;
    }

    // For loop for each parent
    let recursiveSortedOutput = [];
    depth++;
    if (SubParents) {
      let number: boolean = false;
      for (let i = 0; i < SubParents.length; i++) {
        if (SubParents[i].activity.externalActivityId) {
          let result = parseInt(SubParents[i].activity.externalActivityId, 10);
          if (isNaN(result)) {
            number = false;
            break;
          }
          else {
            number = true;
            // break;
          }
        }
      };

      SubParents.sort(function (a, b) {
        if (number == false) {
          if (a.activity.externalActivityId && b.activity.externalActivityId) {
            const nameA = a.activity.externalActivityId.toLowerCase(),
              nameB = b.activity.externalActivityId.toLowerCase();
            if (nameA < nameB) // sort string ascending
              return -1;
            if (nameA > nameB)
              return 1;
            return 0; // default return value (no sorting)
            // return D3.ascending(a.activity.duration, b.activity.duration);
          }
        }
        else {
          return D3.ascending(parseInt(a.activity.externalActivityId), parseInt(b.activity.externalActivityId));
        }
      });

      SubParents.forEach(sP => {
        recursiveSortedOutput.push(sP);
        if (sP.expanded) {
          let tempArray: any = this.SortChildrenRecussive(sP.activity.id, chartInput, sortAscendBool, sortKey, depth);
          recursiveSortedOutput.push(...tempArray);
        }
      });
    }
    sortedResultArray.push(...nonParentsActivities, ...recursiveSortedOutput);
    return sortedResultArray;
  }


  private drawRect(sel: any) {
    sel
      .append('rect')
      .attr('fill', (d) => d.color)
      .attr('stroke', () => this.ganttChartService.gO.borderColor)
      .attr('height', () => this.ganttChartService.gO.rowHeight)
      .attr('width', this.ganttChartService.gO.gridOffsetLeft)
      .attr('transform', (d) => `translate(${-this.ganttChartService.gO.yAxisLabelOffset}, ${d.yCoord})`)
      .on('click', (d) => {
        this.expandCloseRow(d)
        this.sortData('externalActivityId');
      })
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawExpandedState(sel: any) {
    sel
      .append('foreignObject')
      .attr('class', 'y-axis-labels')
      .html((d) => this.ganttChartService.getExpandedStateIcon(d))
      .style('color', (d) => d.textColor)
      .attr('y', (d) => d.yCoord - this.ganttChartService.gO.headerIconYOffset)
      .attr('x', (d) => d.xCoord)
      .on('click', (d) => {
        if (this.ganttChartService.expandCloseRow(d, this.chartDisplay, this.chartInput, this.expandedIds, this.sortType, this.sortAsc))

          this.sortData('externalActivityId');
        // this.drawChart();
      });

    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawId(sel: any) {
    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateStringForId(d.externalActivityId, 7, d.numChildren))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', 6)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawLabel(sel: any, padding: number) {
    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateString(d.label, 30 - d.depth))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', (d) => d.xCoord + this.ganttChartService.gO.expandIconOffset + 60 + padding)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawDuration(sel: any, columnX: number) {
    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateString(String(this.appendLeadingZeroes(d.duration)), 5))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnX)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawStartDay(sel: any, columnX: number) {
    sel
      .append('text')
      .text((d) => {
        let current_datetime = d.startDayEpoch ? Utils.formatDate(d.startDayEpoch) : null;
        current_datetime = d.started ? current_datetime + '(a)' : current_datetime;
        return current_datetime;
      })
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnX)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawEndDay(sel: any, columnX: number) {
    sel
      .append('text')
      .text((d) => {
        let current_datetime = d.endDayEpoch ? Utils.formatDate(d.endDayEpoch) : null;
        if (d.completed) current_datetime = current_datetime + '(A)';
        else if (d.expectedFinishDate) current_datetime = current_datetime + '(E)';
        return current_datetime;
      })
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnX)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  private drawAddIcon(sel: any, columnX: number) {
    sel
      .append('foreignObject')
      .attr('class', 'y-axis-labels')
      .html(this.ganttChartService.gO.addIcon)
      .style('color', (d) => d.textColor)
      .attr('y', (d) => d.yCoord - this.ganttChartService.gO.headerIconYOffset)
      .attr('x', () => columnX)
      .style('display', (d) => d.depth >= this.ganttChartService.gO.maxDepth ? 'none' : 'inline')
      .on('click', (d) => this.addActivity(d))
      .on('mouseover', (d) => this.handleHover(d, HoverType.Add))
      .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));
  }

  async drawYaxis() {
    this.yAxisTransform = `translate(${this.ganttChartService.gO.yAxisLabelOffset} , ${this.ganttChartService.gO.gridOffsetTop})`;

    let lablePostion = 20;
    let columnXStart = 332;
    this.ganttChartService.gO.gridOffsetLeft = 595;

    // Draw Y-Axis labels
    const sel = D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis-labels')
      .attr('transform', this.yAxisTransform)
      .selectAll('g')
      .remove() // refresh data each time
      .exit()
      .data(this.rows)
      .enter()
      .append('g');

    this.drawRect(sel);
    this.drawExpandedState(sel);
    this.drawId(sel);

    this.drawLabel(sel, lablePostion);
    this.drawDuration(sel, columnXStart);
    columnXStart = columnXStart + 40;

    this.drawStartDay(sel, columnXStart);
    columnXStart = columnXStart + 80;

    this.drawEndDay(sel, columnXStart)
    columnXStart = columnXStart + 80;

    if (this.canEdit) {

      // Edit button
      sel
        .append('foreignObject')
        .attr('class', 'y-axis-labels')
        .html(this.ganttChartService.gO.editIcon)
        .style('color', (d) => d.textColor)
        .attr('y', (d) => d.yCoord - this.ganttChartService.gO.headerIconYOffset)
        .attr('x', () => columnXStart)
        .on('click', (d) => this.editActivityOutput.emit(d.rowId))
        .on('mouseover', (d) => this.handleHover(d, HoverType.Edit))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));

      columnXStart = columnXStart + 20;

      let mouseHoverForObject: HoverType = ViewerMode.ForgViewer === this.projectService.viewerMode ? HoverType.Assign : HoverType.AssignWorkArea;

      let icon: string = ViewerMode.ForgViewer === this.projectService.viewerMode ? this.ganttChartService.gO.assignIcon : this.ganttChartService.gO.assignWorkArea;

      // Assign button
      sel
        .append('foreignObject')
        .attr('class', 'y-axis-labels')
        .attr('id', (d) => "line_" + d.id.replace(/[ )(]/g,'') )
        .html(icon)
        .style('color', (d) => d.textColor)
        .attr('y', (d) => d.yCoord - this.ganttChartService.gO.headerIconYOffset)
        .attr('x', () => columnXStart)
        .on('click', (d) => this.assignObjectsClick(d.rowId))
        .on('mouseover', (d) => this.handleHover(d, mouseHoverForObject))
        .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));

      columnXStart = columnXStart + 20;

      // Add button
      this.drawAddIcon(sel, columnXStart);
    }
  }

  async drawBulkEditYaxis() {
    this.yAxisTransform = `translate(${this.ganttChartService.gO.yAxisLabelOffset} , ${this.ganttChartService.gO.gridOffsetTop})`;

    let lablePostion = 30;
    let columnXStart = 332;
    this.ganttChartService.gO.gridOffsetLeft = 1250;

    // Draw Y-Axis labels
    const sel = D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis-labels')
      .attr('transform', this.yAxisTransform)
      .selectAll('g')
      .remove() // refresh data each time
      .exit()
      .data(this.rows)
      .enter()
      .append('g');

    this.drawRect(sel);
    this.drawExpandedState(sel);
    this.drawId(sel);

    sel
      .append('foreignObject')
      .attr('class', 'y-axis-labels')
      .html((d) => this.ganttChartService.getCheckBoxIcon(d))
      .style('color', (d) => d.textColor)
      .attr('y', (d) => d.yCoord - this.ganttChartService.gO.headerIconYOffset)
      .attr('x', (d) => d.xCoord + this.ganttChartService.gO.expandIconOffset + 70)
      .on('click', (d) => { this.chartControl.startBulkEditDisable = !this.ganttChartService.updateEditFlag(d, this.chartDisplay, this.chartInput, this.expandedIds), this.drawChart() });

    this.drawLabel(sel, lablePostion);

    this.drawDuration(sel, columnXStart);
    columnXStart = columnXStart + 40;

    this.drawStartDay(sel, columnXStart);
    columnXStart = columnXStart + 80;

    this.drawEndDay(sel, columnXStart)
    columnXStart = columnXStart + 80;

    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateString(d.subName, 28))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnXStart)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));

    columnXStart = columnXStart + 200;

    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateString(d.equipments, 37))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnXStart)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));

    columnXStart = columnXStart + 150;

    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateString(d.materials, 37))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnXStart)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));

    columnXStart = columnXStart + 150;

    sel
      .append('text')
      .text((d) => Utils.ParseAndTruncateString(d.labors, 37))
      .style('fill', (d) => d.textColor)
      .attr('dy', '.4em')
      .attr('text-anchor', 'start')
      .attr('y', (d) => d.yCoord + this.ganttChartService.gO.rowHeight / 2)
      .attr('x', () => columnXStart)
      .on('click', (d) => this.gotoActivityStart(d))
      .on('dblclick', (d) => this.editActivityOutput.emit(d.rowId));
    // .on('mouseover', (d) => this.handleHover(d, HoverType.DataInfo))
    // .on('mouseout', (d) => this.handleHover(d, HoverType.Dehover));

    columnXStart = columnXStart + 150;

    if (this.canEdit) {
      // Add button
      this.drawAddIcon(sel, columnXStart);
    }
  }


  appendLeadingZeroes(n) {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  }

  truncate(d: any, text: string, n: number) {
    if (d.numChildren > 0) {
      return "";
    }
    if (text) {
      if (text.length <= n) { return text; }
      else
        return text.substr(0, 5) + "..";
    }
  };

  async drawChartWithCurEpochs() {
    this.visibleEpochs = this.ganttChartService.getListOfActiveEpochs(this.curChartStartEpoch, this.ganttChartService.gO.chartRange);
    this.xAxisDataInput = this.ganttChartService.buildXaxisData(this.visibleEpochs);
    await this.drawChart();
  }

  createRowScale(rowId: string, rangeStart: number, rangeEnd: number, domain: any[]): void {
    const rowScale = D3.scaleBand()
      .range([rangeStart, rangeEnd])
      .domain(domain);
    this.rowScales[rowId] = rowScale;
  }

  expandCloseRow(d): void {
    const unselect = this.ganttChartService.isCurrentSelectedOrChild(d.rowId, this.curActivitiesSelected, this.chartDisplay);
    if (this.ganttChartService.expandCloseRow(d, this.chartDisplay, this.chartInput, this.expandedIds, this.sortType, this.sortAsc)) {

      if (unselect) {
        this.resetAllSelections();
        this.chartSelectionOutput.emit(this.curActivitiesSelected);
      }
      this.drawChart();
    }
    if (this.expandedIds.length > 50) SegmentService.track(`Gantt Chart: Over 50 activities expanded`, { numExpanded: this.expandedIds.length });
  }

  gotoActivityStart(d): void {
    SegmentService.track(`Gantt Chart: Activity label click`, {});
    if (this.curChartStartEpoch > d.startDayEpoch || d.startDayEpoch > this.curChartEndEpoch) {
      this.curChartStartEpoch = moment.utc(d.startDayEpoch).startOf('isoWeek').valueOf();
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
    }
    this.resetAllSelections();
    this.curActivitiesSelected.push(d.rowId);
    this.chartSelectionOutput.emit(this.curActivitiesSelected);
    this.drawChartWithCurEpochs();
  }

  calculateChartHeightDimension(): void {
    this.gridContainerHeight = this.rows.length > 0
      ? this.rows.length * this.ganttChartService.gO.rowHeight
      : this.ganttChartService.gO.rowHeight;

    const padding = (Math.abs(this.ganttChartService.gO.chartOffsetTop) * 2) + 45;
    this.chartContainerHeight = this.gridContainerHeight + this.ganttChartService.gO.gridOffsetTop + this.ganttChartService.gO.chartOffsetBottom + padding;
  }

  async createXaxisScale() {
    const endOfLastInterval = moment.utc(this.xAxisDataInput[this.xAxisDataInput.length - 1].day); // Scale to end of last day
    // x-axis scale
    this.xScaleTime = D3.scaleUtc()
      .range([0, this.gridContainerWidth - this.ganttChartService.gO.paddingOffset])
      .domain([this.xAxisDataInput[0].day, endOfLastInterval]);
  }

  async resetAllSelections() {
    this.curActivitiesSelected = [];
    this.rows = this.rows.map(row => ({ ...row, selected: false }));

    D3.selectAll('.D3-tooltip').style('display', 'none');
    D3.selectAll('.D3-chart-bar').each(function (d) { D3.select(this).classed('gantt-bar-selected', false); });
    D3.selectAll('.D3-chart-bar').each(function (d) { D3.select(this).classed('gantt-bar-predecessor', false); });
    D3.selectAll('.D3-chart-bar').each(function (d) { D3.select(this).classed('gantt-bar-successor', false); });
    D3.selectAll('.D3-chart-bar').each(function (d) { D3.select(this).classed('gantt-bar-dim', false); });
  }

  // END HELPERS

  // BUTTONS
  assignObjectsClick(id: string) {
    const findRes = this.barData.find(item => item.id === id);

    if (findRes) {

      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        let activitesInRangeId = { activityId: id, color: findRes ? findRes.color : "gray" };
        this.acctivtySubOutput.emit(activitesInRangeId);



      }
      else {
        const selectedBar = D3.selectAll('.D3-chart-bar').filter('._' + findRes.id);
        if (!selectedBar.classed('gantt-bar-selected')) this.handleBarClick(findRes);

      }
      this.assignObjectsOutput.emit(true);
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        let activitesInRangeIdArray = [];
        activitesInRangeIdArray.push({ activityId: id, color: findRes ? findRes.color : "gray" });
        this.viewerObjectOutput.emit(activitesInRangeIdArray);
      }

    } else {

      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        let activitesInRangeId = { activityId: id, color: 'gray' };
        let activity = this.chartInput.find(element => element.activity.id === id);
        if (activity && activity.subContractorId) {
          let subcontractor = this.subcontractorService.getLocalProjectSubcontractor(activity.subContractorId);
          activitesInRangeId = { activityId: id, color: subcontractor ? subcontractor.hexCode : 'gray' };
        }
        this.acctivtySubOutput.emit(activitesInRangeId);
      }

      this.resetAllSelections();
      this.curActivitiesSelected.push(id);
      this.chartSelectionOutput.emit(this.curActivitiesSelected);
      this.assignObjectsOutput.emit(true);
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        let activitesInRangeIdArray = [];
        let activity = this.chartInput.find(element => element.activity.id === id);
        if (activity && activity.subContractorId) {
          let subcontractor = this.subcontractorService.getLocalProjectSubcontractor(activity.subContractorId);
          activitesInRangeIdArray.push({ activityId: id, color: findRes ? findRes.color : "gray" });
        }
        this.viewerObjectOutput.emit(activitesInRangeIdArray);
      }
    }
  }

  switchViewClick(view: ViewMode) {
    if (view !== ViewMode.Gantt) {
      this.masterScheduleService.setChartMode(view);
      this.switchViewOutput.emit(ViewMode.Table);
    }
  }

  decreaseRangeClick() {
    if (this.currentRange === '12_month') return;

    if (this.currentRange === '1_month') {
      this.currentRange = '3_month';
    } else if (this.currentRange === '3_month') {
      this.currentRange = '6_month';
    } else if (this.currentRange === '6_month') {
      this.currentRange = '12_month';
    }

    const view: ViewMode = this.currentRange;
    const changed = this.ganttChartService.setChartMode(view);

    if (changed) {
      SegmentService.track(`Gantt Chart: Switch view range click`, { viewType: view });
      this.clearColumnSelections();
      this.ganttChartService.editChartIntervals(view);
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
      this.drawChartWithCurEpochs();
    }
  }
  increaseRangeClick() {
    if (this.currentRange === '1_month') return;

    if (this.currentRange === '12_month') {
      this.currentRange = '6_month'
    } else if (this.currentRange === '6_month') {
      this.currentRange = '3_month'
    } else if (this.currentRange === '3_month') {
      this.currentRange = '1_month'
    }

    const view: ViewMode = this.currentRange;
    const changed = this.ganttChartService.setChartMode(view);

    if (changed) {
      SegmentService.track(`Gantt Chart: Switch view range click`, { viewType: view });
      this.clearColumnSelections();
      this.ganttChartService.editChartIntervals(view);
      this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
      this.drawChartWithCurEpochs();
    }
  }

  addActivity(data = null) {
    if (data && data.rowId) this.addActivityOutput.emit(data.rowId);
    else this.addActivityOutput.emit(null);
  }

  handleRangeCtrl(direction: string): void {
    SegmentService.track(`Gantt Chart: Switch range click`, { direction: direction });
    if (direction === 'next') this.moveRange(true, this.ganttChartService.gO.rangeMovement);
    if (direction === 'prev') this.moveRange(false, this.ganttChartService.gO.rangeMovement);
  }

  handleWeekCtrl(direction: string): void {
    SegmentService.track(`Gantt Chart: Switch week click`, { direction: direction });
    if (direction === 'next') this.moveRange(true, 1);
    if (direction === 'prev') this.moveRange(false, 1);
  }

  async handleStartFinishCtrl(start: boolean) {
    if (this.chartInput.length < 1) return;
    let chartEpoch: number;
    if (start) {
      SegmentService.track(`Gantt Chart: Goto scheduled start click`, {});
      chartEpoch = Math.min(...this.chartDisplay.map(c => c.activity.startDate));
    } else {
      SegmentService.track(`Gantt Chart: Goto scheduled end click`, {});
      chartEpoch = Math.max(...this.chartDisplay.map(c => c.activity.endDate));
      chartEpoch = moment.utc(chartEpoch).clone().subtract(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.rangeMovement - 1).valueOf(); // Offset so it appears at end
    }
    this.curChartStartEpoch = moment.utc(chartEpoch).startOf('isoWeek').valueOf();
    this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
    await this.drawChartWithCurEpochs();
  }

  async handleTodayCtrl() {
    SegmentService.track(`Gantt Chart: Today button click`, {});
    this.curChartStartEpoch = moment.utc(this.today).startOf('isoWeek').valueOf();
    this.curChartEndEpoch = moment.utc(this.curChartStartEpoch).clone().add(this.ganttChartService.gO.chartInterval, this.ganttChartService.gO.chartRange).valueOf();
    await this.drawChartWithCurEpochs();
    this.colSelected = [];
    this.resetAllSelections();
    this.xAxisClick({ day: this.curChartStartEpoch }, false);
  }

  handleResetCtrl() {
    SegmentService.track(`Gantt Chart: Reset button click`, {});
    this.resetAllSelections();
    this.clearColumnSelections();
  }

  handleBulkEdit() {
    SegmentService.track(`Gantt Chart: enable bulk Edit button click`, {});
    this.checkAllFlagForBulkEdit = BulkEditSelection.unchecked;
    this.bulkEdit = !this.bulkEdit;
    this.resetBulkEditSelection();
    this.drawChart();
  }

  showBulkEditModel() {
    SegmentService.track(`Gantt Chart: showBulkEditModel starts`, {});
    let bulkEditIds = [];
    this.chartDisplay.forEach(item => { if (item.bulkEditSelection == 1) { bulkEditIds.push(item.activity.id) } })
    this.bulkEditOutput.emit(bulkEditIds);
  }


  showRowEditModel() {
    SegmentService.track(`Gantt Chart: showBulkEditModel starts`, {});
    let bulkRowEditIds = [];
    this.chartDisplay.forEach(item => { if (item.bulkEditSelection == 1) { bulkRowEditIds.push(item.activity.id) } })
    this.rowEditOutput.emit(bulkRowEditIds);
  }

  resetBulkEditSelection() {
    this.chartDisplay.forEach(item => { item.bulkEditSelection = 0 });
  }

  async handleAnimationClick(action: string) {
    if (action === 'play') {
      SegmentService.track(`Gantt Chart: Play button click`, { animationSpeed: this.animationSpeed });
      this.resetAllSelections();
      this.clearColumnSelections();
      await this.handleStartFinishCtrl(true);
      this.curxAxisDay = this.xAxisDataInput[0].day;
      this.animationIsPlaying = true;
      this.animationInterval = setInterval(() => this.iterateAnimation(), this.animationSpeed * 250);
      this.iterateAnimation();
      this.projectService.setDisabledSidebar(true);

    } else if (action === 'stop') {
      this.stopAnimation();
    }
  }

  iterateAnimation(): void {
    if (this.curxAxisDay < this.projectEndDate) {
      this.getNextSelection();
    } else {
      this.stopAnimation();
    }
  }

  async getNextSelection() {
    if (this.curxAxisDay > this.xAxisDataInput[this.xAxisDataInput.length - 2].day) await this.moveRange(true, this.ganttChartService.gO.rangeMovement);
    this.xAxisClick({ day: this.curxAxisDay }, false);
    this.curxAxisDay = moment.utc(this.curxAxisDay).clone().add(this.ganttChartService.gO.chartInterval, 1).valueOf();
  }

  stopAnimation(): void {
    clearInterval(this.animationInterval);
    this.animationIsPlaying = false;
    this.projectService.setDisabledSidebar(false);
  }
  // END BUTTONS

  handleQuickLink(activityId: string) {
    this.handleBarClick(this.barData.find(bar => bar.id === activityId));
  }

  public async handleSearchCtrl(query: string, filterOption: FilterModelOptions) {

    this.filterQuery = query;
    this.ganttChartService.updateChartDisplayForFilter(this.chartDisplay, this.chartInput, this.filterQuery, this.expandedIds, filterOption);
    this.drawChart(undefined, undefined, true);
    SegmentService.track(`Gantt Chart: Search for query` + query, {});
  }

  public async setProcurementLinesToggle(toggle: boolean) {
    this.toggleProcurement = toggle;
    this.toggleProcurement = (localStorage.getItem('previouslySavedProcuremntLinesToggle') == "true");
  }

  public async handleSelectionCtrl(selections: string[], filterOption: FilterModelOptions, orFilterType: boolean) {

    this.ganttChartService.updateChartDisplayForSelection(this.chartDisplay, this.chartInput, selections, this.expandedIds, filterOption, orFilterType);
    this.drawChart(undefined, undefined, true);
    SegmentService.track(`Gantt Chart: Search for query` + selections, {});
  }

  public disableObjectAssignIcons() {
    this.rows.forEach( d => {
        // if(d.id) document.getElementById(d.id + 'assignIcon').style.pointerEvents = "none";
        D3.select("#line_" + d.id.replace(/[ )(]/g,'')).attr("pointer-events","none");
        D3.select("#line_" + d.id.replace(/[ )(]/g,'')).attr("opacity","0.5");
    })
  }
}