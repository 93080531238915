import { Injectable } from '@angular/core';

import { IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { GritColors } from '../../../utils/enums/hex-color.enum';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectViewMode } from '../../../models/project/project.interface';
import { FaIcon, ViewMode, BulkEditSelection } from '../../../utils/enums/shared.enum';
import { StepStatus } from '../../../utils/enums/step-status.enum';
import { TranslationService } from '../../translation/translation.service';
import { FilterModelOptions } from '../../../utils/enums/filter-model-options';

import { ChartService } from '../../chart/chart.service';

import { Utils } from '../../../utils/utils';

import { ProjectService } from '../../../services/project/project.service';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';

import * as D3 from 'd3';
import * as moment from 'moment';
import { iEM } from '@angular/core/src/render3';

export enum ChartInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

enum BGColorPalette {
  D1 = '#FFFFFF',
  D2 = '#676767',
  D3 = '#808080',
  D4 = '#A9A9A9',
  D5 = '#BEBEBE',
}

@Injectable()
export class ProjectGanttChartService extends ChartService {

  // Gantt Options
  gO = {
    chartOffsetTop: null, // Set in contructor; pretty backwards, grid offset should calculate from this, not the oppposite, leaving for now
    chartOffsetBottom: null,
    rowHeight: 22,
    minColumnWidth: 40,
    gridOffsetLeft: 800,
    gridOffsetTop: 22,
    xAxisLabelHeightOffset: 10,
    xAxisLabelYpos: null, // Set in constructor
    barPadding: 2,
    barDepthPercent: 0.85,
    barHeight: null, // Set in constructor
    parentBarHeight: null,
    minBarWidth: 2,
    textPadding: 4,
    lineWidth: 0.5,
    chartInterval: ChartInterval.Week,
    chartRange: 12,
    rangeMovement: 12, // Moves range x amount of intervals
    yAxisLabelOffset: 4,
    yAxisDepthOffset: 10,
    maxDepth: 10,
    expandIconOffset: 12,
    rightAlignIconOffset: 20,
    headerIconYOffset: 2,
    closedIcon: FaIcon.ChevronRight,
    expandedIcon: FaIcon.ChevronDown,
    checkSquareIcon: FaIcon.CheckSquare,
    squareIcon: FaIcon.Square,
    addIcon: FaIcon.Plus,
    partialCheckSquareIcon: FaIcon.MinusSqaure,
    editIcon: FaIcon.Pencil,
    assignIcon: FaIcon.Cube,
    assignWorkArea: FaIcon.Select,
    borderColor: GritColors.dkGray,
    paddingLeftOffset: 7,
    paddingOffset: null, // This is equal to the total padding left and right of the container, since d3 chart interprets padding left position as 0
    projectColor: GritColors.red,
    blockMarkerOffset: 4,
    blockMarkerWidth: 4,
    blockMarkerHeight: null, // Set in constructor
    mStoneSideLength: 8,
    dragTolerence: 5,
    lineFunction: null
  };

  curGanttDisplay: ViewMode = ViewMode.ThreeMonthGantt;

  public ganttDisplayModes: IProjectViewMode[] = [
    {
      mode: ViewMode.OneMonthGantt,
      label: ViewMode.OneMonthGantt,
      selected: false
    },
    {
      mode: ViewMode.ThreeMonthGantt,
      label: ViewMode.ThreeMonthGantt,
      selected: true
    },
    {
      mode: ViewMode.SixMonthGantt,
      label: ViewMode.SixMonthGantt,
      selected: false
    },
    {
      mode: ViewMode.TwelveMonthGantt,
      label: ViewMode.TwelveMonthGantt,
      selected: false
    }
  ];

  private tooltipTimeout;
  private projectService: ProjectService;
  private materialService: ProjectMaterialService
  private equipmentService: ProjectEquipmentService

  constructor() {
    super();
    this.defineCalculatedGanttOptions();
  }

  setProjectService(projectService: ProjectService) {

    this.projectService = projectService;
    this.materialService = projectService.getMaterialService();
    this.equipmentService = projectService.getEquipmentService();
    projectService.loadSubcontractors
  }

  defineCalculatedGanttOptions() {
    this.gO.barHeight = this.gO.rowHeight - this.gO.barPadding;
    this.gO.blockMarkerHeight = this.gO.barHeight - this.gO.blockMarkerOffset;
    this.gO.parentBarHeight = Math.max(Math.round(this.gO.barHeight / 6), 2);
    this.gO.xAxisLabelYpos = this.gO.gridOffsetTop - this.gO.xAxisLabelHeightOffset;
    this.gO.chartOffsetTop = this.gO.gridOffsetTop - (2 * this.gO.rowHeight);
    this.gO.chartOffsetBottom = this.gO.chartOffsetTop;
    this.gO.paddingOffset = 2 * this.gO.paddingLeftOffset;
    this.gO.lineFunction = D3.line()
      .defined((d) => d !== null)
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(D3.curveLinear);
  }

  buildXaxisData(epochs: number[]): any[] {
    return epochs.map(item => {
      return {
        day: item
      };
    });
  }

  getListOfActiveEpochs(startOfWeek: number, totalIntervals: number): number[] {
    const weekOfEpochs = [];
    for (let i = 0; i <= totalIntervals; i++) {
      const startOfDay = moment.utc(startOfWeek).clone().add(i, this.gO.chartInterval).valueOf();
      weekOfEpochs.push(startOfDay);
    }
    return weekOfEpochs;
  }

  setChartActivityStart(startEpoch: number, endEpoch: number, curChartStartEpoch: number): number {
    if ((curChartStartEpoch > startEpoch) && (endEpoch > curChartStartEpoch)) return curChartStartEpoch;
    return startEpoch;
  }

  setChartActivityEnd(endEpoch: number, curChartEndEpoch: number): number {
    if (curChartEndEpoch < endEpoch) return curChartEndEpoch;
    return endEpoch;
  }

  removeInputWithNoDates(chartInput: IMasterScheduleActivity[]): IMasterScheduleActivity[] {
    const returnChartIn: IMasterScheduleActivity[] = [];
    chartInput.forEach(mAct => {
      if (mAct.activity.startDate && mAct.activity.endDate) returnChartIn.push(mAct);
    });
    return returnChartIn;
  }

  getRootParents(chartInput: IMasterScheduleActivity[], expandedIds?: string[], collapse?: boolean): IMasterScheduleActivity[] {

    let tempchartInputArray: any = [];
    if (chartInput.length > 500 || collapse) {
      tempchartInputArray = chartInput.filter(cIn => cIn.activity.depth === 1);
      tempchartInputArray.forEach(cI => {
        if (cI.activity.depth == 1) {
          if (expandedIds)
            expandedIds = [];
          cI.expanded = false;
        }
      });
      return tempchartInputArray;
    }
    else if (chartInput.length < 500 && chartInput.length > 200) {
      tempchartInputArray = chartInput.filter(cIn => cIn.activity.depth === 1 || cIn.activity.depth === 2);
      tempchartInputArray.forEach(cI => {
        if (cI.activity.depth === 1) {
          if (cI.activity.children.length && expandedIds)
            expandedIds.push(cI.activity.id);
          cI.expanded = true;
        }
      });
      return tempchartInputArray;
    }
    else {
      chartInput.forEach((item) => {
        tempchartInputArray.push(Object.assign({}, item));
        item.expanded = true;
      });
      // tempchartInputArray = chartInput;
      tempchartInputArray.forEach(cI => {

        if (cI.activity.children.length && expandedIds)
          expandedIds.push(cI.activity.id);
        cI.expanded = true;
      });
      return tempchartInputArray;
    }



    // return chartInput.filter(cIn => cIn.activity.depth === 1);
  }

  expandFromChildParentId(parentId: string, currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[]): void {
    // Get list of all parentIds in hierarchical order
    const parentIdsToExpand = [parentId];
    let findRes;
    let curParentId = parentId;
    while (curParentId) {
      findRes = chartInput.find(x => x.activity.id === curParentId);
      if (findRes) {
        if (findRes.activity.parentId) {
          curParentId = findRes.activity.parentId;
          parentIdsToExpand.unshift(curParentId);
        } else {
          curParentId = null;
        }
      } else {
        // Shouldn't go here, but we'll break for now
        curParentId = null;
      }
    }
    parentIdsToExpand.forEach(pId => {
      findRes = currentChart.find(x => x.activity.id === pId);
      if (findRes && !findRes.expanded) this.expandParent(pId, currentChart, chartInput);
    });
  }

  expandParent(parentId: string, currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], sortType?: string, sortAsc?: boolean): void {

    let tempChart = [];
    let index: number;
    const findRes = currentChart.find(cItem => cItem.activity.id === parentId);
    if (findRes) findRes.expanded = true;
    chartInput.forEach(item => {
      if (item.activity.parentId === parentId) {
        index = currentChart.findIndex(cItem => cItem.activity.id === parentId);
        tempChart.push(item);
      }
    });

    if (tempChart) {
      if (sortType) {
        //   tempChart.sort(function (a, b) {
        //     return D3.ascending(a.activity.duration, b.activity.duration);
        //   });
        // }
        // }
        switch (sortType) {
          case 'dur':
            if (sortAsc) {
              tempChart.sort(function (a, b) {
                return D3.ascending(a.activity.duration, b.activity.duration);
              });
            }
            else {
              tempChart.sort(function (a, b) {
                return D3.descending(a.activity.duration, b.activity.duration);
              });
            }
            break;
          case 'name':
            if (sortAsc) {
              tempChart.sort(function (a, b) {
                const nameA = a.activity.name.toLowerCase(),
                  nameB = b.activity.name.toLowerCase();
                if (nameA < nameB) // sort string ascending
                  return -1;
                if (nameA > nameB)
                  return 1;
                return 0; // default return value (no sorting)
                // return D3.ascending(a.activity.duration, b.activity.duration);
              });
            }
            else {
              tempChart.sort(function (a, b) {
                const nameA = a.activity.name.toLowerCase(),
                  nameB = b.activity.name.toLowerCase();
                if (nameA > nameB) // sort string ascending
                  return -1;
                if (nameA < nameB)
                  return 1;
                return 0; // default return value (no sorting)
                // return D3.ascending(a.activity.duration, b.activity.duration);
              });
            }
            break;
          case 'startdate':
            if (sortAsc) {
              tempChart.sort(function (a, b) {
                return D3.ascending(a.activity.startDate, b.activity.startDate);
              });
            }
            else {
              tempChart.sort(function (a, b) {
                return D3.descending(a.activity.startDate, b.activity.startDate);
              });
            }
            break;
          case 'enddate':
            if (sortAsc) {
              tempChart.sort(function (a, b) {
                return D3.ascending(a.activity.endDate, b.activity.endDate);
              });
            }
            else {
              tempChart.sort(function (a, b) {
                return D3.descending(a.activity.endDate, b.activity.endDate);
              });
            }
            tempChart.sort(function (a, b) {
              return D3.ascending(a.activity.endDate, b.activity.endDate);
            });
            break;
        }
      }
      if (index > -1) currentChart.splice(index + 1, 0, ...tempChart);
    }
  }

  expandAll(currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[]): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < currentChart.length; i++) this.expandParent(currentChart[i].activity.id, currentChart, chartInput);
  }

  closeParent(parentId: string, currentChart: IMasterScheduleActivity[]): void {
    const findRes = currentChart.find(x => x.activity.id === parentId);
    if (findRes) findRes.expanded = false;
    this.closeChildren(parentId, currentChart);
  }

  closeChildren(parentId: string, currentChart: IMasterScheduleActivity[]): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = currentChart.length - 1; i >= 0; i--) {
      if (currentChart[i].activity.parentId === parentId) {
        currentChart[i].expanded = false;
        this.closeChildren(currentChart[i].activity.id, currentChart);
        currentChart.splice(i, 1);
      }
    }
  }

  getChildrens(id, list, chartInput: IMasterScheduleActivity[]) {
    const children = chartInput.filter(item => item.activity.parentId === id);
    children.forEach(child => {
      list.push(child);
      this.getChildrens(child.activity.id, list, chartInput);
    });
    return list;
  }

  closeSelectedParent(parentId: string, currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], expandedIds: string[], isSelected: BulkEditSelection): void {
    const findRes = currentChart.find(x => x.activity.id === parentId);
    if (findRes) {
      if (findRes.expanded == true) {
        let index = expandedIds.indexOf(parentId);
        if (index > -1)
          expandedIds.splice(index, 1);
        findRes.expanded = false;
        findRes.bulkEditSelection = isSelected;

        for (let i = currentChart.length - 1; i >= 0; i--) {
          if (currentChart[i].activity.parentId === parentId) {
            if (currentChart[i].activity.children.length > 0) {
              this.closeSelectedParent(currentChart[i].activity.id, currentChart, chartInput, expandedIds, isSelected);
              currentChart.splice(i, 1);
            }
            else {
              currentChart[i].bulkEditSelection = isSelected;
              currentChart.splice(i, 1);
            }
          }
        }
      }
      else {
        let childList = this.getChildrens(parentId, [], chartInput);
        childList.forEach(child => {
          child.bulkEditSelection = isSelected;
        });
      }
    }
  }



  expandCloseRow(yAxisLabel, chartDisplay: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], expandedIds: string[], sortType?: string, sortAsc?: boolean): boolean {
    if (yAxisLabel.numChildren < 1) return false;
    const rowId = yAxisLabel.rowId;
    if (yAxisLabel.expanded) {
      const index = expandedIds.indexOf(rowId);
      if (index > -1) {
        expandedIds.splice(index, 1);
        this.closeParent(rowId, chartDisplay);
      }
    } else {
      expandedIds.push(rowId);
      this.expandParent(rowId, chartDisplay, chartInput, sortType, sortAsc);
    }
    return true;
  }

  expandSelectedParent(parentId: string, currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], expandedIds: string[], isSelected: BulkEditSelection): void {
    let tempChart = [];
    let index: number;
    const findRes = chartInput.find(cItem => cItem.activity.id === parentId);
    if (findRes) {
      findRes.bulkEditSelection = isSelected;
      const checkCurrentExpandedIds = expandedIds.find(id => id === parentId);
      if (!checkCurrentExpandedIds)
        expandedIds.push(parentId);

      if (findRes.expanded == false) {
        findRes.expanded = true;
        chartInput.forEach(item => {
          if (item.activity.parentId === parentId) {
            index = currentChart.findIndex(cItem => cItem.activity.id === parentId);
            item.bulkEditSelection = BulkEditSelection.checked;
            tempChart.push(item);
          }
        });
        if (tempChart) {
          if (index > -1) currentChart.splice(index + 1, 0, ...tempChart);
        }
      }
      else {             //just select all children
        chartInput.forEach(item => {
          if (item.activity.parentId === parentId) {
            item.bulkEditSelection = BulkEditSelection.checked;
            tempChart.push(item);
          }
        });
      }
    }

    tempChart.forEach(item => {
      if (item.activity.children.length > 0) {
        this.expandSelectedParent(item.activity.id, currentChart, chartInput, expandedIds, isSelected);
      }
    });
  }




  /**
 * Updates the parent checkbox from childs status
 * @param parentId
 * @param currentChart
 * @param status
 */
  updateParentSelection(parentId: string, currentChart: IMasterScheduleActivity[], status: BulkEditSelection) {
    let findRes = currentChart.find(item => item.activity.id == parentId);
    if (findRes) {
      let clist = [];
      currentChart.forEach(item => {
        if (item.activity.parentId == parentId) {
          clist.push(item);
        }
      });
      let sflag = clist.every(item => {
        return item.bulkEditSelection == status
      });

      if (sflag)
        findRes.bulkEditSelection = status;
      else
        findRes.bulkEditSelection = 2;

      if (findRes.activity.parentId !== null) {
        this.updateParentSelection(findRes.activity.parentId, currentChart, status);
      }
    }
    else {
      return;
    }
  }


  updateEditFlagForAll(currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], expandedIds: string[], flag: BulkEditSelection): BulkEditSelection {
    let checklist = [];
    currentChart.forEach(item => checklist.push(item));
    let n = (checklist.length);

    for (let i = 0; i < n; i++) {
      let activityItem = checklist[i];
      if (activityItem.activity.parentId === null && activityItem.activity.depth === 1) {
        if (flag == 1) {
          this.expandSelectedParent(activityItem.activity.id, currentChart, chartInput, expandedIds, flag);
        }
        else {
          this.closeSelectedParent(activityItem.activity.id, currentChart, chartInput, expandedIds, flag);
        }
      }
    }
    return flag;
  }

  // returns true if atlest one flag is true otherwise returns false
  updateEditFlag(yAxisLabel, chartDisplay: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], expandedIds: string[]): boolean {

    const rowId = yAxisLabel.rowId;
    let chartItem = chartDisplay.find(item => item.activity.id === rowId);

    if (chartItem) {
      chartItem.bulkEditSelection = (chartItem.bulkEditSelection == BulkEditSelection.checked) ? 0 : 1;

      if (yAxisLabel.numChildren < 1) {
        let parentId = chartItem.activity.parentId;

        if (parentId) {
          let parentActivity = chartInput.find(item => item.activity.id === parentId);
          if (parentActivity) {
            // let allChecks ; false;
          }
        }

      }
      else {
        if (chartItem.bulkEditSelection === BulkEditSelection.checked) {
          this.expandSelectedParent(rowId, chartDisplay, chartInput, expandedIds, chartItem.bulkEditSelection);
        }
        else {
          this.closeSelectedParent(chartItem.activity.id, chartDisplay, chartInput, expandedIds, chartItem.bulkEditSelection);
        }
      }
      // Change partial selection
      if (chartItem.activity.parentId !== null) {
        this.updateParentSelection(chartItem.activity.parentId, chartDisplay, chartItem.bulkEditSelection);
      }
    }

    return chartItem.bulkEditSelection ? true : this.checkForbulkEditSelection(chartDisplay);
  }

  // Checks if bulkEditSelection flag is on for atleset one item
  checkForbulkEditSelection(chartDisplay: IMasterScheduleActivity[]): boolean {

    let parentActivity = chartDisplay.find(item => item.bulkEditSelection == 1);
    return parentActivity ? true : false;
  }

  addActivityRecursive(currentChart: IMasterScheduleActivity[], paramItem: IMasterScheduleActivity, chartInput: IMasterScheduleActivity[], expandedIds: string[]) {

    const item = paramItem;
    if (item.activity.depth && item.activity.depth > 1) {

      const parentId = item.activity.parentId;
      const parent = chartInput.find(cItem => cItem.activity.id === parentId);

      this.addActivityRecursive(currentChart, parent, chartInput, expandedIds);

      const checkCurrentChart = currentChart.find(cItem => cItem.activity.id === item.activity.id);
      if (!checkCurrentChart) {
        const index = currentChart.findIndex(cItem => cItem.activity.id === parentId);
        if (index > -1)
          currentChart.splice(index + 1, 0, item);

        const parentID = item.activity.parentId;
        const checkCurrentExpandedIds = expandedIds.find(id => id === parentID);
        if (!checkCurrentExpandedIds)
          expandedIds.push(parentID);
      }
    }
    else {
      const checkCurrentChart = currentChart.find(cItem => cItem.activity.id === item.activity.id);
      if (!checkCurrentChart) {
        currentChart.push(item);
      }
    }
  }

  updateChartDisplayForFilter(currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], filterQuery: string, expandedIds: string[], filterOption: FilterModelOptions) {

    // clear current chart and expanded id's
    expandedIds.splice(0, expandedIds.length);
    currentChart.forEach(item => { item.expanded = false; });
    currentChart.splice(0, currentChart.length);

    if (filterQuery) {

      switch (filterOption) {
        case FilterModelOptions.Activities:
          chartInput.forEach(item => {
            if (item.activity.name.toLowerCase().includes(filterQuery.toLowerCase())) {
              this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
            }
          });
          break;
        case FilterModelOptions.SubContractor:
          chartInput.forEach(item => {
            if (item.subContractorId) {
              const subcontractor = this.projectService.getLocalSubcontractor(item.subContractorId);
              if (subcontractor.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            }
          });
          break;
        case FilterModelOptions.Category:
          const activityIds = this.projectService.getActivityIdsMatchingCategoryName(filterQuery);

          let activities = [];
          activityIds.forEach(activityId => {
            chartInput.forEach(cI => {
              if (cI.activity.id == activityId) {
                activities.push(cI);
              }
            })
          });

          activities.forEach(item => {
            this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
          }
          );
          break;
        case FilterModelOptions.FloorPlan:
          chartInput.forEach(item => {
            const pdfIds = item.activity.pdfIds;
            pdfIds.forEach(pdfId => {
              const pdfObject = this.projectService.getPDFObjectForId(pdfId);
              if (pdfObject.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          });
          break;
        case FilterModelOptions.Equipment:
          chartInput.forEach(item => {
            const equipmentId = item.activity.equipmentIds;
            equipmentId.forEach(eId => {
              const equipment = this.equipmentService.getLocalEquipmentById(eId);
              if (equipment.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          });
          break;
        case FilterModelOptions.Materials:
          chartInput.forEach(item => {
            const materialId = item.activity.materialIds;
            materialId.forEach(mId => {
              const material = this.materialService.getLocalMaterialById(mId);

              if (material.name.toLowerCase().includes(filterQuery.toLowerCase())) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          });
          break;
      }

      expandedIds.forEach(id => {
        const matchingItem = currentChart.find(item => item.activity.id === id);
        if (matchingItem)
          matchingItem.expanded = true;
      });

    }
    else {
      chartInput.forEach(item => {
        item.expanded = false;
        if (item.activity.depth === 1) {
          currentChart.push(item);
        }
      });
    }
  }


  updateChartDisplayForSelection(currentChart: IMasterScheduleActivity[], chartInput: IMasterScheduleActivity[], selections: string[], expandedIds: string[], filterOption: FilterModelOptions, orFilterType: boolean) {

    // clear current chart and expanded id's
    expandedIds.splice(0, expandedIds.length);
    currentChart.forEach(item => { item.expanded = false; });
    currentChart.splice(0, currentChart.length);

    if (selections.length) {

      switch (filterOption) {
        case FilterModelOptions.Activities:
          selections.forEach(sItem => {
            chartInput.forEach(item => {
              if (item.activity.id === sItem) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          });
          break;
        case FilterModelOptions.SubContractor:
          selections.forEach(sItem => {
            chartInput.forEach(item => {
              if (item.subContractorId === sItem) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          });

          break;
        case FilterModelOptions.Category:

          let activityIds = []
          selections.forEach(sItem => {
            activityIds.push(...this.projectService.getActivityIdsMatchingCategoryId(sItem));
          });

          let activityIdListSetUniq = new Set(activityIds);
          let activities = [];

          activityIdListSetUniq.forEach(activityId => {
            chartInput.forEach(cI => {
              if (cI.activity.id == activityId) {
                activities.push(cI);
              }
            })
          });

          activities.forEach(item => {
            this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
          });

          break;

        case FilterModelOptions.FloorPlan:

          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.pdfIds.includes(sItem)) {
                  this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
                }
              });
            });
          } else {
            chartInput.forEach(item => {
              if (Utils.checker(item.activity.pdfIds, selections)) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          }

          break;
        case FilterModelOptions.Equipment:

          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.equipmentIds.includes(sItem)) {
                  this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
                }
              });
            });
          } else {
            chartInput.forEach(item => {
              if (Utils.checker(item.activity.equipmentIds, selections)) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          }
          break;
        case FilterModelOptions.Materials:

          if (orFilterType) {
            selections.forEach(sItem => {
              chartInput.forEach(item => {
                if (item.activity.materialIds.includes(sItem)) {
                  this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
                }
              });
            });

          } else {

            chartInput.forEach(item => {
              if (Utils.checker(item.activity.materialIds, selections)) {
                this.addActivityRecursive(currentChart, item, chartInput, expandedIds);
              }
            });
          }
          break;
      }

      expandedIds.forEach(id => {
        const matchingItem = currentChart.find(item => item.activity.id === id);
        if (matchingItem)
          matchingItem.expanded = true;
      });

    }
    else {
      chartInput.forEach(item => {
        item.expanded = false;
        if (item.activity.depth === 1) {
          currentChart.push(item);
        }
      });
    }
  }



  isCurrentSelectedOrChild(id: string, selectedIds: string[], chartDisplay: IMasterScheduleActivity[]): boolean {
    const childIds: string[] = this.getChildrenIds(id, [], chartDisplay);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < selectedIds.length; i++) {
      if (childIds.includes(selectedIds[i])) return true;
    }
    return false;
  }

  getChildrenIds(id, list, allActivities) {
    const children = allActivities.filter(item => item.activity.parentId === id);
    children.forEach(child => {
      list.push(child.activity.id);
      this.getChildrenIds(child.activity.id, list, allActivities);
    });
    return list;
  }
  // END NEST HELPERS

  // GETTERS
  getXaxisChartLabelPos(day: number, nextDay: number, scaleFunction: (day) => number): string {
    const xPos = scaleFunction(day + ((nextDay - day) / 2));
    return `translate(${xPos}, 0)`;
  }

  getYaxisLabelXcoord(depth: number): number {
    const d = depth > this.gO.maxDepth ? this.gO.maxDepth : depth;
    return this.gO.yAxisDepthOffset * (d - 1);
  }

  getBarHeight(numChildren: number): number {
    return numChildren > 0 ? this.gO.parentBarHeight : this.gO.barHeight;
  }

  getBarYcoord(id: string, barHeight: number, rowScales): number {
    return rowScales[id](0) + ((this.gO.barHeight - barHeight) / 2);
  }

  getExpandedStateIcon(data): string {
    if (data.numChildren < 1) return;
    return data.expanded ? this.gO.expandedIcon : this.gO.closedIcon;
  }

  getCheckBoxIcon(data): string {

    if (data.bulkEditSelection == 1)
      return this.gO.checkSquareIcon
    else if (data.bulkEditSelection == 2)
      return this.gO.partialCheckSquareIcon
    else
      return this.gO.squareIcon
  }

  getCheckBoxIconForFlag(flag: BulkEditSelection): string {
    if (flag == 1)
      return this.gO.checkSquareIcon
    else if (flag == 2)
      return this.gO.partialCheckSquareIcon
    else
      return this.gO.squareIcon
  }

  getDataExternalActivityId(data): string {
    if (data.numChildren > 1) return;
    return data.externalActivityId;
  }

  getYaxisLabelBackgroundColor(depth: number): string {
    switch (depth % 5) {
      case 0:
        return BGColorPalette.D1;
      case 1:
        return BGColorPalette.D2;
      case 2:
        return BGColorPalette.D3;
      case 3:
        return BGColorPalette.D4;
      case 4:
        return BGColorPalette.D5;
    }
    return '#000000';
  }

  getValidYaxisLabelLength(label: string, depth: number): string {
    const charPerDepth = 2;
    const cpd = 40;
    const validLength = depth > this.gO.maxDepth ? cpd - (charPerDepth * this.gO.maxDepth) : cpd - (charPerDepth * depth);
    if (label.length > validLength) {
      return label.substring(0, Math.min(validLength, label.length)) + '...';
    } else {
      return label;
    }
  }

  getLeafActivitiesInRange(chartDisplay: IMasterScheduleActivity[], rangeStart: number): IMasterScheduleActivity[] {
    let leafActivities = chartDisplay.filter(d => d.activity.children.length < 1);
    const rangeEnd = moment.utc(rangeStart).clone().add(this.gO.chartInterval, 1).valueOf();
    leafActivities = leafActivities.filter(leaf => this.isActivityRangeInRange(leaf.activity.startDate, leaf.activity.endDate, rangeStart, rangeEnd));
    return leafActivities;
  }

  isActivityRangeInRange(activityStart: number, activityEnd: number, rangeStart: number, rangeEnd: number): boolean {
    if (activityStart >= rangeStart && activityStart < rangeEnd) return true;
    if (activityEnd >= rangeStart && activityEnd < rangeEnd) return true;
    if (rangeStart >= activityStart && rangeStart < activityEnd) return true;
    return false;
  }

  getPastActivitiesFromRange(chartDisplay: IMasterScheduleActivity[], rangeStart: number): IMasterScheduleActivity[] {
    return chartDisplay.filter(d => d.activity.children.length < 1 && d.activity.endDate < rangeStart);
  }

  buildPastAndActiveObjectIds(pastActivities: IMasterScheduleActivity[], activitiesInRange: IMasterScheduleActivity[], filteredObjectIds: string[]) {
    const returnObj = {
      viewState: {},
      activityIds: []
    };
    pastActivities.forEach(act => {
      act.activity.objectIds.forEach(id => {
        if (!returnObj.viewState[id] && (filteredObjectIds.includes(id) || filteredObjectIds.length === 0)) returnObj.viewState[id] = id;
      });
    });
    activitiesInRange.forEach(act => {
      act.activity.objectIds.forEach(id => {
        if (filteredObjectIds.includes(id) || filteredObjectIds.length === 0) {
          if (!returnObj.viewState[id]) returnObj.viewState[id] = id;
          if (!returnObj.activityIds.includes(act.activity.id)) returnObj.activityIds.push(act.activity.id);
        }
      });
    });
    return returnObj;
  }
  // END GETTERS

  // TOOLTIP
  drawInfoTooltip(data: any): void {
    const ttWidth = 300;
    const ttHeight = 200;
    const ttPosition = this.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);
    this.tooltipTimeout = setTimeout(
      () => {
        D3.selectAll('.D3-tooltip')
          .style('left', ttPosition.left)
          .style('top', ttPosition.top)
          .style('height', ttHeight + 'px')
          .style('width', ttWidth + 'px')
          .style('display', 'flex')
          .html(this.getTooltipInfo(data));
      }, 500);

  }

  drawMaterialInfoTooltip(data: any): void {
    const ttWidth = 250;
    const ttHeight = 120;
    const ttPosition = this.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);
    this.tooltipTimeout = setTimeout(
      () => {
        D3.selectAll('.D3-tooltip')
          .style('left', ttPosition.left)
          .style('top', ttPosition.top)
          .style('height', ttHeight + 'px')
          .style('width', ttWidth + 'px')
          .style('display', 'flex')
          .html(this.getMaterialTooltipInfo(data));
      }, 500);

  }

  drawRfiInfoTooltip(data: any): void {
    const ttWidth = 250;
    const ttHeight = 120;
    const ttPosition = this.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);
    this.tooltipTimeout = setTimeout(
      () => {
        D3.selectAll('.D3-tooltip')
          .style('left', ttPosition.left)
          .style('top', ttPosition.top)
          .style('height', ttHeight + 'px')
          .style('width', ttWidth + 'px')
          .style('display', 'flex')
          .html(this.getRfiTooltipInfo(data));
      }, 500);

  }

  drawTextTooltip(text: string): void {
    const charPerWidth = 6.5;
    const validLength = 100;
    const displayText = text.substring(0, Math.min(validLength, text.length)) + '...';
    const ttWidth = displayText.length * charPerWidth;
    const ttHeight = 10;
    const ttPosition = this.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);
    this.tooltipTimeout = setTimeout(
      () => {
        D3.selectAll('.D3-tooltip')
          .style('left', ttPosition.left)
          .style('top', ttPosition.top)
          .style('width', ttWidth + 'px')
          .style('height', ttHeight + 'px')
          .style('display', 'flex')
          .html(`<p>${text}</p>`);
      }, 500);
  }

  clearTooltip(): void {
    clearTimeout(this.tooltipTimeout);
    D3.selectAll('.D3-tooltip').style('display', 'none');
  }

  getMaterialTooltipInfo(data: any): string {
    let tooltip;
    tooltip = ``;
    // if(data.externalActivityId) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('ID') + `: </span><span class='value'>` + data.externalActivityId + `</span></div>`;
    if (data.name) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('activity') + `: </span><span class='value'>` + data.name + `</span></div>`;
    if (data.materialName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('Material Name') + `: </span><span class='value'>` + data.materialName + `</span></div>`;
    if (data.ActualSubmitBy) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('Submit By.') + `: </span><span class='value'>` + Utils.fromUtcDate(data.ActualSubmitBy).toLocaleDateString() + `(` + data.tMinusDisplayDueSubmitDate + `)</span></div>`;
    if (data.ActualApproved) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('Approve By') + `: </span><span class='value'>` + Utils.fromUtcDate(data.ActualApproved).toLocaleDateString() + `(` + data.tMinusDisplayDueApproved + `)</span></div>`;
    if (data.ActualROS) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('R.O.S.') + `: </span><span class='value'>` + Utils.fromUtcDate(data.ActualROS).toLocaleDateString() + `(` + data.tMinusDisplayDueROS + `)</span></div>`;
    // if (data.tMinusDisplay) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('T- XX due dates') + `: </span><span class='value'>` + data.tMinusDisplay + ` (Days)</span></div>`;
    // // tslint:disable-next-line:max-line-length
    // if (data.durationHours) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('hours') + `: </span><span class='value'>` + data.durationHours + ` (Hours)</span></div>`;
    // if (data.subName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('subcontractor') + `: </span><span class='value'>` + data.subName + `</span></div>`;
    // // tslint:disable-next-line:max-line-length
    // if (data.milestoneName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('milestone') + `: </span><span class='value'>` + data.milestoneName + `</span></div>`;
    // if (data.status) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('status') + `: </span><span class='value'>` + data.status + `</span></div>`;
    return tooltip;
  }

  getRfiTooltipInfo(data: any): string {
    let tooltip;
    tooltip = ``;

    if (data.name) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('activity') + `: </span><span class='value'>` + data.name + `</span></div>`;
    if (data.rfiName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('RFI Name') + `: </span><span class='value'>` + data.rfiName + `</span></div>`;
    if (data.rfiStartDate) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('Start Date') + `: </span><span class='value'>` + Utils.fromUtcDate(data.rfiStartDate).toLocaleDateString() + `</span></div>`;
    if (data.dueDate) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('Due Date') + `: </span><span class='value'>` + Utils.fromUtcDate(data.dueDate).toLocaleDateString() + `</span></div>`;

    return tooltip;
  }

  getTooltipInfo(data: any): string {
    let tooltip;
    tooltip = ``;
    if (data.externalActivityId) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('ID') + `: </span><span class='value'>` + data.externalActivityId + `</span></div>`;
    if (data.name) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('name') + `: </span><span class='value'>` + data.name + `</span></div>`;
    if (data.startDate) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('start_date') + `: </span><span class='value'>` + data.startDate + `</span></div>`;
    if (data.endDate) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('end_date') + `: </span><span class='value'>` + data.endDate + `</span></div>`;
    tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('actual_start_date') + `: </span><span class='value'>` + ( !Utils.isEmpty(data.started) ? Utils.formatDate(data.started): '-' )+ `</span></div>`;
    tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('actual_finish_date') + `: </span><span class='value'>` + ( !Utils.isEmpty(data.completed) ? Utils.formatDate(data.completed): '-' )+ `</span></div>`;
    if (data.expectedFinishDate) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('expected_end_date') + `: </span><span class='value'>` + data.expectedFinishDate + `</span></div>`;
    // if (data.expectedFinishDate) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('expected_end_date') + `: </span><span class='value'>` + data.expectedFinishDate + `</span></div>`;
    if (data.duration) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('Duration') + `: </span><span class='value'>` + data.duration + `</span></div>`;
    // tslint:disable-next-line:max-line-length
    if (data.durationHours) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('hours') + `: </span><span class='value'>` + data.durationHours + ` (Hours)</span></div>`;
    if (data.subName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('subcontractor') + `: </span><span class='value'>` + data.subName + `</span></div>`;
    // tslint:disable-next-line:max-line-length
    if (data.milestoneName) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('milestone') + `: </span><span class='value'>` + data.milestoneName + `</span></div>`;
    if (data.status) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('status') + `: </span><span class='value'>` + data.status + `</span></div>`;
    return tooltip;
  }
  // END TOOLTIP

  // CHART CONTROLS
  getChartControls(startDay: number, endDay: number): IProjectScheduleControls {
    return {
      bulkEdit: true,
      reset: true,
      today: true,
      search: false,
      animation: this.projectService.getHasModelsStatus(),
      timeFrames: {
        display: true,
        rangeStartDate: Utils.formatDate(startDay),
        rangeEndDate: Utils.formatDate(endDay),
        daily: {
          title: 'week',
          display: true,
        },
        range: true,
        start: {
          title: 'scheduled_start',
          display: true
        },
        end: {
          title: 'scheduled_end',
          display: true
        }
      },
      customControls: true
    };
  }

  setChartMode(mode: ViewMode): boolean {
    this.curGanttDisplay = mode;
    let returnVal: boolean = true;
    for (const m of this.ganttDisplayModes) {
      if (m.mode === mode) {
        if (m.selected) returnVal = false;
        else m.selected = true;
      } else {
        m.selected = false;
      }
    }
    return returnVal;
  }

  editChartIntervals(mode: ViewMode) {
    switch (mode) {
      case ViewMode.OneMonthGantt:
        this.gO.chartInterval = ChartInterval.Week;
        this.gO.chartRange = 4;
        this.gO.rangeMovement = 4;
        break;
      case ViewMode.ThreeMonthGantt:
        this.gO.chartInterval = ChartInterval.Week;
        this.gO.chartRange = 12;
        this.gO.rangeMovement = 12;
        break;
      case ViewMode.SixMonthGantt:
        this.gO.chartInterval = ChartInterval.Month;
        this.gO.chartRange = 6;
        this.gO.rangeMovement = 6;
        break;
      case ViewMode.TwelveMonthGantt:
        this.gO.chartInterval = ChartInterval.Month;
        this.gO.chartRange = 12;
        this.gO.rangeMovement = 12;
        break;
    }
  }

  getStatus(status: StepStatus): string {
    // Cases based on StepStatus
    // returns based on TaskStatusColor object keys
    switch (status) {
      case StepStatus.Planned:
      case StepStatus.Recommended:
      case StepStatus.Backlog:
        return 'Scheduled';
      case StepStatus.Committed:
        return 'Committed';
      case StepStatus.InProgress:
        return 'InProgress';
      case StepStatus.Completed:
        return 'Completed';
      default:
        return 'Scheduled'; // should not ever reach here only scheduled tasks should appear
    }
  }

  makeActivityCircleCenterData(yStartCoord: number, hasMatPreparation?: boolean, chartMaterialActivityStartCoord?: number,
    chartmaterialbarXCoordFND?: number, chartmaterialbarXCoordSubmitDate?: number, matbarWidth?: number) {
    const centerData: Array<{ x: number, y: number }> = [];
    let materialbarXCoordPrep: number, materialbarXCoordFND: number, materialbarXCoordSubmitDate: number;
    if (hasMatPreparation) {
      if (chartMaterialActivityStartCoord !== 1)
        materialbarXCoordPrep = chartMaterialActivityStartCoord + (this.gO.barPadding / 2);
      yStartCoord = yStartCoord + this.gO.barHeight / 2 - 2;

      if (chartmaterialbarXCoordFND !== 1)
        materialbarXCoordFND = chartmaterialbarXCoordFND + (this.gO.barPadding / 2);

      if (chartmaterialbarXCoordSubmitDate !== 1)
        materialbarXCoordSubmitDate = chartmaterialbarXCoordSubmitDate + (this.gO.barPadding / 2);
      centerData.push({ x: materialbarXCoordPrep, y: yStartCoord });
      centerData.push({ x: materialbarXCoordFND, y: yStartCoord });
      centerData.push({ x: materialbarXCoordSubmitDate, y: yStartCoord });
    }

    return centerData;
  }

  makeActivityRfiCircleCenterData(yStartCoord: number, hasRfiPreparation?: boolean, chartRfiActivityStartCoord?: number, chartRfibarXCoordSubmitDate?: number, rfiBarWidth?: number) {
    const centerData: Array<{ x: number, y: number }> = [];
    if (hasRfiPreparation) {
      const rfiBarXCoordPrep = chartRfiActivityStartCoord + (this.gO.barPadding / 2);
      yStartCoord = yStartCoord + this.gO.barHeight / 2 - 2;
      const rfiBarXCoordSubmitDate = chartRfibarXCoordSubmitDate + (this.gO.barPadding / 2);
      centerData.push({ x: rfiBarXCoordPrep, y: yStartCoord });
      centerData.push({ x: rfiBarXCoordSubmitDate, y: yStartCoord });
    }

    return centerData;
  }

  makeActivityBarPathData(xStartCoord: number, yStartCoord: number, barWidth: number, hasStartMarker: boolean, hasEndMarker: boolean,
    isParent: boolean, hasMatPreparation?: boolean, chartMaterialActivityStartCoord?: number, matbarWidth?: number, hasRfiPreparation?: boolean, chartRfiActivityStartCoord?: number, rfiBarWidth?: number): Array<{ x: number, y: number }> {
    const pathData: Array<{ x: number, y: number }> = [];
    const barXCoord = xStartCoord + (this.gO.barPadding / 2);
    pathData.push({ x: barXCoord, y: yStartCoord });
    pathData.push({ x: barXCoord + barWidth, y: yStartCoord });
    const yC = isParent ? this.gO.parentBarHeight : this.gO.barHeight;
    pathData.push({ x: barXCoord + barWidth, y: yStartCoord + yC });
    pathData.push({ x: barXCoord, y: yStartCoord + yC });
    pathData.push({ x: barXCoord, y: yStartCoord });
    pathData.push(null);

    if (hasStartMarker) {
      pathData.push({ x: xStartCoord, y: yStartCoord - (this.gO.blockMarkerHeight / 2) });
      pathData.push({ x: xStartCoord + this.gO.blockMarkerWidth, y: yStartCoord - (this.gO.blockMarkerHeight / 2) });
      pathData.push({ x: xStartCoord + this.gO.blockMarkerWidth, y: yStartCoord + (this.gO.blockMarkerHeight / 2) + this.gO.parentBarHeight });
      pathData.push({ x: xStartCoord, y: yStartCoord + (this.gO.blockMarkerHeight / 2) + this.gO.parentBarHeight });
      pathData.push({ x: xStartCoord, y: yStartCoord - (this.gO.blockMarkerHeight / 2) });
      pathData.push(null);
    }

    if (hasEndMarker) {
      const xC = (xStartCoord + barWidth) - (this.gO.blockMarkerWidth / 2);
      pathData.push({ x: xC, y: yStartCoord - (this.gO.blockMarkerHeight / 2) });
      pathData.push({ x: xC + this.gO.blockMarkerWidth, y: yStartCoord - (this.gO.blockMarkerHeight / 2) });
      pathData.push({ x: xC + this.gO.blockMarkerWidth, y: yStartCoord + (this.gO.blockMarkerHeight / 2) + this.gO.parentBarHeight });
      pathData.push({ x: xC, y: yStartCoord + (this.gO.blockMarkerHeight / 2) + this.gO.parentBarHeight });
      pathData.push({ x: xC, y: yStartCoord - (this.gO.blockMarkerHeight / 2) });
      pathData.push(null);
    }

    if (hasMatPreparation) {
      const materialbarXCoord = chartMaterialActivityStartCoord + (this.gO.barPadding / 2);
      yStartCoord = yStartCoord + this.gO.barHeight / 2 - 2;
      pathData.push({ x: materialbarXCoord, y: yStartCoord });
      pathData.push({ x: materialbarXCoord + matbarWidth, y: yStartCoord });
      const yC = this.gO.parentBarHeight;
      pathData.push({ x: materialbarXCoord + matbarWidth, y: yStartCoord + yC });
      pathData.push({ x: materialbarXCoord, y: yStartCoord + yC });
      pathData.push({ x: materialbarXCoord, y: yStartCoord });
      pathData.push(null);
    }

    if (hasRfiPreparation) {

      const rfiBarXCoord = chartRfiActivityStartCoord + (this.gO.barPadding / 2);
      yStartCoord = yStartCoord + this.gO.barHeight / 2 - 2;
      pathData.push({ x: rfiBarXCoord, y: yStartCoord });
      pathData.push({ x: rfiBarXCoord + rfiBarWidth, y: yStartCoord });
      const yC = this.gO.parentBarHeight;
      pathData.push({ x: rfiBarXCoord + rfiBarWidth, y: yStartCoord + yC });
      pathData.push({ x: rfiBarXCoord, y: yStartCoord + yC });
      pathData.push({ x: rfiBarXCoord, y: yStartCoord });
      pathData.push(null);
    }

    return pathData;
  }

  drawColumnSelections(selDays: number[], scaleFunction: (day) => number, container, numRows: number) {
    const columnData = [];
    selDays.forEach(day => {
      const startX = scaleFunction(day);
      const nextDay = moment.utc(day).clone().add(1, this.gO.chartInterval).valueOf();
      const colWidth = scaleFunction(nextDay) - startX;
      const startY = this.gO.xAxisLabelHeightOffset;
      columnData.push({
        x: startX,
        y: startY,
        width: colWidth,
        height: numRows * this.gO.rowHeight
      });
    });

    const transform = `translate(${this.gO.gridOffsetLeft}, ${this.gO.xAxisLabelYpos})`;
    const colSelections = D3.select(container)
      .attr('class', 'column-sel')
      .attr('transform', transform)
      .selectAll('rect')
      .remove()
      .exit()
      .data(columnData)
      .enter();

    colSelections
      .append('rect')
      .attr('pointer-events', 'none')
      .attr('fill', 'black')
      .attr('stroke', 'transparent')
      .style('opacity', .2)
      .attr('y', (d) => d.y)
      .attr('x', (d) => d.x)
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width);
  }

  clearColumnSelections(container) {
    D3.select(container)
      .selectAll('rect')
      .remove();
  }

}
