import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { UserService } from '../../../services/user/user.service';

@Injectable()
export class ProjectMessageService {
  public userMessageAvatars = {};

  constructor(
    private http: HttpBackendService,
    private userService: UserService
  ) {}

  public getProjectMessage(projectId: string, messageId: string): Observable<any> {
    return this.http.get('/project/' + projectId + '/message/' + messageId);
  }

  public getProjectStepMessages(projectId: string, projectStepId: string): Observable<any> {
    return this.http.paged('/project/' + projectId + '/step/' + projectStepId + '/messages');
  }

  public getProjectObjectMessages(projectId: string, projectObjectId: string): Observable<any> {
    return this.http.paged('/project/' + projectId + '/object/' + projectObjectId + '/messages');
  }

  public getProjectActivityMessages(projectId: string, projectActivityId: string): Observable<any> {
    return this.http.paged('/project/' + projectId + '/activity/' + projectActivityId + '/messages');
  }

  public createProjectMessage(json: any, file: any, messageType: string): Observable<any> {
    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
    formData.append('file', file);
    return this.http.formData('/project/' + messageType + '/message', formData);
  }

  public getMessageFile(projectId: string, stepMessageId: string, messageType: string): Observable<any> {
      return this.http.getFile('/project/' + projectId + '/' + messageType + '/message/' + stepMessageId + '/image');
  }

  public getUserAvatar(userId: string) {
    if (this.userMessageAvatars.hasOwnProperty(userId)) return;

    this.userMessageAvatars[userId] = null;
    return new Promise((resolve, reject) => {
      this.userService.getUserAvatar(userId).subscribe(
        result => {
          this.userMessageAvatars[userId] = result;
          resolve(true);
        },
        err => {
          resolve(false);
        }
      );
    });
  }

}
