import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Socket } from 'ng-socket-io';
import { Observable, Subject } from 'rxjs';
import { TranslationService } from '../translation/translation.service';

import { HttpBackendService } from '../http-backend/http-backend.service';

import { EventStatus, EventType } from '../../utils/enums/notification.enum';
import { NotificationType } from '../../utils/enums/notification.enum';
import { INotification } from '../../models/notification/notification.interface';
import { ISocketResponse } from '../../models/notification/socketResponse.interface';
import { Utils } from '../../utils/utils';

declare var Notification: any;

@Injectable()
export class NotificationService {
    socketConnected: boolean = false;
    socketAuthenticated: boolean = false;
    lastMessages = {};
    lastPoll: number = 0;
    projectId: string = null;
    mutedEventTypes: string[] = [];

    private emitEventSource = new Subject<any>();
    event$ = this.emitEventSource.asObservable();

    private emitNotificationSource = new Subject<any>();
    notification$ = this.emitNotificationSource.asObservable();

    private emitProcessSource = new Subject<any>();
    process$ = this.emitProcessSource.asObservable();

    private subject = new Subject<any>();
    private autohide = true;

    constructor(
        private router: Router,
        private socket: Socket,
        private http: HttpBackendService
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.autohide) {
                    this.autohide = false;
                } else {
                    this.clear();
                }
            }
        });

        // Request permission for browser notifications
        if ('Notification' in window) {
            if (Notification.permission === 'default') {
                Notification.requestPermission();
            }
        }

        setInterval(() => {
            if (this.socketConnected && this.lastPoll < new Date().getTime()) {
                this.socketConnected = false;
                this.socketAuthenticated = false;
                this.setupSocket();
            }
        }, 10000);
    }

    getNotification(): Observable<any> {
        return this.subject.asObservable();
    }

    success(key: string, variables?: any) {
        let msg = TranslationService.translate(key.toLocaleLowerCase(), variables);
        if (msg === key) msg =  TranslationService.translate('success');
        this.eventPopup(EventStatus.Success, msg);
    }

    error(key: any, variables?: any) {
        if (key.details) {
            variables = variables || {};
            variables.details = key.details;
            key = key.message;
        }
        let msg = TranslationService.translate(key.toLocaleLowerCase(), variables);
        if (msg === key) msg =  TranslationService.translate('error');
        this.eventPopup(EventStatus.Error, msg);
    }

    info(key: string, variables?: any) {
        let msg = TranslationService.translate(key.toLocaleLowerCase(), variables);
        if (msg === key) msg =  TranslationService.translate('error');
        this.eventPopup(EventStatus.Info, msg);
    }

    async eventPopup(type: EventStatus, message: string) {
        this.subject.next({ type: type, message: message } as INotification);
    }

    clear() {
        this.subject.next();
    }

    setupSocket() {
        if (this.socketConnected) {
            return;
        }

        this.socket.disconnect();
        this.socket.connect();
        this.socket.emit('authenticate', JSON.stringify({csrfToken: localStorage.getItem('csrfToken'), lastMessages: this.lastMessages}));
        this.socketConnected = true;
        this.socket.on('event', (response) => {
            this.lastMessages[response.projectId] = Math.max(this.lastMessages[response.projectId] || 0, response['X-Timestamp'] || 0);
            const socketResponse: ISocketResponse = {
                eventType: response.eventType,
                projectId: response.projectId,
                body: response.body
            };
            this.emitEventSource.next(socketResponse);
        });
        this.socket.on('notification', (response) => {
            this.lastMessages['default'] = Math.max(this.lastMessages['default'] || 0, response['X-Timestamp'] || 0);
            this.sendBrowserNotification(response);
            this.emitNotificationSource.next(response);
        });
        this.socket.on('process', (response) => {
            this.lastMessages['default'] = Math.max(this.lastMessages['default'] || 0, response['X-Timestamp'] || 0);
            if (response.body === 1 || response.body === 2) {
                this.sendBrowserNotification(response);
            }
            this.emitProcessSource.next(response);
        });
        this.socket.on('connected', (response) => {
            if (this.socketAuthenticated) {
                this.lastPoll = new Date().getTime() + response.next;
            }
        });
        this.socket.on('authenticated', (response) => {
            this.socketAuthenticated = true;
            this.lastPoll = new Date().getTime() + response.next;
        });
        this.lastPoll = new Date().getTime() + 15000;
    }

    validEvent(event: any, projectId: string, eventType: EventType): boolean {
        if (projectId !== event.projectId) return false;
        if (eventType !== event.eventType) return false;
        return true;
    }

    toggleMuteNotifications(eventType: string) {
        const indexToRemove = this.mutedEventTypes.indexOf(eventType);
        if (indexToRemove > -1) this.mutedEventTypes = this.mutedEventTypes.splice(indexToRemove + 1, 1);
        else (this.mutedEventTypes.push(eventType));
    }

    getNotifications(): Observable<any> {
        return this.http.get('/user/notifications');
    }

    getProcesses(): Observable<any> {
        return this.http.get('/user/processes');
    }

    seeNotification(notificationId: string): Observable<any> {
        return this.http.get('/user/notification/' + notificationId + '/see');
    }

    dismissNotification(notificationId: string): Observable<any> {
        return this.http.get('/user/notification/' + notificationId + '/dismiss');
    }

    sendBrowserNotification(notificationData) {
        if ('Notification' in window) {
            let body;
            if (notificationData.notificationType === 'process') {
                body = notificationData.subjectName + '\n';
                if (notificationData.body === 2) body = body + 'Complete';
                else if (notificationData.body === 1) body = body + 'Failed';
            } else {
                body = notificationData.fromUserName + '\n' + notificationData.body;
            }
            const options = {
                body: body,
                icon: '../../assets/img/menu-brand.png'
            };
            if (Notification.permission === 'granted') {
                const notification = new Notification(notificationData.projectName, options);
                notification.addEventListener('click', (evt) => {
                    this.router.navigateByUrl(this.getNotificationUrl(notificationData));
                });
            } else if (Notification.permission === 'default') {
                Notification.requestPermission(permission => {
                    if (permission === 'granted') {
                        const notification = new Notification(notificationData.projectName, options);
                        notification.addEventListener('click', (evt) => {
                            this.router.navigateByUrl(this.getNotificationUrl(notificationData));
                        });
                    }
                });
            }
        }
    }

    getNotificationUrl(notification): string {
        let url = 'project/' + notification.projectId;
        switch (notification.notificationType) {
            case NotificationType.OBJECT_MESSAGE:
                url = url + '/models?objectId=' + notification.subjectId + '&message=true';
                break;
            case NotificationType.TASK_MESSAGE:
                url = url + '/schedule?stepId=' + notification.subjectId + '&message=true';
                break;
            case NotificationType.ACTIVITY_MESSAGE:
                url = url + '/master-schedule?activityId=' + notification.subjectId + '&message=true';
                break;
            case NotificationType.SPRINT:
                url = url + '/sprints?sprintId=' + notification.subjectId;
                break;
            case NotificationType.SCHEDULE:
                url = url + '/schedule?scheduleId=' + notification.subjectId;
                break;
            case NotificationType.PROCESS:
                if (notification.subjectType === 'Schedule') {
                    url = url + '/schedule?scheduleId=' + notification.subjectId;
                } else if (notification.subjectType === 'Model') {
                    url = url + '/models?modelId=' + notification.subjectId;
                }
                break;
        }
        return url;
    }
}
