import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Utils } from '../../utils/utils';

import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import {
  IDashboardColHead,
  IDashboardGrid,
  IDashboardGridRow
} from '../../models/project/project-dashboard/project-dashboard.interface';

@Component({
  selector: 'app-project-dashboard-sprint-complete',
  templateUrl: './project-dashboard-sprint-complete.component.html',
  styleUrls: ['./project-dashboard-sprint-complete.component.scss']
})
export class ProjectDashboardSprintCompleteComponent implements OnInit, OnChanges {
  @Input() sprintDdInput: IDropdownItem[];
  @Input() gridDataInput: IDashboardGridRow[];
  @Input() overallPercentCompleteInput: any;

  @Output() sprintIdOutput = new EventEmitter<string>();

  loadingMessage: string = 'Loading...';
  isLoading: boolean = true;
  noDataMessage: string = 'no_data';
  noData: boolean = true;
  committedCount: number;
  inProgressCount: number;
  completedCount: number;
  notCompletedCount: number;
  selectedSprintId: any;
  showSubTable: boolean = false;
  columnHeaders: IDashboardColHead[] = [
    {label: 'subcontractor', sortKey: 'subcontractor', active: true, ascending: true, type: 'string'},
    {label: 'committed', sortKey: 'committed', active: false, ascending: false, type: 'number'},
    {label: 'in_progress', sortKey: 'inProgress', active: false, ascending: false, type: 'number'},
    {label: 'completed', sortKey: 'completed', active: false, ascending: false, type: 'number'},
    {label: 'not_completed', sortKey: 'notCompleted', active: false, ascending: false, type: 'number'},
    {label: 'actual_hours_percent_complete', sortKey: 'actHrs', active: false, ascending: false, type: 'string'},
    {label: 'man_hours_percent_complete', sortKey: 'manHrs', active: false, ascending: false, type: 'string'}
  ];
  dashboardGridInput: IDashboardGrid;
  selectedSprintStatus: string;

  // tslint:disable-next-line:no-empty
  constructor() {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (this.gridDataInput) {
      this.setupData();
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  setupData(): void {
    if (this.sprintDdInput) this.setSelectedSprintItem();

    if (this.gridDataInput.length > 0) {
      this.setOverallTotals(this.gridDataInput);
      this.setupGrid(this.gridDataInput);

      this.noData = false;
    } else {
      this.noData = true;
    }

  }

  /**
   * setupGrid - takes input and static heads and transforms them into what is needed for project-grid
   * @param {IDashboardGridRow[]} gridRows
   */
  setupGrid(gridRows: IDashboardGridRow[]): void {
    this.dashboardGridInput = {
      colHeads: this.columnHeaders,
      rows: gridRows
    };
  }

  setSelectedSprintItem(): void {
    if (!this.selectedSprintId) {
      this.selectedSprintId = this.sprintDdInput[0].value;
      const selectedSprint = this.sprintDdInput.find(sprint => sprint.value === this.selectedSprintId);
      this.selectedSprintStatus = !Utils.isEmpty(selectedSprint) && selectedSprint['status']
        ? selectedSprint['status']
        : null;
    }
  }

  /**
   * setOverallTotals - aggregates the totals for 'badge' displays
   * @param gridRows
   */
  setOverallTotals(gridRows): void {
    let committedCount = 0;
    let inProgressCount = 0;
    let completedCount = 0;
    let notCompletedCount = 0;

    let rowItems = gridRows.map(row => row.items);
    rowItems = [].concat(...rowItems);
    if (!Utils.isEmpty(rowItems)) {
      rowItems.forEach(item => {
        if (item.key === 'committed') committedCount += Number(item.label);
        if (item.key === 'inProgress') inProgressCount += Number(item.label);
        if (item.key === 'completed') completedCount += Number(item.label);
        if (item.key === 'notCompleted') notCompletedCount += Number(item.label);
      });
    }

    this.committedCount = committedCount;
    this.inProgressCount = inProgressCount;
    this.completedCount = completedCount;
    this.notCompletedCount = notCompletedCount;
  }

  handleSubTotalsClick(): void {
    this.showSubTable = !this.showSubTable;
  }

  handleSprintSelection(): void {
    const selectedSprint = this.sprintDdInput.find(sprint => sprint.value === this.selectedSprintId);
    this.selectedSprintStatus = !Utils.isEmpty(selectedSprint) && selectedSprint['status']
      ? selectedSprint['status']
      : null;

    this.sprintIdOutput.emit(this.selectedSprintId);
  }
}
