import { Injectable } from '@angular/core';

import { IUserPermission } from '../../../models/user/user.interface';

export interface IProjectSchedCriteriaData {
    list: IProjectSchedCriteriaList[];
    pageAction: string;
    showSplashScreen?: boolean;
    splashScreenMsg?: string;
    splashButtonText?: string;
}
export interface IProjectSchedCriteriaList {
    label: string;
    key: CriteriaKeyEnum;
    completed: boolean;
    link: string[];
    linkAction: string;
    permission: boolean;
    query?: object;
    routeKeys?: RouteKeysEnum[];
}

export enum CriteriaKeyEnum {
    UploadModels = 'uploadModels',
    UploadActivityTypes = 'uploadActivityTypes',
    AssignSubcontractors = 'subcontractors',
    ActivateSchedule = 'activeSchedule'
}

export enum RouteKeysEnum {
    Dashboard = '/dashboard',
    Models = '/models',
    MasterSchedule = '/master-schedule',
    ManPower = '/manpower',
    MakeReady = '/make-ready',
    Planner = '/planner',
    Network = '/network',
    Scenarios = '/scenarios',
    Lookahead = '/schedule',
    Sprints = '/sprints',
    Settings = '/settings',
    Criteria = '/criteria',
    Reports = '/reports'
}

@Injectable()
export class ProjectPageCriteriaService {
    constructor() {/*--EMPTY--*/}

    criteriaData: IProjectSchedCriteriaData;
    criteriaCheckList: IProjectSchedCriteriaList[] = [
        {
            label: 'create_master_schedule',
            key: CriteriaKeyEnum.UploadActivityTypes,
            completed: false,
            link: [],
            linkAction: 'create',
            permission: false,
            // tslint:disable-next-line:max-line-length
            routeKeys: [RouteKeysEnum.Dashboard, RouteKeysEnum.ManPower, RouteKeysEnum.MakeReady, RouteKeysEnum.Planner, RouteKeysEnum.Network, RouteKeysEnum.Scenarios, RouteKeysEnum.Lookahead, RouteKeysEnum.Sprints]
        }
    ];
    pageCriteriaPassed: boolean = false;
    criteriaPageTitles = {
        [RouteKeysEnum.Dashboard]: 'criteria_dashboard',
        [RouteKeysEnum.Models]: 'criteria_models',
        [RouteKeysEnum.MakeReady]: 'criteria_make_ready',
        [RouteKeysEnum.Planner]: 'criteria_last_planner',
        [RouteKeysEnum.Network]: 'criteria_network_map',
        [RouteKeysEnum.Scenarios]: 'criteria_scenarios',
        [RouteKeysEnum.Lookahead]: 'criteria_lookahead',
        [RouteKeysEnum.Sprints]: 'criteria_sprints',
        [RouteKeysEnum.Reports]: 'criteria_reports',
        [RouteKeysEnum.Criteria]: ' '
    };
    errorGettingCriteria: boolean = false;

    getRouteKeyFromUrl(url: string): RouteKeysEnum {
        switch (true) {
            case url.includes(RouteKeysEnum.Dashboard) : return RouteKeysEnum.Dashboard;
            case url.includes(RouteKeysEnum.Models) : return RouteKeysEnum.Models;
            case url.includes(RouteKeysEnum.MakeReady) : return RouteKeysEnum.MakeReady;
            case url.includes(RouteKeysEnum.Planner) : return RouteKeysEnum.Planner;
            case url.includes(RouteKeysEnum.Network) : return RouteKeysEnum.Network;
            case url.includes(RouteKeysEnum.Scenarios) : return RouteKeysEnum.Scenarios;
            case url.includes(RouteKeysEnum.Lookahead) : return RouteKeysEnum.Lookahead;
            case url.includes(RouteKeysEnum.Sprints) : return RouteKeysEnum.Sprints;
            case url.includes(RouteKeysEnum.Reports) : return RouteKeysEnum.Reports;
            case url.includes(RouteKeysEnum.Criteria) : return RouteKeysEnum.Criteria;
            default : return null;
        }
    }
    getCriteriaPageAction(routeKey: RouteKeysEnum): string {
        return this.criteriaPageTitles[routeKey] ? this.criteriaPageTitles[routeKey] : 'use this page';
    }

    getCriteriaList(criteriaKeys: CriteriaKeyEnum[], projectId?: string, status?: any, permission?: IUserPermission): IProjectSchedCriteriaList[] {
        const filteredCriteria = this.criteriaCheckList.filter(item => criteriaKeys.includes(item.key));
        filteredCriteria.forEach(item => {
            item.permission = permission ? (permission.gc && permission.admin) : false;
            switch (item.key) {
                case CriteriaKeyEnum.UploadModels :
                    item.completed = status.hasModels ? status.hasModels === 1 : false;
                    item.link = projectId ? ['/project/' + projectId + '/settings'] : [];
                    return;

                case CriteriaKeyEnum.UploadActivityTypes :
                    item.completed = status.activitiesAdded ? status.activitiesAdded === 1 : false;
                    item.link = projectId ? ['/project/' + projectId + '/master-schedule'] : [];
                    return;

                case CriteriaKeyEnum.AssignSubcontractors :
                    item.completed = status.hasSubcontractors ? status.hasSubcontractors === 1 : false;
                    item.link = projectId ? ['/project/' + projectId + '/master-schedule'] : [];
                    return;

                case CriteriaKeyEnum.ActivateSchedule :
                    item.completed = status.scheduleActivated ? status.scheduleActivated === 1 : false;
                    item.link = projectId ? ['/project/' + projectId + '/scenarios'] : [];
                    return;
            }
        });

        return filteredCriteria;
    }

    getEntireCriteriaList(): IProjectSchedCriteriaList[] {
        return this.criteriaCheckList;
    }

    setCriteriaError(error: boolean): void {
        this.errorGettingCriteria = error;
    }
}
