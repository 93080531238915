import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.scss']
})
export class BrowserComponent implements OnInit {

  // NOTE: change variables below to inputs if we decide to do more than just chrome
  browserShow: boolean; // used to set flag to send user to download support browsers to use grit properly
  browserIcon: string = '../assets/img/chrome_icon_480px.svg';
  browserLink: string = 'https://www.google.com/chrome/';

  // tslint:disable-next-line:no-empty
  constructor() {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

}
