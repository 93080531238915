
const CHILD_COUNT = 4;

interface BoundingBoxNode {
  box: any;
  object?: any;
  children: BoundingBoxNode[];
}

function volume(b1, b2) {
  return (Math.max(b1.max.x, b2.max.x) - Math.min(b1.min.x, b2.min.x)) *
    (Math.max(b1.max.y, b2.max.y) - Math.min(b1.min.y, b2.min.y)) *
    (Math.max(b1.max.z, b2.max.z) - Math.min(b1.min.z, b2.min.z));
}

function merge(b1: number[], b2: number[]) {
  return [
    Math.min(b1[0], b2[0]),
    Math.min(b1[1], b2[1]),
    Math.min(b1[2], b2[2]),
    Math.max(b1[3], b2[3]),
    Math.max(b1[4], b2[4]),
    Math.max(b1[5], b2[5])
  ];
}

export class BoxTree {
  root: BoundingBoxNode = null;

  constructor() {}

  add(box, object) {
    const node: BoundingBoxNode = {
      box,
      object,
      children: []
    };

    let current: BoundingBoxNode = this.root;
    let parentArray: BoundingBoxNode[] = null;
    let parentIndex = -1;

    while (current != null) {
      if (!current.box.containsBox(node.box)) {
        const duo = {
          box: current.box.clone().union(node.box),
          children: [current, node]
        };
        if (parentArray == null) {
          this.root = duo;
        } else {
          parentArray[parentIndex] = duo;
        }
        return;
      }
      let found = false;
      let bestDistance = 0.0;
      let bestIndex = -1;
      for (let i = 0; i < current.children.length; i++) {
        const child = current.children[i];
        if (child.box.containsBox(node.box)) {
          parentArray = current.children;
          parentIndex = i;
          current = child;
          found = true;
          break;
        } else {
          const dist = volume(child.box, node.box) - volume(child.box, child.box);
          if (dist < bestDistance || bestIndex === -1) {
            bestDistance = dist;
            bestIndex = i;
          }
        }
      }
      if (!found) {
        if (current.children.length < CHILD_COUNT) {
          current.children.push(node);
          return;
        }

        parentArray = current.children;
        parentIndex = bestIndex;
        current = current.children[bestIndex];
      }
    }

    this.root = node;
  }

  raycast(ray) {
    if (this.root != null) {
      return this.raycastNode(this.root, ray, []).sort((a, b) => a.distance - b.distance);
    }
    return [];
  }

  remove(object) {
    if (this.root != null) {
      if (this.root.object === object) {
        this.root = null;
        return true;
      }
      return this.removeNode(object, this.root);
    }
    return false;
  }

  private removeNode(object, node: BoundingBoxNode) {
    for (let i = 0; i < node.children.length; i++) {
      if (object === node.children[i].object) {
        node.children.splice(i, 1);
        return true;
      }
      if (this.removeNode(object, node.children[i])) {
        return true;
      }
    }
    return false;
  }

  private raycastNode(node: BoundingBoxNode, ray, list) {
    if (!ray.ray.isIntersectionBox(node.box)) return list;
    if (node.object) {
      const objectHits = ray.intersectObject(node.object, true);
      objectHits.forEach(hit => {
        if (hit.distance > 0) {
          list.push(hit);
        }
      });
    }
    node.children.forEach(c => this.raycastNode(c, ray, list));
    return list;
  }
}
