import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer } from '@angular/core';
import { Observable } from 'rxjs';

import { IDropdownItem } from '../../models/dropdown/dropdown.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-static-dropdown',
  templateUrl: './static-dropdown.component.html',
  styleUrls: ['../dropdown/dropdown.component.scss']
})

export class StaticDropdownComponent implements OnInit {
  @Input() enabled: boolean;
  @Input() dropdownItems: IDropdownItem[];
  @Input() multiSelect: boolean;
  @Input() displayId: string;
  @Input() showDropdown: boolean;
  @Input() selectedItems: any[] = [];
  @Input() title: string;
  @Input() placeHolderText: string;
  @Input() containerEl: any;

  @Output() dropdownClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedItemsOutput: EventEmitter<IDropdownItem[]> = new EventEmitter();

  selectedItemsDisplay: IDropdownItem[] = [];
  unmodifiedDropdownList: IDropdownItem[]; // dropdown list to be used in displayText() without modification
  firstLoad: boolean = true;
  loading: boolean = false;
  displayText: string = '';

  constructor(private el: ElementRef, private renderer: Renderer) { }

  ngOnInit() {
    // if you pass in pre-selected items for a multiselect dropdown, set them to display
    if (this.selectedItems && this.selectedItems.length > 0 && this.multiSelect) {
      this.selectedItemsDisplay = this.setPreSelectedItemsState(this.selectedItems);
    }

    this.setDisplayText();
  }

    // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(event) {
    if (this.showDropdown) {
      if (this.firstLoad) {
        this.loading = true;
        this.loadData();
        this.firstLoad = false;
      }
    }

    this.setDisplayText();

    // TODO find a way (if possible) to make this this runs only once when dropdown opens and more smooth
    // after data loads check position and boundaries of element to render dropdown in view
    if (this.containerEl) {
      this.checkPosition();
    }
  }

  checkPosition() {
    // if dropdown is not showing dont do ignore
    if (!this.showDropdown) return;
    // TODO currently the DOM data hasn't been painted with data need to find a better way to listen for object (seperate component?)
    // DOM consistent height of dropdown is at 250ms
    const self = this;

    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      // runs only if the class exists
      if ( self.el.nativeElement.getElementsByClassName('list-dropdown-grit').length === 0) return;

      const dropdown = self.el.nativeElement.getElementsByClassName('list-dropdown-grit')[0];
      const dropRect = dropdown.getBoundingClientRect();
      const containerRect = self.el.nativeElement.getBoundingClientRect();

      const containerLimits = {
        scrollTop: self.containerEl.scrollTop,
        visibleHeight: self.containerEl.offsetHeight,
        scrollBottom: self.containerEl.scrollHeight - self.containerEl.offsetHeight - self.containerEl.scrollTop, // distance
        height: self.containerEl.scrollHeight, // entire height of scrollable container
      };

      // calculate the current position of menu
      const menuTop = window.innerHeight - dropRect.y;
      // calculate the available space below the selected menu to the bottom of scrollable container
      const availableBot = menuTop + containerLimits.scrollBottom;

      // rendering above and below
      if (availableBot > dropRect.height) {
        // render below dropdown
        self.renderer.setElementStyle(dropdown, 'top', '4px');
      } else {
        // render above dropdown
        self.renderer.setElementStyle(dropdown, 'bottom', '40px');
      }
    },         250);
  }

  setPreSelectedItemsState(selectedItems: string[]): IDropdownItem[] {
    const selectedItemsDisplay = [];
    selectedItems.filter(item => {
      const filteredDDitem = this.dropdownItems.filter(ddItem => ddItem.value === item);
      if (!Utils.isEmpty(filteredDDitem)) {
        selectedItemsDisplay.push(filteredDDitem[0]);
        this.dropdownItems = Utils.removeArrayItems(this.dropdownItems, filteredDDitem);
      }
    });

    return selectedItemsDisplay;
  }

  autoClose(event): void {
    const target = event.target;

    if (!target.closest('.list-dropdown-grit') && !target.closest('.list-dropdown-textbox-container-grit')) {
      this.toggleDropdown(false);
    }
  }

  loadData() {
    this.loading = true;
    this.dropdownItems = this.checkSelectedItems(this.dropdownItems);
    this.loading = false;
  }

  checkSelectedItems(dropdownItems: IDropdownItem[]): IDropdownItem[] {
    if (!this.multiSelect) return dropdownItems;
    const dropdownIndexes = [];
    dropdownItems.forEach(dropdownItem => {
      if (this.selectedItems.includes(dropdownItem.value)) {
        dropdownIndexes.push(dropdownItems.indexOf(dropdownItem));
        this.selectedItemsDisplay.push(dropdownItem);
      }
    });
    dropdownIndexes.reverse().forEach(index => {
      dropdownItems.splice(index, 1);
    });
    return dropdownItems;
  }

  clearSelectedItems() {
    this.selectedItems = [];
    this.selectedItemsDisplay = [];
    this.dropdownItems.map(dropdownItem => dropdownItem.selected = false);
    this.selectedItemsOutput.emit(this.selectedItems);
    this.setDisplayText();
  }

  itemDeselected(item) {
    if (this.selectedItems.includes(item)) {
      let indexOfItemToSplice = this.selectedItems.findIndex(selectedItem => selectedItem === item);
      this.selectedItems.splice(indexOfItemToSplice, 1);
      indexOfItemToSplice = this.selectedItemsDisplay.findIndex(selectedItem => selectedItem.value === item);
      this.dropdownItems.push(this.selectedItemsDisplay[indexOfItemToSplice]);
      this.dropdownItems = Utils.sortByNumber(this.dropdownItems, 'value', true);
      this.selectedItemsDisplay.splice(indexOfItemToSplice, 1);
      this.selectedItemsOutput.emit(this.selectedItems);

      this.setDisplayText();
    }
  }

  itemSelected(dropdownItem: IDropdownItem) {
    if (!this.multiSelect) {
      this.selectedItems = [dropdownItem.value];
      this.dropdownItems.map(item => {
        item.value !== dropdownItem.value ? item.selected = false : item.selected = true;
      });
      this.toggleDropdown();
    } else {
    if (!this.selectedItems.includes(dropdownItem.value)) {
      this.selectedItems.push(dropdownItem.value);
      this.selectedItemsDisplay.push(dropdownItem);
      const indexOfItemToSplice = this.dropdownItems.indexOf(dropdownItem);
      this.dropdownItems.splice(indexOfItemToSplice, 1);
    }
    }
    this.setDisplayText();
    this.selectedItemsOutput.emit(this.selectedItems);
  }

  setDisplayText() {
    // first time load from ngOnInit() selectedItemsDisplay will be empty
    if ( Utils.isEmpty(this.selectedItemsDisplay) && !Utils.isEmpty(this.selectedItems)) {
      const dropdownItems = this.dropdownItems.filter(dropdownItem => this.selectedItems.includes(dropdownItem.value));
      if (dropdownItems.length === 1) this.displayText = dropdownItems[0].label;
      else if (dropdownItems.length === 0 && !Utils.isEmpty(this.placeHolderText)) this.displayText = this.placeHolderText;
      else if (dropdownItems.length === 0 ) this.displayText = '0 selected';
      else this.displayText = dropdownItems.length + ' selected';
    } else {
      // Once ngChanges has been called selectedItemDisplay will be synced.
      if (this.selectedItemsDisplay.length === 1) this.displayText = this.selectedItemsDisplay[0].label;
      else if (this.selectedItemsDisplay.length === 0 && !Utils.isEmpty(this.placeHolderText)) this.displayText = this.placeHolderText;
      else if (this.selectedItemsDisplay.length === 0) this.displayText = '0 selected';
      else this.displayText = this.selectedItemsDisplay.length + ' selected';
    }
  }

  toggleDropdown(showDropdown?: boolean) {
    this.showDropdown = showDropdown != null ? showDropdown : !this.showDropdown;
    this.dropdownClosed.emit(this.showDropdown);
  }
}
