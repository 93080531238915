import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as FileSaver from 'file-saver';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';
import { ProjectService } from '../../../services/project/project.service';
import { ActivityService } from '../../../services/activity/activity.service';

@Component({
  selector: 'app-project-schedule-chart-controls',
  templateUrl: './project-schedule-chart-controls.component.html',
  styleUrls: ['./project-schedule-chart-controls.component.scss']
})
export class ProjectScheduleChartControlsComponent {
  @Input() controlsInput: IProjectScheduleControls;
  @Input() bulkEdit: boolean;
  @Input() animationIsPlaying: boolean = false;
  @Input() customMinWidth: number;
  @Input() fromMasterSchedule: boolean;
  @Input() projectId: string;

  @Output() resetCtrlOutput: EventEmitter<void> = new EventEmitter();
  @Output() enablebulkEdit: EventEmitter<void> = new EventEmitter();
  @Output() startBulkEdit: EventEmitter<void> = new EventEmitter();
  @Output() startRowBulkEdit: EventEmitter<void> = new EventEmitter();
  @Output() todayCtrlOutput: EventEmitter<void> = new EventEmitter();
  @Output() startCtrlOutput: EventEmitter<void> = new EventEmitter();
  @Output() endCtrlOutput: EventEmitter<void> = new EventEmitter();
  @Output() rangeCtrlOutput: EventEmitter<string> = new EventEmitter();
  @Output() dailyCtrlOutput: EventEmitter<string> = new EventEmitter();
  @Output() animationOutput: EventEmitter<string> = new EventEmitter();
  @Output() animationSpeedOutput: EventEmitter<number> = new EventEmitter();
  @Output() searchCtrlOutput: EventEmitter<string> = new EventEmitter();

  animationSpeed: number = 1;
  showActivityFilters: boolean = false;
  filterApplied: boolean = false;
  startBulkEditDisable = true;

  constructor(
    public projectService: ProjectService,
    public activityService: ActivityService
  ) {/**/}

  handleRangeCtrl(direction: string): void {
    this.rangeCtrlOutput.emit(direction);
  }

  handleDailyCtrl(direction: string): void {
    this.dailyCtrlOutput.emit(direction);
  }

  handleAnimationCtrl(action: string): void {
    if (action === 'play') {
      this.animationIsPlaying = true;
    } else if (action === 'stop') {
      this.animationIsPlaying = false;
    }
    this.animationOutput.emit(action);
  }

  handleAnimationSpeedClick(): void {
    if (this.animationSpeed === 4) this.animationSpeed = 2;
    else if (this.animationSpeed === 2) this.animationSpeed = 1;
    else if (this.animationSpeed === 1) this.animationSpeed = .5;
    else if (this.animationSpeed === .5) this.animationSpeed = .25;
    else if (this.animationSpeed === .25) this.animationSpeed = 4;
    else this.animationSpeed = 1;
    this.animationSpeedOutput.emit(this.animationSpeed);
  }

  getAnimationSpeedLabel(speed: number): string {
    if (speed === 4) return '.25x';
    else if (speed === 2) return '.5x';
    else if (speed === 1) return '1x';
    else if (speed === .5) return '2x';
    else if (speed === .25) return '4x';
    else return '1x';
  }

  toggleActivitiesFilters(): void {
    this.showActivityFilters = !this.showActivityFilters;
    this.filterApplied = true;
  }

  applySearch(query?: string): void {
    console.log("applySearch");
    console.log(query);
    this.filterApplied = true;
    this.searchCtrlOutput.emit(query);
  }

  async downloadPdf(pageLayout) {
    const data: any = document.getElementsByClassName('gantt-container')[0];
    data.scrollTo(0, 0);
    data.style.overflow = 'inherit';
    await setTimeout( () => { this.adjustHeaderAndDownload(data, pageLayout); }, 100);
  }

  async adjustHeaderAndDownload(data, pageLayout) {
    const projectName = this.projectService.currentProject.name;
    const startHTMLString = `<!DOCTYPE html>
      <html lang="en">
      <head>
      <style>
          @page { size: ${pageLayout} landscape; }
          @media screen, print {
            text{
              fill: #a4a4b2;
              text-transform: uppercase;
              font-family: "Roboto", sans-serif;
              font-weight: 500;
              font-size: 11px;
              cursor: pointer;
            }
          }
        </style>
      </head>
      <body>
      <h5>
        <center>
         ${projectName} - Master Schedule
        </center>
      </h5>`;
    const endHTMLString = `</body>
      </html>`;

    const completeHTML =  startHTMLString + data.innerHTML + endHTMLString;
    const voiceBlob = URL.createObjectURL(
      new Blob([this.cleanstring(completeHTML)], { type: 'text/html' })
    );

    const myWindow = window.open(voiceBlob);
    setTimeout(() => { myWindow.print(); }, 10);
    myWindow.onfocus = () => { setTimeout(() => { myWindow.close(); }, 10); };
    data.style.overflow = 'auto';
    return;
  }

  cleanstring(dirty) {
    const smartchr = [ '’', '‘', '“', '”', '–', '—', '…', '„', '‚' , '«', '»', '‹', '›'];
    const correctchr = ["'", "'", '"', '"', '-', '-', '...', '"', "'", '"', '"', "'", "'"];

    let thestring = dirty;
    let regex;
    for (let i = 0; i < smartchr.length; i++) {
      regex = new RegExp(smartchr[i], 'g');
      thestring = thestring.replace(regex, correctchr[i]);
    }

    return thestring;
  }

  enableBulkEditOption(){
    this.bulkEdit = !this.bulkEdit;

    if(this.bulkEdit){
      this.startBulkEditDisable = true;
    }

    this.enablebulkEdit.emit()
  }

}
