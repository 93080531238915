
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Utils } from '../../utils/utils';

import { ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import { LoginStatusService } from '../login/login-status.service';
import { SegmentService } from '../../services/segment/segment.service';

interface BatchRequest {
  path?: string;
  method?: string;
  query?: any;
  post?: any;
}

@Injectable()
export class HttpBackendService {

  private batch: BatchRequest[] = [];
  private batchObservable: Array<Subject<any>> = [];
  private batchTimeout: any = null;

  constructor(private _http: HttpClient,
              private router: Router,
              private segmentService: SegmentService
  ) { }

  public put(path: string, json: any): Observable<any> {
    return this.doRequest({
      path,
      method: 'PUT',
      post: json
    });
  }

  public post(path: string, json: any): Observable<any> {
    return this.doRequest({
      path,
      method: 'POST',
      post: json
    });
  }

  public formData(path: string, json: FormData): Observable<any> {
    const headers = this.getHeaders(false);
    headers['reportProgress'] = true;

    const url = environment.apiServer() + path;
    const req = new HttpRequest('POST', url, json, headers);

    // on success, entire file can be returned...so it does not go through 'handled success'
    // but a failure still needs to be handled in the same manner
    return this._http.request(req).pipe(
      map(success => success),
      catchError(error => this.handleError(error)));
  }

  public paged(path: string): Observable<any> {
    return this.doRequest({
      path,
      method: 'GET'
    }, true);
  }

  public get(path: string): Observable<any> {
    return this.doRequest({
      path,
      method: 'GET'
    });
  }

  public getFile(path: string): Observable<any> {
    const options = this.getHeaders(false);
    options.responseType = 'blob';
    const url = environment.apiServer() + path;
    return this._http
      .get(url, options).pipe(
      map(res => {
        const urlCreator = window.URL;
        return urlCreator.createObjectURL(res);
      }));
  }

  public getBlob(path: string): Observable<any> {
    const options = this.getHeaders(false);
    options.headers = options.headers.set('X-Blob', 'true');
    options.responseType = 'blob';
    const url = environment.apiServer() + path;
    return this._http
      .get(url, options).pipe(
      map(res => {
        return res;
      }));
  }

  public postBlob(path: string, body): Observable<any> {
    const options = this.getHeaders(false);
    options.headers = options.headers.set('X-Blob', 'true');
    options.responseType = 'blob';
    const url = environment.apiServer() + path;
    return this._http
      .post(url, body, options).pipe(
      map(res => {
        return res;
      }));
  }

  public delete(path: string): Observable<any> {
    return this.doRequest({
      path,
      method: 'DELETE'
    });
  }

  public getCsrfToken() {
    return localStorage.getItem('csrfToken');
  }

  public getApiServer() {
    return environment.apiServer();
  }

  private doRequest(req: BatchRequest, paged?: boolean, immediate?: boolean) {
    const obs = new Subject<any>();
    const headers = this.getHeaders(!!req.post);
    const url = environment.apiServer() + req.path;

    this._http[req.method.toLowerCase()](url, req.post ? JSON.stringify(req.post) : headers, req.post ? headers : undefined).subscribe((result) => {
      obs.next(result);
      obs.complete();
    }, (err) => {
      obs.error(err);
      obs.complete();
    });

    return obs.asObservable().pipe(
      map(success => (paged ? this.handlePagedSuccess(success) : this.handleSuccess(success))),
      catchError(error => this.handleError(error))
    );
  }

  private getHeaders(post: boolean): any {
    let headers = new HttpHeaders();
    if (post) {
      headers = headers.set('Content-Type', 'application/json');
    }
    const csrf = this.getCsrfToken();
    if (csrf) {
      headers = headers.set('X-CSRF-TOKEN', csrf);
    }
    return {headers, withCredentials: true};
  }

  private handlePagedSuccess(res) {
    return Utils.handlePagedSuccess(res);
  }

  private handleSuccess(res) {
    return Utils.handleSuccess(res);
  }

  private handleError(res) {
    if (res.status === 401 && res.url.indexOf('/authenticatedUser') === -1) {
      localStorage.setItem('loginReturn', this.router.url);
      this.router.navigateByUrl('/login');
    }

    // Logging server errors and general failures
    // These are errors that indicate server and/or database issues
    if (res.status === 500 || res.error.message === 'FAILED') {
      this.segmentService.track('ERROR', {error: 'HTTP_CALL: ' + res});
    }

    return Utils.handleError(res);
  }
}
