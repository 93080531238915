
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectActivityTypesService } from '../../services/project/project-activity-types/project-activity-types.service';
import { ProjectFilterSearchService } from '../../services/project/project-filter-search/project-filter-search.service';
import { ProjectModelService } from '../../services/project/project-model/project-model.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectPageCriteriaService } from '../../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { ICustomTable, IRow, ITableSearchOptions } from '../../models/custom-table/custom-table.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../utils/enums/forge-viewer-type';
import { MessageType } from '../../utils/enums/message-type.enum'; // USED IN HTML
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectModel } from '../../models/project/project-model/project-model.interface';
import { IProjectObjectSidebarTabs } from '../../models/project/project-object/project-object.interface';
import { IUserPermission } from '../../models/user/user.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-models-component',
  templateUrl: './project-models.component.html',
  styleUrls: ['./project-models.component.scss']
})
export class ProjectModelsComponent implements OnInit, OnDestroy {
  // display toggles
  pageIsLoading: boolean = true;
  dataIsAvailable: boolean = true;
  viewerPoppedOut: boolean;

  // project info
  projectId: string;
  projectPermission: IUserPermission;
  currentProjectObjects: any;
  errorGettingData: boolean = false;

  // forge viewer
  firstLoad: boolean = true;
  myViewerState = {};
  hoveredProjectObjectId: string;

  // table
  tableIsLoading: boolean = false;
  visibleTableData: ICustomTable; // data transformed for table only rows in view
  filteredData: any; // total results of filter (not just items in view)
  selectedProjectObjectIds = {};
  activeSearchQuery: string;
  paginationStart: number = 0;
  allRowsSelected: boolean = false;
  searching = false;
  clearingSearch = false;
  curSelectedRowIndex: number;

  // filters
  activeFilterType: FilterModelOptions;
  activeFilters: string[] = [];
  lastCatFilter: string;
  filtersApplied: boolean = false;

  // Models dropdown
  modelsDropdownItems: IDropdownItem[] = [];
  activeProjectModelId: string;
  showModelsDropdown: boolean = false;

  // sidebar
  sidebarTabIconData: IProjectObjectSidebarTabs[];
  sidebarState: string = 'filter';
  activeActivityFilters: string[] = [];
  filterTypesToHide: string[] = [FilterModelOptions.SubContractor];
  selectedObjectIdsArray = []; // Object properties input
  messageType = MessageType;

  loadedModelId: string;
  loadedObjectId: string;
  loadedMessage: boolean;
  quickLinkSelectReady: boolean;

  // Subscription takeUntil
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // Error handling
  errorContext: INoficationContext = {
    type: 'object data',
    action: 'set'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activityTypeService: ProjectActivityTypesService,
    private projectObjectService: ProjectObjectService,
    private notificationService: NotificationService,
    private projectModelService: ProjectModelService,
    private segmentService: SegmentService,
    private forgeViewerService: ForgeViewerService,
    private projectService: ProjectService,
    private projectFilterSearchService: ProjectFilterSearchService,
    public projectPageCriteriaService: ProjectPageCriteriaService
  ) { }

  async ngOnInit() {
    this.forgeViewerService.onCategoryFilterEnabledPage = true;
    this.projectId = this.projectService.currentProject.id;

    this.pageIsLoading = true;
    this.projectPermission = ProjectService.userPermission;
    this.activeFilterType = FilterModelOptions.Activities;

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(async myParams => {
      this.loadedModelId = myParams['modelId'];
      this.loadedMessage = myParams['message'];
      this.loadedObjectId = myParams['objectId'];
      if (this.loadedObjectId) {
        const object = await this.projectObjectService.getObject(this.loadedObjectId).toPromise();
        if (object) {
          this.loadedModelId = object.projectModelId;
          if (this.quickLinkSelectReady) {
            await this.handleQuickLink();
          }
        }
      }
    });

    if (this.projectService.getProjectReady()) {
      this.firstLoad = false;
      this.setupPageData();
    }

    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.viewerPoppedOut = this.forgeViewerService.poppedOut;

      if (this.forgeViewerService.poppedOut || !this.firstLoad) {
        // Viewer was just popped out and ready to be set to correct state
        await this.setUpTableAndViewer();
        this.highlightSelectedObjectsInViewer();
        this.forgeViewerService.fitModelToView([this.activeProjectModelId]);
      } else {
        this.firstLoad = false;
        this.setupPageData();
      }
    });

    // Viewer output
    this.forgeViewerService.forgeViewerOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      this.handleForgeViewerOutput(gritSelection);
    });

    // Viewer show similar output
    this.forgeViewerService.showSimilarOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      const idForCat = gritSelection[0];
      if (!Utils.isEmpty(idForCat)) {
        this.filterByLastCat(idForCat);
      }
    });

    // Viewer clear filters output
    this.forgeViewerService.clearFiltersOutput.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.filterModelClick([]);
    });

    this.forgeViewerService.viewerCutOutput.pipe(takeUntil(this.destroyed$)).subscribe(async output => {
      this.setupPageData();
    });

    this.segmentService.track('Project Models Loaded', { projectId: this.projectId });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  // Initialize Methods
  async setupPageData() {
    this.forgeViewerService.clearAllThemingColors();
    this.forgeViewerService.clearCutPlanes();
    await this.setupModelsDropdown();

    if (!Utils.isEmpty(this.activeProjectModelId)) {
      this.currentProjectObjects = this.projectObjectService.getModelObjects(this.activeProjectModelId);
      await this.showActiveModel();

      this.setupSideBar();

      // Set table
      await this.setupTableData();
      this.quickLinkSelectReady = true;
      if (this.loadedModelId || this.loadedObjectId) {
        await this.handleQuickLink();
      }
      this.setVisibleTableData();
      this.dataIsAvailable = true;
      this.pageIsLoading = false;
    } else {
      this.dataIsAvailable = false;
      this.pageIsLoading = false;
    }
  }

  async setupModelsDropdown() {
    let projectModels = this.projectModelService.projectModels;
    projectModels = projectModels.filter(model => model.status === 2);
    if (!Utils.isEmptyList(projectModels)) {
      Utils.sortByString(projectModels, 'name');
      this.activeProjectModelId = projectModels[0].id;
      this.modelsDropdownItems = this.getModelsDropdownList(projectModels);
    } else {
      // No data, and flag needs to be set as such, hence forth, cheerio.
      this.dataIsAvailable = false;
      this.pageIsLoading = false;
    }
  }

  getModelsDropdownList(projectModels: IProjectModel[]): IDropdownItem[] {
    const projectModelsDropdownItems: IDropdownItem[] = [];
    projectModels.forEach(projectModel => {
      projectModelsDropdownItems.push({ value: projectModel.id, label: projectModel.name });
    });
    return projectModelsDropdownItems;
  }
  // END Initialization

  // Object Selection
  handleForgeViewerOutput(gritViewerOutputData: any): void {
    if (gritViewerOutputData !== 'esc') {
      if (!Utils.isEmpty(gritViewerOutputData)) {
        gritViewerOutputData.forEach(selection => {
          if (gritViewerOutputData.length > 1) {
            this.addSelectedObject(selection);
          } else {
            this.addOrRemoveSelectedObject(selection);
          }
        });
        // Update table active rows
        const filterDataKeys = Object.keys(this.filteredData);
        this.curSelectedRowIndex = filterDataKeys.findIndex(key => key === gritViewerOutputData[0]);

        if (this.curSelectedRowIndex >= this.paginationStart + 20 || this.curSelectedRowIndex < this.paginationStart) {
          this.applyPagination(Math.floor(this.curSelectedRowIndex / 20) * 20);
        } else {
          this.setVisibleTableData();
        }
      } else {
        this.resetRowSelections();
        this.setVisibleTableData();
      }
    } else {
      this.setViewerToFilterData();
    }
  }

  applySingleRowClick(rows: IRow[]): void {
    let curId;
    // tslint:disable-next-line:prefer-for-of
    for (let r = 0; r < rows.length; r++) {
      curId = rows[r].key;
      this.addOrRemoveSelectedObject(curId);
    }
  }

  applyAllRowsSelection(rows: IRow[]): void {
    this.allRowsSelected = Utils.isEmptyList(rows) ? false : true;

    if (this.allRowsSelected) {
      this.selectAllFilteredData();

      this.selectedObjectIdsArray = Object.keys(this.projectObjectService.getAllObjectIds());
    } else {
      this.resetRowSelections();

      this.selectedObjectIdsArray = [];
    }
    this.setVisibleTableData();
  }
  // END Object selection

  // Object updates
  hideObjectRows() {
    if (Utils.isEmpty(this.selectedProjectObjectIds)) return;

    const hideObjects = this.allRowsSelected ? Object.keys(this.filteredData) : Object.keys(this.selectedProjectObjectIds);

    this.projectObjectService.hide(this.activeProjectModelId, hideObjects).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        res.objectIds.forEach(objectId => {
          delete this.myViewerState[objectId];
        });
        this.forgeViewerService.setViewerState(this.myViewerState);
        this.currentProjectObjects = this.projectObjectService.getModelObjects(this.activeProjectModelId);
        this.resetRowSelections();
        await this.setupTableData();
        this.setVisibleTableData();
      },
      err => {
        this.errorContext.type = 'object';
        this.errorContext.action = 'hide';
        this.notificationService.error(err, this.errorContext);
      });
  }
  // END Object updates

  // Resets
  resetRowSelections(): void {
    this.forgeViewerService.clearThemingColors(this.activeProjectModelId);
    this.selectedProjectObjectIds = {};
    this.selectedObjectIdsArray = [];
  }

  resetTableVariables(): void {
    this.activeSearchQuery = '';
    this.activeFilters = [];
    this.filtersApplied = false;
    this.resetRowSelections();
  }

  async setViewerToFilterData() {
    this.resetRowSelections();
    this.setVisibleTableData();
  }
  // END Resets

  // Helpers
  async selectModel() {
    // Set view
    this.currentProjectObjects = this.projectObjectService.getModelObjects(this.activeProjectModelId);
    await this.showActiveModel();

    // Reset table
    this.resetTableVariables();
    this.resetRowSelections();
    await this.setupTableData();
    this.setVisibleTableData();
  }

  async showActiveModel() {
    this.myViewerState = Utils.copyKeysFromObject(this.currentProjectObjects);
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitModelToView([this.activeProjectModelId]);
  }

  highlightSelectedObjectsInViewer(): void {
    this.forgeViewerService.clearThemingColors(this.activeProjectModelId);
    Object.keys(this.selectedProjectObjectIds).forEach((key) => {
      this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Select });
    });
  }

  addSelectedObject(curId: string): void {
    if (!this.selectedProjectObjectIds[curId]) {
      this.forgeViewerService.setState({ gritObjectId: curId, type: ForgeViewerType.Select });
      this.selectedProjectObjectIds[curId] = curId;
    }

    this.selectedObjectIdsArray = Object.keys(this.selectedProjectObjectIds);
    if (this.selectedObjectIdsArray.length === Object.keys(this.filteredData).length) {
      this.allRowsSelected = true;
    } else {
      this.allRowsSelected = false;
    }

    this.setVisibleTableData();
  }

  addOrRemoveSelectedObject(curId: string): void {
    // Set viewer
    if (!Utils.isEmpty(this.selectedProjectObjectIds[curId])) {
      this.forgeViewerService.setState({ gritObjectId: curId, type: ForgeViewerType.Default });
      delete this.selectedProjectObjectIds[curId];
    } else {
      this.forgeViewerService.setState({ gritObjectId: curId, type: ForgeViewerType.Select });
      this.selectedProjectObjectIds[curId] = curId;
    }

    this.selectedObjectIdsArray = Object.keys(this.selectedProjectObjectIds);
    if (this.selectedObjectIdsArray.length === Object.keys(this.filteredData).length) {
      this.allRowsSelected = true;
    } else {
      this.allRowsSelected = false;
    }

    this.setVisibleTableData();
  }

  handleRowHover(row: IRow) {
    if (Utils.isEmpty(this.hoveredProjectObjectId)) {
      if (Utils.isEmpty(row)) {
        this.hoveredProjectObjectId = null;
        return;
      }
      this.forgeViewerService.setState({ gritObjectId: row.key, type: ForgeViewerType.Hover });
      this.hoveredProjectObjectId = row.key;
    } else {
      if (Utils.isEmpty(this.selectedProjectObjectIds[this.hoveredProjectObjectId])) {
        this.forgeViewerService.setState({ gritObjectId: this.hoveredProjectObjectId, type: ForgeViewerType.Default });
      } else {
        this.forgeViewerService.setState({ gritObjectId: this.hoveredProjectObjectId, type: ForgeViewerType.Select });
      }
      this.hoveredProjectObjectId = null;
    }
  }
  // END Helpers

  // Table and Viewer set <== Set in same area for efficiencies and less looping
  async setUpTableAndViewer() {
    await this.setupTableData();

    this.myViewerState = Utils.copyKeysFromObject(this.filteredData);
    await this.forgeViewerService.setViewerState(this.myViewerState);

    this.searching = false;
    this.clearingSearch = false;

    this.setVisibleTableData();
  }
  // END Table and Viewer set

  // Table methods
  async setupTableData() {
    this.filteredData = await this.projectFilterSearchService.getFilterDataByModel(this.activeFilters, this.activeFilterType, this.activeProjectModelId);
    if (this.filteredData === -1) this.filteredData = this.currentProjectObjects;

    const searchedData = this.projectFilterSearchService.getSearchData(this.activeSearchQuery, this.filteredData);
    if (searchedData !== -1) this.filteredData = searchedData;

    if (Utils.isEmpty(this.filteredData)) {
      if (Utils.isEmptyList(this.activeFilters) && Utils.isEmpty(this.activeSearchQuery)) {
        this.filteredData = this.currentProjectObjects;
      }
    }
  }

  setVisibleTableData(): void {
    // Custom table component input data
    this.visibleTableData = this.activityTypeService.transformObjectTableData(
      this.filteredData,
      this.selectedProjectObjectIds,
      this.activeSearchQuery,
      this.paginationStart
    );
  }

  applyPagination(paginationStart: number): void {
    this.paginationStart = paginationStart;
    this.setVisibleTableData();
  }

  applySearchClick(searchOption: ITableSearchOptions): void {
    this.activeSearchQuery = Utils.isEmpty(searchOption) ? '' : searchOption.searchQuery.toLowerCase();
    this.paginationStart = 0; // go back to first page on new search

    this.resetRowSelections();
    if (this.activeSearchQuery === '') {
      this.clearingSearch = true;
    } else {
      this.searching = true;
    }
    this.setUpTableAndViewer();
  }

  async filterByLastCat(objectId: string) {
    const objCatNames = this.projectObjectService.getLocalObject(objectId).categories;
    if (Utils.isEmptyList(objCatNames)) return;
    const lastCatName = objCatNames[objCatNames.length - 1].toString();
    const allCatsMap = this.projectService.getLocalCategories();
    const catIds = [];
    for (const key in allCatsMap) {
      if (key) {
        if (allCatsMap[key].name === lastCatName) {
          catIds.push(key);
        }
      }
    }
    if (catIds.length > 0) {
      if (this.activeFilterType === FilterModelOptions.Category) {
        this.activeFilters = catIds;
        this.filterModelClick(this.activeFilters);
      } else {
        await this.switchFilterType(FilterModelOptions.Category);
        this.activeFilters = catIds;
        this.filterModelClick(this.activeFilters);
      }
    }
  }
  // END Table methods

  selectAllFilteredData(): void {
    Object.keys(this.filteredData).forEach((key) => {
      this.selectedProjectObjectIds[key] = key;
      this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Select });
    });
  }

  // Sidebar
  async setupSideBar() {
    this.sidebarTabIconData = this.activityTypeService.getSidebarTabData();
    this.forgeViewerService.resizeViewerAfterLoad();
  }

  handleSidebarIconClick(tab: IProjectObjectSidebarTabs): void {
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;

    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.sidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab: IProjectObjectSidebarTabs): void {
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;
    this.sidebarState = tab.key;
  }

  async filterModelClick(filters: string[]) {
    this.filtersApplied = Utils.isEmptyList(filters) ? false : true;
    this.activeFilters = filters;
    this.paginationStart = 0;
    this.resetRowSelections();
    this.projectService.setFilteringModel(true);
    this.forgeViewerService.setDisablePage(true);
    this.setUpTableAndViewer();
  }

  async switchFilterType(type: FilterModelOptions) {
    this.lastCatFilter = '';
    this.activeFilterType = type;
    this.activeFilters = [];
    await this.selectModel();
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) {
      this.segmentService.track('Models Sidebar Loaded', { sideBar: 'properties' });
      this.projectService.toggleSidebarVisibility(isVisible);
    } else {
      this.projectService.toggleSidebarVisibility();
    }
    // SetTimeout is used here so the viewer is resized after the CSS animation
    // to resize to correct width
    setTimeout(() => { this.forgeViewerService.resizeViewerAfterLoad(); }, 500);
  }
  // END Sidebar

  // No models
  navigateToFileManager(): void {
    this.router.navigateByUrl('/project/' + this.projectId + '/settings?menuItem=3');
  }
  // END No models

  pageRefresh(): void {
    window.location.reload(true);
  }

  // Quick link
  async handleQuickLink() {
    if (this.loadedMessage) {
      const sidebarMessagesTab = this.sidebarTabIconData.find(tab => tab.key === 'messages');
      if (!sidebarMessagesTab.active) {
        this.handleSidebarIconClick(sidebarMessagesTab);
      }
    }
    if (!Utils.isEmpty(this.projectModelService.projectModels.find(model => model.id === this.loadedModelId))) {
      this.activeProjectModelId = this.loadedModelId;
      await this.selectModel();
      if (this.loadedObjectId) {
        this.addSelectedObject(this.loadedObjectId);
        const filterDataKeys = Object.keys(this.filteredData);
        this.curSelectedRowIndex = filterDataKeys.findIndex(key => key === this.loadedObjectId);

        if (this.curSelectedRowIndex >= this.paginationStart + 20 || this.curSelectedRowIndex < this.paginationStart) {
          this.applyPagination(Math.floor(this.curSelectedRowIndex / 20) * 20);
        } else {
          this.setVisibleTableData();
        }
      }
    }
  }
}
