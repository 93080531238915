import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate(): boolean | Subject<boolean>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate): boolean | Subject<boolean> {
        return (component && component.canDeactivate) ? component.canDeactivate() : true;
    }
}
