import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from "../../utils/utils";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit {

  @Input() showSelectionModal;
  @Input() selectionData;

  @Output() selectionOutput: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModalOutput: EventEmitter<any> = new EventEmitter<any>();

  message: string = 'Pick a plan';
  showPlanDrowdown: boolean = false;
  scheduleName: string;
  planId: string;
  selectedItem;

  constructor(private _datePipe: DatePipe) { }

  ngOnInit() {
    this.selectedItem = this.selectionData.find(item => item.selected === true);
    if (!Utils.isEmpty(this.selectedItem)) {
      this.planId = this.selectedItem.value;
      this.scheduleName = this._datePipe.transform(Date.now(), 'medium');
    }
  }

  select(item: any): void {
    this.selectionOutput.emit(item);
  }

  closeSelectionModal() {
    this.closeModalOutput.emit();
  }

  createSchedule() {
    this.selectionOutput.emit({name:this.scheduleName, planId: this.planId});
  }

  setSelectedPlan(planId): void {
    this.planId = planId;
    if(Utils.isEmpty(this.scheduleName)) this.scheduleName = this._datePipe.transform(Date.now(), 'medium');
  }
}
