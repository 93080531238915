import { Injectable } from '@angular/core';

import { Utils } from '../../../utils/utils';

import { ChartService } from '../../chart/chart.service';
import { HttpBackendService } from '../../http-backend/http-backend.service';
import { ProjectSprintService } from '../project-sprint/project-sprint.service';
import { TranslationService } from '../../../services/translation/translation.service';

import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { IProgressBar } from '../../../models/progress-bar/progress-bar.interface';
import { IDashboardGrid, IDashboardGridRow } from '../../../models/project/project-dashboard/project-dashboard.interface';
import { IProjectSprint } from '../../../models/project/project-sprint/project-sprint.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISidebarFilterListItem, ISidebarTab } from '../../../models/sidebar/sidebar.interface';
import { IUserPermission } from '../../../models/user/user.interface';

import * as moment from 'moment';
import { SidebarState } from '../../../utils/enums/sidebar-state';

@Injectable()
export class ProjectDashboardService {

  projectPermission: IUserPermission;

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  sidebarTabs: ISidebarTab[] = [
    {
      name: 'filter',
      icon: 'fa-filter',
      key: SidebarState.SUBCONTRACTOR_LIST,
      active: true
    }
  ];

  constructor(
    private _chartService: ChartService,
    private _http: HttpBackendService,
    private _projectSprintService: ProjectSprintService
  ) { }

  public getProjectCompletionTotals(projectId: string): any {
    return this._http.get('/project/' + projectId + '/projectCompletionTotals');
  }

  public getSprintCompletionTotalsBySub(projectId: string, sprintId: string): any {
    return this._http.get('/project/' + projectId + '/sprintCompletionTotalsBySub/' + sprintId);
  }

  public getAllSprintsCompletionTotals(projectId: string): any {
    return this._http.get('/project/' + projectId + '/allSprintsCompletionTotals');
  }

  public getCompletedSprintsCompletionTotals(projectId: string): any {
    return this._http.get('/project/' + projectId + '/completedSprintsCompletionTotals');
  }

  public getLivingScheduleMilestonesData(projectId: string): any {
    return this._http.get('/project/' + projectId + '/livingScheduleMilestonesData');
  }

  public getProjectFeed(projectId: string): any {
    return this._http.get('/project/' + projectId + '/updates');
  }

  public getProjectHomework(projectId: string, activityIds: string[]) {
    return this._http.post('/project/' + projectId + '/homework', {activityIds: activityIds});
  }

  transformLivingScheduleChartData(milestoneData: any): IDashboardGrid {
    const gridData: IDashboardGrid = {
      colHeads: [
        {
          label: 'milestone',
          sortKey: 'name',
          active: true,
          ascending: false,
          type: 'string'
        },
        {
          label: 'projected_completion_date',
          sortKey: 'date',
          active: false,
          ascending: true,
          type: 'string'
        },
        {
          label: 'savings',
          sortKey: 'savings',
          active: false,
          ascending: false,
          type: 'string'
        }
      ],
      rows: []
    };
    milestoneData.forEach(milestone => {
      const completionSavings = this.calculateCompletionSavings(milestone.activeEndDate, milestone.livingEndDate);
      const row = {
        key: milestone.milestoneId,
        items: [
          {
            type: 'string',
            label: milestone.name,
            key: milestone.name
          },
          {
            type: 'string',
            label: Utils.formatDate(milestone.livingEndDate),
            key: milestone.livingEndDate
          },
          {
            type: 'string',
            label: completionSavings < 0 ? '-' : '+' + completionSavings + ' ' + TranslationService.translate('days'),
            key: completionSavings
          }
        ]
      };
      gridData.rows.push(row);
    });
    return gridData;
  }

  calculateCompletionSavings(activeEndDate: number, livingEndDate: number): number {
    return Math.floor((livingEndDate - activeEndDate) / 1000 / 60 / 60 / 24);
  }

  transformSubsToFilterList(subcontractors: IProjectSubContractor[]): ISidebarFilterListItem[] {
    const transformedList = [];
    subcontractors.forEach(sub => {
      if (sub.name && sub.id && sub.hexCode) {
        const subObj = {
          label: sub.name,
          key: sub.id,
          active: false,
          color: sub.hexCode
        };

        transformedList.push(subObj);
      }
    });

    return transformedList;
  }

  transformTotalsForGrid(subcontractors: IProjectSubContractor[], subTotals: any[], sprintId?: string): IDashboardGridRow[] {
    const transformedSubTotals = [];
    if (!Utils.isEmpty(subTotals)) {
      subTotals.forEach((subInfo, index) => {
        const sub = subcontractors.filter(subT => subInfo.subContractorId === subT.id)[0];
        if (sub && subInfo) {
          const gridRow = {
            key: sub.id,
            items: []
          };
          const subName = sub.name ? sub.name : null;
          const actHrs = subInfo.completedHrs && subInfo.totalHrs ? subInfo.completedHrs / subInfo.totalHrs : 0;
          const actHrsLabel = (actHrs * 100).toFixed(0) + '%';
          const manHrs = subInfo.completedManPowerHrs && subInfo.totalManPowerHrs ? subInfo.completedManPowerHrs / subInfo.totalManPowerHrs : 0;
          const manHrsLabel = (manHrs * 100).toFixed(0) + '%';

          let committedCount;
          let inProgressCount;
          let completedCount;
          let notCompletedCount;
          if (sprintId) {
            committedCount = subInfo.committed ? subInfo.committed : 0;
            inProgressCount = subInfo.inProgress ? subInfo.inProgress : 0;
            completedCount = subInfo.completed ? subInfo.completed : 0;
            notCompletedCount = subInfo.notCompleted ? subInfo.notCompleted : 0;
          }

          if (subName) gridRow.items.push({label: subName, value: subName, key: 'subcontractor', type: 'string', hasColorBadge: true, color: sub.hexCode});
          if (committedCount !== undefined) gridRow.items.push({label: JSON.stringify(committedCount), value: JSON.stringify(committedCount), subName, key: 'committed', type: 'string'});
          if (inProgressCount !== undefined) gridRow.items.push({label: JSON.stringify(inProgressCount), value: JSON.stringify(inProgressCount), key: 'inProgress', type: 'string'});
          if (completedCount !== undefined) gridRow.items.push({label: JSON.stringify(completedCount), value: JSON.stringify(completedCount), key: 'completed', type: 'string'});
          if (notCompletedCount !== undefined) gridRow.items.push({label: JSON.stringify(notCompletedCount), value: JSON.stringify(notCompletedCount), key: 'notCompleted', type: 'string'});
          if (actHrsLabel) gridRow.items.push({label: {fill: actHrsLabel, fillColor: sub.hexCode}, value: actHrs, key: 'actHrs', type: 'progressBar'});
          if (manHrsLabel) gridRow.items.push({label: {fill: manHrsLabel, fillColor: sub.hexCode}, value: manHrs, key: 'manHrs', type: 'progressBar'});
          transformedSubTotals.push(gridRow);
        }
      });
    }

    return transformedSubTotals;
  }

  getFilteredGrid(gridRows: IDashboardGridRow[], subIds: string[]): IDashboardGridRow[] {
    let filteredGridRows = [];
    if (!Utils.isEmpty(gridRows)) {
      filteredGridRows = Utils.isEmpty(subIds)
        ? gridRows
        : gridRows.filter(row => subIds.includes(row.key));
    }

    return filteredGridRows;
  }

  getOverallPercentCompleteTotals(subTotals: any[], subIds?: string[]): {[key: string]: IProgressBar} {
    let totalActivities = 0;
    let totalSteps = 0;
    let completedSteps = 0;
    let completedActivities = 0;
    let completedHrs = 0;
    let totalHrs = 0;
    const filteredSubs = !Utils.isEmpty(subIds) ? subTotals.filter(sub => subIds.includes(sub.subContractorId)) : subTotals;

    filteredSubs.forEach(sub => {
      if (sub.totalActivities) totalActivities += sub.totalActivities;
      if (sub.totalSteps) totalSteps += sub.totalSteps;
      if (sub.completedSteps) completedSteps += sub.completedSteps;
      if (sub.completedActivities) completedActivities += sub.completedActivities;
      if (sub.completedHrs) completedHrs += sub.completedHrs;
      if (sub.totalHrs) totalHrs += sub.totalHrs;
    });

    const fillHrs = (completedHrs / totalHrs) ? (completedHrs / totalHrs) * 100 : 0;
    const fillSteps = (completedSteps / totalSteps) ? (completedSteps / totalSteps) * 100 : 0;
    const fillActivities = (completedActivities / totalActivities) ? (completedActivities / totalActivities) * 100 : 0;

    return {
      completedHrsPercent: {
        title: 'hours_percent_complete',
        fill: (fillHrs).toFixed(2) + '%',
        label: (fillHrs).toFixed(2) + '% ' + '(' + completedHrs + '/' + totalHrs + ')'
      },
      completedActivityPercent: {
        title: 'activity_percent_complete',
        fill: (fillActivities).toFixed(2) + '%',
        label: (fillActivities).toFixed(2) + '% ' + '(' + Number((completedActivities).toFixed(2)) + '/' + totalActivities + ')'
      },
      completedStepPercent: {
        title: 'activity_percent_complete',
        fill: (fillSteps).toFixed(2) + '%',
        label: (fillSteps).toFixed(2) + '% ' + '(' + Number((completedSteps).toFixed(2)) + '/' + totalSteps + ')'
      }
    };
  }

  getFilteredSubIds(allSubIds: string[], filterBySubId: string, action: string): string[] {
    const filteredIds = allSubIds;
    if (action === 'add') {
      filteredIds.push(filterBySubId);
    }

    if (action === 'remove') {
      const idIndex = filteredIds.indexOf(filterBySubId);
      if (idIndex > -1) filteredIds.splice(idIndex, 1);
    }

    return filteredIds;
  }

  getSprintDd(sprintList: IProjectSprint[]): IDropdownItem[] {
    const dropdownItems = [];
    const activeSprintId = this._projectSprintService.getMostRecentSprintId(sprintList);

    sprintList.forEach(sprint => {
      const sprintObj = {
        value: sprint.id,
        label: sprint.name,
        selected: sprint.id === activeSprintId ? true : false,
        status: this._projectSprintService.getSprintStatus(sprint)
      };

      dropdownItems.push(sprintObj);
    });

    return dropdownItems;
  }

  transformSprintResourcesForChart(subcontractors: IProjectSubContractor[], sprintId: string, sprintData: any): any {
    const sprintStartDate = sprintData[0].date;
    const lastSprintDate = sprintData[sprintData.length - 1].date;
    const xAxisValues = this._chartService.getBetweenDates(sprintStartDate, lastSprintDate);
    const transformedObj = {
      sprintId: sprintId,
      startDate: sprintStartDate,
      endDate: lastSprintDate,
      xAxisValues: xAxisValues,
      legendItems: [],
      yAxisValues: [],
      stepsByDay: []
    };

    sprintData.forEach((day, index) => {
      const sprintSteps = day.projectScheduleSteps;
      const currSprintDay = day.date;
      const nextSprintDay = sprintData[index + 1] ? sprintData[index + 1].date : null;
      const tomorrow = moment.utc(currSprintDay).clone().add('day', 1).valueOf();
      const subStepObj = {};

      sprintSteps.forEach(step => {
        const subInfo = subcontractors.filter(sub => sub.id === step.subContractorId);
        if (!Utils.isEmpty(subInfo)) {
          transformedObj.yAxisValues.push(step.crewSize);
          const legendItemExists = !Utils.isEmpty(transformedObj.legendItems.filter(item => item.name === subInfo[0].name));
          if (!legendItemExists) transformedObj.legendItems.push({name: subInfo[0].name, color: subInfo[0].hexCode});

          // sort data by subcontractor
          const subId = subInfo[0].id;
          if (subStepObj.hasOwnProperty(subId)) {
            subStepObj[subId]['crewSize'] = subStepObj[subId]['crewSize'] + step.crewSize; // aggregate crew size per day per subcontractor
            subStepObj[subId]['d3linePoints'] = nextSprintDay === tomorrow ? [day.date, nextSprintDay] : null; // only add points for chart line on consecutive days
          } else {
            subStepObj[subId] = {
              date: day.date,
              name: subInfo[0].name,
              crewSize: step.crewSize,
              color: subInfo[0].hexCode
            };
          }
        }
      });

      transformedObj.stepsByDay.push(subStepObj);
    });

    return transformedObj;
  }

  transformProjectPlanDataForChart(sprintList: IProjectSprint[], sprintData: any): any[] {
    const transformedPlanChartData = [];
    sprintList.forEach(sprint => {
      const chartData = sprintData.filter(item => item.sprintId === sprint.id);

      if (chartData.length > 0) {
        const chartInfo = chartData[0];
        const completedActivityPercent = chartInfo.completedActivities ? (chartInfo.completedActivities / chartInfo.totalActivities) : 0;
        const completedActivityPercentDisplay = (completedActivityPercent * 100).toFixed(2) + '%';
        const completedHrsPercent = chartInfo.completedHrs ? (chartInfo.completedHrs / chartInfo.totalHrs) : 0;
        const completedHrsDisplay = (completedHrsPercent * 100).toFixed(2) + '%' ;
        const completedManPowerHrsPercent = chartInfo.completedManPowerHrs ? (chartInfo.completedManPowerHrs / chartInfo.totalManPowerHrs) : 0;
        const completedManPowerHrsPercentDisplay = (completedManPowerHrsPercent * 100).toFixed(2) + '%' ;

        const chartItem = {};
        chartItem['name'] = sprint.name;
        chartItem['id'] = sprint.id;
        chartItem['startDate'] = sprint.startDate;
        chartItem['activities'] = {
          total: chartInfo.totalActivities,
          completed: chartInfo.completedActivities,
          percent: completedActivityPercent,
          percentDisplay: completedActivityPercentDisplay
        };
        chartItem['sprintHrs'] = {
          total: chartInfo.totalHrs,
          completed: chartInfo.completedHrs,
          percent: completedHrsPercent,
          percentDisplay: completedHrsDisplay
        };
        chartItem['manPowerHrs'] = {
          total: chartInfo.totalManPowerHrs,
          completed: chartInfo.completedManPowerHrs,
          percent: completedManPowerHrsPercent,
          percentDisplay: completedManPowerHrsPercentDisplay
        };
        chartItem['isActive'] = sprint.startDate && !sprint.endDate;
        transformedPlanChartData.push(chartItem);
      }
    });

    return transformedPlanChartData;
  }

  aggregateNotCompleteEnums(sprintData: any): any[] {
    let allEnums = sprintData.map(sprint => sprint.notCompletedEnums);
    allEnums = !Utils.isEmpty(allEnums) ? [].concat.apply([], allEnums) : [];
    let chartData = [];

    // get percentage of each value in the array for chart
    allEnums.forEach(item => {
      const itemCount = allEnums.filter(val => val === item).length;
      const itemPercent = itemCount / allEnums.length;
      chartData.push({
          value: (itemPercent * 100).toFixed(2),
          name: item
        });
    });
    chartData = Utils.deDupeObjArrayByKey(chartData, 'name');

    return chartData;
  }

  transformProjectProductivityDataForChart(sprintList: IProjectSprint[], sprintData: any): any[] {
    sprintData.forEach(sprintItem => {
      const filteredSprint = sprintList.filter(sprint => sprint.id === sprintItem.sprintId);
      const sprintName = !Utils.isEmpty(filteredSprint) ? filteredSprint[0].name : null;
      const productivityPercent = sprintItem.actualHrs > 0 ? (sprintItem.totalHrs / sprintItem.actualHrs) : 0;
      const label = (productivityPercent * 100).toFixed(1) + '%';

      sprintItem['sprintName'] = sprintName;
      sprintItem['label'] = label;
      sprintItem['productivityPercent'] = productivityPercent;
      sprintItem['actualHrs'] = Math.round(sprintItem.actualHrs);
      sprintItem['totalHrs'] = Math.round(sprintItem.totalHrs);
    });

    return Utils.sortObjectArrayByKey(sprintData, 'sprintName');
  }

  getFilteredResourcesChartData(chartData: any, subIds: string[]): any {
    const applyFilter = !Utils.isEmpty(chartData.stepsByDay) && !Utils.isEmpty(subIds);
    if (applyFilter) {
      const filteredObject = {
        sprintId: chartData.sprintId,
        startDate: chartData.startDate,
        endDate: chartData.endDate,
        xAxisValues: chartData.xAxisValues,
        yAxisValues: chartData.yAxisValues,
        legendItems: chartData.legendItems,
        stepsByDay: []
      };

      chartData.stepsByDay.forEach(day => {
        const filteredDay = {};
        subIds.forEach(subId => {
          if (day.hasOwnProperty(subId)) {
            filteredDay[subId] = day[subId];
          }
        });
        if (!Utils.objectIsEmpty(filteredDay)) {
          filteredObject.stepsByDay.push(filteredDay);
        }
      });

      return filteredObject;
    } else {
      return chartData;
    }
  }
}
