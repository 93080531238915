import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../../services/notification/notification.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';

import { INoficationContext } from '../../models/notification/notification.interface';
import { Utils } from '../../utils/utils';

@Component({
  templateUrl: './users-forgot-password.component.html',
  styleUrls: ['./users-forgot-password.component.scss'],
  providers: [UserService]
})
export class UsersForgotPasswordComponent implements OnInit {
  email: string = '';
  emailValid: boolean = false;

  constructor(private userService: UserService, private notificationService: NotificationService, private segmentService: SegmentService) { }

  ngOnInit() { /*empty*/ }

  submitForgotPassword(): void {
    if (!this.emailValid) {
      const context: INoficationContext = {
        type: 'valid email',
        action: 'provide'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    const user = new Object();
    user['email'] = this.email;

    this.userService.resetPassword(user).subscribe(
      success => {
        this.notificationService.clear();
        this.notificationService.success('APP_RESET_PASSWORD_CONFIRM', {email: this.email});
        this.segmentService.track('Forgot Password', {email: this.email});
        this.email = '';
      },
      err => {
        const context: INoficationContext = {
          type: 'password',
          action: 'reset'
        };
        this.notificationService.error(err, context);
      });
    return;
  }

  validateEmail(email: string): void {
    this.emailValid = Utils.validateEmailAddress(email);
  }

}
