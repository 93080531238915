import { Component, HostListener, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PDFViewerService } from './../../services/pdf/pdfViewer.service';
import { ProjectService } from './../../services/project/project.service';
import { IPDFToolbar } from '../../models/pdf/pdf.interface';


@Component({
  selector: 'app-project-PDF-controls',
  templateUrl: './project-PDF-controls.component.html',
  styleUrls: ['./project-PDF-controls.component.scss']
})

export class ProjectPDFControlsComponent implements OnInit, OnDestroy {

  @Input() toolbar: IPDFToolbar;

  detatchedWindow: any = false;
  dragEnable: any = false;
  private _DRAG_PARENT: any;
  private _DRAG_CHILD: any;
  private _DRAGGGING_STARTED: any;
  public leftRemaining: number = 0;
  public rightRemaining: number = 0;
  private _LAST_MOUSE_POSITION: any;
  private _DIV_OFFSET = { top: 0, left: 0 };

  constructor(private ngZone: NgZone,
    public pdfViewerService: PDFViewerService,
    public projectService: ProjectService) {
  }

  ngOnInit() {
    if(window.opener){
      if (window.opener.gritParentComponent !== null || window.opener.gritParentComponent.childWindow !== null) {
        this.pdfViewerService = window.opener.gritParentComponent.pdfViewerService;
        this.pdfViewerService.detatchedWindow = true;
      }
    }
    this.updateToolbar(this.toolbar);
  }

  zoomIn() {
    this.pdfViewerService.zoomIn();
  }

  zoomOut() {
    this.pdfViewerService.zoomOut();
  }

  fitToScreen() {
    this.pdfViewerService.fitToScreen();
  }

  getPreviousPage() {
    this.pdfViewerService.getPreviousPage();
  }

  getNextPage() {
    this.pdfViewerService.getNextPage();
  }

  openPDFInNewWindow() {
    this.pdfViewerService.openPDFInNewWindow();

  }

  closePopWindow() {
    this.pdfViewerService.detatchedWindow = false;
    window.opener.gritParentComponent.pdfControl.detatchedWindow = false;
    this.pdfViewerService.closePopWindow();
  }

  disableNextPage(toggleBool: boolean) {
    if (toggleBool) {
      document.getElementById('nextPage').classList.add("disabled-content");
      if(document.getElementById('nav-containertab-Right')!=null)
      document.getElementById('nav-containertab-Right').classList.add("disabled-content");
    }
    else {
      document.getElementById('nextPage').classList.remove("disabled-content");
      if(document.getElementById('nav-containertab-Right')!=null)
      document.getElementById('nav-containertab-Right').classList.remove("disabled-content");
    }
  }


  disableFitToScreen(toggleBool: boolean) {
    if (toggleBool) {
      document.getElementById('firToScreenBtn').classList.add("disabled-content");
    }
    else {
      document.getElementById('firToScreenBtn').classList.remove("disabled-content");
    }
  }

  disablePanToScreen(toggleBool: boolean) {

    // Turns drag off if it's enable.
    this.disableDrag();

    if (toggleBool) {
      if (document.getElementById('panParrent'))
        document.getElementById('panParrent').classList.add("disabled-content");
    }
    else {
      if (document.getElementById('panParrent'))
        document.getElementById('panParrent').classList.remove("disabled-content");
    }
  }

  disableZoomIn(toggleBool: boolean) {
    if (toggleBool) {
      document.getElementById('zoomInBtn').classList.add("disabled-content");
    }
    else {
      document.getElementById('zoomInBtn').classList.remove("disabled-content");
    }
  }

  disableZoomOut(toggleBool: boolean) {
    if (toggleBool) {
      document.getElementById('zoomOutBtn').classList.add("disabled-content");
    }
    else {
      document.getElementById('zoomOutBtn').classList.remove("disabled-content");
    }
  }

  disablePreviousPage(toggleBool: boolean) {
    if (toggleBool) {
      document.getElementById('previousPageBtn').classList.add("disabled-content");
      if(document.getElementById('nav-containertab-Left') != null)
      document.getElementById('nav-containertab-Left').classList.add("disabled-content");
    }
    else {
      document.getElementById('previousPageBtn').classList.remove("disabled-content");
      if(document.getElementById('nav-containertab-Left') != null)
      document.getElementById('nav-containertab-Left').classList.remove("disabled-content");
    }
  }

  ngOnDestroy() {
    if (this.dragEnable)
      this.disableDrag();
  }

  updateToolbar(toolbar: IPDFToolbar) {

    if(!toolbar)
    return;

    this.leftRemaining = toolbar.leftRemaining;
    this.rightRemaining = toolbar.rightRemaining;
    this.disablePreviousPage(toolbar.prePage);
    this.disableNextPage(toolbar.nextPage);
    this.disableZoomOut(toolbar.zoomOut);
    this.disableZoomIn(toolbar.zoomIn);
    this.disableFitToScreen(toolbar.fitToView);
    this.disablePanToScreen(toolbar.pan);

    this.toolbar = toolbar;
  }
  //enable Panning
  enableDrag() {
    this._DRAG_PARENT = document.getElementById("dragParent");
    this._DRAG_CHILD = document.getElementById("dragChild");
    this._DRAGGGING_STARTED = 0;
    this._LAST_MOUSE_POSITION = { x: null, y: null };
    this.enableDisableDragCSS(this._DRAG_PARENT, this._DRAG_CHILD, true);
    this._DRAG_PARENT.addEventListener('mousedown', this.onMouseDown);
    this._DRAG_PARENT.addEventListener('mouseup', this.onMouseUp);
    this._DRAG_PARENT.addEventListener('mousemove', this.onMouseMove);
    this._DRAG_PARENT.addEventListener('mouseleave', this.onMouseLeave);
    this.dragEnable = true;
  }

  disableDrag() {
    if (this.dragEnable) {
      this._DRAG_PARENT = document.getElementById("dragParent");
      this._DRAG_CHILD = document.getElementById("dragChild");
      this._DRAG_PARENT.removeEventListener('mousedown', this.onMouseDown);
      this._DRAG_PARENT.removeEventListener('mouseup', this.onMouseUp);
      this._DRAG_PARENT.removeEventListener('mousemove', this.onMouseMove);
      this._DRAG_PARENT.removeEventListener('mouseleave', this.onMouseLeave);
      this.enableDisableDragCSS(this._DRAG_PARENT, this._DRAG_CHILD, false);
      this.dragEnable = false;
    }
  }

  onMouseUp = (event: MouseEvent) => {
    this._DRAGGGING_STARTED = 0;
  }

  onMouseDown = (event: MouseEvent) => {
    this._DRAGGGING_STARTED = 1;
    this._LAST_MOUSE_POSITION = { x: event.pageX - this._DIV_OFFSET.left, y: event.pageY - this._DIV_OFFSET.top };
  }

  onMouseLeave = (event: MouseEvent) => {
    this._DRAGGGING_STARTED = 0;
  }

  onMouseMove = (event: MouseEvent) => {
    if (this._DRAGGGING_STARTED == 1) {
      var current_mouse_position = { x: event.pageX - this._DIV_OFFSET.left, y: event.pageY - this._DIV_OFFSET.top };
      var change_x = current_mouse_position.x - this._LAST_MOUSE_POSITION.x;
      var change_y = current_mouse_position.y - this._LAST_MOUSE_POSITION.y;
      this._DRAG_PARENT.scrollTop -= (change_y);
      this._DRAG_PARENT.scrollLeft -= (change_x);
      this._LAST_MOUSE_POSITION = current_mouse_position;
    }
  }

  enableDisableDragCSS(parent: any, child: any, enable: boolean) {
    if (enable) {
      parent.classList.remove('overflowAuto');
      parent.style.overflow = null;
      parent.classList.add("dragParent");
      child.classList.add("dragChild");
    }
    if (!enable) {
      parent.classList.remove("dragParent");
      child.classList.remove("dragChild");
      parent.style.overflow = "auto";
      parent.classList.add('overflowAuto');
    }
  }
}
