
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable , Subscription } from 'rxjs';

import { AccountService } from '../services/account/account.service';
import { MenuEmitterService } from '../services/menu/menu-emitter.service';
import { MenuService } from '../services/menu/menu.service';
import { SegmentService } from '../services/segment/segment.service';

import { IAccount } from '../models/account/account.interface';
import { IPageHeader } from '../models/page-header/page-header.interface';
import { IUserAccount } from '../models/user/user.interface';

import { INoficationContext } from '../models/notification/notification.interface';
import { NotificationService } from '../services/notification/notification.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})

export class AccountsComponent implements OnInit, OnDestroy {
  authAccounts: IUserAccount[];
  pageHeaderInfo: IPageHeader;
  message: string;
  account: IAccount;
  accountId: string;
  accountName: string;
  currentAccount: IAccount;
  isEditable: boolean = false;

  // Subscriptions
  routeSubscription: Subscription;
  routerSubscription: Subscription;
  accountSubscription: Subscription;
  accountsSubscription: Subscription;
  permissionDataSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private accountService: AccountService,
    public menuService: MenuService,
    private segmentService: SegmentService
  ) { }

  ngOnInit() {
    // checks local storage to set main menu state
    this.menuService.checkMainMenuState();

    this.routeSubscription = this.route.params.subscribe(async params => {
      this.menuService.setAllowSubMenuNav(false);
      this.accountId = params['accountId'];
      this.getData(this.accountId);
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.accountsSubscription) {
      this.accountsSubscription.unsubscribe();
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.permissionDataSubscription) {
      this.permissionDataSubscription.unsubscribe();
    }
  }

  getData(accountId: string) {
    const observableArray: Array<Observable<any>> = [];
    observableArray.push(this.accountService.getAccountPermission(accountId));
    observableArray.push(this.accountService.getAccountById(accountId));

    forkJoin(observableArray).subscribe(
      results => {
        // Set account permissions
        const accountPermission = results[0];
        AccountService.userAccountPermission = {
          admin: accountPermission === 1,
          edit: accountPermission !== 0 && accountPermission <= 2,
          gc: null
        };
        MenuEmitterService.emitAccountPermissionChange(AccountService.userAccountPermission);
        this.menuService.setAllowSubMenuNav(true);

        // Set account information
        this.account = results[1];
        this.accountService.currentAccount = results[1];

        this.pageHeaderInfo = {
          title: {
            value: this.account.name,
            editable: false
          },
          icon: 'fa-id-badge',
          trialExpires: this.account.trialExpires
        };

        this.segmentService.track('Account Loaded', { accountName: this.account.name });
      },
      error => {
        const context: INoficationContext = {
          type: 'account information',
          action: 'get'
        };
        this.notificationService.error(error, context);
      });
  }
}
