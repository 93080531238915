import { Component, Input, OnChanges } from '@angular/core';

// model interface
import { IUser } from '../../models/user/user.interface';

// services
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent {
    @Input() customImage: string;

    constructor(
        public userService: UserService,
    ) {/*EMPTY*/}

    imgError(): void {
        this.customImage = null;
    }
}
