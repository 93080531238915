import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { EditType, IColHeader, IGritTable, IGRowItem, RowItemType } from '../../../../shared/grit-table/grit-table';
import { IStoryLabor } from '../../../../models/project/project-sprint/project-sprint.interface';

// import { ProjectEquipmentService } from '../../../../services/project/project-equipment/project-equipment.service';
import { ProjectService } from '../../../../services/project/project.service';
import { Utils } from '../../../../utils/utils';

import { ActivityService } from '../../../../services/activity/activity.service';
import { IProjectLabor } from '../../../../models/project/project-labor/project-labor.interface';
import { ProjectLaborService } from '../../../../services/project/project-labor/project-labor.service';
import { ProjectSprintBoardService } from '../../../../services/project/project-sprint-board/project-sprint-board.service';


@Component({
  selector: 'app-sprint-result-labor',
  templateUrl: './sprint-result-labor.component.html',
  styleUrls: ['./sprint-result-labor.component.scss']
})
export class SprintResultLaborComponent implements OnInit {

  @Input() activityLaborIds: string[] = [];
  @Input() taskId: string;
  @Output() updateOutput: EventEmitter<{valid: boolean, data: IStoryLabor[]}> = new EventEmitter();

  @ViewChild('table') table;

  tableData: IGritTable;

  storyLabor: IStoryLabor[] = [];
  allLabor: IProjectLabor[] = [];
  filteredLabor: Observable<IProjectLabor[]>;
  laborOptions: IProjectLabor[];
  laborCtrl = new FormControl();
  displayFn = Utils.defaultDisplayFn;

  isMetric: boolean = false;

  constructor(
    private activityServices: ActivityService,
    private laborService: ProjectLaborService,
    private projectService: ProjectService,
    private projectSprintBoardService: ProjectSprintBoardService
  ) {/*EMPTY*/}

  async ngOnInit() {
    this.isMetric = this.projectService.currentProject.isMetric;

    const currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    console.log('CURRENY IN LABOR COMPONENT');

    console.log(currency);
    console.log(this.projectService.currentProject.currency);

    this.laborService.getList(this.projectService.currentProject.id).subscribe(res => {
      console.log('I am labor result');

      console.log(res);
    }, err => {
      console.error(err);
    });
    if (ProjectService.userPermission.subContractorId) {
      await this.laborService.setLocalUserProjectLabor(this.projectService.currentProject.id, ProjectService.userPermission.subContractorId);
      this.allLabor = this.laborService.getLocalAllProjectLabor();
    } else {
      await this.laborService.setLocalUserProjectLabor(this.projectService.currentProject.id);
      this.allLabor = this.laborService.getLocalAllProjectLabor();
    }
    this.setStoryLabor();
    // this.transformTableData(this.storyLabor);
    this.setValidOptions();
    this.setAutocomplete();
  }

  async setStoryLabor() {
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(this.taskId);
    const activityId = boardItem.activities[0];

    const data = await this.activityServices.getActivityLabors(activityId).subscribe(res => {
      res.forEach((labor, index) => {
        let selectedValue = 0;
        if (labor.elementUnit.toString() === 'area') {
          selectedValue = labor.areaSum ? labor.areaSum : 0;
        } else if (labor.elementUnit.toString() === 'volume') {
          selectedValue = labor.volumeSum ? labor.volumeSum : 0;
        } else if (labor.elementUnit.toString() === 'length') {
          selectedValue = labor.lengthSum ? labor.lengthSum : 0;
        } else if (labor.elementUnit.toString() === 'unit') {
          selectedValue = 1;
        }
        const storyEquip: IStoryLabor = {
          laborId: labor.id,
          name: labor.name,
          quantity:  boardItem.labor && boardItem.labor[index] && boardItem.labor[index].quantity ? boardItem.labor[index].quantity : null,
          plannedQuantity: labor.isMannuallyAddedPlannedQty ? labor.matPlannedQuantity : selectedValue * labor.resourceRate,
          calculatedQuantity: labor.quantityAssigned
        };
        this.storyLabor.push(storyEquip);
      });
      this.transformTableData(this.storyLabor);
    }, err => {
      console.error(err);
    });
  }

  updateTableValid(event) {
    this.updateOutput.emit({valid: event, data: this.formatEquipmentOutput()});
  }

  formatEquipmentOutput(): IStoryLabor[] {
    const returnArr: IStoryLabor[] = [];
    this.tableData.rows.forEach(row => {
      returnArr.push({
        laborId: row.key,
        quantity: row.rowItems.find(ri => ri.colKey === 'quantity').value
      });
    });
    return returnArr;
  }

  deleteRows(ids: string[]) {
    ids.forEach(id => {
      this.storyLabor.splice(this.storyLabor.findIndex(labor => labor.laborId === id), 1);
    });
    this.clearInput();
  }

  setValidOptions(): void {
    this.laborOptions = this.allLabor.filter(item => !this.storyLabor.find(labor => labor.laborId === item.id));
  }

  setAutocomplete(): void {
    this.filteredLabor = this.laborCtrl.valueChanges.pipe(
      startWith<string | IProjectLabor >(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.equipmentSearch(name) : this.getAllLaborOptions()));
  }

  equipmentSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.laborOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllLaborOptions() {
    return this.laborOptions.slice(0, Math.min(this.laborOptions.length, 50));
  }

  laborSelected(matLabor) {
    const labor: IProjectLabor = matLabor.option.value;
    const storyMat: IStoryLabor = {
      laborId: labor.id,
      name: labor.name,
      quantity: null,
      calculatedQuantity: labor.quantityAssigned
    };
    this.storyLabor.push(storyMat);
    this.addToTableData(storyMat);
    this.clearInput();
    this.table.setupTable();
  }

  clearInput(): void {
    this.laborCtrl.setValue('');
    this.laborCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }

  transformTableData(dataInput: IStoryLabor[]) {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text,
        width: '40%'
      },
      // {
      //   displayName: 'calculated',
      //   colKey: 'calculatedQuantity',
      //   type: RowItemType.Number,
      //   width: '25%'
      // },
      {
        displayName: 'planned',
        colKey: 'plannedQuantity',
        type: RowItemType.Text,
        width: '40%'
      },
      {
        displayName: 'actual',
        colKey: 'quantity',
        type: RowItemType.Number,
        width: '20%'
      }
    ];

    this.tableData = {
      colHeaders: colHeaders,
      rows: [],
      openEditable: true,
      showIntro: false,
      selectOptions: {
        showCheckboxes: false
      },
      paginationOptions: {
        showTop: false,
        showBottom: false
      }
    };

    dataInput.forEach(labor => {
      this.addToTableData(labor);
    });
  }

  addToTableData(storyLabor: IStoryLabor) {
    const suffix = Utils.getUnitOfMeasureLabel(this.laborService.getLocalLaborById(storyLabor.laborId).unitOfMeasure, this.isMetric);
    const rowItems: IGRowItem[] = [
      {
        colKey: 'name',
        value: storyLabor.name,
        editable: false
      },
      // {
      //   colKey: 'calculatedQuantity',
      //   value: storyLabor.calculatedQuantity,
      //   editable: false,
      //   suffix: suffix
      // },
      {
        colKey: 'plannedQuantity',
        value: storyLabor.plannedQuantity ? storyLabor.plannedQuantity.toFixed(2) : 0,
        editable: false
      },
      {
        colKey: 'quantity',
        value: storyLabor.quantity ? storyLabor.quantity.toFixed(2) : 0,
        editable: true,
        required: true,
        suffix: suffix
      }
    ];
    this.tableData.rows.push({
      key: storyLabor.laborId,
      rowItems: rowItems,
      editOptions: {
        openEdit: true,
        deletePermission: true,
        editRowValidationFn: this.rowValidationFn,
        rowEdits:   [
          {
            type: EditType.Delete,
            disabled: false
          }
        ]
      },
      selectable: false
    });
  }

  rowValidationFn(rowItems: IGRowItem[]): boolean {
    const quantity = rowItems.find(ri => ri.colKey === 'quantity');
    if (!quantity) return false;
    if (!quantity.value) return false;
    if (typeof quantity.value !== 'number') return false;
    return true;
  }

}
