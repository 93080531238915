import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ChartService } from '../../services/chart/chart.service';
import { NotificationService } from '../../services/notification/notification.service';
import { PDFViewerService } from '../../services/pdf/pdfViewer.service';
import { ProjectFlowlineService } from '../../services/project/project-flowline-chart/project-flowline.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectService } from '../../services/project/project.service';

import { INoficationContext } from '../../models/notification/notification.interface';
import { ICostReportChartData, ICostReportData } from '../../models/reports/project-report.interface';
import { IProjectScheduleControls } from '../../models/project/project-schedule/project-schedule.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { CostColor, GritColors } from '../../utils/enums/hex-color.enum';

import { ChartIntervalState, CostReportValueTypes } from '../../utils/enums/reports.enum';
import { ViewMode } from '../../utils/enums/shared.enum';
import { Utils } from '../../utils/utils';

import * as D3 from 'd3';
import * as moment from 'moment';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-project-flowline-chart',
  templateUrl: './project-flowline-chart.component.html',
  styleUrls: ['./project-flowline-chart.component.scss']
})

export class ProjectFlowlineChartComponent implements OnInit {

  @ViewChild('chartSvgContainer') chartSvgContainer;
  @ViewChild('graphContainer') graphContainer;
  @ViewChild('columnContainer') columnContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('xAxisLabel') xAxisLabel;
  @ViewChild('yAxis') yAxis;
  @ViewChild('yAxisLabel') yAxisLabel;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  pageIsLoading: boolean = false;
  errorGettingData: boolean = false;
  noData: boolean = false;
  errorMessage: string = 'error_getting_data.';
  noDataMessage: string = 'flow_data_error';
  selectedScheduleVersionId: string = '';
  selectedViewWeekIndex: number = 1;
  noScheduleData: boolean = false;
  allScheduleByPlan: any;
  scheduleOptions: IDropdownItem[];
  showRetryBtn: boolean = false;
  noScheduleDisplayMessage: string;
  scheduleCreationEnabled: boolean = false;
  noDataMessages: string[] = [
    'There was an error getting schedule data.',
    'A schedule needs to be generated.',
    'A schedule needs to be generated. Only users with project admin permissions that are not associated with a subcontractor may perform this action.',
    'There is no active schedule. Select a schedule for the dropdown or create a new schedule.',
    'The selected schedule contains no data. Ensure activities are assigned.'
  ];
  private scheduleCostData: any[] = [];
  private formattedData: ICostReportData[] = [];
  private activeChartInterval: ChartIntervalState;
  private allYaxisValues: number[] = [];
  private xScaleTime: D3.scaleUt<any> = null;
  private yScale: D3.ScalePoint<any, any> = null;
  chartControls: IProjectScheduleControls;
  private activeDisplayMode: ViewMode = ViewMode.OneWeekSchedule;
  private chartStart: number;
  private chartEnd: number;
  private visibleMonths: number;
  private xAxisTickCount: number;
  public projectId: string;
  sheets = [];

  constructor(
    private chartService: ChartService,
    private projectService: ProjectService,
    private projectScheduleService: ProjectScheduleService,
    private notificationService: NotificationService,
    public projectFlowlineService: ProjectFlowlineService,
    public pdfViewerService: PDFViewerService
  ) { }

  ngOnInit() {
    this.selectedScheduleVersionId = 'none';
    this.projectService.checkToHideSidebar(true);
    localStorage.setItem('isSideBarOpen', null);
    this.projectId = this.projectService.currentProject.id;
    this.getScheduleData();
    this.getActivitySheetDetails();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getActivitySheetDetails() {
    this.pdfViewerService.getData(this.projectId, this.selectedScheduleVersionId).subscribe((res) => {
       this.sheets = res;
       this.sheets.push({name: ''});
       this.setupData();
       this.noData = this.sheets.length <= 1;
     });
   }

  async getScheduleData() {
    this.pageIsLoading = true;
    const observableArray: Array<Observable<any>> = [
      this.projectScheduleService.getSchedulesByPlan(this.projectId), // check for schedules
    ];

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        if (res[0].length > 0) {
          this.noScheduleData = false;
          this.allScheduleByPlan = res[0];
          this.scheduleOptions = this.projectScheduleService.transformSchedulesForDropdown(this.allScheduleByPlan);
          this.pageIsLoading = false;

          this.scheduleOptions.unshift({ label: 'None', value: 'none' });
        } else {
          this.setNoDataDisplay('no-data');
          this.noScheduleData = true;
          this.pageIsLoading = false;
        }
      },
      err => {
        this.setNoDataDisplay('error');
        const context: INoficationContext = {
          type: 'schedule criteria check',
          action: 'get'
        };
        this.notificationService.error(err, context);

        this.pageIsLoading = false;
      }
    );
  }

  setNoDataDisplay(type: string): void {
    switch (type) {
      case 'no-data' :
        this.noScheduleData = true;
        this.showRetryBtn = false;
        this.noScheduleDisplayMessage = this.scheduleCreationEnabled
          ? this.noDataMessages[1]
          : this.noDataMessages[2];
        break;
      case 'no-active-data' :
        this.noScheduleData = true;
        this.showRetryBtn = false;
        this.noScheduleDisplayMessage = this.noDataMessages[3];
        break;
      case 'empty-schedule' :
        this.errorGettingData = true;
        this.showRetryBtn = false;
        this.noScheduleDisplayMessage = this.noDataMessages[4];
        break;
      default :
        this.errorGettingData = true;
        this.showRetryBtn = true;
        this.noScheduleDisplayMessage = this.noDataMessages[0];
        break;
    }
  }

  setupDisplayedSchedule(scheduleId: any): void {
    this.selectedScheduleVersionId = scheduleId.value;
    this.getActivitySheetDetails();
  }

  setupData(): void {
    this.setChartDisplayMode(ViewMode.ThreeWeekSchedule);
    this.setXaxisData();
    this.setupChartControls();
    this.drawChart();
    this.handleTodayCtrl();
  }

  drawChart(): void {
    this.createChartDimensions();
    this.setScales();
    this.drawYaxis();
    this.drawXaxis();
    this.setupChartData();
  }

  setupChartControls(): void {
    this.chartControls = this.projectFlowlineService.getChartControls(this.chartStart, this.chartEnd);
  }

  setXaxisData(startDate?: number): void {
    switch (this.activeDisplayMode) {
      case ViewMode.OneWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(1, ChartIntervalState.Week, startDate);
        this.xAxisTickCount = 7;
        break;
      case ViewMode.ThreeWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(3, this.activeChartInterval, startDate);
        this.xAxisTickCount = 21;
        break;
      case ViewMode.SixWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(6, this.activeChartInterval, startDate);
        this.xAxisTickCount = 42;
        break;

      case ViewMode.EightWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(8, this.activeChartInterval, startDate);
        this.xAxisTickCount = 56;
        break;

      default :
        this.activeChartInterval = ChartIntervalState.Day;
        this.setStartEnd(1, ChartIntervalState.Week, startDate);
        this.xAxisTickCount = 7;
    }
  }

  setStartEnd(monthCount: number, interval: any, startDate?: number): void {
    this.chartStart = startDate ? startDate : moment.utc(this.projectService.currentProject.startDate).startOf('month').valueOf();
    this.chartEnd = moment.utc(this.chartStart).clone().add(monthCount, interval).subtract( 1 , 'day').valueOf(); // so last day is last of month
    this.visibleMonths = monthCount;
  }

  createChartDimensions(): void {
    const containerHeight = window.innerHeight - this.projectFlowlineService.chartIntroHeight - 500;
    const containerWidth = document.getElementById('chart-scroll-container').offsetWidth - 40;

    this.projectFlowlineService.setGraphDimensions(containerHeight, containerWidth, this.xAxisTickCount);
    this.projectFlowlineService.setChartWrapperDimensions();
    this.projectFlowlineService.setChartAxes();
  }

  wrap(yAxisText, width): void {
    yAxisText.each(function() {
      const text = D3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1;
      const x = text.attr('x');
      const y = text.attr('y');
      const dy = .1;
      let tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em');
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
        }
      }
    });
  }

  addDays(date, days): any {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  setScales(): void {
    const sheetsArray = this.sheets.map(item => item.name);
    this.yScale = D3.scalePoint()
      .rangeRound([this.projectFlowlineService.graphDimensions.height, 0])
      .domain(sheetsArray);

    this.xScaleTime = D3.scaleUtc()
      .rangeRound([0, this.projectFlowlineService.graphDimensions.width])
      .domain([this.chartStart, this.addDays(this.chartEnd, 1)]);
  }

  drawYaxis(): void {
    const yAxis = D3.axisLeft()
      .scale(this.yScale)
      .tickSize(-this.projectFlowlineService.graphDimensions.width)
      .ticks(this.sheets.length);

    D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis')
      .attr('transform', this.projectFlowlineService.axisData.yAxisTransform)
      .call(yAxis)
      .selectAll('text')
      .style('fill', '#a4a4b2')
      .style('font-weight', '900')
      .attr('y', -(this.projectFlowlineService.graphDimensions.height / this.sheets.length) / 2)
      .call(this.wrap, 100);
  }

  drawXaxis(): void {
    const formatLabel = (date: any) => {
      const chartEndDate = this.addDays(this.chartEnd, 1);
      const d1 = new Date(chartEndDate);
      const d2 = new Date(date);
      switch (this.activeChartInterval) {
        case ChartIntervalState.Month : return moment.utc(date).clone().format('M/Y');
        default : return (d1.getTime() === d2.getTime()) ? null : moment.utc(date).clone().format('M/D');
      }
    };
    const xAxis = D3.axisTop()
      .scale(this.xScaleTime)
      .tickSize(this.projectFlowlineService.graphDimensions.height)
      .ticks(this.xAxisTickCount)
      .tickFormat((d) => formatLabel(d));

    D3.select(this.xAxis.nativeElement)
      .attr('class', 'x-axis')
      .attr('transform', this.projectFlowlineService.axisData.xAxisTransform)
      .call(xAxis)
      .selectAll('text')
      .style('writing-mode', ((this.xAxisTickCount > 40) ? 'tb-rl' : 'horizontal-tb'))
      .style('fill', '#a4a4b2')
      .style('font-weight', '900')
      .attr('x', (this.projectFlowlineService.graphDimensions.width / this.xAxisTickCount ) / 2)
      .attr('dy', '-1em');

    D3.select(this.xAxisLabel.nativeElement)
      .attr('transform', this.projectFlowlineService.axisData.xAxisLabelTransform)
      .text(TranslationService.translate('date_by_unit', {unit: TranslationService.translate(this.activeChartInterval)})); // + ' (' + '(by ${this.activeChartInterval})`);
    }

  setupChartData() {
    this.drawLinesAndCircles(this.sheets);
  }

  drawLinesAndCircles(scheduleCostData: any) {
    const graphContainer = D3.select(this.graphContainer.nativeElement)
      .selectAll('line, circle')
      .remove()
      .exit()
      .data(scheduleCostData);

    const _sheets = JSON.parse(JSON.stringify(this.sheets));

    _sheets.forEach((sheet, i) => {
      const visibleActivities = [];
      if (sheet.activities) {
        sheet.activities.forEach((activity, j) => {
          if ((activity.properties.endDate) && (activity.properties.startDate >= this.chartStart)
           && (activity.properties.startDate <= this.chartEnd) && (activity.properties.endDate >= this.chartStart) && (activity.properties.endDate <= this.chartEnd)) {
            if (!Utils.isEmpty(_sheets[i])) {
              visibleActivities.push(activity);
            }
          }

          _sheets[i].activities = visibleActivities;
        });
      }
    });

    for (let i = 0; i < _sheets.length; i++) {
      const chartVisibleData: any = _sheets[i].activities;

      if (Utils.isEmpty(chartVisibleData)) {
        continue;
      }

      graphContainer
        .select(this.graphContainer.nativeElement)
        .data(chartVisibleData)
        .enter()
        .append('line')
        .attr('x1', d => this.xScaleTime(new Date(d.properties.startDate)))
        .attr('x2', d => this.xScaleTime(new Date(d.properties.endDate)))
        .attr('y1', d => this.yScale(_sheets[i].name ))
        .attr('y2', d => this.yScale(_sheets[i + 1].name ))
        .attr('stroke', (d) => {
          if (d.sub && d.sub.properties && d.sub.properties.hexCode) {
            return d.sub.properties.hexCode;
          } else {
            return 'black';
          }
        })
        .attr('stroke-width', '1px')
        .on('mouseover', d => this.handlePointHover(d, 'mouseover', _sheets[i].name))
        .on('mouseout', d => this.handlePointHover(d, 'mouseout', _sheets[i].name));

      graphContainer
        .select(this.graphContainer.nativeElement)
        .data(chartVisibleData)
        .enter()
        .append('circle')
        .attr('cx', d => this.xScaleTime(new Date(d.properties.startDate)))
        .attr('cy', d =>  this.yScale(_sheets[i].name))
        .attr('r', 5)
        .style('fill', (d) => {
          if (d.sub && d.sub.properties && d.sub.properties.hexCode) {
            return d.sub.properties.hexCode;
          } else {
            return 'black';
          }
        })
        .attr('cursor', 'pointer')
        .on('mouseover', d => this.handlePointHover(d, 'mouseover', _sheets[i].name))
        .on('mouseout', d => this.handlePointHover(d, 'mouseout', _sheets[i].name));

      graphContainer
        .select(this.graphContainer.nativeElement)
        .data(chartVisibleData)
        .enter()
        .append('circle')
        .attr('cx', d => this.xScaleTime(new Date(d.properties.endDate)))
        .attr('cy', d =>  this.yScale(_sheets[i + 1].name))
        .attr('r', '6')
        .style('fill', (d) => {
          if (d.sub && d.sub.properties && d.sub.properties.hexCode) {
            return d.sub.properties.hexCode;
          } else {
            return 'black';
          }
        })
        .attr('cursor', 'pointer')
        .on('mouseover', d => this.handlePointHover(d, 'mouseover',  _sheets[i].name))
        .on('mouseout', d => this.handlePointHover(d, 'mouseout', _sheets[i].name));
    }

    // document.getElementsByClassName('domain')[0]['style']['display'] = 'none';
    // document.getElementsByClassName('domain')[1]['style']['display'] = 'none';

  }

  handlePointHover(pointData: any, action: string, dataKey: string): any {
    const chartDimensions = this.chartSvgContainer.nativeElement.getBoundingClientRect();
    const distFromRight = Math.abs(D3.event.pageX - chartDimensions.right);
    const distFromBottom = chartDimensions.bottom - D3.event.pageY;
    if (action === 'mouseover') {
      const ttBuffer = 10;
      const ttHeight = 128;
      const ttWidth = 230;
      const adjustFromRight = distFromRight < (ttWidth + ttBuffer) ? true : false;
      const adjustFromBottom = distFromBottom < (ttHeight + ttBuffer) ? true : false;
      const ttPosition = {
        top: adjustFromBottom ? ((D3.event.pageY + ttBuffer) - ttHeight) : D3.event.pageY + ttBuffer,
        left: adjustFromRight ? ((D3.event.pageX - ttBuffer) - ttWidth) : D3.event.pageX + ttBuffer
      };

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left + 'px')
        .style('top', ttPosition.top + 'px')
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(pointData.properties, dataKey, pointData.sub));

    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  drawTooltip(data: any, dataKey: string, sub: any) {
    let tooltip = ``;
    if (data.dayStart) tooltip += this.getTTlabel(data.dayStart);
    if (data.name) tooltip += `<div class='data-point '><span class='label'>` + TranslationService.translate('name') + `: </span><span class='value'>` + data.name + `</span></div>`;

    if (data.startDate) tooltip += `<div class='data-point'><span class='label'>`
    + TranslationService.translate('start_date') + `: </span><span class='value'>` + Utils.formatDate(data.startDate) + `</span></div>`;

    if (data.endDate) tooltip += `<div class='data-point'><span class='label'>`
    + TranslationService.translate('end_date') + `: </span><span class='value'>` + Utils.formatDate(data.endDate) + `</span></div>`;

    if (sub && sub.properties && sub.properties.name) tooltip += `<div class='data-point'><span class='label'>`
    + TranslationService.translate('subcontractor') + `: </span><span class='value text-ellipsis'>` + sub.properties.name + `</span></div>`;

    if (dataKey) tooltip += `<div class='data-point   '><span class='label'>` + TranslationService.translate('sheet') + `: </span><span class='value text-ellipsis'>` + dataKey + `</span></div>`;

    return tooltip;
  }

  getTTlabel(day: number): string {
    switch (this.activeDisplayMode) {
      case ViewMode.ThreeMonthGantt :
        // tslint:disable-next-line:max-line-length
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('week_of') + `: </span><span class='value'>${moment.utc(day).clone().format('MM/DD/YY')}</span></div>`;
      case ViewMode.SixMonthGantt :
      case ViewMode.TwelveMonthGantt :
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('month_of') + `: </span><span class='value'>${moment.utc(day).clone().format('MMM.')}</span></div>`;
      default :
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('date') + `: </span><span class='value'>${moment.utc(day).clone().format('MM/DD/YY')}</span></div>`;
    }
  }

  handleResetCtrl(): void {
    this.chartStart = this.projectService.currentProject.startDate;
    this.setXaxisData(this.chartStart);
    this.setupChartControls();
    this.drawChart();
  }

  handleTodayCtrl(): void {
    this.chartStart = moment.utc(Utils.getCurrentDate()).startOf('month').valueOf();
    this.setXaxisData(this.chartStart);
    this.setupChartControls();
    this.drawChart();
  }

  handleRangeCtrl(direction: string): void {
    if (direction === 'next') {
      this.chartStart = moment.utc(this.chartStart).clone().add(this.visibleMonths, 'week').valueOf();
      this.setXaxisData(this.chartStart);
      this.setupChartControls();
      this.drawChart();
    } else {
      this.chartStart = moment.utc(this.chartStart).clone().subtract(this.visibleMonths, 'week').valueOf();
      this.setXaxisData(this.chartStart);
      this.setupChartControls();
      this.drawChart();
    }
  }

  handleStartFinishCtrl(isStart: boolean): void {
    if (isStart) {
      this.chartStart = this.chartStart = this.projectService.currentProject.startDate;
      this.setXaxisData(this.chartStart);
      this.setupChartControls();
      this.drawChart();
    } else {
      this.chartEnd = this.chartStart = this.projectService.currentProject.startDate;
      this.chartStart = moment.utc(this.chartEnd).clone().subtract(this.visibleMonths, 'week').valueOf();
      this.setXaxisData(this.chartStart);
      this.drawChart();
    }
  }

  switchViewClick(viewMode: any): void {
    this.setChartDisplayMode(viewMode);
    this.setXaxisData(this.chartStart);
    this.setupChartControls();
    this.drawChart();
  }

  decreaseViewSelection() {
      if (this.selectedViewWeekIndex >= this.projectFlowlineService.chartDisplayModes.length) {
         return;
      }

      this.selectedViewWeekIndex++;
      this.switchViewClick(this.projectFlowlineService.chartDisplayModes[this.selectedViewWeekIndex].label);
   }

  increaseViewSelection() {
    if (this.selectedViewWeekIndex <= 0) {
       return;
    }

    this.selectedViewWeekIndex--;
    this.switchViewClick(this.projectFlowlineService.chartDisplayModes[this.selectedViewWeekIndex].label);
  }

  setChartDisplayMode(viewMode: ViewMode): void {
    this.projectFlowlineService.chartDisplayModes.forEach(mode => {
      if (viewMode === mode.mode) {
        mode.selected = true;
        this.activeDisplayMode = mode.mode;
      } else {
        mode.selected = false;
      }
    });
  }

  resize(): void {
    this.setXaxisData();
    this.drawChart();
  }

  refresh(): void {
    window.location.reload(true);
  }
}
