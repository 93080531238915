
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TREE_ACTIONS } from 'angular-tree-component';
import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeService } from '../../services/forge/forge.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectModelService } from '../../services/project/project-model/project-model.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Utils } from '../../utils/utils';

import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { IGritTable } from '../../shared/grit-table/grit-table';
import { EventType } from '../../utils/enums/notification.enum';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectModel } from '../../models/project/project-model/project-model.interface';
import { IProject } from '../../models/project/project.interface';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.scss']
})
export class ProjectFilesComponent implements OnInit, OnDestroy {
  @Input() projectPermissionInput: IUserPermission;

  @ViewChild('fileInput') fileInput;

  file = null;
  uploadButtonDisabled = true;
  upLoading: boolean = false;
  downloading: boolean = false;
  uploadProgress: string = '0%';
  activities: string[];

  project: IProject;
  projectId: string;
  projectModels: IProjectModel[] = [];
  completedModels: IProjectModel[] = [];
  hasConflicts: boolean = false;

  tableData: IGritTable;

  replaceModelId: string = '';
  replaceModelName: string = '';

  listDisplayTexts: string[] = [];

  fileDropdownItems: IDropdownItem[];
  fileType: string = 'upload';

  // Subscriptions
  routeSubscription: Subscription;

  nodes;
  expandedNodes: any = [];
  options = {
    getChildren: (node) => {
      return new Promise((resolve) => {
        resolve(node.data.children);
      });
    },
    actionMapping: {
      mouse: {
        click: TREE_ACTIONS.TOGGLE_EXPANDED
      }
    }
  };

  hasBIM360 = false;
  showBim360Modal = false;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private projectModelService: ProjectModelService,
    public projectService: ProjectService,
    private forgeService: ForgeService,
    private router: Router
  ) {/*EMPTY*/}

  ngOnInit() {
    this.hasBIM360 = this.projectService.currentProjectAccount.hasBim360;
    this.route.queryParams.subscribe(params => {
      if (!Utils.isEmpty(params['code'])) {
        this.forgeService.postAuthCode(this.projectService.currentProjectAccount.id, params['code']).subscribe(
          () => {
            this.fileDropdownItems = [
              { value: 'upload', label: 'File Upload' },
              { value: 'bim360', label: 'BIM360 Import' },
              { value: 'newBim360', label: 'BIM360 Setup' }
            ];
            this.fileType = 'bim360';
            this.downloading = true;
            this.forgeService.getTree(this.projectService.currentProject.id).subscribe(tree => {
              this.nodes = tree;
              this.downloading = false;
            },
              // tslint:disable-next-line:align
              () => {
                this.downloading = false;
              });
          },
          _error => {
            // TBD
          }
        );
      }
    });

    this.projectId = this.projectService.currentProject.id;

    this.loadGrid();
    SegmentService.track('File Manager Loaded', { projectId: this.projectId });
    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.MODEL_STATUS_UPDATED)) return;
      const item = this.projectModels.find(model => model.id === event.body.modelId);
      if (!Utils.isEmpty(item)) item.status = event.body.status;
      this.projectModels = this.projectModels.filter(m => m.status !== 15);
      this.completedModels = this.projectModels.filter(m => m.status === 2);
      this.hasConflicts = !!this.completedModels.find(m => m.conflicts > 0);
      this.tableData = this.getTableData(this.projectModels);
    });

    window.addEventListener('keydown', this.keyPress);
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    if (!Utils.isEmpty(this.routeSubscription)) this.routeSubscription.unsubscribe();
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  loadGrid() {
    this.projectModelService.getModelList(this.projectId).subscribe(projectModels => {
      this.projectModels = Utils.sortByString(projectModels, 'name').filter(m => m.status !== 15);
      this.completedModels = this.projectModels.filter(m => m.status === 2);
      this.hasConflicts = !!this.completedModels.find(m => m.conflicts > 0);
      this.tableData = this.getTableData(this.projectModels);
    });
  }

  getTableData(projectModels: IProjectModel[]) {
    return this.projectModelService.transformTableData(projectModels, this.projectPermissionInput);
  }

  deleteRows(rowIds: string[]): void {
    const observableArray: Array<Observable<any>> = new Array();
    rowIds.forEach(id => {
      observableArray.push(this.projectModelService.deleteModel(id, this.projectId));
    });

    forkJoin(observableArray).subscribe(
      () => {
        rowIds.forEach(id => {
          this.projectModels.splice(this.projectModels.findIndex(model => model.id === id), 1);
        });
        SegmentService.track('Files Deleted', { projectModelIds: rowIds });
      },
      err => {
        const context: INoficationContext = {
          type: 'model file',
          action: 'remove'
        };

        this.notificationService.error(err, context);
        this.loadGrid();
      });
  }

  fileAdded(event: EventTarget) {
    this.fileType = 'file';
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    this.file = files[0];
    const dupeModel = this.projectModels.find(model => model.name.toLowerCase() === this.file.name.toLowerCase());
    if (!Utils.isEmpty(dupeModel) && !this.replaceModelId) {
      this.notificationService.error('DUPLICATE_PROJECT_MODEL_FILE_NAME', {});
      this.file = null;
    } else {
      this.uploadButtonDisabled = false;
    }
  }

  importBim360() {
    this.downloading = true;
    this.showBim360Modal = true;
    this.forgeService.getTree(this.projectService.currentProject.id).subscribe(
      tree => {
        this.nodes = tree;
        this.downloading = false;
      },
      () => {
        this.downloading = false;
      });
  }

  clearFile(): void {
    this.file = null;
  }

  uploadFile(): void {
    this.upLoading = true;
    if (this.fileType === 'bim360') {
      if (!Utils.isEmptyList(this.activities)) this.file['activities'] = JSON.stringify(this.activities);
      this.file['replaceModelId'] = this.replaceModelId;
      this.projectModelService.importBim360(this.projectId, this.file).subscribe(
        () => {
          this.uploadProgress = '0%';
          this.upLoading = false;
          this.replaceModelId = '';
          this.file = null;
          SegmentService.track('Bim360 Uploaded', { projectId: this.projectId, activities: this.activities });

          this.loadGrid();
        },
        err => {
          const context: INoficationContext = {
            type: 'BIM360 Model',
            action: 'import'
          };

          this.notificationService.error(err, context);
          this.upLoading = false;
        });
    } else {
      this.projectModelService.insertModel(this.projectId, this.file, this.activities, this.replaceModelId).subscribe(
        success => {
          this.replaceModelId = '';
          if (success.type === 1) {
            this.uploadProgress = (success.loaded / success.total * 100).toString() + '%';
          } else if (success.type === 4) {
            this.uploadProgress = '0%';
            this.upLoading = false;
            SegmentService.track('File Uploaded', { projectId: this.projectId, activities: this.activities });
            this.file = null;

            this.loadGrid();
          }
        },
        err => {
          const context: INoficationContext = {
            type: 'model file',
            action: 'upload',
            filetype: '.rvt, .3ds, .nwd, .nwc, .ifc, .skp, .dgn'
          };

          this.notificationService.error(err, context);
          this.upLoading = false;
        });
    }
  }

  getIcon(type: string) {
    if (type === 'items') return 'far fa-file';
    if (type === 'folders') return 'fa fa-folder';
    if (type === 'versions') return 'fa fa-file';
  }

  nodeClicked(node) {
    if (node.data.type !== 'versions') {
      return;
    }
    this.file = {
      name: node.data.fileName,
      urn: node.data.id,
      parentUrn: node.data.parentUrn,
      version: Utils.replaceAll(node.data.name, 'v', ''),
      bim360HubId: node.data.bim360HubId
    };
    this.showBim360Modal = false;
    this.fileType = 'bim360';
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.projectPermissionInput.edit) {
        if (this.file == null) {
          this.fileInput.nativeElement.click();
        } else {
          this.uploadFile();
        }
      }
    }
  }

  editRow(id: string) {
    if (this.file) {
      this.file = null;
      setTimeout(() => {
        this.editRow(id);
      }, 1);
      return;
    }
    const model = this.completedModels.find(m => m.id === id);
    if (model) {
      this.replaceModelId = model.id;
      this.replaceModelName = model.name;
      if (model.fileSize) {
        this.fileInput.nativeElement.click();
      } else {
        this.importBim360();
      }
    }
  }

  resolveConflicts() {
    document.location.href = '/project/' + this.projectId + '/model-conflicts';
  }
}
