
import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

import { AccountService } from '../../services/account/account.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UtilsService } from '../../services/utils/utils.service';

import { IAccount } from '../../models/account/account.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProject } from '../../models/project/project.interface';
import { IUserPermission } from '../../models/user/user.interface';

import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { EventStatus } from '../../utils/enums/notification.enum';
import { UserService } from '../../services/user/user.service';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  pageIsLoading: boolean = true;
  deleteButtonDisabled: boolean = false;
  resetButtonDisabled: boolean = false;
  resetStepsButtonDisabled: boolean = false;
  subContentIsLoading: boolean = true;
  loadingMessage: string = 'Loading...';
  currentProject: IProject;
  currentAccount: IAccount;
  projectId: string;
  project: IProject;
  projectPermission: IUserPermission;
  accountPermission: IUserPermission;
  projectName: string;
  projectTimeZone: string;
  projectCurrency: string;
  projectNameValid: boolean = true;
  menuItemSelected: number = 0;
  projectStartDate: any;
  showConfirmationBox = false;
  confirmationBoxSetup: {
    message: string;
    action: string
  };
  timeZoneDropdownItems: IDropdownItem[] = new Array();
  filteredTimeZoneDropdownItems: IDropdownItem[] = new Array();
  currencyDropdownItems: IDropdownItem[] = new Array();
  filteredCurrencyDropdownItems: IDropdownItem[] = new Array();
  projectAddress: string;
  planningLeadTime: number;
  firstLoad: boolean = true;
  autoAssignResources: boolean = false;

  showExportModal: boolean = false;
  exportModalInput: IConfirmationModalInput = {
    displayMessage: 'export_password',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true
  };

  exportPassword: string = '';

  exporting: boolean = false;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  settingsMenu = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public notificationService: NotificationService,
    public projectService: ProjectService,
    private segmentService: SegmentService,
    private utilsService: UtilsService,
    public accountService: AccountService,
    public userService: UserService
  ) { }

  async ngOnInit() {
    this.setupMenu();
    this.projectService.checkToHideSidebar(true);

    if (this.projectService.getProjectReady()) {
      this.setPageDisplayToggles();
      this.setupPageData();
    } else {
      this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.setPageDisplayToggles();
        this.setupPageData();
      });
    }

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.menuItemSelected = parseInt(params['menuItem'], 10) || 0;
      if (this.menuItemSelected === 0) this.segmentService.track('Project Settings Loaded', { projectId: this.projectId });
    });

    window.addEventListener('keydown', this.keyPress);
  }

  setupMenu() {
    const mItem = parseInt(this.router.url.split('=')[1], 10);
    this.settingsMenu.push({
      title: 'project_menu_item',
      links: [
        {
          title: 'general_menu_item',
          menuIndex: 0,
          active: mItem === 0
        }
      ]
    });
    this.settingsMenu[0].links.push({
      title: 'models_menu_item',
      menuIndex: 3,
      active: mItem === 3
    });
    this.settingsMenu[0].links.push({
      title: 'floor_plans_menu_item',
      menuIndex: 10,
      active: mItem === 10
    });
    this.settingsMenu.push({
      title: 'people_menu_item',
      links: [
        {
          title: 'users_menu_item',
          menuIndex: 1,
          active: mItem === 1
        },
        {
          title: 'subcontractor_roles_menu_item',
          menuIndex: 2,
          active: mItem === 2
        }
      ]
    });

    this.settingsMenu.push({
      title: 'libraries_menu_item',
      links: [
        {
          title: 'equipment',
          menuIndex: 8,
          active: mItem === 8
        },
        {
          title: 'Submittals/Materials',
          menuIndex: 9,
          active: mItem === 9
        },
        {
          title: 'RFI',
          menuIndex: 11,
          active: mItem === 11
        },
        {
          title: 'labor',
          menuIndex: 12,
          active: mItem === 12
        },
        {
          title: 'milestones',
          menuIndex: 5,
          active: mItem === 5
        },
        {
          title: 'reasons_not_complete_menu_item',
          menuIndex: 7,
          active: mItem === 7
        }
      ]
    });

    if (ProjectService.userPermission.admin && ProjectService.userPermission.gc) {
      this.settingsMenu.push(
        {
          title: 'integrations_menu_item',
          links: [
            {
              title: 'Procore',
              menuIndex: 6,
              active: mItem === 6
            }
          ]
        });
    }

  }

  setupPageData() {
    this.projectId = this.projectService.currentProject.id;
    this.currentAccount = this.projectService.currentProjectAccount;
    this.currentProject = this.projectService.currentProject;
    this.currentProject.planningLeadTime = this.currentProject.planningLeadTime ? this.currentProject.planningLeadTime : 0;
    this.projectAddress = this.currentProject.address || '';
    this.projectName = this.currentProject.name;
    this.projectTimeZone = this.currentProject.timeZone;
    this.projectCurrency = this.currentProject.currency;
    if (this.currentProject.autoAssignResources) this.autoAssignResources = this.currentProject.autoAssignResources;

    this.projectPermission = ProjectService.userPermission;
    this.accountPermission = AccountService.userAccountPermission;

    this.utilsService.getTimeZones().pipe(takeUntil(this.destroyed$)).subscribe(timeZones => {
      this.timeZoneDropdownItems = this.buildTimeZoneDropdownItems(timeZones);
    });

    this.currencyDropdownItems = this.buildCurrencyDropdownItems(this.projectService.getCurrencyCodes());
    this.filteredCurrencyDropdownItems = this.currencyDropdownItems;

    // set project start data
    this.setProjectStartDate();

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.menuItemSelected = Number(params['menuItem']) || 0;
      if (this.menuItemSelected === 0) this.segmentService.track('Project Settings Loaded', { projectId: this.projectId });
    });
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    this.projectService.checkToHideSidebar(this.projectService.showProjectSidebar);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async setPageDisplayToggles() {
    this.subContentIsLoading = false;
    this.pageIsLoading = false;
  }

  buildTimeZoneDropdownItems(timezones: string[]): IDropdownItem[] {
    return timezones.map(timezone => ({ value: timezone, label: timezone }));
  }

  buildCurrencyDropdownItems(currencies): IDropdownItem[] {
    return currencies.map(currency => ({ value: currency.code, label: currency.name }));
  }

  setProjectStartDate(): void {
    const startDate = Utils.fromUtcDate(this.currentProject.startDate);
    this.projectStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  }

  timeZoneSelected(timeZone: string) {
    this.currentProject.timeZone = timeZone[0];
  }

  updateProjectInformation(): void {
    if (!this.validateProjectInput()) return;
    this.currentProject.startDate = Utils.toUtcDate(this.projectStartDate);
    this.currentProject.name = this.projectName;
    this.currentProject.timeZone = this.projectTimeZone;
    this.currentProject.address = this.projectAddress;
    this.currentProject.autoAssignResources = this.autoAssignResources;
    this.currentProject.planningLeadTime = Math.floor(this.currentProject.planningLeadTime);
    this.projectService.updateProject(this.currentProject).pipe(takeUntil(this.destroyed$)).subscribe(
      (_success) => {
        this.projectService.currentProjectHeader.title = { value: this.currentProject.name, editable: false };
        this.projectService.currentProjectHeader.address = this.currentProject.address;
        this.projectService.currencyCode = this.currentProject.currency;
        MenuEmitterService.emitProjectChange(this.currentProject);
        this.segmentService.track('Project Updated', { projectId: this.projectId });
        this.notificationService.success('SUCCESSFUL_SAVE', null);
      },
      (error) => {
        const context: INoficationContext = {
          type: 'project information',
          action: 'update'
        };
        this.notificationService.error(error, context);
      });
  }

  exportButtonClick() {
    this.showExportModal = true;
  }

  exportModal(password) {
    this.showExportModal = false;
    this.exporting = true;
    this.projectService.export(this.projectId, password).pipe(takeUntil(this.destroyed$)).subscribe(
      blob => {
        this.exporting = false;
        FileSaver.saveAs(blob, this.currentProject.name + '.grit');
      });
  }

  resetButtonClick() {
    this.confirmationBoxSetup = {
      message: 'are_you_sure',
      action: 'reset'
    };
    this.showConfirmationBox = true;
  }

  resetStepsButtonClick() {
    this.confirmationBoxSetup = {
      message: 'are_you_sure',
      action: 'resetSteps'
    };
    this.showConfirmationBox = true;
  }

  deleteButtonClick() {
    this.confirmationBoxSetup = {
      message: 'are_you_sure',
      action: 'delete'
    };
    this.showConfirmationBox = true;
  }
  closeConfirmation() {
    this.showConfirmationBox = false;
  }

  resetProject(): void {
    if (!this.resetButtonDisabled) {
      this.closeConfirmation();
      this.projectService.setDisablePage(true);
      this.resetButtonDisabled = true;
      this.projectService.reset(this.currentProject.id).pipe(takeUntil(this.destroyed$)).subscribe(
        _result => {
          this.segmentService.track('Project Reset', { projectId: this.projectId });
          this.projectService.setDisablePage(false);
          this.notificationService.eventPopup(EventStatus.Success, 'Project has been reset.');
        },
        (error) => {
          this.resetButtonDisabled = false;
          this.projectService.setDisablePage(false);
          const context: INoficationContext = {
            type: 'project',
            action: 'reset'
          };
          this.notificationService.error(error, context);
        });
    }
  }

  resetSteps(): void {
    if (!this.resetStepsButtonDisabled) {
      this.closeConfirmation();
      this.projectService.setDisablePage(true);
      this.resetStepsButtonDisabled = true;
      this.projectService.resetSteps(this.currentProject.id).pipe(takeUntil(this.destroyed$)).subscribe(
        _result => {
          this.segmentService.track('Project Steps Reset', { projectId: this.projectId });
          this.projectService.setDisablePage(false);
          this.resetStepsButtonDisabled = false;
          this.notificationService.eventPopup(EventStatus.Success, 'Tasks have been deleted.');
        },
        (error) => {
          this.resetStepsButtonDisabled = false;
          this.projectService.setDisablePage(false);
          const context: INoficationContext = {
            type: 'project',
            action: 'reset steps'
          };
          this.notificationService.error(error, context);
        });
    }
  }

  deleteProject(): void {
    if (!this.deleteButtonDisabled) {
      this.projectService.setDisablePage(true);
      this.deleteButtonDisabled = true;
      this.projectService.deleteProject(this.currentProject.id).pipe(takeUntil(this.destroyed$)).subscribe(
        _result => {
          this.projectService.setDisablePage(false);
          this.segmentService.track('Project Deleted', { projectId: this.projectId });
          MenuEmitterService.emitProjectArchive(this.currentProject.id);
          this.router.navigateByUrl('/home');
        },
        (error) => {
          this.deleteButtonDisabled = false;
          this.projectService.setDisablePage(false);
          const context: INoficationContext = {
            type: 'project',
            action: 'delete'
          };
          this.notificationService.error(error, context);
        });
    }
  }

  validateProjectInput() {
    return this.projectNameValid = this.projectName && this.projectTimeZone && this.projectStartDate ? true : false;
  }

  changeEvent(event) {
    this.autoAssignResources = event.checked;
  }

  setMenuItem(selected: number): void {
    this.subContentIsLoading = true;
    this.menuItemSelected = selected;
    this.router.navigateByUrl('/project/' + this.projectId + '/settings?menuItem=' + selected);
    this.subContentIsLoading = false;
  }

  dateSet(event: any) {
    this.projectStartDate = event.value;
  }

  procoreProjectSelected(projectId: string) {
    this.currentProject.procoreProjectId = projectId;
  }

  clearTimeZoneInput() {
    const timeZoneValue = this.timeZoneDropdownItems.find(timeZone => timeZone.label.toLowerCase() === this.projectTimeZone.toLowerCase());
    if (!timeZoneValue) {
      if (this.filteredTimeZoneDropdownItems.length > 0) {
        this.projectTimeZone = this.filteredTimeZoneDropdownItems[0].value;
      } else {
        this.projectTimeZone = '';
      }
      this.filteredTimeZoneDropdownItems = this.timeZoneDropdownItems;
    } else {
      this.projectTimeZone = timeZoneValue.value;
    }
  }

  filterTimeZones() {
    if (this.projectTimeZone !== '') {
      this.filteredTimeZoneDropdownItems = this.timeZoneDropdownItems.filter(timeZone => timeZone.label.toLowerCase().includes(this.projectTimeZone.toLowerCase()));
    } else {
      this.filteredTimeZoneDropdownItems = this.timeZoneDropdownItems;
    }
  }

  currencyAutoSelect(event) {
    setTimeout(() => {
      const text = event.target.value;
      const currencyValue = this.currencyDropdownItems.find(curreucy => curreucy.value.toLowerCase() === text.toLowerCase());
      if (!currencyValue) return this.projectCurrency = '';
      const currency = this.projectService.getCurrencyCodes(currencyValue.value);
      this.currentProject.currency = currency.code;
    },         100);
  }

  filterCurrencies() {
    if (this.projectCurrency !== '') {
      this.filteredCurrencyDropdownItems = this.currencyDropdownItems.filter(currency => currency.label.toLowerCase().includes(this.projectCurrency.toLowerCase()));
    } else {
      this.filteredCurrencyDropdownItems = this.currencyDropdownItems;
    }
  }

  updateMenu() {
    this.settingsMenu = [];
    this.setupMenu();
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.menuItemSelected === 0 && this.projectPermission.gc && this.projectPermission.edit && this.projectNameValid) {
        this.updateProjectInformation();
      }
    }
  }
}
