import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActivityService } from '../../services/activity/activity.service';
import { ChartService } from '../../services/chart/chart.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';

import { IXaxisChartData } from '../../models/chart/chart.interface';
import { IProjectMaterial } from '../../models/project/project-material/project-material.interface';
import { IProjectScheduleMilestone } from '../../models/project/project-milestone/project-milestone.interface';
import { ILocalScheduleData, IProjectScheduleStep, IScheduleSelectionOutput } from '../../models/project/project-schedule/project-schedule.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';

import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { ColorMode, ViewMode, ViewerMode } from '../../utils/enums/shared.enum';
import { Utils } from '../../utils/utils';
import { ActivityColorMap } from '../../models/notification/notification.interface';
import { forEach } from '@angular/router/src/utils/collection';

import * as D3 from 'd3';
import * as moment from 'moment';
import { ProjectService } from '../../services/project/project.service';
import { PDFViewerService } from '../../services/pdf/pdfViewer.service';

@Component({
  selector: 'app-project-schedule-chart',
  templateUrl: './project-schedule-chart.component.html',
  styleUrls: ['./project-schedule-chart.component.scss']
})
export class ProjectScheduleChartComponent implements OnChanges, OnDestroy, OnInit {
  @Input() subContractorsInput: IProjectSubContractor[];
  @Input() xAxisDataInput: IXaxisChartData[] = [];
  @Input() colorByInput: ColorMode;
  @Input() loadedStepId: string;

  @Output() chartSelectionOutput: EventEmitter<IScheduleSelectionOutput> = new EventEmitter();
  @Output() daySelectionOutput: EventEmitter<number[]> = new EventEmitter();
  @Output() dayMaterialsOutput: EventEmitter<IProjectMaterial[]> = new EventEmitter();
  @Output() dayMilestonesOutput: EventEmitter<IProjectScheduleMilestone[]> = new EventEmitter();

  
  @Output() resetchartSelectionOutput = new EventEmitter();

  @ViewChild('chartContainer') chartContainer;
  @ViewChild('chartBarsContainer') chartBarsContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('yAxis') yAxis;
  @ViewChild('gridContainer') gridContainer;
  @ViewChild('outlineContainer') outlineContainer;
  @ViewChild('milestones') milestones;
  @ViewChild('materials') materials;

  scheduleDataAvailable: boolean = false;
  chartContainerHeight: number;
  chartContainerWidth: number;
  gridContainerHeight: number;
  gridContainerWidth: number;
  visibleSchedule: ILocalScheduleData = {};
  stepsInRange: IProjectScheduleStep[] = [];
  stepsBySubcontractors: {[key: string]: IProjectScheduleStep[]};
  extendedChartView: boolean = false; // if range is more than a week
  chartOffsetLeft: number = 210;
  chartOffsetTop: number = 50;
  gridOffsetTransform: string;
  yAxisTransform: string;
  xAxisTransform: string;
  minRowHeight: number = 20;
  minColumnWidth: number = 35;
  barPadding: number = 4;
  colWidth: number;
  rows: any[] = [];
  gridData: any[] = [];
  barData: any[] = [];
  dayInterval: number = 1000 * 60 * 60 * 24;
  visibleDays: number[] = [];
  visibleSubcontractorIds: string[] = [];
  currentStepSelections: IProjectScheduleStep[] = []; // individual task selections by bar, cell, row or column
  currentCellSelections: any[] = []; // cell selections
  currentDaySelections: number[] = [];
  shiftKeyPressed: boolean = false;
  private allD3Bars: D3.selection<SVGElement>;
  showPastObjectsInSelection: boolean = true;

  // scales
  categoryScales: D3.ScaleBand<any> = {};
  xScaleTime: D3.scaleUtc<any> = null;
  colorScale: D3.scaleQuantize<any> = null;

  // subscriptions
  daySelectionSubscription: Subscription;

  constructor(
    private projectScheduleService: ProjectScheduleService,
    private chartService: ChartService,
    private activityService: ActivityService,
    private projectService: ProjectService,
    private pdfViewerService: PDFViewerService
  ) {}

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    window.addEventListener('keyup', this.keyRelease);

    this.visibleSubcontractorIds = this.subContractorsInput.map(subcontractor => subcontractor.id);

    this.daySelectionSubscription = this.projectScheduleService.daySelectionSubject.subscribe( // TODO change to input?
      res => {
        this.currentDaySelections = res ? res : [];
        this.handleColumnSelection(this.currentDaySelections[0], true);
      }
    );
  }

  ngOnChanges() {
    this.setupChart();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    window.removeEventListener('keyup', this.keyRelease);

    if (this.daySelectionSubscription) {
      this.daySelectionSubscription.unsubscribe();
    }
  }

  keyPress = (event) => {
    if (event.key === 'Shift') {
      this.shiftKeyPressed = true;
    }
  }

  keyRelease = (event) => {
    if (event.key === 'Shift') {
      this.shiftKeyPressed = false;
    }
  }

  async setupChart() {
    const subDataAvailable = this.subContractorsInput && this.subContractorsInput.length > 0;
    const xAxisDataAvailable = this.xAxisDataInput && this.xAxisDataInput.length > 0;
    if (subDataAvailable && xAxisDataAvailable) {
      this.extendedChartView = this.xAxisDataInput.length > 7; // extended view is anything more than a week
      this.setVisibleSchedule();
      await this.drawChart(false);
    } else {
      this.resetAllSelections();
      this.scheduleDataAvailable = false;
    }
  }

  async drawChart(isResize: boolean) {
    if (!isResize) this.calculateRows();
    this.calculateChartDimensions();
    await this.createScales();
    this.drawGrid();
    this.drawBars();
    this.drawYaxis();
    this.drawXaxis();
    this.drawStones();
    this.checkForPreSelectedDays();
  }

  // used when switching ranges with the day by day control
  checkForPreSelectedDays(): void {
    const preSelectedDays = this.xAxisDataInput.filter(item => item.selected);
    if (preSelectedDays.length > 0) {
      this.currentDaySelections = preSelectedDays.map(day => day.day);
      preSelectedDays.forEach(day => {
        this.handleColumnSelection(day.day);
      });
    }
  }

  setVisibleSchedule(): void {
    this.visibleSchedule = {};
    this.stepsInRange = [];
    const localSchedData = this.projectScheduleService.getLocalScheduleData();
    const startEpoch = this.xAxisDataInput[0].day;
    const endEpoch = this.xAxisDataInput[this.xAxisDataInput.length - 1].day;
    const visibleSchedKeys = this.projectScheduleService.getLocalScheduleKeysFromRange(startEpoch, endEpoch);
    if (visibleSchedKeys.length > 0) {
      visibleSchedKeys.forEach(key => {
        if (localSchedData[key]) {
          this.visibleSchedule[key] = localSchedData[key];
          this.stepsInRange.push(...localSchedData[key].steps);
        }
      });
     
      this.stepsBySubcontractors = this.projectScheduleService.sortStepsBySubContractorName(this.stepsInRange, this.subContractorsInput, true);
      this.scheduleDataAvailable = !Utils.objectIsEmpty(this.stepsBySubcontractors);



      // RUSHIKESH 
    
      var activityIdColorMap : ActivityColorMap[] = [];
      Object.keys(this.stepsBySubcontractors).forEach( key => {
        this.stepsBySubcontractors[key].forEach( schedule => {
          var subId = schedule['subInfo'].id;
          var color = schedule['subInfo'].hexCode;
          activityIdColorMap.push({activityId: schedule.activities[0], color: color});
        });

      })
      var uniqueactivityIdColorMap = activityIdColorMap.filter((activityIDMap,index) => {
        return index === activityIdColorMap.findIndex(obj => {
          return JSON.stringify(obj) === JSON.stringify(activityIDMap);
        });
      });
      
      this.resetchartSelectionOutput.emit(uniqueactivityIdColorMap);

      // RUSHIKESH
    } else {
      this.scheduleDataAvailable = false;
    }
  }

  async calculateRows() {
    this.resetAllSelections();

    this.rows = [];

    for (const key in this.stepsBySubcontractors) {
      if (this.stepsBySubcontractors[key]) {
        const steps = this.stepsBySubcontractors[key];
        const dyanmicRowInfo = this.getDynamicRowInfo(steps);
        const subId = steps[0]['subInfo'].id ? steps[0]['subInfo'].id : null;
        const rowScaleIndex = this.colorByInput === ColorMode.Criticality || this.colorByInput === ColorMode.Status
          ? dyanmicRowInfo.scale
          : [0];
        const rowObject = {
          hidden: this.rowFilteredOut(subId),
          height: this.minRowHeight,
          id: subId,
          label: key ? key : '',
          abbrv: steps[0]['subInfo'].abbreviation ? steps[0]['subInfo'].abbreviation : '',
          steps: steps,
          selected: false,
          rowScaleIndex: rowScaleIndex,
          rowType: 'sub',
          subId: subId
        };

        if (!rowObject.hidden) {
          this.rows.push(rowObject);
        }

        steps.forEach(step => {
          if (!this.rows.find(row => row.id === step.activities[0])) {
            const activityRowObject = {
              hidden: this.rowFilteredOut(subId),
              height: this.minRowHeight,
              id: step.activities[0],
              label: this.activityService.getLocalActivity(step.activities[0]).name,
              abbrv: '',
              steps: steps.filter(activityStep => activityStep.activities[0] === step.activities[0]),
              selected: false,
              rowScaleIndex: rowScaleIndex,
              rowType: 'activity',
              subId: subId
            };
            if (!activityRowObject.hidden) {
              this.rows.push(activityRowObject);
            }
          }
        });

        if (!rowObject.hidden) {
          this.rows.push({
            hidden: this.rowFilteredOut(subId),
            height: this.minRowHeight,
            id: '',
            label: '',
            abbrv: '',
            steps: [],
            selected: false,
            rowScaleIndex: 0,
            rowType: 'empty',
            subId: subId
          });
        }
      }
    }

    if (this.rows.length > 0) {
      // the row scaling needs to take place after the rows have been sorted
      this.createRowScales(this.rows);
      this.scheduleDataAvailable = true;
    } else {
      this.scheduleDataAvailable = false;
    }
  }

  rowFilteredOut(subId: string): boolean {
    const activeFilter = this.projectScheduleService.getActiveFilterType();
    const activeFilters = this.projectScheduleService.getActiveFilters();
    
    return (subId && activeFilter === FilterModelOptions.SubContractor && activeFilters.length > 0)
      ? !activeFilters.includes(subId)
      : false;
  }

  // determines whether or not to increase the height of the row, to stack overlapping task times
  // determines the domain of each row's D3 scale
  getDynamicRowInfo(steps: IProjectScheduleStep[]): any {
    let dynamicScale = 0;
    const dynamicRowScale = [dynamicScale];
    let largestRowIncrementer = 1; // largest increment for all days per sub, this will be set as the overall total
    let rowHeightIncrementer = 1; // per sub per day increment, used to compare against ^^^
    let previousStep;
    let previousStepDay;

    const arr = [];
    steps.forEach((step, index) => {
      const stepDay = step.dayStart;
      const sameDay = stepDay === previousStepDay;
      const stepStartTime = step.epochStart ? step.epochStart : null;
      const lastTask = index === steps.length - 1;
      const taskOverlaps = previousStep
        ? moment(stepStartTime).isBetween(previousStep.epochStart - 1, previousStep.epochEnd) // subtract 1 so duplicate start times fall within range
        : false;

      // if tasks overlap on the same day, increment the rowIncrementer and add to domain array for row scale
      if (taskOverlaps && sameDay) {
        rowHeightIncrementer += 1;
        dynamicScale += 1;
        if (!dynamicRowScale.includes(dynamicScale)) {
          dynamicRowScale.push(dynamicScale);
        }
      }

      // if it's a new day or the last task for sub, calc the largest incrementer and reset for the next day
      // do this because we want the overall incrementer to be the largest on any given day
      if (!sameDay || lastTask) {
        if (rowHeightIncrementer > largestRowIncrementer) {
          largestRowIncrementer = rowHeightIncrementer;
        }
        rowHeightIncrementer = 1;
        dynamicScale = 0;
      }

      previousStep = step;
      previousStepDay = previousStep.dayStart;

      if (arr.indexOf(step.activities[0]) < 0) arr.push(step.activities[0]);
    });

    return {
      height: this.minRowHeight * arr.length,
      scale: dynamicRowScale
    };
  }

  createRowScales(rows: any[]): void {
    let rowScaleRangeStart = this.barPadding / 2;

    rows.forEach(row => {
      const rowHeight = row.height;
      const rowScaleIndex = row.rowScaleIndex;
      const rowId = row.id;
      const rowScaleRangeEnd = rowScaleRangeStart + rowHeight;

      this.createRowScale(rowId, rowScaleRangeStart, rowScaleRangeEnd, rowScaleIndex);
      rowScaleRangeStart = rowScaleRangeEnd;
    });
  }

  // creates individual scale for rows, for stacking steps if needed
  createRowScale(rowId: string, rangeStart: number, rangeEnd: number, domain: any[]): void {
    const rowScale = D3.scaleBand()
      .range([rangeStart, rangeEnd])
      .domain(domain);
    this.categoryScales[rowId] = rowScale;
  }

  async calculateChartDimensions() {
    const sbWidth = JSON.parse(localStorage.getItem('showSidebar')) ? 300 : 0;
    const menuWidth = JSON.parse(localStorage.getItem('showMainMenu')) ? 155 : 45;
    // 94% = 99% of window without navigation and yAxisOffset - so right border doesnt get cutt off
    this.gridContainerWidth = ((window.innerWidth - sbWidth - menuWidth) * .94) - this.chartOffsetLeft;

    this.gridContainerHeight = !Utils.isEmpty(this.rows)
      ? this.rows.map(row => row.height).reduce((a, b) => a + b)
      : this.minRowHeight;

    this.gridOffsetTransform = `translate(${this.chartOffsetLeft}, ${this.chartOffsetTop})`;
    this.chartContainerHeight = this.gridContainerHeight + this.chartOffsetTop + 1; // +1 for bottom border
    this.chartContainerWidth = (this.gridContainerWidth + this.chartOffsetLeft);
  }

  async createScales() {
    const endOfLastDay = moment.utc(this.xAxisDataInput[this.xAxisDataInput.length - 1].day).clone().endOf('day');

    // x-axis scale
    this.xScaleTime = D3.scaleUtc()
      .range([0, this.gridContainerWidth])
      .domain([this.xAxisDataInput[0].day, endOfLastDay]);

    // criticality color scale
    this.colorScale = D3.scaleQuantize()
      .domain([0, 1])
      .range(this.chartService.getCriticalityColorPalette().map(color => D3.rgb(color)));
  }

  async drawGrid() {
    let yTransform = 0;
    this.colWidth = this.xScaleTime(this.xAxisDataInput[0].day) - this.xScaleTime(this.xAxisDataInput[0].day - this.dayInterval); // sets cell width to the scaled width of a day
    this.gridData = [];
    this.rows.forEach((row) => {
      this.xAxisDataInput.forEach((xAxisItem, index) => {
        const backgroundColor = index % 2 === 0 ? '#EFF3F4' : 'white';
        const cellId = JSON.stringify(xAxisItem.day) + row.id; // this is used to filter the this.currentCellSelections
        const selectedCellIds = this.currentCellSelections.map(cell => cell.cellId);
        const cellIsSelected = selectedCellIds.includes(cellId);

        const cellData = {
          height: row.height,
          width: this.colWidth,
          columnId: xAxisItem.day,
          endOfDay: moment.utc(xAxisItem.day).clone().endOf('day').valueOf(),
          rowId: row.id,
          steps: row.steps.filter(step => step.dayStart === xAxisItem.day && row.id === step.activities[0]),
          cellId: cellId,
          transform: 'translate(' + this.xScaleTime(xAxisItem.day) + ', ' + (yTransform) + ')',
          yPos: yTransform,
          class: 'grid-cell',
          selected: cellIsSelected,
          milestones: xAxisItem.milestones,
          defaultBackground: row.rowType === 'sub' ? row.steps[0].subInfo.hexCode : backgroundColor,
          cellType: row.rowType === 'sub' ? 'sub' : row.rowType === 'activity' ? 'task' : 'empty',
          subId: row.subId
        };

        this.gridData.push(cellData);
      });
      yTransform += row.height;
    });

    const grid = D3.select(this.gridContainer.nativeElement)
      .attr('width', this.gridContainerWidth)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(this.gridData)
      .enter();

    grid
      .append('rect')
      .attr('height', (d) => d.height)
      .attr('width', (d) =>  d.width)
      .attr('transform', (d) => d.transform)
      .attr('fill', (d) => d.defaultBackground)
      .attr('class', (d) => d.selected && d.cellType === 'task'
          ? 'D3-grid-cell _' + d.rowId + ' _' + d.columnId + ' cell-selected'
          : 'D3-grid-cell _' + d.rowId + ' _' + d.columnId
      )
      .on('click', (d) => this.handleGridCellClick(d));

    const outline = D3.select(this.outlineContainer.nativeElement)
      .selectAll('rect')
      .remove()
      .exit()
      .data([{}])
      .enter();

    outline
      .append('rect')
      .attr('width', this.colWidth * this.xAxisDataInput.length)
      .attr('height', this.minRowHeight * this.rows.length)
      .attr('fill', 'none')
      .attr('stroke', '#14151c')
  }

  handleGridCellClick(cell: any): void {
    if (cell.cellType === 'task') {
      cell.selected = true;
      const selectedColumnId = cell.columnId;
      const selectedRowId = cell.rowId;
      const selectedCell = D3.selectAll('.D3-grid-cell').filter('._' + selectedRowId).filter('._' + selectedColumnId);
      const cellSteps = cell.steps;

      if (selectedCell.classed('cell-selected')) {
        selectedCell.classed('cell-selected', false);

        this.updateCellSelections(selectedCell, 'remove');
        this.filterOutBarSelections(cellSteps, 'remove');

      } else {
        if (!this.shiftKeyPressed) {
          this.resetAllSelections();
        }

        selectedCell.classed('cell-selected', true);
        this.updateCellSelections(selectedCell, 'add');
        this.filterOutBarSelections(cellSteps, 'add');
      }
    } else if (cell.cellType === 'sub') {
      this.handleYaxisLabelClick(this.rows.find(row => row.id === cell.rowId));
    }
  }

  async drawBars() {
    if (this.colorByInput === ColorMode.Subcontractor) {
      this.barData = this.getSubBars();
    } else if (this.colorByInput === ColorMode.Criticality) {
      this.barData = this.getTaskBars(false);
    } else if (this.colorByInput === ColorMode.Status) {
      this.barData = this.getTaskBars(true);
    }
    if (!Utils.isEmpty(this.barData)) {
      const barStroke = this.extendedChartView ? '' : 'white';
      D3.select(this.chartBarsContainer.nativeElement)
        .selectAll('rect')
        .remove() // refresh data each time
        .exit()
        .data(this.barData)
        .enter()
        .append('rect')
        .attr('fill', (d) => d.color)
        .attr('stroke', (d) => d.stroke || barStroke)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('x', (d) =>  d.xCoord + 2) // 2 offsets it from grid border
        .attr('y', (d) => d.yCoord)
        .attr('class', (d) => d.selected
          ? 'D3-chart-bar _' + d.barId + ' _' + d.columnId + ' bar-active'
          : 'D3-chart-bar _' + d.barId + ' _' + d.columnId
        )
        .on('click', (d) => this.handleBarClick(d))
        .on('mouseover', (d) => this.handleBarHover(d, 'mouseover'))
        .on('mouseout', (d) => this.handleBarHover(d, 'mouseout'));
    } else {
      D3.select(this.chartBarsContainer.nativeElement)
        .selectAll('rect')
        .remove() // refresh data each time
        .exit();
    }

    this.allD3Bars = D3.selectAll('.D3-chart-bar');

    if (this.loadedStepId) {
      this.handleBarClick(this.barData.find(bar => bar.steps.map(step => step.projectStepId).includes(this.loadedStepId)));
    }
  }

  getSubBars(): any[] {
    const subcontractorBars = [];
    
    Object.keys(this.visibleSchedule).forEach(key => {
      if (this.visibleSchedule[key]) {
        const daysInRange = this.visibleSchedule[key].daysInRange;
        if (daysInRange.length > 0) {
          daysInRange.forEach(day => {
            const dayEpoch = day;
            const formattedDate = moment.utc(Number(dayEpoch)).clone().format('M / D / YYYY');
            const startOfDay = moment.utc(dayEpoch).startOf('day');

            for (const sub in this.stepsBySubcontractors) {
              if (this.stepsBySubcontractors[sub]) {
                const steps = this.stepsBySubcontractors[sub];
                const subId = steps[0]['subInfo'].id ? steps[0]['subInfo'].id : null;
                const activeSubcontractor = subId ? this.subContractorsInput.filter(subcontractor => subcontractor.id === subId)[0] : null;
                const barColor = activeSubcontractor ? activeSubcontractor.hexCode : null;
                const barWidth = this.colWidth - 4; // 4 is just a an offset so bar doesn't overlap grid lines
                const barHeight = this.minRowHeight - this.barPadding;
                const todaySteps = !Utils.isEmpty(steps) ? steps.filter(step => step.dayStart === dayEpoch) : [];
                const todayTotalStepDuration = !Utils.isEmpty(todaySteps)
                  ? todaySteps.map(subStep => subStep.durationHours).reduce((a, b) => a + b)
                  : null;
                const selectedBar = D3.selectAll('.bar-active').filter('._' + steps[0].id);
                const stepIsSelected = selectedBar.empty() ? false : true;
                if (todaySteps.length > 0) {
                  const barInfo = {
                    height: barHeight,
                    width: barWidth > 4 ? barWidth : 4,
                    xCoord: this.xScaleTime(startOfDay),
                    yCoord: this.categoryScales[subId](0),
                    hidden: this.projectScheduleService.subIsFilteredOut(activeSubcontractor, todaySteps),
                    subcontractor: activeSubcontractor ? activeSubcontractor.name : '',
                    day: formattedDate ? formattedDate : null,
                    columnId: dayEpoch,
                    rowId: subId,
                    barId: subId + dayEpoch, // used as a unique id
                    totalSteps: todaySteps.length ? todaySteps.length : 'N/A',
                    totalDuration: todayTotalStepDuration ? todayTotalStepDuration.toFixed(2) : 'N/A',
                    steps: todaySteps ? todaySteps : null,
                    color: barColor ? barColor : null,
                    selected: stepIsSelected
                  };

                  if (!barInfo.hidden) subcontractorBars.push(barInfo);
                }
              }
            }
          });

          this.scheduleDataAvailable = true;
        } else {
          this.scheduleDataAvailable = false;
        }
      }
    });

    return subcontractorBars;
  }

  getTaskBars(colorByStatus: boolean): any[] {
    const taskBars = [];
    for (const key in this.stepsBySubcontractors) {
      if (this.stepsBySubcontractors[key]) {
        const steps = this.stepsBySubcontractors[key];
        const subId = steps[0]['subInfo'].id ? steps[0]['subInfo'].id : null;
        const startTimes = [];

        if (!Utils.isEmpty(steps)) {
          const acts = [];
          steps.map(x => { if (acts.indexOf(x.activities[0]) < 0) acts.push(x.activities[0]); });
          // tslint:disable-next-line:cyclomatic-complexity
          steps.forEach(step => {
            const dayEpoch = step.dayStart ? step.dayStart : null;
            const formattedDate = dayEpoch ? moment.utc(dayEpoch).clone().format('M / D / YYYY') : null;
            const activeSubcontractor = subId ? this.subContractorsInput.filter(subcontractor => subcontractor.id === subId)[0] : null;
            const stepStartTime = step.epochStart ? step.epochStart : null;
            const stepEndTime = step.epochEnd ? step.epochEnd : null;
            let crit = Math.max(0, step.criticality);
            if (crit === 0) {
              const sSteps = steps.filter(s => s.id === step.id);
              if (sSteps.length  > 0) {
                const sCrit = sSteps.find(s => s.criticality > 0);
                if (sCrit) crit = sCrit.criticality;
              }
            }
            crit = crit * 100;
            const formattedCriticality = (crit).toFixed(2) + '%';
            const barWidth = (this.xScaleTime(stepEndTime) - this.xScaleTime(stepStartTime)) - 4;
            const barHeight = this.minRowHeight - this.barPadding;
            let barColor;
            if (!colorByStatus) {
              barColor = this.colorByCriticality(crit);
            } else {
              barColor = this.colorByStatus(step.stepStatus);
            }
            const selectedBar = D3.selectAll('.bar-active').filter('._' + step.id);
            const stepIsSelected = selectedBar.empty() ? false : true;
            const totalDuration = step.totalDurationHours ? step.totalDurationHours.toFixed(2) : null;
            const taskDuration = step.durationHours ? step.durationHours.toFixed(2) : null;
            const actualStartDate = step.started ? step.started : null;
            const actualFinishDate = step.approved ? step.approved : (step.completed? step.completed : null);
            const started = step['startDate'] ? step['startDate'] : null;
            const durationDays = step.totalDurationHours ? Utils.convertHoursToDays(step.totalDurationHours) : null;
            const ended =Utils.getEndDateExcludingWeekends(started, durationDays);
            const barInfo = {
              height: barHeight,
              width: barWidth > 4 ? barWidth : 4,
              xCoord: this.xScaleTime(stepStartTime),
              yCoord: this.minRowHeight * this.rows.indexOf(this.rows.find(row => row.id === step.activities[0])) + this.barPadding / 2,
              subcontractor: activeSubcontractor ? activeSubcontractor.name : '',
              stepName: step.name ? step.name : 'Unnamed',
              hidden: this.projectScheduleService.taskIsFilterOut(step),
              activityTypes: !Utils.isEmpty(step.activities) ? step.activities : null,
              criticality: formattedCriticality,
              day: formattedDate ? formattedDate : null,
              color: barColor,
              stroke: null,
              name: (step.criticality === -1 ? 'Lag: ' : '') + (step.name ? step.name : 'Unnamed'),
              crewSize: step.crewSize ? step.crewSize : null,
              columnId: dayEpoch,
              barId: step.id ? step.id : null,
              rowId: subId,
              steps: [step],
              totalDuration: totalDuration,
              taskDuration: taskDuration,
              selected: stepIsSelected,
              started: started,
              ended: ended,
              actualStartDate : actualStartDate,
              actualFinishDate: actualFinishDate,
              pdfIds: this.projectService.getPDFNameForURL(this.pdfViewerService.getPDFIdForActivity(step.activities[0])),
            };

            startTimes.push(stepStartTime);
            if (!barInfo.hidden) taskBars.push(barInfo);
          });
        }
      }
    }
    
    return taskBars;
  }

  colorByStatus(status: number): string {
    switch (status) {
      case 1:
      return this.chartService.getStatusColorPallette()[0];
      case 2:
      return this.chartService.getStatusColorPallette()[1];
      case 3:
      return this.chartService.getStatusColorPallette()[2];
      case 4:
      return this.chartService.getStatusColorPallette()[3];
    }
  }

  colorByCriticality(criticality: number): string {
      if (criticality < 21) return this.chartService.getCriticalityColorPalette()[0];
      if (criticality < 41) return this.chartService.getCriticalityColorPalette()[1];
      if (criticality < 61) return this.chartService.getCriticalityColorPalette()[2];
      if (criticality < 81) return this.chartService.getCriticalityColorPalette()[3];
      return this.chartService.getCriticalityColorPalette()[4];
  }

  selectBar(stepId: any) {
    const bar = this.barData.find(b => b.barId === stepId);
    this.handleBarClick(bar);
  }

  handleBarClick(bar: any): void {
    this.showPastObjectsInSelection = false;
    bar.selected = true;
    const barId = bar.barId;
    const selectedBar = D3.selectAll('.D3-chart-bar').filter('._' + barId);
    const selectedStep = bar.steps;

    if (selectedBar.classed('bar-active')) {
      this.updateStepSelections(selectedStep, 'remove');
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) this.resetAllSelections();
      this.updateStepSelections(selectedStep, 'add');
    }
  }

  handleBarHover(barData: any, action: string): void {
    const selectedStep = barData.steps;

    if ( action === 'mouseover') {
      const ttType = this.colorByInput === ColorMode.Subcontractor ? ColorMode.Subcontractor : ColorMode.Criticality;
      const ttHeight = ttType === ColorMode.Subcontractor ? 125 : 175;
      const ttWidth = 400;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(barData, ttType));

      this.emitChartSelectionOutput(selectedStep, 'hover');
    } else if ( action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');

      this.emitChartSelectionOutput(selectedStep, 'hoverOut');
    }
  }

  drawStones() {
    const daysWithData = (this.rows.length > 0 && this.xAxisDataInput.length > 0)
      ? this.xAxisDataInput.filter(item => item.materials || item.milestones)
      : [];
    const smtData = [];
    const msData = [];

    daysWithData.forEach(dayOfStones => {
      let stoneAdjustment = 0; // puts stones on top of each other if multiple stones on same day
      if (!Utils.isEmptyList(dayOfStones.materials)) {
        // Add material stone
        stoneAdjustment -= 10;
        const actual = dayOfStones.materials[0].actual;

        const smtObject = {
          xAxis: this.xScaleTime(actual),
          yAxis: -6,
          radius: 4,
          color: 'black',
          stoneData: dayOfStones.materials
        };
        smtData.push(smtObject);
      }

      if (!Utils.isEmptyList(dayOfStones.milestones)) {
        // Add milestone stone
        stoneAdjustment -= 10;
        const actual = dayOfStones.milestones[0].actual;

        const msObject = {
          bHeight: this.gridContainerHeight,
          bWidth: 4,
          color: !Utils.isEmpty(dayOfStones.milestones.find(ms => ms.behindSchedule)) ? 'red' : 'black',
          bTransform: 'translate(' + (this.xScaleTime(actual) - 2) + ', 0)',
          dHeight: 6,
          dWidth: 6,
          dTransform: 'translate(' + (this.xScaleTime(actual) - 3) + ', ' + stoneAdjustment + ') rotate(45 3 3)',
          stoneData: dayOfStones.milestones
        };
        msData.push(msObject);
      }
    });

    const msStoneContainer = D3.select(this.milestones.nativeElement)
      .selectAll('rect')
      .remove() // refresh data each time
      .exit()
      .data(msData)
      .enter();

    msStoneContainer
      .append('rect')
      .attr('fill', (d) => d.color)
      .attr('class', 'D3-milestone-line-marker')
      .attr('height', (d) => d.bHeight)
      .attr('width', (d) =>  d.bWidth)
      .attr('transform', (d) => d.bTransform);

    msStoneContainer
      .append('rect')
      .attr('fill', (d) => d.color)
      .attr('class', 'D3-milestone-diamond-marker')
      .attr('style', 'cursor: pointer')
      .attr('height', (d) => d.dHeight)
      .attr('width', (d) =>  d.dWidth)
      .attr('transform', (d) => d.dTransform)
      .on('click', (d) => this.emitMilestones(d.stoneData))
      .on('mouseover', (d) => this.handleStoneHover(d.stoneData, 'mouseover', 'milestone'))
      .on('mouseout', (d) => this.handleStoneHover(d.stoneData, 'mouseout', 'milestone'));

    const smtStoneContainer = D3.select(this.materials.nativeElement)
      .selectAll('circle')
      .remove() // refresh data each time
      .exit()
      .data(smtData)
      .enter();

    smtStoneContainer
      .append('circle')
      .attr('fill', (d) => d.color)
      .attr('class', 'D3-material-circle-marker')
      .attr('style', 'cursor: pointer')
      .attr('r', (d) => d.radius)
      .attr('cx', (d) =>  d.xAxis)
      .attr('cy', (d) =>  d.yAxis)
      .on('click', (d) => this.emitMaterials(d.stoneData))
      .on('mouseover', (d) => this.handleStoneHover(d.stoneData, 'mouseover', 'material'))
      .on('mouseout', (d) => this.handleStoneHover(d.stoneData, 'mouseout', 'material'));
  }

  handleStoneHover(stoneData: any, action: string, type: string): void {
    const oneLine = 35;
    if ( action === 'mouseover') {
      const ttHeight = 40 + ((stoneData.length - 1) * oneLine);
      const ttWidth = 150;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(stoneData, type));
    } else if ( action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  // tslint:disable-next-line:cyclomatic-complexity
  drawTooltip(data: any, type: ColorMode | string): string {
    let tooltip;
    switch (type) {
      case ColorMode.Criticality :
        tooltip = ``;
        const singleTaskDuration = data.taskDuration && data.totalDuration && (data.taskDuration === data.totalDuration);
        if (data.steps && data.steps[0].externalId) tooltip += `<div class='data-point'><span class='label'>Activity Id: </span><span class='value'>` + data.steps[0].externalId + `</span></div>`;
        if (data.name) tooltip += `<div class='data-point'><span class='label'>Name: </span><span class='value'>` + data.name + `</span></div>`;
        if (data.subcontractor) tooltip += `<div class='data-point'><span class='label'>Subcontractor: </span><span class='value'>` + data.subcontractor + `</span></div>`;
        // if (data.activityTypes) {
        //   data.activityTypes = Utils.deDupeArray(data.activityTypes);
        //   const tcNames = this.activityService.getSpecificActivities(data.activityTypes).map(tc => {if (tc.name) return tc.name; });
        //   tcNames.forEach(tName => {
        //     tooltip += `<div class='data-point'><span class='label'>Activities: </span><span class='value'>` + tName + `</span></div>`;
        //   });
        // }
        if (!Utils.isEmpty(data.steps[0].scheduledStart)) tooltip += `<div class='data-point'><span class='label'>Start Date: </span><span class='value'>` + Utils.formatDate(data.steps[0].scheduledStart) + `</span></div>`;
        if (!Utils.isEmpty(data.steps[0].scheduledEnd)) tooltip += `<div class='data-point'><span class='label'>End Date: </span><span class='value'>` + Utils.formatDate(data.steps[0].scheduledEnd) + `</span></div>`;
        tooltip += `<div class='data-point'><span class='label'>Actual Start Date: </span><span class='value'>` +( !Utils.isEmpty(data.actualStartDate) ? Utils.formatDate(data.actualStartDate): '-') + `</span></div>`;
        tooltip += `<div class='data-point'><span class='label'>Actual Finish Date: </span><span class='value'>`+ (!Utils.isEmpty(data.actualFinishDate) ?  Utils.formatDate(data.actualFinishDate): '-')  + `</span></div>`;
        // tooltip += `<div class='data-point'><span class='label'>Actual Start Date: </span><span class='value'>` + data.actualStartDate ? Utils.formatDate(data.actualStartDate): '-' + `</span></div>`;
        // tooltip += `<div class='data-point'><span class='label'>Actual Finish Date: </span><span class='value'>` +data.actualFinishDate ?  Utils.formatDate(data.actualFinishDate) : '-' + `</span></div>`;
        if (singleTaskDuration) tooltip += `<div class='data-point'><span class='label'>Duration: </span><span class='value'>` + data.totalDuration + ` hrs</span></div>`;
        if (!singleTaskDuration && data.totalDuration) tooltip += `<div class='data-point'><span class='label'>Total Duration: </span><span class='value'>` + Math.round( data.totalDuration/8 * 10 ) / 10 + ` days</span></div>`;
        if (data.criticality) tooltip += `<div class='data-point'><span class='label'>Criticality: </span><span class='value'>` + data.criticality + `</span></div>`;
        if (data.crewSize) tooltip += `<div class='data-point'><span class='label'>Crew: </span><span class='value'>` + data.crewSize + `</span></div>`;
        break;

      case ColorMode.Subcontractor :
        tooltip = ``;
        if (data.day) tooltip += `<div class='data-point'><span class='label'>Date: </span><span class='value'>` + data.day + `</span></div>`;
        if (data.subcontractor) tooltip += `<div class='data-point'> <span class='label'>Subcontactor: </span><span class='value'>` + data.subcontractor + `</span></div>`;
        if (data.totalSteps) tooltip += `<div class='data-point'> <span class='label'>Total Activities: </span><span class='value'>` + data.totalSteps + `</span></div>`;
        if (data.totalDuration) tooltip += `<div class='data-point'> <span class='label'>Total Duration: </span><span class='value'>` + data.totalDuration + ` hrs</span></div>`;
        break;

      case 'material' :
        tooltip = ``;
        if (!Utils.isEmptyList(data) && data.length > 1) {
          tooltip += `<div class='data-point'><span class='label'>` + data.length + ` Materials</span></div>`;
          tooltip += `<div class='data-point'><span class='value'>` + data.map(d => d.name).join('<br>') + `</span></div>`;
        } else {
          tooltip += `<div class='data-point'><span class='label'>Materials: </span><span class='value'>` + data[0].name + `</span></div>`;
        }
        break;

      case 'milestone' :
        tooltip = ``;
        if (!Utils.isEmptyList(data) && data.length > 1) {
          tooltip += `<div class='data-point'><span class='label'>` + data.length + ` Milestones</span></div>`;
          tooltip += `<div class='data-point'><span class='value'>` + data.map(d => d.name).join('<br>') + `</span></div>`;
        } else {
          tooltip += `<div class='data-point'><span class='label'>Milestone: </span><span class='value'>` + data[0].name + `</span></div>`;
        }
        // if (data.name) tooltip += `<div class='data-point'><span class='label'>Name: </span><span class='value'>` + data.name + `</span></div>`;
        // if (data.baseline) tooltip += `<div class='data-point'><span class='label'>Baseline: </span><span class='value'>` + moment.utc(data.baseline).clone().format('MMM DD') + `</span></div>`;
        // if (data.actual) tooltip += `<div class='data-point'><span class='label'>Actual: </span><span class='value'>` + moment.utc(data.actual).clone().format('MMM DD') + `</span></div>`;
        // if (data.status) tooltip += `<div class='data-point'><span class='label'>Status: </span><span class='value'>` + data.status + `</span></div>`;
        break;

      case 'yLabel' :
        tooltip = ``;
        if (data.label) tooltip += `<div class='data-point'><span class='value'>` + data.label + `</span> </div>`;
        break;
    }

    return tooltip;
  }

  async drawXaxis() {
    const xAxisData = this.rows.length > 0 ? this.xAxisDataInput : []; // if there are no rows, clear x-axis data
    this.xAxisTransform = `translate(${this.chartOffsetLeft}, ${this.chartOffsetTop - 10})`;
    const xAxisLabel = D3.select(this.xAxis.nativeElement)
      .attr('class', 'x-axis-labels')
      .attr('transform', this.xAxisTransform)
      .selectAll('g')
      .remove() // refresh data each time
      .exit()
      .data(xAxisData)
      .enter()
      .append('g')
      .attr('transform', (d) => 'translate(' + this.xScaleTime(d.day) + ', 0)');

    xAxisLabel
      .append('text')
      .text((d) => Utils.formatDateToDayAndMonthString(d.day))
      .attr('class', (d) => {
        let c = this.currentDaySelections.includes(d.day)
          ? 'x-axis-label _' + moment(d.day).clone().utc().valueOf() + ' label-selected'
          : 'x-axis-label _' + moment(d.day).clone().utc().valueOf();
        if (xAxisData.length > 21) c = c + ' vertical';
        return c;
      })
      .attr('x', xAxisData.length > 21 ? (this.colWidth / 2) : xAxisData.length > 7 ? (this.colWidth / 2) - 10 : (this.colWidth / 2) - 20)
      .attr('y', xAxisData.length > 21 ? -23 : 0)
      .on('click', (d) => this.handleColumnSelection(d.day));

    // removes extra line from axis
    D3.select(this.xAxis.nativeElement)
      .select('path')
      .remove();

    if (!this.extendedChartView) {
      await this.drawWeatherInfo(xAxisLabel);
    }
  }

  async drawWeatherInfo(label: any) {
    const weatherLabel = label
      .filter((d) => d.weather)
      .append('foreignObject')
      .append('xhtml:div')
      .attr('class', 'weather-info')
      .style('width', this.colWidth + 'px');

    weatherLabel
      .append('xhtml:i')
      .attr('class', (d) => 'fa ' + d.weather.icon);

    weatherLabel
      .append('xhtml:span')
      .attr('class', 'weather-info-label')
      .html((d) => d.weather.highTemp + '&deg; / ' + d.weather.lowTemp + '&deg;');
  }

  handleColumnSelection(label: number, deselectOthers?: boolean): void {
    if (label) {
      const selectedColumnId = moment.utc(label).clone().startOf('day').valueOf();
      const selectedLabel = D3.selectAll('.x-axis-label').filter('._' + selectedColumnId.toString());
      const labelInView = !selectedLabel.empty();
      const columnCells = D3.selectAll('.D3-grid-cell').filter(d => d.columnId === selectedColumnId && d.cellType !== 'sub');
      const columnSteps = this.stepsInRange.filter(step => step.dayStart === selectedColumnId);

      if (labelInView) {
        if (selectedLabel.classed('label-selected')) {
          if (deselectOthers) {
            this.resetAllSelections();
          }

          columnCells.classed('cell-selected', false); // remove single cell selections
          columnCells.classed('cell-selected-x', false); // remove selection from x-axis specifically

          this.currentDaySelections = this.currentDaySelections.filter(columnId => columnId !== selectedColumnId);

          this.updateCellSelections(columnCells, 'remove');
          this.filterOutBarSelections(columnSteps, 'remove');
        } else {
          if (!this.shiftKeyPressed || deselectOthers) {
            this.resetAllSelections();
          }

          columnCells.classed('cell-selected-x', true); // add selection from x-axis specifically

          this.currentDaySelections.push(selectedColumnId);
          this.currentDaySelections = Utils.deDupeArray(this.currentDaySelections); // potential for duplicate with day control

          this.updateCellSelections(columnCells, 'add');
          this.filterOutBarSelections(columnSteps, 'add');
        }

        // set label state
        this.setLabelState(selectedLabel);
      }

      this.emitDaySelections(this.currentDaySelections);
    } else {
      this.resetAllSelections();
    }
  }

  async drawYaxis() {
    this.yAxisTransform = `translate(${(this.chartOffsetLeft - 8)} , ${this.chartOffsetTop})`;
    if (!Utils.isEmpty(this.rows)) {
      // have to manually create y-axis labels because of grouping...probably a better way to do this
      D3.select(this.yAxis.nativeElement)
        .attr('class', 'y-axis-labels')
        .attr('transform', this.yAxisTransform)
        .selectAll('g')
        .remove() // refresh data each time
        .exit()
        .data(this.rows)
        .enter()
        .append('g')
        .append('text')
        .text((d) => d.label.substring(0, 30))
        .attr('text-anchor', 'end')
        .attr('y', (d, index) => index * this.minRowHeight + 14)
        .attr('class' , (d) => {
          let style = d.selected
          ? 'y-axis-label _' + d.id + ' label-selected'
          : 'y-axis-label _' + d.id;
          if (d.rowType === 'sub') style = style + ' label-header';
          return style;
        })
        .on('click', (d) => this.handleYaxisLabelClick(d));
        // .on('mouseover', (d) => this.handleYAxisHover(d, 'mouseover'))
        // .on('mouseout', (d) => this.handleYAxisHover(d, 'mouseout'));

      // removes extra line from axis
      D3.select(this.yAxis.nativeElement)
        .select('path')
        .remove();
    } else {
      D3.select(this.yAxis.nativeElement)
        .selectAll('g')
        .remove(); // refresh data each time
    }
  }

  handleYaxisLabelClick(row: any): void {
    row.selected = true;
    const rowId = row.id;
    const selectedLabel = D3.selectAll('.y-axis-label').filter('._' + rowId);
    const rowCells = D3.selectAll('.D3-grid-cell').filter(d => {
      if (row.rowType === 'sub') {
        return d.subId === rowId && d.cellType !== 'sub';
      } else {
        return d.rowId === rowId;
      }
    });
    const rowSteps = row.steps;

    if (selectedLabel.classed('label-selected')) {
      rowCells.classed('cell-selected', false);
      rowCells.classed('cell-selected-y', false);

      this.updateCellSelections(rowCells, 'remove');
      this.filterOutBarSelections(rowSteps, 'remove');
    } else {
      // If we aren't pressing the shift key, we just need to turn everything off
      if (!this.shiftKeyPressed) {
        this.resetAllSelections();
      }

      rowCells.classed('cell-selected', false);
      rowCells.classed('cell-selected-y', true);

      this.updateCellSelections(rowCells, 'add');
      this.filterOutBarSelections(rowSteps, 'add');
    }

    // set label state
    this.setLabelState(selectedLabel);
  }

  handleYAxisHover(row: any, action: string): void {
    if ( action === 'mouseover') {
      const ttHeight = 40;
      const ttWidth = 150;
      const ttPosition = this.projectScheduleService.positionTooltip(D3.event.pageX, D3.event.pageY, ttWidth, ttHeight);

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left)
        .style('top', ttPosition.top)
        .style('height', ttHeight + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(row, 'yLabel'));
    } else if ( action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  setLabelState(label: any) {
    if (label.classed('label-selected')) {
      label.classed('label-selected', false);
    } else {
      label.classed('label-selected', true);
    }
  }

  updateCellSelections(cells: any, action: string): void {
    const cellData = [];
    cells.each((d) => cellData.push(d));
    const selectedCellIds = cellData.map(cell => cell.cellId);

    cellData.forEach(cellObj => {
      if (!selectedCellIds.includes(cellObj.id) && action === 'add') {
        this.currentCellSelections.push(cellObj);
      } else if (action === 'remove') {
        this.currentCellSelections = this.currentCellSelections.filter(selectedCell => selectedCell.cellId !== cellObj.cellId);
      }
    });
  }

  filterOutBarSelections(steps: IProjectScheduleStep[], action: string): void {
    const stepsToUpdate = [];

    if (!Utils.isEmpty(steps)) {
      steps.forEach(step => {
        const selectedBar = D3.selectAll('.bar-active').filter('._' + step.id);
        const stepIsSelected = selectedBar.empty() ? false : true;

        if (!stepIsSelected) {
          stepsToUpdate.push(step);
        }
      });
    }

    this.updateStepSelections(stepsToUpdate, action);
  }

  updateStepSelections(steps: IProjectScheduleStep[], action: string): void {
    const selectedTaskStepIds = this.currentStepSelections.map(step => step.id);

    if (steps.length > 0) {
      steps.forEach(step => {
        const isFilteredOut = this.projectScheduleService.taskIsFilterOut(step);
        if (!selectedTaskStepIds.includes(step.id) && action === 'add' && !isFilteredOut) {
          this.currentStepSelections.push(step);
          if (step.id && step.subContractorId && step.epochStart) this.setActiveBarState(step, action);
        } else if (action === 'remove' && !isFilteredOut) {
          this.currentStepSelections = this.currentStepSelections.filter(selecteStep => selecteStep.id !== step.id);
          if (step.id && step.subContractorId && step.epochStart) this.setActiveBarState(step, action);
        }
      });
    } else {
      this.currentStepSelections = [];
      this.allD3Bars.classed('bar-active', false);
    }

    this.emitChartSelectionOutput(this.currentStepSelections, 'click');
  }

  setActiveBarState(step: IProjectScheduleStep, action: string): void {
    const selectedBar = this.colorByInput === ColorMode.Subcontractor
      ? D3.selectAll('.D3-chart-bar').filter('._' + step.subContractorId + moment.utc(step.epochStart).clone().startOf('day').valueOf())
      : D3.selectAll('.D3-chart-bar').filter('._' + step.id);

    if (selectedBar) {
      if (action === 'add') selectedBar.classed('bar-active', true);
      else selectedBar.classed('bar-active', false);
    }
  }

  emitDaySelections(selectedEpochs: number[]): void {
    this.daySelectionOutput.emit(selectedEpochs);
  }

  emitChartSelectionOutput(selectedSteps: IProjectScheduleStep[], action: string): void {
    const noSelections = this.currentCellSelections.length === 0 && this.currentStepSelections.length === 0;

    if (action && action === 'click') {
      action = noSelections ? 'reset' : action;
      this.chartSelectionOutput.emit({
        action: action,
        steps: selectedSteps,
        showPastSteps: this.showPastObjectsInSelection
      });
    } else if ((action === 'hover' || action === 'hoverOut' || action === null)) {
      this.chartSelectionOutput.emit({
        action: action,
        steps: selectedSteps,
        showPastSteps: false
      });
    }

    this.showPastObjectsInSelection = true; // reset after emission
  }

  async resetAllSelections() {
    this.currentStepSelections = [];
    this.currentDaySelections = [];
    this.currentCellSelections = [];
    this.rows = this.rows.map(row => ({...row, selected: false}));

    D3.selectAll('.x-axis-label').each(function(d) { D3.select(this).classed('label-selected', false); });
    D3.selectAll('.y-axis-label').each(function(d) { D3.select(this).classed('label-selected', false); });
    D3.selectAll('.D3-grid-cell').each(function(d) { D3.select(this).classed('cell-selected', false).classed('cell-selected-y', false).classed('cell-selected-x', false); });
    D3.selectAll('.D3-chart-bar').each(function(d) { D3.select(this).classed('bar-active', false); });
  }

  // Stone data emit
  emitMaterials(materials: IProjectMaterial[]) {
    this.dayMaterialsOutput.emit(materials);
  }

  emitMilestones(milestones: IProjectScheduleMilestone[]) {
    this.dayMilestonesOutput.emit(milestones);
  }
}
