import { Component, Input, OnInit } from '@angular/core';

import { IGritTable, IGRow, IGRowItem } from '../../../shared/grit-table/grit-table';
import { ISprintStoryResult, SprintStoryResultStatus } from '../../../models/project/project-sprint/project-sprint.interface';
import { IUserPermission } from '../../../models/user/user.interface';

import { SprintSettingsService } from '../../../services/project/project-settings/sprint-settings.service';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-reasons-table',
  templateUrl: './project-reasons-table.component.html',
  styleUrls: ['./project-reasons-table.component.scss']
})
export class ProjectReasonsTableComponent implements OnInit {

  @Input() projectPermissionInput: IUserPermission;
  @Input() projectId: string;

  isLoading: boolean = true;
  tableData: IGritTable;

  reasons: ISprintStoryResult[] = [];

  constructor(
    private sprintSettingsService: SprintSettingsService
    ) {/*EMPTY*/}

  ngOnInit() {
    this.getData();
  }

  async getData() {
    const getRes = await this.sprintSettingsService.getSprintReasonsLibrary(this.projectId);
    if (getRes) {
      this.reasons = Utils.sortByString(getRes, 'name');
      this.setTableData();
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  setTableData() {
    this.tableData = this.sprintSettingsService.transformToTableData(this.reasons, this.projectPermissionInput);
  }

  async edit(row: IGRow) {
    const newEdit: ISprintStoryResult = {name: row.rowItems.find(ri => ri.colKey === 'name').value};
    if (await this.sprintSettingsService.editSprintStoryReason(row.key, newEdit)) {
      const reason = this.reasons.find(item => item.id === row.key);
      reason.name = row.rowItems.find(ri => ri.colKey === 'name').value;
    } else {
      this.setTableData();
    }
  }

  async deleteRows(rowIds: string[]) {
    if (await this.sprintSettingsService.deleteSprintStoryReasons(this.projectId, rowIds)) {
      this.reasons = this.reasons.filter(item => !rowIds.includes(item.id));
    } else {
      this.setTableData();
    }
  }

  async create(rowItems: IGRowItem[]) {
    const addSSR: ISprintStoryResult = {name: rowItems.find(ri => ri.colKey === 'name').value, status: SprintStoryResultStatus.INCOMPLETE};
    const res = await this.sprintSettingsService.addSprintStoryReason(this.projectId, addSSR);
    if (res) {
      this.reasons.push(res);
      this.reasons = Utils.sortByString(this.reasons, 'name');
    }
    this.setTableData();
  }
}
