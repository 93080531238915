
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectSprintBoardService } from '../../../services/project/project-sprint-board/project-sprint-board.service';
import { ProjectSprintService } from '../../../services/project/project-sprint/project-sprint.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';

import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { INoficationContext, ActivityColorMap } from '../../../models/notification/notification.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';
import { ISprintBoardItemResponse, ITransformedSprintBoardData, ITransformedSprintBoardItem } from '../../../models/project/project-sprint/project-sprint-board.interface';
import { ICompleteOutput, INotCompleteOutput, IProjectViewModes, IStoryEquipment, IStoryMaterial, IInProgressOutput, IStoryLabor } from '../../../models/project/project-sprint/project-sprint.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../../utils/enums/forge-viewer-type';
import { EventType } from '../../../utils/enums/notification.enum';
import { ColorMode, ViewMode, ViewerMode } from '../../../utils/enums/shared.enum';
import { SprintStoryResult } from '../../../utils/enums/sprint-story-result.enum';
import { StoryStatus } from '../../../utils/enums/story-status.enum';
import { Utils } from '../../../utils/utils';
import { PDFViewerService } from '../../../services/pdf/pdfViewer.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-project-sprint-board',
  templateUrl: './project-sprint-board.component.html',
  styleUrls: ['./project-sprint-board.component.scss', '../../../shared/page-header/page-header.component.scss']
})
export class ProjectSprintBoardComponent implements OnChanges, OnDestroy, OnInit {
  @Input() subContractorList: any[];
  @Input() sprintId: string;
  @Input() planningMode: boolean;
  @Input() changeName: { name: string, projectStepId: string };
  @Input() activeScheduleNameInput: string;
  @Input() ssResults;

  @Output() displayModeOutput: EventEmitter<IProjectViewModes> = new EventEmitter();
  @Output() objectsSelected: EventEmitter<any> = new EventEmitter();
  @Output() sprintClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() commitTasksOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() uncommitTasksOutput: EventEmitter<string[]> = new EventEmitter();

  transformedSprintBoardItems: ITransformedSprintBoardData;
  storyStatusEnum = StoryStatus;
  storyResultEnum = SprintStoryResult;
  showItems: boolean = false;
  taskIsSelected: boolean = false;
  subcontractorFilter: string[] = new Array();
  projectId: string;
  makingBackendCall: boolean = false;

  showSubmittalModal: boolean = false;
  showCompleteStoryModal: boolean = false;
  showNotCompleteStoryModal: boolean = false;
  showApprovedModal: boolean = false;
  showInProgressStoryModal: boolean = false;
  modalType: string = '';
  dateStarted: any = '';
  actualStartDate: number;
  completeStoryModalInput: IConfirmationModalInput = {
    displayMessage: 'Please enter additional task information:',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true
  };
  completedTaskId: string = null;
  inProgressTaskId: string = null;
  actualDuration = null;
  actualCrewSize = null;
  actualEquipmentIds: string[];
  actualMaterialIds: string[];
  actualLaborIds: string[] = [];
  otherReason = null;
  reasonNotCompleted: number = null;
  moveSprintNotCompleted: string = null;
  moreBacklog: boolean = true;
  completeSprintMode: boolean = false;
  curObjectIds: string[] = [];
  subContractorsDropdownListInput: IDropdownItem[] = new Array();
  sprintName: string;
  defaultSprintSelected: boolean = false;
  displaySprintModes: IProjectViewModes[];
  moveToOptions = [];
  placeholderSubcontractors = 'Task board filter';
  myViewerState = {};
  controls: IProjectScheduleControls;
  submittalSelection: { taskName: string, submittals: Array<{ material: string, url: string }> };
  allActivitites : any;

  inProgressModalObj: IInProgressOutput = {startDate : null, expectedFinishDate: null, duration: 0};
  // Subscriptions
  boardDataSubscription: Subscription;
  dragulaSubscription: Subscription;
  activityColorIdMap : ActivityColorMap[] = [];

  //View Related Properties
  viewListWithIds = [];
  addViewForm: FormGroup;
  viewList = [];
  currentlySelectedView: string = 'default' ;
  toggleAddView: boolean = false;
  isViewEdit: boolean = false;
  editViewToggler: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectSprintBoardService: ProjectSprintBoardService,
    private notificationService: NotificationService,
    private dragularService: DragulaService,
    private segmentService: SegmentService,
    private projectService: ProjectService,
    private forgeViewerService: ForgeViewerService,
    private pdfViewerService: PDFViewerService,
    private projectMaterialService: ProjectMaterialService,
    public projectSprintService: ProjectSprintService
  ) {
    this.dragulaSubscription = dragularService.drop.subscribe((movedItem) => {
      this.updateStep(movedItem[1].id, Number(movedItem[2].id), Number(movedItem[3].id));
    });

    const bag: any = this.dragularService.find('first-bag');
    if (bag !== undefined) this.dragularService.destroy('first-bag');
    dragularService.setOptions('first-bag', {
      accepts: (el, target, source, sibling) => target.id !== source.id && !target.classList.contains('no-drag'),
      moves: (el, source, handle, sibling) => !el.classList.contains('no-drag')
    });
    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.STORY_UPDATED)
        || event.body.sprintId && event.body.sprintId !== this.sprintId) return;

      this.projectSprintBoardService.sprintBoardData = this.projectSprintBoardService.sprintBoardData
        .filter(d => !event.body.stories.find(s => s.projectStepId === d.stepId));
      this.projectSprintBoardService.loadSprintBoardData(event.body.stories, this.subContractorList);
      this.refreshBoard();
    });
  }

  async ngOnInit() {
    this.projectSprintService.setSprintDislayMode(ViewMode.SprintBoard);
    this.initializeAddView();
  }

  initializeAddView() {
    this.viewList = [];
    this.viewList.push('default');
    this.currentlySelectedView = 'default';
    this.disablePage(true);
    this.projectService.getViews(this.projectId).subscribe(res => {
      this.disablePage(false);
      res.forEach(view => {
        this.viewList.push(view.name);
        this.viewListWithIds.push(view);
      });
    }, err => {
      this.disablePage(false);
      console.error(err);
    });
    this.addViewForm = this.projectSprintBoardService.buildViewFormInput();
  }

  onClickOnView(view) {
    if (this.currentlySelectedView === view) {
      this.editView();
      return;
    }

    this.editViewToggler = null;
    this.currentlySelectedView = view;
    this.toggleAddView = false;
    this.isViewEdit = false;
    this.setBoardItems();
    // Call api to filter activities based on view
  }

  cancelEdit() {
    this.editViewToggler = null;
    this.toggleAddView = false;
    this.isViewEdit = false;
  }

  onSubmitAddView(formData) {
    const {name} = formData.value;

    if (Utils.isEmpty(name)) {
      return;
    }

    const found = this.viewList.find(view => view === name);

    if (found) {
      return;
    }

    this.toggleAddView = false;
    this.addViewForm.reset();

    //Call api to post new view onto the db
    const reqData = { name };

    if (!this.isViewEdit) {
      this.projectService.addViews(this.projectId, reqData)
      .subscribe(viewResult => {
          this.viewListWithIds.push(viewResult);
          this.viewList.push(viewResult.name);
      }, err=>{
        console.log('some error occured');
      });
    } else {
      const viewItem = this.viewListWithIds.find(view => view.name === this.currentlySelectedView);
      this.editViewToggler = null;

      if (viewItem && viewItem.id) {
        this.projectService.updateView(this.projectId, viewItem.id, reqData)
          .subscribe(res => {
            this.isViewEdit = false;
            const index = this.viewList.findIndex(view => view === this.currentlySelectedView);
            this.viewList[index] = reqData.name;
            this.viewListWithIds[index - 1].name = reqData.name;
            this.currentlySelectedView = reqData.name;
          }, err => {
            console.log('some error occured');
          });
      }
    }

    this.addViewForm.reset();
  }

  addView() {
    this.editViewToggler = null;
    this.toggleAddView = !this.toggleAddView;
    this.isViewEdit = false;
    this.addViewForm.controls.name.setValue('');
  }

  editView() {
    if (this.currentlySelectedView !== 'default') {
      // this.toggleAddView = !this.toggleAddView;
      this.toggleAddView = false;
      this.editViewToggler = this.currentlySelectedView;
      this.isViewEdit = true;
      this.addViewForm.controls.name.setValue(this.currentlySelectedView);
    }
  }

  deleteView() {
    if (this.currentlySelectedView !== 'default') {
      this.toggleAddView = false;
      this.isViewEdit = false;
      this.editViewToggler = null;
      let viewObj = this.viewListWithIds.find(v => v.name === this.currentlySelectedView);
      if (!viewObj || !viewObj.id) {
        return;
      }
      this.disablePage(true);
      this.projectService.deleteView(this.projectId, viewObj.id).subscribe(res => {
        this.viewList = this.viewList.filter(v => v !== this.currentlySelectedView);
        this.viewListWithIds = this.viewListWithIds.filter(v => v.name !== this.currentlySelectedView);
        this.currentlySelectedView = 'default';
        this.setBoardItems();
        this.disablePage(false);
      }, err => {
        console.log(err);
      })
    }
  }

  onCancelAddView() {
    this.addViewForm.reset();
  }

  ngOnDestroy() {
    if (this.dragulaSubscription) {
      this.dragulaSubscription.unsubscribe();
    }
    if (this.boardDataSubscription) {
      this.boardDataSubscription.unsubscribe();
    }
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  ngOnChanges() {
    if (!Utils.isEmpty(this.changeName)) {
      // If name was changed, we don't want to do anything else
      return;
    }

    this.projectId = this.projectService.currentProject.id;

    this.buildControls();

    // Reset sprint board data
    this.projectSprintBoardService.sprintBoardData = new Array();
    this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData([]);
    this.getBoardData();
    this.setCompleteSprintMode();
    this.moveToOptions = this.projectSprintService.transformSprintList(this.projectSprintService.getOpenSprints());
    this.moveToOptions.push({id: '0000', name: 'Backlog'});
    this.sprintName = this.projectSprintService.getSprintName(this.sprintId);
    if(this.projectSprintService.getSprint(this.sprintId).is_default) {
      this.defaultSprintSelected = true;
      this.displaySprintModes = this.projectSprintService.getFilteredSprintDisplayMode();
    } else{
      this.displaySprintModes = this.projectSprintService.sprintDisplayModes;
    }
  }

  async buildControls() {
    this.controls = {
      bulkEdit: false,
      reset: false,
      today: false,
      animation: false,
      search: false,
      timeFrames: { display: false },
      customControls: true
    };
  }

  setCompleteSprintMode() {
    if (this.projectSprintService.isSprintEnded(this.sprintId)) {
      this.segmentService.track('Sprint loaded that has been ended, but not closed', {sprintId: this.sprintId});
      this.completeSprintMode = true;
      this.projectSprintBoardService.completeSprintMode = true;
    } else {
      this.completeSprintMode = false;
      this.projectSprintBoardService.completeSprintMode = false;
    }
  }

  getBoardData() {
    this.disablePage(true);
    const observableArray: Array<Observable<any>> = [];
    observableArray.push(this.projectSprintService.getSprintBacklog(this.projectId, 0));
    observableArray.push(this.projectSprintService.getSprintBacklog(this.projectId, 1));
    this.projectSprintService.nextBacklogPage();
    this.projectSprintService.nextBacklogPage();

    // If we have an open sprint, get the sprint stories too
    if (!Utils.isEmpty(this.sprintId)) {
      /*-- TODO - get stories in sprint component and share the data with the sprint chart (currently getting stories in both child components) --*/
      observableArray.push(this.projectSprintService.getAllSprintStories(this.sprintId));
    }

    this.boardDataSubscription = forkJoin(observableArray).subscribe(
      res => {
        let sprintBoardData = res[0].response;
        this.projectSprintBoardService.loadSprintBoardData(sprintBoardData, this.subContractorList, true);
        if (!Utils.isEmpty(res[1]) && !Utils.isEmpty(res[1].response)) {
          sprintBoardData = res[1].response;
          this.projectSprintBoardService.loadSprintBoardData(sprintBoardData, this.subContractorList);

          if (res[1].count < res[1].pageSize) this.moreBacklog = false;

        } else {
          this.moreBacklog = false;
        }

        if (!Utils.isEmpty(res[2])) {
          this.projectSprintBoardService.loadSprintBoardData(res[2], this.subContractorList);
        }

        this.setBoardItems();

        this.checkSprintIsDeletable();

        this.disablePage(false);
      },
      error => {
        const context: INoficationContext = {
          type: 'sprint information',
          action: 'get'
        };

        this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData([]);
        this.notificationService.error(error, context);
        this.disablePage(false);
      }
    );
  }

  filterByView(story) {
    if(story.storyStatus === StoryStatus.Backlog) {
      return true;
    } else if(this.currentlySelectedView === 'default') {
      return true;
      // if(!story.view ||  story.view === 'default') {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
      if(story.viewID && foundView && story.viewID === foundView.id) {
        return true
      } else {
        return false;
      }
    }
  }

  setBoardItems(): void {
    const filteredData = this.projectSprintBoardService.sprintBoardData.filter(story => (!this.taskFilteredOut(story) && this.filterByView(story)));

    this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData(filteredData);

    const hoverActivityIds = [];
    const ActivityColorMap = [];
    var self = this;
    //To show the annotation (on default) for all activities except backlog.
    const sprintBoardExcpetBacklogs = JSON.parse(JSON.stringify(this.transformedSprintBoardItems));
    delete sprintBoardExcpetBacklogs.backlog;
    Object.keys(sprintBoardExcpetBacklogs).forEach( key => {
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        let items = sprintBoardExcpetBacklogs[key].items;
        items.forEach(sprintBoardItem => {
        hoverActivityIds.push(...sprintBoardItem.activities);

        // sprintBoardItem.forEach( step => {
          const actIds = sprintBoardItem.activities ? sprintBoardItem.activities : null;
          if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

          if (sprintBoardItem.subContractor) {
            var subId = sprintBoardItem.subContractor;
            var color = sprintBoardItem.subContractor.hexCode ? sprintBoardItem.subContractor.hexCode : "gray";
            ActivityColorMap.push({activityId: actIds[0], color: color});
          }
        });

        // Remove duplicate activity ids
        this.activityColorIdMap = ActivityColorMap.filter((activityIDMap,index) => {
          return index === ActivityColorMap.findIndex(obj => {
            return JSON.stringify(obj) === JSON.stringify(activityIDMap);
          });
        });

        this.pdfViewerService.selectActivities(this.activityColorIdMap);
        }
      });

    if (this.transformedSprintBoardItems) {
      this.resetViewer();
      this.objectsSelected.emit([]);
    }
  }

  taskFilteredOut(story: ISprintBoardItemResponse): boolean {
    const activeFilters = this.projectSprintService.getActiveFilters();
    const activeFilterType = this.projectSprintService.getActiveFilterType();

    if (activeFilters.length > 0) {
      switch (activeFilterType) {
        case FilterModelOptions.Activities:
        return story.activities.length > 0 && Utils.similarValuesInArrays(story.activities, activeFilters).length < 1;

        case FilterModelOptions.SubContractor: {
          //If no subcontractor is associated to the Activity then filter out that activity too.
          if (!(story.subContractor && story.subContractor.subContractorId)) return true;
          return story.subContractor && story.subContractor.subContractorId && !activeFilters.includes(story.subContractor.subContractorId);
        }

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  handleBoardItemClick(sprintBoardItem: ITransformedSprintBoardItem, type: string): void {
    this.setItemSelectionState(sprintBoardItem, type);
    if (sprintBoardItem.active) {
      this.handlePredecessorsAndSuccesssorHighlightion(sprintBoardItem);
      this.transformedSprintBoardItems[type].active = false;
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {

        const hoverActivityIds = [];
        const ActivityColorMap = [];
        hoverActivityIds.push(...sprintBoardItem.activities);

        // sprintBoardItem.forEach( step => {
          const actIds = sprintBoardItem.activities ? sprintBoardItem.activities : null;
          if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

          if (sprintBoardItem.subContractor) {
            var subId = sprintBoardItem.subContractor;
            var color = sprintBoardItem.subContractor.hexCode ? sprintBoardItem.subContractor.hexCode : "gray";
            ActivityColorMap.push({activityId: actIds[0], color: color});
          }
        this.objectsSelected.emit([sprintBoardItem]);
        this.pdfViewerService.selectActivities(ActivityColorMap);

        // });
      }
      else {
        this.objectsSelected.emit([sprintBoardItem]);
        if (!sprintBoardItem.objectIds) return;
        sprintBoardItem.objectIds.forEach(objId => {
          this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
        });
      }
    } else {
      this.setOrResetAllPredecessorsAndSuccessors([], [], true);
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        this.objectsSelected.emit([]);
        // const ActivityColorMap = [];
        this.pdfViewerService.selectActivities(this.activityColorIdMap);
      }
      else {
        this.objectsSelected.emit([]);
        if (!sprintBoardItem.objectIds) return;
        sprintBoardItem.objectIds.forEach(objId => {
          this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
        });
      }
    }
  }

  handlePredecessorsAndSuccesssorHighlightion(sprintBoardItem: ITransformedSprintBoardItem): void {
    const activityId = sprintBoardItem.activities[0];
    const activityObj = this.projectService.getLocalActivity(activityId);
    this.allActivitites = this.projectService.getLocalAllActivities();

    let prerequisiteIds: string[] = [];
    activityObj.prerequisites.forEach(element => { prerequisiteIds.push(element.activity) });
    let predecessors = prerequisiteIds;
    let sucessors = [];

    this.allActivitites.forEach(activity => {
      const prereqIds = activity.prerequisites.map(p => p.activity);
      if (prereqIds.includes(sprintBoardItem.activities[0])) {
        sucessors.push(activity.id);
      }
    });

    this.setOrResetAllPredecessorsAndSuccessors(predecessors, sucessors);
    sprintBoardItem.isDim = false;
  }

  setOrResetAllPredecessorsAndSuccessors(predecessors: Array<string>, sucessors: Array<string>, resetDimming?: boolean): void {
    this.transformedSprintBoardItems.backlog.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });
    this.transformedSprintBoardItems.committed.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });

    this.transformedSprintBoardItems.inProgress.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });

    this.transformedSprintBoardItems.completed.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });

    this.transformedSprintBoardItems.notCompleted.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });

    this.transformedSprintBoardItems.approved.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });
  }

  handleHover(event: string, sprintBoardItem: ITransformedSprintBoardItem): void {
    // For hover, we are setting active/not active by its own mechanism
    let determineBlockers = false;
    if (event === 'mouseenter') {
      determineBlockers = true;
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        //const hoverActivityIds = [];
        //hoverActivityIds.push(...sprintBoardItem.activities);
        //this.pdfViewerService.howerOverActivity(hoverActivityIds);
      }
      else {
        if (!sprintBoardItem.objectIds) return;
        //sprintBoardItem.objectIds.forEach(objId => {
        //  this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Hover });
        //});
      }
    } else {
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        //const hoverActivityIds = [];
        //this.pdfViewerService.howerOverActivity(hoverActivityIds);
      }
      else {
        if (!sprintBoardItem.objectIds) return;
        //sprintBoardItem.objectIds.forEach(objId => {
        //  this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
        //});
        // Go through an find all active items to set objects to active <-NOT OPTIMAL but fine for now
        //this.resetActiveItemsToSelected();
      }
    }

    //this.transformedSprintBoardItems.backlog.items.map(item => item.blocker = false);
    //if (determineBlockers) this.determineBlockers(sprintBoardItem);
  }

  setItemsToActive(items): void {
    items.forEach(item => {
      if (!item.objectIds) return;
      item.objectIds.forEach(objId => {
        this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
      });
    });
  }

  resetActiveItemsToSelected(): void {
    let activeItems = this.transformedSprintBoardItems.backlog.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.committed.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.completed.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.inProgress.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.notCompleted.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.approved.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
  }

  setItemSelectionState(sprintBoardItem: ITransformedSprintBoardItem, type: string): void {
    this.transformedSprintBoardItems[type].active = false;
    sprintBoardItem.active = !sprintBoardItem.active;
    this.taskIsSelected = true;

    if(sprintBoardItem.active) {
      this.resetBoard(type);
      this.determineBlockers(sprintBoardItem);
    }

    this.transformedSprintBoardItems[type].items.forEach(item => {
      if (item.taskId !== sprintBoardItem.taskId) item.active = false;
    });
  }

  handleLaneSelection(event, lane: any, laneType: string): void {
    if (!event.target.id || (Object.values(this.storyStatusEnum).indexOf(Number(event.target.id)) === -1 && event.target.id !== `sprint-checkbox-${laneType}`)) {
      return
    } else {
      if (lane.items.length > 0) {
        lane.items[0].type = laneType;
        this.setLaneSelectionState(lane);

        if (lane.active) {
          this.setOrResetAllPredecessorsAndSuccessors([],[], false);
          if (this.projectService.viewerMode === ViewerMode.PDFViewer) {

            const hoverActivityIds = [];
            const ActivityColorMap = [];

            this.transformedSprintBoardItems[laneType].items.forEach(item => {
              hoverActivityIds.push(...item.activities);
              const actIds = item.activities ? item.activities : null;
              if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

              if (item.subContractor) {
                var subId = item.subContractor;
                var color = item.subContractor.hexCode ? item.subContractor.hexCode : "gray";
                ActivityColorMap.push({activityId: actIds[0], color: color});
              }
            })
            this.objectsSelected.emit(this.transformedSprintBoardItems[laneType].items);
            this.pdfViewerService.selectActivities(ActivityColorMap);

          }
          else {
            const type = laneType;

            let objectIds = this.transformedSprintBoardItems[type].items.map(item => item.objectIds);
            objectIds = [].concat.apply([], objectIds);
            objectIds.forEach(objId => {
              this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
            });

            this.objectsSelected.emit(this.transformedSprintBoardItems[type].items);
          }
        } else {
          this.setOrResetAllPredecessorsAndSuccessors([],[], true);
          if (this.projectService.viewerMode === ViewerMode.PDFViewer) {

            // const ActivityColorMap = [];
            this.objectsSelected.emit([]);
            this.pdfViewerService.selectActivities(this.activityColorIdMap);
          }
          else {
            this.resetBoard();
            const type = lane.items[0].type;

            let objectIds = this.transformedSprintBoardItems[type].items.map(item => item.objectIds);
            objectIds = [].concat.apply([], objectIds);
            objectIds.forEach(objId => {
              this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
            });

            this.objectsSelected.emit([]);
          }
        }
      } else {
        this.setOrResetAllPredecessorsAndSuccessors([],[], true);
        this.objectsSelected.emit([]);
        this.resetAll();
      }
    }
  }

  setLaneSelectionState(lane: any): void {
    this.resetBoard(lane.items[0].type);

    lane.active = !lane.active;
    const laneState = lane.active;
    lane.items.map(item => item.active = laneState);
  }

  setTaskSelection(taskSelected: boolean): void {
    this.taskIsSelected = taskSelected;
  }

  toggleExpandBoardItem(item, e): void {
    e.stopPropagation();
    item.expanded = !item.expanded;
  }

  // tslint:disable-next-line:cyclomatic-complexity
  async updateStep(taskId: string, newStoryStatus: number, oldStoryStatus: number) {
    this.completedTaskId = taskId;
    // Moving an item out of the backlog
    if (oldStoryStatus === StoryStatus.Backlog) {
      // Then we are creating a story
      // The new location doesn't matter - it will snap to the commit. They can manually move it from there.
      this.addSprintStory(taskId, this.sprintId)
      .then(res => {
        if (newStoryStatus !== StoryStatus.Committed) {
          this.notificationService.info('APPINVALIDTASKMOVE', {});
        }
        // check if this exist as a blocker for other steps and make them activeddd
        const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
        boardItem['view'] = this.currentlySelectedView;
        let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
        if(foundView && foundView.id) boardItem['viewID'] = foundView.id;
        this.projectSprintBoardService.updateBoardItemStatus(boardItem, newStoryStatus);
        this.projectSprintBoardService.removeWorkFlowrelatedDetails(this.sprintId, boardItem)
        .subscribe(res => {
          this.projectSprintService.editSprintStoryBack(this.sprintId, boardItem).subscribe(res => {
            this.setBoardItems();
          })
        });
        this.transformedSprintBoardItems = this.projectSprintBoardService.checkForBlockers(this.transformedSprintBoardItems);
      }, err => {
        console.log('some error occured');
        this.setBoardItems();
      });
    } else if (newStoryStatus === StoryStatus.Backlog) {

      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      boardItem['view'] = 'default';
      boardItem['viewID'] = null;
      this.projectSprintService.editSprintStoryBack(this.sprintId, boardItem)
      .subscribe(async (res) => {
        // Moving an item into the backlog
        // A story can only be deleted if not currently on an active sprint
        if (!await this.removeSprintStory(taskId)) return;

        this.projectSprintBoardService.updateBoardItemStatus(boardItem, newStoryStatus);

        this.transformedSprintBoardItems = this.projectSprintBoardService.checkForBlockers(this.transformedSprintBoardItems);

        this.setBoardItems();
      });
    } else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.InProgress && oldStoryStatus < newStoryStatus) {
     this.modalType = 'InProgress';
     this.inProgressTaskId = taskId;
      // Starting a step
      this.activateSprintTaskInProgress();
      this.setBoardItems();
    }
    else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.Completed && oldStoryStatus < newStoryStatus) {
      // Completing a step
      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      if (Utils.isEmpty(boardItem.startDate)) boardItem.startDate = Utils.getCurrentDate();
      const today = Utils.getCurrentDate();
      if (!Utils.isEmpty(boardItem.startDate)) {
        let duration = today - boardItem.startDate;
        duration = duration / 60000; // convert to minutes
        // If less than 15 minutes, leave empty
        if (duration > 15) {
          duration = duration / 60; // convert to hours
          duration = Math.ceil(duration * 4) / 4; // round to 15 min increments
          this.actualDuration = duration;
        }
      }
      this.actualCrewSize = boardItem.crewSize ? boardItem.crewSize : 0;
      this.actualEquipmentIds = boardItem.equipment ? boardItem.equipment.map(e => e.id) : [];
      this.actualMaterialIds = boardItem.materials ? boardItem.materials.map(mat => mat.id) : [];
      this.actualLaborIds = boardItem.labor ? boardItem.labor.map(labor => labor.id) : [];
      this.actualDuration = boardItem.durationHours ?  boardItem.durationHours : [];
      this.modalType = 'Completed';
      this.dateStarted = boardItem.startDate ? boardItem.startDate : Utils.getCurrentDate();
      this.actualStartDate = boardItem.actualStartDate ? boardItem.actualStartDate : (boardItem.startDate ? boardItem.startDate : Utils.getCurrentDate());
      this.showCompleteStoryModal = true;
      this.completedTaskId = taskId;
      this.curObjectIds = boardItem.objectIds;
      this.setBoardItems();
    } else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.Approved) {
      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      if (Utils.isEmpty(boardItem.startDate)) boardItem.startDate = Utils.getCurrentDate();
      const today = Utils.getCurrentDate();
      if (!Utils.isEmpty(boardItem.startDate)) {
        let duration = today - boardItem.startDate;
        duration = duration / 60000; // convert to minutes
        // If less than 15 minutes, leave empty
        if (duration > 15) {
          duration = duration / 60; // convert to hours
          duration = Math.ceil(duration * 4) / 4; // round to 15 min increments
          this.actualDuration = duration;
        }
      }
      this.actualCrewSize = boardItem.crewSize ? boardItem.crewSize : 0;
      this.actualEquipmentIds = boardItem.equipment ? boardItem.equipment.map(e => e.id) : [];
      this.actualMaterialIds = boardItem.materials ? boardItem.materials.map(mat => mat.id) : [];
      this.actualLaborIds = boardItem.labor ? boardItem.labor.map(labor => labor.id) : [];
      this.actualDuration = boardItem.durationHours ?  boardItem.durationHours : [];

      this.completedTaskId = taskId;
      this.curObjectIds = boardItem.objectIds;
      this.modalType = 'Approved';
      this.dateStarted = boardItem.startDate;
      this.actualStartDate = boardItem.actualStartDate;
      this.showApprovedModal = true;
      this.setBoardItems();
    } else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.NotCompleted && oldStoryStatus < newStoryStatus) {
      this.actualCrewSize = null;
      // Adding reason step is not completed
      this.modalType = 'NotComplete';
      this.showNotCompleteStoryModal = true;

      this.completedTaskId = taskId;
      this.setBoardItems();
    } else if (newStoryStatus !== StoryStatus.Backlog && oldStoryStatus > newStoryStatus) {
      // Moving a step backwards
      this.moveSprintStoryBack(taskId, newStoryStatus);
    } else {
      console.error('An illegal move was made. taskId: ', taskId, ' (old: ', oldStoryStatus, '; new: ', newStoryStatus, ')');
    }
  }

  updateSprintToApprove(taskId: string, actualCrewSize: number, actualDuration: number, storyResult: string, approved: boolean,
    startDate: number, approvedDate: number, notes?: string, actualEquipment?: IStoryEquipment[], actualMaterials?: IStoryMaterial[], actualLabors? : IStoryLabor[], sprintId?: string): void {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    boardItem.startDate = startDate;
    boardItem.view = this.currentlySelectedView;
    let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
    if(foundView && foundView.id) boardItem['viewID'] = foundView.id;

    const inputInfo = {
      storyResult: storyResult,
      actualCrewSize: actualCrewSize,
      actualDurationHours: actualDuration,
      nextProjectSprintId: sprintId,
      notes: Utils.isEmpty(notes) ? null : notes,
      startDate: startDate,
      approvedDate: approvedDate,
      actualEquipment: actualEquipment ? actualEquipment : [],
      actualMaterials: actualMaterials ? actualMaterials : [],
      actualLabors: actualLabors ? actualLabors : [],
      status: 10
    };
    this.projectSprintService.approveSprintStory(boardItem.storyId, inputInfo).subscribe(
      () => {
        if (approved)
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.Approved);
        else
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.NotCompleted);

        this.refreshBoard();
        this.disablePage(false);
        this.segmentService.track('Sprint story approved', { taskId: taskId, storyResult: storyResult });

        this.actualCrewSize = null;
        this.actualDuration = null;
        this.reasonNotCompleted = null;
        this.moveSprintNotCompleted = null;
        this.otherReason = null;
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'start'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
      });
  }
  confirmCompletedTask(input: ICompleteOutput, isApproved: boolean) {
    if(isApproved){
      this.showApprovedModal = false;
      this.updateSprintToApprove(
        this.completedTaskId,
        input.actualCrewSize,
        input.actualDuration,
        input.result,
        true,
        input.startDate,
        input.completionDate,
        input.notes,
        input.actualEquipment,
        input.actualMaterials,
        input.actualLabors
      );
    } else {
      this.showCompleteStoryModal = false;
      this.modalType = '';
      this.completeSprintStory(
        this.completedTaskId,
        input.actualCrewSize,
        input.actualDuration,
        input.result,
        true,
        input.startDate,
        input.completionDate,
        input.notes,
        input.actualEquipment,
        input.actualMaterials,
        input.actualLabors
      );
    }
  }

  confirmNotCompletedTask(input: INotCompleteOutput) {
    if (input.moveTo === '0000') {
      this.completeSprintStory(this.completedTaskId, null, null, input.result, false, null, null, input.notes);
    } else {
      this.completeSprintStory(this.completedTaskId, null, null, input.result, false, null, null, input.notes, null, null, null, input.moveTo);
    }
    this.showNotCompleteStoryModal = false;
  }

  handleReasonDropdown(reason: any) {
    this.reasonNotCompleted = Number(reason);
  }

  handleSprintDropdown(item: any) {
    this.moveSprintNotCompleted = item;
  }

  addSprintStory(taskId: string, sprintId: string) {
    return new Promise((resolve, reject) =>{
      this.disablePage(true);
      this.projectSprintService.addSprintStory({projectSprintId: sprintId, projectScheduleTaskId: taskId}).subscribe(
        success => {
          // If we are putting an item in another sprint - we don't need to update the task board
          if (this.sprintId === sprintId) {
            this.projectSprintBoardService.updateBoardItem(taskId, success, this.subContractorList);
          }
          this.disablePage(false);
          this.segmentService.track('Sprint story added', {taskId: taskId});

          // When adding an item to a sprint, it will only move to commit
          this.refreshBoard();
          resolve();
        },
        error => {
          const context: INoficationContext = {
            type: 'task',
            action: 'update'
          };

          this.notificationService.error(error, context);

          this.refreshBoard();
          this.disablePage(false);
          reject();
      });
    });
  }

  removeSprintStory(taskId: string): Promise<boolean> {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);

    return new Promise((resolve) => {
      this.projectSprintService.removeSprintStory(boardItem.storyId).subscribe(
        () => {
          boardItem.storyId = null;
          boardItem.storyStatus = StoryStatus.Backlog;
          this.projectSprintBoardService.updateBoardItem(taskId, boardItem, this.subContractorList, true);

          this.refreshBoard();
          this.disablePage(false);
          this.segmentService.track('Sprint story removed', {taskId: taskId});
          return resolve(true);
        },
        error => {
          const context: INoficationContext = {
            type: 'task',
            action: 'remove'
          };
          this.notificationService.error(error, context);
          this.refreshBoard();
          this.disablePage(false);
          return resolve(false);
        });
    });
  }

  moveSprintStoryBack(taskId: string, storyStatus: number) {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);

    this.projectSprintService.moveSprintStoryBack(boardItem.storyId, storyStatus).subscribe(
      success => {
        boardItem.labor = [];
        boardItem.materials = [];
        boardItem.equipment = [];
        boardItem.view = this.currentlySelectedView;
        let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
        if(foundView && foundView.id) boardItem['viewID'] = foundView.id;
        this.projectSprintBoardService.updateBoardItemStatus(boardItem, storyStatus);
        this.refreshBoard();
        this.disablePage(false);
        this.segmentService.track('Sprint story moved backwards', {taskId: taskId});
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'move back'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
      });
  }

  activateSprintTaskInProgress() {
    const taskId = this.inProgressTaskId;
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    const duration = Utils.convertHoursToDays(boardItem.durationHours);
    const startDate = boardItem.scheduledStart;
    const expectedFinishDate = Utils.getEndDateExcludingWeekends(startDate, duration);
    this.inProgressModalObj = {
      startDate: Utils.getCurrentDate(),
      expectedFinishDate: expectedFinishDate,
      duration: duration
    }
    this.showInProgressStoryModal = true;
  }

  startSprintStory(inProgressInfo: IInProgressOutput) {
    const taskId = this.inProgressTaskId;
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    const requestObj : IInProgressOutput= {
      startDate : + new Date(inProgressInfo.startDate),
      expectedFinishDate: +new Date(inProgressInfo.expectedFinishDate)
    }
    this.disablePage(true);
    this.projectSprintService.startSprintStory(boardItem.storyId, requestObj).subscribe(
      () => {
        boardItem.storyStatus = StoryStatus.InProgress;
        boardItem.startDate =+ new Date(inProgressInfo.startDate);
        boardItem.view = this.currentlySelectedView;
        let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
        if(foundView && foundView.id) boardItem['viewID'] = foundView.id;

        this.projectSprintBoardService.updateBoardItem(taskId, boardItem, this.subContractorList, true);
        this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.InProgress);
        this.refreshBoard();
        this.disablePage(false);
        this.segmentService.track('Sprint story started', { taskId: taskId });
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'start'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
      });
  }

  completeSprintStory(taskId: string, actualCrewSize: number, actualDuration: number, storyResult: string, completed: boolean,
    startDate: number, completionDate: number, notes?: string, actualEquipment?: IStoryEquipment[], actualMaterials?: IStoryMaterial[], actualLabors?: IStoryLabor[], sprintId?: string) {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    boardItem.crewSize = actualCrewSize ? actualCrewSize : boardItem.crewSize;
    boardItem.durationHours = actualDuration ? actualDuration : boardItem.durationHours;
    boardItem.equipment = [];
    boardItem.materials = [];
    boardItem.labor = [];
    boardItem.view = this.currentlySelectedView;
    let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
    if(foundView && foundView.id) boardItem['viewID'] = foundView.id;

    if (actualEquipment && actualEquipment.length > 0) {
      actualEquipment.forEach(equipment => {
        boardItem.equipment.push({
          id: equipment['equipmentId'],
          name: equipment['name'],
          hours: equipment['hours']
        });
      });
    }

    if(actualMaterials && actualMaterials.length > 0) {
      let newMaterial: any;
      actualMaterials.forEach(material => {
        newMaterial = {
          id: material.materialId,
          quantity: material['quantity']
        }
        boardItem.materials.push(newMaterial);
      });
    }

    if (actualLabors && actualLabors.length > 0) {
      actualLabors.forEach(labor => {
        boardItem.labor.push({
          id: labor['laborId'],
          name: labor['name'],
          quantity: labor['quantity']
        });
      });
    }

    const inputInfo = {
      storyResult: storyResult,
      actualCrewSize: actualCrewSize,
      actualDurationHours: actualDuration,
      nextProjectSprintId: sprintId,
      notes: Utils.isEmpty(notes) ? null : notes,
      started: startDate,
      completionDate: completionDate,
      actualEquipment: actualEquipment ? actualEquipment : [],
      actualMaterials: actualMaterials ? actualMaterials : [],
      actualLabors: actualLabors ? actualLabors : []
    };
    this.projectSprintService.endSprintStory(boardItem.storyId, inputInfo).subscribe(
      () => {
        if (completed)
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.Completed);
        else
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.NotCompleted);

        this.refreshBoard();
        this.disablePage(false);
        this.segmentService.track('Sprint story completed', { taskId: taskId, storyResult: storyResult });

        this.actualCrewSize = null;
        this.actualDuration = null;
        this.reasonNotCompleted = null;
        this.moveSprintNotCompleted = null;
        this.otherReason = null;
        if (this.completeSprintMode && this.projectSprintBoardService.isSprintClosed()) {
          this.segmentService.track('Sprint closed (all stories moved to complete or incomplete)', { sprintId: this.sprintId });
          this.sprintClosed.emit(true);
        }
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'start'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
      });
  }

  resetBoard(boardColumn?: string): void {
    // If nothing is passed in, we'll reset everything
    if (Utils.isEmpty(boardColumn)) boardColumn = 'all';

    switch (boardColumn) {
      case 'backlog':
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.completed.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.approved.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => item.blocker = false);
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.approved.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'committed':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;
        this.transformedSprintBoardItems.approved.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.approved.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'inProgress':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;
        this.transformedSprintBoardItems.approved.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.approved.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'notCompleted':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.completed.active = false;
        this.transformedSprintBoardItems.approved.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.approved.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'completed':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.approved.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.approved.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
        case 'approved':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      default:
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;
        this.transformedSprintBoardItems.approved.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.approved.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
    }
  }

  async resetAll() {
    this.resetBoard();
    await this.resetViewer();
  }

  async resetViewer() {
    // The viewer should show everything on the board
    this.myViewerState = {};
    let viewerList = this.projectSprintBoardService.sprintBoardData.filter(item => item.displayedOnBoard).map(item => item.objectIds) as any;
    viewerList = [].concat.apply([], viewerList);
    viewerList.map(item => this.myViewerState[item] = this.projectService.getObject(item));

    await this.forgeViewerService.setViewerState(this.myViewerState);
    await this.forgeViewerService.clearAllThemingColors();
    await this.forgeViewerService.fitModelToView(Object.keys(this.forgeViewerService.projectModelIdMap));
  }

  determineBlockers(sprintBoardItem: ITransformedSprintBoardItem) {
    let itemCache = {};

    sprintBoardItem.prerequisiteObjectIds.forEach((prereqId, prereqIndex) => {
      const prereqSprintBoardItem = this.transformedSprintBoardItems.backlog.items.find(item => {
        if (!itemCache[item.taskId]) {
          itemCache[item.taskId] = {};
          item.objectIds.forEach((id, idx) => itemCache[item.taskId][id] = idx);
        }

        // const objectIndex = item.objectIds.indexOf(prereqId);
        const objectIndex = itemCache[item.taskId][prereqId];
        if (objectIndex !== undefined) {
          return item.activities[objectIndex] === sprintBoardItem.prerequisiteActivities[prereqIndex];
        } else {
          return false;
        }
      });

      if (!Utils.isEmpty(prereqSprintBoardItem)) {
        prereqSprintBoardItem.blocker = true;
      } else {
        const beforeBacklogLength = this.transformedSprintBoardItems.backlog.items.length;
        this.transformedSprintBoardItems.backlog.items = this.projectSprintBoardService.getHiddenBlockers(prereqId, prereqIndex, sprintBoardItem, this.transformedSprintBoardItems.backlog.items);
        if (beforeBacklogLength < this.transformedSprintBoardItems.backlog.items.length) {
          // this.notificationService.info('APPFILTEREDBLOCKERS', {});
        }
      }
    });
  }

  loadMoreItems(columnType: string) {
    // get another round of data (if there is more)
    if (columnType === 'backlog' && this.moreBacklog) {
      // We are going to get another 25 so we stay ahead
      this.projectSprintService.getSprintBacklog(this.projectId, null).subscribe(
        res => {
          this.projectSprintBoardService.loadSprintBoardData(res['response'], this.subContractorList);
          this.projectSprintService.nextBacklogPage();
          if (res['count'] < res['pageSize']) this.moreBacklog = false;
        },
        error => {
          const context: INoficationContext = {
            type: 'sprint information',
            action: 'get'
          };
          this.notificationService.error(error, context);
        });
    }

    this.transformedSprintBoardItems[columnType].items = this.projectSprintBoardService.loadMoreData(this.transformedSprintBoardItems[columnType].items, columnType);
    this.transformedSprintBoardItems = this.projectSprintBoardService.checkForBlockers(this.transformedSprintBoardItems);
    this.resetAll();
    this.segmentService.track('Loaded more stories', { columnType: columnType });
  }

  handledisplayModeSelection(displayMode: IProjectViewModes): void {
    this.displayModeOutput.emit(displayMode);
  }

  checkSprintIsDeletable(): void {
    const currSprint = this.projectSprintService.getSprint(this.sprintId);
    const currStories = this.projectSprintBoardService.sprintBoardData ? this.projectSprintBoardService.sprintBoardData : null;
    this.projectSprintService.isSafeToDeleteSprint(currSprint, currStories);
  }

  checkMoreItemsAvailable(columnType: string) {
    // tslint:disable-next-line:max-line-length
    if (this.transformedSprintBoardItems[columnType].items.length >= (this.projectSprintBoardService.sprintBoardColumnPageSize * this.projectSprintBoardService.sprintBoardPagingData[columnType].currentPage)) {
      return false;
    } else {
      return true;
    }
  }

  async undoBoardAction() {
    if (!Utils.isEmpty(this.subContractorList)) {
      this.transformedSprintBoardItems = this.projectSprintBoardService.getRefreshedData();
      const filteredData = this.projectSprintBoardService.sprintBoardData.filter(story => (this.filterByView(story)));
      this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData(filteredData);
    } else {
      const filteredData = this.projectSprintBoardService.sprintBoardData.filter(story => (!this.taskFilteredOut(story) && this.filterByView(story)));
      this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData(filteredData);
    }
  }

  showLoadMoreButton(columnType: string) {
    return this.projectSprintBoardService.checkColumnNeedsPaging(columnType);
  }

  refreshBoard() {
    this.modalType = '';
    this.dateStarted = null;
    this.actualStartDate = null;
    this.showCompleteStoryModal = false;
    this.showNotCompleteStoryModal = false;
    this.showApprovedModal = false;
    this.showInProgressStoryModal = false;

    this.undoBoardAction();
    this.checkSprintIsDeletable();
  }

  disablePage(disable: boolean) {
    this.makingBackendCall = disable;
  }

  completeformOutput(formOutput: ICompleteOutput) {
      this.confirmCompletedTask(formOutput, formOutput.isApproved);
  }

  notCompleteformOutput(formOutput: INotCompleteOutput) {
    this.confirmNotCompletedTask(formOutput);
  }

  inProgressFormOutput(formOutput : IInProgressOutput) {
    this.startSprintStory(formOutput);
  }

  async handleSubmittalClick(sprintItem: ITransformedSprintBoardItem, event: any): Promise<void> {
    event.stopPropagation();

    const submittalSelection = {
      taskName: sprintItem.stepName,
      submittals: []
    };

    for (const mat of sprintItem.materials) {
      if (mat.procoreId) {
        submittalSelection.submittals.push({
          materialName: mat.name,
          url: await this.projectMaterialService.getProcoreSubmittalUrl(this.projectService.currentProject.id, mat.procoreId)
        });
      }
    }

    if (submittalSelection.submittals.length < 1) {
      return;
    } else if (submittalSelection.submittals.length === 1) {
      window.location.href = submittalSelection.submittals[0].url;
    } else {
      this.submittalSelection = submittalSelection;
      this.showSubmittalModal = true;
    }
  }

  closeSubmittalModal(): void {
    this.submittalSelection = null;
    this.showSubmittalModal = false;
  }
}
