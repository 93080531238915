import { BulkEditSelection } from "../../utils/enums/shared.enum";

export interface IActivity {
    id: string;
    projectId: string;
    parentId: string;
    externalActivityId: string;
    name: string;
    duration: number;
    expectedDuration?: number;
    startDate: number;
    endDate: number;
    crewSize?: number;
    prerequisites?: IPrereqActivity[];
    timings?: string[];
    depth?: number; // Used in gantt chart, automatically calculated
    children?: string[];
    objectIds?: string[];
    equipmentIds?: string[];
    equipmentCost?: number;
    materialIds?: string[];
    rfiIds?: string[];
    laborIds?: string[];
    materialCost?: number;
    laborCost?: number;
    anchorDate?: number;
    static?: boolean;
    pdfIds?: string[];
    actualStartDate?: number;
    actualFinishDate?: number;
    expectedFinishDate?: number;
    started?: number;
    approved?: number;
    completed?: number;
    taskId?: string;
    sprintId?: string;
}

export interface IPrereqActivity {
    activity: string;
    timing: string;
}

export interface RowEdit {
    id: string;
    externalActivityId: string;
    name: string;
    duration: number;
    subContractor: any;
    durationEnabled: boolean;
    newActivity: boolean;
}

export interface IMasterScheduleActivity {
    activity: IActivity;
    subContractorId: string;
    milestoneId: string;
    expanded?: boolean;
    bulkEditSelection?: BulkEditSelection;
}

export interface IActivityFormOutput extends IMasterScheduleActivity {
    updatedEquipments?: any[];
    updatedMaterials?: any[];
    updatedLabors?: any[];
    milestoneName?: string;
}

export interface IGanttChartRow {
    id?: string;
    label?: string;
    rowId?: string;
    objectIds?: string[];
    xCoord?: number;
    yCoord?: number;
    textColor?: string;
    name?: string;
    expanded?: boolean;
    depth?: number;
    externalActivityId?: string;
    duration?: number;
    subName?: string;
    materials?: string;
    equipments?: string;
    labors?: string;
    prerequisiteIds?: string[];
    milestoneName?: string;
    startDate?: string;
    endDate?: string;
    startDayEpoch?: number;
    endDayEpoch?: number;
    expectedFinishDate?: string;
    completed?: number;
    started?: number;
    color?: string;
    numChildren?: number;
    bulkEditSelection?: BulkEditSelection;
    materialIds?: string[];
    rfiIds?: string[];
}

export interface IGanttChartBar extends IGanttChartRow {
    stroke?: string;
    selected?: boolean;
    pathData?: Array<{ x: number, y: number }>;
    materialName?: string;
    materialStart?: string;
    materialROS?: string;
    materialReview?: string;
    matstartDate?: number;
    requredOnSiteDate?: number;
    submitByDate?: number;
    circlePreparation?: number;
    circleFND?: number;
    circleSubmitedby?: number;
    circleCenters?: Array<{ x: number, y: number }>;
    ActualSubmitBy?: number;
    ActualROS?: number;
    ActualApproved?: number;
    tMinusDisplay?: string;
    tMinusDisplayDueSubmitDate?: string;
    tMinusDisplayDueROS?: string;
    tMinusDisplayDueApproved?: string;
    rfiName?: string;
    rfiStart?: string;
    rfiReview?: string;
    rfiStartDate?: number;
    dueDate?: number;
    circleRfiPreparation?: number;
    circleRfiSubmittedBy?: number;
    rfiCircleCenters?: Array<{ x: number, y: number }>;
}

export enum HoverType {
    DataInfo,
    Add,
    Edit,
    Assign,
    AssignWorkArea,
    Done,
    Dehover,
    Expand,
    ProjectedEndDate,
    RowHover,
    materialCircle,
    rfiCircle
}

export interface IPrereqItem {
    id: string;
    timing: string;
}

export interface ICosts {
    equipmentCost: number;
    laborCost: number;
    materialCost: number;
}

export interface ICostsFlag {
    equipmentCost: boolean;
    laborCost: boolean;
    materialCost: boolean;
}
