import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';

import { ActivityService, DEFAULT_TIMING } from '../../activity/activity.service';
import { NotificationService } from '../../notification/notification.service';
import { ProjectMilestoneService } from '../project-milestone/project-milestone.service';
import { ProjectScheduleService } from '../project-schedule/project-schedule.service';
import { ProjectSubContractorService } from '../project-subcontractor/project-subcontractor.service';
import { ProjectWorkDaysService } from '../project-workdays/project-workdays.service';
import { ProjectService } from '../project.service';

import { IActivity, IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { IColumnHeader, ICustomTable, ICustomTableEdit, IRow, IRowItem, ITableSearchOptions } from '../../../models/custom-table/custom-table.interface';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { IProjectSchedule } from '../../../models/project/project-schedule/project-schedule.interface';
import { IHours } from '../../../models/project/project-workdays/project-workdays.interface';
import { IProjectViewMode } from '../../../models/project/project.interface';
import { ViewMode } from '../../../utils/enums/shared.enum';
import { SidebarState } from '../../../utils/enums/sidebar-state';
import { ISidebarTab } from '../../../models/sidebar/sidebar.interface';
import { PDFViewerService } from '../../pdf/pdfViewer.service';

@Injectable()
export class ProjectMasterScheduleService {

  public chartDisplayModes: IProjectViewMode[] = [
    {
      mode: ViewMode.Gantt,
      label: ViewMode.Gantt,
      selected: true
    },
    {
      mode: ViewMode.Table,
      label: ViewMode.Table,
      selected: false
     }
  ];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectService: ProjectService,
    private pdfViewerService: PDFViewerService,
    private subcontractorService: ProjectSubContractorService,
    private milestoneService: ProjectMilestoneService,
    private activityService: ActivityService,
    private notificationService: NotificationService,
    private projectScheduleService: ProjectScheduleService,
    private projectWorkDayService: ProjectWorkDaysService,
    private formBuilderService: FormBuilder
  ) {/*EMPTY*/}

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getProjectHours(): Promise<any> | null {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.projectService.getProjectHours(this.projectService.currentProject.id).subscribe(
        res => {
          const returnObj = {};
          const returnData = this.projectWorkDayService.transformScenarioToWorkDay(res.hours);
          returnObj['workDayData'] = returnData;
          returnObj['timeZone'] = res.timeZone;
          returnObj['exceptions'] = res.exceptions;
          returnObj['hours'] = res.hours;
          return resolve(returnObj);
        },
        err => {
          this.notificationService.error(err, {type: 'project hours', action: 'get'});
          return resolve(null);
        }
      );
    });
  }

  updateProjectHours(json: IHours): Promise<boolean> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.projectService.updateProjectHours(this.projectService.currentProject.id, json).subscribe(
        () => {
          return resolve(true);
        },
        err => {
          this.notificationService.error(err, {type: 'project hours', action: 'update'});
          return resolve(false);
        }
      );
    });
  }

  getTasksList(scheduleId: string, startEpoch: number, endEpoch: number): Promise<any> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise<any>((resolve) => {
      this.projectService.getActivityTasks(this.projectService.currentProject.id, scheduleId, startEpoch, endEpoch).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          resolve(res[0]);
        },
        err => {
          this.notificationService.error(err, {type: 'tasks', action: 'get'});
          resolve(null);
        }
      );
    });
  }

  getActivity(activityId: string): Promise<IActivity> | null {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.getActivity(activityId).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'activity', action: 'get'});
          return resolve(null);
        }
      );
    });
  }

  getActivities(): Promise<IMasterScheduleActivity[]> | null {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.getAllActivities(this.projectService.currentProject.id).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          const chartIn: IMasterScheduleActivity[] = [];
          if (res.length > 0) {
            res.forEach((act) => {
              chartIn.push({
                activity: this.modifyToActivity(act),
                subContractorId: act.subContractorId,
                milestoneId: act.milestoneId,
                expanded: false
              });
            });
          }
          return resolve(chartIn);
        },
        err => {
          this.notificationService.error(err, {type: 'activity', action: 'get'});
          return resolve(null);
        }
      );
    });
  }

  modifyToActivity(input: IActivity): IActivity {
    input.prerequisites.forEach(pre => pre.timing.length === 0 ? pre.timing = DEFAULT_TIMING : pre.timing);
    const activity : IActivity = {
      id: input.id,
      projectId: this.projectService.currentProject.id,
      parentId: input.parentId,
      externalActivityId: input.externalActivityId,
      name: input.name,
      duration: input.duration,
      expectedDuration: input.expectedDuration,
      startDate: input.startDate,
      endDate: input.endDate,
      expectedFinishDate: input.started ? input.expectedFinishDate : null,
      started: input.started,
      completed: input.completed,
      anchorDate: input.anchorDate,
      static: input.static,
      crewSize: input.crewSize,
      prerequisites: input.prerequisites,
      timings: input.timings,
      depth: input.depth,
      children: input.children,
      objectIds: input.objectIds,
      equipmentIds: input.equipmentIds,
      equipmentCost: input.equipmentCost ? Number(input.equipmentCost.toFixed(2)) : null,
      materialIds: input.materialIds,
      rfiIds: input.rfiIds,
      laborIds: input.laborIds,
      pdfIds: this.projectService.getPDFNameForURL(this.pdfViewerService.getPDFIdForActivity(input.id)),
      materialCost: input.materialCost ? Number(input.materialCost.toFixed(2)) : null,
      laborCost: input.laborCost ? Number(input.laborCost.toFixed(2)) : null,
    };
    if (input.started) activity.actualStartDate = input.started;
    if (input.approved || input.completed) activity.actualFinishDate = input.approved || input.completed;
    if (input['projectScheduleTaskId']) activity.taskId = input['projectScheduleTaskId'];
    if (input['sprintId']) activity.sprintId = input['sprintId'];
    // if(input['originalStartDate']) activity.originalStartDate = input['originalStartDate'];
    // if(input['originalEndDate']) activity.originalEndDate = input['originalEndDate'];

    return activity;
  }

  importActivities(file: File, stat: boolean , configuration:  {}): Promise<boolean> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.importCSV(this.projectService.currentProject.id, file, stat, configuration).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          if (res.type === 4) {
            return resolve(res);
          }
        },
        err => {
          this.notificationService.error(err, {type: 'activity', action: 'import'});
          const error: any = {error: err};
          return resolve(error);
        }
      );
    });
  }

  addActivity(formOutput: IMasterScheduleActivity): Promise<IMasterScheduleActivity> | null {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      delete formOutput.activity.id;
      this.activityService.addActivity(formOutput.activity).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          const masterActivityToAdd: IMasterScheduleActivity = {
            activity: res,
            subContractorId: null,
            milestoneId: null
          };
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, {type: 'activity', action: 'add'});
          return resolve(null);
        }
      );
    });
  }

  deleteActivities(activityIds: string[]): Promise<string[]> | null {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.deleteActivity(this.projectService.currentProject.id, activityIds).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          return resolve(activityIds);
        },
        err => {
          this.notificationService.error(err, {type: 'activity', action: 'delete'});
          return resolve(null);
        }
      );
    });
  }

  updateActivity(formOutput: IMasterScheduleActivity): Promise<any> {
    formOutput.activity['fromMasterSchedule'] = true;
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.updateActivity(formOutput.activity.id, formOutput.activity).pipe(takeUntil(this.destroyed$)).subscribe(
        (res) => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'activity', action: 'edit'});
          return resolve(null);
        }
      );
    });
  }

  addMilestone(milestone: {name: string, endDate: number}): Promise<string> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.milestoneService.addMilestone(milestone).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res.id);
        },
        err => {
          this.notificationService.error(err, {type: 'milestone', action: 'add'});
          return reject();
        }
      );
    });
  }

  getAffectedStepIds(selectedObjectIds: string[], curActivityIdsSelected: string[]): Promise<string[]> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.getAffectStepIds(this.projectService.currentProject.id, selectedObjectIds, curActivityIdsSelected).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res.installs.concat(res.requires));
        },
        err => {
          this.notificationService.error(err, {type: 'steps', action: 'get'});
          return resolve([]);
        }
      );
    });
  }

  assignObjects(selectedObjectIds: string[], curActivityIdsSelected: string[]): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.assignObjects(this.projectService.currentProject.id, selectedObjectIds, curActivityIdsSelected).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          return resolve();
        },
        err => {
          this.notificationService.error(err, {type: 'objects', action: 'set'});
          return resolve();
        }
      );
    });
  }

  setEquipment(masterActivityToAdd: IMasterScheduleActivity, formOutput: IMasterScheduleActivity, updatedEquipments?: any[]): Promise<IMasterScheduleActivity> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.setEquipment(this.projectService.currentProject.id, masterActivityToAdd.activity.id, formOutput.activity.equipmentIds, updatedEquipments).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          masterActivityToAdd.activity.equipmentIds = formOutput.activity.equipmentIds;
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, {type: 'equipment', action: 'edit'});
          return resolve(masterActivityToAdd);
        }
      );
    });
  }

  setMaterials(masterActivityToAdd: IMasterScheduleActivity, formOutput: IMasterScheduleActivity, updatedMaterials?: any[]): Promise<IMasterScheduleActivity> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.setMaterials(this.projectService.currentProject.id, masterActivityToAdd.activity.id, formOutput.activity.materialIds, updatedMaterials).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          masterActivityToAdd.activity.materialIds = formOutput.activity.materialIds;
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, {type: 'equipment', action: 'edit'});
          return resolve(masterActivityToAdd);
        }
      );
    });
  }

  setRfi(masterActivityToAdd: IMasterScheduleActivity, formOutput: IMasterScheduleActivity): Promise<IMasterScheduleActivity> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.setRfi(this.projectService.currentProject.id, masterActivityToAdd.activity.id, formOutput.activity.rfiIds).pipe(takeUntil(this.destroyed$)).subscribe(
        (res) => {
          masterActivityToAdd.activity.rfiIds = formOutput.activity.rfiIds;
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, {type: 'rfi', action: 'edit'});
          return resolve(masterActivityToAdd);
        }
      );
    });
  }

  setLabor(masterActivityToAdd: IMasterScheduleActivity, formOutput: IMasterScheduleActivity, updatedLabors?: any[]): Promise<IMasterScheduleActivity> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.setLabor(this.projectService.currentProject.id, masterActivityToAdd.activity.id, formOutput.activity.laborIds, updatedLabors).pipe(takeUntil(this.destroyed$)).subscribe(
        (res) => {
          masterActivityToAdd.activity.laborIds = formOutput.activity.laborIds;
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, { type: 'labor', action: 'edit' });
          return resolve(masterActivityToAdd);
        }
      );
    });
  }

  setSubContractor(masterActivityToAdd: IMasterScheduleActivity, formOutput: IMasterScheduleActivity): Promise<IMasterScheduleActivity> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.setSubContractor(this.projectService.currentProject.id, masterActivityToAdd.activity.id, formOutput.subContractorId).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          masterActivityToAdd.subContractorId = formOutput.subContractorId;
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, {type: 'subContractor', action: 'edit'});
          return resolve(masterActivityToAdd);
        }
      );
    });
  }

  setMilestone(masterActivityToAdd: IMasterScheduleActivity, formOutput: IMasterScheduleActivity): Promise<IMasterScheduleActivity> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve) => {
      this.activityService.setMilestone(this.projectService.currentProject.id, masterActivityToAdd.activity.id, formOutput.milestoneId).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          masterActivityToAdd.milestoneId = formOutput.milestoneId;
          return resolve(masterActivityToAdd);
        },
        err => {
          this.notificationService.error(err, {type: 'milestone', action: 'edit'});
          return resolve(masterActivityToAdd);
        }
      );
    });
  }

  setMilestoneFromIds(activityId: string, milestoneId: string): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.activityService.setMilestone(this.projectService.currentProject.id, activityId, milestoneId).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          return resolve();
        },
        err => {
          this.notificationService.error(err, {type: 'milestone', action: 'edit'});
          return reject();
        }
      );
    });
  }

  getScenarios(): Promise<IProjectSchedule[]> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.projectScheduleService.get(this.projectService.currentProject.id).subscribe(
        res => resolve(res),
        err => {
          const context: INoficationContext = {
            type: 'schedules',
            action: 'get'
          };
          this.notificationService.error(err, context);

          resolve([]);
        });
    });
  }

  getPossiblyValidLevelPrereqs(allActivities: IMasterScheduleActivity[], curActivityId: string): string[] {
    return this.activityService.getPossiblyValidLevelPrereqs(allActivities.map(item => item.activity), curActivityId);
  }

  getSidebarTabData(): ISidebarTab[] {
    const sidebarTabs: ISidebarTab[] = [
      {
        name: 'filter',
        icon: 'fa-filter',
        key: SidebarState.MODEL_FILTER,
        active: true
      },
      {
        name: 'task_list',
        icon: 'fa-tasks',
        key: SidebarState.TASK_LIST,
        active: false
      },
      {
        name: 'thread',
        icon: 'fa-comments',
        key: SidebarState.TASK_MESSAGES,
        active: false
      },
      {
        name: 'object_properties',
        icon: 'fa-cube',
        key: SidebarState.OBJECT_PROPERTIES,
        active: false
      }
    ];

    return sidebarTabs;
  }

  setChartMode(mode: ViewMode) {
    this.chartDisplayModes.forEach(item => {
      item.selected = false;
      if (item.mode === mode) item.selected = true;
    });
  }

  setPrereqAutoInput(activityInput: IMasterScheduleActivity[]): IMasterScheduleActivity[] {
    let findRes;
    const returnArr: IMasterScheduleActivity[] = [];
    activityInput.forEach(item => {
      if (item.activity.parentId) {
        findRes = activityInput.find(a => a.activity.id === item.activity.parentId);
        if (findRes) {
          returnArr.push({activity: this.makeModifiedActivity(findRes.activity, item.activity), subContractorId: item.subContractorId, milestoneId: item.milestoneId});
        }
      } else {
        returnArr.push(item);
      }
    });
    return returnArr;
  }

  makeModifiedActivity(parent: IActivity, child: IActivity): IActivity {
    const returnAct = JSON.parse(JSON.stringify(child));
    returnAct.name = parent.name + ' -> ' + child.name;
    return returnAct;
  }

  transformTableData(tableInput: IMasterScheduleActivity[], selectedIds: string[], paginationStart?: number): ICustomTable {
    const overallCount = tableInput.length;
    const pageStart = paginationStart ? paginationStart : 0;
    const tablePageSize = 20;

    // Table
    const transformedTableData:  ICustomTable = {
      title: 'Activities',
      filterable: true,
      filterByOptions: [{title: 'Show unused activities', active: false}],
      searchable: true,
      searchOptions: [],
      columnHeaders: [],
      rows: [],
      multiSelect: true,
      multiSearch: false,
      canCreate: false,
      createRowModel: [],
      pagination: {
        count: overallCount,
        pageSize: tablePageSize,
        pageStart: pageStart,
        totalItemsSelected: 0,
        display: true
      },
      allRowsSelected: overallCount === selectedIds.length ? true : false,
      bulkHide: true,
      bulkHideNoConfimationModal: true,
      showIntro: true
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'Name',
        key: 'name',
        sortable: false,
        active: false,
        searchable: true,
        width: '35%'
      },
      {
        title: 'Duration',
        key: 'duration',
        sortable: false,
        active: false,
        searchable: false,
        width: '15%'
      },
      {
        title: 'Milestone',
        key: 'milestone',
        sortable: false,
        active: false,
        searchable: false,
        width: '25%'
      },
      {
        title: 'Subcontractor',
        key: 'subcontractor',
        sortable: false,
        active: false,
        searchable: false,
        width: '25%'
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Define search options for multi-search
    const searchOptions: ITableSearchOptions[] = [];
    transformedTableData.columnHeaders.filter((colHead, index) => {
      if (colHead.searchable === true) {
        searchOptions.push({
          title: colHead.title,
          active: false
        });
      }
    });
    transformedTableData.searchOptions = searchOptions;

    let totalOtherItemsSelected = selectedIds.length;

    // Table Rows
    const tableRows: IRow[] = [];

    // Table Rows
    for (let r = pageStart; r < pageStart + tablePageSize; r++) {
      if (r < tableInput.length) {
        const rowItems: IRowItem[] = [
          {
            name: 'name',
            value: tableInput[r].activity.name,
            prevStateValue: tableInput[r].activity.name,
            editable: false,
            type: 'input',
            width: '35%'
          },
          {
            name: 'duration',
            value: tableInput[r].activity.duration,
            prevStateValue: tableInput[r].activity.duration,
            editable: false,
            type: 'input',
            width: '15%'
          },
          {
            name: 'milestone',
            value: tableInput[r].milestoneId ? this.milestoneService.getLocalProjectMilestone(tableInput[r].milestoneId).name : 'Not Assigned',
            prevStateValue: tableInput[r].milestoneId ? this.milestoneService.getLocalProjectMilestone(tableInput[r].milestoneId).name : 'Not Assigned',
            editable: false,
            type: 'input',
            width: '25%'
          },
          {
            name: 'subcontractor',
            value: tableInput[r].subContractorId ? this.subcontractorService.getLocalProjectSubcontractor(tableInput[r].subContractorId).name : 'Not Assigned',
            prevStateValue: tableInput[r].subContractorId ? this.subcontractorService.getLocalProjectSubcontractor(tableInput[r].subContractorId).name : 'Not Assigned',
            editable: false,
            type: 'input',
            width: '25%'
          }
        ];

        if (selectedIds.includes(tableInput[r].activity.id)) {
          totalOtherItemsSelected--;
        }

        tableRows.push({
          selectable: true,
          editable: false,
          removeable: false,
          active: selectedIds.includes(tableInput[r].activity.id),
          rowItems: rowItems,
          key: tableInput[r].activity.id,
          isEditing: false
        });
      }
    }

    transformedTableData.rows = tableRows;
    transformedTableData.pagination.totalItemsSelected = totalOtherItemsSelected;

    // Custom edits
    const edits: ICustomTableEdit[] = [
      {
        icon: 'fa-cube',
        hoverText: 'Assign Objects',
        actionFn: 'assign'
      },
      {
        icon: 'fa-edit',
        hoverText: 'Edit Activity',
        actionFn: 'edit'
      }
    ];

    transformedTableData.customEdits = edits;

    return transformedTableData;
  }

  buildGenerateScheduleForm() {
    return this.formBuilderService.group({
      name: [null, Validators.required],
      honorCommitments: [null],
      sendActivationEmail: [true]
    });
  }
}
