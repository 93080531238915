import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuEmitterService } from '../services/menu/menu-emitter.service';
import { MenuService } from '../services/menu/menu.service';
import { Subscription } from 'rxjs';
import { Utils } from '../utils/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  authenticateUserSubscription: Subscription;
  projectsCount: number = 0;
  accountsCount: number = 0;
  message: string = '';
  isLoading: boolean = true;

  constructor(
    public menuService: MenuService,
    private router: Router,
  ) { }

  ngOnInit() {
    // checks local storage to set main menu state
    this.menuService.checkMainMenuState();
    if (!Utils.isEmpty(MenuEmitterService.getAuthenticatedUser())) {
      this.projectsCount = MenuEmitterService.getAuthenticatedUser().projects.length;
      this.accountsCount = MenuEmitterService.getAuthenticatedUser().accounts.length;
      this.isLoading = false;
    } else {
      this.authenticateUserSubscription = MenuEmitterService.authenticatedUserChanged$.subscribe(authenticatedUser => {
        this.projectsCount = authenticatedUser.projects.length;
        this.accountsCount = authenticatedUser.accounts.length;
        this.isLoading = false;
      });
    }
  }

  contactSales(): void {
      window.open('https://www.gritvirtual.com/#contact', '_blank');
    }

  createNewProject() {
    this.router.navigateByUrl('project/create');
  }

  ngOnDestroy() {
      if (this.authenticateUserSubscription) {
        this.authenticateUserSubscription.unsubscribe();
      }
  }
}