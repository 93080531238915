export enum SprintStoryResult {
  Complete = 1,
  Labor = 2,
  Material = 3,
  Information = 4,
  Weather = 5,
  Coordination = 6,
  Equipment = 7,
  Other = 8
}
