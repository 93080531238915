import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ChartService } from '../../../services/chart/chart.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { IManpowerChartData } from '../../../models/reports/project-report.interface';
import { ProjectReportsService } from '../../../services/project/project-reports/project-reports.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../../services/project/project.service';

import { INoficationContext } from '../../../models/notification/notification.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';

import { GritColors } from '../../../utils/enums/hex-color.enum';
import { ChartIntervalState } from '../../../utils/enums/reports.enum';
import { ViewMode } from '../../../utils/enums/shared.enum';
import { Utils } from '../../../utils/utils';

import * as D3 from 'd3';
import * as moment from 'moment';
import { TranslationService } from '../../../services/translation/translation.service';
@Component({
  selector: 'app-project-manpower-report',
  templateUrl: './project-manpower-report.component.html',
  styleUrls: ['./project-manpower-report.component.scss']
})
export class ProjectManpowerReportComponent implements OnInit, OnDestroy {
  @ViewChild('chartSvgContainer') chartSvgContainer;
  @ViewChild('graphContainer') graphContainer;
  @ViewChild('columnContainer') columnContainer;
  @ViewChild('xAxis') xAxis;
  @ViewChild('xAxisLabel') xAxisLabel;
  @ViewChild('yAxis') yAxis;
  @ViewChild('yAxisLabel') yAxisLabel;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  pageIsLoading: boolean = false;
  errorGettingData: boolean = false;
  noData: boolean = false;
  errorMessage: string = 'error_getting_data.';
  noDataMessage: string = 'manpower_data_error';
  chartControls: IProjectScheduleControls;
  private activeScheduleId: string;
  private scheduleStartDate: number;
  private scheduleEndDate: number;
  private scheduleData: any = [];
  private chartData: IManpowerChartData = {};
  private activeChartInterval: ChartIntervalState;
  private allYaxisValues: number[] = [];
  private xAxisTickCount: number;
  private chartStart: number;
  private chartEnd: number;
  private visibleMonths: number;
  private xScaleTime: D3.scaleUtc<any> = null;
  private yScale: D3.ScaleLinear<number, number> = null;
  private activeDisplayMode: ViewMode = ViewMode.ThreeWeekSchedule;
  selectedSubcontractors: FormControl = new FormControl();
  subcontractorDropdownItems: any[];

  constructor(
    private chartService: ChartService,
    private projectService: ProjectService,
    private projectScheduleService: ProjectScheduleService,
    private notificationService: NotificationService,
    private projectSubcontractorService: ProjectSubContractorService,
    public projectReportsService: ProjectReportsService
  ) {}

  async ngOnInit() {
    await this.projectSubcontractorService.setLocalProjectSubcontractors(this.projectService.currentProject.id);
    this.getPageData();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getPageData(): void {
    this.pageIsLoading = true;
    const observableArray: Array<Observable<any>> = [
      this.projectSubcontractorService.setLocalProjectSubcontractors(this.projectService.currentProject.id),
      this.projectScheduleService.getActiveSchedule(this.projectService.currentProject.id)
    ];

    forkJoin(observableArray).pipe(switchMap(res => {
      this.scheduleStartDate = res[1].startDate;
      this.scheduleEndDate = res[1].endDate;
      this.activeScheduleId = res[1].id;
      const toDate = moment.utc(this.scheduleStartDate).clone().endOf(ChartIntervalState.Month).valueOf();
      return this.projectScheduleService.getSchedule(this.activeScheduleId, this.scheduleStartDate, toDate);
    })).pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.formatRawData(res);
          this.setupSubDropdown();
          this.setChartDisplayMode(this.activeDisplayMode);
          this.setXaxisData();
          this.setChartControls();
          this.drawChart();

          this.pageIsLoading = false;
          this.errorGettingData = false;
          this.noData = res['length'] < 1;
        },
        err => {
          const context: INoficationContext = {
            type: 'get schedule',
            action: 'get'
          };

          this.notificationService.error(err, context);
          this.pageIsLoading = false;
          this.errorGettingData = true;
        }
      );
  }

  getScheduleData(fromDate?: number, toDate?: number): any {
    this.pageIsLoading = true;
    this.projectScheduleService.getSchedule(this.activeScheduleId, fromDate, toDate).subscribe(
      res => {
        this.formatRawData(res);
        this.drawChart();

        this.noData = res.length < 1;
        this.pageIsLoading = false;
        this.errorGettingData = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'get schedule',
          action: 'get'
        };

        this.notificationService.error(err, context);
        this.pageIsLoading = false;
        this.errorGettingData = true;
      }
    );
  }

  setupSubDropdown(): void {
    this.subcontractorDropdownItems = this.projectReportsService.transformSubsForDropdown(this.projectSubcontractorService.getLocalProjectSubcontractors());
    this.selectedSubcontractors.setValue(this.subcontractorDropdownItems.map(item => item.id));
  }

  formatRawData(rawScheduleData: any): void {
    this.scheduleData = this.projectReportsService.transformManpowerReportData(rawScheduleData); // adds the different chart interval keys for each data point (day, week, month)
    this.chartData = this.projectReportsService.formatManpowerDataForChart(this.activeDisplayMode, this.scheduleData); // formats data to render in chart
  }

  setChartControls(): void {
    this.chartControls = this.projectReportsService.getChartControls(this.chartStart, this.chartEnd);
  }

  setChartDisplayMode(viewMode: ViewMode): void {
    this.projectReportsService.chartDisplayModes.forEach(mode => {
      if (viewMode === mode.mode) {
        mode.selected = true;
        this.activeDisplayMode = mode.mode;
      } else {
        mode.selected = false;
      }
    });
  }

  drawChart(): void {
    this.createChartDimensions();
    this.setScales();
    this.drawYaxis();
    this.drawXaxis();
    this.setupChartData(this.chartData);
    this.drawColumns(this.chartData);
  }

  setXaxisData(startDate?: number): void {
    switch (this.activeDisplayMode) {
      case ViewMode.ThreeWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(3, ChartIntervalState.Week, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
        break;
      case ViewMode.SixWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(6, ChartIntervalState.Week, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
        break;
      case ViewMode.OneWeekSchedule :
        this.activeChartInterval = ChartIntervalState.Week;
        this.setStartEnd(1, ChartIntervalState.Week, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
        break;
      default :
        this.activeChartInterval = ChartIntervalState.Day;
        this.setStartEnd(1, ChartIntervalState.Week, startDate);
        this.xAxisTickCount = this.chartService.getIntervalsInRange(this.chartStart, this.chartEnd, this.activeChartInterval).length + 1;
    }
  }

  setStartEnd(monthCount: number, interval: ChartIntervalState, startDate?: number): void {
    this.chartStart = startDate ? startDate : moment.utc(this.scheduleStartDate).startOf('month').valueOf();
    this.chartEnd = moment.utc(this.chartStart).clone().add(monthCount, interval).subtract(1, 'day').valueOf(); // so last day is last of month
    this.visibleMonths = monthCount;
  }

  createChartDimensions(): void {
    const containerHeight = window.innerHeight - this.projectReportsService.chartIntroHeight - (200);
    const containerWidth = document.getElementById('chart-scroll-container').offsetWidth - 40;

    this.projectReportsService.setGraphDimensions(containerHeight, containerWidth, this.xAxisTickCount);
    this.projectReportsService.setChartWrapperDimensions();
    this.projectReportsService.setChartAxes();
  }

  setScales(): void {
    this.allYaxisValues = Object.keys(this.chartData).map(sub => this.chartData[sub].maxCrewSize);
    const maxYvalue = Math.max(...this.allYaxisValues) < 10 ? 10 : Math.max(...this.allYaxisValues) + 1;

    this.yScale = D3.scaleLinear()
      .range([(this.projectReportsService.graphDimensions.height), 0])
      .domain([0, maxYvalue])
      .nice();

    this.xScaleTime = D3.scaleUtc()
      .range([0, this.projectReportsService.graphDimensions.width])
      .domain([this.chartStart, this.chartEnd]);
  }

  drawYaxis(): void {
    const yAxis = D3.axisLeft()
      .scale(this.yScale)
      .tickSize(5)
      .ticks(10)
      .tickFormat(D3.format('d'))
      .tickPadding(10);

    D3.select(this.yAxis.nativeElement)
      .attr('class', 'y-axis')
      .attr('transform', this.projectReportsService.axisData.yAxisTransform)
      .call(yAxis);

    D3.select(this.yAxisLabel.nativeElement)
      .attr('transform', `rotate(-90) ${this.projectReportsService.axisData.yAxisLabelTransform}`)
      .style('text-anchor', 'middle')
      .text(TranslationService.translate('cost'));
  }

  drawXaxis(): void {
    const formatLabel = (date: number) => {
      switch (this.activeChartInterval) {
        case ChartIntervalState.Month : return moment.utc(date).clone().format('M/Y');
        default : return moment.utc(date).clone().format('M/D');
      }
    };

    const xAxis = D3.axisBottom()
      .scale(this.xScaleTime)
      .tickSize(5)
      .tickPadding(10)
      .ticks(this.xAxisTickCount)
      .tickFormat((d) => formatLabel(d));

    D3.select(this.xAxis.nativeElement)
      .attr('class', 'x-axis')
      .attr('transform', this.projectReportsService.axisData.xAxisTransform)
      .call(xAxis);

    D3.select(this.xAxisLabel.nativeElement)
      .attr('transform', this.projectReportsService.axisData.xAxisLabelTransform)
      .text(TranslationService.translate('date_by_unit', { unit: TranslationService.translate(this.activeChartInterval)}));
  }

  setupChartData(charData: IManpowerChartData) {
    const iRrangeData = [];
    const lineGenerator = {
      subLine: D3.line().x((d) => this.xScaleTime(d.xAxisKey)).y((d) => this.yScale(d.crewSize)),
    };

    Object.keys(charData).forEach(day => {
      const dayData = charData[day];
      const yesterday = moment.utc(Number(day)).clone().subtract(1, 'day').valueOf();
      const yesterdayData = charData[yesterday];
      const drawCircle = (Number(day) >= this.chartStart) && (Number(day) <= this.chartEnd);
      const drawLine = (Number(day) > this.chartStart) && (Number(day) < this.chartEnd);
     
      Object.keys(dayData).forEach(key => {
        if (key !== 'maxCrewSize') {
          const yesterDaySubData = yesterdayData ? yesterdayData[key] : null;
          const subData = dayData[key];

          if (drawCircle) {
            if (yesterDaySubData && drawLine) {
              const linePoints = [subData, yesterDaySubData];
              subData.subLine = lineGenerator.subLine(linePoints);
            }

            iRrangeData.push(subData);
          }
        }
      });
    });

    this.drawLinesAndCircles(iRrangeData);
  }

  drawLinesAndCircles(data: IManpowerChartData) {
    const graphContainer = D3.select(this.graphContainer.nativeElement)
      .selectAll('path, circle')
      .remove()
      .exit()
      .data(data);

    graphContainer
      .enter()
      .append('path')
      // need the '_' before class name because classes can't begin with a number
      .attr('class', (d) => d.subInfo.subcontractorId ? `chart-element chart-line _${d.subInfo.subcontractorId}` : `chart-element chart-line`)
      .attr('d', (d) => d.subLine)
      .attr('stroke', (d) => d.subInfo ? d.subInfo.hexCode : GritColors.dkGray)
      .filter((d) => !this.selectedSubcontractors.value.includes(d.subInfo.subcontractorId))
      .style('display', 'none');

    graphContainer
      .enter()
      .append('circle')
      // need the '_' before class name because classes can't begin with a number
      .attr('class', (d) => d.subInfo.subcontractorId ? `chart-element chart-circle _${d.subInfo.subcontractorId}` : `chart-element chart-circle`)
      .attr('fill', (d) => d.subInfo ? d.subInfo.hexCode : GritColors.dkGray)
      .attr('r', '3px')
      .attr('cx', (d) => this.xScaleTime(d.xAxisKey))
      .attr('cy', (d) => this.yScale(d.crewSize))
      .on('mouseover', (d) => this.handlePointHover(d, 'mouseover'))
      .on('mouseout', (d) => this.handlePointHover(d, 'mouseout'))
      .filter((d) => !this.selectedSubcontractors.value.includes(d.subInfo.subcontractorId))
      .style('display', 'none');
  }

  handlePointHover(pointData: any, action: string): void {
    const chartDimensions = this.chartSvgContainer.nativeElement.getBoundingClientRect();
    const distFromRight = Math.abs(D3.event.pageX - chartDimensions.right);
    const distFromBottom = chartDimensions.bottom - D3.event.pageY;
    if (action === 'mouseover') {
      const ttBuffer = 10;
      const ttHeight = 75;
      const ttWidth = 230;
      const adjustFromRight = distFromRight < (ttWidth + ttBuffer) ? true : false;
      const adjustFromBottom = distFromBottom < (ttHeight + ttBuffer) ? true : false;
      const ttPosition = {
        top: adjustFromBottom ? ((D3.event.pageY + ttBuffer) - ttHeight) : D3.event.pageY + ttBuffer,
        left: adjustFromRight ? ((D3.event.pageX - ttBuffer) - ttWidth) : D3.event.pageX + ttBuffer
      };

      D3.selectAll('.D3-tooltip')
        .style('left', ttPosition.left + 'px')
        .style('top', ttPosition.top + 'px')
        .style('height', ttHeight + 'px')
        .style('width', ttWidth + 'px')
        .style('display', 'flex')
        .html(this.drawTooltip(pointData));

    } else if (action === 'mouseout') {
      D3.selectAll('.D3-tooltip')
        .style('display', 'none');
    }
  }

  drawTooltip(data: any) {
    let tooltip = ``;
    if (data.xAxisKey) tooltip += this.getTTlabel(data.xAxisKey);
    if (data.crewSize) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('crew_size') + `: </span><span class='value'>` + data.crewSize + `</span></div>`;
    // tslint:disable-next-line:max-line-length
    if (data.subInfo && data.subInfo.name) tooltip += `<div class='data-point'><span class='label'>` + TranslationService.translate('subcontractor') + `: </span><span class='value'>` + data.subInfo.name + `</span></div>`;
    return tooltip;
  }

  getTTlabel(day: number): string {
    switch (this.activeDisplayMode) {
      case ViewMode.ThreeWeekSchedule :
        // tslint:disable-next-line:max-line-length
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('week_of') + `: </span><span class='value'>${moment.utc(day).clone().format('MM/DD/YY')}</span></div>`;
      case ViewMode.SixWeekSchedule :
      case ViewMode.TwelveMonthGantt :
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('month_of') + `: </span><span class='value'>${moment.utc(day).clone().format('MMM.')}</span></div>`;
      default :
        return `<div class='data-point title'><span class='label'>` + TranslationService.translate('date') + `: </span><span class='value'>${moment.utc(day).clone().format('MM/DD/YY')}</span></div>`;
    }
  }

  drawColumns(data: any): void {
    const colWidth = this.xScaleTime(this.chartStart) + this.xScaleTime(moment.utc(this.chartStart).clone().add(1, 'month').valueOf()); // sets cell width to the scaled width of a day
    const rangeKeys = Object.keys(data).map(key => Number(key));
    const colContainer = D3.select(this.columnContainer.nativeElement)
      .selectAll('rect')
      .remove()
      .exit()
      .data(rangeKeys);

    if (this.activeChartInterval === ChartIntervalState.Month) {
      colContainer
        .enter()
        .append('rect')
        .attr('class', 'column')
        .attr('transform', this.projectReportsService.graphDimensions.transform)
        .attr('height', this.projectReportsService.graphDimensions.height)
        .attr('width', colWidth)
        .attr('fill', 'none')
        .attr('x', (d) => this.xScaleTime(d))
        .on('click', (d) => this.handleColumnClick(d));
    }
  }

  handleColumnClick(xAxisKey: number): void {
    this.setChartDisplayMode(ViewMode.OneMonthGantt);
    this.chartStart = moment.utc(xAxisKey).clone().startOf('month').valueOf();
    this.setXaxisData(this.chartStart);
    this.setChartControls();
    this.getScheduleData(this.chartStart, this.chartEnd);
  }

  handleTodayCtrl(): void {
    this.chartStart = moment.utc(Utils.getCurrentDate()).startOf('week').valueOf(); 
    this.setXaxisData(this.chartStart);
    this.setChartControls();
    this.getScheduleData(this.chartStart, this.chartEnd);
  }
  handleRangeCtrl(direction: string): void {
    if (direction === 'next') {
      this.chartStart = moment.utc(this.chartStart).clone().add(this.visibleMonths, 'month').valueOf();
      this.setXaxisData(this.chartStart);
      this.setChartControls();
      this.getScheduleData(this.chartStart, this.chartEnd);
    } else {
      this.chartStart = moment.utc(this.chartStart).clone().subtract(this.visibleMonths, 'month').valueOf();
      this.setXaxisData(this.chartStart);
      this.setChartControls();
      this.getScheduleData(this.chartStart, this.chartEnd);
    }
  }
  handleStartFinishCtrl(isStart: boolean): void {
    if (isStart) {
      this.chartStart = moment.utc(this.scheduleStartDate).startOf('month').clone().valueOf();
      this.setXaxisData(this.chartStart);
      this.setChartControls();
      this.getScheduleData(this.chartStart, this.chartEnd);
    } else {
      this.chartStart = moment.utc(this.scheduleEndDate).clone().subtract(this.visibleMonths, 'month').valueOf();
      this.setXaxisData(this.chartStart);
      this.setChartControls();
      this.getScheduleData(this.chartStart, this.chartEnd);
    }
  }

  switchViewClick(viewMode: ViewMode): void {
    this.setChartDisplayMode(viewMode);
    this.setXaxisData(this.chartStart);
    this.setChartControls();
    this.getScheduleData(this.chartStart, this.chartEnd);
  }

  handleSubcontractorSelection(subcontractor: any): void {
    subcontractor.selected = !subcontractor.selected;
    if (!subcontractor.selected) D3.selectAll(`._${subcontractor.id}`).style('display', 'none');
    else {
      D3.selectAll(`._${subcontractor.id}`).style('display', 'block');
    }
  }

  resize(): void {
    this.setXaxisData();
    this.drawChart();
  }

  refresh(): void {
    window.location.reload(true);
  }

}
