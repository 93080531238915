
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Utils } from '../../utils/utils';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectUserService } from '../../services/project/project-user/project-user.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { FormGroup } from '@angular/forms';
import { IActivity } from '../../models/activity/activity.interface';
import { IGritTable, IGRow } from '../../shared/grit-table/grit-table';
import { IMatAutoComplete } from '../../models/material/material-components.interface';
import { Permission } from '../../utils/enums/permission.enum';
import { IProjectUser } from '../../models/project/project-user/project-user.interface';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-project-user',
  templateUrl: './project-user.component.html',
  styleUrls: ['./project-user.component.scss']
})
export class ProjectUserComponent implements OnInit, OnDestroy {

  @Input() projectPermissionInput: IUserPermission;

  isLoading = true;

  projectId: string;
  projectUsers: IProjectUser[] = [];

  // table
  tableData: IGritTable;

  // modal
  showModal = false;
  formInput: FormGroup;
  subAutoInput: IMatAutoComplete;
  allActivities: IActivity[];
  userAutoInput: IMatAutoComplete;

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private projectUserService: ProjectUserService,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.projectId = params['projectId'];
      this.loadPage();
      SegmentService.track('Project Users Loaded', { projectId: this.projectId });
    });
  }

  loadPage(): void {
    this.isLoading = true;
    this.projectUserService.getProjectUserList(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      projectUsers => {
        for (const u of projectUsers) {
          if (u.subContractorId == null) {
            u.subContractorId = '-1'; // GC user
            u.subContractor = this.projectService.currentProjectAccount.name;
          }
        }
        this.projectUsers = Utils.sortByString(projectUsers, 'email');
        this.setTableData();
        this.isLoading = false;
      },
      (err) => {
        this.notificationService.error(err, {});
        this.isLoading = false;
      });
  }

  setTableData() {
    this.tableData = this.projectUserService.transformToTableData(this.projectUsers, this.projectPermissionInput);
  }

  ngOnDestroy(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async showCreateModal(): Promise<void> {
    this.formInput = await this.projectUserService.buildFormInput(this.projectId, this.projectPermissionInput);
    this.subAutoInput = this.projectUserService.buildSubAutoInput(this.projectService.currentProjectAccount.name);
    this.userAutoInput = this.projectUserService.buildUserAutoInput();

    this.showModal = true;
  }

  formOutput(user: {email: string, subContractorId: string, permission: Permission, projectId: string}): void {
    this.isLoading = true;
    this.showModal = false;
    user.projectId = this.projectId;
    SegmentService.track('Project User Created', { email: user.email, projectId: user.projectId });
    this.projectUserService.addProjectUser(user).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.loadPage();
      },
      err => {
        this.notificationService.error(err, {});
        this.isLoading = false;
      });
  }

  delete(rowIds: string[]) {
    this.isLoading = true;
    this.projectUserService.deleteProjectUsers(rowIds, this.projectId).subscribe(
      () => {
        SegmentService.track('Project User Deleted', { ids: rowIds });
        rowIds.forEach(id => {
          this.projectUsers.splice(this.projectUsers.findIndex(user => user.id === id), 1);
        });
        this.isLoading = false;
        this.notificationService.success('SUCCESSFUL_DELETE', {});
      },
      err => {
        this.notificationService.error(err, {type: 'project user(s)', action: 'delete'});
        this.loadPage();
      }
    );
  }

  editProjectUserRow(row: IGRow): void {
    this.isLoading = true;
    const userPermission = row.rowItems.find(ri => ri.colKey === 'permission');
    if (!userPermission || !userPermission.value) return;

    const json = {
      permission: userPermission.value,
      projectId: this.projectId
    };
    const userId = row.key;

    this.projectUserService.updateProjectUser(userId, json).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Project Users Edited', { projectId: this.projectId, userId: userId });
        this.isLoading = false;
      },
      err => {
        this.notificationService.error(err, {type: 'project user(s)', action: 'update'});
        this.loadPage();
      }
    );
  }
}
