import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { ProjectMilestoneService } from '../../../services/project/project-milestone/project-milestone.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';

import { IActivity, IActivityFormOutput, IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';

import { Utils } from '../../../utils/utils';
import { BulkEditSelection } from '../../../utils/enums/shared.enum';


@Component({
  selector: 'app-project-activities-form',
  templateUrl: './project-activities-form.component.html',
  styleUrls: ['./project-activities-form.component.scss']
})
export class ProjectActivitiesFormComponent implements OnInit, OnDestroy {

  @Input() formInput: FormGroup;
  @Input() allActivities: IMasterScheduleActivity[];

  @Output() activityOutput: EventEmitter<{ data: IActivityFormOutput[], valid: boolean }> = new EventEmitter();

  // Template Variables
  subcontractorOptions;
  milestoneOptions;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // returns specific form control by label
  get ids() { return this.formInput.get('ids').value; }
  get names() { return this.formInput.get('names').value; }
  get externalActivityId() { return this.formInput.get('externalActivityId'); }
  get durationEditEnable() { return this.formInput.get('durationEditEnable'); }
  get duration() { return this.formInput.get('duration'); }
  get crewSize() { return this.formInput.get('crewSize'); }
  get subcontractorControl() { return this.formInput.get('subContractor'); }
  get subcontractorDisplayFn() { return Utils.defaultDisplayFn; }
  get milestoneControl() { return this.formInput.get('milestone'); }
  get milestoneDisplayFn() { return Utils.defaultDisplayFn; }
  get anchorDate() { return this.formInput.get('anchorDate'); }

  constructor(
    private subContractorService: ProjectSubContractorService,
    private milestoneService: ProjectMilestoneService
  ) {/*EMPTY*/ }

  ngOnInit() {
    const projectSubcontractors = Utils.sortByString(this.subContractorService.getLocalProjectSubcontractors(), 'name');
    this.subcontractorOptions = this.formInput.controls['subContractor'].valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith<string | IProjectSubContractor>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.subSearch(name) : projectSubcontractors.slice(0, Math.min(projectSubcontractors.length, 50)))
    );

    const projectMilestones = Utils.sortByString(this.milestoneService.getLocalProjectMilestones(), 'name');
    this.milestoneOptions = this.formInput.controls['milestone'].valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith<string | any>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.milestoneSearch(name) : projectMilestones.slice(0, Math.min(projectMilestones.length, 50)))
    );

    this.formInput.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onSubmit(this.formInput);
    });

    // Called to update edit modal on load

    this.onSubmit(this.formInput);
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  onSubmit(form, firstAddingNew?): void {

    let formOutpurArray: IActivityFormOutput[] = [];
    let index = 0;

    this.ids.forEach(element => {
      const formValues = form.getRawValue();

      let parentId;
      let updatedDuration;
      let oldPrerequisites;
      let oldSubcontractorId;
      let oldMileStoneId;

      this.allActivities.forEach(activtyItem => {
        if (activtyItem.activity.id === element) {
          parentId = activtyItem.activity.parentId;
          updatedDuration = activtyItem.activity.duration;
          oldPrerequisites = activtyItem.activity.prerequisites;
          oldSubcontractorId = activtyItem.subContractorId;
          oldMileStoneId = activtyItem.milestoneId;
        }
      });

      if(!this.duration.disabled)    
        updatedDuration = this.duration.value;      

      const formActivity: IActivity = {
        id: element,
        parentId: parentId,
        projectId: this.formInput.get('projectId').value,
        externalActivityId: this.formInput.get('externalActivityId').value,
        name: this.names[index++],
        prerequisites: oldPrerequisites,
        duration: updatedDuration,
        startDate: formValues.startDate ? Utils.toUtcDate(formValues.startDate) : null,
        endDate: formValues.endDate ? Utils.toUtcDate(formValues.endDate) : null,
        crewSize: this.crewSize.value,
        anchorDate: formValues.anchorDate ? Utils.toUtcDate(formValues.anchorDate) : null
      };

      const subValue = this.subcontractorControl.value;
      const milestoneValue = this.milestoneControl.value;

      const formOutput: IActivityFormOutput = {
        activity: formActivity,
        subContractorId: subValue && subValue.id ? subValue.id : oldSubcontractorId,
        milestoneId: milestoneValue && milestoneValue.id ? milestoneValue.id : oldMileStoneId,
        bulkEditSelection: BulkEditSelection.unchecked,
      };

      if (!formOutput.milestoneId && milestoneValue && milestoneValue.length > 0) formOutput.milestoneName = milestoneValue.toString();

      formOutpurArray.push(formOutput);
    });

    let valid = this.formInput.valid;
    if (firstAddingNew) valid = false;
    this.activityOutput.emit({ data: formOutpurArray, valid: valid });
  }

  subSearch(name: string): IProjectSubContractor[] {
    const projectSubcontractors = Utils.sortByString(this.subContractorService.getLocalProjectSubcontractors(), 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = projectSubcontractors.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  milestoneSearch(name: string): any[] {
    const projectMilestones = Utils.sortByString(this.milestoneService.getLocalProjectMilestones(), 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = projectMilestones.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }
}
