
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { MenuEmitterService } from '../../services/menu/menu-emitter.service';
import { MenuService } from '../../services/menu/menu.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';
import { Utils } from '../../utils/utils';
import { UtilsService } from '../../services/utils/utils.service';

import { IAccount } from '../../models/account/account.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { IPageHeader } from '../../models/page-header/page-header.interface';
import { IProject } from '../../models/project/project.interface';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {
  createForm: IProject;
  formInput: FormGroup;
  get name() { return this.formInput.get('name'); }
  get startDate() { return this.formInput.get('startDate'); }
  get timeZone() { return this.formInput.get('timeZone'); }
  get unitOfMeasure() { return this.formInput.get('unitOfMeasure'); }
  get currency() { return this.formInput.get('currency'); }
  pageHeader: IPageHeader = {
    title: {
      value: 'Create A New Project',
      editable: false
    },
    icon: 'fa-building'
  };
  pageIsLoading: boolean = true;
  creating: boolean = false;

  // dropdowns
  accountDropdownItems: IDropdownItem[];
  timeZoneArray: string[];
  timeZoneDropdownItems: Observable<string[]>;
  currencyDropdownItems: IDropdownItem[] = [];
  filteredCurrencyDropdownItems: IDropdownItem[] = [];
  projectCurrency: string = '';
  currencyCode: string;

  // values
  selectedAccountId: string;
  projectName: string;
  address: string;
  defaultPlanningLeadTime: number = 30;

  constructor(
    private accountService: AccountService,
    public menuService: MenuService,
    private formBuilderService: FormBuilder,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private segmentService: SegmentService,
    private router: Router,
    private userSerice: UserService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const requiredFields = ['name', 'startDate', 'timeZone'];
    this.formInput = this.buildFormInput(this.createForm, requiredFields);
    this.formInput.controls['timeZone'].setValue(moment.tz.guess());
    this.formInput.controls['unitOfMeasure'].setValue(false);
    this.formInput.controls['currency'].setValue('USD');
    this.currencyCode = 'USD';

    const observableArray: Array<Observable<any>> = [];
    observableArray.push(this.accountService.getAccountList());
    observableArray.push(this.utilsService.getTimeZones());

    this.currencyDropdownItems = this.buildCurrencyDropdownItems(this.projectService.getCurrencyCodes());
    this.filteredCurrencyDropdownItems = this.currencyDropdownItems;

    forkJoin(observableArray).subscribe(results => {
      this.accountDropdownItems = this.buildAccountDropdownItems(results[0]);
      this.timeZoneDropdownItems = this.buildTimeZoneDropdownItems(results[1]);
      this.pageIsLoading = false;
    });
  }

  buildCurrencyDropdownItems(currencies): IDropdownItem[] {
    return currencies.map(currency => ({ value: currency.code, label: currency.name }));
  }

  accountSelected(accountId: string) {
    this.selectedAccountId = accountId[0];
  }

  buildTimeZoneDropdownItems(timezones: string[]): Observable<string[]> {
    this.timeZoneArray = timezones;
    return this.formInput.controls['timeZone'].valueChanges
    .pipe(
      startWith(''),
      map(value => this.timeZoneFilter(value))
    );
  }

  buildAccountDropdownItems(accounts: IAccount[]): IDropdownItem[] {
    accounts = Utils.sortByString(accounts, 'name');
    return accounts.map(account => ({ value: account.id, label: account.name }));
  }

  createProject(form) {
    const formValues = form.getRawValue();
    if (this.accountDropdownItems.length === 1) this.selectedAccountId = this.accountDropdownItems[0].value;

    if (Utils.isEmpty(this.selectedAccountId) || Utils.isEmpty(formValues.name) || Utils.isEmpty(formValues.startDate)) {
      return this.notificationService.error('APP_REQUIRED_FIELDS_MISSNG', {});
    }

    this.creating = true;

    const project: IProject = {
      name: formValues.name,
      accountId: this.selectedAccountId,
      startDate: formValues.startDate ? Utils.toUtcDate(formValues.startDate) : null,
      timeZone: formValues.timeZone,
      planningLeadTime: 30,
      isMetric: formValues.unitOfMeasure,
      currency: this.currencyCode
    };

    if (!Utils.isEmpty(formValues.address)) project.address = formValues.address;

    this.projectService.createProject(project).subscribe(
      res => {
        this.userSerice.getAuthenticatedUser().subscribe(user => {
          this.segmentService.track('Project Created', {id: res.id, bim360: false});
          MenuEmitterService.emitAuthenticatedUserChange(user);
          this.router.navigateByUrl('/project/' + res.id);
        });
      });
  }

  dateSet(event: any) {
    this.formInput.controls['startDate'].setValue(event.value);
  }

  buildFormInput(formInput: IProject, requiredFormOptions: string[]): FormGroup {
    const projectFormInput = this.formBuilderService.group({
      name: [null, Validators.required],
      startDate: [null, Validators.required],
      timeZone: [null, Validators.required],
      address: [null],
      unitOfMeasure: [null, Validators.required],
      currency: [null, Validators.required]
    });
    requiredFormOptions.forEach(option => {
      if (projectFormInput.controls[option].validator) {
        projectFormInput.controls[option].setValidators([projectFormInput.controls[option].validator, Validators.required]);
      } else {
        projectFormInput.controls[option].validator = Validators.required;
      }
    });

    return projectFormInput;
  }

  private timeZoneFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.timeZoneArray.filter(tz => tz.toLowerCase().includes(filterValue));
  }

  timeZoneAutoSelect(event) {
    setTimeout(() => {
      const text = event.target.value;
      const timeFilter = this.timeZoneFilter(text);

      if (timeFilter.length === 0 || Utils.isEmpty(text)) {
        this.formInput.controls['timeZone'].setValue(moment.tz.guess());
      } else if (timeFilter.includes(text)) {
        this.formInput.controls['timeZone'].setValue(text);
      } else {
        this.formInput.controls['timeZone'].setValue(timeFilter[0]);
      }
    },         500);
  }

  currencyAutoSelect(event) {
    setTimeout(() => {
      const text = event.target.value;
      const currencyValue = this.currencyDropdownItems.find(curreucy => curreucy.value.toLowerCase() === text.toLowerCase());
      if (!currencyValue) return this.currency.setValue('');
      const currency = this.projectService.getCurrencyCodes(currencyValue.value);
      this.currencyCode = currency.code;
    },         100);
  }

  filterCurrencies() {
    if (this.currency) {
      this.filteredCurrencyDropdownItems = this.currencyDropdownItems.filter(currency => currency.label.toLowerCase().includes(this.currency.value.toLowerCase()));
    } else {
      this.filteredCurrencyDropdownItems = this.currencyDropdownItems;
    }
  }

}
