import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() showConfirmationModal: boolean = false;
  @Input() confirmationModalInput: IConfirmationModalInput;
  @Input() enableConfirmationButton: boolean;

  @Output() closeClickOutput = new EventEmitter();
  @Output() confirmationClickOutput = new EventEmitter();
  @Output() cancelClickOutput = new EventEmitter();
  @Output() deleteClickOutput = new EventEmitter();

  // Template Variables
  hasFocus: boolean = true;
  customContent: boolean = false;
  confirmLabel: string = 'confirm';
  deleteLabel: string = 'delete';

  // tslint:disable-next-line:no-empty
  constructor(
    private elementRef: ElementRef
  ) { }

  // tslint:disable-next-line:no-empty
  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  keyPress = (event) => {
    if (!this.hasFocus) return;
    if (event.key === 'Enter') {
      if (this.confirmationModalInput.hasDeleteAction) { this.handleDeleteClick(); return; }
      if (this.confirmationModalInput.hasConfirmationAction) this.handleConfirmClick();
    }
    if (event.key === 'Escape') {
      this.handleCancelClick();
    }
  }
  // runs after checking if the child contents has loaded on ngAfterContentChecked ONCE
  // (careful ngAfterContentChecked runs every change on the directive/componet its placed in and on every ngDoChecks)
  ngAfterViewInit() {
    // on initialization if there is an input available set the focus to the first input found;
    if (this.elementRef.nativeElement.getElementsByTagName('input').length > 0) {
      const firstInput = this.elementRef.nativeElement.getElementsByTagName('input')[0];
      firstInput.focus(); // sets user focus to the input
      firstInput.select(); // sets the cursor select to the first input box
    }
  }

  ngOnChanges() {
    if (this.confirmationModalInput.customContent) {
      this.customContent = true;
    }
    if (this.confirmationModalInput.customConfirmLabel) {
      this.confirmLabel = this.confirmationModalInput.customConfirmLabel;
    }
    if (this.confirmationModalInput.customDeleteLabel) {
      this.deleteLabel = this.confirmationModalInput.customDeleteLabel;
    }
    if (Utils.isEmpty(this.enableConfirmationButton)) this.enableConfirmationButton = true;
  }

  handleCloseClick(): void {
    this.showConfirmationModal = false;
    this.closeClickOutput.emit();
  }

  handleConfirmClick(): void {
    if (this.enableConfirmationButton) this.confirmationClickOutput.emit();
  }

  handleCancelClick(): void {
    this.showConfirmationModal = false;
    this.cancelClickOutput.emit();
  }

  handleDeleteClick(): void {
    this.deleteClickOutput.emit();
  }
}
