import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../http-backend/http-backend.service';

import { IAccount } from '../../models/account/account.interface';
import { Utils } from '../../utils/utils';

@Injectable()
export class ProcoreService {

  private origin: string = null;

  constructor(private httpService: HttpBackendService) {
  }

  integrateProcore(json: any): Observable<any> {
    return this.httpService.post('/procore/credentials', json);
  }

  removeProcore(accountId: string): Observable<any> {
    return this.httpService.delete(`/procore/${accountId}`);
  }

  public getAuthUrl(state: string): Observable<any> {
    return this.httpService.get('/procore/oauth/url?state=' + state);
  }

  public postAuthCode(accountId: string, code: string): Observable<any> {
    return this.httpService.post('/procore/oauth/code', {accountId, code});
  }

  public getTree(accountId: string, projectId:string, json:any):Observable<any> {
    return this.httpService.post('/procore/' + accountId + '/filefolder?projectId='+projectId,json);
  }

  public getTreeDrawings(accountId: string, projectId:string, json:any):Observable<any> {
    return this.httpService.post('/procore/' + accountId + '/fileFolderDrawings?projectId='+projectId,json);
  }
  
  public getTreeDrawingsByDiscipline(accountId: string, projectId:string, json:any):Observable<any> {
    return this.httpService.post('/procore/' + accountId + '/fileFolderDrawingsByDiscipline?projectId='+projectId,json);
  }

  public  linkFloorPlanPDF(projectId:string, json:any):Observable<any> {
    return this.httpService.post('/procore/linkFloorPlan?projectId='+projectId,json);
  }

  public  getFloorPlanPDFsURL(projectId:string):Observable<any> {
    return this.httpService.get('/procore/getFloorPlansURL?projectId='+projectId);
  }

  public getAllFloorPlan(projectId:string){
    return this.httpService.get('/procore/getAllFloorPlan?projectId='+projectId);
  }

  public  removeFloorPlanPDF( projectId:string, json:any):Observable<any> {
    return this.httpService.post('/procore/removeFloorPlan?projectId='+projectId,json);
  }

  public changeOrder(projectId: string, json: any):Observable<any> {
    return this.httpService.post('/procore/changeOrder?projectId='+projectId,json);    
  }

  async getProcoreHost(accountId: string): Promise<string> {
    const account: IAccount = await this.httpService.get('/account/' + accountId).toPromise();
    if (!Utils.isEmpty(account.procoreOverride)) return account.procoreOverride;
    else if (!Utils.isEmpty(window.location.origin) && window.location.origin.indexOf('app.gritvirtual.com') >= 0) return 'https://api.procore.com';
    else return 'https://sandbox.procore.com';
  }

  getProcoreProjects(accountId: string, projectId: string): Observable<any[]> {
    return this.httpService.get(`/procore/${accountId}/projects?projectId=${projectId}`);
  }

  linkProcoreProject(projectId: string, json: any): Observable<any[]> {
    return this.httpService.post('/project/' + projectId + '/procore/link', json);
  }

  syncProcoreProject(projectId: string): Observable<any[]> {
    return this.httpService.post('/procore/dailyLog', {projectId: projectId});
  }

  importProcoreSubmittals(projectId: string): Observable<any[]> {
    return this.httpService.post('/project/' + projectId + '/submittals', {});
  }

  getProcoreCompanies(accountId: string): Observable<any[]> {
    return this.httpService.get('/account/' + accountId + '/procore/companies');
  }

}
