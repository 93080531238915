import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpBackendService } from '../http-backend/http-backend.service';
import { Utils } from '../../utils/utils';

@Injectable()
export class AuthenticationVerificationService {

    constructor(private _http: HttpBackendService) { }

    public verifyUser(json: any): Observable<any> {
        return this._http.post('/verify', json);
    }

    public rejectVerification(userId: string, verificationId: string): Observable<any> {
        const json = {
            'userId': userId,
            'verificationNumber': verificationId
        };
        return this._http.post('/reject', json);
    }
}
