import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as D3 from 'd3-ng2-service';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { ProjectService } from '../../../services/project/project.service';
import { TranslationService } from '../../../services/translation/translation.service';

import { NetworkMapView } from '../../../utils/enums/networkmap-view.enum';
import { IUserPermission } from '../../../models/user/user.interface';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-network-map-viewer',
  templateUrl: './project-network-map-viewer.component.html',
  styleUrls: ['./project-network-map-viewer.component.scss']
})

export class ProjectNetworkMapViewerComponent implements OnInit, OnChanges {

  @Input() projectId: string;
  @Input() networkMapTreeInput: any;
  @Input() totalActiveNodes: number;
  @Input() totalActiveDuration: number;
  @Input() subContractors;
  @Input() nodeExpansionInput: any;
  @Input() hideMap: boolean;
  @Input() influenceStats: { steps: number, hours: number, subContractorData: any[] };
  @Input() criticalPathStats: { steps: number, hours: number, subContractorData: any[] };
  @Input() blockerStats: { steps: number, hours: number, subContractorData: any[] };
  @Input() sideBarOpen: boolean = false;

  @Output() mapHoverOutput = new EventEmitter<string[]>();
  @Output() mapDblClickOutput = new EventEmitter<any>();
  @Output() nodeExpansionOutput = new EventEmitter<any>();
  @Output() nodeCollapseOutput = new EventEmitter<any>();
  @Output() viewSelectOutput = new EventEmitter<any>();

  ranInit: boolean = false;
  SIDEBAR_WIDTH: number = 300;

  elementRef;
  networkMapViewerContainer;
  noStepClickedMessage = 'select_object';

  // D3 elements
  d3: D3.D3;
  svgElement = null;
  networkMapTree;
  circles;
  verticeRadius = 12;
  lines;
  labels;
  endNodes;
  graphContainer;
  tooltip: any;
  filteredObjectIds = [];
  activeNodeData = null;
  mapObjectIds = [];
  d3Zoom = null;
  mapClickInterval: number = 0;
  waitingForDblClick: boolean = false;
  disableDrag: boolean = false;
  alternateViewMessage: string;
  canvasBoundingBox: any = null;
  maxToolTipWidth: number = 200;
  maxToolTipHeight: number = 250;
  mapView: number = 0;

  constructor(
    elementRef: ElementRef,
    private forgeViewerService: ForgeViewerService,
    private d3Service: D3.D3Service,
    private projectService: ProjectService
  ) {
    this.d3 = d3Service.getD3();
    this.elementRef = elementRef.nativeElement;
  }

  static setLineColor(lineData: any): any {
    if (!Utils.isEmpty(lineData.criticality))
      return Utils.getCriticalityColor(lineData.criticality);

    return 'grey';
  }

  ngOnInit() {
    if ( (this.elementRef !== null) || !this.ranInit) this.initializeMap();
  }

  ngOnChanges() {
    this.setAlternateMessage();
    if (!this.ranInit) this.initializeMap();

    if (!Utils.isEmpty(this.nodeExpansionInput)) {

      const base = this.nodeExpansionInput.base;
      if (!Utils.isEmptyList(this.nodeExpansionInput.nodes)) {
        this.nodeExpansionInput.nodes.sort((a, b) => b.criticality - a.criticality);
        this.nodeExpansionInput.nodes.forEach(node => {
          this.addToParent(base.vId, base, node, base.stepData.type, 'node', true);
          node.objectIds.forEach(id => this.mapObjectIds.push({id: id, type: base.stepData.type}));
        });
      } else if (Utils.isEmptyList(this.nodeExpansionInput.unplanned)) {
        this.addToParent(base.vId, base, {}, base.stepData.type, 'end', false);
      }

      if (!Utils.isEmptyList(this.nodeExpansionInput.unplanned)) {
        this.nodeExpansionInput.unplanned.forEach(node => {
          this.addToParent(base.vId, base, node, base.stepData.type, 'node', false);
          this.mapObjectIds.push({id: node.objectId, type: base.stepData.type});
        });
      }

      this.reposition(this.networkMapTree.vertex, base.stepData.type);
      this.redraw();
      this.nodeExpansionInput = null;
      base.eventListen = true;
    } else if (!Utils.isEmpty(this.networkMapTreeInput)) {

      this.resetMap();
      this.setupNodeTree(this.networkMapTreeInput);
      this.defineTooltip();
      this.networkMapTreeInput = null;
    }
  }

  initializeMap() {
    this.ranInit = true;
    // add svg elements to canvas
    this.svgElement = this.d3.select('svg');
    this.d3.select('#network-map-viewer').append('g').attr('id', 'g-graph-container');
    this.d3.select('#g-graph-container').append('g').attr('id', 'g-lines');
    this.d3.select('#g-graph-container').append('g').attr('id', 'g-circles');
    this.d3.select('#g-graph-container').append('g').attr('id', 'g-rects');
    this.d3Zoom = this.d3.zoom().scaleExtent([1 / 10, 8]).on('zoom', () => this.zoom());
    this.tooltip = this.d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);
    this.networkMapViewerContainer = this.elementRef.querySelector('#network-map-viewer');
  }

  setupNodeTree(initialData): void {
    this.canvasBoundingBox = this.networkMapViewerContainer.getBoundingClientRect();
    initialData.active.type = 'active';
    this.networkMapTree = {
      w: 40,
      h: 70,
      size: 1,
      vertex: {
        vId: 0,
        label: '?',
        stepData: initialData.active,
        point: {
          x: this.canvasBoundingBox.width / 2,
          y: this.canvasBoundingBox.height / 2
        },
        shapeType: 'node',
        planned: true,
        eventListen: true,
        children: []
      }
    };

    initialData.active.objectIds.forEach(object => this.mapObjectIds.push({id: object, type: 'active'}));

    const firstPointVertex = this.networkMapTree.vertex;

    if (!Utils.isEmptyList(initialData.prereqs)) {
      initialData.prereqs.sort((a, b) => b.criticality - a.criticality);
      initialData.prereqs.forEach(item =>  {
        this.addToParent(firstPointVertex.vId, firstPointVertex, item, 'prerequisite', 'node', true);
        item.objectIds.forEach(id => this.mapObjectIds.push({id: id, type: 'prerequisite'}));
      });
    } else if (Utils.isEmptyList(initialData.unplannedPrereqs)) {
      this.addToParent(firstPointVertex.vId, firstPointVertex, {}, 'prerequisite', 'end', false);
    }

    if (!Utils.isEmptyList(initialData.unplannedPrereqs)) {
      initialData.unplannedPrereqs.forEach(item => {
        this.addToParent(firstPointVertex.vId, firstPointVertex, item, 'prerequisite', 'node', false);
        this.mapObjectIds.push({id: item.objectId, type: 'prerequisite'});
      });
    }

    if (!Utils.isEmptyList(initialData.successors)) {
      initialData.successors.sort((a, b) => b.criticality - a.criticality);
      initialData.successors.forEach(item => {
        this.addToParent(firstPointVertex.vId, firstPointVertex, item, 'successor', 'node', true);
        item.objectIds.forEach(id => this.mapObjectIds.push({id: id, type: 'successor'}));
      });
    } else {
      this.addToParent(firstPointVertex.vId, firstPointVertex, {}, 'successor', 'end', false);
    }

    this.activeNodeData = initialData.active;
    this.reposition(firstPointVertex, 'prerequisite');
    this.reposition(firstPointVertex, 'successor');
    this.redraw();
  }

  // Action Methods
  zoom(): void {
    if (!this.disableDrag) {
      this.graphContainer.attr('transform', this.d3.event.transform);
    }
  }

  // this is intermdate method to prevent click/dblclick/drag events from colliding
  private handleUserMouseAction(): (d, i) => void {
    return (d) => {
      const clickTimeStamp = this.d3.event.timeStamp;
      const clickTimeTolerance = 300;
      this.mapClickInterval = clickTimeStamp - this.mapClickInterval;
      this.waitingForDblClick = true;

      setTimeout(
        () => {
          const dblClickDetected = this.mapClickInterval < clickTimeStamp;

          if (this.waitingForDblClick === true) {
            if (dblClickDetected) {
              this.handleMouseDblClick(d);
            } else {
              this.handleMouseClick(d);
            }
          }

          // resets
          this.mapClickInterval = 0;
          this.waitingForDblClick = false;
        },
        clickTimeTolerance
      );
    };
  }

  private handleMouseOver(): (d, i) => void {
    return (d) => {
      this.disableDrag = true;
      let activeObjectIds = null;

      if (!d.planned && d.stepData.type === 'prerequisite') {
        activeObjectIds = [d.stepData.objectId];
      } else {
        activeObjectIds = d.stepData.objectIds;
      }

      // select node(s) by stepId class, if no step data, get target from d3 hover event
      const hoveredD3Nodes = d.stepData.id
        ? this.d3.selectAll('.z' + d.stepData.id)
        : this.d3.select(this.d3.event.currentTarget);

      hoveredD3Nodes
        .style('fill', this.forgeViewerService.hoverColorHex)
        .attr('r', this.verticeRadius + 5)
        .style('stroke-width', d.stepData.type === 'active' ? '8px' : '4px');
      this.showTooltip(d);
      this.mapHoverOutput.emit(activeObjectIds);
    };
  }

  private handleMouseOut(): (d, i) => void {
    return (d) => {
      this.disableDrag = false;
      const stepId = d.stepData.id;

      this.hideTooltip();
      // remove highlight of each node with that stepId as a class
      this.d3.selectAll('.z' + stepId)
        .style('fill', this.setNodeColor(d.stepData, d.planned))
        .attr('r', this.verticeRadius)
        .style('stroke-width', d.stepData.type === 'active' ? '4px' :  '2px');
      this.mapHoverOutput.emit();
    };
  }

  private handleMouseDblClick(clickedNode: any): void {
    if (clickedNode.planned) {
      this.hideTooltip();
      this.disableDrag = false;
      const selectedStepData = clickedNode.stepData;
      this.mapDblClickOutput.emit(selectedStepData);
      clickedNode.eventListen = false;
    }

  }

  private handleMouseClick(clickedNode: any): void {
    if (clickedNode.children.length === 0 && clickedNode.planned && clickedNode.eventListen) {
      this.nodeExpansionOutput.emit(clickedNode);
      clickedNode.eventListen = false;
    } else if (clickedNode.children.length > 0 && clickedNode.stepData.type !== 'active' && clickedNode.eventListen) {

      this.filteredObjectIds = this.mapObjectIds;
      this.removeChildrenFromParent(clickedNode.vId, this.networkMapTree.vertex);

      this.nodeCollapseOutput.emit(this.filteredObjectIds);

      this.d3.select('#g-lines').selectAll('line').remove();
      this.d3.select('#g-circles').selectAll('circle').remove();
      this.d3.select('#g-rects').selectAll('rect').remove();

      this.reposition(this.networkMapTree.vertex, clickedNode.stepData.type);
      this.redraw();
      this.mapObjectIds = this.filteredObjectIds;
      this.filteredObjectIds = [];
    }
  }
  // END Action Methods

  // Draw Methods
  defineEndNodes(): void {
    this.endNodes = this.d3.select('#g-rects').selectAll('rect').data(this.getNodePositionAndStepData(this.networkMapTree.vertex, {}, 'end'));
    this.endNodes.transition().duration(500)
      .attr('x', (d: any) => d.point.x)
      .attr('y', (d: any) =>  d.point.y - this.verticeRadius)
      .attr( 'transform', (d) => this.rotateEndNodes(d));

    this.endNodes.exit().remove();

    this.endNodes.enter()
      .append('rect')
      .attr('x', (d) => d.point.x)
      .attr('y', (d) =>  d.point.y - this.verticeRadius)
      .attr('width', this.verticeRadius * 2)
      .attr( 'height', this.verticeRadius * 2)
      .attr( 'transform', (d) => this.rotateEndNodes(d))
      .style('fill', 'black')
      .style('stroke', '#14151C') // $grit-dk-gray
      .style('stroke-width', '1px')
      .on('drag', null)
      .on('zoom', null);
  }

  rotateEndNodes(d): string {
    const centerX = d.point.x + 12;
    const centerY = d.point.y;
    return 'rotate(45 ' + centerX + ' ' + centerY + ')';
  }

  defineGraphContainer(): void {
    this.graphContainer = this.d3.select('#g-graph-container').on('zoom', null);
    this.svgElement.call(this.d3Zoom)
      .on('dblclick.zoom', null);
  }

  recenter(): void {
    // Set timeout for popout when popped back in because we need to wait for new window size to render
    setTimeout(
      () => {
        if (!this.networkMapTree || !this.networkMapViewerContainer) return;
        this.canvasBoundingBox = this.networkMapViewerContainer.getBoundingClientRect();
        this.networkMapTree.vertex.point.x = this.canvasBoundingBox.width / 2;
        this.networkMapTree.vertex.point.y = this.canvasBoundingBox.height / 2;
        this.reposition(this.networkMapTree.vertex, 'prerequisite');
        this.reposition(this.networkMapTree.vertex, 'successor');
        this.redraw();
        this.svgElement.call(this.d3Zoom.transform, this.d3.zoomIdentity);
      },
      0);
  }

  setAlternateMessage() {
    switch (this.mapView) {
      case NetworkMapView.Tree:
        break;
      case NetworkMapView.Influence:
        this.alternateViewMessage = 'downstream_influence';
        break;
      case NetworkMapView.Path:
        this.alternateViewMessage = 'path_information';
        break;
      case NetworkMapView.Blockers:
        this.alternateViewMessage = 'blocker_information';
        break;
      default:
        this.alternateViewMessage = '';
        break;
    }
  }

  handleViewSelect(view: number): void {
    if (view === this.mapView) return;
    this.mapView = view as NetworkMapView;
    this.setAlternateMessage();
    this.viewSelectOutput.emit(this.mapView);
  }

  defineNodes(): void {
    this.circles = this.d3.select('#g-circles').selectAll('circle').data(this.getNodePositionAndStepData(this.networkMapTree.vertex, {}, 'node'));
    this.circles.exit().remove();

    this.circles.transition().duration(500)
      .attr('cx', (d: any) => d.point.x)
      .attr('cy', (d: any) =>  d.point.y);

    this.circles.enter()
      .append('circle')
      .attr('cx', (d) => d.point.x)
      .attr('cy', (d) =>  d.point.y)
      .attr('class', (d) => 'z' + d.stepData.id) // add a 'z' because css class can't begin with a number
      .attr('r', this.verticeRadius)
      .style('fill', (d) => this.setNodeColor(d.stepData, d.planned))
      .style('stroke-width', (d) => d.stepData.type === 'active' ? '4px' : '2px')
      .style('stroke', '#14151C') // $grit-dk-gray
      .style('stroke-dasharray', (d) => this.setStrokeType(d.stepData))
      .on('mousedown', this.handleUserMouseAction())
      .on( 'mouseover', this.handleMouseOver())
      .on( 'mouseout', this.handleMouseOut())
      .on('drag', null)
      .on('zoom', null);
  }

  defineLines(): void {
    this.lines = this.d3.select('#g-lines').selectAll('line')
      .data(this.getEdges(this.networkMapTree.vertex));
    this.lines
      .transition().duration(500)
      .attr('x1', (d) => d.point1.x ).attr('y1', (d) => d.point1.y )
      .attr('x2', (d) => d.point2.x ).attr('y2', (d) => d.point2.y );

    this.lines.exit().remove();

    this.lines.enter().append('line')
      .attr('x1', (d) => d.point1.x).attr('y1', (d) => d.point1.y)
      .attr('x2', (d) => d.point1.x).attr('y2', (d) => d.point1.y)
      .style('stroke', (d) => ProjectNetworkMapViewerComponent.setLineColor(d))
      .transition().duration(500)
      .attr('x2', (d) => d.point2.x).attr('y2', (d) => d.point2.y);
  }

  defineTooltip(): void {
    this.tooltip = this.d3.select('.map-container').append('div')
      .attr('id', 'g-tooltip')
      .attr('class', 'g-tooltip')
      .style('opacity', 0)
      .style('width', '200px');

  }

  showTooltip(d): void {
    const tooltipHTML = this.getTooltipInfo(d.stepData, d.planned);

    this.tooltip
      .html(tooltipHTML);

    const tooltipContainer = document.getElementById('g-tooltip').offsetHeight;

    let left = this.d3.event.pageX  + 25;
    let top = this.d3.event.pageY - 10;
    let sideBarSize = 0;
    if (this.sideBarOpen) sideBarSize = this.SIDEBAR_WIDTH;

    if (this.d3.event.pageX >= window.innerWidth  - this.maxToolTipWidth - sideBarSize - 25) {
      left = this.d3.event.pageX - this.maxToolTipWidth - 25;
    }

    if (this.d3.event.pageY >= window.innerHeight - tooltipContainer - 10) {
      top = this.d3.event.pageY - tooltipContainer + 10;
    }

    this.tooltip
      .style('opacity', .9)
      .style('left', left + 'px')
      .style('top', top + 'px');
  }

  getTooltipInfo(stepData: any, planned): string {
    const localActivity = stepData.id ? this.projectService.getLocalActivity(stepData.projectStepId) : null;
    const displayName = (stepData && stepData.name) ? stepData.name
        : (localActivity && localActivity.name) ? localActivity.name
        : null;
    const activeSubcontractorName = this.subContractors.filter(subcontractor => subcontractor.id === stepData.subContractorId)[0]
      ? this.subContractors.filter(subcontractor => subcontractor.id === stepData.subContractorId)[0].name
      : '(Not assigned)';
    let tooltipHTML = '';

    // if object is unplanned, just return unplanned message
    if (!planned) {
      return `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-no-data-label">` + TranslationService.translate('object_unplanned') + `</span>
        </div>
      `;
    }
    if (displayName) {
      tooltipHTML += `
        <p class="g-tooltip-title">` + displayName + `</p>
      `;
    }
    if (stepData.crewSize) {
      tooltipHTML += `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-data-label">` + TranslationService.translate('crew_size') + `: </span>
          <span class="g-tooltip-data-value">` + stepData.crewSize + `</span>
        </div>
      `;
    }
    if (stepData.durationHours) {
      tooltipHTML += `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-data-label">` + TranslationService.translate('hours') + `: </span>
          <span class="g-tooltip-data-value">` + stepData.durationHours + `</span>
        </div>
      `;
    }
    // stepData lead and lag comes back by default (0) which is equivilant to false so as long as one of the data points exists we can show both
    if (stepData.lead || stepData.lag) {
      tooltipHTML += `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-data-label">` + TranslationService.translate('lead') + `: </span>
          <span class="g-tooltip-data-value">` + stepData.lead + ` hrs</span>
          <span class="g-tooltip-data-label">` + TranslationService.translate('lag') + `: </span>
          <span class="g-tooltip-data-value">` + stepData.lag + ` hrs</span>
        </div>
      `;
    }
    if (stepData.criticality) {
      tooltipHTML += `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-data-label">` + TranslationService.translate('criticality') + `: </span>
          <span class="g-tooltip-data-value">` + ((stepData.criticality / 1) * 100).toFixed(1) + `%</span>
        </div>
      `;
    }
    if (activeSubcontractorName) {
      tooltipHTML += `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-data-label">` + TranslationService.translate('subcontractor') + `: </span>
          <span class="g-tooltip-data-value">` + activeSubcontractorName + `</span>
        </div>
      `;
    }
    if (stepData.activities) {
      tooltipHTML += `
        <div class="g-tooltip-data-container">
          <span class="g-tooltip-data-label">` + TranslationService.translate('activities') + `: </span>
            <ul class="g-tooltip-data-value">
      `;
      let activityName;
      stepData.activities = Utils.deDupeArray(stepData.activities);
      stepData.activities.forEach(activity => {
        activityName = this.projectService.getLocalActivity(activity).name;
        tooltipHTML += `
        <li class="g-tooltip-data-activity-value">` + activityName + `</li>
        `;
      });

      tooltipHTML += `
          </ul>
        </div>
      `;
    }

    return tooltipHTML;
  }

  hideTooltip(): void {
    this.tooltip
      .style('opacity', 0)
      .style('left', '0px')
      .style('top', '0px');
  }

  addToParent(id: number, base: any, stepObject: any, type: string, shapeType: string, planned: boolean ) {
    if (base.shapeType === 'end') {
      return;
    }

    if (base.vId === id) {
      stepObject.type = type;
      const childObject = {
        vId: this.networkMapTree.size++,
        label: '?',
        stepData: stepObject,
        point: {},
        shapeType: shapeType,
        planned: planned,
        eventListen: true,
        children: []
      };

      base.children.push(childObject);
      return;
    }

    base.children.forEach(child => this.addToParent(id, child, stepObject, type, shapeType, planned));
  }

  removeChildrenFromParent(id: number, base: any) {
    if (base.vId === id) {
      this.removeFromParent(base);
      return;
    }

    base.children.forEach(child => this.removeChildrenFromParent(id, child));
  }

  removeFromParent(currentNode: any) {
    // takes current node and checks all children nodes to be removed from the parent
    currentNode.children.forEach(child => this.removeFromParent(child));

    if (currentNode.children.length === 0) {
      return;
    } else {
      currentNode.children.forEach(child => {
        // if child is part of the plan it needs to be removed from current filter object id as well
        if (child.planned) {
          child.stepData.objectIds.forEach(id => {
            const index = this.filteredObjectIds.findIndex(object => object.id === id);
            this.filteredObjectIds.splice(index, 1);
          });
        }
      });
      currentNode.children = [];
    }
  }

  redraw() {
    // add container to chart
    this.defineGraphContainer();

    // add edges
    this.defineLines();

    // add circles to chart
    this.defineNodes();

    // add end nodes to chart
    this.defineEndNodes();
  }

  reposition(base: any, type: string) {
    const leafCount = this.getLeafCount(base, type);

    let top = base.point.y - this.networkMapTree.h * (leafCount - 1) / 2;
    let left = base.point.x;

    if (type === 'prerequisite') {
      left -= 100;
    } else if (type === 'successor') {
      left += 100;
    }

    base.children.forEach(child => {
      if (child.stepData.type === type) {
        const height = this.networkMapTree.h * this.getLeafCount(child, type);
        top += height;

        child.point = {
          x: left,
          y: top - (height + this.networkMapTree.h) / 2
        };

        this.reposition(child, child.stepData.type);
      }
    });
  }

  setNodeColor(stepData: any, planned: boolean): any {
    if (stepData.type === 'prerequisite' && !planned) return 'white';

    const itemSub = this.subContractors.find(sub => sub.id === stepData.subContractorId);
    if (!Utils.isEmpty(itemSub)) return itemSub.hexCode;
    return 'black';
  }

  setStrokeType(stepData): any {
    // Checks if is actually a step first
    let isStep = true;
    if (!stepData.hasOwnProperty('id')) isStep = false;
    if (this.checkIfStepHasUnmodeled(stepData, isStep)) return '10,3';
    return '1,0';
  }
  // END Draw Methods

  checkIfStepHasUnmodeled(step, isStep): boolean {
    if (!step.hasOwnProperty('boundingBoxes')) {
      if (isStep) {
        return true;
      } else {
        return false;
      }
    }
    if (step.boundingBoxes.length === 0 || (step.boundingBoxes.length / 6) < step.objectIds.length) {
      if (isStep) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // Get Canvas Data Methods
  getEdges(base): any[] {
    let edges = [];
    if (Utils.isEmptyList(base.children)) return [];

    base.children.forEach(child => {
      let criticality = null;
      if (!Utils.isEmpty(child.stepData.criticality))
        criticality = child.stepData.criticality;

      edges.push({vId1: base.vId, label1: base.label, point1: base.point, vId2: child.vId, label2: child.label, point2: child.point, criticality: criticality });
      edges = [].concat.apply(edges, this.getEdges(child));
      edges = Utils.deDupeArray(edges);
    });

    return edges.sort((a, b) => a.vId2 - b.vId2);
  }

  getLeafCount(base, type) {
    if (base.children.length === 0)
      return 1;
    else {
      return base.children.map(child => {
        if (child.stepData.type === type) {
          return this.getLeafCount(child, type);
        } else {
          return 0;
        }
      }).reduce((a, b) => a + b);
    }
  }

  getNodePositionAndStepData(base, parent, type): any[] {
    let vertices = [];

    const nextVertex = base;
    nextVertex.parent = parent;

    if (nextVertex.shapeType === type) {
      vertices.push(nextVertex);
    }

    if (!Utils.isEmptyList(base.children)) {
      base.children.forEach(child => {
        vertices = [].concat.apply(vertices, this.getNodePositionAndStepData(child, {vId: base.vId, point: base.point}, type));
        vertices = Utils.deDupeArray(vertices);
      });
    }

    return vertices.sort((a, b) => a.vId - b.vId);
  }
  // END Get Canvas Data Methods

  // Resets
  resetMap() {

    if (this.tooltip) this.hideTooltip();

    this.waitingForDblClick = false;
    this.mapClickInterval = 0;

    this.networkMapTree = null;
    this.activeNodeData = null;
    this.filteredObjectIds = [];
    this.mapObjectIds = [];

    this.d3.select('#g-lines').selectAll('line').remove();
    this.d3.select('#g-circles').selectAll('circle').remove();
    this.d3.select('#g-rects').selectAll('rect').remove();

    this.lines = [];
    this.circles = [];
    this.endNodes = [];
  }
  // END Resets
}
