export const environment = {
  production: true,
  apiServer: () => {
    const uri = '__API_SERVER__';
    // Regex so sh does not overwrite equality check
    return /_{2}API_SERVER_{2}/.test(uri) ? 'https://api.gritvirtual.com' : uri;
  },
  pubSubServer: 'https://pubsub.gritvirtual.com',
  procore_client_id: 'fbfdd51692c6d002ea2464b63d55409f5c9af7775d2784cd74350ae22e404dbb'
};
