import { MakeReadyStatus } from '../../../utils/enums/make-ready-status.enum';

export interface IProjectLabor {
    id?: string;
    resourceId?: string,
    projectId?: string;
    name: string;
    unitOfMeasure?: UnitOfMeasure;
    subId?: string;
    cost?: number;
    procoreId?: string;
    startDate?: number;
    quantityAssigned?: number;
    budgetQuantity?: string;
    budget?: string;
    quantityParameter?: string;
    quantity?: number;
    plannedQuantity?: number;
    resourceRate?: number;
    percentageLoss?: number;
    importedFromCsv?: boolean;
}

export interface IUnitOfMeasureOption {
    value: UnitOfMeasure;
    display: string;
}

export enum UnitOfMeasure {
  ManHour = 'manHour',
  ManDay = 'manDay'
}
