import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { SegmentService } from '../../segment/segment.service';
import { Utils } from '../../../utils/utils';

import { IWorkPackage } from '../../../models/work-package/work-package.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectWorkPackagesService {
  private workPackages: IWorkPackage[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private httpService: HttpBackendService,
    private notificationService: NotificationService,
    private formBuilderService: FormBuilder
  ) { }

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getActivityWorkPackages(activityId: string): Promise<IWorkPackage[]> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.httpService.get(`/project/activity/${activityId}/workPackages`).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'work packages', action: 'get'});
          return reject();
        }
      );
    });
  }

  getWorkPackage(wpId: string): Promise<IWorkPackage> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.httpService.get(`/project/workPackage/${wpId}`).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'work package', action: 'get'});
          return reject();
        }
      );
    });
  }

  getPotentialPrereqWorkPackages(wpId: string): Promise<IWorkPackage[]> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.httpService.get(`/project/workPackage/${wpId}/potentialPrereqs`).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'work package prerequisites', action: 'get'});
          return reject();
        }
      );
    });
  }

  addWorkPackage(json: IWorkPackage): Promise<IWorkPackage>  {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.httpService.post(`/project/workPackage`, json).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          SegmentService.track('Planner Form: Work Package Added ', {name: json.name, id: res.id});
          this.workPackages.push(res);
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'work package', action: 'insert'});
          return reject();
        }
      );
    });
  }

  editWorkPackage(json: IWorkPackage): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.httpService.put(`/project/workPackage/${json.id}`, json).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          const wpIndex = this.workPackages.findIndex(wP => wP.id === json.id);
          if (wpIndex > -1) this.workPackages[wpIndex] = json;
          SegmentService.track('Planner Form: Work Package Edited ', {name: json.name, id: json.id});
          return resolve();
        },
        err => {
          this.notificationService.error(err, {type: 'work package', action: 'update'});
          return reject();
        }
      );
    });
  }

  deleteWorkPackage(wpId: string): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.httpService.delete(`/project/workPackage/${wpId}`).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          SegmentService.track('Planner Form: Work Package Deleted ', {id: wpId});
          return resolve();
        },
        err => {
          this.notificationService.error(err, {type: 'work package', action: 'delete'});
          return reject();
        }
      );
    });
  }

  setLocalWorkPackages(activityId: string): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise(async (resolve) => {
      await this.getActivityWorkPackages(activityId).then(res => {
        this.workPackages = Utils.sortByString(res, 'name');
        this.workPackages.forEach(wp => wp.activityId = activityId);
        return resolve();
      }).catch(() => {
        this.workPackages = [];
        return resolve();
      });
    });
  }

  getWorkPackageObjectIds(wpId: string): {objectIds: string[], prereqObjectIds: string[]} {
    const objectIds = [];
    const prereqObjectIds = [];
    this.workPackages.forEach(wp => {
      if (wp.id === wpId) {
        wp.objectIds.forEach(id => objectIds.push(id));
        wp.prerequisiteObjects.forEach(po => prereqObjectIds.push(po.objectId));
      }
    });

    return {
      objectIds: objectIds,
      prereqObjectIds: prereqObjectIds
    };
  }

  getAssignedWorkPackageObjectIds(activityId: string, excludeWpId?: string): {objectIds: string[], prereqObjectIds: string[]} {
    const objectIds = [];
    const prereqObjectIds = [];
    this.workPackages.forEach(wp => {
      if (wp.id !== excludeWpId) {
        if (wp.activityId === activityId) {
          wp.objectIds.forEach(id => objectIds.push(id));
          wp.prerequisiteObjects.forEach(po => prereqObjectIds.push(po.objectId));
        }
      }
    });

    return {
      objectIds: objectIds,
      prereqObjectIds: prereqObjectIds
    };
  }

  getLocalWorkPackages(): IWorkPackage[] {
    return Utils.sortByString(this.workPackages, 'name');
  }

  getLocalActivityWorkPackages(activityId: string): IWorkPackage[] {
    return this.workPackages.filter(wp => wp.activityId === activityId);
  }

  getLocalWorkPackage(wpId: string): IWorkPackage {
    return this.workPackages.find(wp => wp.id === wpId);
  }

  getWorkPackageSteps(wpId: string): any[] {
    const selectedWp = this.workPackages.find(wp => wp.id === wpId);
    const wpSteps = (selectedWp && selectedWp.steps) ? selectedWp.steps : [];
    return wpSteps;
  }

  buildPrereqFormInput(wp: IWorkPackage, potentialWpPrereqs?: IWorkPackage[]) {
    const inheritPrereqs = wp.inheritsPrereqs ? wp.inheritsPrereqs : false;
    const wpPrereqFormInput = this.formBuilderService.group({
      id: wp.id,
      activityId: wp.activityId,
      name: [wp.name, Validators.required],
      inheritsPrereqs: [inheritPrereqs, Validators.required],
      selectedWpPrereqs: [wp.prerequisiteWorkPackages],
      wpPrereqOptions: potentialWpPrereqs ? [potentialWpPrereqs] : []
    });

    return wpPrereqFormInput;
  }
}
