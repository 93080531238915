import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationEnd, Router } from '@angular/router';

import { IAccount } from '../../models/account/account.interface';
import { IPageHeader } from '../../models/page-header/page-header.interface';
import { IProjectObject } from '../../models/project/project-object/project-object.interface';
import { IHours } from '../../models/project/project-workdays/project-workdays.interface';
import { IProject } from '../../models/project/project.interface';
import { IUserPermission } from '../../models/user/user.interface';

import { Permission } from '../../utils/enums/permission.enum';
import { RouteKeysEnum } from './project-page-criteria/project-page-criteria.service';

import { Currencies } from '../../utils/staticLists/currency';
import { HttpBackendService } from '../http-backend/http-backend.service';
import { NotificationService } from '../notification/notification.service';
import { ProjectEquipmentService } from './project-equipment/project-equipment.service';
import { ProjectMaterialService } from './project-material/project-material.service';
import { ProjectRfiService } from './project-rfi/project-rfi.service';
import { Utils } from '../../utils/utils';

import { Buffer } from 'buffer/';
import { EventType } from '../../utils/enums/notification.enum';
import { ProcoreService } from '../procore/procore.service';
import { ActivityService } from '../activity/activity.service';
import { ViewerMode } from '../../utils/enums/shared.enum';
import { PDFViewerService } from '../pdf/pdfViewer.service';
import { ForgeService } from '../forge/forge.service';
import { ProjectLaborService } from './project-labor/project-labor.service';
import { element } from '@angular/core/src/render3/instructions';

@Injectable()
export class ProjectService {

  static userPermission: IUserPermission;
  currentProject: IProject;
  currencyCode: string;
  currentProjectAccount: IAccount;
  currentProjectHeader: IPageHeader;
  projectEditPrivilege: boolean;
  disablePageInteractions: boolean = false;
  filteringModel: boolean = false;
  isSubMenuOpen: boolean = false;
  showProjectSidebar: boolean = false;
  projectSidebarChange: Subject<boolean> = new Subject<boolean>();
  disableSidebar: boolean = true;
  isBim360Project: boolean = false; // determines if the project is a bim360 or not
  hiddenViewerRoutes: string[] = [RouteKeysEnum.Settings, RouteKeysEnum.Scenarios, RouteKeysEnum.Dashboard, RouteKeysEnum.Criteria, RouteKeysEnum.Reports, RouteKeysEnum.MakeReady];
  optionalViewerRoutes: string[] = [RouteKeysEnum.MasterSchedule, RouteKeysEnum.Lookahead, RouteKeysEnum.Sprints, RouteKeysEnum.ManPower];
  hasModels: boolean = true;
  hasPDFs: boolean = false;
  viewerMode: ViewerMode = ViewerMode.ForgViewer;


  // Objects and indexes
  private projectDataReady: boolean = false;
  private forgeViewerReady: boolean = false;
  private hiddenObjects = {};
  private objects = {};
  public projectObjectsChange: Subject<object> = new Subject<object>(); // detect project object Changes
  public projectSetupReady: Subject<boolean> = new Subject<boolean>(); // detect project object Changes

  private modelObjects = {};
  private forgeKeyObject = {};
  private showConflicts = false;
  private conflictedObjects = {};

  private allActivities = {};
  private allActivitiesArr = [];
  private usedActivities = {};
  // detect project activity|activity type Changes
  public projectActivityChange: Subject<boolean> = new Subject<boolean>();

  private categories = {};
  private categoryActivitiesMapWithId = {};
  private categoryIdMapWithName = {};
  private categoriesArray = []; // converted categories into an array
  private modelCategories = {};
  private modelCategoriesObjArray = {}; // returns an object of models ids with category arrays attached to each
  private subcontractors = {};
  private subcontractorArr = [];
  private materials = {};
  private materialsArr = [];
  private equipments = {};
  private equipmentsArr = [];
  public floorPlans: any = [];
  public floorPlanPDFs: any = [];
  public Bim360PDFURLs: any = {};


  // viewer
  public viewerIsHidden: boolean = false;
  private hideSidebar: boolean = false;
  private loadObjectsPromise = {};

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public projectUrl: string;

  // Observable string sources
  private componentMethodCallSource = new Subject<any>();
  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  private gritTableExternalSearchControl = new BehaviorSubject<any>({ searchQuery: '', searchFilters: [] });
  currentSearchQueryOfGritTable = this.gritTableExternalSearchControl.asObservable();

  constructor(
    private http: HttpBackendService,
    private router: Router,
    private notificationService: NotificationService,
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService,
    private procoreService: ProcoreService,
    private forgeService: ForgeService,
    private pdfViewerService: PDFViewerService,
    private rfiService: ProjectRfiService,
    private laborService: ProjectLaborService
  ) {
    this.router.events.subscribe(change => {
      if (change instanceof NavigationEnd) {
        this.projectUrl = change.url;
        this.isSubMenuOpen = false;
      }
    });

    // listen to sidebar
    this.projectSidebarChange.subscribe((value) => {
      this.showProjectSidebar = value;
    });

    // websocket listening for notifications
    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe(
      async event => {
        if (this.currentProject == null) return;
        if (this.notificationService.validEvent(event, this.currentProject.id, EventType.OBJECT_HIDDEN)) {
          event.body.objectIds.forEach(objectId => {
            this.addHiddenObject(objectId);
            this.deleteModelObject(event.body.modelId, objectId);
          });
        } else if (this.notificationService.validEvent(event, this.currentProject.id, EventType.OBJECT_ACTIVITY_CHANGE)) {
          // may not be ideal but for now when new activities are added/removed or assigned we will need to call the api to get all activities AND objects (not modelObjects) to update local cache
          await this.loadObjects(this.currentProject.id, true);
          await this.loadActivities(this.currentProject.id);
        } else if (this.notificationService.validEvent(event, this.currentProject.id, EventType.PROJECT_SUBS_UPDATED)) {
          // Reload subs
          this.loadSubcontractors(event.projectId);

          // Reload trades
          this.loadActivities(event.projectId);

          this.loadMaterials(event.projectId);
          this.loadEquipments(event.projectId);
          this.floorPlanPDFs = [];
          this.floorPlans = [];
          this.Bim360PDFURLs = {};
          await this.loadProcoreFloorPlanData(event.projectId);
          await this.loadBIM360FloorPlanData(event.projectId);
          for (let i = 0; i < this.floorPlanPDFs.length; i++) {
            if(this.floorPlanPDFs[i].indexOf("https://developer.api.autodesk.com") != -1){
              const x = await this.forgeService.getPDF(event.projectId, this.floorPlanPDFs[i].replace("https://developer.api.autodesk.com", "")).toPromise();
              var byteArray = new Uint8Array(x.data);
              const blob = new Blob([byteArray], { type: 'application/pdf' });
              const blobURL = URL.createObjectURL(blob);
              this.Bim360PDFURLs[blobURL] = this.floorPlanPDFs[i];
              this.floorPlanPDFs[i] = blobURL;
            }
          }
          this.ViewerSetUp();
        }
        else if(this.notificationService.validEvent(event, this.currentProject.id, EventType.FLOOR_PLANS_UPDATE)){
          this.floorPlanPDFs = [];
          this.floorPlans = [];
          this.Bim360PDFURLs = {};
          await this.loadProcoreFloorPlanData(event.projectId);
          await this.loadBIM360FloorPlanData(event.projectId);
          for (let i = 0; i < this.floorPlanPDFs.length; i++) {
            if(this.floorPlanPDFs[i].indexOf("https://developer.api.autodesk.com") != -1){
              const x = await this.forgeService.getPDF(event.projectId, this.floorPlanPDFs[i].replace("https://developer.api.autodesk.com", "")).toPromise();
              var byteArray = new Uint8Array(x.data);
              const blob = new Blob([byteArray], { type: 'application/pdf' });
              const blobURL = URL.createObjectURL(blob);
              this.Bim360PDFURLs[blobURL] = this.floorPlanPDFs[i];
              this.floorPlanPDFs[i] = blobURL;
            }
          }
          this.ViewerSetUp();
        }
      });
  }

  private updateFloorPlanObjects(fpObjectArray: any[]) {
    fpObjectArray.sort(function(a,b){ return a.orderNum - b.orderNum; });
    const newArr = [];
    var len = fpObjectArray.length - 1;
    if (len >= 0) {
      for (var i = 0; i < len; i++) {
          if (fpObjectArray[i].url !== fpObjectArray[i + 1].url) {
              newArr.push(fpObjectArray[i]);
          }
      }
      newArr.push(fpObjectArray[len]);
    }
    fpObjectArray =  newArr;
    let plans = [];
    fpObjectArray.forEach(fp => {
      fp.selected = false;
      plans.push(fp.url);
    });
    this.floorPlanPDFs = plans;
  }

  public loadProcoreFloorPlanData(projectId: string) {
    return new Promise((resolve, reject) => {
      this.procoreService.getAllFloorPlan(projectId).subscribe(res => {
        if (this.floorPlans.length == 0)
          this.floorPlans = res;
        else
          this.floorPlans = [...this.floorPlans, ...res];
        this.updateFloorPlanObjects(this.floorPlans);
        resolve();

      },
        err => {
          this.notificationService.error(err, {});
          this.floorPlans = [];
          resolve();
        }
      );
    });
  }

  public loadBIM360FloorPlanData(projectId: string) {
    return new Promise((resolve, reject) => {
      this.forgeService.getAllFloorPlan(projectId).subscribe(res => {
        if (this.floorPlans.length == 0)
          this.floorPlans = res;
        else
          this.floorPlans = [...this.floorPlans, ...res];
          this.updateFloorPlanObjects(this.floorPlans);
        resolve();
      },
        err => {
          this.notificationService.error(err, {});
          resolve();
        });
    });
  }

  public ViewerSetUp() {
    if (this.floorPlanPDFs.length > 0) {
      this.hasPDFs = true;
      this.viewerMode = ViewerMode.PDFViewer;
    }
    else
      this.viewerMode = ViewerMode.ForgViewer;
    if (this.floorPlanPDFs.length == 0 && !this.hasModels) {
      this.hasPDFs = false;
      this.setHideViewer(true);
    }
    this.pdfViewerService.updatePdf(this.floorPlanPDFs);
  }

  public getLocalFloorPlans() {
    const newArr = [];
    var len = this.floorPlans.length - 1;
    if (len >= 0) {
      for (var i = 0; i < len; i++) {
          if (this.floorPlans[i].url !== this.floorPlans[i + 1].url) {
              newArr.push(this.floorPlans[i]);
          }
      }
      newArr.push(this.floorPlans[len]);
    }
    return newArr;
  }

  public getLocalFloorPlanPDFs() {
    return this.floorPlanPDFs;
  }

  public getBIM360ExactURL(blobURL:string){
    if(!this.Bim360PDFURLs[blobURL])
      return blobURL;
    return this.Bim360PDFURLs[blobURL];
  }

  public getBlobUrlOfBIm360(BIMUrl :String){
    let urlblob ;
    Object.entries(this.Bim360PDFURLs).forEach(([key, value]) => {
      if(BIMUrl == value){
        urlblob = key;
      } 
    });
    if(urlblob)
      return urlblob;
    return BIMUrl;
  }

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  public updateObjects(removedObjectIds: string[], hiddenObjectIds: string[], newObjects: any[]) {
    removedObjectIds.forEach(id => {
      const obj = this.objects[id];
      delete this.objects[id];
      delete this.hiddenObjects[id];
      if (obj) {
        if (this.modelObjects[obj.projectModelId]) delete this.modelObjects[obj.projectModelId][id];
        if (this.forgeKeyObject[obj.projectModelId]) delete this.forgeKeyObject[obj.projectModelId][obj.forgeObjectId];

        if (obj.activities) {
          obj.activities.forEach(a => {
            if (this.allActivities[a]) {
              const i = this.allActivities[a].objectIds.indexOf(id);
              if (i >= 0) {
                this.allActivities[a].objectIds.splice(i, 1);
              }
            }
          });
        }
      }
    });
    hiddenObjectIds.forEach(id => {
      const obj = this.objects[id];
      delete this.objects[id];
      if (obj) {
        if (this.modelObjects[obj.projectModelId]) delete this.modelObjects[obj.projectModelId][id];
        if (this.forgeKeyObject[obj.projectModelId]) delete this.forgeKeyObject[obj.projectModelId][obj.forgeObjectId];

        if (obj.activities) {
          obj.activities.forEach(a => {
            if (this.allActivities[a]) {
              const i = this.allActivities[a].objectIds.indexOf(id);
              if (i >= 0) {
                this.allActivities[a].objectIds.splice(i, 1);
              }
            }
          });
        }
      }
      this.hiddenObjects[id] = id;
    });
    newObjects.forEach(obj => {
      delete this.hiddenObjects[obj.id];
      if (this.modelObjects[obj.projectModelId]) this.modelObjects[obj.projectModelId][obj.id] = obj.id;
      if (this.forgeKeyObject[obj.projectModelId]) this.forgeKeyObject[obj.projectModelId][obj.forgeObjectId] = obj.id;
      this.objects[obj.id] = obj;

      if (obj.activities) {
        obj.activities.forEach(a => {
          if (this.allActivities[a]) {
            this.allActivities[a].objectIds.push(obj.id);
            if (!this.usedActivities[a]) {
              this.usedActivities[a] = this.allActivities[a];
            }
          }
        });
      }
    });
  }

  public createProject(json: any): Observable<any> {
    return this.http.post('/project', json);
  }

  public updateProject(json: any): Observable<any> {
    return this.http.put('/project/' + json.id, json);
  }

  public getProjectList(): Observable<IProject[]> {
    return this.http.get('/projects');
  }

  public getProject(projectId: string): Observable<IProject> {
    return this.http.get('/project/' + projectId);
  }

  public deleteProject(projectId: string): Observable<IProject> {
    return this.http.delete('/project/' + projectId);
  }

  public getProjectHours(projectId: string): Observable<IHours> {
    return this.http.get(`/project/${projectId}/hours`);
  }

  public updateProjectHours(projectId: string, json: IHours): Observable<IHours> {
    return this.http.put(`/project/${projectId}/hours`, json);
  }

  public getProjectPermission(projectId: string): Observable<Permission> {
    return this.http.get('/project/' + projectId + '/permission');
  }

  public getGcPermission(projectId: string): Observable<boolean> {
    return this.http.get('/project/' + projectId + '/permission/gc');
  }

  public getProjectAccount(projectId: string): Observable<IProject> {
    return this.http.get('/project/' + projectId + '/account');
  }

  public getUsedActivities(projectId: string): Observable<any[]> {
    return this.http.get('/project/' + projectId + '/trades');
  }

  public getAllActivities(projectId: string) {
    return this.http.get('/project/' + projectId + '/activities');
  }

  public getActivityTree(projectId: string, query?: object) {
    let apiUrl = '/project/' + projectId + '/activityTree';
    if (query != null) {
      const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      apiUrl += '?' + queryString;
    }
    return this.http.get(apiUrl);
  }

  public getActivityTasks(projectId: string, scheduleId: string, start: number, end: number) {
    const apiUrl = '/project/' + projectId + '/schedule/' + scheduleId + '/activityTasks?start=' + start + '&end=' + end;
    return this.http.get(apiUrl);
  }

  public getObjectMap(projectId) {
    return this.http.get('/project/' + projectId + '/objectMap?excludeData=true');
  }

  public getObjectData(projectId) {
    return this.http.getBlob('/project/' + projectId + '/objectData?lod=0');
  }

  public getHiddenMap(projectId) {
    return this.http.get('/project/' + projectId + '/hiddenMap');
  }

  public getModelMap(projectModelId) {
    return this.http.get('/project/model/' + projectModelId + '/modelMap');
  }

  public getProjectStatus(projectId) {
    return this.http.get('/project/' + projectId + '/status');
  }

  public getStepMap(projectId) {
    return this.http.get('/project/' + projectId + '/stepMap');
  }

  public export(projectId: string, password) {
    return this.http.postBlob('/project/' + projectId + '/export', { password });
  }

  public exportActivities(projectId: string, scheduleId: string, type: string) {
    return this.http.getBlob('/project/' + projectId + (scheduleId ? '/schedule/' + scheduleId : '') + '/activities/export/' + type);
  }

  public getCategories(projectId: string, query?: object) {
    let apiUrl = '/project/' + projectId + '/categories';
    if (query != null) {
      const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      apiUrl += '?' + queryString;
    }
    return this.http.get(apiUrl);
  }

  public getProjectSubcontractors(projectId: string) {
    return this.http.get('/project/' + projectId + '/subContractors');
  }

  public getProjectEquipments(projectId: string) {
    return this.http.get('/project/' + projectId + '/equipment');
  }

  public getProjectmaterisals(projectId: string) {
    return this.http.get('/project/' + projectId + '/materials');
  }

  public reset(projectId: string) {
    return this.http.put('/project/' + projectId + '/reset', {});
  }

  public resetSteps(projectId: string) {
    return this.http.put('/project/' + projectId + '/resetSteps', {});
  }

  public async setDisablePage(value: boolean) {
    this.disablePageInteractions = value;
  }

  public setFilteringModel(value: boolean): void {
    this.filteringModel = value;
    this.setDisablePage(value);
  }

  // OBJECT INDEX
  public loadObjects(projectId: string, force?: boolean): Promise<void> {
    if (!this.loadObjectsPromise[projectId] || force) {
      this.loadObjectsPromise = {};
      this.loadObjectsPromise[projectId] = new Promise((resolve, reject) => {
        forkJoin([
          this.getObjectMap(projectId),
          this.getObjectData(projectId)
        ]).subscribe(results => {
          this.objects = {};
          for (const result of results[0]) {
            this.objects[result.id] = result;
          }
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            const buffer = Buffer.from(event.target['result']);
            let index = 0;
            while (index < buffer.length) {
              const idLength = buffer.readInt32BE(index);
              index += 4;
              const id = buffer.slice(index, index + idLength).toString('utf8');
              index += idLength;
              const fragLength = buffer.readInt32BE(index);
              index += 4;
              const frag = buffer.slice(index, index + fragLength);
              index += fragLength;
              (this.objects[id] || {})['fragmentBuffer'] = frag;
            }
            this.toggleConflicts(this.showConflicts);
            resolve();
          };
          fileReader.readAsArrayBuffer(results[1]);
        });
      });
    }
    return this.loadObjectsPromise[projectId];
  }

  public getObject(projectObjectId: string): IProjectObject {
    return this.objects[projectObjectId];
  }

  public getAllObjectIds() {
    const returnObject = {};
    Object.keys(this.objects).map(key => returnObject[key] = key);
    return returnObject;
  }

  public getAllObjectsMap() {
    return this.objects;
  }

  public toggleConflicts(show) {
    this.showConflicts = show;
    if (!show) {
      const removed = Object.keys(this.objects).filter(id => this.objects[id].conflict > 0);
      this.conflictedObjects = removed.map(id => this.objects[id]);
      this.updateObjects(removed, [], []);
    } else {
      const added = Object.keys(this.conflictedObjects).map(id => this.conflictedObjects[id]);
      this.conflictedObjects = {};
      this.updateObjects([], [], added);
    }
  }

  public resolveConflicts(ids) {
    ids.forEach(id => {
      if (this.conflictedObjects[id]) {
        this.conflictedObjects[id].conflict = 0;
        this.updateObjects([], [], [this.conflictedObjects[id]]);
        delete this.conflictedObjects[id];
      } else if (this.objects[id]) {
        this.objects[id].conflict = 0;
      }
    });
  }

  // HIDDEN OBJECT INDEX
  public loadHiddenObjects(projectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getHiddenMap(projectId).subscribe(objects => {
        this.hiddenObjects = {};
        objects.forEach(id => {
          this.hiddenObjects[id] = id;
        });
        resolve();
      });
    });
  }

  public addHiddenObject(objectId: string) {
    if (Utils.isEmpty(this.hiddenObjects[objectId])) this.hiddenObjects[objectId] = objectId;
  }

  public getHiddenObjects() {
    const returnObject = {};
    Object.keys(this.hiddenObjects).map(objectId => returnObject[objectId] = this.objects[objectId]);
    return returnObject;
  }

  public getLocalNonHiddenObjects() {
    const returnObject = {};
    Object.keys(this.objects).forEach(objectId => {
      if (!this.hiddenObjects[objectId]) returnObject[objectId] = this.objects[objectId];
    });
    return returnObject;
  }

  public async getCustomObjects(projectId: string) {
    if (!this.loadObjectsPromise[projectId]) {
      this.loadObjects(projectId);
    }
    await this.loadObjectsPromise[projectId];
    const returnObject = {};
    Object.keys(this.objects).forEach(objectId => {
      if (this.objects[objectId].fragmentData || this.objects[objectId].fragmentBuffer) {
        returnObject[objectId] = this.objects[objectId];
      }
    });
    return returnObject;
  }

  public async getHiddenCustomObjects(projectId: string) {
    if (!this.loadObjectsPromise[projectId]) {
      this.loadObjects(projectId);
    }
    await this.loadObjectsPromise[projectId];
    const returnObject = {};
    Object.keys(this.conflictedObjects).forEach(objectId => {
      if (this.conflictedObjects[objectId].fragmentData || this.conflictedObjects[objectId].fragmentBuffer) {
        returnObject[objectId] = this.conflictedObjects[objectId];
      }
    });
    return returnObject;
  }

  // MODEL OBJECT INDEX
  public loadModelObjects(projectModelId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getModelMap(projectModelId).subscribe(objects => {
        this.modelObjects[projectModelId] = {};
        objects.forEach(id => {
          this.modelObjects[projectModelId][id] = id;
        });
        resolve();
      });
    });
  }

  public deleteModelObject(projectModelId: string, projectObjectId: string) {
    if (!Utils.isEmpty(this.modelObjects[projectModelId][projectObjectId])) delete this.modelObjects[projectModelId][projectObjectId];
  }

  public getModelObjects(projectModelId: string) {
    const returnObject = {};
    Object.keys(this.modelObjects[projectModelId]).map(objectId => returnObject[objectId] = this.objects[objectId]);
    return returnObject;
  }

  public buildForgeKeyMap(): void {
    Object.keys(this.modelObjects).forEach((key) => {
      this.forgeKeyObject[key] = {};
      Object.keys(this.modelObjects[key]).forEach(id => {
        if (this.objects[id]) {
          this.forgeKeyObject[key][this.objects[id].forgeObjectId] = this.objects[id].id;
        }
      });
    });
  }

  public clearCachedData(): void {
    this.projectDataReady = false;
    this.forgeViewerReady = false;
    this.hiddenObjects = [];
    this.objects = {};
    this.modelObjects = {};
    this.forgeKeyObject = {};
    this.allActivities = {};
    this.allActivitiesArr = [];
    this.materialsArr = [];
    this.equipmentsArr = [];
    this.usedActivities = {};
    this.categories = {};
    this.categoriesArray = [];
    this.modelCategories = {};
    this.modelCategoriesObjArray = {};
    this.subcontractors = {};
    this.subcontractorArr = [];
    this.categoryActivitiesMapWithId = {};
    this.categoryIdMapWithName = {}
  }

  public getGritIdByProjectModelForgeObject(projectModelId: string, forgeObjectId: number): string {
    return this.forgeKeyObject[projectModelId][forgeObjectId];
  }

  // ACTIVITY INDEX
  public loadActivities(projectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getAllActivities(projectId).subscribe(activities => {
        this.allActivities = {};
        this.allActivitiesArr.push(...activities);
        activities.forEach(activity => {
          this.allActivities[activity.id] = activity;
        });
        this.getUsedActivities(projectId).subscribe(usedTCs => {
          this.usedActivities = {};
          usedTCs.forEach(activity => {
            this.usedActivities[activity.id] = activity;
          });
          this.projectActivityChange.next(true); // emit allActivities local cache has changed for all subscriptions
          return resolve();
        });
      });
    });
  }

  public getLocalAllActivities() {
    return Object.keys(this.allActivities).map(activity => this.allActivities[activity]);
  }

  public getLocalUsedActivitiesMap() {
    return this.usedActivities;
  }

  public getLocalUsedActivities() {
    return Object.keys(this.usedActivities);
  }

  public updateLocalAllActivities(updatedJson) {
    this.allActivities = updatedJson;
  }

  public getLocalActivity(activityId) {
    return this.allActivities[activityId];
  }

  // Category INDEX
  public loadCategories(projectId: string, projectModels: any): Promise<void> {
    return new Promise((resolve, reject) => {
      projectModels.forEach(model => {
        this.modelCategories[model.id] = {};
        this.modelCategoriesObjArray[model.id] = [];
      });
      const query = { recurse: true };
      this.getCategories(projectId, query).subscribe(results => {
        results.forEach(cat => {
          this.categories[cat.id] = cat;
          this.getCategoryObjects(projectId, cat.id);
          this.categoryIdMapWithName[cat.name] = cat.id;
          cat.modelIds.map(modelId => {
            this.modelCategories[modelId][cat.id] = cat;
          });
        });
        return resolve();
      });
    });
  }


  public getCategoryObjects(projectId: string, category: string) {
    this.http.post('/project/' + projectId + '/categoryObjects', { 'categories': [category] }).subscribe(
      results => {
        this.categoryActivitiesMapWithId[category] = results;
      },
      err => {
      }
    );
  }

  public getLocalCategories() {
    return this.categories;
  }

  public getLocalCategoriesArray() {
    if (this.categoriesArray.length === 0) Object.keys(this.categories).forEach((key) => this.categoriesArray.push(this.categories[key]));

    return this.categoriesArray;
  }

  public getLocalCategoryId(categoryId) {
    if (this.categories[categoryId]) return this.categories[categoryId];
    else return null;
  }

  public getLocalModelCategories(projectModelId: string) {
    return this.modelCategories[projectModelId];
  }

  public getLocalModelCategoriesObjArray(projectModelId: string) {
    if (this.modelCategoriesObjArray[projectModelId].length === 0) {
      const catModelMap = this.getLocalModelCategories(projectModelId);
      Object.keys(catModelMap).forEach((key) => this.modelCategoriesObjArray[projectModelId].push(catModelMap[key]));
    }
    return this.modelCategoriesObjArray[projectModelId];
  }

  // END Category INDEX

  // Subcontractor INDEX
  public loadSubcontractors(projectId): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getProjectSubcontractors(projectId).subscribe(results => {
        results.forEach(sub => {
          this.subcontractors[sub.id] = sub;
        });
        this.subcontractorArr = results;
        return resolve();
      });
    });
  }

  // materials INDEX
  public loadMaterials(projectId): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getProjectmaterisals(projectId).subscribe(results => {
        results.forEach(material => {
          let activityIdList = [];
          this.allActivitiesArr.forEach(activityObject => {
            activityObject.materialIds.forEach(materialId => {
              if (materialId == material.id) {
                activityIdList.push(activityObject.id);
              }
            })
          })
          material.activities = activityIdList;
          this.materials[material.id] = material;
          this.materialsArr.push(material);
        });
        return resolve();
      });
    });
  }

  // equipment INDEX

  public loadEquipments(projectId): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getProjectEquipments(projectId).subscribe(results => {
        results.forEach(equipment => {
          let activityIdList = [];
          this.allActivitiesArr.forEach(activityObject => {
            activityObject.equipmentIds.forEach(equipmentId => {
              if (equipmentId == equipment.id) {
                activityIdList.push(activityObject.id);
              }
            })
          })
          equipment.activities = activityIdList;
          this.equipments[equipment.id] = equipment;
          this.equipmentsArr.push(equipment);
        });
        return resolve();
      });
    });
  }

  public getLocalProjectSubcontractorsMap() {
    return this.subcontractors;
  }

  public getLocalSubcontractor(subId: string) {
    return this.subcontractors[subId];
  }

  public getLocalMaterials(matId: string) {
    return this.materials[matId];
  }

  public getLocalEqupments(eqId: string) {
    return this.equipments[eqId];
  }

  public getLocalCategoriesForId(catId: string) {
    return this.categoryActivitiesMapWithId[catId];
  }

  public getActivitiesMatchingName(activitesNameStr: string) {
    let activityList = [];
    this.allActivitiesArr.forEach(activity => {
      if (activity.name.toLowerCase().includes(activitesNameStr.toLowerCase()))
        activityList.push(activity.id);
    });
    return activityList
  }

  public getCatagoriesMatchingName(catagoryNameStr: string) {
    let activityList = [];
    this.categoriesArray.forEach(catagory => {
      if (catagory.name.toLowerCase().includes(catagoryNameStr.toLowerCase())) {
        activityList.push(catagory.id);
      }
    });
    return activityList
  }


  public getCategoryIdsMatchingCategoryName(catagoryNameStr: string) {
    let categoryIds = [];
    this.categoriesArray.forEach(catagory => {
      if (catagory.name.toLowerCase().includes(catagoryNameStr.toLowerCase())) {
        categoryIds.push(...this.categoryActivitiesMapWithId[catagory.id]);
      }
    });
    let activityIdListSetUniq = new Set(categoryIds);
    return activityIdListSetUniq;
  }


  public getActivityIdsMatchingCategoryName(catagoryNameStr: string) {
    let activityIdList = [];
    this.categoriesArray.forEach(catagory => {
      if (catagory.name.toLowerCase().includes(catagoryNameStr.toLowerCase())) {
        this.categoryActivitiesMapWithId[catagory.id].forEach(element => {
          activityIdList.push(...this.getObject(element).activities)
        });
      }
    });

    let activityIdListSetUniq = new Set(activityIdList);
    return activityIdListSetUniq;
  }

  public getActivityIdsMatchingCategoryId(catagoryId: string) {

    let activityIdList = [];

    this.categoryActivitiesMapWithId[catagoryId].forEach(element => {
      activityIdList.push(...this.getObject(element).activities)
    });

    return activityIdList;
  }

  public getLocalSubContractorMatchingName(eqipmentNameStr: string) {
    let activityList = [];
    this.subcontractorArr.forEach(subContractor => {
      if (subContractor.name.toLowerCase().includes(eqipmentNameStr.toLowerCase())) {
        activityList.push(...this.subcontractors[subContractor.id].activities);
      }
    });
    return activityList
  }

  public getLocalEqupmentsMatchingName(subContractorNameStr: string) {
    let activityList = [];
    this.equipmentsArr.forEach(equipment => {
      if (equipment.name.toLowerCase().includes(subContractorNameStr.toLowerCase())) {
        const eqIdArr = this.equipments[equipment.id]
        activityList.push(...eqIdArr.activities);
      }
    });
    return activityList
  }

  public getLocalMaterialsMatchingName(materialNameStr: string) {
    let activityList = [];
    this.materialsArr.forEach(material => {
      if (material.name.toLowerCase().includes(materialNameStr.toLowerCase())) {
        const matArr = this.materials[material.id]
        activityList.push(...matArr.activities);
      }
    });
    return activityList
  }

  public getActivitesWithEqupments(eqId: string) {
    return this.equipments[eqId];
  }

  // END Subcontractor INDEX

  public setProjectDataReady(ready: boolean): void {
    this.projectDataReady = ready;
  }
  public setForgeViewerReady(ready: boolean): void {
    this.forgeViewerReady = ready;
  }

  public getForgeViewerReady(): boolean {
    return this.forgeViewerReady;
  }

  public getProjectReady(): boolean {
    return (this.projectDataReady && this.forgeViewerReady);
  }

  public emitProjectSetup(hasModels: boolean): void {
    this.projectSetupReady.next(hasModels);
  }

  public setHideViewer(hide: boolean): void {
    this.viewerIsHidden = hide;
  }

  public getHideViewerStatus(): boolean {
    return this.viewerIsHidden;
  }

  public setDisabledSidebar(disable: boolean): void {
    this.disableSidebar = disable;
  }

  public setHideSidebar(hide: boolean): void {
    setTimeout(() => { this.hideSidebar = hide; });
  }

  public getHideSidebar(): boolean {
    return this.hideSidebar;
  }

  public getHasModelsStatus(): boolean {
    return this.hasModels;
  }

  public getHasPDFStatus(): boolean {
    return this.hasPDFs;
  }

  async setEquipmentAndMaterials() {
    if (ProjectService.userPermission.subContractorId) {
      await this.materialService.setLocalUserProjectMaterials(this.currentProject.id, ProjectService.userPermission.subContractorId);
      await this.equipmentService.setLocalUserProjectEquipment(this.currentProject.id, ProjectService.userPermission.subContractorId);
    } else {
      await this.materialService.setLocalAllProjectMaterials(this.currentProject.id);
      await this.equipmentService.setLocalAllProjectEquipment(this.currentProject.id);
    }
  }

  async setRfis() {
    if (ProjectService.userPermission.subContractorId) {
      await this.rfiService.setLocalUserProjectRfis(this.currentProject.id, ProjectService.userPermission.subContractorId);
    } else {
      await this.rfiService.setLocalAllProjectRfis(this.currentProject.id);
    }
  }

  async setLabors() {
    if (ProjectService.userPermission.subContractorId) {
      await this.laborService.setLocalUserProjectLabor(this.currentProject.id, ProjectService.userPermission.subContractorId);
    } else {
      await this.laborService.setLocalUserProjectLabor(this.currentProject.id);
    }
  }

  async checkToHideViewerByRoute(route: string, projectId: string) {

    const isHiddenViewerRoute = this.hiddenViewerRoutes.filter(hRoute => route.includes(hRoute)).length > 0;
    const isOptionalHiddenViewerRoute = this.optionalViewerRoutes.filter(hRoute => route.includes(hRoute)).length > 0;

    if (!this.hasModels && isOptionalHiddenViewerRoute && this.viewerMode === ViewerMode.ForgViewer)
      this.setHideViewer(isOptionalHiddenViewerRoute);
    else if (!this.hasPDFs && isOptionalHiddenViewerRoute && this.viewerMode === ViewerMode.PDFViewer) {
      this.setHideViewer(isOptionalHiddenViewerRoute);
    }
    else this.setHideViewer(isHiddenViewerRoute);

    if (this.viewerMode === ViewerMode.PDFViewer && isHiddenViewerRoute) {
      this.pdfViewerService.resetPDFViewerService();
    }
  }

  showSubMenu(isSubMenuOpen) {
    this.isSubMenuOpen = isSubMenuOpen;
  }

  // used to toggle sidebar and save it's state
  toggleSidebarVisibility(state?: boolean) {
    const sideBarNewState = state != null ? state : !this.showProjectSidebar;
    localStorage.setItem('showSidebar', sideBarNewState.toString());
    this.setSidebar(sideBarNewState);
  }

  checkSidebarState(): void {
    if (localStorage.getItem('showSidebar') === null) {
      localStorage.setItem('showSidebar', this.showProjectSidebar.toString());
    } else {
      this.setSidebar(JSON.parse(localStorage.getItem('showSidebar')));
    }
  }

  // used to open or close project sidebar without saving it's state to local storage
  setSidebar(state: boolean): void {
    this.projectSidebarChange.next(state);
  }

  getCurrencyCodes(code?: string) {
    const list = Currencies.getCurrencies();
    if (code) {
      if (list[code]) {
        return list[code];
      } else {
        return {
          'symbol': '$',
          'name': 'US Dollar',
          'symbol_native': '$',
          'decimal_digits': 2,
          'rounding': 0,
          'code': 'USD',
          'name_plural': 'US dollars'
        };
      }
    } else return Object.keys(list).map((k) => list[k]);
  }

  // method used for pages that don't have a sidebar
  checkToHideSidebar(sidebarState: boolean, reset: boolean = false): void {
    if (sidebarState && !reset) this.setSidebar(false);
    if (sidebarState && reset) this.setSidebar(true);
  }

  refreshPage(): void {
    window.location.reload(true);
  }

  getMaterialService(): ProjectMaterialService {
    return this.materialService;
  }

  getEquipmentService(): ProjectEquipmentService {
    return this.equipmentService;
  }

  getLaborService(): ProjectLaborService {
    return this.laborService;
  }

  public checkIfAnnotationExist(activityIds: string[]): string[] {
    return this.pdfViewerService.checkIfAnnotationExist(activityIds);
  }

  public getViews(projectId: string): Observable<any> {
    return this.http.get('/project/' + projectId + '/views');
  }

  public addViews(projectId: string, json): Observable<any> {
    return this.http.post('/project/' + projectId + '/views', json);
  }

  public updateView(projectId: string, viewId: string, json): Observable<any> {
    return this.http.put('/project/' + projectId + '/views/' + viewId, json);
  }

  public deleteView(projectId: string, viewId: string): Observable<any> {
    return this.http.delete('/project/' + projectId + '/views/' + viewId,);
  }

  public getPDFNameForURL(pdfUrls: string[]) {
    let floorPlanIds = [];
    pdfUrls.forEach(url => {
      let floorplan = this.floorPlans.find(element => element.url === url);
      if (floorplan)
        floorPlanIds.push(floorplan.id);
    });
    return floorPlanIds;
  }

  // maps pdf name with id
  public getPDFObjectForId(pdfId: string) {
    return this.floorPlans.find(element => element.id === pdfId);
  }

  public updateAddAnnotationIcons(toggleBool: boolean) {
    this.componentMethodCallSource.next(toggleBool);
  }

  public externalSearchFilterationOnGritTableHandler(searchQuery: string, clearFilter: boolean, searchFilters?: Array<string>) {
    const searchObj = { searchQuery, searchFilters };
    if (clearFilter) this.gritTableExternalSearchControl.next(null)
    else this.gritTableExternalSearchControl.next(searchObj)
  }
}
