import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IActivity } from '../../../models/activity/activity.interface';
import { IUnmodeledTask } from '../../../models/project/project-object/project-object.interface';

import { ProjectObjectService } from '../../../services/project/project-object/project-object.service';
import { ProjectService } from '../../../services/project/project.service';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-object-add-unmodeled',
  templateUrl: './project-object-add-unmodeled.component.html',
  styleUrls: ['./project-object-add-unmodeled.component.scss']
})
export class ProjectObjectAddUnmodeledComponent implements OnInit, OnDestroy {

  @Input() objectInput: IUnmodeledTask;
  @Input() activityEdittable: boolean;
  @Input() selectingUnmodeledPrereqs: boolean;
  @Input() activityAuthority: string[];

  @Output() objectAddOutput: EventEmitter<IUnmodeledTask> = new EventEmitter();
  @Output() prereqAddOutput: EventEmitter<IUnmodeledTask> = new EventEmitter();

  get name() { return this.formInput.get('name'); }
  get activityControl() { return this.formInput.get('activities'); }
  get uPrereqControl() { return this.formInput.get('prereq'); }

  formInput: FormGroup;
  activityOptions: Observable<IActivity[]>;
  prereqOptions: Observable<IUnmodeledTask[]>;
  possibleActivities: IActivity[] = [];
  possiblePrereqs: IUnmodeledTask[] = [];

  constructor(
    private projectService: ProjectService,
    private projectObjectService: ProjectObjectService,
    private formBuilderService: FormBuilder
  ) {/*EMPTY*/}

  async ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    if (this.selectingUnmodeledPrereqs) {
      await this.setValidPrereqs();
      this.formInput = this.buildPrereqInput();
      this.setupPrereqAutoInput();
    } else {
      this.setValidActivities();
      this.formInput = this.buildAddTaskFormInput(this.objectInput);
      this.setupActivityAutoInput();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  onSubmit(formInput) {
    const formValues = formInput.getRawValue();
    const unmodeledTaskOut: IUnmodeledTask = {
      name: formValues.name,
      activities: [formValues.activities.id]
    };
    this.objectAddOutput.emit(unmodeledTaskOut);
  }

  onPrereqSubmit() {
    this.prereqAddOutput.emit(this.uPrereqControl.value);
  }

  buildAddTaskFormInput(unmodeledTask: IUnmodeledTask): FormGroup {
    const unmodeledTaskInput = this.formBuilderService.group({
      id: [null],
      name: ['', Validators.required],
      activities: [null]
    });

    unmodeledTaskInput.controls['activities'].setValidators([Validators.required, this.validateActivity()]);

    if (unmodeledTask) {
      unmodeledTaskInput.controls['id'].setValue(unmodeledTask.id);
      unmodeledTaskInput.controls['name'].setValue(unmodeledTask.name);
      if (unmodeledTask.activities.length > 0) {
        const lockedActivity = unmodeledTask.activities[0];
        unmodeledTaskInput.controls['activities'].setValue(this.possibleActivities.find(activity => activity.id === lockedActivity));
        unmodeledTaskInput.controls['activities'].disable();
      }
    }

    return unmodeledTaskInput;
  }

  buildPrereqInput(): FormGroup {
    const uPrereqInput = this.formBuilderService.group({
      prereq: [null]
    });
    uPrereqInput.controls['prereq'].setValidators([Validators.required, this.validateActivity()]);
    return uPrereqInput;
  }

  validateActivity(): ValidatorFn {
    return (control: FormControl): {} => {
      const invalid = control.value && control.value.id ? false : true;
      if (invalid) return { invalidActivity: true };
      return {};
    };
  }

  setValidActivities(): void {
    this.possibleActivities = [];
    const canPlanAnything = this.activityAuthority.includes('00 00 00');
    const activitiesToSearch = this.projectService.getLocalAllActivities();
    activitiesToSearch.forEach(activity => {
      if (activity.children.length === 0) {
        if (this.activityAuthority.includes(activity.id) || canPlanAnything) this.possibleActivities.push(activity);
      }
    });
    this.possibleActivities = Utils.sortByString(this.possibleActivities, 'name');
  }

  setupActivityAutoInput() {
    this.activityOptions = this.activityControl.valueChanges.pipe(
      startWith<string | IActivity>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.activitySearch(name) : this.possibleActivities.slice(0, Math.min(this.possibleActivities.length, 50)))
    );
  }

  displayFn(item: any): string { return item && item.name ? item.name : undefined; }

  activitySearch(name: string): IActivity[] {
    const searchQuery = name.toLowerCase();
    const searchRes = this.possibleActivities.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  async setValidPrereqs() {
    this.possiblePrereqs = await this.projectObjectService.getUnmodeledObjectList(this.projectService.currentProject.id).toPromise();
    this.possiblePrereqs = Utils.sortByString(this.possiblePrereqs, 'name');
  }

  setupPrereqAutoInput() {
    this.prereqOptions = this.uPrereqControl.valueChanges.pipe(
      startWith<string | IUnmodeledTask>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.prereqSearch(name) : this.possiblePrereqs.slice(0, Math.min(this.possiblePrereqs.length, 50)))
    );
  }

  prereqSearch(name: string): IUnmodeledTask[] {
    const searchQuery = name.toLowerCase();
    const searchRes = this.possiblePrereqs.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.formInput.invalid) {
        if (this.selectingUnmodeledPrereqs) {
          if (this.formInput.invalid)
          this.onPrereqSubmit();
        } else {
          this.onSubmit(this.formInput);
        }
      }
    }
  }

}
