
import {takeUntil, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject ,  Subject } from 'rxjs';

import { IDropdownItem } from '../../models/dropdown/dropdown.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['../dropdown/dropdown.component.scss']
})

export class DynamicDropdownComponent implements OnDestroy, OnInit, OnChanges {

  @Input() displayId: string;
  @Input() enabled: boolean;
  @Input() loadDataFunction: () => Promise<IDropdownItem[]>;
  @Input() multiSelect: boolean;
  @Input() showDropdown: boolean;
  @Input() loadDataAgain: number;
  @Input() selectedIds: string[] = [];
  @Input() title: string;
  @Input() placeHolderText: string;
  @Input() buttonDisplay: boolean = false;
  @Input() buttonDisplayIcon: string;
  @Input() containerEl: any; // must be nodeType
  @Input() overrideSort: boolean = true;

  @Output() dropdownClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() displayTextOutput: EventEmitter<string> = new EventEmitter();
  @Output() selectedItemsOutput: EventEmitter<any[]> = new EventEmitter();

  displayText: string = '';
  allDropdownItems: IDropdownItem[] = [];
  dropdownItems: IDropdownItem[] = []; // Currently rendered in list
  curSearchRes: IDropdownItem[] = [];
  searchQuery: string = '';
  firstLoad: boolean = true;
  loading: boolean = false;
  pageSize: number = 50;
  moreAvailable = false;
  selectedItems: IDropdownItem[] = [];
  dropdownBelow: boolean = true;

  public keyUp = new Subject<string>();

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private el: ElementRef) {/*EMPTY*/ }

  ngOnInit() {
    this.setDisplayAndPosition();

    this.keyUp.pipe(
      map(event => event),
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroyed$))
      .subscribe(query => {
        this.searchQuery = query;
        this.applySearch();
      });
  }

  async ngOnChanges(event) {
    if (this.firstLoad || event.loadDataFunction || event.loadDataAgain) {
      this.loading = true;
      await this.loadData();
      this.firstLoad = false;
    }
    this.checkAlreadySelectedItems();

    if (event.resetActivityDropdownInput) {
      this.clearSelectedItems();
    }
    this.setDisplayAndPosition();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  setMoreAvailable() {
    if (!Utils.isEmpty(this.searchQuery)) return this.moreAvailable = this.dropdownItems.length < this.curSearchRes.length;
    this.moreAvailable = this.dropdownItems.length < this.allDropdownItems.length;
  }

  checkAlreadySelectedItems(): void {
    this.selectedItems = [];
    const itemsToSelect = this.allDropdownItems.filter(item => this.selectedIds.includes(item.value));
    itemsToSelect.forEach(item => { item.selected = true; this.selectedItems.push(item); });
  }

  clearSelectedItems() {
    this.selectedItems = [];
    const limit = Math.min(this.pageSize, this.allDropdownItems.length);
    this.dropdownItems = this.allDropdownItems.slice(0, limit);
    this.allDropdownItems.forEach(item => item.selected = false);
    this.searchQuery = '';
    this.setDisplayText();
    this.setMoreAvailable();
  }

  applySearch() {
    if (Utils.isEmpty(this.searchQuery)) {
      if (!Utils.isEmptyList(this.allDropdownItems)) {
        const limit = Math.min(this.pageSize, this.allDropdownItems.length);
        this.dropdownItems = this.allDropdownItems.slice(0, limit);
      }
    } else {
      this.curSearchRes = this.allDropdownItems.filter(item => item.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
      const limit = Math.min(this.pageSize, this.curSearchRes.length);
      this.dropdownItems = this.curSearchRes.slice(0, limit);
    }
    this.dropdownItems = this.sortItems(this.dropdownItems);
    this.setMoreAvailable();
  }

  itemSelected(dropdownItem: IDropdownItem) {
    dropdownItem.selected = !dropdownItem.selected;
    const itemsSelected = this.allDropdownItems.filter(item => item.selected === true);
    this.selectedItems = itemsSelected;
    if (!this.multiSelect && !Utils.isEmptyList(this.selectedItems)) {
      this.allDropdownItems.forEach(item => item.selected = false);
      dropdownItem.selected = true;
      this.selectedItems = [dropdownItem];
    }
    this.selectedIds = this.selectedItems.map(item => item.value);
    this.selectedItemsOutput.emit(this.selectedIds);
    this.setDisplayText();
  }

  loadData(): Promise<void> {
    this.loading = true;
    return new Promise((resolve) => {
      this.allDropdownItems = [];
      this.loadDataFunction().then(dropdownItems => {
        this.allDropdownItems = this.sortItems(dropdownItems);
        const limit = Math.min(this.pageSize, this.allDropdownItems.length);
        this.dropdownItems = this.allDropdownItems.slice(0, limit);
        this.setMoreAvailable();
        this.loading = false;
        return resolve();
      });
    });
  }

  sortItems(itemsToSort): IDropdownItem[] {
    let sortedItems: IDropdownItem[] = [];
    sortedItems = this.overrideSort ? Utils.sortByString(itemsToSort, 'label') : itemsToSort;
    const filterRes = sortedItems.filter(item => Utils.isEmpty(item.value));
    if (!Utils.isEmptyList(filterRes)) {
      filterRes.forEach(emptyItem => {
        const emptyIndex = sortedItems.indexOf(emptyItem);
        sortedItems.splice(emptyIndex, 1);
        sortedItems.splice(0, 0, emptyItem);
      });
    }
    return sortedItems;
  }

  loadMore() {
    let limit;
    if (!Utils.isEmpty(this.searchQuery)) {
      limit = Math.min(this.dropdownItems.length + this.pageSize, this.curSearchRes.length);
      this.dropdownItems = this.curSearchRes.slice(0, limit);
    } else {
      limit = Math.min(this.dropdownItems.length + this.pageSize, this.allDropdownItems.length);
      this.dropdownItems = this.allDropdownItems.slice(0, limit);
    }
  }

  setDisplayAndPosition() {
    this.setDisplayText();
    // TODO find a way (if possible) to make this this runs only once when dropdown opens and more smooth
    // after data loads check position and boundaries of element to render dropdown in view
    if (this.containerEl) {
      this.checkPosition();
    } else {
      // Added because dropdown was displaying above
      // So if container isn't passed in, we'll look for the lower-component instead for calculations
      if (document.getElementsByClassName('lower-component').length > 0) {
        this.containerEl = document.getElementsByClassName('lower-component')[0];
        this.checkPosition();
      }
    }
  }

  setDisplayText() {
    if (!Utils.isEmpty(this.selectedIds) && !this.buttonDisplay) {
      if (this.selectedIds.length > 1) {
        this.displayText = this.selectedIds.length + ' selected';
      } else {
        this.displayText = this.selectedItems[0].label;
      }
    } else if (this.placeHolderText) {
      this.displayText = this.placeHolderText;
    } else {
      this.displayText = '0 Items selected';
    }
    this.displayTextOutput.emit(this.displayText);
  }

  checkPosition() {
    // if dropdown is not showing dont do ignore
    if (!this.showDropdown) return;

    const buttonDropdown = this.el.nativeElement.getElementsByClassName('list-dropdown-textbox-container-grit')[0];
    const buttonRect = buttonDropdown.getBoundingClientRect();

    const containerLimits = {
      scrollTop: this.containerEl.scrollTop,
      visibleHeight: this.containerEl.offsetHeight,
      scrollBottom: this.containerEl.scrollHeight - this.containerEl.offsetHeight - this.containerEl.scrollTop, // distance
      height: this.containerEl.scrollHeight, // entire height of scrollable container
    };

    // calculate the current position of menu
    const menuTop = window.innerHeight - (buttonRect.y + buttonRect.height);
    // calculate the available space below the selected menu to the bottom of scrollable container
    const availableBot = menuTop + containerLimits.scrollBottom;
    // rendering above and below
    if (availableBot > 270) { // 270 = height of dropdown
      // render below dropdown
      this.dropdownBelow = true;
    } else {
      // render above dropdown
      this.dropdownBelow = false;
    }
  }

  toggleDropdown(showDropdown?: boolean) {
    this.showDropdown = !this.showDropdown;
    this.showDropdown = showDropdown != null ? showDropdown : !this.showDropdown;
    this.dropdownClosed.emit(this.showDropdown);
  }

  autoClose(event): void {
    const target = event.target;
    // both classes we are dynamically added with ngIf's but this function is also hidden and will only run when both classes are available.
    if (!target.closest('.list-dropdown-grit') && !target.closest('.list-dropdown-textbox-container-grit')) {
      this.toggleDropdown(false);
    }
  }
}
