import { Component, OnInit,ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SegmentService } from '../../services/segment/segment.service';
import { ProjectPlannerPopoutModalService } from '../../services/project/project-planner-popout-modal/project-planner-popout-modal.service';
import { PDFViewerService } from '../../services/pdf/pdfViewer.service';
import { ViewerMode } from '../../utils/enums/shared.enum';
import { ISelectionModal } from '../../models/selection-modal/selection-modal.interface';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-project-pdf-popout',
  templateUrl: './project-pdf-popout.component.html',
  styleUrls: ['./project-pdf-popout.component.scss']
})
export class ProjectPDFPopoutComponent implements OnInit {
  loadingModelsMessage: string = 'Generating Viewer...';
  forgeViewerLoadingProgress: number = 0;

  // Forge Viewer
  @ViewChild('pdfViewer') projectPDFViewerComponent;
  projectId: string;
 // forgeViewerService: ForgeViewerService;
  projectPlannerPopoutModalService: ProjectPlannerPopoutModalService;
  parentRouterService: Router;
  viewerLoaded: boolean = false;
  
  
  // Planner page modal
  onPlannerPage: boolean = false;
  showModal: boolean;
  activitySelection: ISelectionModal;
  activeFilters: string[] = [];

  // Subscriptions
  routeSubscription: Subscription;
  parentRouterSubscription: Subscription;
  popoutKeyUpEventSubscription: Subscription;
  selectedActivitySubscription: Subscription;
  showActivitySelectionSubscription: Subscription;
  activitySelectionSubscription: Subscription;
  activeFilterSubscription: Subscription;
  // Page disabling
  disablePage: boolean = false;
  pdfViewerMode: boolean = false;
  projectPDFFiles: String[] = [];
  viewerMode: ViewerMode;

  constructor(
    private routeService: ActivatedRoute,
    public pdfViewerService: PDFViewerService,
    public projectService: ProjectService
  ) {}

  ngOnInit() {

    SegmentService.hideLauncher();
    window['gritPDFPopoutComponent'] = this;

    if (window.opener.gritParentComponent === null || window.opener.gritParentComponent.childWindow === null) {
      this.onClose();
      window.close();
    } else {

      this.pdfViewerService = window.opener.gritParentComponent.pdfViewerService;
      this.pdfViewerMode = true;
      this.projectService.viewerMode = ViewerMode.PDFViewer;

      this.projectId=this.routeService.snapshot.params.projectId
      //this.projectId=this.projectService.currentProject.id;
      // this.routeSubscription = this.routeService.params.subscribe(params => {
      //   this.projectId = params['projectId'];
      // });

      this.parentRouterService = window.opener.gritParentComponent.router;
      if (this.parentRouterService) {
        this.pageListeners();
        SegmentService.track('Viewer Popped Out', {projectId: window.opener.gritParentComponent.projectId, activeUrl: window.opener.gritParentComponent.activeUrl});

        // This does not fire the first time...which is why we run page listeners and then
        // we also need to run it if the page changes!
        this.parentRouterSubscription = this.parentRouterService.events.subscribe(async () => {
          // We need to turn modals off if we are switching pages...but only if we have the service!
          if (this.projectPlannerPopoutModalService) {
            this.projectPlannerPopoutModalService.setShowActivityModal(false);
          }

          // We need to reset listeners, depending on the page being visited
          this.pageListeners();
        });
      }
      window.addEventListener('beforeunload', this.onClose.bind(this));

      window.addEventListener('keydown', (event) => {
        if (this.viewerLoaded) {
          window.opener.gritParentComponent.popoutKeyDownEvent(event);
        }
      });

      window.addEventListener('keyup', (event) => {
        if (this.viewerLoaded) {
          window.opener.gritParentComponent.popoutKeyUpEvent(event);
        }
        // Enter or ESC key (we need to close the modal if it happens to be open)
        if ((event.keyCode === 13 || event.keyCode === 27) && this.onPlannerPage) {
          this.closeModalClick();
        }
      });
    }
  }

  onClose(): void {
    // window.opener.gritParentComponent.closedPopout();
    this.pdfViewerService.detatchedWindow = false;
    if(window.opener)
      window.opener.gritParentComponent.pdfControl.detatchedWindow = false;
    this.pdfViewerService.closePopWindow();
    
    // this.projectPDFViewerComponent.closePopWindow();
  }

  pageListeners() {
    // We don't want the activity pop up to display on non-planner pages
    const activeUrl = window.opener.gritParentComponent.activeUrl;
    const offset = activeUrl.indexOf('/planner');
    if (offset >= 0 && window.opener.gritParentComponent.projectPlannerPopoutModalService) {
      this.onPlannerPage = true;
      this.projectPlannerPopoutModalService = window.opener.gritParentComponent.projectPlannerPopoutModalService;
      this.openPlannerListeners();
    } else {
      // We aren't in kansas anymore - we need to close listensers
      this.closePlannerListeners();
      this.onPlannerPage = false;
    }
  }

  openPlannerListeners() {
    this.showActivitySelectionSubscription = this.projectPlannerPopoutModalService.showActivityModal.subscribe(
      res => {
        this.showModal = res;
        if (this.showModal) {
          SegmentService.track('Popped out viewer (planner page): Activity selected', {projectId: this.projectId});
        }
      }
    );

    this.activitySelectionSubscription = this.projectPlannerPopoutModalService.activitySelection.subscribe(
      res => {
        this.activitySelection = res;
      }
    );

    this.activeFilterSubscription = this.projectPlannerPopoutModalService.activeFilter.subscribe(
      res => {
        this.activeFilters = res;
      }
    );
  }

  closePlannerListeners() {
    if (this.showActivitySelectionSubscription) {
      // Close the modal if it HAPPENS to be open
      // this.closeActivityClick();
      this.closeModalClick();
      this.showActivitySelectionSubscription.unsubscribe();
    }
    if (this.activitySelectionSubscription) {
      this.activitySelectionSubscription.unsubscribe();
    }
  }

  public loadWithOnePageRender(id: number) {
    this.projectPDFViewerComponent._pdfUrlId = id;
    this.projectPDFViewerComponent.fitToScreen();

    if (this.projectPDFViewerComponent._enableDrawAnnotationPending) {
      this.projectPDFViewerComponent.enableDrawAnnotation();
    }
  }

  forgeViewLoaded(viewerObjectNodes: any): void {
    this.viewerLoaded = true;
    // set stuff in service
   this.pdfViewerService.setPDFViewerPopout(this.projectPDFViewerComponent);
   this.pdfViewerService.setMyPopout(this);

    // do everything we normally do in parent as well
    window.opener.gritParentComponent.forgeViewLoaded(viewerObjectNodes);
  }

  handleForgeViewerOutput(forgeViewerOutputData): void {
    window.opener.gritParentComponent.handleForgeViewerOutput(forgeViewerOutputData);
  }

  showSimilarOutput(event): void {
    window.opener.gritParentComponent.showSimilarOutput(event);
  }

  clearFiltersOutput(event): void {
    window.opener.gritParentComponent.clearFiltersOutput(event);
  }

  contextMenuOutput(event): void {
    window.opener.gritParentComponent.contextMenuOutput(event);
  }

  closeWindow() {
    window.close();
  }

  closePopWindow() {

    // let win = window.opener.document.getElementsByClassName("upper-component") as HTMLCollectionOf<HTMLElement>;;
    
    
    // if (win[0].style.display === "none") {
    // win[0].style.display = "block";
    // } else {
    // win[0].style.display = "block";
    // }
    // window.close();
    
    }

  closeActivityClick() {
    this.projectPlannerPopoutModalService.setShowActivityModal(false);
  }

  closeModalClick() {
    this.projectPlannerPopoutModalService.setShowActivityModal(false);
  }

  setDisablePage(disable): void {
    this.disablePage = disable;
  }

  confirmActivityClick(activity) {
    this.projectPlannerPopoutModalService.setSelectedActivity(activity);
  }

  saveViewOutput() {
    window.opener.gritParentComponent.saveViewOutput();
  }
}


