import * as FileSaver from 'file-saver';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { IProjectLabor } from '../../models/project/project-labor/project-labor.interface';
import { IUserPermission } from '../../models/user/user.interface';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectLaborService } from '../../services/project/project-labor/project-labor.service';

import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { IGritTable } from '../../shared/grit-table/grit-table';
import { Utils } from '../../utils/utils';
import { ForgeViewerService } from '../../services/forge/forge-viewer.service';

@Component({
  selector: 'app-project-labor-settings',
  templateUrl: './project-labor-settings.component.html',
  styleUrls: ['./project-labor-settings.component.scss']
})
export class ProjectLaborSettingsComponent implements OnInit, OnDestroy {

  @Input() projectPermissionInput: IUserPermission;
  @Input() projectId: string;

  @Output() ProcurementLineFilter: EventEmitter<boolean> = new EventEmitter();

  file: any = null;
  showExport: boolean = true;
  upLoading: boolean = false;
  isLoading: boolean = true;
  tableData: IGritTable;
  importedtableData: IGritTable;

  currentData: IProjectLabor[] = [];
  labor: IProjectLabor[] = [];
  showModal: boolean = false;
  formInput: FormGroup;
  currency;
  searchQuery: string;

  hasProcore = false;
  showProcLinesOnMaster = true;
  plannedQuantity: any = 0;
  importedLabors: IProjectLabor[] = [];
  addedLabors: IProjectLabor[] = [];
  autoAssignResources: boolean;
  searchFilters: Array<string> = ["resourceId", "name"];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private laborService: ProjectLaborService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private segmentService: SegmentService,
    public forgeViewerService: ForgeViewerService,
  ) { }

  ngOnInit() {
    this.getData();
    this.autoAssignResources = this.projectService.currentProject.autoAssignResources ? true : false;
    this.showProcLinesOnMaster = (localStorage.getItem('previouslySavedProcuremntLinesToggle') === 'true');
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async exportCSV() {
    let gcOnly = true;
    let subId = null;
    if (this.projectPermissionInput.subContractorId) {
      gcOnly = false;
      subId = this.projectPermissionInput.subContractorId;
    }
    const results = await this.laborService.exportLabors(this.projectId, subId, gcOnly).pipe(takeUntil(this.destroyed$));

    if (results) {
      results.subscribe(blob => {
        FileSaver.saveAs(blob, 'Labor_' + this.projectService.currentProject.name + '.csv');
      });
    }
  }

  async getData() {
    // if (this.projectService.currentProjectAccount.hasProcore && this.projectService.currentProject.procoreProjectId) this.hasProcore = true;
    let gcOnly = true;
    let subId = null;
    if (this.projectPermissionInput.subContractorId) {
      gcOnly = false;
      subId = this.projectPermissionInput.subContractorId;
    }
    const results = await this.laborService.getList(this.projectId, subId, gcOnly).toPromise();
    this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    if (results) {
      this.labor = Utils.sortByString(results, 'name');
      this.labor.forEach((item) => {
        item.importedFromCsv ? this.importedLabors.push(item) : this.addedLabors.push(item);
      });
      this.setTableData();
      this.isLoading = false;
      this.forgeViewerService.setDisablePage(false);
    } else {
      this.isLoading = false;
      this.forgeViewerService.setDisablePage(false);
    }
  }

  setTableData() {
    this.tableData = this.laborService.transformToTableData(this.labor, this.projectPermissionInput, this.currency);
    this.importedtableData = this.laborService.transformToImportedTableData(this.importedLabors, this.projectPermissionInput, this.currency);
  }

  async edit(item: IProjectLabor) {
    if (this.projectPermissionInput.subContractorId) item.subId = this.projectPermissionInput.subContractorId;
    this.laborService.update(item.id, item).pipe(take(1)).subscribe(
      (res) => {
        const index1 = this.labor.findIndex(i => i.id === res.id);
        if (index1 >= 0) this.labor[index1] = res;
        const index2 = this.importedLabors.findIndex(i => i.id === res.id);
        if (index2 >= 0) this.importedLabors[index2] = res;
        this.setTableData();
        this.showModal = false;
      },
      err => {
        this.notificationService.error(err, {type: 'labor', action: 'update'});
      }
    );
  }

  async deleteRows(selectedIds) {
    await this.laborService.delete(selectedIds).toPromise();
    this.labor = this.labor.filter(item => !selectedIds.includes(item.id));
    this.importedLabors = this.importedLabors.filter(item => !selectedIds.includes(item.id));
    this.setTableData();
  }

  async create(item: IProjectLabor) {
    item.projectId = this.projectId;
    if (this.projectPermissionInput.subContractorId) item.subId = this.projectPermissionInput.subContractorId;
    this.laborService.insert(item).pipe(take(1)).subscribe(
      res => {
        this.labor.push(res);
        this.labor = Utils.sortByString(this.labor, 'name');
        this.currentData = this.labor;
        this.setTableData();
        this.showModal = false;
      },
      err => {
        this.notificationService.error(err, {type: 'labor', action: 'insert'});
      }
    );
  }

  validateNewReasonRowFunction(row): boolean {
    if (row.find(col => col.name === 'name').value.length > 0) return true;
    return false;
  }

  async showCreateModal(id: string): Promise<void> {
    const currentItem = this.labor.find(s => s.id === id);
    if (id) this.formInput = this.laborService.buildFormInput(currentItem);
    else this.formInput = this.laborService.buildFormInput(null);
    this.showModal = true;
    this.plannedQuantity = currentItem && parseFloat(Math.round(((currentItem.plannedQuantity * currentItem.resourceRate) * 100) / 100).toString()).toFixed(2);
  }

  formOutput(item: any) {
    if (item.id) this.edit(item);
    else this.create(item);
  }

  fileAdded(event: EventTarget) {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    this.file = files[0];
  }

  setProcLinesToggle(event) {
    this.showProcLinesOnMaster = event.checked;
    this.ProcurementLineFilter.emit(event.checked);
    localStorage.setItem('previouslySavedProcuremntLinesToggle', event.checked);
  }

  uploadFile(): void {
    if (this.upLoading || this.file === null) return;
    this.upLoading = true;
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    this.forgeViewerService.setDisablePage(true);
    this.laborService.importLabor(this.projectId, this.file, this.projectPermissionInput.subContractorId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (res.type === 4) {
          this.upLoading = false;
          this.file = null;
          this.segmentService.track('Labor CSV Uploaded', {projectId: this.projectId});
          this.getData();
        }
      },
      err => {
        this.upLoading = false;
        this.file = null;
        this.notificationService.error(err, {action: 'upload', filetype: '.csv'});
      }
    );
  }

  importFromProcore() {
    this.isLoading = true;
    this.laborService.importFromProcore(this.projectId).subscribe(
      res => {
        this.labor = this.labor.concat(res);
        this.labor = Utils.sortByString(this.labor, 'name');
        this.currentData = this.labor;
        this.setTableData();
        this.isLoading = false;
      },
      err => {
        this.notificationService.error(err);
        this.isLoading = false;
      });
  }

  filterSearch(searchQuery: string): void {
    this.projectService.externalSearchFilterationOnGritTableHandler(searchQuery, false, this.searchFilters);
  }

  clearSearchAndFilters(): void {
    this.searchQuery = null;
    this.projectService.externalSearchFilterationOnGritTableHandler(null, true, this.searchFilters);
  }
}
