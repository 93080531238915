import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IGritTable, IGRow, IGRowItem } from '../../../shared/grit-table/grit-table';
import { IProjectEquipment } from '../../../models/project/project-equipment/project-equipment.interface';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { IProjectMaterial } from '../../../models/project/project-material/project-material.interface';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { ProjectService } from '../../../services/project/project.service';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-activity-materials',
  templateUrl: './activity-materials.component.html',
  styleUrls: ['./activity-materials.component.scss']
})
export class ActivityMaterialsComponent implements OnInit {

  @Input() materialIds: string[] = [];
  @Input() equipmentIds: string[] = [];
  @Input() selectedActivityIds: Array<string>;

  @Output() updateOutput: EventEmitter<{ materialIds: string[] }> = new EventEmitter();
  @Output() materialOut: EventEmitter<boolean> = new EventEmitter();
  @Output() updateParameters:  EventEmitter<Array<any>> = new EventEmitter();

  allMaterials: IProjectMaterial[] = [];
  filteredMats: Observable<IProjectMaterial[]>;
  materialOptions: IProjectMaterial[];
  materialCtrl = new FormControl();
  materialChips: IProjectMaterial[];
  tableData: IGritTable;
  totalBudget: string;
  currency;
  autoAssignResources: boolean;

  get displayFn() { return Utils.defaultDisplayFn; }

  constructor(
    private materialService: ProjectMaterialService,
    private projectService: ProjectService,
    private activityService: ActivityService
  ) {/*EMPTY*/ }

  ngOnInit() {
    this.allMaterials = ProjectService.userPermission.subContractorId ? this.materialService.getLocalUserProjectMaterials() : this.materialService.getLocalAllProjectMaterials();
    this.setValidOptions();
    this.setAutocomplete();

    if(this.selectedActivityIds.length === 1){
      this.activityService.getActivityMaterials(this.selectedActivityIds[0]).subscribe(res => {
        this.materialChips = res;
        this.setTableData();
      });
    } else{
      this.activityService.getActivitiesMaterials(this.selectedActivityIds).subscribe(res => {
        this.materialChips = res;
        this.setTableData();
      });
    }
    this.projectService.currentProject.autoAssignResources ? this.autoAssignResources = this.projectService.currentProject.autoAssignResources : this.autoAssignResources = false;
  }

  setTableData() {
    this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    this.autoAssignResources ?  this.tableData = this.materialService.transformToTableDataForActivityWithAutoAssign(this.materialChips, ProjectService.userPermission, this.currency) : this.tableData = this.materialService.transformToTableDataForActivity(this.materialChips, ProjectService.userPermission, this.currency);
    this.calculateTotalBudget();
  }

  editRows(event) {
    const materialId = event.key;
    let updatedMaterial: any = [];
    updatedMaterial = this.materialChips.find(m => m.id === materialId);
    if(updatedMaterial.isMannuallyAddedPlannedQty){
      updatedMaterial.equipPlannedQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'plannedQuantity').value;
    }
    
    updatedMaterial.plannedQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'plannedQuantity').value;
    updatedMaterial.actualQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'actualQuantity').value;
    this.updateParameters.emit(updatedMaterial);
    this.calculateTotalBudget();
  }

  calculateTotalBudget() {
    let totalBudget: any = 0.00;
    const data: any = this.materialChips;

    data.forEach((item) => {
      let selectedValue = 0;

      if (item.elementUnit.toString() === 'area') {
        selectedValue = item.areaSum ? item.areaSum : 0;
      } else if (item.elementUnit.toString() === 'volume') {
        selectedValue = item.volumeSum ? item.volumeSum : 0;
      } else if (item.elementUnit.toString() === 'length') {
        selectedValue = item.lengthSum ? item.lengthSum : 0;
      } else if (item.elementUnit.toString() === 'unit') {
        selectedValue = 1;
      }

      const plannedQuantity: any =  item.isMannuallyAddedPlannedQty ? parseFloat(item.matPlannedQuantity) : selectedValue * item.resourceRate;
      const budgetAssigned: any = plannedQuantity * item.cost;
      totalBudget += parseFloat(budgetAssigned);
    });

    this.totalBudget = `$ ${totalBudget.toFixed(2)}`;
  }

  deleteRows(Ids) {
    Ids.forEach(id => {
      this.removeMaterial(id);
    });
  }

  removeMaterial(materialID: string) {
    this.materialOut.emit(true);
    this.materialIds = this.materialIds.filter(id => id !== materialID);
    this.materialChips = this.materialChips.filter(item => item.id !== materialID);
    this.updateOutput.emit({ materialIds: this.materialIds });
    this.clearInput();
    this.calculateTotalBudget();
  }

  matSelected(matMaterial) {
    this.materialOut.emit(true);
    const material: IProjectMaterial = matMaterial.option.value;
    this.materialIds.push(material.id);
    this.materialChips.push(this.materialOptions.find(item => item.id === material.id));
    this.setTableData();
    this.updateOutput.emit({ materialIds: this.materialIds });
    this.clearInput();
  }

  setValidOptions(): void {
    this.materialOptions = this.allMaterials.filter(item => !this.materialIds.includes(item.id));
  }

  setAutocomplete(): void {
    this.filteredMats = this.materialCtrl.valueChanges.pipe(
      startWith<string | IProjectMaterial>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.materialSearch(name) : this.getAllMaterialOptions()));
  }

  materialSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.materialOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllMaterialOptions() {
    return this.materialOptions.slice(0, Math.min(this.materialOptions.length, 50));
  }

  clearInput(): void {
    this.materialCtrl.setValue('');
    this.materialCtrl.updateValueAndValidity();

    this.setValidOptions();
    this.setAutocomplete();
  }
}
