import { Injectable } from '@angular/core';

import { IEpochRange, IProjectScheduleItem, IProjectScheduleStep, IScheduleChartSelectionOutput } from '../../models/project/project-schedule/project-schedule.interface';

import { CriticalityColor, TaskStatusColor } from '../../utils/enums/hex-color.enum';
import { Utils } from '../../utils/utils';

import * as moment from 'moment';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
@Injectable()
export class ChartService {
  private unassignedSubcontractorObject: IProjectSubContractor = {
    id: 'unassigned',
    subContractorId: 'unassigned',
    name: 'Unassigned',
    abbreviation: 'unassigned',
    activities: [],
    hexCode: '#a4a4b2',
    availableCrew: 0
  };
  readonly dayInterval: number = 1000 * 60 * 60 * 24;
  readonly weekInterval: number = 1000 * 60 * 60 * 24 * 7;

  criticalityColorPalette: any[] = [CriticalityColor.c1, CriticalityColor.c2, CriticalityColor.c3, CriticalityColor.c4, CriticalityColor.c5];
  statusColorPallette: any[] = [TaskStatusColor.scheduled, TaskStatusColor.committed, TaskStatusColor.inProgress, TaskStatusColor.completed];

  // tslint:disable-next-line:no-empty
  constructor() { }

  getCriticalityColorPalette(): string[] {
    return this.criticalityColorPalette;
  }

  getStatusColorPallette(): string[] {
    return this.statusColorPallette;
  }

  // returns complete list of active epochs displayed in chart
  getListOfActiveEpochs(startOfWeek: number, totalDays: number): number[] {
    const weekOfEpochs = [];
    for (let i = 0; i < totalDays; i++) {
      const startOfDay = moment.utc(startOfWeek).clone().add(i, 'days').startOf('day').valueOf();
      weekOfEpochs.push(startOfDay);
    }
    return weekOfEpochs;
  }

  // returns start and end epoch values displayed in chart
  getActiveEpochRange(activeDay: number, totalDays: number): IEpochRange {
    const epochWeekStart = moment.utc(activeDay).clone().startOf('day').valueOf();
    const epochWeekEnd = moment.utc(activeDay).clone().add(totalDays - 1, 'days').endOf('day').valueOf();

    return {
      startEpochRange: epochWeekStart,
      endEpochRange: epochWeekEnd,
      startRangeForDisplay: Utils.formatDate(epochWeekStart),
      endRangeForDisplay: Utils.formatDate(epochWeekEnd)
    };
  }
  
  positionTooltip(xPos: any, yPos: any, width: number, height: number): any {
    const sidebarBuffer = JSON.parse(localStorage.getItem('showSidebar')) ? 300 : 0;
    const pointerBuffer = 30;
    const xBuffer = width + pointerBuffer;
    const yBuffer = height + pointerBuffer;
    const distanceFromRightEdge = (window.innerWidth - sidebarBuffer) - xPos;
    const distanceFromBottomEdge = window.innerHeight - yPos;
    const horizontalPostion = distanceFromRightEdge < xBuffer
      ? xPos - (width + pointerBuffer) + 'px'
      : xPos + pointerBuffer + 'px';
    const verticalPostion = distanceFromBottomEdge < (yBuffer / 2)
      ? yPos - (yBuffer - distanceFromBottomEdge) + 'px'
      : yPos - (height / 2) + 'px';

    return {
      left: horizontalPostion,
      top: verticalPostion
    };
  }

  getBetweenDates(startDate: number, endDate: number): number[] {
    const dates = [];
    const momentCurrDate = moment.utc(startDate).clone().startOf('day');
    const momentEndDate = moment.utc(endDate).clone().startOf('day');

    while (moment(momentCurrDate).isSameOrBefore(momentEndDate)) {
      dates.push(momentCurrDate.valueOf());
      momentCurrDate.add(1, 'days');
    }

    return dates;
  }

  getDiffInDays(startDate: number, endDate: number): number {
    return moment(endDate).diff(startDate, 'days');
  }

  // returns days, months, years etc. in range
  getIntervalsInRange(startDate: number, endDate: number, interval: any): number[] {
    const months = [];
    const startMonth = moment.utc(startDate).clone().startOf(interval);
    const endMonth = moment.utc(endDate).clone().startOf(interval);

    while (moment(startMonth).isSameOrBefore(endMonth)) {
      months.push(startMonth.valueOf());
      startMonth.add(1, interval);
    }

    return months;
  }

  sortStepsBySubContractorName(steps: IProjectScheduleStep[], subcontractors: IProjectSubContractor[], includeUnassignedSteps: boolean = false) {
    const stepsBySubcontractor = {};
    let subInfo;
    steps.forEach(step => {
      const filterStepBySub = subcontractors.filter(sub => sub.id === step.subContractorId);
      if (filterStepBySub.length > 0) {
        subInfo = filterStepBySub;
      } else if (includeUnassignedSteps) {
        step.subContractorId = this.unassignedSubcontractorObject.id;
        subInfo = [this.unassignedSubcontractorObject];
      }

      if (subInfo && subInfo.length > 0) {
        step['subInfo'] = subInfo[0];
        if (stepsBySubcontractor[subInfo[0].name]) {
          stepsBySubcontractor[subInfo[0].name].push(step);
        } else {
          stepsBySubcontractor[subInfo[0].name] = [step];
        }
      }
    });

    const sortedList = {};

    Object.keys(stepsBySubcontractor).sort((a, b) => {
      if (stepsBySubcontractor[a][0].scheduledStart > stepsBySubcontractor[b][0].scheduledStart) return 1;
      else if (stepsBySubcontractor[a][0].scheduledStart < stepsBySubcontractor[b][0].scheduledStart) return -1;
      else return 0;
    }).forEach(key => {
      sortedList[key] = stepsBySubcontractor[key];
    });

    return sortedList;
  }
  /*---NEW SCHEDULE CHART METHODS---*/

  /*
  // Should convert any schedule method using 'sortStepsBySubContractor' to 'sortStepsBySubContractorName' and delete 'sortStepsBySubContractor'
  */
  sortStepsBySubContractor(steps: IProjectScheduleStep[]) {
    const stepsBySubcontractor = {};
    steps.forEach(step => {
      const subContractorId = step.subContractorId ? step.subContractorId : null;
      if (stepsBySubcontractor[subContractorId]) {
        stepsBySubcontractor[subContractorId].push(step);
      } else {
        stepsBySubcontractor[subContractorId] = [step];
      }
    });

    return stepsBySubcontractor;
  }
}
