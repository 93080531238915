import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectService } from '../../../services/project/project.service';

import { IActivity } from '../../../models/activity/activity.interface';
import { IProjectEquipment } from '../../../models/project/project-equipment/project-equipment.interface';
import { IProjectMaterial } from '../../../models/project/project-material/project-material.interface';
import { IProjectStep } from '../../../models/project/project-step/project-step.interface';

@Component({
  selector: 'app-edit-step-modal',
  templateUrl: './edit-step-modal.component.html',
  styleUrls: ['./edit-step-modal.component.scss']
})
export class EditStepModalComponent implements OnInit, OnDestroy {
  @ViewChild('stepForm') stepForm;

  // Template variables
  isValid: boolean = false;
  materialIds: string[] = [];
  equipmentIds: string[] = [];

  private formData: IProjectStep;

  @Input() formInput: FormGroup;
  @Input() stepActivity: IActivity;

  @Output() submitOutput: EventEmitter<IProjectStep> = new EventEmitter();

  constructor(
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService
  ) {/*EMPTY*/}

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    this.materialIds = this.formInput.get('materialIds').value;
    this.equipmentIds = this.formInput.get('equipmentIds').value;
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  formDataChange(output) {
    this.isValid = output.valid;
    this.formData = output.data;
    this.formData.materialIds = this.materialIds;
    this.formData.equipmentIds = this.equipmentIds;
  }

  equipmentMatUpdate(output: {materialIds: string[], equipmentIds: string[]}) {
    this.materialIds = output.materialIds;
    this.equipmentIds = output.equipmentIds;
    this.formData.materialIds = this.materialIds;
    this.formData.equipmentIds = this.equipmentIds;
  }

  equipmentUpdate(equipmentIds: string[]) {
    this.equipmentIds = equipmentIds;
    this.formData.equipmentIds = this.equipmentIds;
  }

  submit(createAnother) {
    this.formData.createAnother = createAnother;
    this.submitOutput.emit(this.formData);
    if (createAnother) this.stepForm.resetForm();
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.isValid) {
        this.submit(false);
      }
    }
  }

}
