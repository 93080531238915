import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { IActivityFormOutput, ICosts, IMasterScheduleActivity, IPrereqActivity, ICostsFlag } from '../../../models/activity/activity.interface';
import { element } from '@angular/core/src/render3/instructions';


@Component({
  selector: 'app-bulkEdit-activity-modal',
  templateUrl: './bulkEdit-activity-modal.component.html',
  styleUrls: ['./bulkEdit-activity-modal.component.scss']
})
export class BulkEditActivityModalComponent implements OnInit, OnDestroy {

  // Template variables
  isValid: boolean = true;
  prereqData: IPrereqActivity[] = [];
  curIds: string[];
  curNames: string[];
  costs: ICosts;
  costFlag: ICostsFlag;
  matselectedFlag: boolean = false;
  equipSelectedFlag: boolean = false;

  materialIds: string[] = [];
  equipmentIds: string[] = [];
  laborIds: string[] = [];

  private formDataArray: IActivityFormOutput[];


  @Input() formInput: FormGroup;
  @Input() allActivities: IMasterScheduleActivity[];
  @Input() symbol: string;

  @Output() submitOutput: EventEmitter<IActivityFormOutput[]> = new EventEmitter();
  @Output() deleteOutput: EventEmitter<string[]> = new EventEmitter();

  constructor(
  ) {/*EMPTY*/ }

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    this.curIds = this.formInput.get('ids').value;
    this.curNames = this.formInput.get('names').value;
    this.setCostsData();
    this.setCostsflag();
    this.setEquipmentMaterialData();
    this.setLaborData();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }


  setCostsData() {
    this.costs = {
      equipmentCost: this.formInput.get('equipmentCost').value,
      laborCost: this.formInput.get('laborCost').value,
      materialCost: this.formInput.get('materialCost').value
    };
  }

  setCostsflag() {
    this.costFlag = {
      equipmentCost: false,
      laborCost: false,
      materialCost: false
    };
  }


  setEquipmentMaterialData() {
    this.materialIds = this.formInput.get('materialIds').value;
    this.equipmentIds = this.formInput.get('equipmentIds').value;
  }

  setLaborData() {
    this.laborIds = this.formInput.get('laborIds').value;
  }

  // Called on init of activities form
  formDataChange(output) {

    this.formDataArray = output.data;

    this.formDataArray.forEach(element => {

      let oldEquipmentCost;
      let oldLaborCost;
      let oldMaterialCost;
      let oldEquipmentIds;
      let oldMaterialIds;
      let oldLaborIds;

      this.allActivities.forEach(activtyItem => {
        if (activtyItem.activity.id === element.activity.id) {
          oldEquipmentCost = activtyItem.activity.equipmentCost;
          oldLaborCost = activtyItem.activity.laborCost;
          oldMaterialCost = activtyItem.activity.materialCost;
          oldMaterialIds = activtyItem.activity.materialIds;
          oldEquipmentIds = activtyItem.activity.equipmentIds;
          oldLaborIds = activtyItem.activity.laborIds;
          return;
        }
      });


      if (this.costFlag.equipmentCost) {
        element.activity.equipmentCost = this.costs.equipmentCost;
      } else {
        element.activity.equipmentCost = oldEquipmentCost;
      }

      if (this.costFlag.laborCost) {
        element.activity.laborCost = this.costs.laborCost;
      } else {
        element.activity.laborCost = oldLaborCost;
      }

      if (this.costFlag.materialCost) {
        element.activity.materialCost = this.costs.materialCost;
      } else {
        element.activity.materialCost = oldMaterialCost;
      }

      if (this.matselectedFlag) {
        element.activity.materialIds = this.materialIds;
      }
      else {
        element.activity.materialIds = oldMaterialIds;
      }

      if (this.equipSelectedFlag) {
        element.activity.equipmentIds = this.materialIds;
      }
      else {
        element.activity.equipmentIds = oldEquipmentIds;
      }

      element.activity.laborIds = this.laborIds;
    })

  }

  costUpdate(costs: ICosts) {
    this.costs = costs;
    this.formDataArray.forEach(element => {
      if (this.costFlag.equipmentCost)
        element.activity.equipmentCost = this.costs.equipmentCost;

      if (this.costFlag.laborCost)
        element.activity.laborCost = this.costs.laborCost;

      if (this.costFlag.materialCost)
        element.activity.materialCost = this.costs.materialCost;
    });
  }

  costflagUpdate(costflag: ICostsFlag) {
    this.costFlag = costflag;
  }


  submit() {
    this.submitOutput.emit(this.formDataArray);
  }

  delete() {
    this.deleteOutput.emit([this.formInput.get('id').value]);
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.isValid) {
        this.submit();
      }
    }
  }

  updateEquipmentFlag(flag: boolean) {
    this.equipSelectedFlag = flag;
  }

  updateMaterialFlag(flag: boolean) {
    this.matselectedFlag = flag;
  }
  equipmentMatUpdate(output: { materialIds: string[], equipmentIds: string[] }) {
    this.materialIds = output.materialIds;
    this.equipmentIds = output.equipmentIds;

    this.formDataArray.forEach(element => {
      element.activity.materialIds = this.materialIds;
      element.activity.equipmentIds = this.equipmentIds;
    });
  }

  materialUpdate(output: {materialIds: string[]}) {
    this.materialIds = output.materialIds;
    this.formDataArray.forEach(element => {
      element.activity.materialIds = this.materialIds;
    });
  }

  equipmentUpdate(output: {equipmentIds: string[]}) {
    this.equipmentIds = output.equipmentIds;
    this.formDataArray.forEach(element => {
      element.activity.equipmentIds = this.equipmentIds;
    });
  }

  laborUpdate(output: {laborIds: string[]}) {
    this.laborIds = output.laborIds;
    this.formDataArray.forEach(element => {
      element.activity.laborIds = this.laborIds;
    });
  }

}
