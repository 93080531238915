import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IProjectRfi } from '../../../models/project/project-rfi/project-rfi.interface';
import { ProjectRfiService } from '../../../services/project/project-rfi/project-rfi.service';
import { ProjectService } from '../../../services/project/project.service';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-activity-rfi-form',
  templateUrl: './project-activity-rfi-form.component.html',
  styleUrls: ['./project-activity-rfi-form.component.scss']
})

export class ProjectActivityRfiFormComponent implements OnInit {

  @Input() rfiIds: string[] = [];

  @Output() updateOutput: EventEmitter<{rfiIds: string[]}> = new EventEmitter();

  allRfis: IProjectRfi[] = [];
  filteredRfis: Observable<IProjectRfi[]>;
  rfiOptions: IProjectRfi[];
  rfiCtrl = new FormControl();
  rfiChips: IProjectRfi[];

  get displayFn() { return Utils.defaultDisplayFn; }

  constructor(
    private rfiService: ProjectRfiService,

  ) {/*EMPTY*/}

  ngOnInit() {
    this.allRfis = this.rfiService.getLocalAllProjectRfis();
    this.setValidOptions();
    this.setAutocomplete();
    this.rfiChips = this.allRfis.filter(item => this.rfiIds.includes(item.id));
  }

  removeRfi(rfi: IProjectRfi) {
    this.rfiIds = this.rfiIds.filter(id => id !== rfi.id);
    this.rfiChips = this.rfiChips.filter(item => item.id !== rfi.id);
    this.updateOutput.emit({rfiIds: this.rfiIds});
    this.clearInput();
  }

  rfiSelected(matRfi) {
    const rfi: IProjectRfi = matRfi.option.value;
    this.rfiIds.push(rfi.id);
    this.rfiChips.push(this.rfiOptions.find(item => item.id === rfi.id));
    this.updateOutput.emit({rfiIds: this.rfiIds});
    this.clearInput();
  }

  setValidOptions(): void {
    this.rfiOptions = this.allRfis.filter(item => !this.rfiIds.includes(item.id));
  }

  setAutocomplete(): void {
    this.filteredRfis = this.rfiCtrl.valueChanges.pipe(
      startWith<string | IProjectRfi >(''),
      map(value => typeof value === 'string' ? value : value.subject),
      map(subject => subject ? this.rfiSearch(name) : this.getAllRfiOptions()));
  }

  rfiSearch(subject: string) {
    const searchQuery = subject.toLowerCase();
    const searchRes = this.rfiOptions.filter(option => option.subject.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllRfiOptions() {
    return this.rfiOptions.slice(0, Math.min(this.rfiOptions.length, 50));
  }

  clearInput(): void {
    this.rfiCtrl.setValue('');
    this.rfiCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }
}
