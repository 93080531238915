export enum ForgeViewerType {
    Select = 'select',
    Prereq = 'prerequisite',
    Successor = 'successor',
    Self = 'self',
    Hover = 'hover',
    Sprint = 'sprint',
    Committed = 'committed',
    Default = 'default',
    Added = 'added',
    Removed = 'removed',
    Blocker = 'blocker',
    Transparent = 'transparent'
}

export enum ForgeViewerTool {
  SelectionWindow = 1
}
