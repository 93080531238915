import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { IExceptionHours } from '../../../models/project/project-workdays/project-workdays.interface';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-exceptions-table',
  templateUrl: './exceptions-table.component.html',
  styleUrls: ['./exceptions-table.component.scss']
})
export class ExceptionsTableComponent implements OnInit {

  @Input() exceptionsInput: IExceptionHours[] = [];
  @Input() saveButton: boolean = true;

  @Output() eUpdateOutput: EventEmitter<any[]> = new EventEmitter();

  exceptions: Array<{id: string, date: Date, closed: boolean, startTime: number, endTime: number}> = [];
  hoursDropDownList: IDropdownItem[] = Utils.hourDropdownItems();

  constructor() {/*EMPTY*/}

  ngOnInit() {
    if (this.saveButton) this.formatInput(this.exceptionsInput);
  }

  formatInput(input: IExceptionHours[]) {
    this.exceptions = [];
    input.forEach(eIn => {
      this.exceptions.push({
        id: Utils.TempId(),
        date: Utils.fromUtcDate(eIn.date),
        closed: eIn.times.length === 0,
        startTime: eIn.times.length > 0 ? eIn.times[0] : 0,
        endTime: eIn.times.length > 1 ? eIn.times[1] : 0
      });
    });
  }

  saveClick() {
    this.eUpdateOutput.emit(this.formatOutput());
  }

  restoreClick() {
    this.formatInput(this.exceptionsInput);
  }

  formatOutput() {
    const returnOutput = [];
    this.exceptions.forEach(eOut => {
      if (!(eOut.date instanceof Date)) return;
      returnOutput.push({
        date: Utils.toUtcDate(eOut.date),
        times: eOut.closed ? [] : [eOut.startTime, eOut.endTime]
      });
    });
    return returnOutput;
  }

  deleteRow(rowId: string) {
    this.exceptions.splice(this.exceptions.findIndex(e => e.id === rowId), 1);
  }

  addException() {
    this.exceptions.unshift({date: new Date(), closed: true, startTime: 0, endTime: 0, id: Utils.TempId()});
  }

}
