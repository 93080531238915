import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectReportsService } from '../../../services/project/project-reports/project-reports.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectService } from '../../../services/project/project.service';
import { Utils } from '../../../utils/utils';

import { IGritTable } from '../../../shared/grit-table/grit-table';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { IMakeReadyReportData } from '../../../models/reports/project-report.interface';

@Component({
  selector: 'app-project-make-ready-report',
  templateUrl: './project-make-ready-report.component.html',
  styleUrls: ['./project-make-ready-report.component.scss']
})
export class ProjectMakeReadyReportComponent implements OnInit, OnDestroy {

  datesForm: FormGroup;
  invalidDatesMessage: string = `Start date must be before end date.`;
  isLoading: boolean = false;
  errorGettingData: boolean = false;
  tableData: IGritTable;
  csvUrl: any;
  csvFileName: string;

  private rawData: IMakeReadyReportData[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectEquipmentService: ProjectEquipmentService,
    private projectMaterialService: ProjectMaterialService,
    private projectReportsService: ProjectReportsService,
    private notificationService: NotificationService,
    private projectScheduleService: ProjectScheduleService,
    public projectService: ProjectService
  ) { }

  get selectedStartDate() { return this.datesForm.get('selectedStartDate'); }
  get selectedEndDate() { return this.datesForm.get('selectedEndDate'); }

  async ngOnInit() {
    await this.projectEquipmentService.setLocalAllProjectEquipment(this.projectService.currentProject.id);
    await this.projectMaterialService.setLocalAllProjectMaterials(this.projectService.currentProject.id);
    this.setupForm();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  setupForm(): void {
    this.datesForm = this.projectReportsService.getMaterialEquipmentDatesForm();
  }

  getReport(): void {
    this.isLoading = true;
    const fromDate = this.selectedStartDate.value ? Utils.toUtcDate(this.selectedStartDate.value) : null;
    const toDate = this.selectedEndDate.value ? Utils.toUtcDate(this.selectedEndDate.value) : null;
    this.projectScheduleService.getActiveScheduleMakeReadyTasks(this.projectService.currentProject.id, fromDate, toDate)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.rawData = this.projectReportsService.flattenMakeReadyData(res);
          this.tableData = this.projectReportsService.transformToTableData(this.rawData);
          this.setupExport(this.rawData);

          this.errorGettingData = false;
          this.isLoading = false;
        },
        err => {
          const context: INoficationContext = {
            type: 'get make ready tasks',
            action: 'get'
          };
          this.notificationService.error(err, context);
          this.errorGettingData = true;
          this.isLoading = false;
        }
      );
  }

  setupExport(data: IMakeReadyReportData[] = []): void {
    if (data.length === 0) return;
    const csvHeaders = {
      type: 'Type',
      activity: 'Activity',
      task: 'Task',
      scheduledStart: 'Scheduled Start Date',
      scheduledEnd: 'Scheduled End Date',
      duration: 'Duration',
      crewSize: 'Crew Size',
      subcontractor: 'Subcontractor',
      resource: 'Resource',
      quantity: 'Quantity',
      cost: 'Cost'
    };

    const csvData = Utils.generateCsv(csvHeaders, data);
    this.csvUrl = csvData.url;
    this.csvFileName = csvData.fileName;
  }
}
