import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { map ,  startWith } from 'rxjs/operators';

import { IActivity } from '../../../models/activity/activity.interface';
import { IMatAutoComplete } from '../../../models/material/material-components.interface';

import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISubContractor } from '../../../models/subcontractor/subcontractor.interface';
import { IUserPermission } from '../../../models/user/user.interface';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-user-form',
  templateUrl: './project-user-form.component.html',
  styleUrls: ['./project-user-form.component.scss']
})
export class ProjectUserFormComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() subAutoInput: IMatAutoComplete;
  @Input() userAutoInput: IMatAutoComplete;
  @Input() userPermission: IUserPermission;

  @Output() submitOutput: EventEmitter<any> = new EventEmitter();

  subReadOnly = false;
  userSubContractor: string = '';

  // returns specific form control by label
  get permission() { return this.formInput.get('permission'); }
  get subContractor() { return this.formInput.get('subContractor'); }
  get email() { return this.formInput.get('email'); }
  get subcontractorControl() { return this.subAutoInput.formControl; }
  get subcontractorOptions() { return this.subAutoInput.listOptions; }
  get subcontractorDisplayFn() { return this.subAutoInput.displayFn; }
  get userControl() { return this.userAutoInput.formControl; }
  get userOptions() { return this.userAutoInput.listOptions; }
  get userDisplayFn() { return this.userAutoInput.displayFn; }

  ngOnInit() {
    if (!this.userPermission.gc) {
      this.subAutoInput.listOptions.subscribe(subs => {
        this.subAutoInput.formControl.setValue(subs.find(s => s.id === this.userPermission.subContractorId));
        this.subReadOnly = true;
      });
    }

    this.formInput.addControl('subContractor', this.subAutoInput.formControl);
    this.formInput.addControl('email', this.userAutoInput.formControl);

  }

  onSubmit(form): void {

    const formValues = form.getRawValue();

    const user = {
      subContractorId: formValues.subContractor.id === '-1' ? null : formValues.subContractor.id,
      email: formValues.email.name ? formValues.email.email : formValues.email,
      permission: formValues.permission
    };

    this.submitOutput.emit(user);
  }
}
