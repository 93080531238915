import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IMasterScheduleActivity, IPrereqActivity } from '../../../models/activity/activity.interface';

import { ActivityService } from '../../../services/activity/activity.service';
import { SegmentService } from '../../../services/segment/segment.service';

@Component({
  selector: 'app-prerequisites-table',
  templateUrl: './prerequisites-table.component.html',
  styleUrls: ['./prerequisites-table.component.scss']
})
export class PrerequisitesTableComponent implements OnInit {

  @Input() curId: string;
  @Input() parentId: string;
  @Input() prereqs: IPrereqActivity[];
  @Input() allActivities: IMasterScheduleActivity[];
  @Input() static: boolean;

  @Output() updateOutput: EventEmitter<IPrereqActivity[]> = new EventEmitter();

  prereqData = [];
  filteredPrereqs: Observable<IMasterScheduleActivity[]>;
  activityOptions: IMasterScheduleActivity[];
  prereqCtrl = new FormControl();
  curPrereqIds: string[] = [];

  relSelectOptions = [
    {
      value: 'SS',
      display: 'Start To Start'
    },
    {
      value: 'SF',
      display: 'Start To Finish'
    },
    {
      value: 'FF',
      display: 'Finish To Finish'
    },
    {
      value: 'FS',
      display: 'Finish To Start'
    }
  ];

  leadLagSelectOptions = [
    {
      value: 'Lead',
      display: 'Lead'
    },
    {
      value: 'Lag',
      display: 'Lag'
    }
  ];

  constructor(
    private activityService: ActivityService
  ) {/*EMPTY*/}

  ngOnInit() {
    this.prereqData = this.parsePrereqData(this.prereqs);
    this.setPrereqs();
    this.setAutocomplete();
  }

  setPrereqs(): void {
    this.curPrereqIds = this.prereqs.map(p => p.activity);
    this.setValidOptions();
  }

  setValidOptions(): void {
    const curId = this.curId;
    let idsToRemove: string[] = [];
    if (curId) idsToRemove.push(curId);

    // Remove ancestors of activity being editted
    let parentIds: string[] = [];
    const parentId: string = this.parentId;
    if (parentId) parentIds = this.getParentIds(parentId, [parentId]);

    idsToRemove = idsToRemove.concat(parentIds);

    let curAct: IMasterScheduleActivity;
    // Remove invalid related to currentPrereqs
    this.curPrereqIds.forEach(id => {
      // Remove self
      idsToRemove.push(id);

      // Remove ancestors of selected prereqs
      curAct = this.allActivities.find(item => item.activity.id === id);
      if (curAct && curAct.activity.parentId) parentIds = this.getParentIds(curAct.activity.parentId, [curAct.activity.parentId]);
      idsToRemove = idsToRemove.concat(parentIds);

      // Remove children of selected prereqs
      const childIds = this.getChildrenIds(id, []);
      idsToRemove = idsToRemove.concat(childIds);
    });

    this.activityOptions = this.allActivities.filter(item => !idsToRemove.includes(item.activity.id));
  }

  getChildrenIds(id, list) {
    const children = this.allActivities.filter(item => item.activity.parentId === id);
    children.forEach(child => {
      list.push(child.activity.id);
      this.getChildrenIds(child.activity.id, list);
    });
    return list;
  }

  getParentIds(id, list): string[] {
    const parent = this.allActivities.find(item => item.activity.id === id);
    if (parent && parent.activity.parentId) {
      list.push(parent.activity.parentId);
      this.getParentIds(parent.activity.parentId, list);
    }
    return list;
  }

  selected(matPrerq) {
    SegmentService.track(`Activity Prereq Table: Prereq added`, {});
    const mActivity: IMasterScheduleActivity = matPrerq.option.value;
    this.curPrereqIds.push(mActivity.activity.id);
    this.prereqData.push({
      name: mActivity.activity.name,
      relType: 'FS',
      offset: 0,
      leadLag: 'Lead',
      id: mActivity.activity.id
    });
    this.updateOutput.emit(this.reformatPrereqDataForReturn());
    this.clearInput();
  }

  clearInput(): void {
    this.prereqCtrl.setValue('');
    this.prereqCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }

  prereqSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.activityOptions.filter(option => option.activity.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllPrereqOptions() {
    return this.activityOptions.slice(0, Math.min(this.activityOptions.length, 50));
  }

  setAutocomplete(): void {
    this.filteredPrereqs = this.prereqCtrl.valueChanges.pipe(
      startWith<string | IMasterScheduleActivity >(''),
      map(value => typeof value === 'string' ? value : value.activity.name),
      map(name => name ? this.prereqSearch(name) : this.getAllPrereqOptions()));
  }

  displayFn(item: any): string { return item && item.activity && item.activity.name ? item.activity.name : undefined; }

  parsePrereqData(input: IPrereqActivity[]) {
    const returnData = [];
    input.forEach(i => {
      const name = this.activityService.getLocalActivity(i.activity).name;
      const token = i.timing.split(' ')[0];
      const relType = token.substring(0, 2);
      const offset = parseFloat(token.substring(2, token.length));
      const leadLag = token[2] === '+' ? 'Lag' : 'Lead';
      returnData.push({
        name: name,
        relType: relType,
        offset: Math.abs(offset),
        leadLag: leadLag,
        id: i.activity
      });
    });
    return returnData;
  }

  dataChange() {
    this.updateOutput.emit(this.reformatPrereqDataForReturn());
  }

  deleteRow(key: string) {
    SegmentService.track(`Activity Prereq Table: Prereq deleted`, {});
    this.prereqData.splice(this.prereqData.findIndex(item => item.id === key), 1);
    this.curPrereqIds.splice(this.curPrereqIds.indexOf(key), 1);
    this.clearInput();
    this.updateOutput.emit(this.reformatPrereqDataForReturn());
  }

  reformatPrereqDataForReturn() {
    const prereqOut: IPrereqActivity[] = [];
    this.prereqData.forEach(pre => {
      prereqOut.push({activity: pre.id, timing: this.reformatTiming(pre)});
    });
    return prereqOut;
  }

  reformatTiming(prereq) {
    let returnStr = prereq.relType;
    returnStr += prereq.leadLag === 'Lag' ? '+' : '-';
    returnStr += prereq.offset + ' days';
    return returnStr;
  }

}
