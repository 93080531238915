import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectPageCriteriaService } from '../../services/project/project-page-criteria/project-page-criteria.service';
import { ProjectPlannerPopoutModalService } from '../../services/project/project-planner-popout-modal/project-planner-popout-modal.service';
import { ProjectPlannerService } from '../../services/project/project-planner/project-planner.service';
import { ProjectStepService } from '../../services/project/project-step/project-step.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectUserService } from '../../services/project/project-user/project-user.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';

import { IActivity } from '../../models/activity/activity.interface';
import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { ContextMenuEvent } from '../../utils/enums/context-menu-event';
import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { IForgeContextMenuOutput } from '../../models/forge/forge.interface';
import { MessageType } from '../../utils/enums/message-type.enum'; // USED IN HTML
import { IProjectStep } from '../../models/project/project-step/project-step.interface';
import { IPageSpecificFilter, ISidebarTab } from '../../models/sidebar/sidebar.interface';
import { IUserPermission } from '../../models/user/user.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-planner',
  templateUrl: './project-planner.component.html',
  styleUrls: ['./project-planner.component.scss']
})
export class ProjectPlannerComponent implements OnInit, OnDestroy {

  // Template Variables
  pageIsLoading: boolean = true;
  errorGettingData: boolean = false;
  errorMessage: string = '';
  showRetryButton: boolean = false;
  sidebarTabIconData: ISidebarTab[];
  sidebarState: string;
  filtersApplied: boolean = false;
  messageType = MessageType;
  addObjectsText: string = '';
  addingToKit: boolean = false;
  selectingPrereqs: boolean = false;
  selectedActivity: IActivity;

  // Component Inputs
  selectedStepIds: string[] = [];
  activeFilters: string[] = [];
  activeFilterType: FilterModelOptions;
  filterTypesToHide: string[] = [];
  selectedGritObjectIds: string[] = [];
  pageSpecificFilters: IPageSpecificFilter[] = [
    { label: 'hide_planned_work', checked: false, disabled: false, value: FilterModelOptions.Unplanned },
    { label: 'show_my_work', checked: false, disabled: false, value: FilterModelOptions.MyWork }
  ];

  // modal
  showModal: boolean = false;
  formInput: FormGroup;
  showConfirmationModal: boolean = false;
  confirmationModalInput: IConfirmationModalInput = {
    displayMessage: 'continue_without_saving',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: false
  };

  private projectId: string;
  private userPermission: IUserPermission;
  private firstLoad: boolean = true;

  // Subscription
  private unSavedChangesEmitter: Subject<boolean> = new Subject();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // Planner form
  @ViewChild('plannerForm') plannerForm: any;
  plannerInput = {};

  allActivities = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private projectPlannerService: ProjectPlannerService,
    private projectObjectService: ProjectObjectService,
    private projectUserService: ProjectUserService,
    private forgeViewerService: ForgeViewerService,
    private segmentService: SegmentService,
    public projectPlannerPopoutModalService: ProjectPlannerPopoutModalService,
    private projectSubContractorService: ProjectSubContractorService,
    private projectStepService: ProjectStepService,
    private userService: UserService,
    public projectPageCriteriaService: ProjectPageCriteriaService,
    public projectService: ProjectService
  ) { }

  async ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    this.userPermission = ProjectService.userPermission;
    this.plannerInput['projectId'] = this.projectService.currentProject.id;
    this.activeFilterType = FilterModelOptions.Activities;

    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(myParams => {
      this.plannerInput['loadedStepId'] = myParams['stepId'];
      this.plannerInput['loadedActivityId'] = myParams['activityId'];
      if (this.plannerInput['loadedActivityId']) this.pageSpecificFilters[0].checked = true;
    });

    this.forgeViewerService.popoutKeyDownEventOutput.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      this.plannerForm.popoutKeyDownEvent(event);
    });

    this.forgeViewerService.popoutKeyUpEventOutput.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      this.plannerForm.popoutKeyUpEvent(event);
    });

    // Viewer show similar output
    this.forgeViewerService.showSimilarOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      const idForCat = gritSelection[0];
      if (!Utils.isEmpty(idForCat)) {
        this.filterByLastCat(idForCat);
      }
    });

    // Viewer clear filters output
    this.forgeViewerService.clearFiltersOutput.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.filterModel([]);
    });

    // Context menu output <-will replace both above eventually
    this.forgeViewerService.contextMenuOutput.pipe(takeUntil(this.destroyed$)).subscribe(menuOutput => {
      this.contextMenuHandler(menuOutput);
    });

    this.forgeViewerService.viewerCutOutput.pipe(takeUntil(this.destroyed$)).subscribe(viewerCutOutput => {
      this.loadAllSetup();
    });

    if (this.projectService.getProjectReady()) {
      this.firstLoad = false;

      this.loadAllSetup();
    } else {
      // Viewer setup
      this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
        if (this.forgeViewerService.poppedOut || !this.firstLoad) {
          // Viewer was just popped out and ready to be set to correct state
          this.plannerForm.callPopoutSetup();
        } else {
          this.firstLoad = false;

          this.loadAllSetup();
        }
      });
    }

    // listens for viewer output
    this.forgeViewerService.forgeViewerOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      this.handleForgeViewerOutput(gritSelection);
    });

    this.userService.getAuthenticatedUser().pipe(takeUntil(this.destroyed$)).subscribe(
      user => {
        let userName = user.email;
        if (user.firstName && user.firstName.length > 0) userName = user.firstName;
        if (user.lastName && user.lastName.length > 0) {
          if (!user.firstName || (user.firstName && user.firstName.length < 1)) userName = user.lastName;
          else userName = userName + ' ' + user.lastName;
        }
        this.projectPlannerService.setAuthUser(user, userName);
      });

    this.segmentService.track('Project Planner Loaded', { projectId: this.projectId });
  }

  async loadAllSetup() {
    this.forgeViewerService.clearAllThemingColors();
    this.forgeViewerService.clearCutPlanes();
    this.plannerInput['objects'] = {};
    this.plannerInput['objects'] = this.projectObjectService.getLocalNonHiddenObjects();
    this.projectService.getLocalAllActivities().map(tc => {
      this.allActivities[tc.id] = tc;
      this.allActivities[tc.id]['displayName'] = tc.name;
      if (this.allActivities[tc.id].parentId) {
        this.allActivities[tc.id]['displayName'] = this.allActivities[this.allActivities[tc.id].parentId].name + ' > ' + this.allActivities[tc.id]['displayName'];
        if (this.allActivities[this.allActivities[tc.id].parentId].parentId) {
          this.allActivities[tc.id]['displayName'] = this.allActivities[this.allActivities[this.allActivities[tc.id].parentId].parentId].name + ' > ' + this.allActivities[tc.id]['displayName'];
        }
      }
    });
    this.plannerInput['allActivities'] = this.projectService.getLocalAllActivities().map(tc => tc.id);
    this.plannerInput['activeFilterType'] = FilterModelOptions.Activities;

    if (this.errorGettingData) { return this.handleError('error_planning_permission', true); }
    await this.setupPlanningPermissions();
    await this.setupExceptionActivities();
    if (this.errorGettingData) { return this.handleError('error_planning_permission', true); }
    if (Utils.isEmptyList(this.plannerInput['activityAuthority'])) { this.errorGettingData = true; return this.handleError('You have nothing to plan'); }
    await this.setupAuthenticatedObjects();
    if (this.errorGettingData) { return this.handleError('error_planning_permission', true); }
    await this.projectObjectService.setUnmodeledObjects(this.projectId);
    if (this.errorGettingData) { return this.handleError('error_planning_permission', true); }

    await this.projectService.setEquipmentAndMaterials();

    if (this.plannerForm) this.plannerForm.loadAllSetup(this.plannerInput);

    this.setupSideBar();
    this.pageIsLoading = false;
  }

  handleError(message: string, showRetry: boolean = false): void {
    this.pageIsLoading = false;
    this.projectService.setHideViewer(true);
    this.errorMessage = message ? message : 'error_getting_data';
    this.showRetryButton = showRetry;
  }

  contextMenuHandler(data: IForgeContextMenuOutput) {
    switch (data.event) {
      case ContextMenuEvent.ADD_UNMODELED:
        this.plannerForm.addUnmodeledEvent();
        break;
      case ContextMenuEvent.ADD_ACTIVITY_PREREQ:
        this.plannerForm.addActivityPrereqEvent();
        break;
    }
  }

  setupPlanningPermissions(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userPermission.gc) {
        this.plannerInput['activityAuthority'] = this.projectService.getLocalAllActivities().map(tc => tc.id);
        return resolve();
      } else {
        this.projectUserService.getAuthenticatedActivityList(this.projectId, UserService.userId).pipe(takeUntil(this.destroyed$)).subscribe(
          res => {
            if (!res) {
              this.plannerInput['activityAuthority'] = [];
            } else {
              this.plannerInput['activityAuthority'] = res['activities'];
            }
            return resolve();
          },
          err => {
            this.errorGettingData = true;
            this.notificationService.error(err, { type: 'activity type', action: 'get' });
            return resolve();
          }
        );
      }
    });
  }

  setupAuthenticatedObjects(): Promise<void> {
    return new Promise((resolve) => {
      this.plannerInput['authenticatedObjects'] = {};
      this.projectObjectService.getActivityObjects(this.projectId, this.plannerInput['activityAuthority']).pipe(takeUntil(this.destroyed$)).subscribe(
        res3 => {
          this.plannerInput['authenticatedObjects'] = res3;
          return resolve();
        },
        err3 => {
          this.errorGettingData = true;
          // Checking this because this has to do with planning permissions, not activity stuff as in the translation service
          if (err3 === 'INVALID_ACTIVITY') {
            this.notificationService.error('cannot_retrieve_planning_permissions', {});
          } else {
            this.notificationService.error(err3, { type: 'object', action: 'get' });
          }
          return resolve();
        }
      );
    });
  }

  async setupExceptionActivities() {
    this.plannerInput['exceptionActivities'] = [];
    if (this.userPermission.gc) return;
    const subContractors = await this.projectSubContractorService.getAssociatedSubContractors(this.projectId).toPromise();
    for (const x of subContractors) {
      if (!x.activities.includes(this.plannerInput['activityAuthority'][0])) {
        this.plannerInput['exceptionActivities'] = this.plannerInput['exceptionActivities'].concat(x.activities);
      }
    }
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  notifyStepIdsOutput(ids: string[]): void {
    this.selectedStepIds = ids;
  }

  handleForgeViewerOutput(forgeViewerOutputData) {
    const skipUnsavedChangesModal = this.plannerForm.selectingPrereqs || this.plannerForm.addingToKit;
    if (skipUnsavedChangesModal) {
      this.plannerForm.handleForgeViewerOutput(forgeViewerOutputData);
    } else {
      this.plannerForm.handleForgeViewerOutput(forgeViewerOutputData);
    }
  }

  disablePageFilters(event: boolean): void {
    this.pageSpecificFilters.forEach(f => f.disabled = event);
  }

  // Sidebar
  setupSideBar(): void {
    const showObjectSidebar = JSON.parse(localStorage.getItem('showSidebar'));
    this.sidebarTabIconData = this.projectPlannerService.getSidebarTabData();
    this.projectService.showProjectSidebar = showObjectSidebar;
    this.forgeViewerService.resizeViewerAfterLoad();
  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.sidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab: ISidebarTab): void {
    this.segmentService.track('Planner Sidebar Loaded', { sideBar: tab.key });
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;
    this.sidebarState = tab.key;
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) {
      this.segmentService.track('Planner Sidebar Loaded', { sideBar: 'properties' });
      this.projectService.toggleSidebarVisibility(isVisible);
    } else {
      this.projectService.toggleSidebarVisibility();
    }
    // SetTimeout is used here so the viewer is resized after the CSS animation
    // to resize to correct width
    setTimeout(() => { this.forgeViewerService.resizeViewerAfterLoad(); }, 500);
  }

  setSelectedGritObjIds(event: string[]): void {
    this.selectedGritObjectIds = event;
  }
  // END Sidbar

  navigateToFileManager(): void {
    const currentProject = this.projectService.currentProject;
    this.router.navigateByUrl('/project/' + this.projectId + '/settings?menuItem=3');
  }

  syncFilters(output: {filters: string[], type: FilterModelOptions}) {
    this.activeFilters = output.filters;
    this.activeFilterType = output.type;
    this.projectPlannerPopoutModalService.activeFilter.next(this.activeFilters);
  }

  filterModel(filters: string[]): void {
    this.activeFilters = filters;
    this.filtersApplied = (this.activeFilters.length <= 0 && this.pageSpecificFilters.filter(psf => psf.checked)).length <= 0 ? false : true;
    this.projectPlannerPopoutModalService.activeFilter.next(this.activeFilters);

    this.plannerForm.filterModel(this.activeFilters);
  }

  switchFilterType(type: FilterModelOptions): void {
    this.activeFilterType = type;
    this.activeFilters = [];
    this.projectPlannerPopoutModalService.activeFilter.next(this.activeFilters);

    this.plannerForm.switchFilterType(this.activeFilterType);
  }

  handleHomeworkActivitySelection(activityIds: string[]): void {
    if (this.activeFilterType !== FilterModelOptions.Activities) {
      this.setSelectedActivity(activityIds[0]);
      this.switchFilterType(FilterModelOptions.Activities);
      this.filterModel(activityIds);
    } else {
      this.setSelectedActivity(activityIds[0]);
      this.filterModel(activityIds);
    }
  }

  pageFilterOutput(filter: IPageSpecificFilter): void {
    this.filtersApplied = (this.activeFilters.length <= 0 && this.pageSpecificFilters.filter(psf => psf.checked)).length <= 0 ? false : true;
    this.plannerForm.pageFilterOutput(filter);
  }

  navigateToModelsPage(): void {
    this.router.navigateByUrl('/project/' + this.projectId + '/models');
  }

  pageRefresh(): void {
    window.location.reload(true);
  }

  async filterByLastCat(objectId: string) {
    const objCatNames = this.projectObjectService.getLocalObject(objectId).categories;
    if (Utils.isEmptyList(objCatNames)) return;
    const lastCatName = objCatNames[objCatNames.length - 1].toString();
    const allCatsMap = this.projectService.getLocalCategories();
    const catIds = [];
    for (const key in allCatsMap) {
      if (key) {
        if (allCatsMap[key].name === lastCatName) {
          catIds.push(key);
        }
      }
    }
    if (catIds.length > 0) {
      if (this.activeFilterType === FilterModelOptions.Category) {
        this.activeFilters = catIds;
        this.filterModel(this.activeFilters);
      } else {
        await this.switchFilterType(FilterModelOptions.Category);
        this.activeFilters = catIds;
        this.filterModel(this.activeFilters);
      }
      this.projectPlannerPopoutModalService.activeFilter.next(this.activeFilters);
    }
  }

  showStepForm(stepInput: IProjectStep = null) {
    if (this.selectedActivity && this.selectedActivity.crewSize) {
      const presetValues = { crewSize: this.selectedActivity.crewSize };
      this.formInput = this.projectStepService.buildFormInput(stepInput, presetValues);
    } else {
      this.formInput = this.projectStepService.buildFormInput(stepInput);
    }

    this.showModal = true;
    this.plannerForm.showParentModal = true;
  }

  async formOutput(step: IProjectStep): Promise<void> {
    step.subContractor = this.projectSubContractorService.getProjectSubcontractorByActivityId(this.selectedActivity.id); // TODO - find better place for this?
    this.plannerForm.formOutput(step);

    if (step.createAnother) {
      this.showStepForm(null);
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    this.showModal = false;
    if (this.plannerForm) {
      this.plannerForm.showParentModal = false;
      this.plannerForm.setInAddFlow(false);
    }
  }

  startAddingObjectsOutput(selectingPrereqs: boolean): void {
    if (selectingPrereqs === null) {
      this.addObjectsOutput();
    } else {
      if (selectingPrereqs) {
        this.addObjectsText = 'editing_work_package_prereq_objects';
        this.selectingPrereqs = true;
      } else {
        this.addObjectsText = 'editing_work_package_objects';
        this.addingToKit = true;
      }
    }
  }

  addObjectsOutput(): void {
    if (!this.plannerForm.showModal) {
      this.addingToKit = false;
      this.selectingPrereqs = false;
      this.plannerForm.doneAddingObjects();
    }
  }

  async cancelAddObjectsOutput(): Promise<void> {
    if (!this.plannerForm.showModal) {
      this.addingToKit = false;
      this.selectingPrereqs = false;
      await this.plannerForm.resetView();
      this.plannerForm.setViewerSelections();
    }
  }

  handleCloseConfirmationModal(): void {
    this.continueWithoutSaving(false);
  }

  handleConfirmConfirmationModal(): void {
    this.continueWithoutSaving(true);
  }

  setSelectedActivity(activityId: string): void {
    this.selectedActivity = this.projectService.getLocalActivity(activityId);
  }

  continueWithoutSaving(continueWithoutSaving: boolean): void {
    this.unSavedChangesEmitter.next(continueWithoutSaving);
  }
}
