import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { MenuService } from '../../services/menu/menu.service';
import { ProjectHeaderService } from '../../services/project/project-header/project-header.service';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-page-secondary-header',
  templateUrl: './page-secondary-header.component.html',
  styleUrls: ['../page-header/page-header.component.scss']
})
export class PageSecondaryHeaderComponent implements OnInit, OnDestroy {

  constructor(
    public projectService: ProjectService,
    public menuService: MenuService,
    public _projectHeaderService: ProjectHeaderService
  ) { }

  async ngOnInit() {
    this._projectHeaderService.setSecondaryHeaderState(true);
  }

  ngOnDestroy() {
    this._projectHeaderService.setSecondaryHeaderState(false);
  }

  showSubMenu() {
    this.projectService.isSubMenuOpen = !this.projectService.isSubMenuOpen;
  }
}
