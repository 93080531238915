import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lower-confirmation',
  templateUrl: './lower-confirmation.component.html',
  styleUrls: ['./lower-confirmation.component.scss']
})
export class LowerConfirmationComponent implements OnInit, OnDestroy {

  constructor() {/*EMPTY*/}

  @Input() titleText: string;
  @Input() confirmBtnText: string;
  @Input() noCancelBtn: boolean = false;

  @Output() confirmOutput: EventEmitter<void> = new EventEmitter();
  @Output() cancelOutput: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    this.titleText = this.titleText ? this.titleText : 'confirm_to_finish';
    this.confirmBtnText = this.confirmBtnText ? this.confirmBtnText : 'confirm';

    window.addEventListener('keydown', this.keyPress);
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.keyPress);
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.confirmOutput.emit();
    }
  }

}
