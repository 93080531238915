import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { EditType, IColHeader, IGritTable, IGRowItem, RowItemType } from '../../../../shared/grit-table/grit-table';
import { IProjectEquipment } from '../../../../models/project/project-equipment/project-equipment.interface';
import { IStoryEquipment } from '../../../../models/project/project-sprint/project-sprint.interface';

import { ActivityService } from '../../../../services/activity/activity.service';
import { ProjectEquipmentService } from '../../../../services/project/project-equipment/project-equipment.service';
import { ProjectService } from '../../../../services/project/project.service';

import { Utils } from '../../../../utils/utils';
import { ProjectSprintBoardService } from '../../../../services/project/project-sprint-board/project-sprint-board.service';

@Component({
  selector: 'app-sprint-result-equipment',
  templateUrl: './sprint-result-equipment.component.html',
  styleUrls: ['./sprint-result-equipment.component.scss']
})
export class SprintResultEquipmentComponent implements OnInit {

  @Input() activityEquipmentIds: string[] = [];
  @Input() taskId: string;
  @Output() updateOutput: EventEmitter<{valid: boolean, data: IStoryEquipment[]}> = new EventEmitter();

  @ViewChild('table') table;

  tableData: IGritTable;

  storyEquipment: IStoryEquipment[] = [];
  allEquipment: IProjectEquipment[] = [];
  filteredEquipment: Observable<IProjectEquipment[]>;
  equipmentOptions: IProjectEquipment[];
  equipmentCtrl = new FormControl();
  displayFn = Utils.defaultDisplayFn;

  constructor(
    private activityServices: ActivityService,
    private equipmentService: ProjectEquipmentService,
    private projectService: ProjectService,
    private projectSprintBoardService: ProjectSprintBoardService
  ) {/*EMPTY*/}

  async ngOnInit() {
    if (ProjectService.userPermission.subContractorId) {
      await this.equipmentService.setLocalUserProjectEquipment(this.projectService.currentProject.id, ProjectService.userPermission.subContractorId);
      this.allEquipment = this.equipmentService.getLocalUserProjectEquipment();
    } else {
      await this.equipmentService.setLocalAllProjectEquipment(this.projectService.currentProject.id);
      this.allEquipment = this.equipmentService.getLocalAllProjectEquipment();
    }
    this.setStoryEquipment();
    // this.transformTableData(this.storyEquipment);
    this.setValidOptions();
    this.setAutocomplete();
  }

  async setStoryEquipment() {
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(this.taskId);
    const activityId = boardItem.activities[0];
    const data = await this.activityServices.getActivityEquipments(activityId).subscribe(res => {
      res.forEach((equipment, index) => {
        let selectedValue = 0;
        if (equipment.elementUnit.toString() === 'area') {
          selectedValue = equipment.areaSum ? equipment.areaSum : 0;
        } else if (equipment.elementUnit.toString() === 'volume') {
          selectedValue = equipment.volumeSum ? equipment.volumeSum : 0;
        } else if (equipment.elementUnit.toString() === 'length') {
          selectedValue = equipment.lengthSum ? equipment.lengthSum : 0;
        } else if (equipment.elementUnit.toString() === 'unit') {
          selectedValue = 1;
        }
        const storyEquip: IStoryEquipment = {
          equipmentId: equipment.id,
          name: equipment.name,
          calculatedHours: null,
          plannedQuantity:  equipment.isMannuallyAddedPlannedQty ? equipment.matPlannedQuantity : selectedValue * equipment.resourceRate,
          hours: boardItem.equipment && boardItem.equipment[index] && boardItem.equipment[index].hours ? boardItem.equipment[index].hours : null
        };
        this.storyEquipment.push(storyEquip);
      });
      this.transformTableData(this.storyEquipment);
    }, err => {
      console.error(err);
    });

    this.transformTableData(this.storyEquipment);
  }

  updateTableValid(event) {
    this.updateOutput.emit({valid: event, data: this.formatEquipmentOutput()});
  }

  formatEquipmentOutput(): IStoryEquipment[] {
    const returnArr: IStoryEquipment[] = [];
    this.tableData.rows.forEach(row => {
      returnArr.push({
        equipmentId: row.key,
        hours: row.rowItems.find(ri => ri.colKey === 'hours').value
      });
    });
    return returnArr;
  }

  deleteRows(ids: string[]) {
    ids.forEach(id => {
      this.storyEquipment.splice(this.storyEquipment.findIndex(e => e.equipmentId === id), 1);
    });
    this.clearInput();
  }

  setValidOptions(): void {
    this.equipmentOptions = this.allEquipment.filter(item => !this.storyEquipment.find(e => e.equipmentId === item.id));
  }

  setAutocomplete(): void {
    this.filteredEquipment = this.equipmentCtrl.valueChanges.pipe(
      startWith<string | IProjectEquipment >(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.equipmentSearch(name) : this.getAllEquipmentOptions()));
  }

  equipmentSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.equipmentOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllEquipmentOptions() {
    return this.equipmentOptions.slice(0, Math.min(this.equipmentOptions.length, 50));
  }

  equipSelected(matEquipment) {
    const equipment: IProjectEquipment = matEquipment.option.value;
    const storyMat: IStoryEquipment = {
      equipmentId: equipment.id,
      name: equipment.name,
      calculatedHours: null,
      hours: null,
      plannedQuantity: equipment.plannedQuantity
    };
    this.storyEquipment.push(storyMat);
    this.addToTableData(storyMat);
    this.clearInput();
    this.table.setupTable();
  }

  clearInput(): void {
    this.equipmentCtrl.setValue('');
    this.equipmentCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }

  transformTableData(dataInput: IStoryEquipment[]) {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text,
        width: '40%'
      },
      {
        displayName: 'Planned',
        colKey: 'plannedQuantity',
        type: RowItemType.Number,
        width: '40%'
      },
      {
        displayName: 'actual',
        colKey: 'hours',
        type: RowItemType.Number,
        width: '20%'
      }
    ];

    this.tableData = {
      colHeaders: colHeaders,
      rows: [],
      openEditable: true,
      showIntro: false,
      selectOptions: {
        showCheckboxes: false
      },
      paginationOptions: {
        showTop: false,
        showBottom: false
      }
    };
    dataInput.forEach(equipment => {
      this.addToTableData(equipment);
    });
  }

  addToTableData(storyEquipment: IStoryEquipment) {
    const rowItems: IGRowItem[] = [
      {
        colKey: 'name',
        value: storyEquipment.name,
        editable: false
      },
      {
        colKey: 'plannedQuantity',
        value: storyEquipment.plannedQuantity ? storyEquipment.plannedQuantity.toFixed(2) : 0,
        editable: false
      },
      {
        colKey: 'hours',
        value: storyEquipment.hours,
        editable: true,
        required: true,
        suffix: 'hrs'
      }
    ];
    this.tableData.rows.push({
      key: storyEquipment.equipmentId,
      rowItems: rowItems,
      editOptions: {
        openEdit: true,
        deletePermission: true,
        editRowValidationFn: this.rowValidationFn,
        rowEdits:   [
          {
            type: EditType.Delete,
            disabled: false
          }
        ]
      },
      selectable: false
    });
  }

  rowValidationFn(rowItems: IGRowItem[]): boolean {
    const actual = rowItems.find(ri => ri.colKey === 'hours');
    if (!actual) return false;
    if (!actual.value) return false;
    if (typeof actual.value !== 'number') return false;
    return true;
  }

}
