import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { ProjectService } from '../project.service';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectObjectService {
  private unmodeledObjects = {};

  constructor(
    private httpService: HttpBackendService,
    private projectService: ProjectService
  ) { }

  public getObject(projectObjectId: string) {
    return this.httpService.get('/project/object/' + projectObjectId);
  }

  public getLocalObject(projectObjectId: string) {
    return this.projectService.getObject(projectObjectId);
  }

  public getAllObjectsMap() {
    return this.projectService.getAllObjectsMap();
  }

  public toggleConflicts(show) {
    this.projectService.toggleConflicts(show);
  }

  public getLocalNonHiddenObjects() {
    return this.projectService.getLocalNonHiddenObjects();
  }

  public getGritIdByProjectModelForgeObject(projectModelId: string, forgeObjectId: number): string {
    return this.projectService.getGritIdByProjectModelForgeObject(projectModelId, forgeObjectId);
  }

  public getModelObjects(projectModelId: string) {
    return this.projectService.getModelObjects(projectModelId);
  }

  public getAllObjectIds() {
    return this.projectService.getAllObjectIds();
  }

  public getAllHiddenObjects() {
    return this.projectService.getHiddenObjects();
  }

  public async getAllCustomObjects(projectId: string) {
    return await this.projectService.getCustomObjects(projectId);
  }

  public async getAllHiddenCustomObjects(projectId: string) {
    return await this.projectService.getHiddenCustomObjects(projectId);
  }

  public getLocalUnmodeledObject(objectId: string) {
    return this.unmodeledObjects[objectId];
  }

  public getAllLocalUnmodeledObjects() {
    return this.unmodeledObjects;
  }

  public getUnPlannedModelObjects(projectId: string, activities: string[], activeFilters: string[], type: FilterModelOptions): Observable<any> {
    return new Observable((observer) => {
      this.httpService.post('/project/' + projectId + '/unPlannedModelObjects', {'activities': activities, 'activeFilters': activeFilters, type: type}).subscribe(
        results => {
          const returnObject = {};
          results.map(result => returnObject[result] = result);
          observer.next(returnObject);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getActivityObjects(projectId: string, activities: string[]): Observable<any> {
    return new Observable((observer) => {
      this.httpService.post('/project/' + projectId + '/activityObjects', {'activities': activities}).subscribe(
        results => {
          const returnObject = {};
          results.map(result => returnObject[result] = this.projectService.getObject(result));
          observer.next(returnObject);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getObserverForObjects(activities: string[]): Observable<any> {

    let a = [activities[0]];
    activities = a;

    return new Observable((observer) => {
      const returnObject = {};
      activities.forEach(element => returnObject[element] = this.projectService.getObject(element));
      observer.next(returnObject);
      observer.complete();
    });
  }

  public getModelActivityObjects(projectModelId: string, activities: string[]): Observable<any> {
    return new Observable((observer) => {
      this.httpService.post('/project/model/' + projectModelId + '/activityObjects', {'activities': activities}).subscribe(results => {
        const returnObject = {};
        results.map(result => returnObject[result] = this.projectService.getObject(result));
        observer.next(returnObject);
      });
    });
  }

  public getModelCategoryObjects(projectModelId: string, categories: string[]): Observable<any> {
    return new Observable((observer) => {
      let noActivitiesOnly = false;
      let catQueryString = '';
      categories.forEach(cat => {
        if (cat === '') {
          noActivitiesOnly = true;
        } else {
          catQueryString += 'category=' + cat + '&';
        }
      });
      this.httpService.get('/project/model/' + projectModelId + '/objects?' + catQueryString + 'noActivities=' + noActivitiesOnly).subscribe(results => {
        const returnObject = {};
        results.map(result => returnObject[result.id] = this.projectService.getObject(result.id));
        observer.next(returnObject);
      });
    });
  }

  public getCategoryObjects(projectId: string, categories: string[]): Observable<any> {
    return new Observable((observer) => {
      this.httpService.post('/project/' + projectId + '/categoryObjects', {'categories': categories}).subscribe(
        results => {
          const returnObject = {};
          results.map(result => returnObject[result] = this.projectService.getObject(result));
          observer.next(returnObject);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getUnmodeledObjectList(projectId: string) {
    return this.httpService.get('/project/' + projectId + '/objects/unmodeled');
  }

  public upsertUnmodeledObjects(projectId: string, objects: any) {
    const json = { projectObjects: objects };
    return this.httpService.post('/project/unmodeled/' + projectId + '/objects', json);
  }

  public setUnmodeledObjects(projectId: string): Promise<void> {
    return new Promise((resolve) => {
      this.unmodeledObjects = {};
      this.getUnmodeledObjectList(projectId).toPromise().then(uObjects => {
        uObjects.forEach(uObj => {
          this.unmodeledObjects[uObj.id] = uObj;
        });
        return resolve();
      });
    });
  }

  // Object updates
  public hide(projectModelId: string, objectIds: string[]): Observable<any> {
    return new Observable(observer => {
      this.httpService.put('/project/model/' + projectModelId + '/objects/hide', objectIds).subscribe(result => {
        for (const objectId of objectIds) {
          this.projectService.deleteModelObject(projectModelId, objectId);
          this.projectService.addHiddenObject(objectId);
        }
        return observer.next(result);
      });
    });
  }

  public setActivities(projectModelId: string, projectObjectIds: string[], activityIds: string[]): Observable<any> {
    return new Observable(observer => {
      this.httpService.post('/project/model/' + projectModelId + '/objects/activity', { 'projectObjectIds': projectObjectIds, 'activityIds': activityIds }).subscribe(result => {
        if (activityIds.includes('')) activityIds = Utils.removeArrayItems(activityIds, ['']);
        for (const projectObjectId of projectObjectIds) {
          this.projectService.getObject(projectObjectId).activities = activityIds;
        }
        return observer.next(result);
      });
    });
  }

  public addActivities(projectId: string, projectObjectIds: string[], activityIds: string[]): Observable<any> {
    return new Observable(observer => {
      this.httpService.post('/project/' + projectId + '/objects/addActivities', { 'projectObjectIds': projectObjectIds, 'activityIds': activityIds }).subscribe(
        result => {
          for (const projectObjectId of projectObjectIds) {
            this.projectService.getObject(projectObjectId).activities = Utils.deDupeArray(this.projectService.getObject(projectObjectId).activities.concat(activityIds));
          }
          return observer.next(result);
        },
        err => {
          return observer.error(err);
        }
      );
    });
  }

  public getAffectedSteps(projectId: string, projectObjectIds: string[], activityIds: string[]) {
    return this.httpService.post('/project/' + projectId + '/model/affectedSteps', { 'projectObjectIds': projectObjectIds, 'activityIds': activityIds });
  }

  public calculateUnitOfMeasures(objectIds: string[]) {
    const returnObj = {
      each: 0,
      length: 0,
      surfaceArea: 0,
      volume: 0
    };
    let object;
    if (objectIds) {
      objectIds.forEach(id => {
        object = this.getLocalObject(id);
        if (object) {
          returnObj.each++;
          if (object['length']) returnObj.length += object['length'];
          if (object['area']) returnObj.surfaceArea += object['area'];
          if (object['volume']) returnObj.volume += object['volume'];
        }
      });
    }
    return returnObj;
  }

  // END Object updates
}
