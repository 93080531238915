import { Injectable } from '@angular/core';

import { ProjectService } from '../../../services/project/project.service';

import { MakeReadyBoardData, MakeReadyBoardItem } from '../../../models/project/project-make-ready/project-make-ready.interface';
import { IProjectMaterial, UnitOfMeasure } from '../../../models/project/project-material/project-material.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { MakeReadyStatus } from '../../../utils/enums/make-ready-status.enum';
import { IProjectEquipment } from '../../../models/project/project-equipment/project-equipment.interface';
import { SidebarState } from '../../../utils/enums/sidebar-state';
import { ISidebarTab } from '../../../models/sidebar/sidebar.interface';
import { Utils } from '../../../utils/utils';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProjectMakeReadyService {

  private activeFilters: string[] = [];
  private activeFilterType: FilterModelOptions;
  private hiddenFilters: FilterModelOptions[] = [FilterModelOptions.Category];

  constructor(
    private projectService: ProjectService
  ) {}

  transformMakeReadyBoardData(scheduleData: any): MakeReadyBoardData {
    const boardData: MakeReadyBoardData = {
      scheduled: {
        active: false,
        key: 'scheduled',
        items: [],
        currentPage: 1
      },
      approved: {
        active: false,
        key: 'approved',
        items: [],
        currentPage: 1
      },
      ordered: {
        active: false,
        key: 'ordered',
        items: [],
        currentPage: 1
      },
      delivered: {
        active: false,
        key: 'delivered',
        items: [],
        currentPage: 1
      },
      ready: {
        active: false,
        key: 'ready',
        items: [],
        currentPage: 1
      }
    };

    const equipmentDueDates = {};

    scheduleData.forEach(step => {
      step.materials.forEach(material => {
        const dueDate = this.getMaterialDueDate(material, step.scheduledStart);
        const status = material.makeReadyStatus ? material.makeReadyStatus : MakeReadyStatus.scheduled;
        const mat = boardData[MakeReadyStatus[status]] ? boardData[MakeReadyStatus[status]].items.find(item => item.id === material.id) : null;
        if (mat) mat.steps.push(step);
        else boardData[MakeReadyStatus[status]].items.push({
            id: material.makeReadyStatus === MakeReadyStatus.scheduled ? material.id : step.stepId,
            material: material,
            dueDate: dueDate,
            displayDate: Utils.formatDate(dueDate),
            tMinusDisplay: this.getTMinusHours(dueDate),
            pastDue: this.getPastDue(dueDate),
            quantity: this.getQuantity(material, step),
            type: 'material',
            steps: [step],
            active: false,
            expanded: false,
            readOnly: material.makeReadyStatus === MakeReadyStatus.scheduled ? material.procoreId ? true : false : false,
            hidden: false
          });
      });
      step.equipments.forEach(equipment => {
        if (equipmentDueDates[equipment.id]) equipmentDueDates[equipment.id] = Math.min(step.scheduledStart, equipmentDueDates[equipment.id]);
        else equipmentDueDates[equipment.id] = step.scheduledStart;
        const status = equipment.makeReadyStatus ? equipment.makeReadyStatus : MakeReadyStatus.scheduled;
        const equip = boardData[MakeReadyStatus[status]] ? boardData[MakeReadyStatus[status]].items.find(item => item.id === equipment.id) : null;
        if (equip) equip.steps.push(step);
        else boardData[MakeReadyStatus[status]].items.push({
            id: equipment.makeReadyStatus === MakeReadyStatus.scheduled ? equipment.id : step.stepId,
            equipment: equipment,
            type: 'equipment',
            steps: [step],
            active: false,
            expanded: false,
            readOnly: false,
            hidden: false
          });
      });
    });

    this.getEquipmentDueDates(boardData, equipmentDueDates);

    return boardData;
  }

  getQuantity(mat, step) {
    if (!this.projectService.getHasModelsStatus()) return '';
    switch (mat.unitOfMeasure) {
      case UnitOfMeasure.Each: return step.objectCount;
      case UnitOfMeasure.Volume: return Utils.formatUnitOfMeasure(step.objectVolume, mat.unitOfMeasure, this.projectService.currentProject.isMetric);
      case UnitOfMeasure.SurfaceArea: return Utils.formatUnitOfMeasure(step.objectArea, mat.unitOfMeasure, this.projectService.currentProject.isMetric);
      case UnitOfMeasure.Length: return Utils.formatUnitOfMeasure(step.objectLength, mat.unitOfMeasure, this.projectService.currentProject.isMetric);
    }
    return '';
  }

  getTMinusHours(dueDate: number): string {
    const today = Utils.getCurrentDate();
    return dueDate <= today
      ? `T+` + moment(today).diff(moment(dueDate), 'days')
      : `T-` + moment(dueDate).diff(moment(today), 'days');
  }

  getPastDue(dueDate: number): boolean {
    const today = Utils.getCurrentDate();
    return dueDate <= today;
  }

  getMaterialDueDate(material: IProjectMaterial, scheduledStart: number): number {
    switch (material.makeReadyStatus) {
      case MakeReadyStatus.scheduled:
      case MakeReadyStatus.approved:
        return material.approvalDate;
      case MakeReadyStatus.ordered:
        return scheduledStart - (material.prepareLeadDays * 24 * 60 * 60 * 1000);
      case MakeReadyStatus.delivered:
        return scheduledStart;
      case MakeReadyStatus.ready:
        return scheduledStart;
    }
  }

  getEquipmentDueDate(equipment: IProjectEquipment): number {
    return equipment.startDate;
  }

  getEquipmentDueDates(boardData: MakeReadyBoardData, equipmentDueDates: any) {
    Object.keys(boardData).forEach(lane => {
      boardData[lane].items.filter(item => item.equipment).forEach(item => {
        const dueDate = lane === 'scheduled' ? equipmentDueDates[item.equipment.id] : item.steps[0].scheduledStart;
        item.dueDate = dueDate;
        item.displayDate = Utils.formatDate(dueDate);
        item.tMinusDisplay = this.getTMinusHours(dueDate);
        item.pastDue = this.getPastDue(dueDate);
      });
    });
  }

  getSidebarTabs(): ISidebarTab[] {
    let sidebarTabs = [
      {
        name: 'filter',
        icon: 'fa-filter',
        key: SidebarState.MODEL_FILTER,
        active: true
      },
      {
        name: 'task_information',
        icon: 'fa-info',
        key: SidebarState.TASK_INFORMATION,
        active: false
      },
      {
        name: 'thread',
        icon: 'fa-comments',
        key: SidebarState.TASK_MESSAGES,
        active: false
      },
      {
        name: 'procurement_menu_item',
        icon: 'fa-dolly',
        key: SidebarState.PROCUREMENT,
        active: false
      }
    ];

    if (!this.projectService.hasModels) sidebarTabs = sidebarTabs.filter(item => (item.key !== SidebarState.MODEL_FILTER));
    return sidebarTabs;
  }

  setActiveFilterType(type: FilterModelOptions): void {
    this.activeFilterType = type;
  }
  getActiveFilterType(): FilterModelOptions {
    return this.activeFilterType;
  }
  setActiveFilters(filters: string[]): void {
    this.activeFilters = filters;
  }
  getActiveFilters(): string[] {
    return this.activeFilters;
  }

  getHiddenFilters(): FilterModelOptions[] {
    return this.hiddenFilters;
  }
}
