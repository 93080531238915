import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';

import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { EditType, IAddRowItem, IColHeader, IFilter, IGritTable, IGRow, IGRowItem, IRowEditOption, RowItemType, IGRowItemDependents } from './grit-table';
import { ProjectService } from '../../services/project/project.service';
import { Subscription } from 'rxjs';
import { Utils } from '../../utils/utils';
import { ProjectEquipmentService } from '../../services/project/project-equipment/project-equipment.service';

@Component({
  selector: 'app-grit-table',
  templateUrl: './grit-table.component.html',
  styleUrls: ['./grit-table.component.scss']
})
export class GritTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() tableInput: IGritTable;

  @Output() rowKeysSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() rowKeyHovered: EventEmitter<string> = new EventEmitter();
  @Output() curTableRowKeys: EventEmitter<string[]> = new EventEmitter();
  @Output() deleteRowKeys: EventEmitter<string[]> = new EventEmitter();
  @Output() editRowKey: EventEmitter<string> = new EventEmitter();
  @Output() inlineEditRowOutput: EventEmitter<IGRow> = new EventEmitter();
  @Output() addRowOutput: EventEmitter<IGRowItem[]> = new EventEmitter();
  @Output() customEdit: EventEmitter<{key: string, edit: IRowEditOption}> = new EventEmitter();
  @Output() openTableValid: EventEmitter<boolean> = new EventEmitter();

  // TEMPLATE VARS
  objectKeys = Object.keys;
  rowItemType = RowItemType;
  editType = EditType;
  pageSize: number = 20;
  pageStart: number;
  pageEnd: number;
  curTableRows: IGRow[] = [];
  columnKey = {};
  curQuery: string = '';
  selectedKeys = {};
  tableEditCol: boolean = false;
  openEditable: boolean = false;
  showConfirmationModal: boolean = false;
  confirmationModalInput: IConfirmationModalInput;
  addingInlineRow: boolean = false;
  addModel: IAddRowItem[];
  editingInlineRow: boolean = false;
  currentEditCopy: IGRow;
  showCheckBoxes: boolean = true;
  curNumSelectableRows: number = 0;
  addButton: boolean = false;
  addButtonLabel: string = 'add';
  addButtonIcon: string = '';
  enterToAdd: boolean = false;
  deleteButton: boolean = false;
  deleteButtonLabel: string = 'delete';
  deleteButtonIcon: string = '';
  canDelete: boolean = true;
  hasDeleteable: boolean = false;
  hasPaginationArea: boolean = true;
  hasIntroArea: boolean = true;
  currency;
  private externalSourceFilterEvent: Subscription;
  dependents: IGRowItemDependents[] = [];

  constructor(private projectService: ProjectService,
    private equipService : ProjectEquipmentService) {/*EMPTY*/}

  ngOnInit() {
    this.externalSourceFilterEvent = this.projectService.currentSearchQueryOfGritTable
      .subscribe(searchObj => {
        const {searchQuery , searchFilters} = searchObj;
        if (!this.tableInput) return;
        this.setColumnKeys();
        if (Utils.isEmpty(searchQuery)) {
          this.clearSearchAndFilters()
          return
        }
        this.filterSearch(searchQuery, true, searchFilters);
      });

    if (this.projectService.currentProject) this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    else this.currency = this.projectService.getCurrencyCodes('USD');
    this.setupTable();
    window.addEventListener('keydown', this.keyPress);
  }

  ngOnChanges(event) {
    if (event.tableInput.firstChange === true) return;
    this.setupTable();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    this.externalSourceFilterEvent.unsubscribe();
  }

  setupTable() {
    if (!this.tableInput) return;
    this.curTableRows = this.tableInput.rows;
    this.setColumnKeys();
    this.setTableEditable();
    this.setVariousOptions();
    this.setPagination();
    this.filterSearch(this.curQuery, true);
    this.updateTableValid();
    this.updateDependents();
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.editingInlineRow) {
        const row = this.curTableRows.find(r => r.key === this.currentEditCopy.key);
        if (row) this.saveInlineRowEdit(row);
      } else if (this.addingInlineRow) {
        this.addInlineRow();
      } else if (this.enterToAdd) {
        this.addRow();
      }
    } else if (event.key === 'Escape') {
      if (this.editingInlineRow) {
        const row = this.curTableRows.find(r => r.key === this.currentEditCopy.key);
        if (row) this.cancelInlineRowEdit(row);
      } else if (this.addingInlineRow) {
        this.addingInlineRow = false;
      }
    }
  }

  updateDependents(){ 
    if(!this.curTableRows.length) return;
    this.dependents = this.curTableRows[0].dependents;
  }
  
  setTableEditable() {
    this.selectedKeys = {};
    for (const r of this.tableInput.rows) {
      if (r.selected === true) this.selectedKeys[r.key] = r;
      if (r.rowItems.filter(ri => ri.editable === true).length > 0) {
        r.editable = true;
      } else {
        if (r.editable !== true) {
          r.editable = false;
          return;
        }
      }
      if (r.editOptions && r.editOptions.rowEdits && r.editOptions.rowEdits.length > 0) {
        this.tableEditCol = true;
        if (r.editOptions.deletePermission === true) {
          this.hasDeleteable = true;
        }
      }
      if (r.editOptions && r.editOptions.openEdit === true) {
        this.openEditable = true;
        this.tableEditCol = true;
        r.editing = true;
        r.rowItems.forEach(ri => { if (ri.editable === true) ri.editing = true; });
      }
      if (r.editOptions && !r.editOptions.editRowValidationFn) r.editOptions.editRowValidationFn = this.defaultValidationFn;
    }
    if (this.tableInput.addOptions && this.tableInput.addOptions.inline === true) {
      this.tableEditCol = true;
      if (this.tableInput.rows.length === 0) this.deleteButton = true;
    }
    this.addingInlineRow = false;
    this.editingInlineRow = false;
  }

  // tslint:disable-next-line:cyclomatic-complexity
  setVariousOptions() {
    if (this.tableInput.openEditable === true) this.openEditable = true;
    if (this.tableInput.selectOptions && this.tableInput.selectOptions.multiSelect === false) this.showCheckBoxes = false;
    if (this.tableInput.selectOptions && this.tableInput.selectOptions.showCheckboxes === false) this.showCheckBoxes = false;
    if (this.openEditable) {
      this.showCheckBoxes = false;
      this.tableEditCol = true;
    }
    this.curNumSelectableRows = this.curTableRows.filter(r => r.selectable !== false).length;
    if (this.hasDeleteable) this.deleteButton = true;
    if (this.tableInput.deleteOptions && this.tableInput.deleteOptions.show === false) this.deleteButton = false;
    if (this.tableInput.deleteOptions && this.tableInput.deleteOptions.displayName) this.deleteButtonLabel = this.tableInput.deleteOptions.displayName;
    if (this.tableInput.deleteOptions && this.tableInput.deleteOptions.icon) {
      this.deleteButtonIcon = this.tableInput.deleteOptions.icon;
    } else {
      this.deleteButtonIcon = 'fas fa-trash';
    }
    this.addButton = this.tableInput.addOptions && this.tableInput.addOptions.addPermission !== false ? true : false;
    if (this.tableInput.addOptions && this.tableInput.addOptions.displayName) this.addButtonLabel = this.tableInput.addOptions.displayName;
    if (this.tableInput.addOptions && this.tableInput.addOptions.icon) {
      this.addButtonIcon = this.tableInput.addOptions.icon;
    } else {
      this.addButtonIcon = 'fas fa-plus';
    }
    if (this.tableInput.addOptions && this.tableInput.addOptions.enterToAdd === true) this.enterToAdd = true;
    if (this.tableInput.searchOptions && this.tableInput.searchOptions.query) this.curQuery = this.tableInput.searchOptions.query;
    this.hasIntroArea = this.tableInput.showIntro === false ? false : true;
    this.hasPaginationArea =
      !this.tableInput.selectOptions || (this.tableInput.selectOptions && this.tableInput.selectOptions.showSelected !== false) ||
      !this.tableInput.paginationOptions || (this.tableInput.paginationOptions && this.tableInput.paginationOptions.showTop !== false && this.tableInput.paginationOptions.showBottom !== false);
  }

  setPagination() {
    if (this.tableInput.paginationOptions && this.tableInput.paginationOptions.pageSize) this.pageSize = this.tableInput.paginationOptions.pageSize;
    if (this.tableInput.paginationOptions && (this.tableInput.paginationOptions.showTop === false && this.tableInput.paginationOptions.showBottom === false)) this.pageSize = 100;
    if (this.openEditable) this.pageSize = 100;
    this.pageStart = 1;
    this.pageEnd = Math.min((this.pageStart - 1) + this.pageSize, this.curTableRows.length);
  }

  updatePagination() {
    this.pageEnd = Math.min((this.pageStart - 1) + this.pageSize, this.curTableRows.length);
  }

  updateSelected() {
    const newSet = {};
    this.tableInput.rows.forEach(r => r.selected = false);
    this.curNumSelectableRows = 0;
    this.canDelete = true;
    this.curTableRows.forEach(r => {
      if (this.selectedKeys[r.key]) {
        r.selected = true;
        newSet[r.key] = r;
        if (r.editOptions && r.editOptions.deletePermission === false) this.canDelete = false;
      }
      if (r.selectable) this.curNumSelectableRows++;
    });
    this.selectedKeys = newSet;
  }

  updateCanDelete() {
    this.canDelete = true;
    for (const key in this.selectedKeys) {
      if (this.selectedKeys[key].editOptions && this.selectedKeys[key].editOptions.deletePermission === false) {
        this.canDelete = false;
        break;
      }
    }
  }

  setColumnKeys() {
    this.columnKey = {};
    this.tableInput.colHeaders.forEach(head => {
      if (!head.sort) {
        head.sort = {
          sortable: true,
          selected: false,
          sortAsc: true
        };
      } else {
        head.sort.selected = false;
      }
      this.columnKey[head.colKey] = head;
    });
  }

  filterSearch(query: string, enterToSearch: boolean, searchFilters?: Array<string>) {
    if (!enterToSearch || this.addingInlineRow || this.editingInlineRow) return;
    this.curTableRows = this.tableInput.rows;
    this.filter();
    this.search(query, searchFilters);
    this.pageStart = 1;
    this.updatePagination();
    this.updateSelected();
    this.curTableRowKeys.emit(this.curTableRows.map(r => r.key));
    const selectedHeader = this.tableInput.colHeaders.find(h => h.sort.selected === true);
    if (selectedHeader) this.sort(selectedHeader, true);
  }

  clearSearchAndFilters() {
    this.curQuery = '';
    if (this.tableInput.filterOptions) {
      this.tableInput.filterOptions.filtered = false;
      this.tableInput.filterOptions.filters.forEach(f => f.selected = false);
    }
    this.filterSearch(this.curQuery, true);
  }

  search(query: string, searchFilters? : Array<any>) {
    if (query.length === 0) return;
    const colKeys = this.tableInput.searchOptions && this.tableInput.searchOptions.colKeys && this.tableInput.searchOptions.colKeys.length > 0
      ? this.tableInput.searchOptions.colKeys
      : Object.keys(this.columnKey);
    let valuesToCheck: string[];
    let rawVals;
    query = query.toLowerCase();
    for (let x = this.curTableRows.length - 1; x > -1; x--) {
      if (searchFilters && searchFilters.length > 0)
        rawVals = this.curTableRows[x].rowItems.filter(i => colKeys.includes(i.colKey) && searchFilters.includes(i.colKey)).map(ri => ri.value);
      else
        rawVals = this.curTableRows[x].rowItems.filter(i => colKeys.includes(i.colKey)).map(ri => ri.value);
      valuesToCheck = [];
      rawVals.forEach(rv => { if (rv) valuesToCheck.push(rv.toString().toLowerCase()); });
      for (let s = 0; s < valuesToCheck.length; s++) {
        if (valuesToCheck[s].includes(query)) {
          break;
        } else if (s === valuesToCheck.length - 1) {
          this.curTableRows = this.curTableRows.slice(0, x).concat(this.curTableRows.slice(x + 1));
        }
      }
    }
  }

  setFilter(filter: IFilter) {
    filter.selected = !filter.selected;
    this.filterSearch(this.curQuery, true);
  }

  isFiltered(): boolean {
    return this.tableInput.filterOptions.filters.filter(f => f.selected === true).length > 0;
  }

  filter() {
    if (!this.tableInput.filterOptions) return;
    this.tableInput.filterOptions.filtered = false;
    const selectedFilters = this.tableInput.filterOptions.filters.filter(f => f.selected === true);
    selectedFilters.forEach(f => {
      this.curTableRows = f.filterFn(this.curTableRows);
      this.tableInput.filterOptions.filtered = true;
    });
  }

  sort(col: IColHeader, skipToggle: boolean = false) {
    if (this.addingInlineRow || this.editingInlineRow || col.sort.sortable === false) return;
    if (!skipToggle) {
      if (col.sort.selected) {
        col.sort.sortAsc = !col.sort.sortAsc;
      } else {
        this.tableInput.colHeaders.forEach(h => h.sort.selected = false);
        col.sort.selected = true;
      }
    }
    let aItem, bItem: IGRowItem;
    let aVal, bVal: number;
    // tslint:disable-next-line:cyclomatic-complexity
    this.curTableRows.sort((a, b) => {
      aItem = a.rowItems.find(ri => ri.colKey === col.colKey);
      bItem = b.rowItems.find(ri => ri.colKey === col.colKey);
      if ((!aItem || !aItem.value) && (!bItem || !bItem.value)) return 0;
      if (!aItem || !aItem.value) return col.sort.sortAsc ? 1 : -1;
      if (!bItem || !bItem.value) return col.sort.sortAsc ? -1 : 1;

      switch (this.columnKey[aItem.colKey].type) {
        case RowItemType.Currency:
        case RowItemType.Number:
          aVal = typeof aItem.value !== 'number' ? parseFloat(aItem.value.replace(/,/g, '')) : aItem.value;
          bVal = typeof bItem.value !== 'number' ? parseFloat(bItem.value.replace(/,/g, '')) : bItem.value;
          if (aVal > bVal) return col.sort.sortAsc ? 1 : -1;
          if (aVal < bVal) return col.sort.sortAsc ? -1 : 1;
          break;
        default:
          if ((aItem.sortValue || aItem.value) > (bItem.sortValue || bItem.value)) return col.sort.sortAsc ? 1 : -1;
          if ((aItem.sortValue || aItem.value) < (bItem.sortValue || bItem.value)) return col.sort.sortAsc ? -1 : 1;
          break;
      }

      return 0;
    });
  }

  paginate(forward: boolean) {
    this.pageStart = forward ? this.pageStart + this.pageSize : this.pageStart - this.pageSize;
    this.pageEnd = Math.min((this.pageStart - 1) + this.pageSize, this.curTableRows.length);
  }

  handleRowHover(row: IGRow = null) {
    this.rowKeyHovered.emit(row ? row.key : null);
  }

  handleCheckBoxClick(row: IGRow, e: Event) {
    this.handleRowClick(row);
    e.stopPropagation();
    e.preventDefault();
  }

  handleRowClick(row: IGRow) {
    if (this.editingInlineRow || this.addingInlineRow || row.selectable === false || (row.editOptions && row.editOptions.openEdit === true)) return;
    row.selected = !row.selected;
    if (this.selectedKeys[row.key]) {
      delete this.selectedKeys[row.key];
    } else {
      this.selectedKeys[row.key] = row;
    }
    if (this.tableInput.selectOptions && this.tableInput.selectOptions.multiSelect === false) {
      if (Object.keys(this.selectedKeys).length > 1) {
        this.curTableRows.forEach(r => r.selected = false);
        row.selected = true;
        this.selectedKeys = {};
        this.selectedKeys[row.key] = row;
      }
    }
    this.updateCanDelete();
    this.rowKeysSelected.emit(Object.keys(this.selectedKeys));
  }

  forceUnSelected(e: Event) {
    e.stopPropagation();
  }

  updateTableValid() {
    if (!this.openEditable) return;
    let valid = true;
    for (const r of this.tableInput.rows) {
      if (!r.editOptions.editRowValidationFn(r.rowItems)) {
        valid = false;
        break;
      }
    }
    this.openTableValid.emit(valid);
  }

  rowItemValueChanges(rowItem, i, j){
    this.dependents = this.curTableRows[i].dependents;
    if(this.dependents.length){     
      const dependencies = this.dependents.filter(d=> d.dependentOn.includes(rowItem.colKey));
      dependencies.forEach(d => {
        d.dependentOn.forEach(element => {
            if(rowItem.colKey === element){
              d.staticValues[element] = rowItem.value;
            }
        });
        const index =  this.curTableRows[i].rowItems.findIndex(rr => d.dependentCol === rr.colKey)
        this.curTableRows[i].rowItems[index].value = (this.equipService.getChangingTableValues(d)).toFixed(2);
      });
    }  
  }

  editRow(row: IGRow, edit: IRowEditOption) {
    row.rowItems.map(a => a.editable && (a.value === '-') ? a.value = 0: a.value);
    // event.stopPropagation();
    if (edit.disabled === true) return;
    if (this.openEditable) {
      switch (edit.type) {
        case EditType.Delete:
          const rowIndex = this.tableInput.rows.findIndex(r => r.key === row.key);
          if (rowIndex > -1) {
            this.tableInput.rows.splice(rowIndex, 1);
          }
          this.filterSearch(this.curQuery, true);
          this.updateTableValid();
          this.deleteRowKeys.emit([row.key]);
          break;
      }
    } else {
      this.clearEditingAndSelected();
      this.handleRowClick(row);
      if (!row.selectable) this.selectedKeys[row.key] = row;
      switch (edit.type) {
        case EditType.Delete:
          this.showDeleteConfirmation();
          break;
        case EditType.InlineEdit:
          this.setupInlineEdit(row);
          break;
        case EditType.ModalEdit:
          this.editRowKey.emit(row.key);
          break;
        case EditType.Custom:
          this.customEdit.emit({key: row.key, edit: edit});
          break;
      }
    }
  }

  setupInlineEdit(row: IGRow) {
    this.currentEditCopy = cloneDeep(row);
    row.editing = true;
    this.editingInlineRow = true;
    row.rowItems.forEach(ri => {
      if (ri.editable === true) {
        ri.editing = true;
        if (this.columnKey[ri.colKey].type === RowItemType.Currency) {
          ri.value = parseFloat(ri.value.replace(/,/g, ''));
        }
      }
    });
  }

  addRow() {
    this.clearEditingAndSelected();
    if (this.tableInput.addOptions.inline === true) {
      this.addingInlineRow = true;
      if (!this.tableInput.addOptions.addRowValidationFn) {
        this.tableInput.addOptions.addRowValidationFn = this.defaultValidationFn;
      }
      this.addModel = [];
      let addItem: IAddRowItem;
      this.tableInput.colHeaders.forEach(h => {
        addItem = this.tableInput.addOptions.addModel.find(ari => ari.colKey === h.colKey);
        if (addItem) {
          this.addModel.push(cloneDeep(addItem));
        } else {
          this.addModel.push({
            type: h.type,
            value: null,
            colKey: h.colKey,
            editable: false
          });
        }
      });
    } else {
      this.addRowOutput.emit(null);
    }
  }

  saveInlineRowEdit(row: IGRow, e?: Event) {
    if (!row.editOptions.editRowValidationFn(row.rowItems, this.columnKey)) return;
    this.editingInlineRow = false;
    row.editing = false;
    row.rowItems.forEach(ri => {
      ri.editing = false;
      if (this.columnKey[ri.colKey].type === RowItemType.Currency) {
        ri.value = ri.value.toFixed(this.currency.decimal_digits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    });
    this.currentEditCopy = null;
    this.inlineEditRowOutput.emit(row);
    if (e) e.stopPropagation();
    this.filterSearch(this.curQuery, true);
  }

  cancelInlineRowEdit(row: IGRow, e?: Event) {
    this.editingInlineRow = false;
    row.editing = false;
    row.rowItems = cloneDeep(this.currentEditCopy.rowItems);
    row.rowItems.map(a => a.editable && (a.value === '0' || a.value === 0) ? a.value =  '-' : a.value);
    this.currentEditCopy = null;
    if (e) e.stopPropagation();
  }

  addInlineRow() {
    if (!this.tableInput.addOptions.addRowValidationFn(this.addModel, this.columnKey)) return;
    this.addingInlineRow = false;
    this.addRowOutput.emit(this.addModel);
  }

  defaultValidationFn(rowItems: IGRowItem[], columnKey) {
    for (const ri of rowItems) {
      if (ri.editable !== true || ri.required === false) continue;
      if (!ri.value) return false;
      switch (columnKey[ri.colKey].type) {
        case RowItemType.Date:
          if (!(ri.value instanceof Date)) return false;
          break;
        case RowItemType.Text:
          if (ri.value.length < 1) return false;
          break;
        case RowItemType.Checkbox:
          if (typeof ri.value !== 'boolean') return false;
          break;
      }
    }
    return true;
  }

  clearEditingAndSelected() {
    this.selectedKeys = {};
    this.tableInput.rows.forEach(r => { r.selected = false; r.editing = false; });
  }

  showDeleteConfirmation() {
    this.showConfirmationModal = true;
    this.confirmationModalInput = {
      displayMessage: 'are_you_sure',
      hasCancelAction: true,
      hasConfirmationAction: true
    };
  }

  handleCancelCloseOutput() {
    this.showConfirmationModal = false;
    if (Object.keys(this.selectedKeys).length === 1) {
      const key = Object.keys(this.selectedKeys)[0];
      if (!this.selectedKeys[key].selectable) this.selectedKeys = {};
    }
  }

  handleConfirmDeleteOutput() {
    this.showConfirmationModal = false;
    const keysDeleted = Object.keys(this.selectedKeys);
    this.selectedKeys = {};
    this.deleteRowKeys.emit(keysDeleted);
    let rowIndex: number;
    keysDeleted.forEach(key => {
      rowIndex = this.tableInput.rows.findIndex(r => r.key === key);
      if (rowIndex > -1) {
        this.tableInput.rows.splice(rowIndex, 1);
      }
    });
    this.filterSearch(this.curQuery, true);
  }

  selectAll(e: Event) {
    e.preventDefault();
    if (this.curTableRows.length < 1 || this.editingInlineRow || this.addingInlineRow) return;
    if (Object.keys(this.selectedKeys).length === this.curNumSelectableRows) {
      this.clearEditingAndSelected();
    } else {
      this.canDelete = true;
      this.curTableRows.forEach(row => {
        if (row.selectable) {
          row.selected = true;
          if (!this.selectedKeys[row.key]) this.selectedKeys[row.key] = row;
          if (row.editOptions && row.editOptions.deletePermission === false) this.canDelete = false;
        }
      });
    }
    this.rowKeysSelected.emit(Object.keys(this.selectedKeys));
  }
}
