
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { FormControl } from '@angular/forms';

import { IActivity } from '../../models/activity/activity.interface';
import { ButtonState } from '../../utils/enums/button-state.enum';
import { ISelectionModal } from '../../models/selection-modal/selection-modal.interface';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectService } from '../../services/project/project.service';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-selection-modal',
  templateUrl: './selection-modal.component.html',
  styleUrls: ['./selection-modal.component.scss']
})
export class SelectionModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectionData: ISelectionModal;
  @Input() activeFilters: string[] = [];
  @Input() projectObjectIds: string[] = [];
  @Input() exceptionSelections: string[] = [];
  @Input() showBackButton: boolean;
  @Input() enableAdd: boolean = false; // In HTML

  @Output() selectionOutput: EventEmitter<any> = new EventEmitter<any>();
  @Output() backButtonOutput: EventEmitter<void> = new EventEmitter<void>();

  // Modal input
  displayedData: ISelectionModal = { message: '', items: [] };
  totalItems: number = 0;

  // Button state
  buttonState = ButtonState;

  // Autocomplete stuff
  tcAutoControl = new FormControl();
  activitiesAssign: Observable<IActivity[]>;
  allTradesAvailable: IActivity[];
  idToAdd: string;
  pageSize: number = 50;

  // Subscriptions
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectService: ProjectService,
    private projectObjectService: ProjectObjectService,
    private notificationService: NotificationService
  ) {/*EMPTY*/ }

  ngOnInit() {
    this.setupAutocomplete();
  }

  ngOnChanges(event) {
    this.filterSelectionData();
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  filterSelectionData() {
    // gets all active activities and their children
    const allActivitiesFilter = this.getAllActivitiesRecursive({}, this.activeFilters);
    if (this.selectionData) {
      this.displayedData.message = this.selectionData.message;
    } else {
      this.totalItems = 0;
      return;
    }
    if (this.selectionData.items.length <= 0) return;
    if (this.activeFilters.length <= 0) {
      this.displayedData = this.selectionData;
    } else {
      this.totalItems = this.selectionData.items.length;
      this.displayedData.message = 'select_activity_filtered';
      this.displayedData.items = this.selectionData.items.filter(item => allActivitiesFilter.includes(item.value.activity));
    }
  }

  // takes activity and checks parent for children activites
  // will recursively run until it reaches an activity with no children
  getAllActivitiesRecursive(activityObjRef, activityArray) {
    const allActivities = activityObjRef;

    activityArray.forEach( activity => {
      if (Utils.isEmpty(allActivities[activity])) {
        allActivities[activity] = activity;
        const localActivity = this.projectService.getLocalActivity(activity);

        if (localActivity && !Utils.isEmpty(localActivity.children)) this.getAllActivitiesRecursive(allActivities, localActivity.children);
      }
    });

    return Object.keys(allActivities);
  }

  select(item: any): void {
    this.selectionOutput.emit(item);
  }

  setupAutocomplete() {
    this.allTradesAvailable = this.projectService.getLocalAllActivities();
    // Remove Already Assigned
    let alreadyAssignedIds = this.selectionData.items.map(t => t.value.activity);
    alreadyAssignedIds = alreadyAssignedIds.concat(this.exceptionSelections);
    this.allTradesAvailable = Utils.sortByString(this.allTradesAvailable.filter(tc => !alreadyAssignedIds.includes(tc.id)), 'name');
    this.activitiesAssign = this.tcAutoControl.valueChanges.pipe(
      startWith<string | IActivity>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.search(name) : this.allTradesAvailable.slice(0, Math.min(this.allTradesAvailable.length, 50)).filter(t => !t.children || t.children.length === 0))
    );
  }

  displayFn(activity?: IActivity): string {
    return activity ? activity.name : undefined;
  }

  search(name: string): IActivity[] {
    const searchQuery = name.toLowerCase();
    const searchRes = this.allTradesAvailable.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, this.pageSize)).filter(t => !t.children || t.children.length === 0);
  }

  tradeSelectedToAdd(event) {
    this.idToAdd = event.option.value.id;
    this.addActivityToObject();
  }

  // Add activity to object
  addActivityToObject() {
    if (this.projectObjectIds.length <= 0) return;
    this.projectObjectService.addActivities(this.projectService.currentProject.id, this.projectObjectIds, [this.idToAdd]).pipe(takeUntil(this.destroyed$)).subscribe(
      async () => {
        const activity = this.projectService.getLocalActivity(this.idToAdd);
        this.select({ name: activity.name, activity: activity.id });
      },
      err => {
        this.tcAutoControl.setValue('');
        this.notificationService.error(err, { type: 'object', action: 'set' });
      }
    );
  }
  // END Add

}
