import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { IActivityFormOutput, ICosts, IMasterScheduleActivity, IPrereqActivity } from '../../../models/activity/activity.interface';
import { IProjectEquipment } from '../../../models/project/project-equipment/project-equipment.interface';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectLaborService } from '../../../services/project/project-labor/project-labor.service';
import { IProjectMaterial } from '../../../models/project/project-material/project-material.interface';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectService } from '../../../services/project/project.service';

@Component({
  selector: 'app-edit-activity-modal',
  templateUrl: './edit-activity-modal.component.html',
  styleUrls: ['./edit-activity-modal.component.scss']
})
export class EditActivityModalComponent implements OnInit, OnDestroy {

  // Template variables
  addingNew: boolean = false;
  isValid: boolean = false;
  prereqData: IPrereqActivity[] = [];
  curId: string;
  parentId: string;
  costs: ICosts;
  projectId: string;

  materialIds: string[] = [];
  equipmentIds: string[] = [];
  rfiIds: string[] = [];
  laborIds: string[] = [];
  updatedEquipments = [];
  updatedMaterials = [];
  updatedLabors = [];

  private formData: IActivityFormOutput;

  private prerequisites: IPrereqActivity[] = [];

  @Input() formInput: FormGroup;
  @Input() allActivities: IMasterScheduleActivity[];
  @Input() symbol: string;
  @Input() selectedActivityId: string;

  @Output() submitOutput: EventEmitter<IActivityFormOutput> = new EventEmitter();
  @Output() deleteOutput: EventEmitter<string[]> = new EventEmitter();

  constructor(
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService,
    private projectService: ProjectService,
    private laborService: ProjectLaborService
  ) {/*EMPTY*/}

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    if (!this.formInput.get('name').value) this.addingNew = true;
    this.curId = this.formInput.get('id').value;
    this.parentId = this.formInput.get('parentId').value;
    this.setPrereqData();
    this.setCostsData();
    this.setEquipmentMaterialData();
    this.setRfiData();
    this.setLaborData();
    this.projectId = this.projectService.currentProject.id;
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  setPrereqData() {
    this.prerequisites = this.formInput.get('prerequisites').value;
    this.prereqData = cloneDeep(this.prerequisites);
  }

  setCostsData() {
    this.costs = {
      equipmentCost: this.formInput.get('equipmentCost').value,
      laborCost: this.formInput.get('laborCost').value,
      materialCost: this.formInput.get('materialCost').value
    };
  }

  setEquipmentMaterialData() {
    this.materialIds = this.formInput.get('materialIds').value;
    this.equipmentIds = this.formInput.get('equipmentIds').value;
  }

  setRfiData() {
    this.rfiIds = this.formInput.get('rfiIds').value;
  }

  setLaborData() {
    this.laborIds = this.formInput.get('laborIds').value;
  }
  // Called on init of activity form
  formDataChange(output) {
    this.isValid = output.valid;
    this.formData = output.data;
    this.formData.activity.prerequisites = this.prerequisites;
    this.formData.activity.equipmentCost = this.costs.equipmentCost;
    this.formData.activity.laborCost = this.costs.laborCost;
    this.formData.activity.materialCost = this.costs.materialCost;
    this.formData.activity.materialIds = this.materialIds;
    this.formData.activity.equipmentIds = this.equipmentIds;
    this.formData.activity.rfiIds = this.rfiIds;
    this.formData.activity.laborIds = this.laborIds;
  }

  // Called on update of prereq table
  updatePrereqData(prereqs: IPrereqActivity[]) {
    this.prerequisites = prereqs;
    this.formData.activity.prerequisites = this.prerequisites;
  }

  costUpdate(costs: ICosts) {
    this.costs = costs;
    this.formData.activity.equipmentCost = this.costs.equipmentCost;
    this.formData.activity.laborCost = this.costs.laborCost;
    this.formData.activity.materialCost = this.costs.materialCost;
  }

  async submit() {
    const obj = this.formData;
    obj.updatedEquipments =  this.updatedEquipments;
    obj.updatedMaterials =  this.updatedMaterials;
    obj.updatedLabors =  this.updatedLabors;

    await this.submitOutput.emit(obj);
    // if (this.updatedEquipments.length > 0) await this.submitUpdatedEquipments();
    // if (this.updatedMaterials.length > 0) await this.submitUpdatedMaterials();
    // if (this.updatedLabors.length > 0) await this.submitUpdatedLabors();
  }

  async submitUpdatedEquipments() {
    await this.equipmentService.updateEquipments(this.updatedEquipments, this.projectId).subscribe(
      res => {
      },
      err => {
      }
    );
  }

  async submitUpdatedMaterials() {
    await this.materialService.updateMaterials(this.updatedMaterials, this.projectId).subscribe(
      res => {
      },
      err => {
      }
    );
  }

  async submitUpdatedLabors() {
    await this.laborService.updateLabors(this.updatedLabors, this.projectId).subscribe(
      res => {
      },
      err => {
      }
    );
  }

  delete() {
    this.deleteOutput.emit([this.formInput.get('id').value]);
  }

  keyPress = (event: any) => {
    if (event.key === 'Enter') {
      if (this.isValid) {
        this.submit();
      }
    }
  }

  materialUpdate(output: {materialIds: string[]}) {
    this.materialIds = output.materialIds;
    this.formData.activity.materialIds = this.materialIds;
  }

  equipmentUpdate(output: {equipmentIds: string[]}) {
    this.equipmentIds = output.equipmentIds;
    this.formData.activity.equipmentIds = this.equipmentIds;
  }

  updateEquipments(equipmentsToUpdate) {
    this.updatedEquipments.push(equipmentsToUpdate);
  }

  updateMaterials(materialsToUpdate) {
    this.updatedMaterials.push(materialsToUpdate);
  }

  updateLabors(laborsToUpdate) {
    this.updatedLabors.push(laborsToUpdate);
  }

  rfiUpdate(output: {rfiIds: string[]}) {
    this.rfiIds = output.rfiIds
    this.formData.activity.rfiIds = this.rfiIds;
  }

  laborUpdate(output: {laborIds: string[]}) {
    this.laborIds = output.laborIds;
    this.formData.activity.laborIds = this.laborIds;
  }
}
