import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication/authentication.service';
import { LoginStatusService } from '../services/login/login-status.service';
import { INoficationContext } from '../models/notification/notification.interface';
import { NotificationService } from '../services/notification/notification.service';
import { SegmentService } from '../services/segment/segment.service';
import { UserService } from '../services/user/user.service';
import { Utils } from '../utils/utils';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    AuthenticationService,
    LoginStatusService,
    UserService
  ]
})
export class LoginComponent implements OnInit {

  email: string = '';
  password: string = '';
  rememberMe: boolean = false;
  message: string = '';
  token: string = '';

  loginButtonDisabled: boolean = false;
  showMfa: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private segmentService: SegmentService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() { /*empty*/ }

  login() {
    if (Utils.isEmpty(this.email) || Utils.isEmpty(this.password)) {
      this.notificationService.error('CREDENTIALS_MISSING', {});
      return;
    }
    this.loginButtonDisabled = true;
    const authentication = {'email': this.email, 'password': this.password, 'rememberMe': this.rememberMe, token: this.token};
    this.authenticationService.authenticate(authentication).subscribe(
      response => {
        this.segmentService.identify(response.userId, {});
        this.segmentService.track('Log In', {rememberMe: this.rememberMe});
        localStorage.setItem('csrfToken', response.csrfToken);
        LoginStatusService.emitChange(true);
        this.router.navigateByUrl(localStorage.getItem('loginReturn') || '/home');
      },
      err => {
        this.loginButtonDisabled = false;
        this.showMfa = false;
        this.token = '';

        if (err === 'REQUIRES_MFA' && !this.showMfa) {
          this.showMfa = true;
        } else {
          const context: INoficationContext = {
            type: 'user',
            action: 'login'
          };
          this.notificationService.error(err, context);
        }
      });
  }
}
