import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationVerificationService } from '../../services/authentication/authentication-verification.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { LoginStatusService } from '../../services/login/login-status.service';
import { INoficationContext } from '../../models/notification/notification.interface';
import { NotificationService } from '../../services/notification/notification.service';
// tslint:disable-next-line:import-blacklist
import { ReplaySubject } from 'rxjs';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-verify',
  templateUrl: './users-verify.component.html',
  styleUrls: ['./users-verify.component.scss'],
  providers: [
    AuthenticationVerificationService,
    UserService
  ]
})
export class UsersVerifyComponent implements OnInit, OnDestroy {

  email: string = '';
  password: string = '';
  firstName: string = '';
  lastName: string = '';
  confirmPassword: string = '';
  verificationId: string = '';
  showTermsModal: boolean = false;
  termsAccepted: boolean = false;
  showNames: boolean = true;
  terms: boolean = true;
  showToken: boolean = false;
  showConfirm: boolean = false;
  token: string = '';

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private authenticationService: AuthenticationService,
    private _authenticationVerificationService: AuthenticationVerificationService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private segmentService: SegmentService
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      const names = params['names'];
      if (names != null && names.toString() === 'false') this.showNames = false;
      else this.showNames = true;

      const a = params['a'];
      console.log(a);
      if (a != null && a.toString() === 'false') this.terms = false;
      else this.terms = true;
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  showTerms(): void {

    if (!Utils.validateEmailAddress(this.email)) {
      const context: INoficationContext = {
        type: 'valid email',
        action: 'provide'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    if (Utils.isEmpty(this.firstName) && this.showNames) {
      const context: INoficationContext = {
        type: 'first name',
        action: 'provide'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    if (Utils.isEmpty(this.lastName) && this.showNames) {
      const context: INoficationContext = {
        type: 'last name',
        action: 'provide'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    if (Utils.isEmpty(this.password)) {
      const context: INoficationContext = {
        type: 'password',
        action: 'provide'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    if (Utils.isEmpty(this.token) && this.showToken) {
      const context: INoficationContext = {
        type: 'token',
        action: 'confirm'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    if (Utils.isEmpty(this.confirmPassword) && !this.showConfirm) {
      const context: INoficationContext = {
        type: 'password',
        action: 'confirm'
      };
      this.notificationService.error('APP_MISSING_INFO', context);
      return;
    }
    if (this.password !== this.confirmPassword && !this.showConfirm) {
      this.notificationService.error('APP_PASSWORD_MATCH', {});
      return;
    }
    if (!Utils.validatePassword(this.password)) {
      this.notificationService.error('INVALID_PASSWORD', {});
      return;
    }

    if (this.terms) this.showTermsModal = true;
    else (this.submitVerify());
  }

  submitVerify(): void {
    if (!this.termsAccepted && this.terms) return;

    const user = {
      'verificationNumber': this.route.snapshot.params['userVerificationId'],
      'email': this.email,
      'password': this.password,
      'confirmPassword': this.showConfirm ? undefined : this.confirmPassword,
      'token': this.showToken ? this.token : undefined
    };
    if (this.showNames) {
      user['firstName'] = this.firstName;
      user['lastName'] = this.lastName;
    }
    this._authenticationVerificationService.verifyUser(user).subscribe(
      (response) => {
        this.segmentService.track('User Verified', { email: this.email });
        this.segmentService.identify(response.userId, {});
        localStorage.setItem('csrfToken', response.csrfToken);
        LoginStatusService.emitChange(true);
        if (this.showConfirm) {
          this.router.navigateByUrl('/user/settings');
        } else {
          this.router.navigateByUrl('/home');
        }
      },
      (err) => {
        if (err === 'REQUIRES_MFA') {
          this.showToken = true;
        } else {
          this.notificationService.error('INVALID_VERIFICATION_TOKEN', null);
        }
      });
  }
}
