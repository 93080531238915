// Shared Enum for generic enums used on multiple components across the app, just started 8/16/2018, need to transition other enums to this file
export enum ViewMode {
  Table = 'Table',
  Gantt = 'Gantt',
  OneMonthGantt = '1_month',
  ThreeMonthGantt = '3_month',
  SixMonthGantt = '6_month',
  TwelveMonthGantt = '12_month',
  OneWeekSchedule = 'OneWeekSchedule',
  ThreeWeekSchedule = 'ThreeWeekSchedule',
  SixWeekSchedule = 'SixWeekSchedule',
  EightWeekSchedule = 'EightWeekSchedule',
  Calendar = 'Calendar',
  SprintBoard = 'SprintBoard',
  Equipment = 'equipment',
  Materials = 'materials',
  All = 'all',
  SprintCommitment = 'SprintCommitment',
}

export enum FaIcon {
  ChevronRight = '<i class="fas fa-chevron-right"></i>',
  ChevronDown = '<i class="fas fa-chevron-down"></i>',
  Plus = '<i class="fas fa-plus"></i>',
  Pencil = '<i class="fas fa-pencil-alt"></i>',
  Trash = '<i class="fas fa-trash"></i>',
  Cube = '<i class="fas fa-cube"></i>',
  Select = '<i class="far fa-object-ungroup"></i>',
  CheckSquare = '<i class="fas fa-check-square"></i>',
  Square = '<i class="fas fa-square"></i>',
  MinusSqaure = '<i class="fas fa-minus-square"></i>'
}

export enum ColorMode {
  Criticality = 'Criticality',
  Subcontractor = 'Subcontractor',
  Status = 'Status'
}

export enum ViewerMode {
  ForgViewer = 'ForgViewer',
  PDFViewer = 'PDFViewer'
}

export enum PageRenderMode {
  onePage = 'onePage',
  twoPage = 'twoPage',
  fourPage = 'fourPage'
}

export enum BulkEditSelection {
  unchecked = 0,
  checked = 1,
  indeterminate = 2
}