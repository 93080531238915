import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { ActivityService } from '../../../services/activity/activity.service';
import { ProjectMilestoneService } from '../../../services/project/project-milestone/project-milestone.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';

import { IActivity, IActivityFormOutput } from '../../../models/activity/activity.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';

import { Utils } from '../../../utils/utils';
import { BulkEditSelection } from '../../../utils/enums/shared.enum';
import { ProjectSprintService } from '../../../services/project/project-sprint/project-sprint.service';
import { IProjectSprint } from '../../../models/project/project-sprint/project-sprint.interface';

@Component({
  selector: 'app-project-activity-form',
  templateUrl: './project-activity-form.component.html',
  styleUrls: ['./project-activity-form.component.scss']
})
export class ProjectActivityFormComponent implements OnInit, OnDestroy {

  @Input() formInput: FormGroup;

  @Output() activityOutput: EventEmitter<{data: IActivityFormOutput, valid: boolean}> = new EventEmitter();

  // Template Variables
  addingNew: boolean = false;
  subcontractorOptions;
  milestoneOptions;
  containsActiveSprint: boolean;
  updateDependentfieldsOnceOnly : boolean =false ;
  maxEndDate: number;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // returns specific form control by label
  get name() { return this.formInput.get('name'); }
  get externalActivityId() { return this.formInput.get('externalActivityId'); }
  get duration() { return this.formInput.get('duration'); }
  get expectedDuration() { return this.formInput.get('expectedDuration'); }
  get crewSize() { return this.formInput.get('crewSize'); }
  get subcontractorControl() { return this.formInput.get('subContractor'); }
  get subcontractorDisplayFn() { return Utils.defaultDisplayFn; }
  get milestoneControl() { return this.formInput.get('milestone'); }
  get milestoneDisplayFn() { return Utils.defaultDisplayFn; }
  get anchorDate() { return this.formInput.get('anchorDate'); }
  get actualStartDate() { return this.formInput.get('actualStartDate'); }
  get actualFinishDate() { return this.formInput.get('actualFinishDate'); }
  get expectedFinishDate() { return this.formInput.get('expectedFinishDate'); }
  get taskId() { return this.formInput.get('taskId'); }
  get startDate() { return this.formInput.get('startDate'); }
  get sprintId() { return this.formInput.get('sprintId'); }

  sprintList: IProjectSprint[] = [];
  originalActualStartDate: any;
  originalActualFinishDate: any;
  originalExpectedFinishDate: any;

  constructor(
    public activityService: ActivityService,
    private subContractorService: ProjectSubContractorService,
    private milestoneService: ProjectMilestoneService,
    private projectSprintService: ProjectSprintService
  ) {/*EMPTY*/}

  async ngOnInit() {
    this.containsActiveSprint = this.projectSprintService.activeSprint && this.projectSprintService.activeSprint ? true: false;

    //Temporarily disabling actual start date for add activity and if no sprints are active.
    if (!this.name.value || !this.containsActiveSprint) {
      if (this.actualStartDate) this.actualStartDate.disable();
      if (this.actualFinishDate) this.actualFinishDate.disable();
      if (this.expectedFinishDate) this.expectedFinishDate.disable();
      if (this.expectedDuration) this.expectedDuration.disable();
    }

    if (!this.actualStartDate.value) {
      if (this.actualFinishDate) this.actualFinishDate.disable();
      if (this.expectedFinishDate) this.expectedFinishDate.disable();
      if (this.expectedDuration) this.expectedDuration.disable();
    }

    if (this.actualFinishDate.value) {
      if (this.expectedFinishDate) this.expectedFinishDate.disable();
      if (this.expectedDuration) this.expectedDuration.disable();
    }

    if (this.sprintId.value) {
      this.sprintId.disable();
    }

    this.originalActualStartDate = this.actualStartDate.value;
    this.originalActualFinishDate = this.actualFinishDate.value;
    this.originalExpectedFinishDate = this.expectedFinishDate.value;

    await this.getPredecessorDetails(this.formInput.value);

    await this.getWorkPlans().then((sprints: IProjectSprint[]) => {
      this.sprintList = sprints.filter(sprint => !sprint.endDate);
    }).catch(err => { });
    this.handleActualStartDateChange();
    this.handleDurationChange();
    this.handleExpectedDurationChange();
    this.handleSprintChange();
    this.handleExpectedFinishDateChange();
    this.handleActualFinishDateChange();
    if (!this.name.value) this.addingNew = true;
    const projectSubcontractors = Utils.sortByString(this.subContractorService.getLocalProjectSubcontractors(), 'name');
    this.subcontractorOptions = this.formInput.controls['subContractor'].valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith<string | IProjectSubContractor>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.subSearch(name) : projectSubcontractors.slice(0, Math.min(projectSubcontractors.length, 50)))
    );

    const projectMilestones = Utils.sortByString(this.milestoneService.getLocalProjectMilestones(), 'name');
    this.milestoneOptions = this.formInput.controls['milestone'].valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith<string | any>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.milestoneSearch(name) : projectMilestones.slice(0, Math.min(projectMilestones.length, 50)))
    );

    this.formInput.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onSubmit(this.formInput);
    });

    // Called to update edit modal on load
    this.onSubmit(this.formInput, this.addingNew);
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  onSubmit(form, firstAddingNew?): void {
    const formValues = form.getRawValue();
    const formActivity: IActivity = {
      id: formValues.id ? formValues.id : null,
      parentId: this.formInput.get('parentId').value,
      projectId: this.formInput.get('projectId').value,
      externalActivityId: this.formInput.get('externalActivityId').value,
      name: formValues.name ? formValues.name.toString() : '',
      duration: formValues.duration ? formValues.duration : 0,
      expectedDuration: formValues.expectedDuration ? formValues.expectedDuration : 0,
      startDate: formValues.startDate ? Utils.toUtcDate(formValues.startDate) : null,
      endDate: formValues.endDate ? Utils.toUtcDate(formValues.endDate) : null,
      crewSize: this.crewSize.value,
      anchorDate: formValues.anchorDate ? Utils.toUtcDate(formValues.anchorDate) : null,
      actualStartDate: formValues.actualStartDate ? Utils.toUtcDate(formValues.actualStartDate) : null,
      actualFinishDate: formValues.actualFinishDate ? Utils.toUtcDate(formValues.actualFinishDate) : null,
      expectedFinishDate: formValues.expectedFinishDate ? Utils.toUtcDate(formValues.expectedFinishDate) : null,
      sprintId: this.formInput.get('sprintId').value,
    };

    const subValue = this.subcontractorControl.value;
    const milestoneValue = this.milestoneControl.value;
    const formOutput: IActivityFormOutput = {
      activity: formActivity,
      subContractorId: subValue && subValue.id ? subValue.id : null,
      milestoneId: milestoneValue && milestoneValue.id ? milestoneValue.id : null,
      bulkEditSelection: BulkEditSelection.unchecked
    };

    if (!formOutput.milestoneId && milestoneValue && milestoneValue.length > 0) formOutput.milestoneName = milestoneValue.toString();

    let valid = this.formInput.valid;
    if (firstAddingNew) valid = false;

    this.activityOutput.emit({data: formOutput, valid: valid});
  }

  subSearch(name: string): IProjectSubContractor[] {
    const projectSubcontractors = Utils.sortByString(this.subContractorService.getLocalProjectSubcontractors(), 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = projectSubcontractors.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  milestoneSearch(name: string): any[] {
    const projectMilestones = Utils.sortByString(this.milestoneService.getLocalProjectMilestones(), 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = projectMilestones.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  async getPredecessorDetails(activity) {
    await this.activityService.getPredecessorDetails(activity.id).subscribe(
      res => {
        const maxDatesArray = [];
        const existingPrereq = res;
        existingPrereq.forEach((item) => {
          if (!item.actualFinishDate) {
            item.expectedFinishDate ? maxDatesArray.push(item.expectedFinishDate) : maxDatesArray.push(item.endDate);
          }
        });
        this.maxEndDate = Math.max(...maxDatesArray);
      }, err => {
      });
  }

  handleActualStartDateChange() {
    this.formInput.controls['actualStartDate'].valueChanges.subscribe(value => {
      if (!this.isActualStartValid(value)) return;
      if(this.actualFinishDate.disabled) this.actualFinishDate.enable();
      if(this.expectedFinishDate.disabled)  this.expectedFinishDate.enable();
      if(this.expectedDuration.disabled) this.expectedDuration.enable();

      const increment = this.formInput.controls['expectedDuration'].value || this.formInput.controls['duration'].value;
      const finishDate = Utils.getEndDateExcludingWeekends(+value, increment);
      
      this.expectedFinishDate.setValue(new Date(finishDate));
      this.validationHandlerForExpectedFinishDate();
    });
  }

  handleExpectedFinishDateChange() {
    this.formInput.controls['expectedFinishDate'].valueChanges.subscribe(value => {
      if (!value) {
        this.formInput.controls['expectedFinishDate'].markAsTouched();
        this.formInput.controls['expectedFinishDate'].setErrors({ 'invalid': true });
      }
      this.validationHandlerForExpectedFinishDate();
      this.updateDependentfieldsOnceOnly = true;
      this.expectedDuration.setValue(Utils.getExpectedDuration(+this.formInput.controls['actualStartDate'].value, +this.formInput.controls['expectedFinishDate'].value));
      this.updateDependentfieldsOnceOnly = false;
    });
  }

  isActualStartValid(value: any): boolean {
    if (!value) { //Invalid condition.
      // Exception case:
      if (!this.originalActualStartDate) {
        this.formInput.controls['sprintId'].markAsUntouched();
        this.formInput.controls['sprintId'].markAsTouched();
        this.formInput.controls['sprintId'].setErrors({ 'invalid': null });
        this.formInput.controls['sprintId'].reset();

        this.actualFinishDate.disable();
        this.expectedFinishDate.disable();
        this.expectedFinishDate.reset();
        return false;
      } else {
        this.actualFinishDate.disable();
        this.expectedFinishDate.disable();
        this.expectedFinishDate.reset();
        this.formInput.controls['actualStartDate'].setErrors({ 'invalid': true });
        return false;
      }
    } else {
      if (!this.formInput.controls['sprintId'].value) {
        this.formInput.controls['sprintId'].markAsTouched();
        this.formInput.controls['sprintId'].setErrors({ 'invalid': true });
      }
      return true;
    }
  }

  validationHandlerForExpectedFinishDate() {
    const expectedFinishDate = this.expectedFinishDate.value;
    if (expectedFinishDate < this.formInput.controls['actualStartDate'].value) {
      this.formInput.controls['expectedFinishDate'].markAsTouched();
      this.formInput.controls['expectedFinishDate'].setErrors({ 'invalid': true });
    }
  }

  handleDurationChange() {
    // this.formInput.controls['duration'].valueChanges.subscribe(value => {
    //   if(Utils.isEmpty(value)){
    //     this.expectedFinishDate.setValue(null);
    //     return;
    //   }
    //   if (+this.formInput.controls['actualStartDate'].value) {
    //     const finishDate = Utils.getEndDateExcludingWeekends(+this.formInput.controls['actualStartDate'].value, value)
    //     this.expectedFinishDate.setValue(new Date(finishDate));
    //     this.validationHandlerForExpectedFinishDate()
    //   }
    // });
  }

  handleExpectedDurationChange() {
    this.formInput.controls['expectedDuration'].valueChanges.subscribe(value => {
      if(this.updateDependentfieldsOnceOnly) return;

      if(!value){
        this.expectedFinishDate.setValue(null);
        return;
      }

      if (this.formInput.controls['duration'].value && +this.formInput.controls['actualStartDate'].value) {
        const finishDate = Utils.getEndDateExcludingWeekends(+this.formInput.controls['actualStartDate'].value, value)
        this.expectedFinishDate.setValue(new Date(finishDate));
        this.validationHandlerForExpectedFinishDate()
      }
    });
  }

  handleActualFinishDateChange() {
    this.formInput.controls['actualFinishDate'].valueChanges.subscribe(value => {
      if (!value) return;

      this.expectedFinishDate.setValue(new Date(+value));
    });
  }

  handleSprintChange() {
    this.formInput.controls['sprintId'].valueChanges.subscribe(value => {
    });
  }

  async getWorkPlans(): Promise<any> {
    const projectId = this.formInput.get('projectId').value;
    return this.projectSprintService.getSprintList(projectId).toPromise();
  }
}
