export default {
  "1_month": "1 Month",
  "3_month": "3 Months",
  "6_month": "6 Months",
  "12_month": "12 Months",
  "abbreviation": "Abbreviation",
  "account_users": "Account Users",
  "accounts_menu_item": "Accounts",
  "actions": "Actions",
  "activate": "Activate",
  "activate_schedule": "Activate Schedule",
  "activate_schedule_confirm": "Are you sure you want to activate this schedule?",
  "activate_schedule_no_honor_confirm": "This schedule does not honor commitments. If you activate it, any non-active Work Plans will be deleted. Are you sure you want to activate this schedule?",
  "activate_schedule_first": "Please activate a schedule to proceed",
  "active": "Active",
  "active_schedule": "Active Schedule",
  "activities": "Activities",
  "activities_needing_planned": "Activities Needing Planned",
  "activity": "Activity",
  "activity_does_not_contain_objects": "Activity does not contain objects",
  "activity_has_dependencies": "Cannot delete an activity that is assigned to an object",
  "activity_prerequisite": "Activity Prerequisite",
  "activity_required": "Activity is required",
  "activity_list": "Activity List",
  "actual": "Actual",
  "actual_start": "Actual Start",
  "actual_finish": "Actual Finish",
  "actual_hours": "Actual Hours",
  "hours_percent_complete": "Hours Percent Complete",
  "actual_hours_percent_complete": "Actual Hours % Complete",
  "activity_percent_complete": "Activity Percent Complete",
  "adBlocker_warning" : "PDF export of current view. Please ensure Ad Blocker turned off for this site.",
  "add": "Add",
  "add_all_recommended": "Add all Recommended",
  "add_box": "Add Box",
  "add_child": "Add Child",
  "add_cut": "Add Cut",
  "add_days": "Add Set of Days",
  "add_equipment": "Add Equipment",
  "add_labor": "Add Labor",
  "edit_labor": "Edit Labor",
  "add_material": "Add Material",
  "add_milestone": "Add Milestone",
  "add_objects_to_work_package": "Add objects to Work Package",
  "add_or_edit_work_package": "Add or edit a Work Package",
  "add_package": "Add Package",
  "add_prerequisite": "Add a Prerequisite",
  "add_project_user": "Add Project User",
  "add_rfi": "Add RFI",
  "add_scenario": "Add Scenario",
  "add_subcontractor_role": "Add Subcontractor Role",
  "add_unmodeled_prereq": "Add Unmodeled Prerequisite",
  "add_unmodeled_task": "Add Unmodeled Task",
  "add_view": "Add View",
  "add_work_plan": "Add Work Plan",
  "add_x_plane": "Add X Plane",
  "add_y_plane": "Add Y Plane",
  "add_z_plane": "Add Z Plane",
  "adds_activities_predecessors_as_prerequisites": "Adds activities listed as immediate predecessors as prerequisites",
  "assign_activity": "ASSIGN ACTIVITY",
  "assigned_elements": "Assigned Elements",
  "edit_other_prereqs": "Edit other prerequisites",
  "edit_prereq_activities": "Edit prerequisite activities",
  "edit_prereq_objects": "Prerequisite Object(s)",
  "editing_work_package_objects": "Select object(s) to install",
  "editing_prereq_objects": "Select prerequisite object(s) required",
  "editing_work_package_prereq_objects": "Select prerequisite object(s) required",
  "adding_to_work_plan": "Adding to Work Plan",
  "additional_equipment": "Additional Equipment",
  "additional_materials": "Additional Materials",
  "additional_labor": "Additional Labor",
  "address": "Address",
  "admin": "Admin",
  "aggregate_hours": "{{aggregateHours}} Aggregate Hours",
  "always": "Always",
  "anchor_date": "Anchor Date",
  "api_keys": "API Keys",
  "api_token_created": "Your token's key and secret have been generated. Save the secret, as this will not be shown to you again.",
  "app_delete_confirm": "Reloading project",
  "app_help_ticket_sent": "We've received your request and will get back with you as soon as possible",
  "app_not_planned": "Object has not been planned",
  "app_password_match": "Passwords must match",
  "app_required_fields_missng": "Please provide all required information",
  "app_reset_password_confirm": "Check the inbox of {{email}} to reset your password",
  "app_verify_success": "Please Log In",
  "approval_date_abbrev": "Appr.",
  "approval_date": "Approval Date",
  "approved": "Approved",
  "ar_code_expires": "This code will expire in 5 minutes",
  "ar_qr_menu_item": "AR QR Code",
  "archive": "Archive",
  "archive_project": "Archive Project",
  "archive_project_description": "I no longer need regular access to this project",
  "are_you_sure": "Are you sure?",
  "ascending": "Ascending",
  "assign_objects": "Assign Objects",
  "assign_work_area": "Assign Work Area",
  "assign_prereq": "Assign a prerequisite",
  "assign_prerequisites": "Assign Prerequisites",
  "assign_sprint": "Assign Sprint",
  "auto_assign_info": "Auto Assign Info",
  "backlog": "Backlog",
  "blocker_information": "Blocker Information",
  "blockers": "Blockers",
  "blocking_task": "Blocking Task",
  "blocks_nearby_tasks": "Blocks nearby tasks",
  "board": "Board",
  "body_invalid": "Body Invalid",
  "bored_menu_item": "I'm Bored",
  "budget": "Budget",
  "budget_over_time": "Budget Over Time",
  "budget_quantity" : "Budget Quantity",
  "budget_assigned": "Budget Assigned",
  "calculated": "Calculated",
  "calendar": "Calendar",
  "cancel": "Cancel",
  "cannot_retrieve_planning_permissions": "Cannot retrieve all planning permissions",
  "categories": "Categories",
  "floor_plan": "Floor Plan",
  "fp": "Plan",
  "cat": "Cat",
  "category": "Category",
  "center": "Center",
  "change_password": "Change Password",
  "circular_reference": "Activities cannot be dependent on one another, creating a loop. Please verify your prerequisites: {{details}}",
  "clear": "Clear",
  "clear_filters": "Clear Filters",
  "clear_photo": "Clear Photo",
  "clear_set": "Clear Set",
  "closed": "Closed",
  "closing_sprint": "Closing Work Plan",
  "collapse_menu_item": "Collapse",
  "color": "Color",
  "comments": "Comments",
  "commit": "Commit",
  "committed": "Committed",
  "company": "Company",
  "compare_to": "Compare Schedule",
  "completed": "Completed",
  "completion_date_required": "Completion date is required",
  "confirm": "Confirm",
  "confirm_password": "Confirm Password",
  "confirm_to_finish": "Confirm to finish",
  "connect": "Connect",
  "contact_email": "Contact Email",
  "contact_name": "Contact Name",
  "continue_without_saving": "Are you sure you want to continue without saving your changes?",
  "cost": "Cost",
  "cost_data_error": "No cost data in range",
  "flow_data_error": "No activities data in range",
  "create": "Create",
  "create_master_schedule": "Create Master Schedule",
  "create_new_project": "Create new project",
  "creating_tasks_for_x": "Creating tasks for {{activityName}}",
  "credentials_invalid": "Email and/or password do not match",
  "credentials_missing": "Enter email and password",
  "crew_size": "Crew Size",
  "crew_size_at_least_x": "Crew size must be at least {{minCrewSize}}",
  "crew_size_less_than_x": "Crew size must be less than {{maxCrewSize}}",
  "crew_size_must_be_integer": "Crew size must be an integer",
  "crew_size_must_be_number": "Crew size must be a number",
  "crew_size_required": "Crew size is required",
  "crew_supply": "Crew Supply",
  "criteria_dashboard": "You're not ready to use the dashboard yet",
  "criteria_last_planner": "You aren't ready to use the last planner yet",
  "criteria_lookahead": "You aren't ready to lookahead yet",
  "criteria_low_permission": "You may have insufficient permissions to complete necessary criteria",
  "criteria_models": "You aren't ready to view models yet",
  "criteria_network_map": "You aren't ready to use the network map yet",
  "criteria_make_ready": "You aren't ready to use the make ready page yet",
  "criteria_reports": "You aren't ready to view reports yet",
  "criteria_scenarios": "You aren't ready to use the scenarios page yet",
  "criteria_sprints": "You aren't ready to make Work Plans yet",
  "criteria_step": "Step {{step}}",
  "critical": "Critical",
  "criticality": "Criticality",
  "currency": "Currency",
  "current_password": "Current Password",
  "cut": "cut",
  "descending": "Descending",
  "dashboard_menu_item": "Dashboard",
  "data_loading": "Loading",
  "date": "Date",
  "date_added": "Date Added",
  "date_by_unit": "Date (by {{unit}})",
  "date_completed": "Date Completed",
  "daily_logs": "Daily Logs",
  "day": "Day",
  "day_friday": "Friday",
  "day_friday_abbreviation": "Fri",
  "day_friday_letter": "F",
  "day_monday": "Monday",
  "day_monday_abbreviation": "Mon",
  "day_monday_letter": "M",
  "day_saturday": "Saturday",
  "day_saturday_abbreviation": "Sat",
  "day_saturday_letter": "S",
  "day_sunday": "Sunday",
  "day_sunday_abbreviation": "Sun",
  "day_sunday_letter": "U",
  "day_thursday": "Thursday",
  "day_thursday_abbreviation": "Thu",
  "day_thursday_letter": "R",
  "day_tuesday": "Tuesday",
  "day_tuesday_abbreviation": "Tue",
  "day_tuesday_letter": "T",
  "day_wednesday": "Wednesday",
  "day_wednesday_abbreviation": "Wed",
  "day_wednesday_letter": "W",
  "days": "Days",
  "dys": "day(s)",
  "delete": "Delete",
  "delete_confirm": "Are you sure you want to delete?",
  "delete_tasks": "Delete Last Planner tasks",
  "delete_tasks_description": "I want to delete all tasks planned in the Last Planner",
  "delete_work_plan": "Delete Work Plan",
  "delivered": "Delivered",
  "detach_attach_viewer": "Detach/Attach Viewer",
  "deviation": "Deviation",
  "disabled": "Disabled",
  "disconnect": "Disconnect",
  "do_not_leave_page": "Don't leave this page",
  "done": "Done",
  "download_export_template": "Download our template for exporting from Microsoft Project",
  "download_help": "Download our help guide",
  "downstream_influence": "Downstream Influence",
  "downstream_tasks": "{{downstreamTasks}} Downstream Tasks",
  "duplicate_email": "The user already exists",
  "duplicate_project_model_file_name": "Model already exists with this file name",
  "duplicate_sprint_name": "This Work Plan name already exists",
  "duplicate_sprint_story_result_name": "There is already a reason with that name",
  "duration": "Duration",
  "duration_hours": "Duration Hours",
  "duration_must_be_integer": "Duration must be an integer",
  "duration_must_be_number": "Duration must be a number",
  "duration_required": "Duration is required",
  "due_date": "Due Date",
  "edit": "Edit",
  "edit_activity": "Edit Activity",
  "edit_equipment" : "Edit Equipment",
  "edit_material": "Edit Material",
  "edit_rfi": "Edit RFI",
  "edit_objects": "Object(s) To Install",
  "edit_subcontractor_role": "Edit Subcontractor Role",
  "edit_work_package": "Edit Work Package",
  "email": "Email",
  "email_preferences": "Email Preferences",
  "email_preferences_description": "When would you like us to notify you via email?",
  "email_preferences_homework": "Weekly when I have Activities to Plan",
  "email_preferences_model": "When uploading a Model",
  "email_preferences_scenario": "When generating a Scenario",
  "email_preferences_schedule_chnage": "When changing the active Scenario",
  "end_date": "End Date",
  "end_date_required": "End date is required",
  "end_sprint": "End Work Plan",
  "end_time": "End Time",
  "ended": "Ended",
  "equip": "Equip",
  "equipment": "Equipment",
  "equipment_and_materials": "Equipment and Materials",
  "equipment_cost": "Equipment Cost",
  "equipment_not_ready": "Equipment is not ready",
  "equipment_ready": "Equipment is ready",
  "error": "There was an Error",
  "error_getting_data": "Error getting data",
  "error_planning_permission": "Error setting up planning permissions",
  "exception_date": "Exception Date",
  "exception_days": "Exception Days",
  "expand_menu_item": "Expand",
  "export": "Export",
  "export_p6": "Export P6",
  "export_project": "Export MS Project",
  "export_csv": "Export .csv",
  "export_password": "Please enter a password to secure the exported file",
  "export_project": "Export Project",
  "export_project_description": "Export and download all project data",
  "export_your_schedule": "Export your schedule to a XML file, then choose to import as Read-Only or Editable.",
  "fabrication_days": "F&D Days",
  "file_size": "File Size",
  "file_too_large": "Files must be less than 5 MB",
  "filter": "Filter",
  "filter_by": "Filter By",
  "filter_similar_objects": "Filter Similar Objects",
  "first_name": "First Name",
  "first_person": "First Person",
  "follow_help_guide": "Follow instructions outlined in the help guide",
  "forbidden": "You do not have permission",
  "forgot_your_password": "Forgot your password?",
  "from_last_planner": "From Last Planner",
  "from_master_schedule": "From Master Schedule",
  "general_menu_item": "General",
  "generate": "Generate",
  "generate_scenario": "Generate a Scenario",
  "generated": "Generated",
  "generating": "Generating",
  "grit_score_description": "From 0 to 1000, the Grit Rating of a timeframe is its measure of granularity and consistency of task durations",
  "has_mfa": "Has MFA",
  "have_admin_add_permission": "Have your administrator add you to a project or account",
  "help_documentation": "Help Documentation",
  "help_menu_item": "Help",
  "here": "here",
  "hide_planned_work": "Hide Planned Work",
  "home_menu_item": "Home",
  "home_view": "Home View",
  "honor_commitments": "Honor Commitments",
  "send_activation_email": "Send Activation Email",
  "honor_commitments_info": "When checked, committed Work Plan tasks will be prioritized above non-committed tasks.    When not checked, committed Work Plan tasks for not active Work Plans will be treated the same as non-committed tasks.",
  "hour": "Hour",
  "hours": "Hours",
  "hours_blocked": "{{hoursBlocked}} Hours Blocked",
  "hours_completed": "Hours Completed",
  "hours_planned": "Hours Planned",
  "hrs": "hr(s)",
  "imperial": "Imperial",
  "import": "Import",
  "import_edit": "Import as Editable",
  "import_static": "Import as Read-Only",
  "import_bim": "Import from BIM 360",
  "import_csv": "Import CSV",
  "import_exported_file": "Import your exported {{fileType}} file into Grit using the import file button below",
  "import_from_microsoft_project": "Import from Microsoft Project",
  "import_from_p6": "Import from Primavera P6",
  "import_select_SubVal": "Please select Subcontractor name.",
  "import_select_SubAbb": "Please select Subcontractor abbreviation.",
  "import_select_SubErr": "Error in getting Subcontractor code.",
  "import_materials_how": "How To Import Materials",
  "import_milestones_how": "How To Import Milestones",
  "import_model": "Import a Model",
  "import_modal_desc": "Read-only will not allow for update of Start and Finish dates, while Editable will allow Grit users to create Activities, adjust Calendars, durations and relationship logic.",
  "import_project": "Import Project",
  "import_project_description": "Import a .GRIT file as a new project",
  "import_relation": "Relationships Captured.",
  "import_relation_not": "Relationships Not Captured.",
  "import_header_XML": "Import Attributes",
  "import_header_preview": "Import Preview",
  "import_setting": "XML Import Settings",
  "import_setting": "XML Import Settings",
  "insufficient_permissions": "Insufficient permissions",
  "in_progress": "In Progress",
  "influence": "Influence",
  "inherited_access": "Access to this project is inherited from its account",
  "integrations_menu_item": "Integrations",
  "invalid_abbreviation": "Invalid Abbreviation",
  "invalid_assigned_elements" : "Invalid Assigned Elements",
  "invalid_budget" : "Invalid Budget",
  "invalid_budget_quantity" : "Invalid Budget Quantity",
  "invalid_quantity_parameter": "Invalid Quantity Parameter",
  "invalid_company": "Invalid Company",
  "invalid_crew_size": "Invalid Crew Size",
  "invalid_dates": "Invalid Dates",
  "invalid_days": "Invalid Days",
  "invalid_email": "Invalid Email",
  "invalid_name": "Invalid Name",
  "invalid_project_id": "Invalid Project Id",
  "invalid_quantity_assigned" : "Invalid Quantity Assigned",
  "invalid_id": "Invalid Id",
  "invalid_step_status": "Invalid step status",
  "invalid_user_id": "Given username is missing or cannot be found",
  "invalid_verification_number": "Verification number is invalid",
  "invalid_verification_token": "The user verification token provided is either invalid or expired. Use forgot password to generate a new verification token.",
  "invalid_workpackageid": "Invalid Work Package Id",
  "inverted_dates_error": "Start date must be before end date",
  "isolate_selection": "Isolate Chart Selection",
  "iterations": "Iterations",
  "keep": "Keep",
  "key": "Key",
  "labor": "Labor",
  "labor_cost": "Labor Cost",
  "lag": "Lag",
  "lag_must_be_number": "Lag must be a number",
  "language_menu_item": "Español",
  "last_name": "Last Name",
  "last_planner_menu_item": "Last Planner",
  "lead": "Lead",
  "lead_lag": "Lead/Lag",
  "lead_must_be_number": "Lead must be a number",
  "legal": "Legal",
  "letter": "Letter",
  "libraries_menu_item": "Libraries",
  "load_data": "Load Data",
  "load_more": "Load More",
  "location_required": "Move Location is required",
  "log_in": "Log In",
  "log_out": "Log Out",
  "lookahead_menu_item": "Lookahead",
  "make_predecessors_into_prerequisites": "Make Predecessors into Prerequisites",
  "make_ready_menu_item": "Procurement",
  "make_ready_not_approved": "<b>{{userName}}</b> reversed <b>{{secondarySubjectName}}</b> approval for <b>{{taskName}}</b>",
  "make_ready_approved": "<b>{{userName}}</b> approved <b>{{secondarySubjectName}}</b> for <b>{{taskName}}</b>",
  "make_ready_ordered": "<b>{{userName}}</b> ordered <b>{{secondarySubjectName}}</b> for <b>{{taskName}}</b>",
  "make_ready_delivered": "<b>{{userName}}</b> delivered <b>{{secondarySubjectName}}</b> for <b>{{taskName}}</b>",
  "make_ready_ready": "<b>{{userName}}</b> made <b>{{secondarySubjectName}}</b> ready for <b>{{taskName}}</b>",
  "man_hours_percent_complete": "Man Hours % Complete",
  "manpower": "Manpower",
  "manpower_data_error": "No manpower data in range",
  "manpower_report": "Manpower Report",
  "map": "Map",
  "mark_all_as_read": "Mark All As Read",
  "master_schedule_menu_item": "Master Schedule",
  "mat": "Mat",
  "material": "Material",
  "material_cost": "Material Cost",
  "material_not_ready": "Material is not ready",
  "material_ready": "Material is ready",
  "materials": "Materials",
  "measure": "Measure",
  "message": "Message",
  "metric": "Metric",
  "milestone": "Milestone",
  "milestones": "Milestones",
  "mix": "Mix",
  "model": "Model",
  "model_filters": "Model Filters",
  "model_upload_limit_reached": "You have reached the limit of model uploads allowed. Please contact us to get more credits.",
  "models_menu_item": "Models",
  "month": "Month",
  "month_of": "Month of",
  "move_task_to": "Move Task To...",
  "multi_factor_authentication": "Multi-factor Authentication",
  "multi-factor_authentication": "Multi-Factor Authentication",
  "multi-factor_authentication_description": "Multi-factor authentication provides enhanced security in case your email and password are stolen",
  "multi-factor_authentication_finish_setup": "Enter the code provided by the authenticator app below to complete setup",
  "multi-factor_authentication_setup": "Scan the code below in an authenticator app (such as Google Authenticator) to get started",
  "name": "Name",
  "names": "Names",
  "name_required": "Name is required",
  "network_map_menu_item": "Network Map",
  "never": "Never",
  "new_message": "New Message",
  "new_password": "New Password",
  "new_scenario_name": "New Scenario Name",
  "next": "Next",
  "next_day_with_work": "Next Day With Work",
  "next_range": "Next Range",
  "no_activities": "No Activities",
  "no_activities_needing_planned": "No Activities Needing Planned",
  "no_activity_in_range": "No activity in this time range",
  "no_data": "No data currently available",
  "no_projects": "You don't have any active projects",
  "no_work_plans": "There are currently no Work Plans",
  "none_available": "None Available",
  "not_completed": "Not Completed",
  "not_found": "Some expected information could not be found",
  "not_integrated_with_procore": "Not integrated with Procore",
  "notifications_menu_item": "Notifications",
  "number": 'Number',
  "object": "Object",
  "object_in_prereq_work_package": "Object belongs to a prerequisite work package",
  "object_properties": "Object Properties",
  "object_unplanned": "Object is unplanned",
  "objects": "Objects",
  "offset_days": "Offset (Days)",
  "opened": "Opened",
  "ordered": "Ordered",
  "object_names": "Object Names",
  "object_number": "Num. of Objects",
  "pagination": "{{pageStart}} - {{pageEnd}} of {{total}}",
  "pan": "Pan",
  "password": "Password",
  "password_requirements": "Password must be at least eight characters and contain an uppercase letter, a lowercase letter, a number, and one special character (#,?,!,@,$,%,^,&,*,-)",
  "past_due": "Past Due",
  "path": "Path",
  "path_information": "Most critical path through the selected object",
  "peak": "Peak",
  "peak_description": "If the crew size specified is below the crew size of a step the extra crew will only be brought on when required",
  "people": "People",
  "people_menu_item": "People",
  "percent_complete": "Percent Complete",
  "percent_plan_complete": "Percent Plan Complete",
  "percentage_loss": "Percentage Loss",
  "permission": "Permission",
  "person": "Person",
  "planned_cost": "Planned Cost",
  "planned_hours": "{{plannedHours}} planned hours",
  "planned_hours_label": "Planned Hours",
  "planned_tasks": "{{plannedTasks}} planned tasks",
  "planning_lead": "Planning Lead Days",
  "planned_quantity": "Planned Quantity",
  "play": "Play",
  "predecessors": "Predecessors",
  "preferences": "Preferences",
  "prep_days": "Preperation Days",
  "prereq_required": "Prerequisite is required",
  "prerequisite": "Prerequisite",
  "prerequisites": "Prerequisites",
  "previous": "Previous",
  "previous_day_with_work": "Previous Day With Work",
  "previous_range": "Previouse Range",
  "Processes": "Processes",
  "procore_company": "Procore Company",
  "procore_due_date": "Due Date (Procore \"Due Date\" field)",
  "procore_import_companies": "Import Companies from Procore",
  "procore_import_submittals": "Import Submittals from Procore",
  "procore_import_rfis" : "Import RFI from Procore",
  "procore_manual_import": "Sync all completed work now",
  "procore_not_integrated": "Not integrated with Procore",
  "procore_only_before": "Only if the new date is before the existing Procore date",
  "procore_project": "Procore Project",
  "procore_required_date": "Required On Site Date (Procore \"Required On Site\" field)",
  "procore_select_dates": "Which dates would you like to write back?",
  "procore_submit_date": "Submit By Date (Procore \"Submit By\" field)",
  "procore_write_dates_back": "Write dates based on the active schedule to Procore?",
  "procore_daily_log": "Write daily log entries for completed stories at the given hour each day.",
  "procore_daily_hour": "Sync hour",
  "procore_daily_manpower": "Write daily manpower log of crew size and duration worked.",
  "procore_daily_equipment": "Write daily equipment log of equipment used.",
  "procore_daily_delivery": "Write daily delivery log.",
  "procore_daily_quantity": "Write daily quantity log of material used.",
  "procore_write_daily_log": "Which fields would you like to write back?",
  "procurement_menu_item": "Procurement",
  "profile_menu_item": "Profile",
  "project_menu_item": "Project",
  "project_name": "Project Name",
  "project_productivity": "Project Productivity",
  "project_progress": "Project Progress",
  "project_updates": "Project Feed",
  "projected_completion_date": "Projected Completion Date",
  "projects_menu_item": "Projects",
  "quantity": "Quantity",
  "quantity_assigned": "Quantity Assigned",
  "read_only": "Read Only",
  "ready": "Ready",
  "ready_for_review": "Ready for Review",
  "reason_required": "Reason is required",
  "reason_task_not_complete": "Reason task was not completed",
  "reasons_not_complete_menu_item": "Reasons Not Complete",
  "recommended": "Recommended",
  "refresh": "Refresh",
  "relationship": "Relationship",
  "remaining_days": "Remaining Days",
  "remember_me": "Remember me",
  "remove": "Remove",
  "replacements": "Replacements",
  "replacing_objects": "Selecting object replacements.",
  "reports_menu_item": "Reports",
  "request_help_menu_item": "Request Help",
  "required_on_site": "R.O.S",
  "reset": "Reset",
  "reset_form": "Reset Form",
  "reset_project": "Reset Project",
  "reset_project_description": "I want to delete all Schedules and Work Plans",
  "resource": "Resource",
  "restore_defaults": "Restore Defaults",
  "retry": "Retry",
  "revert_exceptions": "Revert to Master Schedule Exception Days",
  "review_days": "Review Days",
  "rfi": "RFI",
  "role": "Role",
  "rolling_budget": "Rolling Budget Over Time",
  "ros_date": "R.O.S. Date",
  "rows_selected": "({{numberOfRows}} Selected)",
  "save": "Save",
  "save_home_view": "Save Current View As Home View",
  "save_name": "Save Name",
  "saved_views": "Saved Views",
  "savings": "Savings",
  "scenario": "Scenario",
  "scenarios_menu_item": "Scenarios",
  "scheduled": "Scheduled",
  "scheduled_date" : "Scheduled Date",
  "scheduled_end": "Scheduled End",
  "scheduled_for": "Scheduled for {{date}}",
  "scheduled_start": "Scheduled Start",
  "search": "Search",
  "secret": "Secret",
  "section_analysis": "Section Analysis",
  "select_activities": "Select Activities",
  "select_activity": "Select an Activity",
  "select_activity_filtered": "Select an Activity (Filtered)",
  "select_activity_or_object_to_plan": "Select an activity or object to start planning",
  "select_date_range": "Select a Date Range",
  "select_location": "Select a location from the list",
  "select_object": "Select an Object",
  "select_object_in_model": "Select an object in the model",
  "select_object_to_plan": "Select an object in the viewer to start planning",
  "select_prereq": "Select a prerequisite",
  "select_project_to_get_started": "Select a project on the left to get started",
  "select_reason": "Select a reason from the list",
  "select_subcontractor": "Select a subcontractor from the list",
  "select_task": "Select a Task",
  "select_work_package": "Select a Work Package",
  "selection_filter": "Selection Filter",
  "selection_window_contains": "Selection Window (Contains)",
  "selection_window_intersects": "Selection Window (Intersects)",
  "server_error": "There was an issue with the server",
  "settings_menu_item": "Settings",
  "setup": "Setup",
  "short_code_expires": "This code will expire in 30 seconds",
  "show_all": "Show All",
  "show_my_work": "Show My Work",
  "show_procurement_lines": "Show Procurement Lead Lines",
  "showing_x_of_x_items": "showing {{shown}} out of {{total}}",
  "sprint": "Work Plan",
  "sprint_name_duplicate": "There is already a Work Plan with this name",
  "sprint_name_invalid": "Invalid Work Plan name",
  "sprints_menu_item": "Work Plan",
  "start_date": "Start Date",
  "start_date_required": "Start date is required",
  "start_time": "Start Time",
  "start_work_plan": "Start Work Plan",
  "started": "Started",
  "status": "Status",
  "stop": "Stop",
  "sub": "Sub",
  "sub_companies": "Sub Companies",
  "subcontractor": "Subcontractor",
  "subcontractor_roles_menu_item": "Subcontractor Roles",
  "subcontractors": "Subcontractors",
  "subject": "Subject",
  "submittals": "Submittals",
  "submit": "Submit",
  "submit_by": "Submit By",
  "submit_create_another": "Submit and Create Another",
  "submittal_info": "Submittal Info",
  "success": "Operation Succeeded",
  "successful_delete": "Data was sucessfully deleted",
  "successful_save": "Data was sucessfully saved",
  "successor": "Successor",
  "successors": "Successors",
  "supply": "Supply",
  "sheet": "Sheet",
  "tabloid": "Tabloid",
  "task": "Task",
  "task_cannot_be_editted": "Task is already committed and cannot be edited",
  "task_hours": "Task Hours",
  "task_information": "Task Information",
  "task_list": "Task List",
  "task_update_changed": "<b>{{userName}}</b> make changes to <b>{{taskName}}</b>",
  "task_update_committed": "<b>{{userName}}</b> comitted to <b>{{taskName}}</b>",
  "task_update_completed": "<b>{{userName}}</b> completed <b>{{taskName}}</b>",
  "task_update_in_progress": "<b>{{userName}}</b> moved <b>{{taskName}}</b> to in progress",
  "task_update_not_completed": "<b>{{userName}}</b> moved <b>{{taskName}}</b> to Not Completed due to <b>{{reason}}</b> and moved it into <b>{{secondarySubjectName}}</b>",
  "task_update_not_completed_backlog": "<b>{{userName}}</b> moved <b>{{taskName}}</b> to Not Completed due to <b>{{reason}}</b> and moved it into the Backlog",
  "task_update_planned": "<b>{{userName}}</b> planned <b>{{taskName}}</b>",
  "task_update_scheduled": "<b>{{userName}}</b> scheduled <b>{{taskName}}</b>",
  "tasks_blocked": "{{tasksBlocked}} Tasks Blocked",
  "tasks_completed": "Tasks Completed",
  "tasks_due": "Tasks Due",
  "tasks_in_path": "{{tasksInPath}} Tasks in Path",
  "tasks_planned": "Tasks Planned",
  "thread": "Message Thread",
  "time_zone": "Time Zone",
  "today": "Today",
  "toggle_ghosting": "Toggle Ghosting",
  "token": "Token",
  "total_cost": "Total Cost",
  "total_hours": "Total Hours",
  "total_hours_description": "Total hours of work for this subcontractor",
  "total_hours_in_path": "{{totalHoursInPath}} total hours in path",
  "trial_expires": "Trial Expires",
  "type": "Type",
  "unauthorized": "You are not logged in",
  "uncommit": "Uncommit",
  "undo": "Undo",
  "unit_cost": "Unit Cost",
  "unit_rate": "Unit Rate",
  "unit_of_measure": "Unit of Measure",
  "update_sprint": "Update Work Plan",
  "upload_file": "Upload File",
  "upload_photo": "Upload Photo",
  "uploading": "Uploading",
  "use_qr_code": "Scan the code below to log into the AR task assist environment",
  "use_short_code": "Use the short code below to log into the VR planning environment",
  "user": "User",
  "user_disabled": "This user has been disabled",
  "user_guide_menu_item": "User Guide",
  "user_information": "User Information",
  "users_menu_item": "Users",
  "version": "Version",
  "view_in_lookahead": "View in Lookahead",
  "view_in_procore": "View in Procore",
  "viewer_settings": "Viewer Settings",
  "viewer_settings_description": "Customize the                3D viewer",
  "viewer_settings_scroll": "Expand/Up (Zooms in when touchpad is expanded or when scroll-wheel is scrolled up)",
  "vr_short_menu_item": "VR Short Code",
  "week": "Week",
  "week_of": "Week of",
  "week_one": "1 week",
  "week_six": "6 weeks",
  "week_three": "3 weeks",
  "work_package": "Work Package",
  "work_packages": "Work Packages",
  "work_package_contains_comitted_task": "Work packages that contain committed task(s) cannot be edited",
  "work_plan": "Work Plan",
  "work_plan_percent_complete": "Work Plan Percent Complete",
  "working_days": "Working Days",
  "working_hours": "Working Hours",
  "schedule_change": "Schedule Change",
  "old_start_date": "Old Start Date",
  "new_start_date": "New Start Date",
  "old_scenario": "Old Scenario",
  "new_scenario": "New Scenario",
  "filter_chart": "Filter Chart",
  "selection_filtering": "Active Selection Filtering",
  "filter_viewer": "Filter Viewer",
  "and": "AND",
  "or": "OR",
  "bulk_edit": "Bulk Edit",
  "bulk_assign": "Bulk Assign",
  "this_is_bulk_edit_for": "This Is Bulk Edit For ",
  "invalid_externalActivityId": "Invalid ExternalActivity ID",
  "id": "Id",
  "in_progress": "In Progress",
  "not_assigned": "(NOT ASSIGNED)",
  "not_completed": "Not Completed",
  "quick_edit": "Quick Edit",
  "quantity_parameter": "Quantity Parameter",
  "remove_activity_from_List": "Remove Activity From List",
  "default_info": "Default Info",
  "delete_activity": "Delete Activity",
  "floor_plans_menu_item": "Floor Plans",
  "procore": "Procore",
  "bim_360": "BIM 360",
  "project_files": "Project Files",
  "floor_plans": "Floor Plans",
  "add_project_files": "Add",
  "remove_project_files": "Remove",
  "select_floor_plans_from_the_file_list_to_the_left": "Select Floor Plans from the file list to the left",
  "select_a_project_from_your_data_source_above": "Select a Project from your Data Source above",
  "data_source": "Data Source",
  "error_get_procore_floorplan":"Error getting floor plans from procore",
  "error_get_floorplan":"Error getting floor plans",
  "error_remove_floorplan":"Error in removing floor plan from project",
  "error_add_floorplan":"Error in linking floor plan to project",
  "error_orderupdate_floorplan":"Error in updating order of floor plans",
  "error_get_annotations":"Error in getting anottations",
  "error_update_annotations":"Error in updating annotations",
  "annotation_assigned":"Annotation assigned successfully",
  "annotation_cannot_assigned":"Switch to the single page mode for annotation draw",
  "error_annotation_assign":"Error in assigning annotation",
  "annotation_removed":"Activity annotations removed successfully",
  "error_remove_annotation":"Error in removing activity annotations",
  "delete_activity": "Delete Activity",
  "no_workspaces_assigned":"No Workspaces Assigned",
  "no_plan_available":"No Plan Available",
  "floorPlans_connect" : "Floor Plans are not able to be used as Models are conected to this project",
  "models_connect" : "Models are not able to be used as Floor Plans are conected to this project",
  "flow_line_chart": "Flowline Schedule",
  "xml_file": "XML File",
  "grit_version": "Grit Version",
  "export_xml": "Export XML",
  "resource_name": "Name",
  "expected_end_date": "Expected End Date",
  "expected_finish": "Expected Finish Date",
  "actual_start_date": "Actual Start Date",
  "actual_start_date_required": "Actual Start Date is required",
  "actual_finish_date": "Actual Finish Date",
  "actual_finish_date_required": "Actual Finish Date is required",
  "expected_finish_date_required": "Expected Finish Date is required"
}
