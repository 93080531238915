import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { MenuEmitterService } from '../services/menu/menu-emitter.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class LoggedInGuard {

  constructor( private userService: UserService, private router: Router ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.userService.getAuthenticatedUser().subscribe(
            response => {
                resolve(false);
            },
            err => {
                MenuEmitterService.emitShowMenuChange(false);
                resolve(true);
            }
        );
    });
  }
}
