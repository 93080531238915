import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CacheService } from './services/cache/cache.service';
import { MenuEmitterService } from './services/menu/menu-emitter.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslationService } from '../app/services/translation/translation.service';
import { Utils } from './utils/utils';

// used to get rid of typescript errors
interface Window {
  chrome: any;
}

@Component({
  selector: 'app-base-template',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class BaseTemplateComponent implements OnInit, OnDestroy {

  showMenu: boolean = false;
  showMenuChanged$: Subscription;
  browserShow: boolean; // used to set flag to send user to download support browsers to use grit properly

  routeUrlSubscription: Subscription;

  // tslint:disable-next-line:no-empty
  constructor(
    private router: Router,
    @Inject('Window') private window: Window,
    private transationService: TranslationService,
    private cacheService: CacheService
  ) {
    this.BrowserDetection();
  }

  async ngOnInit() {
    this.transationService.initalize();

    if (this.browserShow) {
      this.showMenuChanged$ = MenuEmitterService.showMenuChanged$.subscribe(showMenu => {
        this.showMenu = showMenu;
      });
    }

    await this.cacheService.initializeCache(document.location.href);
    this.routeUrlSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(async () => {
      await this.cacheService.initializeCache(document.location.href);

      if (window && window['analytics']) {
        window['analytics'].page();
      }
    });
  }

  ngOnDestroy() {
    if (this.showMenuChanged$) this.showMenuChanged$.unsubscribe();
    if (this.routeUrlSubscription) {
      this.routeUrlSubscription.unsubscribe();
    }
  }

  BrowserDetection() {
    // Opera 8.0+
    // const isOpera = (!!this.window.opr && !!opr.addons) || !!this.window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    // const isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // const isSafari = /constructor/i.test(this.window.HTMLElement) || ( p => {
    //   return p.toString() === '[object SafariRemoteNotification]';
    // })(!this.window['safari'] || safari.pushNotification);
    // Internet Explorer 6-11
    // const isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    // const isEdge = !isIE && !!this.window.StyleMedia;
    // Chrome 1+
    const isChrome = !!this.window.chrome;
    // Blink engine detection
    // const isBlink = (isChrome || isOpera) && !!this.window.CSS;

    if (isChrome) this.browserShow = true;

    // let output = 'Detecting browsers by ducktyping:\n';
    // output += 'isFirefox: ' + isFirefox + '\n';
    // output += 'isChrome: ' + isChrome + '\n';
    // output += 'isSafari: ' + isSafari + '\n';
    // output += 'isOpera: ' + isOpera + '\n';
    // output += 'isIE: ' + isIE + '\n';
    // output += 'isEdge: ' + isEdge + '\n';
    // output += 'isBlink: ' + isBlink + '\n';
    // console.log(output);
  }
}
