import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { IProgressBar } from '../../models/progress-bar/progress-bar.interface';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() progressBarInput: IProgressBar;

  // tslint:disable-next-line:no-empty
  constructor() {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  ngOnChanges() {
    if (!this.progressBarInput.fillColor) {
      this.progressBarInput.fillColor = '#e12727'; // if no color default to grit red
    }
  }
}
