
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectWorkDaysService } from '../../services/project/project-workdays/project-workdays.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Utils } from '../../utils/utils';

import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { IGritTable } from '../../shared/grit-table/grit-table';
import { EventStatus, EventType } from '../../utils/enums/notification.enum';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-project-schedule-scenarios',
  templateUrl: './project-schedule-scenarios.component.html',
  styleUrls: ['./project-schedule-scenarios.component.scss']
})
export class ProjectScheduleScenariosComponent implements OnInit, OnDestroy {
  @ViewChild('scenarioChart') scenarioChart: any;

  pageIsLoading: boolean = true;
  loadingMessage: string = 'Loading...';
  projectId: string;
  projectPermission: IUserPermission;
  showScenarioForm: boolean = false;
  errorGettingData: boolean = false;

  showDeleteConfirm: boolean = false;
  showActivateConfirm: boolean = false;
  activateId: string;
  showActivateLivingScheduleForm: boolean = false;
  newLivingScheduleName: string;
  activateConfirmModalInput: IConfirmationModalInput;
  activateConfirmModal: IConfirmationModalInput = {
    displayMessage: 'activate_schedule_no_honor_confirm',
    hasCancelAction: true,
    hasConfirmationAction: true
  };
  activateNoHonorConfirmModal: IConfirmationModalInput = {
    displayMessage: 'activate_schedule_confirm',
    hasCancelAction: true,
    hasConfirmationAction: true
  };

  schedules: any[] = [];

  tableData: IGritTable;

  masterScheduleHours;
  exceptions;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private projectScheduleService: ProjectScheduleService,
    private projectService: ProjectService,
    private projectWorkDaysService: ProjectWorkDaysService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    this.projectPermission = ProjectService.userPermission;
    this.projectService.setDisablePage(false);
    if (!this.projectService.getHideSidebar()) this.projectService.setHideSidebar(true);
    // Segment Analytics
    SegmentService.track('Scenarios: Page Loaded', { projectId: this.projectId });
    this.setupData();
    // websocket listening for schedule generation and run functions on successful or failed events
    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.SCHEDULE_GENERATED)) return;
      this.setupData();
    });
    window.addEventListener('keydown', this.keyPress);
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    if (this.projectService.getHideSidebar()) this.projectService.setHideSidebar(false);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  // get project schedules data to fill in the page
  // argument allows an id to be searched and selected if available
  setupData(): void {
    this.showScenarioForm = false;
    const observalbleArray = [];
    observalbleArray.push(this.projectScheduleService.get(this.projectId));
    observalbleArray.push(this.projectService.getProjectHours(this.projectId));
    forkJoin(observalbleArray).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.schedules = res[0];
        this.schedules.forEach(schedule => {
          if (schedule.active) schedule['status'] = 'Active';
          else if (schedule.living) schedule['status'] = 'Living';
          else if (schedule.dirty) schedule['status'] = 'Dirty';
          else schedule['status'] = 'Ready';
        });
        this.schedules = Utils.sortByNumber(this.schedules, 'timestamp', false);
        this.setTableData();
        this.masterScheduleHours = this.projectWorkDaysService.transformWorkDay(res[1]);
        this.exceptions = res[1].exceptions;
        this.pageIsLoading = false;
      },
      err => {
        const context: INoficationContext = {
          type: 'schedule setup',
          action: 'get'
        };
        this.notificationService.error(err, context);

        this.errorGettingData = true;
        this.pageIsLoading = false;
      }
    );
  }

  setTableData() {
    this.tableData = this.projectScheduleService.transformToTableData(this.schedules, this.projectPermission);
  }

  activateSchedule(force?: boolean) {
    if (!this.activateId) return;
    const selected = this.schedules.find(s => s.id === this.activateId);
    if (!force) {
      if (selected && !selected.ignoreCommittments) {
        this.activateConfirmModalInput = this.activateNoHonorConfirmModal;
        this.showActivateConfirm = true;
        return;
      } else {
        this.activateConfirmModalInput = this.activateConfirmModal;
        this.showActivateConfirm = true;
        return;
      }
    }
    this.showActivateConfirm = false;
    this.projectService.setDisablePage(true);
    this.projectScheduleService.setActive(this.projectId, this.activateId).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Scenarios: Scenario Activated', { projectId: this.projectId });
        this.projectService.setDisablePage(false);
        this.activateId = null;
        this.setupData();
      },
      (err) => {
        this.activateId = null;
        this.projectService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'active schedule',
          action: 'get'
        };
        this.notificationService.error(err, context);
      });
  }

  activateLivingSchedule(force?: boolean) {
    if (!force) {
      this.showActivateLivingScheduleForm = true;
      return;
    }
    if (!this.newLivingScheduleName) return;
    this.showActivateLivingScheduleForm = false;
    this.projectService.setDisablePage(true);
    this.projectScheduleService.setActive(this.projectId, this.activateId, this.newLivingScheduleName).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Scenarios: Living Schedule Activated', { projectId: this.projectId });
        this.projectService.setDisablePage(false);
        this.activateId = null;
        this.setupData();
      },
      (err) => {
        this.activateId = null;
        this.projectService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'active schedule',
          action: 'get'
        };
        this.notificationService.error(err, context);
      });
  }

  displayScenarioForm() {
    this.showScenarioForm = true;
  }

  closeScenarioForm() {
    this.showScenarioForm = false;
  }

  closeActivateConfirm(): void {
    this.showActivateConfirm = false;
  }

  closeActivateLivingScheduleForm(): void {
    this.newLivingScheduleName = null;
    this.showActivateLivingScheduleForm = false;
  }

  delete(rowIds: string[]): void {
    this.projectService.setDisablePage(true);
    this.projectScheduleService.delete(rowIds[0]).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Scenarios: Scenario Deleted', { projectId: this.projectId });
        this.projectService.setDisablePage(false);
        this.notificationService.eventPopup(EventStatus.Success, 'Scenario deleted');
      },
      (err) => {
        this.projectService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'schedule',
          action: 'delete'
        };
        this.notificationService.error(err, context);
        this.setupData();
      });
  }

  navigateToLookAhead(scheduleId: string) {
    SegmentService.track('Scenarios: Navigate to Lookahead', { projectId: this.projectId });
    this.router.navigateByUrl('project/' + this.projectId + '/schedule?scheduleId=' + scheduleId);
  }

  customFunction(event) {
    switch (event.edit.customVal) {
      case 'activate':
        this.activateId = event.key;
        if (this.schedules.find(schedule => schedule.id === this.activateId).living) this.activateLivingSchedule();
        else this.activateSchedule();
        break;
      case 'lookAhead':
        this.navigateToLookAhead(event.key);
        break;
    }
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.showActivateLivingScheduleForm) {
        if (this.newLivingScheduleName) {
          this.activateLivingSchedule(true);
        }
      }
    }
  }
}
