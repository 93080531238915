import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IAccordion } from '../../../models/accodordion/accordion.interface';
import { MessageType } from '../../../utils/enums/message-type.enum';
import { IObjectActivity, IPrereqActivity, IProjectStep } from '../../../models/project/project-step/project-step.interface';
import { ISelectionModal } from '../../../models/selection-modal/selection-modal.interface';
import { ISidebarTab } from '../../../models/sidebar/sidebar.interface';
import { IUser } from '../../../models/user/user.interface';
import { IWorkPackage } from '../../../models/work-package/work-package.interface';

import { NotificationService } from '../../notification/notification.service';
import { ProjectEquipmentService } from '../project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../project-material/project-material.service';
import { ProjectMessageService } from '../project-message/project-message.service';
import { ProjectObjectService } from '../project-object/project-object.service';
import { ProjectStepService } from '../project-step/project-step.service';
import { ProjectSubContractorService } from '../project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../project.service';
import { SegmentService } from '../../segment/segment.service';

import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectPlannerService {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private authenticatedUser: IUser;
  private userName: string;

  constructor(
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private segmentService: SegmentService,
    private projectStepService: ProjectStepService,
    private projectObjectService: ProjectObjectService,
    private projectMessageService: ProjectMessageService,
    private projectSubContractorService: ProjectSubContractorService,
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService
  ) {/*EMPTY*/ }

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  // Transform data for properties accordion
  transformSidebarData(data): IAccordion[] {
    const sidebarData = [];
    // tslint:disable-next-line:one-variable-per-declaration
    const objectCategories = data.map(object => object.displayCategory),
      uniqueCategories = objectCategories.filter((category, index) => {
        if (objectCategories.indexOf(category) === index) {
          const objectInfo = data.filter(object => object.displayCategory === category);
          sidebarData.push({
            label: category.replace(/_/g, ' ').trim(),
            active: false,
            info: {
              'Display Name': objectInfo[0].displayName,
              'Display Value': objectInfo[0].displayValue,
              'Type': objectInfo[0].type,
              'Precision': objectInfo[0].precision
            }
          });
        }
      });

    return sidebarData;
  }
  // END Sidebar transforms
  // Sidebar Transforms
  getSidebarTabData(): ISidebarTab[] {
    const sidebarTabs = [
      {
        name: 'filter',
        icon: 'fa-filter',
        key: 'filter',
        active: true
      },
      {
        name: 'thread',
        icon: 'fa-comments',
        key: 'messages',
        active: false
      },
      {
        name: 'object_properties',
        icon: 'fa-cube',
        key: 'properties',
        active: false
      },
    ];

    return sidebarTabs;
  }

  transformSelectionModalData(data): Promise<ISelectionModal> {
    return new Promise((resolve, reject) => {
      const selectionModalData: ISelectionModal = {
        message: 'select_activity',
        items: []
      };
      data.forEach(d => {
        const tc = this.projectService.getLocalActivity(d.code);
        const subContractor = tc.subContractorId ? this.projectSubContractorService.getLocalProjectSubcontractor(tc.subContractorId) : null;
        if (tc.children.length === 0) {
          const name = d.status ? tc.name + ' - ' + d.status : tc.name;
          selectionModalData.items.push({
            displayName: name,
            value: {
              name: tc.name,
              activity: tc.id,
              subContractor: subContractor
            },
            displayType: d.displayType
          });
        }
      });
      selectionModalData.items = Utils.sortByString(selectionModalData.items, 'displayName');
      return resolve(selectionModalData);
    });
  }

  setAuthUser(authenticatedUser: IUser, userName: string) {
    this.authenticatedUser = authenticatedUser;
    this.userName = userName;
  }

  convertObjectsMap(selectedObjects, planningActivity: string): IObjectActivity[] {
    const returnArr: IObjectActivity[] = [];
    Object.keys(selectedObjects).forEach((key) => {
      returnArr.push({ objectId: key, activity: planningActivity });
    });
    return returnArr;
  }

  converPrereqMap(selectedPrereqs): IObjectActivity[] {
    const returnArr: IObjectActivity[] = [];
    Object.keys(selectedPrereqs).forEach((objKey) => {
      Object.keys(selectedPrereqs[objKey]).forEach((actKey) => {
        returnArr.push({ objectId: objKey, activity: actKey });
      });
    });
    return returnArr;
  }

  createMessage(message): Promise<void> {
    return new Promise((resolve, reject) => {
      this.projectMessageService.createProjectMessage(message, null, MessageType.TASK).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          return resolve();
        },
        err => {
          this.notificationService.error(err, { type: 'message', action: 'create' });
          return reject();
        }
      );
    });
  }

  createUpdateStepAndMessage(step: IProjectStep): Promise<void> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      if (!Utils.isTempId(step.id)) {
        this.projectStepService.updateProjectStep(step.id, step).pipe(takeUntil(this.destroyed$)).subscribe(
          () => {
            this.segmentService.track('Task Updated', { projectId: this.projectService.currentProject.id, projectStepId: step.id });
            return resolve();
          },
          err => {
            this.notificationService.error(err, { type: 'step', action: 'create' });
            return reject();
          }
        );
      } else {
        this.projectStepService.createProjectStep(step).pipe(takeUntil(this.destroyed$)).subscribe(
          res => {
            this.segmentService.track('Task Planned', { projectId: step.projectId });
            step.id = res.id;
            if (step.notes) {
              const message = {
                projectId: this.projectService.currentProject.id,
                stepId: res.id,
                userId: this.authenticatedUser.id,
                message: step.notes,
                timestamp: Date.now(),
                userName: this.userName
              };
              this.createMessage(message).then(() => resolve()).catch(() => resolve());
            } else {
              return resolve();
            }
          },
          err => {
            this.notificationService.error(err, { type: 'step', action: 'create' });
            return reject();
          }
        );
      }
    });
  }

  async createUpdateStepList(stepList: IProjectStep[], prereqActivities, planningActivityId: string, deletedIds: string[], workPackage: IWorkPackage) {
    const prereqActivityArr = this.converPrereqActivitiesMap(prereqActivities);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < stepList.length; i++) {
      stepList[i].name = `${workPackage.name} - ${stepList[i].stepName}`;
      stepList[i].prerequisiteActivities = prereqActivityArr;
      stepList[i].workPackageId = workPackage.id;
      stepList[i].prerequisiteSteps = [];

      // we no longer need to send objects to step
      delete stepList[i].objects;
      delete stepList[i].prerequisiteObjects;
      if (i - 1 >= 0 && stepList.length > 1) stepList[i].prerequisiteSteps = [{step: stepList[i - 1].id, timing: 'FS-0 days'}];
      try {
        await this.createUpdateStepAndMessage(stepList[i]);
      } catch {
        break;
      }
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < deletedIds.length; i++) {
      try {
        await this.projectStepService.deleteProjectStep(deletedIds[i]).toPromise();
      } catch {
        break;
      }
    }
  }

  converPrereqActivitiesMap(prereqActivities): IPrereqActivity[] {
    const prereqActivitiesArray: IPrereqActivity[] = [];
    Object.keys(prereqActivities).forEach(activityId => {
      prereqActivitiesArray.push({
        activity: activityId,
        timing: null
      });
    });
    return prereqActivitiesArray;
  }

  async checkAndCreateUnmodeledObjects(selectedObjects) {
    let curTempKey;
    let createObject;
    // tslint:disable-next-line:prefer-for-of
    for (const key in selectedObjects) {
      if (selectedObjects[key]) {
        if (Utils.isTempId(key)) {
          curTempKey = key;
          createObject = { name: selectedObjects[key].name, activities: selectedObjects[key].activities };
          await this.projectObjectService.upsertUnmodeledObjects(this.projectService.currentProject.id, [createObject]).toPromise().then(async res => {
            const currentUnmodeledObjects = this.projectObjectService.getAllLocalUnmodeledObjects();
            const inserted = res.inserted[0];
            currentUnmodeledObjects[inserted.id] = inserted;
            delete selectedObjects[curTempKey];
            selectedObjects[inserted.id] = inserted;
            this.segmentService.track('Unmodeled Object Created', { 'projectId': this.projectService.currentProject.id });
          }).catch(err => {
            this.notificationService.error(err, { type: 'object', action: 'create/update' });
          });
        }
      }

    }
  }
}
