import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { IChartLegendItem } from '../../../models/chart/chart.interface';
import { IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { UnitOfMeasure } from '../../../models/project/project-material/project-material.interface';
import { ICostReportData, IMakeReadyReportData, IManpowerChartData, IManpowerData } from '../../../models/reports/project-report.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { IProjectViewMode } from '../../../models/project/project.interface';

import { ActivityService } from '../../../services/activity/activity.service';
import { ProjectSubContractorService } from '../../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../../services/project/project.service';

import { CostColor, GritColors } from '../../../utils/enums/hex-color.enum';
import { ChartIntervalState, CostReportValueTypes, MakeReadyTypes } from '../../../utils/enums/reports.enum';
import { ViewMode } from '../../../utils/enums/shared.enum';
import { Utils } from '../../../utils/utils';

import * as D3 from 'd3';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProjectFlowlineService {

  constructor(
    private fb: FormBuilder,
    private activityService: ActivityService,
    private projectService: ProjectService,
    private projectSubContractorService: ProjectSubContractorService
  ) { }
  chartIntroHeight: number = 85;

  axisData = {
    xAxisTransform: null,
    xAxisLabelTransform: null,
    minTickWidth: 25,
    yAxisTransform: null,
    yAxisLabelTransform: null,
    minTickHeight: 20,
    yTickCount: 10
  };

  // wrapper container graph and axes
  chartWrapperDimensions = {
    width: 0,
    height: 0
  };

  // wrapper containing just graph shapes, no axes
  graphDimensions = {
    width: 0,
    minWidth: 0,
    offsetLeft: 100,
    offsetRight: 30,
    offsetBottom: 100,
    minHeight: this.axisData.minTickHeight * this.axisData.yTickCount,
    height: 0,
    transform: null
  };

  chartDisplayModes: IProjectViewMode[] = [
    {
      mode: ViewMode.OneWeekSchedule,
      label: ViewMode.OneWeekSchedule,
      selected: true
    },
    {
      mode: ViewMode.ThreeWeekSchedule,
      label: ViewMode.ThreeWeekSchedule,
      selected: false
    },
    {
      mode: ViewMode.SixWeekSchedule,
      label: ViewMode.SixWeekSchedule,
      selected: false
    },
    {
      mode: ViewMode.EightWeekSchedule,
      label: ViewMode.EightWeekSchedule,
      selected: false
    }
  ];
  chartLegendItems: IChartLegendItem[] = [
    {
      title: 'equipment_cost',
      value: 0,
      color: CostColor.Equipment,
      icon: 'fas fa-wrench',
      key: CostReportValueTypes.Equipment
    },
    {
      title: 'labor_cost',
      value: 0,
      color: CostColor.Labor,
      icon: 'fas fa-user',
      key: CostReportValueTypes.Labor
    },
    {
      title: 'material_cost',
      value: 0,
      color: CostColor.Material,
      icon: 'fas fa-cubes',
      key: CostReportValueTypes.Material
    },
    {
      title: 'total_cost',
      value: 0,
      color: GritColors.dkGray,
      key: CostReportValueTypes.Total
    }
  ];

  setGraphDimensions(containerHeight: number, containerWidth: number, xAxisTickCount: number): void {
    this.graphDimensions.height = this.graphDimensions.minHeight > (containerHeight - this.graphDimensions.offsetBottom)
      ? this.graphDimensions.minHeight
      : containerHeight - this.graphDimensions.offsetBottom;

    this.graphDimensions.minWidth = this.axisData.minTickWidth * xAxisTickCount;
    this.graphDimensions.width = this.graphDimensions.minWidth > (containerWidth - this.graphDimensions.offsetLeft - this.graphDimensions.offsetRight)
      ? this.graphDimensions.minWidth
      : (containerWidth - this.graphDimensions.offsetLeft - this.graphDimensions.offsetRight);

    this.graphDimensions.transform = `translate(${this.graphDimensions.offsetLeft}, ${this.graphDimensions.offsetRight})`;
  }

  setChartWrapperDimensions(): void {
    this.chartWrapperDimensions.height = this.graphDimensions.height + this.graphDimensions.offsetBottom;
    this.chartWrapperDimensions.width = this.graphDimensions.width + this.graphDimensions.offsetLeft + this.graphDimensions.offsetRight;
  }

  setChartAxes(): void {
    this.axisData.xAxisTransform = `translate(${this.graphDimensions.offsetLeft}, ${this.graphDimensions.height + 30})`;
    this.axisData.xAxisLabelTransform = `translate(${this.chartWrapperDimensions.width / 2}, ${this.graphDimensions.height + (this.graphDimensions.offsetBottom)})`;
    this.axisData.yAxisTransform = this.graphDimensions.transform;
    this.axisData.yAxisLabelTransform = `translate(${-this.graphDimensions.height / 2}, 10)`;
  }

  getChartControls(startDate: number, endDate: number): IProjectScheduleControls {
    return {
      bulkEdit: false,
      reset: false,
      today: true,
      search: false,
      animation: false,
      timeFrames: {
        display: true,
        rangeStartDate: Utils.formatDate(startDate),
        rangeEndDate: Utils.formatDate(endDate),
        range: true,
        start: {
          title: 'scheduled_start',
          display: true
        },
        end: {
          title: 'scheduled_end',
          display: true
        }
      },
      customControls: true
    };
  }

  getChartDisplayModes(): IProjectViewMode[] {
    return [
      {
        mode: ViewMode.OneMonthGantt,
        label: ViewMode.OneMonthGantt,
        selected: true
      },
      {
        mode: ViewMode.ThreeMonthGantt,
        label: ViewMode.ThreeMonthGantt,
        selected: false
      },
      {
        mode: ViewMode.SixMonthGantt,
        label: ViewMode.SixMonthGantt,
        selected: false
      },
      {
        mode: ViewMode.TwelveMonthGantt,
        label: ViewMode.TwelveMonthGantt,
        selected: false
      }
    ];
  }

  transformSubsForDropdown(subcontractors: IProjectSubContractor[]): any[] {
    const transformedSubs = [];
    subcontractors.forEach(item => {
      item['selected'] = true;
      transformedSubs.push(item);
    });

    return transformedSubs;
  }

  // adds keys so D3 can rollup data based on x-xaxis time intervals
  transformCostReportData(data: any): ICostReportData[] {
    const transformedData = [];
    data.forEach(item => {
      item[ChartIntervalState.Day] = moment.utc(item.date).clone().startOf('day').valueOf();
      item[ChartIntervalState.Week] = moment.utc(item.date).clone().startOf('week').valueOf();
      item[ChartIntervalState.Month] = moment.utc(item.date).clone().startOf('month').valueOf();

      transformedData.push(item);
    });

    return transformedData;
  }

  getCostReportRollupData(data: ICostReportData[], rollUpKey: ChartIntervalState): ICostReportData[] {
    let aggEqiupmentTotal = 0;
    let aggLaborTotal = 0;
    let aggMaterialTotal = 0;
    let aggTotal = 0;
    return D3.nest()
      .key(d => d[rollUpKey])
      .rollup(days => {
        return {
          equipmentCost: D3.sum(days, (d) => d.equipmentCost),
          laborCost: D3.sum(days, (d) => d.laborCost),
          materialCost: D3.sum(days, (d) => d.materialCost),
          totalCost: D3.sum(days, (d) => d.totalCost)
        };
      })
      .entries(data)
      .map(group => {
        return {
          dayStart: Number(group.key),
          equipmentCost: group.value.equipmentCost,
          laborCost: group.value.laborCost,
          materialCost: group.value.materialCost,
          totalCost: group.value.totalCost,
          aggregateEquipmentCost: aggEqiupmentTotal += group.value.equipmentCost,
          aggregateLaborCost: aggLaborTotal += group.value.laborCost,
          aggregateMaterialCost: aggMaterialTotal += group.value.materialCost,
          aggregateTotalCost: aggTotal += group.value.totalCost
        };
      });
  }

  formatCostReportDataForChart(displayMode: ViewMode, data: ICostReportData[]): ICostReportData[] {
    switch (displayMode) {
      case ViewMode.ThreeMonthGantt :
        return this.getCostReportRollupData(data, ChartIntervalState.Week);
      case ViewMode.SixMonthGantt :
      case ViewMode.TwelveMonthGantt :
        return this.getCostReportRollupData(data, ChartIntervalState.Month);
      default :
        return this.getCostReportRollupData(data, ChartIntervalState.Day);
    }
  }

  // adds keys so D3 can rollup data based on x-xaxis time intervals
  transformManpowerReportData(data: any): IManpowerData[] { // TODO Type manpower data
    const transformedData = [];
    data.forEach(item => {
      item.projectScheduleSteps.forEach(step => {
        transformedData.push({
          dayStart: moment.utc(item.date).clone().startOf('day').valueOf(),
          weekStart: moment.utc(item.date).clone().startOf('week').valueOf(),
          monthStart: moment.utc(item.date).clone().startOf('month').valueOf(),
          subContractorId: step.subContractorId,
          crewSize: step.crewSize,
        });
      });
    });

    return transformedData;
  }

  getManpowerRollupData(mpData: IManpowerData[], rollUpKey: string) { // TODO Type manpower data
    const sortedByDayThenSub = {};
    D3.nest()
      .key(d => d[rollUpKey])
      .rollup(days => days)
      .entries(mpData)
      .forEach(group => {
          const rollupData = this.rollupSubData(group.value, Number(group.key));
          if (sortedByDayThenSub[group.key]) {
            sortedByDayThenSub[group.key].push(rollupData);
            sortedByDayThenSub[group.key]['maxCrewSize'] = Math.max(...Object.keys(rollupData).map(sub => rollupData[sub].crewSize));
          } else {
            sortedByDayThenSub[group.key] = this.rollupSubData(group.value, Number(group.key));
            sortedByDayThenSub[group.key]['maxCrewSize'] = Math.max(...Object.keys(rollupData).map(sub => rollupData[sub].crewSize));
          }
        }
      );

    return sortedByDayThenSub;
  }

  rollupSubData(data, xAxisKey: number): IManpowerChartData {
    const sortedBySub = {};
    D3.nest()
      .key(d => d.subContractorId)
      .rollup(items => ({ crewSize: D3.sum(items, (d) => d.crewSize)}))
      .entries(data)
      .map(group => {
        sortedBySub[group.key] = {
          xAxisKey: xAxisKey,
          subInfo: {...this.projectSubContractorService.getLocalProjectSubcontractor(group.key), subcontractorId: group.key},
          crewSize: group.value.crewSize
        };
      });

    return sortedBySub;
  }

  formatManpowerDataForChart(displayMode: ViewMode, mpData: IManpowerData[]): any {
    switch (displayMode) {
      case ViewMode.ThreeMonthGantt :
      case ViewMode.SixMonthGantt :
        return this.getManpowerRollupData(mpData, 'weekStart');
      case ViewMode.TwelveMonthGantt :
        return this.getManpowerRollupData(mpData, 'monthStart');
      default :
        return this.getManpowerRollupData(mpData, 'dayStart');
    }
  }

  getMaterialEquipmentDatesForm(): FormGroup {
    const validateDates = (from: string, to: string) => {
      return (group: FormGroup): {[key: string]: any} => {
        const startUtc = group.controls[from].value ? Utils.toUtcDate(group.controls[from].value) : null;
        const endUtc = group.controls[to].value ? Utils.toUtcDate(group.controls[to].value) : null;
        const invertedDates = (startUtc && endUtc) && (startUtc > endUtc);

        if (invertedDates) return { invalidDates: true };
        else return {};
      };
    };

    const formGroup = this.fb.group({
      selectedStartDate: [null, Validators.required],
      selectedEndDate: [null, Validators.required]
    });

    formGroup.setValidators([
      validateDates('selectedStartDate', 'selectedEndDate'),
    ]);

    return formGroup;
  }

  getScheduleChangeForm(): FormGroup {
    const validateDates = (from: string, to: string) => {
      return (group: FormGroup): {[key: string]: any} => {
        const startUtc = group.controls[from].value ? Utils.toUtcDate(group.controls[from].value) : null;
        const endUtc = group.controls[to].value ? Utils.toUtcDate(group.controls[to].value) : null;
        const invertedDates = (startUtc && endUtc) && (startUtc > endUtc);

        if (invertedDates) return { invalidDates: true };
        else return {};
      };
    };

    const formGroup = this.fb.group({
      selectedStartDate: [Utils.fromUtcDate(Math.floor(new Date().getTime() / 86400000) * 86400000)],
      selectedEndDate: [Utils.fromUtcDate((Math.floor(new Date().getTime() / 86400000) + 30) * 86400000)],
      subcontractor: [null],
      oldScheduleId: [null, Validators.required],
      newScheduleId: [null, Validators.required]
    });

    formGroup.setValidators([
      validateDates('selectedStartDate', 'selectedEndDate'),
    ]);

    return formGroup;
  }

  flattenMakeReadyData(data: any[]): IMakeReadyReportData[] {
    const allTheDatas = [];
    data.forEach(item => {
      if (item.materials) {
        item.materials.forEach(mat => {
          if (mat.id) {
            const materialCost = this.getMaterialCost(mat, item);
            const materialItem = Object.assign({
              type: MakeReadyTypes.Material,
              activity: this.activityService.getLocalActivity(item.activities[0]).name,
              task: item.taskName,
              subcontractor: this.projectService.getLocalSubcontractor(item.subcontractor.id).name,
              scheduledStart: Utils.formatDate(item.scheduledStart),
              scheduledEnd: Utils.formatDate(item.scheduledEnd),
              duration: item.duration,
              crewSize: item.crewSize,
              cost: materialCost ? materialCost : ' ',
              quantity: mat.unitOfMeasure ? this.getQuantity(mat.unitOfMeasure, item) : ' ',
              resource: mat.name
            });
            allTheDatas.push(materialItem);
          }
        });
      }

      if (item.equipments) {
        item.equipments.forEach(equipment => {
          if (equipment.id) {
            const equipmentItem = Object.assign({
              type: MakeReadyTypes.Equipment,
              activity: this.activityService.getLocalActivity(item.activities[0]).name,
              task: item.taskName,
              subcontractor: this.projectService.getLocalSubcontractor(item.subcontractor.id).name,
              scheduledStart: Utils.formatDate(item.scheduledStart),
              scheduledEnd: Utils.formatDate(item.scheduledEnd),
              duration: item.duration,
              crewSize: item.crewSize,
              cost: ' ', // will be added later
              quantity: ' ',
              resource: equipment.name
            });
            allTheDatas.push(equipmentItem);
          }
        });
      }
    });

    return allTheDatas;
  }

  flattenScheduleChangeData(data: any[]): any[] {
    const allTheDatas = [];
    data.forEach(item => {
      const days = Math.round((item.newStartDate - item.oldStartDate) / 86400000);
      if (days !== 0) {
        allTheDatas.push({
          name: item.name,
          days: !item.oldStartDate ? 'Added' : !item.newStartDate ? 'Removed' : days < 0 ? '' + days : '+' + days,
          oldStartDate: item.oldStartDate ? Utils.formatDate(item.oldStartDate) : '',
          newStartDate: item.newStartDate ? Utils.formatDate(item.newStartDate) : '',
          oldStartDateValue: item.oldStartDate || 1e18,
          newStartDateValue: item.newStartDate || 1e18,
          subcontractor: this.projectService.getLocalSubcontractor(item.subContractorId).name
        });
      }
    });

    return allTheDatas;
  }

  transformToScheduleChangeTableData(reportData: any[]): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'task',
        colKey: 'task',
        type: RowItemType.Text,
        width: '35%'
      },
      {
        displayName: 'deviation',
        colKey: 'deviation',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'old_start_date',
        colKey: 'oldStartDate',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'new_start_date',
        colKey: 'newStartDate',
        type: RowItemType.Text,
        width: '10%'
      },
      {
        displayName: 'subcontractor',
        colKey: 'subcontractor',
        type: RowItemType.Text,
        width: '35%'
      }
    ];

    const rows: IGRow[] = [];
    reportData.forEach((task, index) => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'task',
          value: task.name
        },
        {
          colKey: 'deviation',
          value: task.days
        },
        {
          colKey: 'oldStartDate',
          value: task.oldStartDate,
          sortValue: task.oldStartDateValue
        },
        {
          colKey: 'newStartDate',
          value: task.newStartDate,
          sortValue: task.newStartDateValue
        },
        {
          colKey: 'subcontractor',
          value: task.subcontractor
        }
      ];

      rows.push(
        {
          key: index.toString(),
          rowItems: rowItems,
          selectable: false,
          editable: false
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false
      },
      deleteOptions: {
        show: false
      }
    };

    return retTable;
  }

  transformToTableData(reportData: IMakeReadyReportData[]): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'type',
        colKey: 'type',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'activity',
        colKey: 'activity',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'task',
        colKey: 'task',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'start_date',
        colKey: 'scheduledStart',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'end_date',
        colKey: 'scheduledEnd',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'duration',
        colKey: 'duration',
        type: RowItemType.Number,
        width: '9%'
      },
      {
        displayName: 'crew_size',
        colKey: 'crewSize',
        type: RowItemType.Number,
        width: '9%'
      },
      {
        displayName: 'subcontractor',
        colKey: 'subcontractor',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'resource',
        colKey: 'resource',
        type: RowItemType.Text,
        width: '9%'
      },
      {
        displayName: 'quantity',
        colKey: 'quantity',
        type: RowItemType.Number,
        width: '9%'
      },
      {
        displayName: 'cost',
        colKey: 'cost',
        type: RowItemType.Currency,
        width: '8%'
      }
    ];

    const rows: IGRow[] = [];
    reportData.forEach((task, index) => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'type',
          value: task.type
        },
        {
          colKey: 'activity',
          value: task.activity
        },
        {
          colKey: 'task',
          value: task.task
        },
        {
          colKey: 'scheduledStart',
          value: task.scheduledStart
        },
        {
          colKey: 'scheduledEnd',
          value: task.scheduledEnd
        },
        {
          colKey: 'duration',
          value: task.duration
        },
        {
          colKey: 'crewSize',
          value: task.crewSize
        },
        {
          colKey: 'subcontractor',
          value: task.subcontractor
        },
        {
          colKey: 'resource',
          value: task.resource ? task.resource : ' '
        },
        {
          colKey: 'quantity',
          value: task.quantity
        },
        {
          colKey: 'cost',
          value: task.cost
        }
      ];

      rows.push(
        {
          key: index.toString(),
          rowItems: rowItems,
          selectable: false,
          editable: false
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false
      },
      deleteOptions: {
        show: false
      }
    };

    return retTable;
  }

  getMaterialCost(materialData: any, task: any): any {
    let rawUnits;
    let cost;
    switch (materialData.unitOfMeasure) {
      case UnitOfMeasure.Volume :
        rawUnits = Utils.formatUnitOfMeasure(task.objectVolume, UnitOfMeasure.Volume, this.projectService.currentProject.isMetric, true); // so cost and quanity columns are rounded the same
        cost = Number(rawUnits) * materialData.cost;
        return Utils.formatCurrency(cost, this.projectService.currencyCode);
      case UnitOfMeasure.SurfaceArea :
        rawUnits = Utils.formatUnitOfMeasure(task.objectArea, UnitOfMeasure.SurfaceArea, this.projectService.currentProject.isMetric, true);
        cost = Number(rawUnits) * materialData.cost;
        return Utils.formatCurrency(cost, this.projectService.currencyCode);
      case UnitOfMeasure.Length :
        rawUnits = Utils.formatUnitOfMeasure(task.objectLength, UnitOfMeasure.Length, this.projectService.currentProject.isMetric, true);
        cost = Number(rawUnits) * materialData.cost;
        return Utils.formatCurrency(cost, this.projectService.currencyCode);
      case UnitOfMeasure.Each :
        return Utils.formatCurrency((task.objectCount * materialData.cost), this.projectService.currencyCode);
      default :
        return ' '; // space so .csv export populate empty cell
    }
  }

  getQuantity(unitType: UnitOfMeasure, task: any): string {
    switch (unitType) {
      case UnitOfMeasure.Volume :
        return Utils.formatUnitOfMeasure(task.objectVolume, UnitOfMeasure.Volume, this.projectService.currentProject.isMetric, false);
      case UnitOfMeasure.SurfaceArea :
        return Utils.formatUnitOfMeasure(task.objectArea, UnitOfMeasure.SurfaceArea, this.projectService.currentProject.isMetric, false);
      case UnitOfMeasure.Length :
        return Utils.formatUnitOfMeasure(task.objectLength, UnitOfMeasure.Length, this.projectService.currentProject.isMetric, false);
      case UnitOfMeasure.Each :
        return task.objectCount.toString();
      default :
        return ' '; // space so .csv export populate empty cell
    }
  }
}
