import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Output() searchQueryOutput: EventEmitter<string | void> = new EventEmitter();

  searchQuery: string;

  constructor() { }

  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  }

  applySearch(): void {
    this.searchQueryOutput.emit(this.searchQuery);
  }

  clearSearch(): void {
    this.searchQuery = null;
    this.searchQueryOutput.emit();
  }

}
