import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-component',
  templateUrl: './loading-component.component.html',
  styleUrls: ['./loading-component.component.scss']
})
export class LoadingComponentComponent {
  @Input() loadingTextInput: string;

  staticLoadingText: string = 'Data Loading';

  constructor() {/*EMPTY*/}
}
