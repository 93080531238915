import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Utils } from '../../utils/utils';

import { IActivity } from '../../models/activity/activity.interface';

import { ITableSearchOptions } from '../../models/custom-table/custom-table.interface';
import { IColumnHeader, ICustomTable, IRow, IRowItem } from '../../models/custom-table/custom-table.interface';
import { HttpBackendService } from '../http-backend/http-backend.service';
import { ProjectService } from '../project/project.service';

export const DEFAULT_TIMING = 'FS-0 days';

@Injectable()
export class ActivityService {
  public tablePageSize: number = 20;

  objectsForTable: any[] = [];
  totalTableObjects: number;

  constructor(private httpService: HttpBackendService, private projectService: ProjectService) { }

  public getActivity(activityId: string): Observable<IActivity> {
    return this.httpService.get(`/project/activity/${activityId}`);
  }

  public getLocalActivity(activityId: string): IActivity {
    return this.projectService.getLocalActivity(activityId);
  }

  public getActivityList(pageSize: number, pageStart: number): IActivity[] {
    const activities = this.projectService.getLocalAllActivities();
    Utils.sortByString(activities, 'name');
    return activities.slice(pageStart, pageSize + pageStart);
  }

  public getSpecificActivities(activities: string[]): IActivity[] {
    return activities.map(activity => this.projectService.getLocalActivity(activity));
  }

  public lookupActivity(query: string, pageSize: number, pageStart: number): IActivity[] {
    const activities = this.projectService.getLocalAllActivities().filter(tc => tc.name.toLowerCase().includes(query.toLowerCase()));
    Utils.sortByString(activities, 'name');
    return activities.slice(pageStart, pageSize + pageStart);
  }

  public getAllActivities(projectId: string) {
    return this.httpService.get('/project/' + projectId + '/activities');
  }

  public getPredecessorDetails(activityId): Observable<any> {
    return this.httpService.get('/project/activity/' + activityId);
  }

  public getUsedActivities(projectId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/trades');
  }

  public downloadXML(projectId: string, fileName: string, fileType: string, scheduleId: string, sortOptions: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/activities/download?fileName=' + fileName + '&fileType=' + fileType + '&scheduleId=' + scheduleId + '&sortOptions=' + sortOptions);
  }

  public addMasterActivity(projectId: any): Observable<any> {
    return this.httpService.post('/project/' + projectId + '/activities/masterImport', {});
  }

  public addActivity(json: any): Observable<any> {
    return this.httpService.post('/project/activity', json);
  }

  public updateActivity(id: string, json: any): Observable<any> {
    return this.httpService.put('/project/activity/' + id, json);
  }

  public deleteActivity(projectId: string, activityIds: string[]): Observable<any> {
    return this.httpService.post('/project/activity/delete', {'projectId': projectId, 'activityIds': activityIds});
  }

  public autoAssignActivities(projectId: string,  json: any):  Observable<any> {
    return this.httpService.post('/project/' + projectId + '/activities/autoassign', {'projectId': projectId, json});
  }

  public importCSV(projectId: string, file: any, stat: boolean , configuration:  {}) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('config', JSON.stringify(configuration));
    return this.httpService.formData('/project/' + projectId + '/activities/import?static=' + stat, formData);
  }

  public getAffectStepIds(projectId: string, projectObjectIds: string[], activityIds: string[]): Observable<any> {
    return this.httpService.post('/project/activity/affectedSteps', { 'projectId': projectId, 'projectObjectIds': projectObjectIds, 'activityIds': activityIds });
  }

  public assignObjects(projectId: string, projectObjectIds: string[], activityIds: string[]): Observable<any> {
    return new Observable(observer => {
      this.httpService.post('/project/activity/assignObjects', { 'projectId': projectId, 'projectObjectIds': projectObjectIds, 'activityIds': activityIds }).subscribe(
        res => {
          return observer.next(res);
        },
        err => {
          return observer.error(err);
        }
      );
    });
  }

  public setMilestone(projectId: string, activityId: string, milestoneId: string): Observable<any> {
    return this.httpService.put('/project/activity/' + activityId + '/milestone', { 'projectId': projectId, 'milestoneId': milestoneId });
  }

  public setSubContractor(projectId: string, activityId: string, subContractorId: string): Observable<any> {
    return this.httpService.put('/project/activity/' + activityId + '/subContractor', { 'projectId': projectId, 'subContractorId': subContractorId });
  }

  public setEquipment(projectId: string, activityId: string, equipmentIds: string[], updatedEquipments?: any[]): Observable<any> {
    return this.httpService.put('/project/activity/' + activityId + '/equipment', { projectId: projectId, equipmentIds: equipmentIds, updatedEquipments: updatedEquipments});
  }

  public setMaterials(projectId: string, activityId: string, materialIds: string[], updatedMaterials?: any[]): Observable<any> {
    return this.httpService.put('/project/activity/' + activityId + '/materials', { projectId: projectId, materialIds: materialIds, updatedMaterials: updatedMaterials});
  }

  public setRfi(projectId: string, activityId: string, rfiIds: string[]): Observable<any> {
    return this.httpService.put('/project/activity/' + activityId + '/rfi', { projectId: projectId, rfiIds: rfiIds});
  }

  public setLabor(projectId: string, activityId: string, laborIds: string[], updatedLabors?: any[]): Observable<any> {
    return this.httpService.put('/project/activity/' + activityId + '/labors', { projectId: projectId, laborIds: laborIds, updatedLabors: updatedLabors});
  }

  public getActivityLabors(activityId: string): Observable<any> {
    return this.httpService.get('/project/activity/' + activityId + '/labors');
  }

  public getActivitiesLabors(activityIds: Array<string>): Observable<any> {
    const ids = activityIds.join('&id=');
    return this.httpService.get('/project/activity' + '/labors/activityIds?id=' + ids);
  }

  public getActivityMaterials(activityId: string): Observable<any> {
    return this.httpService.get('/project/activity/' + activityId + '/materials');
  }

  public getActivitiesMaterials(activityIds: Array<string>): Observable<any> {
    const ids = activityIds.join('&id=');
    return this.httpService.get('/project/activity' + '/materials/activityIds?id=' + ids);
  }

  
  public getActivityEquipments(activityId: string): Observable<any> {
    return this.httpService.get('/project/activity/' + activityId + '/equipments');
  }

  public getActivitiesEquipments(activityIds: Array<string>): Observable<any> {
    const ids = activityIds.join('&id=');
    return this.httpService.get('/project/activity' + '/equipments/activityIds?id=' + ids);
  }

  public getPossiblyValidLevelPrereqs(allActivities: IActivity[], curActivityId: string): string[] {
    let possiblyValid: IActivity[] = [];
    const activity = allActivities.find(act => act.id === curActivityId);
    if (!activity) return [];
    const activities = allActivities.filter(act => !activity.prerequisites.map(p => p.activity).includes(act.id) && act.id !== curActivityId);
    if (activity.parentId) {
      possiblyValid = activities.filter(act => act.parentId === activity.parentId);
    } else {
      possiblyValid = activities.filter(act => !act.parentId);
    }
    const validIds: string[] = [];
    const mapCheck = {};
    for (const a of possiblyValid) {
      if (this.checkCircular(a.id, a.prerequisites.map(p => p.activity), curActivityId, allActivities, mapCheck)) validIds.push(a.id);
    }
    return validIds;
  }

  checkCircular(curId, prereqIds, id, allActivities, mapCheck) {
    if (mapCheck[curId] !== undefined) return mapCheck[curId];
    if (prereqIds.includes(id)) return mapCheck[curId] = false;
    for (const pId of prereqIds) {
      const findRes: IActivity = allActivities.find(act => act.id === pId);
      if (!this.checkCircular(curId, findRes.prerequisites.map(p => p.activity), id, allActivities, mapCheck)) return false;
    }
    return mapCheck[curId] = true;
  }

  public transformActivityTypesToTableData(projectActivityTypes: any[], selected: any[], totalCount?: number, paginationStart?: number): ICustomTable {
    const objectIds = selected.map(obj => obj.id);
    const selectedIds = selected.map(item => item.id);
    const overallCount = projectActivityTypes.length;
    const pageStart = paginationStart ? paginationStart : 0;

    // Table
    const transformedTableData:  ICustomTable = {
      title: 'ActivityType',
      filterable: false,
      searchable: true,
      searchOptions: [],
      columnHeaders: [],
      rows: [],
      multiSelect: true,
      multiSearch: false,
      canCreate: true,
      createRowModel: [],
      pagination: {
        count: overallCount,
        pageSize: this.tablePageSize,
        pageStart: pageStart,
        totalItemsSelected: 0,
        display: true
      },
      showIntro: true
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'Name',
        key: 'name',
        sortable: true,
        active: false,
        searchable: true
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Define search options for multi-search
    const searchOptions: ITableSearchOptions[] = [];
    transformedTableData.columnHeaders.filter((colHead, index) => {
      if (colHead.searchable === true) {
        searchOptions.push({
          title: colHead.title,
          active: false
        });
      }
    });
    transformedTableData.searchOptions = searchOptions;

    let totalOtherItemsSelected = selected.length;

    // Table Rows
    const tableRows: IRow[] = [];

    // Create item row
    const createRowModel = [
      {
        name: 'name',
        value: '',
        prevStateValue: '',
        editable: true,
        type: 'input'
      },
    ];
    transformedTableData.createRowModel = createRowModel;

    // Table Rows
    for (let r = pageStart; r < pageStart + this.tablePageSize; r++) {
      if (r < projectActivityTypes.length) {
        const rowItems: IRowItem[] = [
          {
            name: 'name',
            value: projectActivityTypes[r].name,
            prevStateValue: projectActivityTypes[r].name,
            editable: true,
            type: 'input'
          },
        ];

        if (selectedIds.includes(projectActivityTypes[r].id)) {
          totalOtherItemsSelected--;
        }

        tableRows.push({
          selectable: true,
          editable: true,
          removeable: true,
          active: objectIds.includes(projectActivityTypes[r].id),
          rowItems: rowItems,
          key: projectActivityTypes[r].id,
          isEditing: false
        });
      }
    }

    transformedTableData.rows = tableRows;
    transformedTableData.pagination.totalItemsSelected = totalOtherItemsSelected;

    return transformedTableData;
  }
}
