
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectSprintBoardService } from '../../../services/project/project-sprint-board/project-sprint-board.service';
import { ProjectSprintService } from '../../../services/project/project-sprint/project-sprint.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';

import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { INoficationContext, ActivityColorMap } from '../../../models/notification/notification.interface';
import { IProjectScheduleControls } from '../../../models/project/project-schedule/project-schedule.interface';
import { ISprintBoardItemResponse, ITransformedSprintBoardData, ITransformedSprintBoardItem } from '../../../models/project/project-sprint/project-sprint-board.interface';
import { ICompleteOutput, INotCompleteOutput, IProjectViewModes, IStoryEquipment, IStoryMaterial, IInProgressOutput } from '../../../models/project/project-sprint/project-sprint.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { ForgeViewerType } from '../../../utils/enums/forge-viewer-type';
import { EventType } from '../../../utils/enums/notification.enum';
import { ViewMode, ViewerMode } from '../../../utils/enums/shared.enum';
import { SprintStoryResult } from '../../../utils/enums/sprint-story-result.enum';
import { StoryStatus } from '../../../utils/enums/story-status.enum';
import { Utils } from '../../../utils/utils';
import { FormGroup } from '@angular/forms';
import { PDFViewerService } from '../../../services/pdf/pdfViewer.service';

@Component({
  selector: 'app-project-sprint-commitment',
  templateUrl: './project-sprint-commitment.component.html',
  styleUrls: ['./project-sprint-commitment.component.scss']
})
export class ProjectSprintCommitmentComponent implements OnChanges, OnDestroy, OnInit {
  @Input() subContractorList: any[];
  @Input() sprintId: string;
  @Input() planningMode: boolean;
  @Input() changeName: { name: string, projectStepId: string };
  @Input() activeScheduleNameInput: string;
  @Input() ssResults;

  @Output() displayModeOutput: EventEmitter<IProjectViewModes> = new EventEmitter();
  @Output() objectsSelected: EventEmitter<any> = new EventEmitter();
  @Output() sprintClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() commitTasksOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() uncommitTasksOutput: EventEmitter<string[]> = new EventEmitter();

  transformedSprintBoardItems: ITransformedSprintBoardData;
  storyStatusEnum = StoryStatus;
  storyResultEnum = SprintStoryResult;
  showItems: boolean = false;
  taskIsSelected: boolean = false;
  subcontractorFilter: string[] = new Array();
  projectId: string;
  makingBackendCall: boolean = false;

  showSubmittalModal: boolean = false;
  showCompleteStoryModal: boolean = false;
  showNotCompleteStoryModal: boolean = false;
  showInProgressStoryModal: boolean = false;
  modalType: string = '';
  dateStarted: any = '';
  inProgressTaskId: string = null;
  showApprovedModal: boolean = false;

  completeStoryModalInput: IConfirmationModalInput = {
    displayMessage: 'Please enter additional task information:',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true
  };
  inProgressModalObj: IInProgressOutput = {startDate : null, expectedFinishDate: null, duration: 0};

  completedTaskId: string = null;
  actualDuration = null;
  actualCrewSize = null;
  actualEquipmentIds: string[];
  actualMaterialIds: string[];
  actualLaborIds: string[] = [];
  otherReason = null;
  reasonNotCompleted: number = null;
  moveSprintNotCompleted: string = null;
  moreBacklog: boolean = true;
  completeSprintMode: boolean = false;
  curObjectIds: string[] = [];
  subContractorsDropdownListInput: IDropdownItem[] = new Array();
  sprintName: string;
  moveToOptions = [];
  placeholderSubcontractors = 'Task board filter';
  myViewerState = {};
  controls: IProjectScheduleControls;
  submittalSelection: { taskName: string, submittals: Array<{ material: string, url: string }> };
  selectedTaskInfoToUpdateStatus: any;
  activityColorIdMap : ActivityColorMap[] = [];
  allActivitites : any;

  // Subscriptions
  boardDataSubscription: Subscription;
  dragulaSubscription: Subscription;

  //View Related Properties
  viewListWithIds = [];
  addViewForm: FormGroup;
  viewList = [];
  currentlySelectedView: string = "default";
  currentlySelectedViewID: string = null;
  toggleAddView: boolean = false;
  isViewEdit: boolean = false;
  filteredWorkflowIndicesArray: Array<{flow: number, view: string}> = [];
  editViewToggler: string;
  counterOccuranceOfFlowForView: Object = { default: 0 };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  itemsOptions: { moves: any, accepts: any } = {
    moves: (el, container, handle) => {
      this.clonedWorkFlowArray = JSON.parse(JSON.stringify(this.workflowArray));
      return true;
    },
    accepts: (el, target, source, sibling) => target.className.match(/sprint-lane/g) ? false : true
  };

  clonedWorkFlowArray: Array<any>;
  dragulaDropModelSubscription: any;
  finallyPlacedRealItem: Array<any>;
  pickedWI: number;

  constructor(
    private projectSprintBoardService: ProjectSprintBoardService,
    private notificationService: NotificationService,
    private dragularService: DragulaService,
    private segmentService: SegmentService,
    private projectService: ProjectService,
    private forgeViewerService: ForgeViewerService,
    private projectMaterialService: ProjectMaterialService,
    public projectSprintService: ProjectSprintService,
    public pdfViewerService: PDFViewerService
  ) {
    this.dragulaSubscription = dragularService.drop.subscribe((movedItem) => {
      this.dragulaDropModelSubscription = dragularService.dropModel.subscribe((movedItemAfterDrop) => {
        this.unsubscribeDropModel();

        if (movedItem && movedItem[2] && movedItem[2].className.match(/committed-row/g) && (movedItem[3].className.match(/sprint-lane/g) || movedItem[3].className.match(/unassigned-area/g)) &&  movedItem[4]) {
          //means item dragged from within the backlog OR Unassigned area and dropped within workflowgrod
          let movedFromBackLog = true;
          this.drop(movedItem, movedItem[1].id, movedItem[4].id, movedFromBackLog);

        } else if (movedItem && movedItem[2] && movedItem[2].className.match(/unassigned-area/g) && (movedItem[3].className.match(/sprint-lane/g))) {
          //means item dragged from within the backlog and dropped to unassigned
          this.addNewSprintStory(movedItem);
        } else if (movedItem && movedItem[2] && movedItem[2].className.match(/unassigned-area/g) && movedItem[3].className.match(/committed-row/g)) {
          //means item dragged from within the workflowGrid and dropped to unassigned
          this.handleFeedToUnassigned(movedItem);
        } else if (movedItem && movedItem[2] && movedItem[2].className.match(/committed-row/g) && movedItem[3].className.match(/committed-row/g)) {
          //means item dragged from within the workflowGrid and dropped within workflowgrid
          if (movedItem[4]) {
            this.drop(movedItem, movedItem[1].id, movedItem[4].id);
          } else {
            this.revertBackToPreviousStableState();
          }
        } else {
          this.revertBackToPreviousStableState();
        }
      });
    });

    this.dragulaSubscription = dragularService.cancel.subscribe((element, container, source) => {
      if (element[1].id) {
        this.revertBackToPreviousStableState();
      }

      if (this.workflowArray[this.pickedWI] && this.workflowArray[this.pickedWI].items && this.pickedWI) {
        this.workflowArray[this.pickedWI].items.forEach(item => {
          if (item.new) item.new = "new";
        });
      }

      this.pickedWI = null;

      return true;
    });

    const bag3: any = this.dragularService.find('ITEMS');
    if (bag3 !== undefined) this.dragularService.destroy('ITEMS');
    this.dragularService.setOptions("ITEMS", {
      moves: (el: HTMLElement, source, handle, siblings) => {
        this.pickedWI = null;

        if(el.className.includes('realitemnotexists')){
          return false;
        }

        if(el.className.includes('load-more-btn')){
          return false;
        }
       //Non Dragable when clicked on Change status dropdown icon.
       if(event.target && event.target['className'].includes('fa-sort-down')) return false;

       if (!(el.className.includes('unassigned') || el.className.includes('backlog'))) {
        let pickedToItemIndex = Number(el.id.split('_')[1]);
        let pickedFromItemsWorkflowIndex = Number(el.id.split('_')[0]);
        let adjustedSpan = this.workflowArray[pickedFromItemsWorkflowIndex].items[pickedToItemIndex].adjustedSpan;
        let aggregatedDurationHours = this.workflowArray[pickedFromItemsWorkflowIndex].items[pickedToItemIndex].aggregatedDurationHours;
        let colspan = adjustedSpan || aggregatedDurationHours;

        this.pickedWI = Number(el.id.split('_')[0]);
        this.insertElementEventHandler(colspan, pickedFromItemsWorkflowIndex, pickedToItemIndex);
      }

        return true;
      },
      accepts: (el, target: HTMLElement, handle, siblings) => {
        if(target.className.includes('no-drop')) return false;

        let hoveredItemINdex = Number(el.id.split('_')[1]);
        let hoveredWorkflowIndex = Number(el.id.split('_')[0]);

        this.removeNewlyAddedProp();

        el.style.width = "0px";
        el.setAttribute("colspan",0);

        return true;
      },
      direction: 'horizontal'
    });

    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!this.notificationService.validEvent(event, this.projectId, EventType.STORY_UPDATED)
        || event.body.sprintId && event.body.sprintId !== this.sprintId) return;

      this.projectSprintBoardService.sprintBoardData = this.projectSprintBoardService.sprintBoardData
        .filter(d => !event.body.stories.find(s => s.projectStepId === d.stepId));
      this.projectSprintBoardService.loadSprintBoardData(event.body.stories, this.subContractorList);
      this.refreshBoard();
    });
  }

  removeNewlyAddedProp(){
    if (this.workflowArray[this.pickedWI] && this.workflowArray[this.pickedWI].items && this.pickedWI >= 0) {
      this.workflowArray[this.pickedWI].items.forEach(item => {
        if (item.new) item.new = "new";
      });
    }

    this.pickedWI = null;
  }

  insertElementEventHandler(colspan, pickedFromItemsWorkflowIndex, pickedFromItemIndex) {
    for (let i = 1; i <= colspan; i++) {
      let insertObject = {new: "newlyadded"};

      this.workflowArray[pickedFromItemsWorkflowIndex].items.splice(pickedFromItemIndex + i, 0, insertObject);
    }

    return true;
  }

  async ngOnInit() {
    this.projectSprintService.setSprintDislayMode(ViewMode.SprintCommitment);
    this.initializeAddView();
  }

  initializeAddView() {
    this.viewList = [];
    this.viewList.push('default');
    this.currentlySelectedView = 'default';
    this.disablePage(true);
    this.projectService.getViews(this.projectId).subscribe(res => {
      this.disablePage(false);
      res.forEach(view => {
        this.viewList.push(view.name);
        this.viewListWithIds.push(view);
        this.maintainWorkflowIndices();
      });
    }, err => {
      this.disablePage(false);
      console.error(err);
    });
    this.addViewForm = this.projectSprintBoardService.buildViewFormInput();
  }

  onClickOnView(view) {
    if (this.currentlySelectedView === view) {
      this.editView();
      return;
    }

    this.editViewToggler = null;
    this.currentlySelectedView = view;
    this.assignCurrentlySelectedViewId();
    this.toggleAddView = false;
    this.isViewEdit = false;
    this.maintainWorkflowIndices();
  }

  cancelEdit() {
    this.editViewToggler = null;
    this.toggleAddView = false;
    this.isViewEdit = false;
  }

  maintainWorkflowIndices() {
    this.counterOccuranceOfFlowForView = { default: 0 };
    this.workflowArray.forEach((data, i) => {
      this.checkView(i);
    });

    this.filteredWorkflowIndicesArray = [];
    let filteredWorkflow: any = [];

    this.workflowArray.forEach(workflow => {
      if (workflow.view && workflow.view !== 'default') {
        let viewObj = this.viewListWithIds.find(v => v.name === workflow.view);
        workflow.viewID = viewObj && viewObj.id ? viewObj.id : null;
      }
    });

    if (this.currentlySelectedView !== 'default') {
      let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView);
      // filteredWorkflow = this.workflowArray.filter(w => !w.view || w.view === this.currentlySelectedView);
      // filteredWorkflow = this.workflowArray.filter(w => !w.viewID || (foundView.id &&  w.viewID === foundView.id)); 
      this.workflowArray.forEach(workflow => {
        if (workflow.view && workflow.view !== 'default') {
          let viewObj = this.viewListWithIds.find(v => v.name === workflow.view);
          workflow.viewID = viewObj && viewObj.id ? viewObj.id : null;
        }
      });

      filteredWorkflow = this.workflowArray.filter(w => (!w.viewID && w.view !== 'default') || (foundView.id && w.viewID === foundView.id && w.view !== 'default'));

      filteredWorkflow.forEach((workflow, i) => {
        if (workflow.view !== 'default') {
          this.filteredWorkflowIndicesArray.push({
            flow: i + 1,
            view: workflow.view
          });
        } else {
          this.filteredWorkflowIndicesArray.push({
            flow: i + 1,
            view: 'default'
          });
        }
      });
    } else {
      this.workflowArray.forEach((workflow, i) => {
        if (workflow.view) {
          if (this.counterOccuranceOfFlowForView[workflow.view]) {
            this.counterOccuranceOfFlowForView[workflow.view] = this.counterOccuranceOfFlowForView[workflow.view] + 1;
          } else {
            this.counterOccuranceOfFlowForView[workflow.view] = 1;
          }
          this.filteredWorkflowIndicesArray.push({
            flow: this.counterOccuranceOfFlowForView[workflow.view],
            view: workflow.view
          });
        } else {
          this.counterOccuranceOfFlowForView['default'] = this.counterOccuranceOfFlowForView['default'] + 1;
          this.filteredWorkflowIndicesArray.push({
            flow: this.counterOccuranceOfFlowForView['default'],
            view: 'default'
          });
        }
      });
    }
  }

  onSubmitAddView(formData) {
    const { name } = formData.value;
    if (Utils.isEmpty(name)) {
      return;
    }

    const found = this.viewList.find(view => view === name);

    if (found) {
      return;
    }

    this.toggleAddView = false;
    this.addViewForm.reset();

    //Call api to post new view onto the db
    const reqData = { name };

    if (!this.isViewEdit) {
      this.projectService.addViews(this.projectId, reqData)
        .subscribe(viewResult => {
          this.viewListWithIds.push(viewResult);
          this.viewList.push(viewResult.name);
        }, err => {
          console.log('some error occured');
        });
    } else {
      this.editViewToggler = null;
      const viewItem = this.viewListWithIds.find(view => view.name === this.currentlySelectedView);

      if (viewItem && viewItem.id) {
        this.projectService.updateView(this.projectId, viewItem.id, reqData)
          .subscribe(res => {
            this.isViewEdit = false;
            const index = this.viewList.findIndex(view => view === this.currentlySelectedView);
            this.viewList[index] = reqData.name;
            this.viewListWithIds[index - 1].name = reqData.name;
            this.currentlySelectedView = reqData.name;
            this.maintainWorkflowIndices();
          }, err => {
            console.log('some error occured');
          });
      }
    }

    this.addViewForm.reset();
  }

  addView() {
    this.editViewToggler = null;
    this.toggleAddView = !this.toggleAddView;
    // this.isViewEdit = true;
    this.isViewEdit = false;
    this.addViewForm.controls.name.setValue('');
  }

  editView() {
    if (this.currentlySelectedView !== 'default') {
      // this.toggleAddView = !this.toggleAddView;
      this.toggleAddView = false;
      this.editViewToggler = this.currentlySelectedView;
      this.isViewEdit = true;
      this.addViewForm.controls.name.setValue(this.currentlySelectedView);
    }
  }

  deleteView() {
    if (this.currentlySelectedView !== 'default') {
      this.toggleAddView = false;
      this.isViewEdit = false;
      this.editViewToggler = null;
      let viewObj = this.viewListWithIds.find(v => v.name === this.currentlySelectedView);
      if (!viewObj || !viewObj.id) {
        return;
      }
      this.disablePage(true);
      this.projectService.deleteView(this.projectId, viewObj.id).subscribe(res => {
        this.viewList = this.viewList.filter(v => v !== this.currentlySelectedView);
        this.viewListWithIds = this.viewListWithIds.filter(v => v.name !== this.currentlySelectedView);
        this.currentlySelectedView = 'default';
        this.currentlySelectedViewID = null;
        this.handleWorkflowAfterViewDeletion(viewObj.id, viewObj.name);
        this.disablePage(false);
      }, err => {
        console.log(err);
      })
    }
  }

  handleWorkflowAfterViewDeletion(viewId, view): void {
    this.workflowArray.filter(w => w.viewID === viewId).map(w => w.viewID = null);
    this.maintainWorkflowIndices();
  }

  onCancelAddView() {
    this.addViewForm.reset();
  }

  ngOnDestroy() {
    if (this.dragulaSubscription) {
      this.dragulaSubscription.unsubscribe();
    }

    if(this.dragulaDropModelSubscription) {
      this.dragulaDropModelSubscription.unsubscribe();
    }

    if (this.boardDataSubscription) {
      this.boardDataSubscription.unsubscribe();
    }

    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  ngOnChanges() {
    console.log("NG ON CHANGES");

    this.workflowArray = [];

    if (!Utils.isEmpty(this.changeName)) {
      return;
    }

    const currSprint = this.projectSprintService.getSprint(this.sprintId);
    this.makeSprintDateRangeArray(currSprint)
    this.projectId = this.projectService.currentProject.id;

    this.buildControls();

    // Reset sprint board data
    this.projectSprintBoardService.sprintBoardData = new Array();
    this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData([]);
    this.transformedSprintBoardItems.committed.items
    this.getBoardData();
    this.setCompleteSprintMode();
    this.moveToOptions = this.projectSprintService.transformSprintList(this.projectSprintService.getOpenSprints());
    this.moveToOptions.push({ id: '0000', name: 'Backlog' });
    this.sprintName = this.projectSprintService.getSprintName(this.sprintId);
    this.transformedSprintBoardItems.committed.items
  }

  async buildControls() {
    this.controls = {
      bulkEdit: false,
      reset: false,
      today: false,
      animation: false,
      search: false,
      timeFrames: { display: false },
      customControls: true
    };
  }

  setCompleteSprintMode() {
    if (this.projectSprintService.isSprintEnded(this.sprintId)) {
      this.segmentService.track('Sprint loaded that has been ended, but not closed', { sprintId: this.sprintId });
      this.completeSprintMode = true;
      this.projectSprintBoardService.completeSprintMode = true;
    } else {
      this.completeSprintMode = false;
      this.projectSprintBoardService.completeSprintMode = false;
    }
  }

  getBoardData() {
    this.disablePage(true);
    const observableArray: Array<Observable<any>> = [];
    observableArray.push(this.projectSprintService.getSprintBacklog(this.projectId, 0));
    observableArray.push(this.projectSprintService.getSprintBacklog(this.projectId, 1));
    this.projectSprintService.nextBacklogPage();
    this.projectSprintService.nextBacklogPage();

    // If we have an open sprint, get the sprint stories too
    if (!Utils.isEmpty(this.sprintId)) {
      /*-- TODO - get stories in sprint component and share the data with the sprint chart (currently getting stories in both child components) --*/
      observableArray.push(this.projectSprintService.getAllSprintStories(this.sprintId));
    }

    this.boardDataSubscription = forkJoin(observableArray).subscribe(
      res => {
        let sprintBoardData = res[0].response;
        this.projectSprintBoardService.loadSprintBoardData(sprintBoardData, this.subContractorList, true);
        if (!Utils.isEmpty(res[1]) && !Utils.isEmpty(res[1].response)) {
          sprintBoardData = res[1].response;
          this.projectSprintBoardService.loadSprintBoardData(sprintBoardData, this.subContractorList);

          if (res[1].count < res[1].pageSize) this.moreBacklog = false;

        } else {
          this.moreBacklog = false;
        }

        if (!Utils.isEmpty(res[2])) {
          this.projectSprintBoardService.loadSprintBoardData(res[2], this.subContractorList);
        }

        this.setBoardItems();

        this.checkSprintIsDeletable();

        this.disablePage(false);
      },
      error => {
        const context: INoficationContext = {
          type: 'sprint information',
          action: 'get'
        };

        this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData([]);
        this.transformedSprintBoardItems.committed.items
        this.notificationService.error(error, context);
        this.disablePage(false);
      }
    );
  }

  setWorkflowData() {
    this.activities = [];
    this.unassignedActivities = [];
    this.organiseActivities(this.transformedSprintBoardItems.committed.items);
    this.organiseActivities(this.transformedSprintBoardItems.completed.items);
    this.organiseActivities(this.transformedSprintBoardItems.inProgress.items);
    this.organiseActivities(this.transformedSprintBoardItems.notCompleted.items);
    for (let i = 0; i < this.transformedSprintBoardItems.backlog.items.length; i++) {
      this.makeAggregatedDurationHours(this.transformedSprintBoardItems.backlog.items[i]);
    }
    this.cloneOfBacklogActivities = JSON.parse(JSON.stringify(this.transformedSprintBoardItems.backlog.items));
    this.cloneOfUnAssignedActivities = JSON.parse(JSON.stringify(this.unassignedActivities));
  }

  organiseActivities(arrayOfItems) {
    for (let i = 0; i < arrayOfItems.length; i++) {
      this.makeAggregatedDurationHours(arrayOfItems[i]);
      if (arrayOfItems[i].hasOwnProperty('workflow') && arrayOfItems[i].hasOwnProperty('hour') && arrayOfItems[i].hour > 0) {
        this.activities.push(arrayOfItems[i]);
      } else {
        this.unassignedActivities.push(arrayOfItems[i]);
      }
    }
  }

  makeAggregatedDurationHours(item) {
    if (item.durationHours) {
      const days = item.durationHours / 8;
      item.aggregatedDurationHours = Math.ceil(item.durationHours);

      if (Math.floor(item.durationHours % 8) > 0) {
        item.intDurationDays = days.toFixed(1);
      } else {
        item.intDurationDays = days;
      }
    }
  }

  filterByView(story) {

    if(story.storyStatus === StoryStatus.Backlog) {
      return true;
    } else if(this.currentlySelectedView === 'default') {
      return true;
      // if(!story.view ||  story.view === 'default') {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      let foundView = this.viewListWithIds.find(v => v.name === this.currentlySelectedView );
      if(story.viewID && foundView && story.viewID === foundView.id) {
        return true
      } else {
        return false;
      }
    }
  }

  setBoardItems(): void {
    const filteredData = this.projectSprintBoardService.sprintBoardData.filter(story => (!this.taskFilteredOut(story) && this.filterByView(story)));

    this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData(filteredData);

    this.setWorkflowData();
    this.setMockActivities();
    if (this.transformedSprintBoardItems) {
      this.resetViewer();
      this.objectsSelected.emit([]);
    }
    //Maintain a filtered workflow indices:
    this.maintainWorkflowIndices();
  }

  filterBoardItems(): void {
    const filteredData = this.projectSprintBoardService.sprintBoardData.filter(story => (!this.taskFilteredOut(story) && this.filterByView(story)));

    this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData(filteredData);
    const hoverActivityIds = [];
    const ActivityColorMap = [];
    //To show the annotation (on default) for all activities except backlog.
    const sprintBoardExcpetBacklogs = JSON.parse(JSON.stringify(this.transformedSprintBoardItems));
    delete sprintBoardExcpetBacklogs.backlog;
    Object.keys(sprintBoardExcpetBacklogs).forEach(key => {
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        let items = sprintBoardExcpetBacklogs[key].items;
        items.forEach(sprintBoardItem => {
          hoverActivityIds.push(...sprintBoardItem.activities);

          // sprintBoardItem.forEach( step => {
          const actIds = sprintBoardItem.activities ? sprintBoardItem.activities : null;
          if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

          if (sprintBoardItem.subContractor) {
            const subId = sprintBoardItem.subContractor;
            const color = sprintBoardItem.subContractor.hexCode ? sprintBoardItem.subContractor.hexCode : "gray";
            ActivityColorMap.push({ activityId: actIds[0], color: color });
          }
        });

        // Remove duplicate activity ids
        this.activityColorIdMap = ActivityColorMap.filter((activityIDMap, index) => {
          return index === ActivityColorMap.findIndex(obj => {
            return JSON.stringify(obj) === JSON.stringify(activityIDMap);
          });
        });

        this.pdfViewerService.selectActivities(this.activityColorIdMap);
      }
    });
    this.workflowArray = [];
    this.unassignedActivities = [];
    this.cloneAllNewStates();
    this.setWorkflowData();
    this.setMockActivities();
    if (this.transformedSprintBoardItems) {
      this.resetViewer();
      this.objectsSelected.emit([]);
    }
    //Maintain a filtered workflow indices:
    this.maintainWorkflowIndices();
  }

  taskFilteredOut(story: ISprintBoardItemResponse): boolean {
    const activeFilters = this.projectSprintService.getActiveFilters();
    const activeFilterType = this.projectSprintService.getActiveFilterType();

    if (activeFilters.length > 0) {
      switch (activeFilterType) {
        case FilterModelOptions.Activities:
          return story.activities.length > 0 && Utils.similarValuesInArrays(story.activities, activeFilters).length < 1;

        case FilterModelOptions.SubContractor: {
          //If no subcontractor is associated to the Activity then filter out that activity too.
          if (!(story.subContractor && story.subContractor.subContractorId)) return true;
          return story.subContractor && story.subContractor.subContractorId && !activeFilters.includes(story.subContractor.subContractorId);
        }

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  handleBoardItemClick(event, sprintBoardItem: ITransformedSprintBoardItem, handleType: string): void {
    switch (handleType) {
      case "backlog":
        this.handleBacklogItemClick(sprintBoardItem);
        break;

      case "workflow":
        this.handleWorkflowItemClick(sprintBoardItem);
        break;

      case "unassigned":
        this.handleUnassignedItemClick(sprintBoardItem)
        break;

      default:
        break;
    }
  }

  resetBacklogItemActive(sprintBoardItem?){
    if(sprintBoardItem){
      this.transformedSprintBoardItems.backlog.items.filter(item => item.taskId !== sprintBoardItem.taskId).map(item=> item.active = false );
      this.cloneOfBacklogActivities.filter(item => item.taskId !== sprintBoardItem.taskId).map(item=> item.active = false )
    } else{
      this.transformedSprintBoardItems.backlog.items.map(item=> item.active = false );
      this.cloneOfBacklogActivities.map(item=> item.active = false )
    }
  }

  handleBacklogItemClick(sprintBoardItem) {
    if (this.workflowArray[this.pickedWI] && this.workflowArray[this.pickedWI].items && this.pickedWI >= 0)
      this.workflowArray[this.pickedWI].items.forEach(item => {
        if (item.new) item.new = "new";
    });

    this.pickedWI = null;
    //1. Reset the active status of all other backlogs items except the selected peice
    this.resetBacklogItemActive(sprintBoardItem);
    let index = this.transformedSprintBoardItems[sprintBoardItem.type.toLowerCase()].items.findIndex(item => item.taskId === sprintBoardItem.taskId);
    let type = this.getSprintBoardItemType(sprintBoardItem.type);
    this.transformedSprintBoardItems.backlog.items[index].active = !this.transformedSprintBoardItems.backlog.items[index].active;
    if(this.cloneOfBacklogActivities[index])
      this.cloneOfBacklogActivities[index].active = !this.cloneOfBacklogActivities[index].active;
    this.resetWorkFlowActiveStatus();

    if (sprintBoardItem.active) {
      this.handlePredecessorsAndSuccesssorHighlightion(sprintBoardItem);
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        // Rushikesh
        const hoverActivityIds = [];
        const ActivityColorMap = [];

        hoverActivityIds.push(...sprintBoardItem.activities);
        const actIds = sprintBoardItem.activities ? sprintBoardItem.activities : null;
          if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

          if (sprintBoardItem.subContractor) {
            var subId = sprintBoardItem.subContractor;
            var color = sprintBoardItem.subContractor.hexCode ? sprintBoardItem.subContractor.hexCode : "gray";
            ActivityColorMap.push({activityId: actIds[0], color: color});
          }
          this.objectsSelected.emit([sprintBoardItem]);
          this.pdfViewerService.selectActivities(ActivityColorMap);

      }
      else {
        this.objectsSelected.emit([sprintBoardItem]);

        if (!sprintBoardItem.objectIds) return;
        sprintBoardItem.objectIds.forEach(objId => {
          this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
        });
      }
    } else {
      this.setOrResetAllPredecessorsAndSuccessors([], [], true);
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        // Rushikesh
        this.objectsSelected.emit([]);
        const ActivityColorMap = [];
        this.pdfViewerService.selectActivities(ActivityColorMap);
      }
      else {
        this.objectsSelected.emit([]);
        if (!sprintBoardItem.objectIds) return;
        sprintBoardItem.objectIds.forEach(objId => {
          this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
        });
      }
    }
    //On Single click should allow the whole lane to be checked thats why the below code:
    this.transformedSprintBoardItems.backlog.active = false;
    this.resetUnassignedItemStatus();
  }

  handleWorkflowItemClick(sprintBoardItem){
    if (this.workflowArray[this.pickedWI] && this.workflowArray[this.pickedWI].items && this.pickedWI >= 0) {
      this.workflowArray[this.pickedWI].items.forEach(item => {
        if (item.new) item.new = "new";
      });
    }

    this.pickedWI = null;
    this.revertBackToPreviousStableState();
    this.workflowArray.map(w=> w.items.filter(item=> item.taskId !== sprintBoardItem.taskId).map(item => {
     if(item.active) item.active = false;
    }));
    this.resetUnassignedItemStatus();
    this.resetBacklogItemActive();
    let type =  this.getSprintBoardItemType(sprintBoardItem.type);
    let workflowIndex = sprintBoardItem.workflow - 1;
    let index = this.workflowArray[workflowIndex].items.findIndex(item => item.taskId === sprintBoardItem.taskId);

    if(index > -1){
      this.workflowArray[workflowIndex].items[index].active = !this.workflowArray[workflowIndex].items[index].active;
      this.clonedWorkFlowArray = JSON.parse(JSON.stringify(this.workflowArray));
    }

    if(this.workflowArray[workflowIndex].items[index].active){
      this.handlePredecessorsAndSuccesssorHighlightion(sprintBoardItem);
      this.workflowArray[workflowIndex].items[index].isDim = false;
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        // Rushikesh
        const hoverActivityIds = [];
        const ActivityColorMap = [];

        hoverActivityIds.push(...sprintBoardItem.activities);
        const actIds = sprintBoardItem.activities ? sprintBoardItem.activities : null;
          if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

          if (sprintBoardItem.subContractor) {
            var subId = sprintBoardItem.subContractor;
            var color = sprintBoardItem.subContractor.hexCode ? sprintBoardItem.subContractor.hexCode : "gray";
            ActivityColorMap.push({activityId: actIds[0], color: color});
          }
          this.objectsSelected.emit([sprintBoardItem]);
          this.pdfViewerService.selectActivities(ActivityColorMap);
      }
      else {
        this.objectsSelected.emit([sprintBoardItem]);
        if (!sprintBoardItem.objectIds) return;
        sprintBoardItem.objectIds.forEach(objId => {
          this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
        });
      }
    } else {
      this.setOrResetAllPredecessorsAndSuccessors([], [], true);
      this.workflowArray[workflowIndex].items[index].isDim = false;
      if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
        // Rushikesh

        const ActivityColorMap = [];
        this.objectsSelected.emit([]);
        this.pdfViewerService.selectActivities(ActivityColorMap);
      }
      else {
        this.objectsSelected.emit([]);

        if (!sprintBoardItem.objectIds) return;
        sprintBoardItem.objectIds.forEach(objId => {
          this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
        });
      }
    }
  }

  resetWorkFlowActiveStatus() {
    this.workflowArray.map(w => w.items.map(i => {
      if (i.active) i.active = false;
    }));
  }

  getSprintBoardItemType(type): string {
    switch (type) {
      case "Backlog":
        return "backlog"
      case "Committed":
        return "committed"
      case "In Progress":
        return "inProgress"
      case "Completed":
        return "completed"
      case "Approved":
        return "approved"
    }
    return type;
  }

  handleUnassignedItemClick(sprintBoardItem) {
    this.resetWorkFlowActiveStatus();
    this.resetBacklogItemActive();
    this.unassignedActivities.filter(unassignedActivity=> unassignedActivity.taskId !== sprintBoardItem.taskId).map(a => a.active = false);

    let i = this.unassignedActivities.findIndex(a => a.taskId === sprintBoardItem.taskId)
    if (i > -1) {
      this.unassignedActivities[i].active = !this.unassignedActivities[i].active;
      this.cloneOfUnAssignedActivities = JSON.parse(JSON.stringify(this.unassignedActivities));
    }
    const type = this.getSprintBoardItemType(sprintBoardItem.type);
    if (this.unassignedActivities[i].active) {
      this.handlePredecessorsAndSuccesssorHighlightion(sprintBoardItem);
      this.objectsSelected.emit([sprintBoardItem]);
      if (!sprintBoardItem.objectIds) return;
      sprintBoardItem.objectIds.forEach(objId => {
        this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
      });
    } else {
      this.setOrResetAllPredecessorsAndSuccessors([], [], true);
      this.objectsSelected.emit([]);
      if (!sprintBoardItem.objectIds) return;
      sprintBoardItem.objectIds.forEach(objId => {
        this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
      });
    }
    this.transformedSprintBoardItems[type].active = false;
  }

  handlePredecessorsAndSuccesssorHighlightion(sprintBoardItem: ITransformedSprintBoardItem): void {
    const activityId = sprintBoardItem.activities[0];
    const activityObj = this.projectService.getLocalActivity(activityId);
    this.allActivitites = this.projectService.getLocalAllActivities();

    let prerequisiteIds: string[] = [];
    activityObj.prerequisites.forEach(element => { prerequisiteIds.push(element.activity) });
    let predecessors = prerequisiteIds;
    let sucessors = [];

    this.allActivitites.forEach(activity => {
      const prereqIds = activity.prerequisites.map(p => p.activity);
      if (prereqIds.includes(sprintBoardItem.activities[0])) {
        sucessors.push(activity.id);
      }
    });

    this.setOrResetAllPredecessorsAndSuccessors(predecessors, sucessors);
    sprintBoardItem.isDim = false;
  }

  setOrResetAllPredecessorsAndSuccessors(predecessors: Array<string>, sucessors: Array<string>, resetDimming?: boolean, exceptBacklogLane?: boolean): void {
    this.transformedSprintBoardItems.backlog.items.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;
      i.isDim = exceptBacklogLane ? false : i.isDim;

      if (predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });

    this.workflowArray.forEach(element => {
      element.items.forEach(i => {
        i.isPrerequisite = false;
        i.isSuccessor = false;
        i.isDim = resetDimming ? false : true;

        if (i.activities && i.activities[0] && predecessors.includes(i.activities[0])) {
          i.isPrerequisite = true;
          i.isDim = false;
        }

        if (i.activities && i.activities[0] && sucessors.includes(i.activities[0])) {
          i.isSuccessor = true;
          i.isDim = false;
        }
      });
    });

    this.unassignedActivities.forEach(i => {
      i.isPrerequisite = false;
      i.isSuccessor = false;
      i.isDim = resetDimming ? false : true;

      if (i.activities && i.activities[0] && predecessors.includes(i.activities[0])) {
        i.isPrerequisite = true;
        i.isDim = false;
      }

      if (i.activities && i.activities[0] && sucessors.includes(i.activities[0])) {
        i.isSuccessor = true;
        i.isDim = false;
      }
    });
  }

  resetUnassignedItemStatus() {
    this.unassignedActivities.forEach(unassignedActivity => {
      unassignedActivity.active = false;
    });
    this.cloneOfUnAssignedActivities = JSON.parse(JSON.stringify(this.unassignedActivities));
  }

  handleHover(event: string, sprintBoardItem: ITransformedSprintBoardItem): void {
    // For hover, we are setting active/not active by its own mechanism
    let determineBlockers = false;
    if (event === 'mouseenter') {
      determineBlockers = true;
      if (!sprintBoardItem.objectIds) return;
      sprintBoardItem.objectIds.forEach(objId => {
        this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Hover });
      });
    } else {
      if (!sprintBoardItem.objectIds) return;
      sprintBoardItem.objectIds.forEach(objId => {
        this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
      });
      // Go through an find all active items to set objects to active <-NOT OPTIMAL but fine for now
      this.resetActiveItemsToSelected();
    }

    this.transformedSprintBoardItems.backlog.items.map(item => item.blocker = false);
    if (determineBlockers) this.determineBlockers(sprintBoardItem);
  }

  setItemsToActive(items): void {
    items.forEach(item => {
      if (!item.objectIds) return;
      item.objectIds.forEach(objId => {
        this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
      });
    });
  }

  resetActiveItemsToSelected(): void {
    let activeItems = this.transformedSprintBoardItems.backlog.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.committed.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.completed.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.inProgress.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
    activeItems = this.transformedSprintBoardItems.notCompleted.items.filter(item => item.active);
    if (activeItems) this.setItemsToActive(activeItems);
  }

  setItemSelectionState(sprintBoardItem: ITransformedSprintBoardItem): void {
    this.taskIsSelected = true;
    const type = this.getSprintBoardItemType(sprintBoardItem.type);
    this.resetBoard(type);
    this.cloneOfBacklogActivities = JSON.parse(JSON.stringify(this.transformedSprintBoardItems.backlog.items));
    this.determineBlockers(sprintBoardItem);
  }

  handleLaneSelection(lane: any): void {
    if (lane.items.length > 0) {
      this.setLaneSelectionState(lane);

      if (lane.active) {
        this.setOrResetAllPredecessorsAndSuccessors([],[], false, true);
          if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
            // Rushikesh
            const hoverActivityIds = [];
            const ActivityColorMap = [];
            const type = this.getSprintBoardItemType(lane.items[0].type);

            this.transformedSprintBoardItems[type].items.forEach(item => {
              hoverActivityIds.push(...item.activities);
              const actIds = item.activities ? item.activities : null;
              if (actIds) actIds.forEach(id => hoverActivityIds.push(id));

              if (item.subContractor) {
                var subId = item.subContractor;
                var color = item.subContractor.hexCode ? item.subContractor.hexCode : "gray";
                ActivityColorMap.push({activityId: actIds[0], color: color});
              }
            })
            this.objectsSelected.emit(this.transformedSprintBoardItems[type].items);
            this.pdfViewerService.selectActivities(ActivityColorMap);

          }
          else {
            const type = this.getSprintBoardItemType(lane.items[0].type);
            let objectIds = this.transformedSprintBoardItems[type].items.map(item => item.objectIds);
            objectIds = [].concat.apply([], objectIds);
            objectIds.forEach(objId => {
              this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Select });
            });

            this.objectsSelected.emit(this.transformedSprintBoardItems[type].items);
          }
      } else {
        this.setOrResetAllPredecessorsAndSuccessors([],[], true);
        if (this.projectService.viewerMode === ViewerMode.PDFViewer) {
          // Rushikesh

          const ActivityColorMap = [];
          this.objectsSelected.emit([]);
          this.pdfViewerService.selectActivities(ActivityColorMap);
        }
        else {
          this.resetBoard();
          const type = this.getSprintBoardItemType(lane.items[0].type);
          let objectIds = this.transformedSprintBoardItems[type].items.map(item => item.objectIds);
          objectIds = [].concat.apply([], objectIds);
          objectIds.forEach(objId => {
            this.forgeViewerService.setState({ gritObjectId: objId, type: ForgeViewerType.Default });
          });

          this.objectsSelected.emit([]);
        }
      }
    } else {
      this.objectsSelected.emit([]);
      this.resetAll();
    }
  }

  setLaneSelectionState(lane: any): void {
    this.resetBoard((lane.items[0].type).toLowerCase());

    lane.active = !lane.active;
    const laneState = lane.active;
    lane.items.map(item => item.active = laneState);
  }

  setTaskSelection(taskSelected: boolean): void {
    this.taskIsSelected = taskSelected;
  }

  toggleExpandBoardItem(item, e): void {
    e.stopPropagation();
    item.expanded = !item.expanded;
  }

  async updateStep(taskId: string, newStoryStatus: number, oldStoryStatus: number) {
    this.assignCurrentlySelectedViewId();
    this.cloneAllNewStates();

    // Moving an item out of the backlog
    if (oldStoryStatus === StoryStatus.Backlog) {
      // Then we are creating a story
      // The new location doesn't matter - it will snap to the commit. They can manually move it from there.
      this.addSprintStory(taskId, this.sprintId)
        .then(res => {
          if (newStoryStatus !== StoryStatus.Committed) {
            this.notificationService.info('APPINVALIDTASKMOVE', {});
          }
          // check if this exist as a blocker for other steps and make them activeddd
          const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
          boardItem['view'] = this.currentlySelectedView;
          boardItem['viewID'] = this.currentlySelectedViewID;

          this.projectSprintBoardService.updateBoardItemStatus(boardItem, newStoryStatus);
          this.projectSprintBoardService.removeWorkFlowrelatedDetails(this.sprintId, boardItem)
            .subscribe(res => {
              this.projectSprintService.editSprintStoryBack(this.sprintId, boardItem).subscribe(res => {
                this.setBoardItems();
              })
            });
          this.transformedSprintBoardItems = this.projectSprintBoardService.checkForBlockers(this.transformedSprintBoardItems);
        }, err => {
          console.log('some error occured');
          this.setBoardItems();
        });
    } else if (newStoryStatus === StoryStatus.Backlog) {

      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      boardItem['view'] = 'default';
      boardItem['viewID'] = null;
      this.projectSprintService.editSprintStoryBack(this.sprintId, boardItem)
        .subscribe(async (res) => {
          // Moving an item into the backlog
          // A story can only be deleted if not currently on an active sprint
          if (!await this.removeSprintStory(taskId)) return;

          this.projectSprintBoardService.updateBoardItemStatus(boardItem, newStoryStatus);

          this.transformedSprintBoardItems = this.projectSprintBoardService.checkForBlockers(this.transformedSprintBoardItems);

          this.setBoardItems();
        });
    } else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.InProgress && oldStoryStatus < newStoryStatus) {
      this.modalType = 'InProgress';
      this.inProgressTaskId = taskId;
      // Starting a step
      this.activateSprintTaskInProgress();
      // this.setBoardItems();
    }
    else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.Completed && oldStoryStatus < newStoryStatus) {
      // Completing a step
      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      if (Utils.isEmpty(boardItem.startDate)) boardItem.startDate = Utils.getCurrentDate();
      const today = Utils.getCurrentDate();
      if (!Utils.isEmpty(boardItem.startDate)) {
        let duration = today - boardItem.startDate;
        duration = duration / 60000; // convert to minutes
        // If less than 15 minutes, leave empty
        if (duration > 15) {
          duration = duration / 60; // convert to hours
          duration = Math.ceil(duration * 4) / 4; // round to 15 min increments
          this.actualDuration = duration;
        }
      }
      this.actualCrewSize = boardItem.crewSize ? boardItem.crewSize : 0;
      this.actualEquipmentIds = boardItem.equipment ? boardItem.equipment.map(e => e.id) : [];
      this.actualMaterialIds = boardItem.materials ? boardItem.materials.map(mat => mat.id) : [];
      this.actualLaborIds = boardItem.labor ? boardItem.labor.map(labor => labor.id) : [];
      this.actualDuration = boardItem.durationHours ? boardItem.durationHours : [];
      this.modalType = 'Completed';
      this.dateStarted = boardItem.startDate ? boardItem.startDate : Utils.getCurrentDate();
      this.showCompleteStoryModal = true;
      this.completedTaskId = taskId;
      this.curObjectIds = boardItem.objectIds;
      // this.setBoardItems();
    } else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.Approved) {
      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      if (Utils.isEmpty(boardItem.startDate)) boardItem.startDate = Utils.getCurrentDate();
      const today = Utils.getCurrentDate();
      if (!Utils.isEmpty(boardItem.startDate)) {
        let duration = today - boardItem.startDate;
        duration = duration / 60000; // convert to minutes
        // If less than 15 minutes, leave empty
        if (duration > 15) {
          duration = duration / 60; // convert to hours
          duration = Math.ceil(duration * 4) / 4; // round to 15 min increments
          this.actualDuration = duration;
        }
      }
      this.actualCrewSize = boardItem.crewSize ? boardItem.crewSize : 0;
      this.actualEquipmentIds = boardItem.equipment ? boardItem.equipment.map(e => e.id) : [];
      this.actualMaterialIds = boardItem.materials ? boardItem.materials.map(mat => mat.id) : [];
      this.actualLaborIds = boardItem.labor ? boardItem.labor.map(labor => labor.id) : [];
      this.actualDuration = boardItem.durationHours ? boardItem.durationHours : [];

      this.completedTaskId = taskId;
      this.curObjectIds = boardItem.objectIds;
      this.modalType = 'Approved';
      this.dateStarted = boardItem.startDate;
      this.showApprovedModal = true;
      // this.setBoardItems();
    } else if (oldStoryStatus !== StoryStatus.Backlog && newStoryStatus === StoryStatus.NotCompleted && oldStoryStatus < newStoryStatus) {
      this.actualCrewSize = null;
      // Adding reason step is not completed
      this.modalType = 'NotComplete';
      this.showNotCompleteStoryModal = true;

      this.completedTaskId = taskId;
      // this.setBoardItems();
    } else if (newStoryStatus !== StoryStatus.Backlog && oldStoryStatus > newStoryStatus) {
      // Moving a step backwards
      this.moveSprintStoryBack(taskId, newStoryStatus);
    } else {
      console.error('An illegal move was made. taskId: ', taskId, ' (old: ', oldStoryStatus, '; new: ', newStoryStatus, ')');
    }
    this.revertBackToPreviousStableState();
  }

  activateSprintTaskInProgress() {
    const taskId = this.inProgressTaskId;
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    const duration = Utils.convertHoursToDays(boardItem.durationHours);
    const startDate = boardItem.scheduledStart;
    const expectedFinishDate = Utils.getEndDateExcludingWeekends(startDate, duration);
    this.inProgressModalObj = {
      startDate: Utils.getCurrentDate(),
      expectedFinishDate: expectedFinishDate,
      duration: duration
    }
    this.showInProgressStoryModal = true;
  }

  confirmCompletedTask(input: ICompleteOutput, isApproved: boolean) {
    if(isApproved){
      this.showApprovedModal = false;
      this.updateSprintToApprove(
        this.completedTaskId,
        input.actualCrewSize,
        input.actualDuration,
        input.result,
        true,
        input.startDate,
        input.completionDate,
        input.notes,
        input.actualEquipment,
        input.actualMaterials
        )
    } else{
      this.showCompleteStoryModal = false;
      this.modalType = '';
      this.completeSprintStory(
        this.completedTaskId,
        input.actualCrewSize,
        input.actualDuration,
        input.result,
        true,
        input.completionDate,
        input.notes,
        input.actualEquipment,
        input.actualMaterials
      );
    }
  }

  updateSprintToApprove(taskId: string, actualCrewSize: number, actualDuration: number, storyResult: string, approved: boolean,
    startDate: number, approvedDate: number, notes?: string, actualEquipment?: IStoryEquipment[], actualMaterials?: IStoryMaterial[], sprintId?: string): void {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    boardItem.startDate = startDate;

    const inputInfo = {
      storyResult: storyResult,
      actualCrewSize: actualCrewSize,
      actualDurationHours: actualDuration,
      nextProjectSprintId: sprintId,
      notes: Utils.isEmpty(notes) ? null : notes,
      startDate: startDate,
      approvedDate: approvedDate,
      actualEquipment: actualEquipment ? actualEquipment : [],
      actualMaterials: actualMaterials ? actualMaterials : [],
      status: 10
    };
    this.projectSprintService.approveSprintStory(boardItem.storyId, inputInfo).subscribe(
      () => {
        if (approved)
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.Approved);
        else
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.NotCompleted);

        this.refreshBoard();
        this.disablePage(false);
        this.revertBackToPreviousStableState();
        this.updateTaskStatus();
        this.segmentService.track('Sprint story approved', { taskId: taskId, storyResult: storyResult });

        this.actualCrewSize = null;
        this.actualDuration = null;
        this.reasonNotCompleted = null;
        this.moveSprintNotCompleted = null;
        this.otherReason = null;
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'start'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
        this.revertBackToPreviousStableState();
        this.updateTaskStatus();
      });
  }


  confirmNotCompletedTask(input: INotCompleteOutput) {
    if (input.moveTo === '0000') {
      this.completeSprintStory(this.completedTaskId, null, null, input.result, false, null, input.notes);
    } else {
      this.completeSprintStory(this.completedTaskId, null, null, input.result, false, null, input.notes, null, null, input.moveTo);
    }
    this.showNotCompleteStoryModal = false;
  }

  handleReasonDropdown(reason: any) {
    this.reasonNotCompleted = Number(reason);
  }

  handleSprintDropdown(item: any) {
    this.moveSprintNotCompleted = item;
  }

  async addSprintStory(taskId: string, sprintId: string, movedFromBackLog?: boolean, element?: any) { 
    this.disablePage(true);
    await this.projectSprintService.addSprintStory({ projectSprintId: sprintId, projectScheduleTaskId: taskId }).subscribe(
      success => {
        // If we are putting an item in another sprint - we don't need to update the task board
        if (this.sprintId === sprintId) {
          this.projectSprintBoardService.updateBoardItem(taskId, success, this.subContractorList);
        }
        this.disablePage(false);
        this.segmentService.track('Sprint story added', { taskId: taskId });

        // When adding an item to a sprint, it will only move to commit
        if (movedFromBackLog) {
          let boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);

          boardItem.day = element.day;
          boardItem.hour = element.hour;
          boardItem.workflow = element.workflow;
          boardItem.view = element.view;
          boardItem.viewID = element.viewID;
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, 2);
        }
        this.refreshBoard();
        this.updateTaskStatus(taskId);
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'update'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
        // this.revertBackToPreviousStableState();
      });
  }

  removeSprintStory(taskId: string): Promise<boolean> {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);

    return new Promise((resolve) => {
      this.projectSprintService.removeSprintStory(boardItem.storyId).subscribe(
        () => {
          boardItem.storyId = null;
          boardItem.storyStatus = StoryStatus.Backlog;
          this.projectSprintBoardService.updateBoardItem(taskId, boardItem, this.subContractorList, true);

          this.refreshBoard();
          this.disablePage(false);
	        this.updateTaskStatus();
          this.segmentService.track('Sprint story removed', { taskId: taskId });
          return resolve(true);
        },
        error => {
          const context: INoficationContext = {
            type: 'task',
            action: 'remove'
          };
          this.notificationService.error(error, context);
          this.refreshBoard();
          this.disablePage(false);
          // this.revertBackToPreviousStableState();

          return resolve(false);
        });
    });
  }

  moveSprintStoryBack(taskId: string, storyStatus: number) {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);

    this.projectSprintService.moveSprintStoryBack(boardItem.storyId, storyStatus).subscribe(
      success => {
        boardItem.crewSize = 0;
        this.projectSprintBoardService.updateBoardItemStatus(boardItem, storyStatus);
        this.refreshBoard();
        this.disablePage(false);
        this.updateTaskStatus();
        this.segmentService.track('Sprint story moved backwards', { taskId: taskId });
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'move back'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
        // this.revertBackToPreviousStableState();
      });
  }

  startSprintStory(inProgressInfo: IInProgressOutput) {
    const taskId = this.inProgressTaskId;
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
    const requestObj: IInProgressOutput = {
      startDate : + new Date(inProgressInfo.startDate),
      expectedFinishDate: +new Date(inProgressInfo.expectedFinishDate)
    }
    this.disablePage(true);
    this.projectSprintService.startSprintStory(boardItem.storyId, requestObj).subscribe(
      () => {
        boardItem.storyStatus = StoryStatus.InProgress;
        boardItem.startDate =+ new Date(inProgressInfo.startDate);
        this.projectSprintBoardService.updateBoardItem(taskId, boardItem, this.subContractorList, true);
        this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.InProgress);
        this.refreshBoard();
        this.disablePage(false);
        this.updateTaskStatus();
        this.segmentService.track('Sprint story started', { taskId: taskId });
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'start'
        };

        this.notificationService.error(error, context);
        // this.revertBackToPreviousStableState();
        this.refreshBoard();
        this.disablePage(false);
      });
  }

  completeSprintStory(taskId: string, actualCrewSize: number, actualDuration: number, storyResult: string, completed: boolean,
    completionDate: number, notes?: string, actualEquipment?: IStoryEquipment[], actualMaterials?: IStoryMaterial[], sprintId?: string) {
    this.disablePage(true);
    const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      boardItem.crewSize = actualCrewSize ? actualCrewSize : boardItem.crewSize;
      boardItem.durationHours = actualDuration ? actualDuration : boardItem.durationHours;
       if(actualEquipment && actualEquipment.length > 0){
        actualEquipment.forEach(equipment =>{
          boardItem.equipment.push({
            id: equipment['equipmentId'],
            name: equipment['name']
          });
        });

       }

       if(actualMaterials && actualMaterials.length > 0){
         let newMaterial: any;
        actualMaterials.forEach(material =>{
          newMaterial = {
            id : material.materialId,
          }
          boardItem.materials.push(newMaterial);
        });

       }

    const inputInfo = {
      storyResult: storyResult,
      actualCrewSize: actualCrewSize,
      actualDurationHours: actualDuration,
      nextProjectSprintId: sprintId,
      notes: Utils.isEmpty(notes) ? null : notes,
      started: boardItem.startDate,
      completionDate: completionDate,
      actualEquipment: actualEquipment ? actualEquipment : [],
      actualMaterials: actualMaterials ? actualMaterials : []
    };
    this.projectSprintService.endSprintStory(boardItem.storyId, inputInfo).subscribe(
      () => {
        if (completed)
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.Completed);
        else
          this.projectSprintBoardService.updateBoardItemStatus(boardItem, StoryStatus.NotCompleted);

        this.refreshBoard();
        this.disablePage(false);
        this.updateTaskStatus();
        this.segmentService.track('Sprint story completed', { taskId: taskId, storyResult: storyResult });

        this.actualCrewSize = null;
        this.actualDuration = null;
        this.reasonNotCompleted = null;
        this.moveSprintNotCompleted = null;
        this.otherReason = null;
        if (this.completeSprintMode && this.projectSprintBoardService.isSprintClosed()) {
          this.segmentService.track('Sprint closed (all stories moved to complete or incomplete)', { sprintId: this.sprintId });
          this.sprintClosed.emit(true);
        }
      },
      error => {
        const context: INoficationContext = {
          type: 'task',
          action: 'start'
        };

        this.notificationService.error(error, context);

        this.refreshBoard();
        this.disablePage(false);
        this.revertBackToPreviousStableState();
      });
  }

  resetBoard(boardColumn?: string): void {
    // If nothing is passed in, we'll reset everything
    if (Utils.isEmpty(boardColumn)) boardColumn = 'all';

    switch (boardColumn) {
      case 'backlog':
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.completed.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => item.blocker = false);
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'committed':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'inProgress':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'notCompleted':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.completed.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      case 'completed':
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
      default:
        this.transformedSprintBoardItems.backlog.active = false;
        this.transformedSprintBoardItems.committed.active = false;
        this.transformedSprintBoardItems.inProgress.active = false;
        this.transformedSprintBoardItems.notCompleted.active = false;
        this.transformedSprintBoardItems.completed.active = false;

        this.transformedSprintBoardItems.backlog.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.committed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.inProgress.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.notCompleted.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        this.transformedSprintBoardItems.completed.items.map(item => (item.active = false) && (item.isPrerequisite = false) && (item.isSuccessor = false) && (item.isDim = false));
        break;
    }
  }

  async resetAll() {
    this.resetBoard();
    await this.resetViewer();
  }

  async resetViewer() {
    // The viewer should show everything on the board
    this.myViewerState = {};
    let viewerList = this.projectSprintBoardService.sprintBoardData.filter(item => item.displayedOnBoard).map(item => item.objectIds) as any;
    viewerList = [].concat.apply([], viewerList);
    viewerList.map(item => this.myViewerState[item] = this.projectService.getObject(item));

    await this.forgeViewerService.setViewerState(this.myViewerState);
    await this.forgeViewerService.clearAllThemingColors();
    await this.forgeViewerService.fitModelToView(Object.keys(this.forgeViewerService.projectModelIdMap));
  }

  determineBlockers(sprintBoardItem: ITransformedSprintBoardItem) {
    sprintBoardItem.prerequisiteObjectIds.forEach((prereqId, prereqIndex) => {
      const prereqSprintBoardItem = this.transformedSprintBoardItems.backlog.items.find(item => {
        const objectIndex = item.objectIds.indexOf(prereqId);
        if (objectIndex !== -1) {
          return item.activities[objectIndex] === sprintBoardItem.prerequisiteActivities[prereqIndex];
        } else {
          return false;
        }
      });

      if (!Utils.isEmpty(prereqSprintBoardItem)) {
        prereqSprintBoardItem.blocker = true;
      } else {
        const beforeBacklogLength = this.transformedSprintBoardItems.backlog.items.length;
        this.transformedSprintBoardItems.backlog.items = this.projectSprintBoardService.getHiddenBlockers(prereqId, prereqIndex, sprintBoardItem, this.transformedSprintBoardItems.backlog.items);
        if (beforeBacklogLength < this.transformedSprintBoardItems.backlog.items.length) {
        }
      }
    });
  }

  loadMoreItems(columnType: string) {
    // Get another round of data (if there is more)
    if (columnType === 'backlog' && this.moreBacklog) {
      // We are going to get another 25 so we stay ahead
      this.projectSprintService.getSprintBacklog(this.projectId, null).subscribe(
        res => {
          this.projectSprintBoardService.loadSprintBoardData(res['response'], this.subContractorList);
          this.projectSprintService.nextBacklogPage();
          if (res['count'] < res['pageSize']) this.moreBacklog = false;
        },
        error => {
          const context: INoficationContext = {
            type: 'sprint information',
            action: 'get'
          };
          this.notificationService.error(error, context);
        });
    }

    this.transformedSprintBoardItems[columnType].items = this.projectSprintBoardService.loadMoreData(this.transformedSprintBoardItems[columnType].items, columnType);
    this.transformedSprintBoardItems = this.projectSprintBoardService.checkForBlockers(this.transformedSprintBoardItems);
    this.transformedSprintBoardItems.committed.items
    this.resetAll();
    for (let i = 0; i < this.transformedSprintBoardItems.backlog.items.length; i++) {
      this.makeAggregatedDurationHours(this.transformedSprintBoardItems.backlog.items[i]);
    }
    this.segmentService.track('Loaded more stories', { columnType: columnType });
  }

  handledisplayModeSelection(displayMode: IProjectViewModes): void {
    this.displayModeOutput.emit(displayMode);
  }

  checkSprintIsDeletable(): void {
    const currSprint = this.projectSprintService.getSprint(this.sprintId);
    const currStories = this.projectSprintBoardService.sprintBoardData ? this.projectSprintBoardService.sprintBoardData : null;
    this.projectSprintService.isSafeToDeleteSprint(currSprint, currStories);
  }

  checkMoreItemsAvailable(columnType: string) {
    if (this.transformedSprintBoardItems[columnType].items.length >= (this.projectSprintBoardService.sprintBoardColumnPageSize * this.projectSprintBoardService.sprintBoardPagingData[columnType].currentPage)) {
      return false;
    } else {
      return true;
    }
  }

  async undoBoardAction() {
    if (!Utils.isEmpty(this.subContractorList)) {
      this.transformedSprintBoardItems = this.projectSprintBoardService.getRefreshedData();
      this.transformedSprintBoardItems.committed.items
    } else {
      const filteredData = this.projectSprintBoardService.sprintBoardData.filter(story => !this.taskFilteredOut(story)  && this.filterByView(story));
      this.transformedSprintBoardItems = this.projectSprintBoardService.transformSprintBoardData(filteredData);
      this.transformedSprintBoardItems.committed.items
    }
  }

  showLoadMoreButton(columnType: string) {
    return this.projectSprintBoardService.checkColumnNeedsPaging(columnType);
  }

  refreshBoard() {
    this.modalType = '';
    this.dateStarted = null;
    this.showCompleteStoryModal = false;
    this.showNotCompleteStoryModal = false;
    this.showApprovedModal = false;
    this.showInProgressStoryModal = false;

    this.undoBoardAction();
    this.checkSprintIsDeletable();
  }

  disablePage(disable: boolean) {
    this.makingBackendCall = disable;
  }

  inProgressFormOutput(formOutput: IInProgressOutput) {
    this.startSprintStory(formOutput);
  }

  completeformOutput(formOutput: ICompleteOutput) {
      this.confirmCompletedTask(formOutput, formOutput.isApproved);
  }

  notCompleteformOutput(formOutput: INotCompleteOutput) {
    this.confirmNotCompletedTask(formOutput);
  }

  async handleSubmittalClick(sprintItem: ITransformedSprintBoardItem, event: any): Promise<void> {
    event.stopPropagation();

    const submittalSelection = {
      taskName: sprintItem.stepName,
      submittals: []
    };

    for (const mat of sprintItem.materials) {
      if (mat.procoreId) {
        submittalSelection.submittals.push({
          materialName: mat.name,
          url: await this.projectMaterialService.getProcoreSubmittalUrl(this.projectService.currentProject.id, mat.procoreId)
        });
      }
    }

    if (submittalSelection.submittals.length < 1) {
      return;
    } else if (submittalSelection.submittals.length === 1) {
      window.location.href = submittalSelection.submittals[0].url;
    } else {
      this.submittalSelection = submittalSelection;
      this.showSubmittalModal = true;
    }
  }

  closeSubmittalModal(): void {
    this.submittalSelection = null;
    this.showSubmittalModal = false;
  }

  activities = [];
  unassignedActivities = [];
  cloneOfUnAssignedActivities = [];
  sprintDateRangeArray;

  public workflowArray: Array<any> = [];
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  cloneOfBacklogActivities;

  // Make date range array for TABLE HEADER
  makeSprintDateRangeArray(currSprint) {
    let dateArray = new Array();
    let scheduledStartDate = new Date(currSprint.scheduledStartDate);
    let workflowStartDateTimestamp = scheduledStartDate.setDate(scheduledStartDate.getDate());

    let scheduledEndDate = new Date(currSprint.scheduledEndDate);
    let workflowEndDateTimestamp = scheduledEndDate.setDate(scheduledEndDate.getDate());

    while (workflowStartDateTimestamp <= workflowEndDateTimestamp) {
      dateArray.push(new Date(workflowStartDateTimestamp));
      var date = new Date(workflowStartDateTimestamp);
      workflowStartDateTimestamp = date.setDate(date.getDate() + 1);
    }
    this.sprintDateRangeArray = dateArray;
  }

  //Creates and pushes a new workflow with empty items
  hoursInADay: Number = 8;
  pushNewWorkflow(push) {
    //push empty objects
    let newWorkflow = {
      name: 'workflow-' + this.workflowArray.length + 1,
      items: []
    };

    for (let i = 0; i < this.sprintDateRangeArray.length; i++) {
      for (let j = 0; j < this.hoursInADay; j++) {
        newWorkflow.items.push({});
      }
    }
    this.workflowArray.push(newWorkflow);
    this.manageTaskListOrder(this.workflowArray.length - 1);
  }

  setMockActivities() {
    for (let i = 0; i < this.activities.length; i++) {
      //Each Activity must have a day, hour and workflow

      //Make this.workflowArray add arrays to accomodate current activity
      let curActWorkflowNo;
      if (this.activities[i].hasOwnProperty('workflow') && this.activities[i].workflow && this.activities[i].scheduledStart) {
        curActWorkflowNo = this.activities[i].workflow - 1;
        // Check if new workflow is needed, if needed then create one
        if (this.workflowArray.length - 1 < curActWorkflowNo) {
          while (this.workflowArray.length - 1 !== curActWorkflowNo) {
            this.pushNewWorkflow(true);
          }
        }
      } else {
        this.pushNewWorkflow(true);
        curActWorkflowNo = this.workflowArray.length - 1;
      }

      //Activities must have a day and hour
      let currentActivityDay = this.activities[i]['day'];
      // let currentActivityHour = this.activities[i]['hour'];
      let currentActivityHour = 1; //TEMPCHANGE

      //Find correct index on this.workflowArray and drop the lement
      for (let j = 0; j < this.workflowArray[curActWorkflowNo].items.length; j++) {
        if (currentActivityDay === this.workflowArray[curActWorkflowNo].items[j].day && currentActivityHour === this.workflowArray[curActWorkflowNo].items[j].hour) {
          let barrier = this.barrierDetector(this.activities[i], j, curActWorkflowNo);
          if (!barrier) {

            //Fixing adjustedspan
            for (let k = j + 1; k <= (j + this.activities[i].aggregatedDurationHours); k++) {
              if (k === this.workflowArray[curActWorkflowNo].items.length) {
                this.activities[i].adjustedSpan = (this.workflowArray[curActWorkflowNo].items.length - 1 + 1) - j;
                break;
              }
            }
            this.workflowArray[curActWorkflowNo].items.splice(j, 0, this.activities[i]);
            this.dropElementEventHandler(this.activities[i], curActWorkflowNo, j);
          } else {
            console.log('some error occured');
          }
          break;
        }
      }
    }

    this.pushNewWorkflow(true);
    this.clonedWorkFlowArray = JSON.parse(JSON.stringify(this.workflowArray));
  }

  barrierDetector(element, droppedToItemIndex, droppedToItemsWorkflowIndex, pickedFromItemsWorkflowIndex?, pickedFromItemIndex?) {
    let aggregatedDurationHours = element.aggregatedDurationHours;
    element.pickedFromadjustedSpan = element.adjustedSpan;
    element.adjustedSpan = null;
    element.pickedElementsToBeDeleted = element.elementsToBeDeleted;
    element.elementsToBeDeleted = null;
    let barrierPresent = false;

    for (let i = droppedToItemIndex + 1; i <= (droppedToItemIndex + aggregatedDurationHours - 1); i++) {
      if (this.workflowArray[droppedToItemsWorkflowIndex].items[i] && this.workflowArray[droppedToItemsWorkflowIndex].items[i].hasOwnProperty('workflow')) {
        barrierPresent = true;
        break;
      }
    }

    if (!barrierPresent) {
      if (droppedToItemsWorkflowIndex === pickedFromItemsWorkflowIndex) {
        // Move in same workflow
        if (element.pickedFromadjustedSpan === aggregatedDurationHours) {
          //Means moved activity expanded to its max, no need to expand more, hence simple use the earlier span
          if ((droppedToItemIndex + aggregatedDurationHours) < this.workflowArray[droppedToItemsWorkflowIndex].items.length) {
            element.adjustedSpan = aggregatedDurationHours;
          } else {
            let availableSpace = this.workflowArray[droppedToItemsWorkflowIndex].items.length - droppedToItemIndex;
            element.adjustedSpan = availableSpace -1;
          }

          // END

        } else {
          if ((droppedToItemIndex + aggregatedDurationHours) < this.workflowArray[droppedToItemsWorkflowIndex].items.length && pickedFromItemsWorkflowIndex != droppedToItemsWorkflowIndex) {
            element.adjustedSpan = aggregatedDurationHours;
          } else {
            //The new fresh drop CANNOT be fully expandable.....means this was the last object in the array
            let newlyAvailableSpace = (this.workflowArray[droppedToItemsWorkflowIndex].items.length - 1) - droppedToItemIndex;
            if (newlyAvailableSpace === 0) {
              // Move was made to the right, cuz the dropped element is still the last element
              // Currently this case must be reverted
              barrierPresent = true;
            } else if (newlyAvailableSpace > 0) {
              //Move was made to the left, get how many elements were moved to the left.
              let neededMoreSpan = element.aggregatedDurationHours - element.pickedFromadjustedSpan;
              if (newlyAvailableSpace >= neededMoreSpan) {
                //Newly available space is enough to accomodate the complete span of dropped activity
                element.elementsToBeDeleted = neededMoreSpan;
                element.adjustedSpan = element.aggregatedDurationHours;
              } else {
                //Newly available space NOT enough to accomodate the complete span of dropped activity.
                element.elementsToBeDeleted = newlyAvailableSpace;
                //If think this was written for left shift or what
                // element.adjustedSpan = element.pickedFromadjustedSpan + newlyAvailableSpace;
                //Now i changed the upper to this for my case:
                element.adjustedSpan =newlyAvailableSpace;
              }
            }
          }
        }
      } else {
        //Move in different workflows
        if (element.pickedFromadjustedSpan === aggregatedDurationHours) {
          //Means moved activity expanded to its max, no need to expand more, hence simple use the earlier span

          if (droppedToItemIndex + aggregatedDurationHours < this.workflowArray[droppedToItemsWorkflowIndex].items.length) {
            //The new fresh drop can be fully expandable
            element.adjustedSpan = aggregatedDurationHours;
          } else {
            //The new fresh drop CANNOT be fully expandable;
            element.adjustedSpan = (this.workflowArray[droppedToItemsWorkflowIndex].items.length - droppedToItemIndex) - 1;
          }
        } else {
          //Means moved activity was not able to fully expand
          if (droppedToItemIndex + aggregatedDurationHours < this.workflowArray[droppedToItemsWorkflowIndex].items.length) {
            element.adjustedSpan = aggregatedDurationHours;
          } else {
            element.adjustedSpan = (this.workflowArray[droppedToItemsWorkflowIndex].items.length - droppedToItemIndex) - 1;
          }
        }
      }
    }

    return barrierPresent;
  }

  fixSameWorkflowMove(element, droppedToItemIndex, droppedToItemsWorkflowIndex) {
    let aggregatedDurationHours = element.aggregatedDurationHours;
    element.pickedFromadjustedSpan = element.adjustedSpan;
    element.adjustedSpan = null;

    for (let i = droppedToItemIndex + 1; i <= (droppedToItemIndex + aggregatedDurationHours); i++) {
      if (i === this.workflowArray[droppedToItemsWorkflowIndex].items.length) {
        element.adjustedSpan = this.workflowArray[droppedToItemsWorkflowIndex].items.length - 1 - droppedToItemIndex;
        break;
      }
    }
  }

  async drop(item, pickedFrom, droppedTo, movedFromBackLog?) {
    let pickedFromItemsWorkflowIndex;
    let pickedFromItemIndex;

    if (!movedFromBackLog) {
      pickedFromItemsWorkflowIndex = Number(pickedFrom.split('_')[0]);
      pickedFromItemIndex = Number(pickedFrom.split('_')[1]);
    }

    let droppedToItemsWorkflowIndex = Number(droppedTo.split('_')[0]);
    this.manageTaskListOrder(droppedToItemsWorkflowIndex);
    let droppedtoIndex = Number(droppedTo.split('_')[1]);
    if(droppedToItemsWorkflowIndex === pickedFromItemsWorkflowIndex && droppedtoIndex > pickedFromItemIndex) {
      droppedtoIndex = droppedtoIndex -1;
    }
    let droppedToItemIndex = this.checkBack(droppedToItemsWorkflowIndex, droppedtoIndex);
    this.finallyPlacedRealItem = [droppedToItemsWorkflowIndex, droppedToItemIndex];
    let element = this.workflowArray[droppedToItemsWorkflowIndex].items[droppedToItemIndex];

    if (element && !element.activities || !(element.activities.length > 0)) {
      // Picked element doest seems to contains essential keys, No use executing a move on this.
      this.revertBackToPreviousStableState();

      return;
    }
    console.log("DROP 4");
console.log(JSON.parse(JSON.stringify(this.workflowArray)));

    let barrierPresent = this.barrierDetector(element, droppedToItemIndex, droppedToItemsWorkflowIndex, pickedFromItemsWorkflowIndex, pickedFromItemIndex);

    if (barrierPresent) {
      this.revertBackToPreviousStableState();
      return;
    } else {
      if (!movedFromBackLog) {
        if (pickedFromItemsWorkflowIndex != droppedToItemsWorkflowIndex) {
          let ret = this.dropElementEventHandler(element, droppedToItemsWorkflowIndex, droppedToItemIndex);
          if (ret) {
            this.checkIfNewWorkflowNeeded();
          } else {
            this.workflowArray = JSON.parse(JSON.stringify(this.clonedWorkFlowArray));
            return;
          }
          this.manageTaskListOrder(pickedFromItemsWorkflowIndex);
        } else {
          this.dropElementEventHandler(element, droppedToItemsWorkflowIndex, droppedToItemIndex);
        }
      } else if (movedFromBackLog) {
        this.dropElementEventHandler(element, droppedToItemsWorkflowIndex, droppedToItemIndex);
      }
      this.manageTaskListOrder(droppedToItemsWorkflowIndex);
      this.removeExtraWorkflow();
    }

    if (movedFromBackLog && item && item[3] && item[3].className.match(/sprint-lane/g)) {
      element.view = this.currentlySelectedView;
      element.viewID = this.currentlySelectedViewID;

      await this.addSprintStory(pickedFrom, this.sprintId, true, element);
    }

    element.pickedElementsToBeDeleted = null;
    element.view = this.currentlySelectedView;
    element.viewID = this.currentlySelectedViewID;
    this.projectSprintService.editSprintStoryBack(this.sprintId, element)
    .subscribe(res => {
      const taskId = element.taskId;
      const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
      this.projectSprintBoardService.updateBoardItemStatus(element, boardItem.storyStatus);
      this.cloneAllNewStates();
      this.workflowArray.forEach((data, workflowId)=>{
        this.checkView(workflowId);
      });
      this.maintainWorkflowIndices();
      let finalColspanOfItem;
      let realItem: HTMLElement = document.getElementById(this.finallyPlacedRealItem[0] + '_' + this.finallyPlacedRealItem[1]);
      
      if (element.adjustedSpan || element.aggregatedDurationHours) {
        finalColspanOfItem = element.adjustedSpan || element.aggregatedDurationHours
      }

      realItem.setAttribute('colspan', String(finalColspanOfItem));
      //Maintain a filtered workflow indices:
      this.maintainWorkflowIndices();
    }, err => {
      this.cloneAllNewStates();
    });
  }

  dropElementEventHandler(element, droppedToItemsWorkflowIndex, droppedToItemIndex) {
    //Assign the view
    if (!element.view){
      element.view = this.currentlySelectedView;
      if(this.currentlySelectedView !== 'default'){
        let found  = this.viewListWithIds.find(v=> v.name === this.currentlySelectedView)
        if(found && found.id) element.viewID = found.id;
      }
    }

    //HERE IS THE ACTUAL SPLICING HAPPENING
    let durationHours = element.adjustedSpan || element.aggregatedDurationHours;
    this.workflowArray[droppedToItemsWorkflowIndex].items[droppedToItemIndex].workflow = droppedToItemsWorkflowIndex + 1;
    for (let i = (droppedToItemIndex + durationHours); i >= droppedToItemIndex + 1; i--) {
      this.workflowArray[droppedToItemsWorkflowIndex].items.splice(i, 1);
    }

    return true;
  }

  async addNewSprintStory(movedItem) {
    let taskId = movedItem[1].id;
    let movedItemTaskId =  movedItem[1].id;
    let element;
    for (let i = 0; i < this.unassignedActivities.length; i++) {
      if(movedItemTaskId === this.unassignedActivities[i]['taskId']) {
        this.unassignedActivities[i].view = this.currentlySelectedView;
        this.unassignedActivities[i].viewID = this.currentlySelectedViewID;
        element = this.unassignedActivities[i];
      }
    }
    if(element) {
      //Properties that needs to be added to DB.
      element.view = this.currentlySelectedView;
      element.viewID = this.currentlySelectedViewID;
      await this.addSprintStory(taskId, this.sprintId, true, element);

      //Properties that are client view specific.
      element.adjustedSpan = null;
      element.pickedElementsToBeDeleted = null;
      element.elementsToBeDeleted = null;
      element.workflow = element.day = element.hour = -1;
      element.pickedElementsToBeDeleted = null;
      
      this.projectSprintService.editSprintStoryBack(this.sprintId, element)
      .subscribe(res => {
        const taskId = element.taskId;
        const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
        this.projectSprintBoardService.updateBoardItemStatus(element, boardItem.storyStatus);
        this.cloneAllNewStates();
        //Maintain a filtered workflow indices:
        this.maintainWorkflowIndices();
      });
    } else {
      this.revertBackToPreviousStableState();
    }
  }

  handleFeedToUnassigned(movedItem) {   
      if (movedItem[1] && movedItem[1].id) {
        let pickedFrom = movedItem[1].id;
        let pickedFromItemsWorkflowIndex = Number(pickedFrom.split('_')[0]);
        let pickedFromItemIndex = Number(pickedFrom.split('_')[1]);
        let element = null;

        for (let i = 0; i < this.unassignedActivities.length; i++) {
          let findIfObjectExists = this.cloneOfUnAssignedActivities.some(activity => activity.stepName === this.unassignedActivities[i]['stepName']);
          if (!findIfObjectExists) {
            this.unassignedActivities[i].view = this.currentlySelectedView;
            this.unassignedActivities[i].viewID = this.currentlySelectedViewID;
            element = this.unassignedActivities[i];
          }
        }

        if (element) {
          element.pickedFromadjustedSpan =  element.adjustedSpan;
          // this.pickElementEventHandler(element, pickedFromItemsWorkflowIndex, pickedFromItemIndex);

          element.adjustedSpan = null;
          element.pickedElementsToBeDeleted = null;
          element.elementsToBeDeleted = null;
          element.workflow = element.day = element.hour = -1;
          element.pickedElementsToBeDeleted = null;
          // if(this.currentlySelectedView) element.view = this.currentlySelectedView;
          // if(this.currentlySelectedView !== 'default') {
          //   let found  = this.viewListWithIds.find(v=> v.name === this.currentlySelectedView)
          //   if(found && found.id) element.viewID = found.id;
          // } else{
          //   element.viewID = null;
          // }
          element.view = this.currentlySelectedView;
          element.viewID = this.currentlySelectedViewID;
          
          this.projectSprintService.editSprintStoryBack(this.sprintId, element)
            .subscribe(res => {
              const taskId = element.taskId;
              const boardItem = this.projectSprintBoardService.getBoardItemByTaskId(taskId);
              this.projectSprintBoardService.updateBoardItemStatus(element, boardItem.storyStatus);
              this.cloneAllNewStates();
              this.removeExtraWorkflow();
              //Maintain a filtered workflow indices:
              this.maintainWorkflowIndices();
            }, err => {
              this.cloneAllNewStates();
            })
        } else {
          this.revertBackToPreviousStableState();
        }
      } else {
        this.revertBackToPreviousStableState();
      }
  }

  manageTaskListOrder(workflowId) {
    let day = 1;
    let hour = 1;
    for (let i = 0; i < this.workflowArray[workflowId].items.length; i++) {
      let durationHours = 1;

      this.workflowArray[workflowId].items[i].day = day;
      this.workflowArray[workflowId].items[i].hour = hour;


      if (this.workflowArray[workflowId].items[i].hasOwnProperty('workflow')) {
        durationHours = this.workflowArray[workflowId].items[i].aggregatedDurationHours;
      }

      if ((hour + durationHours) % 8 === 0) {
        day = day + (Math.floor((hour + durationHours) / 8) - 1);
        hour = 8;
      } else {
        let tempHour = hour;
        hour = (hour + durationHours) % 8;
        day = day + Math.floor((tempHour + durationHours) / 8);
      }
    }
    this.workflowArray.forEach((data, workflowId)=>{
      this.checkView(workflowId);
    });
  }

  checkView(id) {
    const workflowId = id - 1;
    if (workflowId < 0) return
    let foundView;
    for (let i = 0; i < this.workflowArray[workflowId].items.length; i++) {
      if (this.workflowArray[workflowId].items[i] && this.workflowArray[workflowId].items[i].viewID) {
        const viewID = this.workflowArray[workflowId].items[i].viewID;
        let existingViewObj = this.viewListWithIds.find(v => v.id === viewID);
        foundView = existingViewObj && existingViewObj.name ? existingViewObj.name : null;
        break;
      }
    }
    this.workflowArray[workflowId].view = foundView ? foundView : "default";
    if(this.clonedWorkFlowArray && this.clonedWorkFlowArray[workflowId]){
      this.clonedWorkFlowArray[workflowId].view  = foundView ? foundView : "default";
    }
  }

  showUnassigned(item): boolean {
    //  this.assignCurrentlySelectedViewId();
    if (this.currentlySelectedView === 'default') {
      return true;
    }

    if (item.viewID && this.currentlySelectedViewID) {
      if (item.viewID === this.currentlySelectedViewID)
        return true;

      if (item.viewID !== this.currentlySelectedViewID)
        return false;
    } else {
      return false;
    }
  }

  assignCurrentlySelectedViewId() : void{
    if(this.currentlySelectedView){
      const viewObj = this.viewListWithIds.find(v => v.name === this.currentlySelectedView);
      this.currentlySelectedViewID = viewObj && viewObj.id? viewObj.id : null;    
    }
  }

  pickElementEventHandler(element, pickedFromItemsWorkflowIndex, pickedFromItemIndex) {
    let durationHours = element.pickedFromadjustedSpan || element.aggregatedDurationHours;
    for (let i = pickedFromItemIndex; i < (pickedFromItemIndex + durationHours); i++) {
      let insertObject = {};
      this.workflowArray[pickedFromItemsWorkflowIndex].items.splice(i, 0, insertObject);
    }
    return true;
  }

  cloneAllNewStates() {
    this.clonedWorkFlowArray = JSON.parse(JSON.stringify(this.workflowArray));
    this.cloneOfUnAssignedActivities = JSON.parse(JSON.stringify(this.unassignedActivities));
    this.cloneOfBacklogActivities = JSON.parse(JSON.stringify(this.transformedSprintBoardItems.backlog.items));
  }

  revertBackToPreviousStableState() {
    this.workflowArray = JSON.parse(JSON.stringify(this.clonedWorkFlowArray));
    this.unassignedActivities = JSON.parse(JSON.stringify(this.cloneOfUnAssignedActivities));
    this.transformedSprintBoardItems.backlog.items = JSON.parse(JSON.stringify(this.cloneOfBacklogActivities));
  }

  checkBack(droppedToItemsWorkflowIndex, droppedToItemIndex) {
    let newDropItemIndex = droppedToItemIndex;
    for (let i = droppedToItemIndex - 1; i >= -1; i--) {
      if (i === -1 || this.workflowArray[droppedToItemsWorkflowIndex].items[i].hour === 8 || this.workflowArray[droppedToItemsWorkflowIndex].items[i].hasOwnProperty('workflow')) {
        newDropItemIndex = i + 1;
        break;
      }
    }
    let itemElement = { ...this.workflowArray[droppedToItemsWorkflowIndex].items[droppedToItemIndex] };
    if (newDropItemIndex != droppedToItemIndex) {
      this.workflowArray[droppedToItemsWorkflowIndex].items.splice(droppedToItemIndex, 1);
      this.workflowArray[droppedToItemsWorkflowIndex].items.splice(newDropItemIndex, 0, itemElement);
    }

    return newDropItemIndex;
  }

  checkIfNewWorkflowNeeded() {
    let found = false;
    let lastWorkflow = this.workflowArray[this.workflowArray.length - 1];
    if (lastWorkflow.items.length > 0) {

      for (let i = 0; i < lastWorkflow.items.length; i++) {
        if (lastWorkflow.items[i].stepName) {
          found = true;
          break;
        }
      }
    }
    if (found) {
      this.pushNewWorkflow(true);
    }
  }

  removeExtraWorkflow() {
    let removingIndexes = [];
    let tempIndex = -1;

    for (let i = this.workflowArray.length - 1; i >= 0; i--) {
      if (this.workflowArray[i].items.length > 0) {
        tempIndex = -1; //Default for every iteration.
        tempIndex = this.workflowArray[i].items.findIndex(item => item.stepName);
      }
      //If the item exist with Task item existence, then enough of the looping and remove all the removing items given by the removingindexes array.
      if ((tempIndex && tempIndex !== -1) || tempIndex === 0) break;
      removingIndexes.push(i)
    }
    removingIndexes.forEach(index => {
      this.workflowArray.splice(index, 1);
    });
    //Removed all the empty workflows
    //Add a new workflow.
    this.pushNewWorkflow(true);
  }

  unsubscribeDropModel() {
    if (this.dragulaSubscription) {
      // this.dragulaSubscription.unsubscribe();
    }

    if(this.dragulaDropModelSubscription) {
      this.dragulaDropModelSubscription.unsubscribe();
    }
  }

  async changeTaskStatus(taskInfo, newStatus){
    this.selectedTaskInfoToUpdateStatus = taskInfo;
    const oldStoryStatus = taskInfo.storyStatus;
    const taskId = taskInfo.taskId;
    await this.updateStep(taskId,newStatus, oldStoryStatus)
  }

  updateTaskStatus(movedFromBacklogId?) {
    this.revertBackToPreviousStableState();
    const boardItem = movedFromBacklogId ? this.projectSprintBoardService.getBoardItemByTaskId(movedFromBacklogId) : this.projectSprintBoardService.getBoardItemByTaskId(this.selectedTaskInfoToUpdateStatus.taskId);
    //if its is Backlog item to Workflow
    if (movedFromBacklogId) {
      // Condition : if the task is dropped to unassigned
      if(boardItem.workflow === -1){
        let index = this.unassignedActivities.findIndex(item => item.taskId === movedFromBacklogId);
        //Changing only selected properties.
        this.unassignedActivities[index].storyStatus = boardItem.storyStatus;
        this.unassignedActivities[index].projectScheduleTaskId = boardItem.projectScheduleTaskId;
        this.unassignedActivities[index].storyId = boardItem.storyId;
        this.unassignedActivities[index].equipment = boardItem.equipment;
        this.unassignedActivities[index].materials = boardItem.materials;
        this.unassignedActivities[index].type = boardItem.type;
        this.unassignedActivities[index].color = boardItem.color;
        this.unassignedActivities[index].crewSize = boardItem.crewSize;

        this.cloneOfUnAssignedActivities = JSON.parse(JSON.stringify(this.unassignedActivities));
      } else{ //Condition : if the task is dropped in workflow grid.
        let workflowIndex = boardItem.workflow - 1;
        let index = this.workflowArray[workflowIndex].items.findIndex(item => item.taskId === movedFromBacklogId)
        if (index > -1) {
          //For Backlog Case
          //Changing only selected properties.
          this.workflowArray[workflowIndex].items[index].storyStatus = boardItem.storyStatus;
          this.workflowArray[workflowIndex].items[index].projectScheduleTaskId = boardItem.projectScheduleTaskId;
          this.workflowArray[workflowIndex].items[index].storyId = boardItem.storyId;
          this.workflowArray[workflowIndex].items[index].equipment = boardItem.equipment;
          this.workflowArray[workflowIndex].items[index].materials = boardItem.materials;
          this.workflowArray[workflowIndex].items[index].type = boardItem.type;
          this.workflowArray[workflowIndex].items[index].color = boardItem.color;
          this.workflowArray[workflowIndex].items[index].crewSize = boardItem.crewSize;

          this.clonedWorkFlowArray = JSON.parse(JSON.stringify(this.workflowArray))
        }
      }
    } else {
      //If its is a workflow
      if (this.selectedTaskInfoToUpdateStatus && this.selectedTaskInfoToUpdateStatus.workflow && this.selectedTaskInfoToUpdateStatus.workflow !== -1) {
        let workflow = this.selectedTaskInfoToUpdateStatus.workflow;
        let i = this.workflowArray[workflow - 1].items.findIndex(item => item.taskId === this.selectedTaskInfoToUpdateStatus.taskId);
        if (i > -1) {
          // this.workflowArray[workflow - 1].items[i] = { ...boardItem };
          this.workflowArray[workflow - 1].items[i].storyStatus = boardItem.storyStatus;
          this.workflowArray[workflow - 1].items[i].projectScheduleTaskId = boardItem.projectScheduleTaskId;
          this.workflowArray[workflow - 1].items[i].storyId = boardItem.storyId;
          this.workflowArray[workflow - 1].items[i].equipment = boardItem.equipment;
          this.workflowArray[workflow - 1].items[i].materials = boardItem.materials;
          this.workflowArray[workflow - 1].items[i].type = boardItem.type;
          this.workflowArray[workflow - 1].items[i].color = boardItem.color;
          this.workflowArray[workflow - 1].items[i].crewSize = boardItem.crewSize;

          this.clonedWorkFlowArray = JSON.parse(JSON.stringify(this.workflowArray));
        }
      }

      //if its a unassigned activity
      if (this.selectedTaskInfoToUpdateStatus && this.selectedTaskInfoToUpdateStatus.workflow && this.selectedTaskInfoToUpdateStatus.workflow === -1) {
        let index = this.unassignedActivities.findIndex(unassignedActivity => unassignedActivity.taskId === this.selectedTaskInfoToUpdateStatus.taskId);
        // this.unassignedActivities[index] = { ...boardItem };
        //Changing only selected properties.
        this.unassignedActivities[index].storyStatus = boardItem.storyStatus;
        this.unassignedActivities[index].projectScheduleTaskId = boardItem.projectScheduleTaskId;
        this.unassignedActivities[index].storyId = boardItem.storyId;
        this.unassignedActivities[index].equipment = boardItem.equipment;
        this.unassignedActivities[index].materials = boardItem.materials;
        this.unassignedActivities[index].type = boardItem.type;
        this.unassignedActivities[index].color = boardItem.color;
        this.unassignedActivities[index].crewSize = boardItem.crewSize;

        this.cloneOfUnAssignedActivities = JSON.parse(JSON.stringify(this.unassignedActivities));
      }
    }

    this.selectedTaskInfoToUpdateStatus = null;
  }

}
