import { NgZone } from '@angular/core';
import * as moment from 'moment';
import { throwError } from 'rxjs';

import { ISort } from './models/sort.interface';

import { Buffer } from 'buffer/';
import * as convert from 'convert-units';
import { Currencies } from './staticLists/currency';
import * as pako from 'pako';
import { UnitOfMeasure } from '../models/project/project-material/project-material.interface';
import * as D3 from 'd3';

export class Utils {

    static removeArrayItems(array: any[], items: any[]): any[] {
        items.forEach(elem => {
            array = array.filter(x => x !== elem);
        });
        return array;
    }

    static removeArrayItemsByKey(array: any[], items: any[], key: any): any[] {
        items.forEach(elem => {
            array = array.filter(x => x[key] !== elem);
        });
        return array;
    }

    static copyKeysFromObject(objectToRead) {
        const returnObject = {};
        Object.keys(objectToRead).map(key => returnObject[key] = key);
        return returnObject;
    }

    static validateEmailAddress(email: string): boolean {
        if (email === '') { return false; }
        // tslint:disable-next-line:max-line-length
        const regEx = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEx.test(email)) { return false; }
        return true;
    }

    static validatePassword(password: string): boolean {
        if (Utils.isEmpty(password)) { return false; }
        const regEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}$/;
        if (!regEx.test(password)) { return false; }
        return true;
    }

    static isEmpty(item: any): boolean {
        if (item === undefined) { return true; }
        if (item == null) { return true; }
        if (item === '') { return true; }
        if (typeof item === 'object') {
            // Dates come back with no keys for some reason
            if (Object.keys(item).length === 0 && !(item instanceof Date)) { return true; }
        }
        return false;
    }

    static isEmptyList(list: any[]): boolean {
        if (list === undefined) { return true; }
        if (list == null) { return true; }
        if (!Array.isArray(list)) { return true; }
        if (list.length === 0) { return true; }
        return false;
    }

    static isNumber(input: any): boolean {
        input = String(input);
        if (Utils.isEmpty(input)) { return false; }
        return !isNaN(Number(input));
    }

    static handleError(error: any) {
        const message = error.error.response || error.error.message || 'UNKNOWN_ERROR';
        return throwError(error.error.details ? { message, details: error.error.details } : message);
    }

    static handleSuccess(res: any) {
        if (res.status === 'ok') {
            return res.response;
        } else {
            return throwError(res);
        }
    }

    static handlePagedSuccess(res: any) {
        if (res.status === 'ok') {
            return res;
        } else {
            return throwError(res);
        }
    }

    static buildSort(sort: ISort): string {
        if (Utils.isEmpty(sort)) { return ''; }
        if (Utils.isEmpty(sort.sortBy)) { return ''; }
        let result: string = '';
        result = '&sortBy=' + sort.sortBy;
        if (sort.sortAsc != null) { result = result + '&sortAsc=' + sort.sortAsc; }
        return result;
    }

    static toCamelCase(str: string): string {
        // tslint:disable-next-line:only-arrow-functions
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
            return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        }).replace(/\s+/g, '');
    }

    static unCamelCase(str: string): string {
        return str
            // insert a space between lower & upper
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            // space before last upper in a sequence followed by lower
            .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
            // uppercase the first character
            // tslint:disable-next-line:only-arrow-functions
            .replace(/^./, function (lstr) { return lstr.toUpperCase(); });
    }


    static sortData(chartDisplay: any[], chartInput: any[], ascending?: boolean) {
        let sortType = 'externalActivityId';
        let sortAscendBool = true;
        let sortedEndResult = [];
        let sortedDepth1Array = [];
        let sortedDepth1ParentsArray = [];
        let leveloneparentsArray = [];
        let depth = 1;

        // Created for storing chart Display to make ther sorted list only with filtered chart Display
        let chartDisplayOld = [];

        chartDisplay.forEach(element => {
          chartDisplayOld.push(element);
        });

        chartDisplay = [];
        chartInput.forEach(cI => {
          if (cI.children.length > 0 ) {
            leveloneparentsArray.push(cI);
          }
        });

        let emptyExternalIDDepth1ParentArray = [];
        let valuedExternalIdDepth1ParentArray = [];
        leveloneparentsArray.forEach(sd1 => {
          if (sd1.externalActivityId) {
            valuedExternalIdDepth1ParentArray.push(sd1);
          }
          else {
            emptyExternalIDDepth1ParentArray.push(sd1);
          }

        });

        let number: boolean = false;
        for (let i = 0; i < valuedExternalIdDepth1ParentArray.length; i++) {
          if (valuedExternalIdDepth1ParentArray[i].externalActivityId) {
            let result = parseInt(valuedExternalIdDepth1ParentArray[i].externalActivityId, 10);
            if (isNaN(result)) {
              number = false;
              break;
            }
            else {
              number = true;
              // break;
            }
          }
        };

        valuedExternalIdDepth1ParentArray.sort(function (a, b) {
          if (number == false) {
            if (a.externalActivityId && b.externalActivityId) {
              const nameA = a.externalActivityId.toLowerCase(),
                nameB = b.externalActivityId.toLowerCase();
              if (nameA < nameB) // sort string ascending
                return -1;
              if (nameA > nameB)
                return 1;
              return 0; // default return value (no sorting)
              // return D3.ascending(a.duration, b.duration);
            }
          }
          else {
            return D3.ascending(parseInt(a.externalActivityId), parseInt(b.externalActivityId));
          }
        });
        leveloneparentsArray = [];
        leveloneparentsArray.push(...valuedExternalIdDepth1ParentArray, ...emptyExternalIDDepth1ParentArray);

        leveloneparentsArray.forEach(level1Parent => {
          chartInput.forEach(cI => {
            if (cI.children.length > 0 ) {

              if (level1Parent.id === cI.id) {
                if (cI.expanded) {
                  sortedDepth1ParentsArray.push(cI);
                  let temparray: any = Utils.SortChildrenRecussive(cI.id, chartInput, sortAscendBool, sortType);
                  sortedDepth1ParentsArray.push(...temparray);
                }
                else {
                  sortedDepth1ParentsArray.push(cI);
                }
              }
            }
          });
        });

        // });
        // else {
        chartInput.forEach(cI => {
          if (cI.children.length == 0 )
            sortedDepth1Array.push(cI);
          // }
        });

        // sort by Duration

        switch (sortType) {
          case 'externalActivityId':
            // if (sortAscendBool) {
            let emptyExternalIDDepth1Array = [];
            let valuedExternalIdDepth1Array = [];
            sortedDepth1Array.forEach(sd1 => {
              if (sd1.externalActivityId) {
                valuedExternalIdDepth1Array.push(sd1);
              }
              else {
                emptyExternalIDDepth1Array.push(sd1);
              }

            });
            for (let i = 0; i < valuedExternalIdDepth1Array.length; i++) {
              if (valuedExternalIdDepth1Array[i].externalActivityId) {
                let result = parseInt(valuedExternalIdDepth1Array[i].externalActivityId, 10);
                if (isNaN(result)) {
                  number = false;
                  break;
                }
                else {
                  number = true;
                  // break;
                }
              }
            };
            valuedExternalIdDepth1Array.sort(function (a, b) {
              if (number == false) {
                if (a.externalActivityId && b.externalActivityId) {
                  const nameA = a.externalActivityId.toLowerCase(),
                    nameB = b.externalActivityId.toLowerCase();
                  if (nameA < nameB) // sort string ascending
                    return -1;
                  if (nameA > nameB)
                    return 1;
                  return 0; // default return value (no sorting)
                  // return D3.ascending(a.duration, b.duration);
                }
              }
              else {
                return D3.ascending(parseInt(a.externalActivityId), parseInt(b.externalActivityId));
              }
            });

            sortedDepth1Array = [];
            sortedDepth1Array.push(...valuedExternalIdDepth1Array, ...emptyExternalIDDepth1Array);


            break;
          case 'dur':
            sortedDepth1Array.sort(function (a, b) {
              if (sortAscendBool)
                return D3.ascending(a.duration, b.duration);
              else
                return D3.descending(a.duration, b.duration);
            });
            break;
          case 'name':
            if (sortAscendBool) {
              sortedDepth1Array.sort(function (a, b) {
                const nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB) // sort string ascending
                  return -1;
                if (nameA > nameB)
                  return 1;
                return 0; // default return value (no sorting)
              });
            }
            else {
              sortedDepth1Array.sort(function (a, b) {
                const nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA > nameB) // sort string ascending
                  return -1;
                if (nameA < nameB)
                  return 1;
                return 0; // default return value (no sorting)
              });
            }
            break;
          case 'startdate':
            if (sortAscendBool) {
              sortedDepth1Array.sort(function (a, b) {
                return D3.ascending(a.startDate, b.startDate);
              });
            }
            else {
              sortedDepth1Array.sort(function (a, b) {
                return D3.descending(a.startDate, b.startDate);
              });
            }
            break;
          case 'enddate':
            if (sortAscendBool) {
              sortedDepth1Array.sort(function (a, b) {
                return D3.ascending(a.endDate, b.endDate);
              });
            }
            else {
              sortedDepth1Array.sort(function (a, b) {
                return D3.descending(a.endDate, b.endDate);
              });
            }
            break;
        }

        sortedEndResult.push(...sortedDepth1Array, ...sortedDepth1ParentsArray);

        // ChartDisplay will have elements that are present in chartDisplayOld
        sortedEndResult.filter(element => {
          if (element.id) {
            chartDisplayOld.forEach(el => {
              if (el.id === element.id)
                chartDisplay.push(element)
            })
          }
        })

        return chartDisplay;

      }

      static SortChildrenRecussive(parentID, chartInput, sortAscendBool, sortKey) {
        let SubParents = [];
        let nonParentsActivities = [];
        let MixedChartInputSubset = [];
        let sortedResultArray = [];
        chartInput.forEach(cI => {
          if (cI.parentId == parentID) {
            if (cI.children.length > 0)
              SubParents.push(cI);
            else {
              if (cI.children.length == 0)
                nonParentsActivities.push(cI);
            }
          }
        });

        //SortByDuration
        switch (sortKey) {
          case 'externalActivityId':
            let number: boolean = false;
            let emptyExternalIDDepth1Array = [];
            let valuedExternalIdDepth1Array = [];
            nonParentsActivities.forEach(sd1 => {
              if (sd1.externalActivityId) {
                valuedExternalIdDepth1Array.push(sd1);
              }
              else {
                emptyExternalIDDepth1Array.push(sd1);
              }

            });

            for (let i = 0; i < valuedExternalIdDepth1Array.length; i++) {
              if (valuedExternalIdDepth1Array[i].externalActivityId) {
                let result = parseInt(valuedExternalIdDepth1Array[i].externalActivityId, 10);
                if (isNaN(result)) {
                  number = false;
                  break;
                }
                else {
                  number = true;
                  // break;
                }
              }
            };

            valuedExternalIdDepth1Array.sort(function (a, b) {
              if (number == false) {
                if (a.externalActivityId && b.externalActivityId) {
                  const nameA = a.externalActivityId.toLowerCase(),
                    nameB = b.externalActivityId.toLowerCase();
                  if (nameA < nameB) // sort string ascending
                    return -1;
                  if (nameA > nameB)
                    return 1;
                  return 0; // default return value (no sorting)
                  // return D3.ascending(a.duration, b.duration);
                }
              }
              else {
                return D3.ascending(parseInt(a.externalActivityId), parseInt(b.externalActivityId));
              }
            });
            nonParentsActivities = [];
            nonParentsActivities.push(...valuedExternalIdDepth1Array, ...emptyExternalIDDepth1Array);


            break;
          case 'dur':
            nonParentsActivities.sort(function (a, b) {
              if (sortAscendBool)
                return D3.ascending(a.duration, b.duration);
              else
                return D3.descending(a.duration, b.duration);
            });
            break;
          case 'name':
            if (sortAscendBool) {
              nonParentsActivities.sort(function (a, b) {
                const nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB) // sort string ascending
                  return -1;
                if (nameA > nameB)
                  return 1;
                return 0; // default return value (no sorting)
                // return D3.ascending(a.duration, b.duration);
              });
            }
            else {
              nonParentsActivities.sort(function (a, b) {
                const nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA > nameB) // sort string ascending
                  return -1;
                if (nameA < nameB)
                  return 1;
                return 0; // default return value (no sorting)
                // return D3.ascending(a.duration, b.duration);
              });
            }
            break;
          case 'startdate':
            if (sortAscendBool) {
              nonParentsActivities.sort(function (a, b) {
                return D3.ascending(a.startDate, b.startDate);
              });
            }
            else {
              nonParentsActivities.sort(function (a, b) {
                return D3.descending(a.startDate, b.startDate);
              });
            }
            break;
          case 'enddate':
            if (sortAscendBool) {
              nonParentsActivities.sort(function (a, b) {
                return D3.ascending(a.endDate, b.endDate);
              });
            }
            else {
              nonParentsActivities.sort(function (a, b) {
                return D3.descending(a.endDate, b.endDate);
              });
            }
            break;
        }

        // For loop for each parent
        let recursiveSortedOutput = [];
        if (SubParents) {
          let number: boolean = false;
          for (let i = 0; i < SubParents.length; i++) {
            if (SubParents[i].externalActivityId) {
              let result = parseInt(SubParents[i].externalActivityId, 10);
              if (isNaN(result)) {
                number = false;
                break;
              }
              else {
                number = true;
                // break;
              }
            }
          };

          SubParents.sort(function (a, b) {
            if (number == false) {
              if (a.externalActivityId && b.externalActivityId) {
                const nameA = a.externalActivityId.toLowerCase(),
                  nameB = b.externalActivityId.toLowerCase();
                if (nameA < nameB) // sort string ascending
                  return -1;
                if (nameA > nameB)
                  return 1;
                return 0; // default return value (no sorting)
                // return D3.ascending(a.duration, b.duration);
              }
            }
            else {
              return D3.ascending(parseInt(a.externalActivityId), parseInt(b.externalActivityId));
            }
          });

          SubParents.forEach(sP => {
            recursiveSortedOutput.push(sP);
            if (sP.expanded) {
              let tempArray: any = this.SortChildrenRecussive(sP.id, chartInput, sortAscendBool, sortKey);
              recursiveSortedOutput.push(...tempArray);
            }
          });
        }
        sortedResultArray.push(...nonParentsActivities, ...recursiveSortedOutput);
        return sortedResultArray;
      }


    static sortByString(items: any[], field: string, ascending?: boolean): any[] {
        if (Utils.isEmpty(ascending)) ascending = true;
        if (Utils.isEmptyList(items)) { return items; }
        // tslint:disable-next-line:only-arrow-functions
        items.sort(function (a, b) {
            const nameA = Utils.isEmpty(a[field]) ? '-' : a[field].toString().toUpperCase();
            const nameB = Utils.isEmpty(b[field]) ? '-' : b[field].toString().toUpperCase();
            if (ascending) {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            }

        });
        return items;
    }

    static sortArrayByObjectKeyString(items: any[], objectKey: string, field: string, ascending: boolean = true): any[] {
        if (items.length < 1) return [];
        // tslint:disable-next-line:only-arrow-functions
        items.sort(function (a, b) {
            const nameA = a[objectKey][field].toString().toUpperCase();
            const nameB = b[objectKey][field].toString().toUpperCase();
            if (ascending) {
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            } else {
                if (nameA > nameB) return -1;
                if (nameA < nameB) return 1;
                return 0;
            }

        });
        return items;
    }

    static sortByNumber(items: any[], field: string, ascending: boolean) {
        return items.sort((a, b) => {
            const x = a[field] ? a[field] : 0;
            const y = b[field] ? b[field] : 0;
            if (ascending) {
                return ((x < y)
                    ? -1
                    : ((x > y) ? 1 : 0));
            } else {
                return ((x > y)
                    ? -1
                    : ((x < y) ? 1 : 0));
            }
        });
    }

    static sortObjectArrayByKey(object: any, key: string): any {
        const propComparator = (propName) => (a, b) => a[propName].toLowerCase() === b[propName].toLowerCase() ? 0 : a[propName].toLowerCase() < b[propName].toLowerCase() ? -1 : 1;
        return object.sort(propComparator(key));
    }

    static sortObjectByKey(object: any): any {
        const orderedObject = {};

        Object.keys(object).sort().forEach(key => {
            orderedObject[key] = object[key];
        });

        return orderedObject;
    }

    static sortArrayByObjectKeyNumber(items: any[], objectKey: string, field: string, ascending: boolean = true) {
        return items.sort((a, b) => {
            const x = a[objectKey][field] ? a[objectKey][field] : 0;
            const y = b[objectKey][field] ? b[objectKey][field] : 0;
            if (ascending) {
                return ((x < y)
                    ? -1
                    : ((x > y) ? 1 : 0));
            } else {
                return ((x > y)
                    ? -1
                    : ((x < y) ? 1 : 0));
            }
        });
    }

    static groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return Array.from(map);
    }

    static objectIsEmpty(object: any) {
        return Object.keys(object).length === 0;
    }

    static objectHasNull(object: any) {
        for (const prop in object) {
            if (object[prop] == null)
                return true;
        }
        return false;
    }

    static checker(arr, target) {
        return target.every(v => arr.includes(v));
    }

    static deDupeArray(array: any) {
        // tslint:disable-next-line:only-arrow-functions
        const uniqueArray = array.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        });
        return uniqueArray;
    }

    static deDupeObjArrayByKey(originalArray: any, key: string) {
        const trimmedArray = [];
        const values = [];
        let value;

        originalArray.forEach((_obj, i) => {
            value = originalArray[i][key];

            if (values.indexOf(value) === -1) {
                trimmedArray.push(originalArray[i]);
                values.push(value);
            }
        });

        return trimmedArray;
    }

    static uniqueValuesInArray(array: any, field: string) {
        const valuesArray = array.map(item => item[field]);
        return valuesArray.filter((v, i) => valuesArray.indexOf(v) === i);
    }

    static objectInArray(array: any, key: string, value: string) {
        const filterdArray = array.filter(item => item[key] === value);
        return filterdArray.length > 0;
    }

    static sleep(ms: number, ngZone: NgZone): Promise<any> {
        return new Promise(resolve => {
            ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    ngZone.run(() => {
                        resolve();
                    });
                }, ms);
            });
        });
    }

    static getCriticalityColor(value: number): string {
        const hue = ((1 - value) * 120).toString(10);
        return ['hsl(', hue, ',100%,50%)'].join('');
    }

    static textBlackOrWhiteHex(hexValue: string): string {
        if (hexValue === 'white') return 'black';
        if (hexValue === 'black') return 'white';
        if (hexValue.indexOf('#') === 0) {
            hexValue = hexValue.slice(1);
        }
        const r = parseInt(hexValue.slice(0, 2), 16);
        const g = parseInt(hexValue.slice(2, 4), 16);
        const b = parseInt(hexValue.slice(4, 6), 16);
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? 'black'
            : 'white';
    }

    static textBlackOrWhiteRGB(rgb: string): string {
        const rgbArr = rgb.match(/\d+/g);
        const r = parseInt(rgbArr[0], 10);
        const g = parseInt(rgbArr[1], 10);
        const b = parseInt(rgbArr[2], 10);
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? 'black'
            : 'white';
    }

    static replaceAll(original: string, search: string, replace: string): string {
        return original.split(search).join(replace);
    }

    static arraysIdentical(array1: any[], array2: any[]): boolean {
        let i = array1.length;

        if (i !== array2.length) {
            return false;
        }

        while (i--) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
        return true;
    }

    static similarValuesInArrays(array1: any[], array2: any[]): any[] {
        const sameVals = [];
        array1.forEach(a1 => {
            if (array2.includes(a1)) sameVals.push(a1);
        });

        return sameVals;
    }

    static getObjectEntries(obj) {
        // tslint:disable-next-line:one-variable-per-declaration
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    }

    static hourDropdownItems() {
        let hours; let minutes; const list = [];
        for (let i = 0; i <= 1380; i += 60) {
            hours = Math.floor(i / 60);
            minutes = i % 60;
            if (minutes < 10) {
                minutes = '0' + minutes; // adding leading zero to minutes portion
            }
            list.push({ value: hours, label: moment(hours + '-' + minutes, 'h:mm').format('h:mm a') });
        }
        return list;
    }

    static fromUtcDate(time: number) {
        return new Date(Math.floor(time / 86400000) * 86400000 + new Date(time).getTimezoneOffset() * 60000);
    }

    static toUtcDate(date: Date) {
        return Math.floor((date.getTime() - date.getTimezoneOffset() * 60000) / 86400000) * 86400000;
    }

    static convertFromUtcToDate(date: number): Date {
        const utcDate = Utils.fromUtcDate(date);
        return new Date(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate());
    }

    static getUtcYesterday() {
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        return this.toUtcDate(yesterday);
    }

    static getUtcSubstract(time: number, days?: number) {

        let timeDate = new Date(Math.floor(time / 86400000) * 86400000 + new Date(time).getTimezoneOffset() * 60000);
        const substractedDate = new Date(timeDate.setDate(timeDate.getDate() - days));
        return Math.floor((substractedDate.getTime() - substractedDate.getTimezoneOffset() * 60000) / 86400000) * 86400000;
    }

    static getCurrentDate() {
        return new Date().getTime();
    }

    static formatDateTime(date: number, utc?: boolean, excludeYear?: boolean): string {
        let utcDate;
        if (!utc) utcDate = Utils.fromUtcDate(date);
        else utcDate = date;
        const format = { month: 'numeric', day: '2-digit', hour: 'numeric', minute: '2-digit' };
        if (!excludeYear) format['year'] = 'numeric';
        return new Date(utcDate).toLocaleTimeString(navigator.language, format);
    }

    static formatDate(date: number): string {
        const utcDate = Utils.fromUtcDate(date);
        const format = { month: '2-digit', day: '2-digit', year: 'numeric' };
        return new Date(utcDate).toLocaleDateString(navigator.language, format);
    }

    static bytesToString(bytes) {
        const thresh = 1000;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    static TempId(): string {
        return Math.random().toString(36).substr(2, 9);
    }


    static isTempId(id: string): boolean {
        if (id.includes('-')) return false;
        return true;
    }

    static defaultDisplayFn(item: any): string { return item && item.name ? item.name : undefined; }

    static async gzip(buffer: Buffer) {
        return Buffer.from(pako.deflate(buffer));
    }

    static async gunzip(buffer: Buffer) {
        return Buffer.from(pako.inflate(buffer));
    }

    static async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    static dist(pointA, pointB) {
        return Math.sqrt(Math.pow((pointA.x - pointB.x), 2) + Math.pow((pointA.y - pointB.y), 2));
    }

    static formatUnitOfMeasure(value: number, unitOfMeasure: string, isMetric: boolean, raw?: boolean): string {
        if (unitOfMeasure === UnitOfMeasure.Each) return raw ? value.toString() : Math.ceil(value).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
        else if (unitOfMeasure === UnitOfMeasure.Length) {
            if (isMetric) return raw ? Math.ceil(value).toString() : Math.ceil(value).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
            // tslint:disable-next-line:max-line-length
            else return raw ? Math.ceil(convert(value).from('m').to('ft')).toString() : Math.ceil(convert(value).from('m').to('ft')).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
        } else if (unitOfMeasure === UnitOfMeasure.SurfaceArea) {
            if (isMetric) return raw ? Math.ceil(value).toString() : Math.ceil(value).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
            // tslint:disable-next-line:max-line-length
            else return raw ? Math.ceil(convert(value).from('m2').to('ft2')).toString() : Math.ceil(convert(value).from('m2').to('ft2')).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
        } else if (unitOfMeasure === UnitOfMeasure.Volume) {
            if (isMetric) return raw ? Math.ceil(value).toString() : Math.ceil(value).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
            // tslint:disable-next-line:max-line-length
            else return raw ? Math.ceil(convert(value).from('m3').to('yd3')).toString() : Math.ceil(convert(value).from('m3').to('yd3')).toLocaleString(navigator.language) + ` ${Utils.getUnitOfMeasureLabel(unitOfMeasure, isMetric)}`;
        }
        return value.toString();
    }

    static getUnitOfMeasureLabel(unitOfMeasure: UnitOfMeasure, isMetric: boolean): string {
        switch (unitOfMeasure) {
            case UnitOfMeasure.Each:
                return 'EA';
            case UnitOfMeasure.Length:
                return isMetric ? 'M' : 'FT';
            case UnitOfMeasure.SurfaceArea:
                return isMetric ? 'M2' : 'SF';
            case UnitOfMeasure.Volume:
                return isMetric ? 'M3' : 'CYD';
            case UnitOfMeasure.ManDay:
                return 'MD';
            case UnitOfMeasure.ManHour:
                return 'MH';
        }
    }

    // static getObjectsMeasure(unitOfMeasure: UnitOfMeasure, measurements): number {
    //     switch (unitOfMeasure) {
    //         case UnitOfMeasure.Each:
    //             return measurements.each;
    //         case UnitOfMeasure.Length:
    //             return measurements.l;
    //         case UnitOfMeasure.SurfaceArea:
    //             return measurements.each;
    //         case UnitOfMeasure.Volume:
    //             return measurements.each;
    //     }
    // }

    static formatCurrency(value: number, currencyCode: string, decimalDigits ?: number) {
        if (!value) return null;
        const currency = Currencies.getCurrencies()[currencyCode];
        decimalDigits = decimalDigits ? decimalDigits : currency.decimal_digits;

        if (!currency) return value.toLocaleString(navigator.language);
        const roundedValue = value.toFixed(decimalDigits);
        return currency.symbol + ' ' + roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // headers object properties must correlate to data object properties
    static generateCsv(headers: { [key: string]: any }, data: any[]): { url: any, fileName: string } {
        const headerKeys = Object.keys(headers);

        const csvData =
            [headerKeys.map(k => headers[k])].concat(data.map(d => headerKeys.map(k => d[k])))
                .map(d => d.map(v => '"' + ('' + v).replace(/"/g, '""') + '"').join(',')).join('\r\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        return {
            url: window.URL.createObjectURL(blob),
            fileName: 'export_' + this.getCurrentDate() + '.csv'
        };
    }

    static formatDateToDayAndMonthString(date: number): string {
        const utcDate = Utils.fromUtcDate(date);
        return utcDate.toLocaleDateString(navigator.language, { month: 'numeric', day: 'numeric' });
    }

    static ParseAndTruncateString(string: string, index: number) {
        if (string && string.length > index)
            return string.substring(0, index) + "..";
        else
            return string;
    }

    static ParseAndTruncateStringForId(string: string, index: number, children: number) {
        if (children && children > 0)
            return "";
        else
            return Utils.ParseAndTruncateString(string, index);
    }

    static intersectionOfArrays(array1: any[], array2: any[]) {
        let commonElements = [];
        array1.forEach(element1 => {
            array2.forEach(element2 => {
                if (element1 === element2) {
                    commonElements.push(element1);
                }
            })
        })
        return commonElements;
    }

    static getCommonElementsFrom2DArray(array: any[][]): any | null {
        if (!array)
            return null;
        else {
            let elements = null;
            if (array.length > 0) {
                elements = array[0];

                array.forEach(items => {
                    elements = this.intersectionOfArrays(elements, items);
                })
            }
            return elements;
        }
    }

    static arrayhasCommonElements(array: any[]): boolean{
        if (!array)
            return false;
        else {
            const set1 = new Set(array);
            if (set1.size == 1) {
                return true;
            }
        }
        return false;
    }

    static getCommonElementFromArray(array: any[]): any | null {
        if (!array)
            return null;
        else {
            const set1 = new Set(array);
            if (set1.size == 1) {
                return array[0];
            }
        }
        return null;
    }

    static hexToRgbA(hex: string, opacity: number): string {
        let color
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            color = hex.substring(1).split('');
            if (color.length == 3) {
                color = [color[0], color[0], color[1], color[1], color[2], color[2]];
            }
            color = '0x' + color.join('');
            return 'rgba(' + [(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',') + ',' + String(opacity) + ')';
        }
        throw new Error('Bad Hex');
    }

    // function to find if given point
    // lies inside a given rectangle or not.
    static isInSideRect(left: number, top: number, width: number, height: number, x: number, y: number): boolean {
        if (x > left && x < left + width && y > top && y < top + height)
            return true;
        return false;
    }

    static annotationGetFactor(ele,fcWidth,zfac){
        return (ele*fcWidth)/(100*parseFloat(zfac));
    }

    static getEndDateExcludingWeekends(startDate: number, additions: number) {
      if (this.isEmpty(startDate)) return null;
      if(!additions) return startDate;
      let counter = 0;
      let endDate = startDate;
      while (counter !== (additions - 1)) {
        endDate = moment(endDate).add("days", 1).clone().valueOf();
        const whatDay = new Date(endDate).getDay();
        if (whatDay !== 0 && whatDay !== 6)
          counter = counter + 1;
      }

      return endDate;
    }

    static getExpectedDuration(startDate: number, endDate: number) {
      if (this.isEmpty(startDate) && this.isEmpty(endDate)) return null;
      if(startDate === endDate) return 0;
      let counter = startDate;
      let calculatedDays = 0;
      while (counter <= endDate) {
        const whatDay = new Date(counter).getDay();
        if (whatDay !== 0 && whatDay !== 6) calculatedDays = calculatedDays + 1;
        counter = counter + 86400000
      }
      return calculatedDays;
    }

    static annotationSetFactor(ele,fcWidth,zfac){
        return ((ele*100)/fcWidth)*parseFloat(zfac);
    }

    static convertHoursToDays(hours: number) {
        return Math.round(hours / 8);
    }

    static convertDaysToHours(days: number) {
        return (days * 8);
    }

}
