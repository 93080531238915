import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-generate-schedule-modal',
  templateUrl: './generate-schedule-modal.component.html',
  styleUrls: ['./generate-schedule-modal.component.scss']
})
export class GenerateScheduleModalComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() userNameInput: string;

  @Output() submitOutput: EventEmitter<{name: string}> = new EventEmitter();

  currentTime: string;

  get name() { return this.formInput.get('name'); }

  constructor() {}

  ngOnInit(){
    this.currentTime = Utils.formatDateTime(Date.now(), true, false);
  }

  onSubmit(form): void {
    const formValues = form.getRawValue();
    const formOutput = {
      name: formValues.name,
      honorCommitments: formValues.honorCommitments,
      sendActivationEmail: formValues.sendActivationEmail
    };
    this.submitOutput.emit(formOutput);
  }

}
