import { Component, OnDestroy, OnInit } from '@angular/core';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectEquipmentService } from '../../services/project/project-equipment/project-equipment.service';
import { ProjectMakeReadyService } from '../../services/project/project-make-ready/project-make-ready.service';
import { ProjectMaterialService } from '../../services/project/project-material/project-material.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectStepService } from '../../services/project/project-step/project-step.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectEquipment } from '../../models/project/project-equipment/project-equipment.interface';
import { MakeReadyBoardData, MakeReadyBoardItem } from '../../models/project/project-make-ready/project-make-ready.interface';
import { IProjectMaterial } from '../../models/project/project-material/project-material.interface';
import { IProjectSchedule, IProjectScheduleControls } from '../../models/project/project-schedule/project-schedule.interface';
import { IProjectViewModes } from '../../models/project/project-sprint/project-sprint.interface';
import { IPageSpecificFilter, ISidebarTab } from '../../models/sidebar/sidebar.interface';

import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { cloneDeep } from 'lodash';
import { MakeReadyStatus } from '../../utils/enums/make-ready-status.enum';
import { MessageType } from '../../utils/enums/message-type.enum';
import { EventType } from '../../utils/enums/notification.enum';
import { ViewMode } from '../../utils/enums/shared.enum';
import { SidebarState } from '../../utils/enums/sidebar-state';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-make-ready',
  templateUrl: './project-make-ready.component.html',
  styleUrls: ['./project-make-ready.component.scss']
})
export class ProjectMakeReadyComponent implements OnInit, OnDestroy {

  activeSchedule: IProjectSchedule;
  activateScheduleWarning: boolean = false;

  allMaterials: IProjectMaterial[] = [];
  allEquipment: IProjectEquipment[] = [];

  today = Utils.getCurrentDate();

  boardData: MakeReadyBoardData;
  filteredBoardData: MakeReadyBoardData;
  selectedPlanStepIds: string[] = [];
  selectedStepIds: string[] = [];

  makeReadyStatusEnum = MakeReadyStatus;

  showingModal: boolean;
  action: {
    movedItem: MakeReadyBoardItem;
    oldStatus: MakeReadyStatus;
    newStatus: MakeReadyStatus;
  };

  dateInput;

  isLoading: boolean;
  errorGettingData: boolean;

  // Sidebar
  sidebarTabs: ISidebarTab[];
  activeSidebarState: string;
  sidebarIsLoading: boolean = false;
  sidebarState = SidebarState;
  pageSpecificFilters: IPageSpecificFilter[] = [
    { label: 'equipment', checked: true, disabled: false, value: FilterModelOptions.Equipment },
    { label: 'materials', checked: true, disabled: false, value: FilterModelOptions.Materials }
  ];
  showMaterials: boolean = true;
  showEquipment: boolean = true;
  messageType = MessageType.TASK;

  // dragulaSubscription: Subscription;

  // Subscription Killer
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectService: ProjectService,
    private projectScheduleService: ProjectScheduleService,
    private projectStepService: ProjectStepService,
    private materialService: ProjectMaterialService,
    private equipmentService: ProjectEquipmentService,
    private makeReadyService: ProjectMakeReadyService,
    private dragulaService: DragulaService,
    private notificationService: NotificationService,
    private segmentService: SegmentService
  ) {
    window.addEventListener('keydown', this.keyPress);
    dragulaService.drop.pipe(takeUntil(this.destroyed$)).subscribe(async (moveData) => {
      const newStatus = Number(moveData[2].id);
      const oldStatus = Number(moveData[3].id);
      const movedItem = this.filteredBoardData[MakeReadyStatus[oldStatus]].items.find(item => item.id === moveData[1].id);
      this.action = {
        movedItem: movedItem,
        oldStatus: oldStatus,
        newStatus: newStatus
      };
      if (newStatus < oldStatus) {
        this.moveItem(movedItem, oldStatus, newStatus, Date.now());
        this.sortBoardItems();
      } else {
        this.showModal();
      }
     });

    dragulaService.dragend.pipe(takeUntil(this.destroyed$)).subscribe((moveData) => {
      this.sortBoardItems();
    });

    const bag: any = this.dragulaService.find('first-bag');
    if (bag !== undefined ) this.dragulaService.destroy('first-bag');
    dragulaService.setOptions('first-bag', {
      accepts: (el, target, source, sibling) => {
        let validMove = target.id !== source.id && !target.classList.contains('no-drag');
        if (el.attributes['data-set'].value === 'equipment') {
          validMove = validMove && (Number(target.id) === MakeReadyStatus.scheduled || Number(target.id) === MakeReadyStatus.ready);
        } else {
          const lanesMoved = Number(target.id) - Number(source.id);
          validMove = validMove && lanesMoved <= 1;
        }
        return validMove;
      },
      moves: (el, source, handle, sibling) => !el.classList.contains('no-drag')
    });

    this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe(async event => {
      if (!this.notificationService.validEvent(event, this.projectService.currentProject.id, EventType.MATERIAL_UPDATED)) return;
      this.loadPageData();
    });
  }

  async ngOnInit() {
    this.segmentService.track('Make Ready Board Loaded', { projectId: this.projectService.currentProject.id });
    this.loadPageData();
  }

  async loadPageData() {
    this.showingModal = false;
    this.isLoading = true;
    this.setupSideBar();
    await this.equipmentService.setLocalAllProjectEquipment(this.projectService.currentProject.id);
    await this.materialService.setLocalAllProjectMaterials(this.projectService.currentProject.id);
    this.allMaterials = ProjectService.userPermission.subContractorId ? this.materialService.getLocalUserProjectMaterials() : this.materialService.getLocalAllProjectMaterials();
    this.allEquipment = ProjectService.userPermission.subContractorId ? this.equipmentService.getLocalUserProjectEquipment() : this.equipmentService.getLocalAllProjectEquipment();
    this.projectScheduleService.getActiveScheduleMakeReadyTasks(this.projectService.currentProject.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.isLoading = false;
          this.errorGettingData = false;
          this.activeSchedule = res;
          this.transformBoardData();
        },
        err => {
          this.isLoading = false;
          this.errorGettingData = true;
          if(err==="activate schedule"){
            this.activateScheduleWarning = true;
          } else{
            const context: INoficationContext = {
              type: 'get make ready tasks',
              action: 'get'
            };
            this.notificationService.error(err, context);
          }
        }
      );
  }

  transformBoardData(viewMode: ViewMode = ViewMode.All) {
    this.boardData = this.makeReadyService.transformMakeReadyBoardData(this.activeSchedule);
    this.filterModel(this.makeReadyService.getActiveFilters());
    this.sortBoardItems();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  showModal() {
    this.dateInput = Utils.fromUtcDate(Date.now());
    this.showingModal = true;
  }

  confirmModal() {
    this.showingModal = false;
    this.moveItem(this.action.movedItem, this.action.oldStatus, this.action.newStatus, Utils.toUtcDate(this.dateInput));
    this.sortBoardItems();
  }

  moveItem(movedItem: MakeReadyBoardItem, oldStatus: MakeReadyStatus, newStatus: MakeReadyStatus, timestamp: number) {
    if (oldStatus === MakeReadyStatus.scheduled) {
      this.moveItemFromScheduled(movedItem, newStatus, timestamp);
    } else if (newStatus === MakeReadyStatus.scheduled) {
      this.moveItemToScheduled(movedItem, timestamp);
    } else {
      this.updateMakeReadyStatus(movedItem, movedItem.steps[0].stepId, newStatus, timestamp);
      movedItem[movedItem.type].makeReadyStatus = newStatus;
      const dueDate = movedItem.type === 'material'
        ? this.makeReadyService.getMaterialDueDate(movedItem.material, movedItem.steps[0].scheduledStart)
        : movedItem.steps[0].scheduledStart;
      movedItem.dueDate = dueDate;
      movedItem.displayDate = Utils.formatDate(dueDate);
      movedItem.tMinusDisplay = this.makeReadyService.getTMinusHours(dueDate);
      movedItem.pastDue = this.makeReadyService.getPastDue(dueDate);
    }
  }

  moveItemFromScheduled(movedItem: MakeReadyBoardItem, newStatus: MakeReadyStatus, timestamp: number) {
    if (newStatus === MakeReadyStatus.approved) {
      this.approveItem(movedItem, true, timestamp);
    } else {
      movedItem.steps.forEach(step => {
        this.updateMakeReadyStatus(movedItem, step.stepId, newStatus, timestamp);
      });
    }
    movedItem[movedItem.type].makeReadyStatus = newStatus;
    movedItem.steps.forEach((step, i) => {
      const dueDate = movedItem.type === 'material'
        ? this.makeReadyService.getMaterialDueDate(movedItem.material, step.scheduledStart)
        : step.scheduledStart;
      if (i > 0) {
        const newItem = {
          id: step.stepId,
          dueDate: dueDate,
          displayDate: Utils.formatDate(dueDate),
          tMinusDisplay: this.makeReadyService.getTMinusHours(dueDate),
          pastDue: this.makeReadyService.getPastDue(dueDate),
          type: movedItem.type,
          steps: [step],
          active: false,
          expanded: false,
          readOnly: false,
        };
        newItem[movedItem.type] = movedItem[movedItem.type];
        this.filteredBoardData[MakeReadyStatus[newStatus]].items.push(newItem);
      } else {
        movedItem.id = step.stepId;
        movedItem.dueDate = dueDate;
        movedItem.displayDate = Utils.formatDate(dueDate);
        movedItem.tMinusDisplay = this.makeReadyService.getTMinusHours(dueDate);
        movedItem.pastDue = this.makeReadyService.getPastDue(dueDate);
      }
    });
    movedItem.steps.splice(1, movedItem.steps.length - 1);
  }

  moveItemToScheduled(movedItem: MakeReadyBoardItem, timestamp: number) {
    this.approveItem(movedItem, false, timestamp);
    movedItem[movedItem.type].makeReadyStatus = MakeReadyStatus.scheduled;
    this.filteredBoardData['approved'].items
      .concat(this.filteredBoardData['ordered'].items)
      .concat(this.filteredBoardData['delivered'].items)
      .concat(this.filteredBoardData['ready'].items).filter(item => item.type === movedItem.type && item[movedItem.type].id === movedItem[movedItem.type].id).forEach(item => {
      if (!movedItem.steps.find(step => step.stepId === item.steps[0].stepId)) {
        movedItem.steps.push(item.steps[0]);
      }
      movedItem.id = movedItem[movedItem.type].id;
    });
    movedItem.steps = Utils.sortByNumber(movedItem.steps, 'scheduledStart', true);
    const dueDate = movedItem.type === 'material' ?
      this.makeReadyService.getMaterialDueDate(movedItem.material, movedItem.steps[0].scheduledStart)
      : movedItem.steps[0].scheduledStart;
    movedItem.dueDate = dueDate;
    movedItem.displayDate = Utils.formatDate(dueDate);
    movedItem.tMinusDisplay = this.makeReadyService.getTMinusHours(dueDate);
    movedItem.pastDue = this.makeReadyService.getPastDue(dueDate);
    this.filteredBoardData['approved'].items = this.filteredBoardData['approved'].items.filter(item => item.type !== movedItem.type || item[movedItem.type].id !== movedItem[movedItem.type].id);
    this.filteredBoardData['ordered'].items = this.filteredBoardData['ordered'].items.filter(item => item.type !== movedItem.type || item[movedItem.type].id !== movedItem[movedItem.type].id);
    this.filteredBoardData['delivered'].items = this.filteredBoardData['delivered'].items.filter(item => item.type !== movedItem.type || item[movedItem.type].id !== movedItem[movedItem.type].id);
    this.filteredBoardData['ready'].items = this.filteredBoardData['ready'].items.filter(item => item.type !== movedItem.type || item[movedItem.type].id !== movedItem[movedItem.type].id);
  }

  approveItem(item: MakeReadyBoardItem, approved: boolean, timestamp: number) {
    const service = item.type === 'material' ? this.materialService : this.equipmentService;
    this.projectService.setDisablePage(true);
    service.approve(item[item.type].id, approved, timestamp).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.projectService.setDisablePage(false);
      },
      err => {
        this.projectService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'update make ready status',
          action: 'put'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  updateMakeReadyStatus(item: MakeReadyBoardItem, stepId: string, status: MakeReadyStatus, timestamp: number) {
    const body = {
      makeReadyStatus: status,
      subcontractorId: item.steps[0].subcontractor.id,
      timestamp: timestamp
    };
    body[item.type + 'Id'] = item[item.type].id;
    this.projectService.setDisablePage(true);
    this.projectStepService.updateProjectStepMakeReadyStatus(stepId, body).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.projectService.setDisablePage(false);
      },
      err => {
        this.projectService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'update make ready status',
          action: 'put'
        };
        this.notificationService.error(err, context);
    });
  }

  selectItem(item: MakeReadyBoardItem, event: MouseEvent) {
    event.stopPropagation();

    this.getAllBoardItems().map(i => i.active = false);
    item.active = true;
    this.selectedPlanStepIds = item.steps.map(step => step.planStepId);
    this.selectedStepIds = item.steps.map(step => step.stepId);
  }

  selectAllItemsInTheLane(lane: MakeReadyStatus){
    let selectedPlanStepIds = [];
    let selectedStepIds = [];
    this.getAllBoardItems().map(i => i.active = false);
    if(this.filteredBoardData[lane].active){
      this.filteredBoardData[lane].active = false;
      this.filteredBoardData[lane].items.forEach(item => {
        item.active = false;
      });    
    } else{
      this.filteredBoardData[lane].active = true;
      this.filteredBoardData[lane].items.forEach(item => {
        item.active = true;
        selectedPlanStepIds.push(...item.steps.map(step => step.planStepId));
        selectedStepIds.push(...item.steps.map(step => step.stepId));
      });
    }
    this.selectedPlanStepIds = selectedPlanStepIds;
    this.selectedStepIds =  selectedStepIds;
  }

  getAllBoardItems(): MakeReadyBoardItem[] {
    return this.filteredBoardData['scheduled'].items
      .concat(this.filteredBoardData['approved'].items)
      .concat(this.filteredBoardData['ordered'].items)
      .concat(this.filteredBoardData['delivered'].items)
      .concat(this.filteredBoardData['ready'].items);
  }

  sortBoardItems() {
    Object.keys(this.filteredBoardData).forEach(status => {
      this.filteredBoardData[status].items = Utils.sortByNumber(this.filteredBoardData[status].items, 'dueDate', true);
    });
  }

  toggleItemExpand(item: MakeReadyBoardItem, e: any) {
    e.stopPropagation();
    item.expanded = !item.expanded;
  }

  keyPress = (event) => {
    if (event.keyCode === 13) {
      if (this.showingModal) {
        this.confirmModal();
      }
    }
  }

  // Sidebar
  setupSideBar(): void {
    this.sidebarTabs = this.makeReadyService.getSidebarTabs();
    this.setSidebarState(this.sidebarTabs[0]);
  }

  toggleSideBar(isVisible?: boolean): void {
    if (isVisible != null) this.projectService.toggleSidebarVisibility(isVisible);
    else this.projectService.toggleSidebarVisibility();

  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.activeSidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab: ISidebarTab): void {
    this.sidebarTabs.map(item => item.active = false);
    tab.active = true;
    this.activeSidebarState = tab.key;
    this.makeReadyService.setActiveFilterType(FilterModelOptions.Activities);

    this.segmentService.track('Make Ready Sidebar Loaded', { sideBar: tab.key });
  }

  setFilterType(type?: FilterModelOptions): void {
    const filterType = type ? type : FilterModelOptions.Activities;
    this.makeReadyService.setActiveFilterType(filterType);
    this.makeReadyService.setActiveFilters([]);
  }

  filterModel(filters: string[]): void {
    this.makeReadyService.setActiveFilters(filters);
    this.filteredBoardData = cloneDeep(this.boardData);
    if (filters.length === 0) {
      this.sortBoardItems();
      return;
    }
    if (this.makeReadyService.getActiveFilterType() === FilterModelOptions.Activities) {
      Object.keys(this.filteredBoardData).forEach(lane => {
        this.filteredBoardData[lane].items = this.filteredBoardData[lane].items.filter(item => {
          for (const step of item.steps) {
            if (step.activities.find(stepActivity => filters.includes(stepActivity))) {
              return true;
            }
            return false;
          }
        });
      });
    }
    if (this.makeReadyService.getActiveFilterType() === FilterModelOptions.SubContractor) {
      Object.keys(this.filteredBoardData).forEach(lane => {
        this.filteredBoardData[lane].items = this.filteredBoardData[lane].items.filter(item => {
          if (item.steps.find(step => filters.includes(step.subcontractor.id))) {
            return true;
          }
          return false;
        });
      });
    }
    this.pageFilterOutput({
      label: FilterModelOptions.Equipment,
      checked: this.showEquipment,
      disabled: false,
      value: FilterModelOptions.Equipment
    });
    this.pageFilterOutput({
      label: FilterModelOptions.Materials,
      checked: this.showMaterials,
      disabled: false,
      value: FilterModelOptions.Materials
    });
    this.sortBoardItems();
  }

  pageFilterOutput(filter: IPageSpecificFilter) {
    if (filter.value === FilterModelOptions.Equipment) {
      SegmentService.track(`Make Ready: Filter equipment`, {showEquipment: filter.checked});
      this.showEquipment = filter.checked;
      this.getAllBoardItems().map(item => {
        if (item.type === 'equipment') item.hidden = !filter.checked;
      });
    }
    if (filter.value === FilterModelOptions.Materials) {
      SegmentService.track(`Make Ready: Filter materials`, {showMaterials: filter.checked});
      this.showMaterials = filter.checked;
      this.getAllBoardItems().map(item => {
        if (item.type === 'material') item.hidden = !filter.checked;
      });
    }
  }
  // END MODEL FILTER
}
