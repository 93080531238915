import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ICosts, ICostsFlag } from '../../../models/activity/activity.interface';

@Component({
  selector: 'app-cost-form',
  templateUrl: './cost-form.component.html',
  styleUrls: ['./cost-form.component.scss']
})
export class CostFormComponent {

  @Input() costs: ICosts;
  @Input() bulkEdit: boolean;
  @Input() symbol: string;

  @Output() costOut: EventEmitter<ICosts> = new EventEmitter();
  @Output() costflagOut: EventEmitter<ICostsFlag> = new EventEmitter();

  costFlag: ICostsFlag;


  constructor() {/*EMPTY*/

    this.costFlag = {
      equipmentCost: false,
      laborCost: false,
      materialCost: false
    };
  }

  costChange(type: string) {

    if (type === 'equipmentCost') {
      this.costFlag.equipmentCost = true;
    }
    if (type === 'laborCost') {
      this.costFlag.laborCost = true;
    }
    if (type === 'materialCost') {
      this.costFlag.materialCost = true;
    }

    if (this.bulkEdit) {
      this.costflagOut.emit(this.costFlag);
    }

    if (this.costs.equipmentCost) this.costs.equipmentCost = Math.abs(Number(this.costs.equipmentCost.toFixed(2)));
    if (this.costs.laborCost) this.costs.laborCost = Math.abs(Number(this.costs.laborCost.toFixed(2)));
    if (this.costs.materialCost) this.costs.materialCost = Math.abs(Number(this.costs.materialCost.toFixed(2)));
    this.costOut.emit(this.costs);
  }

}
