import { Component, EventEmitter, Output } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-import-gantt-modal',
  templateUrl: './import-gantt-modal.component.html',
  styleUrls: ['./import-gantt-modal.component.scss']
})
export class ImportGanttModalComponent {

  //pUBLIXxmldOCUMENT
  XMLdoc: any;
  xmlType: string;
  xmlIndex = 0;
  //Mapping Variables
  mapName = "";
  mapExternelId = "";
  mapStartdate = "";
  mapEnddate = "";
  mapActualstartdate = "";
  mapActualfinishdate = "";
  mapDuration = "";
  mapSubcontractorCode = "";
  mapSubcontractorName = "";
  mapSubcontractorAbb = "";

  //Error String
  importError = "";


  //Priview Variables
  PreName = " ";
  PreExternelId = " ";
  PreStartdate = " ";
  PreEnddate = " ";
  PreActualstartdate = " ";
  PreActualfinishdate = " ";
  PreDuration = " ";
  PreSubcontractorCode = " ";
  PreSubcontractorName = " ";
  PreSubcontractorAbb = " ";

  //End Mapping Variables
  import_relation = '';
  //Btn Disable
  mapBTN = false;

  objArray = []; //List of tags

  subContractorValueArray = [];

  subContractors = [];

  //ImportVariable
  fileList: FileList;
  statVal: boolean;

  @Output() importClickOutput: EventEmitter<{ file: File, static: boolean, configuration: {} }> = new EventEmitter();
  showImport = true;
  constructor() {
    this.showImport = true;
  }

  importClick(event: MSInputMethodContext, stat: boolean): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const files: FileList = target.files;
    this.fileList = target.files;
    this.statVal = stat;
    this.showImport = false;
    let fileReader: FileReader = new FileReader();
    fileReader.onloadend = () => {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(fileReader.result.toString(), 'text/xml');
      this.XMLdoc = xmlDoc;
      var msDoc = "http://schemas.microsoft.com/project";
      var p6Doc = "http://xmlns.oracle.com/Primavera";
      if (xmlDoc.getElementsByTagName("Project")[0].namespaceURI === msDoc) {
        if (fileReader.result.toString().indexOf("<OutlineCodes>") > -1) {
          for (var i = 0; i < xmlDoc.getElementsByTagName("Task").length; i++) {
            if (xmlDoc.getElementsByTagName("Task")[i].outerHTML.toString().indexOf("OutlineCode") > -1) {
              this.xmlIndex = i;
              break;
            }
          }
          this.XMLdoc.getElementsByTagName("OutlineCodes")[0].getElementsByTagName("Value")[0].childNodes.forEach(element => {
            if (element.nodeName != "#text")
              this.subContractorValueArray.push(element.nodeName);
          });
        }
        this.MicrosoftFileReader(xmlDoc);
        this.xmlType = "MS";
        this.CatchMSRelation(xmlDoc);
      }
      else if (xmlDoc.getElementsByTagName("Project")[0].namespaceURI.indexOf(p6Doc) != -1) {
        if (fileReader.result.toString().indexOf("<ActivityCode>") > -1) {
          for (var i = 0; i < xmlDoc.getElementsByTagName("Activity").length; i++) {
            if (xmlDoc.getElementsByTagName("Activity")[i].outerHTML.toString().indexOf("Code") > -1) {
              this.xmlIndex = i;
              break;
            }
          }
          this.XMLdoc.getElementsByTagName("ActivityCode")[0].childNodes.forEach(element => {
            if (element.nodeName != "#text")
              this.subContractorValueArray.push(element.nodeName);
          });
        }
        this.xmlType = "P6";
        this.P6FileReader(xmlDoc)
      }
    }
    fileReader.readAsText(files[0]);
    let elements = document.getElementsByClassName("modal-content") as HTMLCollectionOf<HTMLElement>;
    elements[0].style.width = "675px";
  }

  MicrosoftFileReader(xmlDoc) {
    xmlDoc.getElementsByTagName("Task")[this.xmlIndex].childNodes.forEach(element => {
      if (element.nodeName != "#text")
        this.objArray.push(element.nodeName);
    });
    this.mapName = "Name";
    this.mapExternelId = "ID";
    this.mapStartdate = "Start";
    this.mapEnddate = "Finish";
    this.mapActualstartdate = "LateStart";
    this.mapActualfinishdate = "LateFinish";
    this.mapDuration = "Duration";
  }

  CheckChild (WBSKey,id) {
    let stat = false;
    WBSKey.forEach(element => {
        if(element.startsWith(id)){
            stat = true;
        }
    });
    return stat;  
  };

  CatchMSRelation(xmlDoc) {
    var list = xmlDoc.getElementsByTagName("WBS");
    var wbs = [];
    for (let item of list) {
      wbs.push(item.childNodes[0].data.toString());
    }
    var relMS = false;
    for (let i = 0; i < wbs.length; i++) {
      if (Number.isInteger(parseFloat(wbs[i]))) {
        var num = parseInt(wbs[i]);
        if (this.CheckChild(wbs,num+".")){
          relMS = true;
          continue;
        }
      }
    }
    var relEle = document.getElementById('resMapMSZ');
    if (relMS) {
      this.import_relation = 'import_relation';
      relEle.style.color = "green";
    }
    else {
      this.import_relation = 'import_relation_not';
      relEle.style.color = "red";
    }
    this.setPriviewValues();
  }

  P6FileReader(xmlDoc) {
    var relEle = document.getElementById('resMapMSZ');
    if (xmlDoc.getElementsByTagName("Relationship").length > 0) {
      this.import_relation = 'import_relation';
      relEle.style.color = "green";
    }
    else {
      this.import_relation = 'import_relation_not';
      relEle.style.color = "red";
    }
    xmlDoc.getElementsByTagName("Activity")[this.xmlIndex].childNodes.forEach(element => {
      if (element.nodeName != "#text")
        this.objArray.push(element.nodeName);
    });
    this.mapName = "Name";
    this.mapExternelId = "Id";
    this.mapStartdate = "StartDate";
    this.mapEnddate = "FinishDate";
    this.mapActualstartdate = "ActualStartDate";
    this.mapActualfinishdate = "ActualFinishDate";
    this.mapDuration = "PlannedDuration";
    this.setPriviewValues();
  }

  truncateString(str: String, indx) {
    if (str == undefined)
      return str;
    if (str.length > indx)
      return str.substring(0, indx);
    else
      return str;
  }

  updateWorkout(eve) {
    this.mapBTN = false;
    let x = [];
    x.push(document.getElementById("mapName") as HTMLElement);
    x.push(document.getElementById("mapExternelId") as HTMLElement);
    x.push(document.getElementById("mapStartdate") as HTMLElement);
    x.push(document.getElementById("mapEnddate") as HTMLElement);
    x.push(document.getElementById("mapActualstartdate") as HTMLElement);
    x.push(document.getElementById("mapActualfinishdate") as HTMLElement);
    x.push(document.getElementById("mapDuration") as HTMLElement);
    let y = [];
    for (let i = 0; i < x.length; i++)
      for (let j = i + 1; j < x.length; j++)
        if (x[i].value == x[j].value) {
          y.push(x[j]);
          y.push(x[i]);
        }
    x.forEach(ele => {
      ele.parentElement.style.backgroundColor = "transparent";
    });
    y.forEach(element => {
      element.parentElement.style.backgroundColor = "red";
      this.mapBTN = true;
    });
  }

  logValue(id) {
    var eleName = "";
    this.objArray.forEach(element => {
      if (element.index == id) {
        eleName = element.name;
      }
    });
    return eleName;
  }

  importXML() {
    var conf = {};
    conf["Name"] = this.mapName;
    conf["ExternelId"] = this.mapExternelId;
    conf["Startdate"] = this.mapStartdate;
    conf["Enddate"] = this.mapEnddate;
    conf["Actualstartdate"] = this.mapActualstartdate;
    conf["Actualfinishdate"] = this.mapActualfinishdate;
    conf["Duration"] = this.mapDuration;
    conf["subContractor"] = this.subContractors;
    this.importClickOutput.emit({ file: this.fileList[0], static: this.statVal, configuration: conf });
  }

  setPriviewValues() {
    if (this.xmlType == "P6") {
      this.XMLdoc.getElementsByTagName("Activity")[0].childNodes.forEach(element => {
        if (element.nodeName != "#text" && element.childNodes.length > 0) {
          if (element.nodeName == this.mapName) {
            this.PreName = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapExternelId) {
            this.PreExternelId = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapStartdate) {
            this.PreStartdate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapEnddate) {
            this.PreEnddate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapActualstartdate) {
            this.PreActualstartdate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapActualfinishdate) {
            this.PreActualfinishdate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapDuration) {
            this.PreDuration = element.childNodes[0].data.toString();
          }
        }
      });
    }
    if (this.xmlType == "MS") {
      this.XMLdoc.getElementsByTagName("Task")[this.xmlIndex].childNodes.forEach(element => {
        if (element.nodeName != "#text" && element.childNodes.length > 0) {
          if (element.nodeName == this.mapName) {
            this.PreName = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapExternelId) {
            this.PreExternelId = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapStartdate) {
            this.PreStartdate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapEnddate) {
            this.PreEnddate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapActualstartdate) {
            this.PreActualstartdate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapActualfinishdate) {
            this.PreActualfinishdate = element.childNodes[0].data.toString();
          }
          else if (element.nodeName == this.mapDuration) {
            this.PreDuration = element.childNodes[0].data.toString();
          }
        }
      });
    }
  }

  getSetSubcontractor() {
    if (this.xmlType == "P6") {
      this.subContractors = [];
      try {
        if (this.XMLdoc.getElementsByTagName("Activity")[this.xmlIndex].getElementsByTagName(this.mapSubcontractorCode)[0].getElementsByTagName("TypeObjectId")[0] != undefined) {
          for (var i = 0; i < this.XMLdoc.getElementsByTagName("ActivityCode").length; i++) {
            if (this.mapSubcontractorName == "") {
              this.importError = "import_select_SubVal";
              this.mapBTN = true;
            }
            else if (this.mapSubcontractorAbb == "") {
              this.importError = "import_select_SubAbb";
              this.mapBTN = true;
            }
            else {
              var name = "";
              var abb = "";
              var code = "";
              this.XMLdoc.getElementsByTagName("ActivityCode")[i].childNodes.forEach(element => {
                if (element.nodeName == this.mapSubcontractorName) {
                  name = element.innerHTML.toString();
                }
                else if (element.nodeName == this.mapSubcontractorAbb) {
                  abb = element.innerHTML.toString();
                }
                else if(element.nodeName == "ObjectId"){
                  code = element.innerHTML.toString();
                }
              });
              this.importError = "";
              this.mapBTN = false;
              if (name != "" && abb != "" && code != "")
                this.subContractors.push({ "name": name, "abb": abb, "code" : code });
            }
          }
        }
        else {
          this.mapBTN = true;
          this.importError = "import_select_SubErr";
        }
        if (this.mapSubcontractorName != "")
          this.PreSubcontractorName = this.XMLdoc.getElementsByTagName("ActivityCode")[0].getElementsByTagName(this.mapSubcontractorName)[0].innerHTML.toString();
        if (this.mapSubcontractorAbb != "")
          this.PreSubcontractorAbb = this.XMLdoc.getElementsByTagName("ActivityCode")[0].getElementsByTagName(this.mapSubcontractorAbb)[0].innerHTML.toString();;
        if (this.mapSubcontractorCode != "")
          this.PreSubcontractorCode = this.XMLdoc.getElementsByTagName("Activity")[this.xmlIndex].getElementsByTagName(this.mapSubcontractorCode)[0].getElementsByTagName("TypeObjectId")[0].innerHTML.toString();
      } catch (error) {
        this.mapBTN = true;
        this.importError = "import_select_SubErr";
        console.log(error);
      }
    }
    else if (this.xmlType == "MS") {
      try {
        this.subContractors = [];
        if (this.XMLdoc.getElementsByTagName("Task")[this.xmlIndex].getElementsByTagName(this.mapSubcontractorCode)[0].getElementsByTagName("ValueID")[0] != undefined) {
          for (var i = 0; i < this.XMLdoc.getElementsByTagName("OutlineCodes")[0].getElementsByTagName("Value").length; i++) {
            if (this.mapSubcontractorName == "") {
              this.importError = "import_select_SubVal";
              this.mapBTN = true;
            }
            else if (this.mapSubcontractorAbb == "") {
              this.importError = "import_select_SubAbb";
              this.mapBTN = true;
            }
            else {
              var name = "";
              var abb = "";
              var code = "";
              this.XMLdoc.getElementsByTagName("OutlineCodes")[0].getElementsByTagName("Value")[i].childNodes.forEach(element => {
                if (element.nodeName == this.mapSubcontractorName) {
                  name = element.innerHTML.toString();
                }
                else if (element.nodeName == this.mapSubcontractorAbb) {
                  abb = element.innerHTML.toString();
                }
                else if(element.nodeName == "ValueID") {
                  code = element.innerHTML.toString();
                }
              });
              this.importError = "";
              this.mapBTN = false;
              if (name != "" && abb != "" && code != "")
                this.subContractors.push({ "name": name, "abb": abb , "code" : code});
            }
          }
        }
        else {
          this.mapBTN = true;
          this.importError = "import_select_SubErr";
        }
        if (this.mapSubcontractorName != "")
          this.PreSubcontractorName = this.XMLdoc.getElementsByTagName("OutlineCodes")[0].getElementsByTagName("Value")[0].getElementsByTagName(this.mapSubcontractorName)[0].innerHTML.toString();
        if (this.mapSubcontractorAbb != "")
          this.PreSubcontractorAbb = this.XMLdoc.getElementsByTagName("OutlineCodes")[0].getElementsByTagName("Value")[0].getElementsByTagName(this.mapSubcontractorAbb)[0].innerHTML.toString();
        if (this.mapSubcontractorCode != "")
          this.PreSubcontractorCode = this.XMLdoc.getElementsByTagName("Task")[this.xmlIndex].getElementsByTagName(this.mapSubcontractorCode)[0].getElementsByTagName("ValueID")[0].innerHTML.toString();
      } catch (error) {
        this.mapBTN = true;
        this.importError = "import_select_SubErr";
      }
    }
  }

  OnDropDownChange(evt) {
    this.setPriviewValues();
    if (evt.target.id == "mapSubcontractorCode" || evt.target.id == "mapSubcontractorName" || evt.target.id == "mapSubcontractorAbb") {
      this.getSetSubcontractor();
    }
  }
}
