import { Component, ElementRef, EventEmitter, Input, IterableDiffers, NgZone, OnChanges, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';

import {
  ICustomActionOutput,
  ICustomTable,
  ICustomTablePagination,
  IFilterByOptions,
  IRow,
  IRowItem,
  ITableSearchOptions
} from '../../models/custom-table/custom-table.interface';
import { ISort } from '../../utils/models/sort.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: { '(document:click)': 'handleDocumentClick($event)' }
})
export class CustomTableComponent implements OnInit, OnChanges {
  @Input() tableDataInput: ICustomTable;
  @Input() tableEditPermissionInput: boolean;
  @Input() createRowValidationFunction: (rowInput: any) => boolean;
  @Input() headerBuffer: boolean = false;
  @Input() scrollContainerEl: any;
  @Input() isObjectsTable: boolean = false;
  @Input() searching = false;
  @Input() clearingSearch = false;
  @Input() createInline = true;
  @Input() editInline = true;

  @Output() confirmDeletionOutput: EventEmitter<IRow[]> = new EventEmitter();
  @Output() confirmEditOutput: EventEmitter<IRow[]> = new EventEmitter();
  @Output() hideOutput: EventEmitter<IRow[]> = new EventEmitter();
  @Output() searchOutput: EventEmitter<ITableSearchOptions> = new EventEmitter();
  @Output() sortByOutput: EventEmitter<ISort> = new EventEmitter();
  @Output() paginationStartOutput: EventEmitter<number> = new EventEmitter();
  @Output() resetTableOutput = new EventEmitter();
  @Output() presetFilterOutput: EventEmitter<IFilterByOptions> = new EventEmitter();
  @Output() confirmCreationOutput: EventEmitter<IRowItem[]> = new EventEmitter();
  @Output() setDropdownSelectionsOuput: EventEmitter<string[]> = new EventEmitter();
  @Output() dropdownQueryOutput = new EventEmitter<any>(true);
  @Output() dropdownPaginateOutput: EventEmitter<any> = new EventEmitter();
  @Output() setBulkEditSelectionOutput: EventEmitter<any> = new EventEmitter();
  @Output() listItemButtonOutput: EventEmitter<IRowItem> = new EventEmitter();
  @Output() rowClickOutput: EventEmitter<IRow | IRow[]> = new EventEmitter();
  @Output() rowHoverOutput: EventEmitter<IRow> = new EventEmitter();
  @Output() rowInfoClickOuptput: EventEmitter<IRow[]> = new EventEmitter();
  @Output() singleRowSelectionOutput: EventEmitter<IRow[]> = new EventEmitter();
  @Output() allRowsSelectionOutput: EventEmitter<IRow[]> = new EventEmitter();
  @Output() rowsResetOutput = new EventEmitter();
  @Output() createOutput = new EventEmitter();
  @Output() editOutput = new EventEmitter();
  @Output() customEditFnOutput: EventEmitter<ICustomActionOutput> = new EventEmitter(); // Used in HTML

  @ViewChildren('tableRow') tableRows;
  @ViewChildren('selectAll') selectAllCheck;
  @ViewChild('customFunctionDropdown') customFunctionDropdown;

  isLoading: boolean = true;
  adjustHeaderForPopout: boolean = false;
  showConfirmationBox: boolean = false;
  confirmationModalInput = {
    displayMessage: 'are_you_sure',
    hasCancelAction: true,
    hasConfirmationAction: true
  };
  curEdit: string = 'delete';
  searchQuery: string;
  activeSearchSelection: ITableSearchOptions;
  paginationInfo: ICustomTablePagination;
  paginationStart: number;
  paginationSize: number;
  sort: ISort;
  activeSortKey: string;
  showFilterDropdown: boolean = false;
  activePresetFilter: IFilterByOptions;
  activeRows: IRow[] = [];
  isCreating: boolean = false;
  createRow: IRowItem[];
  creationRowItem: any = {};
  elementRef;
  iterableDiffer: any;
  bulkHideState: boolean = true;
  disabled: boolean;
  totalSelectedRows: number = 0;
  allRowsSelected: boolean = false;
  createColumn: boolean = false;

  customFunctionDropdownSelection: any[] = [];

  constructor(
    elementRef: ElementRef,
    private forgeViewerService: ForgeViewerService,
    private _iterableDiffers: IterableDiffers,
    private ngZone: NgZone
  ) {
    this.elementRef = elementRef;
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);

    // TODO non angular way to get document just a quick fix ALSO remove when scroll Container El is a proper input
    // as long as one class exists run this (should only be one)
    if (document.getElementsByClassName('lower-component').length > 0) {
      this.scrollContainerEl = document.getElementsByClassName('lower-component')[0];
    }
  }

  ngOnInit() {
    this.adjustHeaderForPopout = this.forgeViewerService.poppedOut;
  }

  async ngOnChanges() {
    if (this.tableDataInput) {
      this.paginationInfo = this.setPaginationState(this.tableDataInput.pagination);

      this.setTableSortState(this.tableDataInput.columnHeaders);

      if (this.tableDataInput.searchable) {
        this.setSearchSelectionState(this.tableDataInput.searchOptions);
      }
      if (this.tableDataInput.filterByOptions) {
        this.setPresetFilterState(this.tableDataInput.filterByOptions);
      }
      if (this.tableDataInput.canCreate) {
        this.tableDataInput.columnHeaders.map(colHead => this.creationRowItem[colHead.key] = '');
        this.setCreateRow();
      }
      if (this.tableDataInput.customFunctionDropdown && !Utils.isEmptyList(this.tableDataInput.customFunctionDropdown.selected)) {
        const curSel = Utils.deDupeArray(this.customFunctionDropdownSelection);
        this.tableDataInput.customFunctionDropdown.selected.forEach(id => {
          if (!curSel.includes(id)) curSel.push(id);
        });
        this.customFunctionDropdownSelection = curSel;
      }

      // incase we need to clear the search query from an input or set a default search query then the tableDataInput would send it in
      // otherwise keep the searchQuery the same as what the user previously searched for
      this.searchQuery = this.tableDataInput.searchQuery !== undefined ? this.tableDataInput.searchQuery : this.searchQuery;

      // Bad way of doing this to fix number of Columns matching rows
      // if any rows are editable or removable and if there are any columns with the edit key and permissions then set this flag
      this.createColumn = this.tableDataInput.rows.some(r => r.editable === true || r.removeable === true || r.infoIcon === true)
                        && this.tableEditPermissionInput;

      // set pagination from input data
      this.paginationStart = this.tableDataInput.pagination.pageStart;
      this.paginationSize = this.tableDataInput.pagination.pageSize;

      // If select all, we need to make all rows active
      // Otherwise we need to get rows that are currently active
      if (this.isObjectsTable) {
        if (this.tableDataInput.allRowsSelected) {
          if (!this.allRowsSelected) {
            this.allRowsSelected = true;
          }
        } else {
          if (this.allRowsSelected) {
            this.allRowsSelected = false;
          }
        }
      }
      if (this.allRowsSelected) {
        this.tableDataInput.rows.map(row => row.active = true);
        // We are doing a copy of the array here
        this.activeRows = JSON.parse(JSON.stringify(this.tableDataInput.rows));
        this.totalSelectedRows = this.paginationInfo.count;
      } else {
        // We are doing a copy of the array here
        this.activeRows = JSON.parse(JSON.stringify(this.tableDataInput.rows.filter(row => row.active)));
        this.totalSelectedRows = this.activeRows.length + this.paginationInfo.totalItemsSelected;
      }

      if (!Utils.isEmpty(this.activeRows) && !Utils.isEmpty(this.tableRows)) {
        const selectedRow = this.tableRows._results.find(row => row.nativeElement.dataset.key === this.activeRows[0].key);

        // incase for some reason the active row was not found in the tableRows
        // if found then scroll object into view
        if (!Utils.isEmpty(selectedRow)) selectedRow.nativeElement.scrollIntoView({'behavior': 'smooth', 'block': 'center', 'inline': 'center'});
      }

      // NOTE: disables features if rows come back Empty
      if (this.tableDataInput.rows.length > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }

      // set loading state based on input data
      this.isLoading = this.tableDataInput ? false : true;
    }
  }

  // PAGINATION SETTINGS
  setPaginationState(tablePagination: ICustomTablePagination): ICustomTablePagination {
    let pageSize = tablePagination.pageSize + tablePagination.pageStart > tablePagination.count ? tablePagination.count - tablePagination.pageStart - 1 : tablePagination.pageSize - 1;
    if (pageSize < 0) pageSize = 0;
    return {
      pageStart: tablePagination.count > 0 ? tablePagination.pageStart + 1 : 0,
      pageSize: pageSize,
      count: tablePagination.count,
      totalItemsSelected: Utils.isEmpty(tablePagination.totalItemsSelected) ? 0 : tablePagination.totalItemsSelected,
      display: tablePagination.display
    };
  }

  pageNext(): void {
    this.paginationStart = this.paginationStart + this.paginationSize;
    this.paginationStartOutput.emit(this.paginationStart);
  }

  pageBack(): void {
    this.paginationStart = this.paginationStart - this.paginationSize;
    this.paginationStartOutput.emit(this.paginationStart);
  }

  // SORT SETTINGS
  setTableSortState(columnHeaders): void {
    this.sort = !this.sort
      ? { sortBy: this.tableDataInput.columnHeaders[0].key, sortAsc: true }
      : this.sort;

    if (this.activeSortKey) {
      columnHeaders.map(colHead => {
        colHead.active = colHead.key === this.activeSortKey ? true : false;
      });
    } else {
      columnHeaders[0].active = true;
    }
  }

  setSort(columnKey: string): void {
    this.activeSortKey = columnKey;

    if (this.sort.sortBy === columnKey) {
      this.sort.sortAsc = !this.sort.sortAsc;
    } else {
      this.sort.sortBy = columnKey;
      this.sort.sortAsc = true;
    }
    this.sortByOutput.emit(this.sort);
  }

  // PRESET FILTER SETTINGS
  setPresetFilterState(presetFilterOptions: IFilterByOptions[]): void {
    if (this.activePresetFilter) {
      presetFilterOptions.map(option => {
        if (option.title === this.activePresetFilter.title) {
          option.active = true;
        }
      });
    }
  }

  setPresetFilter(presetFilterOption: IFilterByOptions): void {
    presetFilterOption.active = !presetFilterOption.active;
    this.activePresetFilter = presetFilterOption.active ? presetFilterOption : null;

    this.presetFilterOutput.emit(presetFilterOption);
  }

  resetFilter(): void {
    this.tableDataInput.filterByOptions.forEach(filterOption => {
      if (filterOption.active) this.setPresetFilter(filterOption);
    });
  }

  // SEARCH SETTINGS
  setSearchSelectionState(searchOptions: ITableSearchOptions[]): void {
    if (this.activeSearchSelection) {
      searchOptions.map(option => {
        option.active = option.title === this.activeSearchSelection.title ? true : false;
      });
      if (this.activeSearchSelection.searchQuery) {
        this.searchQuery = this.activeSearchSelection.searchQuery;
      }
    } else {
      this.activeSearchSelection = searchOptions[0];
      searchOptions[0].active = true;
    }
  }

  // CREATE METHODS
  setCreateRow(): void {
    this.createRow = [];

    this.tableDataInput.createRowModel.forEach(rowItem => {
      const createRowItem: IRowItem = {
        name: rowItem.name,
        editable: rowItem.editable,
        value: rowItem.value,
        prevStateValue: '',
        type: rowItem.type,
        showDropdown: rowItem.showDropdown,
        maxLength: rowItem.maxLength
      };
      if ( Utils.isEmpty(createRowItem.maxLength)) {
        createRowItem.maxLength = '999';
      }
      if (rowItem.dynamicDropdown) {
        createRowItem['dynamicDropdown'] = rowItem.dynamicDropdown;
      }
      if (rowItem.staticDropdown) {
        createRowItem['staticDropdown'] = rowItem.staticDropdown;
      }
      if (rowItem.colorDropdown) {
        createRowItem['colorDropdown'] = rowItem.colorDropdown;
      }
      if (rowItem.disabled !== undefined || rowItem.disabled !== null) {
        createRowItem['disabled'] = rowItem.disabled;
      }
      this.createRow.push(createRowItem);
    });
  }

  showCreateNewRow(): void {
    // if we don't want to create inline
    if (!this.createInline) return this.createOutput.emit();

    window.addEventListener('keydown', this.saveOnEnter);
    this.totalSelectedRows = 0;
    this.activeRows = [];

    this.isCreating = true;
    this.tableDataInput.rows.forEach(row1 => {
      row1.active = false;
      row1.isEditing = false;
      row1.rowItems.forEach(rowItem => {
        if (Array.isArray(rowItem.value)) {
          rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
        } else {
          rowItem.value = rowItem.prevStateValue;
        }
      });
      this.removeFromActiveRows(row1);
    });
  }

  cancelNewRow(): void {
    window.removeEventListener('keydown', this.saveOnEnter);
    this.isCreating = false;
    if (this.tableDataInput.canCreate) this.setCreateRow();
  }

  createNewRow(newRow: IRowItem[]): void {
    if (this.createRowValidationFunction(newRow)) this.showConfirmation(newRow, 'create');
  }

  confirmCreation(): void {
    this.confirmCreationOutput.emit(this.createRow);

    this.showConfirmationBox = false;
    this.isCreating = false;
    if (this.tableDataInput.canCreate) this.setCreateRow();
  }

  // EDIT METHODS
  editRow(row: IRow): void {
    if (!this.editInline) return this.editOutput.emit(row.key);
    this.cancelNewRow();
    window.addEventListener('keydown', this.saveOnEnter);
    this.allRowsSelected = false;
    this.totalSelectedRows = 1;

    this.tableDataInput.rows.forEach(row1 => {
      row1.active = false;
      if (row1.isEditing) this.cancelEditRow(row1);
      this.removeFromActiveRows(row1);
    });
    row.active = true;
    row.isEditing = true;
    this.addToActiveRows(row);
    this.singleRowSelectionOutput.emit(this.activeRows);
  }

  cancelEditRow(row: IRow) {
    window.removeEventListener('keydown', this.saveOnEnter);
    row.active = false;
    row.isEditing = false;
    let hasTogglesToDisable = false;
    let toggleVal = false;
    this.totalSelectedRows = 0;
    this.activeRows = [];
    row.rowItems.forEach(rowItem => {
      if (Array.isArray(rowItem.value)) {
        rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
      } else {
        rowItem.value = rowItem.prevStateValue;
      }
      if (rowItem.type === 'toggleDisabledFlagsCheckbox') { hasTogglesToDisable = true; toggleVal = rowItem.prevStateValue; }
    });
    if (hasTogglesToDisable) {
      row.rowItems.forEach(item => {
        if (!Utils.isEmpty(item.disabled)) {
          item.disabled = toggleVal;
        }
      });
    }
  }

  confirmEdit(rows: IRow[]): void {
    window.removeEventListener('keydown', this.saveOnEnter);
    this.confirmEditOutput.emit(rows);

    rows.forEach(row => row.isEditing = false);
    this.activeRows = [];
    this.showConfirmationBox = false;
  }

  // DELETE METHOD
  deleteRow(row: IRow): void {
    this.tableDataInput.rows.forEach(row1 => {
      row1.isEditing = false;
      row1.active = false;
      row1.rowItems.forEach(rowItem => {
        if (Array.isArray(rowItem.value)) {
          rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
        } else {
          rowItem.value = rowItem.prevStateValue;
        }
      });
    });
    // set active rows to empty and add the current selected row to the active row
    this.activeRows = [];
    row.active = true;
    this.addToActiveRows(row);
    this.totalSelectedRows = this.activeRows.length;
    this.allRowsSelected = false;
    // TODO: TEMP FIX to allow deletion b/c totalSelected rows does not get updated when we want to show confirmation of deleting items
    // Need to implement single and bulk delete
    // this.setTotalRowsSelected();
    this.singleRowSelectionOutput.emit(this.activeRows);
    this.showConfirmation(row, 'delete');
  }

  confirmDeletion(): void {
    this.confirmDeletionOutput.emit(this.activeRows);
    this.totalSelectedRows = this.activeRows.length;
    this.activeRows = [];

    // make sure all rows become inactive after confirmation of deletion  (regardless if the row actually gets deleted or not cause of errors) <-- can change in future
    this.deSelectAllRows();

    this.showConfirmationBox = false;
  }

  // Toggle isHidden
  hideRows(): void {
    if (this.tableDataInput.bulkHideNoConfimationModal) {
      this.confirmHideRows();
    } else {
      this.showConfirmation(null, 'hide');
    }
  }

  confirmHideRows(): void {
    this.showConfirmationBox = false;
    // If active rows are empty, we do nothing
    if (this.totalSelectedRows < 1) return;

    const numberOfNotHiddenRows = this.activeRows.filter(row => !row.isHidden).length;
    this.bulkHideState = numberOfNotHiddenRows === this.activeRows.length && numberOfNotHiddenRows > 0 ? true : false;

    // We will still only emit the active row data.
    // The page with the functions should handle if ALL data is selected
    // There may be more data than currently in the table that needs to be handled, so logic remains there
    // This is why we emit the select all value
    this.activeRows.forEach(row => {
      row.isHidden = this.bulkHideState;
    });

    this.hideOutput.emit(this.activeRows);
  }

  // CONFIRMATION BOX
  showConfirmation(row, action: string): void {
    if (!Utils.isEmpty(row)) row.active = true;
    this.curEdit = action;
    if (this.totalSelectedRows > 0 || action === 'create' ) this.showConfirmationBox = true;
  }

  closeConfirmation(): void {
    this.tableDataInput.rows.forEach(row => {
      if (!row.selectable) row.active = false;
    });
    this.activeRows = this.tableDataInput.rows.filter(row => row.active);
    this.showConfirmationBox = false;
  }

  // HANDLE ROW STATE
  setTotalRowsSelected(): void {
    if (this.allRowsSelected) {
      this.totalSelectedRows = this.paginationInfo.count;
    } else {
      this.totalSelectedRows = this.activeRows.length + this.paginationInfo.totalItemsSelected;
    }
  }

  selectAllRows(e): void {
    // The select all shouldn't display if multiSelect is false,
    // But just in case, we don't want to do anything if it happens to be called
    if (!this.tableDataInput.multiSelect) return;

    this.allRowsSelected = e.checked;
    if (this.allRowsSelected) {
      this.totalSelectedRows = this.paginationInfo.count;
      this.tableDataInput.rows.forEach(row => {
        row.active = this.allRowsSelected;
        row.isEditing = false;
        row.rowItems.forEach(rowItem => {
          if (Array.isArray(rowItem.value)) {
            rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
          } else {
            rowItem.value = rowItem.prevStateValue;
          }
        });
        this.addToActiveRows(row);
      });
    } else {
      this.resetAllRows();
    }

    this.allRowsSelectionOutput.emit(this.activeRows);
  }

  selectRow(selectedRow: IRow, e: any): void {
    e.stopPropagation();
    this.isCreating = false;
    if (this.isObjectsTable) {
      this.activeRows.forEach(row => {
        row.isEditing = false;
        row.rowItems.forEach(rowItem => {
          if (Array.isArray(rowItem.value)) {
            rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
          } else {
            rowItem.value = rowItem.prevStateValue;
          }
        });
      });
      if (this.allRowsSelected) {
        selectedRow.active = false;
        this.removeFromActiveRows(selectedRow);
      } else {
        if (selectedRow.active) {
          selectedRow.active = false;
          this.removeFromActiveRows(selectedRow);
        } else {
          selectedRow.active = true;
          this.addToActiveRows(selectedRow);
        }
      }
      this.allRowsSelected = false;
      this.setTotalRowsSelected();
      this.singleRowSelectionOutput.emit([selectedRow]);
      return;
    }

    e.stopPropagation();

    const isChecked = !selectedRow.active;
    if (!this.tableDataInput.multiSelect) this.resetAllRows();
    selectedRow.active = isChecked;

    // This one checks for > 0 because if we have the current row selected then it should go to not editing
    if (this.activeRows.length > 0) {
      this.activeRows.forEach(row => {
        row.isEditing = false;
        row.rowItems.forEach(rowItem => {
          if (Array.isArray(rowItem.value)) {
            rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
          } else {
            rowItem.value = rowItem.prevStateValue;
          }
        });
      });
    }

    if (isChecked) {
      this.addToActiveRows(selectedRow);
      this.totalSelectedRows++;
    } else {
      this.removeFromActiveRows(selectedRow);
      this.totalSelectedRows--;
    }

    if (this.tableDataInput.multiSelect && this.paginationInfo.count === this.totalSelectedRows) this.allRowsSelected = true;
    else this.allRowsSelected = false;

    this.singleRowSelectionOutput.emit(this.activeRows);
  }

  deSelectAllRows(): void {
    this.totalSelectedRows = 0;
    this.activeRows = [];
    this.allRowsSelected = false;
    // selects all rows and set them as active = false to deselect them from checkbox
    if (this.tableDataInput && !Utils.isEmptyList(this.tableDataInput.rows)) {
      this.tableDataInput.rows.forEach( row => row.active = false );
    }
  }

  // tslint:disable-next-line:cyclomatic-complexity
  handleRowClick(selectedRow: IRow, e): void {
    this.isCreating = false;
    if (this.isObjectsTable) {
      if (selectedRow.isEditing) this.removeFromActiveRows(selectedRow);
      this.activeRows.forEach(row => {
        row.isEditing = false;
        row.rowItems.forEach(rowItem => {
          if (Array.isArray(rowItem.value)) {
            rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
          } else {
            rowItem.value = rowItem.prevStateValue;
          }
        });
      });
      if (selectedRow.isEditing) this.addToActiveRows(selectedRow);
      if (this.allRowsSelected) {
        selectedRow.active = false;
        this.removeFromActiveRows(selectedRow);
      } else {
        if (selectedRow.active && !selectedRow.isEditing) {
          selectedRow.active = false;
          this.removeFromActiveRows(selectedRow);
        } else {
          selectedRow.active = true;
          this.addToActiveRows(selectedRow);
        }
      }
      this.allRowsSelected = false;
      this.setTotalRowsSelected();
      this.singleRowSelectionOutput.emit([selectedRow]);
      return;
    }

    let clickedComponent = e.target;
    let isDropdown = false;
    const itemChecked = selectedRow.active;

    do {
      if (clickedComponent.classList) {
        if (clickedComponent.classList.contains('editable-item') || clickedComponent.classList.contains('list-dropdown-grit')
            || clickedComponent.classList.contains('dropdown-element-grit') || clickedComponent.classList.contains('dropdown-menu')) {
          isDropdown = true;
        }
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);

    // If the row is editable and active and the list item clicked is editable, we don't want to toggle the row active state
    if ((selectedRow.editable && selectedRow.active && e.target.classList.contains('editable-item') || isDropdown) && selectedRow.isEditing) {
      selectedRow.active = true;
    } else {
      selectedRow.active = !selectedRow.active;
    }

    if (itemChecked !== selectedRow.active) {
      if (selectedRow.active) {
        this.addToActiveRows(selectedRow);
        this.totalSelectedRows++;
      } else {
        this.removeFromActiveRows(selectedRow);
        this.totalSelectedRows--;
      }

      if (this.tableDataInput.multiSelect && this.paginationInfo.count === this.totalSelectedRows) this.allRowsSelected = true;
      else this.allRowsSelected = false;
    }

    if (this.activeRows.length > 1 && this.tableDataInput.multiSelect) {
      this.activeRows.forEach(row => {
        row.isEditing = false;
        row.rowItems.forEach(rowItem => {
          if (Array.isArray(rowItem.value)) {
            rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
          } else {
            rowItem.value = rowItem.prevStateValue;
          }
        });
      });
    } else if (!this.tableDataInput.multiSelect) {
      // If not multiselect, we need to reset all the non-selected rows
      this.activeRows.forEach(row => {
        if (selectedRow.key === row.key) return;
        row.isEditing = false;
        row.active = false;
        this.removeFromActiveRows(row);
        row.rowItems.forEach(rowItem => {
          if (Array.isArray(rowItem.value)) {
            rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
          } else {
            rowItem.value = rowItem.prevStateValue;
          }
        });
      });
    }

    this.rowClickOutput.emit(this.activeRows);
  }

  handleRowHover(event: string, hoveredRow: IRow) {
    if (event === 'mouseenter') {
      this.rowHoverOutput.emit(hoveredRow);
    } else {
      this.rowHoverOutput.emit();
    }
  }

  addToActiveRows(row: IRow): void {
    if (this.activeRows.indexOf(row) < 0) {
      this.activeRows.push(row);
    }
  }

  removeFromActiveRows(row: IRow): void {
    const activeRowIndex = this.activeRows.indexOf(row);
    this.activeRows.splice(activeRowIndex, 1);
  }

  resetAllRows(): void {
    this.tableDataInput.rows.forEach(row => {
      row.isEditing = false;
      row.active = false;
      row.rowItems.forEach(rowItem => {
        if (Array.isArray(rowItem.value)) {
          rowItem.value = Utils.deDupeArray(rowItem.prevStateValue);
        } else {
          rowItem.value = rowItem.prevStateValue;
        }
      });
    });
    this.activeRows = [];
    this.allRowsSelected = false;

    this.totalSelectedRows = 0;
    this.rowsResetOutput.emit();
  }

  // TODO - possible combine to 1 search method to allow for more than 2 searches
  search(): void {
    this.activeSearchSelection.searchQuery = this.searchQuery ? this.searchQuery : '';

    this.searchOutput.emit(this.activeSearchSelection);
  }

  resetSearch(): void {
    this.searchQuery = '';
    this.activeSearchSelection.searchQuery = '';
    this.paginationStart = 0;
    if (this.tableDataInput.searchQuery) this.tableDataInput.searchQuery = '';
    this.searchOutput.emit(this.activeSearchSelection);
  }

  // Date Picker
  autoCloseDp(d: any, e: any) {
    const target = e.target;
    if (!target.closest('.dropdown-menu')) {
      d.close();
    }
  }

  // LIST ITEM BUTTON OUTPUT
  onListItemButtonClick(e, item: IRowItem, row: IRow): void {
    e.stopPropagation();
    this.addToActiveRows(row);
    this.listItemButtonOutput.emit(item);
  }

  applyListQuery(event): void {
    this.dropdownQueryOutput.emit(event);
  }

  applyListPaginate(event): void {
    this.dropdownPaginateOutput.emit(event);
  }

  applySelectedListItems(rowItem: IRowItem, multiSelect: boolean, selectedItems: any): void {
    // If it is multiselect, then the dropdown already sets the rowItem.value so we should not update the prevState or value
    if (!multiSelect) {
      if (Array.isArray(rowItem.prevStateValue)) {
        rowItem.prevStateValue = Utils.deDupeArray(rowItem.value);
      } else {
        rowItem.prevStateValue = rowItem.value;
      }
      if (Array.isArray(selectedItems)) {
        rowItem.value = Utils.deDupeArray(selectedItems);
      } else {
        rowItem.value = selectedItems;
      }
    }
  }

  // BULK EDIT OUTPUTS
  handleBulkEditClick(e, bulkEditOptions: any): void {
    e.stopPropagation();
    bulkEditOptions.showList = !bulkEditOptions.showList;
  }

  applyBulkEditSelection(selectedItem: string[], bulkEditIdentifier: string) {
    this.activeRows.forEach(row => {
      const activeRowItem = row.rowItems.filter(rowItem => rowItem.bulkEditId === bulkEditIdentifier)[0];
      activeRowItem.value = selectedItem;
    });

    this.confirmEditOutput.emit(this.activeRows);
    this.activeRows = [];
  }

  toggleRowItemsDisabledFlags(rowItems: IRowItem[]): void {
    rowItems.forEach(item => {
      if (item.disabled !== undefined) {
        item.disabled = !item.disabled;
      }
    });
  }

  // DOCUMENT CLICK
  handleDocumentClick(event): void {
    const clickedComponent = event.target;
    const outsideConfirmationBox = clickedComponent.classList.contains('custom-table-confirmation-container');

    if (outsideConfirmationBox) {
      this.showConfirmationBox = false;
    }
  }

  // Custom function dropdown
  customFunctionDropdownSelect(selection: any) {
    if (!this.tableDataInput.customFunctionDropdown.multiSelect) {
      if (!Utils.isEmpty(this.tableDataInput.customFunctionDropdown.applySelectionFunction) && !Utils.isEmpty(selection)) {
        this.tableDataInput.customFunctionDropdown.applySelectionFunction(selection[0]);
        this.customFunctionDropdown.clearSelectedItems();
        this.customFunctionDropdownSelection = [];
      }
    } else {
      this.customFunctionDropdownSelection = selection;
    }
  }

  customFunctionDropdownSave() {
    if (!Utils.isEmpty(this.tableDataInput.customFunctionDropdown.applySelectionFunction) && !Utils.isEmpty(this.customFunctionDropdownSelection)) {
      this.tableDataInput.customFunctionDropdown.applySelectionFunction(this.customFunctionDropdownSelection);
      this.customFunctionDropdown.clearSelectedItems();
      this.customFunctionDropdownSelection = [];
    }
  }

  toggleFilterDropdown(): void {
    this.showFilterDropdown = !this.showFilterDropdown;
  }

  dateSet(dateValue: any, rowItem: any) {
    rowItem.value = dateValue.value;
  }

  handleInfoClick(row: any) {
    this.rowInfoClickOuptput.emit(row);
  }

  saveOnEnter = (event) => {
    if (event.keyCode === 13) {
      if (this.isCreating) this.createNewRow(this.createRow);
      else this.confirmEdit(this.activeRows);
    }
  }

  confirm() {
    if (this.curEdit === 'edit') this.confirmEdit(this.activeRows);
    else if (this.curEdit === 'create') this.confirmCreation();
    else if (this.curEdit === 'hide') this.confirmHideRows();
    else if (this.curEdit === 'delete') this.confirmDeletion();
  }
}
