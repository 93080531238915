import { Injectable, NgZone } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ProjectForgeViewerComponent } from '../../project/project-forge-viewer/project-forge-viewer.component';
import { ProjectPopOutViewerComponent } from '../../project/project-pop-out-viewer/project-pop-out-viewer.component';

import { ForgeService } from '../forge/forge.service';
import { ProjectObjectService } from '../project/project-object/project-object.service';
import { ProjectService } from '../project/project.service';

import { ForgeViewerTool, ForgeViewerType } from '../../utils/enums/forge-viewer-type';
import { IForgeContextMenuOutput, IForgeProjectModel, IForgeViewerState, ISnapshot } from '../../models/forge/forge.interface';

import { Utils } from '../../utils/utils';

declare const THREE: any;

@Injectable()
export class ForgeViewerService {
  viewerLoaded: boolean = false;
  onCategoryFilterEnabledPage: boolean = false;
  viewerComponent: ProjectForgeViewerComponent;

  currentViewerState = {};
  homeView: ISnapshot = null;

  // Emitters
  forgeViewerOutput: Subject<any> = new Subject<any>();
  showSimilarOutput: Subject<any> = new Subject<any>();
  clearFiltersOutput: Subject<void> = new Subject<void>();
  contextMenuOutput: Subject<any> = new Subject<any>();
  viewerCutOutput: Subject<any> = new Subject<any>();
  forgeViewerSetup: Subject<any> = new Subject<any>();
  forgeViewerLoadingStateOutput: Subject<number> = new Subject<any>();
  popoutKeyDownEventOutput: Subject<any> = new Subject<any>();
  popoutKeyUpEventOutput: Subject<any> = new Subject<any>();

  // Key maps
  projectModelIdMap = new Object();
  forgeModelIdMap = new Object();
  projectModelForgeModelMap = new Object();

  // Popout
  poppedOut: boolean = false;
  poppedOutComponent: ProjectPopOutViewerComponent;
  latestForgeModelIds = {};

  private enabledForgeTools: ForgeViewerTool[] = [];
  private selectionWindowRoutes: string[] = ['models'];

  loadedRoute: string = '';
  showConflicts: boolean;

  // Model helpers
  modelUrnSubscription: Subscription;
  modelsArray: IForgeProjectModel[] = [];
  forgeModels: any[] = [];
  largestProjectModelId: string;

  // colors
  selectedColorVector = new THREE.Vector4(.25, .69, 1, .80);
  selectedColorHex: string = '#40adff';
  prereqColorVector = new THREE.Vector4(0, .69, .2, .80);
  prereqColorHex: string = '#004502';
  blockerColorVector = new THREE.Vector4(.75, 0, 0, .80);
  successorColorVector = new THREE.Vector4(.97, .44, .77, .80);
  successorColorHex: string = '#F770C4';
  hoverColorVector = new THREE.Vector4(1, 1, 0, .80);
  hoverColorHex: string = '#40F2ED';
  committedColorVector = new THREE.Vector4(.62, .12, .94, .80);
  committedColorHex: string = '#A020F0';
  removedColorVector = new THREE.Vector4(253 / 255, 184 / 255, 192 / 255, .80);
  removedColorHex: string = '#FDB8C0';
  addeddColorVector = new THREE.Vector4(172 / 255, 242 / 255, 189 / 255, .80);
  addedColorHex: string = '#ACF2BD';
  normalColorVector = new THREE.Vector4(0, 0, 0, 0);
  selfColorVector = new THREE.Vector4(1, .55, 0, .80);

  constructor(
    private forgeService: ForgeService,
    private projectObjectService: ProjectObjectService,
    private projectService: ProjectService,
    private ngZone: NgZone
  ) { }

  setDisablePage(event: boolean): void {
    if (this.poppedOut) {
      this.poppedOutComponent.setDisablePage(event);
    }
    this.projectService.setDisablePage(event);
  }

  handleForgeViewerOutput(selection: any): void {
    if (selection !== 'esc') {
      const gritObjectSelections = this.convertToGritObjIds(selection).filter(object => {
        return this.currentViewerState.hasOwnProperty(object);
      });
      if (gritObjectSelections.length > 0 || this.viewerComponent.forgeViewer.toolController.getActiveToolName() !== 'SelectionWindowTool') {
        this.forgeViewerOutput.next(gritObjectSelections);
      }
    } else {
      this.forgeViewerOutput.next('esc');
    }
  }

  async toggleConflictsView(show: boolean) {
    if (show) {
      this.showConflicts = true;
      this.projectObjectService.toggleConflicts(true);
    }
    const objMap = this.projectObjectService.getAllObjectsMap();
    if (show) {
      Object.keys(objMap).forEach(id => this.currentViewerState[id] = id);
      await this.showObjects(Object.keys(objMap).filter(id => objMap[id].conflict > 0));
    } else {
      Object.keys(objMap).forEach(id => delete this.currentViewerState[id]);
      await this.hideObjects(Object.keys(objMap).filter(id => objMap[id].conflict > 0));
    }
    if (!show) {
      this.showConflicts = false;
      this.projectObjectService.toggleConflicts(false);
    }
  }

  updateObjects(removedObjectIds: string[], hiddenObjectIds: string[], newObjects: any[]) {
    removedObjectIds.forEach(id => delete this.currentViewerState[id]);
    hiddenObjectIds.forEach(id => delete this.currentViewerState[id]);
    newObjects.forEach(o => this.currentViewerState[o.id] = o.id);
    this.projectService.updateObjects(removedObjectIds, hiddenObjectIds, newObjects);
  }

  removeObjects(removed: string[], added: string[]) {
    removed.forEach(id => {
      const gritObject = this.projectObjectService.getLocalObject(id);
      if (!Utils.isEmpty(gritObject)) {
        const projectModelId = gritObject.projectModelId;
        const forgeObjectId = gritObject.forgeObjectId;
        delete this.currentViewerState[id];
        const model = this.projectModelForgeModelMap[projectModelId];
        this.viewerComponent.hideObjectsInModel([forgeObjectId], model);
        this.projectService.updateObjects([id], [], []);
      }
    });
    this.projectService.resolveConflicts(added);
  }

  handleShowSimilarOutput(forgeSelection: any): void {
    const gritObjectIds = this.convertToGritObjIds(forgeSelection);
    this.showSimilarOutput.next(gritObjectIds);
  }

  handleClearFiltersOutput(): void {
    this.clearFiltersOutput.next();
  }

  handleContextMenuOutput(data: IForgeContextMenuOutput): void {
    this.contextMenuOutput.next(data);
  }

  getForgeView() {
    return this.viewerComponent.getForgeView();
  }

  getViewerState() {
    return JSON.stringify(this.currentViewerState);
  }

  restoreView(viewToRestore: string) {
    if (this.viewerComponent) {
      this.viewerComponent.restoreForgeView(viewToRestore);
    }
  }

  clearCutPlanes() {
    if (!this.viewerComponent) return;
    this.viewerComponent.clearCutPlanes();
  }

  public handleViewerCutOutput(data: any): void {
    this.viewerCutOutput.next(data);
  }

  public getGritObject(objectId, modelId) {
    const projectModelId = this.forgeModelIdMap[modelId];
    const projectObjectId = this.projectObjectService.getGritIdByProjectModelForgeObject(projectModelId, objectId);
    return this.projectObjectService.getLocalObject(projectObjectId);
  }

  private convertToGritObjIds(forgeSelection: any): string[] {
    let currentProjectModelId: string = '';
    let currentGritObjectId: string = '';
    const returnArray: string[] = [];
    forgeSelection.forEach(sel => {
      currentProjectModelId = this.forgeModelIdMap[sel.modelId];
      sel.objectIds.forEach(forgeObjId => {
        currentGritObjectId = this.projectObjectService.getGritIdByProjectModelForgeObject(currentProjectModelId, forgeObjId);
        if (!Utils.isEmpty(currentGritObjectId)) returnArray.push(currentGritObjectId);
      });
    });
    return returnArray;
  }

  // Viewer Setup
  public getForgeViewerData(projectId: string): Promise<any> {
    this.modelsArray = [];

    return new Promise((resolve, reject) => {
      this.modelUrnSubscription = this.forgeService.getModelUrns(projectId).subscribe(
        success => {
          success.forEach(element => {
            if (element.status === 2) {
              this.modelsArray.push(element);
            }
          });

          return resolve(this.modelsArray);
        },
        err => {
          return reject(null);
        });
    });
  }

  public handleForgeViewerSetup(): void {
    this.forgeViewerSetup.next();
    this.viewerLoaded = true;
  }

  public setViewerLoadingState(percentLoaded: number): void {
    this.forgeViewerLoadingStateOutput.next(percentLoaded);
  }

  public async resizeViewerAfterLoad() {
    /* resize Viewer after window has been resized at nonviewer pages.
     Otherwise model doesnt show up */
    if (this.viewerComponent) {
      if (!this.projectService.getHideViewerStatus()) {
        await Utils.sleep(1, this.ngZone);
        this.viewerComponent.resize();
      }
    }
  }

  public getKnownForgeObjectIds() {
    const allKnownforgeObjectIds = {};
    const objMap = this.projectObjectService.getAllObjectsMap();
    for (const key in objMap) {
      if (key) allKnownforgeObjectIds[objMap[key].forgeObjectId] = objMap[key].forgeObjectId;
    }
    return allKnownforgeObjectIds;
  }

  public async getCustomObjects(projectId: string) {
    return await this.projectObjectService.getAllCustomObjects(projectId);
  }

  public async getHiddenCustomObjects(projectId: string) {
    return await this.projectObjectService.getAllHiddenCustomObjects(projectId);
  }
  // END Viewer Setup

  // Start NEW//////////////////////////////////////////
  public async setViewerState(componentState, disablePage = true) {
    // Hacky, but what was happening was the page would load and the page was set to disable,
    // but the page would await on that function causing the expression to change after its been read
    if (!this.viewerComponent) return;
    if (disablePage) {
      this.viewerComponent.forgeViewer.impl.toggleGroundShadow(true);
      this.viewerComponent.forgeViewer.setProgressiveRendering(true);
      this.viewerComponent.forgeViewer.setOptimizeNavigation(true);
      await Utils.sleep(1, this.ngZone);
      this.setDisablePage(disablePage);
      await Utils.sleep(300, this.ngZone);
    } else {
      this.viewerComponent.forgeViewer.impl.toggleGroundShadow(false);
      this.viewerComponent.forgeViewer.setProgressiveRendering(false);
      this.viewerComponent.forgeViewer.setOptimizeNavigation(false);
    }
    const viewerStateObject = {};
    Object.keys(this.currentViewerState).forEach((key) => {
      if (Utils.isEmpty(componentState[key])) {
        this.hideObjects([key]);
      }
    });
    Object.keys(componentState).forEach((key) => {
      viewerStateObject[key] = key;
      if (Utils.isEmpty(this.currentViewerState[key])) {
        this.showObjects([key]);
      }
    });
    this.currentViewerState = viewerStateObject;
    this.setDisablePage(false);
    this.projectService.setFilteringModel(false);
  }

  public async removeHiddenObjects(objectsToHide) {
    this.setDisablePage(true);
    await Utils.sleep(300, this.ngZone);
    Object.keys(objectsToHide).forEach((key) => {
      this.hideObjects([key]);
      delete this.currentViewerState[key];
    });
    this.setDisablePage(false);
  }

  public removeUnknownObjects() {
    if (!this.viewerComponent) return;
    const knownIdMap = this.getKnownForgeObjectIds();
    for (const key in this.projectModelForgeModelMap) {
      if (key) {
        const model = this.projectModelForgeModelMap[key];
        if (model) {
          const fragList = model.getFragmentList().fragments.fragId2dbId;
          // Map is to avoid searching an array, and array is so we don't have to go through map an convert to numbers since keys return strings
          const unknownObjectIdsMap = {};
          const unknownObjectIdsArr = [];
          fragList.forEach(num => {
            if (Utils.isEmpty(unknownObjectIdsMap[num]) && Utils.isEmpty(knownIdMap[num])) {
              unknownObjectIdsMap[num] = num;
              unknownObjectIdsArr.push(num);
            }
          });
          this.viewerComponent.hideObjectsInModel(unknownObjectIdsArr, model);
        }
      }
    }
  }

  public clearAllThemingColors(): void {
    if (!this.viewerComponent) return;
    Object.keys(this.projectModelForgeModelMap).forEach((key) => {
      this.viewerComponent.clearThemingColors(this.projectModelForgeModelMap[key]);
    });
  }

  public clearThemingColors(projectModelId: string): void {
    if (!this.viewerComponent) return;
    const model = this.projectModelForgeModelMap[projectModelId];
    this.viewerComponent.clearThemingColors(model);
  }

  private hideObjects(gritObjectIds: string[]): Promise<void> {
    if (!this.viewerComponent) return;
    let projectModelId;
    let model;
    let gritObject;
    let forgeObjectId;
    return new Promise((resolve, reject) => {
      gritObjectIds.forEach(id => {
        gritObject = this.projectObjectService.getLocalObject(id);
        if (!Utils.isEmpty(gritObject) && (this.showConflicts || gritObject.conflict <= 0)) {
          projectModelId = gritObject.projectModelId;
          forgeObjectId = gritObject.forgeObjectId;
          model = this.projectModelForgeModelMap[projectModelId];
          this.viewerComponent.hideObjectsInModel([forgeObjectId], model);
        }
      });
      return resolve();
    });
  }

  private showObjects(gritObjectIds: string[]): Promise<void> {
    if (!this.viewerComponent) return;
    let projectModelId;
    let model;
    let gritObject;
    let forgeObjectId;
    return new Promise(resolve => {
      gritObjectIds.forEach(id => {
        gritObject = this.projectObjectService.getLocalObject(id);
        if (!Utils.isEmpty(gritObject) && (this.showConflicts || gritObject.conflict <= 0)) {
          projectModelId = gritObject.projectModelId;
          forgeObjectId = gritObject.forgeObjectId;
          model = this.projectModelForgeModelMap[projectModelId];
          this.viewerComponent.showObjectsInModel([forgeObjectId], model);
        }
      });
      return resolve();
    });
  }

  public setState(inputObject: IForgeViewerState): void {
    if (!this.viewerComponent) return;
    const gritObject = this.projectObjectService.getLocalObject(inputObject.gritObjectId);
    if (Utils.isEmpty(gritObject)) return;
    if (!Utils.isEmpty(gritObject.forgeObjectId) && !Utils.isEmpty(gritObject.projectModelId)) {
      const model = this.projectModelForgeModelMap[gritObject.projectModelId];
      switch (inputObject.type) {
        case ForgeViewerType.Select:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.selectedColorVector, model);
          break;
        case ForgeViewerType.Prereq:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.prereqColorVector, model);
          break;
        case ForgeViewerType.Blocker:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.blockerColorVector, model);
          break;
        case ForgeViewerType.Successor:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.successorColorVector, model);
          break;
        case ForgeViewerType.Self: // Preq of itself
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.selfColorVector, model);
          break;
        case ForgeViewerType.Hover:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.hoverColorVector, model);
          break;
        case ForgeViewerType.Committed:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.committedColorVector, model);
          break;
        case ForgeViewerType.Added:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.addeddColorVector, model);
          break;
        case ForgeViewerType.Removed:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.removedColorVector, model);
          break;
        case ForgeViewerType.Default:
          this.viewerComponent.setThemingColor(gritObject.forgeObjectId, this.normalColorVector, model);
          break;
      }
    }
  }

  public fitObjectsToView(gritObjectIds: any) {
    if (!this.viewerComponent) return;
    const modelIds = {};
    let curGritObject;
    Object.keys(gritObjectIds).forEach((key) => {
      curGritObject = this.projectObjectService.getLocalObject(key);
      if (!Utils.isEmpty(curGritObject)) {
        if (Utils.isEmpty(modelIds[curGritObject.projectModelId])) modelIds[curGritObject.projectModelId] = curGritObject.projectModelId;
      }
    });
    const forgeObjectIdsToFit = [];
    const modelKeys = Object.keys(modelIds);
    let model = this.projectModelForgeModelMap[modelKeys[0]];
    if (modelKeys.length > 0) {
      if (modelKeys.length > 1) {
        model = this.projectModelForgeModelMap[this.largestProjectModelId];
        this.viewerComponent.fitObjectsToView(forgeObjectIdsToFit, model);
      } else {
        const gritObjects = this.projectObjectService.getModelObjects(modelKeys[0]);
        let box = null;
        Object.keys(gritObjects).forEach((key) => {
          if (!Utils.isEmpty(gritObjectIds[key])) {
            const obj = gritObjects[key];

            if (!box) box = obj.boundingBox;
            else {
              box = [
                Math.min(box[0], obj.boundingBox[0]),
                Math.min(box[1], obj.boundingBox[1]),
                Math.min(box[2], obj.boundingBox[2]),
                Math.max(box[3], obj.boundingBox[3]),
                Math.max(box[4], obj.boundingBox[4]),
                Math.max(box[5], obj.boundingBox[5])
              ];
            }
            forgeObjectIdsToFit.push(obj.forgeObjectId);
          }
        });
        if (box) {
          this.viewerComponent.fitBoxToView(box);
        }
      }
    }
  }

  // Will only fit forgeObjectIds to view if there is one modelId. Otherwise largest model bb is fit
  public fitModelToView(projectModelIds: string[], forgeObjectIdsToFit?: number[]) {
    if (!this.viewerComponent) return;
    if (!Utils.isEmptyList(projectModelIds)) {
      if (projectModelIds.length > 1) {
        this.viewerComponent.fitLargestToView();
      } else {
        const model = this.projectModelForgeModelMap[projectModelIds[0]];
        this.viewerComponent.fitObjectsToView(forgeObjectIdsToFit, model);
      }
    }
  }
  // END NEW//////////////////////////////////////////

  // Popout related
  popoutKeyDownEvent(event: any): void {
    this.popoutKeyDownEventOutput.next(event);
  }

  popoutKeyUpEvent(event: any): void {
    this.popoutKeyUpEventOutput.next(event);
  }

  setForgeViewerPopout(forgeViewerPopout: ProjectForgeViewerComponent): void {
    this.viewerComponent = forgeViewerPopout;
    this.poppedOut = true;
  }

  reassignForgeViewer(forgeViewer: ProjectForgeViewerComponent): void {
    this.setForgeViewer(forgeViewer);
    this.poppedOut = false;
  }

  setForgeViewer(forgeViewer: ProjectForgeViewerComponent): void {
    this.viewerComponent = forgeViewer;
  }

  setMyPopout(popOutComponent: ProjectPopOutViewerComponent): void {
    this.poppedOutComponent = popOutComponent;
  }

  setToPopoutForgeModels(viewerComp: any): void {
    viewerComp.modelArray.forEach(model => {
      this.projectModelIdMap[model.id] = model.forgeModelId;
      this.forgeModelIdMap[model.forgeModelId] = model.id;
      this.projectModelForgeModelMap[model.id] = viewerComp.forgeModels.find(mod => mod.id === model.forgeModelId);
    });
  }

  setToLatestForgeModels(): void {
    Object.keys(this.latestForgeModelIds).forEach((key) => {
      this.projectModelIdMap[key] = this.latestForgeModelIds[key];
      this.forgeModelIdMap[this.latestForgeModelIds[key]] = key;
      this.projectModelForgeModelMap[key] = this.viewerComponent.forgeModels.find(mod => mod.id === this.latestForgeModelIds[key]);
    });
  }

  recordLatestForgeModelIds(): void {
    this.modelsArray.forEach(model => {
      this.latestForgeModelIds[model.id] = model.forgeModelId;
    });
  }

  notifyRoute(url: string) {
    if (!this.viewerComponent) return;
    this.viewerComponent.notifyRoute(url);
    if (this.selectionWindowRoutes.filter(r => url.includes(r)).length > 0) {
      setTimeout(() => { this.enableSelectionWindow(true, true); }, 200);
    } else {
      setTimeout(() => { this.enableSelectionWindow(false, true); }, 200);
    }
  }

  enableTools() {
    setTimeout(() => { this.enabledForgeTools.forEach(tool => { this.enableTool(tool); }); }, 300);
  }
  // END Popout related

  // Selection Window
  enableSelectionWindow(enable: boolean, ignoreList: boolean = false) {
    if (!ignoreList) {
      if (enable) this.addToolToEnabled(ForgeViewerTool.SelectionWindow);
      else this.removeToolFromEnabled(ForgeViewerTool.SelectionWindow);
    }
    if (this.viewerComponent) this.viewerComponent.enableSelectionWindow(enable);
  }

  // Add tool to enabled
  addToolToEnabled(tool: ForgeViewerTool): void {
    if (this.enabledForgeTools.indexOf(tool) > -1) return;
    else this.enabledForgeTools.push(tool);
  }

  // Remove tool from enabled
  removeToolFromEnabled(tool: ForgeViewerTool): void {
    const index = this.enabledForgeTools.indexOf(tool);
    if (index > -1) this.enabledForgeTools.splice(index, 1);
  }

  enableTool(tool: ForgeViewerTool): void {
    switch (tool) {
      case ForgeViewerTool.SelectionWindow:
        this.enableSelectionWindow(true);
        break;
    }
  }
}
