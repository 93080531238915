import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject ,  Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Utils } from '../utils/utils';

import { AuthenticationService } from '../services/authentication/authentication.service';
import { LoginStatusService } from '../services/login/login-status.service';
import { MenuEmitterService } from '../services/menu/menu-emitter.service';
import { MenuService } from '../services/menu/menu.service';
import { NotificationService } from '../services/notification/notification.service';
import { SegmentService } from '../services/segment/segment.service';
import { TranslationService } from '../services/translation/translation.service';
import { UserService } from '../services/user/user.service';

import { AccountsCreateComponent } from '../accounts/accounts-create/accounts-create.component';

import { IMenuItems, ISlideoutMenu } from '../models/menu/menu.interface';
import { IProject } from '../models/project/project.interface';
import { IUser, IUserPermission } from '../models/user/user.interface';
import { ProjectModelService } from '../services/project/project-model/project-model.service';
import { PDFViewerService } from '../services/pdf/pdfViewer.service';

declare var System: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: { '(document:click)': 'handleClick($event)' }
})

export class MenuComponent implements OnInit, OnDestroy {
  subMenu: IMenuItems[];

  rootRoutesWithNoId: string[] = [
    'landing',
    'bim360',
    'popout',
    'accounts-create'
  ];

  activeId: string;
  projectCreationPermission: boolean = false;

  elementRef;
  feedback = {
    name: '',
    email: '',
    subject: '',
    message: ''
  };
  activeUrl: string;
  showSlideOutMenu: boolean = false;
  showNotifications: boolean = false;
  howAccountMenu: boolean;
  showUserMenu: boolean = false;
  showGuideMenu: boolean = false;
  projectsMenu: ISlideoutMenu;
  accountsMenu: ISlideoutMenu;
  notificationsMenu: ISlideoutMenu = {
    title: 'Notifications',
    menuItems: []
  };
  visibleSlideoutMenu: ISlideoutMenu;
  globalMenu: IMenuItems[] = [
    {
      title: 'help_menu_item',
      iconClass: 'fa fa-question',
      showHoverInfo: false,
      id: 'guide-global-menu',
      active: false
    },
    {
      title: 'notifications_menu_item',
      iconClass: 'fas fa-bell',
      showHoverInfo: false,
      id: 'notifications-global-menu',
      active: false
    },
    {
      title: 'User',
      iconClass: 'far fa-user-circle',
      showHoverInfo: false,
      id: 'users-global-menu',
      active: false,
      hasAvatar: true
    }
  ];

  fileManagerMenuItem = {
    title: 'Manage Models',
    iconClass: 'fa fa-file',
    route: 'settings?menuItem=3',
    active: false
  };

  accountsSubMenu: IMenuItems[] = [
    {
      title: 'settings_menu_item',
      iconClass: 'fa fa-cog',
      root: 'accounts',
      routes: ['settings'],
      id: 'account-settings-sub-menu',
      active: true
    },
    {
      title: 'sub_companies',
      iconClass: 'fa fa-address-book',
      root: 'accounts',
      routes: ['subcontractors'],
      id: 'account-subcontractors-sub-menu',
      active: false
    },
    {
      title: 'account_users',
      iconClass: 'fa fa-users',
      root: 'accounts',
      routes: ['users'],
      id: 'account-users-sub-menu',
      active: false
    },
    {
      title: 'integrations_menu_item',
      iconClass: 'fas fa-exchange-alt',
      root: 'accounts',
      routes: ['integrations'],
      id: 'account-integrations-sub-menu',
      active: false
    },
    {
      title: 'api_keys',
      iconClass: 'fa fa-key',
      root: 'accounts',
      routes: ['tokens'],
      id: 'account-tokens-sub-menu',
      active: false
    }
  ];

  projects: IProject[];
  accounts: any[];
  projectAccounts = [];
  currentProject: IProject;

  // Authentication
  authenticatedUser: IUser;
  authenticatedUserDisplayName: string;
  showMenu: boolean = false;
  nonAuthRoutes: string[] = [
    '/login',
    '/signup',
    '/verify',
    '/forgot'
  ];

  permissionDataSubscription: Subscription;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  userPermission: IUserPermission;
  userAccountPermission: IUserPermission;

  showShortCodeModal = false;
  shortCode: string = '';
  showQRCodeModal = false;
  qrCode: string = '';

  appVersion: string = '';

  hasActiveAccounts = false;

  unseenNotificationCount = 0;
  firstLoad = true;
  curRoot: string = '';

  constructor(
    private router: Router,
    public userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public menuService: MenuService,
    private segmentService: SegmentService,
    private notificationService: NotificationService,
    elementRef: ElementRef,
    private translationService: TranslationService,
    private projectModelService : ProjectModelService,
    private pdfViewerService: PDFViewerService,
  ) {
    // when loading the menu be sure to reset user avatar
    userService.setUserAvatarImage(null);
    this.elementRef = elementRef;
  }

  async ngOnInit() {

    this.activeUrl = this.router.url;

    this.checkMenuExists();

    this.getSlideoutMenus();

    LoginStatusService.changeEmitted$.pipe(takeUntil(this.destroyed$)).subscribe(loggedIn => {
      this.getSlideoutMenus();
    });

    MenuEmitterService.accountChanged$.pipe(takeUntil(this.destroyed$)).subscribe(account => {
      const matchIndex = this.accounts.findIndex(a => a.id === account.id);
      if (matchIndex === -1) this.accounts.push(account);
      else this.accounts[matchIndex] = account;
      this.getSlideoutMenu('Accounts', Utils.sortByString(this.accounts, 'name'));
    });

    MenuEmitterService.projectChanged$.pipe(takeUntil(this.destroyed$)).subscribe(project => {
      const matchIndex = this.projects.findIndex(p => p.id === project.id);
      if (matchIndex === -1) this.projects.push(project);
      else {
        this.projects[matchIndex].name = project.name;
      }
      this.getSlideoutMenu('projects_menu_item', Utils.sortByString(this.projects, 'name'));
    });

    MenuEmitterService.projectArchived$.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      const matchIndex = this.projects.findIndex(p => p.id === id);
      if (matchIndex === -1) return;
      else {
        this.projects.splice(matchIndex, 1);
      }
      this.getSlideoutMenu('projects_menu_item', Utils.sortByString(this.projects, 'name'));
    });

    MenuEmitterService.permissionChanged$.pipe(takeUntil(this.destroyed$)).subscribe(permission => {
      this.userPermission = permission;
      this.runFullSetup();
    });

    MenuEmitterService.accountPermissionChanged$.pipe(takeUntil(this.destroyed$)).subscribe(permission => {
      this.userAccountPermission = permission;
      this.runFullSetup();
    });

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(async event => {
      if (event instanceof NavigationEnd) {
        if (this.firstLoad) {
          this.firstLoad = false;
          this.curRoot = '/' + this.router.url.split('/')[1];
        } else {
          this.updateMenuSubItems();
        }
      }
    });

  }

  checkMenuExists() {
    if (localStorage.getItem('showMainMenu') === null) localStorage.setItem('showMainMenu', (this.menuService.mainMenuOpen === true).toString());
  }

  updateMenuSubItems() {
    if (!this.subMenu) return;

    this.activeUrl = this.router.url;
    const root = '/' + this.activeUrl.split('/')[1];
    this.activeId = this.activeUrl.split('/')[2];
    this.menuService.setNavIconState(this.activeUrl, this.subMenu);
    if (this.curRoot !== root) {
      this.curRoot = root;
      this.getSubMenuItems(root);
    }
  }

  runFullSetup() {
    this.getSlideoutMenus();
    this.showUserMenu = false;
    this.showGuideMenu = false;
    this.activeUrl = this.router.url;
    const root = '/' + this.activeUrl.split('/')[1];
    this.activeId = this.activeUrl.split('/')[2];
    if (root === '/project' || root === '/accounts') {
      if (!Utils.isEmpty(this.activeId) && !this.rootRoutesWithNoId.includes(this.activeId)) {
        if (root === '/project') {
          this.permissionCheckProjectSubMenu();
        }
      }
    }
    this.getSubMenuItems(root);
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  changeRoute(event) {
    const newRoute = event[0] + '/' + event[1] + '/' + event[2];
    if (!this.router.url.includes(newRoute)) {
      this.resetNavigationState();
      this.router.navigateByUrl(newRoute);
    }
    this.showSlideOutMenu = false;
  }

  resetNavigationState() {
    if (!Utils.isEmpty(this.subMenu)) {
      this.subMenu.forEach(menuItem => {
        if (!Utils.isEmpty(menuItem.showSubMenu)) menuItem.showSubMenu = false;
      });
      this.menuService.resetNavIconState(this.subMenu);
    }
  }

  getSubMenuItems(root: string) {
    if (root === '/project') {
      if (Utils.isEmpty(this.activeId) || this.rootRoutesWithNoId.includes(this.activeId)) {
        this.subMenu = [];
        this.activeId = null;
      } else {
        this.getModelsAvailability();
      }
    } else if (root === '/accounts') {
      this.subMenu = this.accountsSubMenu;
      this.menuService.setNavIconState(this.activeUrl, this.subMenu);
    } else {
      this.subMenu = [];
      this.activeId = null;
    }
  }

  getModelsAvailability(){
    this.projectModelService.getModelList(this.activeId).subscribe(projectModels => {
      if(projectModels.length > 0)
      {
        this.permissionCheckProjectSubMenu();
        this.menuService.setNavIconState(this.activeUrl, this.subMenu);
      }
      else
      {
        this.permissionCheckProjectSubMenu();
        this.subMenu.splice(1, 1);   // Removes Model tab
        this.menuService.setNavIconState(this.activeUrl, this.subMenu);
      }
      this.getPDFAvailability();
    });
  }

  getPDFAvailability(): void {
    this.pdfViewerService.getData(this.activeId).subscribe(pdfData => {
      if (pdfData && pdfData.length === 0)
        this.subMenu = this.subMenu.filter(m => m.id !== 'project-flowline-schedule-sub-menu');

    });
  }

  permissionCheckProjectSubMenu() {
    this.subMenu = [
      {
        title: 'dashboard_menu_item',
        iconClass: 'fas fa-tachometer-alt',
        root: 'project',
        routes: ['dashboard'],
        showHoverInfo: false,
        id: 'project-dashboard-sub-menu',
        active: false
      },
      {
        title: 'models_menu_item',
        iconClass: 'fa fa-cubes',
        root: 'project',
        routes: ['models'],
        showHoverInfo: false,
        id: 'project-models-sub-menu',
        active: false
      },
      {
        title: 'master_schedule_menu_item',
        iconClass: 'fas fa-align-left',
        root: 'project',
        routes: ['master-schedule'],
        showHoverInfo: false,
        id: 'project-master-schedule-sub-menu',
        active: false
      },
      {
        title: 'flow_line_chart',
        iconClass: 'fas fa-bolt',
        root: 'project',
        routes: ['flowline-schedule'],
        showHoverInfo: false,
        id: 'project-flowline-schedule-sub-menu',
        active: false
      },
      {
        title: 'lookahead_menu_item',
        iconClass: 'far fa-calendar-alt',
        root: 'project',
        routes: ['schedule'],
        showHoverInfo: false,
        id: 'project-schedule-map-sub-menu',
        active: false
      },
      {
        title: 'make_ready_menu_item',
        iconClass: 'fa fa-dolly',
        root: 'project',
        routes: ['make-ready'],
        showHoverInfo: false,
        id: 'project-make-ready-sub-menu',
        active: false
      },
      {
        title: 'sprints_menu_item',
        iconClass: 'fa fa-flag-checkered',
        root: 'project',
        routes: ['sprints'],
        showHoverInfo: false,
        id: 'project-sprints-sub-menu',
        active: false
      },
      {
        title: 'reports_menu_item',
        iconClass: 'fa fa-chart-pie',
        root: 'project',
        routes: ['reports'],
        showHoverInfo: false,
        id: 'project-reports-sub-menu',
        active: false
      },
      {
        title: 'settings_menu_item',
        iconClass: 'fa fa-cog',
        root: 'project',
        routes: [
          'settings?menuItem=0',
          'settings?menuItem=1',
          'settings?menuItem=2',
          'settings?menuItem=3',
          'settings?menuItem=5',
          'settings?menuItem=6',
          'settings?menuItem=7'
        ],
        showHoverInfo: false,
        id: 'project-settings-sub-menu',
        active: false
      }];
    if (this.userPermission && !this.userPermission.gc || this.userPermission && !this.userPermission.admin) {
      this.subMenu = this.subMenu.filter(menuItem => menuItem.title !== 'Scenarios_menu_item');
    }
    if (this.userPermission && !this.userPermission.edit) {
      this.subMenu = this.subMenu.filter(menuItem => menuItem.title !== 'last_planner_menu_item');
    }

    this.menuService.setNavIconState(this.activeUrl, this.subMenu);
  }

  getSlideoutMenus(): void {
    this.userService.getAuthenticatedUser().pipe(takeUntil(this.destroyed$)).subscribe(
      async user => {
        this.segmentService.identify(user.id, this.getUserToTrack(user));
        this.feedback.name = user.firstName + ' ' + user.lastName;
        this.feedback.email = user.email;
        this.authenticatedUser = user;

        if (this.authenticatedUser.avatar) await this.userService.getProfileImg(this.authenticatedUser.id);

        const userMenu = this.globalMenu.find(m => m.title === 'User');

        if (!Utils.isEmpty(userMenu)) userMenu.title = Utils.isEmpty(user.firstName) ? user.email : user.firstName;

        MenuEmitterService.emitAuthenticatedUserChange(user);

        this.projects = Utils.sortByString(user.projects, 'name');
        this.accounts = Utils.sortByString(user.accounts, 'name');

        // if user has an account level access of 2(edit) or 1(admin) then they have project creation permissions
        this.projectCreationPermission = Utils.isEmpty(user.accounts) ? false : user.accounts.some(acct => acct.permission <= 2);

        this.accounts.forEach(a => {
          if (!a.accountDisabled && (!a.accountTrialExpires || Utils.getCurrentDate() < a.accountTrialExpires)) this.hasActiveAccounts = true;
        });

        const uniqueAccountNames = Utils.uniqueValuesInArray(this.projects, 'accountName');
        this.projectAccounts = uniqueAccountNames.sort();
        this.getSlideoutMenu('Accounts', this.accounts);
        this.getSlideoutMenu('projects_menu_item', this.projects);

        this.showMenu = true;
      },
      err => {
        // user is not authorized
        this.showMenu = false;
      });
  }

  getSlideoutMenu(menuType: string, items: any): ISlideoutMenu {
    if (items.length !== 0 && menuType === 'projects_menu_item') {
      const projectMenu = this.globalMenu.find(p => p.title === 'projects_menu_item');
      if (Utils.isEmpty(projectMenu)) {
        this.globalMenu.unshift({
          title: 'projects_menu_item',
          iconClass: 'far fa-building',
          routes: ['project'],
          showHoverInfo: false,
          id: 'projects-global-menu',
          active: false
        });
      }
    }
    if (items.length === 1 && menuType === 'Accounts') {
      const accountMenu = this.globalMenu.find(a => a.title === items[0].name);
      if (Utils.isEmpty(accountMenu)) {
        this.globalMenu.unshift({
          title: items[0].name,
          iconClass: 'far fa-id-badge',
          routes: ['accounts'],
          showHoverInfo: false,
          id: 'account-global-menu',
          active: false
        });
      }
    } else if (items.length > 1 && menuType === 'Accounts') {
      const accountMenu = this.globalMenu.find(a => a.title === 'Accounts');
      if (Utils.isEmpty(accountMenu)) {
        this.globalMenu.unshift({
          title: 'Accounts',
          iconClass: 'far fa-id-badge',
          routes: ['accounts'],
          showHoverInfo: false,
          id: 'accounts-global-menu',
          active: false
        });
      }
    }

    if (menuType === 'projects_menu_item') {
      items = Utils.sortByString(items, 'accountName');
      return this.projectsMenu = this.menuService.createProjectSlideoutMenu(menuType, '/project', items, 'dashboard');
    } else if (menuType === 'Accounts') {
      return this.accountsMenu = this.menuService.createSlideoutMenu(menuType, '/accounts', items, 'settings');
    }
  }

  toggleAccount(acc: any): void {
    const foundAcc = this.projectsMenu.menuItems.find(item => item.accountName === acc.accountName);
    if (foundAcc) {
      const foundIndex = this.projectsMenu.menuItems.indexOf(foundAcc);
      this.visibleSlideoutMenu.menuItems[foundIndex].showProjects = !this.visibleSlideoutMenu.menuItems[foundIndex].showProjects;
      this.rememberPreviouslyExpandedAccounts(foundAcc.accountName, this.visibleSlideoutMenu.menuItems[foundIndex].showProjects);
    }
  }

  rememberPreviouslyExpandedAccounts(accountName: string, showProjects: boolean): void {
    let previouslyExpandedAccounts: any = localStorage.getItem('previouslyExpandedAccounts') || '[]';
    previouslyExpandedAccounts = JSON.parse(previouslyExpandedAccounts);

    if (showProjects) previouslyExpandedAccounts.push(accountName);
    else previouslyExpandedAccounts.splice(previouslyExpandedAccounts.findIndex(i => i === accountName), 1);
    localStorage.setItem('previouslyExpandedAccounts', JSON.stringify(previouslyExpandedAccounts));
  }

  toggleMainMenuState(): void {
    this.menuService.mainMenuOpen = !this.menuService.mainMenuOpen;
    localStorage.setItem('showMainMenu', JSON.stringify(this.menuService.mainMenuOpen));
    MenuEmitterService.emitSlideoutState(this.menuService.mainMenuOpen);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    },         250);
  }

  toggleMenu(id: string): void {
    if (!Utils.isEmpty(this.subMenu)) {
      this.subMenu.forEach(menuItem => {
        if (!Utils.isEmpty(menuItem.showSubMenu)) menuItem.showSubMenu = false;
      });
    }
    if (id === 'projects-global-menu') {
      this.showGuideMenu = false;
      this.showUserMenu = false;
      this.showNotifications = false;
      this.showSlideOutMenu = this.visibleSlideoutMenu !== this.projectsMenu || this.showSlideOutMenu === false
        ? true
        : false;

      this.visibleSlideoutMenu = this.projectsMenu;
    } else if (id === 'accounts-global-menu') {
      this.showGuideMenu = false;
      this.showUserMenu = false;
      this.showNotifications = false;
      this.showSlideOutMenu = this.visibleSlideoutMenu !== this.accountsMenu || this.showSlideOutMenu === false
        ? true
        : false;

      this.visibleSlideoutMenu = this.accountsMenu;
    } else if (id === 'account-global-menu') {
      this.router.navigateByUrl('/accounts/' + this.authenticatedUser.accounts[0].id + '/settings');
    } else if (id === 'notifications-global-menu') {
      this.showGuideMenu = false;
      this.showUserMenu = false;
      this.showSlideOutMenu = false;
      this.showNotifications = !this.showNotifications;
    } else if (id === 'users-global-menu') {
      this.showGuideMenu = false;
      this.showSlideOutMenu = false;
      this.showNotifications = false;
      this.showUserMenu = !this.showUserMenu;
    } else if (id === 'guide-global-menu') {
      this.openUserGuide();
    }
  }

  createNewProject(): void {
    this.menuService.setAllowSubMenuNav(false);
    this.showSlideOutMenu = false;

    this.router.navigateByUrl('/project/create');
  }

  createNewAccount(): void {
    this.modalService.open(AccountsCreateComponent).result.then(result => {
      this.accounts.push(result);
      this.accountsMenu = this.getSlideoutMenu('Accounts', this.accounts);
      this.router.navigateByUrl('/accounts/' + result.id);
    });
  }

  handleClick(event): void {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);

    if (!inside) {
      if (this.showSlideOutMenu) this.showSlideOutMenu = false;
      if (this.showUserMenu) this.showUserMenu = false;
      if (this.showGuideMenu) this.showGuideMenu = false;
      if (!Utils.isEmpty(this.subMenu)) {
        this.subMenu.forEach(menuItem => {
          if (!Utils.isEmpty(menuItem.showSubMenu)) menuItem.showSubMenu = false;
        });
      }
    }
  }

  showSubMenuItems(menuItem) {
    if (this.showSlideOutMenu) this.showSlideOutMenu = false;
    if (this.showUserMenu) this.showUserMenu = false;
    if (this.showGuideMenu) this.showGuideMenu = false;
    if (menuItem.showSubMenu) {
      menuItem.showSubMenu = false;
    } else {
      this.subMenu.forEach(item => {
        if (item.subMenuItems) {
          item.showSubMenu = false;
        }
      });
      menuItem.showSubMenu = true;
    }
  }

  // AUTHENTICATION
  logOut(): void {
    this.authenticationService.logout().subscribe(() => {
      LoginStatusService.emitChange(false);
      this.router.navigateByUrl('/login');
    });
  }

  private getUserToTrack(user) {
    return {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    };
  }

  openUserGuide() {
    window.open('https://intercom.help/gritvirtual/');
    this.showGuideMenu = false;
  }

  openFeedback(feedbackModal) {
    this.modalService.open(feedbackModal).result.then(res => {
      this.menuService.sendFeedback(this.feedback).subscribe(
        result => {
          this.showGuideMenu = false;
          this.notificationService.success('APPHELPTICKETSENT', {});
          this.feedback.message = '';
          this.feedback.subject = '';
        },
        err => {
          console.error('ERROR: ', err);
          this.notificationService.success('APPHELPTICKETERROR', {});
        });
    });
  }

  validateForm() {
    if (!Utils.validateEmailAddress(this.feedback.email)) return true;
    if (Utils.isEmpty(this.feedback.message)) return true;
    return false;
  }

  generateShortCode() {
    this.authenticationService.shortCode().subscribe(result => {
      this.showUserMenu = false;
      this.shortCode = result.shortCode;
      this.showShortCodeModal = true;
    });
  }

  generateQRCode() {
    this.authenticationService.qrCode().subscribe(result => {
      this.showUserMenu = false;
      this.qrCode = result.qr;
      this.showQRCodeModal = true;
    });
  }

  updateNotificationCount(count: number) {
    this.unseenNotificationCount = count;
  }

  async toggleLanguage() {
    const currentLanguage = TranslationService.getLanguage();
    if (currentLanguage === 'es') {
      SegmentService.track('Language Changed', {'language': 'en'});
      this.translationService.setLanguage('en');
    } else {
      SegmentService.track('Language Changed', {'language': 'es'});
      this.translationService.setLanguage('es');
    }
    location.reload();
  }
}
