import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { IAccordion } from '../../../models/accodordion/accordion.interface';
import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { INetworkMap, INetworkMapStep, IProjectPlanStep } from '../../../models/project/project-network-map/project-network-map.interface';
import { IProjectSchedule } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISelectionModal } from '../../../models/selection-modal/selection-modal.interface';
import { SidebarState } from '../../../utils/enums/sidebar-state';
import { ISidebarTab } from '../../../models/sidebar/sidebar.interface';

import { ActivityService } from '../../activity/activity.service';
import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { ProjectScheduleService } from '../project-schedule/project-schedule.service';

import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectNetworkMapService {

  sidebarTabs: ISidebarTab[] = [
    {
      name: 'filter',
      icon: 'fa-filter',
      key: SidebarState.MODEL_FILTER,
      active: true
    },
    {
      name: 'task_information',
      icon: 'fa-info',
      key: SidebarState.TASK_INFORMATION,
      active: false
    },
    {
      name: 'thread',
      icon: 'fa-comments',
      key: SidebarState.TASK_MESSAGES,
      active: false
    },
    {
      name: 'object_properties',
      icon: 'fa-cube',
      key: SidebarState.OBJECT_PROPERTIES,
      active: false
    }
  ];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private httpService: HttpBackendService,
    private activityService: ActivityService,
    private scheduleService: ProjectScheduleService,
    private notificationService: NotificationService
  ) {/*EMPTY*/}

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  getSchedules(projectId): Promise<IProjectSchedule[] | null> {
    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.scheduleService.get(projectId).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          return resolve(res);
        },
        err => {
          this.notificationService.error(err, {type: 'schedules', action: 'get'});
          return resolve(null);
        }
      );
    });
  }

  getLatestPlan(projectId: string, includeSteps = false): Observable<INetworkMap> {
    const obs = this.httpService.get('/project/' + projectId + '/plan');
    return includeSteps ? obs.pipe(mergeMap(plan => this.getProjectPlanStepsList(plan))) : obs;
  }

  getPlanList(projectId: string): Observable<INetworkMap[]> {
    return this.httpService.get('/project/' + projectId + '/plans');
  }

  getPlan(projectPlanId: string): Observable<INetworkMap> {
    return this.httpService.get('/project/plan/' + projectPlanId);
  }

  getProjectPlanStepsList(planId: string) {
    return this.httpService.get('/project/plan/' + planId + '/planSteps');
  }

  getPlanStep(projectPlanStepId: string): Observable<IProjectPlanStep> {
    return this.httpService.get('/project/plan/step/' + projectPlanStepId);
  }

  getPartiallyPlannedObjects(projectId: string, planId: string, activeFilters: string[]): Observable<any> {
    const qParams = JSON.stringify(activeFilters);
    return this.httpService.get('/project/' + projectId + '/partiallyPlanned/' + planId + '?activeFilters=' + qParams);
  }

  getPlanStepsForPlanIdObjectId(projectId: string, planId: string, projectObjectId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/planSteps/' + planId + '/' + projectObjectId);
  }

  getPlanStepInfluence(projectId: string, planId: string, stepId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/plan/' + planId + '/step/' + stepId + '/influence');
  }

  getPlanStepCriticalPath(projectId: string, planId: string, stepId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/plan/' + planId + '/step/' + stepId + '/criticalPath');
  }

  getObjectPlan(projectId: string, planId: string, objectId: string, activity: string, depth: number): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/plan/' + planId + '/object/' + objectId + '/plans?activity=' + activity + '&depth=' + depth );
  }

  getRelatedSteps(projectId: string, planId: string, stepId: string): Observable<any> {
    return this.httpService.get('/project/' + projectId + '/plan/' + planId + '/step/' + stepId + '/related');
  }

  // Filter Data Transforms
  getVersionOptions(schedules: IProjectSchedule[]): IDropdownItem[] {
    const listItems: IDropdownItem[] = [];
    schedules.forEach(s => {
      listItems.push({
        label: s.name + (s.active === true ? ' (Active)' : ''),
        value: s.planId
      });
    });
    return listItems;
  }

  getSubOptions(projectSubContractors: IProjectSubContractor[]): IDropdownItem[] {
    const listItems: IDropdownItem[] = [];
    projectSubContractors = Utils.sortByString(projectSubContractors, 'name');
    projectSubContractors.forEach(item => {
      listItems.push({value: item.id, label: item.name});
    });

    return listItems;
  }

  getStepOptions(): IDropdownItem[] {
    const listItems: IDropdownItem[] = [
      {value: 'First Degree', label: 'First Degree', selected: true},
      {value: 'Path', label: 'Path', selected: false},
      {value: 'Influence', label: 'Influence', selected: false},
    ];

    return listItems;

  }
  // END Filter Data Transforms

  transformKitAccordion(objectData: any): IAccordion[] {
    const objectSidbarData = [];
    objectData.forEach(object => {
      objectSidbarData.push({
        label: object.name,
        active: false,
        nestedAccordion: this.transformSidebarData(object.properties)
      });
    });

    return objectSidbarData;
  }

  // Transform data for properties accordion
  transformSidebarData(data): IAccordion[] {
    const sidebarData = [];
    // tslint:disable-next-line:one-variable-per-declaration
    const objectCategories = data.map(object => object.displayCategory),
        uniqueCategories = objectCategories.filter((category, index) => {
          if (objectCategories.indexOf(category) === index) {
            const objectInfo = data.filter(object => object.displayCategory === category);
            sidebarData.push({
              label: category.replace(/_/g, ' ').trim(),
              active: false,
              info: {
                'Display Name': objectInfo[0].displayName,
                'Display Value': objectInfo[0].displayValue,
                'Type': objectInfo[0].type,
                'Precision': objectInfo[0].precision
              }
            });
          }
        });

    return sidebarData;
  }
  // END Sidebar transforms

  // Forge Viewer/Grit data conversions
  getProjectPlanStepFromObjectId(projectPlanSteps, gritObjectId: string): INetworkMapStep[] {
    return projectPlanSteps.filter(planStep => planStep.objectIds.find(object => object === gritObjectId));
  }

  findRelatedPlanStepIds(networkMap: INetworkMap, gritObjectId: string, filterType: string, filteredSteps: any[]): any {
    switch (filterType) {
      case 'First Degree':
        return this.findFirstDegreeSteps(networkMap, gritObjectId);
      case 'Path':
        return filteredSteps;
      case 'Influence':
        return filteredSteps;
    }
  }

  findFirstDegreeSteps(networkMap: INetworkMap, gritObjectId: string): any {
    const relatedPlanSteps = {
      activeIds: null,
      prereqIds: null,
      successorIds: []
    };

    networkMap.projectPlanSteps.forEach(planStep => {

      const stepObjectIds = planStep.objectIds;
      const prereqObjectIds = planStep.prerequisiteObjectIds;
      if (stepObjectIds.includes(gritObjectId)) {
        relatedPlanSteps.activeIds = stepObjectIds;
        relatedPlanSteps.prereqIds = prereqObjectIds;
      }

      if (prereqObjectIds.includes(gritObjectId)) {
        stepObjectIds.forEach(id => {
          relatedPlanSteps.successorIds.push(id);
        });
      }
    });

    return relatedPlanSteps;
  }

  transformActivitySelectionModalData(data): Promise<ISelectionModal> {
    return new Promise((resolve, reject) => {
      const selectionModalData: ISelectionModal = {
        message: 'select_activity',
        items: []
      };
      this.activityService.getSpecificActivities(data).forEach(tc => {
        if (tc.children.length === 0) {
          selectionModalData.items.push({displayName: tc.name, value: {name: tc.name, activity: tc.id}});
        }
      });
      selectionModalData.items = Utils.sortByString(selectionModalData.items, 'displayName');
      return resolve(selectionModalData);
    });
  }

  transformTaskSelectionModalData(steps: IProjectPlanStep[]): Promise<ISelectionModal> {
    return new Promise((resolve, reject) => {
      const selectionModalData: ISelectionModal = {
        message: 'select_task',
        items: []
      };

      steps.forEach(step => {
        if (step.projectStepId) {
          selectionModalData.items.push({displayName: step.name, value: {name: step.name, step: step.projectStepId}});
        }
      });

      selectionModalData.items = Utils.sortByString(selectionModalData.items, 'displayName');
      return resolve(selectionModalData);
    });
  }
  // END // Forge Viewer/Grit data conversions
}
