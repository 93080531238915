import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, Observable, throwError } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ChartService } from '../../chart/chart.service';
import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectStepService } from '../../../services/project/project-step/project-step.service';
import { ProjectService } from '../../../services/project/project.service';
import { TranslationService } from '../../translation/translation.service';

import { IXaxisChartData } from '../../../models/chart/chart.interface';
import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { INoficationContext } from '../../../models/notification/notification.interface';
import { IProjectMaterial } from '../../../models/project/project-material/project-material.interface';
import { IProjectScheduleMilestone } from '../../../models/project/project-milestone/project-milestone.interface';
import { ILocalScheduleData } from '../../../models/project/project-schedule/project-schedule.interface';
import { IScheduleDisplayOptions } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectSchedule, IProjectScheduleItem, IProjectScheduleStep } from '../../../models/project/project-schedule/project-schedule.interface';
import { IProjectSprintStory } from '../../../models/project/project-sprint/project-sprint-story/project-sprint-story.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISidebarTab } from '../../../models/sidebar/sidebar.interface';
import { IUserPermission } from '../../../models/user/user.interface';
import { IWeatherForecast } from '../../../models/weather/weather.interface';

import { FilterModelOptions } from '../../../utils/enums/filter-model-options';
import { ColorMode, ViewMode } from '../../../utils/enums/shared.enum';
import { SidebarState } from '../../../utils/enums/sidebar-state';
import { Utils } from '../../../utils/utils';

import * as moment from 'moment';
import { PDFViewerService } from '../../pdf/pdfViewer.service';

@Injectable()
export class ProjectScheduleService extends ChartService {
  public daySelectionSubject = new Subject<number[]>();
  private localScheduleData: ILocalScheduleData = {};
  private localScheduleId: string = null;
  private activeFilters: string[] = [];
  private activeFilterType: FilterModelOptions;
  private hiddenFilters: FilterModelOptions[] = [FilterModelOptions.Category, FilterModelOptions.Equipment, FilterModelOptions.Materials];

  reformatControlsAt: number = 1000; // widht at which we adjust the chart controls layout for smaller screens
  chartControlsMinWidth: number = 775;

  tablePageSize: number = 10;

  // static schedule dimensions
  sD = {
    chartOffsetLeft: 75,
    chartOffsetTop: 50,
    minColumnWidth: 30,
    minRowHeight: 15,
    yAxisTransform: '',
    xAxisTransform: '',
    barHeight: 0,
    barPadding: 4,
    gridOffsetTransform: ''
  };

  chartXaxisData: any = [];

  viewOptions: IScheduleDisplayOptions[] = [
    {
      value: ViewMode.OneWeekSchedule,
      label: 'week_one',
      selected: true
    },
    {
      value: ViewMode.ThreeWeekSchedule,
      label: 'week_three',
      selected: false
    },
    {
      value: ViewMode.SixWeekSchedule,
      label: 'week_six',
      selected: false
    },
    {
      value: ViewMode.Calendar,
      label: 'calendar',
      selected: false
    }
  ];

  colorOptions: IScheduleDisplayOptions[] = [
    {
      value: ColorMode.Criticality,
      label: 'criticality',
      selected: true
    },
    // {
    //   value: ColorMode.Subcontractor,
    //   label: 'sub',
    //   selected: false
    // },
    {
      value: ColorMode.Status,
      label: 'status',
      selected: false
    }
  ];

  // Subscription takeUntil
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private http: HttpBackendService,
    private notificationService: NotificationService,
    private projectStepService: ProjectStepService,
    private projectService: ProjectService,
    private pdfViewerService: PDFViewerService
  ) {
    super();
    this.calculateChartOptions();
  }

  public generateSchedule(projectId: string, options: any): any {
    return this.http.post('/project/' + projectId + '/schedule', options);
  }

  public get(projectId: string): any {
    return this.http.get('/project/' + projectId + '/schedules');
  }

  public getSchedule(scheduleId: string, fromDate?: any, toDate?: any): any {
    if (!fromDate && !toDate) {
      const url = '/project/schedule/' + scheduleId + '/range';
      return this.http.get(url);
    } else {
      let url = '/project/schedule/' + scheduleId + '/projectedSchedule';
      if (fromDate && toDate) url += '?start=' + fromDate.toString() + '&end=' + toDate.toString();
      else if (fromDate) url += '?start=' + fromDate.toString();
      else if (toDate) url += '?end=' + toDate.toString();
      return this.http.get(url);
    }
  }

  public getScheduleObjects(scheduleId: string, activeFilters: string[], type: FilterModelOptions, fromDate: number, toDate: number): any {
    const body = { activeFilters: activeFilters, type: type };
    let url = '/project/schedule/' + scheduleId + '/projectedSchedule/objects';
    if (fromDate && toDate) url += '?start=' + fromDate.toString() + '&end=' + toDate.toString();
    else if (fromDate) url += '?start=' + fromDate.toString();
    else if (toDate) url += '?end=' + toDate.toString();
    return this.http.post(url, body);
  }

  public getScheduleBuild(scheduleId: string): any {
    return this.http.get('/project/schedule/' + scheduleId);
  }

  public getActiveSchedule(projectId: string): any {
    return this.http.get('/project/' + projectId + '/schedule/active');
  }

  public getLivingSchedule(projectId: string): any {
    return this.http.get('/project/' + projectId + '/schedule/living');
  }

  public delete(scheduleId: string): any {
    return this.http.delete('/project/schedule/' + scheduleId);
  }

  public getSchedulesByPlan(projectId: string): any {
    return this.http.get('/project/' + projectId + '/schedulesByPlan');
  }

  public getMakeReadyTasks(scheduleId: string, fromDate?: number, toDate?: number): any {
    let url = '/project/schedule/' + scheduleId + '/makeReadyTasks';
    if (fromDate && toDate) url += '?start=' + fromDate.toString() + '&end=' + toDate.toString();
    else if (fromDate) url += '?start=' + fromDate.toString();
    else if (toDate) url += '?end=' + toDate.toString();

    return this.http.get(url);
  }

  public getActiveScheduleMakeReadyTasks(projectId: string, fromDate?: number, toDate?: number): any {
    return this.getActiveSchedule(projectId)
      .pipe(switchMap(
        res => {
          const activeScheduleId = res['id'];
          if(Utils.isEmpty(activeScheduleId)) return throwError("activate schedule");
          return this.getMakeReadyTasks(activeScheduleId, fromDate, toDate);
        }
      ));
  }

  public getScheduleDifference(scheduleId: string, newScheduleId: string, fromDate?: number, toDate?: number): any {
    return this.http.get('/project/schedule/' + scheduleId + '/compare/' + newScheduleId + '?' + (fromDate ? '&start=' + fromDate : '') + (toDate ? '&end=' + toDate : ''));
  }

  public setActive(projectId: string, scheduleId: string, name?: string): any {
    if (name) {
      return this.http.post('/project/' + projectId + '/schedule/' + scheduleId + '/active?name=' + name, {});
    } else {
      return this.http.post('/project/' + projectId + '/schedule/' + scheduleId + '/active', {});
    }
  }

  public getCostSchedule(scheduleId: string): any {
    return this.http.get('/project/schedule/' + scheduleId + '/cost');
  }

  sortSchedules(schedules: IProjectSchedule[]): IProjectSchedule[] {
    schedules = Utils.sortByNumber(schedules, 'timestamp', false);
    let index = schedules.findIndex(s => s.living === true);
    if (index > - 1) {
      schedules.unshift(schedules[index]);
      schedules.splice(index + 1, 1);
    }
    index = schedules.findIndex(s => s.active === true);
    if (index > - 1) {
      schedules.unshift(schedules[index]);
      schedules.splice(index + 1, 1);
    }

    return schedules;
  }

  calculateChartOptions(): void {
    this.sD.yAxisTransform = `translate(${(this.sD.chartOffsetLeft - 10)} , ${this.sD.chartOffsetTop})`;
    this.sD.xAxisTransform = `translate(${this.sD.chartOffsetLeft}, ${this.sD.chartOffsetTop - 10})`;
    this.sD.barHeight = this.sD.minRowHeight - this.sD.barPadding;
    this.sD.gridOffsetTransform = `translate(${this.sD.chartOffsetLeft}, ${this.sD.chartOffsetTop})`;
  }

  transformToTableData(schedules: any, permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      },
      {
        displayName: 'generated',
        colKey: 'timestamp',
        type: RowItemType.TextIcon
      },
      {
        displayName: 'projected_completion_date',
        colKey: 'endDate',
        type: RowItemType.Text
      },
      {
        displayName: 'working_days',
        colKey: 'workingDays',
        type: RowItemType.Number
      },
      {
        displayName: 'iterations',
        colKey: 'iterations',
        type: RowItemType.Number
      },
      {
        displayName: 'status',
        colKey: 'status',
        type: RowItemType.Text
      }
    ];

    const rows: IGRow[] = [];
    schedules.forEach(sched => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: sched.name
        },
        {
          colKey: 'timestamp',
          value: {
            text: sched.timestamp ? Utils.formatDateTime(sched.timestamp, true) : TranslationService.translate('generating'),
            icon: sched.timestamp ? 'fa fa-check green' : 'fas fa-circle-notch spin'
          }
        },
        {
          colKey: 'endDate',
          value: sched.timestamp ? Utils.formatDate(sched.endDate) : ''
        },
        {
          colKey: 'workingDays',
          value: sched.workingDays ? sched.workingDays.toLocaleString(undefined, {maximumFractionDigits: 0}) : ''
        },
        {
          colKey: 'iterations',
          value: sched.iterations ? sched.iterations.toLocaleString(undefined, {maximumFractionDigits: 0}) : ''
        },
        {
          colKey: 'status',
          value: sched.timestamp ? sched.status : ''
        }
      ];

      let schedActivatable: boolean = !sched.active && sched.timestamp;
      if (schedActivatable) schedActivatable = !sched.dirty || sched.living;

      rows.push(
        {
          key: sched.id,
          rowItems: rowItems,
          selectable: false,
          editable: true,
          editOptions: {
            deletePermission: permission.gc && permission.admin,
            rowEdits: permission.gc && permission.admin
            ? [
              {type: EditType.Custom, icon: 'fas fa-play-circle', displayName: 'activate', customVal: 'activate', disabled: !schedActivatable},
              {type: EditType.Custom, icon: 'fas fa-calendar-alt', displayName: 'view_in_lookahead', customVal: 'lookAhead', disabled: !sched.timestamp},
              {type: EditType.Delete, disabled: (sched.active || sched.living)}
            ]
            : [{type: EditType.Custom, icon: 'fas fa-calendar-alt', displayName: 'view_in_lookahead', customVal: 'lookAhead', disabled: !sched.timestamp}]
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: true,
        inline: false,
        displayName: 'add_scenario',
        enterToAdd: true
      },
      deleteOptions: {
        show: false
      }
    };

    return retTable;
  }

  getSidebarTabs(): ISidebarTab[] {
    let sidebarTabs = [
      {
        name: 'filter',
        icon: 'fa-filter',
        key: SidebarState.MODEL_FILTER,
        active: true
      },
      {
        name: 'task_list',
        icon: 'fa-tasks',
        key: SidebarState.TASK_LIST,
        active: false
      },
      {
        name: 'task_information',
        icon: 'fa-info',
        key: SidebarState.TASK_INFORMATION,
        active: false
      },
      {
        name: 'thread',
        icon: 'fa-comments',
        key: SidebarState.TASK_MESSAGES,
        active: false
      },
      {
        name: 'object_properties',
        icon: 'fa-cube',
        key: SidebarState.OBJECT_PROPERTIES,
        active: false
      },
      {
        name: 'procurement_menu_item',
        icon: 'fa-dolly',
        key: SidebarState.PROCUREMENT,
        active: false
      },
    ];

    if (!this.projectService.hasModels) {
      sidebarTabs = sidebarTabs.filter(
        item => (item.key !== SidebarState.OBJECT_PROPERTIES)
      );
    }
    return sidebarTabs;
  }

  getGritRating(steps: IProjectScheduleStep[]): number | string {
    let gritRating;
    const tasks = Utils.deDupeObjArrayByKey(steps, 'projectPlanStepId');
    if (tasks.length === 0) return gritRating = `__`;
    let sumOfSquares = 0;
    tasks.forEach(step => {
      sumOfSquares += step.totalDurationHours * step.totalDurationHours;
    });
    const numSteps = tasks.length;
    gritRating = Math.round(1000 / (Math.sqrt((1 / numSteps) * sumOfSquares)));

    return gritRating;
  }

  destroyOpenSubscriptions(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  setDaySelectionState(epoch?: number[]): void {
    return this.daySelectionSubject.next(epoch);
  }

  transformSchedulesForDropdown(schedulesByPlans: any) {
    const schedulesDropdownList = [];

    schedulesByPlans.forEach(planSchedule => {
      const schedules = planSchedule.schedules ? planSchedule.schedules : null;
      if (schedules && !Utils.isEmpty(schedules)) {
        schedules.forEach(
          schedule => {
            const scheduleDropdownItem = {
              label: schedule.active ? schedule.name + ' (ACTIVE)' : schedule.name,
              value: schedule.id,
              selected: schedule.living,
              timestamp: schedule.timestamp
            };

            schedulesDropdownList.push(scheduleDropdownItem);
          }
        );
      }
    });

    return schedulesDropdownList.sort((s1, s2) => s1.selected ? -1 : s2.selected ? 1 : s2.timestamp - s1.timestamp);
  }

  getMonthOfEpochs(activeDay: number): number[] {
    const startOfMonth = moment.utc(activeDay).startOf('month').valueOf();
    const daysInMonth = moment.utc(startOfMonth).daysInMonth();
    const monthEpochs = [];

    for (let i = 0; i < daysInMonth; i++) {
      const day = moment.utc(startOfMonth).add(i, 'days').valueOf();
      monthEpochs.push(day);
    }

    return this.getCalendarCells(monthEpochs);
  }

  getCalendarCells(monthEpochs: number[]): number[] {
    const calendarViewDays = monthEpochs;
    const startOfMonthEpoch = monthEpochs[0];
    const monthStartDay = moment.utc(monthEpochs[0]).day(); // 0 Sun, 1 Mon, 2 Tues....
    const endOfMonthEpoch = monthEpochs[monthEpochs.length - 1];
    const monthEndDay = moment.utc(monthEpochs[monthEpochs.length - 1]).day();

    // push days from past month that will show in calendar
    for (let i = 0; i < monthStartDay; i++) {
      const pastMonthDay = moment.utc(startOfMonthEpoch).clone().subtract(i + 1, 'days').valueOf();
      calendarViewDays.unshift(pastMonthDay);
    }

    // push days from next month that will show in calendar
    for (let i = 0; i < 6 - monthEndDay; i++) {
      const nextMonthDay = moment.utc(endOfMonthEpoch).clone().add(i + 1, 'days').valueOf();
      calendarViewDays.push(nextMonthDay);
    }

    return calendarViewDays;
  }

  getVisibleSchedule(startEpoch: number, endEpoch: number, projectSchedule: IProjectScheduleItem[]) {
    const newSched = [];
    if (projectSchedule.length > 0) {
      projectSchedule.forEach(day => {
        const dayEpoch = day.date ? day.date : null;
        const dayInRange = dayEpoch ? moment.utc(dayEpoch).clone().isBetween(startEpoch - 1, endEpoch + 1) : null;
        if (dayInRange) {
          newSched.push(day);
        }
      });
    }

    return newSched;
  }

  // adds weather and milestones to xaxis data for charts and schedule
  buildXaxisData(epochs: number[], weatherForecast: IWeatherForecast[], milestones: IProjectScheduleMilestone[], materials: any[], selectedDays: number[]): IXaxisChartData[] {
    return epochs.map(item => {
      const isSelected = selectedDays.includes(item);
      const endOfDay = moment.utc(item).clone().endOf('day').valueOf();
      const weatherForDay = !Utils.isEmpty(weatherForecast) ? weatherForecast.filter(weaterDay => weaterDay.date === item) : [];
      const milestonesForDay = this.getDataForDay(milestones, endOfDay);
      const materialsForDay = this.getDataForDay(materials, endOfDay);

      return {
        day: item,
        selected: isSelected,
        weather: !Utils.isEmpty(weatherForDay) ? weatherForDay[0] : null,
        milestones: !Utils.isEmpty(milestonesForDay) ? milestonesForDay : null,
        materials: !Utils.isEmpty(materialsForDay) ? materialsForDay : null
      };
    });
  }

  getDataForDay(data: any, day: number): any {
    const msForDay = data ? data.filter(stone => stone.actual === day) : [];
    if (!Utils.isEmpty(data)) {
      // Status is only valid for milestones
      // Different status field is used for submtital data
      msForDay.map(stone => stone['status'] = this.getMilestoneStatus(stone));
    }

    return msForDay;
  }

  getMilestoneStatus(stone: any): string {
    if (stone.actual && stone.baseline) {
      const msDiff = moment(stone.baseline).diff(moment(stone.actual), 'days');
      const milestoneStatus = msDiff < 0
        ? msDiff * -1 + ' days behind'
        : msDiff > 0
          ? msDiff + ' days ahead'
          : 'On schedule';

      return milestoneStatus;
    } else {
      return null;
    }
  }

  // function sets schedule days to beginning of day,
  // adds milestone data to days
  // adds materials data to days
  // sets task start and end times for chart and calendar
  addAdditionalDataToSchedule(
    projectSchedule: IProjectScheduleItem[],
    subcontractors: IProjectSubContractor[],
    selectedDays: number[],
    milestones?: IProjectScheduleMilestone[],
    materials?: IProjectMaterial[]
  ): any {
    // subcontractors.forEach(subcontractor => {
    const overallOffset = this.getLargestOffset(projectSchedule);

    projectSchedule.forEach((schedDay, index) => {
      const startOfTaskDay = moment.utc(Number(schedDay.date)).clone().startOf('day').valueOf();
      const endOfTaskDay = moment.utc(Number(schedDay.date)).clone().endOf('day').valueOf();
      const projectSteps = schedDay.projectScheduleSteps ? schedDay.projectScheduleSteps : null;
      const dayMilestones = (milestones && milestones.length > 0) ? milestones.filter(mstone => mstone.actual === endOfTaskDay) : null;
      const dayMaterials = (materials && materials.length > 0) ? materials.filter(material => material.requredOnSiteDate === endOfTaskDay) : null;
      projectSteps.forEach(step => {
        step.canEdit = this.projectStepService.userHasStepPermissions(step);
        this.setStartAndStopTimesForGraph(startOfTaskDay, step, overallOffset);
      });

      schedDay.date = startOfTaskDay; // ensure the schedule values are the beginning of each day
      schedDay['selected'] = selectedDays.includes(startOfTaskDay);
      if (milestones) schedDay['milestones'] = dayMilestones;
      if (materials) schedDay['materials'] = dayMaterials;
    });
    // });

    return projectSchedule;
  }

  /*--Returns largest hours for a working day, so schedule can offset evenly each day, probably needs to come from BE at some point--*/
  getLargestOffset(schedule: IProjectScheduleItem[]): number {
    const largestDurationOffset = schedule.map(day => day.projectScheduleSteps.map(step => step.dayHours));
    const allOffset = [].concat(...largestDurationOffset);
    return Math.max(...allOffset);
  }

  setStartAndStopTimesForGraph(epochDate: number, step: any, durationOffset: number): void {
    step.taskStart = step.offsetHours * 24 / durationOffset;
    step.taskEnd = step.taskStart + (step.durationHours * (24 / durationOffset));

    step['epochStart'] = moment.utc(epochDate).clone().startOf('day').add(step.taskStart, 'hours').valueOf();
    step['epochEnd'] = moment.utc(epochDate).clone().startOf('day').add(step.taskEnd, 'hours').valueOf();
    step['dayStart'] = moment.utc(epochDate).clone().startOf('day').valueOf();
  }

  /*--NEW--*/
  setSprintStepIdentifiers(steps: IProjectScheduleStep[], sprintRange: number[]): void {
    steps.forEach((step) => {
      if (step.epochStart >= sprintRange[0] && step.epochEnd <= sprintRange[1]) step['inSprint'] = true;
      else step['inSprint'] = false;
    });
  }

  transformMilestones(milestones: IProjectScheduleMilestone[]): IProjectScheduleMilestone[] {
    return milestones.map(mStone => ({
      ...mStone,
      actual: moment.utc(mStone.actual).clone().endOf('day').valueOf(), // ensure milestone times fall at end of day to display in chart
      baseline: moment.utc(mStone.baseline).clone().endOf('day').valueOf(),
      behindSchedule: mStone.baseline < mStone.actual
    }));
  }

  transformMaterials(materials: IProjectMaterial[]): IProjectMaterial[] {
    return materials.map(matData => ({
      ...matData,
      actual: moment.utc(matData.requredOnSiteDate).clone().endOf('day').valueOf() // ensure milestone times fall at end of day to display in chart
    }));
  }

  getNextTaskDay(selectedDay: number) {
    const localSchedKey = moment.utc(selectedDay).clone().startOf('week').valueOf().toString();
    const nextStep = this.findStep(localSchedKey, selectedDay, 'next');
    let nextTaskDay;
    nextTaskDay = (nextStep && nextStep.epochStart) ? nextStep.dayStart : null;

    return nextTaskDay;
  }

  getPrevTaskDay(selectedDay: number): number {
    const localSchedKey = moment.utc(selectedDay).clone().startOf('week').valueOf().toString();
    const prevStep = this.findStep(localSchedKey, selectedDay, 'prev');
    let prevTaskDay;
    prevTaskDay = (prevStep && prevStep.epochStart) ? prevStep.dayStart : null;

    return prevTaskDay;
  }

  findStep(localSchedKey: string, selectedDay: number, direction: string): IProjectScheduleStep {
    let foundSteps;
    if (this.localScheduleData[localSchedKey]) {
      if (direction === 'next') {
        foundSteps = this.localScheduleData[localSchedKey].steps.find(step => (moment.utc(step.epochStart).clone().startOf('day').valueOf() > selectedDay) && !this.taskIsFilterOut(step));
      } else {
        foundSteps = this.localScheduleData[localSchedKey].steps.filter(step => (moment.utc(step.epochStart).clone().startOf('day').valueOf() < selectedDay) && !this.taskIsFilterOut(step));
        foundSteps = foundSteps.length > 0 ? foundSteps.reverse()[0] : null;
      }

      if (!foundSteps) {
        const nextRangeKey = direction === 'next'
          ? moment.utc(Number(localSchedKey)).clone().add('week', 1).valueOf().toString()
          : moment.utc(Number(localSchedKey)).clone().subtract('week', 1).valueOf().toString();
        foundSteps = this.findStep(nextRangeKey, selectedDay, direction);
      }

      return foundSteps;
    } else {
      return null;
    }
  }

  taskIsFilterOut(step: IProjectScheduleStep): boolean {
    if (this.activeFilters.length > 0) {
      switch (this.activeFilterType) {
        case FilterModelOptions.Activities:
          return step.activities.length > 0 && Utils.similarValuesInArrays(step.activities, this.activeFilters).length < 1;

        case FilterModelOptions.SubContractor:
          return step.subContractorId && !this.activeFilters.includes(step.subContractorId);

        case FilterModelOptions.FloorPlan:{
          let pdfId = this.projectService.getPDFNameForURL(this.pdfViewerService.getPDFIdForActivity(step.activities[0]))
          if( pdfId && pdfId.length){
            let found = pdfId.find(id => this.activeFilters.includes(id));
            return found ? false: true;
          } else{
            return false;
          }
        } 
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  subIsFilteredOut(subcontractor: IProjectSubContractor, subSteps: IProjectScheduleStep[]): boolean {
    if (this.activeFilters.length > 0) {
      switch (this.activeFilterType) {
        case FilterModelOptions.Activities:
          if (subcontractor.activities.length > 0) {
            if (Utils.similarValuesInArrays(subcontractor.activities, this.activeFilters).length < 1) {
              return true;
            } else {
              // if sub is not filtered out we need to check the steps on each day
              const subStepsActivities = [].concat.apply([], subSteps.map(step => step.activities));
              if (Utils.similarValuesInArrays(subStepsActivities, this.activeFilters).length < 1) return true;
            }
          } else {
            return false;
          }
          break;

        case FilterModelOptions.SubContractor:
          return subcontractor.id && !this.activeFilters.includes(subcontractor.id);

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  setActiveFilterType(type: FilterModelOptions): void {
    this.activeFilterType = type;
  }
  getActiveFilterType(): FilterModelOptions {
    return this.activeFilterType;
  }
  setActiveFilters(filters: string[]): void {
    this.activeFilters = filters;
  }
  getActiveFilters(): string[] {
    return this.activeFilters;
  }
  getHiddenFilters(): FilterModelOptions[] {
    return this.hiddenFilters;
  }

  /*--SIDEBAR--*/
  // Sidebar Transforms
  createArrayForSidebarDisplay(stepsBySub: any, subcontractors: IProjectSubContractor[]): any[] {
    const sidebarDisplay = [];
    if (!Utils.objectIsEmpty(stepsBySub)) {
      for (const key in stepsBySub) {
        if (stepsBySub[key]) {
          const filteredSubs = subcontractors.filter(subcontractor => subcontractor.id === key);
          const activeSubcontractor = !Utils.isEmpty(filteredSubs) ? filteredSubs[0] : null;
          if (activeSubcontractor) {
            const sidebarObj = {
              label: activeSubcontractor.name ? activeSubcontractor.name : null,
              color: activeSubcontractor.hexCode ? activeSubcontractor.hexCode : null,
              steps: this.sidebarStepDataAggregation(stepsBySub[key]),
              active: true
            };
            sidebarDisplay.push(sidebarObj);
          }
        }
      }
    }

    return sidebarDisplay;
  }

  sidebarStepDataAggregation(arrData: any[]): any[] {
    if (!arrData) return;
    // create new step data array for aggregation
    const stepArr = [];
    // take the stepData and loop through and push all data into stepArr if projectStepId does not exists otherwise update needed objects
    arrData.forEach(step => {
      // findIndex will iterate and stop at the first found object and return it's index.
      if (stepArr.findIndex(i => i.projectStepId === step.projectStepId) === -1) stepArr.push(step);
    });
    return stepArr;
  }

  /*--- CODE FOR FLATTENING TASKS INTO SINGLE LINE - LEAVE HERE - CHRIS MAY WANT TO IMPLEMENT AGAIN - CW ---*/
  // setStartAndStopTimesForGraph(epochDate: number, steps: any[]): void {
  // Squish two steps relative to each based on crew size ratios
  // const squishStep = (prev, next) => {
  //   const overlap = Math.min(prev.taskEnd, next.taskEnd) - Math.max(prev.taskStart, next.taskStart);
  //   if (overlap > 0.01) {
  //     const reverse = prev.taskStart > next.taskStart || prev.taskStart === next.taskStart && prev.taskEnd > next.taskEnd;
  //     const first = reverse ? next : prev;
  //     const last = reverse ? prev : next;
  //
  //     const firstMen = first.crewSize * first.durationHours / (first.taskEnd - first.taskStart);
  //     const lastMen = last.crewSize * last.durationHours / (last.taskEnd - last.taskStart);
  //     const ratio = firstMen / (firstMen + lastMen);
  //     if (last.taskEnd < first.taskEnd - 0.01 && first.taskStart < last.taskStart - 0.01) {
  //       last.taskEnd = first.taskEnd;
  //     }
  //     first.taskEnd -= overlap * (1.0 - ratio);
  //     last.taskStart = first.taskEnd;
  //   }
  // };

  // steps.forEach((step) => {
  //   step.taskStart =  step.offsetHours;
  //   step.taskEnd = step.taskStart + step.durationHours;
  // });

  // for (let index = 0; index < steps.length; index++) {
  //   const step = steps[index];
  //   for (let i = index - 1; i >= 0; i--) {
  //     squishStep(step, steps[i]);
  //   }
  // }

  // steps.forEach((step, index) => {
  //   step['epochStart'] = moment.utc(epochDate).clone().startOf('day').add(step.taskStart, 'hours').valueOf();
  //   step['epochEnd'] = moment.utc(epochDate).clone().startOf('day').add(step.taskEnd, 'hours').valueOf();
  //   step['dayStart'] = moment.utc(epochDate).clone().startOf('day').valueOf();
  // });
  // }
  /*----*/

  getTasksSortedBySubcontractor(projectSteps: any): any {
    const subcontractorSteps = {};
    projectSteps.forEach(projectStep => {
      const projectStepSubId = projectStep.subContractorId;

      if (!subcontractorSteps[projectStepSubId]) {
        subcontractorSteps[projectStepSubId] = [projectStep];
      } else {
        subcontractorSteps[projectStepSubId].push(projectStep);
      }
    });

    // Sort Subcontractor tasks by criticality
    const stepsSortedByCriticality = this.getTasksSortedByCriticality(subcontractorSteps);

    return stepsSortedByCriticality;
  }

  getTasksSortedByCriticality(projectSteps: any): any {
    for (const key in projectSteps) {
      if (projectSteps.hasOwnProperty(key)) {
        const steps = projectSteps[key];
        projectSteps[key] = Utils.sortByNumber(steps, 'criticality', false);
      }
    }

    return projectSteps;
  }

  /*-- NEW --*/
  getStepsFromDay(timestamp: number): IProjectScheduleStep[] {
    const localSchedKey = moment.utc(timestamp).startOf('week').valueOf();
    const inRangeSteps = [];
    if (this.localScheduleData[localSchedKey]) {
      const steps = this.localScheduleData[localSchedKey].steps;
      steps.forEach(step => {
        if (step.dayStart === timestamp) inRangeSteps.push(step);
      });
    }

    return inRangeSteps;
  }

  /**
   * buildLocalScheduleObject - builds a local copy of schedule data that is an object organized by week (for charts)
   */
  buildLocalScheduleObject(startOfWeek: number, toDate: number, data: any, scheduleId: string): void {
    if (scheduleId !== this.localScheduleId) {
      this.localScheduleId = scheduleId;
      this.localScheduleData = {};
    }
    while (startOfWeek <= toDate) {
      const endOfWeek = moment(startOfWeek).utc().clone().add('days', 6).valueOf();

      this.localScheduleData[startOfWeek] = {
        scheduleId: scheduleId,
        daysInRange: this.getBetweenDates(startOfWeek, endOfWeek),
        steps: this.getStepsInRange(data, startOfWeek, endOfWeek)
      };

      startOfWeek = moment(startOfWeek).utc().clone().add('week', 1).valueOf();
    }
  }

  /**
   * addLocalScheduleData - checks if local data for range exists, if not make a schedule call and appends the new data to the local copy of data
   */
  async updateLocalScheduleData(fromDate: number, toDate: number, scheduleId: string, subContractors: IProjectSubContractor[] = []): Promise<any> {
    fromDate = fromDate ? moment.utc(fromDate).clone().startOf('week').valueOf() : null; // insuring the local schedule object key is always the start of a week
    toDate = toDate ? moment.utc(toDate).clone().endOf('week').startOf('day').valueOf() : null;

    if (this.destroyed$.closed) this.destroyed$ = new ReplaySubject(1);
    return new Promise((resolve, reject) => {
      this.getSchedule(scheduleId, fromDate, toDate).pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          if (res.length > 0) {
            if (!fromDate) fromDate = moment.utc(res[0].date).clone().startOf('week').startOf('day').valueOf(); // if no fromDate get the start of first week of schedule
            if (!toDate) toDate = moment.utc(res[res.length - 1].date).clone().endOf('week').startOf('day').valueOf(); // if no toDate get the start of last week of schedule
            const chartInput = this.addAdditionalDataToSchedule(res, subContractors, []);
            this.buildLocalScheduleObject(fromDate, toDate, chartInput, scheduleId);
          }

          resolve();
        },
        err => {
          const context: INoficationContext = {
            type: 'schedule',
            action: 'get'
          };

          this.notificationService.error(err, context);

          reject(err);
        }
      );
    });
  }

  /**
   * updateLocalScheduleStep - update local step by key value pairing
   */
  updateLocalScheduleStepFromStep(rangeKey: number, itemsToUpdate: Array<{ step: IProjectScheduleStep, key: string, value: any }>): void {
    rangeKey = moment.utc(rangeKey).startOf('week').valueOf();

    itemsToUpdate.forEach(item => {
      if (this.localScheduleData[rangeKey]) {
        this.localScheduleData[rangeKey].steps.forEach(step => {
          if (item.step.id === step.id) {
            step[item.key] = item.value;
          }
        });
      }
    });
  }

  updateLocalScheduleStepFromStory(rangeKey: number, itemsToUpdate: Array<{ story: IProjectSprintStory, key: string, value: any }>): void {
    rangeKey = moment.utc(rangeKey).startOf('week').valueOf();

    itemsToUpdate.forEach(item => {
      if (this.localScheduleData[rangeKey]) {
        this.localScheduleData[rangeKey].steps.forEach(step => {
          if (item.story['projectScheduleTaskId'] === step.id) {
            step[item.key] = item.value;
          }
        });
      }
    });
  }

  getFirstProjectDayFromLocal(): number {
    const firstWeek = this.localScheduleData[Object.keys(this.localScheduleData)[0]];
    const firstWeekSteps = (firstWeek && firstWeek.steps) ? firstWeek.steps : null;
    return (firstWeekSteps && firstWeekSteps.length > 0) ? moment.utc(firstWeekSteps[0].epochStart).clone().startOf('day').valueOf() : null;
  }

  getLastProjectDayFromLocal(): number {
    const lastWeek = this.localScheduleData[Object.keys(this.localScheduleData)[Object.keys(this.localScheduleData).length - 1]];
    const lastWeekSteps = (lastWeek && lastWeek.steps) ? lastWeek.steps : null;
    return (lastWeekSteps && lastWeekSteps.length > 0) ? moment.utc(lastWeekSteps[lastWeekSteps.length - 1].epochStart).clone().startOf('day').valueOf() : null;
  }

  getStepsFromLocalScheduleByDay(day: number): IProjectScheduleStep[] {
    let filteredSteps = [];
    const startOfWeek = moment.utc(day).clone().startOf('week').valueOf();
    const startOfDay = moment.utc(day).clone().startOf('day').valueOf();
    if (this.localScheduleData[startOfWeek]) {
      filteredSteps = this.localScheduleData[startOfWeek].steps.filter(step => {
        const startDay = step.epochStart ? moment.utc(step.epochStart).startOf('day').valueOf() : null;
        const endDay = step.epochEnd ? moment.utc(step.epochEnd).startOf('day').valueOf() : null;
        return (startDay === startOfDay || endDay === startOfDay);
      });
    }

    return filteredSteps;
  }

  findStartDate(day: number): number {
    let start = moment.utc(day).clone().startOf('week').valueOf();
    while (!this.localScheduleData[start] && start < day + 365 * 86400000) {
      start += 86400000 * 7;
    }
    if (this.localScheduleData[start]) return start;
    start = moment.utc(day).clone().startOf('week').valueOf();
    while (!this.localScheduleData[start] && start > day - 365 * 86400000) {
      start -= 86400000 * 7;
    }
    if (this.localScheduleData[start]) return start;
    return day;
  }

  /*
  * getLocalScheduleKeysFromRange - returns an array of week start epochs, which is how we store schedule data
  */
  getLocalScheduleKeysFromRange(fromDate: number, toDate: number): string[] {
    const schedKeys = [];
    let startOfFirstWeek = moment.utc(fromDate).clone().startOf('week').valueOf();
    const startOfLastWeek = moment.utc(toDate).clone().startOf('week').valueOf();

    while (startOfFirstWeek <= startOfLastWeek) {
      schedKeys.push(startOfFirstWeek);
      startOfFirstWeek = moment.utc(startOfFirstWeek).add('week', 1).valueOf();
    }

    return schedKeys;
  }

  getStepsFromLocalByRange(start: number, end: number): IProjectScheduleStep[] {
    const stepsInRange = [];
    const visibleSchedKeys = this.getLocalScheduleKeysFromRange(start, end);
    if (visibleSchedKeys.length > 0) {
      visibleSchedKeys.forEach(key => {
        if (this.localScheduleData[key]) {
          stepsInRange.push(...this.localScheduleData[key].steps);
        }
      });
    }

    return stepsInRange;
  }

  getObjectIdsFromLocalByRange(start: number, end: number): string[] {
    const objIds = [];
    const visibleSchedKeys = this.getLocalScheduleKeysFromRange(start, end);
    if (visibleSchedKeys.length > 0) {
      visibleSchedKeys.forEach(key => {
        if (this.localScheduleData[key]) {
          const steps = this.localScheduleData[key].steps.filter(step => step.epochStart < end);
          objIds.push(...steps.map(step => step.objectIds ? step.objectIds.filter(id => !objIds.includes(id)) : []));
        }
      });
    }

    return [].concat.apply([], objIds);
  }

  resetLocalSchedule(): void {
    this.localScheduleData = {};
  }

  getStepsInRange(schedule: IProjectScheduleItem[], startEpoch: number, endEpoch: number): IProjectScheduleStep[] {
    const stepsInRange = [];
    schedule.forEach(day => {
      if (day.date >= startEpoch && day.date <= endEpoch) {
        const steps = day.projectScheduleSteps;
        stepsInRange.push(steps);
      }
    });
    const concatArray = [].concat(...stepsInRange);
    return Utils.sortByNumber(concatArray, 'epochStart', true);
  }

  getLocalScheduleData(): ILocalScheduleData {
    return this.localScheduleData;
  }
}
