
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { Utils } from '../../utils/utils';

import { IGritTable } from '../../shared/grit-table/grit-table';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { FormGroup } from '@angular/forms';
import { IActivity } from '../../models/activity/activity.interface';
import { IMatAutoComplete } from '../../models/material/material-components.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { ReplaySubject } from 'rxjs';
import { IUserPermission } from '../../models/user/user.interface';

@Component({
  selector: 'app-project-subcontractor',
  templateUrl: './project-subcontractor.component.html',
  styleUrls: ['./project-subcontractor.component.scss']
})
export class ProjectSubContractorComponent implements OnInit, OnDestroy {

  @Input() projectPermissionInput: IUserPermission;

  isLoading = true;

  projectId: string;
  projectSubcontractors: IProjectSubContractor[] = [];

  // table
  tableData: IGritTable;

  // modal
  showModal = false;
  formInput: FormGroup;
  subAutoInput: IMatAutoComplete;
  allActivities: IActivity[];
  colorAutoInput: IMatAutoComplete;

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private projectSubContractorService: ProjectSubContractorService,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.projectId = params['projectId'];
      this.loadPage();
      SegmentService.track('Project Subcontractors Loaded', { projectId: this.projectId });
    });
  }

  loadPage(): void {
    this.projectSubContractorService.getAssociatedSubContractors(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      projectSubcontractors => {
        this.projectSubcontractors = Utils.sortByString(projectSubcontractors, 'name');
        this.setTableData();
        this.isLoading = false;
      },
      (err) => {
        this.notificationService.error(err, {});
      });
  }

  setTableData() {
    this.tableData = this.projectSubContractorService.transformToTableData(this.projectSubcontractors, this.projectPermissionInput);
  }

  ngOnDestroy(): void {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async showCreateModal(id: string): Promise<void> {
    const currentSubcontractor = this.projectSubcontractors.find(s => s.id === id);
    if (id) this.formInput = await this.projectSubContractorService.buildFormInput(currentSubcontractor, this.projectId);
    else this.formInput = await this.projectSubContractorService.buildFormInput(null, this.projectId);

    this.subAutoInput = this.projectSubContractorService.buildSubAutoInput(currentSubcontractor);
    this.allActivities = this.projectService.getLocalAllActivities();
    this.colorAutoInput = this.projectSubContractorService.buildColorAutoInput(currentSubcontractor);

    this.showModal = true;
  }

  formOutput(subcontractor: IProjectSubContractor): void {
    this.isLoading = true;
    subcontractor.projectId = this.projectId;
    if (subcontractor.id) SegmentService.track('Project Subcontractors Edited', { id: subcontractor.id });
    else SegmentService.track('Project Subcontractors Created', { name: subcontractor.name });
    this.projectSubContractorService.addProjectSubcontractor(subcontractor).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.loadPage();
        this.showModal = false;
      },
      err => {
        this.notificationService.error(err, {});
        this.isLoading = false;
      }
    );
  }

  delete(rowIds: string[]) {
    this.isLoading = true;
    this.projectSubContractorService.deleteProjectSubcontractors(this.projectId, rowIds).subscribe(
      () => {
        SegmentService.track('Project Subcontractors Deleted', { ids: rowIds });
        rowIds.forEach(id => {
          this.projectSubcontractors.splice(this.projectSubcontractors.findIndex(pSub => pSub.id === id), 1);
        });
        this.isLoading = false;
        this.notificationService.success('SUCCESSFUL_DELETE', {});
      },
      err => {
        this.notificationService.error(err, {});
        this.setTableData();
        this.isLoading = false;
      }
    );
  }
}
