import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../services/notification/notification.service';
import { ProjectEquipmentService } from '../../../services/project/project-equipment/project-equipment.service';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';
import { ProjectReportsService } from '../../../services/project/project-reports/project-reports.service';
import { ProjectScheduleService } from '../../../services/project/project-schedule/project-schedule.service';
import { ProjectService } from '../../../services/project/project.service';
import { Utils } from '../../../utils/utils';

import { IGritTable } from '../../../shared/grit-table/grit-table';
import { INoficationContext } from '../../../models/notification/notification.interface';

@Component({
  selector: 'app-project-schedule-change-report',
  templateUrl: './project-schedule-change-report.component.html',
  styleUrls: ['./project-schedule-change-report.component.scss']
})
export class ProjectScheduleChangeReportComponent implements OnInit, OnDestroy {

  datesForm: FormGroup;
  invalidDatesMessage: string = `Start date must be before end date.`;
  isLoading: boolean = false;
  errorGettingData: boolean = false;
  tableData: IGritTable;
  csvUrl: any;
  csvFileName: string;

  schedules: any[];
  subcontractors: any[];
  subcontractorOptions;

  private rawData: any[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectReportsService: ProjectReportsService,
    private notificationService: NotificationService,
    private projectScheduleService: ProjectScheduleService,
    public projectService: ProjectService,
    private route: ActivatedRoute
  ) { }

  get subcontractorControl() { return this.datesForm.get('subcontractor'); }
  get subcontractorDisplayFn() { return Utils.defaultDisplayFn; }
  get selectedStartDate() { return this.datesForm.get('selectedStartDate'); }
  get selectedEndDate() { return this.datesForm.get('selectedEndDate'); }

  async ngOnInit() {
    const promise = this.setupForm();
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(async params => {
      await promise;
      if (params['oldScheduleId'] && params['newScheduleId']) {
        this.datesForm.get('oldScheduleId').setValue(params['oldScheduleId']);
        this.datesForm.get('newScheduleId').setValue(params['newScheduleId']);
        this.datesForm.get('subcontractor').setValue(this.subcontractors.find(s => s.id === params['subContractorId']));
        this.getReport();
      }
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.unsubscribe();
    }
  }

  async setupForm() {
    this.datesForm = this.projectReportsService.getScheduleChangeForm();
    const res = await forkJoin([
      this.projectScheduleService.get(this.projectService.currentProject.id),
      this.projectService.getProjectSubcontractors(this.projectService.currentProject.id),
      this.projectService.loadSubcontractors(this.projectService.currentProject.id)
    ]).toPromise();
    this.schedules = res[0];
    this.subcontractors = Utils.sortByString(res[1], 'name');
    this.subcontractorOptions = this.datesForm.controls['subcontractor'].valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith<any>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.subcontractors.filter(s => s.name.toLowerCase().indexOf(name.toLowerCase()) !== -1) : this.subcontractors.slice(0, Math.min(this.subcontractors.length, 50)))
    );
    if (this.schedules.find(s => s.living)) {
      this.datesForm.get('oldScheduleId').setValue(this.schedules.find(s => s.active).id);
      this.datesForm.get('newScheduleId').setValue(this.schedules.find(s => s.living).id);
    }
  }

  getReport(): void {
    this.isLoading = true;
    const fromDate = this.selectedStartDate.value ? Utils.toUtcDate(this.selectedStartDate.value) : null;
    const toDate = this.selectedEndDate.value ? Utils.toUtcDate(this.selectedEndDate.value) : null;
    this.projectScheduleService.getScheduleDifference(this.datesForm.get('oldScheduleId').value, this.datesForm.get('newScheduleId').value, fromDate, toDate)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          const sub = this.datesForm.value['subcontractor'];
          this.rawData = this.projectReportsService.flattenScheduleChangeData(res.filter(s => {
            return !sub || s.subContractorId === sub.id;
          })).sort((a, b) => {
            if (a.subcontractor === b.subcontractor) {
              return a.newStartDateValue - b.newStartDateValue;
            }
            return a.subcontractor < b.subcontractor ? -1 : 1;
          });
          this.tableData = this.projectReportsService.transformToScheduleChangeTableData(this.rawData);
          this.setupExport(this.rawData);

          this.errorGettingData = false;
          this.isLoading = false;
        },
        err => {
          const context: INoficationContext = {
            type: 'get schedule changes',
            action: 'get'
          };
          this.notificationService.error(err, context);
          this.errorGettingData = true;
          this.isLoading = false;
        }
      );
  }

  setupExport(data: any[] = []): void {
    if (data.length === 0) return;
    const csvHeaders = {
      name: 'Task',
      days: 'Deviation',
      oldStartDate: 'Old Scheduled Start Date',
      newStartDate: 'New Scheduled Start Date',
      subcontractor: 'Subcontractor'
    };

    const csvData = Utils.generateCsv(csvHeaders, data);
    this.csvUrl = csvData.url;
    this.csvFileName = csvData.fileName;
  }
}
