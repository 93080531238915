import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProcoreService } from '../../services/procore/procore.service';
import { SegmentService } from '../../services/segment/segment.service';
import { SubContractorService } from '../../services/sub-contractor/sub-contractor.service';

import { IGritTable, IGRow, IGRowItem } from '../../shared/grit-table/grit-table';
import { INoficationContext } from '../../models/notification/notification.interface';
import { ISubContractor } from '../../models/subcontractor/subcontractor.interface';
import { IUserPermission } from '../../models/user/user.interface';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-accounts-subcontractor',
  templateUrl: './accounts-subcontractor.component.html',
  styleUrls: ['./accounts-subcontractor.component.scss']
})
export class AccountsSubcontractorComponent implements OnInit, OnDestroy {
  accountId: string;
  accountPermission: IUserPermission;
  subContractors: ISubContractor[];
  tableData: IGritTable;

  hasProcore = false;
  showProcoreCompaniesModal = false;
  procoreCompaniesModalLoading = true;
  procoreCompanies = [];
  procoreCompanyId;
  procoreImporting = false;

  // Subscription
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private accountService: AccountService,
    public route: ActivatedRoute,
    private procoreService: ProcoreService,
    private subContractorService: SubContractorService
  ) { }

  ngOnInit() {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.accountId = params['accountId'];

      this.accountService.getAccountPermission(this.accountId).pipe(takeUntil(this.destroyed$)).subscribe(permission => {
        this.accountPermission = this.accountService.transformPermissions(permission);
      });

      this.hasProcore = this.accountService.currentAccount.hasProcore;

      this.getSubContractors(this.accountId);

      SegmentService.track('Account Subcontractors Loaded', { accountId: this.accountId });
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getSubContractors(accountId: string) {
    this.subContractorService.getSubContractorList(accountId).pipe(takeUntil(this.destroyed$)).subscribe(
      subContractors => {
        this.subContractors = Utils.sortByString(subContractors, 'name');

        this.tableData = this.subContractorService.transformToTableData(this.subContractors, this.accountPermission);
      },
      err => {
        const context: INoficationContext = {
          type: 'subcontractor',
          action: 'get'
        };

        this.notificationService.error(err, context);
      });
  }

  create(rowItems: IGRowItem[]): void {
    const newSubContractor = {
      accountId: this.accountId,
      name: rowItems.find(ri => ri.colKey === 'name').value,
      address: rowItems.find(ri => ri.colKey === 'address').value
    };

    this.subContractorService.createSubContractor(newSubContractor).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.getSubContractors(this.accountId);
        SegmentService.track('Account Subcontractor Added', { name: newSubContractor.name });
      },
      err => {
        const context: INoficationContext = {
          type: 'subcontractor',
          action: 'create'
        };

        this.notificationService.error(err, context);
      }
    );
  }

  deleteRows(rowIds: string[]): void {
    const observableArray: Array<Observable<any>> = [];
    let subContractorId;
    rowIds.forEach(id => {
      subContractorId = id;
      observableArray.push(this.subContractorService.deleteSubContractor(subContractorId));
    });

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Account Subcontractor Deleted', { subContractorId: subContractorId });
      },
      err => {
        const context: INoficationContext = {
          type: 'subcontractor',
          action: 'remove'
        };
        this.notificationService.error(err, context);
        this.getSubContractors(this.accountId);
      });
  }

  edit(row: IGRow): void {
    const subContractor = {
      accountId: this.accountId,
      address: row.rowItems.find(ri => ri.colKey === 'address').value,
      id: row.key,
      name: row.rowItems.find(ri => ri.colKey === 'name').value
    };

    this.subContractorService.updateSubContractor(subContractor).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Account Subcontractor Edited', {});
      },
      err => {
        const context: INoficationContext = {
          type: 'subcontractor',
          action: 'edit'
        };

        this.notificationService.error(err, context);
        this.getSubContractors(this.accountId);
      });
  }

  showProcoreImportModal(): void {
    this.showProcoreCompaniesModal = true;
    this.procoreCompaniesModalLoading = true;
    this.procoreService.getProcoreCompanies(this.accountId).subscribe(
      companies => {
        this.procoreCompaniesModalLoading = false;
        this.procoreCompanies = Utils.sortByString(companies, 'name');
      },
      () => {
        this.showProcoreCompaniesModal = false;
        this.notificationService.error('ERROR');
      });
  }

  importFromProcore(): void {
    this.procoreImporting = true;
    const body = {
      accountId: this.accountId,
      procoreCompanyId: this.procoreCompanyId
    };
    this.subContractorService.importFromProcore(body).subscribe(
      () => {
        this.procoreImporting = false;
        this.showProcoreCompaniesModal = false;
        this.getSubContractors(this.accountId);
      }
    );
  }

  closeProcoreModal(): void {
    if (!this.procoreImporting) this.showProcoreCompaniesModal = false;
  }
}
