
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable , ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { NotificationService } from '../../services/notification/notification.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';

import { IGritTable, IGRow, IGRowItem } from '../../shared/grit-table/grit-table';
import { IUser, IUserPermission } from '../../models/user/user.interface';

import { INoficationContext } from '../../models/notification/notification.interface';
import { Permission } from '../../utils/enums/permission.enum';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-accounts-user',
  templateUrl: './accounts-user.component.html',
  styleUrls: ['../accounts.component.scss']
})
export class AccountsUserComponent implements OnInit, OnDestroy {
  authenticatedUser: IUser;
  accountId: string;
  accountPermission: IUserPermission;
  users: IUser[];
  tableData: IGritTable;

  // Subscription
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private notificationService: NotificationService,
    private accountService: AccountService,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.accountId = params['accountId'];

      this.accountService.getAccountPermission(this.accountId).subscribe(permission => {
        this.accountPermission = this.accountService.transformPermissions(permission);
      });

      this.getAccountUsers();
      SegmentService.track('Account Users Loaded', {accountId: this.accountId});
    });
    this.userService.getAuthenticatedUser().subscribe(
      success => {
        this.authenticatedUser = success;
      },
      err => {
        const context: INoficationContext = {
          type: 'user',
          action: 'get'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getAccountUsers(): void {
    this.accountService.getAccountUsers(this.accountId).subscribe(
      res => {
        this.users = Utils.sortByString(res, 'email');
        this.tableData = this.accountService.transformToUserTableData(this.users, this.accountPermission);
      },
      err => {
        const context: INoficationContext = {
          type: 'account user(s)',
          action: 'get'
        };

        this.notificationService.error(err, context);
      });
  }

  create(rowItems: IGRowItem[]): void {
    const newUser = {
      id: this.accountId,
      email: rowItems.find(ri => ri.colKey === 'email').value,
      permission: rowItems.find(ri => ri.colKey === 'permission').value
    };

    if (Utils.isEmpty(newUser.permission)) newUser.permission = Permission.ReadOnly;

    this.accountService.createAccountUser(newUser).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Account User Created', {email: newUser.email});
        this.getAccountUsers();
      },
      err => {
        const context: INoficationContext = {
          type: 'account user',
          action: 'create'
        };

        this.notificationService.error(err, context);
      }
    );
  }

  deleteRows(rowIds: string[]) {
    const observableArray: Array<Observable<any>> = new Array();
    rowIds.forEach(id => {
      observableArray.push(this.accountService.deleteAccountUser(this.accountId, id));
    });

    forkJoin(observableArray).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Account Users Deleted', {userIds: rowIds});
        if (rowIds.includes(this.authenticatedUser.id)) {
          this.router.navigateByUrl('/home');
        }
      },
      err => {
        const context: INoficationContext = {
          type: 'account user',
          action: 'remove'
        };

        this.notificationService.error(err, context);
        this.getAccountUsers();
    });
  }

  edit(row: IGRow) {
    const entry = {
      id: this.accountId,
      permission: row.rowItems.find(ri => ri.colKey === 'permission').value
    };

    this.accountService.updateAccountUser(entry, row.key).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('Account Users Edited', {userIds: row.key});
      },
      err => {
        const context: INoficationContext = {
          type: 'account user(s)',
          action: 'edit'
        };
        this.notificationService.error(err, context);
        this.getAccountUsers();
      });
  }
}
