import * as FileSaver from 'file-saver';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ICustomTable, IRow, ITableSearchOptions } from '../../models/custom-table/custom-table.interface';
import { IProjectRfi } from '../../models/project/project-rfi/project-rfi.interface';
import { IUserPermission } from '../../models/user/user.interface';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectRfiService } from '../../services/project/project-rfi/project-rfi.service';

import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';

import { IGritTable } from '../../shared/grit-table/grit-table';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-rfi-settings',
  templateUrl: './project-rfi-settings.component.html',
  styleUrls: ['./project-rfi-settings.component.scss']
})

export class ProjectRfiSettingsComponent implements OnInit, OnDestroy {

  @Input() projectPermissionInput: IUserPermission;
  @Input() projectId: string;

  @Output() ProcurementLineFilter: EventEmitter<boolean> = new EventEmitter();

  file: any = null;
  showExport: boolean = true;
  upLoading: boolean = false;
  isLoading: boolean = true;
  tableData: IGritTable;

  currentRfiData: IProjectRfi[] = [];
  rfi: IProjectRfi[] = [];
  showModal: boolean = false;
  formInput: FormGroup;
  currency;

  hasProcore = false;
  showProcLinesOnMaster = true;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private rfiService: ProjectRfiService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private segmentService: SegmentService,
  ) { }

  ngOnInit() {
    this.getData();
    this.showProcLinesOnMaster = (localStorage.getItem('previouslySavedProcuremntLinesToggle') == "true");
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async getData() {
    if (this.projectService.currentProjectAccount.hasProcore && this.projectService.currentProject.procoreProjectId) this.hasProcore = true;
    let gcOnly = true;
    let subId = null;
    if (this.projectPermissionInput.subContractorId) {
      gcOnly = false;
      subId = this.projectPermissionInput.subContractorId;
    }
    const results = await this.rfiService.getList(this.projectId, subId, gcOnly).toPromise();

    this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    if (results) {
      this.rfi = Utils.sortByString(results, 'subject');
      this.setTableData();
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  setTableData() {
    this.tableData = this.rfiService.transformToTableData(this.rfi, this.projectPermissionInput, this.currency);
  }

  async edit(item: IProjectRfi) {
    // if (this.projectPermissionInput.subContractorId) item.subId = this.projectPermissionInput.subContractorId;
    this.rfiService.update(item.id, item).pipe(take(1)).subscribe(
      (res) => {
        const index = this.rfi.findIndex(i => i.id === res.id);
        this.rfi[index] = res;
        this.setTableData();
        this.showModal = false;
      },
      err => {
        this.notificationService.error(err, {type: 'rfi', action: 'update'});
      }
    );
  }

  async deleteRows(selectedIds) {
    await this.rfiService.delete(selectedIds).toPromise();
    this.rfi = this.rfi.filter(item => !selectedIds.includes(item.id));
  }

  async showCreateModal(id: string): Promise<void> {
    const currentItem = this.rfi.find(s => s.id === id);
    if (id) this.formInput = this.rfiService.buildFormInput(currentItem);
    else this.formInput = this.rfiService.buildFormInput(null);
    this.showModal = true;
  }

  formOutput(item: any) {
    if (item.id) this.edit(item);
  }

  setProcLinesToggle(event) {
    this.showProcLinesOnMaster = event.checked;
    this.ProcurementLineFilter.emit(event.checked);
    localStorage.setItem('previouslySavedProcuremntLinesToggle', event.checked);
  }

  importFromProcore() {
    this.isLoading = true;
    this.rfiService.importFromProcore(this.projectId).subscribe(
      res => {
        this.rfi = this.rfi.concat(res);
        this.rfi = Utils.sortByString(this.rfi, 'subject');
        this.currentRfiData = this.rfi;
        this.setTableData();
        this.isLoading = false;
      },
      err => {
        this.notificationService.error(err);
        this.isLoading = false;
      });
  }
}
