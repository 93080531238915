export enum MakeReadyTypes {
    Material = 'material',
    Equipment = 'equipment'
}

export enum CostReportValueTypes {
    Equipment = 'equipment',
    Labor = 'labor',
    Material = 'material',
    Total = 'total'
}

export enum ChartIntervalState {
    Day = 'day',
    Week = 'week',
    Month = 'month'
}