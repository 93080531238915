import { Buffer } from 'buffer/';

function readFloats(buffer, i, length, list) {
    for (let j = 0; j < length; j++) {
        list.push(buffer.readFloatBE(i));
        i += 4;
    }
    return i;
}

function readInts(buffer, i, length, list) {
    for (let j = 0; j < length; j++) {
        list.push(buffer.readUInt16BE(i));
        i += 2;
    }
    return i;
}

export interface SimpleMesh {
    vertices: number[];
    triangles: number[];
    copies: number[];
    material: number[];
    fragments: number[];
}

export function DecodeMesh(buffer: Buffer) {
    const vertices: number[] = [];
    const triangles: number[] = [];
    const copies: number[] = [];
    const material: number[] = [];
    const fragments: number[] = [];

    let i = 0;
    const fragCount = buffer.readInt32BE(i);
    i += 4;
    for (let j = 0; j < fragCount; j++) {
        const listCount = buffer.readInt32BE(i) / 4;
        i += 4;
        const counts = [];
        for (let k = 0; k < listCount; k++) {
            counts.push(buffer.readInt32BE(i));
            i += 4;
        }

        i = readFloats(buffer, i, counts[0] / 4, vertices);
        i = readInts(buffer, i, counts[1] / 2, triangles);
        i = readFloats(buffer, i, counts[2] / 4, material);
        i = readInts(buffer, i, counts[3] / 2, copies);
        i = readInts(buffer, i, counts[5] / 2, fragments);

        if (fragments.length === 0) fragments.push(vertices.length / 3);
        return {
            vertices,
            triangles,
            copies,
            material,
            fragments
        };
    }
}

function compactFloats(list) {
    const b = Buffer.alloc(list.length * 4);
    if (list) {
        list.forEach((f, i) => {
            b.writeFloatBE(f, i * 4);
        });
    }
    return b;
}

function compactInts(list) {
    const b = Buffer.alloc(list.length * 2);
    if (list) {
        list.forEach((f, i) => {
            b.writeUInt16BE(f, i * 2);
        });
    }
    return b;
}

export function EncodeMesh(mesh: SimpleMesh, lod): Buffer {
    const b: Buffer = Buffer.alloc(8 * 4);
    b.writeInt32BE(7 * 4, 0);
    b.writeInt32BE(mesh.vertices.length * 4, 4);
    b.writeInt32BE(mesh.triangles.length * 2, 8);
    b.writeInt32BE(mesh.material.length * 4, 12);
    b.writeInt32BE(mesh.copies.length * 2, 16);
    b.writeInt32BE(0, 20);
    b.writeInt32BE(mesh.fragments.length * 2, 24);
    b.writeFloatBE(lod, 28);

    return Buffer.concat([
        b,
        compactFloats(mesh.vertices),
        compactInts(mesh.triangles),
        compactFloats(mesh.material),
        compactInts(mesh.copies),
        compactInts(mesh.fragments)
    ]);
}
