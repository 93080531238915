import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivityService } from '../../../services/activity/activity.service';
import { IProjectLabor } from '../../../models/project/project-labor/project-labor.interface';
import { ProjectLaborService } from '../../../services/project/project-labor/project-labor.service';
import { ProjectService } from '../../../services/project/project.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IGritTable, IGRow, IGRowItem } from '../../../shared/grit-table/grit-table';
import { Utils } from '../../../utils/utils';
@Component({
  selector: 'app-project-activity-labor-form',
  templateUrl: './project-activity-labor-form.component.html',
  styleUrls: ['./project-activity-labor-form.component.scss']
})

export class ProjectActivityLaborFormComponent implements OnInit {

  @Input() laborIds: string[] = [];
  @Input() selectedActivityIds: Array<string>;
  @Output() updateOutput: EventEmitter<{laborIds: string[]}> = new EventEmitter();
  @Output() updateParameters:  EventEmitter<Array<any>> = new EventEmitter();

  allLabors: IProjectLabor[] = [];
  filteredLabors: Observable<IProjectLabor[]>;
  laborOptions: IProjectLabor[];
  laborCtrl = new FormControl();
  laborChips: IProjectLabor[];
  tableData: IGritTable;
  totalBudget: string;
  currency;
  autoAssignResources: boolean;

  get displayFn() { return Utils.defaultDisplayFn; }

  constructor(
    private laborService: ProjectLaborService,
    private projectService: ProjectService,
    private activityService: ActivityService
  ) {/*EMPTY*/}

  ngOnInit() {
    this.allLabors = this.laborService.getLocalAllProjectLabor();
    this.setValidOptions();
    this.setAutocomplete();
    if(this.selectedActivityIds.length === 1){
         this.activityService.getActivityLabors(this.selectedActivityIds[0]).subscribe(res => {
      this.laborChips = res;
      this.setTableData();
    });
    } else{
      this.activityService.getActivitiesLabors(this.selectedActivityIds).subscribe(res => {
        this.laborChips = res;
        this.setTableData();
      });
    }
    this.projectService.currentProject.autoAssignResources ? this.autoAssignResources = this.projectService.currentProject.autoAssignResources : this.autoAssignResources = false;
    // this.activityService.getActivityLabors(this.selectedActivityId).subscribe(res => {
    //   this.laborChips = res;
    //   this.setTableData();
    // });
  }

  setTableData() {
    this.currency = this.projectService.getCurrencyCodes(this.projectService.currentProject.currency);
    this.autoAssignResources ?  this.tableData = this.laborService.transformToTableDataForActivityWithAutoAssign(this.laborChips, ProjectService.userPermission, this.currency) : this.tableData = this.laborService.transformToTableDataForActivity(this.laborChips, ProjectService.userPermission, this.currency);
    this.calculateTotalBudget();
  }

  calculateTotalBudget() {
    let totalBudget: any = 0.00;
    const data: any = this.laborChips;

    data.forEach((item) => {
      let selectedValue = 0;

      if (item.elementUnit.toString() === 'area') {
        selectedValue = item.areaSum ? item.areaSum : 0;
      } else if (item.elementUnit.toString() === 'volume') {
        selectedValue = item.volumeSum ? item.volumeSum : 0;
      } else if (item.elementUnit.toString() === 'length') {
        selectedValue = item.lengthSum ? item.lengthSum : 0;
      } else if (item.elementUnit.toString() === 'unit') {
        selectedValue = 1;
      }

      const plannedQuantity: any =  item.isMannuallyAddedPlannedQty ? parseFloat(item.labPlannedQuantity) : selectedValue * item.resourceRate;
      const budgetAssigned: any = plannedQuantity * item.cost;
      totalBudget += parseFloat(budgetAssigned);
    });

    // totalBudget = totalBudget)) * 100) / 100).toString()).toFixed(2);
    this.totalBudget = `$ ${totalBudget.toFixed(2)}`;
  }

  deleteRows(Ids) {
    Ids.forEach(id => {
      this.removeLabor(id);
    });
  }

  removeLabor(laborId: string) {
    this.laborIds = this.laborIds.filter(id => id !== laborId);
    this.laborChips = this.laborChips.filter(item => item.id !== laborId);
    this.updateOutput.emit({laborIds: this.laborIds});
    this.clearInput();
    this.calculateTotalBudget();
  }

  editRows(event) {
    const laborId = event.key;
    let updatedlabor: any = [];
    updatedlabor = this.laborChips.find(l => l.id === laborId);
    if(updatedlabor.isMannuallyAddedPlannedQty){
      updatedlabor.equipPlannedQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'plannedQuantity').value;
    }

    updatedlabor.plannedQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'plannedQuantity').value;
    updatedlabor.actualQuantity = event.rowItems && event.rowItems.find(r => r.colKey === 'actualQuantity').value;
    this.updateParameters.emit(updatedlabor);
    this.calculateTotalBudget();
  }

  laborSelected(matLabor) {
    const labor: IProjectLabor = matLabor.option.value;
    this.laborIds.push(labor.id);
    this.laborChips.push(this.laborOptions.find(item => item.id === labor.id));
    this.setTableData();
    this.updateOutput.emit({laborIds: this.laborIds});
    this.clearInput();
  }

  setValidOptions(): void {
    this.laborOptions = this.allLabors.filter(item => !this.laborIds.includes(item.id));
  }

  setAutocomplete(): void {
    this.filteredLabors = this.laborCtrl.valueChanges.pipe(
      startWith<string | IProjectLabor >(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.laborSearch(name) : this.getAlllaborOptions()));
  }

  laborSearch(name: string) {
    const searchQuery = name.toLowerCase();
    const searchRes = this.laborOptions.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAlllaborOptions() {
    // rconsole
    return this.laborOptions.slice(0, Math.min(this.laborOptions.length, 50));
  }

  clearInput(): void {
    this.laborCtrl.setValue('');
    this.laborCtrl.updateValueAndValidity();
    this.setValidOptions();
    this.setAutocomplete();
  }
}
