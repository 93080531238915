import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GestureConfig, MatAutocompleteModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatFormFieldModule, MatIconModule,
         MatInputModule, MatMenuModule, MatNativeDateModule, MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';

// THIRD PARTY
import { TreeModule } from 'angular-tree-component';
import { SocketIoConfig, SocketIoModule } from 'ng-socket-io';

import { D3Service } from 'd3-ng2-service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { SplitPaneModule } from 'ng2-split-pane/lib/ng2-split-pane';

// GUARDS
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/canDeactivate.guard';
import { CriteriaGuard } from './guards/criteria.guard';
import { LoggedInGuard } from './guards/loggedIn.guard';

// BASE
import { BaseTemplateComponent } from './app.component';

// BOOTSTRAP
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// PIPES
import { GritDatePipe } from '../assets/pipes/date.pipe';
import { NumberToTextPipe } from '../assets/pipes/numToText.pipe';
import { HoursToDays } from '../assets/pipes/hoursToDays.pipe';
import { ObjectKeysPipe } from '../assets/pipes/objectKeys.pipe';
import { OrderByPipe } from '../assets/pipes/orderBy.pipe';
import { SafeHtml, SafeUrl } from '../assets/pipes/safe.pipe';
import { TranslationPipe } from '../assets/pipes/translate.pipe';

import { AutofocusDirective } from '../assets/directives/autoFocus.directive';

// COMPONENTS
import { AccountsCreateComponent } from './accounts/accounts-create/accounts-create.component';
import { AccountsIntegrationsComponent } from './accounts/accounts-integrations/accounts-integrations.component';
import { AccountsSettingsComponent } from './accounts/accounts-settings/accounts-settings.component';
import { AccountsSubcontractorComponent } from './accounts/accounts-subcontractor/accounts-subcontractor.component';
import { AccountsTokenComponent } from './accounts/accounts-token/accounts-token.component';
import { AccountsUserComponent } from './accounts/accounts-user/accounts-user.component';
import { AccountsComponent } from '../app/accounts/accounts.component';
import { ActivityEquipmentAndMaterialsComponent } from './project/project-master-schedule/activity-equipment-and-materials/activity-equipment-and-materials.component';
import { ActivityMaterialsComponent } from './project/project-master-schedule/activity-materials/activity-materials.component';
import { BrowserComponent } from './browser-support/browser-support.component';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { CostFormComponent } from './project/project-master-schedule/cost-form/cost-form.component';
import { CustomTableComponent } from './shared/custom-table/custom-table.component';
import { DynamicDropdownComponent } from './shared/dynamic-dropdown/dynamic-dropdown.component';
import { EditActivityModalComponent } from './project/project-master-schedule/edit-activity-modal/edit-activity-modal.component';
import { BulkEditActivityModalComponent } from './project/project-master-schedule/bulkEdit-activity-modal/bulkEdit-activity-modal.component';
import { RowEditActivitiesModalComponent } from './project/project-master-schedule/rowEdit-activities-modal/rowEdit-activities-modal.component';
import { EditStepModalComponent } from './project/project-planner/edit-step-modal/edit-step-modal.component';
import { ExceptionsTableComponent } from './project/project-master-schedule/exceptions-table/exceptions-table.component';
import { GritTableComponent } from './shared/grit-table/grit-table.component';
import { GritrisComponent } from './shared/gritris/gritris.component';
import { HomeComponent } from './home/home.component';
import { ImageModalComponent } from './shared/image-modal/image-modal.component';
import { ImportGanttModalComponent } from './project/project-master-schedule/import-gantt-modal/import-gantt-modal.component';
import { LoadingComponentComponent } from './shared/loading-component/loading-component.component';
import { LoginComponent } from './login/login.component';
import { LowerConfirmationComponent } from './shared/lower-confirmation/lower-confirmation.component';
import { MenuComponent } from './menu/menu.component';
import { NotificationComponent } from '../app/shared/notification/notification.component';
import { NotificationsComponent } from './menu/notifications/notifications.component';
import { PageHeaderComponent } from './shared/page-header/page-header.component';
import { PageSecondaryHeaderComponent } from './shared/page-secondary-header/page-secondary-header.component';
import { PageSubmenuComponent } from './shared/page-submenu/page-submenu.component';
import { PopupModalComponent } from './shared/popup-modal/popup-modal.component';
import { PrerequisitesTableComponent } from './project/project-master-schedule/prerequisites-table/prerequisites-table.component';
import { ProfileAvatarComponent } from './users/profile-avatar/profile-avatar.component';
import { ProgressBarComponent } from './shared/progress-bar/progress-bar.component';
import { ProjectActivityFormComponent } from './project/project-master-schedule/project-activity-form/project-activity-form.component';
import { ProjectActivitiesFormComponent } from './project/project-master-schedule/project-activities-form/project-activities-form.component';
import { ProjectActivityTableComponent } from './project/project-master-schedule/project-activity-table/project-activity-table.component';
import { ProjectActivityRfiFormComponent } from './project/project-master-schedule/project-activity-rfi-form/project-activity-rfi-form.component';
import { ProjecBudgetReportComponent } from './project/project-report/project-budget-report/project-budget-report.component';
import { ProjectCreateComponent } from './project/project-create/project-create.component';
import { ProjectScheduleCriteriaCheckComponent } from './project/project-criteria-check/project-criteria-check.component';
import { ProjectDashboardFeedComponent } from './project/project-dashboard-feed/project-dashboard-feed.component';
import { ProjectDashboardGridComponent } from './project/project-dashboard-grid/project-dashboard-grid.component';
import { ProjectDashboardLivingScheduleComponent } from './project/project-dashboard-living-schedule/project-dashboard-living-schedule.component';
import { ProjectDashboardPlanCompleteComponent } from './project/project-dashboard-plan-complete/project-dashboard-plan-complete.component';
import { ProjectDashboardPlanNotCompleteComponent } from './project/project-dashboard-plan-not-complete/project-dashboard-plan-not-complete.component';
import { ProjectDashboardProjectCompleteComponent } from './project/project-dashboard-project-complete/project-dashboard-project-complete.component';
import { ProjectDashboardSprintCompleteComponent } from './project/project-dashboard-sprint-complete/project-dashboard-sprint-complete.component';
import { ProjectDashboardSprintProductivityComponent } from './project/project-dashboard-sprint-productivity/project-dashboard-sprint-productivity.component';
import { ProjectDashboardSprintResourcesComponent } from './project/project-dashboard-sprint-resources/project-dashboard-sprint-resources.component';
import { ProjectDashboardComponent } from './project/project-dashboard/project-dashboard.component';
import { ProjectEquipmentSettingsComponent } from './project/project-equipment-settings/project-equipment-settings.component';
import { ProjectFilesComponent } from './project/project-files/project-files.component';
import { ProjectFloorPlanComponent } from './project/project-Floor-Plan/project-floor-plan.component';
import { ProjectForgeViewerComponent } from './project/project-forge-viewer/project-forge-viewer.component';
import { ProjectPDFViewerComponent } from './project/project-PDF-viewer/project-PDF-viewer.component';
import { ProjectPDFControlsComponent } from './project/Project-PDF-Controls/project-PDF-controls.component';
import { ProjectPDFPopoutComponent } from './project/project-pdf-popout/project-pdf-popout.component';
import { ProjectGanttChartComponent } from './project/project-master-schedule/project-gantt-chart/project-gantt-chart.component';
import { ProjectHomeworkFeedComponent } from './project/project-dashboard/project-homework-feed/project-homework-feed.component';
import { ProjectMakeReadyReportComponent } from './project/project-report/project-make-ready-report/project-make-ready-report.component';
import { ProjectMakeReadyComponent } from './project/project-make-ready/project-make-ready.component';
import { ProjectManpowerReportComponent } from './project/project-report/project-manpower-report/project-manpower-report.component';
import { ProjectMasterScheduleComponent } from './project/project-master-schedule/project-master-schedule.component';
import { ProjectMaterialFormComponent } from './project/project-material-settings/project-material-form/project-material-form.component';
import { ProjectLaborFormComponent } from './project/project-labor-settings/project-labor-form/project-labor-form.component';
import { ProjectMaterialSettingsComponent } from './project/project-material-settings/project-material-settings.component';
import { ProjectLaborSettingsComponent } from './project/project-labor-settings/project-labor-settings.component';
import { ProjectRfiSettingsComponent } from './project/project-rfi-settings/project-rfi-settings.component';
import { ProjectRfiFormComponent } from './project/project-rfi-settings/project-rfi-form/project-rfi-form.component';
import { ProjectMessageSidebarComponent } from './project/project-message-sidebar/project-message-sidebar.component';
import { ProjectMessagesComponent } from './project/project-messages/project-messages.component';
import { ProjectMilestoneFormComponent } from './project/project-master-schedule/project-milestone-form/project-milestone-form.component';
import { ProjectMilestoneSettingsComponent } from './project/project-milestone-settings/project-milestone-settings.component';
import { ProjectModelConflictsComponent } from './project/project-model-conflicts/project-model-conflicts.component';
import { ProjectModelFilterComponent } from './project/project-model-filter/project-model-filter.component';
import { ProjectModelsComponent } from './project/project-models/project-models.component';
import { ProjectNetworkMapViewerComponent } from './project/project-network-map/project-network-map-viewer/project-network-map-viewer.component';
import { ProjectNetworkMapComponent } from './project/project-network-map/project-network-map.component';
import { ProjectObjectAddUnmodeledComponent } from './project/project-planner/project-object-add-unmodeled/project-object-add-unmodeled.component';
import { ProjectObjectPropertiesComponent } from './project/project-object-properties/project-object-properties.component';
import { ProjectPlannerFormComponent } from './project/project-planner/project-planner-form/project-planner-form.component';
import { ProjectPlannerComponent } from './project/project-planner/project-planner.component';
import { ProjectPopOutViewerComponent } from './project/project-pop-out-viewer/project-pop-out-viewer.component';
import { ProjectProcoreSettingsComponent } from './project/project-settings/project-procore-settings/project-procore-settings.component';
import { ProjectReasonsTableComponent } from './project/project-settings/project-reasons-table/project-reasons-table.component';
import { ProjectReportComponent } from './project/project-report/project-report.component';
import { ProjectRollingBudgetReportComponent } from './project/project-report/project-rolling-budget-report/project-rolling-budget-report.component';
import { ProjectScenarioFormComponent } from './project/project-schedule-scenarios/project-scenario-form/project-scenario-form.component';
import { ProjectScheduleCalendarComponent } from './project/project-schedule-calendar/project-schedule-calendar.component';
import { ProjectScheduleChangeReportComponent } from './project/project-report/project-schedule-change-report/project-schedule-change-report.component';
import { ProjectScheduleChartControlsComponent } from './project/project-schedule-chart/project-schedule-chart-controls/project-schedule-chart-controls.component';
import { ProjectScheduleChartLegendComponent } from './project/project-schedule-chart-legend/project-schedule-chart-legend.component';
import { ProjectScheduleChartComponent } from './project/project-schedule-chart/project-schedule-chart.component';
import { ProjectScheduleScenariosComponent } from './project/project-schedule-scenarios/project-schedule-scenarios.component';
import { ProjectScheduleComponent } from './project/project-schedule/project-schedule.component';
import { ProjectSettingsComponent } from './project/project-settings/project-settings.component';
import { ProjectSidebarComponent } from './project/project-sidebar-component/project-sidebar-component.component';
import { ProjectSidebarFilterComponent } from './project/project-sidebar-filter/project-sidebar-filter.component';
import { ProjectSidebarProcurementComponent } from '../app/project/project-sidebar-procurement/project-sidebar-procurement.component';
import { ProjectSprintBoardComponent } from './project/project-sprints/project-sprint-board/project-sprint-board.component';
import { ProjectSprintChartComponent } from './project/project-sprints/project-sprint-chart/project-sprint-chart.component';
import { ProjectSprintCommitmentComponent } from './project/project-sprints/project-sprint-commitment/project-sprint-commitment.component';
import { ProjectSprintFormComponent } from './project/project-sprints/project-sprint-form/project-sprint-form.component';
import { ProjectSprintResultFormComponent } from './project/project-sprints/sprint-result-modal/project-sprint-result-form/project-sprint-result-form.component';
import { ProjectSprintsComponent } from './project/project-sprints/project-sprints.component';
import { ProjectStepFormComponent } from './project/project-planner/project-step-form/project-step-form.component';
import { ProjectStepInfoComponent } from './project/project-step-info/project-step-info.component';
import { ProjectSubcontractorFormComponent } from '../app/project/project-subcontractor//project-subcontractor-form/project-subcontractor-form.component';
import { ProjectSubcontractorStepsSidebarComponent } from './project/project-subcontractor-steps-sidebar/project-subcontractor-steps-sidebar.component';
import { ProjectSubContractorComponent } from '../app/project/project-subcontractor/project-subcontractor.component';
import { ProjectTaskListComponent } from './project/project-planner/project-task-list/project-task-list.component';
import { ProjectUserFormComponent } from '../app/project/project-user/project-user-form/project-user-form.component';
import { ProjectUserComponent } from './project/project-user/project-user.component';
import { ProjectWorkPackagesComponent } from './project/project-planner/project-work-packages/project-work-packages.component';
import { ProjectWorkDaysComponent } from './project/project-workdays/project-workdays.component';
import { ProjectComponent } from './project/project.component';
import { ScheduleModalComponent } from './shared/schedule-modal/schedule-modal.component';
import { SearchInputComponent } from './shared/search-input/search-input.component';
import { SelectionModalComponent } from './shared/selection-modal/selection-modal.component';
import { SnapshotsSidebarComponent } from './project/project-model-filter/snapshots-sidebar/snapshots-sidebar.component';
import { SprintResultEquipmentComponent } from './project/project-sprints/sprint-result-modal/sprint-result-equipment/sprint-result-equipment.component';
import { SprintResultMaterialsComponent } from './project/project-sprints/sprint-result-modal/sprint-result-materials/sprint-result-materials.component';
import { SprintResultModalComponent } from './project/project-sprints/sprint-result-modal/sprint-result-modal.component';
import { StaticDropdownComponent } from './shared/static-dropdown/static-dropdown.component';
import { StepEquipmentAndMaterialsComponent } from './project/project-planner/step-equipment-and-materials/step-equipment-and-materials.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { UsersForgotPasswordComponent } from './users/users-forgot-password/users-forgot-password.component';
import { UsersRejectComponent } from './users/users-reject/users-reject.component';
import { UsersVerifyComponent } from './users/users-verify/users-verify.component';
import { WorkPackagePrereqFormComponent } from './project/project-planner/work-package-prereq-form/work-package-prereq-form.component';
import { AccountsTrialComponent } from './accounts/accounts-trial/accounts-trial.component';
import { GenerateScheduleModalComponent } from './project/project-master-schedule/generate-schedule-modal/generate-schedule-modal.component';
import { ProjectSprintInprogressResultFormComponent } from './project/project-sprints/sprint-result-modal/project-sprint-inprogress-result-form/project-sprint-inprogress-result-form.component';
import { ProjectEquipmentFormComponent } from './project/project-equipment-settings/project-equipment-form/project-equipment-form.component';
import { ProjectActivityLaborFormComponent } from './project/project-master-schedule/project-activity-labor-form/project-activity-labor-form.component';
import { SprintResultLaborComponent } from './project/project-sprints/sprint-result-modal/sprint-result-labor/sprint-result-labor.component';

// SERVICES
import { AccountService } from './services/account/account.service';
import { ActivityService } from './services/activity/activity.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { CacheService } from './services/cache/cache.service';
import { ChartService } from './services/chart/chart.service';
import { ColorPaletteService } from './services/color-palette/color-palette.service';
import { ForgeViewerService } from './services/forge/forge-viewer.service';
import { ForgeService } from './services/forge/forge.service';
import { PDFViewerService } from './services/pdf/pdfViewer.service';
import { HttpBackendService } from './services/http-backend/http-backend.service';
import { MenuService } from './services/menu/menu.service';
import { NotificationService } from '../app/services/notification/notification.service';
import { ProcoreService } from './services/procore/procore.service';
import { ProjectActivityTypesService } from './services/project/project-activity-types/project-activity-types.service';
import { ProjectDashboardService } from './services/project/project-dashboard/project-dashboard.service';
import { ProjectEquipmentService } from './services/project/project-equipment/project-equipment.service';
import { ProjectFilterSearchService } from './services/project/project-filter-search/project-filter-search.service';
import { ProjectGanttChartService } from './services/project/project-gantt-chart/project-gantt-chart.service';
import { ProjectHeaderService } from './services/project/project-header/project-header.service';
import { ProjectMasterScheduleService } from './services/project/project-master-schedule/project-master-schedule.service';
import { ProjectMaterialService } from './services/project/project-material/project-material.service';
import { ProjectLaborService } from './services/project/project-labor/project-labor.service';
import { ProjectRfiService } from './services/project/project-rfi/project-rfi.service';
import { ProjectMessageService } from './services/project/project-message/project-message.service';
import { ProjectMilestoneService } from './services/project/project-milestone/project-milestone.service';
import { ProjectModelService } from './services/project/project-model/project-model.service';
import { ProjectNetworkMapService } from './services/project/project-network-map/project-network-map.service';
import { ProjectObjectService } from './services/project/project-object/project-object.service';
import { CriteriaKeyEnum, ProjectPageCriteriaService } from './services/project/project-page-criteria/project-page-criteria.service';
import { ProjectPlannerPopoutModalService } from './services/project/project-planner-popout-modal/project-planner-popout-modal.service';
import { ProjectPlannerService } from './services/project/project-planner/project-planner.service';
import { ProjectReportsService } from './services/project/project-reports/project-reports.service';
import { ProjectScheduleService } from './services/project/project-schedule/project-schedule.service';
import { ProjectSprintBoardService } from './services/project/project-sprint-board/project-sprint-board.service';
import { ProjectSprintService } from './services/project/project-sprint/project-sprint.service';
import { ProjectStepService } from './services/project/project-step/project-step.service';
import { ProjectSubContractorService } from './services/project/project-subcontractor/project-subcontractor.service';
import { ProjectUserService } from './services/project/project-user/project-user.service';
import { ProjectWorkPackagesService } from './services/project/project-work-packages/project-work-packages.service';
import { ProjectWorkDaysService } from './services/project/project-workdays/project-workdays.service';
import { ProjectService } from './services/project/project.service';
import { SegmentService } from './services/segment/segment.service';
import { SnapshotsService } from './services/project/snapshots/snapshots.service';
import { SprintSettingsService } from './services/project/project-settings/sprint-settings.service';
import { SubContractorService } from './services/sub-contractor/sub-contractor.service';
import { TranslationService } from './services/translation/translation.service';
import { UserService } from './services/user/user.service';
import { UtilsService } from './services/utils/utils.service';
import { WeatherService } from './services/weather/weather.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProjectFlowlineChartComponent } from './project/project-flowline-chart/project-flowline-chart.component';
import { AssignActivityModalComponent } from './project/project-master-schedule/assign-activity-modal/assign-activity-modal.component';
import { ProjectFlowlineService } from './services/project/project-flowline-chart/project-flowline.service';
import { ExportModalComponent } from './project/project-master-schedule/export-modal/export-modal.component';

declare var require: any;

const socketIOConfig: SocketIoConfig = {
  url: environment.pubSubServer, options: {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999
  }
};

@NgModule({
  declarations: [
    NumberToTextPipe,
    HoursToDays,
    OrderByPipe,
    GritDatePipe,
    ObjectKeysPipe,
    SafeUrl,
    SafeHtml,
    TranslationPipe,
    BaseTemplateComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    ProjectComponent,
    ProjectNetworkMapComponent,
    ProjectStepInfoComponent,
    UsersVerifyComponent,
    UsersRejectComponent,
    ProjectUserComponent,
    NotificationComponent,
    ProjectSubContractorComponent,
    ProjectSubcontractorFormComponent,
    ProjectModelConflictsComponent,
    AccountsComponent,
    AccountsUserComponent,
    AccountsTokenComponent,
    AccountsSubcontractorComponent,
    BrowserComponent,
    ProjectSettingsComponent,
    AccountsCreateComponent,
    ProjectModelsComponent,
    ProjectSidebarComponent,
    LoadingComponentComponent,
    CustomTableComponent,
    ImageModalComponent,
    DynamicDropdownComponent,
    ProjectForgeViewerComponent,
    ProjectPDFViewerComponent,
    ProjectPDFControlsComponent,
    ProjectPDFPopoutComponent,
    ProjectFilesComponent,
    PageHeaderComponent,
    ProjectFloorPlanComponent,
    UsersForgotPasswordComponent,
    UserSettingsComponent,
    ProjectPopOutViewerComponent,
    ProjectSprintsComponent,
    ProjectSprintBoardComponent,
    ProjectActivityLaborFormComponent,
    SprintResultLaborComponent,

    StaticDropdownComponent,
    ProjectScheduleComponent,
    ProjectScheduleScenariosComponent,
    ProjectScheduleChartComponent,
    ProjectPlannerComponent,
    ProjectPlannerFormComponent,
    ConfirmationModalComponent,
    ProjectNetworkMapViewerComponent,
    ProjectModelFilterComponent,
    SelectionModalComponent,
    GritrisComponent,
    ProjectMessagesComponent,
    ProjectObjectPropertiesComponent,
    ProjectCreateComponent,
    ProjectMilestoneSettingsComponent,
    ProjectWorkDaysComponent,
    ScheduleModalComponent,
    ProjectMessageSidebarComponent,
    PageSecondaryHeaderComponent,
    ProjectScheduleCalendarComponent,
    PopupModalComponent,
    ProfileAvatarComponent,
    ProjectObjectAddUnmodeledComponent,
    ProjectSubcontractorStepsSidebarComponent,
    ProjectScheduleChartLegendComponent,
    AccountsIntegrationsComponent,
    ProjectDashboardComponent,
    ProjectSidebarFilterComponent,
    ProjectDashboardProjectCompleteComponent,
    ProjectScheduleCriteriaCheckComponent,
    ProgressBarComponent,
    ProjectDashboardSprintCompleteComponent,
    ProjectDashboardGridComponent,
    ProjectDashboardSprintResourcesComponent,
    ProjectDashboardPlanCompleteComponent,
    ProjectDashboardSprintProductivityComponent,
    ProjectDashboardPlanNotCompleteComponent,
    ProjectSprintFormComponent,
    ProjectScheduleChartControlsComponent,
    ProjectSprintChartComponent,
    ProjectSprintCommitmentComponent,
    ProjectMasterScheduleComponent,
    ProjectGanttChartComponent,
    ProjectDashboardLivingScheduleComponent,
    ProjectEquipmentFormComponent,
    ProjectActivityTableComponent,
    ProjectActivityRfiFormComponent,
    ProjectProcoreSettingsComponent,
    LowerConfirmationComponent,
    ProjectActivityFormComponent,
    ProjectActivitiesFormComponent,
    AccountsSettingsComponent,
    ProjectMilestoneFormComponent,
    ProjectDashboardFeedComponent,
    ProjectTaskListComponent,
    ProjectStepFormComponent,
    ImportGanttModalComponent,
    ProjectHomeworkFeedComponent,
    NotificationsComponent,
    ProjectSprintResultFormComponent,
    ProjectReasonsTableComponent,
    ProjectUserFormComponent,
    EditActivityModalComponent,
    BulkEditActivityModalComponent,
    RowEditActivitiesModalComponent,
    PrerequisitesTableComponent,
    ProjectScenarioFormComponent,
    ProjectReportComponent,
    PageSubmenuComponent,
    ProjecBudgetReportComponent,
    ProjectEquipmentSettingsComponent,
    EditStepModalComponent,
    StepEquipmentAndMaterialsComponent,
    ProjectMaterialSettingsComponent,
    ProjectPDFViewerComponent,
    ProjectLaborSettingsComponent,
    ProjectMaterialFormComponent,
    ProjectRfiSettingsComponent,
    ProjectLaborFormComponent,
    ProjectRfiFormComponent,
    ProjectRollingBudgetReportComponent,
    CostFormComponent,
    ProjectManpowerReportComponent,
    ProjectSidebarProcurementComponent,
    ExceptionsTableComponent,
    SearchInputComponent,
    ProjectWorkPackagesComponent,
    AutofocusDirective,
    SnapshotsSidebarComponent,
    ProjectMakeReadyReportComponent,
    ProjectScheduleChangeReportComponent,
    SearchInputComponent,
    ExceptionsTableComponent,
    GritTableComponent,
    ActivityEquipmentAndMaterialsComponent,
    ActivityMaterialsComponent,
    ProjectMakeReadyComponent,
    SprintResultEquipmentComponent,
    SprintResultModalComponent,
    SprintResultMaterialsComponent,
    WorkPackagePrereqFormComponent,
    AccountsTrialComponent,
    GenerateScheduleModalComponent,
    ProjectSprintInprogressResultFormComponent,
    AssignActivityModalComponent,
    ProjectFlowlineChartComponent,
    ExportModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMenuModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatIconModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    HttpClientModule,
    PdfViewerModule,
    FormsModule,
    TreeModule,
    NgbModule.forRoot(),
    DragulaModule,
    NgCircleProgressModule.forRoot({
      radius: 30,
      outerStrokeWidth: 8,
      space: -8,
      innerStrokeWidth: 8,
      showInnerStroke: true,
      maxPercent: 100,
      outerStrokeColor: '#e12727',
      innerStrokeColor: '#dcdfe2',
      animationDuration: 300,
      responsive: true,
      showTitle: false,
      titleFontSize: '20px',
      unitsFontSize: '20px',
      showUnits: false,
      showSubtitle: false,
      showBackground: false,
      clockwise: true,
      startFromZero: true
    }),
    ReactiveFormsModule,
    SplitPaneModule,
    SocketIoModule.forRoot(socketIOConfig),
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'gritris',
        component: GritrisComponent
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trials',
        component: AccountsTrialComponent
      },
      {
        path: 'accounts/:accountId',
        component: AccountsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'settings',
            component: AccountsSettingsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'users',
            component: AccountsUserComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'subcontractors',
            component: AccountsSubcontractorComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'integrations',
            component: AccountsIntegrationsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: '',
            redirectTo: 'subcontractors',
            pathMatch: 'full'
          },
          {
            path: 'tokens',
            component: AccountsTokenComponent
          }
        ]
      },
      {
        path: 'project/create',
        canActivate: [AuthGuard],
        component: ProjectCreateComponent
      },
      {
        path: 'project/popout/:projectId',
        component: ProjectPopOutViewerComponent
      },
      {
        path: 'project/pdfpopup/:projectId',
        component: ProjectPDFPopoutComponent
      },
      {
        path: 'project/:projectId',
        component: ProjectComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'network-map',
            component: ProjectNetworkMapComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          },
          {
            path: 'model-conflicts',
            component: ProjectModelConflictsComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels]
            }
          },
          {
            path: 'models',
            component: ProjectModelsComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels]
            }
          },
          {
            path: 'master-schedule',
            component: ProjectMasterScheduleComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: []
            }
          },
          {
            path: 'sprints',
            component: ProjectSprintsComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          },
          {
            path: 'planner/filter/:activityId',
            component: ProjectPlannerComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes]
            }
          },
          {
            path: 'planner/:stepId',
            component: ProjectPlannerComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes]
            }
          },
          {
            path: 'planner',
            component: ProjectPlannerComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes]
            }
          },
          {
            path: 'settings',
            component: ProjectSettingsComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: []
            }
          },
          {
            path: 'schedule',
            component: ProjectScheduleComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          },
          {
            path: 'scenarios',
            component: ProjectScheduleScenariosComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadActivityTypes]
            }
          },
          {
            path: 'dashboard',
            component: ProjectDashboardComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          },
          {
            path: 'flowline-schedule',
            component: ProjectFlowlineChartComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: []
            }
          },
          {
            path: 'make-ready',
            component: ProjectMakeReadyComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          },
          {
            path: 'criteria',
            component: ProjectScheduleCriteriaCheckComponent,
            data: {
              criteria: null
            }
          },
          {
            path: 'reports',
            component: ProjectReportComponent,
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          },
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
            canActivate: [CriteriaGuard],
            data: {
              criteria: [CriteriaKeyEnum.UploadModels, CriteriaKeyEnum.UploadActivityTypes, CriteriaKeyEnum.ActivateSchedule]
            }
          }
        ]
      },
      {
        path: 'accounts/accounts-create',
        component: AccountsCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user/settings',
        component: UserSettingsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'users/:projectId',
        component: ProjectUserComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'plan/:projectId',
        component: ProjectNetworkMapComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'subContractor/:projectId',
        component: ProjectSubContractorComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'verify/:userVerificationId',
        component: UsersVerifyComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'forgot',
        component: UsersForgotPasswordComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'reject/:userId/:userVerificationId',
        component: UsersRejectComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: '**',
        redirectTo: 'home'
      }
    ])
  ],
  providers: [
    {
      provide: 'Window',
      useValue: window
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    AuthenticationService,
    HttpBackendService,
    CacheService,
    DatePipe,
    ProjectStepService,
    UserService,
    ProjectUserService,
    NotificationService,
    ProjectService,
    AccountService,
    MenuService,
    AuthGuard,
    LoggedInGuard,
    CanDeactivateGuard,
    CriteriaGuard,
    ProjectMilestoneService,
    ProjectModelService,
    SubContractorService,
    ProjectNetworkMapService,
    ProjectPlannerService,
    ProjectPlannerPopoutModalService,
    ActivityService,
    ProjectDashboardService,
    ProjectSubContractorService,
    ProjectObjectService,
    ForgeService,
    PDFViewerService,
    ProjectReportsService,
    ProjectFlowlineService,
    ForgeViewerService,
    ProjectSprintBoardService,
    ForgeViewerService,
    ProjectScheduleService,
    ProjectWorkDaysService,
    ProcoreService,
    D3Service,
    SegmentService,
    ChartService,
    ProjectSprintService,
    WeatherService,
    UtilsService,
    ProjectFilterSearchService,
    ProjectActivityTypesService,
    ColorPaletteService,
    ProjectHeaderService,
    ProjectGanttChartService,
    ProjectMasterScheduleService,
    ProjectMessageService,
    SprintSettingsService,
    ProjectPageCriteriaService,
    ProjectEquipmentService,
    ProjectMaterialService,
    ProjectLaborService,
    ProjectRfiService,
    TranslationService,
    SnapshotsService,
    ProjectWorkPackagesService,
    ProjectLaborService
  ],
  bootstrap: [BaseTemplateComponent],
  entryComponents: [],
})
export class BaseTemplateModule { }
