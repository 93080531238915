import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { ProjectService } from '../../../services/project/project.service';
import { SegmentService } from '../../../services/segment/segment.service';
import { SnapshotsService } from '../../../services/project/snapshots/snapshots.service';

import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { ISnapshot } from '../../../models/forge/forge.interface';

import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-snapshots-sidebar',
  templateUrl: './snapshots-sidebar.component.html',
  styleUrls: ['./snapshots-sidebar.component.scss']
})
export class SnapshotsSidebarComponent implements OnInit {

  @Output() expandOutput: EventEmitter<boolean> = new EventEmitter();

  loading: boolean = false;
  moreAvailable: boolean = false;
  expanded: boolean = true;
  snapshotName: string = '';
  curSnapEditName: string = '';
  addingSnapshot: boolean = false;
  showConfirmationModal: boolean = false;
  confirmationModalInput: IConfirmationModalInput;
  snapshots: ISnapshot[] = [];

  private pageSize: number = 50;
  private allSnapshots: ISnapshot[] = [];
  private deletingSnap: boolean = false;
  private curSnapEdit: ISnapshot;

  constructor(
    private snapshotsService: SnapshotsService,
    private projectService: ProjectService,
    private forgeViewerService: ForgeViewerService
  ) {/*EMPTY*/}

  async ngOnInit() {
    if (localStorage.getItem('snapShotExpanded')) this.expanded = JSON.parse(localStorage.getItem('snapShotExpanded'));
    setTimeout(() => this.expandOutput.emit(this.expanded), 1);
    localStorage.setItem('snapShotExpanded', this.expanded.toString());
    await this.setSnapshots();
    this.setMoreAvailable();
    this.loading = false;
  }

  async itemSelected(item) {
    SegmentService.track('Model Filter: Selected Snapshot', {});
    if (item.selected) {
      item.selected = false;
      this.forgeViewerService.clearCutPlanes();
    } else {
      this.snapshots.forEach(s => s.selected = false);
      const selectedSnap: ISnapshot = this.allSnapshots.find(snap => snap.id === item.id);
      selectedSnap.selected = true;
      this.forgeViewerService.restoreView(selectedSnap.forgeView);
    }
  }

  clearSnapshot() {
    this.snapshots.forEach(s => { s.editting = false; s.selected = false; });
    this.forgeViewerService.clearCutPlanes();
  }

  expand() {
    this.expanded = !this.expanded;
    localStorage.setItem('snapShotExpanded', this.expanded.toString());
    this.expandOutput.emit(this.expanded);
    SegmentService.track('Model Filter: Toggled Snapshot Panel', {expanded: this.expanded});
  }

  async setSnapshots() {
    await this.snapshotsService.setLocalSnapshots(this.projectService.currentProject.id);
    this.allSnapshots = this.snapshotsService.getLocalSnapshots();
    this.allSnapshots = Utils.sortByString(this.allSnapshots, 'name');
    const limit = Math.min(this.pageSize, this.allSnapshots.length);
    this.snapshots = this.allSnapshots.slice(0, limit);
  }

  async saveSnapshotClick(snap?: ISnapshot) {
    if (snap) {
      snap.editting = false;
      snap.name = this.curSnapEditName;
      await this.snapshotsService.editSnapshot(snap).catch(async () => {
        this.loading = true;
        await this.setSnapshots();
        this.loading = false;
      });
    } else {
      const newSnapshot: ISnapshot = {
        name: this.snapshotName,
        forgeView: this.forgeViewerService.getForgeView(),
        myViewerState: '{}',
        isHomeView: false,
        projectId: this.projectService.currentProject.id
      };
      this.snapshotName = '';
      await this.snapshotsService.addSnapshot(newSnapshot).then(
        res => {
          this.allSnapshots.push(res);
          this.allSnapshots = Utils.sortByString(this.allSnapshots, 'name');
        }
      ).catch(() => {/*EMPTY*/});

      const limit = Math.min(this.pageSize, this.allSnapshots.length);
      this.snapshots = this.allSnapshots.slice(0, limit);
    }
    this.addingSnapshot = false;
    this.setMoreAvailable();
  }

  addSnapshotClick() {
    this.addingSnapshot = true;
    this.snapshots.forEach(s => s.editting = false);
  }

  overwrite(snap: ISnapshot) {
    this.curSnapEdit = snap;
    this.deletingSnap = false;
    this.showConfirmationModal = true;
    this.confirmationModalInput = {
      displayMessage: 'are_you_sure',
      hasCancelAction: true,
      hasConfirmationAction: true
    };
  }

  editSnapName(snap: ISnapshot) {
    this.addingSnapshot = false;
    this.snapshots.forEach(s => s.editting = false);
    this.curSnapEdit = snap;
    this.curSnapEdit.editting = true;
    this.curSnapEditName = this.curSnapEdit.name;
  }

  deleteSnap(snap: ISnapshot) {
    this.curSnapEdit = snap;
    this.deletingSnap = true;
    this.showConfirmationModal = true;
    this.confirmationModalInput = {
      displayMessage: 'are_you_sure',
      hasCancelAction: true,
      hasConfirmationAction: true
    };
  }

  handleCancelCloseOutput() {
    this.showConfirmationModal = false;
  }

  async handleConfirmationConfirmOutput() {
    this.showConfirmationModal = false;
    if (this.deletingSnap) {
      await this.snapshotsService.deleteSnapshot(this.curSnapEdit.id).then(() => {
        const index = this.allSnapshots.findIndex(s => s.id === this.curSnapEdit.id);
        if (index > -1) {
          this.allSnapshots.splice(index, 1);
          const limit = Math.min(this.pageSize, this.allSnapshots.length);
          this.snapshots = this.allSnapshots.slice(0, limit);
        }
      });
    } else {
      this.curSnapEdit.forgeView = this.forgeViewerService.getForgeView();
      this.curSnapEdit.myViewerState = '{}';
      await this.snapshotsService.editSnapshot(this.curSnapEdit).catch(async () => {
        this.loading = true;
        await this.setSnapshots();
        this.loading = false;
      });
    }
  }

  loadMore() {
    const limit = Math.min(this.snapshots.length + this.pageSize, this.allSnapshots.length);
    this.snapshots = this.allSnapshots.slice(0, limit);
    this.setMoreAvailable();
  }

  setMoreAvailable() {
    this.moreAvailable = this.snapshots.length < this.allSnapshots.length;
  }

}
