import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TREE_ACTIONS } from 'angular-tree-component';
import { ProjectService } from '../../services/project/project.service';
import { ProcoreService } from '../../services/procore/procore.service';
import { ForgeService } from '../../services/forge/forge.service';
import { IUserPermission } from '../../models/user/user.interface';
import { NotificationService } from '../../services/notification/notification.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-project-floor-plan',
  templateUrl: './project-floor-plan.component.html',
  styleUrls: ['./project-floor-plan.component.scss']
})
export class ProjectFloorPlanComponent implements OnInit, OnDestroy {
  @Input() projectPermissionInput: IUserPermission;
  @Output() updateMenu: EventEmitter<any> = new EventEmitter();
  updating: boolean = false;
  options = {
    getChildren: (node) => {
      if (!this.isProcoreActive)
        return node.data.children;
      else
        this.procoreService.getTreeDrawingsByDiscipline(this.projectService.currentProjectAccount.id, this.projectService.currentProject.id, { 'fileFolderId': node.data.id, 'drawingAreaId': this.drawingAreaId, 'disciplineName': node.data.name }).subscribe(
          tree => {
            node.data.children = tree;
            node.treeModel.update();
            return node.data.children;
          },
          err => {
            this.notificationService.error(err, {});
            return [];
          }
        );
    },
    actionMapping: {
      mouse: {
        click: TREE_ACTIONS.TOGGLE_EXPANDED
      }
    }
  };
  drawingAreaId: number;
  downloading: boolean = false;
  nodes;
  isProcoreActive = false;
  selectedNodes = [];
  selectedElement: any;
  floorPlans: any = [];
  selectNodesTree: any = [];
  disableUpArrow: any = false;
  disableDownArrow: any = true;

  constructor(
    public projectService: ProjectService,
    private procoreService: ProcoreService,
    private notificationService: NotificationService,
    private forgeService: ForgeService
  ) { }

  ngOnInit() {
    this.projectService.getLocalFloorPlans().forEach(element => {
      this.selectedNodes.push(element);
    });
    this.selectedNodes.sort(function (a, b) { return a.orderNum - b.orderNum });
    if (this.selectedNodes.length > 0)
      this.floorPlans = [this.selectedNodes[this.selectedNodes.length - 1].id];
    if (this.floorPlans.length > 0)
      this.updateMenu.next();
  }

  ngOnDestroy() {
  }

  getHighestIndex() {
    let orderNum = 0;
    if (this.selectedNodes.length == 0)
      return 1;
    else
      this.selectedNodes.forEach(element => {
        if (element.orderNum > orderNum)
          orderNum = element.orderNum;
      });
    return orderNum + 1;
  }

  addFiles() {
    if (this.selectedElement) {
      this.setRemoveSelection(document.getElementById(this.selectedElement.id), false);
      if (this.setSelectedNodes(this.selectedElement)) {
        this.updating = true;
        if (this.isProcoreActive) {
          this.procoreService.linkFloorPlanPDF(this.projectService.currentProject.id, {
            'fileId': this.selectedElement.id.toString(),
            'url': this.selectedElement.url,
            'name': this.selectedElement.name,
            'orderNum': this.getHighestIndex()
          }).subscribe(
            () => {
              this.updating = false;
              this.selectedElement['orderNum'] = this.getHighestIndex();
              this.setSelectedNodes(this.selectedElement, true);
              if (this.selectedNodes.length == 0)
                this.floorPlans = [];
              else {
                this.floorPlans = [this.selectedNodes[this.selectedNodes.length - 1].id];
                this.setIndex();
              }
              this.selectedElement = null;
              this.notificationService.success('SUCCESSFUL_SAVE', null);
            },
            err => {
              this.selectedElement = null;
              this.updating = false;
              this.notificationService.error(err, {});
            }
          );
        }
        else {
          this.forgeService.linkFloorPlanPDF(this.projectService.currentProject.id, {
            'fileId': this.selectedElement.id.toString(),
            'url': this.selectedElement.url,
            'name': this.selectedElement.name,
            'orderNum': this.getHighestIndex()
          }).subscribe(
            () => {
              this.updating = false;
              this.selectedElement['orderNum'] = this.getHighestIndex();
              this.setSelectedNodes(this.selectedElement, true);
              if (this.selectedNodes.length == 0)
                this.floorPlans = [];
              else {
                this.floorPlans = [this.selectedNodes[this.selectedNodes.length - 1].id];
                this.setIndex();
              }
              this.selectedElement = null;
              this.notificationService.success('SUCCESSFUL_SAVE', null);
            },
            err => {
              this.selectedElement = null;
              this.updating = false;
              this.notificationService.error(err, {});
            }
          );
        }
      }
      else
        this.selectedElement = null;
    }
  }

  removeFiles() {
    let selectToRemove:any[] = this.floorPlans;
    this.procoreService.removeFloorPlanPDF(this.projectService.currentProject.id, { 'fileId': selectToRemove }).subscribe(
      () => {
        this.updating = false;
        this.selectedNodes = this.selectedNodes.filter(obj => !selectToRemove.includes(obj.id));
        this.notificationService.success('SUCCESSFUL_DELETE', null);
        if (this.selectedNodes.length == 0) {
          this.floorPlans = [];
          this.projectService.hasPDFs = false;
        }
        else {
          this.floorPlans = [this.selectedNodes[this.selectedNodes.length - 1].id];
          this.setIndex();
        }
      },
      err => {
        this.updating = false;
        this.notificationService.error(err, {});
      }
    );
  }

  procoreClicked() {
    this.downloading = true;
    this.isProcoreActive = true;
    this.procoreService.getTreeDrawings(this.projectService.currentProjectAccount.id, this.projectService.currentProject.id, {}).subscribe(
      tree => {
        this.nodes = tree;
        this.drawingAreaId = tree[0].id;
        this.downloading = false;
      },
      err => {
        this.notificationService.error(err, {});
        this.downloading = false;
      }
    );
  }

  bim360Clicked() {
    this.isProcoreActive = false;
    this.downloading = true;
    this.forgeService.getPDFTree(this.projectService.currentProject.id).subscribe(
      tree => {
        this.nodes = tree;
        this.downloading = false;
      },
      () => {
        this.downloading = false;
      });
  }

  setSelectedNodes(node: any, insert: boolean = false) {
    let alreadyPresent = false;
    this.selectedNodes.forEach(element => {
      if (element.name == node.name && element.id == node.id)
        alreadyPresent = true;
    });
    if (!alreadyPresent && insert) {
      this.selectedNodes.push(node);
      this.setIndex();
    }
    return !alreadyPresent;
  }

  getIcon(type: string) {
    if (type === 'items') return 'far fa-file';
    if (type === 'folders') return 'fa fa-folder';
    if (type === 'versions') return 'fa fa-file';
  }

  async nodeClicked(node, elem) {
    Array.from(document.querySelectorAll("[treeSelect]")).forEach(element => {
      let ele = element as HTMLElement;
      ele.style.removeProperty("background-color");
      ele.style.removeProperty("color");
      ele.removeAttribute('treeSelect');
    });
    if (node.data.type == 'items') {
      this.setRemoveSelection(elem, true);
      this.selectedElement = node.data;
      this.selectedElement.id = node.data.id.toString();
    }
  }

  setRemoveSelection(element, set) {
    if (set) {
      element.currentTarget.parentElement.parentElement.style.setProperty("background-color", "#1F90FF");
      element.currentTarget.parentElement.parentElement.style.setProperty("color", "white");
      element.currentTarget.parentElement.parentElement.setAttribute('treeSelect', '');
    }
    else {
      element.parentElement.parentElement.style.removeProperty("background-color");
      element.parentElement.parentElement.style.removeProperty("color");
      element.parentElement.parentElement.removeAttribute('treeSelect');
    }
  }

  upArrow() {
    let index = 0;
    for (let i = 0; i < this.selectedNodes.length; i++) {
      if (this.floorPlans[0] == this.selectedNodes[i].id) {
        index = i;
      }
    }
    let swap = {};
    swap[0] = this.selectedNodes[index].orderNum;
    swap[1] = this.selectedNodes[index - 1].orderNum;
    this.updating = true;
    this.procoreService.changeOrder(this.projectService.currentProject.id, swap).subscribe(
      data => {
        this.updating = false;
        let order = this.selectedNodes[index].orderNum;
        this.selectedNodes[index].orderNum = this.selectedNodes[index - 1].orderNum;
        this.selectedNodes[index - 1].orderNum = order;
        this.selectedNodes.sort(function (a, b) { return a.orderNum - b.orderNum });
        var x = <HTMLSelectElement>document.getElementsByClassName('rightSelectBox')[0];
        x.scrollTop -= 32;
        this.afterArrow();
        this.notificationService.success('SUCCESSFUL_SAVE', null);
      },
      err => {
        this.updating = false;
        this.notificationService.error(err, {});
      }
    );
  }

  downArrow() {
    let index = this.selectedNodes.length;
    for (let i = 0; i < this.selectedNodes.length; i++) {
      if (this.floorPlans[0] == this.selectedNodes[i].id) {
        index = i;
      }
    }
    let swap = {};
    swap[0] = this.selectedNodes[index].orderNum;
    swap[1] = this.selectedNodes[index + 1].orderNum;
    this.updating = true;
    this.procoreService.changeOrder(this.projectService.currentProject.id, swap).subscribe(
      data => {
        this.updating = false;
        let order = this.selectedNodes[index].orderNum;
        this.selectedNodes[index].orderNum = this.selectedNodes[index + 1].orderNum;
        this.selectedNodes[index + 1].orderNum = order;
        this.selectedNodes.sort(function (a, b) { return a.orderNum - b.orderNum });
        var x = <HTMLSelectElement>document.getElementsByClassName('rightSelectBox')[0];
        x.scrollTop += 32;
        this.afterArrow();
        this.notificationService.success('SUCCESSFUL_SAVE', null);
      },
      err => {
        this.updating = false;
        this.notificationService.error(err, {});
      }
    );
  }

  setIndex() {
    setTimeout(function () {
      var x = document.getElementsByClassName('rightSelectBox')[0];
      x.scrollTop = x.scrollHeight;
    }, 100);
    this.onChangeSelCount();
  }

  onChangeSelCount() {
    if (this.floorPlans.length > 1) {
      this.disableUpArrow = true;
      this.disableDownArrow = true;
    }
    else {
      this.disableUpArrow = false;
      this.disableDownArrow = false;
    }
    let selIndex = 0;
    for (let i = 0; i < this.selectedNodes.length; i++) {
      if (this.floorPlans[0] == this.selectedNodes[i].id) {
        selIndex = i;
      }
    }
    if (selIndex < 1)
      this.disableUpArrow = true;
    if (selIndex > this.selectedNodes.length - 2)
      this.disableDownArrow = true;
  }

  afterArrow() {
    let selIndex = 0;
    for (let i = 0; i < this.selectedNodes.length; i++) {
      if (this.floorPlans[0] == this.selectedNodes[i].id) {
        selIndex = i;
      }
    }
    if (selIndex < 1)
      this.disableUpArrow = true;
    else
      this.disableUpArrow = false;
    if (selIndex > this.selectedNodes.length - 2)
      this.disableDownArrow = true;
    else
      this.disableDownArrow = false;
  }
}
