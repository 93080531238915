import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { ProjectSubContractorService } from '../project-subcontractor/project-subcontractor.service';
import { Utils } from '../../../utils/utils';

import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { IMatAutoComplete } from '../../../models/material/material-components.interface';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { IProjectUser } from '../../../models/project/project-user/project-user.interface';
import { ISubContractor } from '../../../models/subcontractor/subcontractor.interface';
import { IUser, IUserPermission } from '../../../models/user/user.interface';

@Injectable()
export class ProjectUserService {

  private previousUsers: IUser[] = [];

  constructor(
    private http: HttpBackendService,
    private formBuilderService: FormBuilder,
    private projectSubContractorService: ProjectSubContractorService,
    private notificationService: NotificationService) { }

  public getAuthenticatedActivityList(projectId: string, userId: string): Observable<any[]> {
    return this.http.get('/project/' + projectId + '/user/' + userId);
  }

  public addProjectUser(json: any): Observable<any> {
    return this.http.post('/project/user', json);
  }

  public getProjectUserList(projectId: string): Observable<IProjectUser[]> {
    return this.http.get('/project/' + projectId + '/users');
  }

  public getDomainUserList(projectId: string, subContractorId: string): Observable<IProjectUser[]> {
    return this.http.get('/project/' + projectId + '/domainUsers/' + subContractorId);
  }

  public getPreviousUserList(projectId: string): Observable<IUser[]> {
    return this.http.get('/project/' + projectId + '/previousUsers');
  }

  public resendInviteProjectUsers(json: any): Observable<any> {
    return this.http.post('/project/user/resendVerification', json);
  }

  public updateProjectUser(userId: string, json: any): Observable<IProjectUser[]> {
    return this.http.put('/project/user/' + userId, json);
  }

  public deleteProjectUsers(userIds: string[], projectId: string): Observable<IProjectUser[]> {
    return this.http.post('/project/' + projectId + '/user/delete', {userIds: userIds});
  }

  public setLocalPreviousUsers(projectId: string, userPermission: IUserPermission) {
    this.previousUsers = [];
    if (userPermission.gc) {
      return new Promise((resolve) => {
        this.getPreviousUserList(projectId).subscribe(
          users => {
            const pastUsers = [];
            for (const u of users) {
              pastUsers.push({ name: u.firstName ? u.firstName + ' ' + u.lastName + ' <' + u.email + '>' : u.email, email: u.email });
            }
            this.previousUsers = Utils.sortByString(pastUsers, 'firstName');
            return resolve(true);
          },
          err => {
            this.notificationService.error(err, { type: 'previous users', action: 'get' });
            return resolve(false);
          }
        );
      });
    } else {
      return new Promise((resolve) => {
        this.getDomainUserList(projectId, userPermission.subContractorId).subscribe(
          users => {
            const pastUsers = [];
            for (const u of users) {
              pastUsers.push({ name: u.firstName ? u.firstName + ' ' + u.lastName + ' <' + u.email + '>' : u.email, email: u.email });
            }
            this.previousUsers = Utils.sortByString(pastUsers, 'firstName');
            return resolve(true);
          },
          err => {
            this.notificationService.error(err, { type: 'previous users', action: 'get' });
            return resolve(false);
          }
        );
      });
    }
  }

  transformToTableData(dataInput: any, permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'user',
        colKey: 'email',
        type: RowItemType.Text,
        width: '35%'
      },
      {
        displayName: 'permission',
        colKey: 'permission',
        type: RowItemType.MatSelect
      },
      {
        displayName: 'company',
        colKey: 'subContractor',
        type: RowItemType.Text
      },
      {
        displayName: 'has_mfa',
        colKey: 'hasMfa',
        type: RowItemType.Icon,
        width: '15%'
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(user => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'email',
          value: user.firstName ? `${user.firstName} ${user.lastName} <${user.email}>` : user.email
        },
        {
          colKey: 'permission',
          value: user.permission,
          editable: permission.gc || permission.subContractorId === user.subContractorId,
          matSelectOptions: [
            { display: 'Admin', value: 1 },
            { display: 'Edit', value: 2 },
            { display: 'Read Only', value: 3 }
          ]
        },
        {
          colKey: 'subContractor',
          value: user.subContractor
        },
        {
          colKey: 'hasMfa',
          value: this.getMFAIcon(user.hasMfa)
        }
      ];
      rows.push(
        {
          key: user.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.gc || permission.subContractorId === user.subContractorId,
            rowEdits: permission.gc || permission.subContractorId === user.subContractorId ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : [],
            editRowValidationFn: this.validationFn
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        inline: false
      }
    };

    return retTable;
  }

  validationFn(rowItems: IGRowItem[]): boolean {
    const permission = rowItems.find(ri => ri.colKey === 'permission');
    if (!permission) return false;
    if (!permission.value) return false;
    if (permission.value < 1 || permission.value > 3) return false;
    return true;
  }

  getIcon(enabled: boolean): string {
    if (enabled) return 'fa fa-check green';
    return 'fa fa-clock blue';
  }

  getMFAIcon(hasMfa: boolean): string {
    if (hasMfa) return 'fa fa-check green';
    return 'fas fa-times-circle red';
  }

  async buildFormInput(projectId: string, userPermission: IUserPermission): Promise<FormGroup> {

    await this.projectSubContractorService.setLocalAccountSubcontractors();
    await this.setLocalPreviousUsers(projectId, userPermission);

    const userFormInput = this.formBuilderService.group({
      permission: [null, Validators.required],
    });

    return userFormInput;
  }

  buildSubAutoInput(accountName: string) {
    const accountSubcontractors = Utils.sortByString(this.projectSubContractorService.getLocalAccountSubcontractors(), 'name');
    accountSubcontractors.unshift({name: accountName, id: '-1'});

    const subAutoInput: IMatAutoComplete = {
      formControl: new FormControl(),
      listOptions: null,
      displayFn: (item: any): string => {
        return item && item.name ? item.name : undefined;
      }
    };

    subAutoInput.formControl.setValidators([Validators.required, this.validateSub()]);

    subAutoInput.listOptions = subAutoInput.formControl.valueChanges.pipe(
      startWith<string | ISubContractor>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.subSearch(name) : accountSubcontractors.slice(0, Math.min(accountSubcontractors.length, 50)))
    );
    return subAutoInput;
  }

  subSearch(name: string): IProjectSubContractor[] {
    const accountSubcontractors = Utils.sortByString(this.projectSubContractorService.getLocalAccountSubcontractors(), 'name');
    const searchQuery = name.toString().toLowerCase();
    const searchRes = accountSubcontractors.filter(option => option.name.toString().toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  buildUserAutoInput() {
    const previousUsers = Utils.sortByString(this.previousUsers, 'name');

    const userAutoInput: IMatAutoComplete = {
      formControl: new FormControl(),
      listOptions: null,
      displayFn: (item: any): string => {
        return item && item.name ? item.name : undefined;
      }
    };

    userAutoInput.formControl.setValidators([Validators.required, this.validateEmail()]);

    userAutoInput.listOptions = userAutoInput.formControl.valueChanges.pipe(
      startWith<string | ISubContractor>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.userSearch(name) : previousUsers.slice(0, Math.min(previousUsers.length, 50)))
    );
    return userAutoInput;
  }

  userSearch(name: string): IProjectSubContractor[] {
    const previousUsers = Utils.sortByString(this.previousUsers, 'name');
    const searchQuery = name.toLowerCase();
    const searchRes = previousUsers.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  validateSub(): ValidatorFn {
    return (control: FormControl): {} => {
      const invalid = control.value && control.value.id ? false : true;
      if (invalid) return { invalidSub: true };
      return {};
    };
  }

  validateEmail(): ValidatorFn {
    return (control: FormControl): {} => {
      if (Utils.validateEmailAddress(control.value)) return {};
      else if (control.value && Utils.validateEmailAddress(control.value.email)) return {};
      return { email: true };
    };
  }
}
