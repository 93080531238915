import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { map ,  startWith } from 'rxjs/operators';

import { IActivity } from '../../../models/activity/activity.interface';
import { IMatAutoComplete } from '../../../models/material/material-components.interface';

import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISubContractor } from '../../../models/subcontractor/subcontractor.interface';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-project-subcontractor-form',
  templateUrl: './project-subcontractor-form.component.html',
  styleUrls: ['./project-subcontractor-form.component.scss']
})
export class ProjectSubcontractorFormComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() subAutoInput: IMatAutoComplete;
  @Input() allActivities: IActivity[];
  @Input() colorAutoInput: IMatAutoComplete;

  @Output() submitOutput: EventEmitter<IProjectSubContractor> = new EventEmitter();

  // Template Variables
  addingNew: boolean = false;
  // Activity chips and autofill
  // Keeping setup in here due to complexity with material chips and issues, will more later when time
  // Had to store differently due to material chips bug
  filteredActivities: Observable<IActivity[]>;
  activityOptions: IActivity[];
  separatorKeysCodes: number[] = [ENTER];
  activityCtrl = new FormControl();
  activityChips: IActivity[] = [];
  curActivityIds: string[] = [];

  accountSubcontractors: ISubContractor[] = [];

  // returns specific form control by label
  get name() { return this.formInput.get('name'); }
  get abbreviation() { return this.formInput.get('abbreviation'); }
  get subcontractorControl() { return this.subAutoInput.formControl; }
  get subcontractorOptions() { return this.subAutoInput.listOptions; }
  get subcontractorDisplayFn() { return this.subAutoInput.displayFn; }
  get colorControl() { return this.colorAutoInput.formControl; }
  get colorOptions() { return this.colorAutoInput.listOptions; }
  get colorDisplayFn() { return this.colorAutoInput.displayFn; }

  @ViewChild('activityInput') activityInput;

  constructor() {/*EMPTY*/}

  ngOnInit() {
    if (!this.name.value) this.addingNew = true;
    this.setActivities();
    this.setAutocomplete();
  }

  setActivities(): void {
    this.curActivityIds = this.formInput.get('activities').value;
    this.activityChips = this.allActivities.filter(item => this.curActivityIds.includes(item.id));
    const curId = this.formInput.get('id').value;
    if (curId) this.activityOptions = this.allActivities.filter(item => curId !== item.id);
    else this.activityOptions = this.allActivities;
  }

  onSubmit(form): void {
    const formValues = form.getRawValue();
    const subcontractor: IProjectSubContractor = {
      id: formValues.id ? formValues.id : null,
      subContractorId: this.subcontractorControl.value ? this.subcontractorControl.value.id : null,
      name: formValues.name,
      abbreviation: formValues.abbreviation,
      activities: this.formInput.get('activities').value,
      hexCode: this.colorControl.value ? this.colorControl.value.hex : null
    };
    this.submitOutput.emit(subcontractor);
  }

  remove(activity: IActivity) {
    this.curActivityIds = this.curActivityIds.filter(id => id !== activity.id);
    this.activityChips = this.activityChips.filter(item => item.id !== activity.id);
    this.clearInput();
  }

  selected(matActivity) {
    const mActivity: IActivity = matActivity.option.value;
    this.curActivityIds.push(mActivity.id);
    this.activityChips.push(this.activityOptions.find(item => item.id === mActivity.id));
    this.clearInput();
  }

  activitySearch(name: string) {
    const searchQuery = name.toLowerCase();
    let searchRes = this.activityOptions.filter(option => !this.curActivityIds.includes(option.id));
    searchRes = searchRes.filter(option => option.name.toLowerCase().includes(searchQuery));
    return searchRes.slice(0, Math.min(searchRes.length, 50));
  }

  getAllActivityOptions() {
    const options = Utils.sortByString(this.activityOptions.filter(option => !this.curActivityIds.includes(option.id)), 'name');
    return options.slice(0, Math.min(options.length, 50));
  }

  setAutocomplete(): void {
    this.filteredActivities = this.activityCtrl.valueChanges.pipe(
      startWith<string | IActivity >(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.activitySearch(name) : this.getAllActivityOptions()));
  }

  clearInput(): void {
    this.activityInput.nativeElement.value = '';
    this.activityCtrl.setValue('');
    this.activityCtrl.updateValueAndValidity();
    this.setAutocomplete();
  }
  displayFn(item: any): string { return item && item.activity && item.activity.name ? item.activity.name : undefined; }
}
