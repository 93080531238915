import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { IActivityFormOutput, ICosts, IActivity, IMasterScheduleActivity, IPrereqActivity, RowEdit } from '../../../models/activity/activity.interface';
import { element } from '@angular/core/src/render3/instructions';
import { IProjectSubContractor } from '../../../models/project/project-subcontractor/project-subcontractor.interface';
import { Utils } from '../../../utils/utils';
import { BulkEditSelection } from '../../../utils/enums/shared.enum';

@Component({
  selector: 'app-rowEdit-activities-modal',
  templateUrl: './rowEdit-activities-modal.component.html',
  styleUrls: ['./rowEdit-activities-modal.component.scss']
})

export class RowEditActivitiesModalComponent implements OnInit, OnDestroy {

  // Template variables
  isValid: boolean = true;
  prereqData: IPrereqActivity[] = [];
  tableData: RowEdit[] = [];
  deleteOutputIdList: string[] = [];
  activitiesParent = [];
  parentId = null;
  projectId = null;
  hasCommonParent = false;


  costs: ICosts;
  private formDataArray: IActivityFormOutput[] = [];

  @Input() formInput: FormGroup[];
  @Input() subcontractors: IProjectSubContractor[] = [];
  @Input() allActivities: IMasterScheduleActivity[];
  @Input() symbol: string;

  @Output() submitOutput: EventEmitter<IActivityFormOutput[]> = new EventEmitter();
  @Output() deleteOutput: EventEmitter<string[]> = new EventEmitter();
  @Output() closeModalOutput: EventEmitter<any> = new EventEmitter();

  constructor(
  ) {/*EMPTY*/ }

  ngOnInit() {

    window.addEventListener('keydown', this.keyPress);

    this.formInput.forEach(element => {
      let id = element.get('id').value as string;
      let externalActivityId = element.get('externalActivityId').value as string;
      let name = element.get('name').value as string;
      let duration = element.get('duration').value as number;
      let durationEnabled = element.get('duration').enabled;
      let subContractor = element.get('subContractor').value as string;
      let newActivity = false;
      let rowData = cloneDeep({ id, externalActivityId, name, duration, subContractor, durationEnabled, newActivity }) as RowEdit;
      this.tableData.push(rowData);

      this.activitiesParent.push(element.get('parentId').value);
    })

    this.projectId = this.formInput[0].get('projectId').value as string
    this.checkForActivitiesParent();
  }

  checkForActivitiesParent() {

    if (!this.hasCommonParent)
      if (Utils.arrayhasCommonElements(this.activitiesParent)) {
        this.parentId = Utils.getCommonElementFromArray(this.activitiesParent);
        this.hasCommonParent = true;
        this.addEmptyRow();
      }
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  }

  addEmptyRow() {

    let id = null;
    let externalActivityId = "";
    let name = "";
    let duration = 0;
    let durationEnabled = true;
    let subContractor = "";
    let newActivity = true;
    let rowData = cloneDeep({ id, externalActivityId, name, duration, subContractor, durationEnabled, newActivity }) as RowEdit;

    this.tableData.push(rowData);
  }

  submit() {

    this.formInput.forEach(element => {

      const formActivity: IActivity = {
        id: element.get('id').value,
        parentId: element.get('parentId').value,
        projectId: element.get('projectId').value,
        externalActivityId: element.get('externalActivityId').value,
        name: element.get('name').value,
        duration: element.get('duration').value,
        prerequisites: element.get('prerequisites').value,
        startDate: null,
        endDate: null,
        crewSize: element.get('crewSize').value,
        anchorDate: null
      };

      let subValue = null;
      let currentFormActivity = this.tableData.find((element => (element.id === formActivity.id)));
      formActivity.externalActivityId = currentFormActivity.externalActivityId;
      formActivity.name = currentFormActivity.name;
      formActivity.duration = currentFormActivity.duration;

      if (currentFormActivity.subContractor)
        subValue = currentFormActivity.subContractor.id;

      const formOutput: IActivityFormOutput = {
        activity: formActivity,
        subContractorId: subValue,
        milestoneId: element.controls['milestone'].value,
        bulkEditSelection: BulkEditSelection.unchecked,
      };
      this.formDataArray.push(formOutput);
    });


    this.tableData.forEach(element => {

      if (!element.id && !element.newActivity) {

        const formActivity: IActivity = {
          id: element.id,
          parentId: this.parentId,
          projectId: this.projectId,
          externalActivityId: element.externalActivityId,
          name: element.name,
          duration: element.duration,
          startDate: null,
          endDate: null,
          crewSize: null,
          anchorDate: null
        }

        const formOutput: IActivityFormOutput = {
          activity: formActivity,
          subContractorId: element.subContractor.id,
          milestoneId: null,
          bulkEditSelection: BulkEditSelection.unchecked,
        };

        this.formDataArray.push(formOutput);
      }
    });


    if (this.deleteOutputIdList && this.deleteOutputIdList.length > 0)
      this.deleteOutput.emit(this.deleteOutputIdList);

    if (this.formDataArray && this.formDataArray.length > 0)
      this.submitOutput.emit(this.formDataArray);
  }

  keyPress = (event) => {

    if (event.key === 'Enter') {
      if (this.isValid) {
        this.submit();
        return;
      }
    }

    const x = document.activeElement.id;
    let decimalValue = Number(x.substring(0, x.length - 1));
    let floorValue = Number(x[x.length - 1]);

    switch (event.key) {
      case 'ArrowRight':
        floorValue = floorValue + 1;
        break;
      case 'ArrowUp':
        event.preventDefault();
        decimalValue = decimalValue - 1;
        break;
      case 'ArrowDown':
        event.preventDefault();
        decimalValue = decimalValue + 1;
        break;
      case 'ArrowLeft':
        floorValue = floorValue - 1;
        break;

      default:
        return;
    }

    const idStr = String(decimalValue) + String(floorValue);
    let id = document.getElementById(idStr)

    if (id) {
      id.focus();
    }
  }

  chnageSubcontractor(subcontractor: IProjectSubContractor, element: RowEdit) {
    element.subContractor = subcontractor;
  }

  removeElement(index: number) {
    this.tableData.splice(index, 1);
    this.formInput.splice(index, 1);
    this.activitiesParent.splice(index, 1);
    this.checkForActivitiesParent();
  }
  // delete activity shedule 
  deleteElement(index: number) {

    if (this.tableData[index].id)
      this.deleteOutputIdList.push(this.tableData[index].id);

    this.tableData.splice(index, 1);
    this.formInput.splice(index, 1);
  }

  myInputselect(evt) {
    evt.srcElement.select();
  }

  onInputChnage(index: number) {

    let td = this.tableData[index];
    if (td.newActivity) {
      if (td.name.length > 0 && td.externalActivityId.length > 0) {
        td.newActivity = false;
        this.addEmptyRow();
      }
    }
  }

}
