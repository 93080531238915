import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IProjectMaterial, IUnitOfMeasureOption, UnitOfMeasure } from '../../../models/project/project-material/project-material.interface';
import { ProjectMaterialService } from '../../../services/project/project-material/project-material.service';

@Component({
  selector: 'app-project-material-form',
  templateUrl: './project-material-form.component.html',
  styleUrls: ['./project-material-form.component.scss']
})
export class ProjectMaterialFormComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() currency: string;
  @Input() plannedQuantity: string;
  @Output() submitOutput: EventEmitter<IProjectMaterial> = new EventEmitter();

  // Template Variables
  addingNew: boolean = false;
  // measureOptions: IUnitOfMeasureOption[] = [];
  currencySymbol: string;

  // returns specific form control by label
  get resourceId() { return this.formInput.get('resourceId'); }
  get name() { return this.formInput.get('name'); }
  get unitOfMeasure() { return this.formInput.get('unitOfMeasure'); }
  get cost() { return this.formInput.get('cost'); }

  get prepareLeadDays() { return this.formInput.get('prepareLeadDays'); }
  get fabricationLeadDays() { return this.formInput.get('fabricationLeadDays'); }
  get reviewLeadDays() { return this.formInput.get('reviewLeadDays'); }

  get quantityAssigned() { return this.formInput.get('quantityAssigned'); }
  get budgetQuantity() { return this.formInput.get('budgetQuantity'); }
  get budget() { return this.formInput.get('budget'); }

  constructor(
    private materialService: ProjectMaterialService
  ) {/*EMPTY*/}

  ngOnInit() {
    if (!this.name.value) this.addingNew = true;
    // Object.keys(UnitOfMeasure).forEach(key => this.measureOptions.push({value: UnitOfMeasure[key], display: this.materialService.getDisplayValue(UnitOfMeasure[key])}));
  }

  onSubmit(form): void {
    const formValues = form.getRawValue();
    const subcontractor: IProjectMaterial = {
      id: formValues.id ? formValues.id : null,
      resourceId: formValues.resourceId ? formValues.resourceId : null,
      name: formValues.name,
      unitOfMeasure: formValues.unitOfMeasure,
      cost: formValues.cost,
      prepareLeadDays: formValues.prepareLeadDays,
      fabricationLeadDays: formValues.fabricationLeadDays,
      reviewLeadDays: formValues.reviewLeadDays,
      quantityAssigned: formValues.quantityAssigned,
      budgetQuantity: formValues.budgetQuantity,
      budget: formValues.budget,
    };
    this.submitOutput.emit(subcontractor);
  }

  displayFn(item: any): string {
    return item && item.activity && item.activity.name ? item.activity.name : undefined;
  }
}
