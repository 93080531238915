import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';

import { ProjectWorkDaysService } from '../../services/project/project-workdays/project-workdays.service';

import { IDropdownItem } from '../../models/dropdown/dropdown.interface';

import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-workdays',
  templateUrl: './project-workdays.component.html',
  styleUrls: ['./project-workdays.component.scss'],
})

export class ProjectWorkDaysComponent implements OnInit {
  @Input() saveButton: boolean = false;
  @Input() workDataInput: any;
  @Input() masterScheduleHours: any;

  @Output() updateWorkDayOutput: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveOutput: EventEmitter<any> = new EventEmitter<any>();
  // loading
  hoursDropDownList: IDropdownItem[]; // data comes from Utils

  workDayData: any[] = [];
  showStartDD: boolean = true; // to allow to show start dropdown (left)
  showEndDD: boolean = true; // to allow to show end dropdown (right)
  startTime: number = 0; // Start 24 hour selection 0=12am -> 13=1pm
  endTime: number = 0; // End 24 hour selection 0=12am -> 13=1pm
  defaultTimeZone = 'US/Central'; // default Timezone incase none was provided

  // days - associated array to declare all the days of the week and its available selections
  newDaySelection: any[];
  newHour: any;

  constructor(
    private workDayService: ProjectWorkDaysService
  ) {
    this.newDaySelection = workDayService.newDaySelection;
    this.newHour = workDayService.newHour;
  }

  ngOnInit() {
    this.hoursDropDownList = Utils.hourDropdownItems();
    if (this.saveButton) {
      this.setData(cloneDeep(this.workDataInput));
    } else {
      this.addWorkDaySet();
    }
  }

  setData(res): void {
    this.workDayData = res;
    if (this.workDayData.length === 0) {
      this.addWorkDaySet();
    } else {
      this.updateWorkDayOutput.emit();
    }
  }

  getData() {
    return this.workDayData;
  }

  resetData() {
    this.workDayData = [];
    this.addWorkDaySet();
  }

  setTimeZone(timezone) {
    this.defaultTimeZone = timezone;
  }

  getTimeZone() {
    return this.defaultTimeZone;
  }

  // adds a new set of workDays to the workDayData
  addWorkDaySet() {
    // create new data and reference for day and hour selectors
    const daySelection = JSON.parse(JSON.stringify(this.newDaySelection));
    const firstH = JSON.parse(JSON.stringify(this.newHour));

    const hourSelection = [firstH]; // create new hour selection array and push its first hour selector
    const daySelector = {
      days: daySelection,
      hours: hourSelection
    };

    this.workDayData.push(daySelector);
    this.updateWorkDayOutput.emit();
  }

  removeWorkDaySet(workDayIndex) {
    this.workDayData.splice(workDayIndex, 1);
    this.updateWorkDayOutput.emit();
  }

  // adds a new set of workDays to the workDayData
  addHourSet(workDay) {
    const newHourSet = JSON.parse(JSON.stringify(this.newHour));
    workDay.hours.push(newHourSet);
    this.updateWorkDayOutput.emit();
  }

  removeHourSet(workDay, workDayIndex) {
    if (workDay.hours.length === 1) {
      this.removeWorkDaySet(workDayIndex);
    } else {
      workDay.hours.pop();
    }
    this.updateWorkDayOutput.emit();
  }

  // modify the selected referenced day selected
  daySelected(day) {
    if (!day.selected) {
      day.selected = true;
    } else {
      day.selected = false;
    }
    this.updateWorkDayOutput.emit();
  }

  // modify the selected referenced hour selected
  // NOTE: using isStartTime since if sending direct value for currentHour[startTime|endTime] in HTML, it won't be reference therefore not actually changing the stored dataset being sent int
  selectedHour() {
    this.updateWorkDayOutput.emit();
  }

  save() {
    const returnData = this.workDayService.transformWorkDay(this.workDayData);
    this.saveOutput.emit(returnData);
  }

  masterHours() {
    this.setData(cloneDeep(this.masterScheduleHours));
  }
}
