import { Component, ElementRef, EventEmitter, Injectable, Input, NgZone, OnChanges, OnDestroy, OnInit, Output,  ViewChild, ViewChildren } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../services/notification/notification.service';
import { ProjectMessageService } from '../../services/project/project-message/project-message.service';
import { ProjectService } from '../../services/project/project.service';
import { TranslationService } from '../../services/translation/translation.service';
import { UserService } from '../../services/user/user.service';

import { MessageType } from '../../utils/enums/message-type.enum';
import { EventType } from '../../utils/enums/notification.enum';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProjectStepMessage } from '../../models/project/project-step/project-step-message.interface';
import { IUser } from '../../models/user/user.interface';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-messages',
  templateUrl: './project-messages.component.html',
  styleUrls: ['./project-messages.component.scss'],
  providers: [
    ProjectMessageService
  ]
})
@Injectable()
export class ProjectMessagesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedId;
  @Input() messageType: MessageType;

  authenticatedUser: IUser;
  messages: IProjectStepMessage[] = [];
  allMessages: any[] = [];
  pageSize: number = 10;
  numberOfDisplayedMessages: number;
  messageAvatars: any = {};

  newMessageInput: string;
  messageInputFocus: boolean;
  imageInput: any;
  imageSrc: string;
  showImageModal: boolean;
  modalUserName: string;
  modalTime: string;
  modalMessage: string;

  submitting: boolean = false;
  loadingMoreMessages: boolean = false;
  newMessages: boolean = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChildren('messageElement') messageElements;
  @ViewChild('messageDisplay') messageDisplay;
  @ViewChild('imagePreview') imagePreview;
  @ViewChild('fileInput') fileInput;
  @ViewChild('messageInput') messageInput: ElementRef;

  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    private projectMessageService: ProjectMessageService,
    public userService: UserService,
    private notificationService: NotificationService,
    private ngZone: NgZone
  ) { }

  // tslint:disable-next-line:no-empty
  ngOnInit() {
    window.addEventListener('keydown', this.keyPress);
    this.userService.getAuthenticatedUser().pipe(takeUntil(this.destroyed$)).subscribe(
      user => {
        this.authenticatedUser = user;

        this.notificationService.toggleMuteNotifications('stepMessage');

        this.notificationService.event$.pipe(takeUntil(this.destroyed$)).subscribe(async event => {
          if (!this.notificationService.validEvent(event, this.projectService.currentProject.id, EventType.MESSAGE_POSTED)) return;
          let subjectId;
          if (event.body.stepId) subjectId = event.body.stepId;
          if (event.body.objectId) subjectId = event.body.objectId;
          if (event.body.activityId) subjectId = event.body.activityId;
          if (subjectId !== this.selectedId || this.authenticatedUser.id === event.body.userId) return;
          const scrolledToBottom = this.isScrolledToBottom();
          const message: IProjectStepMessage = {
            userId: event.body.userId,
            message: event.body.message,
            timestamp: event.body.timestamp,
            userName: event.body.userName,
            subjectName: event.body.subjectName,
            hasFile: event.body.hasFile,
          };
          if (event.body.stepId) message.stepId = event.body.stepId;
          if (event.body.objectId) message.objectId = event.body.objectId;
          if (event.body.activityId) message.activityId = event.body.activityId;
          message.time = this.convertTimestamp(message.timestamp);
          await this.getAvatar(message);
          if (message.hasFile) {
            this.projectMessageService.getMessageFile(this.projectService.currentProject.id, event.body.id, this.messageType).pipe(takeUntil(this.destroyed$)).subscribe(
              async res => {
                message['file'] = res;
                this.scrollToBottom();
              }
            );
          }
          this.messages.push(message);
          this.allMessages.push(message);
          if (scrolledToBottom) this.scrollToBottom(); else this.newMessages = true;
          this.submitting = false;
        });
      },
      error => {
        const context: INoficationContext = {
          type: 'user',
          action: 'get'
        };
        this.notificationService.error(error, context);
      });
  }

  async ngOnChanges() {
    this.loadingEvent.emit(true);
    if (Utils.isEmpty(this.authenticatedUser)) {
      this.authenticatedUser = await this.userService.getAuthenticatedUser().toPromise();
    }

    if (this.selectedId) {
      if (this.messageType === MessageType.TASK) {
        this.getTaskMessagesData();
      } else if (this.messageType === MessageType.OBJECT) {
        this.getObjectMessagesData();
      } else if (this.messageType === MessageType.ACTIVITY) {
        this.getActivityMessagesData();
      }
    } else {
      this.messages = [];
      this.loadingEvent.emit(false);
    }
  }

  ngOnDestroy() {
    this.notificationService.toggleMuteNotifications('stepMessage');
    window.removeEventListener('keydown', this.keyPress);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async getTaskMessagesData() {
    this.projectMessageService.getProjectStepMessages(this.projectService.currentProject.id, this.selectedId).pipe(takeUntil(this.destroyed$)).subscribe(
      async response => {
        this.messageAvatars = this.projectMessageService.userMessageAvatars;
        this.allMessages = response.response.map(message => { if (!message.message && !message.hasFile) return this.convertSystemMessage(message); else return message; });
        this.sortMessages();
        this.messages = this.allMessages.slice(0, this.pageSize).reverse();
        this.numberOfDisplayedMessages = this.messages.length;
        for (const message of this.messages) {
          message.time = this.convertTimestamp(message.timestamp);

          await this.getAvatar(message);

          if (message.hasFile) {
            let messageType: MessageType;
            if (message.stepId) {
              messageType = MessageType.TASK;
            } else if (message.objectId) {
              messageType = MessageType.OBJECT;
            } else if (message.activityId) {
              messageType = MessageType.ACTIVITY;
            }
            message.file = await this.projectMessageService.getMessageFile(this.projectService.currentProject.id, message.id, messageType).toPromise();
          }
        }
        this.loadingEvent.emit(false);
        this.scrollToBottom();
      },
      error => {
        this.loadingEvent.emit(false);
        const context: INoficationContext = {
          type: 'project step messages',
          action: 'get'
        };
        this.notificationService.error(error, context);
      });
  }

  async getObjectMessagesData() {
    this.projectMessageService.getProjectObjectMessages(this.projectService.currentProject.id, this.selectedId).pipe(takeUntil(this.destroyed$)).subscribe(
      async response => {
        this.messageAvatars = this.projectMessageService.userMessageAvatars;
        this.allMessages = response.response;
        this.sortMessages();
        this.messages = this.allMessages.slice(0, this.pageSize).reverse();
        this.numberOfDisplayedMessages = this.messages.length;
        for (const message of this.messages) {
          message['time'] = this.convertTimestamp(message.timestamp);

          await this.getAvatar(message);

          if (message.hasFile) {
            let messageType: MessageType;
            if (message.stepId) {
              messageType = MessageType.TASK;
            } else if (message.objectId) {
              messageType = MessageType.OBJECT;
            } else if (message.activityId) {
              messageType = MessageType.ACTIVITY;
            }
            message.file = await this.projectMessageService.getMessageFile(this.projectService.currentProject.id, message.id, messageType).toPromise();
          }
        }
        this.loadingEvent.emit(false);
        this.scrollToBottom();
      },
      error => {
        this.loadingEvent.emit(false);
        const context: INoficationContext = {
          type: 'project object messages',
          action: 'get'
        };
        this.notificationService.error(error, context);
      });
  }

  async getActivityMessagesData() {
    this.projectMessageService.getProjectActivityMessages(this.projectService.currentProject.id, this.selectedId).pipe(takeUntil(this.destroyed$)).subscribe(
      async response => {
        this.messageAvatars = this.projectMessageService.userMessageAvatars;
        this.allMessages = response.response;
        this.sortMessages();
        this.messages = this.allMessages.slice(0, this.pageSize).reverse();
        this.numberOfDisplayedMessages = this.messages.length;
        for (const message of this.messages) {
          message['time'] = this.convertTimestamp(message.timestamp);

          await this.getAvatar(message);

          if (message.hasFile) {
            let messageType: MessageType;
            if (message.stepId) {
              messageType = MessageType.TASK;
            } else if (message.objectId) {
              messageType = MessageType.OBJECT;
            } else if (message.activityId) {
              messageType = MessageType.ACTIVITY;
            }
            message.file = await this.projectMessageService.getMessageFile(this.projectService.currentProject.id, message.id, messageType).toPromise();
          }
        }
        this.loadingEvent.emit(false);
        this.scrollToBottom();
      },
      error => {
        this.loadingEvent.emit(false);
        const context: INoficationContext = {
          type: 'project object messages',
          action: 'get'
        };
        this.notificationService.error(error, context);
      });
  }

  convertSystemMessage(systemMessage: any): any {
    const formattedMessage = {
      id: systemMessage.id,
      timestamp: systemMessage.timestamp,
      message: this.getStatus(systemMessage),
      userName: systemMessage.userName,
      systemMessage: true
    };
    return formattedMessage;
  }

  getStatus(message) {
    if (message.newValue === 0) {
      return TranslationService.translate('task_update_planned', {userName: message.userName, taskName: message.subjectName});
    } else if (message.newValue === 1) {
      return TranslationService.translate('task_update_scheduled', {userName: message.userName, taskName: message.subjectName});
    } else if (message.newValue === 2) {
      return TranslationService.translate('task_update_committed', {userName: message.userName, taskName: message.subjectName});
    } else if (message.newValue === 3) {
      return TranslationService.translate('task_update_in_progress', {userName: message.userName, taskName: message.subjectName});
    } else if (message.newValue === 4) {
      return TranslationService.translate('task_update_completed', {userName: message.userName, taskName: message.subjectName});
    } else if (message.newValue === 5) {
      return TranslationService.translate('task_update_changed', {userName: message.userName, taskName: message.subjectName});
    } else if (message.newValue === 7) {
      return TranslationService.translate('task_update_not_completed_backlog', {userName: message.userName, taskName: message.subjectName, reason: message.reason});
    } else if (message.newValue === 8) {
      // tslint:disable-next-line:max-line-length
      return TranslationService.translate('task_update_not_completed', {userName: message.userName, taskName: message.subjectName, reason: message.reason, secondarySubjectName: message.secondarySubjectName});
    } else if (message.newValue === 9) {
      return TranslationService.translate('make_ready_not_approved', {userName: message.userName, taskName: message.subjectName, secondarySubjectName: message.secondarySubjectName});
    } else if (message.newValue === 10) {
      return TranslationService.translate('make_ready_approved', {userName: message.userName, taskName: message.subjectName, secondarySubjectName: message.secondarySubjectName});
    } else if (message.newValue === 11) {
      return TranslationService.translate('make_ready_ordered', {userName: message.userName, taskName: message.subjectName, secondarySubjectName: message.secondarySubjectName});
    } else if (message.newValue === 12) {
      return TranslationService.translate('make_ready_delivered', {userName: message.userName, taskName: message.subjectName, secondarySubjectName: message.secondarySubjectName});
    } else if (message.newValue === 13) {
      return TranslationService.translate('make_ready_ready', {userName: message.userName, taskName: message.subjectName, secondarySubjectName: message.secondarySubjectName});
    }
    return 'Unknown';
  }

  async submitMessage() {
    if (!Utils.isEmpty(this.selectedId)) {
      if (Utils.isEmpty(this.newMessageInput) && (this.imageInput === null || this.imageInput === undefined)) return;
      let userName = this.authenticatedUser.email;
      if (!Utils.isEmpty(this.authenticatedUser.firstName)) userName = this.authenticatedUser.firstName;
      if (!Utils.isEmpty(this.authenticatedUser.lastName)) {
        if (Utils.isEmpty(this.authenticatedUser.firstName)) userName = this.authenticatedUser.lastName;
        else userName = userName + ' ' + this.authenticatedUser.lastName;
      }
      const message = {
        projectId: this.projectService.currentProject.id,
        userId: this.authenticatedUser.id,
        message: this.newMessageInput,
        timestamp: Date.now(),
        userName: userName
      };
      message[this.messageType + 'Id'] = this.selectedId;
      if (this.imageInput !== undefined && this.imageInput !== null) {
        const fileSplit = this.imageInput.name.split('.');
        const fileType = fileSplit[fileSplit.length - 1];
        message['hasFile'] = fileType;
      }
      this.submitting = true;
      this.projectMessageService.createProjectMessage(message, this.imageInput, this.messageType).pipe(takeUntil(this.destroyed$)).subscribe(
        async response => {
          if (response.type === 4) {
            const newMessage = response.body.response;
            if (this.imageInput !== null && this.imageInput !== undefined) {
              const reader = new FileReader();
              reader.readAsDataURL(this.imageInput);
              reader.onload = (image: any) => {
                newMessage['file'] = image.target.result;
                this.scrollToBottom();
              };
            }
            newMessage['time'] = this.convertTimestamp(newMessage.timestamp);
            await this.getAvatar(message);
            this.allMessages.push(newMessage);
            this.messages.push(newMessage);
            this.numberOfDisplayedMessages = this.messages.length;
            this.scrollToBottom();
            this.newMessageInput = '';
            this.imageInput = null;
            this.submitting = false;
            await Utils.sleep(0, this.ngZone);
            this.messageInput.nativeElement.focus();
          }
        },
        error => {
          this.messages[this.messages.length - 1].failed = true;
          this.submitting = false;
          const context: INoficationContext = {
            type: 'project message',
            action: 'create'
          };
          this.notificationService.error(error, context);
        });
    }
  }

  sortMessages() {
    this.allMessages.sort((a, b) => {
      if (a.timestamp < b.timestamp) {
        return 1;
      } else if (a.timestamp > b.timestamp) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  convertTimestamp(timestamp: number) {
    return Utils.formatDateTime(timestamp, true);
  }

  async loadMoreMessages() {
    this.sortMessages();
    let numberOfNewMessages = this.messages.length;
    this.numberOfDisplayedMessages = this.numberOfDisplayedMessages + this.pageSize <= this.allMessages.length
      ? this.numberOfDisplayedMessages + this.pageSize
      : this.allMessages.length;

    const currentElement = document.getElementById('messages').children[1];
    this.messages = this.allMessages.slice(0, this.numberOfDisplayedMessages).reverse();
    numberOfNewMessages = this.messages.length - numberOfNewMessages;
    this.messages.forEach(async message => {
      message['time'] = this.convertTimestamp(message.timestamp);
      await this.getAvatar(message);
      if (message.hasFile && Utils.isEmpty(message.file)) {
        this.projectMessageService.getMessageFile(this.projectService.currentProject.id, message.id, this.messageType).pipe(takeUntil(this.destroyed$)).subscribe(
          async res => {
            message['file'] = res;
            if (this.isScrolledToBottom()) this.scrollToBottom();
          }
        );
      }
    });
    await Utils.sleep(0, this.ngZone);
    currentElement.scrollIntoView({ 'behavior': 'instant', 'block': 'start', 'inline': 'start' });
  }

  keyPress = (event) => {
    if (this.messageInputFocus && event.keyCode === 13) {
      this.submitMessage();
    }
  }

  pickFile(event) {
    this.imageInput = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageInput);
    reader.onload = (image: any) => {
      this.imagePreview.nativeElement.src = image.target.result;
    };
  }

  showImage(message) {
    this.imageSrc = message.file;
    this.modalMessage = message.message;
    this.modalUserName = message.userName;
    this.modalTime = message.time;
    this.showImageModal = true;
  }

  onImageClosed() {
    this.showImageModal = false;
  }

  clearImage() {
    this.fileInput.nativeElement.value = '';
    this.imageInput = null;
  }

  scrollToBottom() {
    this.newMessages = false;
    Utils.sleep(0, this.ngZone).then(() => {
      document.getElementById('messages').lastElementChild.scrollIntoView({ 'behavior': 'instant', 'block': 'start', 'inline': 'start' });
    });
  }

  isScrolledToBottom(): boolean {
    const element = document.getElementById('messages');
    return element.scrollTop === (element.scrollHeight - element.offsetHeight + 1);
  }

  async getAvatar(message) {
    if (!message.systemMessage && this.messageAvatars[message.userId] == null) {
      // updates the userMessageAvatars in the step message service which stepMessageAvatar is referenced to
      await this.projectMessageService.getUserAvatar(message.userId);
    }
    if (!message.systemMessage) {
      message.avatar = this.messageAvatars[message.userId];
    }
    return;
    // set a reference link to of message to local cache of user avatar ID
  }

  onScroll() {
    if (this.isScrolledToBottom()) this.newMessages = false;
  }
}
