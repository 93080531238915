import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { IMasterScheduleActivity } from '../../../models/activity/activity.interface';
import { ITableSearchOptions } from '../../../models/custom-table/custom-table.interface';
import { IColumnHeader, ICustomTable, IRow, IRowItem } from '../../../models/custom-table/custom-table.interface';
import { IDropdownItem } from '../../../models/dropdown/dropdown.interface';
import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../../shared/grit-table/grit-table';
import { IProjectMilestone } from '../../../models/project/project-milestone/project-milestone.interface';
import { IProjectObjectSidebarTabs } from '../../../models/project/project-object/project-object.interface';
import { IUserPermission } from '../../../models/user/user.interface';

import { HttpBackendService } from '../../http-backend/http-backend.service';
import { NotificationService } from '../../notification/notification.service';
import { ProjectService } from '../project.service';
import { Utils } from '../../../utils/utils';

@Injectable()
export class ProjectMilestoneService {
  constructor(
    private httpService: HttpBackendService,
    private projectService: ProjectService,
    private formBuilderService: FormBuilder,
    private notificationService: NotificationService
  ) { }

  public tablePageSize: number = 20;
  private projectMilestones = [];

  public getMilestones(projectId: string) {
    return this.httpService.get('/project/' + projectId + '/milestones');
  }

  public setLocalProjectMilestones(projectId: string): Promise<boolean> {
    this.projectMilestones = [];
    return new Promise((resolve) => {
      this.getMilestones(projectId).subscribe(
        res => {
          this.projectMilestones = res;
          return resolve(true);
        },
        err => {
          this.notificationService.error(err, {type: 'get subcontractors', action: 'get'});
          return resolve(false);
        }
      );
    });
  }

  public getLocalProjectMilestones(): any[] {
    return this.projectMilestones;
  }
  public getLocalProjectMilestone(milestoneId: string): any {
    return this.projectMilestones.find(m => m.id === milestoneId);
  }

  public getScheduledMilestones(scheduleId: string): Observable<any> {
    return this.httpService.get('/project/schedule/' + scheduleId + '/milestones');
  }

  public getMilestone(projectMilestoneId: string): Observable<any> {
    return this.httpService.get('/project/milestone/' + projectMilestoneId);
  }

  public getSpecificMilestones(projectId: string, milestoneIds: string[]): Observable<any> {
    return this.httpService.post('/project/' + projectId + '/milestones', { milestoneIds });
  }

  public addMilestone(json: any): Observable<any> {
    return this.httpService.post('/project/milestone', json);
  }

  public updateMilestone(projectMilestoneId: string, json: any): Observable<any> {
    return this.httpService.put('/project/milestone/' + projectMilestoneId, json);
  }

  public deleteMilestone(projectMilestoneId: string): Observable<any> {
    return this.httpService.delete('/project/milestone/' + projectMilestoneId);
  }

  public importCSVMilestones(projectId: string, file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpService.formData('/project/' + projectId + '/milestone', formData);
  }

  public buildMilestoneFormInput(formInput: IMasterScheduleActivity) {
    const milestoneFormInput = this.formBuilderService.group({
      name: [null, Validators.required],
      endDate: [null, Validators.required]
    });
    if (formInput) {
      milestoneFormInput.controls['name'].setValue(formInput.activity.name);
      milestoneFormInput.controls['endDate'].setValue(Utils.fromUtcDate(formInput.activity.endDate));
    }

    return milestoneFormInput;
  }

  transformTableData(dataInput: IProjectMilestone[], permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      },
      {
        displayName: 'end_date',
        colKey: 'endDate',
        type: RowItemType.Date
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(mStone => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: mStone.name,
          editable: permission.edit && !permission.subContractorId
        },
        {
          colKey: 'endDate',
          value: Utils.convertFromUtcToDate(mStone.endDate),
          editable: permission.edit && !permission.subContractorId
        }
      ];
      rows.push(
        {
          key: mStone.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.edit && !permission.subContractorId,
            rowEdits: permission.edit && !permission.subContractorId ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        inline: true,
        addPermission: permission.edit && !permission.subContractorId,
        addModel: [
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'name',
            required: true,
            editable: true,
          },
          {
            type: RowItemType.Date,
            value: '',
            colKey: 'endDate',
            required: true,
            editable: true,
          }
        ]
      }
    };

    return retTable;
  }

  // Transform data for table
  transformObjectTableData(
    tableDataResponse: any,
    selectedProjectObjects: any,
    assignMilestoneFunction: (milestoneId: string) => void,
    selectedActivity: string,
    searchQuery?: string,
    paginationStart?: number,
    activeSearchOptions?: string[]
  ): ICustomTable {
    const objectsData = tableDataResponse;
    const objectsDataKeys = Object.keys(objectsData);
    const overallCount = objectsDataKeys.length;
    const pageSize = this.tablePageSize;
    const pageStart = paginationStart ? paginationStart : 0;

    const transformedTableData: ICustomTable = {
      title: 'Project Objects',
      filterable: false,
      searchable: true,
      columnHeaders: [],
      rows: [],
      multiSelect: true,
      canCreate: false,
      multiSearch: false,
      searchOptions: [],
      filterByOptions: null,
      bulkEdit: false,
      searchQuery: searchQuery,
      pagination: {
        count: overallCount,
        pageSize: pageSize,
        pageStart: pageStart,
        totalItemsSelected: 0,
        display: true
      },
      showIntro: true,
      bulkHide: false,
      customFunctionDropdown: {
        title: 'Milestones',
        label: 'Assign Milestones',
        showDropdown: false,
        loadDataFunction: this.getMilestonesDropdownItems,
        multiSelect: false,
        buttonDisplay: true,
        buttonDisplayIcon: 'fa-cube',
        applySelectionFunction: assignMilestoneFunction
      },
      allRowsSelected: overallCount === Object.keys(selectedProjectObjects).length ? true : false
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'Name',
        key: 'name',
        sortable: false,
        active: false,
        searchable: true
      },
      {
        title: 'Milestone',
        key: 'milestone',
        sortable: false,
        active: false,
        searchable: true
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Define search options for multi-search
    const searchOptions: ITableSearchOptions[] = [];
    transformedTableData.columnHeaders.filter((colHead, index) => {
      if (colHead.searchable === true) {
        searchOptions.push({
          title: colHead.title,
          active: activeSearchOptions && activeSearchOptions.includes(colHead.title) ? true : false
        });
      }
    });
    transformedTableData.searchOptions = searchOptions;

    let totalOtherItemsSelected = Object.keys(selectedProjectObjects).length;

    // Table Rows
    const tableRows: IRow[] = [];
    for (let r = pageStart; r < pageStart + pageSize; r++) {
      if (r < objectsDataKeys.length) {
        const rowItems: IRowItem[] = [
          {
            value: objectsData[objectsDataKeys[r]].name,
            prevStateValue: objectsData[objectsDataKeys[r]].name,
            editable: false,
            type: 'text',
            actionOutput: objectsData[objectsDataKeys[r]]
          },
          {
            name: 'milestone',
            value: !Utils.isEmpty(objectsData[objectsDataKeys[r]].milestone) && !Utils.isEmpty(objectsData[objectsDataKeys[r]].milestone[selectedActivity])
              ? objectsData[objectsDataKeys[r]].milestone[selectedActivity].name
              : ['None Selected'],
            prevStateValue: ['None Selected'],
            editable: false,
            type: 'text',
            bulkEditId: 'milestone',
            actionOutput: !Utils.isEmpty(objectsData[objectsDataKeys[r]].milestone) && !Utils.isEmpty(objectsData[objectsDataKeys[r]].milestone[selectedActivity])
              ? objectsData[objectsDataKeys[r]].milestone[selectedActivity].name
              : []
          }
        ];

        if (selectedProjectObjects.hasOwnProperty(objectsData[objectsDataKeys[r]].id)) {
          totalOtherItemsSelected--;
        }

        tableRows.push({
          selectable: true,
          editable: false,
          removeable: false,
          active: selectedProjectObjects.hasOwnProperty(objectsData[objectsDataKeys[r]].id),
          rowItems: rowItems,
          key: objectsData[objectsDataKeys[r]].id,
          isEditing: false,
          isHidden: objectsData[objectsDataKeys[r]].hidden
        });
      }
    }

    transformedTableData.rows = tableRows;
    transformedTableData.pagination.totalItemsSelected = totalOtherItemsSelected;

    return transformedTableData;
  }

  // Milestone dropdown
  getMilestonesDropdownItems = (): Promise<IDropdownItem[]> => {
    return new Promise((resolve, _reject) => {
      const dropdownItems: IDropdownItem[] = [{label: 'None', value: ''}];
      return this.getMilestones(this.projectService.currentProject.id).toPromise().then(milestones => {
        milestones.map(m => dropdownItems.push({ label: m.name, value: m.id }));
        return resolve(dropdownItems);
      });
    });
  }

  // Get Sidebar data
  getSidebarTabData(): IProjectObjectSidebarTabs[] {
    const sidebarTabs = [
      {
        name: 'Object Properties',
        icon: 'fa-cube',
        key: 'properties',
        active: true
      }
    ];

    return sidebarTabs;
  }
}
