import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-project-sidebar-component',
  templateUrl: './project-sidebar-component.component.html',
  styleUrls: ['./project-sidebar-component.component.scss']
})
export class ProjectSidebarComponent implements OnInit {
  @Input() sideBarInput: boolean;
  @Output() sideBarOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public projectService: ProjectService
  ) {}

  ngOnInit() {
    this.projectService.checkSidebarState();
    this.projectService.setDisabledSidebar(false);
  }

  closeSideBar(): void {
    this.sideBarOutput.emit(false);
  }
}
