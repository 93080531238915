import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IProject } from '../../models/project/project.interface';
import { IUser, IUserPermission } from '../../models/user/user.interface';

@Injectable()
export class MenuEmitterService {

    private static emitShowMenuChangeSource = new Subject<boolean>();
    private static emitAccountChangeSource = new Subject<Account>();
    private static emitProjectChangeSource = new Subject<IProject>();
    private static emitProjectArchiveSource$ = new Subject<string>();
    private static emitPermissionChangeSource = new Subject<IUserPermission>();
    private static emitAccountPermissionChangeSource = new Subject<IUserPermission>();
    private static emitAuthenticatedUserChangeSource = new Subject<IUser>();
    private static emitSlideOutStateChangeSource = new Subject<boolean>();

    static showMenuChanged$ = MenuEmitterService.emitShowMenuChangeSource.asObservable();
    static accountChanged$ = MenuEmitterService.emitAccountChangeSource.asObservable();
    static projectChanged$ = MenuEmitterService.emitProjectChangeSource.asObservable();
    static projectArchived$ = MenuEmitterService.emitProjectArchiveSource$.asObservable();
    static permissionChanged$ = MenuEmitterService.emitPermissionChangeSource.asObservable();
    static accountPermissionChanged$ = MenuEmitterService.emitAccountPermissionChangeSource.asObservable();
    static authenticatedUserChanged$ = MenuEmitterService.emitAuthenticatedUserChangeSource.asObservable();
    static slideOutStateChanged$ = MenuEmitterService.emitSlideOutStateChangeSource.asObservable();

    private static authenticatedUser: IUser;
    private static projectPermission: IUserPermission;

    static emitShowMenuChange(showMenu: boolean) {
        MenuEmitterService.emitShowMenuChangeSource.next(showMenu);
    }

    static emitAccountChange(change: Account) {
        MenuEmitterService.emitAccountChangeSource.next(change);
    }

    static emitProjectChange(project: IProject) {
        MenuEmitterService.emitProjectChangeSource.next(project);
    }

    static emitPermissionChange(permission: IUserPermission) {
        this.projectPermission = permission;
        MenuEmitterService.emitPermissionChangeSource.next(permission);
    }

    static emitAccountPermissionChange(permission: IUserPermission) {
        MenuEmitterService.emitAccountPermissionChangeSource.next(permission);
    }

    static emitAuthenticatedUserChange(authenticatedUser: IUser) {
        this.authenticatedUser = authenticatedUser;
        MenuEmitterService.emitAuthenticatedUserChangeSource.next(authenticatedUser);
    }

    static emitProjectArchive(projectId: string) {
        MenuEmitterService.emitProjectArchiveSource$.next(projectId);
    }

    static getAuthenticatedUser(): IUser {
        return this.authenticatedUser;
    }

    static getProjectPermissions(): IUserPermission {
        return this.projectPermission;
    }

    static emitSlideoutState(showMenu: boolean) {
      MenuEmitterService.emitSlideOutStateChangeSource.next(showMenu);
    }
}
