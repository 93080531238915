import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ProjectObjectService } from '../../services/project/project-object/project-object.service';

import { IAccordion } from '../../models/accodordion/accordion.interface';

import { Subscription } from 'rxjs';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-project-object-properties',
  templateUrl: './project-object-properties.component.html',
  styleUrls: ['./project-object-properties.component.scss']
})
export class ProjectObjectPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gritObjectIds: string[];

  curObjIdPos: number = 0;
  curObjName: string = '';
  curObjId: string = '';
  accordionData: IAccordion[] = [];
  showArrows: boolean = false;
  errorGettingData: boolean = false;
  noData: boolean = false;
  loadingMessage: string = 'loading';
  errorMessage: string = 'error';
  noDataMessage: string = 'no_data';
  openCategories: string[];
  oldArrayMethod: boolean = true;

  projectObjectSubscription: Subscription;

  objectKeys = Object.keys; // used for ngFor loop on object in template

  constructor(
    private projectObjectService: ProjectObjectService
  ) {/*EMPTY*/}

  ngOnInit() {/*EMPTY*/}

  ngOnDestroy() {
    if (this.projectObjectSubscription) {
      this.projectObjectSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.resetSidebarData();
    this.showArrows = false;
    if (!Utils.isEmptyList(this.gritObjectIds)) {
      if (Utils.isEmpty(this.curObjId)) {
        this.curObjIdPos = 0;
        this.openCategories = [];
      } else {
        if (this.gritObjectIds.includes(this.curObjId)) {
          this.curObjIdPos = this.gritObjectIds.indexOf(this.curObjId);
        } else {
          this.curObjIdPos = 0;
          this.openCategories = [];
        }
      }
      if (this.gritObjectIds.length > 1) {
        this.showArrows = true;
      }
      this.setObjectData();
    } else {
      this.noData = true;
      this.noDataMessage = 'select_task';
    }
  }

  setObjectData(): void {
    this.resetSidebarData();
    this.curObjId = this.gritObjectIds[this.curObjIdPos];
    this.projectObjectSubscription = this.projectObjectService.getObject(this.curObjId).subscribe(
      res => {
        if (!Utils.isEmptyList(this.gritObjectIds)) {
          if (!Utils.isEmpty(res.name)) {
            this.curObjName = res.name;
          }
          if (!Utils.isEmpty(res.properties)) {
            this.accordionData = this.transformAccordionData(res.properties);
          } else {
            this.noData = true;
            this.noDataMessage = 'no_data';
          }
        }
      },
      err => {
        this.errorGettingData = true;
      }
    );
  }

  transformAccordionData(data: any): IAccordion[] {
    const retData: IAccordion[] = [];
    for (const cat in data) {
      if (cat) {
        const accordionItem = { label: cat, active: false};
        if (this.openCategories.includes(cat)) accordionItem.active = true;
        const itemInfo = {};
        if (typeof (data[cat]) !== 'object') continue;
        for (const val in data[cat]) {
          if (val) {
            itemInfo[val] = data[cat][val];
          }
        }
        accordionItem['info'] = itemInfo;
        retData.push(accordionItem);
      }
    }
    return retData;
  }

  toggleAccordion(item: IAccordion): void {
    item.active = !item.active;
    if (item.active) {
      if (!this.openCategories.includes(item.label)) this.openCategories.push(item.label);
    } else {
      if (this.openCategories.includes(item.label)) this.openCategories.splice(this.openCategories.indexOf(item.label), 1);
    }
  }

  nextObject(direction: number): void {
    this.curObjIdPos += direction;
    if (this.curObjIdPos >= this.gritObjectIds.length) {
      this.curObjIdPos = 0;
    } else if (this.curObjIdPos < 0) {
      this.curObjIdPos = this.gritObjectIds.length - 1;
    }
    this.setObjectData();
  }

  resetSidebarData(): void {
    this.accordionData = [];
    this.curObjName = '';
    this.errorGettingData = false;
    this.noData = false;
  }
}
