import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IPageSubmenuLink, IPageSubmenuSection } from '../../models/page-submenu/page-submenu.interface';

@Component({
  selector: 'app-page-submenu',
  templateUrl: './page-submenu.component.html',
  styleUrls: ['./page-submenu.component.scss']
})
export class PageSubmenuComponent implements OnInit {
  @Input() submenuSections: IPageSubmenuSection[];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {/*EMPTY*/}

  setMenuItem(selectedLink: IPageSubmenuLink): void {
    this.submenuSections.forEach(item => item.links.forEach(link => link.active = false));
    selectedLink.active = true;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { menuItem: selectedLink.menuIndex }
    });
  }

}
