
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../services/notification/notification.service';
import { IProjectPlanStep } from '../../models/project/project-network-map/project-network-map.interface';
import { ProjectStepService } from '../../services/project/project-step/project-step.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';

import { IMenuItems } from '../../models/menu/menu.interface';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IProject } from '../../models/project/project.interface';
import { StepStatus } from '../../utils/enums/step-status.enum';
import { Utils } from '../../utils/utils';
import { ActivityService } from '../../services/activity/activity.service';

@Component({
  selector: 'app-project-step-info',
  templateUrl: './project-step-info.component.html',
  styleUrls: ['./project-step-info.component.scss']
})
export class ProjectStepInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() planStepIds: string[];
  @Output() editted = new EventEmitter<any>();
  @Output() nameChanged = new EventEmitter<string>();

  projectId: string;
  curPlanStepId: string;
  planStep: any;
  stepDerivedFromActivity: boolean;
  stepSubcontractor: any;
  projectStepImage: any;
  showImage: boolean = false;
  stepInfoLoading: boolean = false;
  showModal: boolean = false;
  project: IProject;

  curStepIdPos: number = 0;
  showArrows: boolean = false;

  showMessage: boolean = false;
  noStepInfoMessage: string;

  stepStatusEnum = StepStatus; // For HTML

  // For editing a step
  // flag to let us know if edit is in progress
  userHasEditPermission: boolean = false;
  editStepDuration: boolean = false;
  editStepCrewSize: boolean = false;
  editStepName: boolean = false;
  editLead: boolean = false;
  editLag: boolean = false;
  // ties to the input field
  newDuration: any = null;
  newLead: any = null;
  newLag: any = null;
  newCrewSize: any = null;
  newStepName: string;

  quickNav: IMenuItems[] = [];

  // Subscriptions
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectStepService: ProjectStepService,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private projectSubContractorService: ProjectSubContractorService,
    private activityService: ActivityService
  ) { }

  ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    this.project = this.projectService.currentProject;
    window.addEventListener('keydown', this.keyPress);
  }

  ngOnChanges() {
    this.curStepIdPos = 0;
    this.getProjectStep();

    if (this.planStepIds && this.planStepIds.length > 1) this.showArrows = true;
    else this.showArrows = false;
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.keyPress);
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  keyPress = (event) => {
    // If enter is hit - save the data
    if (event.keyCode === 13) {
      if (this.editStepName) return this.onNameSaveClick();
      this.onSaveClick();
    }

    // reset edit bar if ESC is hit
    if (event.keyCode === 27) {
      this.onEditClick('');
    }
  }

  getProjectStep() {
    if (Utils.isEmptyList(this.planStepIds)) {
      this.noStepInfoMessage = 'select_task';
      this.showMessage = true;
      this.planStep = null;
      return;
    }
    if (Utils.isEmpty(this.planStepIds[0])) {
      this.noStepInfoMessage = 'select_task';
      this.showMessage = true;
      this.planStep = null;
      return;
    }
    this.showMessage = false;
    this.curPlanStepId = this.planStepIds[this.curStepIdPos];
    this.stepInfoLoading = true;

    this.projectStepService.getPlanStep(this.curPlanStepId).pipe(takeUntil(this.destroyed$)).subscribe(
      results => {
        this.planStep = results;
        if(this.planStep.parentId){
         this.planStep['parentExternalActivityId']=  this.activityService.getLocalActivity(this.planStep.parentId).externalActivityId;
         this.planStep['parentName']=  this.activityService.getLocalActivity(this.planStep.parentId).name;
        }

        if(this.planStep.durationHours){
          this.planStep.durationDays = Utils.convertHoursToDays(this.planStep.durationHours);
        }

        if(this.planStep.projectStep.durationHours){
          this.planStep.projectStep.durationDays = Utils.convertHoursToDays(this.planStep.projectStep.durationHours);
        }
        
        this.stepDerivedFromActivity = this.planStep.derivedFrom.includes('Activity');
        this.formatCosts(this.planStep);

        this.generateQuickLinks();
        if (this.planStep.scheduledStart) this.planStep.scheduledStart = Utils.formatDate(this.planStep.scheduledStart);
        if (this.planStep.scheduledEnd) this.planStep.scheduledEnd = Utils.formatDate(this.planStep.scheduledEnd);
        this.onEditClick('');
        this.getSubcontractor();
        // refresh permission
        this.getPermission();
      },
      err => {
        const context: INoficationContext = {
          type: 'project step',
          action: 'get'
        };
        this.notificationService.error(err, context);
        this.stepInfoLoading = false;
      });
  }

  formatCosts(planStep: IProjectPlanStep): void {
    if (planStep.equipmentCost) planStep.formattedEquipmentCost = Utils.formatCurrency(planStep.equipmentCost, this.projectService.currencyCode);
    if (planStep.laborCost) planStep.formattedLaborCost = Utils.formatCurrency(planStep.laborCost, this.projectService.currencyCode);
    if (planStep.materialCost) planStep.formattedMaterialCost = Utils.formatCurrency(planStep.materialCost, this.projectService.currencyCode);
  }

  generateQuickLinks() {
    if (this.planStep.projectStepId == null) return;

    const quickLinks = [];
    const projectId = this.projectService.currentProject.id;
    const pStepId = this.planStep.projectStepId;
    const stepId = this.planStep.id;
    const activity = this.planStep.activities ? this.planStep.activities[0] : null;
    const scheduledStart = this.planStep.scheduledStart;

    const currentUrl = this.projectService.projectUrl;

    if (!currentUrl.includes('master-schedule') && activity) {
      // generate planner
      const master = {
        title: 'master_schedule_menu_item',
        iconClass: 'fas fa-align-left',
        routes: ['/project', projectId, 'master-schedule'],
        query: {
          activityId: activity
        },
        active: true,
      };
      quickLinks.push(master);
    }

    // Commented as requested: 

    // if (!currentUrl.includes('planner')) {
    //   // generate planner
    //   const planner = {
    //     title: 'last_planner_menu_item',
    //     iconClass: 'fas fa-puzzle-piece',
    //     active: true,
    //   };
    //   if (activity) {
    //     planner['routes'] = ['/project', projectId, 'planner', 'filter', activity];
    //   } else {
    //     planner['routes'] = ['/project', projectId, 'planner', stepId];
    //   }
    //   quickLinks.push(planner);
    // }

    // // generate network map link if planId exists
    // if (this.planStep.projectPlanId && !currentUrl.includes('network-map')) {
    //   const networkMap = {
    //     title: 'network_map_menu_item',
    //     iconClass: 'fas fa-project-diagram',
    //     routes: ['/project', projectId, 'network-map'],
    //     query: {
    //       stepId: stepId
    //     },
    //     active: true,
    //   };
    //   quickLinks.push(networkMap);
    // }

    if (scheduledStart && !currentUrl.includes('schedule')) {
      const lookAhead = {
        title: 'lookahead_menu_item',
        iconClass: 'far fa-calendar-alt',
        routes: ['/project', projectId, 'schedule'],
        query: {
          stepId: pStepId
        },
        active: true,
      };
      quickLinks.push(lookAhead);
    }

    this.quickNav = quickLinks;
  }

  getSubcontractor() {
    this.projectSubContractorService.getAssociatedSubContractors(this.projectId).pipe(takeUntil(this.destroyed$)).subscribe(
      response => {
        this.stepSubcontractor = response.find(sub => sub.id === this.planStep.subContractorId);
        this.stepInfoLoading = false;
      },
      error => {
        const context: INoficationContext = {
          type: 'project subcontractor',
          action: 'get'
        };
        this.notificationService.error(error, context);
        this.stepInfoLoading = false;
      });
  }

  getPermission() {
    const userPermission = ProjectService.userPermission;
    this.userHasEditPermission = false;

    // A user can edit a step if they have edit permissions and are of the same subcontractor
    if (userPermission.gc && userPermission.edit) {
      this.userHasEditPermission = true;
    } else if (!userPermission.gc && userPermission.edit && userPermission.hasOwnProperty('subContractorId') && userPermission.subContractorId === this.planStep.subContractorId) {
      this.userHasEditPermission = true;
    }
  }

  onEditClick(clickAction: string) {
    // If no edit permisisons, make sure flags are reset
    if (!this.userHasEditPermission || this.stepDerivedFromActivity) {
      clickAction = '';
    }
    this.newCrewSize = null;
    this.newDuration = null;
    this.newLead = null;
    this.newLag = null;
    this.newStepName = this.planStep.stepName;

    switch (clickAction) {
      case 'duration':
        this.editStepDuration = !this.editStepDuration;
        this.editStepCrewSize = false;
        this.editStepName = false;
        this.editLead = false;
        this.editLag = false;
        break;
      case 'crewSize':
        this.editStepCrewSize = !this.editStepCrewSize;
        this.editStepDuration = false;
        this.editStepName = false;
        this.editLead = false;
        this.editLag = false;
        break;
      case 'name':
        this.editStepName = !this.editStepName;
        this.editStepDuration = false;
        this.editStepCrewSize = false;
        this.editLead = false;
        this.editLag = false;
        break;
      case 'lead':
        this.editLead = !this.editLead;
        this.editLag = false;
        this.editStepName = false;
        this.editStepDuration = false;
        this.editStepCrewSize = false;
        break;
      case 'lag':
        this.editLag = !this.editLag;
        this.editLead = false;
        this.editStepName = false;
        this.editStepDuration = false;
        this.editStepCrewSize = false;
        break;
      default:
        this.editLead = false;
        this.editLag = false;
        this.editStepName = false;
        this.editStepDuration = false;
        this.editStepCrewSize = false;
        break;
    }
  }

  onSaveClick()  {
    // If user does not have permisison, DO NOTHING
    if (!this.userHasEditPermission) return;
    // We will actually do something ONLY if there one of the values is entered
    if (!Utils.isEmpty(this.newCrewSize) || !Utils.isEmpty(this.newDuration) || this.newStepName !== this.planStep.name
      || !Utils.isEmpty(this.newLead) || !Utils.isEmpty(this.newLag)) {
      this.updateStep(this.newCrewSize, this.newDuration, this.newStepName, this.newLead, this.newLag);
    }

    this.onEditClick('');
  }

  onNameSaveClick() {
    if (Utils.isEmpty(this.newStepName)) return this.notificationService.error('INVALID_NAME', null);

    this.planStep.name = this.planStep.name.replace(this.planStep.stepName, this.newStepName);
    this.planStep.stepName = this.newStepName;
    this.onEditClick('');

    this.projectStepService.updateStepName(this.planStep.projectStepId, this.newStepName).pipe(takeUntil(this.destroyed$)).subscribe(
      success => {
        this.nameChanged.emit(this.newStepName);
      },
      err => {
        this.notificationService.error(err, { type: 'step', action: 'edit' });
      }
    );
  }

  updateStep(newCrewSize: number, newDuration: number, newStepName: string, newLead: number, newLag: number) {
    const originalCrewSize = this.planStep.crewSize;
    const originalDuration = this.planStep.durationHours;
    const originalName = this.planStep.name;
    const originalStepName = this.planStep.stepName;
    const originalLead = this.planStep.lead;
    const originalLag = this.planStep.lag;

    if (!Utils.isEmpty(newCrewSize)) {
      newCrewSize = Math.floor(newCrewSize);
    } else {
      newCrewSize = this.planStep.projectStep.crewSize;
    }

    if (!Utils.isEmpty(newDuration)) {
      newDuration = Math.ceil(newDuration * 4) / 4;
    } else {
      newDuration = this.planStep.projectStep.durationHours;
    }

    if (Utils.isEmpty(newStepName)) {
      newStepName = this.planStep.stepName;
    }

    if (!Utils.isEmpty(newLead)) {
      newLead = Math.ceil(newLead * 4) / 4;
    } else {
      newLead = this.planStep.projectStep.lead;
    }

    if (!Utils.isEmpty(newLag)) {
      newLag = Math.ceil(newLag * 4) / 4;
    } else {
      newLag = this.planStep.projectStep.lag;
    }

    // First we need to get the !!!Project!!! NOT Plan...step
    // All info must be passed in, and this is th easiest way to get the proper format.
    this.projectStepService.getProjectStep(this.planStep.projectStepId).pipe(takeUntil(this.destroyed$)).subscribe(
      step => {
        step.crewSize = newCrewSize;
        step.durationHours = newDuration;
        step.stepName = newStepName;
        step.lead = newLead;
        step.lag = newLag;

        // update old info with new info - we'll put it back if there is a failure.
        // This is done early to prevent it looking like 'there was a change suddenly'...since there is no notification of a success
        this.planStep.projectStep.durationHours = step.durationHours;
        this.planStep.projectStep.durationDays = Utils.convertHoursToDays(step.durationHours);
        this.planStep.projectStep.crewSize = step.crewSize;
        this.planStep.projectStep.lead = step.lead;
        this.planStep.projectStep.lag = step.lag;
        this.planStep.name = step.name; // Does not affect generated plan therefore just keep
        this.planStep.stepName = step.stepName; // Does not affect generated plan therefore just keep

        this.projectStepService.updateProjectStep(this.planStep.projectStepId, step).pipe(takeUntil(this.destroyed$)).subscribe(
          stepReult => {
            this.editted.emit();
          },
          err => {
            const context: INoficationContext = {
              type: 'step',
              action: 'update'
            };
            this.notificationService.error(err, context);

            // Since there was a failure updating, we'll reset the values.
            this.planStep.projectStep.durationHours = originalDuration;
            this.planStep.projectStep.crewSize = originalCrewSize;
            this.planStep.projectStep.lead = originalLead;
            this.planStep.projectStep.lag = originalLag;
            this.planStep.stepName = originalStepName;
            this.planStep.name = originalName;
          });
      },
      error => {
        const context: INoficationContext = {
          type: 'step',
          action: 'get'
        };
        this.notificationService.error(error, context);
      });
  }

  updateStepStatus(newStepStatus: number) {
    // If user does not have permisison, DO NOTHING
    if (!this.userHasEditPermission) return;

    const originalStepStatus = this.planStep.stepStatus;
    this.planStep.stepStatus = newStepStatus;

    this.projectStepService.updateProjectStepStatus(this.planStep.projectStepId, { stepStatus: newStepStatus }).pipe(takeUntil(this.destroyed$)).subscribe(
      success => {
        // No need to do anything further
      },
      err => {
        const context: INoficationContext = {
          type: 'step status',
          action: 'update'
        };

        this.notificationService.error(err, context);
        this.planStep.stepStatus = originalStepStatus;
      });
  }

  validStepInfo(): boolean {
    let crewSizeValid = false;
    let durationValid = false;

    // If there is a value, then it should be between 1 and 99
    // No value is considered valid as well
    if ((!Utils.isEmpty(this.newCrewSize) && this.newCrewSize <= 99 && this.newCrewSize >= 1) || Utils.isEmpty(this.newCrewSize)) {
      crewSizeValid = true;
    }

    // Duration needs to be greater than 0
    // No value is also considered valid
    if ((!Utils.isEmpty(this.newDuration) && this.newDuration > 0) || Utils.isEmpty(this.newDuration)) {
      durationValid = true;
    }

    return crewSizeValid && durationValid;
  }

  onImageClosed() {
    this.showImage = false;
  }

  differentValues(val1, val2): boolean {
    if (val1 !== val2) return true;
    return false;
  }

  nextStep(direction: number): void {
    this.curStepIdPos += direction;
    if (this.curStepIdPos >= this.planStepIds.length) {
      this.curStepIdPos = 0;
    } else if (this.curStepIdPos < 0) {
      this.curStepIdPos = this.planStepIds.length - 1;
    }
    this.getProjectStep();
  }
}
