
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account/account.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MenuService } from '../../services/menu/menu.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectUserService } from '../../services/project/project-user/project-user.service';
import { SegmentService } from '../../services/segment/segment.service';
import { UserService } from '../../services/user/user.service';
import { Utils } from '../../utils/utils';

import { IConfirmationModalInput } from '../../models/confirmation-modal/confirmation-modal.interface';
import { IGritTable } from '../../shared/grit-table/grit-table';
import { INoficationContext } from '../../models/notification/notification.interface';
import { IPageHeader } from '../../models/page-header/page-header.interface';
import { IProject } from '../../models/project/project.interface';
import { IUser } from '../../models/user/user.interface';


@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [ProjectUserService]

})
export class UserSettingsComponent implements OnDestroy, OnInit {
  pageHeaderInfo: IPageHeader;
  menuItemSelected: number = 0;
  hasEditPrivilege: boolean = true;
  isLoading: boolean = true;
  projects: IProject[] = [];
  accounts: Account[] = [];
  accountsTableData: IGritTable;
  projectsTableData: IGritTable;
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  message: string = '';
  fileToUpload: any;
  qrCode: string;

  // email prefreneces
  receiveScenarioCompleteEmail: boolean;
  receiveScheduleChangeEmail: boolean;
  receiveModelCompleteEmail: boolean;
  receiveHomeworkReminderEmail: boolean;

  showConfirmationModal: boolean = false;
  confirmationModalInput: IConfirmationModalInput = {
    displayMessage: 'are_you_sure',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true
  };

  mfaToken: string = '';
  showMfaModal: boolean = false;
  mfaModalInput: IConfirmationModalInput = {
    displayMessage: 'multi-factor_authentication_setup',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: true
  };

  // modal input
  toggleModal: boolean = false;
  modalInput: IConfirmationModalInput = {
    displayMessage: 'are_you_sure',
    hasCancelAction: true,
    hasConfirmationAction: true,
    hasDeleteAction: false,
    customContent: false
  };

  // Authentication
  authenticatedUser: IUser;
  authenticatedUserDisplayName: string;
  imageChange: boolean;

  // Subscription
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public userService: UserService,
    private projectUserService: ProjectUserService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    public router: Router,
    public notificationService: NotificationService,
    public authenticationService: AuthenticationService,
    public menuService: MenuService,
  ) { }

  ngOnInit() {
    this.route.parent.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      SegmentService.track('User Settings Loaded', {});
      this.getData();

      this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(qparams => {
        this.menuItemSelected = qparams['menuItem'] || 0;
      });
    });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  setMenuItem(selected: number): void {
    this.isLoading = true;
    this.menuItemSelected = selected;
    this.isLoading = false;
  }

  private getData(): void {
    this.isLoading = true;
    this.pageHeaderInfo = {
      title: {
        value: 'profile_menu_item',
        editable: false
      },
      account: '',
      icon: 'fas fa-user'
    };
    this.projects = [];
    this.accounts = [];
    this.userService.getAuthenticatedUser().pipe(takeUntil(this.destroyed$)).subscribe(async user => {
      this.authenticatedUser = user;
      this.userService.setUserData(user); // TODO Need to use setters and getters more
      this.receiveHomeworkReminderEmail = user.receiveHomeworkReminderEmail;
      this.receiveModelCompleteEmail = user.receiveModelCompleteEmail;
      this.receiveScenarioCompleteEmail = user.receiveScenarioCompleteEmail;
      this.receiveScheduleChangeEmail = user.receiveScheduleChangeEmail;

      if (this.authenticatedUser.avatar) await this.userService.getProfileImg(this.authenticatedUser.id);

      this.authenticatedUserDisplayName = Utils.isEmpty(user.firstName) ? user.email : user.firstName;

      this.getProjectData();
      this.getAccountData();
      this.isLoading = false;
      // tslint:disable-next-line:align
    }, err => {
      // user is not authorized
      this.hasEditPrivilege = false;
      this.isLoading = false;
      this.message = 'forbidden';
      return;
    });
  }

  handleImage(files: FileList): void {
    this.fileToUpload = files[0] as File;
    this.uploadProfileImg();
  }

  uploadProfileImg(): void {
    if (!this.fileToUpload) return;

    const formData = new FormData();
    formData.append('userImage', this.fileToUpload, this.fileToUpload.name);
    this.userService.updateUserAvatar(this.authenticatedUser.id, formData).pipe(takeUntil(this.destroyed$)).subscribe(
      async result => {
        if (result.status === 200 && !Utils.isEmpty(result.body)) {
          this.fileToUpload = null;
          await this.userService.getProfileImg(this.authenticatedUser.id);
        }
        this.toggleModal = false;
      },
      err => {
        this.userService.setUserAvatarImage(null);
        this.toggleModal = false;
        this.isLoading = false;
      }
    );
  }

  deleteProfileImg(): void {
    this.userService.deleteUserAvatar(this.authenticatedUser.id).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.userService.setUserAvatarImage(null);
      },
      err => {
        this.toggleModal = false;
        this.isLoading = false;
      }
    );
    this.toggleModal = false;
  }

  // currently attached to UploadImage only
  toggleModalBox(state?: boolean): void {
    if (state) {
      this.toggleModal = state;
      return;
    }
    this.toggleModal = !this.toggleModal;
  }

  getAccountData(): void {
    this.accounts = Utils.sortByString(this.authenticatedUser.accounts, 'name');
    this.accountsTableData = this.userService.transformAccountsTableData(this.accounts, this.hasEditPrivilege);
  }

  getProjectData(): void {
    this.projects = Utils.sortByString(this.authenticatedUser.projects, 'name');
    this.projectsTableData = this.userService.transformProjectsTableData(this.projects, this.hasEditPrivilege);
  }

  updateUser(): void {
    this.isLoading = true;
    let userUpdateInfo;

    userUpdateInfo = {
      'id': this.authenticatedUser.id,
      'firstName': this.authenticatedUser.firstName,
      'lastName': this.authenticatedUser.lastName,
      'receiveScenarioCompleteEmail': this.receiveScenarioCompleteEmail,
      'receiveScheduleChangeEmail': this.receiveScheduleChangeEmail,
      'receiveModelCompleteEmail': this.receiveModelCompleteEmail,
      'receiveHomeworkReminderEmail': this.receiveHomeworkReminderEmail,
      'viewerZoomScrollDirection': this.authenticatedUser.viewerZoomScrollDirection
    };

    this.userService.updateUser(userUpdateInfo).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        SegmentService.track('User Settings Updated', {});
        this.notificationService.clear();
        this.notificationService.success('SUCCESSFUL_SAVE', null);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.notificationService.error(err);
      });
  }

  changePassword(): void {
    const user = { oldPassword: this.oldPassword, password: this.confirmPassword };

    if (this.confirmPassword !== this.newPassword) {
      this.notificationService.error('app_password_match');
      return;
    }

    if (!Utils.validatePassword(this.confirmPassword) || Utils.isEmpty(this.confirmPassword)) {
      this.notificationService.error('credentials_invalid');
      return;
    }

    this.isLoading = true;
    SegmentService.track('User Password Changed', {});
    this.userService.updatePassword(user).pipe(takeUntil(this.destroyed$)).subscribe(
      response => {
        localStorage.setItem('csrfToken', response.csrfToken);

        this.notificationService.clear();
        this.notificationService.success('APP_PASSWORD_CHANGE_CONFIRM', {});
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        const context: INoficationContext = {
          type: 'password',
          action: 'change'
        };
        this.notificationService.error(err, context);
      });
  }

  deleteAccountRows(rowIds: string[]): void {
    SegmentService.track('User Account Removed', {});
    this.isLoading = true;

    rowIds.forEach(id => {
      this.accountService.deleteAccountUser(id, this.authenticatedUser.id).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          this.isLoading = false;
        },
        (err) => {
          this.notificationService.error(err);
          this.getData();
        });
    });
  }

  deleteProjectRows(rowIds: string[]): void {
    SegmentService.track('User Project Removed', {});
    this.isLoading = true;

    rowIds.forEach(id => {
      this.projectUserService.deleteProjectUsers([this.authenticatedUser.id], id).pipe(takeUntil(this.destroyed$)).subscribe(
        () => {
          this.isLoading = false;
        },
        () => {
          this.notificationService.error('inherited_access');
          this.isLoading = false;
          this.getData();
        });
    });
  }

  setupMfa() {
    this.isLoading = true;
    this.authenticationService.setupMfa().pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.qrCode = res.qr;
        this.showMfaModal = true;
        this.isLoading = false;
      },
      err => {
        this.notificationService.error('error');
        this.isLoading = false;
      }
    );
  }

  removeMfa() {
    this.isLoading = true;
    this.authenticationService.removeMfa().pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.authenticatedUser.hasMfa = false;
        this.isLoading = false;
      },
      () => {
        this.notificationService.error('error');
        this.isLoading = false;
      }
    );
  }

  handleConfirmationConfirmOutput() {
    this.showConfirmationModal = false;
    this.removeMfa();
  }

  submitMfaModal() {
    this.isLoading = true;
    this.authenticationService.verifyMfa(this.mfaToken).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.authenticatedUser.hasMfa = true;
        this.showMfaModal = false;
        this.isLoading = false;
      },
      err => {
        this.notificationService.error(err, null);
        this.isLoading = false;
      }
    );
  }
}
