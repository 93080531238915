import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAccount, IApiToken } from '../../models/account/account.interface';
import { IList, IListType } from '../../models/custom-list/custom-list.interface';
import { IColumnHeader, ICustomTable, IRow, IRowItem } from '../../models/custom-table/custom-table.interface';
import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../shared/grit-table/grit-table';
import { Permission } from '../../utils/enums/permission.enum';
import { ISubContractor } from '../../models/subcontractor/subcontractor.interface';
import { IUser, IUserPermission } from '../../models/user/user.interface';

import { HttpBackendService } from '../http-backend/http-backend.service';

@Injectable()
export class AccountService {
  static userAccountPermission: IUserPermission;
  currentAccount: IAccount;

  constructor(private _http: HttpBackendService) { }

  public getAccountList(): Observable<IAccount[]> {
    return this._http.get('/accounts');
  }

  public getTrials(): Observable<IAccount[]> {
    return this._http.get('/accounts/trials');
  }

  public updateTrial(id: string, trialExpires: any) {
    return this._http.put('/accounts/trials/' + id, {trialExpires: trialExpires && trialExpires.getTime ? trialExpires.getTime() : trialExpires});
  }

  public getAccountById(id: string): Observable<IAccount> {
    return this._http.get('/account/' + id);
  }

  public createAccount(json: any): Observable<IAccount> {
    return this._http.post('/account', json);
  }

  public updateAccountById(id: string, entry: any): Observable<IAccount> {
    return this._http.put('/account/' + id, entry);
  }

  public getAccountNames(accountIds: string[]): Observable<any> {
    const json = { accountIds: accountIds };
    return this._http.post('/account/names', json);
  }

  public getAccountUsers(id: string): Observable<IUser[]> {
    return this._http.get('/account/' + id + '/users');
  }

  public getAccountSubcontractors(id: string): Observable<ISubContractor[]> {
    return this._http.get('/account/' + id + '/subcontractors');
  }

  public updateAccountUser(json: any, userId: string): Observable<any> {
    return this._http.put('/account/user/' + userId, json);
  }

  public deleteAccountUser(accountId: string, userId: string): Observable<any> {
    return this._http.delete('/account/' + accountId + '/user/' + userId);
  }

  public createAccountUser(json: any): Observable<IUser> {
    return this._http.post('/account/user', json);
  }

  public createApiToken(accountId: string, name: string, permission): Observable<IApiToken> {
    return this._http.post('/account/' + accountId + '/tokens', {name, permission});
  }

  public getApiTokens(accountId: string): Observable<IApiToken[]> {
    return this._http.get('/account/' + accountId + '/tokens');
  }

  public updateApiToken(tokenId: string, enabled, permission): Observable<any> {
    return this._http.put('/account/token/' + tokenId, {enabled, permission});
  }

  public removeApiToken(tokenId: string): Observable<any> {
    return this._http.delete('/account/token/' + tokenId);
  }

  public getAccountPermission(accountId: string): Observable<Permission> {
    return this._http.get('/account/' + accountId + '/permission');
  }

  public importProject(accountId: string, file: any, password?: string) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('password', password);
    return this._http.formData('/account/' + accountId + '/projects/import', formData);
  }

  public transformPermissions(permission: number): IUserPermission {
    return {
      admin: permission === 1,
      edit: permission === 1 || permission === 2,
      gc: false
    };
  }

  public getPermissionsOptions(): IList {
    const permissionList: IListType =  {
      key : 'value'
    };

    const customList = {
      title: 'Permissions',
      showDropdown: false,
      searchable: false,
      type: permissionList,
      selectedItem: '',
      list: new Array(),
      pagination: false,
      multiSelect: false
    };

    return customList;
  }

  transformToUserTableData(dataInput: IUser[], permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'email',
        colKey: 'email',
        type: RowItemType.TextIcon
      },
      {
        displayName: 'permission',
        colKey: 'permission',
        type: RowItemType.MatSelect
      },
      {
        displayName: 'has_mfa',
        colKey: 'hasMfa',
        type: RowItemType.Icon,
        width: '20%'
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(user => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'email',
          value: {text: user.email, icon: this.getIcon(user.enabled)},
          editable: false
        },
        {
          colKey: 'permission',
          value: user.accounts[0].permission,
          editable: permission.edit,
          matSelectOptions: [
            { display: 'admin', value: 1 },
            { display: 'edit', value: 2 },
            { display: 'read_only', value: 3 }
          ]
        },
        {
          colKey: 'hasMfa',
          value: this.getMfaIcon(user.hasMfa),
          editable: false,
        }
      ];
      rows.push(
        {
          key: user.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: permission.edit,
        inline: true,
        addModel: [
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'email',
            required: true,
            editable: true,
          },
          {
            type: RowItemType.MatSelect,
            value: 3,
            colKey: 'permission',
            required: false,
            editable: true,
            matSelectOptions: [
              { display: 'admin', value: 1 },
              { display: 'edit', value: 2 },
              { display: 'read_only', value: 3 }
            ]
          }
        ]
      }
    };

    return retTable;
  }

  transformAccountUsersToTableData(users: IUser[], permissionDropdownItems: IDropdownItem[]): ICustomTable {
    // Table
    const transformedTableData: ICustomTable = {
      title: 'User Accounts',
      filterable: false,
      searchable: false,
      columnHeaders: [],
      rows: [],
      multiSelect: true,
      multiSearch: false,
      canCreate: true,
      createRowModel: [],
      pagination: {
        count: users.length,
        pageSize: users.length,
        pageStart: 0,
        display: true
      },
      showIntro: true
    };

    // Column Headers
    const columnHeaders: IColumnHeader[] = [
      {
        title: 'email',
        key: 'email',
        sortable: false,
        active: false,
        searchable: false
      },
      {
        title: 'permission',
        key: 'permission',
        sortable: false,
        active: false,
        searchable: false
      },
      {
        title: 'has_mfa',
        key: 'hasMfa',
        sortable: true,
        active: false,
        searchable: false
      }
    ];
    transformedTableData.columnHeaders = columnHeaders;

    // Table Rows
    const tableRows: IRow[] = [];

    // Create item row
    const createRowModel = [
      {
        name: 'email',
        value: '',
        prevStateValue: '',
        editable: false,
        type: 'input'
      },
      {
        name: 'permission',
        value: [],
        prevStateValue: [],
        editable: true,
        type: 'dropdown',
        staticDropdown: {
          staticDropdownItemsInput: permissionDropdownItems,
          multiSelect: false,
          title: 'Permissions'
        },
        showDropdown: false
      },
      {
        name: 'hasMfa',
        value: '',
        prevStateValue: '',
        editable: false,
        type: 'hidden'
      }
    ];
    transformedTableData.createRowModel = createRowModel;

    // Other Rows
    users.forEach(item => {
      const rowItems: IRowItem[] = [
        {
          value: item.email,
          prevStateValue: item.email,
          editable: false,
          type: 'input',
          icon: this.getIcon(item.enabled)
        },
        {
          name: 'permission',
          value: [item.accounts[0].permission],
          prevStateValue: [item.accounts[0].permission],
          editable: true,
          type: 'dropdown',
          staticDropdown: {
            staticDropdownItemsInput: permissionDropdownItems,
            multiSelect: false,
            title: 'Permissions'
          },
          showDropdown: false
        },
        {
          name: 'hasMfa',
          value: '',
          prevStateValue: '',
          editable: false,
          type: 'input',
          icon: this.getMfaIcon(item.hasMfa)
        }
      ];

      tableRows.push({
        selectable: true,
        editable: true,
        removeable: true,
        active: false,
        rowItems: rowItems,
        key: item.id,
        isEditing: false
      });
    });
    transformedTableData.rows = tableRows;

    return transformedTableData;
  }

  transformToTokenTableData(dataInput: IApiToken[], permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      },
      {
        displayName: 'permission',
        colKey: 'permission',
        type: RowItemType.MatSelect
      },
      {
        displayName: 'key',
        colKey: 'id',
        type: RowItemType.Text
      },
      {
        displayName: 'secret',
        colKey: 'secret',
        type: RowItemType.Text
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(api => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: api.name,
          editable: permission.edit
        },
        {
          colKey: 'permission',
          value: api.permission,
          editable: permission.edit,
          matSelectOptions: [
            { display: 'admin', value: 1 },
            { display: 'edit', value: 2 },
            { display: 'read_only', value: 3 },
            { display: 'disabled', value: 0 }
          ]
        },
        {
          colKey: 'id',
          value: api.id,
          editable: false,
        },
        {
          colKey: 'secret',
          value: api.secret,
          editable: false
        }
      ];
      rows.push(
        {
          key: api.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: permission.edit,
        inline: true,
        addModel: [
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'name',
            required: true,
            editable: true,
          },
          {
            type: RowItemType.MatSelect,
            value: 3,
            colKey: 'permission',
            required: false,
            editable: true,
            matSelectOptions: [
              { display: 'admin', value: 1 },
              { display: 'edit', value: 2 },
              { display: 'read_only', value: 3 },
              { display: 'disabled', value: 0 }
            ]
          }
        ]
      }
    };

    return retTable;
  }

  transformTrialData(dataInput: IAccount[]): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      },
      {
        displayName: 'Trial Expires',
        colKey: 'trialExpires',
        type: RowItemType.Date
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(api => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: api.name,
          editable: false
        },
        {
          colKey: 'trialExpires',
          value: api.trialExpires ? new Date(api.trialExpires) : null,
          editable: true
        }
      ];
      rows.push(
        {
          key: api.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: false,
            rowEdits: api.trialExpires ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : [{type: EditType.InlineEdit}]
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: false,
        inline: true
      }
    };

    return retTable;
  }

  public getAccountOptions(): IList {
    const versionList: IListType =  {
      key : 'id'
    };

    const customList = {
      title: 'Accounts',
      showDropdown: false,
      searchable: false,
      type: versionList,
      selectedItem: '',
      list: new Array(),
      pagination: false,
      multiSelect: false,
      displayNote: new Array(),
    };

    return customList;
  }

  getIcon(enabled: boolean): string {
    if (enabled) return 'fa fa-check green';
    return 'fa fa-clock blue';
  }

  getMfaIcon(enabled: boolean): string {
    if (enabled) return 'fa fa-check green';
    return 'fa fa-times red';
  }
}
