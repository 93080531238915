import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject, Subject  } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../../services/forge/forge-viewer.service';
import { ProjectWorkPackagesService } from '../../../services/project/project-work-packages/project-work-packages.service';

import { ForgeViewerType } from '../../../utils/enums/forge-viewer-type';
import { PopupModalState } from '../../../utils/enums/popup-modal-state.enum';

import { IConfirmationModalInput } from '../../../models/confirmation-modal/confirmation-modal.interface';
import { IWorkPackage } from '../../../models/work-package/work-package.interface';

@Component({
  selector: 'app-project-work-packages',
  templateUrl: './project-work-packages.component.html',
  styleUrls: ['./project-work-packages.component.scss', '../project-planner-form/project-planner-form.component.scss']
})
export class ProjectWorkPackagesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() activityIdInput: string;

  @Output() selectedWorkpackageOutput: EventEmitter<IWorkPackage> = new EventEmitter();
  @Output() kitObjectSelectionOut: EventEmitter<any> = new EventEmitter();
  @Output() prereqObjectSelectionOut: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkPackageOutput: EventEmitter<IWorkPackage> = new EventEmitter();
  @Output() selectOtherPrereqsOutput: EventEmitter<IWorkPackage> = new EventEmitter();

  // Template
  hasFocus: boolean = false;
  wpData: IWorkPackage[];
  newWpName: string;
  isAddingWp: boolean = false;
  showDeleteConfirmationModal: boolean = false;
  deleteWpConfirmationModalInput: IConfirmationModalInput = {
    displayMessage: 'Are you sure you want to permanently delete this work package?',
    hasCancelAction: true,
    hasConfirmationAction: false,
    hasDeleteAction: true,
    customContent: false
  };
  selectedWp: IWorkPackage;
  showModal: boolean = false;
  modalState: PopupModalState = PopupModalState.None;
  popupModalState = PopupModalState;
  formInput: FormGroup;

  private inAddFlow: boolean = false;
  private updateData: Subject<{reset: boolean, emitBack: boolean}> = new Subject();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private projectWorkPackagesService: ProjectWorkPackagesService,
    private forgeViewerService: ForgeViewerService
  ) {}

  async ngOnInit() {
    this.listenersSetup();

    this.updateData.pipe(takeUntil(this.destroyed$)).subscribe(async (options) => {
      if (options.reset === true || !this.selectedWp) {
        await this.getWorkPackages();
        this.setSelectedWpData();
        this.inAddFlow = false;
        if (options.emitBack) this.selectedWorkpackageOutput.emit();
      } else {
        await this.getWorkPackages();
        this.setSelectedWpData(this.selectedWp.id);
        this.inAddFlow = false;
        if (options.emitBack) this.selectedWorkpackageOutput.emit(this.selectedWp);
      }
    });
  }

  async ngOnChanges() {
    if (this.activityIdInput) {
      await this.getWorkPackages();
      this.setSelectedWpData();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keyPress);
    this.projectWorkPackagesService.destroyOpenSubscriptions();

    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  listenersSetup(): void {
    window.addEventListener('keydown', this.keyPress);
  }

  keyPress = (event) => {
    if (!this.hasFocus) return;
    if (event.key === 'Enter') {
      if (this.isAddingWp) this.handleSaveWp();
    }
  }

  async getWorkPackages(): Promise<void> {
    await this.projectWorkPackagesService.setLocalWorkPackages(this.activityIdInput);
    const workPackages = this.projectWorkPackagesService.getLocalWorkPackages();

    this.wpData = workPackages.map(wp => ({
      ...wp,
      editting: false,
      selected: false,
      canEdit: wp.steps.filter(s => s.stepStatus > 1).length === 0
    }));
  }

  handleSaveWp(): void {
    this.setInAddFlow(true);
    const wp: IWorkPackage = {
      name: this.newWpName,
      activityId: this.activityIdInput
    };
    this.projectWorkPackagesService.addWorkPackage(wp).then(
      async res => {
        await this.getWorkPackages();
        this.setSelectedWpData(wp.id);
        this.setAddingWpState(false);
        this.assignObjects(res);
        this.selectedWorkpackageOutput.emit(res);
      }
    ).catch(() => {
      this.setSelectedWpData();
      this.forgeViewerService.clearAllThemingColors();
    });
  }

  overwriteWp(wp: IWorkPackage): void {
    const edittedWp: IWorkPackage = {
      name: wp.name,
      id: wp.id,
      activityId: wp.activityId
    };
    this.projectWorkPackagesService.editWorkPackage(edittedWp).then(
      async () => {
        await this.getWorkPackages();
        this.setSelectedWpData(wp.id);
      }
    ).catch(async () => {
      this.setSelectedWpData();
      this.forgeViewerService.clearAllThemingColors();
    });
  }

  handleWpSelection(wp: IWorkPackage, event: any): void {
    event.stopPropagation();

    if (wp.selected) {
      this.setSelectedWpData();
      this.selectedWorkpackageOutput.emit();
    } else {
      this.setSelectedWpData(wp.id);
      this.selectedWorkpackageOutput.emit(wp);
    }
  }

  wpMenuClick(wp: IWorkPackage, event: any): void {
    if (wp.selected) event.stopPropagation();
  }

  handleWpHover(wp: IWorkPackage): void {
    this.setForgeViewerState(wp);
  }

  handleAddWpClick(): void {
    this.setSelectedWpData();
    this.setEdittingState();
    this.setAddingWpState(!this.isAddingWp);
    this.selectedWorkpackageOutput.emit();
  }

  handleCancelWp(): void {
    this.setInAddFlow(false);
    this.setAddingWpState(false);
  }

  assignObjects(wp: IWorkPackage): void {
    this.setSelectedWpData(wp.id);
    this.kitObjectSelectionOut.emit(wp.id);
  }

  addObjectsToWp(objects: any): void {
    if (this.selectedWp) {
      const edittedWp = {
        name: this.selectedWp.name,
        id: this.selectedWp.id,
        activityId: this.activityIdInput,
        objectIds: Object.keys(objects)
      };

      this.projectWorkPackagesService.editWorkPackage(edittedWp).then(
        async () => {
          await this.getWorkPackages();
          this.setSelectedWpData(this.selectedWp.id);

          if (this.inAddFlow) this.assignPrereqs(edittedWp);
        }
      ).catch(() => {
        this.setSelectedWpData();
      });
    }
  }

  assignPrereqs(wp: IWorkPackage): void {
    this.setSelectedWpData(wp.id);
    this.prereqObjectSelectionOut.emit(wp.id);
  }

  addPreqObjectsToWp(prereqObjects: any): void {
    if (this.selectedWp) {
      const edittedWp = {
        name: this.selectedWp.name,
        id: this.selectedWp.id,
        activityId: this.selectedWp.activityId,
        prerequisiteObjects: prereqObjects
      };

      this.projectWorkPackagesService.editWorkPackage(edittedWp).then(
        async () => {
          await this.getWorkPackages();
          this.setSelectedWpData(this.selectedWp.id);
        }
      ).catch(() => {
        this.setSelectedWpData();
      });
    }
  }

  deleteWp(wp: IWorkPackage): void {
    this.selectedWp = wp;
    this.showDeleteConfirmationModal = true;
  }

  confirmDeleteWpClick(): void {
    this.showDeleteConfirmationModal = false;
    this.projectWorkPackagesService.deleteWorkPackage(this.selectedWp.id).then(
      async () => {
        this.deleteWorkPackageOutput.emit(this.selectedWp);

        await this.getWorkPackages();
        this.setSelectedWpData();
      }
    ).catch(() => {
      this.setSelectedWpData();
    });
  }

  async editWp(wp: IWorkPackage) {
    this.showModal = true;
    this.modalState = PopupModalState.EditWorkPackage;
    this.projectWorkPackagesService.getPotentialPrereqWorkPackages(wp.id).then(res => {
      this.formInput = this.projectWorkPackagesService.buildPrereqFormInput(wp, res);
    });
  }

  handleWpPrereqFormSubmit(wp: IWorkPackage): void {
    this.projectWorkPackagesService.editWorkPackage(wp).then(
      async () => {
        this.closeModal();

        await this.getWorkPackages();
        this.setSelectedWpData(wp.id);
      }
    ).catch(() => {
      this.closeModal();
      this.setSelectedWpData();
    });
  }

  closeModal() {
    this.showModal = false;
    this.modalState = null;
    this.formInput = null;
  }

  closeConfirmationClick(): void {
    this.showDeleteConfirmationModal = false;
  }

  confirmNoObjectsClick(): void {
    this.inAddFlow = false;
  }

  setAddingWpState(isAdding: boolean): void {
    this.newWpName = isAdding ? this.newWpName : null;
    this.isAddingWp = isAdding;
  }

  setEdittingState(wp?: IWorkPackage): void {
    this.wpData.forEach(item => item.editting = false);
    if (wp) wp.editting = true;
  }

  setInAddFlow(inAddFlow: boolean): void {
    this.inAddFlow = inAddFlow;
  }

  setSelectedWpData(wpId?: string): void {
    this.wpData.forEach(item => item.selected = false);
    if (wpId) {
      const selectedWp = this.wpData.find(wp => wp.id === wpId);
      selectedWp.selected = true;
      this.selectedWp = selectedWp;
    } else {
      this.selectedWp = null;
    }
  }

  setForgeViewerState(wp: IWorkPackage): void {
    this.forgeViewerService.clearAllThemingColors();

    if (this.selectedWp && this.selectedWp.objectIds) {
      this.selectedWp.objectIds.forEach(oId => {
        this.forgeViewerService.setState({ gritObjectId: oId, type: ForgeViewerType.Select });
      });

      this.selectedWp.prerequisiteObjects.forEach(o => {
        this.forgeViewerService.setState({ gritObjectId: o.objectId, type: ForgeViewerType.Prereq });

        if (o.kitIds) {
          o.kitIds.forEach(kId => this.forgeViewerService.setState({ gritObjectId: kId, type: ForgeViewerType.Prereq }));
        }
      });

      if (this.selectedWp.prerequisiteWorkPackages) {
        for (const pwp of this.selectedWp.prerequisiteWorkPackages) {
          if (pwp.objectIds && pwp.objectIds.length > 0) {
            pwp.objectIds.forEach(oId => this.forgeViewerService.setState({ gritObjectId: oId, type: ForgeViewerType.Prereq }));
          }
        }
      }
    }

    if (wp && wp.objectIds) {
      wp.objectIds.forEach(oId => {
        this.forgeViewerService.setState({ gritObjectId: oId, type: ForgeViewerType.Hover });
      });
    }
  }
}
