import { Component, HostListener, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fabric } from 'fabric';
import { Utils } from '../../utils/utils';
import { IEvent } from 'fabric/fabric-impl';
import { PDFViewerService } from './../../services/pdf/pdfViewer.service';
import { ProjectService } from './../../services/project/project.service';
import { PDFProgressData, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PageRenderMode } from '../../utils/enums/shared.enum';
import { IRectangleData, IPDFAnnotation, IPDFToolbar } from '../../models/pdf/pdf.interface';
import { NotificationService } from '../../services/notification/notification.service';
import { IActivityAnnotation } from '../../models/pdf/pdf.interface';

import { ViewerMode } from '../../utils/enums/shared.enum';
import { ActivatedRoute, Router } from '@angular/router';

let projectPDFVieweCurrentObject: ProjectPDFViewerComponent;

@Component({
  selector: 'app-project-PDF-viewer',
  templateUrl: './project-PDF-viewer.component.html',
  styleUrls: ['./project-PDF-viewer.component.scss']
})

export class ProjectPDFViewerComponent implements OnInit, OnDestroy {
  @Output() viewerLoadCompleteOutput = new EventEmitter();
  @Output() viewerOutput: EventEmitter<any> = new EventEmitter();
  @Output() hideToolbar: EventEmitter<any> = new EventEmitter();
  @Output() updateToolbarControls: EventEmitter<IPDFToolbar> = new EventEmitter();
  @Output() updateAssignIcons: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('singlePageViewer') singlePageViewer;

  public _pdfFiles: string[] = [];  // Making it public for production deployment.
  public _pdfFilesFiltered: string[] = [];  // To store filtered pdf files
  private _pdfFilesOrignal: string[] = [];
  public _pdfUrlId: number; // Making it public for production deployment.
  private _fitToScreenZoom = "0.4";
  public _twoPageZoom = "0.70";
  public _fourPageZoom = "0.55";
  public _twoPageZoomToggle = "0.50";
  public _fourPageZoomToggle = "0.50";
  private _zoomToggleFactor = 0.1;
  public _zoom = this._fitToScreenZoom;  // Making it public for production deployment.
  private _pdfPageRender: PageRenderMode = PageRenderMode.onePage;
  private _annotationDisplayMode = false;
  private _currentActivityId: string;
  private _pendingAnnot: IPDFAnnotation[];
  private _panDisabled: boolean = true;

  private _isAnnotationLayerPresent = false;
  private poppedout = false;
  // Created for identifying zoom in zoom out fit to screen events for one screen mode.
  private _isReRendered: boolean = false;

  // current fabric canvas : should be only one.
  private _fabricCanvas: fabric.Canvas;

  // current fabric canvas : should be only for column selection and hover mode.
  private _fabricCanvasTwoPage1: fabric.Canvas;
  private _fabricCanvasTwoPage2: fabric.Canvas;


  // current fabric canvas : should be only for column selection and hover mode.
  private _fabricCanvasFourPage1: fabric.Canvas;
  private _fabricCanvasFourPage2: fabric.Canvas;
  private _fabricCanvasFourPage3: fabric.Canvas;
  private _fabricCanvasFourPage4: fabric.Canvas;

  // For storing RectangleData fabric object til confermation 


  // Rect annoation draw
  private _drawAnnoationInProgress: boolean = false;
  private _enableDrawAnnotationPending: boolean = false;
  private _mouseX: number = 0;
  private _mouseY: number = 0;
  private projectId: string;



  //Rushikesh 
  // Page disabling
  disablePage: boolean = false;
  pdfViewerMode: boolean = false;
  projectPDFFiles: String[] = [];
  viewerMode: ViewerMode;
  //rushikesh
  private _selctionMode: boolean = false;
  private _fileterMode: boolean = false;



  constructor(private ngZone: NgZone,
    private pdfViewerService: PDFViewerService,
    private projectService: ProjectService,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    

    if(this.projectService.currentProject){
      // this.pdfViewerService.detatchedWindow = true;
      this.projectId = this.projectService.currentProject.id;
    }
    else 
    {
      if (window.opener.gritParentComponent === null || window.opener.gritParentComponent.childWindow === null) {
        // this.onClose();
        window.close();

      }
      else {
        this.pdfViewerService = window.opener.gritParentComponent.pdfViewerService;
        this.projectService = window.opener.gritParentComponent.projectService;
        this.pdfViewerMode = true;
        this.projectService.viewerMode = ViewerMode.PDFViewer;
        window.opener.gritParentComponent.pdfViewerService.detatchedWindow = true;
      }
    }  
    projectPDFVieweCurrentObject = this;
    this.pdfViewerService.setPDFViewer(this);
    this._pdfFiles = this.projectService.getLocalFloorPlanPDFs();
    this.loadPDF(this._pdfFiles);
    if (this.projectService.showProjectSidebar)
      this.reloadPDF(this.projectService.showProjectSidebar);

    this.projectId = this.projectService.currentProject.id,
    this.pdfViewerService.pdfLoadingComplete = true;
    this.pdfViewerService.getAnnotations(this.projectService.currentProject.id);
  }

  public resetPDFViewer() {
    this._fitToScreenZoom = "0.4";
    this._zoom = this._fitToScreenZoom;
    this._pdfPageRender = PageRenderMode.onePage;
    this.updateAssignIcons.emit(true);

    if (this.projectService.showProjectSidebar) {
      this._zoom = String(Number(this._zoom));
      this._fitToScreenZoom = String(Number(this._fitToScreenZoom) + this._zoomToggleFactor);
    }

    this._pendingAnnot = null;

    this.loadPDF(this._pdfFiles);
    // reset _fabricCanvas of all
    if (this._fabricCanvas)
      this.removeAllFabricElementFor(this._fabricCanvas);

    this.cleanFabricCanvas();
    this.updateToolbar();
  }

  resizeTimeout: any;
  @HostListener('window:resize')
  onWindowResize() {
    //debounce resize, wait for resize to finish before doing stuff
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      var pdfCanvas = document.getElementById("page1");

      if (this._fabricCanvas) {
        this._fabricCanvas.setHeight(pdfCanvas.clientHeight);
        this._fabricCanvas.setWidth(pdfCanvas.clientWidth);
        this._fabricCanvas.setZoom(parseFloat(this._zoom));
      }
    }).bind(this), 500);
  }


  // Accepts array of PDF to be loaded 
  public loadPDF(pdfFiles: string[]) {
    this._pdfFiles = pdfFiles;
    this._pdfFilesOrignal = pdfFiles;
    if (this._pdfFiles.length > 0) {
      this._pdfUrlId = 0;
    } else {
    }
  }

  ngOnDestroy() {
    this.removeAllFabricElements();
  }

  @HostListener('dblclick', ['$event']) onDoubleClick(e: MouseEvent) {

    if (e.ctrlKey) {
      this.zoomOut();
    }
    else {
      this.zoomIn();
    }
  }

  // Decreses current page zoom by 0.1
  // Chnages page view mode
  public zoomOut() {

    if (this._drawAnnoationInProgress)
      return;

    let pointNum = Number(this._zoom);

    pointNum = Math.floor((pointNum - 0.2) * 100) / 100;

    if (pointNum >= 0.4) {
      this._zoom = String(pointNum);
      this._isReRendered = true;
    }
    else {
      this._zoom = this._fitToScreenZoom;
      if (this._pdfPageRender === PageRenderMode.onePage) {
        this._pdfPageRender = PageRenderMode.twoPage;
        this.updateAssignIcons.emit(false);
      }
      else if (this._pdfPageRender === PageRenderMode.twoPage) {
        this._pdfPageRender = PageRenderMode.fourPage;
        this.updateAssignIcons.emit(false);
      }
    }
  }

  // Increses current page zoom by 0.1
  // Chnages page view mode if zoom value is less than minimum value
  public zoomIn() {
    if (this._drawAnnoationInProgress)
      return;
    if (this._pdfPageRender === PageRenderMode.fourPage) {
      this._pdfPageRender = PageRenderMode.twoPage;
      this.updateAssignIcons.emit(false);
    }
    else if (this._pdfPageRender === PageRenderMode.twoPage) {
      this.fitToScreen();
    } else {
      // this.isAnnotationLayerPresent = false;
      let pointNum = Number(this._zoom);
      pointNum = Math.floor((pointNum + 0.2) * 100) / 100;
      if (pointNum < 2) {
        this._zoom = String(pointNum);
        this._isReRendered = true;
      }
    }
  }

  // Makes current page to _fitToScreenZoom zoom mode.
  public fitToScreen() {
    if (this._pdfPageRender !== PageRenderMode.onePage) {
      this._pdfPageRender = PageRenderMode.onePage
      this.updateAssignIcons.emit(true);
    }
    else {
      this._isReRendered = true;
    }
    this._zoom = this._fitToScreenZoom;
  }

  // soft reload
  public reloadPDF(isVisable?: boolean) {
    let reloadValue = 0.01
    
    if (isVisable) {
      this._zoom = String(Math.floor((Number(this._zoom) + reloadValue) * 100) / 100);
      this._twoPageZoom = String(Math.floor((Number(this._twoPageZoom) - 0.01) * 100) / 100);
      this._fourPageZoom = String(Math.floor((Number(this._fourPageZoom) - 0.01) * 100) / 100);
      this._fitToScreenZoom = String(Math.floor((Number(this._fitToScreenZoom) + reloadValue) * 100) / 100);
    } else {
      this._fitToScreenZoom = String(Math.floor((Number(this._fitToScreenZoom) - reloadValue) * 100) / 100);
      this._zoom = String(Math.floor((Number(this._zoom) - reloadValue) * 100) / 100);
      this._twoPageZoom = String(Math.floor((Number(this._twoPageZoom) + 0.01) * 100) / 100);
      this._fourPageZoom = String(Math.floor((Number(this._fourPageZoom) + 0.01) * 100) / 100);
    }
  }


  // Chnages current page to next page.
  public getNextPage() {

    let nextPageId = 0;
    if (this._pdfPageRender === PageRenderMode.onePage) {
      nextPageId = this._pdfUrlId + 1;

      if (this._pdfFiles.length - 1 >= nextPageId) {
        this._pdfUrlId = nextPageId;
      }
    } else if (this._pdfPageRender === PageRenderMode.twoPage) {
      nextPageId = this._pdfUrlId + 2;

      if (this._pdfFiles.length - 2 >= nextPageId) {
        this._pdfUrlId = nextPageId;
      } else {
        nextPageId = this._pdfFiles.length - 2;
        if (nextPageId >= 0) {
          this._pdfUrlId = nextPageId;
        } else {
          this._pdfUrlId = this._pdfFiles.length - 1;
        }
      }

    } if (this._pdfPageRender === PageRenderMode.fourPage) {

      nextPageId = this._pdfUrlId + 4;
      if (this._pdfFiles.length - 4 >= nextPageId) {
        this._pdfUrlId = nextPageId;
      } else {
        nextPageId = this._pdfFiles.length - 4;
        if (nextPageId >= 0) {
          this._pdfUrlId = nextPageId;
        } else {
          this._pdfUrlId = this._pdfFiles.length - 1;
        }
      }
    }
    this.updateToolbar();
  }

  // Chnages current page to previous page.
  public getPreviousPage() {
    let previosPageId = 0;

    if (this._pdfPageRender === PageRenderMode.onePage) {
      previosPageId = this._pdfUrlId - 1;
      if (previosPageId >= 0) {
        this._pdfUrlId = previosPageId;
      }
    } if (this._pdfPageRender === PageRenderMode.twoPage) {
      previosPageId = this._pdfUrlId - 2;
      if (previosPageId >= 0) {
        this._pdfUrlId = previosPageId;
      } else {
        this._pdfUrlId = 0;
      }
    } if (this._pdfPageRender === PageRenderMode.fourPage) {
      previosPageId = this._pdfUrlId - 4;
      if (previosPageId >= 0) {
        this._pdfUrlId = previosPageId;
      } else {
        this._pdfUrlId = 0;
      }
    }
    this.updateToolbar();
  }

  private setDefaultcanvasContainerProperties(canvasContainer: HTMLElement) {
    canvasContainer.style.position = "absolute";
    canvasContainer.style.margin = "auto";
    canvasContainer.style.top = "0";
    canvasContainer.style.left = "0";
    canvasContainer.style.right = "0";
  }
  // Creates canvas layers for annotaion over pdf files.
  private createAnnotationLayer() {
    let container = document.createElement("canvas");
    container.className = "annotationlayer";
    container.id = "c";
    var pageElement = document.getElementById("singlePageRenderer");
    var pdfCanvas = document.getElementById("page1");
    pageElement.appendChild(container);
    this._fabricCanvas = new fabric.Canvas('c');

    this._fabricCanvas.setHeight(pdfCanvas.clientHeight);
    this._fabricCanvas.setWidth(pdfCanvas.clientWidth);
    this._fabricCanvas.setZoom(parseFloat(this._zoom));

    this.setDefaultcanvasContainerProperties(<HTMLElement>document.getElementsByClassName("canvas-container")[0]);

    this._fabricCanvas.selection = false;
  }

  // Creates canvas layers for annotaion over pdf files.
  private createAnnotationLayerForMultipage(index: number, pageRenderer: string, pdfCanvas: HTMLElement) {

    let containerId = "c" + index;
    let elementId = pageRenderer + index; // "twoPageRenderer"
    let container = document.createElement("canvas");
    container.className = "annotationlayer";
    container.id = containerId;
    var pageElement = document.getElementById(elementId);
    pageElement.appendChild(container);

    let fabricCanvas = new fabric.Canvas(containerId);
    fabricCanvas.setHeight(pdfCanvas.clientHeight);
    fabricCanvas.setWidth(pdfCanvas.clientWidth);
    fabricCanvas.setZoom(parseFloat(this._zoom));
    let canvasContainerElement = pageElement.children[1] as HTMLElement;
    canvasContainerElement.style.width = pdfCanvas.clientWidth + 'px';
    canvasContainerElement.style.height = pdfCanvas.clientHeight + 'px';
    this.setDefaultcanvasContainerProperties(pageElement.children[1] as HTMLElement);
    fabricCanvas.selection = false;

    return fabricCanvas;
  }

  // Enable's draw annoatation 
  public enableDrawAnnotation(activityId: string) {

    this._currentActivityId = activityId;
    this.hideToolbar.emit(true);
    this._enableDrawAnnotationPending = false;
    if (this._pdfPageRender === PageRenderMode.onePage) {

      if (!this._fabricCanvas) {
        return;
      }

      //  Reset Zoom to deafult 
      this._fabricCanvas.discardActiveObject();
      this._fabricCanvas.off('mouse:down');
      this._fabricCanvas.off('mouse:move');
      this._fabricCanvas.off('mouse:up');
      this._fabricCanvas.on('mouse:down', (e: fabric.IEvent) => { this.startDraw(e) });
      this._fabricCanvas.on('mouse:move', (e: fabric.IEvent) => { this.continueDraw(e) });
      this._fabricCanvas.on('mouse:up', (e: fabric.IEvent) => { this.endDraw(e) });
      this.removeAllFabricElementFor(this._fabricCanvas);
      this._fabricCanvas.defaultCursor = "crosshair";
      for (var obj of this._fabricCanvas._objects) {
        obj.selectable = false;
      }
      this._fabricCanvas.renderAll();

    }
    else {
      this._enableDrawAnnotationPending = true;
    }

  }

  // Creates fabric.Rect
  private createFabricRect(x: number, y: number, color: string, opacity: number, lock: boolean, width?: number, height?: number): fabric.Rect {
    return new fabric.Rect({
      width: width ? width : 0,
      height: height ? height : 0,
      left: x,
      top: y,
      fill: Utils.hexToRgbA(color, opacity),
      strokeWidth: 2,
      stroke: color,
      opacity: 1,
      lockRotation: true,
      lockMovementX: lock,
      lockMovementY: lock,
      lockScalingX: lock,
      lockScalingY: lock,
      lockScalingFlip: lock,
      selectable: true
    });
  }

  /* Gets called from mouse down if DrawAnnotation is enabled */
  private startDraw(event: IEvent) {

    if (!this._fabricCanvas) {
      return;
    }

    var mouse = this._fabricCanvas.getPointer(event.e);

    this._mouseX = mouse.x;
    this._mouseY = mouse.y;

    this._drawAnnoationInProgress = true;
    let square = this.createFabricRect(this._mouseX, this._mouseY, this.pdfViewerService.getSubcontractornColor(this._currentActivityId), 0.3, false);

    this._fabricCanvas.add(square);
    this._fabricCanvas.renderAll();
    this._fabricCanvas.setActiveObject(square);

  }

  /* Gets called from mouse move if DrawAnnotation is enabled */
  private continueDraw(event: IEvent) {

    if (!this._drawAnnoationInProgress) {
      return false;
    }

    var mouse = this._fabricCanvas.getPointer(event.e);

    let width = Math.abs(mouse.x - this._mouseX);
    let height = Math.abs(mouse.y - this._mouseY);

    if (!width || !height) {
      return false;
    }

    var square = this._fabricCanvas.getActiveObject();
    square.set('width', width).set('height', height);
    this._fabricCanvas.renderAll();

  }

  /* Gets called from Mouseup if DrawAnnotation is enabled */
  private endDraw(event: IEvent) {

    if (this._drawAnnoationInProgress) {
      this._drawAnnoationInProgress = false;

    } else {
      return;
    }

    var mouse = this._fabricCanvas.getPointer(event.e);
    let width = Math.abs(mouse.x - this._mouseX);
    let height = Math.abs(mouse.y - this._mouseY);
    var square = this._fabricCanvas.getActiveObject();
    square.set('width', width).set('height', height);

    // this._fabricCanvas.discardActiveObject();
    this._fabricCanvas.renderAll();
    this.endDrawRectangularAnnotation();
  }

  // Create minimum data for storing annotation in IRectangleData
  private createRowIRectangleData(square: fabric.Rect): IRectangleData {
    return {
      width: Utils.annotationSetFactor(square.width, this._fabricCanvas.getWidth(), this._zoom),
      height: Utils.annotationSetFactor(square.height, this._fabricCanvas.getHeight(), this._zoom),
      left: Utils.annotationSetFactor(square.left, this._fabricCanvas.getWidth(), this._zoom),
      top: Utils.annotationSetFactor(square.top, this._fabricCanvas.getWidth(), this._zoom)
    }
  }

  private createAnnoationData(square: fabric.Object[]): IRectangleData[] {
    let rectangleDataArray: IRectangleData[] = [];

    square.forEach(element => {
      rectangleDataArray.push(this.createRowIRectangleData(element as fabric.Rect));
    });

    return rectangleDataArray;
  }

  // Create minimum data for storing annotation in IPDFAnnotation
  private createIPDFAnnotation(): IPDFAnnotation | null {
    let rect = this.createAnnoationData(this._fabricCanvas.getObjects())

    if (rect.length === 0)
      return null

    let URL = "";
    if(this._pdfFiles[this._pdfUrlId].indexOf("blob:")!=-1){
      URL = this.projectService.getBIM360ExactURL(this._pdfFiles[this._pdfUrlId]);
    }
    else{
      URL = this._pdfFiles[this._pdfUrlId];
    }

    let pdfannoation: IPDFAnnotation = {
      pdfId: URL, // Insted of url it will be some id
      annotationData: rect
    }
    return pdfannoation;
  }

  // Create minimum data for storing annotation in IActivityAnnotation
  private createRowAnnoatationData(): IActivityAnnotation | null {

    let pdfannoation = this.createIPDFAnnotation();

    if (!pdfannoation)
      return null;

    let activityAnnotation: IActivityAnnotation = {
      activityId: this._currentActivityId,
      projectId: this.projectService.currentProject.id,
      annotation: [pdfannoation]
    }
    return activityAnnotation;
  }

  // Updates the annoatation data for activty
  private updateRowAnnoatationData(activityAnnotationData: IActivityAnnotation) {

    let storedAnnotationdata = activityAnnotationData.annotation;
    let pdfannoation = this.createIPDFAnnotation();

    let currrentActivityAnnotation = storedAnnotationdata.find(element => element.pdfId === pdfannoation.pdfId);

    if (currrentActivityAnnotation) {
      currrentActivityAnnotation.annotationData.push(...pdfannoation.annotationData);
    } else {
      storedAnnotationdata.push(pdfannoation);
    }
  }

  // Ends and stores annotations 
  public endAndSaveAnnotation() {

    let currrentActivityAnnotation = this.pdfViewerService.activtyAnnoations.find(element => element.activityId == this._currentActivityId);
    // if annoation data is not present for current activity then create data from scratch.
    if (currrentActivityAnnotation) {
      this.updateRowAnnoatationData(currrentActivityAnnotation);
    } else {
      let newAnnotation = this.createRowAnnoatationData();

      if (!newAnnotation) {
        this.removeAllFabricElementFor(this._fabricCanvas);
        this._fabricCanvas.defaultCursor = "default";
        this.hideToolbar.emit(false);
        return;
      }

      this.pdfViewerService.activtyAnnoations.push(newAnnotation);
    }
    // Call serive -> service will call databse
    this.pdfViewerService.updateAnnotation(this.projectService.currentProject.id, this.pdfViewerService.activtyAnnoations).subscribe(
      res => {
        this.notificationService.success("ANNOTATION_ASSIGNED", {});
      },
      err => {
        this.notificationService.error("ERROR_ANNOTATION_ASSIGN", {});
      }
    );

    this.removeAllFabricElementFor(this._fabricCanvas);
    this._fabricCanvas.defaultCursor = "default";

    this.hideToolbar.emit(false);
  }

  // remove mouse:down | move | up events and stopannotation draw oprations. 
  public endDrawRectangularAnnotation() {
    this._drawAnnoationInProgress = false;
    this._mouseX = 0;
    this._mouseY = 0;

    if (this._fabricCanvas) {
      this._fabricCanvas.discardActiveObject();
      this._fabricCanvas.off('mouse:down');
      this._fabricCanvas.off('mouse:move');
      this._fabricCanvas.off('mouse:up');
    }
  }

  // cancel storing of fabric object.
  public cancelDrawAnnotation() {
    this._currentActivityId = "";
    this.endDrawRectangularAnnotation();
    this.removeAllFabricElementFor(this._fabricCanvas);
    this.hideToolbar.emit(false);

    if (this._fabricCanvas)
      this._fabricCanvas.defaultCursor = 'default';
  }

  // cancel storing of fabric object.
  public updateDrawAnnotation() {
    this.pdfViewerService.getAnnotations(this.projectService.currentProject.id);
    this.cancelDrawAnnotation();
  }

  // Removes all fabric elements 
  private removeAllFabricElementFor(fabricCanvas: fabric.Canvas) {
    if (fabricCanvas)
      fabricCanvas.remove(...fabricCanvas.getObjects());
  }

  // Will remove FabricCanvas that are no longer required. 
  private cleanFabricCanvas() {

    if (this._pdfFiles.length <= 0) {
      this.destroyfabricCanvas(this._fabricCanvas);
      this.destroyfabricCanvas(this._fabricCanvasTwoPage1);
      this.destroyfabricCanvas(this._fabricCanvasTwoPage2);
      this.destroyfabricCanvas(this._fabricCanvasFourPage1);
      this.destroyfabricCanvas(this._fabricCanvasFourPage2);
      this.destroyfabricCanvas(this._fabricCanvasFourPage3);
      this.destroyfabricCanvas(this._fabricCanvasFourPage4);
      this._fabricCanvas = this._fabricCanvasTwoPage1 = this._fabricCanvasTwoPage2 = this._fabricCanvasFourPage1 = this._fabricCanvasFourPage2 = this._fabricCanvasFourPage3 = this._fabricCanvasFourPage4 = null;
      return;
    }

    if (this._pdfPageRender === PageRenderMode.onePage) {
      this.destroyfabricCanvas(this._fabricCanvasTwoPage1);
      this.destroyfabricCanvas(this._fabricCanvasTwoPage2);
      this.destroyfabricCanvas(this._fabricCanvasFourPage1);
      this.destroyfabricCanvas(this._fabricCanvasFourPage2);
      this.destroyfabricCanvas(this._fabricCanvasFourPage3);
      this.destroyfabricCanvas(this._fabricCanvasFourPage4);
      this._fabricCanvasTwoPage1 = this._fabricCanvasTwoPage2 = this._fabricCanvasFourPage1 = this._fabricCanvasFourPage2 = this._fabricCanvasFourPage3 = this._fabricCanvasFourPage4 = null;
      return;
    }
    if (this._pdfPageRender === PageRenderMode.twoPage) {
      this.destroyfabricCanvas(this._fabricCanvas);
      this.destroyfabricCanvas(this._fabricCanvasFourPage1);
      this.destroyfabricCanvas(this._fabricCanvasFourPage2);
      this.destroyfabricCanvas(this._fabricCanvasFourPage3);
      this.destroyfabricCanvas(this._fabricCanvasFourPage4);
      this._fabricCanvas = this._fabricCanvasFourPage1 = this._fabricCanvasFourPage2 = this._fabricCanvasFourPage3 = this._fabricCanvasFourPage4 = null;
      return;
    }
    if (this._pdfPageRender === PageRenderMode.fourPage) {
      this.destroyfabricCanvas(this._fabricCanvas);
      this.destroyfabricCanvas(this._fabricCanvasTwoPage1);
      this.destroyfabricCanvas(this._fabricCanvasTwoPage2);
      this._fabricCanvas = this._fabricCanvasTwoPage1 = this._fabricCanvasTwoPage2 = null;
      return;
    }

  }

  // Will remove fabric canvas object
  private destroyfabricCanvas(fabricCanvas: fabric.Canvas) {
    if (fabricCanvas) {
      fabricCanvas.dispose();
    }
    fabricCanvas = null;
  }

  /* Callback funtctions start --------------*/
  onProgressCallBackFn(progressData: PDFProgressData) {
  }

  pdfLoadCallBackFn(pdf: PDFDocumentProxy) {
  }


  pageRenderedCallBackFn(e: any) {
    this.cleanFabricCanvas();

    if (!this._fabricCanvas) {
      this.createAnnotationLayer();
      this.pdfViewerService.pdfLoadingComplete = true;
    } else {
      var pdfCanvas = document.getElementById("page1");
      this._fabricCanvas.setZoom(parseFloat(this._zoom));
      this._fabricCanvas.setHeight(pdfCanvas.clientHeight);
      this._fabricCanvas.setWidth(pdfCanvas.clientWidth);
    }

    if (this._selctionMode && !this._isReRendered && this._pendingAnnot) {
      this.removeAllFabricElementFor(this._fabricCanvas);
      let pdfUrl = e.source.annotationLayerFactory.pdfDocument._transport._params.url;

      this._pendingAnnot.forEach(element => {
        if(element.pdfId.indexOf("https://developer.api.autodesk.com") != -1)
          element.pdfId = this.projectService.getBlobUrlOfBIm360(element.pdfId);
        if(element.pdfId == this._pdfFiles[this._pdfUrlId])
        this.drawAnnotatiOnCanvas(element, true, this._fabricCanvas);
      });
    }

    if (this._isReRendered) {
      // reset _isReRendered for opartions 
      this._isReRendered = false;
    }

    this.removeOverflowPDFContainer();
    this.isScrollAvailable();
    this.updateToolbar();
  }

  // Will work for twoPageRenderer
  // Will resume drawing annotations for two page pdf mode
  private onTwoPagePDFLoad(e: any, fabricCanvas: fabric.Canvas, index: number): fabric.Canvas {

    // if (!fabricCanvas)
    //   fabricCanvas = this.createAnnotationLayerForMultipage(index, "twoPageRenderer", e.source.div);
    // else {
    //   fabricCanvas.setHeight(e.source.height);
    //   fabricCanvas.setWidth(e.source.width);
    // }

    fabricCanvas = this.createAnnotationLayerForMultipage(index, "twoPageRenderer", e.source.div);
    // fabricCanvas.setHeight(e.source.height);
    // fabricCanvas.setWidth(e.source.width);

    let fixedAnnotation = true;
    if (this._selctionMode && this._pendingAnnot) {
      this.removeAllFabricElementFor(fabricCanvas);

      let pdfUrl = e.source.annotationLayerFactory.pdfDocument._transport._params.url;

      this._pendingAnnot.forEach(element => {
        if (element.pdfId === pdfUrl) {
          this.drawAnnotatiOnCanvas(element, fixedAnnotation, fabricCanvas);
        }
        return;
      });
    }
    this.removeExtraAnnotations();
    return fabricCanvas;
  }

  // Will work for fourPageRenderer
  // Will resume drawing annotations for four page pdf mode
  private onFourPagePDFLoad(e: any, fabricCanvas: fabric.Canvas, index: number): fabric.Canvas {
    // if (!fabricCanvas)
    //   fabricCanvas = this.createAnnotationLayerForMultipage(index, "fourPageRenderer", e.source.div);
    // else {
    //   fabricCanvas.setHeight(e.source.height);
    //   fabricCanvas.setWidth(e.source.width);
    // }

    fabricCanvas = this.createAnnotationLayerForMultipage(index, "fourPageRenderer", e.source.div);
    // fabricCanvas.setHeight(e.source.height);
    // fabricCanvas.setWidth(e.source.width);

    let fixedAnnotation = true;
    if (this._selctionMode && this._pendingAnnot) {
      this.removeAllFabricElementFor(fabricCanvas);
      let pdfUrl = e.source.annotationLayerFactory.pdfDocument._transport._params.url;
      this._pendingAnnot.forEach(element => {
        if (element.pdfId === pdfUrl) {
          this.drawAnnotatiOnCanvas(element, fixedAnnotation, fabricCanvas);
        }
        return;
      });
    }
    this.removeExtraAnnotations();
    return fabricCanvas;
  }
  removeExtraAnnotations(){
    var elements: any = document.getElementsByTagName("canvas");
    var uniqueIds = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
       if(element.className === 'annotationlayer') {
         element.remove();
        //  if(uniqueIds.includes(element.id)){
        //    element.remove();
        //  } else{
        //    uniqueIds.push(element.id);
        //  }
       }
    }
  }

  twoPageRenderedCallBackFn1(e: any) {
    var x = document.getElementById('pdfPage1');
    e.source.renderingQueue.pdfViewer.currentScaleValue = x.clientWidth/4031.999999999999;
    this.cleanFabricCanvas();
    this._fabricCanvasTwoPage1 = this.onTwoPagePDFLoad(e, this._fabricCanvasTwoPage1, 0);
    this.updateToolbar();
  }

  called = true;
  twoPageRenderedCallBackFn2(e: any) {
    var x = document.getElementById('pdfPage1');
    e.source.renderingQueue.pdfViewer.currentScaleValue = x.clientWidth/4031.999999999999;
    this.cleanFabricCanvas();
    this._fabricCanvasTwoPage2 = this.onTwoPagePDFLoad(e, this._fabricCanvasTwoPage2, 1);
    this.updateToolbar();
  }

  fourPageRenderedCallBackFn1(e: any) {
    var x = document.getElementById('fourPageRenderer0');
    e.source.renderingQueue.pdfViewer.currentScaleValue = (x.clientWidth*0.8)/4031.999999999999;
    this.cleanFabricCanvas();
    this._fabricCanvasFourPage1 = this.onFourPagePDFLoad(e, this._fabricCanvasFourPage1, 0);
    this.updateToolbar();
  }

  fourPageRenderedCallBackFn2(e: any) {
    var x = document.getElementById('fourPageRenderer0');
    e.source.renderingQueue.pdfViewer.currentScaleValue = (x.clientWidth*0.8)/4031.999999999999;
    this.cleanFabricCanvas();
    this._fabricCanvasFourPage2 = this.onFourPagePDFLoad(e, this._fabricCanvasFourPage2, 1);
    this.updateToolbar();
  }

  fourPageRenderedCallBackFn3(e: any) {
    var x = document.getElementById('fourPageRenderer0');
    e.source.renderingQueue.pdfViewer.currentScaleValue = (x.clientWidth*0.8)/4031.999999999999;
    this.cleanFabricCanvas();
    this._fabricCanvasFourPage3 = this.onFourPagePDFLoad(e, this._fabricCanvasFourPage3, 2);
    this.updateToolbar();
  }

  fourPageRenderedCallBackFn4(e: any) {
    var x = document.getElementById('fourPageRenderer0');
    e.source.renderingQueue.pdfViewer.currentScaleValue = (x.clientWidth*0.8)/4031.999999999999;
    this.cleanFabricCanvas();
    this._fabricCanvasFourPage4 = this.onFourPagePDFLoad(e, this._fabricCanvasFourPage4, 3);
    this.updateToolbar();
  }

  // callback end -------------------

  // Will check and return PageRenderMode to html
  // This function (_pdfPageRender) will decide the PageRenderMode for PDF view
  public checkPageRender(type: PageRenderMode) {
    if (type == this._pdfPageRender) {
      return true;
    }
  }

  // Will update the _pdfUrlId for showing PDF in one page render mode.
  // Will allow DrawAnnotation if it's pending.
  loadWithOnePageRender(id: number) {

    this.fitToScreen();
    this._pdfUrlId = id;
    if (this._enableDrawAnnotationPending) {
      this.enableDrawAnnotation(this._currentActivityId);
    }
  }

  // Annotation manupulation.-------------------------------------------------

  // Draw Annottion on pdf 
  // will be used for highlight | select } play button funtionality. 

  private drawAnnotatiOnCanvas(annotation: IPDFAnnotation, fixedAnnotation: boolean, fabricCanvas: fabric.Canvas) {
    annotation.annotationData.forEach(element => {
      fabricCanvas.setZoom(parseFloat(this._zoom));
      let square = this.createFabricRect(
        Utils.annotationGetFactor(element.left, fabricCanvas.getWidth(), this._zoom), Utils.annotationGetFactor(element.top, fabricCanvas.getWidth(), this._zoom),
        element.color ? element.color : this.pdfViewerService.gethowerHeighlightColor(), 0.5, fixedAnnotation,
        Utils.annotationGetFactor(element.width, fabricCanvas.getWidth(), this._zoom), Utils.annotationGetFactor(element.height, fabricCanvas.getHeight(), this._zoom)
      );
      fabricCanvas.add(square);
    });
    fabricCanvas.renderAll();
  }

  // Funtion will draw annoations on FourPage PageRenderMode
  // if PageRenderMode is not FourPage then it will set PageRenderMode to FourPage and then callback funtions will draw the PDf annotation.
  private drawAnnotation(annotation: IPDFAnnotation[]) {


    let fixedAnnotation = true;
    let newPdfFilesArray = []
    annotation.forEach(element => {
      if(element.pdfId.indexOf("https://developer.api.autodesk.com") != -1)
        newPdfFilesArray.push(this.projectService.getBlobUrlOfBIm360(element.pdfId));
      else
        newPdfFilesArray.push(element);
    });

    let oldPDFUrl = [];
    this._pdfFiles.forEach(element => {
      if(element.indexOf('blob:')!= -1)
        oldPDFUrl.push(this.projectService.getBIM360ExactURL(element));
      else
        oldPDFUrl.push(element);
    });

    let oldPDFUrlId = this._pdfUrlId;
    this._pdfFilesFiltered = newPdfFilesArray;
    if (this._fileterMode) {
      this._pdfFiles = this._pdfFilesFiltered;
      this._pdfUrlId = 0;

      if (this._pdfFiles.length === 0) {
        this.cleanFabricCanvas();
      }
    } else {
      // Show all pdf as it is.
      // Dont reset the _pdfFiles as _fileterMode is off
    }

    //this.pdfViewerService.pdfLoadingComplete = false;

    // If PDF mode is fourPage then remove elements form exsisting 4 page fabric canvas and redraw it.
    if (this._pdfPageRender === PageRenderMode.fourPage) {

      this.removeAllFabricElementFor(this._fabricCanvasFourPage1);
      this.removeAllFabricElementFor(this._fabricCanvasFourPage2);
      this.removeAllFabricElementFor(this._fabricCanvasFourPage3);
      this.removeAllFabricElementFor(this._fabricCanvasFourPage4);

      if (!this._pdfFiles[0]) {
        this.destroyfabricCanvas(this._fabricCanvasFourPage1);
        this._fabricCanvasFourPage1 = null;
      }

      if (!this._pdfFiles[1]) {
        this.destroyfabricCanvas(this._fabricCanvasFourPage2);
        this._fabricCanvasFourPage2 = null;
      }

      if (!this._pdfFiles[2]) {
        this.destroyfabricCanvas(this._fabricCanvasFourPage3);
        this._fabricCanvasFourPage3 = null;
      }

      if (!this._pdfFiles[3]) {
        this.destroyfabricCanvas(this._fabricCanvasFourPage4);
        this._fabricCanvasFourPage4 = null;
      }

      // Added checks for below calls becouse there is possiblity that pdf is not present.
      if (this._fabricCanvasFourPage1) {
        annotation.forEach(element => {
          if (element.pdfId === oldPDFUrl[oldPDFUrlId])  
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvasFourPage1);
        });
      }
      if (this._fabricCanvasFourPage2) {
        annotation.forEach(element => {
          if (element.pdfId === oldPDFUrl[oldPDFUrlId+1])  
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvasFourPage2);
        });
      }
      if (this._fabricCanvasFourPage3) {
        annotation.forEach(element => {
          if (element.pdfId === oldPDFUrl[oldPDFUrlId+2])  
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvasFourPage3);
        });
      }
      if (this._fabricCanvasFourPage4) {
        annotation.forEach(element => {
          if (element.pdfId === oldPDFUrl[oldPDFUrlId+3])  
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvasFourPage4);
        });
      }
    } else if (this._pdfPageRender === PageRenderMode.twoPage) {

      if (!this._pdfFiles[0]) {
        this.destroyfabricCanvas(this._fabricCanvasTwoPage1);
        this._fabricCanvasTwoPage1 = null;
      }

      if (!this._pdfFiles[1]) {
        this.destroyfabricCanvas(this._fabricCanvasTwoPage2);
        this._fabricCanvasTwoPage2 = null;
      }

      this.removeAllFabricElementFor(this._fabricCanvasTwoPage1);
      this.removeAllFabricElementFor(this._fabricCanvasTwoPage2);
      if (this._fabricCanvasTwoPage1) {
        annotation.forEach(element => {
          if (element.pdfId === oldPDFUrl[oldPDFUrlId])  
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvasTwoPage1);
        });
      }

      if (this._fabricCanvasTwoPage2) {
        annotation.forEach(element => {
          if (element.pdfId === oldPDFUrl[oldPDFUrlId+1])  
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvasTwoPage2);
        });
      }

    } else if (this._pdfPageRender === PageRenderMode.onePage) {
      this.removeAllFabricElementFor(this._fabricCanvas);
      if (this._fabricCanvas) {
        annotation.forEach(element => {
          if(element.pdfId === oldPDFUrl[oldPDFUrlId])
            this.drawAnnotatiOnCanvas(element, fixedAnnotation, this._fabricCanvas);
        });
      }

    }
  }

  // Funtion will draw annoations on Onepage PageRenderMode
  // if PageRenderMode is not Onepage then it will set PageRenderMode to Onepage and then callback funtions will draw the PDf annotation.
  private prepareAndDrawAnnotationOnPDF(annotation: IPDFAnnotation[]): boolean {

    this._annotationDisplayMode = true;
    let newPdfFileIndexArray = [];
    let newPdfFilesMap: IPDFAnnotation[] = [];
    let ExactPDFFiles = [];
    this._pdfFiles.forEach(element => {
      if(element.indexOf('blob:')!= -1)
        ExactPDFFiles.push(this.projectService.getBIM360ExactURL(element));
      else
        ExactPDFFiles.push(element);
    });
    annotation.forEach(element => {
      let tempPdfUrlId = ExactPDFFiles.indexOf(element.pdfId);
      if (tempPdfUrlId >= 0) {
        newPdfFileIndexArray.push(tempPdfUrlId);
        newPdfFilesMap.push(element);
      }
    });

    if (newPdfFilesMap.length <= 0)
      return false;

    this._pendingAnnot = newPdfFilesMap;

    // Sorting the newPdfFilesMap to make the sequence consistent.  
    let newPdfFileIndexArraySorted = [];
    let newPdfFilesMapSorted = [];
    let inserted;
    for (var i = 0, ii = newPdfFileIndexArray.length; i < ii; i++) {
      inserted = false;
      for (var j = 0, jj = newPdfFileIndexArraySorted.length; j < jj; j++) {

        if (newPdfFileIndexArray[i] < newPdfFileIndexArraySorted[j]) {
          inserted = true;
          newPdfFileIndexArraySorted.splice(j, 0, newPdfFileIndexArray[i]);
          newPdfFilesMapSorted.splice(j, 0, newPdfFilesMap[i]);
          break;
        }
      }

      if (!inserted) {
        newPdfFileIndexArraySorted.push(newPdfFileIndexArray[i])
        newPdfFilesMapSorted.push(newPdfFilesMap[i])
      }
    }

    this.drawAnnotation(newPdfFilesMapSorted);
    this.updateToolbar();
    return true;
  }

  // Funtion will reset the _pdfFiles and _pdfUrlId
  private updateToOrignalFiles() {
    this._pdfUrlId = this._pdfFilesOrignal.findIndex(element => element === this._pdfFiles[this._pdfUrlId]);
    if (this._pdfUrlId < 0) {
      this._pdfUrlId = 0;
    }
    this._pdfFiles = this._pdfFilesOrignal;
  }

  private margeActivityDataAndStoreColor(activityIds: string[]): IPDFAnnotation[] {

    let margedAnnotation: IPDFAnnotation[] = [];

    activityIds.forEach(id => {

      let currrentActivityAnnotation = this.pdfViewerService.activtyAnnoations.find(element => element.activityId == id);
      let color = this.pdfViewerService.getSubcontractornColor(id);

      if (currrentActivityAnnotation) {
        currrentActivityAnnotation.annotation.forEach(annotationElement => {

          // Creating new element for shalow copy issue.
          let newAnnotationElementt: IPDFAnnotation = {
            pdfId: annotationElement.pdfId,
            annotationData: []
          }
          // Add color to all rect.
          annotationElement.annotationData.forEach(dataElement => {

            let newDataElement: IRectangleData = {
              width: dataElement.width,
              height: dataElement.height,
              left: dataElement.left,
              top: dataElement.top,
              color: color
            }
            newAnnotationElementt.annotationData.push(newDataElement);
          });

          let storedIPDFAnnotation = margedAnnotation.find(element => element.pdfId === annotationElement.pdfId);
          if (storedIPDFAnnotation) {
            storedIPDFAnnotation.annotationData.push(...newAnnotationElementt.annotationData);
          } else {
            margedAnnotation.push(newAnnotationElementt);
          }
        });
      } else {

      }
    });

    return margedAnnotation;
  }

  // Highlight the activity annotation if present
  // highlight color is stored in pdf service
  public selectActivities(activityIds: string[]) {
    this.removeAllFabricElements();
    this.updateToOrignalFiles();

    if (this.pdfViewerService.activtyAnnoations && activityIds.length > 0) {
      let margeAnnotation: IPDFAnnotation[] = this.margeActivityDataAndStoreColor(activityIds);

      if (margeAnnotation.length > 0) {
        let returnValue = this.prepareAndDrawAnnotationOnPDF(margeAnnotation);
        if (!returnValue) {
          this.disableViewerForSelction();
        }
      } else {
        this.disableViewerForSelction();
      }
      this.activateSelectionMode();
    }
    else {
      this.deactivateSelectionMode();
    }
  }

  // Disable the viewer if annotations are not present for the selcted activity.
  private disableViewerForSelction() {

    if (this._fileterMode) {
      this._pdfFiles = [];
      this.cleanFabricCanvas();
    }
    this._pendingAnnot = [];
    this._pdfFilesFiltered = [];
    this.updateToolbar();
  }

  // Cleans FabricElements objects. 
  public removeAllFabricElements() {

    if (this._fabricCanvas)
      this.removeAllFabricElementFor(this._fabricCanvas);
    if (this._fabricCanvasTwoPage1)
      this.removeAllFabricElementFor(this._fabricCanvasTwoPage1);
    if (this._fabricCanvasTwoPage2)
      this.removeAllFabricElementFor(this._fabricCanvasTwoPage2);
    if (this._fabricCanvasFourPage1)
      this.removeAllFabricElementFor(this._fabricCanvasFourPage1);
    if (this._fabricCanvasFourPage2)
      this.removeAllFabricElementFor(this._fabricCanvasFourPage2);
    if (this._fabricCanvasFourPage3)
      this.removeAllFabricElementFor(this._fabricCanvasFourPage3);
    if (this._fabricCanvasFourPage4)
      this.removeAllFabricElementFor(this._fabricCanvasFourPage4);
  }

  // Anctivtates selctions mode by setting _selctionMode to true.
  private activateSelectionMode() {
    this._selctionMode = true;
  }


  // Ends selction mode and viewer will be reset to show all PDF present for project.
  private deactivateSelectionMode() {
    this._selctionMode = false;
    this._pendingAnnot = null;
    let index = this._pdfFilesOrignal.findIndex(element => element === this._pdfFiles[this._pdfUrlId]);
    this._pdfFiles = this._pdfFilesOrignal;

    if (index >= 0) {

      if (this._pdfPageRender === PageRenderMode.onePage) {
        this._pdfUrlId = index;
      } else if (this._pdfPageRender === PageRenderMode.twoPage) {
        if (this._pdfFiles.length - 2 >= index) {
          this._pdfUrlId = index;
        } else {
          index = this._pdfFiles.length - 2;
          if (index >= 0) {
            this._pdfUrlId = index;
          } else {
            this._pdfUrlId = this._pdfFiles.length - 1;
          }
        }
      } else if (this._pdfPageRender === PageRenderMode.fourPage) {
        if (this._pdfFiles.length - 4 >= index) {
          this._pdfUrlId = index;
        } else {
          index = this._pdfFiles.length - 4;
          if (index >= 0) {
            this._pdfUrlId = index;
          } else {
            this._pdfUrlId = this._pdfFiles.length - 1;
          }
        }
      }
    }
    else
      this._pdfUrlId = 0;

    this.updateToolbar();
  }

  // Highlight the activity annotation if present
  // Highlight color if stored in pdf service
  public howerOverActivity(activityId: string[]) {
    this.removeAllFabricElementFor(this._fabricCanvas);
    if (!activityId)
      return;
    if (this.pdfViewerService.activtyAnnoations && activityId) {
      let currrentActivityAnnotation = this.pdfViewerService.activtyAnnoations.find(element => element.activityId == activityId[0]);
      if (currrentActivityAnnotation) {
        this.prepareAndDrawAnnotationOnPDF(currrentActivityAnnotation.annotation);
      }
    }
  }

  // false = visable 
  // Will create IPDFToolbar object to 
  private getToolbar(): IPDFToolbar {
    return {
      leftRemaining: 0,
      rightRemaining: 0,
      fitToView: true,
      nextPage: true,
      prePage: true,
      zoomIn: true,
      zoomOut: true,
      detachWindow: true,
      pan: true
    }
  }

  // it Will check the current state of viewer and update the chart control.
  private updateToolbar() {

    if (this._pdfFiles.length > 0) {

      let toolbar = this.getToolbar();

      if (this._pdfPageRender === PageRenderMode.onePage) {
        toolbar.pan = this._panDisabled;
      }

      if (this._pdfPageRender === PageRenderMode.onePage || this._pdfPageRender === PageRenderMode.twoPage) {
        toolbar.zoomOut = false;
      }

      toolbar.leftRemaining = (this._pdfUrlId - 1) + 1; // index is 0 so addling 1 will make it correct remaining value.    
      if (this._pdfUrlId > 0) {
        toolbar.prePage = false;
      }




      if (this._pdfFiles.length > 0) {

        if (this._pdfPageRender === PageRenderMode.fourPage || this._pdfPageRender === PageRenderMode.twoPage) {
          toolbar.fitToView = true;
        } else if (this._pdfPageRender === PageRenderMode.onePage && this._zoom === this._fitToScreenZoom) {
          toolbar.fitToView = true;
        } else {
          toolbar.fitToView = false;
        }

        if (this._pdfPageRender === PageRenderMode.onePage || this._pdfPageRender === PageRenderMode.twoPage) {
          toolbar.zoomOut = false;
        }
        if (parseFloat(this._zoom) + 0.2 < 2) {
          toolbar.zoomIn = false;
        }
        toolbar.leftRemaining = (this._pdfUrlId - 1) + 1; // index is 0 so addling 1 will make it correct remaining value.    
        if (this._pdfUrlId > 0) {
          toolbar.prePage = false;
        }

        if (this._pdfPageRender === PageRenderMode.onePage) {
          if (this._pdfUrlId < this._pdfFiles.length - 1) {
            toolbar.rightRemaining = (this._pdfFiles.length - this._pdfUrlId) - 1;
            toolbar.nextPage = false;
          }
        }
          if (this._pdfPageRender === PageRenderMode.twoPage) {
            if (this._pdfUrlId < this._pdfFiles.length - 2) {
              toolbar.nextPage = false;
              toolbar.rightRemaining = (this._pdfFiles.length - this._pdfUrlId) - 2;
            }
          }

          if (this._pdfPageRender === PageRenderMode.fourPage) {
            if (this._pdfUrlId < this._pdfFiles.length - 4) {
              toolbar.nextPage = false;
              toolbar.rightRemaining = (this._pdfFiles.length - this._pdfUrlId) - 4;
            }
          }
        
      }

      this.updateToolbarControls.emit(toolbar);
      this.hideToolbar.emit(false);
    } else
      this.hideToolbar.emit(true);
  }

  public setSelectionFilterCheck(check: boolean) {
    this._fileterMode = check;

    if (this._selctionMode) {
      if (this._fileterMode) {
        this._pdfFiles = this._pdfFilesFiltered;
        this._pdfUrlId = 0;
      } else {
        this.updateToOrignalFiles();
      }

      if (this._pdfFiles.length === 0) {
        this.cleanFabricCanvas()
      }

      this.updateToolbar();
    }
  }


  // Apply PDF Filter.
  // Reset if urls is null.
  public applyPDFFilter(urls: string[]) {

    if (urls) {
      this._pdfFiles = urls;
      this._pdfUrlId = 0;

      if (this._pdfFiles.length === 0) {
        this.cleanFabricCanvas();
      }// More cases to add.      
    } else {
      this.updateToOrignalFiles();
    }
    this.updateToolbar();
  }

  // Annotation manupulation.-------------------------------------------------
  childWindow: Window;
  openPDFInNewWindow() {
    this.poppedout = true;
    var centerWidth = (window.screen.width ) / 2 + 100;
    var centerHeight = (window.screen.height) / 2 + 100;

    this.childWindow = window.open('/project/pdfpopup/' + this.projectId, '', 'fullscreen:yes,width=' + centerWidth+',height='+centerHeight+ ', top=150, left=150');
    let win = document.getElementsByClassName("upper-component") as HTMLCollectionOf<HTMLElement>;
    if (win[0].style.display === "block") {
      win[0].style.display = "none";
    } else {
      win[0].style.display = "none";
    }

    let lowerWin = document.getElementsByClassName("lower-component") as HTMLCollectionOf<HTMLElement>;
    lowerWin[0].style.height = "95%";
  }

  closePopWindow() {
    let win = window.opener.document.getElementsByClassName("upper-component") as HTMLCollectionOf<HTMLElement>;
    if (win[0].style.display === "none") {
      win[0].style.display = "block";
    } else {
      win[0].style.display = "block";
    }
    
    window.opener.gritParentComponent.pdfControl.detatchedWindow = false;
    window.opener.gritParentComponent.closedPDFPopout();
    window.close();
    
    this.poppedout = false;
  }

  removeOverflowPDFContainer() {
    let container = <HTMLElement>document.getElementsByClassName("ng2-pdf-viewer-container")[0];
    container.style.overflowX = 'unset';
  }

  isScrollAvailable() {

    //Remove left lag if canvas is bigger
    let pageBorderContainer = <HTMLElement>document.getElementsByClassName('pdfViewer removePageBorders')[0];
    let canvas = <HTMLElement>document.getElementById('page1');
    let singlePageRenderer = document.getElementById('singlePageRenderer')

    let fabriContainer = <HTMLElement>singlePageRenderer.children[1];

    if (fabriContainer) {
      if (pageBorderContainer.offsetWidth < canvas.offsetWidth) {
        fabriContainer.style.left = null;
        fabriContainer.style.right = null;
      }
      else {
        fabriContainer.style.left = '0px';
        fabriContainer.style.right = '0px';
      }
    }

    //Scroll check
    let _DRAG_PARENT = <any>document.getElementById("dragParent");
    let _DRAG_CHILD = <any>document.getElementById("dragChild");
    let cHeight = _DRAG_CHILD.children[0].offsetHeight;
    let cWidth = _DRAG_CHILD.children[0].offsetWidth;

    if (_DRAG_PARENT.offsetHeight === 1 && this._zoom === this._fitToScreenZoom) {
      this._panDisabled = true;
      return;
    }

    if (cHeight > _DRAG_PARENT.offsetHeight || cWidth > _DRAG_PARENT.offsetWidth)
      this._panDisabled = false;
    else
      this._panDisabled = true;
  }

  public getpdfPageRender(){
    return this._pdfPageRender;
  }

}
