import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sortBy } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForgeViewerService } from '../../services/forge/forge-viewer.service';
import { ForgeService } from '../../services/forge/forge.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProjectFilterSearchService } from '../../services/project/project-filter-search/project-filter-search.service';
import { ProjectNetworkMapService } from '../../services/project/project-network-map/project-network-map.service';
import { ProjectObjectService } from '../../services/project/project-object/project-object.service';
import { ProjectScheduleService } from '../../services/project/project-schedule/project-schedule.service';
import { ProjectStepService } from '../../services/project/project-step/project-step.service';
import { ProjectSubContractorService } from '../../services/project/project-subcontractor/project-subcontractor.service';
import { ProjectService } from '../../services/project/project.service';
import { SegmentService } from '../../services/segment/segment.service';
import { Utils } from '../../utils/utils';

import { IDropdownItem } from '../../models/dropdown/dropdown.interface';
import { ForgeViewerType } from '../../utils/enums/forge-viewer-type';
import { INetworkMapInput, INetworkMapStep, IProjectPlanStep, IProjectUnplannedPrereq } from '../../models/project/project-network-map/project-network-map.interface';
import { IProjectObject } from '../../models/project/project-object/project-object.interface';
import { IProjectSubContractor } from '../../models/project/project-subcontractor/project-subcontractor.interface';
import { ISelectionModal } from '../../models/selection-modal/selection-modal.interface';
import { ISidebarTab } from '../../models/sidebar/sidebar.interface';

import { FilterModelOptions } from '../../utils/enums/filter-model-options';
import { MessageType } from '../../utils/enums/message-type.enum';
import { NetworkMapView } from '../../utils/enums/networkmap-view.enum';
import { INoficationContext } from '../../models/notification/notification.interface';
import { PopupModalState } from '../../utils/enums/popup-modal-state.enum';
import { IProjectSchedule } from '../../models/project/project-schedule/project-schedule.interface';
import { SidebarState } from '../../utils/enums/sidebar-state';

@Component({
  selector: 'app-project-network-map',
  templateUrl: './project-network-map.component.html',
  styleUrls: ['./project-network-map.component.scss']
})
export class ProjectNetworkMapComponent implements OnInit, OnDestroy {
  // Template Vars
  pageIsLoading: boolean = true;
  errorGettingData: boolean = false;
  hideMap: boolean = false;
  mapView: NetworkMapView = NetworkMapView.Tree;
  selectedProjectObject: IProjectObject;
  firstLoad: boolean = true;
  popupModalState = PopupModalState;
  activeModalType: PopupModalState;
  versionListOptions: IDropdownItem[];

  // project info
  projectId: string;
  projectSubContractors: IProjectSubContractor[] = [];
  schedules: IProjectSchedule[] = [];

  // plan info
  projectPlanStep: any;
  projectPlanSteps: INetworkMapStep[] = [];
  projectStepIds: string[] = [];
  projectPlanStepIds: string[] = [];
  selectedActivityIds: string[];
  selPlanSteps = {};
  plannedObjectsState = {};
  influenceStepState = {};
  blockerStepState = {};
  criticalPathState = {};
  influenceStats: { steps: number, hours: number, subContractorData: any[] };
  criticalPathStats: { steps: number, hours: number, subContractorData: any[] };
  blockerStats: { steps: number, hours: number, subContractorData: any[] };
  selectedActivityTasks: IProjectPlanStep[] = [];
  showModalBackButton: boolean = false;
  curPlanId: string = null;

  // network info
  @ViewChild('networkMapViewer') networkMapViewer: any;
  networkMapTreeInput: INetworkMapInput;
  mapStepExpansionOutput: any;
  loadedPlanStepId: string;

  // Forge viewer
  myViewerState = {};
  selected = {};
  prereqs = {};
  blockers = {};
  successors = {};
  lastHoveredIds = {};
  curMapState = {};

  // Sidebar
  totalActiveNodes: number;
  totalActiveDuration: number;
  totalDurationOfAllSteps: number;
  showPlanInfoSideBar: boolean;
  sidebarData: boolean = false;
  sidebarTabIconData: ISidebarTab[];
  noSelectionMessage: string = 'select_object';
  sidebarState = SidebarState;
  activeSidebarState: SidebarState;
  activeFilters: string[];
  stepSubContractor: IProjectSubContractor = null;
  selectedGritObjectIds: string[] = [];
  filterTypesToHide: string[] = [];
  activeFilterType: FilterModelOptions = FilterModelOptions.Activities;
  filtersApplied: boolean = false;
  messageType = MessageType;

  // Confirmation Modal
  showModal: boolean = false;
  selectionModalData: ISelectionModal;

  // Subscription takeUntil
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private networkMapService: ProjectNetworkMapService,
    private notificationService: NotificationService,
    private subContractorService: ProjectSubContractorService,
    private filterSearchService: ProjectFilterSearchService,
    private projectObjectService: ProjectObjectService,
    private segmentService: SegmentService,
    private projectService: ProjectService,
    private projectStepService: ProjectStepService,
    private projectScheduleService: ProjectScheduleService,
    public forgeService: ForgeService,
    public forgeViewerService: ForgeViewerService
  ) { }

  async ngOnInit() {
    this.projectId = this.projectService.currentProject.id;
    this.pageIsLoading = true;

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(query => {
      if (query['stepId']) {
        this.loadedPlanStepId = query['stepId'];
      }
    });

    if (this.projectService.getProjectReady()) {
      this.firstLoad = false;
      this.setupPageData();
    }

    // listen for viewer initial setup
    this.projectService.projectSetupReady.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.forgeViewerService.clearAllThemingColors();

      if (this.forgeViewerService.poppedOut || !this.firstLoad) {
        if (!Utils.isEmpty(this.networkMapTreeInput)) {
          const activePlanStep: IProjectPlanStep = this.networkMapTreeInput.active;
          activePlanStep.objectIds.forEach(id => {
            this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Select });
          });
          this.handleViewChange(this.mapView);
          this.networkMapViewer.recenter();
        } else {
          this.resetMapAndViewerData();
        }
      } else {
        this.firstLoad = false;
        this.setupPageData();
      }
    });

    // Viewer output
    this.forgeViewerService.forgeViewerOutput.pipe(takeUntil(this.destroyed$)).subscribe(gritSelection => {
      this.handleForgeViewerOutput(gritSelection);
    });

    this.segmentService.track('Network Map Loaded', { projectId: this.projectId });
  }

  ngOnDestroy() {
    if (this.destroyed$ && !this.destroyed$.closed) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  // Data Methods
  async setupPageData() {
    this.forgeViewerService.clearAllThemingColors();
    this.forgeViewerService.clearCutPlanes();
    this.setupSideBar();

    await this.subContractorService.setLocalProjectSubcontractors(this.projectId);
    this.projectSubContractors = this.subContractorService.getLocalProjectSubcontractors();

    if (!await this.setupNetworkMapData()) {
      this.pageIsLoading = false;
      this.errorGettingData = true;
      return;
    }

    if (this.loadedPlanStepId) {
      this.projectStepService.getPlanStep(this.loadedPlanStepId).pipe(takeUntil(this.destroyed$)).subscribe(async res => {
        const object = res.objects[0];

        this.activeModalType = PopupModalState.SelectTask;
        this.selectedProjectObject = this.projectService.getObject(object.id);
        if (this.schedules.findIndex(s => s.planId === res.projectPlanId) > -1) {
          this.curPlanId = res.projectPlanId;
          this.selPlanSteps = await this.networkMapService.getPlanStepsForPlanIdObjectId(this.projectId, res.projectPlanId, object.id).toPromise();
          await this.setSelectedVersionFilter(this.curPlanId, true);
          this.getStepInfo(res.projectStepId);
        } else {
          await this.setSelectedVersionFilter(this.curPlanId);
        }
      });
    } else {
      await this.setSelectedVersionFilter(this.curPlanId);
    }
  }
  async setupNetworkMapData() {
    this.schedules = await this.networkMapService.getSchedules(this.projectId);
    if (!this.schedules || this.schedules.length < 1) return false;

    this.schedules = this.projectScheduleService.sortSchedules(this.schedules);

    let findRes = this.schedules.find(s => s.active === true);
    if (findRes) {
      this.curPlanId = findRes.planId;
    } else {
      findRes = this.schedules.find(s => s.living === true);
      if (findRes) {
        this.curPlanId = findRes.planId;
      } else {
        this.curPlanId = this.schedules[0].planId;
      }
    }
    this.versionListOptions = this.networkMapService.getVersionOptions(this.schedules);
    return true;
  }
  // END Data Methods

  setSelectedVersionFilter(planId: string, loadedStep: boolean = false): Promise<void> {
    this.plannedObjectsState = {};
    this.forgeViewerService.setDisablePage(true);

    return new Promise((resolve) => {
      this.networkMapService.getProjectPlanStepsList(planId).pipe(takeUntil(this.destroyed$)).subscribe(
        async res => {
          this.segmentService.track('Old Plan Selected', { projectId: this.projectId });
          this.projectPlanStep = res[0];
          this.projectPlanSteps = res;
          this.totalActiveNodes = this.projectPlanSteps.length;
          const totalDurations = this.projectPlanSteps.map(projectStep => projectStep.durationHours);
          this.totalDurationOfAllSteps = totalDurations.reduce((a, b) => a + b, 0);
          this.totalActiveDuration = this.totalDurationOfAllSteps;
          if (loadedStep) return resolve();
          await this.resetMapAndViewerData();
          this.forgeViewerService.fitObjectsToView(this.myViewerState);
          return resolve();
        },
        async err => {
          const context: INoficationContext = {
            type: 'project plan steps',
            action: 'get'
          };
          this.notificationService.error(err, context);
          if (loadedStep) return resolve();
          await this.resetMapAndViewerData();
          this.forgeViewerService.fitObjectsToView(this.myViewerState);
          return resolve();
        }
      );
    });
  }

  // Child Output Methods
  handleMapHover(activeObjectIds: string[]): void {
    if (!Utils.isEmptyList(activeObjectIds)) {
      this.lastHoveredIds = {};
      activeObjectIds.forEach(id => {
        this.lastHoveredIds[id] = id;
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Hover });
      });
    } else {
      Object.keys(this.lastHoveredIds).forEach((key) => {
        this.setStateAfterAddRemoveHover(key);
      });
      this.lastHoveredIds = {};
    }
  }

  handleMapExpansion(data: any): void {
    this.forgeViewerService.setDisablePage(true);
    this.networkMapService.getRelatedSteps(this.projectId, this.curPlanId, data.stepData.projectStepId).pipe(takeUntil(this.destroyed$)).subscribe(
      results => {
        const activeType = data.stepData.type;
        switch (activeType) {
          case 'successor':
            this.mapStepExpansionOutput = {
              base: data,
              nodes: results[0].successors
            };
            if (!Utils.isEmptyList(results[0].successors)) {
              let successorObjectIds = results[0].successors.map(dataObject => dataObject.objectIds);
              successorObjectIds = [].concat.apply([], successorObjectIds);
              this.appendMapSelectionToViewer(successorObjectIds, ForgeViewerType.Successor, this.successors, 'successors');
              this.forgeViewerService.setViewerState(this.myViewerState);
            } else {
              this.forgeViewerService.setDisablePage(false);
            }
            break;
          case 'prerequisite':
            this.mapStepExpansionOutput = {
              base: data,
              nodes: results[0].prerequisites,
              unplanned: results[0].unplannedPrerequisites
            };
            let prereqObjectIds;
            if (!Utils.isEmptyList(results[0].prerequisites)) {
              prereqObjectIds = results[0].prerequisites.map(dataObject => dataObject.objectIds);
              prereqObjectIds = [].concat.apply([], prereqObjectIds);
              this.appendMapSelectionToViewer(prereqObjectIds, ForgeViewerType.Prereq, this.prereqs, 'prereqs');
            }

            if (!Utils.isEmptyList(results[0].unplannedPrerequisites)) {
              prereqObjectIds = results[0].unplannedPrerequisites.map(dataObject => dataObject.objectId);
              prereqObjectIds = [].concat.apply([], prereqObjectIds);
              this.appendMapSelectionToViewer(prereqObjectIds, ForgeViewerType.Prereq, this.prereqs, 'prereqs');
            }
            if (!Utils.isEmptyList(results[0].unplannedPrerequisites) || !Utils.isEmptyList(results[0].prerequisites)) {
              this.forgeViewerService.setViewerState(this.myViewerState);
            } else {
              this.forgeViewerService.setDisablePage(false);
            }
            break;
        }
      },
      err => {
        this.forgeViewerService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'related step',
          action: 'get'
        };
        this.notificationService.error(err, context);
      });
  }

  handleMapCollapse(activeData: any): void {
    this.myViewerState = {};
    this.prereqs = {};
    this.blockers = {};
    this.selected = {};
    this.successors = {};
    let objectIds = activeData.filter(item => item.type === 'active').map(item => item.id);
    objectIds.forEach(id => {
      this.myViewerState[id] = id;
      this.selected[id] = id;
      this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Select });
    });
    this.selectedGritObjectIds = Object.keys(this.selected);
    this.curMapState['prereqs'] = {};
    objectIds = activeData.filter(item => item.type === 'prerequisite').map(item => item.id);
    objectIds.forEach(id => {
      this.myViewerState[id] = id;
      this.prereqs[id] = id;
      this.curMapState['prereqs'][id] = id;
      if (!Utils.isEmpty(this.selected[id])) {
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Prereq });
      }
    });
    this.curMapState['successors'] = {};
    objectIds = activeData.filter(item => item.type === 'successor').map(item => item.id);
    objectIds.forEach(id => {
      this.myViewerState[id] = id;
      this.successors[id] = id;
      this.curMapState['successors'][id] = id;
      if (!Utils.isEmpty(this.selected[id]) || !Utils.isEmpty(this.prereqs[id])) {
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Successor });
      }
    });

    this.forgeViewerService.setViewerState(this.myViewerState);
  }

  async getInfluenceData() {
    this.forgeViewerService.setDisablePage(true);
    this.networkMapService.getPlanStepInfluence(this.projectId, this.curPlanId, this.projectPlanStep.id).pipe(takeUntil(this.destroyed$)).subscribe(
      async results => {
        const subContractorIds = results[0].subContractorIds;
        const subContractorData = sortBy(subContractorIds.map(id => this.projectSubContractors.find(sub => sub.id === id)), 'name');
        for (let s = subContractorData.length - 1; s >= 0; s--) {
          if (!subContractorData[s]) {
            subContractorData.splice(s, 1);
          }
        }
        this.influenceStats = {
          steps: results[0].successorObjectIds.length,
          hours: Utils.isEmpty(results[0].totalHours) ? 0 : results[0].totalHours,
          subContractorData: subContractorData
        };

        this.influenceStepState = {};
        results[0].successorObjectIds.map(id => this.influenceStepState[id] = id);
        this.setInfluenceView(this.influenceStepState);
      },
      err => {
        const context: INoficationContext = {
          type: 'step influence',
          action: 'get'
        };
        this.notificationService.error(err, context);
        this.forgeViewerService.setDisablePage(false);
      });
  }

  async getCriticalPathData() {
    this.networkMapService.getPlanStepCriticalPath(this.projectId, this.curPlanId, this.projectPlanStep.id).pipe(takeUntil(this.destroyed$)).subscribe(
      async results => {
        const totalHours: number = results.successors.hours + results.prerequisites.hours + this.projectPlanStep.durationHours;

        let subContractorData = [];
        if (!Utils.isEmpty(this.projectPlanStep.subContractorId)) {
          let subContractorIds = [this.projectPlanStep.subContractorId];
          const incomingSubcontractorIds = [].concat.apply(results.successors.subContractorIds, results.prerequisites.subContractorIds);
          subContractorIds = Utils.deDupeArray([].concat.apply(subContractorIds, incomingSubcontractorIds));
          subContractorData = sortBy(subContractorIds.map(id => this.projectSubContractors.find(sub => sub.id === id)), 'name');
          for (let s = subContractorData.length - 1; s >= 0; s--) {
            if (!subContractorData[s]) {
              subContractorData.splice(s, 1);
            }
          }
        }
        this.criticalPathStats = {
          steps: (1 + results.successors.steps.length + results.prerequisites.steps.length),
          hours: totalHours,
          subContractorData: subContractorData
        };

        this.criticalPathState['successors'] = {};
        results.successors.steps.forEach(step => {
          step.objects.map(id => this.criticalPathState['successors'][id] = id);
        });
        this.criticalPathState['prereqs'] = {};
        results.prerequisites.steps.forEach(step => {
          step.objects.map(id => this.criticalPathState['prereqs'][id] = id);
        });
        this.setCriticalPathOrMapView(this.criticalPathState, true);
      },
      err => {
        const context: INoficationContext = {
          type: 'critical step data',
          action: 'get'
        };
        this.notificationService.error(err, context);
        this.forgeViewerService.setDisablePage(false);
      });
  }

  async setInfluenceView(influenceState: any) {
    this.hideMap = true;
    this.successors = {};
    this.myViewerState = Utils.copyKeysFromObject(this.selected);
    Object.keys(influenceState).forEach((key) => {
      this.successors[key] = key;
      this.myViewerState[key] = key;
      this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Successor });
    });
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitObjectsToView(this.myViewerState);
  }

  async setCriticalPathOrMapView(mapOrPathState: any, hideMap: boolean) {
    this.hideMap = hideMap;
    this.successors = {};
    this.prereqs = {};
    this.blockers = {};
    this.myViewerState = Utils.copyKeysFromObject(this.selected);
    Object.keys(mapOrPathState['successors']).forEach((key) => {
      this.successors[key] = key;
      this.myViewerState[key] = key;
      if (!Utils.isEmpty(this.selected[key])) {
        this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Successor });
      }
    });
    Object.keys(mapOrPathState['prereqs']).forEach((key) => {
      this.prereqs[key] = key;
      this.myViewerState[key] = key;
      if (!Utils.isEmpty(this.selected[key]) || !Utils.isEmpty(mapOrPathState['successors'][key])) {
        this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Prereq });
      }
    });
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitObjectsToView(this.myViewerState);
  }

  async getMapView(networkMapStepInput: INetworkMapInput) {
    this.curMapState['prereqs'] = {};
    const prereqs: IProjectPlanStep[] = networkMapStepInput.prereqs;
    prereqs.forEach(planStep => {
      planStep.objectIds.forEach(id => {
        this.curMapState['prereqs'][id] = id;
      });
    });
    const unplannedPrereqs: IProjectUnplannedPrereq[] = networkMapStepInput.unplannedPrereqs;
    unplannedPrereqs.forEach(obj => {
      this.curMapState['prereqs'][obj.objectId] = obj.objectId;
    });
    this.curMapState['successors'] = {};
    const successors: IProjectPlanStep[] = networkMapStepInput.successors;
    successors.forEach(planStep => {
      planStep.objectIds.forEach(id => {
        this.curMapState['successors'][id] = id;
      });
    });
    this.setCriticalPathOrMapView(this.curMapState, false);
  }

  async setBlockersView() {
    this.hideMap = true;
    this.myViewerState = Utils.copyKeysFromObject(this.selected);
    Object.keys(this.blockerStepState).forEach((key) => {
      this.myViewerState[key] = key;
      this.forgeViewerService.setState({ gritObjectId: key, type: ForgeViewerType.Blocker });
    });
    await this.forgeViewerService.setViewerState(this.myViewerState);
    this.forgeViewerService.fitObjectsToView(this.myViewerState);
  }

  async handleViewChange(type: NetworkMapView) {
    this.mapView = type;
    if (Utils.isEmpty(this.selectedProjectObject)) { this.forgeViewerService.setDisablePage(false); return; }
    switch (this.mapView) {
      case NetworkMapView.Tree:
        if (Object.keys(this.curMapState).length <= 0) {
          this.getMapView(this.networkMapTreeInput);
        } else {
          this.setCriticalPathOrMapView(this.curMapState, false);
        }
        break;
      case NetworkMapView.Influence:
        if (Object.keys(this.influenceStepState).length <= 0) {
          this.getInfluenceData();
        } else {
          this.setInfluenceView(this.influenceStepState);
        }
        break;
      case NetworkMapView.Path:
        if (Object.keys(this.criticalPathState).length <= 0) {
          this.getCriticalPathData();
        } else {
          this.setCriticalPathOrMapView(this.criticalPathState, true);
        }
        break;
      case NetworkMapView.Blockers:
        this.setBlockersView();
        break;
      default:
        this.forgeViewerService.setDisablePage(false);
        break;
    }
  }
  // END Child Output Methods

  getActivityInfo(selectedObjectId: string, selectedActivities: string[]): void {
    this.forgeViewerService.setDisablePage(true);
    this.networkMapService.getObjectPlan(this.projectId, this.curPlanId, selectedObjectId, selectedActivities[0], 1).pipe(takeUntil(this.destroyed$)).subscribe(
      async res => {
        if (res) {
          this.networkMapTreeInput = res[0]; // map data input
          this.projectPlanStep = this.selPlanSteps[res[0].active.id];
          this.projectStepIds = [this.projectPlanStep.projectStepId];
          this.projectPlanStepIds = [this.projectPlanStep.id];
          if (!Utils.isEmpty(this.projectPlanStep.subContractorId)) this.stepSubContractor = this.projectSubContractors.find(sub => sub.id === this.projectPlanStep.subContractorId);
          const activePlanStep: IProjectPlanStep = this.networkMapTreeInput.active;
          this.selected = {};
          this.forgeViewerService.clearAllThemingColors();
          activePlanStep.objectIds.forEach(id => {
            this.selected[id] = id;
            this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Select });
          });
          this.selectedGritObjectIds = Object.keys(this.selected);
          this.setupBlockerData(res[0]['blockers']);
          this.handleViewChange(this.mapView);
        } else {
          await this.resetMapAndViewerData();
        }

        this.forgeViewerService.setDisablePage(false);
      },
      err => {
        this.forgeViewerService.setDisablePage(false);
        const context: INoficationContext = {
          type: 'pre-requisite(s) and successor(s)',
          action: 'get'
        };
        this.notificationService.error(err, context);
      }
    );
  }

  getStepInfo(stepId?: string): void {
    this.forgeViewerService.setDisablePage(true);
    if (stepId) {
      this.networkMapService.getRelatedSteps(this.projectId, this.curPlanId, stepId).pipe(takeUntil(this.destroyed$)).subscribe(
        async res => {
          if (res) {
            this.networkMapTreeInput = {
              active: res[0].active ? res[0].active : [],
              blockers: res[0].blockers ? res[0].blockers : [],
              prereqs: res[0].prerequisites ? res[0].prerequisites : [],
              successors: res[0].successors ? res[0].successors : [],
              unplannedPrereqs: res[0].unplannedPrerequisites ? res[0].unplannedPrerequisites : []
            };
            this.projectPlanStep = this.selPlanSteps[res[0].active.id];
            this.projectStepIds = [this.projectPlanStep.projectStepId];
            this.projectPlanStepIds = [this.projectPlanStep.id];
            if (!Utils.isEmpty(this.projectPlanStep.subContractorId)) this.stepSubContractor = this.projectSubContractors.find(sub => sub.id === this.projectPlanStep.subContractorId);
            const activePlanStep: IProjectPlanStep = this.networkMapTreeInput.active;
            this.selected = {};
            this.forgeViewerService.clearAllThemingColors();
            activePlanStep.objectIds.forEach(id => {
              this.selected[id] = id;
              this.forgeViewerService.setState({ gritObjectId: id, type: ForgeViewerType.Select });
            });
            this.selectedGritObjectIds = Object.keys(this.selected);
            this.setupBlockerData(res[0]['blockers']);
            await this.handleViewChange(this.mapView);
            this.pageIsLoading = false;
            this.forgeViewerService.setDisablePage(false);
          } else {
            await this.resetMapAndViewerData();
          }
        },
        err => {
          this.forgeViewerService.setDisablePage(false);
          this.pageIsLoading = false;
          const context: INoficationContext = {
            type: 'pre-requisite(s) and successor(s)',
            action: 'get'
          };
          this.notificationService.error(err, context);
        }
      );
    } else {
      this.resetMapAndViewerData();
    }
  }

  setupBlockerData(blockers) {
    this.blockerStepState = {};
    if (blockers.length > 0) {
      let subContractorData = [];
      const activities = [].concat.apply([], blockers.map(b => b.activities));
      this.projectSubContractors.forEach(sub => {
        const findRes = sub.activities.find(t => activities.includes(t));
        if (findRes) {
          subContractorData.push(sub);
        }
      });
      if (subContractorData.length > 0) subContractorData = sortBy(subContractorData, 'name');
      const totalHours = blockers.map(b => b.durationHours).reduce((a, b) => a + b, 0);
      this.blockerStats = {
        steps: blockers.length,
        hours: totalHours,
        subContractorData: subContractorData
      };

      blockers.forEach(b => {
        b.objectIds.forEach(id => {
          this.blockerStepState[id] = id;
        });
      });
    }
  }

  setStateAfterAddRemoveHover(gritObjectId: string): void {
    if (!Utils.isEmpty(this.selected[gritObjectId])) {
      if (!Utils.isEmpty(this.prereqs[gritObjectId]) || !Utils.isEmpty(this.successors[gritObjectId])) {
        this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Select });
      }
    } else if (!Utils.isEmpty(this.prereqs[gritObjectId])) {
      if (!Utils.isEmpty(this.successors[gritObjectId])) {
        this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Self });
      } else {
        this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Prereq });
      }
    } else if (!Utils.isEmpty(this.successors[gritObjectId])) {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Successor });
    } else if (!Utils.isEmpty(this.blockers[gritObjectId])) {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Blocker });
    } else {
      this.forgeViewerService.setState({ gritObjectId: gritObjectId, type: ForgeViewerType.Default });
    }
  }

  appendMapSelectionToViewer(objectIds: string[], type: ForgeViewerType, indexMap: any, typeString: string): void {
    objectIds.forEach(objectId => {
      indexMap[objectId] = objectId;
      this.curMapState[typeString][objectId] = objectId;
      this.myViewerState[objectId] = objectId;
      this.forgeViewerService.setState({ gritObjectId: objectId, type: type });
    });
  }
  // END Data Transforms

  // Sidebar
  setupSideBar(): void {
    this.showPlanInfoSideBar = this.projectService.showProjectSidebar;
    this.sidebarTabIconData = this.networkMapService.sidebarTabs;
    const getActiveSideBar = this.networkMapService.sidebarTabs.find(t => t.active === true);
    const curActiveSideBar = getActiveSideBar ? getActiveSideBar : this.sidebarTabIconData[0];
    curActiveSideBar.active = true;

    this.activeSidebarState = curActiveSideBar.key;
    this.setSidebarState(curActiveSideBar);

    this.forgeViewerService.resizeViewerAfterLoad();
  }

  handleSidebarIconClick(tab: ISidebarTab): void {
    // toggle sidebar open and close if you click on the same tab
    // if changing tabs while sidebar is open then keep it open
    if (this.activeSidebarState === tab.key) this.toggleSideBar();
    else this.toggleSideBar(true);

    this.setSidebarState(tab);
  }

  setSidebarState(tab: ISidebarTab): void {
    this.segmentService.track('Network Map Sidebar Loaded', { sideBar: tab.key });
    this.sidebarTabIconData.map(item => item.active = false);
    tab.active = true;
    this.activeSidebarState = tab.key;
  }

  toggleSideBar(isVisible?: boolean): void {
    this.showPlanInfoSideBar = isVisible;

    if (isVisible != null) this.projectService.toggleSidebarVisibility(isVisible);
    else this.projectService.toggleSidebarVisibility();
    // SetTimeout is used here so the viewer is resized after the CSS animation
    // to resize to correct width
    setTimeout(() => { this.forgeViewerService.resizeViewerAfterLoad(); if (!Utils.isEmpty(this.networkMapTreeInput)) this.networkMapViewer.recenter(); }, 500);
  }
  // END Sidebar

  // Data resets
  async resetMapAndViewerData() {
    this.resetData();
    this.activeFilters = [];
    this.filtersApplied = false;
    this.selectedProjectObject = null;
    await this.showPlannedObjects();
  }

  resetData(): void {
    this.sidebarData = false;
    this.noSelectionMessage = 'Select an object in the model.';
    this.criticalPathState = {};
    this.influenceStepState = {};
    this.curMapState = {};
    this.influenceStats = { steps: 0, hours: 0, subContractorData: [] };
    this.criticalPathStats = { steps: 0, hours: 0, subContractorData: [] };
    this.blockerStats = { steps: 0, hours: 0, subContractorData: [] };
    this.forgeViewerService.clearAllThemingColors();
    if (this.networkMapViewer) this.networkMapViewer.resetMap();
    this.selectedGritObjectIds = [];
    this.selectedActivityIds = [];
    this.selectedActivityTasks = [];
    this.projectPlanStep = null;
    this.projectStepIds = [];
    this.projectPlanStepIds = [];
    this.networkMapTreeInput = null;
  }

  resizeForgeViewer() {
    this.forgeViewerService.viewerComponent.resize();
  }
  // END Data resets

  handleMapDblClick(selectedStep: IProjectPlanStep): void {
    this.selPlanSteps = {};
    this.curMapState = {};
    this.selPlanSteps[selectedStep.id] = selectedStep;
    this.forgeViewerService.setDisablePage(true);
    this.getStepInfo(selectedStep.projectStepId);
  }

  async handleForgeViewerOutput(gritViewerOutputData) {
    this.activeFilters = [];
    if (gritViewerOutputData !== 'esc') {
      if (gritViewerOutputData.length > 0) {
        if (gritViewerOutputData[0].length > 0) {
          this.selectedProjectObject = this.projectObjectService.getLocalObject(gritViewerOutputData[0]);
          this.handleViewerClickOnObject();
        }
      } else {
        await this.resetMapAndViewerData();
        this.forgeViewerService.fitObjectsToView(this.myViewerState);
      }
    } else {
      await this.resetMapAndViewerData();
      this.forgeViewerService.fitObjectsToView(this.myViewerState);
    }
  }

  async handleViewerClickOnObject() {
    this.forgeViewerService.setDisablePage(true);
    this.selPlanSteps = await this.networkMapService.getPlanStepsForPlanIdObjectId(this.projectId, this.curPlanId, this.selectedProjectObject.id).toPromise();

    if (!Utils.objectIsEmpty(this.selPlanSteps)) {
      this.resetData();
      this.sidebarData = true;
      this.selectedActivityIds = this.getActivityIdsFromSteps(this.selPlanSteps);
      this.setupPopupModal(PopupModalState.SelectActivity);
    } else {
      this.notificationService.info('APP_NOT_PLANNED', {});
      this.forgeViewerService.setDisablePage(false);
    }
  }

  getActivityIdsFromSteps(steps: { [key: string]: IProjectPlanStep }): string[] {
    let uniqueActivityIds = [];
    const stepIds = Object.keys(steps);
    if (stepIds && stepIds.length > 0) {
      let allActivityIds = [];
      stepIds.map(key => allActivityIds = allActivityIds.concat(steps[key].activities));
      uniqueActivityIds = Utils.deDupeArray(allActivityIds);
    }
    return uniqueActivityIds;
  }

  // Confirmation Modal
  async setupPopupModal(type: PopupModalState) {
    if (type === PopupModalState.SelectActivity) {
      this.setupActivityConfirmationModal();
    } else {
      this.setupTaskConfirmationModal();
    }
  }
  async setupActivityConfirmationModal() {
    this.activeModalType = PopupModalState.SelectActivity;
    if (this.selectedActivityIds.length === 1) {
      this.handleActivitySelection(this.selectedProjectObject.activities);
      this.showModalBackButton = false;
    } else {
      this.selectionModalData = await this.networkMapService.transformActivitySelectionModalData(this.selectedActivityIds);
      this.showModalBackButton = true;

      this.forgeViewerService.setDisablePage(false);
      this.showModal = true;
    }
  }
  async setupTaskConfirmationModal() {
    this.activeModalType = PopupModalState.SelectTask;
    if (this.selectedActivityTasks.length > 0) {
      this.selectionModalData = await this.networkMapService.transformTaskSelectionModalData(this.selectedActivityTasks);
      this.forgeViewerService.setDisablePage(false);
      this.showModal = true;
    }
  }
  closeModalClick(): void {
    this.showModal = false;
    this.resetMapAndViewerData();
  }
  handleModalSelection(selectedActivityObj: any): void {
    if (this.activeModalType === PopupModalState.SelectActivity) {
      this.handleActivitySelection([selectedActivityObj.activity]);
    } else {
      this.getStepInfo(selectedActivityObj.step);
    }
    this.showModal = false;
    this.forgeViewerService.setDisablePage(true);
  }
  handleModalBackButtonClick(): void {
    this.setupActivityConfirmationModal();
  }
  // END Confirmation Modal

  // Data Transforms
  handleActivitySelection(selectedActivities: string[]): void {
    this.selectedActivityTasks = this.getChildrenStepsFromActivity(this.selPlanSteps, selectedActivities);
    if (this.selectedActivityTasks.length > 1) {
      this.setupTaskConfirmationModal();
    } else if (this.selectedActivityTasks.length === 1) {
      this.getStepInfo(this.selectedActivityTasks[0].projectStepId);
    } else {
      this.getStepInfo();
    }
  }

  getChildrenStepsFromActivity(steps: { [key: string]: IProjectPlanStep }, selectedActivityIds: string[]): IProjectPlanStep[] {
    const childSteps = [];
    for (const key in steps) {
      if (steps[key]) {
        const stepActivityIds = steps[key].activities ? steps[key].activities : [];
        const stepBelongsToActivity = Utils.similarValuesInArrays(stepActivityIds, selectedActivityIds).length > 0;
        if (stepBelongsToActivity) {
          childSteps.push(steps[key]);
        }
      }
    }

    return childSteps;
  }

  async showPlannedObjects() {
    if (this.projectPlanSteps.length > 0) {
      if (Utils.objectIsEmpty(this.plannedObjectsState)) {
        this.loadedPlanStepId = null;
        this.myViewerState = await this.networkMapService.getPartiallyPlannedObjects(this.projectId, this.curPlanId, []).toPromise();
        if (Utils.objectIsEmpty(this.myViewerState)) this.notificationService.info('Plan has no tasks associated with model objects.', {});
        this.plannedObjectsState = Utils.copyKeysFromObject(this.myViewerState);
      } else {
        this.myViewerState = Utils.copyKeysFromObject(this.plannedObjectsState);
      }
      await this.forgeViewerService.setViewerState(this.myViewerState);
    }
    this.forgeViewerService.setDisablePage(false);
    this.pageIsLoading = false;
  }

  filterModel(filters: string[]) {
    this.filtersApplied = Utils.isEmptyList(filters) ? false : true;
    this.resetData();
    this.selectedProjectObject = null;
    this.activeFilters = filters;
    this.applyFilterOnly();
  }

  async switchFilterType(type: FilterModelOptions) {
    this.activeFilterType = type;
    this.activeFilters = [];
    this.filterModel([]);
  }

  async applyFilterOnly() {
    this.forgeViewerService.setDisablePage(true);
    if (!Utils.isEmptyList(this.activeFilters)) {
      const filterObjects = await this.filterSearchService.getFilterData(this.activeFilters, this.activeFilterType, true);
      this.myViewerState = Utils.copyKeysFromObject(filterObjects);
      this.projectService.setFilteringModel(true);
      await this.forgeViewerService.setViewerState(this.myViewerState);
    } else {
      this.showPlannedObjects();
    }
  }

  navigateToPlannerPage(): void {
    this.router.navigateByUrl('/project/' + this.projectId + '/planner');
  }

  pageRefresh(): void {
    window.location.reload(true);
  }

}
  // END Map and Viewer Outputs
