import { Injectable } from '@angular/core';

import { EditType, IColHeader, IGritTable, IGRow, IGRowItem, RowItemType } from '../../shared/grit-table/grit-table';
import { ISubContractor } from '../../models/subcontractor/subcontractor.interface';

import { HttpBackendService } from '../http-backend/http-backend.service';
import { IUserPermission } from '../../models/user/user.interface';

@Injectable()
export class SubContractorService {

  constructor(private _http: HttpBackendService) { }

  public getSubContractorList(accountId: string) {
    return this._http.get('/account/' + accountId + '/subContractors');
  }

  public createSubContractor(json: any) {
    return this._http.post('/subContractor', json);
  }

  public importFromProcore(json: any) {
    return this._http.post('/subContractor/procore', json);
  }

  public updateSubContractor(json: any) {
    return this._http.put('/subContractor/' + json.id, json);
  }

  public deleteSubContractor(subContractorId: string) {
    return this._http.delete('/subContractor/' + subContractorId);
  }

  transformToTableData(dataInput: ISubContractor[], permission: IUserPermission): IGritTable {
    const colHeaders: IColHeader[] = [
      {
        displayName: 'name',
        colKey: 'name',
        type: RowItemType.Text
      },
      {
        displayName: 'address',
        colKey: 'address',
        type: RowItemType.Text
      }
    ];

    const rows: IGRow[] = [];
    dataInput.forEach(sub => {
      const rowItems: IGRowItem[] = [
        {
          colKey: 'name',
          value: sub.name,
          editable: permission.edit
        },
        {
          colKey: 'address',
          value: sub.address,
          editable: permission.edit,
          required: false
        }
      ];
      rows.push(
        {
          key: sub.id,
          rowItems: rowItems,
          selectable: true,
          editOptions: {
            deletePermission: permission.edit,
            rowEdits: permission.edit ? [{type: EditType.InlineEdit}, {type: EditType.Delete}] : []
          }
        }
      );
    });

    const retTable: IGritTable = {
      colHeaders: colHeaders,
      rows: rows,
      addOptions: {
        addPermission: permission.edit,
        inline: true,
        addModel: [
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'name',
            required: true,
            editable: true,
          },
          {
            type: RowItemType.Text,
            value: '',
            colKey: 'address',
            required: false,
            editable: true,
          }
        ]
      }
    };

    return retTable;
  }
}
